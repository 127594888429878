import React, { useState } from "react";
import {
	DropDown,
	Table,
	GenemodIcon,
	LoadingSpinner,
	Notification,
	Typography,
	DropdownV2,
} from "@components";
import { useItemTypeNameMappingsFn } from "@helpers/Hooks";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { IdsInConstType } from "@helpers/TypeHelpers";
import "./ItemGroupList.scss";
import { ColumnProps, TableEventListeners } from "antd/lib/table";
import { Box, ITEM_GROUP_CONTAINERS, ItemGroup } from "@common/types";
import { useExportState } from "@containers/Freezer/hooks";
import column_styles from "@components/Table/ItemColumnWidth.module.scss";
import { truncArgs } from "@helpers/Formatters";
import { useCommonModalState } from "@redux/CommonModals/hooks";

import {
	DropdownMenuDivider,
	DropdownMenuItem,
} from "@common/components/DropDown/dropdown";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { useItemTypesQuery } from "@redux/inventory/Item";
import { wasCreatedOnTheLastFiveSeconds } from "@helpers/UXHelpers";
import { useRepository } from "@containers/Freezer/Repository/RepositoryContext";

type ItemGroupListProps = {
	itemGroups: ItemGroup[];
	/** True if parent freezer is archived */
	viewOnly: boolean;
	onRow: (record: ItemGroup, index: number) => TableEventListeners;
};

/** Table Component to show item groups as ListView */
export default function ItemGroupList(props: ItemGroupListProps): JSX.Element {
	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const { isLoading: itemTypeLoading } = useItemTypesQuery();
	const itemTypeNameMappingsFn = useItemTypeNameMappingsFn();
	const { handleExport, exportModal } = useExportState();

	const [showDropdown, setDropdown] = useState(false);
	const [dropdownIndex, setDropdownIndex] = useState(-1);

	const { openDeleteItemGroupModal } = useCommonModalState(
		"deleteItemGroupModal"
	);
	const {
		genericallyNamed: { openPanel },
	} = useCommonPanelState("itemGroupSettings");
	const { setSelectedItems, setSelectedLayer, setIsArchiveModalVisible } =
		useRepository();

	const handleItemGroupMenu = (e: ClickParam) => {
		const event = e.domEvent;
		event.stopPropagation();
		setDropdown(false);

		const itemGroup = props.itemGroups[dropdownIndex];
		if (!itemGroup) return;
		const key = e.key;
		if (key === "settings") {
			openPanel({
				id: itemGroup.id,
			});
		} else if (key === "share") {
			openShareLinkModal({
				additionalPath: `tem-groups/${itemGroup.id}`,
			});
		} else if (key === "export") {
			handleExport("item-groups", itemGroup.id, itemGroup.name);
		} else if (key === "archive") {
			setSelectedLayer("ITEMGROUP");
			setSelectedItems([itemGroup]);
			setIsArchiveModalVisible(true);
		} else if (key === "delete") {
			openDeleteItemGroupModal({ id: itemGroup.id });
		}
	};

	const dropdownMenu = (
		<Menu onClick={handleItemGroupMenu}>
			<DropdownMenuItem key="settings" label="Settings" icon="settings" />
			<DropdownMenuItem key="share" label="Share" icon="share" />
			<DropdownMenuItem key="export" label="Export" icon="export" />
			<DropdownMenuDivider />
			{!props.viewOnly && (
				<DropdownMenuItem
					key="archive"
					label="Archive"
					icon="archive"
					color="dust-red"
				/>
			)}
		</Menu>
	);

	const columns: ColumnProps<any>[] = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			ellipsis: true,
			render: (name: string) => <Typography ellipsis>{name}</Typography>,
		},
		{
			title: "Item Count",
			dataIndex: "item_count",
			key: "item_count",
			align: "right" as const,
			className: column_styles["S_COLUMN_WIDTH"],
			render: (count: string) => {
				return "x" + count;
			},
		},
		{
			title: "Item Type",
			dataIndex: "item_type",
			key: "item_type",
			align: "left" as const,
			className: column_styles["M_COLUMN_WIDTH"],
			render: itemTypeNameMappingsFn,
		},
		{
			title: "Container Type",
			dataIndex: "container_type",
			key: "container_type",
			align: "left" as const,
			className: column_styles["M_COLUMN_WIDTH"],
			render: (
				type: IdsInConstType<typeof ITEM_GROUP_CONTAINERS>,
				row: ItemGroup
			) => {
				return row.container_volume.length > 0
					? ITEM_GROUP_CONTAINERS[type] +
							" (" +
							row.container_volume +
							")"
					: ITEM_GROUP_CONTAINERS[type];
			},
		},
		{
			title: "Dropdown",
			key: "dropdown",
			width: 56,
			render: (_value: string, _row: ItemGroup, index: number) => {
				return (
					<DropdownV2
						visible={index === dropdownIndex && showDropdown}
						onVisibleChange={(visible) => setDropdown(visible)}
						overlay={dropdownMenu}
					>
						<GenemodIcon
							name="meatballs"
							size="large"
							hoverEffect
							onClick={(e) => {
								e.stopPropagation();
								setDropdownIndex(index);
							}}
						/>
					</DropdownV2>
				);
			},
		},
	];
	return (
		<div className="FreezerCategoryList">
			<LoadingSpinner loading={itemTypeLoading}>
				<Table
					showHeader={false}
					columns={columns}
					dataSource={props.itemGroups}
					onRow={props.onRow}
					rowClassName={(column: ItemGroup) =>
						wasCreatedOnTheLastFiveSeconds(column)
							? "isNewListLikeComponent"
							: ""
					}
				/>
			</LoadingSpinner>
			{exportModal}
		</div>
	);
}
