import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import commonStyles from "common/styles/common.module.scss";
import {
	GenemodIcon,
	Modal,
	RadioButtonV2,
	Select,
	SelectV2,
	Tooltip,
	Typography,
} from "@common/components";
import ItemTypeSelect from "@containers/Freezer/components//ItemTypeSelect/ItemTypeSelect";
import {
	MAX_ROWS_BOX,
	MAX_COLS_BOX,
	MIN_ROWS,
	MIN_COLS,
} from "@containers/Freezer/data";
import { RadioChangeEvent } from "antd/lib/radio";
import styles from "./AddBoxModal.module.scss";
import { useFreezer } from "../Contents";
import {
	DEFAULT_ITEM_TYPE,
	Box,
	Dimension,
	ITEM_GROUP_CONTAINERS,
	ItemContainerLocation,
	Rack,
	Category,
	ITEM_STATUS,
} from "@common/types";
import { useLazyFreezerRecentBoxDimensionQuery } from "@redux/inventory/Freezer";
import { useItemTypesQuery } from "@redux/inventory/Item";
import { useDebounceEventHandler } from "@helpers/Hooks";
import { useGetRepositoryRacksQuery } from "@redux/inventory/Rack";
import { useGetRepositoryCategoriesQuery } from "@redux/inventory/Category";
import { AppState } from "@redux/store";
import InputV3 from "@common/components/InputV3/InputV3";

type AddBoxModalProps = {
	visible: boolean;
	onCancel: () => void;
	onConfirm: (box: Partial<Box>) => void;
	isCategory?: boolean;
	isRepository?: boolean;
};

/** Modal component to create a new Box in Rack and Cagegory */
export default function AddBoxModal({
	visible,
	onConfirm,
	onCancel,
	isCategory,
	isRepository,
}: AddBoxModalProps): JSX.Element {
	const updatedTypeId = useSelector(
		(state: AppState) => state.itemType.typeId
	);

	const { freezer } = useFreezer();
	const { data: itemTypes } = useItemTypesQuery();
	const defaultItemType =
		itemTypes?.find(
			(itemType) =>
				!itemType.is_locked &&
				itemType.id ===
					(freezer?.default_item_type || DEFAULT_ITEM_TYPE)
		)?.id || DEFAULT_ITEM_TYPE;

	const [name, setName] = useState("");
	const [showErrors, setShowErrors] = useState(false);
	const [rows, setRows] = useState(9);
	const [cols, setCols] = useState(9);
	const [isCustom, setIsCustom] = useState(false);
	const [isRecent, setRecent] = useState(false);
	const [recentDimension, setRecentDimension] = useState<null | Dimension>(
		null
	);

	const [itemType, setItemType] = useState(defaultItemType);
	const [isLoading, setLoading] = useState(false);
	const [rack, setRack] = useState<Rack | undefined>(undefined);
	const [category, setCategory] = useState<Category | undefined>(undefined);

	const [fetchRecentBoxDimension] = useLazyFreezerRecentBoxDimensionQuery();
	useEffect(() => {
		setName("");
		setRows(9);
		setCols(9);
		setIsCustom(false);
		setRecent(false);
		setLoading(false);
		setItemType(defaultItemType);
		setShowErrors(false);
		if (freezer && visible) {
			fetchRecentBoxDimension(freezer.id)
				.unwrap()
				.then((data) => {
					if (data && data.row && data.col) {
						setRecent(true);
						setRecentDimension({
							row: data.row,
							col: data.col,
						});
						setRows(data.row);
						setCols(data.col);
					}
				});
		}
	}, [visible]);

	useEffect(() => {
		if (updatedTypeId !== null) {
			const { typeId } = updatedTypeId as unknown as { typeId: number };
			setItemType(typeId);
		}
	}, [updatedTypeId]);

	const validateForm = () => {
		return !!name.trim().length;
	};

	const handleRadioChg = (e: string) => {
		if (e === "CUSTOM") {
			setIsCustom(true);
			setRecent(false);
		} else if (e === "RECENT" && recentDimension) {
			setRows(recentDimension.row);
			setCols(recentDimension.col);
			setRecent(true);
			setIsCustom(false);
		} else {
			if (e === "9x9") {
				setRows(9);
				setCols(9);
			} else if (e === "10x10") {
				setRows(10);
				setCols(10);
			}
			setIsCustom(false);
			setRecent(false);
		}
	};

	const handleSubmit = () => {
		if (!validateForm()) {
			setShowErrors(true);
			return;
		}
		setLoading(true);
		const boxToCreate = {
			name: name.trim(),
			columns: cols,
			rows: rows,
			axis_direction: 1,
			item_type: itemType,
		} as Partial<Box>;
		if (rack || category) {
			boxToCreate.location = {} as any;
		}
		if (rack && boxToCreate.location) {
			boxToCreate.location.rack_location = {
				rack: rack.id,
			} as any;
		}
		if (category) {
			boxToCreate.location = {
				rack_location: null,
				category: category.id,
			} as ItemContainerLocation;
		}

		if (!isRepository) {
			boxToCreate.location = { rack_location: {}, category: null } as any;
		}

		onConfirm(boxToCreate);
		setRack(undefined);
		setCategory(undefined);
	};

	const { Option } = Select;

	const boxDimensionOptions: Dimension[] = [
		{
			row: 9,
			col: 9,
		},
		{
			row: 10,
			col: 10,
		},
	];

	const hasRecentDimension = () => {
		if (!recentDimension) return false;

		const recentExist = boxDimensionOptions.find(
			(d) =>
				d.row === recentDimension.row && d.col === recentDimension.col
		);
		if (recentExist) return false;

		return true;
	};

	return (
		<Modal
			title="New box"
			visible={visible}
			width={400}
			okText="Create box"
			hideCancelButton
			onOk={handleSubmit}
			onCancel={onCancel}
			okButtonProps={{
				disabled: !validateForm(),
				loading: isLoading,
				style: {
					pointerEvents: "visible",
				},
			}}
			dataCy="add-box-modal"
		>
			<div className={styles.newBoxModal}>
				<InputV3
					autoFocus
					value={name}
					label="Box name*"
					labelColor="text-primary-v2"
					placeholder="e.g. DNA protein"
					onChange={(e) => setName(e.target.value)}
					onPressEnter={handleSubmit}
					errorMessage={!name && showErrors ? "Required" : undefined}
					dataCy="add-box-modal-name"
					className={styles.inputName}
				/>
				<Typography
					variant="body"
					medium
					color="text-primary-v2"
					style={{ marginBottom: 4, fontWeight: 500 }}
				>
					Item type
				</Typography>
				<div className={styles.itemTypeContainer}>
					<ItemTypeSelect
						value={itemType}
						defaultActiveFirstOption
						onSelect={(option) => setItemType(option)}
						dataCy="add-box-item-type"
					/>
					<Tooltip
						placement="right"
						title={
							<Typography variant="caption" color="text-inverse">
								This will be the template for creating items.
								<br />
								Change anytime in the settings panel.
							</Typography>
						}
						trigger="click"
					>
						<GenemodIcon
							name="question-mark-o"
							size="large"
							fill="text-secondary-v2"
						/>
					</Tooltip>
				</div>
				<Typography
					color="text-primary-v2"
					variant="body"
					medium
					style={{ marginBottom: "8px", fontWeight: 500 }}
				>
					Dimension (height x width)
				</Typography>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 12,
					}}
				>
					{recentDimension && hasRecentDimension() && (
						<RadioButtonV2
							name="recent"
							checked={isRecent}
							className={commonStyles.flexCenter}
							onChange={handleRadioChg}
							value={"RECENT"}
							dataCy="add-box-dim-recent"
							radioLabel={
								<Typography
									variant="body"
									color="text-secondary-v2"
								>
									{recentDimension.row} x{" "}
									{recentDimension.col} (recently used)
								</Typography>
							}
						/>
					)}
					{boxDimensionOptions.map((d) => (
						<RadioButtonV2
							key={`${d.row}x${d.col}`}
							name={`${d.row}x${d.col}`}
							checked={
								!isCustom && rows === d.row && cols === d.col
							}
							className={commonStyles.flexCenter}
							onChange={handleRadioChg}
							value={`${d.row}x${d.col}`}
							dataCy={`add-box-dim${d.row}x${d.col}`}
							radioLabel={
								<Typography
									variant="body"
									color="text-secondary-v2"
								>
									{`${d.row} x ${d.col}`}
									{d.row === recentDimension?.row &&
										d.col === recentDimension?.col &&
										" (recently used)"}
								</Typography>
							}
						></RadioButtonV2>
					))}
					<RadioButtonV2
						name="custom"
						checked={isCustom}
						onChange={handleRadioChg}
						value={"CUSTOM"}
						className={styles.custom}
						dataCy="add-box-dim-custom"
						radioLabel={
							<Typography
								variant="body"
								color="text-secondary-v2"
								style={{
									marginRight: 16,
									display: "inline-block",
								}}
							>
								Custom
							</Typography>
						}
					/>
					<div>
						{isCustom && (
							<div className={styles.customSize}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Typography
										variant="body"
										color="text-secondary-v2"
										style={{
											marginRight: 4,
										}}
									>
										Height:
									</Typography>
									<SelectV2
										defaultValue={9}
										defaultActiveFirstOption
										value={rows}
										isInput
										onSelect={(option) =>
											setRows(option as number)
										}
										style={{ width: 68 }}
									>
										{Array(MAX_ROWS_BOX - MIN_ROWS + 1)
											.fill(0)
											.map((_, index) => {
												return (
													<Option
														key={MIN_ROWS + index}
													>
														{MIN_ROWS + index}
													</Option>
												);
											})}
									</SelectV2>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Typography
										variant="body"
										color="text-secondary-v2"
										style={{
											marginRight: 4,
										}}
									>
										Width:
									</Typography>
									<SelectV2
										defaultValue={9}
										defaultActiveFirstOption
										value={cols}
										onSelect={(option) =>
											setCols(option as number)
										}
										isInput
										style={{ width: 68 }}
									>
										{Array(MAX_COLS_BOX - MIN_COLS + 1)
											.fill(0)
											.map((_, index) => {
												return (
													<Option
														key={MIN_COLS + index}
													>
														{MIN_COLS + index}
													</Option>
												);
											})}
									</SelectV2>
								</div>
							</div>
						)}
					</div>
				</div>
				{isRepository ? (
					<RackOrCategorySelect
						rack={rack}
						setRack={setRack}
						category={category}
						setCategory={setCategory}
					/>
				) : null}
			</div>
		</Modal>
	);
}

function RackOrCategorySelect({ rack, setRack, category, setCategory }: any) {
	const [containerSearch, setContainerSearch] = useState<string>("");
	const onSetContainerSearch = useDebounceEventHandler((value: string) => {
		setContainerSearch(value);
	}, 350);
	const { data: racksData } = useGetRepositoryRacksQuery({
		page: 1,
		page_size: 5,
		search: containerSearch,
		ordering: "-updated_at",
		status__in: [ITEM_STATUS.ACTIVE],
	});
	const { data: categoriesData } = useGetRepositoryCategoriesQuery({
		page: 1,
		page_size: 5,
		search: containerSearch,
		ordering: "-updated_at",
		status__in: [ITEM_STATUS.ACTIVE],
	});

	return (
		<>
			<Typography
				variant="label"
				bold
				color="text-secondary"
				className="mt-6 mb-2"
			>
				Location
			</Typography>
			<div className="mb-6">
				<Select
					className="locationSelect"
					allowClear
					clearIcon={
						<GenemodIcon
							name="exit"
							color="text-secondary-v2"
							size="small"
							onClick={() => {
								setRack(null);
								setCategory(null);
							}}
						/>
					}
					isInput
					showSearch
					value={
						rack?.name ||
						category?.name ||
						containerSearch ||
						undefined
					}
					onSelect={(n) => {
						const [type, id] = (n as string).split("-");
						if (type === "rack") {
							const selectedId = parseInt(id, 10);
							const rackFinded = racksData?.results.find(
								(rack) => rack.id === selectedId
							);
							setRack(rackFinded as any as Rack);
							setCategory(null);
						} else if (type === "category") {
							const selectedId = parseInt(id, 10);
							const categoryFinded = categoriesData?.results.find(
								(category) => category.id === selectedId
							);
							setCategory(categoryFinded as any as Category);
							setRack(null);
						}
					}}
					notFoundContent={
						<div>
							<span>Rack or Category not found</span>
						</div>
					}
					onSearch={(value) => onSetContainerSearch(value)}
					placeholder="Select a parent rack/category"
					dataCy="create-box-select-container"
				>
					{racksData?.results.map((rack) => (
						<Select.Option
							key={rack.id}
							value={`rack-${rack.id}`}
							style={{
								height: "fit-content",
							}}
							className="py-4"
							disabled={rack.availability === 0}
						>
							<div className="flex justify-between align-middle">
								<div>
									<Typography
										variant="label"
										color="text-secondary"
									>
										{rack.name}
									</Typography>
									<Typography
										variant="caption"
										color="text-tertiary"
									>
										{rack.location_data?.freezer_name
											? rack.location_data?.freezer_name +
											  " > "
											: ""}
										{rack.location_data?.shelf_name
											? rack.location_data?.shelf_name
											: ""}
									</Typography>
								</div>
								<Typography
									variant="label"
									color="text-tertiary"
								>
									{rack.availability}
									{" slots available"}
								</Typography>
							</div>
						</Select.Option>
					))}
					{categoriesData?.results.map((category: any) => (
						<Select.Option
							key={category.id}
							value={`category-${category.id}`}
							className="py-4 rackSelectitem"
							style={{
								height: "fit-content",
							}}
						>
							<div className="">
								<Typography
									variant="label"
									color="text-secondary"
								>
									{category.name}
								</Typography>
								<Typography
									variant="caption"
									color="text-tertiary"
								>
									{category.location_data?.freezer_name
										? category.location_data?.freezer_name +
										  " > "
										: ""}
									{category.location_data?.shelf_name
										? category.location_data?.shelf_name
										: ""}
								</Typography>
							</div>
						</Select.Option>
					))}
				</Select>
			</div>
		</>
	);
}
