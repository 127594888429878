import { Descendant } from "slate";

/**
 * Content saved to the backend and used as data for the slate editor.
 */
export type ContentType = Descendant[];

export const DefaultContent = [
	{ children: [{ text: "" }] },
] as unknown as ContentType;
