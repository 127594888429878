import React, { BlockquoteHTMLAttributes, useEffect, useState } from "react";
import { Tooltip as ToolTip, TooltipProps } from "antdv5";
import styles from "./index.module.scss";
import { Typography } from "../index";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { usePreferences } from "@helpers/Hooks/UsePreferencesHook";
import { UserSettingsKey } from "../../types/UserPreference";
import { Color, ColorHexMap, ColorOptions } from "@common/styles/Colors";
import { getCurrentBodyTheme } from "../Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "../Theme/context/ThemeTypes";

/**
 * Styled tooltip component from AntD.
 * @see https://3x.ant.design/components/tooltip/#components-tooltip-demo-arrow-point-at-center
 * */
type CustomTooltipProps = Omit<TooltipProps, "color"> & {
	/** if ture render only children */
	hideTooltip?: boolean;
	/** add text underline decoration*/
	underlineText?: boolean;
	firstLoadVisibleKey?: UserSettingsKey;
	/** If true the tooltip will not show */
	disabled?: boolean;
	// If true the tooltip will have a color background an white text
	color?: ColorOptions | Color;
};
export default function Tooltip({
	hideTooltip = false,
	underlineText = false,
	firstLoadVisibleKey,
	disabled = false,
	color = "layer-inverse",
	...props
}: CustomTooltipProps): JSX.Element {
	const { children, overlayClassName } = props;
	const theme = getCurrentBodyTheme();
	const blackColors = ["yellow", "gold", "lime"];
	const isLightTheme = theme === THEME_PREFERENCE.light;
	const isDarkText = isLightTheme && color && blackColors.includes(color);

	const showTooltipOnFirstLoad =
		useFirstLoadVisibleTooltip(firstLoadVisibleKey);

	if (hideTooltip) {
		return <>{children}</>;
	}

	const _color = color?.startsWith("layer")
		? `var(--${color}) !important`
		: `var(--${color}-05) !important`;

	return (
		<ToolTip
			{...showTooltipOnFirstLoad}
			{...props}
			title={
				typeof props.title === "string" ? (
					<Typography color="text-inverse">{props.title}</Typography>
				) : (
					props.title
				)
			}
			overlayClassName={classNames(overlayClassName, styles.overlay)}
			className={classNames(props.className, {
				["underlineText"]: underlineText,
			})}
			{...(disabled ? { open: false } : {})}
			mouseEnterDelay={0.5}
			color={_color}
		>
			{children}
		</ToolTip>
	);
}

// Add a small delay to make the feature notification more noticable
const LOAD_DELAY = 750;
// Automatically close after 4 seconds
const CLOSE_DELAY = 4 * 1000;

/**
 * Making this a hook in case we need this elsewhere.
 */
const useFirstLoadVisibleTooltip = (storageKey?: UserSettingsKey) => {
	const [tooltipSeen, setTooltipSeen] =
		usePreferences(storageKey || "DUMMY") || false;
	const [showTooltip, setShowTooltip] = useState<true | false>(false);

	// The tooltip doesn't hide itself after the timeout, we have to change the key
	const [key, setKey] = useState(nanoid());

	useEffect(() => {
		setTimeout(() => {
			if (storageKey && !tooltipSeen) {
				setShowTooltip(true);
				setKey(nanoid());
				setTooltipSeen(true);
				setTimeout(() => {
					setShowTooltip(false);
					setKey(nanoid());
				}, CLOSE_DELAY);
			}
		}, LOAD_DELAY);
	}, []);
	return showTooltip ? { visible: true, key } : { key };
};
