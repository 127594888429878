import { GridCoord } from "@common/components/InteractiveGrid/GridTypes";
import { CellId } from "@common/types";
import { AppState, store } from "@redux/store";
import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import {
	AreaBounds,
	breakExistingBoxIntoNewBoxes,
	doesNewAreaOverlapWithExisting,
	parseCellId,
} from "../../containers/Freezer/data";
import {
	createActionsHook,
	createSelectorHook,
} from "../helpers/ActionHelpers";

type DraggingState = "NOT_DRAGGING" | "NORMAL_DRAG" | "DRAG_HANDLE_DRAG";

const getInitialState = () => ({
	cellId: null as CellId | null,
	isFocused: false,
	hoveredCell: null as GridCoord | null,
	draggingState: "NOT_DRAGGING" as DraggingState,
	selectedAreaBounds: [] as AreaBounds[],
	itemsCut: {} as Record<number, boolean>,
	loadingCells: [] as GridCoord[],
	movingItems: false,
	isImporting: false,
	cutMode: false,
	cellIdWasUnselectedOnAreaBoundsChange: false as false | GridCoord,
	isArchiving: false,
});

export const boxTableSlice = createSlice({
	name: "boxTable",
	initialState: getInitialState(),
	reducers: {
		resetBoxTableState: () => getInitialState(),
		/**
		 * Use the function in useCellAndItem instead.
		 */
		doNotUse_setCellId: (
			state,
			action: { type: string; payload: CellId | null }
		) => {
			const cellId = action.payload;
			if (state.cellId === cellId) return;
			state.cellId = cellId;
		},
		setIsFocused: (state, action: { type: string; payload: boolean }) => {
			state.isFocused = action.payload;
		},
		setHoveredCell: (
			state,
			action: { type: string; payload: GridCoord | null }
		) => {
			state.hoveredCell = action.payload;
		},
		setDraggingState: (
			state,
			action: { type: string; payload: DraggingState }
		) => {
			state.draggingState = action.payload;
		},

		clearSelectedAreaBounds: (state) => {
			state.selectedAreaBounds = [];
		},

		setSelectedAreaBounds: (
			state,
			action: { type: string; payload: AreaBounds[] }
		) => {
			const newBounds = action.payload || [];
			state.selectedAreaBounds = newBounds;
		},

		setItemsCut: (state, action: { type: string; payload: number[] }) => {
			const newItemsCut = action.payload || {};
			for (let i = 0; i < newItemsCut.length; i++) {
				const itemId = newItemsCut[i];
				state.itemsCut[itemId] = true;
			}
		},

		clearItemsCut: (state) => {
			state.itemsCut = {};
		},

		addSelectedAreaBound: (
			state,
			action: {
				type: string;
				payload: { origin: GridCoord; newBox: AreaBounds };
			}
		) => {
			const { origin, newBox } = action.payload;
			const isUnselect = doesNewAreaOverlapWithExisting(
				state.selectedAreaBounds,
				{
					topLeft: origin,
					bottomRight: origin,
				}
			);
			// Using the above function can you complete this redux action? Any existing areaBounds on the state that overlap with the payload AreaBounds if "isUnselect" is true should end up being split into multiple new AreaBounds boxes and set on the the state. If "isUnselect" is false then the payload should just be added as a new areabounds on the state.
			if (!isUnselect) {
				state.selectedAreaBounds.push(newBox);
				return;
			}

			state.selectedAreaBounds = state.selectedAreaBounds.flatMap(
				(existingBound) => {
					if (
						!doesNewAreaOverlapWithExisting([existingBound], newBox)
					) {
						return [existingBound];
					}
					return breakExistingBoxIntoNewBoxes(existingBound, newBox);
				}
			);

			// If the current cellId is not in the selected area bounds, then set the cellId to the corner of the last selected area
			const topLeftOfLastSelectedArea =
				state.selectedAreaBounds[state.selectedAreaBounds.length - 1]
					?.topLeft;

			if (!state.cellId || !topLeftOfLastSelectedArea) return;

			const location = parseCellId(state.cellId);
			const cellIdIsInSelection = doesNewAreaOverlapWithExisting(
				state.selectedAreaBounds,
				{
					topLeft: location,
					bottomRight: location,
				}
			);
			if (!cellIdIsInSelection) {
				state.cellIdWasUnselectedOnAreaBoundsChange =
					topLeftOfLastSelectedArea;
			}
		},
		setMovingItems: (state, action: { type: string; payload: boolean }) => {
			state.movingItems = action.payload;
		},
		setIsImporting: (state, action: { type: string; payload: boolean }) => {
			state.isImporting = action.payload;
		},
		setIsArchiving: (state, action: { type: string; payload: boolean }) => {
			state.isArchiving = action.payload;
		},
		setCutMode: (state, action: { type: string; payload: boolean }) => {
			state.cutMode = action.payload;
		},
		setCellIdWasUnselectedOnAreaBoundsChange: (
			state,
			action: { type: string; payload: GridCoord | false }
		) => {
			state.cellIdWasUnselectedOnAreaBoundsChange = action.payload;
		},
		setLoadingCells: (
			state,
			action: { type: string; payload: GridCoord[] }
		) => {
			state.loadingCells = action.payload;
		},
		clearLoadingCells: (state) => {
			state.loadingCells = [];
		},
	},
});

export const useBoxTableFields = createSelectorHook(
	`freezer.${boxTableSlice.name}`
);
export const useBoxTableActions = createActionsHook(boxTableSlice.actions);

/**
 * Get the boxTable slice state without triggering a re-render
 */
export const getBoxTableState = () => store.getState().freezer.boxTable;

export const getIsSingleSelection = (state: AppState) => {
	const areaBounds = state.freezer.boxTable.selectedAreaBounds;
	if (areaBounds.length !== 1) return false;
	return isEqual(areaBounds[0].topLeft, areaBounds[0].bottomRight);
};
