import React, { useState } from "react";
import { GenemodIcon, DropDown, Typography } from "@components";
import { Menu } from "antd";
import { useItemTypeNameMappingsFn } from "@helpers/Hooks";
import { ClickParam } from "antd/lib/menu";
import styles from "./ItemGroupCard.module.scss";
import { Box, ItemGroup } from "@common/types";
import cn from "classnames";
import { useExportState } from "@containers/Freezer/hooks";
import { GenemodSkeletonWrapper } from "@common/components/GenemodSkeleton";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { DropDownWithItemsV2 } from "@common/components/DropDownV2/dropdownV2";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { Color } from "@common/styles/Colors";
import { useItemTypesQuery } from "@redux/inventory/Item";
import { wasCreatedOnTheLastFiveSeconds } from "@helpers/UXHelpers";
import { useRepository } from "@containers/Freezer/Repository/RepositoryContext";

type ItemGroupCardProps = {
	itemGroup: ItemGroup;
	/** True if parent freezer is archived */
	viewOnly: boolean;
	onClick: () => void;
};

/** Card Component for Item Group list */
export default function ItemGroupCard(props: ItemGroupCardProps): JSX.Element {
	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const itemTypeNameMappingsFn = useItemTypeNameMappingsFn();
	const { handleExport, exportModal } = useExportState();
	const [showMenu, setMenu] = useState(false);
	const { isLoading: itemTypeLoading } = useItemTypesQuery();
	const { id, name, container_volume, item_type, item_count } =
		props.itemGroup;
	const { setSelectedItems, setSelectedLayer, setIsArchiveModalVisible } =
		useRepository();

	const { openDeleteItemGroupModal } = useCommonModalState(
		"deleteItemGroupModal"
	);
	const {
		genericallyNamed: { openPanel },
	} = useCommonPanelState("itemGroupSettings");

	const getdropdownMenuOptions = () => {
		const options = [];

		options.push({
			label: "Settings",
			key: "settings",
			icon: "settings",
			action: () => {
				setMenu(false);
				openPanel({
					id: props.itemGroup.id,
				});
			},
			dataCy: "item-group-menu-item",
		});
		options.push({
			label: "Share",
			key: "share",
			icon: "share",
			action: () => {
				setMenu(false);
				openShareLinkModal({
					additionalPath: `item-groups/${props.itemGroup.id}`,
				});
			},
			dataCy: "item-group-menu-item",
		});
		options.push({
			label: "Export",
			key: "export",
			icon: "export",
			action: () => {
				setMenu(false);
				handleExport(
					"item-groups",
					props.itemGroup.id,
					props.itemGroup.name
				);
			},
			dataCy: "item-group-menu-item",
		});
		options.push({
			label: "divider",
			isBorder: true,
		});

		if (!props.viewOnly) {
			options.push({
				label: "Archive",
				key: "archive",
				icon: "archive",
				action: () => {
					setSelectedLayer("ITEMGROUP");
					setSelectedItems([props.itemGroup]);
					setIsArchiveModalVisible(true);
				},
				dataCy: "item-group-archive",
			});
			// options.push({
			// 	label: "Delete",
			// 	key: "delete",
			// 	icon: "trash",
			// 	color: "red" as Color,
			// 	action: () => {
			// 		setMenu(false);
			// 		openDeleteItemGroupModal({ id });
			// 	},
			// 	dataCy: "item-group-menu-item",
			// });
		}

		return options;
	};

	return (
		<>
			<div
				className={cn(styles.card, {
					[styles.card__focused]: showMenu,
					isNewCardLikeComponent: wasCreatedOnTheLastFiveSeconds(
						props.itemGroup
					),
				})}
				onClick={props.onClick}
				data-cy={`item-group-card-${props.itemGroup.name.replace(
					/\s/g,
					""
				)}`}
				data-box-id={props.itemGroup.id}
			>
				<div className={styles.body}>
					<DropDownWithItemsV2
						type="meatballs"
						className={styles.options}
						hoverEffect
						visible={showMenu}
						onVisibleChange={(visible) => setMenu(visible)}
						menu={getdropdownMenuOptions()}
						dataCy={`item-group-card-${props.itemGroup.name.replace(
							/\s/g,
							""
						)}-menu`}
					/>

					<GenemodSkeletonWrapper
						isLoading={itemTypeLoading}
						shape="round"
						height={16}
						width="100%"
						wrapperProps={{
							className: styles.nameContainer,
						}}
					>
						<Typography
							variant="label"
							ellipsis
							className={styles.name}
						>
							{name}
						</Typography>
					</GenemodSkeletonWrapper>
					<GenemodSkeletonWrapper
						isLoading={itemTypeLoading}
						shape="round"
						height={10}
						width="30%"
						wrapperProps={{
							className: styles.bottom,
						}}
					>
						<Typography
							variant="caption"
							className={styles.bottomText}
						>
							{itemTypeNameMappingsFn(item_type)}{" "}
							{container_volume ? `(${container_volume})` : ""}
						</Typography>
						<Typography
							variant="caption"
							className={styles.bottomText}
						>
							x{item_count}
						</Typography>
					</GenemodSkeletonWrapper>
				</div>
			</div>
			{exportModal}
		</>
	);
}
