import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { Workspace, WorkspaceId } from "@common/types";
import {
	Button,
	Demo,
	DemoSection,
	DemoWrapper,
	TeamAvatar,
	Typography,
} from "@components";
import { useWindowDimensions } from "@helpers/Hooks";
import { useLocalStorage } from "@helpers/Hooks/UseLocalStorageHook";
import { useCurrentPlanNameHook } from "@helpers/Hooks/featureRestrictionHook";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import {
	useGetOrganizationsQuery,
	useGetPersonalWorkspacesQuery,
} from "@redux/team/TeamApi";
import { useOrganizationRouter } from "@root/AppRouter";
import { ACCOUNT_SETTINGS_PATHS } from "@root/routes";
import { Drawer } from "antd";
import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import { useTheme } from "../Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "../Theme/context/ThemeTypes";
import IMG_DARK from "./assets/img-dark.svg";
import IMG_LIGHT from "./assets/img-light.svg";
import IMG_LEFT from "./assets/left-img.svg";
import styles from "./index.module.scss";

type WorkspacePanelProps = {
	visible?: boolean;
	// The list of workspaces that user is part of (exclude the ones where the user is deactivated)
	workspaceList?: Workspace[];
	// Callback that will be called when a user clicks "Switch"
	onSwitch?: (workspaceId: WorkspaceId) => void;
	// Callback that will be called when a user clicks mask
	onClose?: () => void;
};

export default function WorkspacePanel({
	visible,
	workspaceList,
	onSwitch,
	onClose,
}: WorkspacePanelProps): JSX.Element {
	const { isChangeWorkspacePanelVisible, closeChangeWorkspacePanel } =
		useCommonModalState("changeWorkspacePanel");
	const { planName } = useCurrentPlanNameHook();
	const { data: workspacesData } = useGetPersonalWorkspacesQuery();
	const { data: organizationsData } = useGetOrganizationsQuery();
	const currentOrg = organizationsData?.[0];
	const { width } = Object(useWindowDimensions());
	const isLargeScreen = width >= 1920;
	const [lastUsedWorkspaceId, _] = useLocalStorage<WorkspaceId | null>(
		"LAST_USED_WORKSPACE_ID",
		null
	);
	const { appendBaseUrl } = useOrganizationRouter();
	const { push } = useHistory();
	const [theme] = useTheme();

	const workspaces = workspaceList || workspacesData;

	return (
		<Drawer
			visible={visible || isChangeWorkspacePanelVisible}
			placement="left"
			zIndex={1000}
			onClose={() => onClose?.() || closeChangeWorkspacePanel()}
			width={isLargeScreen ? 356 : 324}
			closable={false}
		>
			<div className={styles.teamPanelContainer}>
				<div
					className={classNames(styles.teamList, {
						[styles.fixHeight]:
							planName === "team" || planName === "free",
					})}
				>
					<div className={styles.header}>
						<Typography variant="largeTitle" bold>
							Workspaces
						</Typography>
					</div>
					<CollapsibleOrgRow name={currentOrg?.name} defaultOpen>
						<>
							{workspaces?.map((eachTeam: any, index: number) => {
								return (
									<a
										className={styles.teamRow}
										key={index}
										href={`/app/${eachTeam.id}`}
									>
										<div className={styles.teamInfo}>
											<TeamAvatar
												teamName={eachTeam.name}
											/>
											<Typography
												variant="subheadline"
												className={styles.teamName}
											>
												{eachTeam.name}
											</Typography>
										</div>
										{lastUsedWorkspaceId === eachTeam.id ? (
											<Typography
												variant="caption"
												className={styles.current}
											>
												Current
											</Typography>
										) : (
											<Button
												className={styles.switch}
												type="link"
											>
												Switch
											</Button>
										)}
									</a>
								);
							})}
						</>
					</CollapsibleOrgRow>
				</div>
				{(planName === "free" || planName === "team") && (
					<div className={styles.newWorkspaceContainer}>
						<div className={styles.newWorkspace}>
							<img
								className={styles.img}
								src={IMG_LEFT}
								alt="Image left"
							/>
							<img
								src={
									theme === THEME_PREFERENCE.light
										? IMG_LIGHT
										: IMG_DARK
								}
								alt="New workspace"
							/>
							<Typography
								bold
								style={{ marginBottom: 24, marginTop: 32 }}
							>
								Create a new workspace?
							</Typography>
							<Typography variant="label">
								Contact us to explore flexible packaging options
								for your team.
							</Typography>
							<Button
								onClick={() => {
									onClose?.() || closeChangeWorkspacePanel();
									push(
										appendBaseUrl(
											ACCOUNT_SETTINGS_PATHS.CHANGE_PLAN
												.route
										)
									);
								}}
								style={{ marginTop: 32 }}
								size="small"
							>
								Explore plans
							</Button>
						</div>
					</div>
				)}
			</div>
		</Drawer>
	);
}

type CollapsibleOrgRowProps = {
	name?: string;
	children: React.ReactNode;
	defaultOpen?: boolean;
};

function CollapsibleOrgRow({
	name,
	children,
	defaultOpen = false,
}: CollapsibleOrgRowProps): JSX.Element {
	const [opened, setOpened] = useState<boolean>(defaultOpen || false);
	return (
		<div className={styles.collapsibleOrgRowContainer}>
			<div className={styles.row} onClick={() => setOpened(!opened)}>
				<Typography
					ellipsis
					className={styles.orgName}
					variant="headline"
					bold
				>
					{name || "N/A"}
				</Typography>
				<GenemodIcon name={opened ? "caret-down" : "caret-up"} />
			</div>
			{opened && <div style={{ width: "100%" }}>{children}</div>}
		</div>
	);
}

export function WORKSPACEPANEL_DEMO(): JSX.Element {
	const workspaceList = [
		{ id: nanoid(), name: "Biochemistry team" },
		{ id: nanoid(), name: "Medicine" },
		{ id: nanoid(), name: "Pathology" },
		{ id: nanoid(), name: "Loooooooooooooooooooong name" },
	] as Workspace[];
	const [visible, setVisible] = useState<boolean>(false);
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo>
					<Button onClick={() => setVisible(true)}>
						Open workspace panel
					</Button>
					<WorkspacePanel
						visible={visible}
						onClose={() => setVisible(false)}
						workspaceList={workspaceList}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
