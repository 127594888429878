import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	BreadCrumb,
	DropDown,
	GenemodIcon,
	GenemodLink,
	Typography,
	Navbar,
} from "@components";
import styles from "./index.module.scss";
import { useOrganizationRouter } from "@root/AppRouter";
import { Link, useRouteMatch } from "react-router-dom";
import cn from "classnames";
import { Menu } from "antd";
import {
	CrumbPath,
	useConsumableCrumbs,
	useFreezerCrumbs,
	useFreezerPages,
	useOrdersCrumbs,
	useStockTrackerCrumbs,
	useRepositoryCrumbs,
} from "@helpers/Hooks/UseFreezerNavbarHooks";
import { ORDER_MODE } from "../components/OrderingWidget";
import { useCommonPanelsOpen } from "@redux/CommonPanels/hooks";

import {
	useBoxViewFields,
	useBoxViewActions,
	setSearchResults,
	searchResultsSelector,
} from "@redux/freezer/BoxViewSlice";
import { appSelector, useAppDispatch } from "@redux/store";
import { useSearchParamAndSet } from "@helpers/URLParams";
import { FREEZER_PAGES, FREEZER_PATHS } from "..";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { INVENTORY_MENU_ITEMS } from "../constants";
import { useFreezerSettingsQuery } from "@redux/freezer/FreezerApiSlice";
import { useBoxQuery } from "@redux/inventory/Box";
import { useBoxItemsQuery } from "@redux/inventory/Item";
import { useDebouncedCallback } from "@helpers/Hooks";

export default function FreezerNavbar() {
	const freezerCrumbs = useFreezerCrumbs();
	const consumableCrumbs = useConsumableCrumbs();
	const stockTrackerCrumbs = useStockTrackerCrumbs();
	const ordersCrumbs = useOrdersCrumbs();
	const repositoryCrumbs = useRepositoryCrumbs();

	if (freezerCrumbs.length) {
		return <DefaultNavbar crumbs={freezerCrumbs} />;
	}
	if (consumableCrumbs.length) {
		return <DefaultNavbar crumbs={consumableCrumbs} />;
	}
	if (stockTrackerCrumbs.length) {
		return <DefaultNavbar crumbs={stockTrackerCrumbs} />;
	}
	if (ordersCrumbs.length) {
		return <DefaultNavbar crumbs={ordersCrumbs} />;
	}
	if (repositoryCrumbs.length) {
		return <DefaultNavbar crumbs={repositoryCrumbs} />;
	}
	return <></>;
}

type DefaultNavbarProps = {
	crumbs: CrumbPath[];
};
function DefaultNavbar({ crumbs }: DefaultNavbarProps) {
	const { appendBaseUrl } = useOrganizationRouter();
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const { openedPanel, togglePanel, closePanel } = useCommonPanelsOpen();
	const { data: freezerSettings } = useFreezerSettingsQuery();

	const currentPage = useFreezerPages();

	useEffect(() => {
		/** when page is chnaged, close panel */
		closePanel();
	}, [currentPage]);

	const waffleMenu = (
		<Menu className={styles.waffleMenu}>
			{INVENTORY_MENU_ITEMS.map((item) => {
				return (
					<Menu.Item
						data-cy={item.dataCy}
						key={item.title}
						onClick={() => setDropdownVisible(false)}
					>
						<Link
							to={appendBaseUrl(item.route)}
							className={styles.link}
						>
							<GenemodIcon
								name={item.icon}
								size="large"
								fill="text-secondary-v2"
								color="text-secondary-v2"
							/>
							<Typography
								variant="subheadline"
								color="text-secondary-v2"
							>
								{item.title}
							</Typography>
						</Link>
					</Menu.Item>
				);
			})}
		</Menu>
	);
	const waffleButton = (
		<DropDown
			key="waffleMenu"
			overlay={waffleMenu}
			overlayStyle={{ width: 190 }}
			visible={dropdownVisible}
			onVisibleChange={setDropdownVisible}
			getPopupContainer={() => document.body}
		>
			<GenemodIcon
				dataCy="inventory-waffle-menu"
				name="waffle"
				size="large"
				hoverEffect
				fill="text-primary"
				className={cn({
					[styles.buttonFocused]: dropdownVisible,
				})}
			/>
		</DropDown>
	);

	function handleSettingsIconClicked() {
		if (!crumbs.length) return;
		const lastCrumb = crumbs[crumbs.length - 1];

		const lastPathData = { id: lastCrumb.objectId || 0 };

		switch (currentPage) {
			case FREEZER_PAGES.HOME:
				togglePanel("inventorySettings")({});
				break;
			case FREEZER_PAGES.CONTENTS:
				if (lastCrumb.search) {
					togglePanel("shelfRackSettings")({
						rackId: Number(lastCrumb.search.get("selected_rack")),
						categoryId: Number(
							lastCrumb.search.get("selected_category")
						),
					});
					break;
				}
				togglePanel("fridgeSettings")(lastPathData);
				break;
			case FREEZER_PAGES.BOXES:
				togglePanel("boxSettings")(lastPathData);
				break;
			case FREEZER_PAGES.CONSUMABLE:
				togglePanel("consumablesHomePageSettings")();
				break;
			case FREEZER_PAGES.ITEMGROUPS:
				togglePanel("itemGroupSettings")(lastPathData);
				break;
			case FREEZER_PAGES.SPACE:
				if (lastCrumb.search) {
					togglePanel("furnitureSettings")({
						id: Number(lastCrumb.search.get("selected_furniture")),
					});
					break;
				}
				togglePanel("spaceSettings")(lastPathData);
				break;
			case FREEZER_PAGES.FURNITURE_CATEGORIES:
				togglePanel("furnitureCategorySettings")(lastPathData);
				break;
			case FREEZER_PAGES.SEARCH:
				togglePanel("freezerSearchSettings")();
				break;
			case FREEZER_PAGES.CONSUMABLE_SEARCH:
				togglePanel("consumableSearchSettings")();
				break;
			case FREEZER_PAGES.ORDERS:
				togglePanel("orderSettings")();
				break;
			case FREEZER_PAGES.REPOSITORY:
				togglePanel("repositorySearchSettings")();
		}
	}

	const extraButtons: JSX.Element[] = useMemo(() => {
		if (!crumbs.length) return [];

		const buttons: JSX.Element[] = [];
		// only Table insert Search
		if (crumbs[crumbs.length - 1].path === "FREEZER_BOX") {
			buttons.push(<BoxItemSearch />);
		}

		if (crumbs[0].path === "FREEZER_ITEMTYPE") return [];

		buttons.push(
			...[
				<GenemodIcon
					key="box"
					name="box"
					size="large"
					hoverEffect
					fill="text-primary"
					className={cn({
						[styles.panelOpen]: openedPanel === "orderingWidget",
					})}
					onClick={() =>
						togglePanel("orderingWidget")({
							mode: ORDER_MODE.VIEW,
						})
					}
					showNotification={freezerSettings?.has_new_order}
				/>,
				<GenemodIcon
					key="bookmark"
					name="bookmark"
					size="large"
					hoverEffect
					fill="text-primary"
					className={cn({
						[styles.panelOpen]: openedPanel === "bookmark",
					})}
					onClick={() => {
						togglePanel("bookmark")();
					}}
					dataCy="navbar-bookmark"
				/>,
			]
		);

		const hideSettingsPages = [
			FREEZER_PAGES.STOCK_TRACKER,
			FREEZER_PAGES.ARCHIVE,
			FREEZER_PAGES.ARCHIVED_ORDERS,
			FREEZER_PAGES.CREATE_ORDER,
			FREEZER_PAGES.ITEMTYPE,
		];
		if (hideSettingsPages.find((page) => page === currentPage)) {
			// If path in hideSettingsPages, do not render settings icon.
			return buttons;
		}

		buttons.push(
			<GenemodIcon
				key="settings"
				name="settings"
				size="large"
				hoverEffect
				fill="text-primary"
				className={cn({
					[styles.panelOpen]: openedPanel.includes("Settings"),
				})}
				onClick={handleSettingsIconClicked}
				dataCy="freezer-navbar-settings"
			/>
		);
		return buttons;
	}, [crumbs, openedPanel]);
	return (
		<Navbar buttons={[...extraButtons, waffleButton]}>
			<div className={styles.crumbs}>
				{crumbs.map((crumb, index) => (
					<GenemodLink
						key={index + crumb.label}
						to={crumb.path}
						toProps={{ search: crumb.search?.toString() }}
						objectId={crumb.objectId}
						hoverUnderline={false}
						className={cn(styles.crumbs, {
							[styles.multipleCrumbs]: crumb.secondLabel,
							[styles.lastCrumb]: index === crumbs.length - 1,
						})}
					>
						{crumb.secondLabel && (
							<BreadCrumb
								key="secondLabel"
								label={crumb.secondLabel}
								isLastCrumb={false}
								state={
									index === crumbs.length - 1
										? "active"
										: "idle"
								}
							/>
						)}
						<BreadCrumb
							label={crumb.label}
							isLastCrumb={index === crumbs.length - 1}
							state={
								index === crumbs.length - 1 ? "active" : "idle"
							}
						/>
					</GenemodLink>
				))}
			</div>
		</Navbar>
	);
}

function BoxItemSearch() {
	const { appendBaseUrl } = useOrganizationRouter();
	const [searchIds] = useSearchParamAndSet("search_ids");
	const [search, setSearch] = useState("");
	useDebouncedCallback(() => setSearchText(search), 300, [search]);

	const boxRoute = useRouteMatch<{ freezer_id: string; box_id: string }>({
		path: appendBaseUrl(FREEZER_PATHS.BOXES),
		exact: true,
	});
	const { data: box } = useBoxQuery(
		Number(boxRoute?.params.box_id) || skipToken
	);
	const { data: items } = useBoxItemsQuery(
		boxRoute
			? {
					id: Number(boxRoute?.params.box_id),
			  }
			: skipToken
	);

	const [isExpanded, setIsExpanded] = useState(true);
	const boxSearchRef = useRef<HTMLInputElement | null>(null);

	const dispatch = useAppDispatch();
	const { selectedItemIndex, searchText } = useBoxViewFields(
		"selectedItemIndex",
		"searchText"
	);
	const { setSelectedItemIndex, setSearchText: _setSearchText } =
		useBoxViewActions();
	const searchResults = appSelector((state) =>
		searchResultsSelector.selectAll(state)
	);

	// On initial load, set the search results to the search_ids in the url.
	useEffect(() => {
		if (items !== undefined) {
			const urlSearchIds =
				searchIds?.split(",").map((id) => parseInt(id)) || [];
			dispatch(
				setSearchResults(
					items.filter((item) => urlSearchIds.includes(item.id))
				)
			);
		}
	}, [items]);

	const setSearchText = (text: string) => {
		if (items === undefined) return;
		let results;
		if (!text) {
			const urlSearchIds =
				searchIds?.split(",").map((id) => parseInt(id)) || [];
			results = items.filter((item) => urlSearchIds.includes(item.id));
		} else {
			results = items
				.filter((item) => !!item.name)
				.filter((item) => item.name.toLowerCase().includes(text))
				.sort(
					(a, b) =>
						(a.location?.box_location?.row || 0) -
							(b.location?.box_location?.row || 0) ||
						(a.location?.box_location?.column || 0) -
							(b.location?.box_location?.column || 0)
				);
		}
		dispatch(setSearchResults(results));
		_setSearchText(text);
	};

	const changeSelectedIndex = (change: number) => {
		let next = 0;
		if (selectedItemIndex === "UNSELECTED") {
			next = change === 1 ? 0 : -1;
		} else {
			next = selectedItemIndex + change;
		}
		if (!searchResults.length) return;
		setSelectedItemIndex(next);
	};

	const getSelectedIndexDisplay = () => {
		if (selectedItemIndex === "UNSELECTED") return 0;

		return selectedItemIndex + 1;
	};

	const handleResultsTab = (e: {
		key: string;
		preventDefault: () => void;
		shiftKey: any;
	}) => {
		if (
			document.activeElement === boxSearchRef.current &&
			isExpanded &&
			e.key === "Tab"
		) {
			/**
			 * if boxSearch input is focused,
			 * move to next searched item with Tab key
			 */
			e.preventDefault();
			changeSelectedIndex(e.shiftKey ? -1 : 1);
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleResultsTab);
		return () => {
			document.removeEventListener("keydown", handleResultsTab);
		};
	});

	if (!boxRoute) return <></>;

	return (
		<div
			className={cn(styles.navbarSearch, {
				[styles.navbarSearch__expanded]: isExpanded,
			})}
		>
			<div
				className={styles.searchBtn}
				onClick={() => setIsExpanded((prev) => !prev)}
			>
				<GenemodIcon
					name="search"
					size="large"
					hoverEffect
					fill={isExpanded ? "button-primary" : "text-primary"}
					stroke={isExpanded ? "button-primary" : "text-primary"}
				/>
			</div>
			<div className={styles.searchbarContents}>
				<input
					ref={boxSearchRef}
					autoFocus
					onChange={(e) =>
						setSearch((e.target.value || "").toLowerCase())
					}
					value={search}
					placeholder="Find in box"
				/>
				{searchText && (
					<>
						<div className={styles.numResults}>
							{getSelectedIndexDisplay()} of{" "}
							{searchResults.length}
						</div>
						<div className={styles.resultsNavigation}>
							<GenemodIcon
								name="chevron-left"
								fill="text-primary"
								onClick={() => changeSelectedIndex(-1)}
							/>
							<GenemodIcon
								name="chevron-right"
								fill="text-primary"
								onClick={() => changeSelectedIndex(1)}
							/>
						</div>
						<GenemodIcon
							onClick={() => setSearch("")}
							name="cancel"
							fill="text-primary"
						/>
					</>
				)}
			</div>
		</div>
	);
}
