import { GoogleDrivePreviewWithHeader } from "@common/components/GoogleDocsIntegration/GoogleDrivePreview";
import {
	ExperimentAttachment,
	ExperimentDocumentAttachment,
	ExperimentReportAttachment,
	FreezerOrderAttachment,
	ItemAttachment,
	ProjectAttachment,
	SubprojectAttachment,
} from "@common/types";
import { Merge } from "@helpers/TypeHelpers";
import React from "react";
import {
	GoogleDocCreateDto,
	GoogleDocDto,
} from "../GoogleDocsIntegration/GoogleDrivePickerData";
import AttachmentPreviewContainer, {
	AttachmentInfo,
	AttachmentPreviewContainerProps,
} from "./AttachmentPreviewContainer";
import { UploadPreview } from "./UploadPreview";

type AttachmentProps = {
	attachment:
		| ProjectAttachment
		| SubprojectAttachment
		| ExperimentAttachment
		| FreezerOrderAttachment
		| ItemAttachment
		| GoogleDocDto
		| GoogleDocCreateDto
		| ExperimentDocumentAttachment
		| ExperimentReportAttachment
		| null
		| undefined;
	previewWrapperStyles?: React.CSSProperties;
};

type AttachmentPreviewProps<T extends AttachmentInfo> = Merge<
	Omit<AttachmentPreviewContainerProps<T>, "children">,
	AttachmentProps
>;

/**
 * Bundles both the preview attachment header and the standard upload preview container
 */
export default function AttachmentPreview<T extends AttachmentInfo>(
	props: AttachmentPreviewProps<T>
): JSX.Element {
	const { attachment, onClose, onDelete } = props;

	if (
		attachment?.document_type === "GoogleDriveDocument" ||
		attachment?.document_type === "GoogleDocCreateDto"
	) {
		return (
			<GoogleDrivePreviewWithHeader
				visible={!!attachment}
				attachment={attachment}
				onClose={onClose}
				onDelete={onDelete as any}
			/>
		);
	}

	return (
		<AttachmentPreviewContainer<T>
			{...props}
			attachment={props.attachment as unknown as T}
		>
			<UploadPreview
				previewWrapperStyles={{ height: "calc(100% - 56px)" }}
				attachment={attachment}
			/>
		</AttachmentPreviewContainer>
	);
}
