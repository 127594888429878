import React, { useEffect, useState } from "react";
import { Modal, Notification } from "@components";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { useOrganizationRouter } from "@root/AppRouter";

import { useCommonModalState } from "@redux/CommonModals/hooks";
import {
	useItemTypeQuery,
	usePatchItemTypeMutation,
	useSaveItemTypeMutation,
} from "@redux/inventory/Item";
import { itemTypeSlice } from "@redux/CommonModals/CommonModals";
import { useAppDispatch } from "@redux/store";
import InputV3 from "@common/components/InputV3/InputV3";

export default function CreateOrEditItemTypeModal(): JSX.Element {
	const {
		isCreateItemTypeModalVisible,
		closeCreateItemTypeModal,
		createItemTypeModalData,
	} = useCommonModalState("createItemTypeModal");
	const {
		isRenameItemTypeModalVisible,
		closeRenameItemTypeModal,
		renameItemTypeModalData,
	} = useCommonModalState("renameItemTypeModal");
	const { data: currentItemType } = useItemTypeQuery(
		renameItemTypeModalData.itemTypeId,
		{
			skip: !renameItemTypeModalData.itemTypeId,
		}
	);

	const [title, setTitle] = useState("");
	const [duplicatedTitle, setDuplicatedTitle] = useState("");
	const [updateItemType] = usePatchItemTypeMutation();
	const [saveItemType] = useSaveItemTypeMutation();
	const history = useHistory();
	const { appendBaseUrl } = useOrganizationRouter();
	const dispatch = useAppDispatch();

	const visible =
		isCreateItemTypeModalVisible || isRenameItemTypeModalVisible;

	useEffect(() => {
		if (isRenameItemTypeModalVisible && currentItemType) {
			setTitle(currentItemType.name);
		}
	}, [isRenameItemTypeModalVisible, currentItemType]);

	const handleErrorCases = (error: any) => {
		if (error && error.status === 400 && "name" in error.data) {
			//check duplicate name
			setDuplicatedTitle(title);
			return;
		}
		Notification.warning({
			message: `Failed to ${
				isRenameItemTypeModalVisible
					? "edit item type name"
					: "create new item type"
			}. Try again or contact us if it continues.`,
		});
	};

	const editItemTypeName = () => {
		const data = {
			itemTypeId: renameItemTypeModalData.itemTypeId,
			data: {
				name: title,
			},
		};
		updateItemType(data)
			.unwrap()
			.then(() => {
				handleClose();
			})
			.catch((err) => {
				handleErrorCases(err);
			});
	};

	const createNewItem = () => {
		saveItemType({
			name: title,
			schema: "[]",
		})
			.unwrap()
			.then((res) => {
				const typeId = res.id;
				dispatch(itemTypeSlice.actions.setTypeId({ typeId: typeId }));
				handleClose();
				// Added timeout to give time to rtk query to get the item types with the newly created one
			})
			.catch((err) => {
				handleErrorCases(err);
			});
	};

	const handleClose = () => {
		if (isCreateItemTypeModalVisible) {
			closeCreateItemTypeModal();
			if (createItemTypeModalData.onClose) {
				createItemTypeModalData?.onClose();
			}
		} else {
			closeRenameItemTypeModal();
		}
		setTitle("");
		setDuplicatedTitle("");
	};

	const handleSubmit = () => {
		if (!title.length || isDuplicated) {
			return;
		}
		if (isRenameItemTypeModalVisible) {
			editItemTypeName();
		} else {
			createNewItem();
		}
	};
	const isDuplicated = !!title.length && title === duplicatedTitle;
	return (
		<Modal
			visible={visible}
			okText={isCreateItemTypeModalVisible ? "Save" : "Continue"}
			onOk={handleSubmit}
			onCancel={handleClose}
			title={
				isCreateItemTypeModalVisible
					? "Create new item template"
					: "Rename item template"
			}
			hideCancelButton
			okButtonProps={{
				disabled: !title.length || isDuplicated,
			}}
			destroyOnClose
		>
			<InputV3
				label="Template title"
				value={title}
				autoFocus
				onFocus={(e: React.ChangeEvent<HTMLInputElement>) =>
					e.target.select()
				}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setTitle(e.target.value)
				}
				maxLength={15}
				errorMessage={
					isDuplicated ? "The title is already in use." : undefined
				}
				onPressEnter={handleSubmit}
			/>
		</Modal>
	);
}
