import {
	Box,
	BoxDataForBackend,
	PaginatedSearchQuery,
	PaginatedSearchResults,
	UpdateBoxDataForBackend,
} from "@common/types";
import { BoxStorageProps } from "@containers/Freezer/components/BoxReagentSetting/BoxReagentSetting";
import { AtLeastId } from "@helpers/TypeHelpers";

import { inventoryApi, withFreezerBase } from ".";

export type RepositoryBoxFilters = Omit<
	PaginatedSearchQuery &
		Partial<{
			id: number | null;
			ordering: string | null;
			search: string | null;
			status__in: number[] | null;
			created_at__gte: string | null;
			created_at__lte: string | null;
			created_by__in: string[] | null;
			updated_at__gte: string | null;
			updated_at__lte: string | null;
			updated_by__in: string[] | null;
			location__category: number | null;
			location__category__in: number[] | null;
			location__rack_location__rack: number | null;
			location__rack_location__rack__in: number[] | null;
			location__isnull: number;
			include_filter_options: boolean | null;
			item_type__in: number[] | null;
		}>,
	"sort_by"
>;
export type RepositoryBoxResult = PaginatedSearchResults<Box>;

const boxApi = inventoryApi.injectEndpoints({
	endpoints: (builder) => ({
		boxes: builder.query<Box[], { id: number; is_archived?: boolean }>({
			query: ({ id, is_archived = false }) => ({
				url: withFreezerBase(`v2/racks/${id}/boxes/`),
				params: { is_archived },
			}),
			providesTags: ["Box"],
		}),
		box: builder.query<Box, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/boxes/${id}/`),
			}),
			providesTags: ["Box"],
		}),
		boxCreate: builder.mutation<
			Box,
			{ rackId: number; boxData: BoxDataForBackend }
		>({
			query: ({ rackId, boxData }) => ({
				url: withFreezerBase(`v2/boxes/`),
				method: "POST",
				body: boxData,
			}),
			invalidatesTags: ["Box", "Rack"],
		}),
		boxPatch: builder.mutation<Box, AtLeastId<UpdateBoxDataForBackend>>({
			query: (body) => ({
				url: withFreezerBase(`v2/boxes/${body.id}/`),
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["Box", "Rack"],
		}),
		boxDelete: builder.mutation<void, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/boxes/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["Freezer", "Rack", "Box"],
		}),
		boxRowInsert: builder.mutation<Box, { boxId: number; row: number }>({
			query: ({ boxId, row }) => ({
				url: withFreezerBase(`v2/boxes/${boxId}/insert-row/`),
				body: {
					row,
				},
				method: "POST",
			}),
			invalidatesTags: ["Box", "Item"],
		}),
		boxRowDelete: builder.mutation<void, { boxId: number; row: number }>({
			query: ({ boxId, row }) => ({
				url: withFreezerBase(`v2/boxes/${boxId}/delete-row/`),
				body: {
					row,
				},
				method: "POST",
			}),
			invalidatesTags: ["Box", "Item"],
		}),
		boxColInsert: builder.mutation<Box, { boxId: number; column: number }>({
			query: ({ boxId, column }) => ({
				url: withFreezerBase(`v2/boxes/${boxId}/insert-column/`),
				body: {
					column,
				},
				method: "POST",
			}),
			invalidatesTags: ["Box", "Item"],
		}),
		boxColDelete: builder.mutation<void, { boxId: number; column: number }>(
			{
				query: ({ boxId, column }) => ({
					url: withFreezerBase(`v2/boxes/${boxId}/delete-column/`),
					body: {
						column,
					},
					method: "POST",
				}),
				invalidatesTags: ["Box", "Item"],
			}
		),
		boxRelocate: builder.mutation<
			Box,
			{
				boxId: number;
				rackId: number;
				first_location: {
					row: number;
					column: number;
				};
				second_location: {
					row: number;
					column: number;
				};
			}
		>({
			query: ({ rackId, boxId, ...body }) => ({
				url: withFreezerBase(`v2/racks/${rackId}/rearrange-boxes/`),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Box", "Rack"],
			async onQueryStarted(
				{ rackId, second_location, boxId },
				{ dispatch, queryFulfilled }
			) {
				const patchResult = dispatch(
					boxApi.util.updateQueryData(
						"boxes",
						{
							id: rackId,
							is_archived: false,
						},
						(draft) => {
							const boxToUpdate = draft.find(
								(box) => box.id === boxId
							);
							if (
								boxToUpdate &&
								boxToUpdate.location.rack_location
							) {
								boxToUpdate.location.rack_location.row =
									second_location.row;
								boxToUpdate.location.rack_location.column =
									second_location.column;
							}
						}
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
		getBoxStorageSummary: builder.query<
			BoxStorageProps[],
			{ boxId: number }
		>({
			query: ({ boxId }) => ({
				url: withFreezerBase(`v2/boxes/${boxId}/storage-summary/`),
			}),
		}),
		getRepositoryBoxes: builder.query<
			RepositoryBoxResult,
			RepositoryBoxFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/boxes-repository/"),
				method: "GET",
				params,
			}),
			providesTags: ["Box"],
		}),
		restorableBoxes: builder.query<
			RepositoryBoxResult,
			RepositoryBoxFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/restorable-boxes/"),
				method: "GET",
				params,
			}),
			providesTags: ["Box"],
		}),
		getRepositoryBox: builder.query<Box, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/boxes-repository/${id}/`),
				method: "GET",
			}),
			providesTags: ["Box"],
		}),
		boxBulkCreate: builder.mutation<Box[], Partial<Box>[]>({
			query: (body) => ({
				url: withFreezerBase("v2/boxes-repository/bulk-create/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Box"],
		}),
		boxBulkUpdate: builder.mutation<Box[], Partial<Box>[]>({
			query: (body) => ({
				url: withFreezerBase("v2/boxes-repository/bulk-update/"),
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["Item", "Box"],
		}),
		boxBulkDelete: builder.mutation<void, { box_ids: number[] }>({
			query: (body) => ({
				url: withFreezerBase("v2/boxes-repository/bulk-delete/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Box"],
		}),
	}),
});

export const {
	// Box crud
	useBoxesQuery,
	useLazyBoxesQuery,
	useBoxQuery,
	useBoxCreateMutation,
	useBoxPatchMutation,
	useBoxDeleteMutation,
	useLazyBoxQuery,
	useBoxRowInsertMutation,
	useBoxRowDeleteMutation,
	useBoxColInsertMutation,
	useBoxColDeleteMutation,
	useBoxRelocateMutation,
	useGetBoxStorageSummaryQuery,
	useLazyGetBoxStorageSummaryQuery,
	useGetRepositoryBoxesQuery,
	useRestorableBoxesQuery,
	useGetRepositoryBoxQuery,
	useBoxBulkCreateMutation,
	useBoxBulkUpdateMutation,
	useBoxBulkDeleteMutation,
} = boxApi;
