import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodIcon,
	Typography,
	UpgradeButton,
} from "@components";
import { Semaphore } from "@helpers/Hooks";
import styles from "./RestrictionWarning.module.scss";
import ExploreButton from "../ExploreButton/ExploreButton";

export function RESTRICTIONWARNING_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Restriction warning section/banner"
					description="Restriction warning section/banner"
				>
					<div style={{ width: 504 }}>
						<RestrictionWarning>
							<Typography>
								To create a shared freezer, you must invite a
								colleague first.
							</Typography>
						</RestrictionWarning>
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}

type RestrictionWarningProps = {
	/** trigger to animate */
	animationSemaphore?: Semaphore;
	showUpgradeButton?: boolean;
	showExplorePlansButton?: boolean;
	children: React.ReactNode;
};

const ANIMATION_DURATION = 500;

/**
 * Banner displayed to warn about restrictions
 */
export default function RestrictionWarning({
	animationSemaphore,
	showUpgradeButton = false,
	showExplorePlansButton = false,
	children,
}: RestrictionWarningProps): JSX.Element {
	const [firstAnimationSkipped, setFirstAnimationSkipped] = useState(false);
	const [animateActive, setAnimateActive] = useState(false);

	useEffect(() => {
		if (!firstAnimationSkipped) {
			setFirstAnimationSkipped(true);
			return;
		}

		setAnimateActive(true);
		setTimeout(() => setAnimateActive(false), ANIMATION_DURATION);
	}, [animationSemaphore]);

	return (
		<div
			className={cn(styles.restrictionWarning, {
				[styles.restrictionWarning__active]: animateActive,
			})}
		>
			<div className={styles.flex}>
				<div className={styles.iconContainer}>
					<GenemodIcon
						name="error"
						size="large"
						fill="yellow"
						stroke="yellow"
					/>
				</div>
				<div>
					{children}
					{showUpgradeButton && (
						<div style={{ marginTop: 16 }}>
							<UpgradeButton
								style={{
									width: 180,
									justifyContent: "center",
								}}
							/>
						</div>
					)}
					{showExplorePlansButton && (
						<ExploreButton style={{ marginTop: 16, width: 180 }} />
					)}
				</div>
			</div>
		</div>
	);
}
