import {
	GoogleDocCreateDto,
	GoogleDocDto,
} from "@common/components/GoogleDocsIntegration/GoogleDrivePickerData";
import { Column, Row } from "@common/components/InteractiveGrid/GridTypes";
import { IdsInConstType } from "@helpers/TypeHelpers";
import {
	AttachmentAcceptedFile,
	BaseDocument,
	CURRENCY_TYPES,
	ItemLocationMeta,
	TableItem,
	UUID,
} from ".";
import { ISOString } from "./Date";
import { Avatar } from "./User";

export const ITEM_TYPES = Object.freeze({
	DEFAULT: 1,
	PRIMER: 2,
	STRAIN: 3,
	ANTIBODY: 4,
	CHEMICAL: 5,
	CHEMICAL_PROBE: 6,
	PLASMID: 7,
	ENZYME: 8,
	CELL_LINE: 9,
	1: "Default",
	2: "Primer",
	3: "Strain",
	4: "Antibody",
	5: "Chemical",
	6: "Chemical probe",
	7: "Plasmid",
	8: "Enzyme",
	9: "Cell Line",
} as const);

export type TypeData = Record<UUID, string | string[]>;

export type BoxItemLocation = {
	id: number;
	box: number;
	row: Row;
	column: Column;
};

export type NewBoxItemLocation = {
	id?: number;
	box: number;
	row: Row;
	column: Column;
};

export type ItemLocation = {
	id: number;
	freezer: number;
	item_group: number | null;
	box_location: BoxItemLocation | null;
};

export enum ITEM_STATUS {
	ACTIVE = 1,
	ARCHIVED = 2,
}

export type SimpleItem = {
	id: number;
	name: string;
	location: ItemLocation | null;
	created_by: {
		id: number;
	};
	/** If true, this item is bookmarked */
	is_bookmarked: boolean;
	/**
	 * Combine this with the custom_id to get the new user-facing item id.
	 */
	organization_prefix: string;
	/** Custom ID. For internal use within each team */
	custom_id: number;
};

export type Item = {
	id: number;
	name: string;
	concentration: string;
	volume: string;
	/** Date that the Item was created */
	added_at: ISOString;
	/** Date that the Item was created */
	created_at: ISOString;
	/** The user that created the Item */
	created_by: Avatar | null;
	/** Name of the user that last updated the Item */
	updated_by: Avatar | null;
	/** Date that the Item was last updated */
	updated_at: ISOString;
	/** Expiration date of the item */
	expiration_date: string | null;
	notes: string;
	/**
	 * This is also referred to as "vendor" in the UI.
	 */
	source: string;
	reference: string;
	catalog: string;
	/** Type of item */
	item_type: number;
	/** Custom ID. For internal use within each team */
	custom_id: number;
	packaging: string;
	price: string;
	currency: IdsInConstType<typeof CURRENCY_TYPES>;
	lot: string;
	/** If true, this item is bookmarked */
	is_bookmarked: boolean;
	/** Additional dictionary of data depending on the `Item.item_type` field */
	type_data: TypeData | null;
	/** CUSTOM ITEM TYPE RELATED data */
	org?: number;
	/**
	 * Combine this with the custom_id to get the new user-facing item id.
	 */
	organization_prefix: string;
	location: ItemLocation | null;
	location_data: ItemLocationMeta;
	status: ITEM_STATUS;
	is_archived?: boolean;
	freezer?: number;
};

export type MoveItem = {
	id: number;
	name: string;
	old_location: BoxItemLocation;
	new_location: NewBoxItemLocation;
	location: ItemLocation;
};

export type UpdateItemBackendData = {
	location: Partial<
		{
			box_location: Partial<BoxItemLocation>;
		} & Omit<ItemLocation, "box_location">
	>;
} & Omit<Item, "location">;

export type Bookmark = {
	id: number;
	workspaces: UUID[];
	organization: {
		id: number;
		name: string;
	};
	location: {
		freezer: number;
		shelf: number;
		rack: number | null;
		category: number | null;
		box: number | null;
		item_group: number | null;
		item: number;
		item_name: string;
		freezer_name: string;
		shelf_name: string;
		category_name: string | null;
		item_group_name: string | null;
		box_name: string | null;
		rack_name: string | null;
	};
	created_at: ISOString;
	item: number;
	owner: number;
};

export type BookmarkBulkDeletePayload = {
	bookmark_ids: number[];
};

export type ItemPasteResponse = {
	toDelete: boolean;
	item: Item;
};

/**
 * Returns a user-facing custom id for items.
 */
export function formatCustomId(prefix: string, id: number | string): string {
	return prefix + String(id).padStart(8, "0");
}

export const DEFAULT_ITEM_TYPE = 1;

/**
 * Enum for custom type field
 */
export enum FieldType {
	SINGLE_LINE = 1,
	MULTIPLE_LINE,
	NUMBER,
	GENUS,
	SPECIES,
	SELECTION_MARKER,
	DATE_PICKER,
}

// Used to render input field in Cell creation view
export const FIELD_TYPES = Object.freeze({
	TEXTAREA: "TEXTAREA",
	GENUS: "GENUS",
	SPECIES: "SPECIES",
	SELECTION_MARKER: "SELECTION_MARKER",
} as const);

export const FIELD_WIDTHS = Object.freeze({
	FULL: "FULL",
	FIRST_TWO_THIRDS: "FIRST_TWO_THIRDS",
	SECOND_TWO_THIRDS: "SECOND_TWO_THIRDS",
	FIRST_HALF: "FIRST_HALF",
	SECOND_HALF: "SECOND_HALF",
	FIRST_THIRD: "FIRST_THIRD",
	SECOND_THIRD: "SECOND_THIRD",
	THIRD_THIRD: "THIRD_THIRD",
} as const);

// For use when styling the css grid for new item fields
export const FIELD_WIDTH_STYLES = Object.freeze({
	FULL: {
		gridColumnStart: "first-half-start",
		gridColumnEnd: "second-half-end",
	},
	FIRST_TWO_THIRDS: {
		gridColumnStart: "first-third-start",
		gridColumnEnd: "second-third-end",
	},
	SECOND_TWO_THIRDS: {
		gridColumnStart: "second-third-start",
		gridColumnEnd: "third-third-end",
	},
	FIRST_HALF: {
		gridColumnStart: "first-half-start",
		gridColumnEnd: "first-half-end",
	},
	SECOND_HALF: {
		gridColumnStart: "second-half-start",
		gridColumnEnd: "second-half-end",
	},
	FIRST_THIRD: {
		gridColumnStart: "first-third-start",
		gridColumnEnd: "first-third-end",
	},
	SECOND_THIRD: {
		gridColumnStart: "second-third-start",
		gridColumnEnd: "second-third-end",
	},
	THIRD_THIRD: {
		gridColumnStart: "third-third-start",
		gridColumnEnd: "third-third-end",
	},
} as const);

export type Layout = keyof typeof FIELD_WIDTH_STYLES;

export const REAGENT_FIELDS = Object.freeze({
	[ITEM_TYPES.DEFAULT]: {},
	[ITEM_TYPES.PRIMER]: {
		sequence: {
			label: `Sequence (5' -> 3')`,
		},
		melting_temperature: {
			label: "Melting temperature",
		},
		restriction_sites: {
			label: "Restriction sites",
		},
		modification: {
			label: "Modification",
		},
		gene: {
			label: "Gene",
			width: FIELD_WIDTHS.FIRST_THIRD,
		},
		size: {
			label: "Size",
			width: FIELD_WIDTHS.SECOND_THIRD,
		},
		strand: {
			label: "Strand",
			width: FIELD_WIDTHS.THIRD_THIRD,
		},
	},
	[ITEM_TYPES.ANTIBODY]: {
		host: {
			label: "Host",
			width: FIELD_WIDTHS.FIRST_HALF,
		},
		target_species: {
			label: "Target species",
			width: FIELD_WIDTHS.SECOND_HALF,
		},
		primary_secondary: {
			label: "Primary / Secondary",
		},
		application_1: {
			label: "Application 1",
			width: FIELD_WIDTHS.FIRST_TWO_THIRDS,
		},
		dilution_1: {
			label: "Dilution 1",
			width: FIELD_WIDTHS.THIRD_THIRD,
		},
		application_2: {
			label: "Application 2",
			width: FIELD_WIDTHS.FIRST_TWO_THIRDS,
		},
		dilution_2: {
			label: "Dilution 2",
			width: FIELD_WIDTHS.THIRD_THIRD,
		},
		clone: {
			label: "Clone",
		},
		conjugate: {
			label: "Conjugate",
		},
	},
	[ITEM_TYPES.PLASMID]: {
		backbone: {
			label: "Backbone",
		},
		prokaryotic_marker: {
			label: "Prokaryotic marker",
			width: FIELD_WIDTHS.FIRST_HALF,
		},
		eukaryotic_marker: {
			label: "Eukaryotic marker",
			width: FIELD_WIDTHS.SECOND_HALF,
		},
		species: {
			label: "Species",
		},
		gene_or_insert: {
			label: "Gene or Insert",
		},
		promoter: {
			label: "Promoter",
		},
		tags: {
			label: "Tags",
		},
		a_260_280: {
			label: "A260/280",
		},
		sequence3_primer: {
			label: `3' Sequence primer`,
		},
		sequence5_primer: {
			label: `5' Sequence primer`,
		},
	},
	[ITEM_TYPES.ENZYME]: {
		recognition_sequence: {
			label: "Recognition sequence",
			width: FIELD_WIDTHS.FIRST_HALF,
		},
		buffer: {
			label: "Buffer",
			width: FIELD_WIDTHS.SECOND_HALF,
		},
		incubation_temperature: {
			label: "Incubation temperature",
			width: FIELD_WIDTHS.FIRST_HALF,
		},
		heat_inactivation: {
			label: "Heat inactivation",
			width: FIELD_WIDTHS.SECOND_HALF,
		},
		activity: {
			label: "Activity",
		},
		methylation_sensitivity: {
			label: "Methylation sensitivity",
			field_type: FieldType.MULTIPLE_LINE,
		},
	},
	[ITEM_TYPES.CELL_LINE]: {
		sample_type: {
			label: "Sample type",
		},
		passage_num: {
			label: "Passage #",
			width: FIELD_WIDTHS.FIRST_THIRD,
		},
		patient_id: {
			label: "Patient ID",
			width: FIELD_WIDTHS.SECOND_TWO_THIRDS,
		},
		study: {
			label: "Study",
		},
		selection_marker: {
			label: "Selection marker",
		},
	},
	[ITEM_TYPES.STRAIN]: {
		genus: {
			label: "Genus",
			field_type: FieldType.GENUS,
			width: FIELD_WIDTHS.FIRST_HALF,
		},
		species: {
			label: "Species",
			field_type: FieldType.SPECIES,
			width: FIELD_WIDTHS.SECOND_HALF,
		},
		genotype_and_strain_background: {
			label: "Genotype / Strain background",
		},
		selection_marker_and_resistance: {
			label: "Selection marker / Resistance",
			field_type: FieldType.SELECTION_MARKER,
		},
		associated_plasmids: {
			label: "Associated plasmids",
		},
		modification: {
			label: "Modification",
		},
		use: {
			label: "Use",
		},
	},
	[ITEM_TYPES.CHEMICAL]: {
		molecular_weight: {
			label: "Molecular weight",
			width: FIELD_WIDTHS.FIRST_HALF,
		},
		melting_point: {
			label: "Melting / Boiling point",
			width: FIELD_WIDTHS.SECOND_HALF,
		},
		application: {
			label: "Application",
		},
		solubility: {
			label: "Solubility",
		},
		synonym: {
			label: "Synonym",
			width: FIELD_WIDTHS.FIRST_HALF,
		},
		formula: {
			label: "Formula",
			width: FIELD_WIDTHS.SECOND_HALF,
		},
		cas: {
			label: "CAS #",
			width: FIELD_WIDTHS.FIRST_HALF,
		},
		mdl: {
			label: "MDL #",
			width: FIELD_WIDTHS.SECOND_HALF,
		},
		form: {
			label: "Form",
			width: FIELD_WIDTHS.FIRST_HALF,
		},
		grade: {
			label: "Grade",
			width: FIELD_WIDTHS.SECOND_HALF,
		},
	},
	[ITEM_TYPES.CHEMICAL_PROBE]: {
		solvent: {
			label: "Solvent",
		},
		aliquot: {
			label: "Aliquot",
		},
		stock_solution: {
			label: "Stock solution",
		},
		modification_label: {
			label: "Modification / Label",
		},
	},
} as const);

/**
 * Attachment type to be used in ItemViewaAndEditPanel
 */
export type AttachmentOrGoogle = GoogleDocDto | ItemAttachment;

/**
 * Attachment type to be using in CreateNewItem
 */
export type NewAttachmentOrGoogle = GoogleDocCreateDto | ItemAttachment;

export type ItemAttachment = BaseDocument & {
	document_type: "ItemAttachment";
	id: number;
	file_type: AttachmentAcceptedFile;
	upload: string;
	file?: File;
	formData?: FormData;
};
export function isItem(tableItem: TableItem): tableItem is Item {
	return "item_type" in tableItem && "currency" in tableItem;
}
