import { IdsInConstType } from "@helpers/TypeHelpers";
import { ISOString } from "./Date";
import { SharedInternalObj } from "./Sharing";
import { ITEM_TYPES } from "./Item";
import { Avatar } from "./User";

export const FREEZER_TYPES = Object.freeze({
	MINUS_EIGHTY: 0,
	MINUS_TWENTY: 1,
	FOUR: 2,
	DELI: 3,
	NITROGEN: 4,
	0: "-80 °C",
	1: "-20 °C",
	2: "4 °C",
	3: "Deli fridge",
	4: "LN2",
} as const);

export const FREEZER_TYPES_QUERY: {
	[key: string]: IdsInConstType<typeof FREEZER_TYPES>;
} = Object.freeze({
	"-80 °C": 0,
	"-20 °C": 1,
	"4 °C": 2,
	"Deli fridge": 3,
	LN2: 4,
} as const);

export const FREEZER_LABEL_TYPE = Object.freeze({
	DEFAULT: 0,
	NAME: 0,
	ID: 1,
	BOTH: 2,
	0: "Name only",
	1: "ID only",
	2: "ID - Name",
} as const);

export type FreezerLabelType = IdsInConstType<typeof FREEZER_LABEL_TYPE>;

/**
 * Fields common between what we use
 */
type FreezerBase = {
	id: number;
	is_locked: boolean;
	name: string;
	freezer_type: IdsInConstType<typeof FREEZER_TYPES>;
	description: string;
	shelves: number;
	created_at: ISOString;
	updated_at: ISOString;
	label_type: FreezerLabelType;
	is_archived: boolean;
	created_by: number | Avatar;
	updated_by?: number | Avatar;
	organization: number;
	default_item_type: IdsInConstType<typeof ITEM_TYPES>;
	status?: number;
	location_data?: { freezer_name?: string };
	freezer?: number;
};

export type Freezer = SharedInternalObj<FreezerBase>;

type FreezerSummary = {
	name: string;
	boxes: number;
	racks: number;
	items: number;
};
export type FreezerStorageSummary = FreezerSummary;

export type FreezerSettings = {
	has_new_order: boolean;
	order_last_viewed_at: string;
};
