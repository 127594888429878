import React from "react";
import { default as G } from "react-inlinesvg";

// Directly applies styling to SVG elements
const processSVG = (text, props) => {
	// Generate classname to styling map
	let styleMap = {};

	// Grab contents of style tag
	let classString = text.match(/<style>(.|\s)*?<\/style>/g);
	if (classString) {
		classString = classString[0];
		classString = classString.replace(/<\/?style>/g, "").trim();
		let stylings = classString.split("}");

		// Split into selector to styling pairs and
		// convert to map
		stylings = stylings.splice(0, stylings.length - 1);

		stylings.forEach((style) => {
			let keys = style.split("{");
			let [selectors, styling] = keys;
			selectors.split(",").forEach((selector) => {
				const className = selector.substring(1);
				let styles = styling.split(";");
				styles = styles.filter((s) => !!s);

				const stylingMap = styles.reduce((acc, val) => {
					let [name, value] = val.split(":");
					acc[name] = value;
					return acc;
				}, {});

				if (styleMap[className]) {
					styleMap[className] = {
						...styleMap[className],
						...stylingMap,
					};
				} else {
					styleMap[className] = stylingMap;
				}
			});
		});

		// Replace classes with styling
		const temp = text.match(/(class=".*?")/g);
		temp.forEach((match) => {
			const className = match.replace(/class="(.*?)"/g, "$1");
			const styling = styleMap[className];

			const stylingString = Object.keys(styling)
				.map((key) => {
					let value = styling[key];
					// Check for prop attribute overrides
					if (props[key]) {
						value = props[key];
					}
					return key + ":" + value;
				})
				.join("; ")
				.replace("!important", "");
			// Important removes attribute when applied to SVG for some reason...
			text = text.replace(match, `style="${stylingString};"`);
		});

		// Remove defs
		text = text.replace(/<defs>(.|\s)*?<\/defs>/g, "");
	}
	return text;
};

export default function SVG(props) {
	return <G {...props} preProcessor={(code) => processSVG(code, props)} />;
}
