import { IdsInConstType } from "@helpers/TypeHelpers";
import { ITEM_TYPES } from "./Item";
import { Avatar } from "./User";
import { ITEM_GROUP_CONTAINERS } from ".";

type ItemGroupLocationData = {
	freezer_name: string;
	freezer_is_archived: boolean;
	shelf_name: string;
	shelf_is_archived: boolean;
	category_name: string;
	category_is_archived: boolean;
};

export type ItemGroup = {
	id: number;
	name: string;
	description: string;
	location: {
		id: number;
		rack_location: null;
		freezer: number;
		category: number;
	};
	created_by: Avatar | null;
	created_at: string;
	updated_at: string;
	updated_by: Avatar | null;
	is_locked: boolean;
	item_type: IdsInConstType<typeof ITEM_TYPES>;
	container_type: IdsInConstType<typeof ITEM_GROUP_CONTAINERS>;
	location_data: ItemGroupLocationData;
	container_volume: string;
	item_count: number;
	is_archived?: boolean;
	status?: number;
	freezer?: number;
};

export type ItemGroupDataForBackend = Pick<
	ItemGroup,
	"name" | "description" | "container_volume" | "container_type" | "item_type"
> & {
	location: {
		rack_location: null;
		category: number;
	};
};
