import React, { useEffect } from "react";
import {
	Route,
	Redirect,
	useLocation,
	RouteProps,
} from "@common/helpers/Hooks/UseRouterDom";
import { Switch } from "@common/helpers/Hooks/UseRouterDom";
import asyncComponent from "@common/AsyncFunc";
import AcceptInvitation from "@containers/Auth/AcceptInvitation/AcceptInvitation";
import { TeamCreation } from "@containers/Auth/TeamCreation/index";
import { InviteTeam } from "@containers/Auth/InviteTeam/index";
import { ThankYou } from "@containers/Auth/ThankYou";
import Login from "./containers/Auth/Login/index";
import {
	CognitoAuthLanding,
	GoogleSigninRedirect,
} from "@containers/Auth/LoginInterfaces/Cognito/CognitoPages";
import { EmailVerificationMessage } from "@containers/Auth/EmailVerificationMessage";
import ResetPass from "./containers/Auth/ResetPassword";
import { useAppDispatch } from "@redux/store";
import { Registration } from "@containers/Auth/Registration";
import { PlanType } from "@common/types/Stripe";
import { APP_ROUTES, useOrganizationRouter } from "./AppRouter";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import Logout from "./containers/Auth/Logout";
import { useLoginContext } from "@containers/Auth/LoginInterfaces/LoginContext";
import useCurrentSubscription from "@helpers/Hooks/useCurrentSubscriptionHook";
import { useLazyGetOrganizationsQuery } from "@redux/team/TeamApi";
import useCurrentTeamMembers from "@helpers/Hooks/useCurrentTeamMembersHooks";
import useCurrentOrganization from "@helpers/Hooks/useCurrentOrganizationHook";

type IsEmailVerified = "LOADING" | "VERIFIED" | "NOT_VERIFIED";
type HasOrganizations = "LOADING" | "HAS_ORGANIZATIONS" | "NO_ORGANIZATIONS";

export const useLoginAndSignupDependencies = () => {
	const { loginStatus, logout } = useLoginContext();
	const {
		data: user,
		isError,
		isLoading,
	} = useCurrentUserQuery(undefined, { skip: loginStatus !== "LOGGEDIN" });
	const {
		allOrganizations: organizations,
		isLoading: organizationsLoading,
		isError: organizationsError,
	} = useCurrentOrganization();

	const isEmailVerified: IsEmailVerified =
		isLoading || !user || isError
			? "LOADING"
			: user?.email_verified
			? "VERIFIED"
			: "NOT_VERIFIED";

	const hasOrganizations: HasOrganizations =
		organizationsLoading || organizationsError
			? "LOADING"
			: (organizations || []).length > 0
			? "HAS_ORGANIZATIONS"
			: "NO_ORGANIZATIONS";

	useEffect(() => {
		if (isError) {
			logout();
		}
	}, [isError]);

	return {
		loginStatus,
		isEmailVerified,
		hasOrganizations,
		logout,
	} as const;
};

type RestrictedRouteProps = RouteProps & {
	component: any;
	require?: {
		login?: boolean;
		verification?: boolean;
		organizations?: boolean;
		adminOnly?: boolean;
		restrictedPlan?: PlanType[];
	};
	adminRoute?: boolean;
};
export function RestrictedRoute({
	component: Component,
	require,
	adminRoute = false,
	...routeProps
}: RestrictedRouteProps): JSX.Element {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { getRedirect } = useOrganizationRouter();
	const { loginStatus, isEmailVerified, hasOrganizations, logout } =
		useLoginAndSignupDependencies();
	const { currentUserAsTeamMember: user } = useCurrentTeamMembers();
	const { subscription: activeSubscription } = useCurrentSubscription();
	const planName = activeSubscription?.plan.product.name as PlanType;
	const intRequire = {
		login: true,
		verification: true,
		organizations: true,
		...require,
	};
	const [getOrganizations] = useLazyGetOrganizationsQuery();

	// Fetch user after login, when the auth token allows the backend to determine the user identity
	useEffect(() => {
		if (loginStatus === "LOGGEDIN") {
			(async () => {
				try {
					await getOrganizations();
				} catch (error) {
					logout();
				}
			})();
		}
	}, [dispatch, loginStatus, getOrganizations]);

	return (
		<Route
			{...routeProps}
			render={(props) => {
				if (intRequire.login && loginStatus === "LOGGEDOUT") {
					return (
						<Redirect
							to={{
								pathname: adminRoute
									? "/admin/login"
									: "/login",
								state: {
									from: props.location,
									nextPathname: location?.pathname,
									nextSearch: location?.search,
								},
							}}
						/>
					);
				}

				if (
					intRequire.verification &&
					isEmailVerified === "NOT_VERIFIED"
				) {
					return <Redirect to="/verify-email" />;
				}

				if (
					intRequire.organizations &&
					hasOrganizations === "NO_ORGANIZATIONS"
				) {
					return <Redirect to="/signup/create-team" />;
				}

				if (
					(intRequire.login && loginStatus === "LOADING") ||
					(intRequire.verification &&
						isEmailVerified === "LOADING") ||
					(intRequire.organizations && hasOrganizations === "LOADING")
				) {
					return <></>;
				}
				if (
					(intRequire.adminOnly && !user?.is_admin) ||
					(intRequire.restrictedPlan?.length &&
						!intRequire.restrictedPlan?.includes(planName))
				) {
					return getRedirect(APP_ROUTES.DASHBOARD.path);
				}

				return <Component {...props} />;
			}}
		/>
	);
}

function NotFound() {
	return <Redirect to="/login" />;
}

export const APP_URL = "/app";
export default function PublicRoutes() {
	return (
		<Switch>
			{process.env.REACT_APP_NODE_ENV !== "production" && (
				<Route
					path={"/components"}
					component={asyncComponent(
						() => import("./ComponentDemo/ComponentDemo")
					)}
				/>
			)}
			<RestrictedRoute
				path={APP_URL}
				component={asyncComponent(
					() => import("./containers/GenemodApp/GenemodApp")
				)}
			/>
			<Route
				path={"/logout"}
				component={asyncComponent(
					() => import("./containers/Auth/Logout")
				)}
			/>
			<Route path={"/admin/login"} component={Login} />
			<Route path={"/admin/logout"} render={() => <Logout admin />} />
			<RestrictedRoute
				adminRoute
				path={"/admin"}
				component={asyncComponent(
					() => import("./containers/AdminEnterprisePortal")
				)}
			/>
			<Route path={"/reset"} component={ResetPass} />
			<Route
				path={"/forgot-password"}
				component={asyncComponent(
					() => import("./containers/Auth/ForgotPassword")
				)}
			/>
			<RestrictedRoute
				path="/verify-email"
				component={asyncComponent(
					() => import("./containers/Auth/EmailVerificationPage")
				)}
				require={{ verification: false, organizations: false }}
			/>
			<Route
				path="/registration/verify-email/:token"
				render={(props) => <EmailVerificationMessage {...props} />}
			/>
			<Route exact path={"/signup"} component={Registration} />
			<RestrictedRoute
				path={"/signup/create-team"}
				component={TeamCreation}
				require={{ organizations: false }}
			/>
			<RestrictedRoute
				path={"/signup/invite-team"}
				component={InviteTeam}
			/>
			<RestrictedRoute
				path={"/signup/registration-complete"}
				component={ThankYou}
			/>
			<Route path="/invite" component={AcceptInvitation} />
			<Route exact path="/cognitoauth" component={CognitoAuthLanding} />
			<Route
				exact
				path="/google-login"
				component={GoogleSigninRedirect}
			/>
			<Route exact path="/login" component={Login} />
			<Route component={NotFound} />
		</Switch>
	);
}
