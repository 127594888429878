import React, { useEffect, useRef, useState } from "react";
import { Demo, DemoSection, DemoWrapper } from "@common/components";
import styles from "./GenemodSkeleton.module.scss";
import cn from "classnames";

const buildStyleClass = (keyA: string | undefined, keyB: string | undefined) =>
	keyA && keyB && styles[`${keyA}__${keyB}`];

/**
 * The basis for the animation speed. 100 pixels per second.
 */
const ANIMATION_BASIS = 100;

type CircleComponent = {
	component?: "circle";
	size?: "small" | "medium" | "large";
};

type TypographyComponent = {
	component?: "typography";
	variant: "title" | "headline" | "body" | "caption";
	width: number | string;
};

type GenemodSkeleton = {
	height?: number | string;
	width?: number | string;
	/** (default = next-layer) */
	style?: React.CSSProperties;
	shape?: "circle" | "round" | "default";
	animate?: boolean;
	className?: string;
	children?: React.ReactNode;
} & (CircleComponent | TypographyComponent);

export default function GenemodSkeleton(
	extProps: GenemodSkeleton
): JSX.Element {
	const ref = useRef<HTMLDivElement>(null);
	const {
		className,
		shape = "default",
		height,
		width,
		component,
		style,
		...props
	} = extProps;

	const getComponentStyles = () => {
		if (extProps.component === "typography") {
			return extProps.variant ? styles[extProps.variant] : styles.body;
		} else if (extProps.component === "circle") {
			return extProps.size ? styles[extProps.size] : styles.small;
		}
		return "";
	};

	useEffect(() => {
		if (!ref.current) return;

		// This makes the animation speed roughly the same for all skeleton component widths.
		const animationWidth = ref.current.clientWidth + ANIMATION_BASIS;
		const speed = animationWidth / ANIMATION_BASIS;
		const style = ref.current.style;
		style.setProperty("--loading-speed", `${speed}s`);
		style.setProperty("--loading-width", `${animationWidth}px`);

		// This also adds a bit of a pause after the animation has finished before it starts again.
		style.setProperty(
			"--animation-start",
			`calc(-100% - ${2 * ANIMATION_BASIS}px)`
		);
		style.setProperty(
			"--animation-end",
			`calc(100% + ${ANIMATION_BASIS}px)`
		);
	}, []);

	return (
		<div
			ref={ref}
			className={cn(
				className,
				styles.genemodSkeleton,
				buildStyleClass("genemodSkeleton", shape || "default"),
				{ [styles.typography]: component === "typography" },
				{ [styles.circle]: component === "circle" },
				getComponentStyles()
			)}
			style={{
				width: width,
				height: height,
				...style,
			}}
		/>
	);
}

type GenemodSkeletonWrapperProps = GenemodSkeleton & {
	wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
	isLoading?: boolean;
};

export function GenemodSkeletonWrapper({
	wrapperProps,
	...props
}: GenemodSkeletonWrapperProps) {
	const isActive = props.isLoading !== false;
	const animate = props.animate === undefined ? true : props.animate;
	const childRef = useRef<HTMLDivElement>(null);
	const [skeletonWidth, setSkeletonWidth] = useState(props.width);
	const [skeletonHeight, setSkeletonHeight] = useState(props.height);
	useEffect(() => {
		if (childRef.current) {
			setSkeletonWidth(props.width || childRef.current.clientWidth);
			setSkeletonHeight(props.height || childRef.current.clientHeight);
		}
	}, [childRef]);
	return (
		<>
			<div
				{...wrapperProps}
				style={{
					display: isActive ? "inherit" : "none",
				}}
			>
				<GenemodSkeleton
					width={skeletonWidth}
					height={skeletonHeight}
					animate={animate}
					{...props}
				/>
			</div>
			<div
				ref={childRef}
				{...wrapperProps}
				style={{
					...(isActive
						? {
								position: "absolute",
								zIndex: -999,
								display: "inline-block",
								visibility: "hidden",
						  }
						: {}),
				}}
			>
				{props.children}
			</div>
		</>
	);
}

export function GENEMOD_SKELETON_DEMO(): JSX.Element {
	const [isLoading, setIsLoading] = useState(true);
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Genemod Skeleton">
					<div
						style={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							marginBottom: 32,
							gap: 20,
						}}
					>
						<GenemodSkeleton
							width={100}
							height={15}
							shape="round"
						/>
						<GenemodSkeleton width={100} height={15} />
						<GenemodSkeleton width={100} height={15} />
						<GenemodSkeleton
							width={24}
							height={24}
							shape="circle"
						/>
					</div>
					<div>
						<GenemodSkeleton
							width={800}
							height={20}
							shape="round"
						/>
					</div>
				</Demo>
				<Demo title="Genemod Loading Skeleton with children">
					<button
						onClick={() => setIsLoading(!isLoading)}
						style={{ marginBottom: "16px" }}
					>
						Toggle loading
					</button>
					<GenemodSkeletonWrapper isLoading={isLoading}>
						<div style={{ width: "100px", height: "100px" }}>
							hello world!
						</div>
					</GenemodSkeletonWrapper>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
