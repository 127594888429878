import { ResponsiveTableProps } from "./ResponsiveTable";
import { overrideRender } from "./EditRowMixin";

export type NewRowTableMixinProps<T> = {
	/**
	 * Use the "renderEditing" function (in EditRowMixin.tsx) on each column when the row is being edited
	 */
	isAddingNewRow?: boolean;
};

/**
 * Appends a new row to the beginning of the row data and overrides column render functions to "renderEditing" if isAddingNewRow is true.
 */
export const useNewRowMixin = <T,>(
	props: ResponsiveTableProps<T>
): ResponsiveTableProps<T> => {
	const { isAddingNewRow = false, ...cleanedProps } = props;
	const { columns, dataSource } = props;

	if (!isAddingNewRow) {
		return cleanedProps;
	}

	return {
		...cleanedProps,
		columns: columns?.map((col) => ({
			...col,
			render: overrideRender<T>((_data, _row, index) => index === 0)(col),
		})),
		dataSource: [{} as T, ...(dataSource || [])],
	};
};
