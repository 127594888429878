import { createApi, skipToken } from "@reduxjs/toolkit/query/react";
import API from "@API";
import { ContactFormType } from "@common/components/ContactFormPanel/ContactFormPanelData";
import { PersistedObject } from "@helpers/TypeHelpers";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import {
	Customer,
	InvoiceChangePreview,
	InvoicePreview,
	Price,
	Product,
	PromotionCode,
	StripeKeys,
	InvoiceResponse,
} from "@common/types/Stripe";
import { OrganizationId } from "@common/types";
import useCurrentOrganization from "@helpers/Hooks/useCurrentOrganizationHook";

export const CURRENT_PRICES = [
	StripeKeys.prices.businessAnnual,
	StripeKeys.prices.teamAnnual,
	StripeKeys.prices.teamMonthly,
];

type InvoiceChangePreviewData = {
	organizationId: OrganizationId;
	/** ID of the Stripe price object to subscribe to */
	price?: string;
	/** Coupon id if applicable */
	promotion_code?: string;
	/** Seat number if we're changing seats, leave this undefined if just changing plan */
	quantity?: number;
	free_trial: boolean;
};

/**
 * Info required for upgrading plans
 * */
type UpgradeSubscriptionData = InvoiceChangePreviewData & {
	/** ID of the user's payment method. Comes from `stripe.createPaymentMethod` */
	payment_method: string;
};

export const accountApi = createApi({
	reducerPath: "accountApi",
	baseQuery: genemodBaseQuery({ baseUrl: "" }),
	tagTypes: ["stripeCustomer", "invoices"],
	endpoints: (builder) => {
		const getData = {
			subscriptionPlans: builder.query<Price[], void>({
				query: () => API.org2.stripe().subscriptionPlans(),
			}),
			stripeCustomer: builder.query<Customer, OrganizationId>({
				query: (organizationId) =>
					API.org2.get(organizationId).stripe().customer().getRoute(),
				providesTags: ["stripeCustomer"],
			}),
			downgradeToFreePlan: builder.mutation<void, OrganizationId>({
				query: (organizationId) => ({
					url: API.org2
						.get(organizationId)
						.stripe()
						.customer()
						.subscription(),
					method: "DELETE",
				}),
				invalidatesTags: ["stripeCustomer", "invoices"],
			}),
			updatePaymentMethod: builder.mutation<
				void,
				{ organizationId: OrganizationId; payment_method: string }
			>({
				query: ({ organizationId, payment_method }) => ({
					url: API.org2
						.get(organizationId)
						.stripe()
						.customer()
						.payment(),
					method: "POST",
					body: { payment_method },
				}),
				invalidatesTags: ["stripeCustomer", "invoices"],
			}),

			changeSubscriptionPlan: builder.mutation<
				void,
				UpgradeSubscriptionData
			>({
				query: ({ organizationId, ...body }) => ({
					url: API.org2
						.get(organizationId)
						.stripe()
						.customer()
						.subscription(),
					method: "POST",
					body,
				}),
				invalidatesTags: ["stripeCustomer", "invoices"],
			}),
			invoiceChangePreview: builder.query<
				InvoiceChangePreview,
				InvoiceChangePreviewData
			>({
				query: ({ organizationId, ...body }) => ({
					url: API.org2
						.get(organizationId)
						.stripe()
						.customer()
						.previewChanges(),
					method: "POST",
					body,
				}),
			}),

			invoicePreview: builder.query<
				InvoicePreview | "NO_UPCOMING_INVOICE",
				OrganizationId
			>({
				query: (organizationId) => ({
					url: API.org2
						.get(organizationId)
						.stripe()
						.customer()
						.previewInvoice(),
					method: "POST",
				}),
				providesTags: ["invoices"],
			}),
			invoices: builder.query<InvoiceResponse, OrganizationId>({
				query: (orgId) => ({
					url: API.org2.get(orgId).stripe().customer().invoices(),
					method: "GET",
				}),
				providesTags: ["invoices"],
			}),
			promotionCode: builder.query<PromotionCode, string>({
				query: (promoCode) => API.org2.stripe().promotions(promoCode),
			}),
		};

		const postForm = {
			postForm: builder.mutation<
				void,
				{ type: ContactFormType; fields: PersistedObject }
			>({
				query: ({ type, fields: { id, ...otherFields } }) => {
					const route =
						type === "ENTERPRISE_ADJUST_PLAN"
							? "enterprise-adjust-plan"
							: "enterprise-support";

					return {
						url: `${API.org2.getRoute()}/contact-form/${id}/${route}/`,
						method: "POST",
						body: otherFields,
					} as const;
				},
			}),
			requestUpgrade: builder.mutation<
				void,
				{
					orgId: number;
					fields: {
						email: string;
						notes?: string;
						interested_pricing_tier: string;
						feature_interests?: string;
					};
				}
			>({
				query: ({ orgId, fields }) => {
					return {
						url: `${API.org2.getRoute()}/contact-form/${orgId}/request-upgrade/`,
						method: "POST",
						body: fields,
					} as const;
				},
			}),
			requestCancelSubscription: builder.mutation<
				void,
				{
					orgId: number;
					fields: {
						email: string;
						cancellation_reason?: string;
						notes?: string;
					};
				}
			>({
				query: ({ orgId, fields }) => {
					return {
						url: `${API.org2.getRoute()}/contact-form/${orgId}/request-cancel-subscription/`,
						method: "POST",
						body: fields,
					} as const;
				},
			}),
		} as const;

		return {
			...getData,
			...postForm,
		};
	},
});

export const {
	useSubscriptionPlansQuery,
	usePostFormMutation,
	useRequestUpgradeMutation,
	useRequestCancelSubscriptionMutation,
	useStripeCustomerQuery,
	useUpdatePaymentMethodMutation,
	usePromotionCodeQuery,
	useLazyPromotionCodeQuery,
	useChangeSubscriptionPlanMutation,
} = accountApi;

/** Selects all Price objects in the store */
export const useAllPrices = (): Price[] =>
	useSubscriptionPlansQuery().data || [];

/** Returns a list of subscription prices for a product wity `productId` */
export const useProductPricing = (productId: string): Price[] =>
	useAllPrices().filter((price) => price.product.id === productId);

export const useProducts = (includeInactive = false): Product[] =>
	useAllPrices().reduce<Product[]>((accumulator, price) => {
		if (
			!accumulator.some((product) => product.id === price.product.id) &&
			(price.product.active || includeInactive)
		) {
			return [...accumulator, price.product];
		}
		return accumulator;
	}, []);

type SelectProductOptions = {
	/** If true, includes inactive products. Default is false */
	includeInactive?: boolean;
};

/** Selects a product from the store by productId */
export const useProductById = (
	productId: string,
	options: SelectProductOptions = {}
): Product | undefined =>
	useProducts(options.includeInactive).find(
		(product) => product.id === productId
	);

export const useInvoicePreviewQuery = () => {
	const { organizationId } = useCurrentOrganization();
	return accountApi.useInvoicePreviewQuery(organizationId || skipToken);
};

export const useInvoiceChangePreviewQuery = (
	args: Omit<InvoiceChangePreviewData, "organizationId">
) => {
	const { organizationId } = useCurrentOrganization();
	return accountApi.useInvoiceChangePreviewQuery(
		organizationId ? { organizationId, ...args } : skipToken
	);
};

export const useStripeCustomer = () => {
	const { organizationId } = useCurrentOrganization();
	return accountApi.useStripeCustomerQuery(organizationId || skipToken);
};

export const useDowngradeToFreePlanMutation = () => {
	const { organizationId } = useCurrentOrganization();
	const [downgrade, ...opts] = accountApi.useDowngradeToFreePlanMutation();
	return [
		() => {
			if (!organizationId) {
				return;
			}
			return downgrade(organizationId);
		},
		...opts,
	] as const;
};
export const useInvoicesQuery = () => {
	const { organizationId } = useCurrentOrganization();
	return accountApi.useInvoicesQuery(organizationId || skipToken);
};
