import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0,0,2048,2048"
		>
			<path
				className="OfficeIconColors_HighContrast"
				d="M 1638 922 h -409 v -103 h 409 m 0 307 h -409 v -102 h 409 m 0 307 h -409 v -102 h 409 m 177 -728 q 28 31 28 71 v 1271 q 0 21 -8 40 q -8 19 -22 33 q -14 14 -32 22 q -19 8 -40 8 h -1229 q -21 0 -40 -8 q -19 -8 -32 -22 q -14 -14 -22 -33 q -8 -19 -8 -40 v -307 h -205 q -21 0 -40 -8 q -19 -8 -33 -22 q -14 -14 -22 -32 q -8 -19 -8 -40 v -820 q 0 -21 8 -39 q 8 -19 22 -33 q 14 -14 33 -22 q 19 -8 40 -8 h 205 v -307 q 0 -21 8 -40 q 8 -19 22 -33 q 13 -14 32 -22 q 19 -8 40 -8 h 847 q 47 0 77 35 m -2 127 v 248 h 247 m -1197 819 l 118 -216 q 10 -17 13 -28 h 2 q 4 9 7 16 q 3 7 7 13 l 115 215 h 143 l -192 -310 l 187 -304 h -134 l -107 194 q -6 11 -10 22 q -5 10 -10 21 h -1 q -3 -7 -7 -17 q -5 -10 -12 -24 l -100 -196 h -146 l 182 306 l -199 308 m 1401 512 v -1229 h -307 q -21 0 -40 -8 q -19 -8 -33 -21 q -14 -14 -22 -33 q -8 -19 -8 -40 v -307 h -819 v 307 h 512 q 21 0 40 8 q 18 8 32 22 q 14 14 22 33 q 8 18 8 39 v 820 q 0 21 -8 40 q -8 18 -22 32 q -14 14 -32 22 q -19 8 -40 8 h -512 v 307 z"
			/>
			<path
				fill="#FAFAFA"
				fillOpacity="1.000"
				d="M 461 1843 v -1638 q 0 -21 15 -36 q 15 -15 36 -15 h 847 q 11 0 21 5 q 10 4 18 12 l 381 367 q 13 15 13 34 v 1271 q 0 21 -15 36 q -15 15 -36 15 h -1229 q -21 0 -36 -15 q -15 -15 -15 -36 z"
			/>
			<path
				fill="#797774"
				fillOpacity="1.000"
				d="M 1815 501 q 28 31 28 71 v 1271 q 0 21 -8 40 q -8 19 -22 33 q -14 14 -32 22 q -19 8 -40 8 h -1229 q -21 0 -40 -8 q -19 -8 -32 -22 q -14 -14 -22 -33 q -8 -19 -8 -40 v -1638 q 0 -21 8 -40 q 8 -19 22 -33 q 13 -14 32 -22 q 19 -8 40 -8 h 847 q 47 0 77 35 m -2 127 v 248 h 247 m -1169 1331 h 1229 v -1229 h -307 q -21 0 -40 -8 q -19 -8 -33 -21 q -14 -14 -22 -33 q -8 -19 -8 -40 v -307 h -819 z"
			/>
			<path
				fill="#107C41"
				fillOpacity="1.000"
				d="M 1638 1126 h -409 v -102 h 409 z"
			/>
			<path
				fill="#33C481"
				fillOpacity="1.000"
				d="M 1638 922 h -409 v -103 h 409 z"
			/>
			<path
				fill="#134A2C"
				fillOpacity="1.000"
				d="M 1638 1331 h -409 v -102 h 409 z"
			/>
			<path
				fill="#107C41"
				fillOpacity="1.000"
				d="M 1024 1536 h -819 q -21 0 -40 -8 q -19 -8 -33 -22 q -14 -14 -22 -32 q -8 -19 -8 -40 v -820 q 0 -21 8 -39 q 8 -19 22 -33 q 14 -14 33 -22 q 19 -8 40 -8 h 819 q 21 0 40 8 q 18 8 32 22 q 14 14 22 33 q 8 18 8 39 v 820 q 0 21 -8 40 q -8 18 -22 32 q -14 14 -32 22 q -19 8 -40 8 z"
			/>
			<path
				fill="#FFFFFF"
				fillOpacity="1.000"
				d="M 340 1331 l 199 -308 l -182 -306 h 146 l 100 196 q 7 14 12 24 q 4 10 7 17 q 5 -11 10 -21 q 5 -11 11 -22 l 107 -194 h 134 l -187 304 l 192 310 h -143 l -115 -215 q -4 -6 -7 -13 q -3 -7 -7 -16 q -3 5 -6 12 q -4 7 -9 16 l -118 216 z"
			/>
		</svg>
	);
}

const ExcelIcon = React.memo(SvgComponent);
export default ExcelIcon;
