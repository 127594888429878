import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0,0,2048,2048"
		>
			<path
				className="OfficeIconColors_HighContrast"
				d="M 1815 501 q 28 31 28 71 v 1281 q 0 43 -30 68 q -30 25 -72 25 h -1229 q -21 0 -40 -8 q -19 -8 -32 -22 q -14 -14 -22 -33 q -8 -19 -8 -40 v -307 h -205 q -21 0 -40 -8 q -19 -8 -33 -22 q -14 -14 -22 -32 q -8 -19 -8 -40 v -820 q 0 -21 8 -39 q 8 -19 22 -33 q 14 -14 33 -22 q 19 -8 40 -8 h 205 v -307 q 0 -21 8 -40 q 8 -19 22 -33 q 13 -14 32 -22 q 19 -8 40 -8 h 847 q 47 0 77 35 m 305 1706 v -1229 h -307 q -21 0 -40 -8 q -19 -8 -33 -21 q -14 -14 -22 -33 q -8 -19 -8 -40 v -307 h -819 v 307 h 512 q 21 0 40 8 q 18 8 32 22 q 14 14 22 33 q 8 18 8 39 v 820 q 0 21 -8 40 q -8 18 -22 32 q -14 14 -32 22 q -19 8 -40 8 h -512 v 307 m 13 -512 v -217 h 111 q 65 0 117 -26 q 51 -26 78 -73 q 28 -46 28 -106 q 0 -93 -56 -143 q -55 -49 -160 -49 h -232 v 614 m 920 -205 h 307 q 0 64 -24 120 q -24 56 -65 98 q -42 42 -98 66 q -56 24 -120 24 q -27 0 -52 -5 q -26 -5 -50 -13 v -579 q 48 -18 102 -18 m 103 -102 q 63 0 119 24 q 56 24 98 66 q 41 41 65 97 q 24 56 24 120 h -306 m -724 -31 q -30 27 -87 27 h -98 v -208 h 102 q 112 0 112 101 q 0 53 -29 80 m 724 -481 h 247 l -247 -248 z"
			/>
			<path
				fill="#FAFAFA"
				fillOpacity="1.000"
				d="M 461 1853 v -1648 q 0 -21 15 -36 q 15 -15 36 -15 h 847 q 24 0 39 17 l 381 367 q 13 15 13 34 v 1281 q 0 22 -15 37 q -15 15 -36 15 h -1229 q -21 0 -36 -15 q -15 -15 -15 -37 z"
			/>
			<path
				fill="#797774"
				fillOpacity="1.000"
				d="M 1815 501 q 28 31 28 71 v 1281 q 0 43 -30 68 q -30 25 -72 25 h -1229 q -21 0 -40 -8 q -19 -8 -32 -22 q -14 -14 -22 -33 q -8 -19 -8 -40 v -1638 q 0 -21 8 -40 q 8 -19 22 -33 q 13 -14 32 -22 q 19 -8 40 -8 h 847 q 47 0 77 35 m 305 1706 v -1229 h -307 q -21 0 -40 -8 q -19 -8 -33 -21 q -14 -14 -22 -33 q -8 -19 -8 -40 v -307 h -819 v 1638 m 922 -1331 h 247 l -247 -248 z"
			/>
			<path
				fill="#ED6C47"
				fillOpacity="1.000"
				d="M 1434 717 q 63 0 119 24 q 56 24 98 66 q 42 41 66 97 q 24 56 24 120 h -307 m 204 102 q 0 64 -24 120 q -24 56 -65 98 q -42 42 -98 66 q -56 24 -120 24 q -27 0 -52 -5 q -26 -5 -50 -13 v -290 z"
			/>
			<path
				fill="#FF8F6B"
				fillOpacity="1.000"
				d="M 1229 837 q 48 -18 102 -18 v 307 h -102 z"
			/>
			<path
				fill="#FFFFFF"
				fillOpacity="0.498"
				d="M 1434 717 q 63 0 119 24 q 56 24 98 66 q 42 41 66 97 q 24 56 24 120 h -307 z"
			/>
			<path
				fill="#C43E1C"
				fillOpacity="1.000"
				d="M 1024 512 q 21 0 40 8 q 18 8 32 22 q 14 14 22 33 q 8 18 8 39 v 820 q 0 21 -8 40 q -8 18 -22 32 q -14 14 -32 22 q -19 8 -40 8 h -819 q -21 0 -40 -8 q -19 -8 -33 -22 q -14 -14 -22 -32 q -8 -19 -8 -40 v -820 q 0 -21 8 -39 q 8 -19 22 -33 q 14 -14 33 -22 q 19 -8 40 -8 z"
			/>
			<path
				fill="#FFFFFF"
				fillOpacity="1.000"
				d="M 643 717 q 105 0 161 49 q 55 49 55 143 q 0 45 -16 83 q -16 37 -45 65 q -30 27 -71 42 q -41 15 -91 15 h -111 v 217 h -114 v -614 m 114 303 h 98 q 57 0 87 -26 q 29 -27 29 -81 q 0 -101 -112 -101 h -102 z"
			/>
		</svg>
	);
}

const PowerPointIcon = React.memo(SvgComponent);
export default PowerPointIcon;
