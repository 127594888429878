import React, { useMemo, useState } from "react";
import { Tag, TagProps } from "antdv5";
import styles from "./index.module.scss";
import { GenemodIcon, Typography } from "@components";
import { Input } from "antd";
import { AutoComplete } from "antdv4";
import classNames from "classnames";
import { DataSourceItemObject } from "antdv4/lib/auto-complete";
import cn from "classnames";

type LabelProps = TagProps & {
	onClose?: () => void;
	type?: "default" | "static";
	isActive?: boolean;
	dataCy?: string;
	noElipsis?: boolean;
};
export default function Label(props: LabelProps): JSX.Element {
	const {
		children,
		onClose,
		className,
		type = "default",
		isActive,
		noElipsis = false,
	} = props;
	return (
		<Tag
			{...props}
			className={cn(styles.genemodLabel, styles[type], className, {
				[styles.genemodLabel__closable]: !!onClose,
				[styles.active]: isActive,
				[styles.genemodLabelNoElipsis]: noElipsis,
			})}
			closable={!!onClose}
			onClose={onClose}
			closeIcon={<GenemodIcon name="cancel" fill="text-tertiary" />}
			data-cy={`${props.dataCy}-label`}
		>
			{children}
		</Tag>
	);
}

type NewLabelProps = {
	dataSource: string[] | DataSourceItemObject[];
	onLabelCreate: (value: string) => void;
	showInput: boolean;
	setShowInput: (vis: boolean) => void;
	value: string;
	onChange: (e: any) => void;
	onBlur?: (e: React.FocusEvent) => void;
	setRenamePrefix?: boolean;
};

export function NewLabel({
	dataSource,
	onLabelCreate,
	showInput,
	setShowInput,
	value,
	onChange,
	onBlur,
	setRenamePrefix,
}: NewLabelProps): JSX.Element {
	const currentOptions = useMemo(() => {
		let options = dataSource;
		if (dataSource.length && typeof dataSource[0] === "string") {
			options = (dataSource as string[]).map((data) => {
				return {
					value: data,
					text: data,
				};
			});
		}
		return (options as DataSourceItemObject[]).sort((a, b) =>
			a.text.localeCompare(b.text)
		);
	}, [dataSource]);
	// Options for the autocomplete input
	const [options, setOptions] =
		useState<DataSourceItemObject[]>(currentOptions);
	// Visibility of the autocomplete dropdown
	const [dropdownVis, setDropdownVis] = useState<boolean>(false);
	// Whether to display the line above the last option
	const [showLine, setShowline] = useState<boolean>(false);

	// Display results that match the input along with a create new option

	const handleSearchOrFocus = (inputValue: string) => {
		if (!inputValue) {
			setOptions(currentOptions);
			setDropdownVis(!!currentOptions.length);
			setShowline(false);
			return;
		}
		const hasValue = currentOptions.find(
			(option) =>
				inputValue.trim().toLowerCase() === option.value.toLowerCase()
		);
		const filteredRes = currentOptions?.filter(
			(option) =>
				option.value
					.toLowerCase()
					.includes(inputValue.trim().toLowerCase()) &&
				hasValue?.value.toLowerCase() !== option.value.toLowerCase()
		);

		const textPrefix = setRenamePrefix ? "Rename:" : "New label:";

		setOptions([
			...(!hasValue
				? [
						{
							text: `${textPrefix} "${inputValue}"`,
							value: `${inputValue}`,
						},
				  ]
				: [hasValue]),
			...filteredRes,
		]);
		setDropdownVis(true);
		setShowline(!hasValue);
	};

	const handleOnBlurAutoComplete = () => {
		if (!value) {
			setShowInput(false);
		} else {
			onLabelCreate(value);
		}
	};

	return (
		<>
			{showInput ? (
				<AutoComplete
					className={styles.genemodNewLabelContainer}
					value={value}
					onChange={onChange}
					dataSource={options}
					autoFocus
					allowClear
					defaultActiveFirstOption
					getPopupContainer={(trigger) =>
						trigger.parentNode as HTMLElement
					}
					dropdownClassName={classNames(styles.genemodLabelDropdown, {
						[styles.newLabelWithLine]: showLine,
					})}
					dropdownMatchSelectWidth={false}
					open={dropdownVis}
					onSearch={handleSearchOrFocus}
					onSelect={(selectedValue: any) => {
						onLabelCreate(selectedValue.toString());
						setDropdownVis(false);
						setShowline(false);
					}}
					onFocus={() => handleSearchOrFocus(value)}
					onBlur={handleOnBlurAutoComplete}
					dropdownAlign={{
						overflow: {
							adjustX: true,
							adjustY: false,
						},
					}}
				>
					<Input maxLength={50} onBlur={onBlur} />
				</AutoComplete>
			) : (
				<div
					className={styles.newLabel}
					onClick={() => setShowInput(true)}
				>
					<GenemodIcon name="plus" fill="text-secondary" />
					<Typography variant="label">New label</Typography>
				</div>
			)}
		</>
	);
}
