import React from "react";
import { CommonDeleteModal, Notification } from "@components";
import { useCommonModalState } from "@redux/CommonModals/hooks";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { truncArgs } from "@helpers/Formatters";
import { useHistory } from "@helpers/Hooks/UseRouterDom";
import {
	useItemGroupDeleteMutation,
	useItemGroupQuery,
} from "@redux/inventory/ItemGroup";

/**
 * A delete modal used for deleting Box/Item group level objects
 */
export default function DeleteItemGroupModal(): JSX.Element {
	const history = useHistory();
	const {
		isDeleteItemGroupModalVisible,
		closeDeleteItemGroupModal,
		deleteItemGroupModalData: { id, navigateToParentRack = false },
	} = useCommonModalState("deleteItemGroupModal");
	const [deleteItemGroup] = useItemGroupDeleteMutation();

	const { data: itemGroup } = useItemGroupQuery(id || skipToken);
	const itemCount = itemGroup?.item_count || 0;
	const type = "item group";

	const deleteItemGroupSelected = () => {
		if (!itemGroup) return;
		deleteItemGroup(itemGroup.id)
			.unwrap()
			.then(() => {
				closeDeleteItemGroupModal();
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${itemGroup?.name}`(68)}</b>
							{" has been deleted."}
						</span>
					),
				});
				if (navigateToParentRack) {
					history.push({
						pathname: history.location.pathname.replace(
							/item-groups\/(.*)/g,
							""
						),
						search: new URLSearchParams({
							selected_category: itemGroup.location.category + "",
						}).toString(),
					});
				}
			})
			.catch((_err) =>
				Notification.warning({ message: `Failed to delete a ${type}.` })
			);
	};

	return (
		<CommonDeleteModal
			visible={isDeleteItemGroupModalVisible}
			titleObject={type}
			bodyObject={itemGroup?.name || ""}
			onOk={deleteItemGroupSelected}
			onCancel={closeDeleteItemGroupModal}
			dataCy="delete-item-group"
		/>
	);
}
