import { GenemodLinkType, ISOString } from "@common/types";

export type WhatsNewPost = {
	id: number;
	title: string;
	body_content: string;
	availability: string;
	featured_image: string;
	action_text: string;
	explore_now: GenemodLinkType;
	published_at: ISOString;
	action_href: string;
	is_viewed: boolean;
};
