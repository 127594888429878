import React from "react";
import "./StatusIndicator.scss";

// Props
// labels: [String]
// - list of strings indicating the different statuses
// status: int
// - indicates the current status level (0 to labels.length)
export default class StatusIndicator extends React.Component {
	renderStatus = () => {
		let data = [];
		this.props.labels.forEach((label, index) => {
			if (index !== 0) {
				data.push(
					<Separator isComplete={index <= this.props.status} />
				);
			}
			data.push(
				<Indicator
					label={label}
					index={index + 1}
					isComplete={index < this.props.status}
					isCurrent={index === this.props.status}
				/>
			);
		});
		return data;
	};

	render() {
		let children = this.renderStatus();
		let classNames = [
			"gm-status-indicator",
			this.props.className ? this.props.className.trim() : "",
		].join(" ");
		return <div className={classNames}>{children}</div>;
	}
}

function Separator(props) {
	return <div className={"line" + (props.isComplete ? " complete" : "")} />;
}

function Indicator(props) {
	return (
		<div
			className={
				"indicator" +
				(props.isComplete ? " complete" : "") +
				(props.isCurrent ? " current-item" : "") +
				(props.index === 1 ? " first-child" : "")
			}
		>
			<div className="label">{props.label}</div>
			<div className="number">{props.index}</div>
		</div>
	);
}
