import {
	Shelf,
	ShelfDataForBackend,
	ShelfStorageSummary,
	Category,
	PaginatedSearchQuery,
	PaginatedSearchResults,
} from "@common/types";
import { AtLeastId } from "@helpers/TypeHelpers";
import { inventoryApi, withFreezerBase } from ".";

export type RepositoryShelfFilters = Omit<
	PaginatedSearchQuery &
		Partial<{
			id?: number | null;
			ordering?: string | null;
			search?: string | null;
			status__in?: number[] | null;
			created_at__gte?: string | null;
			created_at__lte?: string | null;
			created_by__in?: string[] | null;
			updated_at__gte?: string | null;
			updated_at__lte?: string | null;
			updated_by__in?: string[] | null;
			freezer?: number | null;
			freezer__in?: number[] | null;
			location__isnull?: number;
			include_filter_options?: boolean | null;
		}>,
	"sort_by"
>;
export type RepositoryShelfResult = PaginatedSearchResults<Shelf>;

const shelfApi = inventoryApi.injectEndpoints({
	endpoints: (builder) => ({
		createShelf: builder.mutation<Shelf, ShelfDataForBackend>({
			query: (shelfData) => ({
				url: withFreezerBase(`v2/shelves/`),
				method: "POST",
				body: shelfData,
			}),
			invalidatesTags: ["Freezer", "Shelf"],
		}),

		shelf: builder.query<Shelf, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/shelves/${id}/`),
			}),
			providesTags: ["Shelf"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		shelves: builder.query<Shelf[], { id: number; is_archive?: boolean }>({
			query: ({ id, is_archive = false }) => ({
				url: withFreezerBase(`v2/freezers/${id}/shelves/`),
				params: { is_archive },
			}),
			providesTags: ["Shelf"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		shelfPatch: builder.mutation<Shelf, AtLeastId<Shelf>>({
			query: (body) => ({
				url: withFreezerBase(`v2/shelves/${body.id}/`),
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["Freezer", "Shelf"],
		}),
		shelfDelete: builder.mutation<void, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/shelves/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["Freezer", "Shelf"],
		}),
		shelfStorageSummary: builder.query<ShelfStorageSummary, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/shelves/${id}/storage-summary/`),
			}),
			extraOptions: {
				maxRetries: 0,
			},
		}),
		categories: builder.query<Category[], number>({
			query: (shelfId) => ({
				url: withFreezerBase(`v2/shelves/${shelfId}/categories/`),
			}),
			providesTags: ["Category"],
		}),
		getRepositoryShelves: builder.query<
			RepositoryShelfResult,
			RepositoryShelfFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/shelves-repository/"),
				method: "GET",
				params,
			}),
			providesTags: ["Shelf"],
		}),
		getRepositoryShelf: builder.query<Shelf, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/shelves-repository/${id}/`),
				method: "GET",
			}),
			providesTags: ["Shelf"],
		}),
		shelfBulkCreate: builder.mutation<Shelf[], Partial<Shelf>[]>({
			query: (body) => ({
				url: withFreezerBase("v2/shelves-repository/bulk-create/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Freezer", "Shelf"],
		}),
		shelfBulkUpdate: builder.mutation<Shelf[], Partial<Shelf>[]>({
			query: (body) => ({
				url: withFreezerBase("v2/shelves-repository/bulk-update/"),
				method: "PATCH",
				body,
			}),
			invalidatesTags: [
				"Shelf",
				"Category",
				"Rack",
				"ItemGroup",
				"Box",
				"Item",
			],
		}),
		shelfBulkDelete: builder.mutation<void, { shelf_ids: number[] }>({
			query: (body) => ({
				url: withFreezerBase("v2/shelves-repository/bulk-delete/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Freezer", "Shelf"],
		}),
	}),
});

export const {
	// Shelf crud
	useCreateShelfMutation,
	useShelvesQuery,
	useShelfQuery,
	useCategoriesQuery,
	useLazyCategoriesQuery,
	useShelfPatchMutation,
	useShelfDeleteMutation,
	useShelfStorageSummaryQuery,
	useGetRepositoryShelvesQuery,
	useGetRepositoryShelfQuery,
	useShelfBulkCreateMutation,
	useShelfBulkUpdateMutation,
	useShelfBulkDeleteMutation,
} = shelfApi;
