import {
	createSlice,
	createEntityAdapter,
	PayloadAction,
} from "@reduxjs/toolkit";
import { PMDocument, GenemodDocumentUUID } from "@common/types";
import { AppState } from "@redux/store";
import { NoteChainId } from "@common/components/Editor/plugins/Notes/types";
import {
	createActionsHook,
	createSelectorHook,
	createSimpleStoreGettersAndSetters,
} from "../helpers/ActionHelpers";
import { boxViewSlice } from "../freezer/BoxViewSlice";

export const pmDocumentAdapter = createEntityAdapter<PMDocument>({
	selectId: (doc): GenemodDocumentUUID => doc.uuid,
});

export type RightBarValue =
	| "CLOSED"
	| "NOTES"
	| "ACTIVITY"
	| "CHATGPT"
	| "MATERIALS";
export type CommentTypeValues = "open" | "resolved";

export type ActivityVersionSection = "activity" | "version";

export const pmDocumentSlice = createSlice({
	name: "PMDocuments",
	initialState: {
		openOverlayNotechain: null as null | NoteChainId,
		editOverlayNote: null as null | string,
		leftBarVisible: true,
		rightBarVisibleType: "CLOSED" as RightBarValue,
		notesCommentTypeFilter: "open" as CommentTypeValues,
		activityVersionSection: "activity" as ActivityVersionSection,
		selectedVersionId: undefined as undefined | number,
	},
	reducers: {
		setOpenOverlayNotechain: (
			state,
			action: PayloadAction<null | NoteChainId>
		) => {
			state.openOverlayNotechain = action.payload;
		},
		setEditOverlayNote: (state, action: PayloadAction<null | string>) => {
			state.editOverlayNote = action.payload;
		},
		setLeftBarVisible: (state, action: PayloadAction<boolean>) => {
			state.leftBarVisible = action.payload;
		},
		setRightBarVisibleType: (
			state,
			action: PayloadAction<RightBarValue>
		) => {
			state.rightBarVisibleType = action.payload;
		},
		setNotesCommentTypeFilter: (
			state,
			action: PayloadAction<CommentTypeValues>
		) => {
			state.notesCommentTypeFilter = action.payload;
		},
		setActivityVersionSection: (
			state,
			action: PayloadAction<ActivityVersionSection>
		) => {
			state.activityVersionSection = action.payload;
		},
		setSelectedVersionId: (
			state,
			action: PayloadAction<undefined | number>
		) => {
			state.selectedVersionId = action.payload;
		},
	},
});

export const selectOpenOverlayNotechain = (state: AppState) =>
	state.pm.documents.openOverlayNotechain;

export const selectEditOverlayNote = (state: AppState) =>
	state.pm.documents.editOverlayNote;

export const { setOpenOverlayNotechain, setEditOverlayNote } =
	pmDocumentSlice.actions;

export const usePmDocumentSlice = createSimpleStoreGettersAndSetters(
	`pm.documents`
)(pmDocumentSlice.actions);
