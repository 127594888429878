import React from "react";
import { useSearchParamAndSet } from "@helpers/URLParams";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import {
	COGNITO_AUTH_BASE,
	COGNITO_URI_COMPONENTS,
} from "./service/CognitoConfig";
import { useCognitoContext } from "./CognitoContext";

const useHashParam = (param: string) =>
	useSearchParamAndSet(param, { useHash: true })[0] || "";

/**
 * The cognito hosted ui redirects to this page after user <login />
 * here we process the tokens cognito sends back and setup login.
 */
export const CognitoAuthLanding = () => {
	const history = useHistory();
	const { loginViaTokens } = useCognitoContext();

	const AccessToken = useHashParam("access_token");
	const IdToken = useHashParam("id_token");
	const state = useHashParam("state");

	loginViaTokens({ AccessToken, IdToken }).then(() => {
		history.replace({
			pathname: state || "/app",
		});
	});

	return null;
};

/**
 * Paths we can link to on the cognito hosted UI.
 *
 * "login" is another option but we're using our own login page for now.
 *
 * "confirm" is also an option but there does not seem to be a simple way to restart a confirmation process
 * once a user leaves the first confirm page after signup.
 */
export type PathType = "signup" | "forgotPassword" | "authorize";

/**
 * Builds an internal page that redirects to a cognito hosted UI page.
 */
export const buildCognitoRedirectPage = (
	path: PathType,
	otherUriComponents: Record<string, string | number> = {}
) => {
	const otherUriComponentsStr = Object.entries(otherUriComponents).reduce(
		(str, [key, value]) => `${str}&${key}=${encodeURIComponent(value)}`,
		""
	);
	const cognitoUrl = `${COGNITO_AUTH_BASE}/${path}?${COGNITO_URI_COMPONENTS}${otherUriComponentsStr}`;

	return () => {
		window.location.href = cognitoUrl;
		return null;
	};
};

export const SignupRedirect = buildCognitoRedirectPage("signup");
export const ForgotPasswordRedirect =
	buildCognitoRedirectPage("forgotPassword");

export const GoogleSigninRedirect = buildCognitoRedirectPage("authorize", {
	identity_provider: "Google",
	scope: "email openid phone",
});
export const VerifyEmailRedirect = () => {
	return (
		<div>
			Email not yet verified. Please continue the verification process or
			contact us!
		</div>
	);
};
