import { DateRange, DateRangeEdge } from "@common/types";
import { Avatar } from "@common/types/User";
import {
	Checkbox,
	CustomDatePicker,
	GenemodIcon,
	GenemodSkeleton,
	LayerSystemContainer,
	RadioButton,
	RadioGroup,
	SearchBar,
	Select,
	SelectV2,
	Typography,
	UpgradeButton,
} from "@components";
import { formatFilterCount } from "@helpers/Formatters";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import cn from "classnames";
import React, { useMemo, useState } from "react";
import styles from "./SearchFilterContainer.module.scss";
import "./SearchFilterContainer.scss";

function useFilterUpgradeModal() {
	const { openUpgradeModal } = useCommonModalState("upgradeModal");

	return () => {
		openUpgradeModal({
			type: "SEARCH_FILTER",
		});
	};
}

type SearchFilterContainerProps = {
	/** the label that gets displayed at the top of the search filter */
	label?: string;
	/** the type of filtering tool that gets wrapped by the searchfilter wrapper */
	children?: React.ReactNode;
	/** whether to restrict filter feature or not */
	restrict?: boolean;
	/** show Skeleton if isLoading */
	isLoading?: boolean;
};

export default function SearchFilterContainer({
	label,
	children,
	restrict = false,
	isLoading = false,
}: SearchFilterContainerProps): JSX.Element {
	const [showFilter, setShowFilter] = useState(true);
	const handleToggleShow = () => {
		setShowFilter(!showFilter);
	};
	const showUpgradeModal = useFilterUpgradeModal();

	if (isLoading) {
		return <SearchFilterContainerSkeleton />;
	}
	return (
		<LayerSystemContainer className="search-filter-container">
			<LayerSystemContainer
				className={cn("filter-header-collapsible", {
					opened: showFilter,
					closed: !showFilter,
				})}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: restrict ? 12 : 0,
					}}
				>
					<Typography className="filter-label" variant="subheadline">
						{label}
					</Typography>

					{restrict && (
						<UpgradeButton type="tag" onClick={showUpgradeModal} />
					)}
				</div>

				<GenemodIcon
					onClick={handleToggleShow}
					stroke="text-secondary"
					style={{ marginRight: 8 }}
					name={showFilter === false ? "plus" : "minus"}
				/>
			</LayerSystemContainer>
			<div
				className={cn({
					"search-filter-content": showFilter,
				})}
			>
				{showFilter && children}
			</div>
		</LayerSystemContainer>
	);
}

/** Radio button group to go inside the SearchFilterContainer component wrapper */
export type radioGroupOptions = {
	label: JSX.Element | string | undefined;
	search: string | undefined;
	name: string;
	count: number;
	value: number | null;
};

type RadioGroupFilterProps = {
	options: radioGroupOptions[];
	value: number | null;
	onChange: (e: any) => void;
	restrict?: boolean;
};

export function RadioGroupFilter({
	options,
	value,
	onChange,
	restrict = false,
}: RadioGroupFilterProps): JSX.Element {
	const showUpgradeModal = useFilterUpgradeModal();

	return (
		<div>
			<RadioGroup
				onChange={(e: any) => {
					if (restrict) {
						showUpgradeModal();
					} else {
						onChange(e.target.value);
					}
				}}
				value={value || null}
				style={{ paddingTop: 0 }}
			>
				<>
					{options.map((val?: any) => {
						return (
							<div
								key={val.id}
								style={{
									height: 36,
									display: "flex",
									alignItems: "center",
									cursor: "none",
								}}
							>
								<RadioButton
									value={val.value}
									theme="lightweight"
								>
									<OptionLabel
										label={val.label}
										count={val.count}
									/>
								</RadioButton>
							</div>
						);
					})}
				</>
			</RadioGroup>
		</div>
	);
}

export type SimpleOrgUser = {
	id: number;
	user: Avatar;
};

/** Checkbox group to go inside the SearchFilterContainer wrapper component */
export type checkboxOptions<T> = {
	key: string;
	label: JSX.Element | string;
	search?: string;
	name?: string | JSX.Element;
	count: number;
	value: T;
};

type CheckBoxGroupFilterProps<T> = {
	/** the filter function associated with this filter */
	onChange?: (values: T[]) => void;
	/** the selected values on the specified filter. comes from the SearchFilterState prop */
	value?: T[];
	/** the list of value options for the checkbox group component. In a format of [{key: unique, value: string, search: string, label: React.ReactNode}] */
	options: checkboxOptions<T>[];
	/** whether to include a search function within the filter. Not included by default. */
	isSearchable?: boolean;
	/** whether to include user avatar for each option. Not included by default. */
	hasAvatar?: boolean;
	/** it represents the target users are searching for. E.g. "researcher", "location", "project", "publisher" */
	target?: string;
	edgeTarget?: string;
	/** whether to restrict the feature */
	restrict?: boolean;
	placeholder?: React.ReactNode;
};

export function CheckBoxGroupFilter<T>({
	onChange,
	value,
	options,
	isSearchable = false,
	target,
	edgeTarget,
	restrict = false,
	placeholder = "Find a label",
}: CheckBoxGroupFilterProps<T>): JSX.Element {
	const [searchTerm, setSearchTerm] = useState<string>("");
	const showUpgradeModal = useFilterUpgradeModal();

	const results = useMemo(() => {
		if (!isSearchable || !searchTerm?.trim()) return options;

		return options.filter(({ search }) =>
			search?.toLowerCase()?.includes(searchTerm.toLowerCase().trim())
		);
	}, [isSearchable, searchTerm, options]);

	const handleCheckCheckbox = (id: T) => {
		if (restrict) {
			showUpgradeModal();
			return;
		}
		let newValue = value ?? [];
		if (newValue.includes(id)) {
			newValue = newValue.filter((val) => val !== id);
		} else {
			newValue.push(id);
		}
		onChange?.(newValue);
	};

	return (
		<div>
			{isSearchable && (
				<div className="search-bar">
					<SearchBar
						value={searchTerm}
						onChange={(text) => {
							restrict ? showUpgradeModal() : setSearchTerm(text);
						}}
						placeholder={
							placeholder || `Find a ${target || "label"}`
						}
						disableSuggestions
						wrapperProps={{ style: { height: 34 } }}
						iconPosition="left"
						searchIconColor="text-secondary"
					/>
				</div>
			)}
			{results.length === 0 ? (
				<Typography className="no-result-found">
					{`No ${edgeTarget ? edgeTarget : target}s found`}
				</Typography>
			) : (
				results.map((result) => {
					return (
						<div key={result.key}>
							<Checkbox
								key={result.key}
								value={value?.includes(result.value) ?? false}
								onChange={() =>
									handleCheckCheckbox(result.value)
								}
								className="search-filter-checkbox"
							>
								<OptionLabel
									label={result.label}
									count={result.count}
								/>
							</Checkbox>
						</div>
					);
				})
			)}
		</div>
	);
}

type OptionsLabelProps = {
	label: JSX.Element | string;
	count?: number;
};
function OptionLabel({ label, count }: OptionsLabelProps) {
	return (
		<div className={styles.optionLabel}>
			{typeof label === "string" ? (
				<Typography variant="label" color="text-secondary" ellipsis>
					{label}
				</Typography>
			) : (
				label
			)}
			{count ? (
				<Typography variant="caption" color="text-tertiary">
					{`(${formatFilterCount(count)})`}
				</Typography>
			) : (
				<></>
			)}
		</div>
	);
}

type DateRangeFilterProps = {
	value: DateRange;
	onChange?: (value: moment.Moment | null, edge: DateRangeEdge) => void;
	restrict?: boolean;
};

export function DateRangeFilter({
	value,
	onChange,
	restrict = false,
}: DateRangeFilterProps) {
	const { start, end } = value;
	const showUpgradeModal = useFilterUpgradeModal();

	/**
	 * Returns a function that filters the date picker's dates depending on the edge
	 */
	const handleDisabledDates = (edge: DateRangeEdge) => {
		// This function returns True for dates that are disabled
		return (date: moment.Moment) => {
			if (edge === "start" && end) {
				// Disabled if a date comes after the end date
				return date.isAfter(end);
			} else if (edge === "end" && start) {
				// Disabled if a date comes before the start date
				return date.isBefore(start);
			}
			return false;
		};
	};

	return (
		<div className={styles.dateRangeFilter}>
			<div className={styles.dateRangeRow}>
				<Typography variant="label">From</Typography>
				<CustomDatePicker
					allowClear
					value={start}
					onChange={(e) => {
						restrict ? showUpgradeModal() : onChange?.(e, "start");
					}}
					onClick={() => {
						restrict ? showUpgradeModal() : null;
					}}
					disabledDate={handleDisabledDates("start")}
				/>
			</div>
			<div className={styles.dateRangeRow}>
				<Typography variant="label">To</Typography>
				<CustomDatePicker
					allowClear
					value={end}
					onChange={(e) => {
						restrict ? showUpgradeModal() : onChange?.(e, "end");
					}}
					onClick={() => {
						restrict ? showUpgradeModal() : null;
					}}
					disabledDate={handleDisabledDates("end")}
				/>
			</div>
		</div>
	);
}

export const PAGE_SIZES = [25, 50, 100, 200];
type PageSizeFilterProps = {
	count: number;
	label?: string;
	pageSize: number | null;
	onChange: (pageSize: number) => void;
	isRepository?: boolean;
	isSmall?: boolean;
};
export function PageSizeFilter({
	count,
	label = "row",
	pageSize,
	onChange,
	isRepository,
	isSmall = true,
}: PageSizeFilterProps) {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
			}}
		>
			{!isRepository && (
				<Typography
					variant="label"
					color="text-secondary-v2"
					style={{ marginRight: 8 }}
				>
					Show
				</Typography>
			)}
			<SelectV2
				defaultValue={PAGE_SIZES[0]}
				value={pageSize || PAGE_SIZES[0]}
				onChange={(val) => onChange(+val)}
				isSmall={isSmall}
				isStrokeIcon
				getPopupContainer={undefined}
				variant="borderless"
				style={{ width: 50 }}
				suffixIcon={
					<GenemodIcon
						name="caret-down"
						size="small"
						stroke="text-secondary-v2"
					/>
				}
				optionLabelProp="label"
			>
				{PAGE_SIZES.map((size) => {
					return (
						<SelectV2.Option
							key={size}
							value={size}
							label={
								<b>
									{size}
								</b>
							}
						>
							{size}
						</SelectV2.Option>
					);
				})}
			</SelectV2>
			{isRepository && (
				<Typography variant="label" color="text-secondary">
					results per page
				</Typography>
			)}
			{!isRepository && (
				<Typography variant="label" color="text-secondary">
					of {count} {count > 1 ? "results" : "result"}
				</Typography>
			)}
		</div>
	);
}

const SearchFilterContainerSkeleton = () => {
	return (
		<div className={styles.filterCardSkeleton}>
			<div className={styles.skeletonHeader}>
				<GenemodSkeleton
					height={15}
					width={100}
					style={{ background: "var(--layer-02)" }}
					shape="round"
				/>
			</div>
			<div className={styles.skeletonBody}>
				{Array(2)
					.fill(0)
					.map((_, index) => (
						<div
							key={`skeleton-row-${index}`}
							className={styles.skeletonRow}
						>
							<GenemodSkeleton
								height={14}
								width={14}
								shape="circle"
							/>
							<GenemodSkeleton
								height={15}
								width={100}
								shape="round"
							/>
						</div>
					))}
			</div>
		</div>
	);
};
