import { Avatar, ISOString, RackLocationData } from ".";
import { ShelfLocation } from "./ShelfLocation";

/** Type data for `Rack`/`Category` */
export type Category = {
	/** ID of the object */
	id: number;
	/** Name of the object */
	name: string;
	is_archived: boolean;
	is_locked: boolean;
	owner: number;
	created_at: ISOString;
	updated_at: ISOString;
	created_by?: Avatar;
	updated_by?: Avatar;
	location: ShelfLocation;
	location_data?: RackLocationData;
	status?: number;
	freezer?: number;
};

/** Type data for sending new rack data to the backend */
export type CategoryDataForBackend = Pick<Category, "name"> & {
	location?: {
		shelf: number;
		index: number;
	};
};
