/** get hex variable from css variable */
export function getHexFromCss(cssColor: string): string {
	if (cssColor.includes("var")) {
		// extract color from fill like var(--hl_1a) => --hl_1a
		const extractedColor = cssColor.slice(4, cssColor.length - 1);
		return extractedColor;
	}
	return cssColor;
}

export function formatFileSize(size: number | undefined): string {
	if (size === 0 || size === undefined) {
		return "0 B";
	}
	const k = 1024;
	const units = ["B", "KB", "MB", "GB"];
	const i = Math.floor(Math.log(size) / Math.log(k));
	return `${Math.floor(size / Math.pow(k, i))} ${units[i]}`;
}

export function formatFileName(
	name: string | undefined,
	extension: string | undefined
): string {
	if (name === undefined || extension === undefined) {
		return "";
	}
	return extension !== "" ? `${name}.${extension}` : name;
}
