import React from "react";
import styles from "./index.module.scss";
import { Typography } from "@components";
import { useTheme } from "@components/Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "@components/Theme/context/ThemeTypes";
import cn from "classnames";

// Beta tag tha gets displayed next to a bullet
export default function BetaTag(): JSX.Element {
	const [theme] = useTheme();
	return (
		<div
			className={cn(styles.betaTag, {
				[styles.betaTag__dark]: theme === THEME_PREFERENCE.dark,
			})}
		>
			<Typography variant="caption" color="none">
				Beta
			</Typography>
		</div>
	);
}
