import { FREEZER_PATHS } from "@containers/Freezer";
import {
	ACCOUNT_SETTINGS_PATHS,
	EQUIPMENTS_PATHS,
	PM_ROUTES,
} from "@root/routes";

// Helper to append a prefix to path keys to tell them apart (eg: "base" is found in many path objects)
type AppendContainerPrefix<
	Prefix extends string,
	Paths extends string
> = `${Prefix}_${Paths}`;

// Prefixes
export type AccountPrefix = "ACCOUNT";
export type PmPrefix = "PM";
export type FreezerPrefix = "FREEZER";
export type EquipmentPrefix = "EQUIPMENT";
export type AllGenemodLinkPrefixes =
	| AccountPrefix
	| PmPrefix
	| FreezerPrefix
	| EquipmentPrefix;

// The various keys for paths
export type AccountKeys = keyof typeof ACCOUNT_SETTINGS_PATHS;
export type PmKeys = keyof typeof PM_ROUTES;
export type FreezerKeys = keyof typeof FREEZER_PATHS;
export type EquipmentKeys = keyof typeof EQUIPMENTS_PATHS;
export type AllGenemodLinkKeys = AccountKeys | PmKeys | FreezerKeys;

/**
 * All the possible links, expand as needed.
 */
export type GenemodLinkType =
	| AppendContainerPrefix<AccountPrefix, keyof typeof ACCOUNT_SETTINGS_PATHS>
	| AppendContainerPrefix<PmPrefix, keyof typeof PM_ROUTES>
	| AppendContainerPrefix<FreezerPrefix, keyof typeof FREEZER_PATHS>
	| AppendContainerPrefix<EquipmentPrefix, keyof typeof EQUIPMENTS_PATHS>
	| "none";
