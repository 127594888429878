import React from "react";
import { Typography } from "@components";
import { Editor } from "slate";
import { overrideFunction } from "./PluginHelpers";
import { GenemodRenderElementProps } from "../types";
import styles from "./editor.module.scss";

/**
 * Initializes functions that get passed to slate-react's Editable component
 * */
export const withRenderedEditorPlugin = (editor: Editor): Editor => {
	overrideFunction(editor)("onKeyDown")(() => (event) => {
		if (event.key === "Tab") {
			event.preventDefault();
			editor.insertText("\t");
		}
	});

	overrideFunction(editor)("renderElements")(() => RenderElements);

	overrideFunction(editor)("renderLeaves")(
		() =>
			({ attributes, children }) =>
				<span {...attributes}>{children}</span>
	);

	overrideFunction(editor)("onBlur")(() => () => {});

	editor.readOnly = false;

	return editor;
};

const RenderElements = ({
	attributes,
	children,
}: GenemodRenderElementProps) => {
	return (
		<Typography
			{...attributes}
			style={{ ...attributes.style }}
			className={styles.editorText}
			resize={false}
		>
			{children}
		</Typography>
	);
};
