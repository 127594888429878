export type PageNumberPaginationParams = {
	page_size?: number;
	page: number;
};

export type PageNumberPaginationResponse<T> = {
	/**
	 * Number of results.
	 */
	count: number;
	/**
	 * Total number of pages
	 */
	total_page_count?: number;
	/**
	 * URL for the next page of results.
	 */
	next: string;
	/**
	 * URL for the previous page of results.
	 */
	previous: string;
	results: T[];
};
