import React from "react";
import { ArchiveStep, FreezerLayers } from "../../RepositoryContext";
import UnarchiveTable from "./UnarchiveTable";
import OrganizeBoxOnRack from "./OrganizeBoxOnRack/OrganizeBoxOnRack";
import OrganizeItemOnBox from "./OrganizeItemOnBox/OrganizeItemOnBox";

export const UNARCHIVE_STEPS: {
	[K in FreezerLayers]: ArchiveStep[];
} = {
	FREEZER: [],
	SHELF: [],
	RACK: [
		{
			key: "restore-rack",
			number: 1,
			label: "",
			dataCy: "",
			title: "Select archived Racks to Shelf",
			component: <UnarchiveTable layer="RACK" />,
		},
	],
	CATEGORY: [
		{
			key: "restore-category",
			number: 1,
			label: "",
			dataCy: "",
			title: "Select archived Categories to Shelf",
			component: <UnarchiveTable layer="CATEGORY" />,
		},
	],
	BOX: [
		{
			key: "restore-box-select",
			number: 1,
			label: "Select Boxes",
			dataCy: "select-restore-boxes",
			title: "Select archived Boxes to Category",
			component: <UnarchiveTable layer="BOX" />,
		},
		{
			key: "restore-box-organize",
			number: 2,
			label: "Organize Boxes",
			dataCy: "organize-boxes",
			title: "Organize restore Boxes",
			component: <OrganizeBoxOnRack />,
		},
	],
	ITEMGROUP: [
		{
			key: "restore-itemgroup",
			number: 1,
			label: "",
			dataCy: "",
			title: "Select archived Item Groups to Category",
			component: <UnarchiveTable layer="ITEMGROUP" />,
		},
	],
	ITEM: [
		{
			key: "restore-items-select",
			number: 1,
			label: "Select Items",
			dataCy: "select-restore-items",
			title: "Select archived Items to Box",
			component: <UnarchiveTable layer="ITEM" />,
		},
		{
			key: "restore-items-organize",
			number: 2,
			label: "Organize Items",
			dataCy: "organize-items",
			title: "Organize restore Items",
			component: <OrganizeItemOnBox />,
		},
	],
};
