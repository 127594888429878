import React, { useState, useEffect, HTMLProps } from "react";
import Checkbox from "antdv5/es/checkbox/Checkbox";
import { GenemodIcon, Typography } from "@components";
import { Demo, DemoSection, DemoWrapper } from "../index";
import classNames from "classnames";
import { Color } from "@common/styles/Colors";
import styles from "./CheckboxV2.module.scss";

type CheckboxV2Props = {
	value?: boolean | "complicated";
	onChange?: (
		state: boolean | "complicated",
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	id?: string;
	className?: string;
	children?: any;
	color?: Color;
	style?: React.CSSProperties;
	disabled?: boolean;
	dataCy?: string;
};

export default function CheckboxV2({
	value,
	onChange,
	id,
	className,
	children,
	color = "accent-subtle",
	style,
	disabled,
	dataCy,
}: CheckboxV2Props): JSX.Element {
	const [currentValue, setValue] = useState<true | false | "complicated">(
		false
	);
	const handleCheck = (event: any) => {
		if (disabled) return;

		let nextState = false;
		if (currentValue === "complicated" || currentValue === false) {
			nextState = true;
		}

		setValue(nextState);
		// Add id to any event targets
		event.target.id = id;
		if (onChange) onChange(nextState, event);
	};
	useEffect(() => {
		if (value !== undefined && value !== currentValue) {
			setValue(value);
		}
	}, [value]);

	return (
		<div className={styles.checkboxContainer}>
			<Checkbox
				onChange={handleCheck}
				checked={currentValue === "complicated" ? false : currentValue}
				indeterminate={currentValue === "complicated"}
				disabled={disabled}
			>
				{children}
			</Checkbox>
		</div>
	);
}

// Demo code
export function CHECKBOXV2_DEMO(): JSX.Element {
	const checkBoxState = [false, true, "complicated"] as (
		| boolean
		| "complicated"
	)[];

	const [index, setIndex] = useState(0);
	const handleToggle = () => {
		if (index === checkBoxState.length - 1) {
			setIndex(0);
		} else {
			setIndex(index + 1);
		}
	};

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Basic usage" description="Basic Check box element">
					<div>
						<h4>Checked and uncheked</h4>
						<CheckboxV2>
							<Typography color="text-secondary" variant="body2">
								Check me out
							</Typography>
						</CheckboxV2>
					</div>
				</Demo>
				<Demo
					title="Check box states"
					description="There are three different check box states."
				>
					<CheckboxV2 value={true}>checked</CheckboxV2>
					<CheckboxV2 value={false}>unchecked</CheckboxV2>
					<CheckboxV2 value={"complicated"}>complicated</CheckboxV2>
					<CheckboxV2 value={true} disabled={true}>
						disabled - checked
					</CheckboxV2>
					<CheckboxV2 value={false} disabled={true}>
						disabled - unchecked
					</CheckboxV2>
					<CheckboxV2 value={"complicated"} disabled={true}>
						disabled - complicated
					</CheckboxV2>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
