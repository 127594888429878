import { GenemodIcon, Tooltip } from "@common/components";
import EXCEL_ICON from "@common/icons/excel.svg";
import PPRT_ICON from "@common/icons/power-point.svg";
import WORD_ICON from "@common/icons/word.svg";
import { Typography } from "@components";
import { OFFICE_FILE_TYPE, useOffice } from "@containers/Office/context";
import { useSafeWindowEventListener } from "@helpers/Hooks";
import { useParams as useUrlParams } from "@helpers/URLParams";
import { useLazyProjectQuery } from "@redux/ProjectManagement/PmApiSlice";
import classNames from "classnames";
import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import styles from "./AttachmentPreviewOffice.module.scss";

const WORD = {
	label: "Word",
	fileType: OFFICE_FILE_TYPE.DOCX,
	oldFileType: OFFICE_FILE_TYPE.DOC,
};
const EXCEL = {
	label: "Excel",
	fileType: OFFICE_FILE_TYPE.XLSX,
	oldFileType: OFFICE_FILE_TYPE.XLS,
};
const POWER_POINT = {
	label: "Power Point",
	fileType: OFFICE_FILE_TYPE.PPTX,
	oldFileType: OFFICE_FILE_TYPE.PPT,
};
const WOPI_TEST = {
	label: "Wopitest",
	fileType: OFFICE_FILE_TYPE.WOPI_TEST,
};

export type AttachmentInfo = {
	name: string;
	id?: number;
	upload?: string;
	file_type?: "docx" | "xlsx" | "pptx" | "wopitest";
};

export type AttachmentPreviewContainerProps<T extends AttachmentInfo> = {
	/** Whether or not to show the preview. */
	visible: boolean;
	/** attahcment to be shown as preview */
	attachment: T | null | undefined;
	/** function to close preivew */
	onClose: () => void;
	/** funciton to download current attachemnt, if falsy will not render */
	onDownload?: (attachment: T | null | undefined) => void;
	/** function to delete current attachemnt */
	onDelete: undefined | ((attachment: T | null | undefined) => void);
	children: React.ReactNode;
};

/**
 * Container for the full screen file preview. Has the header.
 */
export default function AttachmentPreviewOfficeContainer<
	T extends AttachmentInfo
>({
	visible,
	attachment,
	onClose,
	onDownload,
	onDelete,
	children,
}: AttachmentPreviewContainerProps<T>): JSX.Element {
	useSafeWindowEventListener("keydown", (ev) => {
		if (visible && ev.key === "Escape") {
			onClose();
		}
	});
	const { onAttachmentClick, onLoadOfficeOnline } = useOffice();
	const { getParam } = useUrlParams();
	const projectId = getParam("projectId");
	const [getProject] = useLazyProjectQuery();

	const fileType = useMemo(() => {
		if (attachment?.file_type === POWER_POINT.fileType)
			return { type: POWER_POINT.label, icon: PPRT_ICON };
		if (attachment?.file_type === EXCEL.fileType)
			return { type: EXCEL.label, icon: EXCEL_ICON };
		if (attachment?.file_type === WORD.fileType)
			return { type: WORD.label, icon: WORD_ICON };
		if (attachment?.file_type === WOPI_TEST.fileType)
			return { type: WOPI_TEST.label, icon: WORD_ICON };

		// old file versions
		if (attachment?.file_type === POWER_POINT.oldFileType)
			return {
				type: POWER_POINT.label,
				icon: PPRT_ICON,
				newerFileVersion: POWER_POINT.fileType,
			};
		if (attachment?.file_type === EXCEL.oldFileType)
			return {
				type: EXCEL.label,
				icon: EXCEL_ICON,
				newerFileVersion: EXCEL.fileType,
			};
		if (attachment?.file_type === WORD.oldFileType)
			return {
				type: WORD.label,
				icon: WORD_ICON,
				newerFileVersion: WORD.fileType,
			};
		return undefined;
	}, [attachment]);

	const openFileInOffice = async () => {
		try {
			const project = await getProject(Number(projectId)).unwrap();
			if (
				!attachment?.id ||
				!attachment?.file_type ||
				!attachment.upload ||
				!project
			)
				return;
			onAttachmentClick({
				attachment: attachment.id,
				fileType: attachment.file_type,
				url: attachment.upload,
				projectId: project.id,
				projectIsArchived: project.is_archived,
			});
			onLoadOfficeOnline();
		} catch (error) {
			console.log("error", error);
		}
	};

	const renderHeader = () => (
		<div
			className={styles.header}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<div className={styles.headerLeft}>
				{attachment?.name ? (
					<Typography color="text-inverse">
						{attachment.name}
					</Typography>
				) : (
					<GenemodIcon
						name="arrow-forward"
						hoverEffect
						size="large"
						style={{ transform: "rotate(180deg)" }}
						stroke="text-on-color"
						onClick={onClose}
					/>
				)}
			</div>
			<div className={styles.headerMiddle}>
				{fileType ? (
					<Tooltip
						placement="bottom"
						title={
							fileType.newerFileVersion
								? `Microsoft Office can't open older version of documents online. Please upload a "${fileType.newerFileVersion}" version of the file.`
								: null
						}
					>
						<div
							className={classNames(styles.openTextContainer, {
								[styles.openTextContainerDisabled]:
									!!fileType.newerFileVersion,
							})}
							onClick={
								fileType.newerFileVersion
									? undefined
									: openFileInOffice
							}
						>
							<img src={fileType.icon} alt="404 Image" />
							<Typography ellipsis color="text-secondary-v2">
								Open in {fileType.type} for the web
							</Typography>
						</div>
					</Tooltip>
				) : null}
			</div>
			<div className={styles.headerRight}>
				{onDownload && (
					<div
						className={styles.downloadContainer}
						onClick={() => {
							onDownload(attachment);
							onClose();
						}}
					>
						<GenemodIcon
							name="download"
							hoverEffect
							size="large"
							fill="text-inverse"
							color="text-inverse"
						/>
						<Typography color="text-inverse">Download</Typography>
					</div>
				)}
				<GenemodIcon
					name="exit"
					hoverEffect
					size="large"
					fill="text-inverse"
					color="text-inverse"
					onClick={onClose}
				/>
			</div>
		</div>
	);

	const preview = (
		<div className={styles.previewAttachment} contentEditable={false}>
			{renderHeader()}
			<div className={styles.closeCapture} onClick={onClose}>
				<div
					style={{ height: " 100%", width: "100%" }}
					onClick={(ev) => ev.stopPropagation()}
				>
					{children}
				</div>
			</div>
		</div>
	);

	if (!visible) {
		return <></>;
	}

	return ReactDOM.createPortal(
		preview,
		document.querySelector("#root") as Element
	);
}
