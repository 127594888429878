import React, { useState, useEffect, HTMLProps } from "react";
import styles from "../Input/Input.module.scss";
import "./TextArea.scss";
import classNames from "classnames";
import { DemoWrapper, DemoSection, Demo, Typography } from "@components";
import { InputValidator } from "../Input/Input";
import { useInputValidation } from "@helpers/InputHelper.js";
import { Input } from "antdv4";
import { Color } from "@common/styles/Colors";
import { useFormContext } from "react-hook-form";

type TextAreaProps = {
	/** Textarea id */
	id?: string;
	/** Textarea value */
	value?: string;
	/** The placeholder of the textarea */
	placeholder?: string;
	/** Textarea label */
	label?: string;
	/** Color of the label */
	labelColor?: Color;
	/** Props for the wrapper element of the textarea */
	wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
	/** Props for the textarea */
	inputProps?: React.HTMLAttributes<HTMLDivElement>;
	/** A forced error state that displays an error message */
	error?: string | boolean | null;
	/** A list of local functions that checks the validity of the input value */
	validators?: InputValidator[];
	/** Whether to autosize the height of the textarea*/
	autoFocus?: boolean;
	/** Whether to autosize the height of the textarea*/
	autoSize?: boolean;
	/** Stylings of the textarea */
	style?: React.CSSProperties;
	/** Called when the validity on the input changes */
	onValidityChange?: (arg0: boolean) => void;
	/** Called when the value of the input changes */
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	/** Called when users press on ENTER */
	onPressEnter?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	/** Whether to display a gutter at the bottom */
	gutterBottom?: boolean;
	/** Whether to disabled the input */
	disabled?: boolean;
	/** Do not show the focus underline */
	noFocusUnderline?: boolean;
	/** Remove border around the text area */
	noBorder?: boolean;
	forwardedRef?: React.RefObject<HTMLTextAreaElement>;
	/** set a limit to the amount of characters allowed */
	maxLength?: number;
	onFocus?: () => void;
	onBlur?: () => void;
	dataCy?: string;
	containerClassname?: string;
};
export default function TextArea({
	id = "",
	value = "",
	placeholder = "",
	label = "",
	labelColor = "text-primary-v2",
	error = "",
	autoSize = true,
	autoFocus = false,
	wrapperProps = {},
	inputProps = {},
	style = {},
	validators = [],
	onValidityChange = () => {},
	onChange = () => {},
	onPressEnter = () => {},
	gutterBottom = true,
	disabled = false,
	noFocusUnderline = false,
	noBorder,
	forwardedRef,
	maxLength,
	onBlur,
	onFocus,
	dataCy,
	containerClassname,
}: TextAreaProps): JSX.Element {
	const { TextArea } = Input;
	const [inputValue, setValue] = useState(value ? value : "");
	const [isValid, showError] = useInputValidation(
		value,
		validators,
		onValidityChange
	);
	// Sync internal value with props
	useEffect(() => {
		if (value !== undefined && value !== inputValue) {
			setValue(value);
		}
	}, [value, inputValue]);

	// Update internal value if value prop changed
	useEffect(() => {
		setValue(value);
	}, [value]);

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value);
		if (onChange) onChange(e);
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === "Enter" && onPressEnter) {
			onPressEnter(e);
		}
	};

	const validity = isValid && !error;

	return (
		<div
			className={classNames(
				wrapperProps?.className,
				{ [styles.container__error]: !validity },
				{ [styles.container__noBottomMargin]: gutterBottom === false },
				styles.container
			)}
			style={wrapperProps?.style}
		>
			{label && (
				<Typography
					variant="body"
					color={labelColor}
					className={styles.label}
				>
					{label}
				</Typography>
			)}
			<div className={styles.content}>
				<div
					className={classNames(
						styles.inputContainer,
						containerClassname,
						{
							[styles.inputContainer__error]: !validity,
							[styles.inputContainer__disabled]: disabled,
							[styles.inputContainer__noFocusUnderline]:
								noFocusUnderline,
							[styles.inputContainer__noBorder]: noBorder,
						}
					)}
					style={{ overflow: "none", height: "100%" }}
				>
					<TextArea
						ref={forwardedRef}
						id={id}
						className={classNames(
							styles.input,
							inputProps?.className
						)}
						value={inputValue}
						placeholder={placeholder}
						autoFocus={autoFocus}
						autoSize={autoSize}
						style={style}
						onChange={handleChange}
						onKeyPress={handleKeyPress}
						maxLength={maxLength}
						onFocus={onFocus}
						onBlur={onBlur}
						data-cy={dataCy}
					/>
				</div>
				{!validity && (showError || error) && (
					<Typography
						className={styles.inputContainerError}
						variant="caption"
						color="red-contrast"
					>
						{showError || error}
					</Typography>
				)}
			</div>
		</div>
	);
}

type Props = {
	name: string;
	label?: string;
	wrapperProps?: HTMLProps<HTMLDivElement>;
} & HTMLProps<HTMLTextAreaElement>;

export const ValidationTextArea = ({
	label,
	name,
	wrapperProps,
	...props
}: Props): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	return (
		<div {...wrapperProps}>
			{label && (
				<Typography style={{ marginBottom: 8 }} variant="label" bold>
					{label}
				</Typography>
			)}
			<div
				className={classNames(styles.inputContainer, {
					[styles.inputContainer__error]: errors[name],
				})}
				style={{ minHeight: 58 }}
			>
				<textarea
					{...register(name)}
					{...props}
					style={{ width: "100%", height: "100%", ...props.style }}
					className={classNames(styles.input, props.className)}
				></textarea>
			</div>
			{errors[name] && (
				<Typography
					className={styles.inputContainerError}
					variant="caption"
					color="red-contrast"
				>
					{errors[name]?.message}
				</Typography>
			)}
		</div>
	);
};

export function TEXTAREA_DEMO(): JSX.Element {
	const [isValid, setValid] = useState(true);
	const handleValidityChange = (value: boolean) => {
		setValid(value);
	};

	const [text, setText] = useState("");
	const [text2, setText2] = useState("");
	const [text3, setText3] = useState("");
	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(e.target.value);
	};
	const handleChange2 = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText2(e.target.value);
	};
	const handleChange3 = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText3(e.target.value);
	};

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Basic usage" description="Basic usage of TextArea">
					<TextArea
						placeholder="The height of this TextArea is fixed"
						autoSize={false}
					/>
					<TextArea placeholder="The height of this TextArea will auto-resize" />
					<TextArea placeholder="Placeholder text" label="Label" />
				</Demo>
				<Demo
					title="Input validation"
					description="Detect errors using input validation."
				>
					<TextArea
						label="No text allowed"
						validators={[
							{
								validator: (val) => !val,
								error: "Text is not allowed here",
							},
						]}
						placeholder="Don't enter text here"
						value="Hello"
					/>
					<TextArea
						value={text}
						onChange={handleChange}
						label="UPPERCASE ONLY"
						placeholder="ENTER UPPERCASE LETTERS"
						validators={[
							{
								validator: (text) =>
									text === text.toUpperCase(),
								error: "ONLY UPPERCASE LETTERS",
							},
						]}
					/>
				</Demo>
				<Demo
					title="Event Listeners"
					description="Setup callback functions to listen to validity and input changes"
				>
					<h4>
						Input status:{" "}
						<span style={{ color: isValid ? "green" : "red" }}>
							{isValid ? "valid" : "invalid"}
						</span>
					</h4>
					<TextArea
						value={text2}
						onChange={handleChange2}
						label="Numbers only"
						placeholder="Enter a number"
						validators={[
							{
								validator: (text) => !isNaN(text),
								error: "Enter numbers only!",
							},
						]}
						onValidityChange={handleValidityChange}
					/>
					<h4>Current text: {text3}</h4>
					<TextArea
						label="onChange listener"
						placeholder="Enter some text"
						onChange={handleChange3}
					/>
				</Demo>
				<Demo
					title="Forced error state"
					description="Pass a string or boolean to the error prop to display an error"
				>
					<TextArea error={true} value="I have an error" />
					<TextArea error="This textarea has an error" />
					<TextArea error={false} value="No error here" />
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
