import { GenemodIcon, LayerSystemContainer, Typography } from "@components";
import cn from "classnames";
import React from "react";
import styles from "./AIButton.module.scss";

type AIButtonProps = {
	noCursor?: boolean;
	isVertical?: boolean;
	backgroundHover?: boolean;
	onClick?: () => void;
	active?: boolean;
	className?: string;
};

const AIButton: React.FunctionComponent<AIButtonProps> = ({
	noCursor,
	isVertical,
	backgroundHover,
	onClick,
	active,
	className,
}) => {
	return (
		<LayerSystemContainer
			onClick={onClick}
			backgroundHover={backgroundHover}
			className={cn(
				styles.aiButton,
				{ [styles.isVertical]: isVertical },
				{ [styles.active]: active },
				className
			)}
			contentEditable={false}
			style={{ cursor: noCursor ? "unset" : "pointer" }}
		>
			<GenemodIcon
				name="ai-icon"
				style={{ cursor: noCursor ? "unset" : "pointer" }}
			/>
			<Typography variant="caption" color="button-text">
				AI
			</Typography>
		</LayerSystemContainer>
	);
};

export default AIButton;
