import {
	Button,
	Demo,
	DemoSection,
	DemoWrapper,
	ExploreButton,
	GenemodIcon,
	Modal,
	Typography,
} from "@components";
import DRIVE_INTEGRATION_DARK from "@components/UpgradeModal/assets/drive-integration-dark.png";
import DRIVE_INTEGRATION_LIGHT from "@components/UpgradeModal/assets/drive-integration-light.png";
import FILTER_LIMIT_DARK from "@components/UpgradeModal/assets/filter-limit-dark.png";
import FILTER_LIMIT_LIGHT from "@components/UpgradeModal/assets/filter-limit-light.png";
import FREEZER_LIMIT_DARK from "@components/UpgradeModal/assets/freezer-limit-dark.png";
import FREEZER_LIMIT_LIGHT from "@components/UpgradeModal/assets/freezer-limit-light.png";
import ITEM_LIMIT_LIGHT from "@components/UpgradeModal/assets/item-limit-light.png";
import UPGRADE_ORDERS_DARK from "@components/UpgradeModal/assets/orders-upgrade-dark.png";
import UPGRADE_ORDERS_LIGHT from "@components/UpgradeModal/assets/orders-upgrade-light.png";
import PROTOCOL_LIMIT_DARK from "@components/UpgradeModal/assets/protocol-limit-dark.png";
import PROTOCOL_LIMIT_LIGHT from "@components/UpgradeModal/assets/protocol-limit-light.png";
import PROTOCOL_VERSION_LIMIT_DARK from "@components/UpgradeModal/assets/protocol-version-limit-dark.png";
import PROTOCOL_VERSION_LIMIT_LIGHT from "@components/UpgradeModal/assets/protocol-version-limit-light.png";
import SPACE_LIMIT_DARK from "@components/UpgradeModal/assets/space-limit-dark.png";
import SPACE_LIMIT_LIGHT from "@components/UpgradeModal/assets/space-limit-light.png";
import TEMPLATE_LIMIT_DARK from "@components/UpgradeModal/assets/template-limit-dark.png";
import TEMPLATE_LIMIT_LIGHT from "@components/UpgradeModal/assets/template-limit-light.png";
import USER_LIMIT_DARK from "@components/UpgradeModal/assets/user-limit-dark.png";
import USER_LIMIT_LIGHT from "@components/UpgradeModal/assets/user-limit-light.png";
import { useCurrentPlanNameHook } from "@helpers/Hooks/featureRestrictionHook";
import { flattenUnion } from "@helpers/TypeHelpers";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import React, { useState } from "react";
import { useTheme } from "../Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "../Theme/context/ThemeTypes";
import styles from "./UpgradeModal.module.scss";
import PROJECT_LIMIT_IMG from "./assets/project-limit-light.png";

// Common strings
export const YOUR_REACHED_LIMIT_MSG = "You have reached the limit.";
export const ENTERPRISE_RESTRICTION_MSG = "See subscription details.";
const CONTACT_US_TO_EXPLORE =
	"Contact us to explore flexible packaging options that meet your R&D needs.";

// Since most modals share these fields, making a common object to spread
const COMMON_TYPE_DATA = {
	secondLine: CONTACT_US_TO_EXPLORE,
	enterpriseSecondLine: ENTERPRISE_RESTRICTION_MSG,
} as const;

/**
 * Display data for each modal type
 */
export const UPGRADE_MODAL_TYPE_DATA = {
	SEARCH_FILTER: {
		imgSrcLight: FILTER_LIMIT_LIGHT,
		imgSrcDark: FILTER_LIMIT_DARK,
		firstLine: "Upgrade to access filters",
	},
	PROJECT: {
		imgSrcLight: PROJECT_LIMIT_IMG,
		imgSrcDark: PROJECT_LIMIT_IMG,
		firstLine: "You’ve reached the project limit",
		...COMMON_TYPE_DATA,
	},
	FREEZER: {
		imgSrcLight: FREEZER_LIMIT_LIGHT,
		imgSrcDark: FREEZER_LIMIT_DARK,
		firstLine: "You’ve reached the freezer limit",
		...COMMON_TYPE_DATA,
	},
	TEMPLATE: {
		imgSrcLight: TEMPLATE_LIMIT_LIGHT,
		imgSrcDark: TEMPLATE_LIMIT_DARK,
		firstLine: "You’ve reached the custom template limit",
		...COMMON_TYPE_DATA,
	},
	TEMPLATE_NO_ACCESS: {
		imgSrcLight: TEMPLATE_LIMIT_LIGHT,
		imgSrcDark: TEMPLATE_LIMIT_DARK,
		firstLine: "Upgrade to unlock custom templates",
		...COMMON_TYPE_DATA,
	},
	SPACE: {
		imgSrcLight: SPACE_LIMIT_LIGHT,
		imgSrcDark: SPACE_LIMIT_DARK,
		firstLine: "You’ve reached the space limit",
		...COMMON_TYPE_DATA,
	},
	PROTOCOL: {
		imgSrcLight: PROTOCOL_LIMIT_LIGHT,
		imgSrcDark: PROTOCOL_LIMIT_DARK,
		firstLine: "You’ve reached the protocol limit",
		...COMMON_TYPE_DATA,
	},
	PROTOCOL_VERSION: {
		imgSrcLight: PROTOCOL_VERSION_LIMIT_LIGHT,
		imgSrcDark: PROTOCOL_VERSION_LIMIT_DARK,
		firstLine: "You’ve reached the protocol version limit",
		...COMMON_TYPE_DATA,
	},
	TEAM_SIZE: {
		imgSrcLight: USER_LIMIT_LIGHT,
		imgSrcDark: USER_LIMIT_DARK,
		firstLine: "You’ve reached the user limit",
		...COMMON_TYPE_DATA,
	},
	USER_ROLES: {
		imgSrcLight: USER_LIMIT_LIGHT,
		imgSrcDark: USER_LIMIT_DARK,
		firstLine: "Upgrade to access user roles",
		...COMMON_TYPE_DATA,
	},
	ITEMS: {
		imgSrcLight: ITEM_LIMIT_LIGHT,
		imgSrcDark: ITEM_LIMIT_LIGHT,
		firstLine: "You've reached the item limit",
		...COMMON_TYPE_DATA,
	},
	GOOGLE_DRIVE: {
		imgSrcLight: DRIVE_INTEGRATION_LIGHT,
		imgSrcDark: DRIVE_INTEGRATION_DARK,
		firstLine: "Upgrade to access Google Drive integration",
		...COMMON_TYPE_DATA,
	},
	UNLOCK_ORDERS: {
		imgSrcLight: UPGRADE_ORDERS_LIGHT,
		imgSrcDark: UPGRADE_ORDERS_DARK,
		firstLine: "Upgrade to access orders for your inventory",
		...COMMON_TYPE_DATA,
	},
} as const;

export type UpgradeModalType = keyof typeof UPGRADE_MODAL_TYPE_DATA;

type UpgradeModalDemoOnlyProps = {
	demoIsEnterprise?: boolean;
};

export default function UpgradeModal(
	demoOverrides: UpgradeModalDemoOnlyProps
): JSX.Element {
	const { planName } = useCurrentPlanNameHook();
	const [theme] = useTheme();
	const { isUpgradeModalVisible, upgradeModalData, closeUpgradeModal } =
		useCommonModalState("upgradeModal");
	const { type = "FREEZER" } = upgradeModalData || {};

	const {
		imgSrcLight,
		imgSrcDark,
		firstLine,
		secondLine,
		enterpriseSecondLine,
	} = flattenUnion(UPGRADE_MODAL_TYPE_DATA[type]);

	const isEnterprise =
		planName === "enterprise" || demoOverrides.demoIsEnterprise;

	const secondLineDisplay =
		(isEnterprise && enterpriseSecondLine) ||
		secondLine ||
		COMMON_TYPE_DATA.secondLine;

	return (
		<Modal
			className={styles.upgradeModal}
			visible={isUpgradeModalVisible}
			onCancel={closeUpgradeModal}
			closeIcon={
				<div className={styles.closeIcon} data-cy="close-upgrade-modal">
					<GenemodIcon
						name="exit"
						size="large"
						stroke={"text-secondary"}
						onClick={closeUpgradeModal}
					/>
				</div>
			}
			width={380}
			footer={null}
			dataCy="upgrade-account"
		>
			<img
				className={styles.upgradeImage}
				src={
					theme === THEME_PREFERENCE.light ? imgSrcLight : imgSrcDark
				}
				alt={firstLine}
			/>
			<div className={styles.content}>
				<Typography variant="headline" bold>
					{firstLine}
				</Typography>
				<Typography
					variant="label"
					style={{ marginTop: 16, marginBottom: 24 }}
				>
					{secondLineDisplay}
				</Typography>
				<ExploreButton stretch onClick={() => closeUpgradeModal()} />
			</div>
		</Modal>
	);
}

export function UPGRADEMODAL_DEMO(): JSX.Element {
	const { openUpgradeModal } = useCommonModalState("upgradeModal");

	const [isEnterprise, setIsEnterprise] = useState(false);

	const onClick = (type: UpgradeModalType) => () => {
		setIsEnterprise(false);
		openUpgradeModal({ type });
	};
	const onClickForEnterprise = (type: UpgradeModalType) => () => {
		setIsEnterprise(true);
		openUpgradeModal({ type });
	};

	const modals = Object.keys(UPGRADE_MODAL_TYPE_DATA).map(
		(type) => type as UpgradeModalType
	);

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Upgrade Modals"
					description="Click buttons to see each type of modal"
				>
					{modals.map((type) => (
						<Button
							key={type}
							onClick={onClick(type)}
							style={{ marginTop: 16 }}
						>
							{type}
						</Button>
					))}
				</Demo>
				<Demo
					title="Upgrade Modals when on enterprise plan"
					description="Click buttons to see each type of modal"
				>
					{modals.map((type) => (
						<Button
							key={type}
							onClick={onClickForEnterprise(type)}
							style={{ marginTop: 16 }}
						>
							Enterprise {type}
						</Button>
					))}
				</Demo>
				<UpgradeModal demoIsEnterprise={isEnterprise} />
			</DemoSection>
		</DemoWrapper>
	);
}
