import React, { useState, useEffect, useRef, useMemo } from "react";
import { Notification } from "@components";
import { ToolbarBtn } from "../../components";
import {
	Editor,
	Node as SlateNode,
	Element as SlateElement,
	Transforms,
	Text,
	Node,
	Range,
	Path,
	BaseRange,
} from "slate";
import { ReactEditor } from "slate-react";
import { Input, Modal } from "@components";
import isUrl from "is-url";
import styles from "./index.module.scss";
import { useGenemodSlate } from "@common/components/Editor/hooks/UseGenemodSlateHook";
import { CustomText, RenderedEditor } from "../../types";
import { PM_EDITOR_ID } from "../../components/Editor";
import { useSafeWindowEventListener } from "@helpers/Hooks";
import { ColorCssVar } from "@common/styles/Colors";
import { useCommonModalState } from "@redux/CommonModals/hooks";

export type LinkEditor = RenderedEditor & {
	/**
	 * Return neighborinLinkRange
	 */
	getNeighboringLinkRange: () => BaseRange | undefined;
	/**
	 * Set selection of editor with neighboringLinkRange
	 */
	selectNeighboringLink: () => void;
};
export function withLinkFormat<T extends LinkEditor>(editor: T): T {
	/**
	 * get edge path which have the same href
	 * @param href
	 * @param basePath
	 * @param dir direction to find the path
	 * @param min min path
	 * @param max max path
	 */
	const getEdgePath = (
		href: string,
		basePath: Path,
		dir: "forward" | "backward",
		min: number,
		max: number
	): Path => {
		const nextPath = basePath.slice(0, -1);
		const nextPoint =
			basePath[basePath.length - 1] + (dir === "forward" ? 1 : -1);
		nextPath.push(nextPoint);

		if (nextPoint < min || nextPoint > max) {
			return basePath;
		}
		const [nextNode] = Editor.node(editor, nextPath);

		if (!Text.isText(nextNode) || nextNode.href !== href) return basePath;

		return getEdgePath(href, nextPath, dir, min, max);
	};

	editor.getNeighboringLinkRange = () => {
		const { selection } = editor;
		if (!selection) return;
		const [linkNode, linkPath] = Editor.node(editor, selection.anchor.path);
		const currentHref = Text.isText(linkNode) ? linkNode.href : undefined;
		if (!currentHref) {
			return;
		}
		const [parentNode] = Editor.parent(editor, linkPath);
		const [min, max] = [0, parentNode.children.length - 1];
		const start = getEdgePath(currentHref, linkPath, "backward", min, max);
		const end = getEdgePath(currentHref, linkPath, "forward", min, max);
		return {
			anchor: Editor.point(editor, start, { edge: "start" }),
			focus: Editor.point(editor, end, { edge: "end" }),
		};
	};

	editor.selectNeighboringLink = () => {
		const linkRange = editor.getNeighboringLinkRange();
		if (!linkRange) return;
		Transforms.select(editor, linkRange);
	};

	const { normalizeNode } = editor;
	editor.normalizeNode = (entry) => {
		const [node, path] = entry;
		// Migration Link format to mark from Node
		if (SlateElement.isElement(node) && (node.type as any) === "link") {
			const linkProps = Node.extractProps(node);
			if (Object.keys(linkProps).length) {
				Transforms.setNodes(
					editor,
					{ href: (node as any).url },
					{ at: path, match: Text.isText, mode: "lowest" }
				);
				Transforms.unsetNodes(editor, Object.keys(linkProps), {
					at: path,
				});
				Transforms.liftNodes(editor, {
					at: path,
					mode: "lowest",
					match: Text.isText,
				});
			}
			return;
		}
		/** if link text is empty, unset all the props in the Link text */
		if (Text.isText(node) && !!node.href && !node.text) {
			const textProps = Node.extractProps(node);
			if (Object.keys(textProps).length) {
				Transforms.unsetNodes(editor, Object.keys(textProps), {
					at: path,
				});
				return;
			}
		}
		normalizeNode(entry);
	};

	return editor;
}

export function InsertLinkModal(): JSX.Element {
	const [displayText, setText] = useState<string>("");
	const [disabledDisplayText, setDisabledDisplayText] = useState(false);
	const [link, setLink] = useState<string>("");
	const [error, setError] = useState<string | null>(null);

	const { isEditorLinkModalVisible, closeEditorLinkModal } =
		useCommonModalState("editorLinkModal");
	const editor = useGenemodSlate();
	const [linkEntry] = Editor.nodes(editor, {
		match: (n) => Text.isText(n) && !!n.href,
	});
	const linkNode = linkEntry?.[0] as CustomText | undefined;

	useEffect(() => {
		if (isEditorLinkModalVisible) {
			let href = "";
			let text = "";
			if (linkNode) {
				editor.selectNeighboringLink();
				if (!editor.selection) return;
				text = Editor.string(editor, editor.selection);
				href = linkNode.href || "";
				setDisabledDisplayText(false);
			} else if (editor.getFragment()[0]) {
				text = SlateNode.string(editor.getFragment()[0]);
				setDisabledDisplayText(!!text);
			}
			setText(text);
			setLink(href);
			setError(null);
		} else {
			try {
				ReactEditor.focus(editor);
				// eslint-disable-next-line no-empty
			} catch (e) {}
		}
	}, [isEditorLinkModalVisible]);

	const onSubmit = (e?: React.KeyboardEvent<HTMLInputElement>) => {
		e?.preventDefault();
		if (!editor.selection) return;
		const withHttp = `http://${link}`;
		const validLink =
			(isUrl(link) && link) || (isUrl(withHttp) && withHttp);
		if (!validLink) {
			setError("Enter a valid URL");
			return;
		}
		const currentText = Editor.string(editor, editor.selection);
		const currentLink = linkNode?.href;

		if (!displayText || displayText !== currentText) {
			Editor.deleteFragment(editor);
			Transforms.insertFragment(editor, [
				{
					text: displayText || validLink,
					href: validLink,
					underline: editor.getMarkValue("underline") || true,
					color:
						editor.getMarkValue("color") ||
						("var(--color-2B)" as ColorCssVar),
				},
			]);
		} else if (validLink !== currentLink) {
			editor.toggleMark("href", validLink);
			if (!linkNode) {
				editor.toggleMark("underline", true);
				editor.toggleMark("color", "var(--color-2B)" as ColorCssVar);
			}
		}
		closeEditorLinkModal();
	};

	return (
		<Modal
			visible={isEditorLinkModalVisible}
			onOk={() => onSubmit()}
			onCancel={() => closeEditorLinkModal()}
			title="Insert link"
			okText="Apply"
			okButtonProps={{ disabled: !link || !!error }}
			hideCancelButton
		>
			<Input
				label="Display text"
				value={displayText}
				onChange={(e) => setText(e.target.value)}
				disabled={disabledDisplayText}
			/>
			<Input
				label="URL*"
				value={link}
				autoFocus
				onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
					e.currentTarget.select()
				}
				error={error}
				onPressEnter={onSubmit}
				onChange={(e) => {
					setError(null);
					setLink(e.target.value);
				}}
			/>
		</Modal>
	);
}

const DEFAULT_RECT = {
	x: 0,
	y: 0,
	width: 1,
	height: 1,
	top: 0,
	left: 0,
};

export function HangingLinkToolbar() {
	const { openEditorLinkModal } = useCommonModalState("editorLinkModal");
	const ref = useRef<HTMLDivElement | null>(null);
	const editor = useGenemodSlate();

	const linkPermission = editor.permissions.link;
	const href = editor.getMarkValue("href");
	const neighboringLinkRange = useMemo(
		() => editor.getNeighboringLinkRange(),
		[editor.selection?.anchor]
	);
	const scroll = () => {
		const el = ref.current;
		const { selection } = editor;
		if (!el) return;

		if (
			!selection ||
			!href ||
			!neighboringLinkRange ||
			!Range.includes(neighboringLinkRange, selection.focus)
		) {
			el.style.display = "none";
			return;
		}
		const editorRect =
			el.closest("#" + PM_EDITOR_ID)?.getBoundingClientRect() ||
			DEFAULT_RECT;
		try {
			const domRange = ReactEditor.toDOMRange(
				editor,
				neighboringLinkRange
			);
			const selectionRect =
				domRange?.getBoundingClientRect() || DEFAULT_RECT;
			el.style.display = "block";
			el.style.top = `${selectionRect.bottom - editorRect.top + 8}px`;
			let left = selectionRect.left - editorRect.left;
			if (left + el.clientWidth > editorRect.width) {
				left = editorRect.width - el.clientWidth;
			}
			el.style.left = `${left}px`;
		} catch {
			/** if slate cannot convert SlateRange to DomRange */
			el.style.display = "none";
		}
	};

	useEffect(scroll);
	useSafeWindowEventListener("scroll", scroll, true);

	/** Copy the current url to the clipboard */
	const handleCopyLink = () => {
		if (!href) {
			return;
		}
		navigator.clipboard.writeText(href);
		Notification.success({ message: "Link copied to clipboard." });
	};
	/** remove the current link */
	const handleRemoveLink = () => {
		const { selection } = editor;
		if (!selection) return;

		if (Range.isCollapsed(selection)) {
			editor.selectNeighboringLink();
			if (!editor.selection) return;
		}
		Transforms.unsetNodes(editor, ["href", "color", "underline"], {
			match: (n) => Text.isText(n) && !!n.href,
			split: true,
		});
	};
	return (
		<div
			ref={ref}
			className={styles.hoveringToolbar}
			onMouseDown={(e) => e.preventDefault()}
		>
			<div className={styles.toolbarContent}>
				<div className={styles.link} onClick={() => window.open(href)}>
					{href}
				</div>
				<div className={styles.linkButtons}>
					<ToolbarBtn
						icon="copy-link"
						description="Copy link"
						onClick={handleCopyLink}
					/>
					{linkPermission && (
						<>
							<ToolbarBtn
								icon="remove-link"
								description="Remove link"
								onClick={handleRemoveLink}
							/>
							<ToolbarBtn
								icon="edit"
								description="Edit link"
								onClick={() => openEditorLinkModal()}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
