import React, { useState } from "react";
import { Typography, GenemodIcon } from "@components";
import { InfoSectionDivider } from "@containers/Freezer/table/components/CellInfo/CellInfo";
import styles from "./styles.module.scss";
import cn from "classnames";

type FormSectionProps = {
	defaultCollapsed?: boolean;
	dividerBefore?: boolean;
	dividerAfter?: boolean;
	header?: boolean;
	sectionTitle?: string;
	children?: any;
	style?: React.CSSProperties;
};

/**
 * The component which is collapsible section designed as list view
 *
 * @param {*} props
 * @param {Boolean} [props.defaultCollapsed = false] - wheather the section is collapsed or not at first.
 * @param {Boolean} dividerBefore
 * @param {Boolean} dividerAfter
 * @param {Boolean} header
 * @param {String} sectionTitle
 */
function FormSection({
	defaultCollapsed,
	dividerBefore,
	dividerAfter,
	header,
	sectionTitle,
	children,
}: FormSectionProps) {
	const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
	return (
		<>
			{dividerBefore && (
				<InfoSectionDivider
					className={header ? "divider-header" : ""}
				/>
			)}
			<div className={styles.formSection}>
				{sectionTitle && (
					<div
						className={cn(
							styles.formSectionTitleWrapper,
							"formSectionTitleWrapper"
						)}
						style={{
							userSelect: "none",
						}}
						data-cy={sectionTitle.toLowerCase().replace(" ", "-")}
					>
						<Typography
							variant="headline5"
							className={styles.formSectionTitle}
							color="text-primary-v2"
							bold
						>
							{sectionTitle}
						</Typography>
					</div>
				)}
				{children}
			</div>
			{dividerAfter && <InfoSectionDivider />}
		</>
	);
}

/**
 * Renders form section but also adds item grid div.
 */
export function FormItemsSection(props: FormSectionProps) {
	return (
		<FormSection
			{...{
				...props,
				children: null,
			}}
		>
			<div className={styles.sectionItemsContainer} style={props.style}>
				{props.children}
			</div>
		</FormSection>
	);
}
