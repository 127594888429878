export type UUID = string;

export const CURRENCY_DEF = "USD";
export const CURRENCY_TYPES = Object.freeze({
	USD: 0,
	EUR: 1,
	JPY: 2,
	GBP: 3,
	AUD: 4,
	CAD: 5,
	CHF: 6,
	CNH: 7,
	BRL: 8,
	0: "$",
	1: "€",
	2: "¥",
	3: "£",
	4: "$",
	5: "$",
	6: "Fr.",
	7: "¥",
	8: "R$",
} as const);
export const CURRENCY_DEFAULT_ID = CURRENCY_TYPES[CURRENCY_DEF];
