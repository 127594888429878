import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Location } from "history";
import { useLocation, useHistory } from "@common/helpers/Hooks/UseRouterDom";
import LZString from "lz-string";

/**
 * Converts a parameter string to a number. Returns null otherwise.
 */
export function paramToNumber(param: string | null): number | null {
	if (!param) return null;
	const convertedValue = parseInt(param);
	return isNaN(convertedValue) ? null : convertedValue;
}

/**
 * Converts a parameter string to a list. Expects the parameter string
 * to be a comma separated list of values.
 *
 * Returns an empty list if the parameter is undefined or invalid.
 * Non-numeric values are discarded.
 */
export function paramToList(param: string | null, type: "string"): string[];
export function paramToList(param: string | null, type: "number"): number[];
export function paramToList(
	param: string | null,
	type: "string" | "number" = "number"
): string[] | number[] {
	if (!param) return [];
	const list = param.split(",");
	if (type === "number") {
		return list
			.filter((x) => !isNaN(parseInt(x)))
			.map((x) => parseInt(x)) as number[];
	} else {
		return list as string[];
	}
}

/**
 * Converts a parameter string to a moment. Returns null if invalid or undefined.
 */
export function paramToMoment(param: string | null): moment.Moment | null {
	if (!param) return null;
	const convertedValue = moment(param);
	return moment.isMoment(convertedValue) ? convertedValue : null;
}

export function useMemoizedURLSearchParams() {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	return useMemo(() => params, [location.search]);
}

export function useEffectParams(
	callback: (changedParams: string[]) => void,
	extraDependencies: React.DependencyList = []
) {
	const location = useLocation();
	const getParamObject = () => {
		const params = new URLSearchParams(location.search);
		const result: Record<string, string> = {};
		params.forEach((value, key) => (result[key] = value));
		return result;
	};

	const params = useMemoizedURLSearchParams();
	const prevParams = useRef<Record<string, string>>(getParamObject());

	useEffect(() => {
		// Check for changed params
		const currentParams = getParamObject();
		const prevParamList = Object.keys(prevParams.current);
		const currentParamList = Object.keys(currentParams);
		const allParams = [...new Set([...prevParamList, ...currentParamList])];

		const changedParams = allParams.reduce<string[]>(
			(changed, paramName) => {
				if (
					prevParams.current[paramName] === undefined ||
					currentParams[paramName] === undefined ||
					prevParams.current[paramName] !== currentParams[paramName]
				) {
					changed.push(paramName);
				}
				return changed;
			},
			[]
		);

		// Update the prevParams
		prevParams.current = currentParams;
		return callback(changedParams);
	}, [params, ...extraDependencies]);
}

type ParamOptions = {
	defaultValue: string;
	/**
	 * Validates parameter values. Returns true
	 * if the value is valid. If false, sets the parameter
	 * to `defaultValue`.
	 * */
	validator?: (paramValue: string, api: UseParamsAPI) => boolean;
};

type GetWithOptions =
	| {
			type: "number";
			fallbackValue: number;
	  }
	| {
			type: "string";
			fallbackValue: string;
	  }
	| {
			type: "numberlist";
			fallbackValue: Array<number>;
	  }
	| {
			type: "stringlist";
			fallbackValue: Array<string>;
	  }
	| {
			type: "moment";
			fallbackValue: moment.Moment;
	  };

type UseParamsAPI = {
	/** Sets a single param and updates the url. if replace, replace history*/
	setParam: (param: string, value: string, replace?: boolean) => void;
	/** Deletes a single param and updates the url. if replace, replace history */
	deleteParam: (param: string, replace?: boolean) => void;
	/** Updates the URL using the URLSearchParams. if replace, replace history*/
	updateUrl: (param: URLSearchParams, replace?: boolean) => void;
	/** Get the value of a specific key param */
	getParam: (param: string) => string | null;
	resetParams: () => void;
	/** The current URLSearchParams */
	params: URLSearchParams;
	/**
	 * Helper function for fetching a URLSearchParam with
	 * type conversions and a fallback value.
	 * */
	getParamWithOptions: <Options extends GetWithOptions>(
		param: string,
		options: Options
	) => Options["fallbackValue"];

	/**
	 * Helper method for creating an object from a list of params.
	 * Null values are not included in the returned object.
	 */
	getParamsFromList: <ParamList extends string[]>(
		paramList: ParamList
	) => Partial<Record<ParamList[number], string>>;

	/**
	 * True if params are missing or invalid. Typically used
	 * to prevent API calls until all required/invalid params are fixed.
	 * */
	invalidParams: boolean;
	updateMultipleParamOnURL: (
		param: string,
		value: number,
		add: boolean
	) => void;
	resetParamsOnURL: (params: string[]) => void;
	setParamOnURL: (param: string, value: string | null) => void;
};

/**
 * Updates the URL with required params. `params` is a mapping from
 * parameter name to an options object
 */
export function useParams(
	requiredParams: Record<string, ParamOptions> = {}
): UseParamsAPI {
	const params = useMemoizedURLSearchParams();
	const location = useLocation();
	const history = useHistory();

	const api = useMemo(() => {
		const updateUrl = (params: URLSearchParams, replace = false) => {
			const newLocation = {
				pathname: location.pathname,
				search: params.toString(),
			};
			if (replace) {
				history.replace(newLocation);
			} else {
				history.push(newLocation);
			}
		};
		const setParam = (param: string, value: string, replace = false) => {
			params.set(param, value);
			updateUrl(params, replace);
		};
		const deleteParam = (param: string, replace = false) => {
			params.delete(param);
			updateUrl(params, replace);
		};

		const getParam = (param: string) => params.get(param);

		const resetParams = () => updateUrl(new URLSearchParams());

		/**
		 * Add helper functions to the params object for automatically converting
		 * between types. See `GetWithOptions` for supported types.
		 *
		 * Lists are expected to be comma separated values in the URL search.
		 */
		const getParamWithOptions = (
			param: string,
			options: GetWithOptions
		) => {
			const value = params.get(param);
			if (value === null) return options.fallbackValue;

			if (options.type === "string") {
				return value;
			} else if (options.type === "number") {
				// If failed to convert, it's not our fault. This should be covered in the validator function :)
				return parseInt(value);
			} else if (options.type === "stringlist") {
				return value.split(",");
			} else if (options.type === "numberlist") {
				return value.split(",").map((x) => parseInt(x));
			} else {
				// Type must be moment
				return moment(value);
			}
		};

		const getParamsFromList = <ParamList extends string[]>(
			paramList: ParamList
		): Partial<Record<ParamList[number], string>> => {
			return paramList.reduce((paramObj, param) => {
				const value = params.get(param);
				if (value !== null) {
					paramObj[param as ParamList[number]] = value;
				}
				return paramObj;
			}, {} as Partial<Record<ParamList[number], string>>);
		};

		const invalidParams = Object.keys(requiredParams).reduce(
			(invalid, param) => {
				return invalid || params.get(param) === null;
			},
			false
		);

		const resetParamsOnURL = (params: string[]) => {
			const copyURL = new URLSearchParams(location.search);
			params.forEach((param) => {
				copyURL.delete(param); // Remove the specified parameter
			});
			updateUrl(copyURL);
		};

		const updateMultipleParamOnURL = (
			param: string,
			value: number,
			add: boolean
		) => {
			const copyURL = new URLSearchParams(location.search);
			let values: any[] = [];

			// Get the current values from the URL, if any
			const currentValue = copyURL.get(param);
			if (currentValue) {
				values = currentValue.split(",").map(Number);
			}

			if (add) {
				// Add the value if it's not already present
				if (!values.includes(value)) {
					values.push(value);
				}
				if (param === "located_in" && value === -1) {
					values = [-1];
				}
			} else {
				// Remove the value if it's present
				values = values.filter((val) => val !== value);
			}

			// Update the URL parameter
			if (values.length > 0) {
				setParam(param, values.join(","));
			} else {
				deleteParam(param);
			}
		};

		const setParamOnURL = (param: string, value: string | null) => {
			if (!value) {
				deleteParam(param);
			} else {
				setParam(param, value);
			}
		};

		return {
			updateUrl,
			setParam,
			deleteParam,
			params,
			getParamWithOptions,
			getParamsFromList,
			getParam,
			resetParams,
			invalidParams,
			resetParamsOnURL,
			updateMultipleParamOnURL,
			setParamOnURL,
		};
	}, [params, location.pathname]);

	useEffectParams(() => {
		let update = false;
		Object.entries(requiredParams).map(([param, options]) => {
			// Set the param to the defaultValue is missing or invalid
			const currentValue = params.get(param);
			if (
				currentValue === null ||
				options.validator?.(currentValue, api) === false
			) {
				update = true;
				params.set(param, options.defaultValue);
			}
		});
		if (update) {
			history.replace({
				pathname: location.pathname,
				search: params.toString(),
			});
		}
	}, [api]);

	return api;
}

// TODO: merge below effects with above?

/**
 * Checks if the search params has an "edit" key.
 * Eg: /app/freezer/consumables/spaces/1/furnitures/1/categories/1?edit
 */
export const useUrlIsEditMode = () => {
	return useSearchBooleanAndSet("edit");
};

/**
 * Returns the current url search parameters as a map.
 */
export function useSearchParams<T extends Record<string, string | number>>() {
	const params = new URLSearchParams(useLocation().search);
	const result: any = {};
	params.forEach(
		(value, key) => (result[key] = isNaN(+value) ? value : +value)
	);

	return result as Partial<T>;
}

// Params can either be encoded in the "search" portion of a url: ?A=0&B=1
// or in the "hash" portion #A=0&B=1
const getParams = (location: Location, useHash: boolean) =>
	new URLSearchParams(
		useHash ? `?${location.hash.substring(1)}` : location.search
	);

type ExtraOptions = {
	useHash?: boolean;
};
/**
 * Returns a url search param and provides a function to update it.
 *
 * If setValue receieves a null it will delete the parameter.
 *
 * When running multiple updates inside of an event you may need to
 * wrap it in ReactDOM.unstable_batchedUpdates()
 */
export function useSearchParamAndSet<T extends string = string>(
	parameterName: string,
	options: ExtraOptions = {}
) {
	const intOptions = {
		useHash: false,
		...options,
	};

	const history = useHistory();
	const location = useLocation();
	const { resetParams } = useParams();
	const params = getParams(location, intOptions.useHash);
	const value: string | number | null = params.get(parameterName);

	const setValue = useCallback(
		(
			value: T | null,
			replaceNotPushHistory = true,
			resetUrlParams = false
		) => {
			if (resetUrlParams) {
				resetParams();
			}
			// Note: we need to use history.location to get the most up to date value so when we do multiple
			// "set" calls synchronously we do not overwrite the new parameters.
			const location = history.location;
			const params = getParams(location, intOptions.useHash);
			const currentValue = params.get(parameterName);
			const nextValue = value === null ? null : value + "";
			if (nextValue === currentValue) return;
			if (nextValue === null) {
				params.delete(parameterName);
			} else {
				params.set(parameterName, nextValue);
			}
			const historyFn = replaceNotPushHistory
				? history.replace
				: history.push;
			historyFn({
				pathname: location.pathname,
				search: params.toString().replace(/=$|=(?=&)/g, ""), // replaces unnecessary equals sign
			});
		},
		[parameterName, history]
	);

	return [value as T | null, setValue] as const;
}

export function useSearchNumericAndSet<T extends number = number>(
	parameterName: string,
	options?: ExtraOptions
) {
	const [value, setValue] = useSearchParamAndSet(parameterName, options);
	const parsedValue = value === null || isNaN(+value) ? value : +value; // try to convert to nmber if possible
	const newSetVal = useCallback(
		(v: T | null, replaceNotPushHistory = true) =>
			setValue(v === null ? null : v + "", replaceNotPushHistory),
		[setValue]
	);
	return [parsedValue as T | null, newSetVal] as const;
}

/**
 * Returns whether a parameter is in the search parameters of the url and provides a set function
 */
export function useSearchBooleanAndSet(
	parameterName: string,
	options?: ExtraOptions
) {
	const [value, setValue] = useSearchParamAndSet<string>(
		parameterName,
		options
	);
	const newSetVal = useCallback(
		(newValue: boolean) => setValue(newValue ? "" : null),
		[setValue]
	);
	return [
		value !== null, // value is either 0 for true or null for false
		newSetVal,
	] as const;
}

/**
 * Returns a list of strings from a comma separated list in the url search parameters
 */
export function useSearchStringListAndSet(
	parameterName: string,
	options?: ExtraOptions
) {
	const [value, setValue] = useSearchParamAndSet(parameterName, options);
	const newSetVal = useCallback(
		(newValue: string[]) =>
			setValue(newValue.length ? newValue.join(",") : null),
		[setValue]
	);
	return [value?.split(",") || [], newSetVal] as const;
}

/**
 * Returns a list of numbers from a comma separated list in the url search parameters
 */
export function useSearchNumericListAndSet(
	parameterName: string,
	options?: ExtraOptions
) {
	const [value, setValue] = useSearchStringListAndSet(parameterName, options);

	// const [value, setValue] = useSearchParamAndSet(parameterName, options);
	const newSetVal = useCallback(
		(newValue: number[]) => setValue(newValue.map((v) => v + "")),
		[setValue]
	);
	return [value?.map((val) => Number(val)) || [], newSetVal] as const;
}

/**
 * Takes a value of any type and returns a base64-encoded string with URL-safe characters.
 */
function compressValueForUri(value: any): string {
	return LZString.compressToEncodedURIComponent(JSON.stringify(value));
}

/**
 * Takes a base64-encoded URL-safe string and returns the decoded value.
 */
function decompressValueFromUri(encodedValue: string): any {
	const decodedValue =
		LZString.decompressFromEncodedURIComponent(encodedValue);
	return decodedValue && JSON.parse(decodedValue);
}

const BASE_64_PARAM_KEY = "p";

const decodeLocationSearch = <T>(location: Location, lastValue: T | null) => {
	const params = new URLSearchParams(location.search);
	const encodedValue = params.get(BASE_64_PARAM_KEY);
	if (encodedValue) {
		try {
			const decodedValue = decompressValueFromUri(encodedValue);
			if (decodedValue === lastValue) {
				return lastValue;
			}
			return decodedValue;
		} catch (error) {
			console.warn(`Error decoding base64 encoded value: ${error}`);
		}
	}
	return null;
};

/**
 * The setBase64QueryParam function sets the URL parameter value by encoding it with encodeBase64Url,
 * replacing any disallowed characters with hyphens, and then updating the browser's URL with history.push.
 * The hook also replaces hyphens with the original disallowed characters when decoding the URL parameter
 * value with decodeBase64Url.
 *
 * Note: does not set url to the intitialValue if the url does not have a p param
 */
export function useEncodedQueryParam<T>(
	initialValue: T | null
): [T | null, (value: T | null) => void] {
	const location = useLocation();
	const history = useHistory();
	const [state, setState] = useState<T | null>(() => {
		return decodeLocationSearch(location, null) || initialValue;
	});

	useEffect(() => {
		setState((state) => decodeLocationSearch(location, state));
	}, [location]);

	const setBase64QueryParam = (value: T | null, pushNotReplace = true) => {
		const params = new URLSearchParams();
		if (value) {
			const encodedValue = compressValueForUri(value);
			params.set("p", encodedValue);
		} else {
			params.delete("p");
		}
		const newHistoryState = {
			search: params.toString(),
		};
		if (pushNotReplace) {
			history.push(newHistoryState);
		} else {
			history.replace(newHistoryState);
		}
		setState(value);
	};

	return [state, setBase64QueryParam];
}
