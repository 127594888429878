import React from "react";
import classNames from "classnames";
import styles from "../FreezerTab.module.scss";
import {
	Typography,
	DropDown,
	CustomDatePicker,
	Button,
	GenemodFilter,
} from "@common/components";
import moment from "moment";
import { Menu } from "antdv5";

type ExpiresOnColumnTitleProps = {
	expiresOnLte: string | null;
	setExpiresOnLte: (value: string | null) => void;
	expiresOnGte: string | null;
	setExpiresOnGte: (value: string | null) => void;
	ref?: any;
};

export const ExpiresOnColumnTitle = ({
	expiresOnGte,
	setExpiresOnGte,
	expiresOnLte,
	setExpiresOnLte,
	ref,
}: ExpiresOnColumnTitleProps) => {
	const filterApplied = expiresOnGte || expiresOnLte ? true : false;
	return (
		<div
			className={classNames(styles.columnHeader, "active-ordering")}
			ref={ref}
		>
			<Typography bold className="active-ordering">
				Expires on
			</Typography>
			<DropDown
				overlayClassName={styles.dropdown}
				overlay={
					<Menu className={classNames(styles.menu, styles.createdOn)}>
						<div className={styles.items}>
							<div className={styles.dateRange}>
								<CustomDatePicker
									value={
										expiresOnGte
											? moment(expiresOnGte)
											: undefined
									}
									placeholder="MM/DD/YYYY"
									labelText="From"
									className={styles.datepicker}
									labelStyle={{
										fontWeight: "bold",
									}}
									onChange={(val) => {
										setExpiresOnGte(
											val?.format("MM/DD/YYYY") || null
										);
									}}
								/>
								<div className={styles.line}></div>
								<CustomDatePicker
									value={
										expiresOnLte
											? moment(expiresOnLte)
											: undefined
									}
									placeholder="MM/DD/YYYY"
									labelText="To"
									className={styles.datepicker}
									labelStyle={{
										fontWeight: "bold",
									}}
									onChange={(val) => {
										setExpiresOnLte(
											val?.format("MM/DD/YYYY") || null
										);
									}}
								/>
							</div>
						</div>
						<div className={styles.reset}>
							<Button
								type="link"
								onClick={() => {
									setExpiresOnGte(null);
									setExpiresOnLte(null);
								}}
							>
								Reset
							</Button>
						</div>
					</Menu>
				}
				getPopupContainer={() => document.body}
			>
				<div style={{ marginLeft: "auto" }}>
					<GenemodFilter
						applied={filterApplied}
						wrapperClassName={
							!filterApplied ? "isOnRepository" : ""
						}
					/>
				</div>
			</DropDown>
		</div>
	);
};
