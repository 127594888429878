import { NominalId } from "@helpers/TypeHelpers";
import { FurnitureId, SpaceId, OrganizationId, Avatar, ISOString } from ".";

export type FurnitureCategoryId = NominalId<"FurnitureCategoryId">;

export type FurnitureCategory = {
	/** ID of category */
	id: FurnitureCategoryId;
	/** Name of the object */
	name: string;
	/** Description of category */
	description: string;
	/** Parent furniture ID */
	parent_furniture: FurnitureId;
	/** Parent space ID */
	parent_space: SpaceId;
	/** Organization id for the category */
	org: OrganizationId;
	/** Creator of the category */
	created_by: Avatar;
	/** Date when the category was created */
	created_at: ISOString;
	/** Most recent updated date */
	updated_at: ISOString;
	/** The user who recently updated */
	updated_by: Avatar;
	added_at: string;
};

export type FurnitureCategoryWithItemCount = {
	/** ID of category */
	id: FurnitureCategoryId;
	/** Name of the category */
	name: string;
	/** Number of items under the category */
	item_count: number;
};
