import React, { useEffect } from "react";
import styles from "./PreviewScaleWrapper.module.scss";

type PreviewScaleWrapperProps = {
	/**
	 * The height of an inner div that we first render the child into before scaling.
	 */
	virtualHeight: number;
	/**
	 * The width of an inner div that we first render the child into before scaling.
	 */
	virtualWidth: number;
	/**
	 * The max width this component should be after scaling the child.
	 * It can end up smaller depending on the child's width/height ratio
	 * and the numbers given in minimumScaling and maximumScaling.
	 */
	maxParentWidth: number;
	/**
	 * The max height this component should be after scaling the child.
	 * It can end up smaller depending on the child's width/height ratio
	 * and the numbers given in minimumScaling and maximumScaling.
	 */
	maxParentHeight: number;
	/**
	 * The smallest scaling value that it should have.
	 * Eg: "0.1" would mean it shouldn't be scaled smaller than 10%.
	 */
	minimumScaling?: number;
	/**
	 * The largest scaling value that it should have.
	 * Eg: "2" would mean it shouldn't be scaled larger than 200%.
	 */
	maximumScaling?: number;
	/**
	 * The child to be scaled. This assumes there is one root node for the child.
	 */
	children: React.ReactNode;
};

/**
 * Scales the given child using the css "scale" transform.
 * Currently a minimal implementation for use in moving cart preview scaling. Extend as needed.
 *
 * Note: This expects that the child has a single root node.
 */
export default function PreviewScaleWrapper({
	virtualHeight,
	virtualWidth,
	maxParentWidth,
	maxParentHeight,
	minimumScaling = 99999, // really big value so it will probably never be min
	maximumScaling = -1,
	children,
}: PreviewScaleWrapperProps) {
	useEffect(() => {
		const wrapperElem = document.querySelector(`.${styles.wrapper}`);
		const innerElem = wrapperElem?.querySelector(`.${styles.inner}`);

		// Grab the first valid child. We assume there is only one child for simplicity sake.
		const childElem =
			innerElem &&
			Array.from(innerElem.children).find(
				(child) => child instanceof HTMLElement
			);

		if (
			!(wrapperElem instanceof HTMLElement) ||
			!(childElem instanceof HTMLElement)
		)
			return;

		// Get size of child
		const { width: widthStr, height: heightStr } =
			window.getComputedStyle(childElem);
		const width = parseFloat(widthStr);
		const height = parseFloat(heightStr);

		// Calculate scale for the child component
		const scale = Math.max(
			minimumScaling,
			Math.min(
				maximumScaling,
				maxParentWidth / width,
				maxParentHeight / height
			)
		);

		// Scale the child component
		childElem.style.scale = scale + "";
		childElem.style.transformOrigin = "top left";

		// Set the size of the wrapper that the the parent of this component can know the correct width/height for things like borders
		wrapperElem.style.width = `${width * scale}px`;
		wrapperElem.style.height = `${height * scale}px`;
	});

	return (
		<div className={styles.wrapper}>
			<div
				className={styles.inner}
				style={{ height: virtualHeight, width: virtualWidth }}
			>
				{children}
			</div>
		</div>
	);
}
