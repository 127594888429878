import { Button, TeamAvatar, Typography } from "@common/components";
import { Workspace, WorkspaceForUser } from "@common/types";
import classNames from "classnames";
import React from "react";
import styles from "./TeamCard.module.scss";

type Props = {
	workspace: WorkspaceForUser | Workspace;
	underlined?: boolean;
	displayButton?: boolean;
	buttonText?: string;
	onButtonPress?: (value: Workspace) => void;
};

const TeamCard = ({
	workspace,
	underlined,
	displayButton = true,
	buttonText = "Select",
	onButtonPress,
}: Props): JSX.Element => {
	const { name, admins, members } = workspace as any;
	return (
		<div
			className={classNames(styles.option, {
				[styles.underlined]: underlined,
			})}
		>
			<TeamAvatar teamName={name} />
			<span className={styles.optionContent}>
				<Typography variant="body" medium color="text-secondary-v2">
					{name}
				</Typography>
				<Typography variant="caption" color="text-tertiary-v2">
					{admins} admin & {members} members
				</Typography>
			</span>
			{displayButton && (
				<Button
					onClick={() => onButtonPress?.(workspace as any)}
					className={styles.selectButton}
					type="link"
				>
					{buttonText}
				</Button>
			)}
		</div>
	);
};

export default TeamCard;
