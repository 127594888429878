export const HELP_CENTER_URLS = Object.freeze({
	HOME: "https://help.genemod.net/en/",
	INVENTORY: "https://help.genemod.net/en/collections/3540725-inventory",
	PROJECTS: "https://help.genemod.net/en/collections/3540846-projects",
	ACCOUNT: "https://help.genemod.net/en/collections/2160641-account-team",
} as const);

export type TopSuggestionsLinkItem = {
	title: string;
	link: string;
};

export const PM_TOP_SUGGETIONS = [
	{
		title: "How to create an experiment in projects?",
		link: "https://help.genemod.net/en/articles/6370179-creating-an-experiment",
	},
	{
		title: "How can I connect my inventory items directly to my experiment?",
		link: "https://help.genemod.net/en/articles/6370289-connecting-my-inventory-items-directly-to-any-experiment",
	},
	{
		title: "How do I save a protocol template for future use?",
		link: "https://help.genemod.net/en/articles/6370323-creating-a-protocol-template-in-the-protocol-library",
	},
] as TopSuggestionsLinkItem[];

export const FREEZER_TOP_SUGGESTIONS = [
	{
		title: "How to import items into a box?",
		link: "https://intercom.help/genemod/en/articles/6370075-importing-items-with-my-own-spreadsheet",
	},
	{
		title: "What is an item and item type?",
		link: "https://help.genemod.net/en/articles/6370018-what-is-an-item-and-item-type",
	},
	{
		title: "How should I define my custom item type?",
		link: "https://intercom.help/genemod/en/articles/6370028-custom-item-templates",
	},
] as TopSuggestionsLinkItem[];

export const ACCOUNT_TOP_SUGGESTIONS = [
	{
		title: "How do I change my password?",
		link: "https://help.genemod.net/en/articles/6339593-how-do-i-change-my-password",
	},
	{
		title: "How do I invite my colleagues to the team?",
		link: "https://help.genemod.net/en/articles/6513106-how-to-invite-users-to-your-team",
	},
] as TopSuggestionsLinkItem[];
