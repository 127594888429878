import React from "react";
import {
	ProductMenuWrapper,
	ProductMenuTitle,
	ProductMenuItem,
} from "@common/components/ProductMenu";
import { INVENTORY_MENU_ITEMS } from "../constants";

export default function InventoryProductMenu() {
	return (
		<ProductMenuWrapper>
			<ProductMenuTitle>Inventory</ProductMenuTitle>
			{INVENTORY_MENU_ITEMS.map((item, index) => {
				return (
					<ProductMenuItem
						key={index}
						{...item}
						dataCy={INVENTORY_MENU_ITEMS[index].icon}
					/>
				);
			})}
		</ProductMenuWrapper>
	);
}
