import React, { createContext, useContext, useMemo, useState } from "react";
import { Editor } from "slate";
import { overrideFunction } from "./PluginHelpers";

/**
 * Context used to provide functionality to force the calling of the "renderElements" function for all nodes in the editor.
 */
export const ForceRenderElementsContext = createContext({
	dummyState: 1,
	forceRefresh: () => {},
});

type ForceRenderElementsWrapperProps = {
	editor: Editor & ForceRenderElementsEditor;
	children: React.ReactNode;
};
/**
 * Provides context and manages the internal dummy state that forces refresh.
 */
export const ForceRenderElementsWrapper = ({
	editor,
	children,
}: ForceRenderElementsWrapperProps): JSX.Element => {
	const [dummyState, setDummyState] = useState(1);

	const forceRerenderValue = useMemo(
		() => ({
			dummyState,
			forceRefresh: () => setDummyState((v) => v + 1),
		}),
		[dummyState, setDummyState]
	);

	editor.forceRenderElements = forceRerenderValue.forceRefresh;

	return (
		<ForceRenderElementsContext.Provider value={forceRerenderValue}>
			{children}
		</ForceRenderElementsContext.Provider>
	);
};

export type ForceRenderElementsEditor = {
	/**
	 * Forces the rerender of all elements (ie calling "renderElements").
	 *
	 * Note: If you've changed the nodes/children of the editor this isn't necessaryily the function
	 * you want to call. Try "onChange" instead.
	 */
	forceRenderElements: () => void;
};

/**
 * Override the "renderElements" function to include a dependency on the force render context.
 */
export const withForceRenderElementsPlugin = <T extends Editor>(
	editor: T
): T & ForceRenderElementsEditor =>
	overrideFunction(editor)("renderElements")((renderElements) => (props) => {
		const { dummyState: _dummyState } = useContext(
			ForceRenderElementsContext
		);
		return renderElements(props);
	}) as T & ForceRenderElementsEditor;
