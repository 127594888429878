import React, { useState } from "react";
import "./ShelfRackSetting.scss";
import {
	ActivityLog,
	EditableSidePanel,
	SidePanel,
	TabList,
	Typography,
	Notification,
	ButtonV2,
} from "@components";
import { TabListTabText } from "@common/components/TabList/TabList";
import { Divider } from "antd";
import AnalyticsTabContent from "../AnalyticsTabContent/AnalyticsTabContent";
import { truncArgs } from "@helpers/Formatters";

import { useRackStorageSummary } from "../RackSettings/RackSettings";
import { useShelfStorageSummary } from "../ShelfSettings/ShelfSettings";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { useRackQuery, useRackPatchMutation } from "@redux/inventory/Rack";
import { useShelfQuery, useShelfPatchMutation } from "@redux/inventory/Shelf";
import {
	useCategoryPatchMutation,
	useCategoryQuery,
} from "@redux/inventory/Category";
import InputV3 from "@common/components/InputV3/InputV3";
import { useCategoryStorageSummary } from "../CategorySettings/CategorySettings";
type ShelfRackSettingProps = {
	setShowRearrangeRacksModal: (visible: boolean) => void;
	setShowRearrangeBoxModal: (visible: boolean) => void;
	setShowEditRackModal: (visible: boolean) => void;
};

export default function ShelfRackSetting(
	props: ShelfRackSettingProps
): JSX.Element {
	const {
		genericallyNamed: {
			isVisible,
			closePanel: _closePanel,
			data: { shelfId, rackId, categoryId },
		},
	} = useCommonPanelState("shelfRackSettings");
	const { data: rack } = useRackQuery(rackId || skipToken);
	const { data: category } = useCategoryQuery(categoryId || skipToken);
	const { data: shelf } = useShelfQuery(
		shelfId || rack?.location.shelf || category?.location.shelf || skipToken
	);
	const { is_limit_reached: restrictFreezerAnalytics } =
		useFeatureRestrictionHook("freezer_analytics");
	const isCategory = !!category;
	const viewOnly = !!rack?.is_archived;

	const [updateRack] = useRackPatchMutation();
	const [updateCategory] = useCategoryPatchMutation();
	const [updateShelf] = useShelfPatchMutation();

	// For Shelf update
	const [showEditShelf, _setEditShelf] = useState(false);
	const setEditShelf = (value: boolean) => {
		if (value) {
			setName(shelf?.name || "");
			setDescription(shelf?.description || "");
		}
		_setEditShelf(value);
	};

	// For Rack update
	const [showEditRack, _setEditRack] = useState(false);
	const setEditRack = (value: boolean) => {
		if (value) {
			setRackName(rack?.name || category?.name || "");
		}
		_setEditRack(value);
	};

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [rackName, setRackName] = useState("");

	const closePanel = () => {
		setEditShelf(false);
		setEditRack(false);
		_closePanel();
	};

	const validateForm = () => {
		return !!name.trim().length;
	};

	const handleSubmit = () => {
		if (!validateForm() || !shelf) return;

		const failure = (err?: string) => {
			console.log("Failed to edit the shelf", err, shelf);
			Notification.warning({
				message: "Failed to edit the shelf",
			});
		};
		updateShelf({
			...shelf,
			name: name.trim(),
			description: description,
		})
			.unwrap()
			.then((shelf) => {
				setEditShelf(false);
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${shelf.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});
			})
			.catch(failure);
	};

	const validateRackForm = () => {
		return !!rackName.trim().length;
	};

	const handleRackSubmit = () => {
		if (!rack && !category) return;
		if (!validateRackForm()) return;

		const failure = (err?: string) => {
			console.log(
				`Failed to edit the ${category ? "category" : "rack"}`,
				err,
				rack
			);
			Notification.warning({
				message: `Failed to edit the ${category ? "category" : "rack"}`,
			});
		};
		if (rack) {
			updateRack({
				...rack,
				name: rackName.trim(),
				location: rack.location,
			})
				.unwrap()
				.then((rack) => {
					setEditRack(false);
					Notification.success({
						message: (
							<span>
								<b>{truncArgs`${rack.name}`(68)}</b>
								{" has been updated."}
							</span>
						),
					});
				})
				.catch(failure);
		}
		if (category) {
			updateCategory({
				id: category.id,
				name: rackName.trim(),
			})
				.unwrap()
				.then((rack) => {
					setEditRack(false);
					Notification.success({
						message: (
							<span>
								<b>{truncArgs`${rack.name}`(68)}</b>
								{" has been updated."}
							</span>
						),
					});
				})
				.catch(failure);
		}
	};
	const [shelfSpaceData, shelfStorageData] = useShelfStorageSummary(
		shelf?.id
	);
	const [rackSpaceData, rackStorageData] = useRackStorageSummary(
		rack?.id,
		isCategory
	);
	const [categorySpaceData, categoryStorageData] = useCategoryStorageSummary(
		category?.id,
		isCategory
	);

	const analyticName = isCategory ? category?.name : rack?.name;
	const analyticContent = (
		<>
			<AnalyticsTabContent
				title={`Distribution in ${shelf?.name}`}
				subtitle="Available space in shelf"
				storageSummary={{
					dataSource: shelfStorageData,
					columns: [
						{
							title: "Rack / Category",
							dataIndex: "name",
							key: "name",
							ellipsis: true,
						},
						{
							title: "Boxes",
							dataIndex: "boxes",
							key: "boxes",
							align: "right",
							width: 72,
						},
						{
							title: "Items",
							dataIndex: "items",
							key: "items",
							align: "right",
							width: 88,
						},
					],
					mockDataSource: shelfStorageData,
				}}
				spaceLeft={{
					dataSource: shelfSpaceData,
					columns: [
						{
							title: "Rack",
							dataIndex: "name",
							key: "name",
							ellipsis: true,
						},
						{
							title: `Additional boxes allowed`,
							dataIndex: "available_boxes",
							key: "available_boxes",
							align: "right",
						},
					],
					mockDataSource: shelfSpaceData,
				}}
				hideUpgradeSection={true}
			/>
			<AnalyticsTabContent
				title={`Distribution in ${analyticName}`}
				subtitle={`Available space in ${
					isCategory ? "category" : "rack"
				}`}
				storageSummary={{
					dataSource: isCategory
						? categoryStorageData
						: rackStorageData,
					columns: [
						{
							title: `${isCategory ? "Box / Item group" : "Box"}`,
							dataIndex: "name",
							key: "name",
							ellipsis: true,
						},
						{
							title: "Items",
							dataIndex: "items",
							key: "items",
							align: "right",
							width: 88,
						},
					],
					mockDataSource: isCategory
						? categoryStorageData
						: rackStorageData,
				}}
				spaceLeft={{
					dataSource: isCategory ? categorySpaceData : rackSpaceData,
					columns: [
						{
							title: "Available space",
							dataIndex: "name",
							key: "name",
							ellipsis: true,
						},
						{
							title: "Items",
							dataIndex: "available_items",
							key: "available_items",
							align: "right",
							width: 88,
						},
					],
					mockDataSource: isCategory
						? categorySpaceData
						: rackSpaceData,
				}}
			/>
		</>
	);

	const settingContent = [
		{
			key: 1,
			tabtitle: <TabListTabText tabTitle="Details" />,
			tabcontent: (
				<>
					{!showEditShelf && !showEditRack && (
						<>
							<div className="shelf-rack-info-container">
								<EditableSidePanel
									title={shelf?.name || ""}
									type="shelf"
									showEdit={() => setEditShelf(true)}
									viewOnly={viewOnly}
									setShowRearrangeRacksModal={() =>
										props.setShowRearrangeRacksModal(true)
									}
								>
									{description.trim().length !== 0 && (
										<Typography
											withLinks
											className="sidePanel-description"
										>
											{shelf?.description || ""}
										</Typography>
									)}
								</EditableSidePanel>
							</div>
							<div className="shelf-rack-info-conatiner">
								<EditableSidePanel
									title={rack?.name || category?.name || ""}
									edit={showEditRack}
									type={isCategory ? "category" : "rack"}
									showEdit={() => setEditRack(true)}
									onConfirm={handleSubmit}
									validateForm={validateForm}
									viewOnly={viewOnly}
									handleShowEditRackModal={() =>
										props.setShowEditRackModal(true)
									}
								>
									{!isCategory && (
										<div className="rackInformationDisplay">
											<div className="sidePanel-infoView-layout">
												<Typography className="sidePanel-infoView-leftSide">
													Dimension
												</Typography>
												<Typography>
													{rack?.rows} x{" "}
													{rack?.columns}
												</Typography>
											</div>
											{!viewOnly && (
												<div className="sidePanel-infoView-layout">
													<Typography className="sidePanel-infoView-leftSide">
														More options
													</Typography>
													<ButtonV2
														onClick={() =>
															props.setShowRearrangeBoxModal(
																true
															)
														}
														type="link"
														style={{
															marginTop: -4,
															marginLeft: -16,
														}}
													>
														Rearrange
													</ButtonV2>
												</div>
											)}
										</div>
									)}
								</EditableSidePanel>
							</div>
						</>
					)}
					{showEditShelf && (
						<EditableSidePanel
							edit={showEditShelf}
							showEdit={() => setEditShelf(false)}
							onConfirm={handleSubmit}
							validateForm={validateForm}
							type="shelf"
							inlineEdit
						>
							<div className="edit-form">
								<InputV3
									value={name}
									onChange={(e) => setName(e.target.value)}
									required
								/>
							</div>
						</EditableSidePanel>
					)}
					{showEditRack && (
						<EditableSidePanel
							edit={showEditRack}
							type={isCategory ? "category" : "rack"}
							showEdit={() => setEditRack(false)}
							onConfirm={handleRackSubmit}
							validateForm={validateRackForm}
							inlineEdit
						>
							<InputV3
								value={rackName}
								onChange={(e) => setRackName(e.target.value)}
								required
							/>
						</EditableSidePanel>
					)}
				</>
			),
		},
		{
			key: 3,
			tabtitle: <TabListTabText tabTitle="Activity" />,
			tabcontent: (
				<ActivityLog
					activityFilter={{
						location_model: isCategory ? "Category" : "Rack",
						location_object_id: isCategory
							? category?.id
							: rack?.id,
					}}
				/>
			),
		},
		{
			key: 4,
			tabtitle: (
				<TabListTabText
					tabTitle="Analytics"
					withUpgradeIcon={restrictFreezerAnalytics}
				/>
			),
			tabcontent: analyticContent,
		},
	];

	return (
		<SidePanel
			isVisible={isVisible}
			onClose={() => closePanel()}
			header={
				isCategory
					? "Shelf and category settings"
					: "Shelf and rack settings"
			}
		>
			<TabList
				inSettings
				tabListItems={settingContent}
				largeSize={true}
			/>
		</SidePanel>
	);
}
