import TeamAvatarGroup from "@common/components/TeamAvatarGroup";
import { Color } from "@common/styles/Colors";
import { SharedInternalObj, SharingOptions, WorkspaceId } from "@common/types";
import { PlanName } from "@common/types/FeatureRestriction";
import { useCurrentPlanNameHook } from "@helpers/Hooks/featureRestrictionHook";
import { useGetPersonalWorkspacesQuery } from "@redux/team/TeamApi";
import classNames from "classnames";
import React, { HTMLProps } from "react";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";
import RestrictionWarning from "../RestrictionWarning/RestrictionWarning";
import Tooltip from "../Tooltip";
import Typography from "../Typography/Typography";
import {
	CurrentWorkspaceSelectionDisplay,
	WorkspaceSelector as W2,
} from "../WorkspaceSelector/index";
import styles from "./SharingObjectButtons.module.scss";
import cn from "classnames";

type ObjectTypes = "freezer" | "space";

type Sizes = "default" | "small";

export type SharingLevelRestrictionWarning = {
	warning: JSX.Element;
	visible: boolean;
	showExplorePlansButton?: boolean;
};

type Props = {
	objectType: ObjectTypes;
	value: SharingOptions | undefined;
	onChange?: (value: SharingOptions) => void;
	restrictionWarnings?: SharingLevelRestrictionWarning[];
	setWorkspaceOpen?: () => void;
	selectedVal?: WorkspaceId[];
	hideHeader?: boolean;
	size?: Sizes;
	personalTooltip?: string;
	showBottomSeparator?: boolean;
	hideWorkspaceSelector?: boolean;
	// disable specific sharing preference option
	disablePersonalOption?: boolean;
	disableWorkspacesOption?: boolean;
	dataCy?: string;
	useSmallButtons?: boolean;
	setSelectedWorkspaces?: (workspaces: WorkspaceId[]) => void;
} & Omit<HTMLProps<HTMLDivElement>, "onChange" | "size">;

export const SharingObjectButtons = ({
	onChange,
	value,
	objectType,
	personalTooltip,
	restrictionWarnings,
	setWorkspaceOpen,
	selectedVal,
	hideHeader,
	size = "default",
	showBottomSeparator = false,
	hideWorkspaceSelector,
	disablePersonalOption,
	disableWorkspacesOption,
	dataCy,
	useSmallButtons = false,
	setSelectedWorkspaces,
	...props
}: Props): JSX.Element => {
	const showWorkspaceSelector = useShowWorkspacesSelector(
		value,
		setWorkspaceOpen,
		hideWorkspaceSelector
	);
	const sizes: { [key in Sizes]: string } = {
		default: "card",
		small: "smallCard",
	};
	const { planName } = useCurrentPlanNameHook();

	const sharingOptions: {
		[label in SharingOptions]: {
			title: string;
			icon: IconName;
			plan?: PlanName;
			disabled?: boolean;
			tooltip?: string;
			hide?: boolean;
			dataCy?: string;
		};
	} = {
		personal: {
			title: "Just me",
			icon: "profile",
			disabled: disablePersonalOption,
			tooltip: personalTooltip ? personalTooltip : undefined,
			hide: objectType === "space",
			dataCy: "sharing-object-buttons-just-me",
		},
		workspace: {
			title:
				planName === "enterprise"
					? "Workspaces in my organization"
					: "People in my workspace",
			icon: "group",
			disabled: disableWorkspacesOption,
			dataCy: "sharing-object-buttons-workspace",
		},
		org: {
			title: "My entire organization",
			icon: "building-o",
			plan: "enterprise",
			dataCy: "sharing-object-buttons-organization",
		},
	};

	const warnings =
		restrictionWarnings
			?.filter((warning) => warning.visible)
			.map(({ warning, showExplorePlansButton }, index) => (
				<RestrictionWarning
					key={index}
					showExplorePlansButton={showExplorePlansButton}
				>
					{warning}
				</RestrictionWarning>
			)) || [];

	return (
		<div {...props}>
			{!hideHeader && (
				<>
					<Typography
						color="text-secondary-v2"
						variant="headline5"
						medium
						style={{ marginBottom: 4 }}
					>
						Who needs to access to this {objectType}?
					</Typography>
					<Typography color="text-tertiary" variant="footnote">
						People with access will be able to view, modify, and
						delete items in this {objectType}.
					</Typography>
				</>
			)}
			<div className={styles.sharingOptionsContainer}>
				{Object.keys(sharingOptions)
					.filter((key) => {
						const { plan, hide } =
							sharingOptions[key as SharingOptions];
						return (!hide && !plan) || plan === planName;
					})
					.map((key) => {
						const { icon, title, disabled, tooltip, dataCy } =
							sharingOptions[key as SharingOptions];

						let color: Color = disabled
							? "text-disabled"
							: "text-secondary-v2";

						if (key === value) {
							color = "brand-08";
						}
						const component = (
							<div
								className={classNames(
									styles.sharingOption,
									{
										[styles.sharingOptionActive]:
											key === value,
									},
									{
										[styles.sharingOptionSmall]:
											useSmallButtons,
									},
									{ [styles.small]: size === "small" },
									{
										[styles.hoverDisabled]:
											disablePersonalOption &&
											key === "personal",
									}
								)}
								onClick={() => {
									if (disabled) return;
									onChange?.(key as SharingOptions);
								}}
								data-cy={dataCy}
							>
								<GenemodIcon
									size={sizes[size] as any}
									className={styles.buttonIcon}
									color={color}
									fill={color}
									name={icon}
								/>
								<Typography
									variant={
										useSmallButtons || size === "small"
											? "footnote"
											: "body"
									}
									color={
										key === value
											? "brand-08"
											: disablePersonalOption &&
											  key === "personal"
											? "text-disabled"
											: "text-secondary-v2"
									}
								>
									{title}
								</Typography>
							</div>
						);
						return tooltip ? (
							<Tooltip
								title={
									<Typography
										variant="caption"
										color="text-inverse"
									>
										{tooltip}
									</Typography>
								}
							>
								{component}
							</Tooltip>
						) : (
							component
						);
					})}
			</div>
			{showWorkspaceSelector && (
				<WorkspaceSelector
					objectType={objectType}
					setWorkspaceOpen={setWorkspaceOpen}
					selectedVal={selectedVal}
					setSelectedWorkspaces={setSelectedWorkspaces}
				/>
			)}
			{warnings.length > 0 && <Separator />}
			{warnings}
			{showBottomSeparator && <Separator />}
		</div>
	);
};

const useShowWorkspacesSelector = (
	value: SharingOptions | undefined,
	setWorkspaceOpen?: () => void,
	hideWorkspaceSelector?: boolean
) => {
	const { data: workspaces } = useGetPersonalWorkspacesQuery({
		get_all: true,
	});
	const { planName } = useCurrentPlanNameHook();
	const isEnterprise = planName === "enterprise";
	if (hideWorkspaceSelector) {
		return false;
	}
	return (
		value === "workspace" &&
		!!setWorkspaceOpen &&
		isEnterprise &&
		workspaces
	);
};

type WorkspaceSelectorProps = {
	objectType: ObjectTypes;
	setWorkspaceOpen?: () => void;
	selectedVal?: WorkspaceId[];
	setSelectedWorkspaces?: (workspaces: WorkspaceId[]) => void;
};

const WorkspaceSelector = ({
	objectType,
	setWorkspaceOpen = () => {},
	selectedVal = [],
	setSelectedWorkspaces,
}: WorkspaceSelectorProps) => {
	const { data: workspacesData } = useGetPersonalWorkspacesQuery({
		get_all: true,
	});
	return (
		<div>
			<Typography
				style={{ marginTop: 24, marginBottom: 4 }}
				variant="headline5"
				medium
				color="text-secondary-v2"
			>
				Which workspace needs access?
			</Typography>
			<Typography
				style={{ marginBottom: 24 }}
				color="text-tertiary"
				variant="footnote"
			>
				Grant access to other workspaces later in freezer settings. Only
				org admins can revoke access.
			</Typography>
			{
				<div
					style={{
						cursor:
							workspacesData && workspacesData?.length < 2
								? "default"
								: undefined,
					}}
					className={styles.accessBox}
					onClick={
						workspacesData && workspacesData?.length > 1
							? setWorkspaceOpen
							: undefined
					}
				>
					<W2
						style={{ width: "100%" }}
						values={selectedVal}
						setValues={setSelectedWorkspaces ?? (() => {})}
						showArrowRight
					/>
				</div>
			}
		</div>
	);
};

const Separator = () => (
	<div
		style={{
			backgroundColor: "var(--border-subtle)",
			height: 1,
			marginBottom: 31.5,
			marginTop: 31.5,
		}}
	/>
);

type SharedWithDisplayProps<T> = {
	sharedObject?: SharedInternalObj<T>;
};
/**
 * Used in settings components
 */
export const SharedWithDisplay = <T,>({
	sharedObject,
}: SharedWithDisplayProps<T>): JSX.Element => {
	if (sharedObject?.sharing === "personal") {
		return <Typography>Only you</Typography>;
	}
	if (sharedObject?.sharing === "workspace") {
		if (sharedObject?.access.workspaces.length > 1) {
			return (
				<div>
					<TeamAvatarGroup
						avatarSize={32}
						workspaceIds={sharedObject?.access.workspaces}
					/>
				</div>
			);
		}
		return (
			<Typography variant="body" color="text-secondary-v2">
				Everyone in your workspace
			</Typography>
		);
	}

	return (
		<Typography variant="body" color="text-secondary-v2">
			Everyone in your organization
		</Typography>
	);
};
