/* 
    Event Name must be in this format: (Noun Past-tense-verb)
    ex) Freezer Created, Shelf Created, Freezer Viewed
    Use Proper Case (not snake_case, not camelCase, not Sentence case)
*/
const SegmentFreezerEvents = {
	FREEZER_VIEW: "Freezer Viewed",
	FREEZER_CREATE: "Freezer Created",
	FREEZER_EDIT: "Freezer Edited",
	FREEZER_DELETE: "Freezer Deleted",
	SHELF_VIEW: "Shelf Viewed",
	SHELF_CREATE: "Shelf Created",
	SHELF_EDIT: "Shelf Edited",
	SHELF_DELETE: "Shelf Deleted",
	RACK_VIEW: "Rack Viewed",
	RACK_CREATE: "Rack Created",
	RACK_EDIT: "Rack Edited",
	RACK_DELETE: "Rack Deleted",
	BOX_VIEW: "Box Viewed",
	BOX_CREATE: "Box Created",
	BOX_EDIT: "Box Edited",
	BOX_DELETE: "Box Deleted",
	ITEM_VIEW: "Item Viewed",
	ITEM_CREATE: "Item Created",
	ITEM_EDIT: "Item Edited",
	ITEM_DELETE: "Item Deleted",
	ITEM_PASTE: "Item Pasted",
	SEARCH_VIEW: "Search Viewed",
	SEARCH_PERFORM: "Search Performed",
	REPOSITORY_VIEW: "Repository Viewed",
	REPOSITORY_SEARCH: "Repository Searched",
} as const;

const SegmentPMEvents = {
	PROJECT_VIEW: "Project Viewed",
	PROJECT_CREATE: "Project Created",
	PROJECT_EDIT: "Project Edited",
	PROJECT_DELETE: "Project Deleted",
	PROJECT_USER_INVITE: "Project User Invited",
	SUBPROJECT_VIEW: "Subproject Viewed",
	SUBPROJECT_CREATE: "Subproject Created",
	SUBPROJECT_EDIT: "Subproject Edited",
	SUBPROJECT_DELETE: "Subproject Deleted",
	SUBPROJECT_USER_INVITE: "Subproject User Invited",
	SUBPROJECT_FILE_UPLOAD: "Subproject File Uploaded",
	EXPERIMENT_VIEW: "Experiment Viewed",
	EXPERIMENT_CREATE: "Experiment Created",
	EXPERIMENT_EDIT: "Experiment Edited",
	EXPERIMENT_DELETE: "Experiment Deleted",
	EXPERIMENT_PUBLISH: "Experiment Published",
	EXPERIMENT_COMPLETE: "Experiment Completed",
} as const;

const SegmentConsumableEvents = {
	SPACE_VIEW: "Space Viewed",
	SPACE_CREATE: "Space Created",
	SPACE_EDIT: "Space Edited",
	SPACE_DELETE: "Space Deleted",
	FURNITURE_VIEW: "Furniture Viewed",
	FURNITURE_CREATE: "Furniture Created",
	FURNITURE_EDIT: "Furniture Edited",
	FURNITURE_DELETE: "Furniture Deleted",
	CATEGORY_VIEW: "Furniture Category Viewed",
	CATEGORY_CREATE: "Furniture Category Created",
	CATEGORY_EDIT: "Furniture Category Edited",
	CATEGORY_DELETE: "Furniture Category Deleted",
	CONSUMABLE_VIEW: "Consumable Viewed",
	CONSUMABLE_CREATE: "Consumable Created",
	CONSUMABLE_EDIT: "Consumable Edited",
	CONSUMABLE_DELETE: "Consumable Deleted",
	SEARCH_VIEW: "Consumable Search Viewed",
	SEARCH_PERFORM: "Consumable Search Performed",
} as const;

export type SegmentEvent = keyof (typeof SegmentFreezerEvents &
	typeof SegmentPMEvents &
	typeof SegmentConsumableEvents);

export { SegmentFreezerEvents, SegmentPMEvents, SegmentConsumableEvents };
