import React from "react";
import { Modal } from "@components";

type ArchiveType =
	| "space"
	| "box"
	| "item"
	| "itemgroup"
	| "rack"
	| "category"
	| "shelf"
	| "freezer";

type ArchiveModalProps = {
	// Controls the visibility of the modal
	visible: boolean;
	// Called when users closed the modal
	onCancel: () => void;
	// Called when users click on "Move to archive"
	onArchive: () => void;
	type?: ArchiveType;
	numberOfItems?: number;
};

export default function ArchiveModal({
	visible,
	onCancel,
	onArchive,
	type,
	numberOfItems,
}: ArchiveModalProps) {
	const warningMessage = {
		space: "You will not be able to modify this space. Items within this space will not appear in search results.",
		box: (
			<div>
				Archiving boxes prevents modifications to the boxes and their
				contents. You can restore them anytime from the Repository or
				rack/category view. <br />
				<br />
				<b>Note:</b> Restored boxes won{`'`}t be linked to their
				previous locations.
			</div>
		),
		rack: (
			<div>
				Archiving racks prevents modifications to the racks and their
				contents. You can restore them anytime from the Repository or
				Shelf view. <br />
				<br />
				<b>Note:</b> Restored racks won{`'`}t be linked to their
				previous locations.
			</div>
		),
		category: (
			<div>
				Archiving categories prevents modifications to the categories
				and their contents. You can restore them anytime from the
				Repository or Shelf view. <br />
				<br />
				<b>Note:</b> Restored categories won{`'`}t be linked to their
				previous locations.
			</div>
		),
		shelf: (
			<div>
				Archiving shelves prevents modifications to the shelves and
				their contents. You can restore them anytime from the Repository
				or Shelf view. <br />
				<br />
				<b>Note:</b> Restored shelves won{`'`}t be linked to their
				previous locations.
			</div>
		),
		freezer: (
			<div>
				Archiving freezers prevents modifications to the freezers and
				their contents. You can restore them anytime from the
				Repository.
			</div>
		),
		itemgroup: (
			<div>
				Archiving item groups prevents modifications to the item groups
				and their contents. You can restore them anytime from the
				Repository or rack/category view. <br />
				<br />
				<b>Note:</b> Restored item groups won{`'`}t be linked to their
				previous locations.
			</div>
		),
		item: (
			<div>
				Archiving items prevents modifications, but you can access and
				restore them via any Box page or the Repository. <br /> <br />
				<b>Note:</b> Restored items won{`'`}t be linked to their
				previous locations.
			</div>
		),
	};

	const titleText = {
		space: "Move space to archive?",
		box: `Archive ${numberOfItems} ${
			numberOfItems === 1 ? "box" : "boxes"
		}?`,
		rack: `Archive ${numberOfItems} ${
			numberOfItems === 1 ? "rack" : "racks"
		}?`,
		category: `Archive ${numberOfItems} ${
			numberOfItems === 1 ? "category" : "categories"
		}?`,
		shelf: `Archive ${numberOfItems} ${
			numberOfItems === 1 ? "shelf" : "shelves"
		}?`,
		freezer: `Archive ${numberOfItems} ${
			numberOfItems === 1 ? "freezer" : "freezers"
		}?`,
		itemgroup: `Archive ${numberOfItems} ${
			numberOfItems === 1 ? "item group" : "item groups"
		}?`,
		item: `Archive ${numberOfItems} ${
			numberOfItems === 1 ? "item" : "items"
		}?`,
	};

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			onOk={onArchive}
			title={titleText[type || "space"]}
			okText="Archive"
			hideCancelButton
		>
			{type ? warningMessage[type] : ""}
		</Modal>
	);
}
