import React, { useContext, useState } from "react";
import NavigationList, { WithSlots } from "../NavigationList";
import { MovingCartContext } from "..";
import { Button, ButtonV2, Typography } from "@components";
import { Box, Category, Rack, isItemGroup, isRack } from "@common/types";
import { ColumnProps } from "antd/es/table/interface";
import styles from "./index.module.scss";
import cn from "classnames";

import ItemPlacement from "./ItemPlacement";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useBoxesQuery } from "@redux/inventory/Box";
import { useFreezerQuery } from "@redux/inventory/Freezer";
import { useRackQuery } from "@redux/inventory/Rack";
import { useShelfQuery } from "@redux/inventory/Shelf";
import {
	useCategoryBoxesQuery,
	useCategoryQuery,
} from "@redux/inventory/Category";

type BoxListProps = {
	/** Sets the selected box id */
	setBoxId: (boxId: number) => void;
};

// BoxList compontnet for ItemMoving (Context)
export default function BoxList({ setBoxId }: BoxListProps): JSX.Element {
	const { rackId, categoryId, clearRack, clearCategory, setShelf } =
		useContext(MovingCartContext);
	const { data: rack, isLoading: isRackLoading } = useRackQuery(
		rackId || skipToken
	);

	const { data: category, isLoading: isCategoryLoading } = useCategoryQuery(
		categoryId || skipToken
	);

	let rackOrCategory: Rack | Category | null = null;
	if (rack) {
		rackOrCategory = rack;
	} else if (category) {
		rackOrCategory = category;
	}

	const { data: shelf } = useShelfQuery(
		rackOrCategory?.location.shelf ?? skipToken
	);
	const { data: freezer } = useFreezerQuery(shelf?.freezer ?? skipToken);
	const rackData = useBoxesQuery(
		rackId
			? {
					id: rackId,
			  }
			: skipToken,
		{
			selectFromResult: ({ data, ...rest }) => ({
				...rest,
				data: data,
			}),
		}
	);

	const categoryData = useCategoryBoxesQuery(
		categoryId
			? {
					id: categoryId,
			  }
			: skipToken,
		{
			selectFromResult: ({ data, ...rest }) => ({
				...rest,
				data: data,
			}),
		}
	);

	let boxes: Box[] | undefined;
	let areBoxesLoading = false;

	if (rack) {
		boxes = rackData.data;
		areBoxesLoading = rackData.isLoading;
	} else {
		boxes = categoryData.data;
		areBoxesLoading = categoryData.isLoading;
	}

	// Override the columns so we can gray out item groups
	const COLUMNS: Array<ColumnProps<WithSlots<Box>>> = [
		{
			width: 300,
			ellipsis: true,
			render: (_, record) => {
				return (
					<div>
						<Typography
							color="text-secondary-v2"
							className={cn({
								[styles.boxName__isItemGroup]:
									isItemGroup(record),
							})}
						>
							{record.name}
						</Typography>{" "}
						<Typography
							color="text-tertiary-v2"
							className={styles.caption}
						>
							{record.slots} slots available
						</Typography>
					</div>
				);
			},
		},
		{
			dataIndex: "id",
			render: (id: number, record: Box) => {
				// Attach onClick handler w/ the Id of the item
				return (
					<ButtonV2
						type="link"
						onClick={() => setBoxId(id)}
						className={styles.linkButton}
					>
						Open
					</ButtonV2>
				);
			},
			width: 88,
			align: "center",
			defaultSortOrder: "descend",
		},
	];
	const [hoverBox, setHoverBox] = useState<undefined | number>(undefined);
	return (
		<>
			{rackOrCategory && (
				<NavigationList
					navigationLevel={
						isRack(rackOrCategory) ? "rack" : "category"
					}
					onNavigateForward={(item) => {
						setBoxId(item.id);
					}}
					onNavigateBack={() => {
						clearRack();
						clearCategory();
					}}
					parentName={rackOrCategory?.name}
					childrenList={
						boxes
							? boxes.map((box) => ({
									...box,
									slots:
										box.rows * box.columns - box.item_count,
							  }))
							: []
					}
					loading={
						isRackLoading || isCategoryLoading || areBoxesLoading
					}
					columns={COLUMNS}
					onRow={(record) => ({
						onMouseEnter: () => setHoverBox(record.id),
						onMouseLeave: () => setHoverBox(undefined),
					})}
					navigationElements={[
						{
							name: freezer?.name || "",
							onClick: () => {
								setShelf(null);
								clearRack();
								clearCategory();
							},
						},
						{
							name: shelf?.name || "",
							onClick: () => {
								clearRack();
								clearCategory();
							},
						},
						{
							name: rackOrCategory?.name || "",
							onClick: () => {},
						},
					]}
				/>
			)}
			{hoverBox && (
				<div className={styles.boxPreview}>
					<ItemPlacement
						boxId={hoverBox}
						viewOnly
						cellSize={24}
						className={styles.previewStyling}
					/>
				</div>
			)}
		</>
	);
}
