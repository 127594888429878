import React from "react";
import { Demo, DemoSection, DemoWrapper, Typography } from "@components";
import classNames from "classnames";
import styles from "./GradientContainer.module.scss";
import { ColorCssVarMap, Colors } from "@common/styles/Colors";

export type GradientContainerProps = {
	// Color of the gradient
	color?: "layer-01" | "layer-02" | "layer-03" | "background";
	// Height of the top gradient (default is 32px)
	topHeight?: number;
	// Height of the bottom gradient (default is 32px)
	bottomHeight?: number;
	// Width of the top gradient (default is 100%)
	topWidth?: any;
	// Width of the bottom gradient (default is 100%)
	bottomWidth?: any;
	// Whether to display the top gradient (default is true)
	hasTop?: boolean;
	// Whether to display the bottom gradient (default is true)
	hasBottom?: boolean;
	// The border-radius of the bottom gradient (default is 0)
	borderRadius?: number;
	// Any contents within the container
	children: any;
	// classname of the gradient container
	className?: string;
	// id of the gradient container
	id?: string;
};

export default function GradientContainer({
	color = "layer-01",
	topHeight = 32,
	bottomHeight = 32,
	topWidth = "100%",
	bottomWidth = "100%",
	hasTop = true,
	hasBottom = true,
	borderRadius = 0,
	children,
	className,
	id,
}: GradientContainerProps): JSX.Element {
	const getStyles = (color: string, isTop: boolean) => {
		const gradientStyle = isTop ? "topGradient" : "bottomGradient";
		return [styles[gradientStyle], styles[`${gradientStyle}__${color}`]];
	};
	return (
		<div
			className={classNames(styles.gradientContainer, className)}
			id={id}
		>
			{hasTop && (
				<div
					className={classNames(getStyles(color, true))}
					style={{
						height: topHeight,
						width: topWidth,
					}}
				/>
			)}
			{children}
			{hasBottom && (
				<div
					className={classNames(getStyles(color, false))}
					style={{
						height: bottomHeight,
						width: bottomWidth,
						borderBottomLeftRadius: borderRadius,
						borderBottomRightRadius: borderRadius,
					}}
				/>
			)}
		</div>
	);
}

export function GRADIENTCONTAINER_DEMO(): JSX.Element {
	const contents = (
		<div style={{ padding: "0 8px 0" }}>
			<Typography variant="headline" color="text-primary">
				Description
			</Typography>
			<Typography style={{ paddingTop: 12 }}>
				The red glint of paint sparkled under the sun. He had dreamed of
				owning this car since he was ten, and that dream had become a
				reality less than a year ago. It was his baby and he spent hours
				caring for it, pampering it, and fondling over it. She knew this
				all too well, and that&apos;s exactly why she had taken a sludge
				hammer to it. It was going to rain. The weather forecast
				didn&apos;t say that, but the steel plate in his hip did. He had
				learned over the years to trust his hip over the weatherman. It
				was going to rain, so he better get outside and prepare.
			</Typography>
		</div>
	);

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage"
					description="Use hasTop and hasBottom to customize whether to show the top/bottom gradients"
				>
					<div style={{ display: "flex" }}>
						<div
							className={styles.gradientDemoWrapper}
							style={{
								backgroundColor: ColorCssVarMap["layer-01"],
								marginRight: 24,
							}}
						>
							<GradientContainer>{contents}</GradientContainer>
						</div>
						<div
							className={styles.gradientDemoWrapper}
							style={{
								backgroundColor: ColorCssVarMap["layer-01"],
							}}
						>
							<GradientContainer hasBottom={false}>
								{contents}
							</GradientContainer>
						</div>
					</div>
				</Demo>
				<Demo
					title="Basic usage: Customize height/width of the gradient"
					description="Customize the height/width of the gradient by assigning values to topHeight/topWidth and bottomHeight/bottomWidth"
				>
					<div style={{ display: "flex" }}>
						<div
							className={styles.gradientDemoWrapper}
							style={{
								backgroundColor: ColorCssVarMap["layer-02"],
								marginRight: 24,
							}}
						>
							<GradientContainer
								color={Colors["layer-02"]}
								topHeight={24}
								bottomHeight={48}
							>
								{contents}
							</GradientContainer>
						</div>
						<div
							className={styles.gradientDemoWrapper}
							style={{
								backgroundColor: ColorCssVarMap["layer-03"],
							}}
						>
							<GradientContainer
								color={Colors["layer-03"]}
								topHeight={24}
								topWidth={"calc(100% - 24px)"}
								bottomHeight={48}
								bottomWidth={200}
							>
								{contents}
							</GradientContainer>
						</div>
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
