import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import {
	Typography,
	DemoWrapper,
	DemoSection,
	Demo,
	GenemodIcon,
	Button,
} from "@components";
import classnames from "classnames";

export const exampleOptions = [
	{
		key: 0,
		value: "Cell biology",
	},
	{
		key: 1,
		value: "General techniques",
	},
	{
		key: 2,
		value: "Antibodies and vaccines",
	},
	{
		key: 3,
		value: "Assay",
	},
	{
		key: 4,
		value: "Biochemistry",
	},
	{
		key: 5,
		value: "Biological techniques",
	},
	{
		key: 6,
		value: "Custom label 1",
	},
	{
		key: 7,
		value: "Custom label 2",
	},
	{
		key: 8,
		value: "Custom label 3",
	},
] as LabelValue[];

export type LabelValue = {
	key?: number | string;
	value: string;
};

export type LabelFilterProps = {
	options: LabelValue[];
	values: LabelValue["key"][];
	onChange?: (vals: LabelValue["key"][]) => void;
};

export default function LabelFilter({
	options,
	values,
	onChange,
}: LabelFilterProps): JSX.Element {
	const [valuesState, setValuesState] = useState<LabelValue["key"][]>(
		values || []
	);

	useEffect(() => {
		setValuesState(values);
	}, [values.toString()]);

	const [showMore, setShowMore] = useState(false);
	const filterContainerRef = useRef<HTMLDivElement>(null);

	const [containerHeights, setContainerHeights] = useState({
		offset_height: 0,
		scroll_height: 0,
	});

	useLayoutEffect(() => {
		if (filterContainerRef.current) {
			setContainerHeights({
				offset_height: filterContainerRef.current.offsetHeight,
				scroll_height: filterContainerRef.current.scrollHeight,
			});
		}
	}, [filterContainerRef]);

	const handleReset = () => {
		if (onChange) onChange([]);
		setValuesState([]);
	};

	const handleSelectLabel = (val: LabelValue["key"]) => {
		let tempValsState = [...valuesState];
		if (valuesState.includes(val)) {
			tempValsState = valuesState.filter((key) => key !== val);
		} else {
			tempValsState.push(val);
		}

		if (onChange) onChange(tempValsState);
		setValuesState(tempValsState);
	};

	const renderShowMoreAndReset = (
		extraCondition?: boolean,
		marginLeft?: number
	) => {
		return (
			<div
				className={styles.expandAndReset}
				style={{ marginLeft: extraCondition ? marginLeft : 0 }}
			>
				{containerHeights.offset_height <
					containerHeights.scroll_height &&
					extraCondition && (
						<div className={styles.showMoreContainer}>
							<GenemodIcon
								className={classnames(styles.showMoreButton, {
									[styles.showMoreButton__expanded]: showMore,
								})}
								onClick={() => setShowMore(!showMore)}
								size="large"
								name="caret-down"
							/>
						</div>
					)}
				{valuesState.length > 0 && extraCondition && (
					<Button onClick={handleReset} type="text">
						Reset
					</Button>
				)}
			</div>
		);
	};

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
			}}
		>
			<div
				className={classnames(styles.labelFilterContainer, {
					[styles.labelFilterContainer__expanded]: showMore,
				})}
				ref={filterContainerRef}
			>
				{options.map((option) => {
					const key = option?.key || option.value;
					return (
						<div
							className={classnames(styles.label, {
								[styles.label__selected]:
									valuesState.includes(key),
							})}
							key={key}
							onClick={() => handleSelectLabel(key)}
						>
							<Typography variant="label" color="none">
								{option.value}
							</Typography>
						</div>
					);
				})}
				{renderShowMoreAndReset(showMore)}
			</div>
			{renderShowMoreAndReset(!showMore, 12)}
		</div>
	);
}

export function LABELFILTER_DEMO(): JSX.Element {
	const exampleState = [] as LabelValue["key"][];

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Label filter examples"
					description="When a label is clicked, the color changes, meaning the value is selected. There is also a reset button that shows up when a value is selected. Clicking on reset button will deselect the values."
				>
					<LabelFilter
						options={exampleOptions}
						values={exampleState}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
