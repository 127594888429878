import React, { CSSProperties } from "react";
import { Avatar, ConfigProvider } from "antdv5";
import { Tooltip, Typography } from "@components";
import {
	Avatar as AvatarType,
	getFullNameFromAvatar,
	UserId,
} from "@common/types";
import { ColorCssVarMap } from "@common/styles/Colors";
import cn from "classnames";
import styles from "./UserAvatar.module.scss";
import useOrganizationUser from "@helpers/Hooks/useOrganizationUser";
import classNames from "classnames";
import { FONT_VARIANT } from "../Typography/Typography";

/**
 * Gets a css var string for a given text and opacity
 */
export const getAvatarColorFromString = (text: string, opacity = 1): string => {
	const colors = [
		ColorCssVarMap["pink-04-rgb"],
		ColorCssVarMap["dust-red-04-rgb"],
		ColorCssVarMap["desert-04-rgb"],
		ColorCssVarMap["gold-05-rgb"],
		ColorCssVarMap["yellow-04-rgb"],
		ColorCssVarMap["lime-04-rgb"],
		ColorCssVarMap["green-04-rgb"],
		ColorCssVarMap["cyan-04-rgb"],
		ColorCssVarMap["sky-blue-04-rgb"],
		ColorCssVarMap["royal-blue-04-rgb"],
		ColorCssVarMap["violet-04-rgb"],
	];
	const textSum = [...text].reduce(
		(acc, char) => char.charCodeAt(0) + acc,
		0
	);
	const color = colors[textSum % colors.length];
	return `rgba(${color}, ${opacity})`;
};

export enum UserAvatarSizes {
	XL = 64,
	L = 40,
	M = 32,
	S = 24,
	XS = 18,
}

type UserAvatarProps = {
	/** if user is not provided, show user as Unknown. */
	user?: AvatarType | null;
	/** the size of avatar picture (default=24) */
	size?: UserAvatarSizes;
	/** the className for avatar picture design */
	className?: string;
	/**  styles to be applied to the avatar */
	avatarStyle?: CSSProperties;
	/** show user name on hover */
	tooltip?: boolean;
	/**  show user name on the right of the avatar */
	displayName?: boolean;
	/** used to do number placeholder avatars (ie: +2 ) */
	plusNumber?: number;
	/** Applies deactivated styling */
	deactivated?: boolean;
	/** Classes applied to avatar's name */
	nameClassName?: string;
	displayNameVariant?: FONT_VARIANT;
	shape?: "circle" | "square";
};

/**
 * A User_Avatar components
 */
export default function UserAvatar({
	plusNumber,
	deactivated,
	user,
	avatarStyle,
	size = 24,
	tooltip,
	displayName,
	className,
	nameClassName,
	displayNameVariant,
	shape = "circle",
}: UserAvatarProps): JSX.Element {
	let name = "",
		initials = "",
		src = "" as string | undefined,
		backgroundColor = "" as string | undefined,
		color = "var(--text-on-color)" as string | undefined;

	if (typeof plusNumber !== "undefined") {
		if (plusNumber === 0) return <></>;
		initials = `+${plusNumber}`;
		backgroundColor = "var(--informative)";
		color = "var(--text-on-color)";
	} else {
		if (!user) {
			name = "Unknown";
			initials = "?";
		} else {
			name = getFullNameFromAvatar(user);
			src = user.profile_picture;
			initials = (
				user?.first_name?.[0] + user?.last_name?.[0] || ""
			).toUpperCase();
			backgroundColor = getAvatarColorFromString(initials);
		}
	}

	const CLASS_BY_SIZE: Record<UserAvatarSizes, string> = {
		[UserAvatarSizes.XL]: styles.UserAvatarSizes_XL,
		[UserAvatarSizes.L]: styles.UserAvatarSizes_L,
		[UserAvatarSizes.M]: styles.UserAvatarSizes_M,
		[UserAvatarSizes.S]: styles.UserAvatarSizes_S,
		[UserAvatarSizes.XS]: styles.UserAvatarSizes_XS,
	};

	const avatar = (
		<Avatar
			gap={3}
			size={size}
			style={{
				cursor: "default",
				backgroundColor: user?.profile_picture
					? undefined
					: backgroundColor,
				color,
				...avatarStyle,
			}}
			className={cn(styles.avatar, className, CLASS_BY_SIZE[size], {
				[styles.deactivated]: deactivated && !src,
				[styles.deactivatedWithImage]: deactivated && src,
			})}
			src={src}
			shape={shape}
		>
			{initials}
		</Avatar>
	);

	if (tooltip) {
		return <Tooltip title={name}>{avatar}</Tooltip>;
	}
	if (displayName) {
		return (
			<div className={classNames(styles.avatarAndName, nameClassName)}>
				{avatar}
				<Typography
					variant={displayNameVariant || "body"}
					color="text-secondary-v2"
					ellipsis
				>
					{name}
				</Typography>
			</div>
		);
	}
	return avatar;
}

type CustomUserAvatarProps = Omit<UserAvatarProps, "user"> & {
	/** if id is not provided, show user as Unknown. */
	customUserId: UserId;
};

/**
 * Fetches org usres and renders UserAvatar
 */
export const CustomUserAvatar = ({
	customUserId,
	...props
}: CustomUserAvatarProps) => {
	const { user } = useOrganizationUser({
		type: "CustomUser",
		id: customUserId,
	});

	return <UserAvatar user={user?.user} {...props} />;
};
