import React from "react";
import styles from "./TypingAnimation.module.scss";
import {} from "@components";
import { Color, ColorCssVarMap } from "@common/styles/Colors";
import { Demo, DemoSection, DemoWrapper } from "@components";

type TypingAnimationProps = {
	color?: Color;
	size?: number;
};

const TypingAnimation: React.FunctionComponent<TypingAnimationProps> = ({
	color = "neutral-gray",
	size = 5,
}: TypingAnimationProps) => (
	<div
		className={styles.dotTyping}
		style={
			{
				"--dotTypingColor": ColorCssVarMap[color],
				"--size": `${size}px`,
			} as React.CSSProperties
		}
	/>
);

export default TypingAnimation;

export function TYPING_ANIMATION_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo>
					<TypingAnimation />
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
