import React from "react";
import "./index.scss";
import cn from "classnames";
import {
	DemoWrapper,
	DemoSection,
	Demo,
	Typography,
	Button,
	Input,
	Select,
	LayerSystemContainer,
} from "@components";
import { useWindowDimensions } from "@helpers/Hooks";
import { TimezoneOptions } from "@containers/Auth/TeamCreation/TimeZone/TimeZone";

export type WideOnboardingProps = {
	// Custom classname to be added to the wrapper
	wrapperClassName?: string;
	// JSX element to be rendered on the left side
	children?: JSX.Element;
	// Optional height to be given to the wrapper
	height?: number;
};

export default function WideOnboarding(
	props: WideOnboardingProps
): JSX.Element {
	const { width } = Object(useWindowDimensions());
	const largeWidth = width >= 1920;
	const {
		wrapperClassName,
		children,
		height = largeWidth ? 591 : 477,
	} = props;

	return (
		// Temprarily adding the genemod-lightmode class name since we are currently only using light mode colors for anything auth related
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<LayerSystemContainer
				className={cn(
					"genemod-wide-wrapper",
					wrapperClassName,
					"genemod-lightmode"
				)}
				style={{
					height: height,
				}}
				overrideLayer={2}
			>
				<div className="left">{children}</div>
				<div className="right"></div>
			</LayerSystemContainer>
		</div>
	);
}

export function WIDEONBOARDING_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Example for the Email Verification"
					description="JSX element accepted for the left side. The height and dimension of the image will adjust to the dimension of the content on the left side."
				>
					<WideOnboarding>
						<div>
							<div
								style={{
									width: "100%",
									height: "100%",
									paddingTop: 48,
								}}
							>
								<Typography
									variant="title"
									bold
									color="text-primary"
									style={{
										whiteSpace: "pre",
										textAlign: "center",
										marginBottom: 36,
									}}
								>
									Email address verified.
									<br />
									You’re only a couple steps away
								</Typography>
								<Typography>
									Lorem ipsum dolor sit amet, consetetur
									sadipscing elitr, sed diam nonumy eirmod
									tempor invidunt ut labore et dolore magna
									aliquyam erat, sed diam voluptua. At vero
									eos et accusam et justo duo dolores et ea
									rebum. Stet
								</Typography>
								<Button
									style={{
										marginTop: 48,
										width: "100%",
										justifyContent: "center",
									}}
								>
									Continue
								</Button>
							</div>
						</div>
					</WideOnboarding>
				</Demo>
				<Demo
					title="Example for the Team Creation"
					description="An example for the team creation"
				>
					<WideOnboarding>
						<div>
							<Typography
								variant="title"
								bold
								color="text-primary"
								style={{
									textAlign: "center",
									marginBottom: 48,
								}}
							>
								Create your team
							</Typography>
							<Input label="Institution" labelPosition="top" />
							<Input label="Team name" labelPosition="top" />
							<Typography
								style={{
									marginBottom: 8,
								}}
							>
								Timezone
							</Typography>
							<Select style={{ width: "100%" }} isInput>
								{TimezoneOptions()}
							</Select>
							<Button
								style={{
									marginTop: 49,
									width: "100%",
									justifyContent: "center",
								}}
							>
								Create team
							</Button>
						</div>
					</WideOnboarding>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
