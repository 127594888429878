import { nanoid } from "nanoid";
import React, { useEffect, useMemo, useState } from "react";
import { Node, Transforms } from "slate";
import { ReactEditor, Slate } from "slate-react";
import { BaseEditor } from "../Editor/components/Editor/index";
import { ForceRenderElementsWrapper } from "../Editor/plugins/withForceRenderElementsPlugin";
import { MentionNotificationOptions } from "../Mention/Mention";
import { FONT_VARIANT } from "../Typography/Typography";
import {
	PluginOptions,
	useControlledSlateValue,
	useSlateTextAreaEditor,
} from "./SlateTextAreaHooks";
import {
	convertMentionSpecialSequenceToSlate,
	convertSlateToMentionSpecialSequence,
} from "./TextAreaMentionsSerializerHelpers";

export type EditableProps = { className?: string; style?: React.CSSProperties };

type SlateAndEditorProps = {
	value: string;
	onChange?: (value: string) => void;
	id?: string;
	placeholder?: string;
	placeholderVariant?: FONT_VARIANT;
	autoFocus?: boolean | "END" | "FORCED";
	slatePluginOptions: PluginOptions;
	readOnly: boolean;
	editableProps: EditableProps;
	notificationOptions?: MentionNotificationOptions;
	onFocus?: () => void;
	onBlur?: () => void;
};

/**
 * Combines a <Slate /> context component and the <BaseEditor /> component.
 * Meant for the simple usecase of the textarea.
 */
const SlateAndEditor = ({
	id,
	placeholder,
	autoFocus = false,
	slatePluginOptions,
	readOnly,
	editableProps,
	notificationOptions,
	onFocus,
	onBlur,
	placeholderVariant,
	...valueProps
}: SlateAndEditorProps): JSX.Element => {
	const [tmpId] = useState(nanoid());

	const editor = useSlateTextAreaEditor(
		placeholder,
		slatePluginOptions,
		notificationOptions,
		placeholderVariant
	);
	const initialSlateValue = editor.children;
	editor.readOnly = readOnly;

	useEffect(() => {
		if (!autoFocus) return;

		// Added latency to focus
		const timer = setTimeout(() => {
			// if autoFocus is set to "END", focus the end of the editor
			if (autoFocus === "END" || autoFocus === "FORCED") {
				const lastNode = editor.children[editor.children.length - 1];
				const lastNodePath = ReactEditor.findPath(editor, lastNode);
				const point = {
					path: lastNodePath,
					offset: Node.string(lastNode).length,
				};
				Transforms.select(editor, {
					anchor: point,
					focus: point,
				});
			}
			// Focus on the Slate node, cannot use React.focus because there is a bug
			// that scrolls the page to the top
			const el = ReactEditor.toDOMNode(editor, editor);
			if (!el) return;
			el.focus({ preventScroll: true });
		}, 250);
		return () => clearTimeout(timer);
	}, [autoFocus, editor]);

	const onSlateChange = useControlledSlateValue<string>({
		editor,
		...valueProps,
		fromSlateDescendants: convertSlateToMentionSpecialSequence,
		toSlateDescendants: convertMentionSpecialSequenceToSlate,
	});

	const style = useMemo(() => {
		return {
			...editableProps.style,
			width: "100%",
		};
	}, [editableProps.style]);

	return (
		<Slate
			key={`slate-${id || tmpId}`}
			editor={editor}
			value={initialSlateValue}
			onChange={onSlateChange}
		>
			<ForceRenderElementsWrapper editor={editor}>
				<BaseEditor
					editor={editor}
					className={editableProps.className}
					style={style}
					onFocus={onFocus}
					onBlur={onBlur}
					isTextArea
				/>
			</ForceRenderElementsWrapper>
		</Slate>
	);
};

export default SlateAndEditor;
