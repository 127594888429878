import React from "react";
import styles from "../FreezerTab.module.scss";
import {
	Typography,
	DropDown,
	Checkbox,
	GenemodDot,
	Button,
	GenemodFilter,
} from "@common/components";
import { Menu } from "antdv5";
import { ColorCssVarMap } from "@common/styles/Colors";
import { ITEM_STATUS } from "@common/types";

export type StatusFilterOption = {
	name: boolean;
	value: boolean;
	count: number;
};

type StatusColumnTitleProps = {
	statuses: StatusFilterOption[];
	statusCheckboxes: number[];
	updateMultipleParamOnURL: (
		param: string,
		value: number,
		add: boolean
	) => void;
	resetParamOnURL: (param: string[]) => void;
};
export const StatusColumnTitle = ({
	statuses,
	statusCheckboxes,
	updateMultipleParamOnURL,
	resetParamOnURL,
}: StatusColumnTitleProps) => {
	const filterApplied = statusCheckboxes.length !== 0;
	return (
		<div className={styles.columnHeader}>
			<Typography bold>Status</Typography>
			<DropDown
				overlayClassName={styles.dropdown}
				overlay={
					<Menu className={styles.menu}>
						<div className={styles.items}>
							<div className={styles.item}>
								<Checkbox
									className={styles.filterCheckbox}
									value={statusCheckboxes.includes(
										ITEM_STATUS.ACTIVE
									)}
									onChange={(val) => {
										updateMultipleParamOnURL(
											"status_in",
											ITEM_STATUS.ACTIVE,
											val as boolean
										);
									}}
								>
									<div className={styles.spacing}>
										<GenemodDot
											color={ColorCssVarMap["green"]}
										/>
										<Typography variant="label">
											Active
										</Typography>
									</div>
								</Checkbox>
							</div>
							<div className={styles.item}>
								<Checkbox
									className={styles.filterCheckbox}
									value={statusCheckboxes.includes(
										ITEM_STATUS.ARCHIVED
									)}
									onChange={(val) => {
										updateMultipleParamOnURL(
											"status_in",
											ITEM_STATUS.ARCHIVED,
											val as boolean
										);
									}}
								>
									<div className={styles.spacing}>
										<GenemodDot
											color={
												ColorCssVarMap["neutral-gray"]
											}
										/>
										<Typography variant="label">
											Archived
										</Typography>
									</div>
								</Checkbox>
							</div>
						</div>
						<div className={styles.reset}>
							<Button
								type="link"
								onClick={() => resetParamOnURL(["status_in"])}
							>
								Reset
							</Button>
						</div>
					</Menu>
				}
				getPopupContainer={() => document.body}
			>
				<div style={{ marginLeft: "auto" }}>
					<GenemodFilter
						applied={filterApplied}
						wrapperClassName={
							!filterApplied ? "isOnRepository" : ""
						}
					/>
				</div>
			</DropDown>
		</div>
	);
};
