import { DropDown, GenemodIcon } from "@common/components";
import { Typography } from "@components";
import { useSafeWindowEventListener } from "@helpers/Hooks";
import { Menu } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import styles from "./AttachmentPreview.module.scss";

export type AttachmentInfo = {
	name: string;
};

export type AttachmentPreviewContainerProps<T extends AttachmentInfo> = {
	/** Whether or not to show the preview. */
	visible: boolean;
	/** attahcment to be shown as preview */
	attachment: T | null | undefined;
	/** function to close preivew */
	onClose: () => void;
	/** funciton to download current attachemnt, if falsy will not render */
	onDownload?: (attachment: T | null | undefined) => void;
	/** function to delete current attachemnt */
	onDelete: undefined | ((attachment: T | null | undefined) => void);
	children: React.ReactNode;
};

/**
 * Container for the full screen file preview. Has the header.
 */
export default function AttachmentPreviewContainer<T extends AttachmentInfo>({
	visible,
	attachment,
	onClose,
	onDownload,
	onDelete,
	children,
}: AttachmentPreviewContainerProps<T>): JSX.Element {
	useSafeWindowEventListener("keydown", (ev) => {
		if (visible && ev.key === "Escape") {
			onClose();
		}
	});

	const renderHeader = () => (
		<div
			className={styles.header}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<div className={styles.headerIcons}>
				<GenemodIcon
					name="arrow-forward"
					hoverEffect
					size="large"
					style={{ transform: "rotate(180deg)" }}
					stroke="text-on-color"
					onClick={onClose}
				/>
				<Typography
					ellipsis
					style={{ width: 320 }}
					color="text-on-color"
				>
					{attachment?.name}
				</Typography>
			</div>
			<div className={styles.headerIcons}>
				{onDownload && (
					<GenemodIcon
						name="download"
						hoverEffect
						size="large"
						fill="text-on-color"
						onClick={() => {
							onDownload(attachment);
							onClose();
						}}
					/>
				)}
				{onDelete && (
					<DropDown
						overlay={
							<Menu>
								<Menu.Item
									key="delete"
									onClick={() => {
										onDelete(attachment);
										onClose();
									}}
								>
									Delete
								</Menu.Item>
							</Menu>
						}
						placement="bottomRight"
					>
						<GenemodIcon
							name="kabob"
							hoverEffect
							size="large"
							fill="text-on-color"
						/>
					</DropDown>
				)}
			</div>
		</div>
	);

	const preview = (
		<div className={styles.previewAttachment} contentEditable={false}>
			{renderHeader()}
			<div className={styles.closeCapture} onClick={onClose}>
				<div
					style={{ height: " 100%", width: "100%" }}
					onClick={(ev) => ev.stopPropagation()}
				>
					{children}
				</div>
			</div>
		</div>
	);

	if (!visible) {
		return <></>;
	}

	return ReactDOM.createPortal(
		preview,
		document.querySelector("#root") as Element
	);
}
