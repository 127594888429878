import { LoadingSpinner } from "@common/components";
import AnimatedPanel from "@containers/Freezer/components/AnimatedPanel";
import {
	useBoxTableActions,
	useBoxTableFields,
} from "@redux/freezer/BoxTableSlice";
import React, {
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import {
	useBoxView,
	useHandleAddOrEditItem,
	useSetCellId,
} from "../../BoxTableHooks";
import BoxInfo from "../BoxInfo/BoxInfo";
import ItemInfoPanel, { useItemForCellInfo } from "../CellInfo/CellInfo";
import CreateNewItem from "../../../CreateNewItem/CreateNewItem";
import styles from "./RightSideInfoPanels.module.scss";
import { SafeLeavingGuardContext } from "@common/context/SafeLeavingGuardContext";

type LoadingContextProps = { setIsUpdating: Dispatch<SetStateAction<boolean>> };
const LoadingContext = createContext({} as LoadingContextProps);

/**
 * All the panels that are displayed on the right side of the box table page
 */
const RightSideInfoPanels = (): JSX.Element => {
	const { cellId } = useBoxTableFields("cellId");
	const { box, isBoxLoading, isItemLoading, freezer } = useBoxView();
	const item = useItemForCellInfo();
	const setCellID = useSetCellId();
	const { setIsFocused, clearSelectedAreaBounds } = useBoxTableActions();

	const handleAddOrEditItem = useHandleAddOrEditItem();
	const [creating, setCreating] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const isLoading = isBoxLoading || isItemLoading || creating || isUpdating;
	const { setShouldBlock } = useContext(SafeLeavingGuardContext);

	useEffect(() => {
		setCreating(false);
	}, [item]);

	const isCreatePanelVisible = Boolean(
		(!isLoading && cellId && !item && !freezer?.is_archived) || creating
	);

	return (
		<LoadingContext.Provider value={{ setIsUpdating }}>
			<div className={styles.informationView}>
				<LoadingSpinner loading={isLoading} wrapper>
					{!item && <BoxInfo />}
				</LoadingSpinner>
				{!!item && (
					<div className={styles.itemInfoPanel}>
						<ItemInfoPanel />
					</div>
				)}
				<AnimatedPanel visible={isCreatePanelVisible}>
					<CreateNewItem
						key={cellId}
						setIsFocused={setIsFocused}
						onCancel={() => {
							setCellID(null);
							clearSelectedAreaBounds();
						}}
						focusedCell={cellId}
						handleSubmit={(item, callback) => {
							setCreating(true);
							handleAddOrEditItem(item, (newBox, item) => {
								callback?.(newBox, item);
							});
						}}
						dataFromFreezerView={{
							box,
							setShouldBlock,
							item_group: null,
						}}
						isCreatePanelVisible={isCreatePanelVisible}
						redirectTo={location.pathname}
					/>
				</AnimatedPanel>
			</div>
		</LoadingContext.Provider>
	);
};

const RightSideInfoPanelsMemo = React.memo(RightSideInfoPanels);
export default RightSideInfoPanelsMemo;
