import {
	Typography,
	UserAvatar,
	GenemodDot,
	Notification,
	CommonDeleteModal,
	ButtonV2,
} from "@common/components";
import React, { useState } from "react";
import styles from "./OrderingCard.module.scss";
import moment from "moment";
import {
	getFullNameFromAvatar,
	FreezerOrder,
	ORDER_TYPE_TO_LABEL,
	CURRENCY_TYPES,
} from "@common/types";
import { useOrderStatusAction } from "@common/helpers/Hooks/OrdersHooks";
import cn from "classnames";
import {
	useOrderDeleteMutation,
	useOrderPatchMutation,
} from "@redux/freezer/FreezerApiSlice";
import { formatCost, formatNumber } from "@helpers/Formatters";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import StatusLabel from "../../OrdersView/components/StatusLabel";
import { DropDownWithItems } from "@common/components/DropDown/dropdown";
import { useHistory } from "@helpers/Hooks/UseRouterDom";
import { FREEZER_PATHS } from "../../../Freezer";
import { useOrganizationRouter } from "@root/AppRouter";
import { useNavigateToCreateOrderPage } from "@common/helpers/Hooks/useNavigateToCreateOrderPage";
import { ColorCssVarMap } from "@common/styles/Colors";

type OrderingCardProps = {
	orderInfo: FreezerOrder;
	isExpanded: boolean;
	isUpdated: boolean;
	onClick: () => void;
};

export default function OrderingCard({
	orderInfo,
	isExpanded,
	isUpdated,
	onClick,
}: OrderingCardProps): JSX.Element {
	const dateFormat = "MMMM DD, YYYY";
	const orderDetailKeys: (keyof typeof ORDER_TYPE_TO_LABEL)[] = [
		"total_price",
		"price",
		"quantity",
		"source",
		"catalog",
		"lot",
		"grant_id",
	];
	const renderOrderHeader = () => (
		<div className={styles.orderHeader}>
			<div className={styles.headerContent}>
				<div className={styles.avatarBox}>
					<UserAvatar user={orderInfo.submitted_by} size={18} />
				</div>
				<div className={styles.nameAndDate}>
					<div>
						<Typography
							variant="subheadline"
							color="text-primary-v2"
						>
							{getFullNameFromAvatar(orderInfo.submitted_by)}
						</Typography>
					</div>
					<Typography variant="caption" color="text-tertiary-v2">
						{moment(orderInfo.submitted_at).format(dateFormat)}
					</Typography>
				</div>
			</div>
			<StatusLabel status={orderInfo.status} />
			{isUpdated && (
				<GenemodDot
					size={8}
					color={ColorCssVarMap["accent-strong"]}
					wrapperProps={{ className: styles.updatedDot }}
				/>
			)}
		</div>
	);

	const renderOrderInfo = () => (
		<div className={styles.orderInfo}>
			<div
				className={styles.orderBrief}
				style={{ marginBottom: isExpanded ? "48px" : "16px" }}
			>
				<OrderField
					field={"name"}
					label={ORDER_TYPE_TO_LABEL.name}
					value={orderInfo.name}
				/>
				<OrderField
					field={"order_number"}
					label={ORDER_TYPE_TO_LABEL.order_number}
					value={orderInfo.order_number}
				/>
			</div>
			{isExpanded && (
				<>
					<div className={styles.orderDetails}>
						{orderDetailKeys.map((key) => {
							const fieldName =
								key as keyof typeof ORDER_TYPE_TO_LABEL;
							let value = "-";

							if (
								fieldName === "price" ||
								fieldName === "total_price"
							) {
								if (orderInfo.price) {
									value = CURRENCY_TYPES[orderInfo.currency];
									// Substring to remove the dollar sign. Replace w/ user defined currency
									if (fieldName === "price") {
										value += formatCost(
											orderInfo.price
										).substring(1);
									} else {
										value += formatCost(
											orderInfo.quantity *
												parseFloat(orderInfo.price)
										).substring(1);
									}
								}
							} else if (fieldName === "quantity") {
								// Format w/ commas
								// https://stackoverflow.com/a/2901298
								value = orderInfo[fieldName]
									? formatNumber(orderInfo[fieldName])
									: "";
							} else {
								value = "" + orderInfo[fieldName];
							}

							return (
								<OrderField
									key={fieldName}
									field={fieldName}
									label={ORDER_TYPE_TO_LABEL[fieldName]}
									value={value}
								/>
							);
						})}
					</div>
				</>
			)}
		</div>
	);

	return (
		<div
			className={cn(styles.container, {
				[styles.container_expanded]: isExpanded,
			})}
		>
			<div className={styles.body} onClick={onClick}>
				{renderOrderHeader()}
				{renderOrderInfo()}
			</div>
			{isExpanded && <OrderCardFooter orderInfo={orderInfo} />}
		</div>
	);
}

type OrderFieldProps = {
	field: keyof typeof ORDER_TYPE_TO_LABEL;
	/** label of the field */
	label: string;
	/** value of the field */
	value: string;
};

function OrderField({ field, label, value }: OrderFieldProps): JSX.Element {
	return (
		<div className={styles.orderFieldContainer} style={{ gridArea: field }}>
			<Typography
				variant="subheadline"
				color="text-secondary-v2"
				semibold
			>
				{label}
			</Typography>
			<Typography
				className={styles.orderFieldValue}
				variant="subheadline"
				color="text-secondary-v2"
				withLinks={
					field === "order_number" ||
					field === "source" ||
					field === "catalog"
				}
				ellipsis
			>
				{value ? value : "-"}
			</Typography>
		</div>
	);
}

function OrderCardFooter({ orderInfo }: { orderInfo: FreezerOrder }) {
	const history = useHistory();
	const { appendBaseUrl } = useOrganizationRouter();
	const ordersPage = appendBaseUrl(FREEZER_PATHS.ORDERS);
	const [deleteOrder] = useOrderDeleteMutation();
	const [patchOrder] = useOrderPatchMutation();

	const [isDeleteOrderModalVisible, setIsDeleteOrderModalVisible] =
		useState(false);

	const { openOrdersInfoPanel } = useCommonPanelState("ordersInfoPanel");
	const { closeOrderingWidget } = useCommonPanelState("orderingWidget");
	const navigateToCreateOrderPage = useNavigateToCreateOrderPage();

	const orderStatusAction = useOrderStatusAction();
	const { onClick, label, disabled } = orderStatusAction(
		orderInfo.status,
		orderInfo.id
	);

	const handleDeleteOrder = () => {
		deleteOrder(orderInfo.id)
			.unwrap()
			.then(() =>
				Notification.success({
					message: `Order ${
						orderInfo.order_number
							? `#${orderInfo.order_number} `
							: ""
					} has been deleted.`,
				})
			)
			.catch(() =>
				Notification.warning({
					message: `Failed to delete order. Try again or contact us if it continues.`,
				})
			);
	};

	return (
		<div className={styles.footerContainer}>
			<div className={styles.buttons}>
				<ButtonV2
					type="link"
					onClick={() => {
						openOrdersInfoPanel({
							orderId: orderInfo.id,
							includeGoBackButton: true,
							defaultTab: "1",
						});
					}}
				>
					View details
				</ButtonV2>

				<ButtonV2
					type="link"
					onClick={() => onClick()}
					disabled={disabled}
				>
					{label}
				</ButtonV2>
			</div>
			<DropDownWithItems
				type="meatballs"
				menu={[
					{
						label: "Open in Orders",
						action: () => {
							history.push(ordersPage);
							openOrdersInfoPanel({
								orderId: orderInfo.id,
								defaultTab: "1",
							});
							closeOrderingWidget();
						},
					},
					{
						label: "Request again",
						action: () => {
							navigateToCreateOrderPage({
								mode: "create",
								prefill: orderInfo,
							});
						},
					},
					{
						label: "Archive",
						action: () =>
							patchOrder({
								id: orderInfo.id,
								is_archived: orderInfo.is_archived
									? false
									: true,
							}),
					},
					{
						label: "Delete",
						action: () => setIsDeleteOrderModalVisible?.(true),
					},
				]}
			/>
			<CommonDeleteModal
				visible={isDeleteOrderModalVisible}
				titleObject="order"
				bodyObject="this order"
				onOk={() => {
					handleDeleteOrder();
					setIsDeleteOrderModalVisible(false);
				}}
				onCancel={() => setIsDeleteOrderModalVisible(false)}
			/>
		</div>
	);
}
