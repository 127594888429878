import React, { useCallback, useState } from "react";
import { notification } from "antdv4";
import Typography from "../Typography/Typography";
import Button from "../Button";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import { RatingAndComment } from "../NPSFeedback/NPSFeedback";
import THANK_YOU from "./assets/thank-you.svg";
import styles from "./SurveyNotification.module.scss";
import { RATING_MAP } from "../NPSFeedback/data";
import {
	useFeedbackCreateMutation,
	useNpsStatusPatchMutation,
	useNpsStatusQuery,
} from "@redux/Dashboard/DashboardApiSlice";
import SVG from "../SVG/SVG";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import { User, NPSSubmissionStatusEnum } from "@common/types";

type RatingValues = keyof typeof RATING_MAP;

export const useSurveyNotification = () => {
	const { data: user } = useCurrentUserQuery();
	const { data: nps } = useNpsStatusQuery(user?.id || 0, {
		skip: !user,
	});
	const [npsPatch] = useNpsStatusPatchMutation();
	const [createFeedback, { isLoading }] = useFeedbackCreateMutation();
	const [isOpen, setIsOpen] = useState(false);

	const showNotification = useCallback(() => {
		const showNpsNotification = nps?.show_nps_notification;

		if (showNpsNotification && !isOpen) {
			setTimeout(() => {
				const key = `open${Date.now()}`;
				setIsOpen(true);
				npsPatch({
					status: NPSSubmissionStatusEnum.DELAYED,
					userId: user?.id || 0,
				});
				notification.open({
					message: (
						<Typography
							variant="subheadline"
							bold
							color="text-secondary"
							resize={false}
						>
							Hi {user?.first_name} 👋 , how likely would you
							recommend Genemod to your colleagues?
						</Typography>
					),
					description: (
						<NotificationForm
							createFeedback={createFeedback}
							isFeedbackLoading={isLoading}
							notificationKey={key}
							user={user}
						/>
					),
					placement: "bottomLeft",
					duration: 0,
					style: {
						left: 47,
						bottom: 8,
						backgroundColor: "var(--layer-03)",
						border: "1px solid var(--border-subtle)",
						boxShadow: "0 4px 15px 0 var(--shadow-overlay)",
					},
					closeIcon: <GenemodIcon name="exit" size="large" />,
					key,
				});
			}, 500);
		}
	}, [createFeedback, isLoading, nps, npsPatch, user, isOpen]);

	return {
		showNotification,
	};
};

type NotificationFormProps = {
	user: User | undefined;
	notificationKey: string;
	createFeedback: (props: {
		feedback: string;
		score: RatingValues | undefined;
	}) => void;
	isFeedbackLoading: boolean;
};

const NotificationForm = ({
	notificationKey,
	user,
	createFeedback,
	isFeedbackLoading,
}: NotificationFormProps) => {
	const [feedback, setFeedback] = useState("");
	const [score, setScore] = useState<undefined | RatingValues>(undefined);
	const [showFeedbackInput, setShowFeedbackInput] = useState<boolean>(false);

	const getThankYouText = () => {
		switch (score) {
			case 1:
			case 2:
			case 3:
				return "Our team might reach out to you to learn more about what went wrong and how we can do to improve your future experience.";
			case 4:
				return "We will continue to work hard to provide you with the best possible experience:)";
			case 5:
				return "It’s great to hear that you’re a fan of Genemod. Your feedback will help us discover new opportunities to improve our products:)";
			default:
				return "";
		}
	};

	return (
		<div>
			<RatingAndComment
				showFeedbackInput={showFeedbackInput}
				setShowFeedbackInput={setShowFeedbackInput}
				npsFeedback={{ feedback, score }}
				onChange={({ feedback, score }) => {
					setFeedback(feedback);
					setScore(score);
				}}
			/>
			{showFeedbackInput && (
				<div>
					<Button
						onClick={() => {
							notification.close(notificationKey);
							createFeedback({ feedback, score });
							notification.open({
								message: (
									<div
										className={
											styles.thankYouNotificationMessage
										}
									>
										<SVG
											src={THANK_YOU}
											alt="Thank you!"
											style={{ width: 180, height: 136 }}
										/>
										<Typography
											color="text-secondary"
											bold
											style={{ marginTop: 24 }}
										>
											Thank you, {user?.first_name}!
										</Typography>
									</div>
								),
								description: (
									<div
										className={
											styles.thankYouNotificationDescription
										}
									>
										<Typography
											color="text-secondary"
											resize={false}
											variant="label"
										>
											{getThankYouText()}
										</Typography>
									</div>
								),
								style: {
									width: 344,
									left: 47,
									bottom: 8,
									backgroundColor: "var(--layer-03)",
									border: "1px solid var(--border-subtle)",
									boxShadow:
										"0 4px 15px 0 var(--shadow-overlay)",
								},
								placement: "bottomLeft",
								closeIcon: (
									<GenemodIcon name="exit" size="large" />
								),
							});
						}}
						size="small"
						style={{ marginLeft: "auto", marginTop: 16 }}
						loading={isFeedbackLoading}
					>
						Submit
					</Button>
				</div>
			)}
		</div>
	);
};
