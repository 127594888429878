import { QuickAccessItemTypeEnum } from "@common/types";
import { FREEZER_PATHS } from "@containers/Freezer";
import { PM_ROUTES } from "@root/routes";
import { useQuickAccessItemCreateMutation } from "@redux/Dashboard/DashboardApiSlice";
import { useOrganizationRouter } from "@root/AppRouter";
import { useEffect } from "react";
import { useRouteMatch } from "@common/helpers/Hooks/UseRouterDom";
import { useParams } from "@helpers/URLParams";

/**
 * The Hook that recording the quick access Items when a url matches.
 */
export default function useQuickAccessItemsHook() {
	const [createQuickAccessItem] = useQuickAccessItemCreateMutation();
	const { appendBaseUrl } = useOrganizationRouter();
	const { getParam } = useParams();
	const freezerRoute = useRouteMatch<{ id: string }>({
		path: appendBaseUrl(FREEZER_PATHS.CONTENTS),
		exact: true,
		strict: true,
	});
	const boxRoute = useRouteMatch<{ freezer_id: string; box_id: string }>({
		path: appendBaseUrl(FREEZER_PATHS.BOXES),
		exact: true,
	});
	const itemGroupRoute = useRouteMatch<{ id: string; item_group_id: string }>(
		{
			path: appendBaseUrl(FREEZER_PATHS.ITEMGROUPS),
			exact: true,
		}
	);
	const spaceRoute = useRouteMatch<{ id: string }>({
		path: appendBaseUrl(FREEZER_PATHS.SPACE),
		exact: true,
		strict: true,
	});
	const consumableCategoryRoute = useRouteMatch<{
		furniture_category_id: string;
	}>({
		path: appendBaseUrl(FREEZER_PATHS.FURNITURE_CATEGORIES),
		exact: true,
		strict: true,
	});

	const projectId = getParam("projectId");
	const experimentId = getParam("fileId");
	const freezerId = freezerRoute?.params.id;
	const boxId = boxRoute?.params.box_id;
	const itemGroupId = itemGroupRoute?.params.item_group_id;
	const spaceId = spaceRoute?.params.id;
	const consumableCategoryId =
		consumableCategoryRoute?.params.furniture_category_id;

	useEffect(() => {
		if (!!projectId && !experimentId) {
			createQuickAccessItem({
				type: QuickAccessItemTypeEnum.PROJECT,
				project: +projectId,
			});
		} else if (!!projectId && !!experimentId) {
			createQuickAccessItem({
				type: QuickAccessItemTypeEnum.EXPERIMENT,
				experiment_file: +experimentId,
			});
		} else if (freezerId) {
			createQuickAccessItem({
				type: QuickAccessItemTypeEnum.FREEZER,
				freezer: +freezerId,
			});
		} else if (boxId) {
			createQuickAccessItem({
				type: QuickAccessItemTypeEnum.BOX,
				box: +boxId,
			});
		} else if (itemGroupId) {
			createQuickAccessItem({
				type: QuickAccessItemTypeEnum.ITEM_GROUP,
				item_group: +itemGroupId,
			});
		} else if (spaceId) {
			createQuickAccessItem({
				type: QuickAccessItemTypeEnum.SPACE,
				space: +spaceId,
			});
		} else if (consumableCategoryId) {
			createQuickAccessItem({
				type: QuickAccessItemTypeEnum.CONSUMABLES_CATEGORY,
				consumables_category: +consumableCategoryId,
			});
		}
	}, [
		projectId,
		experimentId,
		freezerId,
		boxId,
		spaceId,
		consumableCategoryId,
	]);
}
