import { Link } from "@common/helpers/Hooks/UseRouterDom";
import { ItemLocationData, SearchResultItem } from "@common/types";
import { GenemodIcon, LoadingSpinner, Typography } from "@components";
import { useOrganizationRouter } from "@root/AppRouter";
import { FREEZER_PATHS } from "@root/routes";
import React from "react";
import styles from "./LocationPopup.module.scss";
import { useParams } from "@helpers/URLParams";

type Props = {
	isLocationLoading: boolean;
	result: SearchResultItem;
	currentLocationData?: ItemLocationData;
};

export default ({ isLocationLoading, result, currentLocationData }: Props) => {
	const { getParam } = useParams();
	const isArchive = getParam("archive") === "true" ? true : false;

	const { appendBaseUrl } = useOrganizationRouter();

	if (!currentLocationData) return null;

	const getHistoryPath = (path: {
		freezerId: number;
		rackOrCategoryId?: number;
		boxOrItemGroupId?: number;
		isRack?: boolean;
		isBox?: boolean;
		itemId?: number;
	}) => {
		let pathname = appendBaseUrl(
			FREEZER_PATHS.CONTENTS.replace(":id", "" + path.freezerId)
		);
		const params = new URLSearchParams({
			return_url: location.pathname + location.search,
		});
		if (path.rackOrCategoryId) {
			if (path.isRack) {
				params.set("selected_rack", "" + path.rackOrCategoryId);
			} else {
				params.set("selected_category", "" + path.rackOrCategoryId);
			}
		}
		if (path.boxOrItemGroupId) {
			if (path.isBox) {
				pathname = appendBaseUrl(
					FREEZER_PATHS.BOXES.replace(
						":freezer_id",
						path.freezerId + ""
					).replace(":box_id", path.boxOrItemGroupId + "")
				);
				if (path.itemId) {
					params.set("item_id", "" + path.itemId);
				}
			} else {
				pathname = appendBaseUrl(
					FREEZER_PATHS.ITEMGROUPS.replace(
						":freezer_id",
						path.freezerId + ""
					).replace(":item_group_id", path.boxOrItemGroupId + "")
				);
				if (path.itemId) {
					params.set("item_id", "" + path.itemId);
				}
			}
		}
		return {
			pathname,
			search: params.toString(),
		};
	};

	return (
		<>
			<LoadingSpinner loading={isLocationLoading} centered size="large" />
			{!isLocationLoading && (
				<table>
					{currentLocationData.freezer_name ? (
						<tr>
							<td width={60}>
								<Typography
									variant="label"
									bold
									color="text-secondary"
									resize={false}
								>
									Freezer
								</Typography>
							</td>
							<td style={{ maxWidth: "200px" }}>
								<Link
									to={() =>
										getHistoryPath({
											freezerId:
												Number(
													currentLocationData?.freezer
												) || result.location?.freezer,
										})
									}
									className={styles.locationLink}
								>
									{currentLocationData.freezer_is_archived && (
										<GenemodIcon name="archive" />
									)}
									<Typography
										variant="label"
										color="button-text"
										className={styles.pathLinkText}
										resize={false}
									>
										{currentLocationData.freezer_name}
									</Typography>
								</Link>
							</td>
						</tr>
					) : null}
					{currentLocationData.shelf_name ? (
						<tr>
							<td width={60}>
								<Typography
									variant="label"
									bold
									color="text-secondary"
									resize={false}
								>
									Shelf
								</Typography>
							</td>
							<td style={{ maxWidth: "200px" }}>
								<Link
									to={() =>
										getHistoryPath({
											freezerId:
												Number(
													currentLocationData.freezer
												) || result.location.freezer,
										})
									}
									className={styles.locationLink}
								>
									{currentLocationData.shelf_is_archived && (
										<GenemodIcon name="archive" />
									)}
									<Typography
										variant="label"
										color="button-text"
										className={styles.pathLinkText}
										resize={false}
									>
										{currentLocationData.shelf_name}
									</Typography>
								</Link>
							</td>
						</tr>
					) : null}
					{(currentLocationData.rack_name ||
						currentLocationData.category) && (
						<tr>
							<td width={60}>
								<Typography
									variant="label"
									bold
									color="text-secondary"
								>
									{currentLocationData.rack
										? "Rack"
										: "Category"}
								</Typography>
							</td>
							<td style={{ maxWidth: "200px" }}>
								<Link
									to={() =>
										getHistoryPath({
											freezerId: Number(
												currentLocationData.freezer
											),
											rackOrCategoryId:
												currentLocationData.rack
													? currentLocationData.rack
													: currentLocationData.category,
											isRack: currentLocationData.rack
												? true
												: false,
										})
									}
									className={styles.locationLink}
								>
									{((currentLocationData.rack &&
										currentLocationData.rack_is_archived) ||
										(!currentLocationData.rack &&
											currentLocationData.category_is_archived)) && (
										<GenemodIcon name="archive" />
									)}
									<Typography
										variant="label"
										className={styles.pathLinkText}
										color="button-text"
									>
										{currentLocationData.rack
											? currentLocationData.rack_name
											: currentLocationData.category_name}
									</Typography>
								</Link>
							</td>
						</tr>
					)}
					{(result.location_data.item_group_name ||
						result.location_data.box_name) && (
						<tr>
							<td width={60}>
								<Typography
									variant="label"
									bold
									color="text-secondary"
								>
									{currentLocationData.item_group
										? "Item group"
										: "Box"}
								</Typography>
							</td>
							<td style={{ maxWidth: "200px" }}>
								<Link
									to={() =>
										getHistoryPath({
											freezerId:
												currentLocationData.freezer ||
												result.location.freezer,
											boxOrItemGroupId:
												currentLocationData.item_group ||
												currentLocationData.box,
											isBox: currentLocationData.box
												? true
												: false,
											itemId: !isArchive
												? currentLocationData.item
												: undefined,
										})
									}
									className={styles.locationLink}
								>
									{((result.location_data.box_name &&
										result.location_data.box_is_archived) ||
										(!result.location_data
											.item_group_name &&
											result.location_data
												.item_group_is_archived)) && (
										<GenemodIcon name="archive" />
									)}
									<Typography
										variant="label"
										color="button-text"
										className={styles.pathLinkText}
									>
										{result.location_data.box_name
											? result.location_data.box_name
											: result.location_data
													.item_group_name}
									</Typography>
								</Link>
							</td>
						</tr>
					)}
				</table>
			)}
		</>
	);
};
