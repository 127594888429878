import React, { useEffect } from "react";
// Props
// hotkeys: [{
//   key: string,
//   onPress: (event) =>
// }]
export default function KeyListener(props) {
	let fn = (e) => {
		if (props.hotkeys) {
			props.hotkeys.forEach((mapping) => {
				if (e.key === mapping.key && mapping.onPress) {
					mapping.onPress(e);
				}
			});
		}
	};

	useEffect(() => {
		document.addEventListener("keypress", fn);
		return () => {
			document.removeEventListener("keypress", fn);
		};
	});

	return <>{props.children}</>;
}
