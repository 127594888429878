import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			data-name="type_video (x24)"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				data-name="Icon material-movie"
				d="m14.2 6 1.4 2.8h-2.1L12.1 6h-1.4l1.4 2.8H10L8.6 6H7.2l1.4 2.8H6.5L5.1 6h-.7a1.4 1.4 0 0 0-1.393 1.4L3 15.8a1.4 1.4 0 0 0 1.4 1.4h11.2a1.4 1.4 0 0 0 1.4-1.4V6z"
				transform="translate(2)"
				style={{ fill: "#c1c1c3" }}
			/>
		</svg>
	);
}
const MiniVideoIcon = React.memo(SvgComponent);
export default MiniVideoIcon;
