export type DocumentPermissions = {
	text: boolean;
	image: boolean;
	table: boolean;
	date: boolean;
	link: boolean;
	notes: boolean;
	list: boolean;
};

export const DisabledDocumentPermissions = Object.freeze({
	text: false,
	image: false,
	table: false,
	date: false,
	link: false,
	notes: false,
	list: false,
} as const);

export const EnabledDocumentPermissions = Object.freeze({
	text: true,
	image: true,
	table: true,
	date: true,
	link: true,
	notes: true,
	list: true,
} as const);
