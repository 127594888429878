import { PricingCardPlanName } from "@containers/AccountSettings/components/ChangePlan/types/PricingCardPlanName";
import { useAnalyticsEvent } from "@redux/Analytics/AnalyticsApi";
import { useEffect, useRef } from "react";
import { useCurrentPlanNameHook } from "./featureRestrictionHook";
import useCurrentOrganization from "./useCurrentOrganizationHook";
import useCurrentTeamMembers from "./useCurrentTeamMembersHooks";

/**
 * Sends new member invite for first and second invited members
 */
export const useNewTeamInvitesUserEvent = () => {
	const sendEvent = useAnalyticsEvent("NEW_TEAM_INVITES_OTHER_USER");

	const { teamMembers } = useCurrentTeamMembers();

	return () => {
		if (teamMembers && teamMembers.length < 3) {
			sendEvent();
		}
	};
};

/**
 * Send event when a team first starts a subscription
 */
export const useNewSubscriptionEvent = () => {
	const sendEvent = useAnalyticsEvent("NEW_SUBSCRIPTION");
	const { planName } = useCurrentPlanNameHook();

	const isFreePlan = planName === "free";

	return () => {
		if (isFreePlan) {
			sendEvent();
		}
	};
};

/**
 * Sends choose plan event when a user clicks "continue" on a plan
 */
export const useChoosePlanEvent = () => {
	const sendEvent = useAnalyticsEvent("CHOOSE_PLAN");
	return (plan: PricingCardPlanName) => sendEvent({ plan });
};

/**
 * Sends two day reegagement even if it has been more than 24 hours after team creation (on login!)
 * Note: stops sending after a week so it doesn't become spammy.
 */
export const useTwoDayReengagementEvent = () => {
	const sendEvent = useAnalyticsEvent("TWO_DAY_RE_ENGAGEMENT");
	const { organization } = useCurrentOrganization();
	const previousTeam = useRef(organization);

	// Fetch user after login, when the auth token allows the backend to determine the user identity
	useEffect(() => {
		if (!previousTeam.current && organization) {
			const oneDayAgo = new Date(
				new Date().getTime() - 1000 * 60 * 60 * 24
			);
			const oneWeekAgo = new Date(
				new Date().getTime() - 1000 * 60 * 60 * 24 * 7
			);
			const created = new Date(organization.created);
			if (created < oneDayAgo && created > oneWeekAgo) {
				sendEvent();
			}
		}
		previousTeam.current = organization;
	}, [previousTeam, organization]);
};
