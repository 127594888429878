import React, { createContext, useContext } from "react";
import { useConfig } from "@common/config/GenemodConfig";
import { CognitoProvider } from "./Cognito/CognitoContext";
import { DrfLoginProvider } from "./DrfLogin/DrfContext";

export type LoginStatus = "LOADING" | "LOGGEDIN" | "LOGGEDOUT";

export type RegistrationFormData = {
	email: string;
	password: string;
	first_name: string;
	last_name: string;
};

export type LoginArgs = {
	email: string;
	password: string;
};

// Result types
type AuthResultFailure = {
	type: "failure";
	error: { name: string; message: string };
};
type AuthResultSuccess = {
	type: "success";
};
export type AuthResult = AuthResultSuccess | AuthResultFailure;

export type EmptyResultError = {
	name: "EmptyResultError";
	message: string;
};

type LoginContextProps = {
	loginStatus: LoginStatus;
	register: ({
		email,
		password,
		first_name,
		last_name,
	}: RegistrationFormData) => Promise<AuthResult>;
	login: (
		loginArgs: LoginArgs,
		rememberLogin: boolean
	) => Promise<AuthResult>;
	logout: () => void;
	updatePassword: (
		oldPassword: string,
		newPassword: string
	) => Promise<AuthResult>;
	resetForgottenPassword: (arg: {
		email: string;
		newPassword: string;
		verificationParams: /* For cognito */
		{ code: string } | /* For DRF */ { uid: string; token: string };
	}) => Promise<AuthResult>;
	sendForgotEmailPassword: (email: string) => Promise<AuthResult>;
};

const DUMMY_FUNC: any = () => {
	console.error("Dummy function called unexpectedly!");
};

export const LoginContext = createContext<LoginContextProps>({
	loginStatus: "LOADING",
	register: DUMMY_FUNC,
	login: DUMMY_FUNC,
	logout: DUMMY_FUNC,
	updatePassword: DUMMY_FUNC,
	resetForgottenPassword: DUMMY_FUNC,
	sendForgotEmailPassword: DUMMY_FUNC,
});

export type LoginProviderProps = { children: React.ReactNode };

export const LoginProvider = ({ children }: LoginProviderProps) => {
	const authSystem = useConfig("REACT_APP_AUTH_SYSTEM");

	if (authSystem === "COGNITO") {
		return <CognitoProvider>{children}</CognitoProvider>;
	}
	return <DrfLoginProvider>{children}</DrfLoginProvider>;
};

export const useLoginContext = () => {
	return useContext(LoginContext);
};
