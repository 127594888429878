import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
	Button,
	Modal,
	Typography,
	GenemodIcon,
	ProgressBar,
	CommonDeleteModal,
} from "@components";
import "./UploadAttachmentModal.scss";
import { IMAGE_ACCEPTED_FILES } from "@common/types/FileAttachment";
import { ColorCssVarMap } from "@common/styles/Colors";

type UploadAttachmentModalProps = {
	visible: boolean;
	onCancel: () => void;
	uploadCompleted: (file: any) => void;
	uploadingRate: number;
	type?: "attachment" | "image";
};

function UploadAttachmentModal({
	visible,
	onCancel,
	uploadCompleted,
	uploadingRate,
	type = "attachment",
}: UploadAttachmentModalProps): JSX.Element {
	const generateAcceptedFiles = (fileTypes: any) => {
		return fileTypes.map((ext: any) => "." + ext);
	};

	const VALID_IMG_EXTENTIONS = generateAcceptedFiles(IMAGE_ACCEPTED_FILES);

	const errorMessage = {
		INVALID: "File type not supported.",
		OVERSIZE: "File size exceeds 50MB.",
	};

	const uploadType = type ? type : "attachment";
	const acceptedFileList =
		uploadType === "attachment" ? [] : VALID_IMG_EXTENTIONS;

	const MAX_SIZE = 50 * (1024 * 1024); //50MB
	const [error, setError] = React.useState<any | null>(null);
	const [fileData, setFileData] = React.useState<any | null>(null);

	useEffect(() => {
		if (!visible) {
			resetPicker();
		}
	}, [visible]);

	const onDrop = (acceptedFiles: any, fileRejections: any, event: any) => {
		//If there is not accpectedFiles or there is a fileRejection file and it is same as first uploaded file, then show error
		if (
			!acceptedFiles[0] ||
			(fileRejections[0] &&
				event.dataTransfer.files[0] === fileRejections[0].file)
		) {
			setError({
				fileName: fileRejections[0].file.name,
				message:
					fileRejections[0].errors[0].code === "file-invalid-type"
						? errorMessage.INVALID
						: errorMessage.OVERSIZE,
			});
		} else {
			setFileData(acceptedFiles[0]);
			uploadCompleted(acceptedFiles[0]);
		}
	};

	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		onDrop,
		accept: acceptedFileList,
		noClick: true,
		noKeyboard: true,
		multiple: true,
		maxSize: MAX_SIZE,
	});

	const resetPicker = () => {
		setError(null);
		setFileData(null);
	};

	const renderErrorView = () => (
		<div className="error">
			<div className="errorFile">
				<GenemodIcon
					name="file"
					decorative
					style={{ marginRight: 8 }}
				/>
				<Typography>{error.fileName}</Typography>
				<GenemodIcon
					name="cancel"
					style={{ marginLeft: 10 }}
					color="red"
					onClick={resetPicker}
				/>
			</div>
			<div className="errorMessage">
				<Typography>Could not upload. {error.message}</Typography>
			</div>
		</div>
	);

	const renderRegularView = () => (
		<div className="textLabel">
			<Typography style={{ marginRight: "8px" }}>
				Drag and drop your file here or
			</Typography>
			<Button size="small" type="ghost" onClick={open}>
				Choose a file
			</Button>
		</div>
	);

	const renderProgressBarView = () => (
		<div className="progressbar">
			<ProgressBar
				value={uploadingRate / 100}
				suffix={uploadingRate + "%"}
				label={fileData.name || ""}
			/>
		</div>
	);
	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={496}
			footer={null}
			title={`Upload ${type === "attachment" ? "file" : "image"}`}
			subtitle={
				type !== "attachment"
					? `Supported type: ${acceptedFileList.join(", ")}`
					: undefined
			}
		>
			<div className="uploadFile">
				<div
					className="importdropzone"
					{...getRootProps({
						style: {
							outline: "none",
							borderColor: "",
							overflow: "hidden",
							flexDirection: "column",
							backgroundColor: isDragActive
								? ColorCssVarMap["layer-hover-01"]
								: "transparent",
						},
					})}
				>
					<input {...getInputProps()} />
					{error
						? renderErrorView()
						: fileData
						? renderProgressBarView()
						: renderRegularView()}
				</div>
			</div>
		</Modal>
	);
}

type DeleteFileModalProps = {
	visible: boolean;
	onCancel: () => void;
	onDelete: () => void;
};

export function DeleteAttachmentModal({
	visible,
	onCancel,
	onDelete,
}: DeleteFileModalProps): JSX.Element {
	return (
		<CommonDeleteModal
			visible={visible}
			titleObject="attachment"
			bodyObject="this attachment"
			onOk={onDelete}
			onCancel={onCancel}
		/>
	);
}

export default UploadAttachmentModal;
