import { useState, useEffect } from "react";
import { useDocumentGetQuery } from "@redux/ProjectManagement/PmApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { GenemodDocumentSettings, GenemodDocumentUUID } from "@common/types";

export const useGenemodDocumentSettings = (
	documentId: GenemodDocumentUUID | undefined
): [
	GenemodDocumentSettings,
	(preferences: GenemodDocumentSettings) => void
] => {
	const { data: document } = useDocumentGetQuery(documentId || skipToken);
	const [preferences, _setPreferences] = useState<GenemodDocumentSettings>({
		numbered_lists: false,
		numbered_sublists: false,
		bulleted_lists: false,
		bulleted_sublists: false,
	});

	useEffect(() => {
		_setPreferences({
			numbered_lists: document?.settings?.numbered_lists || false,
			numbered_sublists: document?.settings?.numbered_sublists || false,
			bulleted_lists: document?.settings?.bulleted_lists || false,
			bulleted_sublists: document?.settings?.bulleted_sublists || false,
		});
	}, [document?.settings]);

	const setPreferences = (newPreferences: GenemodDocumentSettings) => {
		_setPreferences(newPreferences);
	};

	return [preferences, setPreferences];
};
