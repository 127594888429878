import React, { useEffect, useState } from "react";
import NavigationList from "./";

import { Button, Typography } from "@common/components";
import { Box, Category, Rack, isRack } from "@common/types";
import { useLazyBoxesQuery } from "@redux/inventory/Box";
import { useFreezerQuery } from "@redux/inventory/Freezer";
import { useRacksQuery } from "@redux/inventory/Rack";
import { useCategoriesQuery, useShelfQuery } from "@redux/inventory/Shelf";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ColumnProps } from "antd/es/table/interface";
import { MovingCartRackDisplay } from "../BoxMovingCart";
import styles from "./index.module.scss";

type RackListProps = {
	/** ID of the parent shelf */
	shelfId: number | null;
	/** Sets the selected shelf id to navigate back*/
	setshelfId: (shelfId: number | null) => void;
	/** Sets the selected rack id */
	setRackId: (rackId: number | null) => void;
	/** Sets the Selected Category id */
	setCategoryId: (categoryId: number | null) => void;
};

export default function RackList({
	shelfId,
	setshelfId,
	setRackId,
	setCategoryId,
}: RackListProps): JSX.Element {
	const [hoveredRackId, setHoveredRackId] = useState<number | null>(null);
	const [isBoxesLoading, setIsBoxesLoading] = useState(true);
	const [boxes, setBoxes] = useState<Box[]>([]);
	const [isHoveredRack, setIsHoveredRack] = useState(false);
	const { data: shelf, isLoading: isShelfLoading } = useShelfQuery(
		shelfId || skipToken
	);
	const { data: freezer } = useFreezerQuery(shelf?.freezer || skipToken);
	const { data: racks = [], isLoading: isRacksLoading } = useRacksQuery(
		shelfId || skipToken
	);
	const { data: categories = [], isLoading: isCategoriesLoading } =
		useCategoriesQuery(shelfId || skipToken);
	const [getRackBoxes] = useLazyBoxesQuery();

	useEffect(() => {
		(async () => {
			setIsBoxesLoading(true);
			const newBoxes = [];
			for (let i = 0; i < racks.length; i++) {
				const rack = racks[i];
				const { data } = await getRackBoxes({
					id: rack.id,
				});
				if (data?.length) {
					newBoxes.push(...data);
				}
			}
			setBoxes(newBoxes);
			setIsBoxesLoading(false);
		})();
	}, [racks]);

	const COLUMNS: Array<ColumnProps<Rack | Category>> = [
		{
			dataIndex: "name",
			ellipsis: true,
			render: (name, record) => {
				const rackBoxes = boxes.filter(
					(b) => b.location.rack_location?.rack === record.id
				);
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 4,
						}}
					>
						<Typography>{name}</Typography>{" "}
						{isRack(record) && (
							<Typography
								color="text-tertiary-v2"
								style={{
									color: "var(--text-tertiary-v2) !important",
								}}
							>
								{(record as Rack)?.columns *
									(record as Rack)?.rows -
									rackBoxes.length}{" "}
								slots available
							</Typography>
						)}
					</div>
				);
			},
		},
		{
			dataIndex: "id",
			render: (id: number, record: Rack | Category) => (
				<Button
					type="link"
					className={styles.linkButton}
					onClick={() => {
						if (isRack(record)) {
							setRackId(id);
							setCategoryId(null);
						} else {
							setCategoryId(id);
							setRackId(null);
						}
					}}
				>
					Open
				</Button>
			),
			width: 88,
			align: "center",
		},
	];

	return (
		<div className={styles.listPreviewWrapper}>
			<NavigationList
				navigationLevel="shelf"
				onNavigateForward={(obj) => {
					if (isRack(obj)) {
						setRackId(obj.id);
						setCategoryId(null);
					} else {
						setCategoryId(obj.id);
						setRackId(null);
					}
				}}
				onNavigateBack={() => setshelfId(null)}
				parentName={shelf?.name}
				childrenList={
					[...racks, ...categories] as Array<Rack | Category>
				}
				columns={COLUMNS}
				loading={
					isShelfLoading ||
					isRacksLoading ||
					isCategoriesLoading ||
					isBoxesLoading
				}
				onRow={(obj: Rack | Category) => ({
					onMouseEnter: () => {
						setHoveredRackId(obj.id);

						if (isRack(obj)) {
							setIsHoveredRack(true);
						} else {
							setIsHoveredRack(false);
						}
					},
				})}
				navigationElements={[
					{
						name: freezer?.name || "",
						onClick: () => {
							setshelfId(null);
							setRackId(null);
						},
					},
					{
						name: shelf?.name || "",
						onClick: () => {},
					},
				]}
			/>
			{true && (
				<div className={styles.rackPreview}>
					<MovingCartRackDisplay
						rackId={isHoveredRack ? hoveredRackId : null}
						categoryId={isHoveredRack ? null : hoveredRackId}
					/>
				</div>
			)}
		</div>
	);
}
