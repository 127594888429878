import React, { forwardRef, ReactNode, useState } from "react";
import "./Select.scss";
import styles from "./Select.module.scss";
import { Select as ST } from "antd";
import {
	DemoWrapper,
	DemoSection,
	Demo,
	GenemodIcon,
	Typography,
} from "../index";
import className from "classnames";
import { SelectProps as AntdSelectProps, OptionProps } from "antd/lib/select";

export type GenemodSelectProps = AntdSelectProps &
	OptionProps & {
		// If true, we render a small version of the select
		isSmall?: boolean;
		// Set to true if the component is used along with other input fields
		isInput?: boolean;
		// If true, set the background to transparent. Usually used for filtering
		noBackground?: boolean;
		// If true, set the stroke color of the icon instead of fill color of the icon. Usually set the fill color of the icon when it is focused
		isStrokeIcon?: boolean;
		// If true, change color to red
		error?: boolean | string;
		// An icon that's attached to the dropdown menu. If not given, it will display the caret-down icon
		suffixIcon?: React.ReactNode;
		// Adding a class name to the dropdown
		dropdownClassName?: string;
		// Appends the drop down menu to the select container and sets up positions so it should scroll with the container
		scrollSafeMode?: boolean;
		// Classname for the component wrapper
		wrapperClassname?: string;
		// Open select by default
		defaultOpen?: boolean;
		// Manually set the visibility of the dropdown
		visible?: boolean;
		//Data-cy selector
		dataCy?: string;
	};

const Select = forwardRef<HTMLElement, GenemodSelectProps>((props, ref) => {
	const [visible, setVisible] = useState(props.defaultOpen || props.visible);
	const [wasClicked, setWasClicked] = useState(false);

	const handleFocus = () => {
		if (!wasClicked) {
			setVisible(true);
		}
	};

	const handleBlur = () => {
		setVisible(false);
	};

	const handleVisibleChange = (open: boolean) => {
		setVisible(open);
	};

	return (
		<div
			className={className(
				"genemod-select-container",
				props.wrapperClassname
			)}
			style={{
				position: "relative",
			}}
			onKeyDown={(e) => {
				if (visible) {
					if (e.key === "Tab") {
						setVisible(false);
					}
				}
			}}
			data-cy={props.dataCy}
		>
			<ST
				dropdownMatchSelectWidth={false}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onDropdownVisibleChange={handleVisibleChange}
				onMouseEnter={() => setWasClicked(true)}
				onMouseLeave={() => setWasClicked(false)}
				open={visible}
				getPopupContainer={(trigger: any) => trigger.parentNode}
				{...props}
				dropdownClassName={className(
					"genemod-select-dropdown",
					props.dropdownClassName,
					{
						"genemod-select-dropdown__isSmall": props.isSmall,
						"genemod-select-dropdown__isInput": props.isInput,
					}
				)}
				className={className("genemod-select", props.className, {
					"genemod-select__error": props.error,
					"genemod-select__isSmall": props.isSmall,
					"genemod-select__isInput": props.isInput,
					"genemod-select__noBackground": props.noBackground,
					"genemod-select__isStrokeIcon": props.isStrokeIcon,
					"genemod-select__isFilled":
						(props.isInput &&
							props.value !== null &&
							props.value !== undefined) ||
						(props.isInput &&
							props.defaultValue !== null &&
							props.defaultValue !== undefined),
				})}
				suffixIcon={
					props.suffixIcon || (
						<GenemodIcon name="caret-down" fill="text-secondary" />
					)
				}
			>
				{props.children}
			</ST>
			{typeof props.error === "string" && (
				<Typography
					style={{ marginTop: 8, marginBottom: -24 }}
					variant="caption"
					color="red-contrast"
				>
					{props.error}
				</Typography>
			)}
		</div>
	);
});

type SelectedOptionProps = {
	isSelected?: boolean;
	label?: string | number | React.ReactNode;
};

function SelectedOption({
	isSelected = false,
	label,
}: SelectedOptionProps): JSX.Element {
	return (
		<div className={"selected-option-flex"}>
			<div>{label}</div>
			{isSelected && (
				<GenemodIcon name="dropdown-check" style={{ marginLeft: 12 }} />
			)}
		</div>
	);
}

type OptionWithCheckmarkProps = {
	children: React.ReactNode;
};

/**
 * An upgraded version of SelectedOption, don't need to worry about values or labels.
 */
function OptionWithCheckmark({ children }: OptionWithCheckmarkProps) {
	return (
		<div className={styles.selectedOptionFlex}>
			<div>{children}</div>
			<GenemodIcon
				name="dropdown-check"
				className={styles.dropdownCheck}
				style={{ marginLeft: 12 }}
			/>
		</div>
	);
}

const extras = {
	Option: ST.Option,
	OptGroup: ST.OptGroup,
	SelectedOpt: SelectedOption,
	OptionWithCheckmark: OptionWithCheckmark,
};
Object.assign(Select, {
	Option: ST.Option,
	OptGroup: ST.OptGroup,
	SelectedOpt: SelectedOption,
	OptionWithCheckmark: OptionWithCheckmark,
});
export default Select as typeof Select & typeof extras;

export const SELECT_DEMO = () => {
	const { Option } = ST;

	const [demoValue, setDemoValue] = useState(0);

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Select (normal size)"
					description="See AntDesign Select for API"
				>
					<Select
						value={demoValue}
						onChange={(val: any) => {
							setDemoValue(val);
						}}
						optionLabelProp="label"
					>
						{Array(5)
							.fill(0)
							.map((x, index) => {
								return (
									<Option
										key={index}
										label={
											<React.Fragment>
												<div
													style={{ display: "flex" }}
												>
													{index}
												</div>
											</React.Fragment>
										}
									>
										<SelectedOption
											isSelected={demoValue == index}
											label={index}
										/>
									</Option>
								);
							})}
					</Select>
				</Demo>
				<Demo
					title="Select (small size)"
					description="The size is 32px. See AntDesign Select for API"
				>
					<Select defaultValue={0} defaultActiveFirstOption isSmall>
						{Array(5)
							.fill(0)
							.map((x, index) => {
								return <Option key={index}>{index}</Option>;
							})}
					</Select>
				</Demo>
				<Demo
					title="Select (with inputs)"
					description="Use this one with other input fields"
				>
					<Select defaultValue={0} defaultActiveFirstOption isInput>
						{Array(5)
							.fill(0)
							.map((x, index) => {
								return <Option key={index}>{index}</Option>;
							})}
					</Select>
				</Demo>
				<Demo
					title="Select (with no background)"
					description="Use this one for filtering"
				>
					<Select
						defaultValue={0}
						defaultActiveFirstOption
						noBackground
					>
						{Array(5)
							.fill(0)
							.map((x, index) => {
								return <Option key={index}>{index}</Option>;
							})}
					</Select>
				</Demo>
				<Demo
					title="Error state"
					description="Turns red if error prop is true"
				>
					<Select defaultValue={0} defaultActiveFirstOption error>
						{Array(5)
							.fill(0)
							.map((x, index) => {
								return <Option key={index}>{index}</Option>;
							})}
					</Select>
				</Demo>
				<Demo
					title="Custom Suffix Icon"
					description="Ability to change icon next to select. If there is no provided suffixIcon, will default to caret-down"
				>
					<Select
						defaultValue={0}
						defaultActiveFirstOption
						suffixIcon={
							<GenemodIcon name="meatballs" size="small" />
						}
					>
						{Array(5)
							.fill(0)
							.map((x, index) => {
								return <Option key={index}>{index}</Option>;
							})}
					</Select>
				</Demo>
				<Demo
					title="Prefix icon"
					description="Adding prefix icon to the select"
				>
					<Select
						suffixIcon={
							<GenemodIcon name="meatballs" size="small" />
						}
						optionLabelProp="label"
						defaultValue={"0"}
						style={{ width: "auto" }}
					>
						{Array(5)
							.fill(0)
							.map((x, index) => {
								return (
									<Option
										key={index}
										label={
											<React.Fragment>
												<div
													style={{ display: "flex" }}
												>
													<GenemodIcon
														name="filter"
														className="filter-btn"
														size="small"
														style={{
															marginRight: "8px",
														}}
													/>
													{index}
												</div>
											</React.Fragment>
										}
									>
										{index}
									</Option>
								);
							})}
					</Select>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
};
