import {
	Item,
	ItemGroup,
	ItemGroupDataForBackend,
	PaginatedSearchQuery,
	PaginatedSearchResults,
} from "@common/types";
import { inventoryApi, withFreezerBase } from ".";
import { ItemGroupStorageProps } from "@containers/Freezer/components/BoxReagentSetting/BoxReagentSetting";
import { getItemBackendFormat } from "@helpers/BoxHelper";
import { AtLeastId } from "@helpers/TypeHelpers";

export type RepositoryItemGroupFilters = Omit<
	PaginatedSearchQuery &
		Partial<{
			id: number | null;
			ordering: string | null;
			search: string | null;
			status__in: number[] | null;
			created_at__gte: string | null;
			created_at__lte: string | null;
			created_by__in: string[] | null;
			updated_at__gte: string | null;
			updated_at__lte: string | null;
			updated_by__in: string[] | null;
			location__category: number | null;
			location__category__in: number[] | null;
			location__isnull: number;
			include_filter_options: boolean | null;
			item_type__in: number[] | null;
			container_type__in: number[] | null;
		}>,
	"sort_by"
>;
export type RepositoryItemGroupResult = PaginatedSearchResults<ItemGroup>;

const itemGroupApi = inventoryApi.injectEndpoints({
	endpoints: (builder) => ({
		itemGroups: builder.query<ItemGroup[], number>({
			query: () => ({
				url: withFreezerBase(`v2/item-groups/`),
			}),
			providesTags: ["ItemGroup"],
		}),
		itemGroup: builder.query<ItemGroup, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/item-groups/${id}/`),
			}),
			providesTags: ["ItemGroup"],
		}),
		itemGroupCreate: builder.mutation<
			ItemGroup,
			{ itemGroupData: ItemGroupDataForBackend }
		>({
			query: (body) => ({
				url: withFreezerBase(`v2/item-groups/`),
				method: "POST",
				body: body.itemGroupData,
			}),
			invalidatesTags: ["ItemGroup"],
		}),
		itemGroupPatch: builder.mutation<ItemGroup, AtLeastId<ItemGroup>>({
			query: (body) => ({
				url: withFreezerBase(`v2/item-groups/${body.id}/`),
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["ItemGroup"],
		}),
		itemGroupDelete: builder.mutation<void, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/item-groups/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["ItemGroup"],
		}),
		itemGroupsFromCategory: builder.query<
			ItemGroup[],
			{ id: number; is_archived?: boolean }
		>({
			query: ({ id, is_archived = false }) => ({
				url: withFreezerBase(`v2/categories/${id}/item-groups/`),
				params: {
					is_archived,
				},
			}),
			providesTags: ["ItemGroup"],
		}),
		itemGroupBulkCreate: builder.mutation<
			Item[],
			{ item_group: number; items: Partial<Item>[] }
		>({
			query: ({ item_group, items }) => ({
				url: withFreezerBase(
					`v2/item-groups/${item_group}/bulk-create-items/`
				),
				method: "POST",
				body: items.map((item) => getItemBackendFormat(item)),
			}),
			invalidatesTags: ["ItemGroup", "Item"],
		}),
		getItemGroupStorageSummary: builder.query<
			ItemGroupStorageProps,
			{ item_group: number }
		>({
			query: ({ item_group }) => ({
				url: withFreezerBase(
					`v2/item-groups/${item_group}/storage-summary/`
				),
			}),
		}),
		getRepositoryItemGroups: builder.query<
			RepositoryItemGroupResult,
			RepositoryItemGroupFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/item-groups-repository/"),
				method: "GET",
				params,
			}),
			providesTags: ["ItemGroup"],
		}),
		restorableItemGroups: builder.query<
			RepositoryItemGroupResult,
			RepositoryItemGroupFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/restorable-item-groups/"),
				method: "GET",
				params,
			}),
			providesTags: ["ItemGroup"],
		}),
		getRepositoryItemGroup: builder.query<ItemGroup, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/item-groups-repository/${id}/`),
				method: "GET",
			}),
			providesTags: ["ItemGroup"],
		}),
		itemGroupRepositoryBulkCreate: builder.mutation<
			ItemGroup[],
			Partial<ItemGroupDataForBackend>[]
		>({
			query: (body) => ({
				url: withFreezerBase("v2/item-groups-repository/bulk-create/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["ItemGroup"],
		}),
		itemGroupRepositoryBulkUpdate: builder.mutation<
			ItemGroup[],
			Partial<ItemGroupDataForBackend>[]
		>({
			query: (body) => ({
				url: withFreezerBase("v2/item-groups-repository/bulk-update/"),
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["ItemGroup", "Item"],
		}),
		itemGroupRepositoryBulkDelete: builder.mutation<
			void,
			{ item_group_ids: number[] }
		>({
			query: (body) => ({
				url: withFreezerBase("v2/item-groups-repository/bulk-delete/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["ItemGroup"],
		}),
	}),
});

export const {
	// ItemGroup crud
	useItemGroupsQuery,
	useItemGroupQuery,
	useItemGroupCreateMutation,
	useItemGroupPatchMutation,
	useItemGroupDeleteMutation,
	useItemGroupsFromCategoryQuery,
	useItemGroupBulkCreateMutation,
	useGetItemGroupStorageSummaryQuery,
	useLazyGetItemGroupStorageSummaryQuery,
	useGetRepositoryItemGroupsQuery,
	useRestorableItemGroupsQuery,
	useGetRepositoryItemGroupQuery,
	useItemGroupRepositoryBulkCreateMutation,
	useItemGroupRepositoryBulkUpdateMutation,
	useItemGroupRepositoryBulkDeleteMutation,
} = itemGroupApi;
