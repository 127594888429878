import { IdsInConstType } from "@helpers/TypeHelpers";
import { STATUS_COLORS } from "./ProtocolStatus";

export const EXPERIMENT_STATUSES = Object.freeze({
	DRAFT: 0,
	IN_PROGRESS: 1,
	COMPLETED: 2,
	0: "Draft",
	1: "In progress",
	2: "Completed",
} as const);

export const STATUS_CHANGE_MESSAGES = {
	[EXPERIMENT_STATUSES.DRAFT]: {
		message: "Experiment is in draft",
		checkColor: STATUS_COLORS[EXPERIMENT_STATUSES.DRAFT],
	},
	[EXPERIMENT_STATUSES.IN_PROGRESS]: {
		message: "Experiment is in progress",
		checkColor: STATUS_COLORS[EXPERIMENT_STATUSES.IN_PROGRESS],
	},
	[EXPERIMENT_STATUSES.COMPLETED]: {
		message: "Experiment is complete",
		checkColor: STATUS_COLORS[EXPERIMENT_STATUSES.COMPLETED],
	},
};

export type ExperimentStatus = IdsInConstType<typeof EXPERIMENT_STATUSES>;
