import SafeLeavingGuardProvider from "@common/context/SafeLeavingGuardContext";
import { Route, Switch } from "@common/helpers/Hooks/UseRouterDom";
import { Box, Freezer, Item, Rack, Shelf, Workspace } from "@common/types";
import { LayerSystemContainer, LoadingSpinner } from "@components";
import { useCurrentPlanNameHook } from "@helpers/Hooks/featureRestrictionHook";
import { ValuesOf } from "@helpers/TypeHelpers";
import {
	useLazyFreezerItemSearchQuery,
	useLazyGetBulkItemsFromItemsQuery,
} from "@redux/inventory/Item";
import { useOrganizationRouter } from "@root/AppRouter";
import { FREEZER_PATHS } from "@root/routes";
import React, { Suspense, useState } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import InventoryProductMenu from "./Menu";
import OrdersInfoPanel from "./OrdersView/components/OrdersInfoPanel/OrdersInfoPanel";
import ItemReroute from "./Reroute/Item";
import AddSpaceModal, {
	AddSpaceContextProvider,
} from "./components/AddSpaceModal/AddSpaceModal";
import OrderingWidget from "./components/OrderingWidget";
import DeleteBoxModal from "./contents/DeleteBoxModal";
import DeleteItemGroupModal from "./contents/DeleteItemGroupModal";
import "./index.scss";
import FreezerNavbar from "./navbar/FreezerNavbar";
import CustomizeBoxViewModal from "./table/components/CustomizeBoxViewModal";
import UnarchiveContainer from "./Repository/components/Unarchive/UnarchiveContainer";
import { RepositoryProvider } from "./Repository/RepositoryContext";
const Home = React.lazy(() => import("./home/Home"));
const Contents = React.lazy(() => import("./contents/Contents"));
const BoxView = React.lazy(() => import("./BoxView/BoxView"));
const SearchView = React.lazy(() => import("./SearchView/SearchView"));
const ItemTypeView = React.lazy(() => import("./ItemTypeView/ItemTypeView"));
const BookmarkPanel = React.lazy(
	() => import("./components/BookmarkPanel/BookmarkPanel")
);
const InventoryArchive = React.lazy(
	() => import("./InventoryArchive/InventoryArchive")
);
const Consumables = React.lazy(() => import("./Consumables"));
const SpaceView = React.lazy(() => import("./SpaceView"));
const FurnitureCategoryView = React.lazy(
	() => import("./FurnitureCategoryView/FurnitureCategoryView")
);
const ConsumableSearch = React.lazy(() => import("./Consumables/Search"));
const StockTracker = React.lazy(() => import("./StockTracker"));
const AddFreezerStockTracker = React.lazy(
	() => import("./StockTracker/AddFreezerStockTracker")
);
const AddConsumablesStockTracker = React.lazy(
	() => import("./StockTracker/AddConsumableStockTracker")
);
const ArchivedOrdersView = React.lazy(
	() => import("./ArchivedOrdersView/ArchivedOrdersView")
);
const CreateOrder = React.lazy(
	() => import("./OrdersView/CreateOrder/CreateOrder")
);
const ItemGroupView = React.lazy(() => import("./BoxView/ItemGroupView"));
const Repository = React.lazy(() => import("./Repository/Repository"));
const OrdersView = React.lazy(() => import("./OrdersView/OrdersView"));
const BoxReroute = React.lazy(() => import("./Reroute/Box"));
const ShelfReroute = React.lazy(() => import("./Reroute/Shelf"));
const RackReroute = React.lazy(() => import("./Reroute/Rack"));
const CategoryReroute = React.lazy(() => import("./Reroute/Category"));
const FurnitureCategoryReroute = React.lazy(
	() => import("./Reroute/FurnitureCategory")
);

export type StorageDataType = {
	name: string;
	racks: Rack[] | string;
	boxes: Box[] | string;
	items: Item[] | string;
	children: any;
	shelves?: any;
};

export type AvailableSpaceDataType = {
	name: string;
	available_boxes:
		| {
				name: string;
				available_items: number | string;
		  }[]
		| string;
	available_items: number | string;
	children: any;
};

export type LocationType = {
	page: ValuesOf<typeof FREEZER_PAGES>;
	freezer: Freezer | null;
	shelf: Shelf | null;
	rack: Rack | null;
	box: Box | null; // will remove Box once we get rid of Box class
};

export type AddSpaceModalProps = {
	visible: boolean;
	onCancel: () => void;
	setBackgroundModal: (value: boolean) => void;
	setWorkspaceOpen: (value: boolean) => void;
	selectedVal: Workspace[];
};

// Constants
export const FREEZER_PAGES = Object.freeze({
	HOME: 0,
	CONTENTS: 1,
	BOXES: 2, //Category or Table
	SEARCH: 3,
	AUDIT_LOG: 4,
	RECENTLY_DELETED: 5,
	ITEMTYPE: 6,
	ARCHIVE: 7,
	CONSUMABLE: 8,
	SPACE: 9,
	FURNITURE_CATEGORIES: 10,
	CONSUMABLE_SEARCH: 11,
	STOCK_TRACKER: 12,
	CREATE_FREEZER_STOCK_TRACKER: 13,
	EDIT_FREEZER_STOCK_TRACKER: 14,
	CREATE_CONSUMABLE_STOCK_TRACKER: 15,
	EDIT_CONSUMABLE_STOCK_TRACKER: 16,
	ORDERS: 17,
	ARCHIVED_ORDERS: 18,
	CREATE_ORDER: 19,
	ITEMGROUPS: 20,
	REPOSITORY: 21,
} as const);

// For searching freezer item
export function useFreezerSearch() {
	const [fetchSearchResults, { ...rest }] = useLazyFreezerItemSearchQuery();
	return {
		fetchSearchResults,
		...rest,
	};
}

// For fetching data of a row on a grouped freezer item search
// export function useFreezerGroupedSearchRow() {
// 	const [fetchBulkItems, { ...rest }] = useLazyGetBulkItemsFromItemsQuery();
// 	return {
// 		fetchBulkItems,
// 		...rest,
// 	};
// }

export function useFreezerGroupedSearchRow() {
	const [fetchBulkItems, { ...rest }] = useLazyGetBulkItemsFromItemsQuery();
	return {
		fetchBulkItems,
		...rest,
	};
}

export default function FreezerMain() {
	const { appendBasePath, getRedirect } = useOrganizationRouter();
	const { planName } = useCurrentPlanNameHook();
	const isFreePlan = planName === "free";

	const [isAddingSpace, setIsAddingSpace] = useState<boolean>(false);
	const closeAddSpaceModal = () => {
		setIsAddingSpace(false);
	};
	return (
		<DndProvider backend={HTML5Backend}>
			<div className="freezer-content">
				<div id="freezer-navbar">
					<FreezerNavbar />
				</div>
				<LayerSystemContainer id="freezer">
					<Suspense
						fallback={
							<>
								<InventoryProductMenu />
								<div
									style={{
										width: "100%",
										height: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: "var(--background)",
									}}
								>
									<LoadingSpinner
										size="large"
										loading
										centered
									/>
								</div>
							</>
						}
					>
						<Switch>
							<Route
								exact
								path={appendBasePath(FREEZER_PATHS.CONTENTS)}
								render={() => (
									<RepositoryProvider restorableItems>
										<Contents />
										<UnarchiveContainer />
									</RepositoryProvider>
								)}
							/>
							<Route
								exact
								path={appendBasePath(FREEZER_PATHS.BOXES)}
								render={() => (
									<RepositoryProvider restorableItems>
										<BoxView />
										<UnarchiveContainer />
									</RepositoryProvider>
								)}
							/>
							<Route
								exact
								path={appendBasePath(FREEZER_PATHS.ITEMGROUPS)}
								render={() => (
									<RepositoryProvider restorableItems>
										<ItemGroupView />
									</RepositoryProvider>
								)}
							/>
							<Route
								path={appendBasePath(FREEZER_PATHS.SEARCH)}
								render={() => <SearchView />}
							/>
							<Route
								path={appendBasePath(FREEZER_PATHS.ITEMTYPE)}
								render={() => <ItemTypeView />}
							/>
							<Route
								exact
								path={appendBasePath(FREEZER_PATHS.SPACE)}
								render={() => <SpaceView />}
							/>
							<Route
								exact
								path={appendBasePath(
									FREEZER_PATHS.FURNITURE_CATEGORIES
								)}
								render={() => <FurnitureCategoryView />}
							/>
							<Route
								exact
								path={appendBasePath(
									FREEZER_PATHS.CONSUMABLE_SEARCH
								)}
								render={() => <ConsumableSearch />}
							/>
							<Route
								exact
								path={appendBasePath(FREEZER_PATHS.ITEM)}
								render={() => <ItemReroute />}
							/>
							<Route
								exact
								path={appendBasePath(FREEZER_PATHS.BOX)}
								render={() => <BoxReroute />}
							/>
							<Route
								exact
								path={appendBasePath(FREEZER_PATHS.SHELF)}
								render={() => <ShelfReroute />}
							/>
							<Route
								exact
								path={appendBasePath(FREEZER_PATHS.RACK)}
								render={() => <RackReroute />}
							/>
							<Route
								exact
								path={appendBasePath(FREEZER_PATHS.CATEGORY)}
								render={() => <CategoryReroute />}
							/>
							<Route
								exact
								path={appendBasePath(
									FREEZER_PATHS.FURNITURE_CATEGORY
								)}
								render={() => <FurnitureCategoryReroute />}
							/>

							<Route
								exact
								path={appendBasePath(
									FREEZER_PATHS.CREATE_CONSUMABLE_STOCK_TRACKER
								)}
								render={() => (
									<SafeLeavingGuardProvider>
										<AddConsumablesStockTracker />
									</SafeLeavingGuardProvider>
								)}
							/>
							<Route
								exact
								path={appendBasePath(
									FREEZER_PATHS.EDIT_CONSUMABLE_STOCK_TRACKER
								)}
								render={() => (
									<SafeLeavingGuardProvider>
										<AddConsumablesStockTracker />
									</SafeLeavingGuardProvider>
								)}
							/>
							<Route
								exact
								path={appendBasePath(
									FREEZER_PATHS.CREATE_FREEZER_STOCK_TRACKER
								)}
								render={() => (
									<SafeLeavingGuardProvider>
										<AddFreezerStockTracker />
									</SafeLeavingGuardProvider>
								)}
							/>
							<Route
								exact
								path={appendBasePath(
									FREEZER_PATHS.EDIT_FREEZER_STOCK_TRACKER
								)}
								render={() => (
									<SafeLeavingGuardProvider>
										<AddFreezerStockTracker />
									</SafeLeavingGuardProvider>
								)}
							/>
							<Route
								exact
								path={appendBasePath(
									FREEZER_PATHS.ARCHIVED_ORDERS
								)}
								render={() => <ArchivedOrdersView />}
							/>
							{!isFreePlan && (
								<Route
									exact
									path={appendBasePath(
										FREEZER_PATHS.CREATE_ORDER
									)}
									render={() => <CreateOrder />}
								/>
							)}
							<Route>
								<InventoryProductMenu />
								<Switch>
									<Route
										exact
										path={appendBasePath(
											FREEZER_PATHS.HOME
										)}
										render={() => (
											<RepositoryProvider restorableItems>
												<Home />
											</RepositoryProvider>
										)}
									/>
									<Route
										exact
										path={appendBasePath(
											FREEZER_PATHS.CONSUMABLE
										)}
										render={() => (
											<Consumables
												setIsAddingSpace={
													setIsAddingSpace
												}
											/>
										)}
									/>
									<Route
										exact
										path={appendBasePath(
											FREEZER_PATHS.ORDERS
										)}
										render={() => <OrdersView />}
									/>
									<Route
										exact
										path={appendBasePath(
											FREEZER_PATHS.ARCHIVE
										)}
										render={() => <InventoryArchive />}
									/>
									<Route
										exact
										path={appendBasePath(
											FREEZER_PATHS.STOCK_TRACKER
										)}
										render={() => <StockTracker />}
									/>
									<Route
										exact
										path={appendBasePath(
											FREEZER_PATHS.REPOSITORY
										)}
										render={() => <Repository />}
									/>
									<Route>
										{getRedirect(FREEZER_PATHS.HOME)}
									</Route>
								</Switch>
							</Route>
						</Switch>
					</Suspense>
					<BookmarkPanel />
					<OrderingWidget />
					<AddSpaceContextProvider>
						<AddSpaceModal
							visible={isAddingSpace}
							onClose={closeAddSpaceModal}
						/>
					</AddSpaceContextProvider>
					<DeleteBoxModal />
					<DeleteItemGroupModal />
					<CustomizeBoxViewModal />
					<OrdersInfoPanel />
				</LayerSystemContainer>
			</div>
		</DndProvider>
	);
}

export { FREEZER_PATHS };
