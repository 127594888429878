import { UUID } from "@common/types";

/**
 * Allows access to variables on objects using the bracket syntax myObj[key] to
 * work even if the key is a string. Useful for gradual typescript transition.
 * Taken from Typescript examples
 */
export function pluck<T, K extends keyof T>(o: T, propertyNames: K[]): T[K][] {
	return propertyNames.map((n) => o[n]);
}

// ~~~~~~~~ CONST OBJECT HELPERS ~~~~~~~~

// For objects like the following:
/*
    const FREEZER_TYPES = Object.freeze({
        MINUS_EIGHTY: 0,
        MINUS_TWENTY: 1,
    ...
        0: "-80 °C",
        1: "-20 °C",
    ...
    } as const); // CONST HERE IS IMPORTANT
*/

// Type of the numeric ids in const object
export type IdsInConstType<T> = Extract<keyof T, number>;

// Type of the names in const object
export type NamesInConstType<T> = Exclude<keyof T, number>;

/**
 * Given an object, will return the objects keys with types, be careful using this since
 * objects can change type at runtime. I chose the name to direct usage. For more info:
 *https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
 * */
export function keysOfConst<T>(obj: T): (keyof T)[] {
	return Object.keys(obj as any) as (keyof T)[];
}

/**
 * Does the same thing as keysOfConst but returns only the numbers type keys
 * */
export function idKeysOfConst<T>(obj: T): IdsInConstType<T>[] {
	return Object.keys(obj as any)
		.filter((key) => !isNaN(+key))
		.map((key) => parseInt(key)) as any;
}

/**
 * Does the same thing as keysOfConst but returns only the name type keys
 *  */
export function nameKeysOfConst<T>(obj: T): NamesInConstType<T>[] {
	return Object.keys(obj as any).filter((key) => isNaN(+key)) as any;
}

/**
 * Gets the labels, the values for the numeric keys
 */
export function labelsOfConst<T>(obj: T): T[IdsInConstType<T>][] {
	return idKeysOfConst(obj).map((id) => obj[id]);
}

/**
 * Gets all the values of a given type
 */
export type ValuesOf<T> = T[keyof T];

/**
 * Merges the types of two objects. Any keys shared between them take the type of B. So B overrides A.
 * { a: 0, b: string } & { a: 1 } // becomes { a: 1, b: string }
 */
export type Merge<A, B> = Omit<A, keyof B> & B;

/**
 * Returns map of the keys of the given object mapped to the same key values as sting.
 * Eg:
 * Input:  {a: 0, b: 1, c: 2}
 * Output: {a: "a", b: "b", c: "c"}
 */
export function keysMappedToSelf<T>(obj: T) {
	return Object.keys(obj as any).reduce(
		(obj, key) => ({
			...obj,
			[key]: key,
		}),
		{}
	) as { [K in keyof T]: K };
}

/**
 * Returns values in array as keys to an object with the same value as the key.
 */
export const arrayToSymmetricalObject = <T extends string | number | symbol>(
	arr: T[]
) => {
	return arr.reduce((obj, item) => {
		obj[item] = item;
		return obj;
	}, {} as { [K in T]: K });
};

/**
 * Allows for defining of "nominal" types, meaning that you can declare a custom type with
 * the same basic identity as a primitive type (ie: number, string) but the type checker will
 * still consider them distinct types.
 *
 * This is useful for example when dealing with IDs. If an id for two data types are declared to be
 * just numbers (without using this nominal type) then you can mistakenly try to compare or assign
 * one to another. Eg:
 *
 * const myOrg = {id: 1};
 * const myProj = {id: 2};
 * myOrg.id = myProj.id; // Legal operation, but probably shouldn't be!
 *
 * When using this nominal type (Nominal Id in this case) we can prevent the previous assignment:
 *
 * type OrgId = NominalId<"OrgId">;
 * type ProjId = NominalId<"ProjId">;
 * const myOrg = {id: 1 as OrgId};
 * const myProj = {id: 2 as ProjId};
 * myOrg.id = myProj.id; // ERROR: "Type 'ProjId' is not assignable to type 'OrgId'."
 *
 *
 * Note: This may become a first class feature of Typescript in the future. Track this issue:
 * https://github.com/microsoft/TypeScript/issues/202
 */
export type NominalType<
	Identifier extends string,
	PrimitiveType
> = PrimitiveType & { [k in Identifier]: void };

/**
 * Nominal type for an "ID" (number). See "NominalType" for details about nominal types.
 */
export type NominalId<Identifier extends string> = NominalType<
	Identifier,
	number
>;

export type NominalUuid<Identifier extends string> = NominalType<
	Identifier,
	UUID
>;

/**
 * Allows you to create a "Partial" type that still must have at least some of it's properties defined.
 * Eg: AtLeast<Freezer, "id"> // Freezer type with all fields optional except "id"
 */
export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

/**
 * Allows you to create a "Partial" type that still must it's "id" field defined
 * Eg: AtLeast<Freezer, "id"> // Freezer type with all fields optional except "id"
 */
export type AtLeastId<T extends { id: unknown }> = AtLeast<T, "id">;

/**
 * The mapAValuesAsKeysToBValues maps the values in object A as keys to the values of object B. It will
 * only do the mapping for the keys they have in common. Useful for building styles from sass imports.
 * Eg:
	const x0 = {
		a: "hello",
		b: "world",
	} as const;

	const x1 = {
		a: 3,
		b: 4,
		c: 5,
	} as const;

	const y0 = mapAValuesAsKeysToBValues(x0, x1); // { hello: 3, world: 4 }
 */
export type PossibleObjectKey = string | number | symbol;
export const mapAValuesAsKeysToBValues = <
	A extends Record<PossibleObjectKey, PossibleObjectKey>,
	B extends Record<PossibleObjectKey, any>
>(
	objA: A,
	objB: B
) => {
	const keysA = Object.keys(objA);
	const keysB = Object.keys(objB);
	const commonKeys = keysA.filter((ka) => keysB.indexOf(ka) > -1);
	type CommonKey = keyof A & keyof B;
	type OutType = {
		[K in CommonKey as A[K]]: B[K];
	};
	return commonKeys.reduce(
		(out, key) => ({
			...out,
			[objA[key]]: objB[key],
		}),
		{}
	) as OutType;
};

/**
 * Type of an object with an id
 */
export type PersistedObject = { id: number | string };

/**
 * Type that matches any "object". Naively it would seem that the type of any object should be:
 * MyObject extends {}
 * But that is not actually the case. Use the type below instead.
 */
export type AnyObject = Record<PropertyKey, any>;

/**
 * Get's type of indexes of array. If it is an array literal it pull's out the exact indexes.
 * eg:
	const arr = [0,0,0];
	const arrLiteral = [0,0,0] as const;
 	Indices<typeof arr>; // number
 	Indices<typeof arrLiteral>; // 0 | 1 | 2
 * NOTE: Taken from: https://stackoverflow.com/a/63904714/16098999
 */

type UnsafeIndices<T extends readonly any[]> = Exclude<
	Partial<T>["length"],
	T["length"]
>;
export type Indices<A extends Readonly<any[]>> = UnsafeIndices<A> extends never
	? number
	: UnsafeIndices<A>;

/**
 * Helper types that allow you to verify that an Enum is like our standard enum types on function calls.
 * By "standard" meaning that it has numbers that map to strings and also those same strings map to back to the same numbers.
 * example:
 	const valid = {
		0: "a",
		1: "b",
		a: 0,
		b: 1,
 	} as const;

	 const invalid = {
		0: "a", // a does not map back to 0
		1: "b",
		a: 2, // 2 is not present
		b: 1,
		c: 3 // 3 is not present
	} as const;

	const myFunc = <T extends VerifyStandardEnum<T>>(myEnum: StandardEnum<T>) => {};

	myFunc(valid); // NO ERROR!
	myFunc(invalid); // ERROR!
 *
 * NOTE: I tried making a type that just verified the "symmetry" of the object without having to do generics but could
 * not get it to work. So the standard enum passed the function is just a plain record. That should be good enough.
*/
export type StandardEnum<T> = T & Record<string | number, string | number>;
export type VerifyStandardEnum<T> = {
	[K in keyof T]: T[K] extends keyof T
		? K extends T[T[K]]
			? T[K]
			: never
		: never;
};

/**
 * Allows for more convenient type narrowing casting.
 */
export const cast = <T>(_arg: any): _arg is T => true;

/**
 * Allows adding some custom errors in type error messages. eg:
 *
 * const wrong = ValidateType<boolean, string, "wrong type!"> = false; // "Customer error: wrong type!"
 */
export type ValidateType<
	Type,
	Expected,
	Message extends string
> = Type extends Expected ? Type : `Custom error: ${Message}`;

/**
 * Type safe helper to determine if an object is in a list.
 */
export const inList =
	<T>(list: T[]) =>
	(obj: T) =>
		list.includes(obj);

export const notInList =
	<T>(list: T[]) =>
	(obj: T) =>
		list.includes(obj);

/**
 * Type safe helper to convert a list of objects to a map from the value of one of the object's keys to the objects.
 * Eg:
 * const arr = [{id: 1}, {id: 2}];
 *
 * mapToKeyValue(arr, "id"); // {1: {id: 1}, 2: {id: 2}}
 */
export const mapToKeyValue = <
	V extends PossibleObjectKey,
	K extends PossibleObjectKey,
	Arr extends Readonly<{ [Key in K]: V }[]>
>(
	arr: Arr,
	key: K
): MapLiteralFieldToNarrowedObj<Arr[number], K> =>
	arr.reduce(
		(mapping, obj: any) => ({ ...mapping, [obj[key] as any]: obj }),
		{}
	) as any;

/**
 * Make a keys of an object optional.
 */
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

/**
 * Type that roughly corresponds to a "falsy" value.
 * https://stackoverflow.com/questions/51390276/type-for-non-false-aka-truthy
 */
export type Falsy = false | 0 | "" | null | undefined;

/**
 * Provided a value that is a type literal, allows you do define an object mapping the type literal options to functions,
 * and checks that the options are exhaustively covered. Useful to replace if statements checking literals that can be added to later.
 * 
 * 
 * Eg:
	type t = "X" | "Y" | "Z";

	const r = actionOnTypeLiteral<t>("X")<number | string>({
		"X": () => 1, // this function is called
		"Y": () => "hi",
		"Z": () => 3,
		// "E": () => 4, // uncommenting will cause a type error
	});
	// value of r: 1
 */
export const actionOnTypeLiteral =
	<T extends PossibleObjectKey>(literal: T) =>
	<Return = void>(
		mapping: { [K in T]: (l: T) => Return },
		fallback?: (l: T) => Return
	): Return => {
		const func = mapping[literal] || fallback;
		if (!func) {
			console.error("Access function not found!", {
				mapping,
				literal,
			});
		}
		return func(literal);
	};

/**
 * Same as actionOnTypeLiteral, but takes in the key as the last function's argument.
 */
export const actionOnTypeLiteralLazy =
	<T extends PossibleObjectKey>() =>
	<Return = void>(
		mapping: { [K in T]: (l: T) => Return },
		fallback?: (l: T) => Return
	) =>
	(literal: T): Return => {
		const func = mapping[literal] || fallback;
		if (!func) {
			console.error("Access function not found!", {
				mapping,
				literal,
			});
		}
		return func(literal);
	};

/**
 * This generic enforces that the value of "Obj" at "Field" can be a type literal (ie: string, symbol, number)
 */
type EnsureFieldValueIsTypeLiteral<
	Obj,
	Field extends keyof Obj
> = Obj[Field] extends PossibleObjectKey ? Obj[Field] : never;

/**
 * This generates a type that is an exhaustive mapping from some literal value to the literal value and the "narrowed"
 * object types that match the literal. Useful to build other types.
 * 
 * Eg: 
	type obj = {type: "A"} | {type: "B"} | {type: "C"};
	type mapping = MapLiteralFieldToNarrowedObj<obj, "type">;
	// Mapping: {
		A: {type: "A"},
		B: {type: "B"},
		C: {type: "C"}
	}
 */
type MapLiteralFieldToNarrowedObj<Obj, Field extends keyof Obj> = {
	[K in EnsureFieldValueIsTypeLiteral<Obj, Field>]: Extract<
		Obj,
		{ [F2 in Field]: K }
	>;
};

/**
 * Similar to MapLiteralFieldToNarrowedObj, but maps to functions that take in the narrowed object.
 * Eg: 
	type obj = {type: "A"} | {type: "B"} | {type: "C"};
	type mapping = MapLiteralFieldToNarrowedObj<obj, "type">;
	// Mapping: {
		A: (arg: {type: "A"}) => OutputVal,
		B: (arg: {type: "B"}) => OutputVal,
		C: (arg: {type: "C"}) => OutputVal,
	}
 */
export type TypeFieldToFunctions<Obj, Field extends keyof Obj, OutputVal> = {
	[K in EnsureFieldValueIsTypeLiteral<Obj, Field>]: (
		typedTypeOption: MapLiteralFieldToNarrowedObj<Obj, Field>[K]
	) => OutputVal;
};

/**
 * Accepts an object, then a string that is a field on that object that should map to a type literal, 
 * and finally an object with keys that should exhausively cover the type literal options. Can use generics
 * to define a specific return type if desired.
 * 
 * Eg: 
	type MyType = {type: "A", x: number} | {type: "B", y: string} | {type: "C", z: boolean};
	const inst = {type: "B", y: "hello"};

	const r = actionOnTypeLiteralField(inst)
		("type")
		<number | string | boolean>({
			A: (a) => a.x,
			B: (b) => b.y, // This one is called because inst is of "type" B
			C: (c) => c.z,
		});
	// value of r: "hello"
 */
export const actionOnTypeLiteralField =
	<Obj>(obj: Obj) =>
	<Field extends keyof Obj>(field: Field) =>
	<OutputVal>(
		accessFunctions: TypeFieldToFunctions<Obj, Field, OutputVal>,
		fallback?: (l: Obj) => OutputVal
	): OutputVal => {
		const literalValue = obj[field];
		if (!literalValue && !fallback) {
			console.error("Literal field not found in object!", { obj, field });
		}

		const func =
			accessFunctions[obj[field] as keyof typeof accessFunctions] ||
			fallback;
		if (!func || typeof func !== "function") {
			console.error("Access function not found!", {
				accessFunctions,
				literalValue,
			});
		}

		return func(obj as any);
	};

/**
 * Allows for better syntax when adding elements to an array conditionally with the spread operator.
 * Usage:
	const myConstArr = [
		{ type: "A" } as const,
		...conditionalArrayElements(true)(
			{ type: B } as const,
			{ type: C } as const
		),
	] as const;
	Type should be: [{
		type: "A";
	}, {
		type: "B";
	}, {
		type: "C";
	}]
 */
export const conditionalArrayElements =
	(condition: any) =>
	<T, Arr extends T[]>(...vals: Arr): Arr =>
		(!!condition && vals) || ([] as unknown as Arr);

/**
 * Defines either a type, or a function that returns the type. To be used in combination with the "fromLazy" function
 */
export type Lazy<T> = T | (() => T);

/**
 * Takes in a lazy type and either returns the type or evaluates it. Beware using this if the generic type is a function itself!
 *
 * Todo: should we have a wrapper around lazy values to enable lazy functions?
 */
export const fromLazy = <T>(arg: Lazy<T>) => {
	if (typeof arg === "function") {
		return (arg as () => T)();
	}
	return arg as T;
};

/**
 * Exactly the same as actionOnTypeLiteralField, but you input the obj last in the functional chaining.
 */
export const actionOnTypeLiteralFieldLazy =
	<Obj>() =>
	<Field extends keyof Obj>(field: Field) =>
	<OutputVal>(
		accessFunctions: TypeFieldToFunctions<Obj, Field, OutputVal>,
		fallback?: (l: Obj) => OutputVal
	) =>
	(obj: Obj): OutputVal =>
		actionOnTypeLiteralField(obj)(field)(accessFunctions, fallback);

/**
 * Extracts all the keys from a type union.
 * Eg:
 * type t0 = { x: "x0"; y: "y" } | { x: "x1"; z: 0 };
 * type t1 = KeysOfUnion<t0>; // "x" | "y" | "z"
 */
export type KeysOfUnion<T> = T extends T ? keyof T : never;

/**
 * Extracts the value type of a type union at a given key.
 * Eg:
 * type t0 = { x: "x0"; y: "y" } | { x: "x1"; z: 0 };
 * type t1 = ValueOfUnion<t0, "z">; // 0
 */
export type ValueOfUnion<O, K extends keyof O> = O extends O
	? K extends keyof O
		? O[K]
		: never
	: never;

/**
 * Extracts the value type of a record type.
 */
export type ValueOf<T> = T[keyof T];

/**
 * Given a union type, allows accessing a single field that exists on any given member of the union like
 * you're accessing an optional value.
 */
export const typeOptional =
	<Obj>(obj: Obj) =>
	<K extends KeysOfUnion<Obj>>(key: K) =>
		obj[key] as unknown as ValueOfUnion<Obj, K> | undefined;

/**
 * You should prrobably use "FlattenUnion" below.
 * Converts a union of types into a type that has all of the keys of the union mapped to a union of their values.
 * Note: This is a shallow flattening. Also it does not make any keys optional.
 * eg:
 * type t0 = { x: "x0"; y: "y" } | { x: "x1"; z: 0 };
 * type t1 = JoinUnion<t0>; // {x: "x0" | "x1", y: "y", z: 0}
 */
export type CombineUnion<O> = { [K in KeysOfUnion<O>]: ValueOfUnion<O, K> };

/**
 * Gets an type consisting of the fields that a union has in common.
 * type t0 = { x: "x0"; y: "y" } | { x: "x1"; z: 0 };
 * type t1 = CommonFieldsOfUnion<t0>; // {x: "x0" | "x1"}
 */
type CommonFieldsOfUnion<O> = { [K in keyof O]: O[K] };

/**
 * Converts a union of types into a type that has all of the keys of the union mapped to a union of their values.
 * Note: This is a shallow flattening.
 * eg:
 * type t0 = { x: "x0"; y: "y" } | { x: "x1"; z: 0 };
 * type t1 = JoinUnion<t0>; // {x: "x0" | "x1", y?: "y", z?: 0}
 */
export type FlattenedUnion<O> = Merge<
	Partial<CombineUnion<O>>,
	CommonFieldsOfUnion<O>
>;

/**
 * Converts an object that is a union type into a type that has all of the keys of the union mapped to a union of their values.
 * Note: This is a shallow flattening.
 * eg:
 * type t0 = { x: "x0"; y: "y" } | { x: "x1"; z: 0 };
 * const x0 = { x: "x0", y: "y" } as t0;
 * const {
 *		x, // "x0" | "x1"
 *		y, // "y" | undefined
 *		z // 0 | undefined
 *	} = flattenUnion(x0);
 */
export const flattenUnion = <Obj>(obj: Obj) =>
	(obj || {}) as unknown as FlattenedUnion<Obj>;

// export const flattenUnionAllDefined = <Obj>(obj: Obj) =>
// 	(obj || {}) as unknown as FlattenedUnion<Obj>;

/**
 * Type safe version of Object.values
 */
export const objectValues = <T>(obj: T): T[keyof T][] => {
	return Object.values(obj as any) as T[keyof T][];
};

/**
 * Type of an array with at least one elment
 */
export type AtLeastOneElement<T> = [T, ...T[]];

/**
 * Shorthand type for a react setstate function.
 */
export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;
type ParamIdentifier<ParamName extends string> = `:${ParamName}`;
type IsParameter<Part> = Part extends ParamIdentifier<infer ParamName>
	? ParamName
	: never;
type FilteredParts<Path> = Path extends `${infer PartA}/${infer PartB}`
	? IsParameter<PartA> | FilteredParts<PartB>
	: IsParameter<Path>;

/**
 * Takes in a url and pulls out any parameters that start with a colon.
 */
export type UrlParams<Path> = {
	[Key in FilteredParts<Path>]: any;
};

export const fillInUrlParams =
	<Path extends string>(path: Path) =>
	<Params extends UrlParams<Path>>(params: Params) =>
		Object.keys(params).reduce((acc, key) => {
			return acc.replace(`:${key}`, params[key as keyof Params] + "");
		}, path);

/**
 * This is a utility function that takes in a function fn and returns a new function that
 * wraps the original function with a wrapper function. The wrapper function is passed an
 * object with two properties: args and applyOriginal. args contains the arguments that are
 * passed to the wrapped function, and applyOriginal is a function that, when called, will
 * execute the original function with the provided args.
 *
 * The returned function is typed such that it has the same args and return type as the
 * original function, but its return type is replaced with the return type of the wrapper
 * function.
 *
 * You can use this function in order to add a layer of functionality to any existing
 * function (for example, logging, error handling, etc.)
 *
 * Example:
 *
 *	const fn = (n: number) => console.log("inner " + n);
 * 	const newFn = wrapFn(fn)(({ args, applyOriginal }) => {
 * 		console.log("outer");
 * 		applyOriginal();
 *	});
 * newFn(5); // logs "outer" then "inner 5"
 *
 */
export const wrapFn =
	<Args, Ret>(fn: (args: Args) => Ret) =>
	<NewRet, NewF extends (args: Args) => NewRet>(
		wrapper: (wrapperArgs: {
			args: Args;
			applyOriginal: () => Ret;
		}) => NewRet
	): NewF => {
		const newFn = (...args: any) => {
			return wrapper({ args, applyOriginal: () => (fn as any)(...args) });
		};
		return newFn as NewF;
	};

/**
 * mapObject is a utility function that takes an object, obj, and a mapper
 * function, mapper. The mapper function is applied to each key-value pair in
 * the object, and the returned values are used to create a new object, which
 * is then returned.
 *
 * Example:
 * const obj = {a: 1, b: 2, c: 3};
 * const squared = mapObject(obj, (key, value) => value ** 2);
 * console.log(squared); // {a: 1, b: 4, c: 9}
 */
export function mapObject<K extends string | number, T, U>(
	obj: Record<K, T>,
	mapper: (key: K, value: T) => U
): Record<K, U> {
	return Object.keys(obj).reduce((acc, key) => {
		acc[key as K] = mapper(key as K, obj[key as K]);
		return acc;
	}, {} as Record<K, U>);
}

type GenericObject = Record<any, any>;
/**
 * NestedType is a generic type that takes in an object type and a path as an array of strings representing properties in the object.
 * It returns the type of the nested property of the object that is located in the specified path. In case the provided path is invalid, it returns `never`.
 * This can be useful for type-safe access to nested properties.
 *
 * @template Object The object that contains the nested property.
 * @template Path An array of strings representing the path to the nested property in the object.
 * @returns The type of the nested property located in the provided path or never if the path is invalid
 *
 * @example
 * interface Person {
 *   name: string;
 *   age: number;
 *   address: {
 *    street: string;
 *    city: string;
 *    state: string;
 *   };
 * }
 * type PersonName = NestedType<Person, ['name']> //string
 * type PersonAddressState = NestedType<Person, ['address', 'state']> //string
 * type InvalidType = NestedType<Person, ['invalid']> // never
 */
export type NestedType<
	T extends GenericObject,
	Path extends string // Or, if you prefer, NestedPaths<T>
> = {
	[K in Path]: K extends keyof T
		? T[K]
		: K extends `${infer P}.${infer S}`
		? T[P] extends GenericObject
			? NestedType<T[P], S>
			: never
		: never;
}[Path];

export type ValueOrGet<V> = V | ((current: V) => V);

export const applyValueOrGet = <V>(valueOrGet: ValueOrGet<V>, current: V) => {
	return typeof valueOrGet === "function"
		? (valueOrGet as (v: V) => V)(current)
		: valueOrGet;
};
