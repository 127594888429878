import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import {
	Box,
	BoxDataForBackend,
	DEFAULT_ITEM_TYPE,
	ItemGroup,
	ItemGroupDataForBackend,
} from "@common/types";
import {
	ButtonV2,
	ClickToEdit,
	GenemodIcon,
	GridViewToggle,
	LayerSystemContainer,
	Notification,
	Sort,
	Typography,
} from "@components";
import { FREEZER_PATHS } from "@containers/Freezer";
import { usePreferences } from "@helpers/Hooks/UsePreferencesHook";
import { useBoxCreateMutation } from "@redux/inventory/Box";
import {
	useCategoryBoxesQuery,
	useCategoryPatchMutation,
} from "@redux/inventory/Category";
import {
	useItemGroupCreateMutation,
	useItemGroupsFromCategoryQuery,
} from "@redux/inventory/ItemGroup";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useOrganizationRouter } from "@root/AppRouter";
import classNames from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BoxMovingCart from "../components/MovingCart/BoxMovingCart";
import { BOX_GROUP_SORT, ITEM_GROUP_LAYOUT, ITEM_GROUP_SORT } from "../data";
import AddBoxModal from "./AddBoxModal";
import AddItemGroupModal from "./AddItemGroupModal";
import BoxTableCell from "./BoxTableCell";
import styles from "./CategoryView.module.scss";
import "./CategoryView.scss";
import { useFreezer, useSelectedCategory } from "./Contents";
import ItemGroupCard from "./ItemGroupCard";
import ItemGroupList from "./ItemGroupList";
import "./RackView.scss";
import { RackMenu } from "./ShelfView/RackCard";

type CategoryViewProps = {
	/** whether to show editRackModal or not */
	showEditRackModal: boolean;
	/** whether to show rearrangeBoxModal or not */
	showRearrangeBoxModal: boolean;
};

/** Category View */
export default function CategoryView(props: CategoryViewProps): JSX.Element {
	return (
		<LayerSystemContainer className={styles.right}>
			<CategoryContents
				showEditRackModal={props.showEditRackModal}
				showRearrangeBoxModal={props.showRearrangeBoxModal}
			/>
		</LayerSystemContainer>
	);
}

type CategoryContentsProps = {
	/** whether to show editRackModal or not */
	showEditRackModal: boolean;
	/** whether to show rearrangeBoxModal or not */
	showRearrangeBoxModal: boolean;
};

function CategoryContents(props: CategoryContentsProps): JSX.Element {
	enum CATEGORY_TYPE {
		BOXES,
		ITEMS,
	}
	const { freezer } = useFreezer();
	const { category } = useSelectedCategory();
	const { data } = useCategoryBoxesQuery(
		category
			? {
					id: category?.id,
					is_archived: freezer?.is_archived,
			  }
			: skipToken
	);
	const itemGroupData = useItemGroupsFromCategoryQuery(
		category
			? {
					id: category.id,
					is_archived: freezer?.is_archived,
			  }
			: skipToken
	);
	const [updateCategory] = useCategoryPatchMutation();
	const boxes = data || [];
	const itemGroups = itemGroupData.data || [];

	const [addBox] = useBoxCreateMutation();
	const [addItemGroup] = useItemGroupCreateMutation();

	const viewOnly = freezer?.is_archived || false;
	const defaultItemType = freezer?.default_item_type || DEFAULT_ITEM_TYPE;

	const [isAddingBox, setAddingBox] = useState(false);
	const [isAddingGroup, setAddingGroup] = useState(false);

	const [isBoxCollapsed, setBoxCollapsed] = useState(false);
	const [isItemsCollapsed, setItemsCollapsed] = useState(false);

	// Item Group Sorting
	const [itemGroupSorting, setItemGroupSorting] = usePreferences(
		"FREEZER_CATEGORY_ITEM_SORT"
	);
	// Box Group Sorting
	const [boxGroupSorting, setBoxGroupSorting] = usePreferences(
		"FREEZER_CATEGORY_BOX_SORT"
	);
	// Item Group Layout (Grid and List)
	const [itemGroupLayout, setItemGroupLayout] = usePreferences(
		"FREEZER_CATEGORY_ITEM_LAYOUT"
	);

	const classes = ["table-cell"];
	if (props.showRearrangeBoxModal) {
		classes.push("box__rearranging");
	}

	const handleAddBox = (boxToAdd: Partial<Box>) => {
		if (!category || !boxToAdd.location) return;
		boxToAdd.location.category = category.id;
		boxToAdd.location.rack_location = null;
		const box = {
			...boxToAdd,
		} as BoxDataForBackend;
		addBox({ rackId: category.id, boxData: box })
			.unwrap()
			.then((box) => {
				SegmentTrackEvent(SegmentFreezerEvents.BOX_CREATE, {
					id: box.id,
					num_row_item: box.rows,
					num_col_item: box.columns,
					default_item_type: box.item_type,
					is_item_group: false,
				});
			})
			.catch(() =>
				Notification.warning({
					message: "Failed to create a box.",
				})
			)
			.finally(() => {
				setAddingBox(false);
			});
	};

	const handleAddItemGroup = (itemGroupToAdd: Partial<ItemGroup>) => {
		if (!category) return;
		const itemGroup = {
			...itemGroupToAdd,
			location: {
				category: category.id,
				rack_location: null,
			},
		} as ItemGroupDataForBackend;

		addItemGroup({ itemGroupData: itemGroup })
			.unwrap()
			.then((itemGroup) => {
				SegmentTrackEvent(SegmentFreezerEvents.BOX_CREATE, {
					id: itemGroup.id,
					num_row_item: 0,
					num_col_item: 0,
					default_item_type: itemGroup.item_type,
				});
			})
			.then(() => {
				setAddingGroup(false);
			})
			.catch(() =>
				Notification.warning({
					message: "Failed to create a box.",
				})
			);
	};

	const getFilteredGroups = (
		category_type: CATEGORY_TYPE,
		objs: Box[] | ItemGroup[]
	) => {
		const objsCopy = [...objs];
		const sort_option =
			category_type === CATEGORY_TYPE.ITEMS
				? itemGroupSorting
				: boxGroupSorting;

		if (sort_option === ITEM_GROUP_SORT.Newest) {
			return objsCopy.sort((a, b) => {
				if (a.created_at < b.created_at) {
					return 1;
				} else if (a.created_at > b.created_at) {
					return -1;
				} else {
					return 0;
				}
			});
		} else if (sort_option === ITEM_GROUP_SORT.Oldest) {
			return objsCopy.sort((a, b) => {
				if (a.created_at > b.created_at) {
					return 1;
				} else if (a.created_at < b.created_at) {
					return -1;
				} else {
					return 0;
				}
			});
		} else if (sort_option === ITEM_GROUP_SORT["Name (a - z)"]) {
			return objsCopy.sort((a, b) => {
				const aName = a.name as string;
				const bName = b.name as string;
				if (aName > bName) {
					return 1;
				} else if (aName < bName) {
					return -1;
				} else {
					return 0;
				}
			});
		} else if (sort_option === ITEM_GROUP_SORT["Count (ascend)"]) {
			return objsCopy.sort((a, b) => a.item_count - b.item_count);
		} else if (sort_option === ITEM_GROUP_SORT["Count (descend)"]) {
			return objsCopy.sort((a, b) => b.item_count - a.item_count);
		} else {
			return objsCopy;
		}
	};

	const getFilteredBoxes = (groups: Box[]) => {
		const boxesCopy = [...groups];
		const sort_option = boxGroupSorting;

		if (sort_option === BOX_GROUP_SORT.Newest) {
			return boxesCopy.sort((a, b) => {
				if (a.created_at < b.created_at) {
					return 1;
				} else if (a.created_at > b.created_at) {
					return -1;
				} else {
					return 0;
				}
			});
		} else if (sort_option === BOX_GROUP_SORT.Oldest) {
			return boxesCopy.sort((a, b) => {
				if (a.created_at > b.created_at) {
					return 1;
				} else if (a.created_at < b.created_at) {
					return -1;
				} else {
					return 0;
				}
			});
		} else if (sort_option === BOX_GROUP_SORT["Name (a - z)"]) {
			return boxesCopy.sort((a, b) => {
				const aName = a.name as string;
				const bName = b.name as string;
				if (aName > bName) {
					return 1;
				} else if (aName < bName) {
					return -1;
				} else {
					return 0;
				}
			});
		} else if (sort_option === BOX_GROUP_SORT["Count (ascend)"]) {
			return boxesCopy.sort((a, b) => a.item_count - b.item_count);
		} else if (sort_option === BOX_GROUP_SORT["Count (descend)"]) {
			return boxesCopy.sort((a, b) => b.item_count - a.item_count);
		} else {
			return boxesCopy;
		}
	};
	// Moving cart for boxes in categories
	const [movingCartBoxId, setMovingCartBoxId] = useState<number | null>(null);
	const handleMovingCart = (boxData: Box) => {
		if (!category) return;
		setMovingCartBoxId(null);
	};

	const { appendBaseUrl } = useOrganizationRouter();
	const history = useHistory();
	const selectBox = (box: ItemGroup | Box) => {
		history.push(
			appendBaseUrl(
				FREEZER_PATHS.BOXES.replace(
					":freezer_id",
					"" + box.location.freezer
				).replace(":box_id", "" + box.id)
			)
		);
	};

	const selectItemGroup = (itemGroup: ItemGroup) => {
		history.push(
			appendBaseUrl(
				FREEZER_PATHS.ITEMGROUPS.replace(
					":freezer_id",
					"" + itemGroup.location.freezer
				).replace(":item_group_id", "" + itemGroup.id)
			)
		);
	};

	const renderBoxes = () => {
		const filteredBoxes = boxes;
		if (!filteredBoxes.length) {
			return renderEmptyState(CATEGORY_TYPE.BOXES);
		}

		return getFilteredGroups(CATEGORY_TYPE.BOXES, filteredBoxes).map(
			(box) => {
				return (
					<BoxTableCell
						viewOnly={viewOnly}
						key={box.id}
						box={box as Box}
						showRearrangeBoxModal={props.showRearrangeBoxModal}
						showEditRackModal={props.showEditRackModal}
						setMovingCartBoxId={setMovingCartBoxId}
						handleAddBox={handleAddBox}
						onClick={() => selectBox(box as Box)}
					/>
				);
			}
		);
	};

	//method above rewritten with sorting logic
	const renderItemGroups = () => {
		if (!itemGroups.length) {
			return renderEmptyState(CATEGORY_TYPE.ITEMS);
		}
		if (itemGroupLayout == ITEM_GROUP_LAYOUT.LIST) {
			return (
				<ItemGroupList
					itemGroups={
						getFilteredGroups(
							CATEGORY_TYPE.ITEMS,
							itemGroups
						) as ItemGroup[]
					}
					viewOnly={viewOnly}
					onRow={(group: ItemGroup) => {
						return {
							onClick: () => {
								selectItemGroup(group);
							},
						};
					}}
				/>
			);
		} else {
			return getFilteredGroups(CATEGORY_TYPE.ITEMS, itemGroups).map(
				(group, idx) => {
					return (
						<ItemGroupCard
							key={idx}
							itemGroup={group as ItemGroup}
							viewOnly={viewOnly}
							onClick={() => selectItemGroup(group as ItemGroup)}
						/>
					);
				}
			);
		}
	};

	const renderSortSelecter = (category_type: CATEGORY_TYPE) => {
		const defaultOption =
			category_type == CATEGORY_TYPE.ITEMS
				? itemGroupSorting
				: boxGroupSorting;
		const sortOptions =
			category_type == CATEGORY_TYPE.ITEMS
				? ITEM_GROUP_SORT
				: BOX_GROUP_SORT;

		return (
			<Sort
				defaultValue={defaultOption}
				sortList={sortOptions}
				onChange={(val) => {
					if (category_type === CATEGORY_TYPE.BOXES) {
						setBoxGroupSorting(val as BOX_GROUP_SORT);
					} else if (category_type === CATEGORY_TYPE.ITEMS) {
						setItemGroupSorting(val as ITEM_GROUP_SORT);
					}
				}}
			/>
		);
	};
	const renderEmptyState = (category_type: CATEGORY_TYPE) => {
		const empty_state_style: React.CSSProperties = {};
		if (category_type === CATEGORY_TYPE.BOXES) {
			empty_state_style["marginBottom"] = "21px"; //50-29px (50: desire, 29: marginTop from individual item section)
		}
		return (
			<div style={empty_state_style}>
				<Typography color="text-secondary">
					No {CATEGORY_TYPE[category_type].toLowerCase()} found.
				</Typography>
			</div>
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.categoryView}>
				<div className={styles.title}>
					<GenemodIcon name="category" />
					<ClickToEdit
						className={styles.clickToEdit}
						value={category?.name || ""}
						onComplete={(val) => {
							if (!category) return;
							updateCategory({ id: category.id, name: val });
						}}
						variant="headline5"
						color="text-secondary-v2"
						addConfirmAndCancel={false}
						component="input"
						lightBackground
						cancelEditionIfEmpty
						tabToSave
					/>

					{category && (
						<RackMenu
							freezer={freezer}
							rackOrCategory={category}
							viewOnly={viewOnly}
						>
							<GenemodIcon
								name="meatballs"
								style={{
									marginLeft: "auto",
									minHeight: 32,
									minWidth: 32,
								}}
								className={styles.categoryMenu}
							/>
						</RackMenu>
					)}
				</div>
				<div className={styles.wrapper}>
					<div className={styles.content}>
						<div className={styles.boxes}>
							<div className={styles.boxesToolbar}>
								<Typography bold>Boxes</Typography>
								<ButtonV2
									size="small"
									icon="plus-v2"
									style={{ marginLeft: "auto" }}
									iconColor="text-tertiary-v2"
									onClick={() => setAddingBox(true)}
								>
									<Typography
										variant="small"
										color="text-secondary-v2"
									>
										Add box
									</Typography>
								</ButtonV2>
							</div>
							<div className={styles.boxesDisplay}>
								{renderBoxes()}
							</div>
						</div>
						<div className={styles.itemGroups}>
							<div className={styles.itemGroupToolbar}>
								<Typography bold>Item groups</Typography>
								<ButtonV2
									size="small"
									icon="plus-v2"
									style={{ marginLeft: "auto" }}
									iconColor="text-tertiary-v2"
									onClick={() => setAddingGroup(true)}
								>
									<Typography
										variant="small"
										color="text-secondary-v2"
									>
										Add item group
									</Typography>
								</ButtonV2>
								<div className="filter">
									{renderSortSelecter(CATEGORY_TYPE.ITEMS)}
								</div>
								<GridViewToggle
									isGrid={
										itemGroupLayout ===
										ITEM_GROUP_LAYOUT.GRID
									}
									onGrid={() =>
										setItemGroupLayout(
											ITEM_GROUP_LAYOUT.GRID
										)
									}
									onList={() =>
										setItemGroupLayout(
											ITEM_GROUP_LAYOUT.LIST
										)
									}
								/>
							</div>
							<div
								className={classNames({
									[styles.itemGroupsGrid]:
										itemGroupLayout ===
										ITEM_GROUP_LAYOUT.GRID,
								})}
							>
								{renderItemGroups()}
							</div>
						</div>
					</div>
				</div>
			</div>
			<BoxMovingCart
				boxId={movingCartBoxId}
				onClose={() => setMovingCartBoxId(null)}
				onMoveBox={handleMovingCart}
			/>
			<AddItemGroupModal
				visible={isAddingGroup}
				defaultItemType={defaultItemType}
				onCancel={() => setAddingGroup(false)}
				onConfirm={handleAddItemGroup}
			/>
			<AddBoxModal
				visible={isAddingBox}
				onCancel={() => setAddingBox(false)}
				onConfirm={handleAddBox}
				isCategory
			/>
		</div>
	);
}
