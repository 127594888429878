import React from "react";
import { ImportStep3 } from "@containers/Freezer/table/BoxImport";
import { useRepository } from "@containers/Freezer/Repository/RepositoryContext";
import { useBoxView } from "@containers/Freezer/table/BoxTableHooks";
import { Item } from "@common/types";

function findEmptySpaces(items: Item[], numRows: number, numColumns: number) {
	const occupied = new Set(); // To hold occupied cells as "row,column"

	// Mark occupied spaces by adding them to the set
	items.forEach((item) => {
		if (item.location && item.location.box_location) {
			const { row, column } = item.location.box_location;
			if (row < numRows && column < numColumns) {
				occupied.add(`${row},${column}`); // Use string to uniquely identify the position
			}
		}
	});

	// Collect empty spaces
	const emptySpaces = [];
	for (let row = 0; row < numRows; row++) {
		for (let column = 0; column < numColumns; column++) {
			if (!occupied.has(`${row},${column}`)) {
				// Check if the position is not in the occupied set
				emptySpaces.push({ row, column });
			}
		}
	}

	return emptySpaces;
}

function OrganizeItemOnBox() {
	const { selectedItems, setSelectedItems } = useRepository();
	const { box, items } = useBoxView();

	const spaces = findEmptySpaces(items, box?.rows ?? 0, box?.columns ?? 0);

	const itemsSelected = (selectedItems as Item[]).map((item, idx) => {
		if (
			!item.location?.box_location?.row &&
			!item.location?.box_location?.column
		) {
			return {
				...item,
				location: {
					box_location: {
						box: box?.id,
						row: spaces[idx]["row"],
						column: spaces[idx]["column"],
					},
				},
			};
		}
		return item;
	});
	const selectedIds = itemsSelected.map((item) => item.id);

	return (
		<div className="flex mt-5">
			<div
				className="w-full"
				style={{
					height: "calc(100vh - 224px)",
				}}
			>
				<ImportStep3
					newItems={itemsSelected as Item[]}
					box={box as any}
					items={items}
					setNewItems={(items: Item[]) => {
						if (items.length === 0) return;
						setSelectedItems(items);
					}}
					guidenceLabel="Newly Restored"
					isNewItem={(item: Item) => {
						return selectedIds.includes(item.id);
					}}
				/>
			</div>
		</div>
	);
}

export default OrganizeItemOnBox;
