import { useMemo } from "react";
import {
	ITEM_GROUP_SORT,
	ITEM_GROUP_LAYOUT,
	BOX_GROUP_SORT,
	FREEZER_SORT,
	CONSUMABLE_SORT,
	ARCHIVED_CONSUMABLE_SORT,
	ARCHIVED_FREEZER_SORT,
} from "@containers/Freezer/data";
import { Layout } from "../components/ListGridContainer/ListGridContainer";
import { ValuesOf } from "@helpers/TypeHelpers";
import { CATEGORY_SORT } from "@containers/Freezer/SpaceView/FurnitureCategoryListPanel/FurnitureCategoryListPanel";
import { LastSelectedItemType } from "../../containers/Freezer/data";
import { SortOrder } from "antd/lib/table";
import { UserPreferencesTabData } from "./TabsContext";

export const DEFAULT_NUM_ITEMS_PER_PAGE = "25";

export const useUserSettingsDefault = () => {
	return useMemo(
		() => ({
			DUMMY: undefined,
			FREEZER_CATEGORY_ITEM_SORT:
				ITEM_GROUP_SORT.Newest as ITEM_GROUP_SORT,
			FREEZER_CATEGORY_ITEM_LAYOUT:
				ITEM_GROUP_LAYOUT.GRID as ITEM_GROUP_LAYOUT,
			FREEZER_CATEGORY_BOX_SORT: BOX_GROUP_SORT.Newest as BOX_GROUP_SORT,
			LOCKED_FREEZER_BANNER_VISIBILITY: true as boolean,
			LOCKED_PROJECT_BANNER_VISIBILITY: true as boolean,
			LOCKED_CUSTOM_ITEM_TYPE_BANNER_VISIBILITY: true as boolean,
			FREEZER_SORT: FREEZER_SORT["Recently created"] as FREEZER_SORT,
			CONSUMABLE_SORT: CONSUMABLE_SORT[
				"Recently created"
			] as CONSUMABLE_SORT,
			ARCHIVED_CONSUMABLE_SORT: ARCHIVED_CONSUMABLE_SORT[
				"Recently archived"
			] as ARCHIVED_CONSUMABLE_SORT,
			ARCHIVED_FREEZER_SORT: ARCHIVED_FREEZER_SORT[
				"Recently archived"
			] as ARCHIVED_FREEZER_SORT,
			FURNITURE_CATEGORY_SORT: 0 as ValuesOf<typeof CATEGORY_SORT>,
			FURNITURE_CATEGORY_VIEW: "GRID" as Layout,
			CONSUMABLES_TABLE_SORT: undefined as
				| {
						key: React.Key;
						sortOrder: SortOrder | undefined;
				  }
				| undefined,
			THEME_TOOLTIP_SEEN: false as boolean,
			SELECTED_ITEM_TYPE: {} as LastSelectedItemType,
			SHOW_INLINE_EDIT_ANNOUNCEMENT: true as boolean,
			FREEZER_SEARCH_ITEMS_PER_PAGE: DEFAULT_NUM_ITEMS_PER_PAGE as string,
			ORDER_INFO_PANEL_TAB: "1" as string,
			PM_TABS: { schemaVersion: 1 } as UserPreferencesTabData,
		}),
		[]
	);
};

export type UserSettings = ReturnType<typeof useUserSettingsDefault>;
export type UserSettingsKey = keyof UserSettings;

export type UserPreference = {
	theme: number;
	settings: UserSettings;
};
