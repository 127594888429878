import {
	createEntityAdapter,
	createSlice,
	EntityState,
	PayloadAction,
} from "@reduxjs/toolkit";
import {
	initPayload,
	updateLoading,
	updateSuccess,
} from "@redux/helpers/DataPayload";
import { Item } from "@common/types";
import { AppState } from "@redux/store";
import {
	createActionsHook,
	createSelectorHook,
} from "../helpers/ActionHelpers";

export const searchResultsAdapter = createEntityAdapter<Item>();
export const boxViewSlice = createSlice({
	name: "boxView",
	initialState: {
		selectedItemId: null as number | null,
		searchText: "",
		selectedItemIndex: 0 as number | "UNSELECTED",
		searchResults: initPayload<EntityState<Item>>(
			searchResultsAdapter.getInitialState()
		),
		itemPrintId: initPayload<number | null>(null),
	},
	reducers: {
		setSelectedItemId: (state, action: PayloadAction<number | null>) => {
			state.selectedItemId = action.payload;
		},
		setSearchText: (
			state,
			action: PayloadAction<string | null | undefined>
		) => {
			const searchText = action.payload || "";
			state.searchText = searchText;
		},
		setSearchResults: (
			state,
			action: PayloadAction<Item[] | undefined>
		) => {
			searchResultsAdapter.setAll(
				state.searchResults.data as EntityState<Item>,
				action.payload || []
			);
			if (action?.payload && action.payload.length > 0) {
				if (state.selectedItemIndex !== 0) {
					state.selectedItemIndex = 0;
				}
			} else {
				state.selectedItemIndex = "UNSELECTED";
			}
		},
		setSelectedItemIndex: (state, action: PayloadAction<number>) => {
			let index = action.payload;
			const searchResults = searchResultsAdapter
				.getSelectors()
				.selectAll(state.searchResults.data as EntityState<Item>);
			if (index < 0) {
				index = searchResults.length - 1;
			} else if (index >= searchResults.length) {
				index = 0;
			}
			state.selectedItemIndex = index;
		},
		prepareItemPrint: (state, action: PayloadAction<number>) => {
			state.itemPrintId.data = action.payload;
			updateLoading(state.itemPrintId);
		},
		finishItemPrint: (state) => {
			updateSuccess(state.itemPrintId, null);
		},
	},
});
// search selector
export const searchResultsSelector = searchResultsAdapter.getSelectors(
	(state: AppState) =>
		state.freezer.boxView.searchResults.data as EntityState<Item>
);
export const {
	setSearchText,
	prepareItemPrint,
	finishItemPrint,
	setSelectedItemIndex,
	setSearchResults,
} = boxViewSlice.actions;

export const useBoxViewFields = createSelectorHook(
	`freezer.${boxViewSlice.name}`
);
export const useBoxViewActions = createActionsHook(boxViewSlice.actions);
