import {
	AuthenticationDetails,
	CognitoUserSession,
	CognitoAccessToken,
	CognitoIdToken,
	CognitoUser,
	CognitoRefreshToken,
	CognitoUserAttribute,
	ISignUpResult,
} from "amazon-cognito-identity-js";
import { RegistrationFormData } from "../../LoginContext";
import {
	cognitoPromise,
	getLocalStorageUser,
	getUser,
	getUserName,
	getUserPool,
	LocalStorageUserNotFoundError,
} from "./CognitoHelpers";

// Note: The "errors" defined below are not necessarily comprehensive for each cognito action.

const BLANK_REFRESH_TOKEN = "NONE";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Registration
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// registration errors
type InvalidPasswordException = Error & {
	name: "InvalidPasswordException";
};

type UsernameExistsException = Error & {
	name: "UsernameExistsException";
};

type RegistrationErrors =
	| InvalidParameterException
	| InvalidPasswordException
	| UsernameExistsException;

export const cognitoRegister = async ({
	email,
	password,
	first_name,
	last_name,
}: RegistrationFormData) =>
	cognitoPromise<RegistrationErrors, ISignUpResult>(({ autoResolve }) => {
		getUserPool().signUp(
			getUserName(email),
			password,
			[
				new CognitoUserAttribute({
					Name: "email",
					Value: email,
				}),
				new CognitoUserAttribute({
					Name: "given_name",
					Value: first_name,
				}),
				new CognitoUserAttribute({
					Name: "family_name",
					Value: last_name,
				}),
			],
			[],
			autoResolve()
		);
	});

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Verify email
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// also sent if email is invalid
type ExpiredCodeException = Error & {
	name: "ExpiredCodeException";
};
// if the email is fine but the code is wrong
type CodeMismatchException = Error & {
	name: "CodeMismatchException";
};
type VerifyEmailErrors =
	| InvalidParameterException
	| LocalStorageUserNotFoundError
	| ExpiredCodeException
	| CodeMismatchException;

export const cognitoVerifyEmail = (code: string) =>
	cognitoPromise<VerifyEmailErrors>(({ failure, autoResolve }) =>
		getUser("jlawrence6809@yahoo.com")?.confirmRegistration(
			code,
			true,
			autoResolve({ emptyResultIsValid: true })
		)
	);

// Does not error except if email is empty
type ResendEmailVerificationErrors =
	| InvalidParameterException
	| LocalStorageUserNotFoundError;
export const cognitoResendEmailVerificationCode = () =>
	cognitoPromise<ResendEmailVerificationErrors>(({ failure, autoResolve }) =>
		getLocalStorageUser(failure)?.resendConfirmationCode(
			<any>autoResolve({ emptyResultIsValid: true })
		)
	);

// Invalid email address, empty fields etc. Most of these we should probably handle before submit.
type InvalidParameterException = Error & {
	name: "InvalidParameterException";
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Login
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

type NotAuthorizedException = Error & {
	name: "NotAuthorizedException";
	message: "Incorrect username or password.";
};

type UserNotConfirmedException = Error & {
	name: "UserNotConfirmedException";
	message: "User is not confirmed.";
};

type LoginErrors =
	| InvalidParameterException
	| NotAuthorizedException
	| UserNotConfirmedException;

export type LoginArgs = {
	email: string;
	password: string;
};
export const cognitoLogin = async (
	{ email, password }: LoginArgs,
	rememberLogin: boolean
) =>
	cognitoPromise<LoginErrors, CognitoUserSession>(({ success, failure }) =>
		getUser(email).authenticateUser(
			new AuthenticationDetails({
				Username: getUserName(email),
				Password: password,
			}),
			{
				onSuccess: (session) => {
					if (rememberLogin) {
						success(session);
						return;
					}
					// If user does not check "remember me" we want to get rid of the refresh token so that
					// the token only lasts for 1 day (or however long we set up in the cognito dashboard).
					// Relogging in with only id/access tokens.
					success(
						cognitoLoginViaTokens({
							IdToken: session.getIdToken().getJwtToken(),
							AccessToken: session.getAccessToken().getJwtToken(),
						})
					);
				},
				onFailure: failure,
			}
		)
	);

export type CognitoTokens = {
	IdToken: string;
	AccessToken: string;
};
export const cognitoLoginViaTokens = ({
	IdToken,
	AccessToken,
}: CognitoTokens) => {
	const tokens = {
		IdToken: new CognitoIdToken({ IdToken }),
		AccessToken: new CognitoAccessToken({ AccessToken }),
		// Login via tokens does not have refresh tokens b/c the hosted UI does not support it for implicit auths.
		RefreshToken: new CognitoRefreshToken({
			RefreshToken: BLANK_REFRESH_TOKEN,
		}),
	};
	const session = new CognitoUserSession(tokens);
	new CognitoUser({
		Username: tokens.AccessToken.payload.username,
		Pool: getUserPool(),
	}).setSignInUserSession(session);
	return session;
};

type InvalidSessionError = Error & {
	name: "InvalidSessionError";
};
/**
 * Returns a validated cognito user, if there are no cognito tokens in local storage or refreshing a token fails, it will return failure.
 */
export type CognitoUserAndSessionErrors =
	| LocalStorageUserNotFoundError
	| InvalidSessionError
	| NotAuthorizedException;
export const cognitoGetUserWithValidSession = async () =>
	cognitoPromise<CognitoUserAndSessionErrors, CognitoUser>(
		({ success, failure, autoResolve }) => {
			const cognitoUser = getLocalStorageUser(failure);

			if (!cognitoUser) return;
			cognitoUser.getSession(
				autoResolve<CognitoUserSession>({
					alternativeSuccess: (session) => {
						if (session.isValid()) {
							success(cognitoUser);
							return;
						}
						failure({
							name: "InvalidSessionError",
							message: "Invalid session failure",
						});
					},
				})
			);
		}
	);

/**
 * Clears the tokens from local storage.
 */
export const cognitoLogout = () => getLocalStorageUser()?.signOut();

/**
 * Updates user's password in cognito
 */
export const cognitoUpdatePassword = async (
	oldPassword: string,
	newPassword: string
) =>
	cognitoPromise<CognitoUserAndSessionErrors>(({ failure, autoResolve }) =>
		cognitoGetUserWithValidSession().then((result) => {
			if (result.type === "failure") {
				failure(result.error);
				return;
			}
			result.result.changePassword(
				oldPassword,
				newPassword,
				autoResolve()
			);
		})
	);

type UnverifiedEmailError = Error & { name: "UnverifiedEmailError" };
export const cognitoSendForgotPasswordEmail = async (email: string) =>
	cognitoPromise<UnverifiedEmailError>(({ success, failure }) =>
		getUser(email).forgotPassword({
			onSuccess: (data) => {
				success();
			},
			onFailure: (err) => {
				if (err.name === "InvalidParameterException") {
					failure({
						name: "UnverifiedEmailError",
						message: "Email is not yet verified",
					});
				}
				failure(err as any);
			},
		})
	);

export const cognitoResetForgottenPassword = async (
	email: string,
	code: string,
	newPassword: string
) =>
	cognitoPromise<Error>(({ success, failure }) =>
		getUser(email)?.confirmPassword(code, newPassword, {
			onSuccess(data) {
				success();
			},
			onFailure: failure,
		})
	);
