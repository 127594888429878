import React from "react";
import { Typography, Button } from "@common/components";
import { useWindowDimensions } from "@helpers/Hooks";
import { NarrowOnboarding } from "..";
import styles from "./AccountDeactivatedScreen.module.scss";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";

/**
 * Account deactivated placeholder screen
 * @param {function} close close this screen and return to login
 */
export default function AccountDeactivatedScreen({
	adminConsole = false,
}: {
	adminConsole?: boolean;
}): JSX.Element {
	const history = useHistory();
	const { width } = Object(useWindowDimensions());
	const largeWidth = width >= 1920;

	return (
		<NarrowOnboarding
			modalWidth={largeWidth ? 512 : 456}
			modalHeight={largeWidth ? 590 : 511}
			className={styles.accountDeactivateModal}
		>
			<Typography
				variant="title"
				bold
				className={styles.textcenter}
				style={{
					marginTop: 32,
					marginBottom: 24,
				}}
			>
				Your account is deactivated
			</Typography>
			<Typography className={styles.textcenter}>
				Your account is deactivated by your admin. If you believe this
				is an error, please contact your admin to reactivate.
			</Typography>
			<Button
				style={{
					marginTop: 48,
				}}
				onClick={() =>
					history.push({
						pathname: adminConsole ? "/admin/logout" : "/logout",
					})
				}
				stretch
			>
				Return to login
			</Button>
		</NarrowOnboarding>
	);
}
