import React, { useRef } from "react";
import { Descendant, Editor, Node, Text } from "slate";
import { overrideFunction } from "./PluginHelpers";
import { ForceRenderElementsEditor } from "./withForceRenderElementsPlugin";
import { UtilityEditor } from "./withUtilityPlugin";

type PlaceholderMinalEditor = Editor &
	ForceRenderElementsEditor &
	UtilityEditor;

/**
 * What is the expected number of children for an editor to be "blank"
 */
const getExpectedChildrenLength = (editor: Editor) => {
	return 1;
};

/**
 * Recursive function that checks if a node/descendent is a text node or if all it's descendents are text nodes
 */
const allNodesAreText = (node: Descendant): boolean => {
	// base case
	if (Text.isText(node)) {
		return true;
	}

	// Text nodes do not have "type"
	if ((node as any).type !== undefined) {
		return false;
	}

	// Some other type of node without a type and children?
	if (!node.children) {
		return false;
	}

	return !node.children.find((child) => !allNodesAreText(child));
};

/**
 * Assuming we have the "getExpectedChildrenLength" for a blank editor, what is the content of the body node?
 */
export const getIsBodyEmpty = (editor: Editor) => {
	const lastNode = editor.children[getExpectedChildrenLength(editor) - 1];
	return (
		!!lastNode && allNodesAreText(lastNode) && Node.string(lastNode) === ""
	);
};

/**
 * Renders the given placeholder plugin when the document body is empty.
 */
export const usePlaceholderPlugin = <T extends PlaceholderMinalEditor>(
	/**
	 * Return null if you do not want the placeholder to be displayed
	 */
	placeholder: React.ReactNode
): ((editor: T) => T) => {
	// Note: the following function ends up as a memoized plugin, do not use renderPlaceholder directly
	return (editor: T): T => {
		overrideFunction(editor)("renderPlaceholder")(() => (props) => {
			const { attributes, children } = props;
			return (
				<div {...attributes}>
					{placeholder}
					{children}
				</div>
			);
		});

		return editor;
	};
};
