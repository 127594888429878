import React, { useEffect, useState } from "react";
import styles from "./ConnectingAnimations.module.scss";
import cn from "classnames";
import { Demo, DemoSection, DemoWrapper } from "@components";
import { Color, ColorCssVarMap } from "@common/styles/Colors";
import Tooltip from "../Tooltip";

export type ConnectingAnimationsProps = {
	connected: boolean;
};

export default function ConnectingAnimations({
	connected,
}: ConnectingAnimationsProps): JSX.Element {
	// delay the showing of the "connecting" animation for small amount of time to prevent abrupt changes when pages are loading quickly
	const [show, setShow] = useState(false);

	useEffect(() => {
		setTimeout(() => setShow(true), 500);
	}, []);

	return (
		<Tooltip
			title={
				connected
					? "All changes are automatically saved"
					: "Connecting..."
			}
			placement="right"
		>
			{connected && <ConnectedAnimation />}
			{show && !connected && <ConnectingAnimation />}
		</Tooltip>
	);
}

const COMMON_SVG_PROPS = {
	className: styles.normalizedPathLength,
	xmlns: "http://www.w3.org/2000/svg",
	width: 24,
	viewBox: "0 0 16 10.667",
};

function ConnectingAnimation(): JSX.Element {
	return (
		<svg {...COMMON_SVG_PROPS}>
			<CloudPath stroke="border-subtle" animated={false} />
			<CloudPath stroke="accent-subtle" animated />
		</svg>
	);
}

function ConnectedAnimation(): JSX.Element {
	return (
		<svg {...COMMON_SVG_PROPS}>
			<CloudPath
				stroke="accent-subtle"
				fill="accent-subtle"
				animated={false}
			/>
			<path
				className={styles.checkPath}
				d="M 4.8 6 L 6.6 7.7 L 10.5 4"
				strokeWidth={1.2}
				stroke={ColorCssVarMap["text-inverse"]}
				fill="none"
				pathLength={
					100 /* Defines the lenght of the path as 100 so we can more easily reference it during the animation */
				}
			/>
		</svg>
	);
}

type CloudPathProps = {
	animated: boolean;
	stroke: Color;
	fill?: Color;
};
const CloudPath = ({ animated, stroke, fill }: CloudPathProps) => {
	return (
		<path
			className={cn({ [styles.connectingPath]: animated })}
			d="M 8 0.65 C 8.5 0.65 12 1 12.3 4.7 C 16.3 4.6 16.4 9.9 12.5 10 L 4 10 C -0.5 10 -0.5 3.3 4 3.35 C 5 1 7 0.65 8 0.65"
			strokeWidth={1.35}
			pathLength={
				100 /* Defines the lenght of the path as 100 so we can more easily reference it during the animation */
			}
			stroke={ColorCssVarMap[stroke]}
			fill={fill ? ColorCssVarMap[fill] : "none"}
		/>
	);
};

export function CONNECTING_ANIMATION_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo>
					<ConnectingAnimation />
				</Demo>
				<Demo>
					<ConnectedAnimation />
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
