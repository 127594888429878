import { Button, Demo, DemoSection, DemoWrapper, Modal } from "@components";
import { ModalProps } from "@components/Modal/Modal";
import React, { useState } from "react";

type CommonDeleteModalProps = {
	titleObject?: string;
	bodyObject?: string;
	dataCy?: string;
	loading?: boolean;
} & Omit<ModalProps, "children">;

const CommonDeleteModal: React.FunctionComponent<CommonDeleteModalProps> = ({
	titleObject,
	bodyObject,
	dataCy,
	loading,
	...props
}: CommonDeleteModalProps) => {
	return (
		<Modal
			title={`Delete ${titleObject}?`}
			okButtonProps={{
				danger: true,
				loading,
			}}
			okText="Delete"
			hideCloseButton
			{...props}
			dataCy={dataCy}
		>
			You are about to delete <strong>{bodyObject}.</strong> This action
			cannot be undone.
		</Modal>
	);
};

export default CommonDeleteModal;

export function COMMON_DELETE_MODAL_DEMO(): JSX.Element {
	const [visible, setVisible] = useState(false);
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Common Delete Modal"
					description={<div>Click the button to open the modal.</div>}
				>
					<Button onClick={() => setVisible(true)}>Open</Button>

					<div id="deleteModalContainer"></div>
					<CommonDeleteModal
						titleObject="freezer"
						bodyObject="this freezer"
						visible={visible}
						onOk={() => setVisible(false)}
						onCancel={() => setVisible(false)}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
