import { Freezer } from "./Freezer";
import { Shelf } from "./Shelf";
import { Rack } from "./Rack";
import { Category } from "./Category";
import { Item } from "./Item";
import { Box } from "./Box";
import { ItemGroup } from "./ItemGroup";

export type TableItem =
	| Freezer
	| Shelf
	| Rack
	| Category
	| Item
	| Box
	| ItemGroup;
