import {
	ButtonV2,
	GenemodIcon,
	Notification,
	Typography,
} from "@common/components";
import { Category, Rack, Shelf, SimpleShelf } from "@common/types";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { truncArgs } from "@helpers/Formatters";
import {
	useCategoryPatchMutation,
	useCategoryQuery,
} from "@redux/inventory/Category";
import {
	useLazyRacksQuery,
	useRackPatchMutation,
	useRackQuery,
} from "@redux/inventory/Rack";
import {
	useLazyCategoriesQuery,
	useShelvesQuery,
} from "@redux/inventory/Shelf";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import classNames from "classnames";
import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";
import MovingCart, { MovingCartContext } from "..";
import styles from "./RackMovingCart.module.scss";
import { useFreezer } from "@containers/Freezer/contents/Contents";
import { useParams } from "@helpers/URLParams";

type Props = {
	visible: boolean;
	onClose: () => void;
	rackOrCategory: Rack | Category;
};

const RackMovingCart = ({ rackOrCategory, visible, onClose }: Props) => {
	return (
		<MovingCart
			visible={visible}
			onClose={onClose}
			title={`Moving "${
				rackOrCategory?.name || rackOrCategory?.name
			}" to`}
			showRacks={false}
		>
			<RackPlacement rackOrCategory={rackOrCategory} onClose={onClose} />
		</MovingCart>
	);
};

export default RackMovingCart;

type RackPlacementProps = {
	onClose: () => void;
	rackOrCategory: Rack | Category;
};

const RackPlacement = ({ rackOrCategory, onClose }: RackPlacementProps) => {
	const isRack = (obj: Rack | Category) => {
		return (obj as Rack).rows !== undefined ? (obj as Rack) : undefined;
	};
	const isCategory = (obj: Rack | Category) => {
		return (obj as any).rows === undefined ? (obj as Category) : undefined;
	};

	const rack = isRack(rackOrCategory);
	const category = isCategory(rackOrCategory);

	const { setFreezer, freezerId } = useContext(MovingCartContext);
	const { data: shelves = [] } = useShelvesQuery(
		{ id: freezerId as number } || skipToken
	);
	const [patchRack] = useRackPatchMutation();
	const [patchCategory] = useCategoryPatchMutation();
	const [selectedShelf, setSelectedShelf] = useState<number>(-1);
	const [rackIndex, setRackIndex] = useState<null | number>(null);
	const [isRackOrCategory, setIsRackOrCategory] = useState<
		"rack" | "category"
	>("rack");
	const [isLeft, setIsLeft] = useState(false);
	const { freezer: currentFreezer } = useFreezer();
	const { deleteParam } = useParams();

	const deleteParamUrl = (paramName: string) => {
		if (freezerId !== currentFreezer?.id) {
			deleteParam(paramName);
		}
	};

	const handleButton = () => {
		if (rackIndex === null || rackIndex === undefined) return;
		if (rack) {
			patchRack({
				id: rack.id,
				location: {
					freezer: freezerId || -1,
					shelf: selectedShelf,
					index: rackIndex + (isLeft ? 0 : 1),
				},
			})
				.then(() => {
					Notification.success({
						message: (
							<span>
								<b>{rack.name} </b>
								has been moved to shelf
								<b>
									{" "}
									{shelves
										.filter(
											(shelf) =>
												shelf.id === selectedShelf
										)
										.map((shelf) =>
											truncArgs`${shelf.name}`(68)
										)}
								</b>
							</span>
						),
					});
				})
				.catch(() => {
					Notification.warning({
						message:
							"Failed to move rack. Try again or contact us if it continues.",
					});
				})
				.finally(() => {
					deleteParamUrl("selected_rack");
					onClose();
				});
		} else if (category) {
			patchCategory({
				id: category.id,
				location: {
					freezer: freezerId || -1,
					shelf: selectedShelf,
					index: rackIndex + (isLeft ? 0 : 1),
				},
			})
				.then(() => {
					Notification.success({
						message: (
							<span>
								<b>{category.name} </b>
								has been moved to shelf
								<b>
									{" "}
									{shelves
										.filter(
											(shelf) =>
												shelf.id === selectedShelf
										)
										.map((shelf) =>
											truncArgs`${shelf.name}`(68)
										)}
								</b>
							</span>
						),
					});
				})
				.catch(() => {
					Notification.warning({
						message:
							"Failed to move category. Try again or contact us if it continues.",
					});
				})
				.finally(() => {
					deleteParamUrl("selected_category");
					onClose();
				});
		}
	};

	useEffect(() => {
		if (rack) {
			if (freezerId === null) {
				setFreezer(rack.location.freezer);
				setIsRackOrCategory("rack");
				setSelectedShelf(rack.location.shelf);
			}
		} else if (category) {
			if (freezerId === null) {
				setFreezer(category.location.freezer);
				setIsRackOrCategory("category");
				setSelectedShelf(category.location.shelf);
			}
		}
	}, [rack, category, freezerId]);

	return (
		<div className={styles.container}>
			<div>
				{(rack || category) && shelves.length ? (
					shelves
						.slice()
						.sort((a, b) => a.row - b.row)
						.map((shelf) => {
							return (
								<ShelfRow
									key={shelf.id}
									shelf={shelf}
									selectedRackOrCategory={
										rack ? rack : category!
									}
									selected={selectedShelf === shelf.id}
									setSelectedShelf={setSelectedShelf}
									changeIndex={(index) => setRackIndex(index)}
									isRackOrCategory={isRackOrCategory}
									setIsLeft={setIsLeft}
								/>
							);
						})
				) : (
					<Typography>
						There are no shelves on this freezer.
					</Typography>
				)}
			</div>
			<div className={styles.right}>
				<ButtonV2
					type="primary"
					onClick={handleButton}
					disabled={!selectedShelf || !shelves.length}
				>
					Move here
				</ButtonV2>
			</div>
		</div>
	);
};

type ShelfRowProps = {
	shelf: SimpleShelf;
	selectedRackOrCategory: Rack | Category;
	selected?: boolean;
	setSelectedShelf: Dispatch<SetStateAction<number>>;
	changeIndex: (index: number) => void;
	isRackOrCategory: "rack" | "category";
	setIsLeft: Dispatch<SetStateAction<boolean>>;
};

const ShelfRow = ({
	shelf,
	selectedRackOrCategory,
	selected,
	setSelectedShelf,
	changeIndex,
	isRackOrCategory,
	setIsLeft,
}: ShelfRowProps) => {
	const [getRacks] = useLazyRacksQuery();
	const [getCategories] = useLazyCategoriesQuery();
	const [patchRack] = useRackPatchMutation();
	const [patchCategory] = useCategoryPatchMutation();
	const [racksAndCats, setRacksAndCats] = useState<(Rack | Category)[]>([]);

	const { freezer: currentFreezer } = useFreezer();
	const { deleteParam } = useParams();

	const isCurrentShelf = selectedRackOrCategory.location.shelf === shelf.id;

	useEffect(() => {
		const fetchRacks = async () => {
			const [fetchedRacks, fetchedCategories] = await Promise.all([
				getRacks(shelf.id).unwrap(),
				getCategories(shelf.id).unwrap(),
			]);
			const racksAndCategories = [...fetchedRacks, ...fetchedCategories];
			const sortedRacksAndCats = [...racksAndCategories].sort(
				(a, b) => a.location.index - b.location.index
			);
			setRacksAndCats(sortedRacksAndCats);
		};
		fetchRacks();
	}, []);

	useEffect(() => {
		const newRacks = [...racksAndCats];
		if (selected) {
			newRacks.push(selectedRackOrCategory);
			changeIndex(racksAndCats.length);
			setRacksAndCats(newRacks);
		} else {
			setRacksAndCats(
				newRacks.filter((obj) => obj.id !== selectedRackOrCategory.id)
			);
		}
	}, [selected]);

	const deleteParamUrl = (paramName: string) => {
		if (shelf.freezer !== currentFreezer?.id) {
			deleteParam(paramName);
		}
	};

	const onSelectShelf = () => {
		if (isCurrentShelf) return;
		setSelectedShelf(shelf.id);
	};

	const onDragEnd = ({ active, over }: DragEndEvent) => {
		if (over && active.id !== over.id) {
			const activeIndex = racksAndCats.findIndex(
				({ id }) => id === active.id
			);
			const overIndex = racksAndCats.findIndex(
				({ id }) => id === over.id
			);
			changeIndex(overIndex);
			if (activeIndex > overIndex) {
				setIsLeft(true);
			} else {
				setIsLeft(false);
			}
			setRacksAndCats(arrayMove(racksAndCats, activeIndex, overIndex));
		}
	};

	return (
		<div
			className={classNames(styles.shelfRow, {
				[styles.selectedShelf]: selected,
				[styles.enablePointer]: !isCurrentShelf,
			})}
			onClick={onSelectShelf}
		>
			<div className={styles.actions}>
				<Typography bold>{shelf.name}</Typography>
				{isCurrentShelf ? (
					<Typography
						color="text-tertiary"
						style={{ fontStyle: "italic" }}
					>
						Current location
					</Typography>
				) : (
					<ButtonV2
						type="link"
						style={{ marginLeft: -4 }}
						onClick={onSelectShelf}
					>
						Select shelf
					</ButtonV2>
				)}
			</div>
			<div className={styles.racks}>
				<DndContext onDragEnd={onDragEnd}>
					<SortableContext items={racksAndCats}>
						{racksAndCats.map((rack) => {
							return (
								<RackCard
									key={rack.id}
									rack={rack}
									selected={
										selectedRackOrCategory.id === rack.id
									}
								/>
							);
						})}
					</SortableContext>
				</DndContext>
			</div>
		</div>
	);
};

type RackCardType = {
	rack: Rack | Category;
	selected?: boolean;
};

const RackCard = ({ rack, selected }: RackCardType) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: rack.id, disabled: !selected });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
			className={classNames(styles.rackCard, {
				[styles.selected]: selected,
				[styles.rack]: (rack as any).rows,
			})}
		>
			{!(rack as any).rows && (
				<GenemodIcon
					name="category"
					size="large"
					className={styles.icon}
				/>
			)}
			<div className={styles.rackNameContainer}>
				<Typography
					variant="small"
					className={styles.rackName}
					color={selected ? "text-primary" : "text-tertiary"}
					ellipsis
				>
					{rack.name}
				</Typography>
			</div>
		</div>
	);
};
