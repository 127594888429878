import { NominalId } from "@helpers/TypeHelpers";
import {
	PMDocumentDTO,
	pmDocumentFromDto,
} from "@redux/ProjectManagement/PmSliceHelpers";
import { ISOString } from "./Date";
import { PMDocument } from "./PMDocument";
import { ProtocolId } from "./ProtocolId";
import { Avatar } from "./User";

export enum ProtocolVersionStatusEnum {
	DRAFT,
	PUBLISHED,
}

export type ProtocolVersionId = NominalId<"ProtocolVersionId">;
/**
 * A single version of a protocol
 */
export type ProtocolVersion = {
	id: ProtocolVersionId;
	name: string;
	updated_at: ISOString;
	published_by?: Avatar;
	published_at: ISOString | null;
	version: number;
	documents: Omit<PMDocument, "owner" | "created_by" | "updated_by">[];
	protocol: ProtocolId;
	status: ProtocolVersionStatusEnum;
};

export type ProtocolVersionDto = Omit<ProtocolVersion, "documents"> & {
	documents: PMDocumentDTO[];
};

export const protocolVersionFromDto = (
	protocolVersion: ProtocolVersionDto
): ProtocolVersion => ({
	...protocolVersion,
	documents: protocolVersion.documents.map((doc) =>
		pmDocumentFromDto(protocolVersion.protocol, "protocol", doc)
	),
});

export const isDraftVersion = (protocolVersion: ProtocolVersion) =>
	protocolVersion.status === ProtocolVersionStatusEnum.DRAFT;
