export type Token<TokenType extends string> = {
	value: string;
	type: TokenType;
	start: number;
	end: number;
};

type Stream<TokenType extends string> = {
	/**
	 * Returns the next character in the stream without consuming it.
	 */
	peek: () => null | string;
	/**
	 * Matches the next characters in the stream against the pattern. If consume is true, the matched characters are consumed.
	 */
	match: (pattern: RegExp, consume: boolean) => null | string;
	/**
	 * Consumes the next character in the stream and returns it.
	 */
	next: () => null | string;
	/**
	 * Returns true if the end of the stream has been reached.
	 */
	isEnd: () => boolean;
	/**
	 * Returns the previous token in the stream.
	 */
	getPreviousToken: () => null | Token<TokenType>;
};

/**
 * Lexer is a simple lexer that takes a string and a tokenizer function and returns an array of tokens.
 */
export const Lexer =
	<TokenType extends string>(
		syntaxTokenizer: (stream: Stream<TokenType>) => TokenType
	) =>
	(formula: string) => {
		let position = 0;
		const tokens: Token<TokenType>[] = [];

		const isEnd = () => {
			return position >= formula.length;
		};
		const peek = () => {
			if (isEnd()) {
				return null;
			}
			return formula.substring(position, position + 1);
		};
		const match = (pattern: RegExp, consume: boolean) => {
			const restOfFormula = formula.substring(position);
			const match = restOfFormula.match(pattern);
			if (!match || match.length > 1) {
				return null;
			}
			if (consume) {
				position += match[0].length;
			}
			return match[0] || null;
		};
		const next = () => {
			position += 1;
			return peek();
		};
		const getPreviousToken = () => {
			for (let i = tokens.length - 1; i >= 0; i--) {
				if (tokens[i].type !== "whitespace") {
					return tokens[i];
				}
			}
			return null;
		};

		while (!isEnd()) {
			const startingPosition = position;
			const tokenType = syntaxTokenizer({
				peek,
				match,
				next,
				isEnd,
				getPreviousToken,
			});

			if (startingPosition === position) {
				throw "Tokenizer did not move forward";
			}

			const token = {
				value: formula.substring(startingPosition, position),
				type: tokenType,
				start: startingPosition,
				end: position,
			};

			tokens.push(token);
		}

		return tokens;
	};
