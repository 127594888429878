import React from "react";
import { Typography, Button } from "@components";
import NarrowOnboarding from "../NarrowOnboarding/NarrowOnboarding";
import styles from "./index.module.scss";
import { useWindowDimensions } from "@helpers/Hooks";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import NO_WORKSPACES_IMG from "./images/no-workspaces.png";

type AccountRestrictedScreenProps = {
	type: "NO_WORKSPACES" | "NO_ADMIN";
};

const TYPE_DATA = {
	NO_WORKSPACES: {
		title: "You no longer have access to your workspace",
		message:
			"It looks like your account does not belong to any workspace. Please contact your admin to gain access.",
		extraLink: null,
	},
	NO_ADMIN: {
		title: "You do not have access to the admin console",
		message:
			"Please contact your org admin to gain access or log into Genemod platform.",
		extraLink: { text: "Go to Genemod platform", url: "/app" },
	},
} as const;

export default function AccountRestrictedScreen({
	type,
}: AccountRestrictedScreenProps): JSX.Element {
	const history = useHistory();
	const { width } = Object(useWindowDimensions());
	const largeWidth = width >= 1920;

	const { title, message, extraLink } = TYPE_DATA[type];

	return (
		<NarrowOnboarding
			modalWidth={largeWidth ? 512 : 456}
			modalHeight={largeWidth ? 590 : 511}
			className={styles.accountDeactivateModal}
			hideLogo
		>
			<img src={NO_WORKSPACES_IMG} alt="no workspaces" />
			<Typography
				variant="title"
				bold
				className={styles.textcenter}
				style={{
					marginTop: 32,
					marginBottom: 24,
					maxWidth: 290,
				}}
			>
				{title}
			</Typography>
			<Typography className={styles.textcenter}>{message}</Typography>
			<Button
				style={{
					marginTop: 48,
				}}
				onClick={() => history.push({ pathname: "/admin/logout" })}
				stretch
			>
				Return to login
			</Button>
			{extraLink && (
				<Button
					type="link"
					onClick={() => history.push({ pathname: extraLink.url })}
					style={{ marginTop: 24 }}
				>
					{extraLink.text}
				</Button>
			)}
		</NarrowOnboarding>
	);
}
