import {
	Freezer,
	WorkspaceId,
	SharedReadDto,
	fromSharedReadDto,
	SharedWriteDto,
	Dimension,
	FreezerStorageSummary,
	FreezerRepositorySettings,
	PaginatedSearchResults,
	PaginatedSearchQuery,
} from "@common/types";
import { FreezerDataResponse } from "@containers/Freezer/components/HomePageSetting/HomePageSetting";
import { AtLeastId } from "@helpers/TypeHelpers";
import { FreezerEntities, inventoryApi, withFreezerBase } from ".";

const transformFreezerResponse = (resp: SharedReadDto<Freezer>) =>
	fromSharedReadDto(resp);

export type FreezerRepositoryFilters = Omit<
	PaginatedSearchQuery & {
		ordering?: string;
		freezer_type__in?: number[] | null;
		updated_by__in?: string[] | null;
		created_by__in?: string[] | null;
		default_item_type__in?: Array<string | number> | null;
		status__in?: number[] | null;
		include_filter_options?: null | boolean;
		updated_at__gte?: string | null;
		updated_at__lte?: string | null;
		created_at__gte?: string | null;
		created_at__lte?: string | null;
	},
	"sort_by"
>;

const freezerApi = inventoryApi.injectEndpoints({
	endpoints: (builder) => ({
		freezers: builder.query<
			Freezer[],
			{
				private?: boolean;
				workspace?: WorkspaceId;
				show_organization_freezers?: boolean;
			}
		>({
			query: (params) => ({
				url: withFreezerBase(`v2/freezers/`),
				params,
			}),
			transformResponse: (resp: SharedReadDto<Freezer>[]) =>
				resp.map(fromSharedReadDto),
			providesTags: ["Freezer"],
		}),
		freezerCreate: builder.mutation<
			Freezer,
			Partial<SharedWriteDto<Freezer>>
		>({
			query: (body) => ({
				url: withFreezerBase(`v2/freezers/`),
				method: "POST",
				body,
			}),
			transformResponse: transformFreezerResponse,
			invalidatesTags: ["Freezer"],
		}),
		freezer: builder.query<Freezer, number>({
			query: (id) => withFreezerBase(`v2/freezers/${id}/`),
			transformResponse: transformFreezerResponse,
			providesTags: ["Freezer"],
		}),
		freezerUpdate: builder.mutation<
			Freezer,
			AtLeastId<SharedWriteDto<Freezer>>
		>({
			query: (body) => ({
				url: withFreezerBase(`v2/freezers/${body.id}/`),
				method: "PATCH",
				body,
			}),
			transformResponse: transformFreezerResponse,
			// Affects the is_archived field
			invalidatesTags: ["Freezer", "Shelf", "Rack", "Box"],
		}),
		freezerDelete: builder.mutation<void, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/freezers/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["Freezer"],
		}),
		freezerDeleteShelf: builder.mutation<
			Freezer,
			{
				freezerID: number;
				row: number;
			}
		>({
			query: ({ freezerID, row }) => ({
				url: withFreezerBase(`v2/freezers/${freezerID}/row/${row}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["Freezer", "Shelf"],
		}),
		freezerInsertRow: builder.mutation<
			Freezer,
			{
				freezerID: number;
				row: number;
			}
		>({
			query: ({ freezerID, row }) => ({
				url: withFreezerBase(`v2/freezers/${freezerID}/row/${row}/`),
				method: "POST",
			}),
			invalidatesTags: ["Freezer", "Shelf"],
		}),
		freezerRecentBoxDimension: builder.query<Dimension, number>({
			query: (id) => ({
				url: withFreezerBase(
					`v2/freezers/${id}/most-recent-box-dimension/`
				),
			}),
			extraOptions: {
				maxRetries: 0,
			},
		}),
		freezerStorageSummary: builder.query<FreezerStorageSummary[], number>({
			query: (id) => ({
				url: withFreezerBase(`v2/freezers/${id}/storage-summary/`),
			}),
			extraOptions: {
				maxRetries: 0,
			},
		}),
		exportFreezerEntity: builder.query<
			string,
			{ entityName: FreezerEntities; entityId: number }
		>({
			query: ({ entityId, entityName }) => ({
				url: withFreezerBase(`v2/${entityName}/${entityId}/export/`),
				// fetchBaseQuery as default parses the response as json, so is necessary to specify in which format the response is
				responseHandler: (response: any) => response.text(),
			}),
		}),
		getFreezersStorageSummary: builder.query<FreezerDataResponse, void>({
			query: () => ({
				url: withFreezerBase("v2/freezers/storage-summary/"),
			}),
		}),
		freezerRepositorySettings: builder.query<
			FreezerRepositorySettings[],
			void
		>({
			query: () => ({
				url: withFreezerBase("v2/repository-freezer-settings/"),
			}),
			providesTags: ["FreezerSettings"],
		}),
		updateFreezerRepositorySettings: builder.mutation<
			FreezerRepositorySettings,
			Partial<FreezerRepositorySettings>
		>({
			query: (body) => ({
				url: withFreezerBase("v2/repository-freezer-settings/"),
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["FreezerSettings"],
		}),
		bulkDeleteFreezers: builder.mutation<void, { freezer_ids: number[] }>({
			query: (body) => ({
				url: withFreezerBase("v2/freezers/bulk-delete/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Freezer"],
		}),
		bulkCreateFreezers: builder.mutation<Freezer[], Freezer[]>({
			query: (body) => ({
				url: withFreezerBase("v2/freezers/bulk-create/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Freezer"],
		}),
		bulkUpdateFreezers: builder.mutation<Freezer[], Freezer[]>({
			query: (body) => ({
				url: withFreezerBase("v2/freezers/bulk-update/"),
				method: "PATCH",
				body,
			}),
			invalidatesTags: [
				"Freezer",
				"Shelf",
				"Category",
				"Rack",
				"ItemGroup",
				"Box",
				"Item",
			],
		}),
		getRepositoryFreezers: builder.query<
			PaginatedSearchResults<Freezer>,
			FreezerRepositoryFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/freezers-repository/"),
				method: "GET",
				params,
			}),
			providesTags: ["Freezer"],
		}),
	}),
});

export const {
	useFreezersQuery,
	useLazyFreezersQuery,
	useFreezerQuery,
	useFreezerCreateMutation,
	useFreezerUpdateMutation,
	useFreezerDeleteMutation,
	useFreezerDeleteShelfMutation,
	useFreezerInsertRowMutation,
	useFreezerRecentBoxDimensionQuery,
	useLazyFreezerRecentBoxDimensionQuery,
	useFreezerStorageSummaryQuery,
	useExportFreezerEntityQuery,
	useLazyExportFreezerEntityQuery,
	useGetFreezersStorageSummaryQuery,
	useLazyGetFreezersStorageSummaryQuery,
	useFreezerRepositorySettingsQuery,
	useBulkDeleteFreezersMutation,
	useBulkCreateFreezersMutation,
	useBulkUpdateFreezersMutation,
	useUpdateFreezerRepositorySettingsMutation,
	useGetRepositoryFreezersQuery,
} = freezerApi;
