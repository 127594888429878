import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	const cls3 = { fill: "#e6194b" };
	const textStyle = {
		fill: "#fff",
		fontSize: "8px",
		fontFamily: "OpenSans-Extrabold, Open Sans",
		fontWeight: 800,
		letterSpacing: "0.06em",
	};
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={48}
			height={48}
			viewBox="0 0 48 48"
			{...props}
		>
			<g id="XMLID_2_" transform="translate(-51.8 2)">
				<g id="XMLID_58_" transform="translate(62.8 6)">
					<path
						id="Path_4289"
						data-name="Path 4289"
						d="M2 0h13.568l7.551 7.48V30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
						transform="translate(1.607)"
						fill="#edc7d0"
					/>
					<path
						id="XMLID_61_"
						style={cls3}
						d="m330.7 6 7.584 7.475H330.7z"
						transform="translate(-313.554 -6)"
					/>
					<path
						id="XMLID_60_"
						style={cls3}
						transform="translate(0 18.739)"
						d="M0 0h21.056v9.99H0z"
					/>
					<path
						id="XMLID_59_"
						d="m62.8 454.9 1.606 1.357V454.9z"
						transform="translate(-62.8 -426.17)"
						fill="#a51337"
					/>
				</g>
				<text
					id="pdf"
					transform="translate(66.35 32)"
					style={textStyle}
				>
					<tspan x="0" y="0">
						pdf
					</tspan>
				</text>
			</g>
		</svg>
	);
}

const PdfIcon = React.memo(SvgComponent);
export default PdfIcon;
