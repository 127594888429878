import React from "react";
import { useEffect, useState, useCallback, useContext, useRef } from "react";
import cn from "classnames";
import {
	toCellId,
	getAreaBounds,
	AreaBounds,
	doesNewAreaOverlapWithExisting,
	areaBoundsIncludesCellId,
	parseCellId,
	getCellIdsForArea,
} from "../../../data";
import { useBoxView, useSetCellId } from "../../BoxTableHooks";
import styles from "./CellSelectionAndDragMask.module.scss";
import {
	useBoxTableActions,
	useBoxTableFields,
} from "@redux/freezer/BoxTableSlice";
import { DraggableGrid } from "@common/components/InteractiveGrid/InteractiveGrid";
import {
	Row,
	Column,
	GridCoord,
} from "@common/components/InteractiveGrid/GridTypes";
import { CellId, Box } from "@common/types";
import { SafeLeavingGuardContext } from "@common/context/SafeLeavingGuardContext";
import { actionOnTypeLiteral } from "@helpers/TypeHelpers";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { useEdgeDebounce } from "@common/helpers/Hooks";

const LEFT_BUTTON_CLICK = 0;

/**
 * Outline of selected cells
 */
export const CellSelectionAndDragMask = React.memo(function SelectionOutline() {
	const { box } = useBoxView();
	const setCellID = useSetCellId();
	const {
		cellId,
		selectedAreaBounds,
		cellIdWasUnselectedOnAreaBoundsChange,
	} = useBoxTableFields(
		"cellId",
		"selectedAreaBounds",
		"cellIdWasUnselectedOnAreaBoundsChange"
	);
	const {
		addSelectedAreaBound,
		setSelectedAreaBounds,
		clearSelectedAreaBounds,
		setHoveredCell,
		setDraggingState,
		setIsFocused,
		setCellIdWasUnselectedOnAreaBoundsChange,
	} = useBoxTableActions();

	const clickedIntoSameCellBehavior = useClickedIntoSameCellBehavior();
	const [dragOrigin, setDragOrigin] = useState<GridCoord | null>(null);
	const [dragBounds, setDragBounds] = useState<AreaBounds | null>(null);

	const isDragging = !!dragOrigin;
	const cursor = useCursorForDrag(isDragging);

	// // If the current cellId is not in the selected area bounds, then set the cellId to the corner of the last selected area
	useEffect(() => {
		if (!box || !cellIdWasUnselectedOnAreaBoundsChange) return;
		setCellID(
			toCellId(cellIdWasUnselectedOnAreaBoundsChange, box.axis_direction)
		);
		setCellIdWasUnselectedOnAreaBoundsChange(false);
	}, [cellIdWasUnselectedOnAreaBoundsChange]);

	if (!box) return <></>;

	const isUnselect =
		!!dragOrigin &&
		doesNewAreaOverlapWithExisting(selectedAreaBounds, {
			topLeft: dragOrigin,
			bottomRight: dragOrigin,
		});

	return (
		<>
			<DraggableGrid
				className={styles.selectionDragMask}
				dataCy="item-cell-grid"
				style={{
					...getFullBoxGrid(box),
					cursor,
				}}
				width={box.columns as Column}
				height={box.rows as Row}
				onDragStart={(location, { button, shiftKey, metaKey }) => {
					const isInCurrentSelection = !!selectedAreaBounds.find(
						(area) => areaBoundsIncludesCellId(area, location)
					);

					// Right click into current selection should bring up context menu
					// Skip the click/drag behavior
					if (button !== LEFT_BUTTON_CLICK && isInCurrentSelection) {
						return;
					}

					setDraggingState("NORMAL_DRAG");
					setIsFocused(true);

					if (!(metaKey || shiftKey)) {
						clearSelectedAreaBounds();
					}

					setDragOrigin(location);

					if (cellId && (metaKey || shiftKey)) {
						clearSelectedAreaBounds();
						setDragBounds(getAreaBounds([cellId, location]))
						return
					}

					setDragBounds({
						topLeft: location,
						bottomRight: location,
					});

					// We're unselecting, so don't update the cellId
					if (isInCurrentSelection && (metaKey || shiftKey)) return;

					const cell = toCellId(location);
					clickedIntoSameCellBehavior.handleMouseDown(cell);
					setCellID(cell, "SET");
				}}
				onDragEnd={(_location, ev) => {
					setDraggingState("NOT_DRAGGING");
					if (!isDragging) return;
					setDragBounds(null);
					setDragOrigin(null);
					addSelectedAreaBound({
						origin: dragOrigin,
						newBox: dragBounds as AreaBounds,
					});

					// Make sure this comes after addSelectedAreaBound
					if (dragBounds && !(ev.metaKey || ev.shiftKey))
						setSelectedAreaBounds([dragBounds]);
					clickedIntoSameCellBehavior.handleMouseUp(ev);
				}}
				onDrag={(location) => {
					if (!isDragging) return;
					setDragBounds(getAreaBounds([dragOrigin, location]));
				}}
				onMouseMove={(location) => setHoveredCell(location)}
				onMouseLeave={() => setHoveredCell(null)}
			/>
			{dragBounds && (
				<SelectionBox bounds={dragBounds} isUnselect={isUnselect} />
			)}
			{selectedAreaBounds.map((bounds, i) => (
				<SelectionBox key={i} bounds={bounds} />
			))}
		</>
	);
});

/**
 * The drag handle is the small circle that appears in the bottom right corner of a selection box.
 */
export const DragHandle = React.memo(function DragHandle() {
	const { cellId, selectedAreaBounds } = useBoxTableFields(
		"cellId",
		"selectedAreaBounds"
	);
	const { box } = useBoxView();
	const {
		setSelectedAreaBounds,
		clearSelectedAreaBounds,
		setDraggingState,
		setIsFocused,
	} = useBoxTableActions();

	const { openPasteBoxCellsFromDragModal } = useCommonModalState(
		"pasteBoxCellsFromDragModal"
	);

	const ref = useRef<HTMLDivElement>(null);
	const [dragOrigin, setDragOrigin] = useState<GridCoord | null>(null);
	const [initialSelection, setInitialSelection] = useState<AreaBounds | null>(
		null
	);
	const [dragBounds, setDragBounds] = useState<AreaBounds | null>(null);
	const isDragging = !!dragBounds;
	const cursor = useCursorForDrag(isDragging);

	if (!box || (!dragOrigin && selectedAreaBounds.length !== 1)) return <></>;

	const bounds = dragBounds || selectedAreaBounds[0];
	const preDragSelection =
		initialSelection && getCellIdsForArea(initialSelection);

	return (
		<>
			<div
				ref={ref}
				style={{
					...getFullBoxGrid(box),
					cursor,
					// This is to make sure the drag handle is on top of the SelectionOutlines components for cursor behavior
					zIndex: isDragging ? 3 : 1,
				}}
			/>
			<DraggableGrid
				externalRef={ref}
				style={{
					gridRowStart: bounds.bottomRight.row + 3,
					gridRowEnd: bounds.bottomRight.row + 4,
					gridColumnStart: bounds.bottomRight.column + 3,
					gridColumnEnd: bounds.bottomRight.column + 4,
				}}
				width={box.columns as Column}
				height={box.rows as Row}
				onDragStart={(_location) => {
					setIsFocused(true);
					setDraggingState("DRAG_HANDLE_DRAG");
					setDragOrigin(parseCellId(cellId as CellId));
					setDragBounds(bounds);
					setInitialSelection(bounds);
					clearSelectedAreaBounds();
				}}
				onDragEnd={(_location) => {
					setDraggingState("NOT_DRAGGING");
					if (
						!isDragging ||
						!dragBounds ||
						!initialSelection ||
						!preDragSelection
					)
						return;
					setSelectedAreaBounds([dragBounds]);
					setDragBounds(null);
					setDragOrigin(null);
					const draggedIntoSameSelection =
						getCellIdsForArea(initialSelection).length >
						getCellIdsForArea(dragBounds).length;

					if (!draggedIntoSameSelection) {
						openPasteBoxCellsFromDragModal({
							preDragBounds: initialSelection,
						});
					}
				}}
				onDrag={(location) => {
					if (
						!isDragging ||
						!dragOrigin ||
						!initialSelection ||
						!preDragSelection
					)
						return;
					const columns = preDragSelection
						.map(parseCellId)
						.map(({ column }) => column);
					const vertical = columns.some((x) => x === location.column);

					setDragBounds(
						getAreaBounds([
							...preDragSelection,
							{
								row: vertical ? location.row : dragOrigin.row,
								column: !vertical
									? location.column
									: dragOrigin.column,
							},
						])
					);
				}}
			>
				<div className={styles.dragHandle} />
			</DraggableGrid>
			{dragBounds && <SelectionBox bounds={dragBounds} />}
		</>
	);
});

/**
 * Prevents clicking a cell when the an item is being edited
 */
export const ShouldBlockMask = React.memo(function ShouldBlockMask() {
	const { box } = useBoxView();
	const { shouldBlock, clearAllShouldBlockKeys, showShouldBlockModal } =
		useContext(SafeLeavingGuardContext);

	if (!box || !shouldBlock) return <></>;

	return (
		<div
			className={styles.shouldBlockMask}
			style={getFullBoxGrid(box)}
			onClick={() =>
				showShouldBlockModal((option) =>
					actionOnTypeLiteral(option)({
						OK: () => {
							clearAllShouldBlockKeys();
						},
						CANCEL: () => {},
					})
				)
			}
		/>
	);
});

type SelectionBoxProps = {
	bounds: AreaBounds;
	isUnselect?: boolean;
};

const SelectionBox = React.memo(function SelectionBox({
	bounds: { topLeft, bottomRight },
	isUnselect = false,
}: SelectionBoxProps) {
	return (
		<div
			className={cn(styles.selections, {
				[styles.isUnselect]: isUnselect,
			})}
			style={{
				gridRowStart: topLeft.row + 2,
				gridRowEnd: bottomRight.row + 3,
				gridColumnStart: topLeft.column + 2,
				gridColumnEnd: bottomRight.column + 3,
			}}
		/>
	);
});

/**
 * Handles the clearing of the cell id when the user clicks into the same cell twice.
 */
const useClickedIntoSameCellBehavior = () => {
	const { cellId } = useBoxTableFields("cellId");
	const { clearSelectedAreaBounds } = useBoxTableActions();

	const setCellID = useSetCellId();
	const [clickedIntoSameCell, setClickedIntoSameCell] = useState(false);

	const handleMouseDown = useCallback(
		(newCellId: CellId) => {
			if (cellId === newCellId) {
				setClickedIntoSameCell(true);
				setTimeout(() => {
					setClickedIntoSameCell(false);
				}, 500);
			}
		},
		[cellId, setClickedIntoSameCell]
	);

	const handleMouseUp = useCallback(
		(ev: MouseEvent) => {
			if (clickedIntoSameCell && ev.button === LEFT_BUTTON_CLICK) {
				setClickedIntoSameCell(false);
				setCellID(null);
				clearSelectedAreaBounds();
			}
		},
		[clickedIntoSameCell, setClickedIntoSameCell]
	);

	return { clickedIntoSameCell, handleMouseDown, handleMouseUp };
};

const getFullBoxGrid = (box: Box) => {
	return {
		gridRowStart: 2,
		gridRowEnd: box?.rows + 2,
		gridColumnStart: 2,
		gridColumnEnd: box?.columns + 2,
	};
};

const useCursorForDrag = (isDragging: boolean) => {
	const showCrosshair = useEdgeDebounce(isDragging, "LEADING", 300);
	if (showCrosshair) {
		return "crosshair";
	}
	return "pointer";
};
