import React, { useEffect } from "react";
import { RackContents } from "@containers/Freezer/contents/RackView";
import { useRepository } from "@containers/Freezer/Repository/RepositoryContext";
import { useBoxesQuery } from "@redux/inventory/Box";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Box, Rack } from "@common/types";
import { DragAndDropGuidance, Typography } from "@common/components";
import { useRackQuery } from "@redux/inventory/Rack";

function findEmptySpaces(boxes: Box[], numRows: number, numColumns: number) {
	const occupied = new Set(); // To hold occupied cells as "row,column"

	// Mark occupied spaces by adding them to the set
	boxes.forEach((box) => {
		if (box?.location?.rack_location) {
			const { row, column } = box.location.rack_location;
			if (row < numRows && column < numColumns) {
				occupied.add(`${row},${column}`); // Use string to uniquely identify the position
			}
		}
	});

	// Collect empty spaces
	const emptySpaces = [];
	for (let row = 0; row < numRows; row++) {
		for (let column = 0; column < numColumns; column++) {
			if (!occupied.has(`${row},${column}`)) {
				// Check if the position is not in the occupied set
				emptySpaces.push({ row, column });
			}
		}
	}

	return emptySpaces;
}

function OrganizeBoxOnRack() {
	const {
		selectedItems,
		setSelectedItems,
		selectedParent: rackItem,
		selectedLayer,
	} = useRepository();
	const { data: rack } = useRackQuery(rackItem?.id || -1, {
		skip: !rackItem || selectedLayer !== "BOX",
	});
	const { data } = useBoxesQuery(
		rack
			? {
					id: rack.id,
			  }
			: skipToken
	);

	useEffect(() => {
		if (!rack?.id) return;
		const spaces = findEmptySpaces(
			data || [],
			rack?.rows ?? 0,
			rack?.columns ?? 0
		);
		const boxesWithNoLocation: Box[] = [];
		for (let i = 0; i < selectedItems.length; i++) {
			const box = selectedItems[i] as Box;
			console.log(
				box.location?.rack_location?.row,
				box.location?.rack_location?.column
			);
			if (
				!box.location?.rack_location?.row &&
				!box.location?.rack_location?.column
			) {
				boxesWithNoLocation.push({
					...box,
					location: {
						rack_location: {
							rack: rack?.id,
							row: spaces?.[i]?.["row"],
							column: spaces?.[i]?.["column"],
						},
					},
				} as Box);
			}
		}
		if (boxesWithNoLocation.length) {
			setSelectedItems(boxesWithNoLocation);
		}
	}, [rack]);

	if (!rack || !data) return null;

	return (
		<div className="flex gap-12 mt-5">
			<div className="w-full max-w-4xl">
				<RackContents
					rack={rack}
					showEditRackModal={false}
					showRearrangeBoxModal={true}
					setShowRearrangeBoxModal={() => {}}
					additionalBoxes={selectedItems as Box[]}
					onChangeLocation={(boxId, row, col) => {
						const newItems = selectedItems.map((box) =>
							box.id === boxId
								? {
										...box,
										location: {
											rack_location: {
												rack: rack?.id,
												row,
												column: col,
											},
										},
								  }
								: box
						);
						setSelectedItems(newItems as Box[]);
					}}
				/>
			</div>
			<DragAndDropGuidance
				activeLabel="Newly Restored"
				element="box"
				shape="reactangle"
			/>
		</div>
	);
}

export default OrganizeBoxOnRack;
