import { Radio, Space } from "antdv5";
import React from "react";
import { RadioChangeEvent, RadioGroupOptionType } from "antdv5/es/radio";
import { Typography } from "@components";
import styles from "@components/InputV2/InputV2.module.scss";
import "./RadioButtonV2.scss";
import { Color } from "@common/styles/Colors";

export type RadioButtonV2Props = {
	/** ID of the input field */
	id?: string;

	/** The label of the radio */
	label?: string | React.ReactNode;
	/** Input label color */
	labelColor?: Color;

	/** The name of the radio button */
	name: string;

	/** The value of the radio button */
	value: string;
	/** The label of the radio button */
	radioLabel: React.ReactNode;
	/** default value of the radio button */

	defaultValue?: string;

	/** Whether the radio button is disabled */
	disabled?: boolean;

	/** The onChange event handler */
	onChange?: (value: string) => void;

	/** The checked status of the radio button */
	checked: boolean;

	/** options for the radio button */
	options?: {
		/** The value of the radio button */
		value: string;
		/** The label of the radio button */
		label: string;
		/** Whether the radio button is disabled */
		disabled?: boolean;
		/** The name of the radio button */
		name?: string;
		/** The default status of the radio button */
		defaultChecked?: boolean;
	}[];

	/** Is Radio Group */
	isGroup?: boolean;

	/** direction of the radio button */
	direction?: "vertical" | "horizontal";

	/** Options for the radio button */
	optionType?: RadioGroupOptionType | undefined;

	/** button style */
	buttonStyle?: "solid" | "outline";

	/** The size of the radio button */
	size?: "small" | undefined | "large";
	dataCy?: string;
	className?: string;
};

/**
 * A radio button component.
 */
export default function RadioButtonV2({
	id,
	value,
	label,
	labelColor,
	radioLabel,
	defaultValue,
	disabled,
	name,
	onChange,
	checked,
	options,
	isGroup,
	direction,
	optionType,
	buttonStyle,
	size,
	className,
}: RadioButtonV2Props) {
	const handleChange = (e: any) => {
		if (onChange) {
			onChange(e.target.value);
		}
	};

	if (isGroup) {
		return (
			<div>
				{label && (
					<Typography
						variant="label"
						className={styles.label}
						color={labelColor}
						bold
					>
						{label}
					</Typography>
				)}
				<Radio.Group
					id={id}
					value={value}
					defaultValue={defaultValue}
					disabled={disabled}
					name={name}
					onChange={handleChange}
					options={options}
					optionType={optionType}
					buttonStyle={buttonStyle}
					size={size}
					rootClassName={styles.radioGroup}
				/>
			</div>
		);
	} else {
		return (
			<div>
				{label && (
					<Typography
						variant="label"
						className={styles.label}
						color={labelColor}
						bold
					>
						{label}
					</Typography>
				)}
				{options && options?.length > 1 ? (
					<Radio.Group
						className={className}
						size={size}
						id={id}
						name={name}
						onChange={handleChange}
						value={value}
						disabled={disabled}
						rootClassName={styles.radioGroup}
					>
						<Space direction={direction}>
							{options?.map((option, index) => (
								<Radio
									key={index}
									value={option.value}
									disabled={option.disabled || disabled}
								>
									{option.label}
								</Radio>
							))}
						</Space>
					</Radio.Group>
				) : (
					<Radio
						id={id}
						value={value}
						disabled={disabled}
						name={name}
						onChange={handleChange}
						checked={checked}
						className={className}
					>
						{radioLabel}
					</Radio>
				)}
			</div>
		);
	}
}
