import { useRouteMatch } from "@common/helpers/Hooks/UseRouterDom";
import { FREEZER_PATHS } from "@containers/Freezer";
import { PM_ROUTES } from "@root/routes";
import { APP_ROUTES, useOrganizationRouter } from "@root/AppRouter";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetLocation = () => {
	const { appendBaseUrl } = useOrganizationRouter();

	const mapping = {
		ACCOUNT_SETTINGS: useRouteMatch({
			path: appendBaseUrl(APP_ROUTES.ACCOUNT_SETTINGS.path),
		}),
		BOX: useRouteMatch<{ freezer_id: string; box_id: string }>({
			path: appendBaseUrl(FREEZER_PATHS.BOXES),
			exact: true,
		}),
		EXPERIMENT: useRouteMatch<{ id: string }>({
			path: appendBaseUrl(PM_ROUTES.EXPERIMENT),
			exact: true,
			strict: true,
		}),
		CONSUMABLES: useRouteMatch({
			path: appendBaseUrl(FREEZER_PATHS.CONSUMABLE),
			exact: true,
			strict: true,
		}),
		FREEZERS: useRouteMatch({
			path: appendBaseUrl(FREEZER_PATHS.HOME),
			exact: true,
			strict: true,
		}),
		INVENTORY_ARCHIVE: useRouteMatch({
			path: appendBaseUrl(FREEZER_PATHS.ARCHIVE),
			exact: true,
			strict: true,
		}),
		FREEZER: useRouteMatch<{ id: string }>({
			path: appendBaseUrl(FREEZER_PATHS.CONTENTS),
			exact: true,
			strict: true,
		}),
		ARCHIVED_PROJECTS: useRouteMatch({
			path: appendBaseUrl(PM_ROUTES.ARCHIVED_PROJECTS),
			exact: true,
			strict: true,
		}),
		ACTIVE_PROJECTS: useRouteMatch({
			path: appendBaseUrl(PM_ROUTES.HOME),
			exact: true,
			strict: true,
		}),
		PROTOCOL_LIBRARY: useRouteMatch({
			path: appendBaseUrl(PM_ROUTES.PROTOCOL_LIBRARY),
			exact: true,
			strict: true,
		}),
		PROJECT: useRouteMatch<{ id: string }>({
			path: appendBaseUrl(PM_ROUTES.PROJECT),
			exact: true,
			strict: true,
		}),
		SPACE: useRouteMatch<{ id: string }>({
			path: appendBaseUrl(FREEZER_PATHS.SPACE),
			exact: true,
			strict: true,
		}),
	};
	type LocationType = keyof typeof mapping;

	return (
		(Object.entries(mapping).find(([_, v]) => !!v)?.[0] as LocationType) ||
		"OTHER"
	);
};
