import { Color } from "@common/styles/Colors";
import classNames from "classnames";
import React, { HTMLProps, ReactNode } from "react";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import { useTheme } from "../Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "../Theme/context/ThemeTypes";
import Typography from "../Typography/Typography";
import styles from "./EmptyState.module.scss";

export type EmptyStateProps = {
	img: {
		dark: string;
		light: string;
	};
	imgStyle?: React.CSSProperties;
	title: string;
	centerTitle?: boolean;
	description?: string;
	children?: ReactNode;
	learnMore?: string | ReactNode;
	small?: boolean;
	descriptionColor?: Color;
	dataCy?: string;
} & HTMLProps<HTMLDivElement>;

const EmptyState = ({
	img,
	title,
	centerTitle,
	description,
	children,
	learnMore,
	className,
	imgStyle,
	small,
	descriptionColor = "text-secondary-v2",
	dataCy,
	...props
}: EmptyStateProps): JSX.Element => {
	const [theme] = useTheme();
	return (
		<div
			{...props}
			className={classNames(styles.main, className)}
			data-cy={dataCy}
		>
			<img
				src={theme === THEME_PREFERENCE.light ? img.light : img.dark}
				alt="Empty state"
				style={{
					marginBottom: 24,
					...imgStyle,
				}}
			/>
			<Typography
				variant={small ? "subheadline" : "title"}
				color="text-primary-v2"
				style={{
					marginBottom: 16,
					textAlign: centerTitle ? "center" : undefined,
				}}
			>
				{title}
			</Typography>
			{description && (
				<Typography
					variant={small ? "label" : "body"}
					color={descriptionColor}
					style={
						children
							? { marginBottom: 24, textAlign: "center" }
							: { textAlign: "center" }
					}
				>
					{description}
				</Typography>
			)}
			{children}
			{learnMore && (
				<span style={{ marginTop: children ? 16 : 32 }}>
					{typeof learnMore === "string" ? (
						<Link
							to={{
								pathname: learnMore,
							}}
							target="_blank"
						>
							Learn more
						</Link>
					) : (
						learnMore
					)}
				</span>
			)}
		</div>
	);
};

export default EmptyState;
