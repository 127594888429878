import { IdsInConstType } from "@helpers/TypeHelpers";
import { ITEM_TYPES } from "./Item";
import { Avatar } from "./User";
import { ItemGroup } from ".";

export type Contributor = {
	/** Number of contributions */
	count: number;
	/** User who is the contribute */
	user: Avatar;
};

export const ITEM_GROUP_CONTAINERS = Object.freeze({
	DEFAULT: 1,
	BOTTLE: 0,
	CONTAINER: 1,
	JUG: 2,
	FLASK: 3,
	TUBE_RACK: 4,
	VIAL_RACK: 5,
	0: "Bottle",
	1: "Container",
	2: "Jug",
	3: "Flask",
	4: "Tube Rack",
	5: "Vial Rack",
} as const);

export const AXIS_DIRECTION = {
	NUMBER_ROWS_LETTER_COLUMNS: 0,
	LETTER_ROWS_NUMBER_COLUMNS: 1,
} as const;

export type AxisDirection =
	(typeof AXIS_DIRECTION)[keyof typeof AXIS_DIRECTION];

export type Dimension = {
	row: number;
	col: number;
};

type BoxPath = {
	freezer: {
		id: number;
		name: string;
	};
	shelf: {
		id: number;
		name: string;
	};
	rack: {
		id: number;
		name: string;
		is_category: boolean;
	};
};

export type RackChildLocation = {
	id: number;
	rack: number;
	row: number;
	column: number;
};

export type ItemContainerLocation = {
	id: number;
	freezer: number;
	category: number | null;
	rack_location: RackChildLocation | null;
};

export type BoxLocationData = {
	freezer_name: string | null;
	freezer_is_archived: boolean;
	shelf_name: string | null;
	shelf_is_archived: boolean;
	rack_name: string | null;
	rack_is_archived: boolean;
	category_name: string | null;
	category_is_archived: boolean;
};

export type Box = {
	id: number;
	name: string;
	/** Number of rows that box have */
	rows: number;
	/** Number of the cols that box have */
	columns: number;
	/** Default type of itme under the Box */
	item_type: IdsInConstType<typeof ITEM_TYPES>;
	/** Number of items that the Box have */
	item_count: number;
	/** Date when the Box was created */
	created_at: string;
	/** Name of the user who recently updated the Box*/
	updated_by: Avatar | null;
	/** Last date When the Box is updated */
	updated_at: string;
	/** List of the contributors */
	contributors: Contributor[];
	/** True if the Freezer that this box is in is archived */
	is_archived: boolean;
	/* If the parent freezer is locked */
	is_locked: boolean; // TODO: FREEZE-1308
	/** 0 for NUMBER_ROWS_LETTER_COLUMNS, 1 for LETTER_ROWS_NUMBER_COLUMNS */
	axis_direction: AxisDirection;
	/** created_by id */
	created_by: Avatar | null;
	/** Path of the Box (used when items are printed)*/
	path: BoxPath;
	location: ItemContainerLocation;
	location_data: BoxLocationData;
	status?: number;
	freezer?: number;
};

export type UpdateBoxDataForBackend = {
	location: Partial<
		{
			rack_location: Partial<ItemContainerLocation["rack_location"]>;
		} & Omit<ItemContainerLocation, "rack_location">
	>;
} & Omit<Box, "location">;

/** Type data for sending new box data to the backend */
export type BoxDataForBackend = {
	name: string;
	location: {
		rack_location: {
			row: number;
			column: number;
			rack: number;
		} | null;
		category: number | null;
	};
	rows: number;
	columns: number;
	axis_direction: number;
	item_type: number;
};

export const CellCols = [
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M", // Current max
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
] as const;

/* tslint:disable-next-line */
export type CellId = Readonly<`${(typeof CellCols)[number]}${number}`>;

export const isItemGroup = (obj: Box | ItemGroup): obj is ItemGroup => {
	return !(obj as Box).rows; // If rows is not defined, it is an ItemGroup
};
