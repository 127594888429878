import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const withFreezerBase = (url: string) => `/freezer/api/${url}`;
export type FreezerEntities =
	| "boxes"
	| "freezers"
	| "racks"
	| "shelves"
	| "item-groups";

const TAG_TYPES = [
	"ConsumableItem",
	"FreezerItem",
	"FreezerAlert",
	"ConsumableAlert",
	"Item",
	"ItemAttachment",
	"ItemType",
	"ItemTypes",
	"Box",
	"ItemGroup",
	"Freezer",
	"Shelf",
	"Rack",
	"Racks",
	"Category",
	"FurnitureCategory",
	"Bookmark",
	"SearchSettings",
	"RepositorySearchSettings",
	"ItemOrder",
	"Consumable search columns",
	"ItemOrderSettings",
	"ItemOrderStatusHistory",
	"ItemOrderAttachment",
	"ItemOrderComment",
	"FreezerSettings",
] as const;

export const inventoryApi = createApi({
	reducerPath: "inventoryApi",
	baseQuery: genemodBaseQuery({ baseUrl: "" }),
	endpoints: () => ({}),
	tagTypes: TAG_TYPES,
});
