// Objects and constant
import { fromLazy, Lazy } from "@helpers/TypeHelpers";
import { range } from "@helpers/Arrays";
import {
	AxisDirection,
	AXIS_DIRECTION,
	Box,
	CustomItemTypeField,
	CustomItemTypeSchema,
	DEFAULT_ITEM_TYPE,
	Item,
	CellId,
	CellCols,
} from "@common/types";
import { useLocalStorage } from "@helpers/Hooks/UseLocalStorageHook";

import { useBoxView, useItemGroup } from "./table/BoxTableHooks";

import { Row, Column } from "../../common/components/InteractiveGrid/GridTypes";
import { GridCoord } from "@common/components/InteractiveGrid/GridTypes";
import { usePreferences } from "@helpers/Hooks/UsePreferencesHook";
import { useItemTypesQuery } from "@redux/inventory/Item";

export const FREEZER_NAME_CHAR_LIMITS = Object.freeze({
	FREEZER: 200,
	SHELF: 200,
	RACK: 200,
	BOX: 200,
	ITEM: 200,
} as const);

// Table classes
export const BOX_GRID_ID = "box-grid";
export const CONTEXT_MENU_ID = "freezer_table_contextmenu";
export const DRAG_TABLE_CELL_CLASS = "drag_table_cell";

export enum FREEZER_SORT {
	"Recently created" = 0,
	"Freezer name",
	"Freezer type",
}

export enum CONSUMABLE_SORT {
	"Recently created" = 0,
	"Space name",
}

export enum ARCHIVED_CONSUMABLE_SORT {
	"Recently archived" = 0,
	"Space name",
}

export enum ARCHIVED_FREEZER_SORT {
	"Recently archived" = 0,
	"Freezer name",
	"Freezer type",
}

export enum BOX_GROUP_SORT {
	"Newest" = 0,
	"Oldest",
	"Name (a - z)",
	"Count (ascend)",
	"Count (descend)",
}

export enum ITEM_GROUP_LAYOUT {
	GRID = 0,
	LIST,
}

export enum ITEM_GROUP_SORT {
	"Newest" = 0,
	"Oldest",
	"Name (a - z)",
	"Count (ascend)",
	"Count (descend)",
}

// MAX DIMENSION FOR BOX
export const MAX_ROWS_BOX = 15;
export const MAX_COLS_BOX = 15;

// MAX DIMENSION FOR RACK
export const MAX_ROWS_RACK = 15;
export const MAX_COLS_RACK = 8;

// MAX SHELVES
export const MAX_SHELVES = 6;

// MIN DIMENSION
export const MIN_COLS = 1;
export const MIN_ROWS = 1;
export const MIN_SHELVES = 1;

export const getCellId = (
	item: Item,
	axis: AxisDirection = AXIS_DIRECTION.NUMBER_ROWS_LETTER_COLUMNS
): CellId => {
	return getReagentLocation(
		item.location?.box_location?.column || 0,
		item.location?.box_location?.row || 0,
		axis
	);
};

export const getGridCoord = (item: Item): GridCoord => ({
	column: (item.location?.box_location?.column || 0) as Column,
	row: (item.location?.box_location?.row || 0) as Row,
});

export const getReagentLocation = (
	col: number,
	row: number,
	/**
	 * Only apply axis if youre getting a CellId to display to the user. Do not use in internal logic.
	 */
	axis: AxisDirection = AXIS_DIRECTION.NUMBER_ROWS_LETTER_COLUMNS
): CellId => {
	if (axis === AXIS_DIRECTION.LETTER_ROWS_NUMBER_COLUMNS) {
		const tmp = col;
		col = row;
		row = tmp;
	}
	const returnedRowNumber = row + 1;
	return (CellCols[col] + returnedRowNumber) as CellId;
};

type AreaDimension = {
	rows: number;
	cols: number;
	source: CellId;
};

export type AreaBounds = { topLeft: GridCoord; bottomRight: GridCoord };

// write a function that checks if the payload AreaBounds overlaps with any of the existing AreaBounds

/**
 * Returns true if the new AreaBounds overlaps with any of the existing AreaBounds.
 */
export const doesNewAreaOverlapWithExisting = (
	existing: AreaBounds[],
	newArea: AreaBounds
): boolean => {
	for (const bounds of existing) {
		if (
			newArea.topLeft.row <= bounds.bottomRight.row &&
			newArea.bottomRight.row >= bounds.topLeft.row &&
			newArea.topLeft.column <= bounds.bottomRight.column &&
			newArea.bottomRight.column >= bounds.topLeft.column
		) {
			return true;
		}
	}
	return false;
};

/**
 * Returns a list of new AreaBounds that are created by breaking the existing AreaBounds into smaller AreaBounds when overlapping box is excluded.
 */
export const breakExistingBoxIntoNewBoxes = (
	existingBox: AreaBounds,
	overlappingBox: AreaBounds
): AreaBounds[] => {
	const newBoxes: AreaBounds[] = [];
	// Top box
	let topBox = null as AreaBounds | null;
	if (overlappingBox.topLeft.row > existingBox.topLeft.row) {
		topBox = {
			topLeft: existingBox.topLeft,
			bottomRight: {
				row: (overlappingBox.topLeft.row - 1) as Row,
				column: existingBox.bottomRight.column,
			},
		};
		newBoxes.push(topBox);
	}

	// Bottom box
	let bottomBox = null as AreaBounds | null;
	if (overlappingBox.bottomRight.row < existingBox.bottomRight.row) {
		bottomBox = {
			topLeft: {
				row: (overlappingBox.bottomRight.row + 1) as Row,
				column: existingBox.topLeft.column,
			},
			bottomRight: existingBox.bottomRight,
		};
		newBoxes.push(bottomBox);
	}

	// Left box
	if (overlappingBox.topLeft.column > existingBox.topLeft.column) {
		newBoxes.push({
			topLeft: {
				row: (topBox
					? topBox.bottomRight.row + 1
					: existingBox.topLeft.row) as Row,
				column: existingBox.topLeft.column,
			},
			bottomRight: {
				row: (bottomBox
					? bottomBox.topLeft.row - 1
					: existingBox.bottomRight.row) as Row,
				column: (overlappingBox.topLeft.column - 1) as Column,
			},
		});
	}

	// Right box
	if (overlappingBox.bottomRight.column < existingBox.bottomRight.column) {
		newBoxes.push({
			topLeft: {
				row: (topBox
					? topBox.bottomRight.row + 1
					: existingBox.topLeft.row) as Row,
				column: (overlappingBox.bottomRight.column + 1) as Column,
			},
			bottomRight: {
				row: (bottomBox
					? bottomBox.topLeft.row - 1
					: existingBox.bottomRight.row) as Row,
				column: existingBox.bottomRight.column,
			},
		});
	}
	return newBoxes;
};

/**
 * Returns a CellId for the top left of the given area and the bottom right.
 */
export const getAreaBounds = (cells: (CellId | GridCoord)[]): AreaBounds => {
	const dims = cells.map(normalizeCellId);
	const rows = dims.map((dim) => dim.row);
	const cols = dims.map((dim) => dim.column);
	const maxRow = Math.max(...rows);
	const minRow = Math.min(...rows);
	const maxCol = Math.max(...cols);
	const minCol = Math.min(...cols);
	return {
		topLeft: { column: minCol, row: minRow } as GridCoord,
		bottomRight: { column: maxCol, row: maxRow } as GridCoord,
	};
};

const normalizeCellId = (cell: CellId | GridCoord): GridCoord =>
	isCellId(cell) ? parseCellId(cell) : cell;

/**
 * Returns true if the given cell is a CellId
 */
const isCellId = (cell: CellId | GridCoord): cell is CellId => {
	return typeof cell === "string";
};

// Returns the dimensions (rows and cols) for an array of cells
export const getAreaDimensions = (cells: CellId[]): AreaDimension => {
	const { topLeft, bottomRight } = getAreaBounds(cells);
	return {
		rows: bottomRight.row - topLeft.row + 1,
		cols: bottomRight.column - topLeft.column + 1,
		source: getReagentLocation(topLeft.column, topLeft.row),
	};
};

const isAreaDimension = (
	area: AreaDimension | AreaBounds
): area is AreaDimension => (area as any).source !== undefined;

/**
 * Returns all the cell ids found inside the given area
 */
export const getCellIdsForArea = (
	area: AreaDimension | AreaBounds | null | undefined
): CellId[] => {
	if (!area) return [];
	area = isAreaDimension(area) ? areaDimensionToAreaBounds(area) : area;
	const { topLeft, bottomRight } = area;
	return range(topLeft.row, bottomRight.row).flatMap((r) =>
		range(topLeft.column, bottomRight.column).map((c) =>
			getReagentLocation(c, r)
		)
	);
};

/**
 * Returns true if the given cellId is inside the given area
 */
export const areaBoundsIncludesCellId = (
	area: AreaBounds | null | undefined,
	cellId: GridCoord | CellId
): boolean => {
	if (!area) return false;

	const { topLeft, bottomRight } = area;
	const { column, row } = normalizeCellId(cellId);
	return (
		column >= topLeft.column &&
		column <= bottomRight.column &&
		row >= topLeft.row &&
		row <= bottomRight.row
	);
};

/**
 * Takes in a list of CellIds, and finds the area that bound all of the cells, and returns that area as a list of new CellIds
 */
export const getAreaCellIdsFromSelection = (cells: CellId[]): CellId[] =>
	getCellIdsForArea(getAreaDimensions(cells));

/**
 * CellId to CellCoord
 */
export const parseCellId = (cellId: CellId): GridCoord => {
	return {
		column: cellId.charCodeAt(0) - 65,
		row: parseInt(cellId.substring(1, cellId.length)) - 1,
	} as GridCoord;
};

/**
 * Convert CellCoord to CellId
 */
export const toCellId = (
	lazyCoord: Lazy<GridCoord>,
	axis?: AxisDirection
): CellId => {
	const coord = fromLazy(lazyCoord);
	return getReagentLocation(coord.column, coord.row, axis);
};

const areaDimensionToAreaBounds = (area: AreaDimension): AreaBounds => {
	const topLeft = parseCellId(area.source);
	return {
		topLeft,
		bottomRight: {
			row: (topLeft.row + area.rows - 1) as Row,
			column: (topLeft.column + area.cols - 1) as Column,
		},
	};
};

/**
 * Bounds the given input "dim" between 0 and max
 */
const bound = (dim: number, max: number) => Math.min(Math.max(dim, 0), max);
/**
 * "Wraps" the input "dim" to keep it between 0 and max
 */
const wrap = (dim: number, max: number) => (dim < 0 ? max : dim % (max + 1));
/**
 * Returns 1 if input "dim" is above max (and wraps back to 0), -1 if is less than 0, otherwise 0
 */
const wrapDirection = (dim: number, max: number) =>
	dim > max ? 1 : dim < 0 ? -1 : 0;

/**
 * Takes in CellCoord and bounds it based on the provided "max" CellCoord (uses 0 for the min)
 */
export const boundCellCoord =
	(max: GridCoord) =>
	(coord: GridCoord): GridCoord =>
		({
			row: bound(coord.row, max.row),
			column: bound(coord.column, max.column),
		} as GridCoord);

/**
 * Wraps the given "coord" from one row to the next after it is moved past the min/max of the table columns.
 * This is for the "tab" button.
 */
export const wrapCellCoord =
	(max: GridCoord) =>
	(coord: GridCoord): GridCoord =>
		({
			row: wrap(
				coord.row + wrapDirection(coord.column, max.column),
				max.row
			),
			column: wrap(coord.column, max.column),
		} as GridCoord);

/**
 * Gets the max bounds in CellCoord form for a box
 */
export const getMaxBounds = (box: {
	rows: number;
	columns: number;
}): GridCoord =>
	({
		row: box.rows - 1,
		column: box.columns - 1,
	} as GridCoord);

export const sortByCellId = (cellIds: CellId[], vertical = false) => {
	type RowCol = { row: number; column: number };
	const sortF = vertical
		? (a: RowCol, b: RowCol) => a.row - b.row || a.column - b.column
		: (a: RowCol, b: RowCol) => a.column - b.column || a.row - b.row;

	return cellIds
		.map((cellId) => ({
			cellId,
			parsed: parseCellId(cellId),
		}))
		.sort((a, b) => sortF(a.parsed, b.parsed))
		.map((data) => data.cellId);
};

/**
 * Get the min row/column for a range of cell as a CellId
 */
const getAbsoluteStart = (cells: CellId[]): CellId => {
	const coords = cells.map(parseCellId);
	const row = Math.min(...coords.map((coord) => coord.row));
	const column = Math.min(...coords.map((coord) => coord.column));
	return toCellId({ row, column } as GridCoord);
};

export const getColumnLabel = (
	index: number,
	axis: AxisDirection = AXIS_DIRECTION.NUMBER_ROWS_LETTER_COLUMNS
): string => {
	if (axis === AXIS_DIRECTION.LETTER_ROWS_NUMBER_COLUMNS) {
		return index + "";
	}
	return String.fromCharCode(index + 64);
};
export const getRowLabel = (
	index: number,
	axis: AxisDirection = AXIS_DIRECTION.NUMBER_ROWS_LETTER_COLUMNS
): string => {
	if (axis === AXIS_DIRECTION.LETTER_ROWS_NUMBER_COLUMNS) {
		return String.fromCharCode(index + 64);
	}
	return index + "";
};

export const DefaultFields: {
	[field: string]: string;
} = {
	volume: "Vol / Mass",
	concentration: "Concentration",
	location: "Location",
	added_by: "Created by",
	added_on: "Created on",
	expiration_date: "Expires on",
	notes: "Notes",
};

export type PanelSearchSettings = {
	name: string;
	item_id: number;
	fields: { [field_uuid: string]: string };
	visibles: { [field_uuid: string]: boolean };
	default_order: string;
	column_order: string;
};

export const getPanelSearchSettings = (
	item_type: CustomItemTypeSchema,
	search_setting: any | null = null,
	defaultFields: { [field_uuid: string]: string } = DefaultFields
): PanelSearchSettings => {
	const settings = {
		fields: {},
		default_order: "",
		visibles: {},
	} as PanelSearchSettings;
	settings.name = item_type.name;
	settings.item_id = item_type.id;
	// Add default item fields
	Object.keys(defaultFields).forEach((field, index) => {
		settings.fields[field] = defaultFields[field];
		settings.default_order += `${index === 0 ? "" : ","}${field}`;
		if (!search_setting) {
			settings.visibles[field] = true;
		} else if (typeof search_setting[field] === "boolean") {
			// If it's a boolean, use it directly.
			// The function is being called from search settings
			settings.visibles[field] = search_setting[field];
		} else if (
			typeof search_setting[field] === "object" &&
			search_setting[field] !== null
		) {
			// If it's an object and not null, use the 'visible' property
			// The function is being called from repository settings
			settings.visibles[field] = search_setting[field].visible;
		}
	});
	// Add extra item fields (schema)
	item_type?.schema?.forEach?.((field: CustomItemTypeField) => {
		settings.fields[field.uuid] = field.label;
		settings.default_order += `,${field.uuid}`;
		// Check if search_setting exists and the specific field is a boolean
		if (search_setting && typeof search_setting[field.uuid] === "boolean") {
			settings.visibles[field.uuid] = search_setting[field.uuid];
		} else if (
			search_setting &&
			typeof search_setting[field.uuid] === "object" &&
			search_setting[field.uuid] !== null
		) {
			// If it's an object and not null, use the 'visible' property
			settings.visibles[field.uuid] = search_setting[field.uuid].visible;
		} else {
			// Default to true if search_setting is not provided or the field is neither a boolean nor a valid object
			settings.visibles[field.uuid] = true;
		}
	});
	settings.column_order = search_setting
		? search_setting.column_order
		: settings.default_order;

	// if "expiration_data" is inside visible fields, but not inside column_order, add it to column_order
	// NOTE: this is after adding "expiration_date" as the last field into the backend
	if (
		settings.visibles.expiration_date &&
		!settings.column_order.includes("expiration_date")
	) {
		settings.column_order += ",expiration_date";
	}
	return settings;
};

// Genus-species map
export const GENUS_SPECIES_MAP = Object.freeze({
	Acinetobacter: ["baumanii", "baylyi", "calcoaceticus"],
	Aeromonas: ["hydrophila"],
	Agrobacterium: ["tumefaciens", "vitis"],
	Bacillus: ["cereus", "subtilis"],
	Bacteroides: [
		"caccae",
		"eggerthii",
		"eniformis",
		"fragilis",
		"thetaiotaomicron",
		"vulgatus",
	],
	Burkholderia: [
		"ambifaria",
		"cenocepacia",
		"phytofirmans",
		"thailandensis",
		"vietnamiensis",
		"xenovorans",
	],
	Campylobacter: ["jejuni"],
	Candida: ["albicans"],
	Cronobacter: ["turicensis"],
	Dictyostelium: ["discoideum"],
	Edwardsiella: ["tarda"],
	Enterobacter: ["cloacae"],
	Enterococcus: ["faecalis"],
	Escherichia: ["coli"],
	Flavobacterium: ["johnsoniae"],
	Francisella: ["novicida", "tularensis"],
	Klebsiella: ["aerogenes"],
	Legionella: ["pneumophila"],
	Listeria: ["monocytogenes"],
	Micrococcus: ["luteus"],
	Mycobacterium: ["abscessus", "smegmatis"],
	Paracoccus: ["denitrificans"],
	Propionibacterium: ["acnes"],
	Proteus: ["mirabilis"],
	Pseudomonas: [
		"aeruginosa",
		"chlororaphis",
		"fluorescens",
		"protegens",
		"putida",
	],
	Ralstonia: ["pickettii"],
	Saccharomyces: ["cerevisiae"],
	Salmonella: ["enterica ser. arizonae", "entericus ser. typhimurium"],
	Serratia: ["proteamaculans"],
	Shewanella: ["frigidimarina"],
	Staphylococcus: ["aureus", "epidermidis"],
	Stenotrophomonas: ["maltophilia"],
	Streptococcus: ["anginosus", "constellatus", "intermedius", "pneumoniae"],
	Vibrio: ["cholerae", "neptunius", "vulnificus"],
	Xanthomonas: ["maltophilia"],
	Xenorhabdus: ["nematophilus"],
	Yersinia: ["pestis", "pestis -icr"],
});

export const ANTIBIOTICS = Object.freeze([
	"Ampicillin",
	"Carbenicillin",
	"Cefoxitin",
	"Chloramphenicol",
	"Cycloheximide",
	"Erythromycin",
	"Gentamicin",
	"Hygromycin",
	"Irgasan",
	"Kanamycin",
	"Nalidixic Acid",
	"Nourseothricin",
	"Rifampicin",
	"Spectinomycin",
	"Streptomycin",
	"Tetracycline",
	"Trimethoprim",
	"Zeocin",
]);

export type LastSelectedItemType = Record<
	number,
	{ objId: number; item_type: number }
>;

/**
 * Find valid default item type based on local storage and box defaults
 */
export const useDefaultItemType = () => {
	const { data: itemTypesData } = useItemTypesQuery();
	const { box } = useBoxView();
	const { item_group } = useItemGroup();

	// const boxId = box?.id || 0;
	const objId = box?.id || item_group?.id || 0;

	const [lastSelectedItemTypes, setLastSelectedItemTypes] =
		usePreferences("SELECTED_ITEM_TYPE");

	const localStorageDefault = lastSelectedItemTypes[objId]?.item_type;
	const boxDefault = box
		? box.item_type
		: item_group
		? item_group.item_type
		: null;

	const validTypes = itemTypesData
		?.filter((it) => !it.is_locked)
		.map((it) => it.id);

	const defaultItemType =
		validTypes?.find((id) => id === localStorageDefault) ||
		validTypes?.find((id) => id === boxDefault) ||
		DEFAULT_ITEM_TYPE;

	const setLastSelectedItemType = (item_type: number) =>
		setLastSelectedItemTypes({
			...lastSelectedItemTypes,
			[objId]: { objId, item_type },
		});

	return { defaultItemType, setLastSelectedItemType };
};
