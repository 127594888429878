import GenemodIcon from "@common/components/GenemodIcon/GenemodIcon";
import InputV3 from "@common/components/InputV3/InputV3";
import {
	ResponsiveTable,
	ResponsiveTableColumns,
} from "@common/components/Table/ResponsiveTable";
import Typography from "@common/components/Typography/Typography";
import {
	CustomField,
	CustomFields as CustomFieldsType,
	UpdateCustomFields,
} from "@common/types";
import React, { useEffect, useState } from "react";
import { convertCustomFieldsToArray } from "./helper";
import styles from "./index.module.scss";

type Item = {
	key: string;
	value: CustomField;
};

type Props = {
	fields?: CustomFieldsType;
	newField?: boolean;
	onAddNewField?: (newField?: CustomFieldsType) => void;
	onUpdateField?: (
		updatedField: UpdateCustomFields,
		currentKey: string
	) => void;
	onDeleteField?: (key: string) => void;
	isArchived: boolean;
};

const CustomFields = ({
	fields,
	onAddNewField,
	onUpdateField,
	onDeleteField,
	newField,
	isArchived,
}: Props) => {
	const [newKey, setNewKey] = useState("");
	const [newValue, setNewValue] = useState("");
	const [editField, setEditField] = useState<CustomFieldsType>();
	const fieldsArray = convertCustomFieldsToArray(fields);
	const keyAlreadyExists = fieldsArray.some((i) => i.key === newKey);
	const [tableDataSource, setTableDataSource] = useState<Array<Item>>([]);

	const isEditing = !!editField;

	useEffect(() => {
		setTableDataSource(convertCustomFieldsToArray(fields));
	}, [fields]);

	useEffect(() => {
		setNewKey("");
		setNewValue("");
		if (newField) {
			setTableDataSource((prev) => [
				{
					key: "",
					value: {
						value: "",
					},
				},
				...prev,
			]);
		} else {
			setTableDataSource((prev) => prev.filter((i) => i.key !== ""));
		}
	}, [newField]);

	const columns: ResponsiveTableColumns<{
		key: string;
		value: CustomField;
	}>[] = [
		{
			key: "name",
			dataIndex: "key",
			title: "Name",
			width: "40%",
		},
		{
			key: "value",
			dataIndex: "value",
			render: (val: CustomField) => val.value,
			title: "Value",
			width: "40%",
		},
		{
			key: "options",
			width: 20,
			render: (_, record) => {
				if (
					(newField && record.key === "") ||
					Object.keys(editField || {})[0] === record.key
				) {
					if (keyAlreadyExists) return;
					return (
						<div style={{ display: "flex", gap: 8, width: 36 }}>
							<GenemodIcon
								name="swoosh-check"
								color="brand-08"
								onClick={() => {
									if (newField) {
										onAddNewField?.({
											[newKey]: { value: newValue },
										});
									}
									if (isEditing) {
										if (newKey === "" && newValue === "") {
											setEditField(undefined);
											return;
										}
										setEditField(undefined);
										onUpdateField?.(
											{
												current_key: record.key,
												new_key: newKey || record.key,
												new_value: {
													value:
														newValue ||
														record.value.value,
												},
											},
											record.key
										);
									}
								}}
							/>
							<GenemodIcon
								name="exit"
								color="text-secondary-v2"
								onClick={() => {
									setEditField(undefined);
									onAddNewField?.(undefined);
								}}
							/>
						</div>
					);
				}
				return (
					<div style={{ display: "flex", gap: 8 }}>
						<GenemodIcon
							name="edit"
							color={
								newField || isEditing
									? "text-disabled"
									: "text-secondary-v2"
							}
							onClick={() => {
								if (!newField && !isEditing) {
									setEditField({
										[record.key]: { value: record.value },
									});
								}
							}}
						/>
						<GenemodIcon
							name="trash-outlined"
							color={
								newField || isEditing
									? "text-disabled"
									: "error"
							}
							onClick={() => {
								if (!newField && !isEditing)
									onDeleteField?.(record.key);
							}}
						/>
					</div>
				);
			},
		},
	];

	const mergedColumns: ResponsiveTableColumns<Item>[] = columns.map((col) => {
		if (col.key === "options") return col;
		return {
			...col,
			onCell: (record: Item) => ({
				record,
				dataIndex: col.dataIndex,
				title: col.title,
				editing:
					(record.key === "" && newField) ||
					Object.keys(editField || {})[0] === record.key,
				onChange:
					col.key === "name"
						? (e: React.ChangeEvent<HTMLInputElement>) =>
								setNewKey(e.target.value)
						: (e: React.ChangeEvent<HTMLInputElement>) =>
								setNewValue(e.target.value),
			}),
		};
	});

	if (!tableDataSource?.length) {
		return (
			<Typography variant="regular" color="text-tertiary-v2">
				{isArchived ? "No custom fields" : "Start by adding fields"}
			</Typography>
		);
	}

	return (
		<div>
			<ResponsiveTable
				hideColumnBorders
				className={styles.table}
				components={{
					body: {
						cell: EditableCell,
					},
				}}
				dataSource={tableDataSource}
				columns={mergedColumns}
			/>
		</div>
	);
};

type EditableCellProps = {
	editing: boolean;
	dataIndex: string;
	title: any;
	record: Item;
	index: number;
	children: React.ReactNode;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const EditableCell = ({
	editing,
	dataIndex,
	title,
	record,
	index,
	children,
	onChange,
	...restProps
}: EditableCellProps) => {
	return (
		<td {...restProps}>
			{editing ? (
				<InputV3
					onChange={onChange}
					defaultValue={
						dataIndex === "key" ? record.key : record.value.value
					}
					autoFocus={editing && dataIndex === "key"}
				/>
			) : (
				children
			)}
		</td>
	);
};

export default CustomFields;
