import { Color } from "@common/styles/Colors";
import { GenemodIcon, LayerSystemContainer, Typography } from "@components";
import { AutoComplete, Input } from "antd";
import classNames from "classnames";
import React from "react";
import { LayerSystemContainerProps } from "../LayerSystemContainer/LayerSystemContainer";
import "./SearchBar.scss";

const { Option, OptGroup } = AutoComplete;

type SearchBarProps = {
	/** The search term value for the search bar */
	value?: string;
	/** The placeholder of the search bar */
	placeholder?: React.ReactNode;
	/** The stylings for the search bar wrapper */
	wrapperProps?: LayerSystemContainerProps;
	/** Function called when users search */
	onChange?: (e: any) => void;
	/** Function called when users select an option */
	onSelect?: (e: any) => void;
	/** Whether it's fetching the search results */
	isFetchingSearch?: boolean;
	/** Fetch the search result */
	fetchSearchResults?: (number: number) => void;
	/** Used to generate autocomplete results */
	searchResults?: any;
	/** Whether to hide the autocomplete suggestions */
	disableSuggestions?: boolean;
	/** Custom rendering for AutoComplete options */
	providedOptions?: React.ReactNode | React.ReactNode[];
	/** Where to position the search icon */
	iconPosition?: "right" | "left";
	/** color of search icon (default = "link-primary") */
	searchIconColor?: Color;
	/** Size of the search bar */
	size?: "normal" | "small";
	/** Autofocus on the input */
	autofocus?: boolean;
	dataCy?: string;
	iconClassName?: string;
	suffix?: React.ReactNode;
};
export default function SearchBar({
	value = "",
	placeholder = "Find items by keyword",
	wrapperProps,
	onChange,
	onSelect,
	isFetchingSearch,
	fetchSearchResults,
	searchResults,
	disableSuggestions = false,
	providedOptions,
	iconPosition = "right",
	searchIconColor = "text-tertiary-v2",
	size = "normal",
	autofocus,
	dataCy,
	iconClassName,
	suffix,
}: SearchBarProps): JSX.Element {
	const error = (
		<OptGroup
			label={
				<div
					onClick={(e) =>
						fetchSearchResults
							? fetchSearchResults(1)
							: e.preventDefault()
					}
					style={{ cursor: "pointer" }}
				>
					An error occurred. Click to try again
				</div>
			}
		></OptGroup>
	);

	// Option key must be appended with a placeholder string so that results that match
	// the query exactly are not highlighted when the placeholder option is hovered on.
	const topPlaceholder = (
		<Option key={`topPlaceholder-${value}`}>
			<div className="autocomplete-result-row">
				<GenemodIcon
					name="search"
					className="search-icon-container"
					style={{ margin: 0 }}
					color={searchIconColor}
				/>
				<Typography color={searchIconColor}>
					Search for {value}
				</Typography>
			</div>
		</Option>
	);

	/** Display this banner at the end of the suggestions if there's locked freezers */
	const lockedFreezerBanner = (
		<Option className="lockedFreezer_class" key="locked_freezer">
			<div className="lockedFreezerBanner">
				<GenemodIcon name="lock" />
				<Typography>Reagents in locked freezers are hidden</Typography>
			</div>
		</Option>
	);

	const noResults = (
		<Option disabled key="No results" data-cy={`${dataCy}-no-results`}>
			<div>No results</div>
		</Option>
	);

	// Creates the datasource for the autocomplete component
	const createChildren = () => {
		if (!value) {
			return [];
		}

		if (isFetchingSearch) {
			return [topPlaceholder];
		}

		if (!searchResults && !isFetchingSearch) {
			return [topPlaceholder, error];
		}

		if (
			searchResults?.results?.length === 0 ||
			(providedOptions as React.ReactNode[])?.length === 0
		) {
			return [topPlaceholder, noResults];
		}

		let options = [];
		const results = searchResults?.items || searchResults?.results || [];
		if (providedOptions) {
			options = providedOptions as any[];
		} else if (results.length) {
			// Only show unique names
			let groupedResults = results.reduce((acc: any, val: any) => {
				const index = acc.findIndex((x: any) => {
					return x.name === val.name;
				});
				if (index === -1) {
					return [
						...acc,
						{
							name: val.name,
							count: val.count || val.num_versions || 1,
						},
					];
				} else {
					acc[index].count += val.count || 1;
					return acc;
				}
			}, []);
			if (groupedResults[0].name === "") {
				groupedResults = [];
			}

			options = groupedResults.map((result: any, index: number) => (
				<Option
					key={result.name}
					data-cy={`${dataCy}-${result.name
						.toLowerCase()
						.replace(/\s/g, "-")}`}
				>
					<div className="autocomplete-result-row">
						<GenemodIcon name="search" className={iconClassName} />
						<Typography ellipsis hideTooltip>
							{result.name}
						</Typography>
						<Typography>
							{result.count}{" "}
							{result.count === 1 ? "result" : "results"}
						</Typography>
					</div>
				</Option>
			));
		}

		if (searchResults?.has_locked_freezers) {
			options.push(lockedFreezerBanner);
		}
		return [topPlaceholder, ...options];
	};

	// Remove placeholder from value
	const selectWrap = (val?: any) => {
		if (typeof val === "string") {
			onSelect?.(val.replace("topPlaceholder-", ""));
		} else {
			onSelect?.(val);
		}
	};

	return (
		<LayerSystemContainer
			{...wrapperProps}
			className={classNames(
				"genemod-search-container",
				{
					["genemod-search-container-iconLeft"]:
						iconPosition === "left",
					["genemod-search-container-small"]: size === "small",
				},
				wrapperProps?.className
			)}
		>
			<AutoComplete
				value={value}
				onChange={onChange}
				defaultActiveFirstOption={false}
				dropdownMatchSelectWidth={false}
				dataSource={disableSuggestions ? [] : createChildren()}
				placeholder={placeholder}
				optionLabelProp="value"
				onSelect={selectWrap}
				getPopupContainer={(trigger: any) => trigger.parentNode}
				autoFocus={autofocus}
			>
				<Input
					className="genemod-search-input"
					onPressEnter={selectWrap}
					data-cy={dataCy}
				/>
			</AutoComplete>
			{suffix ? (
				suffix
			) : (
				<GenemodIcon
					name="search"
					onClick={selectWrap}
					size="large"
					className={classNames(
						"search-icon-container",
						iconClassName
					)}
					color={searchIconColor}
					dataCy="search-button"
				/>
			)}{" "}
		</LayerSystemContainer>
	);
}
