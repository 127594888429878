import { Column, Row } from "@common/components/InteractiveGrid/GridTypes";
import {
	FREEZER_LABEL_TYPE,
	FreezerLabelType,
	Item,
	SimpleItem,
	formatCustomId,
} from "@common/types";
import { GenemodIcon, GenemodSkeleton } from "@components";
import { IdsInConstType } from "@helpers/TypeHelpers";
import { searchResultsSelector } from "@redux/freezer/BoxViewSlice";
import { appSelector } from "@redux/store";
import cn from "classnames";
import React from "react";
import { DRAG_TABLE_CELL_CLASS, getCellId, toCellId } from "../../../data";
import styles from "./BoxTableCell.module.scss";

type BoxTableCellProps = {
	row: Row;
	column: Column;
	item: SimpleItem | undefined;
	selectedItemIndex: number | "UNSELECTED";
	num_rows: number;
	labelType: FreezerLabelType | undefined;
	viewOnly: boolean;
	dataCy?: string;
	isInCutMode?: boolean;
};

const BoxTableCell: React.FunctionComponent<BoxTableCellProps> = ({
	row,
	column,
	item,
	selectedItemIndex,
	num_rows,
	labelType = FREEZER_LABEL_TYPE.DEFAULT,
	viewOnly,
	dataCy,
	isInCutMode,
}: BoxTableCellProps) => {
	const id = toCellId({ row, column });

	const isFocusedCell = appSelector(
		(state) => state.freezer.boxTable.cellId === id
	);

	const isHoveredCell = appSelector((state) => {
		if (state.freezer.boxTable.draggingState !== "NOT_DRAGGING")
			return false;
		const hoveredCell = state.freezer.boxTable.hoveredCell;
		if (!hoveredCell) return false;
		return hoveredCell.row === row && hoveredCell.column === column;
	});
	const isLoadingCell = !!appSelector((state) =>
		state.freezer.boxTable.loadingCells.find(
			(coord) => coord.row === row && coord.column === column
		)
	);

	const searchResults = appSelector((state) =>
		searchResultsSelector.selectAll(state)
	);

	const classNames = [] as string[];
	if (isFocusedCell) {
		classNames.push(styles.focusedCell);
	}
	if (isHoveredCell && !isLoadingCell) {
		classNames.push(styles.hoveredCell);
	}
	if (viewOnly) {
		classNames.push(styles.viewOnly);
	}
	if (isInCutMode) {
		classNames.push(styles.cutModeCell);
	}

	const isEmptyCell = !item;

	// Item exists in this cell
	if (isEmptyCell) {
		classNames.push(styles.emptyCell);
	} else {
		let index = -1;
		const searchResult = searchResults.filter((r, ind) => {
			if (getCellId(r) === id) {
				index = ind;
			}
			return getCellId(r) === id;
		});
		// Item is in search results
		if (searchResult.length) {
			classNames.push(styles.resultCell);
			if (index === selectedItemIndex) {
				classNames.push(styles.focusedResult);
			}
		}
	}
	if (isLoadingCell) {
		classNames.push(styles.loadingCell);
	}

	return (
		<div
			className={cn(styles.cell, DRAG_TABLE_CELL_CLASS, ...classNames)}
			key={id}
			id={id}
			style={{
				gridRowStart: row + 2,
				gridRowEnd: row + 3,
				gridColumnStart: column + 2,
				gridColumnEnd: column + 3,
			}}
			data-cy={dataCy}
		>
			{
				// Don't show the empty icon for the selected cell
				!isLoadingCell && !isFocusedCell && isEmptyCell && (
					<GenemodIcon
						className={styles.emptyCellPlusIcon}
						name="plus-v2"
						size="large"
						stroke="text-secondary-v2"
						fill="text-secondary-v2"
					/>
				)
			}
			<div
				id={id}
				className={cn(
					styles.cellContents,
					num_rows > 9 && styles.less,
					isInCutMode && styles.cutModeCellContent
				)}
			>
				{isLoadingCell ? (
					<>
						<GenemodSkeleton
							height={8}
							width="100%"
							style={{ background: "var(--border-subtle-v2)" }}
						/>
						<GenemodSkeleton
							height={8}
							width="50%"
							style={{
								background: "var(--border-subtle-v2)",
								marginTop: 8,
							}}
						/>
					</>
				) : (
					getLabel(item, labelType || FREEZER_LABEL_TYPE.DEFAULT) ||
					""
				)}
			</div>
			{!isEmptyCell && item.is_bookmarked && (
				<GenemodIcon
					className={styles.bookmarkIcon}
					name="bookmark-freezer"
					color="pink-06"
				/>
			)}
		</div>
	);
};
const BoxTableCellMemo = React.memo(BoxTableCell);
export default BoxTableCellMemo;

const getLabel = (
	item: SimpleItem | undefined,
	type?: IdsInConstType<typeof FREEZER_LABEL_TYPE>
) => {
	if (!item) return "";
	let str = "";
	switch (type) {
		case FREEZER_LABEL_TYPE.NAME:
			return item.name;
		case FREEZER_LABEL_TYPE.ID:
			return formatCustomId(item.organization_prefix, item.custom_id);
		case FREEZER_LABEL_TYPE.BOTH:
			if (item.custom_id) {
				str += `${formatCustomId(
					item.organization_prefix,
					item.custom_id
				)} `;
			}
			str += item.name;
			return str;
		default:
			return item.name;
	}
};
