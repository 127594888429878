import {
	Button,
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodIcon,
	Typography,
} from "@components";
import { useInputValidation } from "@helpers/InputHelper.js";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { DatePicker, DatePickerProps } from "antdv5";
import classnames from "classnames";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import React, {
	CSSProperties,
	ReactElement,
	ReactNode,
	useEffect,
	useState,
} from "react";
import { InputValidator } from "../Input/Input";
import InputStyles from "../Input/Input.module.scss";
import styles from "./CustomDatePickerV2.module.scss";

export type CustomDatePickerProps = {
	/** the title which will be shown above the timepicker */
	labelText?: string;
	/** A list of local functions that checks the validity of the input value */
	validators?: InputValidator[];
	/** Called when the validity on the input changes */
	onValidityChange?: (arg0: boolean) => void;
	/** extra dependencies for validators */
	extraValidityDependencies?: any;
	/** Whether to hide the error message */
	hideError?: boolean;
	/** A forced error state that displays an error message */
	error?: string | boolean;
	/** the style of input container */
	inputStyle?: CSSProperties;
	/** Whether to display the clear icon */
	allowClear?: boolean;
	forwardRef?: React.Ref<any>;
	containerRef?: React.Ref<any>;
	/** Whether to restrict the feature and show upgrade modal */
	restrict?: boolean;
	wrapperClassName?: string;
	autoFocus?: boolean;
	showHideDate?: boolean;
	dataCy?: string;
	labelStyle?: CSSProperties;
	showTime?: boolean;
} & Omit<DatePickerProps, "showTime" | "renderExtraFooter">;
/**
 * A component of customDatePicker to select or input a date.
 * @param {Object} props - any props of DatePicker
 * @param {Moment | null} [props.value] - the value of CustomDatePicker
 * @param {Function} [props.onChange] - the function is called when CustomDatePicker is changed (e, props.id) => ()
 * @param {Function} [props.disabledDate] - the function is determine disabled dates
 *
 */
export default function CustomDatePickerV2({
	labelText,
	validators,
	onValidityChange,
	extraValidityDependencies,
	hideError,
	error,
	inputStyle,
	allowClear = false,
	forwardRef,
	containerRef,
	showHideDate,
	restrict = false,
	wrapperClassName,
	autoFocus = false,
	dataCy,
	placeholder,
	labelStyle,
	showTime: _showTime = true,
	...props
}: CustomDatePickerProps): JSX.Element {
	const dateFormat = "MM/DD/YYYY";
	const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
	const [selectedDateString, setSelectedDateString] = React.useState<
		string | string[] | null
	>(null);
	const [showTime, setShowTime] = useState<boolean>(false);
	const [currentPanelMode, setCurrentPanelMode] = React.useState("date");
	const [isValid, _error] = useInputValidation(
		props.value,
		validators,
		onValidityChange,
		extraValidityDependencies
	);
	const { openUpgradeModal } = useCommonModalState("upgradeModal");

	useEffect(() => {
		const newValue = props.value ? dayjs(props.value) : null;
		setSelectedDate(newValue);
		setShowTime(shouldShowTime(newValue, _showTime));
	}, [props.value]);

	/**
	 * inputValid is a boolean that determines the valid state of an input.
	 * The validity of the input can be determined by three different
	 * props: props.hideError, props.error, and props.validators
	 */
	const inputValid = hideError || (!error && isValid);

	function onChange(
		date: Dayjs,
		dateString: string | string[],
		changeExternally = true
	) {
		if (restrict) {
			openUpgradeModal({
				type: "SEARCH_FILTER",
			});
		} else {
			setSelectedDate(date);
			setSelectedDateString(dateString);
			if (changeExternally && props.onChange) {
				props.onChange(date, dateString);
			}
		}
	}

	const renderCustomPanel = (originalPanel: ReactNode): ReactNode => {
		const left_icon = <GenemodIcon name="caret-left" />;
		const right_icon = <GenemodIcon name="caret-right" />;
		const customPanel = React.cloneElement(originalPanel as ReactElement, {
			prevIcon: left_icon,
			superPrevIcon: left_icon,
			nextIcon: right_icon,
			superNextIcon: right_icon,
		});

		return props.panelRender?.(customPanel) || customPanel;
	};

	const renderCustomFooter = () => {
		return (
			<>
				{showTime ? (
					<div>
						{showHideDate ? (
							<div className={styles.hideDateFooter}>
								<Button type="text" className={styles.buttons}>
									Hide date
								</Button>
							</div>
						) : null}
						<div className={styles.okPanel}>
							<Button
								type="text"
								className={styles.buttons}
								onClick={() => {
									setShowTime(false);
								}}
							>
								Don&apos;t include time
							</Button>
							<Button
								shape="squared"
								size="small"
								onClick={() =>
									onChange?.(
										selectedDate as Dayjs,
										selectedDateString || ""
									)
								}
							>
								Ok
							</Button>
						</div>
					</div>
				) : (
					<div className={styles.hideDateFooter}>
						<Button
							type="text"
							className={styles.buttons}
							onClick={() => {
								setShowTime(true);
							}}
						>
							Include time
						</Button>
						<Button
							shape="squared"
							size="small"
							onClick={() =>
								onChange?.(
									dayjs(
										selectedDate?.format('"MM-DD-YYYY"')
									) as Dayjs,
									selectedDateString || ""
								)
							}
						>
							Ok
						</Button>
					</div>
				)}
			</>
		);
	};

	return (
		<>
			<div
				className={classnames(
					wrapperClassName,
					styles.datePickerContainer,
					{
						[styles.datePickerContainer__filled]: selectedDate,
						[styles.datePickerContainer__error]: !inputValid,
					}
				)}
				ref={containerRef}
			>
				{labelText && (
					<Typography
						className={InputStyles.label}
						variant="label"
						style={labelStyle}
					>
						{labelText}
					</Typography>
				)}

				<DatePicker
					{...props}
					autoFocus={autoFocus}
					value={selectedDate}
					allowClear={allowClear}
					onChange={onChange}
					onCalendarChange={
						showTime
							? (date, dateString) =>
									onChange(date as Dayjs, dateString, false)
							: undefined
					}
					placeholder={placeholder || dateFormat.toLowerCase()}
					panelRender={renderCustomPanel}
					style={inputStyle}
					showNow={false}
					onOk={undefined}
					renderExtraFooter={renderCustomFooter}
					popupClassName={classnames(
						styles.dropdownClass,
						props.popupClassName,
						{
							[styles.dropdownClass__exceptDatePicker]:
								currentPanelMode !== "date",
							[styles.hideFooter]: showTime,
						}
					)}
					format={[
						"MM/DD/YYYY",
						"MM-DD-YYYY",
						"DD/MM/YYYY",
						"DD-MM-YYYY",
						"LL",
						"D-M-YYYY",
						"D/M/YYYY",
						"M/D/YYYY",
						"M-D-YYYY",
						"M-D-YY",
						"M/D/YY",
						"D/M/YY",
						"D-M-YY",
					]}
					picker="date"
					showTime={
						showTime
							? ({
									format: "hh:mm A",
									use12Hours: true,
									defaultValue: dayjs("12:00 AM", "hh:mm A"),
							  } as any)
							: false
					}
					suffixIcon={props.suffixIcon ? props.suffixIcon : null}
					getPopupContainer={
						(trigger) =>
							props.getPopupContainer?.(trigger) ||
							(trigger.parentNode as HTMLElement) /* allows calendar popup to scroll with content */
					}
					onPanelChange={(_val, mode) => setCurrentPanelMode(mode)}
					ref={forwardRef}
					data-cy={dataCy}
				/>
				{!inputValid && (
					<Typography
						className={InputStyles.inputContainerError}
						variant="smallprint"
						color="red-contrast"
					>
						{error ? (error === true ? "" : error) : _error}
					</Typography>
				)}
			</div>
		</>
	);
}

export const shouldShowTime = (date: Dayjs | null, showTime = true) => {
	if (!date) return false;
	const hasMinutes = date.minute() > 0;
	const hasSeconds = date.second() > 0;
	const hasHours = date.hour() > 0;
	return (hasHours || hasSeconds || hasMinutes) && showTime;
};

// Demo code
export function CUSTOMDATEPICKERV2_DEMO() {
	const [currentDate, setCurrentDate] = React.useState<Dayjs | null>(dayjs());
	const [validDate, setValidDate] = React.useState<Dayjs | null>(null);
	const [disableButton, toggleDisable] = React.useState<boolean | undefined>(
		undefined
	);

	const handleButtonChange = () => {
		if (currentDate) {
			setCurrentDate(dayjs((currentDate as Dayjs).add(1, "days")));
		}
	};

	const handleCurrentDateChange = (date: Dayjs | null) => {
		setCurrentDate(date);
	};

	useEffect(() => {
		if (currentDate === undefined) {
			toggleDisable(true);
		} else {
			toggleDisable(false);
		}
	}, [currentDate]);

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage"
					description="Basic Date Picker element"
				>
					<CustomDatePickerV2 />
				</Demo>
				<Demo
					title="Custom label"
					description="You can put a custom label for the date picker. The Date picker above has 'Hello' as its label."
				>
					<CustomDatePickerV2 labelText="Hello" />
				</Demo>
				<Demo
					title="Controlled value"
					description="Value of the date picker can be controlled by other components."
				>
					<button
						disabled={disableButton}
						style={{ color: "black" }}
						onClick={handleButtonChange}
					>
						Increment by 1 day
					</button>
					<CustomDatePickerV2
						value={currentDate}
						onChange={handleCurrentDateChange}
					/>
				</Demo>
				<Demo
					title="CustomDatePicker Validator"
					description="Detect errors using validation."
				>
					<CustomDatePickerV2
						labelText="Please enter any date :)"
						value={validDate}
						onChange={(date) => setValidDate(date)}
						validators={[
							{
								validator: (val: any) => val,
								error: "It cannot be empty",
							},
						]}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
