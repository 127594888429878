import React from "react";
import styles from "./HelpCenterPanel.module.scss";
import { GenemodDot, Typography, GenemodIcon } from "@common/components";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import { ColorCssVarMap } from "@common/styles/Colors";

export type HelpCenterSecondRowProps = {
	key: number;
	type: "button" | "link";
	title: string;
	icon: IconName;
	link?: string;
	showIndicator?: boolean;
	onClick?: () => void;
	dataCy?: string;
};

export function HelpCenterSecondRow({
	title,
	icon,
	type,
	link,
	showIndicator,
	onClick,
	dataCy,
}: HelpCenterSecondRowProps): JSX.Element {
	const children = (
		<div
			data-cy={dataCy && type !== "link" && dataCy}
			className={styles.secondPart}
			onClick={onClick}
		>
			<div className={styles.secondPartSec}>
				<GenemodIcon name={icon} size="large" color="text-secondary" />
				<Typography
					className={styles.secondTitle}
					variant="subheadline"
					color="text-secondary"
				>
					{title}
				</Typography>
				{showIndicator && (
					<GenemodDot
						color={ColorCssVarMap["accent-strong"]}
						wrapperProps={{
							className: styles.redDot,
						}}
						size={8}
					/>
				)}
				{type === "link" && (
					<GenemodIcon
						className={styles.iconHover}
						style={{ marginLeft: "auto" }}
						name="link-tab"
						size="large"
					/>
				)}
			</div>
		</div>
	);

	return (
		<>
			{type === "button" && <>{children}</>}
			{type === "link" && (
				<Link
					data-cy={dataCy}
					to={{
						pathname: link || "/",
					}}
					target="_blank"
				>
					{children}
				</Link>
			)}
		</>
	);
}
