import React from "react";
import { Modal, Typography } from "@components";
import "./RearrangeModal.scss";
import classNames from "classnames";

type RearrangeModalProps = {
	className?: string;
	title?: string;
	visible: boolean;
	width: number;
	onOk: () => void;
	children: any;
	dataCy?: string;
};

export default function RearrangeModal({
	className,
	title = "Rearrange",
	visible,
	width,
	children,
	onOk,
}: RearrangeModalProps): JSX.Element {
	return (
		<Modal
			className={classNames(className, "rearranged-modal")}
			visible={visible}
			width={width}
			resize={false}
			onOk={onOk}
			onCancel={onOk}
			okText="Done"
			hideCancelButton
			skipVisibleOverlayMask
		>
			<div className="rearrange-title-container">
				<Typography
					variant="headline4"
					medium
					color="text-primary-v2"
					style={{ marginBottom: "24px" }}
				>
					{title}
				</Typography>
			</div>
			{children}
		</Modal>
	);
}
