import { Merge } from "@helpers/TypeHelpers";
import {
	PMDocument,
	PMDocumentType,
	ExperimentId,
	ProtocolId,
} from "@common/types";

export type PMDocumentDTO = Merge<
	PMDocument,
	{
		content: string;
	}
>;

export const pmDocumentToDto = (
	doc: Partial<PMDocument>
): Partial<PMDocumentDTO> => {
	const { parentId, type, ...filteredDoc } = doc;
	const dto = filteredDoc as Partial<PMDocumentDTO>;
	if (doc.content) {
		dto.content = JSON.stringify(doc.content || "");
	}
	return dto;
};

export const pmDocumentFromDto = (
	parentId: ExperimentId | ProtocolId,
	type: PMDocumentType,
	dto: PMDocumentDTO
): PMDocument => ({
	...dto,
	content: dto.content && JSON.parse(dto.content),
	parentId,
	type,
});
