import { useStripeCustomerQuery } from "@redux/Account/AccountApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useCurrentOrganization from "./useCurrentOrganizationHook";

/**
 * A hook for getting the subscription data of current organization
 * @returns
 * subscription - Active subscription data of current organization
 * isLoading - Whether the subscription data is currently loading
 * isSuccess - Whether the subscription is successfully loaded
 * isError - Whether failed to fetch subscription data
 */
export default function useCurrentSubscription() {
	const { organizationId } = useCurrentOrganization();
	const { data, ...rest } = useStripeCustomerQuery(
		organizationId || skipToken
	);

	const subscription = data?.subscriptions?.data?.[0] || null;

	return {
		subscription,
		...rest,
	} as const;
}
