import { FreezerOrderId } from "@common/types";
import { BoxReagentSettingsTabs } from "@containers/Freezer/components/BoxReagentSetting/BoxReagentSetting";
import { ORDER_MODE } from "@containers/Freezer/components/OrderingWidget";
import { NominalId } from "@helpers/TypeHelpers";
import combineSlices from "@redux/helpers/CombineSlices";
import { createPanelSlice } from "@redux/helpers/ModalStateHelpers";
import { createSlice } from "@reduxjs/toolkit";

export const { reducers: commonPanelReducers, actions: commonPanelActions } =
	combineSlices([
		createPanelSlice<{
			defaultTab?: "activity" | "anlaytics" | "templates";
		}>()("inventorySettings"),
		createPanelSlice()("freezerSearchSettings"),
		createPanelSlice()("repositorySearchSettings"),
		createPanelSlice()("consumablesRepositorySearchSettings"),
		createPanelSlice<{ id: number }>()("fridgeSettings"),
		createPanelSlice<{ id: number }>()("shelfSettings"),
		createPanelSlice<{
			shelfId?: number;
			rackId: number;
			categoryId: number;
		}>()("shelfRackSettings"),
		createPanelSlice<{ id: number }>()("rackSettings"),
		createPanelSlice<{ id: number }>()("categorySettings"),
		createPanelSlice<{ id: number; defaultTab?: BoxReagentSettingsTabs }>()(
			"boxSettings"
		),
		createPanelSlice<{ id: number; defaultTab?: BoxReagentSettingsTabs }>()(
			"itemGroupSettings"
		),
		createPanelSlice()("bookmark"),
		createPanelSlice<
			| {
					mode: ORDER_MODE.VIEW;
					tab?: "active" | "complete";
			  }
			| {
					mode: ORDER_MODE.DETAILS;
					orderId: NominalId<"FreezerOrderId">;
			  }
			| {
					mode:
						| ORDER_MODE.CREATE
						| ORDER_MODE.EDIT
						| ORDER_MODE.REORDER;
			  }
		>()("orderingWidget"),
		createPanelSlice<{
			orderId: FreezerOrderId;
			defaultTab: string;
			setDefaultTab?: (tab: string) => void;
			idFieldFocused?: boolean;
			includeGoBackButton?: boolean;
		}>()("ordersInfoPanel"),
		createPanelSlice()("orderSettings"),
		createPanelSlice()("consumablesHomePageSettings"),
		createPanelSlice()("consumableSearchSettings"),
		createPanelSlice<{ id: number }>()("spaceSettings"),
		createPanelSlice<{ id: number }>()("furnitureSettings"),
		createPanelSlice<{ id: number; defaultTab?: "details" | "activity" }>()(
			"furnitureCategorySettings"
		),
		createPanelSlice()("helpCenter"),
	]);

// type PanelEntries = Extract<keyof AppState["commonPanels"], string>;
export const baseSidePanelSlice = createSlice({
	name: "baseSidePanelSlice",
	initialState: {
		openedSidePanel: "" as string,
	},
	reducers: {
		setOpenedSidePanel: (state, action) => {
			return action.payload;
		},
	},
});
