import React, { forwardRef } from "react";
import { GenemodIcon, Tooltip } from "@components";
import styles from "./index.module.scss";
import cn from "classnames";
import { GenemodIconProps } from "@common/components/GenemodIcon/GenemodIcon";
import { IconName } from "@common/components/GenemodIcon/GenemodIconRegistry";
import LayerSystemContainer from "@common/components/LayerSystemContainer/LayerSystemContainer";
import { useDebounceEventHandler } from "@helpers/Hooks";

/**
 * Divider between toolbar sections
 * @component
 */
function _ToolbarDivider() {
	return (
		<div className={styles.toolbarDividerContainer}>
			<div className={styles.toolbarDivider} />
		</div>
	);
}
export const ToolbarDivider = React.memo(_ToolbarDivider);

type ToolbarBtnProps = {
	icon: JSX.Element | IconName;
	description: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	active?: boolean;
	disabled?: boolean | null;
	iconProps?: GenemodIconProps;
	children?: React.ReactNode;
};

/**
 * Toolbar button
 */
export function ToolbarBtn(props: ToolbarBtnProps) {
	const onClick = useDebounceEventHandler(
		((ev) => {
			if (props.disabled) return;
			props.onClick?.(ev);
		}) as React.MouseEventHandler<HTMLButtonElement>,
		350
	);

	return (
		<button
			className={cn(styles.toolbarBtn, {
				[styles.toolbarBtn__active]: props.active,
				[styles.toolbarBtn__disabled]: props.disabled,
			})}
			onClick={(ev) => onClick(ev)}
			contentEditable={false}
		>
			{props.children || (
				<Tooltip title={props.description} placement="top">
					{typeof props.icon === "string" ? (
						<GenemodIcon
							name={props.icon}
							size="medium"
							disabled={!!props.disabled}
							fill={props.iconProps?.fill || "text-secondary"}
							color={props.iconProps?.fill || "text-secondary"}
							{...props.iconProps}
						/>
					) : (
						<>{props.icon}</>
					)}
				</Tooltip>
			)}
		</button>
	);
}

type ToolbarWrapperProps = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;

/**
 * Wrapper that provides toolbar styling
 */
export const ToolbarWrapper = forwardRef<HTMLDivElement, ToolbarWrapperProps>(
	(props, ref) => {
		return (
			<LayerSystemContainer
				{...props}
				wrapperRef={ref}
				className={cn(props.className, styles.toolbar)}
			>
				{props.children}
			</LayerSystemContainer>
		);
	}
);
