import classNames from "classnames";
import React from "react";
import Button from "../Button";
import Typography from "../Typography/Typography";
import styles from "./NewsPopUp.module.scss";

type Props = {
	title: string | JSX.Element;
	content: string | JSX.Element;
	img: string;
	visible: boolean;
	onButtonClick?: () => void;
};

export const NewsPopUp = ({
	title,
	content,
	img,
	visible,
	onButtonClick,
}: Props): JSX.Element => {
	return (
		<div className={classNames(styles.main, { [styles.visible]: visible })}>
			<Typography variant="caption" color="yellow">
				NEW
			</Typography>
			<Typography variant="title" bold>
				{title}
			</Typography>
			<img className={styles.img} src={img} alt="gif" />
			<div className={styles.content}>{content}</div>
			<div className={styles.actions}>
				<Button onClick={onButtonClick} shape="squared" size="small">
					Got it
				</Button>
			</div>
		</div>
	);
};
