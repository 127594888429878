import {
	Column,
	Cell,
	CellLocation,
	TableWrapper,
	TableSelection,
	CellSelectionBounds,
	CellNumericLocation,
	CellNumericBounds,
} from "./types";
import { LexFormula, FormulaToken } from "./formulaLexer";
import { PMTableSelectionManagerAPI } from "./hooks";
import { A_CHAR_CODE, CHAR_RANGE } from "./constants";
import { ReactEditor } from "slate-react";
import { CustomEditor } from "../types";

///////////////////////////////////////////
//
//
// Implementations!
//
//
//
///////////////////////////////////////////
/**
 * Returns the row and columns of a cell
 */
export function getCellDimensions(cell: Cell): CellLocation {
	const location: CellLocation = { row: 1, column: "A" };
	const chars = cell.split("");
	location.row = parseInt(chars.filter((c) => !isNaN(+c)).join(""));
	location.column = chars.filter((c) => isNaN(+c)).join("") as Column;
	return location;
}

/*
 * Returns the CellNumericLocation given a cell
 */
export function getNumericCell(cell: Cell): CellNumericLocation {
	const { row, column } = getCellDimensions(cell);
	return {
		row,
		column: columnStringToNumber(column),
	};
}

/**
 * Returns the CellNumericLocation given a CellLocation
 */
export function getCellNumericBoundsFromBounds(
	bounds: CellSelectionBounds
): CellNumericBounds {
	return {
		topRow: bounds.topRow,
		bottomRow: bounds.bottomRow,
		leftColumn: columnStringToNumber(bounds.leftColumn),
		rightColumn: columnStringToNumber(bounds.rightColumn),
	};
}

/**
 * Returns the CellLocation given a CellNumericLocation
 */
export function getCellBoundsFromNumeric(
	numericBounds: CellNumericBounds
): CellSelectionBounds {
	return {
		topRow: numericBounds.topRow,
		bottomRow: numericBounds.bottomRow,
		leftColumn: numberToColumn(numericBounds.leftColumn),
		rightColumn: numberToColumn(numericBounds.rightColumn),
	};
}

export function getTableSelectionFromBounds(bounds: CellNumericBounds) {
	return {
		anchor: cellFromLocation({
			column: numberToColumn(bounds.leftColumn),
			row: bounds.topRow,
		}),
		focus: cellFromLocation({
			column: numberToColumn(bounds.rightColumn),
			row: bounds.bottomRow,
		}),
	};
}

/**
 * Converts a column from string representation to a number.
 * Zero-indexed.
 */
export function columnStringToNumber(column: Column): number {
	return (
		column
			.split("")
			.reverse()
			.reduce<number>((value, char, index) => {
				const multiplier = Math.pow(CHAR_RANGE, index);
				const columnNumber = char.charCodeAt(0) - A_CHAR_CODE + 1; // A = 1
				return value + columnNumber * multiplier;
			}, 0) - 1
	);
}

/**
 * Converts from the numeric value of a column to the `Column` (`string`) representation
 */
export function numberToColumn(num: number): Column {
	let column = "";
	num += 1;
	while (num > 0) {
		const mod = (num - 1) % CHAR_RANGE;
		column = String.fromCharCode(A_CHAR_CODE + mod) + column;
		num = Math.floor((num - mod) / 26);
	}
	return column as Column;
}

/**
 * Converts a `CellLocation` into a `Cell`
 */
export function cellFromLocation(location: CellLocation): Cell {
	return `${location.column}${location.row}`;
}

/**
 * Returns the bounds of the cell block selection from `start` to `end`
 */
export function getSelectionBounds(
	start: Cell,
	end: Cell
): CellSelectionBounds {
	// Break down the cells into row and column components
	const startDims = getCellDimensions(start);
	const endDims = getCellDimensions(end);

	// Determine which cell is the top left cell
	const topRow = Math.min(startDims.row, endDims.row);
	const bottomRow = Math.max(startDims.row, endDims.row);
	const leftColumn = Math.min(
		columnStringToNumber(startDims.column),
		columnStringToNumber(endDims.column)
	);
	const rightColumn = Math.max(
		columnStringToNumber(startDims.column),
		columnStringToNumber(endDims.column)
	);
	return {
		topRow,
		bottomRow,
		leftColumn: numberToColumn(leftColumn),
		rightColumn: numberToColumn(rightColumn),
	};
}

export const isCellNotColumn = (
	cellOrColumn: Cell | Column
): cellOrColumn is Cell =>
	typeof cellOrColumn === "string" &&
	!!cellOrColumn.match(/^[$A-Z]+[$0-9]+$/)?.[0];

export const isStaticCell = (cell: Cell | Column): boolean =>
	cell.includes("$");

const MAX_ROW_PLACEHOLDER = 9999;

export function getRangeBounds(
	start: Cell | Column,
	end: Cell | Column
): CellSelectionBounds {
	const isStartCell = isCellNotColumn(start);
	const isEndCell = isCellNotColumn(end);
	const startAsCell = isStartCell
		? start
		: (`${start}${MAX_ROW_PLACEHOLDER}` as Cell);
	const endAsCell = isEndCell
		? end
		: (`${end}${MAX_ROW_PLACEHOLDER}` as Cell);
	return getSelectionBounds(startAsCell, endAsCell);
}

/**
 * Returns the bounds of the block formed by `cells`. Returns null if the list of cells is empty
 */
export function getBoundsFromCells(cells: Cell[]): CellSelectionBounds | null {
	const bounds = cells.reduce<CellSelectionBounds | null>((bounds, cell) => {
		const dims = getCellDimensions(cell);
		if (bounds === null) {
			return {
				topRow: dims.row,
				bottomRow: dims.row,
				leftColumn: dims.column,
				rightColumn: dims.column,
			};
		}

		if (bounds.topRow > dims.row) {
			bounds.topRow = dims.row;
		}
		if (bounds.bottomRow < dims.row) {
			bounds.bottomRow = dims.row;
		}
		if (
			columnStringToNumber(bounds.leftColumn) >
			columnStringToNumber(dims.column)
		) {
			bounds.leftColumn = dims.column;
		}
		if (
			columnStringToNumber(bounds.rightColumn) <
			columnStringToNumber(dims.column)
		) {
			bounds.rightColumn = dims.column;
		}
		return bounds;
	}, null);
	return bounds;
}

/**
 * Returns a list of cells within an area defined by `start` and `end`.
 */
export function getCellsInArea(start: Cell, end: Cell): Cell[] {
	const { topRow, bottomRow, leftColumn, rightColumn } = getSelectionBounds(
		start,
		end
	);

	// Generate the list of cells
	const cells: Cell[] = [];
	for (let row = topRow; row <= bottomRow; row++) {
		for (
			let col = columnStringToNumber(leftColumn);
			col <= columnStringToNumber(rightColumn);
			col++
		) {
			cells.push(
				cellFromLocation({
					row,
					column: numberToColumn(col),
				})
			);
		}
	}
	return cells;
}

/**
 * Adds a number to a column string, returning a new column string
 */
export const addToColumn = (column: Column, amount: number): Column => {
	const columnNumber = columnStringToNumber(column);
	return numberToColumn(columnNumber + amount);
};

/**
 * Returns `true` if a cell is within the bounds of the `selection`
 */
export function isCellWithinSelection(cell: Cell, selection: TableSelection) {
	if (!selection) return false;
	const cellDims = getCellDimensions(cell);
	const bounds = getSelectionBounds(selection.anchor, selection.focus);
	return isCellLocationWithinBounds(cellDims, bounds);
}

/**
 * Returns `true` if a cell is within the 'bounds'
 */
export function isCellLocationWithinBounds(
	cell: CellLocation,
	bounds: CellSelectionBounds
) {
	const cellColumn = columnStringToNumber(cell.column);
	return (
		bounds.topRow <= cell.row &&
		cell.row <= bounds.bottomRow &&
		columnStringToNumber(bounds.leftColumn) <= cellColumn &&
		cellColumn <= columnStringToNumber(bounds.rightColumn)
	);
}

/**
 * Shifts the `cell` in one dimension, staying within
 * the bounds of `selection`.
 */
export function shiftCellWithinSelection(
	cell: Cell,
	selection: TableSelection,
	dimension: "row" | "column",
	distance: number,
	wrap = true
): Cell {
	if (!selection) return cell;
	const bounds = getSelectionBounds(selection.anchor, selection.focus);
	const dims = getCellDimensions(cell);

	const columnOffset = columnStringToNumber(bounds.leftColumn);
	const rowOffset = bounds.topRow;

	// Convert into numeric values relative to the selection's top-left corner
	let r = dims.row - rowOffset;
	let c = columnStringToNumber(dims.column) - columnOffset;

	let width =
		columnStringToNumber(bounds.rightColumn) -
		columnStringToNumber(bounds.leftColumn) +
		1;
	let height = bounds.bottomRow - bounds.topRow + 1;
	if (wrap) {
		// Switch variables around to support column wrapping
		if (dimension === "row") {
			let temp = r;
			r = c;
			c = temp;

			temp = width;
			width = height;
			height = temp;
		}

		// Treat the table as a wrapped array
		let index = r * width + c + distance;
		const area = width * height;
		while (index < 0) {
			index += area;
		}
		index %= area;

		// Extract dimensions from index
		r = Math.floor(index / width);
		c = index % width;

		// Flip dimensions back to normal if needed
		if (dimension === "row") {
			const temp = r;
			r = c;
			c = temp;
		}

		r += rowOffset;
		c += columnOffset;
	} else {
		// Shift the dimension
		if (dimension === "row") {
			r += distance;
		} else {
			c += distance;
		}

		// Prevent indices from exiting selection bounds
		r = Math.max(Math.min(r + rowOffset, bounds.bottomRow), bounds.topRow);
		c = Math.max(
			Math.min(
				c + columnOffset,
				columnStringToNumber(bounds.rightColumn)
			),
			columnStringToNumber(bounds.leftColumn)
		);
	}

	// Add the offset of the selection back in
	const column = numberToColumn(c);
	const row = r;
	return cellFromLocation({ row, column });
}

/**
 * Shifts the `cell` in one dimension to 'direction'
 * staying within the bounds of `selection`.
 */
export function shiftCellWithDirection(
	editor: CustomEditor,
	selectionManager: PMTableSelectionManagerAPI,
	tableWrapperElement: TableWrapper,
	dir: "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown"
) {
	if (selectionManager.selection && selectionManager.tableDimensions) {
		let dimension: "row" | "column" = "row";
		let direction = 1;
		if (dir === "ArrowLeft" || dir === "ArrowRight") {
			dimension = "column";
		}
		if (dir === "ArrowLeft" || dir === "ArrowUp") {
			direction = -1;
		}
		const { tableDimensions } = selectionManager;
		const entireTableSelection: TableSelection = {
			anchor: "A1",
			focus: cellFromLocation({
				row: tableDimensions.rows,
				column: numberToColumn(tableDimensions.columns - 1),
			}),
		};

		const newCell = shiftCellWithinSelection(
			selectionManager.selection.anchor,
			entireTableSelection,
			dimension,
			1 * direction
		);

		ReactEditor.focus(editor);
		selectionManager.selectBlock(newCell, newCell);
		editor.selectTableCell(
			tableWrapperElement.tableId,
			getCellDimensions(newCell)
		);
	}
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Table Function Helpers
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/**
 * Parses a formula and returns the bounds of all referenced cells, as well as the token.
 */
export const tableFunctionTextToCellRangeBounds = (
	cellValue: string
): { bounds: CellSelectionBounds; token: FormulaToken }[] => {
	if (!cellValue.startsWith("=")) {
		return [];
	}

	return LexFormula(cellValue.toUpperCase())
		.filter(
			(token) =>
				token.type === "operand" ||
				token.type === "range-operand" ||
				token.type === "column-operand"
		)
		.map((token) => {
			if (token.value.includes(":")) {
				return token;
			}
			return { ...token, value: `${token.value}:${token.value}` };
		})
		.map((token) => {
			const [start, end] = token.value.split(":") as [
				Cell | Column,
				Cell | Column
			];
			return { bounds: getRangeBounds(start, end), token };
		});
};

/**
 * Test cases for tableFunctionTextToCellSelectionBounds
 */
// export const testTableFunctionTextToCellSelectionBounds = () => {
// 	const excelFormulaTestCases = [
// 		{
// 			formula: "=A1 + B1",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 				{ topRow: 1, bottomRow: 1, leftColumn: "B", rightColumn: "B" },
// 			],
// 		},
// 		{
// 			formula: "=SUM(A1:A5)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 5, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=AVERAGE(A1:A5)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 5, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=A1 * (B1 - C1)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 				{ topRow: 1, bottomRow: 1, leftColumn: "B", rightColumn: "B" },
// 				{ topRow: 1, bottomRow: 1, leftColumn: "C", rightColumn: "C" },
// 			],
// 		},
// 		{
// 			formula: '=IF(A1 > B1, "Greater", "Less or equal")',
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 				{ topRow: 1, bottomRow: 1, leftColumn: "B", rightColumn: "B" },
// 			],
// 		},
// 		{
// 			formula: "=VLOOKUP(A1, A2:B10, 2, FALSE)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 				{ topRow: 2, bottomRow: 10, leftColumn: "A", rightColumn: "B" },
// 			],
// 		},
// 		{
// 			formula: '=COUNTIF(A1:A5, ">20")',
// 			ranges: [
// 				{ topRow: 1, bottomRow: 5, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=MAX(A1:A5)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 5, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=MIN(B1:B5)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 5, leftColumn: "B", rightColumn: "B" },
// 			],
// 		},
// 		{
// 			formula: "=STDEV(A1:A5)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 5, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=ROUND(A1, 2)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=A1 ^ 2",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=SQRT(A1)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=ABS(-A1)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=SIN(A1)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=COS(A1)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=TAN(A1)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: "=AND(A1 > 0, B1 < 0)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 				{ topRow: 1, bottomRow: 1, leftColumn: "B", rightColumn: "B" },
// 			],
// 		},
// 		{
// 			formula: "=OR(A1 > 0, B1 < 0)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 				{ topRow: 1, bottomRow: 1, leftColumn: "B", rightColumn: "B" },
// 			],
// 		},
// 		{
// 			formula: "=NOT(A1 > 0)",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 		{
// 			formula: '=IFERROR(VLOOKUP(A1, A2:B10, 2, FALSE), "Not found")',
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 				{ topRow: 2, bottomRow: 10, leftColumn: "A", rightColumn: "B" },
// 			],
// 		},
// 		{ formula: '=LEN("Hello, world!")', ranges: [] },
// 		{ formula: '=LEFT("Hello, world!", 5)', ranges: [] },
// 		{ formula: '=RIGHT("Hello, world!", 6)', ranges: [] },
// 		{ formula: '=MID("Hello, world!", 8, 5)', ranges: [] },
// 		{ formula: '=CONCATENATE("Hello", ", ", "world!")', ranges: [] },
// 		{ formula: '=LOWER("HELLO, WORLD!")', ranges: [] },
// 		{ formula: '=UPPER("hello, world!")', ranges: [] },
// 		{ formula: '=TRIM("   Hello, world!  ")', ranges: [] },
// 		{
// 			formula: '=SUBSTITUTE("Hello, world!", "world", "everyone")',
// 			ranges: [],
// 		},
// 		{
// 			formula: "=A1 + B",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 1, leftColumn: "A", rightColumn: "A" },
// 				{
// 					topRow: 9999,
// 					bottomRow: 9999,
// 					leftColumn: "B",
// 					rightColumn: "B",
// 				},
// 			],
// 		},
// 		{
// 			formula: "=AVERAGE(A1:A4",
// 			ranges: [
// 				{ topRow: 1, bottomRow: 4, leftColumn: "A", rightColumn: "A" },
// 			],
// 		},
// 	];

// 	let fails = 0;
// 	excelFormulaTestCases.forEach(({ formula, ranges }) => {
// 		const bounds = tableFunctionTextToCellRangeBounds(formula);
// 		const result = _.isEqual(bounds, ranges);
// 		if (result) {
// 			return;
// 		}
// 		fails++;
// 		console.log(
// 			`Formula: ${formula} - Expected: ${JSON.stringify(
// 				ranges
// 			)} - Actual: ${JSON.stringify(bounds)}`
// 		);
// 	});
// 	// print fails
// 	console.log(`Fails: ${fails || "None"}`);
// };
