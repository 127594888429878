import {
	Button,
	Typography,
	ProgressBar,
	Notification,
	axios,
	API,
} from "@common/components";
import React, { HTMLProps, useState } from "react";
import styles from "./UploadAttachmentFiled.module.scss";
import { useDropzone } from "react-dropzone";
import { FreezerOrder, IMAGE_ACCEPTED_FILES } from "@common/types";
import { ColorCssVarMap } from "@common/styles/Colors";
import { truncArgs } from "@helpers/Formatters";
import { useLazyOrderAttachmentsQuery } from "@redux/freezer/FreezerApiSlice";
import { FAILED_UPLOAD_MESSAGE } from "@common/constants";

type Props = {
	order: FreezerOrder | undefined;
} & HTMLProps<HTMLDivElement>;

const UploadAttachmentField = ({ order, ...props }: Props) => {
	const generateAcceptedFiles = (fileTypes: any) => {
		return fileTypes.map((ext: any) => "." + ext);
	};
	const [getOrderAttachments] = useLazyOrderAttachmentsQuery();
	const VALID_IMG_EXTENTIONS = generateAcceptedFiles(IMAGE_ACCEPTED_FILES);
	const [fileData, setFileData] = React.useState<any | null>(null);
	const [uploadingRate, setUploadingRate] = useState(0.0);

	const uploadCompleted = async (file: any) => {
		if (!order) return;
		const config = {
			onUploadProgress: function (progressEvent: any) {
				const percentCompleted = Math.floor(
					(progressEvent.loaded / progressEvent.total) * 100
				);
				setUploadingRate(percentCompleted);
			},
		};
		const data = new FormData();
		data.append("upload", file);
		data.append("order", order.id.toString());

		// Utilize axios here so we can utilize the upload progress
		axios
			.post(API.orders.get(order.id).attachments(), data, config)
			.then(() => {
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${file.name}`(68)}</b>
							{" has been uploaded."}
						</span>
					),
				});
				getOrderAttachments(order.id);
			})
			.catch((error) => {
				let errorMessage = FAILED_UPLOAD_MESSAGE;
				if (error.response && error.response.data.upload) {
					errorMessage = error.response.data.upload[0];
				}
				Notification.warning({
					message: errorMessage,
				});
			})
			.finally(() => {
				setFileData(null);
				setUploadingRate(0.0);
			});
	};

	const onDrop = (acceptedFiles: any, fileRejections: any, event: any) => {
		//If there is not accpectedFiles or there is a fileRejection file and it is same as first uploaded file, then show error
		if (
			!acceptedFiles[0] ||
			(fileRejections[0] &&
				event.dataTransfer.files[0] === fileRejections[0].file)
		) {
			return;
		} else {
			setFileData(acceptedFiles[0]);
			uploadCompleted(acceptedFiles[0]);
		}
	};

	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		onDrop,
		accept: VALID_IMG_EXTENTIONS,
		noClick: true,
		noKeyboard: true,
		multiple: true,
	});

	return (
		<div
			{...props}
			{...getRootProps({
				backgroundColor: isDragActive
					? ColorCssVarMap["layer-hover-01"]
					: "transparent",
			})}
		>
			<input {...getInputProps()} />
			{!fileData ? (
				<div className={styles.upload}>
					<div className={styles.uploadContent}>
						<Typography>Drop a file here, or</Typography>
						<Button type="ghost" size="small" onClick={open}>
							Choose a file
						</Button>
					</div>
				</div>
			) : (
				<ProgressBar
					value={uploadingRate / 100}
					suffix={uploadingRate + "%"}
					label={fileData.name || ""}
				/>
			)}
		</div>
	);
};

export default UploadAttachmentField;
