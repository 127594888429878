import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			data-name="type_image (x24)"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				data-name="Icon material-image"
				d="M18.5 16.944V6.056A1.56 1.56 0 0 0 16.944 4.5H6.056A1.56 1.56 0 0 0 4.5 6.056v10.888A1.56 1.56 0 0 0 6.056 18.5h10.888a1.56 1.56 0 0 0 1.556-1.556zm-9.722-4.277 1.944 2.341 2.722-3.508 3.5 4.667H6.056z"
				transform="translate(.5 .5)"
				style={{ fill: "#c1c1c3" }}
			/>
		</svg>
	);
}
const MiniImageIcon = React.memo(SvgComponent);
export default MiniImageIcon;
