import React from "react";
import { ToggleButton } from "../ToggleButton/ToggleButton";

type GenemodFilter = {
	applied: boolean;
	wrapperClassName?: string;
};

export function GenemodFilter({ applied, wrapperClassName }: GenemodFilter) {
	return (
		<ToggleButton
			checked={applied}
			hasFilters={applied}
			wrapperClassName={wrapperClassName}
		/>
	);
}
