import { FieldType, FIELD_WIDTH_STYLES, UUID } from ".";

export const FIELD_TYPE_TO_LABEL = Object.freeze({
	1: "Single-line text",
	2: "Multiple-line text",
	3: "Number",
	4: "Genus",
	5: "Species",
	6: "Selection Marker",
	7: "Date Picker",
} as const);

/** ItemTypeSchema for item type previews for when we edit a schema */
export type CustomItemTypeSchema = {
	/** ID of the object */
	id: number;
	/** Name of the object */
	name: string;
	/** The user id of the schema owner/creator */
	owner: number;
	/** The organization id that this schema belongs to */
	organization: number;
	/** Array of CustomItemTypeField */
	schema: CustomItemTypeField[];
	/** When the schema got updated */
	updated_at: string;
	/** If true, it will show up for all organization */
	predefined?: boolean;
	/** The number of items in inventory tool using the schema */
	count?: number;
	/** Is the item type disabled */
	is_locked: boolean;
};

export type NewCustomItemTypeDTO = {
	name: string;
	schema: string;
};

/** Another CustomItemTypeSchema type to be sent to or recieved from the backend */
export type CustomItemTypeSchemaDTO = Omit<CustomItemTypeSchema, "schema"> & {
	/** JSON stored in text field */
	schema: string;
};

/** Item Type Field that is part of the CustomItemTypeSchema's schema field */
export type CustomItemTypeField = {
	/** UUID */
	uuid: UUID;
	/** Custom field type: single line, multiple line, number, dropdown, date... etc */
	type: FieldType;
	/** Label for the custom field */
	label: string;
	/** Order position of the custom field. Might not need sinse the field gets rearranged inside array */
	position?: number;
	/** Whether this field is active */
	active?: boolean;
	/** Configuration of the custom field */
	config?: any;
	/** Layout of the custom field */
	layout?: keyof typeof FIELD_WIDTH_STYLES;
};

/** Custom item type value that comes as part of the Item data */
export type CustomItemTypeValue = {
	item_id: number;
	schema_id: number;
	value: string;
	uuid: UUID;
};

/**
 * Convert the CustomItemTypeSchema to CustomItemTypeSchemaDTO for backend
 * @param data - the schema data in CustomItemTypeSchema type
 */
export function CustomItemTypeSchemaToDTO(data: CustomItemTypeSchema) {
	const temp = { ...data } as any;
	temp.schema = JSON.stringify(temp.schema) as string;
	return temp as CustomItemTypeSchemaDTO;
}

/**
 * Convert the CustomItemTypeSchemaDTO to CustomItemTypeSchema for frontend
 * @param data - the schema data in CustomItemTypeSchemaDTO type
 */
export function CustomItemTypeSchemaToNonDTO(data: CustomItemTypeSchemaDTO) {
	const temp = { ...data } as any;
	try {
		temp.schema = JSON.parse(temp.schema) as CustomItemTypeField[];
	} catch (e) {
		console.error(e);
		temp.schema = [];
	}
	return temp as CustomItemTypeSchema;
}
