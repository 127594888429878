export const FIELD_TYPES = Object.freeze({
	NUMBER: 0,
	DATE: 1,
	NONE: 2,
	EXPIRES_ON: 3,
	AVATAR: 4,
	// otherwise treat as STRING
} as const);

export const COLUMN_FIELDS = Object.freeze({
	volume: {
		label: "Vol / Mass",
		className: "S_COLUMN_WIDTH",
		type: FIELD_TYPES.NUMBER,
	},
	concentration: {
		label: "Concentration",
		className: "S_COLUMN_WIDTH",
		abbr: "Conc.",
		type: FIELD_TYPES.NUMBER,
	},
	location: {
		label: "Location",
		className: "L_COLUMN_WIDTH",
	},
	added_by: {
		label: "Created by",
		className: "S_COLUMN_WIDTH",
		type: FIELD_TYPES.AVATAR,
	},
	added_on: {
		label: "Created on",
		className: "S_COLUMN_WIDTH",
		type: FIELD_TYPES.DATE,
	},
	notes: {
		label: "Notes",
		className: "L_COLUMN_WIDTH",
		type: FIELD_TYPES.NONE,
	},
	expiration_date: {
		label: "Expires on",
		className: "L_COLUMN_WIDTH",
		type: FIELD_TYPES.EXPIRES_ON,
	},

	//Primer
	sequence: {
		label: "Sequence (5’ > 3’)",
		className: "L_COLUMN_WIDTH",
	},
	melting_temperature: {
		label: "Melting temp",
		className: "S_COLUMN_WIDTH",
	},
	restriction_sites: {
		label: "Restriction sites",
		className: "S_COLUMN_WIDTH",
	},
	modification: {
		label: "Modification",
		className: "L_COLUMN_WIDTH",
	},
	gene: {
		label: "Gene",
		className: "S_COLUMN_WIDTH",
	},
	size: {
		label: "Size",
		className: "S_COLUMN_WIDTH",
	},
	strand: {
		label: "Strand",
		className: "S_COLUMN_WIDTH",
	},

	//Antibody
	host: {
		label: "Host",
		className: "L_COLUMN_WIDTH",
	},
	clone: {
		label: "Clone",
		className: "S_COLUMN_WIDTH",
	},
	target_species: {
		label: "Taget species",
		className: "M_COLUMN_WIDTH",
	},
	conjugate: {
		label: "Conjugate",
		className: "S_COLUMN_WIDTH",
	},
	application_1: {
		label: "Application 1",
		className: "M_COLUMN_WIDTH",
	},
	dilution_1: {
		label: "Dilution 1",
		className: "S_COLUMN_WIDTH",
	},
	application_2: {
		label: "Application 2",
		className: "M_COLUMN_WIDTH",
	},
	dilution_2: {
		label: "Dilution 2",
		className: "S_COLUMN_WIDTH",
	},
	primary_secondary: {
		label: "Primary / Secondary",
		className: "S_COLUMN_WIDTH",
	},

	//Plasmid
	backbone: {
		label: "Backbone",
		className: "S_COLUMN_WIDTH",
	},
	prokaryotic_marker: {
		label: "Prokaryotic marker",
		className: "M_COLUMN_WIDTH",
	},
	eukaryotic_marker: {
		label: "Eukaryotic marker",
		className: "M_COLUMN_WIDTH",
	},
	species: {
		label: "Species",
		className: "M_COLUMN_WIDTH",
	},
	gene_or_insert: {
		label: "Gene or Insert",
		className: "M_COLUMN_WIDTH",
	},
	promoter: {
		label: "Promoter",
		className: "S_COLUMN_WIDTH",
	},
	tags: {
		label: "Tags",
		className: "S_COLUMN_WIDTH",
	},
	a_260_280: {
		label: "A 260/280",
		className: "S_COLUMN_WIDTH",
	},
	sequence3_primer: {
		label: "3' Sequence primer",
		className: "L_COLUMN_WIDTH",
	},
	sequence5_primer: {
		label: "5' Sequence primer",
		className: "L_COLUMN_WIDTH",
	},

	//Enzyme
	recognition_sequence: {
		label: "Recognition sequence",
		className: "S_COLUMN_WIDTH",
		abbr: "Recognition seq.",
	},
	buffer: {
		label: "Buffer",
		className: "M_COLUMN_WIDTH",
	},
	incubation_temperature: {
		label: "Incubation temp",
		className: "S_COLUMN_WIDTH",
	},
	heat_inactivation: {
		label: "Heat inactivation",
		className: "S_COLUMN_WIDTH",
	},
	activity: {
		label: "Activity",
		className: "L_COLUMN_WIDTH",
	},
	methylation_sensitivity: {
		label: "Mehylation sensitivity",
		className: "L_COLUMN_WIDTH",
	},

	//Cell Line
	sample_type: {
		label: "Sample type",
		className: "L_COLUMN_WIDTH",
	},
	passage_num: {
		label: "Passage #",
		className: "XS_COLUMN_WIDTH",
	},
	patient_id: {
		label: "Patient ID",
		className: "S_COLUMN_WIDTH",
	},
	study: {
		label: "Study",
		className: "L_COLUMN_WIDTH",
	},
	selection_marker: {
		label: "Selection marker",
		className: "S_COLUMN_WIDTH",
	},

	//Chemical
	molecular_weight: {
		label: "Molecular weight",
		className: "S_COLUMN_WIDTH",
	},
	melting_point: {
		label: "Melting / Boiling point",
		className: "M_COLUMN_WIDTH",
	},
	application: {
		label: "Application",
		className: "M_COLUMN_WIDTH",
	},
	solubility: {
		label: "Solubility",
		className: "M_COLUMN_WIDTH",
	},
	synonym: {
		label: "Synonym",
		className: "S_COLUMN_WIDTH",
	},
	formula: {
		label: "Formula",
		className: "S_COLUMN_WIDTH",
	},
	cas: {
		label: "CAS #",
		className: "S_COLUMN_WIDTH",
	},
	mdl: {
		label: "MDL #",
		className: "S_COLUMN_WIDTH",
	},
	form: {
		label: "Form",
		className: "S_COLUMN_WIDTH",
	},
	grade: {
		label: "Grade",
		className: "M_COLUMN_WIDTH",
	},

	//Chemical probe
	solvent: {
		label: "Solvent",
		className: "S_COLUMN_WIDTH",
	},
	aliquot: {
		label: "Aliquot",
		className: "S_COLUMN_WIDTH",
	},
	stock_solution: {
		label: "Stock solution",
		className: "S_COLUMN_WIDTH",
	},
	modification_label: {
		label: "Modification / Label",
		className: "M_COLUMN_WIDTH",
	},
	//Strain
	genus: {
		label: "Genus",
		className: "S_COLUMN_WIDTH",
	},
	genotype_and_strain_background: {
		label: "Genotype / Strain_background",
		className: "L_COLUMN_WIDTH",
	},
	selection_marker_and_resistance: {
		label: "Selection marker / Resistance",
		className: "L_COLUMN_WIDTH",
	},
	associated_plasmids: {
		label: "Associated plasmids",
		className: "M_COLUMN_WIDTH",
	},
	use: {
		label: "use",
		className: "S_COLUMN_WIDTH",
	},
} as const);

export const PREDEFINED_SCHEMA = Object.freeze({
	"0ee56455-fd08-45aa-9d72-95696b8d7e54": "genus",
	"1cb3bd81-b89c-4856-9ae9-5af431872969": "conjugate",
	"2d037571-a152-4a5f-ab14-a45c5de30b73": "modification",
	"3b110ed7-b57c-43a1-a882-bebf8447108b": "host",
	"04fd2de7-d28f-44f7-816a-f80663ee9327": "modification",
	"4a946b1f-9031-4cc3-99d6-9217ccbb0297": "tags",
	"4d44eeb0-77ac-40a4-9c58-6d515592253b": "primary_secondary",
	"4f5698e9-1b3f-4269-be78-25c2704478a3": "buffer",
	"5af4ab12-6674-4940-b381-e01a413b2d16": "grade",
	"5c4f4ad1-4caa-4afc-a7d1-85c4d1d3fe63": "gene",
	"6e96691b-3c38-4472-8d63-e9a0a9cd495a": "sample_type",
	"08e09d35-4106-4f21-a713-09235399b606": "application_1",
	"8b587b0b-c333-4f7d-bb1f-b71dfaa4032b": "species",
	"8cc8a9f8-a033-4f3a-9926-36652866f8cf": "promoter",
	"9bdcc0e1-3182-4891-a5a1-bbda143b013d": "melting_point",
	"9e0b7c0b-ff60-4900-81c8-821ac0416a4d": "incubation_temperature",
	"030a8cf2-72ec-4a11-87f8-29b919f9965c": "aliquot",
	"37b402b6-5994-43fb-aba3-7acc8e778e2d": "modification_label",
	"40a3607f-0770-45cf-aa20-e4db16faa844": "synonym",
	"65eab60f-3337-4cad-ae65-9c728e8a2362": "a_260_280",
	"82d9952e-f63d-4320-a3b6-40aed09da3fc": "eukaryotic_marker",
	"89e4584d-b95f-4dce-8ad2-c59aa5b279d3": "selection_marker_and_resistance",
	"377cb4f0-a526-482f-9d92-4ff4ddb2b5e4": "backbone",
	"504fbbca-920b-43d8-a540-19e81a7bf174": "solvent",
	"592bca13-6d80-4f30-9e89-d726b048ee02": "methylation_sensitivity",
	"610cfa6b-feef-41dc-a551-57f6dff02740": "selection_marker",
	"667eb506-241d-480e-8083-ac505dd0ad67": "target_species",
	"768c38a7-562c-46a2-b5bb-2f166ed5dfe2": "stock_solution",
	"962d762f-156b-4f8a-badd-3beb852d81db": "melting_temperature",
	"983f50a0-8686-4d0c-919c-6b8d2fb2fc37": "form",
	"10211dc3-655e-4c94-b870-cf36266f5577": "cas",
	"68639ab5-9e5f-4601-a20e-349732215961": "application_2",
	"075182ce-1b5e-49b4-a254-424b36b8b985": "restriction_sites",
	"350428ad-178a-4691-a4ef-ffadb0e666e2": "strand",
	"2715459c-5bc6-40e8-8a28-f65fa7ede6df": "passage_num",
	"11729189-94ab-44e2-b9b1-80415413a115": "size",
	"a202b0c9-da87-4383-8b3e-945b32772a4d": "species",
	"afc38e88-85bd-451c-93ef-1e4bcf73b5bc": "sequence",
	"b82e8e39-8c38-4317-a437-21536aaf2c74": "activity",
	"b521cf5d-2378-4b23-9361-145e49f8d4bf": "sequence3_primer",
	"c7e3d442-6339-4ee0-9ee7-94286c3531d3": "mdl",
	"c011ed58-5de7-41ec-be79-7a09e0b343b9": "clone",
	"c3518635-a6d5-46b7-908d-8d0066eb5c97": "associated_plasmids",
	"ce8b527d-4d18-42ab-90da-aea6816cc81a": "dilution_1",
	"cfe3a053-498a-4dc4-8bc5-89d77d6cb030": "sequence5_primer",
	"d9ac8e09-a051-4a1d-8ae4-7c90714eefdc": "molecular_weight",
	"d0695ca2-15c3-473c-b255-3fa243366270": "prokaryotic_marker",
	"d3550389-1d37-4136-ac8f-68ecc705960d": "patient_id",
	"ddaa3063-f594-471d-89e6-ff3ae35f2342": "gene_or_insert",
	"ef9acc33-86d3-4a95-a618-0c230db8cf0b": "recognition_sequence",
	"efa3818e-2564-48dd-9383-1ade2057786e": "dilution_2",
	"f7ff0dea-a532-43de-a107-5fd3ff0acd9a": "study",
	"f9a742ee-f21b-4db5-8ce6-5d14799d73d7": "genotype_and_strain_background",
	"f44e5794-14f8-47ea-9a7f-7036671fee33": "formula",
	"f80e7d42-a755-4e5a-aa7e-dc9114befc86": "heat_inactivation",
	"f81de593-c81d-42be-b176-13d16238c10f": "application",
	"f244d23b-a105-4787-8694-34abb7b0af2a": "use",
	"fa451893-2fb0-4160-b1d7-37d7cfd1af66": "solubility",
} as const);
