import React from "react";
import { GenemodIcon, Typography, Button } from "@common/components";
import { ColorCssVarMap } from "@common/styles/Colors";
import { IconName } from "@common/components/GenemodIcon/GenemodIconRegistry";
import styles from "./ConfirmMessage.module.scss";
import cn from "classnames";

type ConfirmMessageProps = {
	status: "warning" | "error";
	iconName: IconName;
	okText?: string;
	cancelText?: string;
	onOk: () => void;
	onCancel: () => void;
	children: React.ReactNode;
	className?: string;
	dataCy?: string;
};

export function ConfirmMessage(props: ConfirmMessageProps) {
	const okText = props.okText || "Confirm";
	const cancelText = props.cancelText || "Never mind";

	const messageColor = props.status === "warning" ? "yellow" : "red-contrast";
	return (
		<div
			className={cn(styles.messageContainer, props.className)}
			style={{ borderColor: ColorCssVarMap[messageColor] }}
		>
			<GenemodIcon
				name={props.iconName}
				stroke={messageColor}
				fill={messageColor}
				size="large"
				className={styles.messageIcon}
			/>
			<div className={styles.contents}>
				<Typography color="text-secondary">{props.children}</Typography>
				<div className={styles.buttons}>
					<Button
						type="text"
						onClick={props.onOk}
						dataCy={`confirm-${props.dataCy}`}
					>
						{okText}
					</Button>
					<Button
						type="text"
						onClick={props.onCancel}
						dataCy={`cancel-${props.dataCy}`}
					>
						{cancelText}
					</Button>
				</div>
			</div>
		</div>
	);
}
