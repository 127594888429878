import { Button, Typography } from "@components";
import { WideOnboarding } from "@containers/Auth/components";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { useLoginContext } from "@containers/Auth/LoginInterfaces/LoginContext";
import {
	useCurrentUserQuery,
	useVerifyEmailMutation,
} from "@redux/user/UserApi";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

/**
 * Email verification page
 * @function
 * @category Auth
 * @subcategory Email Verification
 * @param {object} props
 * @param {string} props.match.params.token Verification token from email verification link
 */
export function EmailVerificationMessage(props: any): JSX.Element {
	const { refetch } = useCurrentUserQuery();
	const history = useHistory();
	const { loginStatus } = useLoginContext();
	const { token } = props.match.params;
	const [isLoading, setLoading] = useState(true);
	const [verifyEmail] = useVerifyEmailMutation();

	// Evaluate token
	useEffect(() => {
		verifyEmail({
			key: token,
		})
			.unwrap()
			.catch(() => {
				// Failed to verify. Bad token or backend error
				history.push("/login");
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const redirect = () => {
		if (loginStatus === "LOGGEDIN") {
			// fetching user should pickup the email verified status and the router will take us to the team creation page
			refetch();
			history.push("/signup/create-team");
		} else {
			history.push("/login");
		}
	};

	return (
		<WideOnboarding>
			<div
				data-cy="registration-verified"
				className={styles.verifiedEmailModal}
			>
				<Typography variant="title" bold className={styles.header}>
					Email address verified.
					<br />
					You’re only a couple steps away.
				</Typography>
				<Button
					dataCy="continue-verification"
					stretch
					onClick={redirect}
					disabled={isLoading}
				>
					Continue
				</Button>
			</div>
		</WideOnboarding>
	);
}
