import { mapObject, wrapFn, NestedType } from "@helpers/TypeHelpers";
import { useMemo } from "react";
import { useAppDispatch, appSelector } from "@redux/store";
import { shallowEqual } from "react-redux";
import { AtLeastOneElement } from "@common/helpers/TypeHelpers";
import { AppState } from "../store";

type SliceActions = {
	[key: string]: (...args: any[]) => any;
};

/**
 * This is a utility function that takes in a redux toolkit slice's actions
 * object and returns a function that returns the same actions object, but
 * with the actions wrapped in a dispatch function. The returned function
 * can be used in place of the actions object in order to avoid having to
 * import the store's dispatch function.
 *
 * Example:
 *
 * const slice = createSlice({...});
 * const useSliceActions = createActionsHook(slice.actions);
 *
 * const MyComponent = () => {
 * 		const { action1, action2 } = useSliceActions();
 * 		return <button onClick={() => action1()}>Click Me</button>;
 * }
 */
export const createActionsHook =
	<ActionsType extends SliceActions>(actions: ActionsType) =>
	(): ActionsType => {
		const dispatch = useAppDispatch();
		return useMemo(
			() =>
				mapObject(actions, (key, action) => {
					type ActionType = typeof action;
					const newAction = wrapFn<
						Parameters<ActionType>,
						ReturnType<ActionType>
					>(action as any)(({ args, applyOriginal }) => {
						return dispatch(applyOriginal());
					});
					return newAction as typeof action;
				}) as unknown as ActionsType,
			[dispatch]
		);
	};

/**
 * This is a utility function that takes in a redux toolkit slice's path
 * and returns a custom hook that can be used to select data from the slice.
 *
 * Example:
 *
 * const slice = createSlice({...});
 * const useSliceSelector = createSelectorHook(slice.path);
 *
 * const MyComponent = () => {
 * 		const { field1, field2 } = useSliceSelector("field1", "field2");
 * 		return <div>{field1} {field2}</div>;
 * }
 */
export const createSelectorHook = <Path extends string>(slicePath: Path) => {
	type SliceState = NestedType<AppState, Path>;
	const paths = slicePath.split(".");

	return <
		K extends keyof SliceState,
		Return extends {
			[k in K]: k extends keyof SliceState ? SliceState[k] : never;
		}
	>(
		...fields: AtLeastOneElement<K>
	): Return => {
		return appSelector((state: AppState) => {
			let slice: SliceState = state as any;
			for (const path of paths) {
				slice = (slice as any)[path];
			}
			return fields.reduce((selected, field) => {
				return { ...selected, [field]: slice[field] };
			}, {} as Return);
		}, shallowEqual) as Return;
	};
};

/**
 * This is a utility function that takes in a redux toolkit slice's path
 * and the slice's actions object and returns a custom hook that can be
 * used to select data from the slice and dispatch actions.
 */
export const createSimpleStoreGettersAndSetters =
	<Path extends string>(slicePath: Path) =>
	<ActionsType extends SliceActions>(actions: ActionsType) => {
		const useActions = createActionsHook(actions);

		type SliceState = NestedType<AppState, Path>;
		const paths = slicePath.split(".");

		return <
			K extends keyof SliceState,
			Return extends {
				[k in K]: k extends keyof SliceState ? SliceState[k] : never;
			} & ReturnType<typeof useActions>
		>(
			...fields: AtLeastOneElement<K>
		): Return => {
			const actions = useActions();
			return {
				...appSelector((state: AppState) => {
					let slice: SliceState = state as any;
					for (const path of paths) {
						slice = (slice as any)[path];
					}
					return fields.reduce((selected, field) => {
						return { ...selected, [field]: slice[field] };
					}, {} as Return);
				}, shallowEqual),
				...actions,
			} as Return;
		};
	};
