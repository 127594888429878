import React from "react";
import { Anchorme } from "react-anchorme";
import anchorme from "anchorme";

type AnchormeProps = React.ComponentProps<typeof Anchorme>;

export const GenemodAnchorme = (props: AnchormeProps) => {
	return <Anchorme {...props} />;
};

const BAD_CHARACTERS = new Set([
	"!",
	"@",
	"$",
	"#",
	"%",
	"^",
	"&",
	"*",
	"(",
	")",
	"+",
	"=",
	"?",
	":",
	";",
	"'",
	"`",
	'"',
	"[",
	"]",
	"{",
	"}",
	"|",
	"<",
	">",
	",",
	" ",
]);

/**
 * Wrap a validator function to try again with the last character removed
 * to address these issues:
 * https://github.com/alexcorvi/anchorme.js/issues/82
 * https://github.com/alexcorvi/anchorme.js/issues/115
 */
const wrapValidator = (validator: any) => (url: string) => {
	const urlEncoded = encodeURI(url);
	const lastChar = urlEncoded[urlEncoded.length - 1];
	if (BAD_CHARACTERS.has(lastChar)) {
		return false;
	}
	return validator(urlEncoded);
};

export const anchormeValidator = {
	ip: wrapValidator(anchorme.validate.ip),
	email: wrapValidator(anchorme.validate.email),
	file: wrapValidator(anchorme.validate.file),
	url: wrapValidator(anchorme.validate.url),
};
