import React, { useState } from "react";
import LocationPopup from "@containers/Freezer/components/LocationPopup";
import {
	Box,
	Item,
	ItemGroup,
	ItemLocationData,
	Rack,
	SearchResultItem,
	TableItem,
} from "@common/types";
import { FreezerLayers } from "../RepositoryContext";
import { useLazyItemLocationQuery } from "@redux/inventory/Item";
import { GenemodIcon, Popover, Tooltip, Typography } from "@common/components";
import AssignLocationPopup from "@containers/Freezer/components/AssignLocationPopup";
import styles from "./FreezerTab.module.scss";

export function LocationTooltip({
	item,
	data,
	layer,
}: {
	item: TableItem;
	data: any;
	layer: FreezerLayers;
}) {
	const [isLocationLoading, setIsLocationLoading] = useState(false);
	const [currentLocationData, setCurrentLocationData] =
		useState<ItemLocationData>();
	const [getLocationData] = useLazyItemLocationQuery();

	return (
		<Tooltip
			overlayClassName="location-popover"
			trigger="hover"
			color="layer-03-v2"
			title={
				<LocationPopup
					isLocationLoading={isLocationLoading}
					currentLocationData={currentLocationData}
					result={item as unknown as SearchResultItem}
				/>
			}
			onVisibleChange={(open) => {
				if (open) {
					setIsLocationLoading(true);
					getLocationData(item.id)
						.unwrap()
						.then((res) => {
							setCurrentLocationData(res);
						})
						.catch((err) => {
							console.log(err);
						})
						.finally(() => {
							setIsLocationLoading(false);
						});
				}
			}}
		>
			<div className="flex items-center gap-2">
				{(item as Item).is_archived && (
					<GenemodIcon name="archive" color="text-tertiary" />
				)}
				<Typography>
					{data !== undefined &&
						(data.box_name || data.item_group_name)}
				</Typography>
			</div>
		</Tooltip>
	);
}

export function BoxLocationTooltip({ item, data }: { item: Box; data: any }) {
	return (
		<Tooltip
			overlayClassName="location-popover"
			trigger="hover"
			color="layer-03-v2"
			title={
				<LocationPopup
					isLocationLoading={false}
					currentLocationData={{
						...data,
						...item.location,
						rack: item.location?.rack_location?.rack,
					}}
					result={item as unknown as SearchResultItem}
				/>
			}
			className={styles.locationName}
		>
			{(data.rack_is_archived || data.category_is_archived) && (
				<GenemodIcon name="archive" color="text-tertiary-v2" />
			)}
			<Typography>{data.rack_name || data.category_name}</Typography>
		</Tooltip>
	);
}

export function RackLocationTooltip({ item, data }: { item: Rack; data: any }) {
	const [isLocationLoading, setIsLocationLoading] = useState(false);

	return (
		<Tooltip
			overlayClassName="location-popover"
			trigger="hover"
			color="layer-03-v2"
			title={
				<LocationPopup
					isLocationLoading={isLocationLoading}
					currentLocationData={data}
					result={item as unknown as SearchResultItem}
				/>
			}
			className={styles.locationName}
		>
			<div className="flex items-center gap-1">
				{data.shelf_is_archived && (
					<GenemodIcon name="archive" color="text-tertiary-v2" />
				)}
				<Typography>{data.shelf_name || "-"}</Typography>
			</div>
		</Tooltip>
	);
}

export function ItemGroupLocationTooltip({
	item,
	data,
}: {
	item: ItemGroup;
	data: any;
}) {
	const [isLocationLoading, setIsLocationLoading] = useState(false);

	return (
		<Tooltip
			overlayClassName="location-popover"
			trigger="hover"
			color="layer-03-v2"
			title={
				<LocationPopup
					isLocationLoading={isLocationLoading}
					currentLocationData={data}
					result={item as unknown as SearchResultItem}
				/>
			}
			className={styles.locationName}
		>
			{data.category_is_archived && (
				<GenemodIcon name="archive" color="text-tertiary-v2" />
			)}
			<Typography>{data.category_name || "-"}</Typography>
		</Tooltip>
	);
}

export function AssignItemGroupLocationTooltip({
	isAssignLocationOpen,
	onBoxSelect,
	onVisibleChange,
}: {
	isAssignLocationOpen: boolean;
	onBoxSelect: (categoryId: number, category: TableItem) => void;
	onVisibleChange: (open: boolean) => void;
}) {
	return (
		<Popover
			trigger="click"
			content={
				isAssignLocationOpen ? (
					<AssignLocationPopup onSelect={onBoxSelect} />
				) : undefined
			}
			visible={isAssignLocationOpen}
			onVisibleChange={onVisibleChange}
		>
			<div className={styles.locationContainer}>
				<div className={styles.noLocation}>-</div>
				<Typography
					className={styles.assignLocation}
					color="button-text"
				>
					Assign Location
				</Typography>
			</div>
		</Popover>
	);
}
