import React from "react";
import "./Bookmark.scss";
import { Demo, DemoSection, DemoWrapper, GenemodIcon } from "../index";
import styles from "./Bookmark.module.scss";
import classNames from "classnames";

type BookmarkProps = {
	isBookmarked: boolean;
	onChange?: (bookmark: boolean) => void;
	size?: "medium" | "large" | "small";
	containerStyle?: React.CSSProperties;
};
export default function Bookmark({
	isBookmarked,
	onChange,
	size = "medium",
	containerStyle,
}: BookmarkProps) {
	const handleClick = () => {
		onChange?.(!isBookmarked);
	};

	return (
		<div
			className="bookmark-container"
			onClick={handleClick}
			style={containerStyle}
		>
			<div className="bookmark-icon">
				{
					<GenemodIcon
						name={isBookmarked ? "bookmark-freezer" : "bookmark"}
						color="pink-06"
						dataCy="bookmark-freezer"
						className={classNames(styles.bookmark, styles[size])}
					/>
				}
			</div>
		</div>
	);
}

export function BOOKMARK_DEMO() {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Bookmark states"
					description="There are 2 different bookmark states."
				>
					<h4>Empty</h4>
					<Bookmark isBookmarked={false} />
					<h4>Fully Filled</h4>
					<Bookmark isBookmarked={true} />
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
