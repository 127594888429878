import { ContactFormType } from "@common/components/ContactFormPanel/ContactFormPanelData";
import { EditorDocumentTableId } from "@common/components/Editor/TableV2/types";
import { UpgradeModalType } from "@common/components/UpgradeModal";
import {
	Consumable,
	ConsumableId,
	ExperimentId,
	FolderId,
	FreezerOrderId,
	GenemodDocumentUUID,
	Item,
	ProtocolId,
	ProtocolVersionId,
	WorkspaceId,
	WorkspaceUserId,
} from "@common/types";
import {
	EquipmenUnitId,
	EquipmentId,
	Schedule,
} from "@containers/Facility/constants";
import { ORDER_MODE } from "@containers/Freezer/components/OrderingWidget";
import { AreaBounds } from "@containers/Freezer/data";
import combineSlices from "@redux/helpers/CombineSlices";
import { createModalSlice } from "@redux/helpers/ModalStateHelpers";
import { RevisionTypeValue } from "../../common/types/ChatGPT";
import { createSlice } from "@reduxjs/toolkit";

export const { reducers: commonModalReducers, actions: commonModalActions } =
	combineSlices([
		// GLOBAL MODALS
		createModalSlice<{
			/** additional Path from current path withouth search url*/
			additionalPath?: string;
			/** new path instead current path */
			newPath?: string;
		}>()("shareLinkModal"),
		createModalSlice<{
			defaultBusiness?: boolean;
		}>()("changePlanModal"),
		createModalSlice<{
			type: UpgradeModalType;
		}>()("upgradeModal"),
		createModalSlice()("contactUsPanel"),
		createModalSlice()("changeWorkspacePanel"),
		// ACCOUNT MODALS
		createModalSlice()("cancelSubscriptionModal"),
		createModalSlice()("resumeSubscription"),
		createModalSlice<{ type: ContactFormType }>()("contactForm"),

		// DASHBOARD MODALS
		createModalSlice()("NPSFeedbackModal"),
		createModalSlice()("preferencesModal"),
		// PM MODALS
		createModalSlice<
			| {
					type: "PROTOCOL_OR_LAST_REMAINING_VERSION";
					protocolId: ProtocolId;
			  }
			| {
					type: "PROTOCOL_VERSION";
					protocolId: ProtocolId;
					versionId: ProtocolVersionId;
			  }
		>()("deleteProtocolModal"),
		createModalSlice<
			| { type: "CREATE"; projectId: number; subprojectId?: number }
			| {
					type: "RENAME";
					experimentId: ExperimentId;
			  }
			| {
					type: "RENAME";
					folderId: FolderId;
			  }
		>()("experimentCreateRenameModal"),
		createModalSlice<{ id: number }>()("editFridgeSettingsPanel"),
		createModalSlice<{ id: number }>()("editBoxSettingsPanel"),
		createModalSlice<
			| { type: "CREATE"; experimentId: number }
			| {
					type: "RENAME";
					documentId: GenemodDocumentUUID;
			  }
		>()("experimentCreateRenameProtocolDocumentModal"),
		createModalSlice<{
			type: "DELETE";
			documentId: GenemodDocumentUUID;
			documentName: string;
		}>()("experimentDeleteProtocolDocumentModal"),
		createModalSlice<{
			type: "CREATE";
		}>()("createProtocolTemplateModal"),
		createModalSlice<{
			previewProtocolId: ProtocolId;
			version: number;
		}>()("protocolPreviewModal"),
		createModalSlice()("editorLinkModal"),
		createModalSlice()("editorImageModal"),
		createModalSlice()("notificationPanel"),
		createModalSlice<{
			itemTypeId: number;
		}>()("itemTypePreviewPanel"),
		createModalSlice<{
			// Need it to handle the modal superposition
			// when you are creating a new freezer
			onClose?: () => void;
			origin?: "pannel" | "select";
			redirectTo?: string;
		}>()("createItemTypeModal"),
		createModalSlice<{
			itemTypeId: number;
		}>()("renameItemTypeModal"),
		// FREEZER MODALS
		createModalSlice<{
			boxId: number;
		}>()("customizeBoxModal"),
		// ADMIN ENTERPRISE PORTAL
		createModalSlice<{
			userId: number;
		}>()("manageUserPanel"),
		createModalSlice<{
			userId: number;
		}>()("deactivateUserPanel"),
		createModalSlice<{
			userId: number;
		}>()("reactivateUserPanel"),
		createModalSlice<{
			userId: number;
		}>()("changeAccountTypePanel"),
		createModalSlice<{
			userId: number;
		}>()("manageUserWorkspacePanel"),
		createModalSlice<{ id?: WorkspaceId }>()("enterpriseTeamSidepanel"),
		createModalSlice()("enterpriseInviteUsersModal"),
		createModalSlice<{ defaultPlan: "team" | "business" | "enterprise" }>()(
			"requestUpgradeModal"
		),
		createModalSlice()("searchProjectFileModal"),
		createModalSlice<{
			tableId: EditorDocumentTableId;
		}>()("pmTablePrecisionModal"),
		// ENTERPRISE TEAM MEMBERS PAGE
		createModalSlice<{
			workspaceUserId: WorkspaceUserId;
			workspaceUserName: string;
			workspaceName: string;
		}>()("removeUserModal"),
		createModalSlice<{ id: ConsumableId }>()("deleteConsumableModal"),
		createModalSlice<{ id: ConsumableId; onlyMoves?: boolean }>()(
			"consumableMovingCartModal"
		),
		createModalSlice<{ id: number; navigateToParentRack?: boolean }>()(
			"deleteBoxModal"
		),
		createModalSlice<{ id: number; navigateToParentRack?: boolean }>()(
			"deleteItemGroupModal"
		),
		createModalSlice<{ toDelete: number[] }>({ toDelete: [] })(
			"deleteSelectedBoxTableCellsModal"
		),
		createModalSlice<{ focusModeOnly: boolean }>()(
			"pasteSelectedBoxTableCellsModal"
		),
		createModalSlice<{ preDragBounds: AreaBounds }>()(
			"pasteBoxCellsFromDragModal"
		),
		createModalSlice<{
			itemAmount: number;
			itemId?: number;
			itemDetails?: Partial<Item>;
		}>()("createRepositoryItems"),
		createModalSlice<{
			quantity: number;
			consumableId?: number;
			consumableDetails?: Partial<Consumable>;
		}>()("createRepositoryConsumables"),
		createModalSlice<
			| {
					mode: ORDER_MODE.VIEW;
					tab?: "active" | "complete";
			  }
			| {
					mode: ORDER_MODE.DETAILS;
					orderId: FreezerOrderId;
			  }
			| {
					mode:
						| ORDER_MODE.CREATE
						| ORDER_MODE.EDIT
						| ORDER_MODE.REORDER;
			  }
		>()("orderingWidget"),
		createModalSlice<{ selectedRevisionTypeValue: RevisionTypeValue }>()(
			"reviseHighlightedTextHangingToolbar"
		),
		// *** FACILITY MODALS **
		createModalSlice<{
			equipment: EquipmentId;
			equipment_unit?: EquipmenUnitId;
			is_combined: boolean;
			schedule?: Schedule;
			date?: Date;
		}>()("scheduleFacilityModal"),
	]);

export const itemTypeSlice = createSlice({
	name: "itemTypeSlice",
	initialState: {
		typeId: null,
	},
	reducers: {
		setTypeId: (state, action) => {
			state.typeId = action.payload;
		},
	},
});
