export type Tool = "project_management" | "freezer" | "dna";

export type ToolsSettings = Record<Tool, boolean>;

export type SidebarSettingsDto = ToolsSettings & {
	order: string;
};

export type SidebarSettings = ToolsSettings & {
	order: Tool[];
};

export const sidebarSettingsFromDto = (
	dto: SidebarSettingsDto
): SidebarSettings => ({
	...dto,
	order: [
		...dto.order.split(","),
		// make sure all of the tools are in the order array
		"project_management",
		"freezer",
		"dna",
		"equipments",
	] /* Remove extras from order */
		.filter((item, pos, self) => self.indexOf(item) === pos) as Tool[],
});

export const sidebarSettingsToDto = (
	settings: SidebarSettings
): SidebarSettingsDto => ({ ...settings, order: settings.order.join(",") });
