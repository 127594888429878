import {
	PatchOrgUserData,
	PatchWorkSpaceUserEnterpriseData,
	Workspace,
	WorkspaceUser,
	WorkspaceUserId,
	WorkspaceId,
	WorkspaceUserRole,
	WorkspaceStatus,
} from "@common/types";
import {
	PaginatedSearchQuery,
	PaginatedSearchResults,
} from "@common/types/Search";
import { OrganizationUser } from "@common/types";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

const TAG_TYPES = ["Users", "WorkspaceUser", "Workspace"] as const;

export type TeamMemberPaginatedSearchQuery = PaginatedSearchQuery & {
	ordering?: string;
	status?: number;
	role?: number;
};

export type WorkspaceUserPaginatedSearchQuery = PaginatedSearchQuery & {
	org_user_id?: number;
	ordering?: string;
	isManagement?: boolean;
};

export type WorkspacePaginatedSearchQuery = PaginatedSearchQuery & {
	org_user_id?: number;
};

export type EnterprisePaginatedSearchQuery = PaginatedSearchQuery & {
	ordering?: any;
	status?: number;
	role?: number;
	org_user_id?: number;
};

type SaveWorkspaceProps = {
	name: string;
	status: WorkspaceStatus;
};

export const adminEnterpriseApi = createApi({
	reducerPath: "adminApi",
	tagTypes: TAG_TYPES,
	baseQuery: genemodBaseQuery({ baseUrl: "" }),
	endpoints: (builder) => {
		const orgUsers = {
			getOrgUsers: builder.query<
				PaginatedSearchResults<OrganizationUser>,
				EnterprisePaginatedSearchQuery
			>({
				query: (params) => ({
					url: `/enterprise/org-user/`,
					method: "GET",
					params,
				}),
				providesTags: ["Users"],
			}),
			getSpecificUser: builder.query<
				OrganizationUser,
				{
					id: number;
				}
			>({
				query: ({ id }) => ({
					url: `/enterprise/org-user/${id}/`,
					method: "GET",
				}),
				providesTags: ["Users"],
			}),
			getOrgUser: builder.query<OrganizationUser, number>({
				query: (orgUserId) => ({
					url: `/enterprise/org-user/${orgUserId}/`,
					method: "GET",
				}),
				providesTags: ["Users"],
			}),
			patchOrgUser: builder.mutation<OrganizationUser, PatchOrgUserData>({
				query: (body) => ({
					url: `/org/v2/${body.organization}/org-users/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["WorkspaceUser", "Users"],
			}),
			sendInvitationToOrgUser: builder.mutation<
				any,
				{
					orgId: number;
					body: {
						is_admin?: boolean;
						email: string;
						workspaces?: WorkspaceId[];
						workspace_role?: WorkspaceUserRole;
					}[];
				}
			>({
				query: ({ orgId, body }) => ({
					url: `/org/v2/${orgId}/org-users/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["Workspace", "Users", "WorkspaceUser"],
			}),
			checkInviteEmail: builder.mutation<
				any,
				{
					orgId: number;
					emails: { email: string; workspaces?: WorkspaceId[] }[];
				}
			>({
				query: ({ orgId, emails }) => ({
					url: `/org/v2/${orgId}/check-invite-email/`,
					method: "POST",
					body: emails,
				}),
			}),
			cancelInvitation: builder.mutation<
				void,
				{
					orgId: number;
					orgUserId: number;
				}
			>({
				query: ({ orgId, orgUserId }) => ({
					url: `/org/v2/${orgId}/org-users/${orgUserId}/cancel-invite/`,
					method: "DELETE",
				}),
				invalidatesTags: ["WorkspaceUser", "Users"],
			}),
		};

		const workspaces = {
			getWorkspaces: builder.query<
				PaginatedSearchResults<Workspace>,
				EnterprisePaginatedSearchQuery
			>({
				query: (params) => ({
					url: "/enterprise/workspace/",
					method: "GET",
					params,
				}),
				providesTags: ["Workspace"],
				extraOptions: { maxRetries: 1 },
			}),
			saveWorkspace: builder.mutation<Workspace, SaveWorkspaceProps>({
				query: (body) => ({
					url: "/enterprise/workspace/",
					method: "POST",
					body,
				}),
				invalidatesTags: ["Workspace"],
			}),
			deleteWorkspace: builder.mutation<void, string>({
				query: (workspaceId) => ({
					url: `/enterprise/workspace/${workspaceId}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Workspace"],
			}),
			patchWorkspace: builder.mutation<Workspace, Workspace>({
				query: (body) => ({
					url: `/enterprise/workspace/${body.id}/`,
					method: "PATCH",
					body,
				}),
			}),
			getWorkspace: builder.query<Workspace, WorkspaceId>({
				query: (id) => ({
					url: `/enterprise/workspace/${id}/`,
					method: "GET",
				}),
				providesTags: ["Workspace"],
			}),
		};

		const workspaceUser = {
			getWorkspaceUser: builder.query<
				PaginatedSearchResults<WorkspaceUser>,
				WorkspaceUserPaginatedSearchQuery
			>({
				query: (params) => ({
					url: `/enterprise/workspace-user/`,
					method: "GET",
					params,
				}),
				providesTags: ["WorkspaceUser"],
			}),
			patchWorkSpaceUserEnterprise: builder.mutation<
				WorkspaceUser,
				PatchWorkSpaceUserEnterpriseData
			>({
				query: (body) => ({
					url: `/enterprise/workspace-user/${body.workspace_user_id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["WorkspaceUser", "Users", "Workspace"],
			}),
			deleteWorkspaceUser: builder.mutation<void, WorkspaceUserId>({
				query: (id) => ({
					url: `/enterprise/workspace-user/${id}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Workspace", "Users", "WorkspaceUser"],
			}),
		};

		return {
			...orgUsers,
			...workspaces,
			...workspaceUser,
		};
	},
});

export const {
	useGetOrgUsersQuery: useEnterpriseGetOrgUsersQuery,
	useGetSpecificUserQuery,
	useGetWorkspaceUserQuery,
	usePatchOrgUserMutation,
	useCancelInvitationMutation,
	usePatchWorkSpaceUserEnterpriseMutation,
	useGetWorkspacesQuery,
	useGetWorkspaceQuery,
	useGetOrgUserQuery,
	useLazyGetOrgUserQuery,
	useSaveWorkspaceMutation,
	useDeleteWorkspaceMutation,
	usePatchWorkspaceMutation,
	useSendInvitationToOrgUserMutation,
	useCheckInviteEmailMutation,
	useDeleteWorkspaceUserMutation,
} = adminEnterpriseApi;

export const useAllWorkspaces = () =>
	adminEnterpriseApi.useGetWorkspacesQuery({
		page: 1,
		page_size: 99,
	});
