import React from "react";
import { Typography, TextArea, Input, Select } from "@components";
import styles from "./index.module.scss";
import { SelectValue } from "antd/lib/select";
import { appSelector } from "@redux/store";
import { useCurrentUserQuery } from "@redux/user/UserApi";

type topicOption = {
	value: string;
	label: string;
	placeholder: string;
};

const topicOptions = [
	{
		value: "1",
		label: "Ask a question",
		placeholder: "What would you like to know...",
	},
	{
		value: "2",
		label: "Report a bug",
		placeholder: "Describe your bug or issue...",
	},
	{
		value: "3",
		label: "Suggest a feature",
		placeholder: "Let us know what you hope to improve...",
	},
	{
		value: "4",
		label: "Other",
		placeholder: "Let us know what's on your mind...",
	},
] as topicOption[];

type productOption = {
	value: string;
	label: string;
};

const productOptions = [
	{
		value: "1",
		label: "Dashboard",
	},
	{
		value: "2",
		label: "Projects",
	},
	{
		value: "3",
		label: "Inventory",
	},
	{
		value: "4",
		label: "DNA",
	},
	{
		value: "5",
		label: "Account settings",
	},
] as productOption[];

export type ContactUsFormData = {
	description: string;
	support_email_topic: string;
	support_email_tool: string;
};

export const defaultContactFormData = {
	description: "",
	support_email_topic: "1",
	support_email_tool: "0",
} as ContactUsFormData;

type ContactUsFormProps = {
	/** form data */
	data: ContactUsFormData;
	/** function for updating the form data */
	onChange: (data: ContactUsFormData) => void;
	/** whether to show an email input at the top */
	showEmailInput?: boolean;
	/** whether the cubmit button got clicked */
	submitClicked: boolean;
	/** updated whether the submit button clicked or not */
	setSubmitClicked: (arg: boolean) => void;
};

export default function ContactUsForm({
	data,
	onChange,
	showEmailInput = false,
	submitClicked,
	setSubmitClicked,
}: ContactUsFormProps): JSX.Element {
	const { Option } = Select as any;
	const { data: user } = useCurrentUserQuery();
	const { description, support_email_topic, support_email_tool } = data;
	const selectedPlaceholder = topicOptions.find(
		(opt) => opt.value === support_email_topic
	)?.placeholder;

	const handleTopicChange = (topic: SelectValue) => {
		setSubmitClicked(false);
		onChange({
			...data,
			support_email_topic: topic.toString(),
		});
	};

	const handleToolChange = (tool: SelectValue) => {
		onChange({
			...data,
			support_email_tool: tool.toString(),
		});
	};

	const handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setSubmitClicked(false);
		const value = e.target.value;
		onChange({
			...data,
			description: value,
		});
	};

	return (
		<div className={styles.formContainer}>
			{showEmailInput && (
				<Input label="Email" value={user?.email} disabled />
			)}
			<div className={styles.select}>
				<Typography
					className={styles.boardLabel}
					variant="label"
					bold
					color="text-secondary"
				>
					Subject
				</Typography>
				<Select
					placeholder="Choose an option"
					value={
						support_email_topic === "0"
							? undefined
							: support_email_topic
					}
					onChange={handleTopicChange}
					error={submitClicked && support_email_topic === "0"}
					isInput
				>
					{topicOptions.map((opt) => (
						<Option key={opt.value} value={opt.value}>
							{opt.label}
						</Option>
					))}
				</Select>
				{submitClicked && support_email_topic === "0" && (
					<Typography
						className={styles.topicError}
						variant="caption"
						color="red-contrast"
					>
						Please choose a topic
					</Typography>
				)}
			</div>

			<div className={styles.select}>
				<Typography
					className={styles.boardLabel}
					variant="label"
					bold
					color="text-secondary"
				>
					Product (optional)
				</Typography>
				<Select
					placeholder="Choose an option"
					value={
						support_email_tool === "0"
							? undefined
							: support_email_tool
					}
					onChange={handleToolChange}
					isInput
				>
					{productOptions.map((opt) => (
						<Option key={opt.value} value={opt.value}>
							{opt.label}
						</Option>
					))}
				</Select>
			</div>
			<TextArea
				label="Message"
				style={{ minHeight: 200 }}
				gutterBottom={false}
				value={description}
				onChange={handleDescription}
				placeholder={
					selectedPlaceholder || "Write your message here..."
				}
				error={
					submitClicked && !description.trim()
						? "Please write your message"
						: undefined
				}
			/>
		</div>
	);
}
