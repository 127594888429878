import React from "react";
import NavigationList from "./";
import { useFreezerQuery } from "@redux/inventory/Freezer";
import { useShelvesQuery } from "@redux/inventory/Shelf";

type ShelfListProps = {
	/** ID of the parent freezer */
	freezerId: number | null;
	/** Sets the selected shelf id */
	setShelfId: (rackId: number) => void;
};

/**
 * Displays a list of shelves within a freezer
 */
export default function ShelfList(props: ShelfListProps): JSX.Element {
	const { freezerId, setShelfId } = props;
	const freezerFetchId = freezerId || -1;
	const { data: freezer, isLoading: isFreezerLoading } = useFreezerQuery(
		freezerFetchId,
		{ skip: freezerFetchId < 0 || !freezerFetchId }
	);
	const { data: shelves = [], isLoading: isShelvesLoading } = useShelvesQuery(
		{
			id: freezerFetchId,
		},
		{ skip: freezerFetchId < 0 || !freezerFetchId }
	);
	return (
		<NavigationList
			navigationLevel="freezer"
			onNavigateForward={(item) => {
				setShelfId(item.id);
			}}
			parentName={freezer?.name}
			childrenList={shelves}
			loading={isFreezerLoading || isShelvesLoading}
			navigationElements={[
				{
					name: freezer?.name || "",
					onClick: () => {},
				},
			]}
		/>
	);
}
