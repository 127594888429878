/** @file String formatters */

import { Color, ColorCssVarMap } from "@common/styles/Colors";
import { ISOString } from "@common/types/Date";
import React from "react";

/**
 * Formats a string or value to USD with two
 * trailing decimals.
 * @function
 *
 * @param {string|number} cost Cost to format
 * */
export function formatCost(cost: string | number) {
	return Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	}).format(+cost);
}

export function capitalizeFirstLetter<T extends string>(str: T): Capitalize<T> {
	if (!str || typeof str !== "string") {
		return str as Capitalize<T>;
	}
	return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
}

/**
 * Formats a string to be a sentence case
 * @function
 *
 * @param {string} str text to format
 * */
export function formatSentenceCase(str: string) {
	if (!str || typeof str !== "string") {
		return str;
	}
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Adds commas to a number
 */
export function formatNumber(value: string | number) {
	return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 * Internal helper function that handles formatting dates from date strings.
 * Right now we are just formatting dates with en-US optioins.
 */
const intlFormatDate = (
	options: Intl.DateTimeFormatOptions,
	isoString: ISOString
) => new Intl.DateTimeFormat("en-US", options).format(new Date(isoString));

/**
 * Convert ISOString value to "Month Day, Year"
 * Eg: "August 13, 2021"
 * @param isoString
 */
export const formatDate = (isoString: ISOString) =>
	intlFormatDate(
		{
			month: "short",
			day: "numeric",
			year: "numeric",
		},
		isoString
	);

/**
 * Convert ISOString value to "MM/DD"
 * EG: "11/30"
 */
export const formatMonthDay = (isoString: ISOString) =>
	intlFormatDate(
		{
			month: "numeric",
			day: "numeric",
		},
		isoString
	);

/**
 * Format a number using a dictionary of cutoff values.
 * Only works with positive cutoff values.
 *
 * Returns the label corresponding to the greatest cutoff value that num exceeds.
 */
export function getNumberRepr(
	num: number,
	representations: Record<string, number>
): string {
	let representation = num.toLocaleString();
	let currentCutoff = 0;
	Object.entries(representations).forEach(([label, cutoff]) => {
		// Check if the number exceeds the cutoff
		if (cutoff > currentCutoff && num >= cutoff) {
			currentCutoff = cutoff;
			representation = Math.floor(num / cutoff) + label;
		}
	});
	return representation;
}

/**
 * formats the filter count. capped at 1k. see zeplin for details
 */
export function formatFilterCount(num: number): string {
	return getNumberRepr(num, { "K+": 1000 });
}

/**
 * formats the quantity of consumables. Add commas to make it more readable.
 * capped at 1M.
 * @returns {string}
 */
export function formatQuantity(num: number): string {
	const max_count = 1000000;
	return getNumberRepr(num, { "M+": max_count });
}

/**
 * A "tagged template literal" function to shorten string template params so we can get somewhat smaller strings.
 * Usage:
 * const manyOs = "oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo";
 *
 * const truncated = truncArgs`my really l${manyOs}ng str`(10) // "my really loooooooooo...ng string"
 */
export function truncArgs(strings: TemplateStringsArray, ...args: any[]) {
	// start with the first template string, iterate over the rest, building up the result
	// Note: When testing this, the length of the args array was always one less than the strings array
	return (limit: number) =>
		strings.slice(1).reduce((concat, next, index) => {
			let arg = args[index];
			if (!arg) return arg;
			if (arg.length > limit) {
				// limit string and add ellipsis character
				arg = arg.slice(0, limit).trim() + "\u2026";
			}
			return concat + arg + next;
		}, strings[0]);
}

/**
 * Given list of words, we list then out separated by commas just like in sentence
 *
 * ex) words = ["banana", "orange", "apple"] => banana, orange, and apple
 */
export function sentenceOutListOfWords(words: string[]) {
	if (words.length === 1) {
		return words[0];
	} else {
		return `${words.slice(0, words.length - 1).join(", ")}, and ${
			words[words.length - 1]
		}`;
	}
}

/*
 * Checks if the input "node" can be rendered by react (ie: passed as a child).
 *
 * Source: https://stackoverflow.com/questions/67422317/check-if-value-is-a-valid-react-child
 */
export function isReactRenderable(node: any): boolean {
	/*
	TEST:
	console.log([
		isReactRenderable(0),			// true
		isReactRenderable("0"),			// true
		isReactRenderable([]),			// true
		isReactRenderable([0]),			// true
		isReactRenderable(["0"]),		// true
		isReactRenderable([[]]),		// true
		isReactRenderable([[],[]]),		// true
		isReactRenderable([[0]]),		// true
		isReactRenderable({}),			// false
		isReactRenderable([{}]),		// false
		isReactRenderable([[[[{}]]]]), 	// false
	]);
	*/

	if (Array.isArray(node)) {
		return node.length === 0 || !node.some((v) => !isReactRenderable(v));
	}

	return (
		["string", "number"].includes(typeof node) || React.isValidElement(node)
	);
}

/**
 * Emphasize some text.
 */
export const emphasizeText =
	(strings: TemplateStringsArray, ...emphasizeText: string[]) =>
	(emphasizeColor: Color) =>
		strings.reduce(
			(elems, str, i) => (
				<>
					{elems}
					{str}
					{i < emphasizeText.length && (
						<span style={{ color: ColorCssVarMap[emphasizeColor] }}>
							{emphasizeText[i]}
						</span>
					)}
				</>
			),
			<></>
		);

export const pluralS = (count: number) => (count === 1 ? "" : "s");
