import { NotificationApps, NotificationSourceType } from "@common/types";
import {
	NotificationPayload,
	useNotification,
} from "@redux/Dashboard/DashboardApiSlice";

export type MentionNotificationSourceType =
	| "EXPERIMENT_MENTION"
	| "ORDER_COMMENT";

export type SendNotificationFunctionType = ({
	object_name,
	object_id,
	receiver,
}: Pick<NotificationPayload, "object_name" | "object_id" | "receiver">) => void;

/**
 * Sends mention notification
 */
export const useSendMentionNotification = (
	source: NotificationSourceType
): SendNotificationFunctionType => {
	const sendNotification = useNotification(source as NotificationSourceType);

	const description =
		source === "EXPERIMENT_MENTION"
			? "mentioned you in experiment"
			: "mentioned you in order";
	const notification_type = NotificationApps.MENTIONS;

	return ({
		object_name,
		object_id,
		receiver,
	}: Pick<NotificationPayload, "object_name" | "object_id" | "receiver">) =>
		sendNotification({
			object_name,
			object_id,
			receiver,
			description,
			notification_type,
		});
};
