import { Editor } from "slate";
import { Node } from "./Node";

export const Inline = {
	...Node,
	isInline: true,
};

export const Mark = {
	...Inline,
	isMark: true,
	type: "",

	getMarks(editor) {
		return Editor.marks(editor);
	},

	/**
	 * Returns true if the given mark is active.
	 * @param {Editor} editor Editor instance
	 * @param {String} mark Mark type
	 */
	isActive(editor, mark) {
		const marks = Editor.marks(editor);
		return marks && marks[mark];
	},

	/**
	 * Toggles a mark with a given value. Defaults to true
	 * @param {Editor} editor Editor instance
	 * @param {Mark} mark
	 * @param {Any} [value=true] Value of the mark if applicable
	 */
	toggleMark(editor, mark, value = true) {
		if (this.isActive(editor, mark.type, value)) {
			Editor.removeMark(editor, mark.type);
		} else {
			Editor.addMark(editor, mark.type, value);
		}
		this.focusEditor(editor);
	},

	/**
	 * Toggles this specific mark
	 * @param {Editor} editor
	 */
	toggle(editor, value = true) {
		this.toggleMark(editor, this, value);
	},
};

/**
 * Attribute is like a mark but it can have
 * changing values. E.g. color mark
 */
export const Attribute = {
	...Mark,
	/**
	 * @param {Editor} editor
	 * @returns {Any} The value for an attribute node of this type
	 */
	getValue(editor) {
		const marks = this.getMarks(editor);
		return marks[this.type];
	},

	/**
	 * @returns {Boolean} True if the mark is active with the provided value
	 */
	isActive(editor, mark, value) {
		return Mark.isActive(editor, mark) && this.getValue(editor) === value;
	},
};
