import React, { ComponentPropsWithoutRef } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { ColorCssVar, ColorCssVarMap } from "@common/styles/Colors";
import { Typography } from "..";
import { TypographyProps } from "../Typography/Typography";
import {
	ExperimentStatus,
	EXPERIMENT_STATUSES,
	STATUS_COLORS,
} from "@common/types";

type GenemodDotProps = {
	color?: ColorCssVar;
	addBorder?: boolean;
	size?: number;
	wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
};

export default function GenemodDot({
	color = ColorCssVarMap["layer-01"] as ColorCssVar,
	addBorder = false,
	size = 8,
	wrapperProps,
}: GenemodDotProps): JSX.Element {
	return (
		<div
			{...wrapperProps}
			className={classNames(wrapperProps?.className, {
				[styles.bordered]: addBorder,
			})}
		>
			<div
				className={styles.genemodDot}
				style={{
					backgroundColor: color,
					width: size,
					height: size,
				}}
			></div>
		</div>
	);
}

interface PmStatusIndicatorProps extends ComponentPropsWithoutRef<"span"> {
	status: 0 | 1 | 2;
	labelProps?: TypographyProps;
}
export const PmStatusIndicator = ({
	status,
	labelProps = {},
	...props
}: PmStatusIndicatorProps): JSX.Element => (
	<span
		{...props}
		style={{
			...props.style,
			display: "flex",
			gap: 8,
			alignItems: "center",
		}}
	>
		<GenemodDot color={STATUS_COLORS[status as 0 | 1 | 2]} />
		<Typography {...labelProps}>
			{EXPERIMENT_STATUSES[status as ExperimentStatus]}
		</Typography>
	</span>
);
