import React, { useEffect, useMemo } from "react";
import {
	Modal,
	Typography,
	Input,
	GenemodIcon,
	Notification,
} from "@components";
import styles from "./ShareLinkModal.module.scss";
import { useLocation } from "@common/helpers/Hooks/UseRouterDom";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { deprecated_getConfig } from "@common/config/GenemodConfig";

/** backendUrl for share link */
const backendUrl = deprecated_getConfig("REACT_APP_URL");

export default function ShareLinkModal(): JSX.Element {
	const location = useLocation();

	const { isShareLinkModalVisible, shareLinkModalData, closeShareLinkModal } =
		useCommonModalState("shareLinkModal");
	const { additionalPath, newPath } = shareLinkModalData || {};

	useEffect(closeShareLinkModal, [location.pathname]);

	const link = useMemo(() => {
		const defaultLink = backendUrl + location.pathname;
		if (newPath) {
			return backendUrl + newPath;
		} else if (additionalPath !== undefined) {
			// if additionalPath is empty string, just return defaultUrl although there is search url
			return additionalPath
				? [defaultLink, additionalPath].join("/")
				: defaultLink;
		} else if (location.search) {
			return defaultLink + location.search;
		}
		return defaultLink;
	}, [location, additionalPath, newPath]);

	const handleCopyLink = () => {
		navigator.clipboard.writeText(link);
		Notification.success({ message: "Link copied." });
	};
	return (
		<Modal
			visible={isShareLinkModalVisible}
			onCancel={closeShareLinkModal}
			title={"Share link"}
			footer={null}
			dataCy="share-link"
		>
			<div className={styles.shareLinkContainer}>
				<div className={styles.copyText}>
					<Input
						dataCy="share-link-input"
						value={link}
						gutterBottom={false}
					/>
					<GenemodIcon
						name="copy-link"
						size="large"
						onClick={handleCopyLink}
						hoverEffect
						dataCy="copy-link-btn"
					/>
				</div>
				<Typography variant="caption" color="text-secondary">
					This link works for people with existing access.
				</Typography>
			</div>
		</Modal>
	);
}
