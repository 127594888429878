import { NominalType } from "@helpers/TypeHelpers";
import { Avatar, ISOString } from ".";
import { UUID } from "./Common";

export type CommentId = NominalType<"CommentId", UUID>;

export type Comment = {
	id: CommentId;
	created_by: Avatar;
	created_at: ISOString;
	updated_by: Avatar;
	updated_at: ISOString;
	body: string;
};
