import { createApi } from "@reduxjs/toolkit/query/react";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import {
	GenemodDocumentUUID,
	ExperimentDocumentChat,
	ExperimentDocumentChatId,
	ExperimentDocumentChatListItem,
	RevisionTypeValue,
	HighlightedTextRevision,
	ExperimentDocumentSuggestedPrompts,
	SuggestedPrompts,
	SuggestedPromptsId,
} from "@common/types";

export const chatGPTApi = createApi({
	reducerPath: "chatGPTApi",
	baseQuery: genemodBaseQuery({ baseUrl: "" }),
	tagTypes: [
		"ExperimentDocumentChat",
		"ExperimentDocumentChatWithMessages",
		"ProtocolChat",
		"ProtocolChatWithMessages",
		"ExperimentDocumentSuggestedPrompts",
	],
	endpoints: (builder) => ({
		/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
		/* ~~~~ Experiment Document ~~~~~ */
		/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
		getExperimentDocumentChat: builder.query<
			ExperimentDocumentChat,
			ExperimentDocumentChatId
		>({
			query: (id) => `/chatgpt/api/experiment-document-chat/${id}/`,
			providesTags: ["ExperimentDocumentChatWithMessages"],
		}),
		listExperimentDocumentChat: builder.query<
			ExperimentDocumentChatListItem[],
			GenemodDocumentUUID
		>({
			query: (id) =>
				`/chatgpt/api/experiment-document-chat/?experiment_document=${id}`,
			providesTags: ["ExperimentDocumentChat"],
		}),
		deleteExperimentDocumentChat: builder.mutation<
			ExperimentDocumentChatId,
			ExperimentDocumentChatId
		>({
			query: (id) => ({
				url: `/chatgpt/api/experiment-document-chat/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: [
				"ExperimentDocumentChat",
				"ExperimentDocumentChatWithMessages",
			],
		}),
		sendExperimentDocumentChatPrompt: builder.mutation<
			ExperimentDocumentChat,
			{
				prompt: string;
				experiment_document?: GenemodDocumentUUID;
				id?: ExperimentDocumentChatId;
			}
		>({
			query: (data) => ({
				url: `/chatgpt/api/experiment-document-chat/send_prompt/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: [
				"ExperimentDocumentChat",
				"ExperimentDocumentChatWithMessages",
			],
		}),
		sendExperimentDocumentTitle: builder.query<
			ExperimentDocumentSuggestedPrompts,
			{
				experiment_document?: GenemodDocumentUUID;
				id?: ExperimentDocumentChatId;
			}
		>({
			query: (data) => ({
				url: `/chatgpt/api/experiment-document-chat/send_doc_title/`,
				method: "POST",
				body: data,
			}),
			providesTags: ["ExperimentDocumentSuggestedPrompts"],
		}),
		patchUsedSuggestedPrompt: builder.mutation<
			SuggestedPrompts,
			{
				id: SuggestedPromptsId;
				used_prompt: string;
			}
		>({
			query: (body) => ({
				url: `/chatgpt/api/suggested-prompts/${body.id}/`,
				method: "PATCH",
				body,
			}),
		}),

		/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
		/* ~~~~~~~~~~ Revision ~~~~~~~~~~ */
		/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

		createHighlightedTextRevisionRequest: builder.query<
			HighlightedTextRevision,
			{
				experiment_document_uuid: GenemodDocumentUUID;
				highlighted_text: string;
				revision_type: RevisionTypeValue;
			}
		>({
			query: (data) => ({
				url: `/chatgpt/api/revise-highlighted-text/request_revision/`,
				method: "POST",
				body: data,
			}),
		}),
		patchHighlightedTextRevision: builder.mutation<
			HighlightedTextRevision,
			{
				id: HighlightedTextRevision["id"];
				was_used: boolean;
			}
		>({
			query: ({ id, was_used }) => ({
				url: `/chatgpt/api/revise-highlighted-text/${id}/`,
				method: "PATCH",
				body: { was_used },
			}),
		}),
	}),
});

export const {
	useGetExperimentDocumentChatQuery,
	useListExperimentDocumentChatQuery,
	useDeleteExperimentDocumentChatMutation,
	useSendExperimentDocumentChatPromptMutation,
	useSendExperimentDocumentTitleQuery,
	usePatchUsedSuggestedPromptMutation,
	useCreateHighlightedTextRevisionRequestQuery,
	usePatchHighlightedTextRevisionMutation,
} = chatGPTApi;
