import {
	ClickToEdit,
	DropDown,
	GenemodDot,
	GenemodIcon,
	Select,
	SidePanel,
	TabList,
	Typography,
	UserAvatar,
	CommonDeleteModal,
	Notification,
	ButtonV2,
} from "@common/components";
import {
	useOrderDeleteMutation,
	useOrderPatchMutation,
	useOrderQuery,
} from "@redux/freezer/FreezerApiSlice";
import React, { useEffect, useMemo, useState } from "react";
import AttachmentsTab from "./components/AttachmentsTab/AttachmentsTab";
import DetailsTab from "./components/DetailsTab";
import ProgressTab from "./components/ProgressTab";
import styles from "./OrdersInfoPanel.module.scss";
import moment from "moment";
import classNames from "classnames";
import {
	FREEZER_ORDER_STATUSES,
	FreezerOrderStatusId,
	FreezerOrderId,
	FREEZER_ORDER_STATUSES_BY_ID,
	ITEM_ORDER_CHAR_LIMITS,
} from "@common/types";
import {
	useCanModifyOrder,
	useOrderStatusAction,
	usePossibleStatusChanges,
} from "@common/helpers/Hooks/OrdersHooks";
import { Color, ColorCssVarMap } from "@common/styles/Colors";
import StatusLabel from "../StatusLabel";
import CommentsTab from "./components/CommentsTab/CommentsTab";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ValueOf } from "@common/helpers/TypeHelpers";
import { ORDER_MODE } from "@containers/Freezer/components/OrderingWidget";
import { useNavigateToCreateOrderPage } from "@common/helpers/Hooks/useNavigateToCreateOrderPage";
import { DropdownMenuItem } from "@common/components/DropDown/dropdown";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { useCommonModalState } from "@redux/CommonModals/hooks";

const OrdersInfoPanel = () => {
	const [isDeleteOrderModalVisible, setIsOrderModalVisible] = useState(false);
	const navigate = useNavigateToCreateOrderPage();
	const {
		isOrdersInfoPanelVisible,
		ordersInfoPanelData: {
			orderId,
			idFieldFocused,
			includeGoBackButton,
			defaultTab,
			setDefaultTab,
		},
		closeOrdersInfoPanel,
	} = useCommonPanelState("ordersInfoPanel");
	const { openOrderingWidget } = useCommonPanelState("orderingWidget");
	const { openCreateRepositoryItems } = useCommonModalState(
		"createRepositoryItems"
	);
	const { openCreateRepositoryConsumables } = useCommonModalState(
		"createRepositoryConsumables"
	);
	const { data: order } = useOrderQuery(
		isOrdersInfoPanelVisible && orderId ? orderId : skipToken
	);
	const readOnly = !useCanModifyOrder()(order);
	const currentStatus = order?.status || FREEZER_ORDER_STATUSES.Requested.id;
	const [patchOrder] = useOrderPatchMutation();
	const [deleteOrder] = useOrderDeleteMutation();
	const [isFieldEditing, setIsFieldEditing] = useState(false);

	const getOrderStatusActions = useOrderStatusAction();
	const possibleNextStatuses = usePossibleStatusChanges(currentStatus);

	const groups: {
		groupName: string;
		content: ValueOf<typeof FREEZER_ORDER_STATUSES>[];
	}[] = [
		{
			groupName: "Requested",
			content: [FREEZER_ORDER_STATUSES.Requested],
		},
		{
			groupName: "Processing",
			content: [
				FREEZER_ORDER_STATUSES.Approved,
				FREEZER_ORDER_STATUSES.Ordered,
			],
		},
		{
			groupName: "Complete",
			content: [FREEZER_ORDER_STATUSES.Received],
		},
		{
			groupName: "Incomplete",
			content: [FREEZER_ORDER_STATUSES.Denied],
		},
	];

	useEffect(() => {
		if (idFieldFocused) {
			setTimeout(() => {
				setIsFieldEditing(true);
			}, 200);
		}
	}, [idFieldFocused]);

	const renderStatusAction = () => {
		if (!order) return;
		const { label, onClick, disabled } = getOrderStatusActions(
			order.status || (0 as FreezerOrderStatusId),
			order.id
		);
		return (
			<ButtonV2 type="link" onClick={onClick} disabled={disabled}>
				{label.includes("order") ? label : label + " order"}
			</ButtonV2>
		);
	};

	const items = useMemo(() => {
		const newItems = [
			{
				key: "request",
				label: "Request again",
			},
			{
				key: "archive",
				label: order?.is_archived ? "Unarchive" : "Archive",
			},
			{
				key: "delete",
				label: "Delete",
				color: "dust-red" as Color,
			},
		];

		const addToRepositoryMenu = [];

		if (order?.item || (!order?.item && !order?.consumable)) {
			addToRepositoryMenu.push({
				key: "add-to-item-repo",
				label: "Add to freezer",
			});
		}

		if (order?.consumable || (!order?.item && !order?.consumable)) {
			addToRepositoryMenu.push({
				key: "add-to-consumable-repo",
				label: "Add to consumables",
			});
		}

		newItems.splice(1, 0, ...addToRepositoryMenu);

		return newItems;
	}, [order]);

	const handleMeatballsMenuClick = (e: ClickParam) => {
		if (!order) return;
		const key = e.key;
		switch (key) {
			case "archive":
				patchOrder({
					id: order.id,
					is_archived: order.is_archived ? false : true,
				})
					.unwrap()
					.then(() => {
						Notification.success({
							message: `Order ${order.name} has been ${
								order.is_archived ? "unarchived" : "archived"
							}.`,
						});
					})
					.catch(() => {
						Notification.warning({
							message: `Failed to ${
								order.is_archived ? "unarchive" : "archive"
							} the order. Try again or contact us if it continues.`,
						});
					});
				break;
			case "add-to-item-repo":
				openCreateRepositoryItems({
					itemAmount: order.quantity,
					itemId: order.item,
					itemDetails: {
						name: order.name,
						source: order.source,
						concentration: order.concentration,
						reference: order.reference,
						catalog: order.catalog,
						price: order.price,
						currency: order.currency,
					},
				});
				break;
			case "add-to-consumable-repo":
				openCreateRepositoryConsumables({
					quantity: order.quantity,
					consumableId: order.item,
					consumableDetails: {
						name: order.name,
						source: order.source,
						reference: order.reference,
						catalog: order.catalog,
						price: order.price,
						currency: order.currency,
					},
				});
				break;
			case "delete":
				setIsOrderModalVisible(true);
				break;
			case "request":
				navigate({ mode: "create", prefill: order });
				break;
		}
	};

	const onClose = () => {
		closeOrdersInfoPanel();
		setIsFieldEditing(false);
	};
	return (
		<SidePanel
			isVisible={isOrdersInfoPanelVisible}
			hideHeader
			onClose={onClose}
			className={styles.orderInfoPanel}
			hideMask
			layerSystemLayer={2}
		>
			{includeGoBackButton && (
				<div className={styles.goBackButton}>
					<GenemodIcon name="chevron-left" stroke="link-primary" />
					<ButtonV2
						type="link"
						onClick={() => {
							openOrderingWidget({
								mode: ORDER_MODE.VIEW,
							});
						}}
					>
						Go back
					</ButtonV2>
				</div>
			)}
			<div className={styles.header}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: 12,
						flexGrow: 0,
						width: "100%",
					}}
				>
					<ClickToEdit
						className={styles.orderName}
						variant="title"
						value={order?.name || ""}
						onComplete={(e: string) => {
							if (order?.id) {
								patchOrder({
									id: order.id,
									name: e,
								});
							}
						}}
						validators={[
							{
								validator: (tempName) =>
									!(tempName.length === 0),
								error: "Must fill",
							},
						]}
						component="input"
						readOnly={readOnly}
						maxLength={ITEM_ORDER_CHAR_LIMITS.NAME}
					/>
					{order?.is_archived && (
						<Typography
							className={styles.archived}
							color="text-on-color"
							variant="smallprint"
						>
							Archived
						</Typography>
					)}
					<DropDown
						// NOTE: Once we upgrade to antdv4 4.24.0, we cannot use the overlay property
						overlay={
							<Menu>
								{items.map((item) => (
									<DropdownMenuItem
										key={item.key}
										label={item.label}
										onClick={handleMeatballsMenuClick}
										color={item.color || "none"}
									/>
								))}
							</Menu>
						}
					>
						<GenemodIcon
							name="meatballs"
							style={{ marginLeft: "auto" }}
						/>
					</DropDown>
				</div>
				<Typography
					style={{ marginTop: 8 }}
					variant="label"
					color="text-secondary-v2"
				>
					Last updated: {moment(order?.updated_at).format("LL")}
				</Typography>
				<div className={styles.info}>
					<Typography
						variant="label"
						semibold
						color="text-primary-v2"
					>
						Order no.
					</Typography>
					<ClickToEdit
						inputProps={{ className: styles.customInput }}
						edit={isFieldEditing}
						value={order?.order_number.toString() || ""}
						placeholder={readOnly ? "-" : "Click to add"}
						onComplete={(val) => {
							if (!order) return;
							patchOrder({
								id: order.id,
								order_number: val,
							});
							setIsFieldEditing(false);
						}}
						component="input"
						onBlur={() => setIsFieldEditing(false)}
						onClick={() => setIsFieldEditing(true)}
						readOnly={order?.is_archived}
						maxLength={ITEM_ORDER_CHAR_LIMITS.ORDER_NUMBER}
					/>
					<Typography
						variant="label"
						semibold
						color="text-primary-v2"
					>
						Status
					</Typography>
					{!order?.is_archived ? (
						<div className={styles.statusSelectWrapper}>
							<Select
								suffixIcon={
									<GenemodIcon
										style={{ transform: "rotate(90deg)" }}
										name="chevron-right"
									/>
								}
								className={classNames(
									styles.statusSelect,
									styles[
										FREEZER_ORDER_STATUSES_BY_ID[
											order?.status ||
												FREEZER_ORDER_STATUSES.Requested
													.id
										].label.toLowerCase()
									]
								)}
								dropdownClassName={styles.dropdown}
								value={currentStatus}
								optionLabelProp="label"
								onChange={(val) => {
									if (!order) return;
									patchOrder({
										id: order.id,
										status: val as FreezerOrderStatusId,
									});
								}}
							>
								{groups.map((group, index) => {
									return (
										<Select.OptGroup
											key={index}
											label={
												<Typography variant="caption">
													{group.groupName}
												</Typography>
											}
										>
											{group.content.map(
												({ id, color, label }) => {
													const isCurrentStatus =
														currentStatus === id;
													const disabled =
														!isCurrentStatus &&
														!possibleNextStatuses.includes(
															id
														);
													return (
														<Select.Option
															key={id}
															value={id}
															label={label}
															disabled={disabled}
														>
															<div
																className={
																	styles.option
																}
															>
																<div
																	className={
																		styles.optionLabel
																	}
																>
																	<div
																		style={{
																			opacity:
																				disabled
																					? 0.2
																					: 1,
																		}}
																	>
																		<GenemodDot
																			size={
																				12
																			}
																			color={
																				ColorCssVarMap[
																					color
																				]
																			}
																		/>
																	</div>
																	<Typography
																		color={
																			disabled
																				? "text-tertiary"
																				: "text-primary"
																		}
																	>
																		{label}
																	</Typography>
																</div>
																{isCurrentStatus && (
																	<GenemodIcon name="dropdown-check" />
																)}
															</div>
														</Select.Option>
													);
												}
											)}
										</Select.OptGroup>
									);
								})}
							</Select>
							{renderStatusAction()}
						</div>
					) : (
						<StatusLabel
							status={
								order?.status || (1 as FreezerOrderStatusId)
							}
							style={{ width: "69px" }}
						/>
					)}
					<Typography
						variant="label"
						semibold
						color="text-primary-v2"
					>
						Submitted by
					</Typography>
					<div style={{ overflow: "hidden" }}>
						<UserAvatar user={order?.submitted_by} displayName />
					</div>
				</div>
			</div>
			<TabList
				gradient={false}
				defaultActiveKey={defaultTab}
				className={styles.orderInfoTabList}
				onChange={(tab) => {
					setDefaultTab?.(tab);
				}}
				tabListItems={[
					{
						key: 1,
						tabtitle: <Typography>Details</Typography>,
						tabcontent: <DetailsTab order={order} />,
					},
					{
						key: 2,
						tabtitle: <Typography>Progress</Typography>,
						tabcontent: (
							<ProgressTab
								orderIdNum={order?.id}
								is_archived={order?.is_archived}
							/>
						),
					},
					{
						key: 3,
						tabtitle: <Typography>Comments</Typography>,
						tabcontent: (
							<CommentsTab orderId={orderId as FreezerOrderId} />
						),
					},
					{
						key: 4,
						tabtitle: <Typography>Attachments</Typography>,
						tabcontent: <AttachmentsTab order={order} />,
					},
				]}
			/>
			<CommonDeleteModal
				visible={isDeleteOrderModalVisible}
				titleObject="order"
				bodyObject="this order"
				onOk={() => {
					if (!order) return;
					deleteOrder(order.id)
						.unwrap()
						.then(() => {
							Notification.success({
								message: `Order ${order.name} has been deleted.`,
							});
						})
						.catch(() => {
							Notification.warning({
								message:
									"Failed to delete the order. Try again or contact us if it continues.",
							});
						});
					setIsOrderModalVisible(false);
					closeOrdersInfoPanel();
				}}
				onCancel={() => setIsOrderModalVisible(false)}
			/>
		</SidePanel>
	);
};

export default OrdersInfoPanel;
