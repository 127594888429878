import React, { useState, useEffect } from "react";
// Custom hook for input validation
// Props
// value: string
// validators: [{ validator: (value) => boolean, error: string }]
// onValidityChange: (validity, error) =>; Called when validity changes
// Returns: [isValid: boolean, error: string]
export const useInputValidation = (
	value,
	validators,
	onValidityChange,
	extraDependencies = []
) => {
	const [isValid, setValidity] = useState(true);
	const [error, setError] = useState("");

	useEffect(() => {
		if (validators) {
			let error = "";
			let validity = validators.reduce((validity, validator) => {
				if (!validity) {
					// If false, always return false
					return false;
				} else {
					let temp = validator.validator(value);
					if (!temp) {
						setError(validator.error);
						error = validator.error;
					}
					return temp;
				}
			}, true);
			setValidity(validity);
			if (validity !== isValid && onValidityChange) {
				onValidityChange(validity, error);
			}
			if (validity) setError("");
		}
	}, [value, ...extraDependencies]);
	return [isValid, error];
};
