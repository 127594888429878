import "react-hot-loader";
import "antd/dist/antd.css";
import "antdv4/lib/skeleton/style/css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App/App";
import "./index.scss";
import "./tailwind.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// this is a test
serviceWorkerRegistration.register();
