import { CustomField, CustomFields } from "@common/types";

export const convertCustomFieldsToArray = (
	customFields: CustomFields | undefined
): { key: string; value: CustomField }[] => {
	const customFieldsArray = [];
	for (const key in customFields) {
		customFieldsArray.push({ key, value: customFields[key] });
	}
	return customFieldsArray;
};
