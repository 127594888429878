import {
	IMAGE_ACCEPTED_FILES,
	VIDEO_ACCEPTED_FILES,
} from "@common/types/FileAttachment";
import DefaultIcon from "./assets/DefaultIcon";
import ExcelIcon from "./assets/ExcelIcon";
import ExperimentIcon from "./assets/ExperimentIcon";
import ImageIcon from "./assets/ImageIcon";
import MiniDefaultIcon from "./assets/MiniDefaultIcon";
import MiniExperimentIcon from "./assets/MiniExperimentIcon";
import MiniImageIcon from "./assets/MiniImageIcon";
import MiniPdfIcon from "./assets/MiniPdfIcon";
import MiniTxtIcon from "./assets/MiniTxtIcon";
import MiniVideoIcon from "./assets/MiniVideoIcon";
import MiniXpsIcon from "./assets/MiniXpsIcon";
import PdfIcon from "./assets/PdfIcon";
import PowerPointIcon from "./assets/PowerPointIcon";
import TxtIcon from "./assets/TxtIcon";
import VideoIcon from "./assets/VideoIcon";
import WORD_ICON from "./assets/WordIcon";
import XpsIcon from "./assets/XpsIcon";

/**
 * Maps list of file extensions to file icons and mini file icons
 * Eg: mapFilesToIcons([doc, txt], DocIcon, MiniDocIcon)
 * {
 * 		doc: DocIcon,
 * 		doc_sm: MiniDocIcon,
 * 		txt: DocIcon,
 * 		txt_sm: MiniDocIcon,
 * }
 */
const mapFilesToIcons = <Files extends readonly string[], Icon>(
	files: Files,
	icon: Icon,
	miniIcon: Icon
) =>
	files.reduce(
		(obj, extension) =>
			({
				...obj,
				[extension]: icon,
				[`${extension}_sm`]: miniIcon,
			} as const),
		{} as Record<Files[number], Icon> & Record<`${Files[number]}_sm`, Icon>
	);

export const FILE_ICON_MAPPING = Object.freeze({
	default: DefaultIcon,
	gmd: ExperimentIcon,
	doc: WORD_ICON,
	docx: WORD_ICON,
	pdf: PdfIcon,
	ppt: PowerPointIcon,
	pptx: PowerPointIcon,
	txt: TxtIcon,
	xls: ExcelIcon,
	xlsx: ExcelIcon,
	csv: ExcelIcon,
	xps: XpsIcon,
	default_sm: MiniDefaultIcon,
	gmd_sm: MiniExperimentIcon,
	doc_sm: WORD_ICON,
	docx_sm: WORD_ICON,
	pdf_sm: MiniPdfIcon,
	ppt_sm: PowerPointIcon,
	pptx_sm: PowerPointIcon,
	txt_sm: MiniTxtIcon,
	xls_sm: ExcelIcon,
	xlsx_sm: ExcelIcon,
	csv_sm: ExcelIcon,
	xps_sm: MiniXpsIcon,
	image: ImageIcon,
	image_sm: MiniImageIcon,
	video: VideoIcon,
	video_sm: MiniVideoIcon,
	...mapFilesToIcons(IMAGE_ACCEPTED_FILES, ImageIcon, MiniImageIcon),
	...mapFilesToIcons(VIDEO_ACCEPTED_FILES, VideoIcon, MiniVideoIcon),
} as const);

export type FileIconKey = keyof typeof FILE_ICON_MAPPING;

/**
 * Returns a file icon component for a given file type string.
 */
export const getFileTypeIcon = (fileType: string) =>
	FILE_ICON_MAPPING[fileType as FileIconKey] ||
	(fileType.endsWith("_sm")
		? FILE_ICON_MAPPING.default_sm
		: FILE_ICON_MAPPING.default);
