import { Slice, combineReducers } from "@reduxjs/toolkit";
import { mapToKeyValue, CombineUnion } from "@common/helpers/TypeHelpers";

/**
 * Takes in an array of slices and combines the reducers with rtk's "combineReducers" function
 * and also puts all the actions into one object so it is cleaner to export them all.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function combineSlices<T extends Readonly<Slice[]>>(slices: T) {
	const namesToSlices = mapToKeyValue(slices, "name");

	type NamesToSlicesType = typeof namesToSlices;

	const reducers = Object.entries(namesToSlices).reduce(
		(obj, [name, slice]) => ({
			...obj,
			[name]: (slice as any).reducer,
		}),
		{} as {
			[name in keyof NamesToSlicesType]: NamesToSlicesType[name]["reducer"];
		}
	);

	const allActions = slices.reduce(
		(actions, slice) => ({
			...actions,
			...slice.actions,
		}),
		{} as T[number]["actions"]
	);
	const actions = allActions as CombineUnion<typeof allActions>;
	return {
		reducers: combineReducers<typeof reducers>(reducers),
		actions,
	} as const;
}
