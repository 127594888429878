import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import { CommonDeleteModal, Modal, Notification } from "@components";
import {
	getBoxTableState,
	useBoxTableActions,
	useBoxTableFields,
} from "@redux/freezer/BoxTableSlice";
import React, { useEffect, useState } from "react";
import {
	areaBoundsIncludesCellId,
	getAreaBounds,
	getAreaDimensions,
	getCellId,
	getCellIdsForArea,
	parseCellId,
	sortByCellId,
} from "../../../data";
import {
	getClipboardManager,
	useBoxView,
	useCheckOverlap,
	useCopySelected,
	usePasteItems,
	useShowItemLimitNotification,
} from "../../BoxTableHooks";
import styles from "./SelectedCellActionModal.module.scss";
import { Item, ItemLocation, MoveItem } from "@common/types";
import { useDebounce } from "@helpers/Hooks";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import {
	useBulkItemsDeleteMutation,
	useItemDeleteMutation,
} from "@redux/inventory/Item";
import classNames from "classnames";

export const useOpenDeleteSelectedBoxTableCellsModal = () => {
	const { openDeleteSelectedBoxTableCellsModal } = useCommonModalState(
		"deleteSelectedBoxTableCellsModal"
	);
	const { setIsFocused } = useBoxTableActions();
	const { items } = useBoxView();

	return () => {
		setIsFocused(true);
		const { isFocused, selectedAreaBounds } = getBoxTableState();
		const toDelete = items
			.filter((x: Item) =>
				selectedAreaBounds.find((bounds) =>
					areaBoundsIncludesCellId(bounds, getCellId(x))
				)
			)
			.map((item) => item.id);
		if (!isFocused || !toDelete.length) return;
		setIsFocused(false);
		openDeleteSelectedBoxTableCellsModal({ toDelete });
	};
};

export const DeleteSelectedBoxTableCellsModal = React.memo(
	function DeleteSelectedBoxTableCellsModal() {
		const {
			isDeleteSelectedBoxTableCellsModalVisible,
			closeDeleteSelectedBoxTableCellsModal,
			deleteSelectedBoxTableCellsModalData: { toDelete = [] },
		} = useCommonModalState("deleteSelectedBoxTableCellsModal");

		const { box } = useBoxView();
		const [deleteBulkItems] = useBulkItemsDeleteMutation();
		const [deleteItem] = useItemDeleteMutation();

		const handleDeleteSuccess = () => {
			closeDeleteSelectedBoxTableCellsModal();
			SegmentTrackEvent(SegmentFreezerEvents.ITEM_DELETE, {});
			Notification.success({
				message: `${toDelete.length} item(s) deleted.`,
			});
		};

		const deleteCells = () => {
			if (!box) {
				Notification.warning({
					message:
						"Could not delete cell(s). Please refresh the page and try again.",
				});
				return;
			}
			if (toDelete.length === 1) {
				deleteItem(toDelete[0])
					.unwrap()
					.then(() => {
						handleDeleteSuccess();
					});
			} else {
				deleteBulkItems({ ids: toDelete })
					.unwrap()
					.then(() => {
						handleDeleteSuccess();
					});
			}
		};

		// Debounce toDelete.length to prevent modal content from flickering while it closes
		const toDeleteCount = useDebounce(toDelete.length, 200);

		const itemsMsg = `${toDeleteCount} ${
			toDeleteCount === 1 ? "item" : "items"
		}`;

		return (
			<CommonDeleteModal
				visible={isDeleteSelectedBoxTableCellsModalVisible}
				titleObject={itemsMsg}
				bodyObject={`${itemsMsg} and associated activity data`}
				onOk={deleteCells}
				onCancel={closeDeleteSelectedBoxTableCellsModal}
				dataCy="delete-items-modal"
			/>
		);
	},
	() => true
);

export const PasteSelectedBoxTableCellsModal = React.memo(
	function PasteSelectedBoxTableCellsModal() {
		const {
			isPasteSelectedBoxTableCellsModalVisible,
			closePasteSelectedBoxTableCellsModal,
			pasteSelectedBoxTableCellsModalData: { focusModeOnly },
		} = useCommonModalState("pasteSelectedBoxTableCellsModal");

		const { setSelectedAreaBounds, setIsFocused } = useBoxTableActions();

		const { box, items } = useBoxView();

		const { getClipboard, setClipboard } = getClipboardManager();

		const showItemLimitNotification = useShowItemLimitNotification();

		const checkOverlap = useCheckOverlap();

		const pasteItems = usePasteItems();
		const { isFocused, selectedAreaBounds, cutMode } = getBoxTableState();
		const { cells: clipboard, items: clipboardItems } = getClipboard();

		const isMultiSelect = selectedAreaBounds.length > 1;
		const bounds = selectedAreaBounds[0];
		const selectedCells = getCellIdsForArea(bounds);

		const disablePaste =
			!box ||
			!selectedCells.length ||
			!clipboard.length ||
			(focusModeOnly && !isFocused) ||
			isMultiSelect;

		const pasteCells = () => {
			if (disablePaste) return;

			// Paste cells here
			const dims = getAreaDimensions(clipboard);
			const source = sortByCellId([...selectedCells])[0];
			let targetCells = sortByCellId(
				getCellIdsForArea({
					...dims,
					source,
				})
			).filter((cell) => {
				// Filter out cells that would be pasted beyond the table dimensions
				const { row, column } = parseCellId(cell);
				return row < box.rows && column < box.columns;
			});

			if (clipboard.length === 1) {
				targetCells = [...selectedCells];
			}

			const toDelete: number[] = [];
			const toCreate: Item[] = [];
			const toCopy: MoveItem[] = [];
			targetCells.forEach((targetCellId) => {
				const targetDims = getAreaDimensions([
					targetCellId,
					targetCells[0],
				]);
				let srcID = sortByCellId(
					getCellIdsForArea({
						...targetDims,
						source: dims.source,
					})
				).pop();

				if (clipboard.length === 1) {
					srcID = clipboard[0];
				}

				const srcCell = srcID && clipboardItems[srcID];
				const targetCell = items.filter(
					(item: Item) => getCellId(item) === targetCellId
				);

				if (srcCell) {
					const rowCol = parseCellId(targetCellId);
					// if src exists, copy over, patch for cut mode
					const copy =
						cutMode || clipboard.length
							? {
									...srcCell,
									id: srcCell.id,
									location: {
										...srcCell.location,
										box_location: {
											...(srcCell.location
												?.box_location || {}),
											row: rowCol.row,
											column: rowCol.column,
											box: box.id,
										},
									},
							  }
							: {
									...srcCell,
									id: -1,
									location: {
										box_location: {
											box: box.id,
											row: rowCol.row,
											column: rowCol.column,
										},
									},
							  };

					// if target exists, copy the id
					if (targetCell.length) {
						toDelete.push(targetCell[0].id);
					}
					if (
						cutMode &&
						copy.location.box_location &&
						srcCell.location?.box_location
					) {
						toCopy.push({
							id: copy.id,
							name: copy.name,
							new_location: copy.location.box_location,
							old_location: srcCell.location.box_location,
							location: copy.location as ItemLocation,
						});
					} else {
						toCreate.push(copy as Item);
					}
				} else if (targetCell.length) {
					// If only target cell, then delete
					toDelete.push(targetCell[0].id);
				}
			});

			if (showItemLimitNotification() === "SHOWN") {
				return;
			}

			const callback = () => {
				setSelectedAreaBounds([getAreaBounds(targetCells)]);
				if (cutMode) {
					setClipboard([], {});
				}
				SegmentTrackEvent(SegmentFreezerEvents.ITEM_PASTE, {});
			};
			pasteItems(box.id, toDelete, toCreate, toCopy, callback, true);
			closePasteSelectedBoxTableCellsModal();
		};

		const src = sortByCellId([...selectedCells])[0];
		const isOverlapping = src && checkOverlap(clipboard, src);

		useEffect(() => {
			if (isPasteSelectedBoxTableCellsModalVisible && !isOverlapping) {
				pasteCells();
			} else if (isPasteSelectedBoxTableCellsModalVisible && isFocused) {
				setIsFocused(false);
			}
		}, [isOverlapping, isPasteSelectedBoxTableCellsModalVisible]);

		useEffect(() => {
			if (isPasteSelectedBoxTableCellsModalVisible && isMultiSelect) {
				Notification.warning({
					message: `Invalid action. Cannot paste over multiple selections.`,
				});
			}
		}, [isPasteSelectedBoxTableCellsModalVisible, isMultiSelect]);

		const showModal =
			isPasteSelectedBoxTableCellsModalVisible &&
			isOverlapping &&
			!disablePaste;

		return (
			<Modal
				destroyOnClose
				title="Replace items?"
				visible={!!showModal}
				okText="Replace"
				onCancel={closePasteSelectedBoxTableCellsModal}
				hideCancelButton
				okButtonProps={{ onClick: pasteCells }}
			>
				Pasting here will replace existing items. Would you like to
				continue?
			</Modal>
		);
	},
	() => true
);

export const PasteBoxCellsFromDragModal = React.memo(
	function PasteBoxCellsFromDragModal() {
		const {
			isPasteBoxCellsFromDragModalVisible,
			closePasteBoxCellsFromDragModal,
			pasteBoxCellsFromDragModalData: { preDragBounds = null },
		} = useCommonModalState("pasteBoxCellsFromDragModal");

		const { box, items } = useBoxView();
		const copySelected = useCopySelected();
		const pasteItems = usePasteItems();
		const { selectedAreaBounds } = useBoxTableFields("selectedAreaBounds");
		const selectedBounds = selectedAreaBounds[0];

		const [modalMessage, setModalMessage] = React.useState("");

		const handleDragHandlePaste = async () => {
			if (!box || !preDragBounds || !selectedAreaBounds) {
				closePasteBoxCellsFromDragModal();
				return;
			}
			const preDragSelection = getCellIdsForArea(preDragBounds);
			const cells = getCellIdsForArea(selectedBounds);
			let toCheck = sortByCellId(
				cells.filter((x) => !preDragSelection.includes(x))
			);
			let src = sortByCellId([...preDragSelection]);

			const horizontal = !toCheck
				.map((x) => x[0])
				.some((x) => src.map((y) => y[0]).includes(x));
			toCheck = sortByCellId(toCheck, !horizontal);
			src = sortByCellId(src, !horizontal);

			let index = 0;
			const toDelete: number[] = [];
			const toCreate: Item[] = [];
			let replaceItems = false;

			toCheck.forEach((cellID, i) => {
				const srcID = src[index++];
				const srcCell = items.filter(
					(x: Item) => getCellId(x) === srcID
				);
				const targetCell = items.filter(
					(x: Item) => getCellId(x) === cellID
				);

				if (srcCell.length) {
					const { row, column } = parseCellId(cellID);
					const _copy = {
						...srcCell[0],
						id: -1,
					};
					const { location, ...copy } = _copy as any;
					copy.location = {
						box_location: {
							row,
							column,
							box: location.box_location.box,
						},
						item_group: null,
					};

					if (targetCell.length) {
						copy.id = targetCell[0].id;
						toDelete.push(targetCell[0].id);
						replaceItems = true;
					}

					toCreate.push(copy);
				} else if (targetCell.length) {
					toDelete.push(targetCell[0].id);
					replaceItems = true;
				}

				if (index === src.length) {
					index = 0;
				}
			});

			pasteItems(box.id, toDelete, toCreate);
			closePasteBoxCellsFromDragModal();
		};

		const overlap = (() => {
			if (!selectedBounds || !preDragBounds) return false;

			return !!items
				.map((item) => getCellId(item, box?.axis_direction))
				.find(
					(cellId) =>
						areaBoundsIncludesCellId(selectedBounds, cellId) &&
						!areaBoundsIncludesCellId(preDragBounds, cellId)
				);
		})();

		useEffect(() => {
			if (!isPasteBoxCellsFromDragModalVisible) return;
			copySelected({ cutNotCopy: false });

			const preDragSelection = getCellIdsForArea(preDragBounds);
			const cells = getCellIdsForArea(selectedBounds);
			const toCheck = sortByCellId(
				cells.filter((x) => !preDragSelection.includes(x))
			);

			const replaceItems = toCheck.some((cellID) => {
				const targetCell = items.filter(
					(x: Item) => getCellId(x) === cellID
				);
				return targetCell.length > 0;
			});

			const newItems = toCheck.some((cellID) => {
				const targetCell = items.filter(
					(x: Item) => getCellId(x) === cellID
				);
				return targetCell.length === 0;
			});

			if (replaceItems && newItems) {
				setModalMessage(
					"Pasting here will create new items and replace existing items. Would you like to continue?"
				);
			} else if (replaceItems) {
				setModalMessage(
					"Pasting here will replace existing items. Would you like to continue?"
				);
			} else if (newItems) {
				setModalMessage(
					"Pasting here will create new items. Would you like to continue?"
				);
			}

			if (!replaceItems && newItems) {
				handleDragHandlePaste();
				closePasteBoxCellsFromDragModal();
			}
		}, [isPasteBoxCellsFromDragModalVisible]);

		const modalVisible = isPasteBoxCellsFromDragModalVisible && overlap;

		return (
			<Modal
				destroyOnClose
				title="Replace items?"
				visible={modalVisible}
				okText="Continue"
				onCancel={closePasteBoxCellsFromDragModal}
				cancelButtonProps={{
					style: { display: "none" },
				}}
				okButtonProps={{
					onClick: () => handleDragHandlePaste(),
				}}
				wrapClassName={classNames({
					[styles.closed]: !modalVisible,
				})}
			>
				{modalMessage}
			</Modal>
		);
	},
	() => true
);
