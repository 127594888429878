/**
 * Stripe object types aren't included with stripejs or types/stripejs.
 * **Not all properties are included. See documentation for full list of properties**
 */
import { deprecated_getConfig } from "@common/config/GenemodConfig";
import { formatCost } from "@helpers/Formatters";
import { PaymentIntent, PaymentMethod } from "@stripe/stripe-js";

export const StripeKeys = deprecated_getConfig("STRIPE_KEYS");

type Duration = "once" | "repeating" | "forever";
type Interval = "day" | "week" | "month" | "year";
type Metadata = Record<string, string>;
/** Time measured in **seconds** since the Unix epoch */
type Timestamp = number;

/**
 * Info required for previewing price changes
 */
export type InvoiceChangePreviewData = {
	/** ID of the Stripe price object to subscribe to */
	price?: string;
	/** Coupon id if applicable */
	promotion_code?: string;
	/** Seat number if we're changing seats, leave this undefined if just changing plan */
	quantity?: number;
	free_trial: boolean;
};

/**
 * Info required for upgrading plans
 * */
export type UpgradeSubscriptionData = InvoiceChangePreviewData & {
	/** ID of the user's payment method. Comes from `stripe.createPaymentMethod` */
	payment_method: string;
};

type CommonStripeProperties = {
	id: string;
	object: string;
	created: Timestamp;
};

type TaxRate = CommonStripeProperties & {
	active: boolean;
	country: string;
	description: string;
	display_name: string;
	inclusive: boolean;
	jurisdiction: string;
	livemode: boolean;
	metadata: Metadata;
	percentage: number;
	state: string;
};

type BillingThresholds = {
	amount_gte: number;
	reset_billing_cycle_anchor: boolean;
};

export type Address = {
	city: string;
	country: string;
	line1: string;
	line2: string;
	postal_code: string;
	state: string;
};

export type Customer = CommonStripeProperties & {
	id: number;
	address?: Address;
	balance: number;
	currency: string;
	default_source: string | PaymentMethod;
	delinquent: boolean;
	description?: string;
	discount?: Discount;
	email?: string;
	invoice_prefix: string;
	invoice_settings: {
		custom_fields: Array<{ name: string; value: string }>;
		default_payment_method?: PaymentMethod;
		footer: string;
	};
	livemode: boolean;
	metadata: Metadata;
	name?: string;
	next_invoice_sequence: number;
	phone?: string;
	preferred_locales: Array<string>;
	subscriptions: {
		object: "list";
		data: Array<Subscription>;
		has_more: boolean;
		url: string;
	};
	shipping: {
		address: Address;
		name: string;
		phone: string;
	};
	tax_exempt: string;
};

export type Product = CommonStripeProperties & {
	active: boolean;
	description?: string;
	images: Array<string>;
	livemode: boolean;
	metadata: Metadata;
	name: PlanType;
	package_dimensions: {
		height: number;
		length: number;
		weight: number;
		width: number;
	};
	shippable?: boolean;
	statement_description: string;
	unit_label: string;
	updated: Timestamp;
	url?: string;
};

export type Price = CommonStripeProperties & {
	active: boolean;
	billing_scheme: "per_unit" | "tiered";
	currency: string;
	livemode: boolean;
	lookup_key?: string;
	metadata: Metadata;
	nickname?: string;
	product: Product;
	recurring?: {
		aggregate_usage: string;
		interval: Interval;
		interval_count: number;
		usage_type: string;
	};
	tiers_mode?: "graduated" | "volume";
	transform_quantity?: {
		divide_by: number;
		round: "up" | "down";
	};
	type: "one_time" | "recurring";
	unit_amount: number;
	unit_amount_decimal: string;
	discount_amounts?: { amount: number }[];
};

export type Invoice = CommonStripeProperties & {
	account_country: string;
	account_name: string;
	account_tax_ids: Array<string>;
	amount_due: number;
	amount_paid: number;
	amount_remaining: number;
	application_fee_amount?: number;
	attempt_count: number;
	attempted: boolean;
	auto_advance: boolean;
	billing_reason: string;
	charge?: string;
	collection_method: "charge_automatically" | "send_invoice";
	currency: string;
	custom_fields: Array<{
		name: string;
		value: string;
	}>;
	customer: string | Customer;
	customer_address?: Address;
	customer_email?: string;
	customer_name?: string;
	customer_phone?: string;
	customer_shipping?: {
		address: Address;
		name: string;
		phone: string;
	};
	customer_tax_exempt: string;
	customer_tax_ids: Array<Record<string, any>>;
	default_payment_method?: string | PaymentMethod;
	default_source?: string | PaymentMethod;
	default_tax_rates: Array<Record<string, any>>;
	description?: string;
	discount?: Discount;
	discounts: Array<string | Discount>;
	due_date?: Timestamp;
	ending_balance?: number;
	footer?: string;
	hosted_invoice_url?: string;
	invoice_pdf?: string;
	last_finalization_error: {
		code: string;
		doc_url: string;
		message: string;
		param: string;
		payment_method_type: string;
		type: string;
	};
	lines: {
		object: "list";
		data: Array<LineItem>;
		has_more: boolean;
		url: string;
	};
	livemode: boolean;
	metadata: Metadata;
	next_payment_attempt?: Timestamp;
	number: string;
	paid: boolean;
	payment_intent: string | PaymentIntent;
	payment_settings: {
		payment_method_options?: Record<string, any>;
		payment_method_types: Array<string>;
	};
	period_end?: Timestamp;
	period_start?: Timestamp;
	post_payment_credit_notes_amount: number;
	pre_payment_credit_notes_amount: number;
	receipt_number: string;
	starting_balance: number;
	statement_description?: string;
	status: "draft" | "open" | "paid" | "uncollectible" | "void";
	status_transitions: {
		finalized_at?: Timestamp;
		marked_uncollectible_at?: Timestamp;
		paid_at?: Timestamp;
		voided_at?: Timestamp;
	};
	subscription: string | Subscription;
	subtotal: number;
	total: number;
	total_discount_amounts: Array<{
		amount: number;
		discount: string | Discount;
	}>;
	webhooks_delivered_at: Timestamp;
};

export type InvoiceResponse = {
	invoices: any;
	total_pages: number;
};

type SubscriptionItem = CommonStripeProperties & {
	billing_thresholds?: BillingThresholds;
	metadata: Metadata;
	price: Price;
	quantity: number;
	subscription: string;
	tax_rates: Array<TaxRate>;
};

type LineItem = {
	type: "subscription" | "invoiceitem";
	amount: number;
	description: string;
	price: Price;
	plan: Plan;
	quantity: number;
};

export type Subscription = CommonStripeProperties & {
	application_fee_percent?: number;
	billing_cycle_anchor: Timestamp;
	billing_thresholds?: BillingThresholds;
	cancel_at?: Timestamp;
	cancel_at_period_end: boolean;
	canceled_at?: Timestamp;
	collection_method: string;
	current_period_start: Timestamp;
	current_period_end: Timestamp;
	customer: string | Customer;
	days_until_due?: number;
	default_payment_method?: string | PaymentMethod;
	default_source?: string | PaymentMethod;
	default_tax_rates?: Array<TaxRate>;
	discount?: Discount;
	ended_at?: Timestamp;
	items: {
		object: "list";
		has_more: boolean;
		url: string;
		data: Array<SubscriptionItem>;
	};
	latest_invoice: string | Invoice;
	livemode: boolean;
	metadata: Metadata;
	next_pending_invoice_item_invoice?: Timestamp;
	pause_collection?: {
		behavior: string;
		resumes_at: Timestamp;
	};
	pending_invoice_item_interval?: {
		interval: Interval;
		interval_count: number;
	};
	pending_setup_intent?: string;
	pending_update?: {
		billing_cycle_anchor: Timestamp;
		expires_at: Timestamp;
		subscriptions_items: Array<SubscriptionItem>;
		trial_end: Timestamp;
		trial_from_plan: boolean;
	};
	plan: Plan;
	quantity?: number;
	schedule: string;
	start_date: Timestamp;
	status: SubscriptionStatus;
	transfer_data?: {
		amount_percent: number;
		destination: string;
	};
	trial_end?: Timestamp;
	trial_start?: Timestamp;
};

export enum SubscriptionStatus {
	incomplete = "incomplete",
	incomplete_expired = "incomplete_expired",
	trialing = "trialing",
	active = "active",
	past_due = "past_due",
	canceled = "canceled",
	unpaid = "unpaid",
}

export type Discount = {
	id: string;
	object: string;
	checkout_session: string;
	coupon: Coupon;
	customer: string | Customer;
	end: Timestamp;
	invoice: string;
	invoice_item: string;
	promotion_code: string | PromotionCode;
	start: Timestamp;
	subscription: string;
};

export type PromotionCode = CommonStripeProperties & {
	active: boolean;
	code: string;
	coupon: Coupon;
	customer: string | Customer;
	expires_at: Timestamp;
	livemode: boolean;
	max_redemptions: number;
	metadata: Metadata;
	restrictions: {
		first_time_transaction: boolean;
		minimum_amount: number;
		minimum_amount_currency: string;
	};
	times_redeemed: number;
};

export type Coupon = CommonStripeProperties & {
	amount_off: number;
	currency: string;
	duration: Duration;
	duration_in_months: number;
	livemode: boolean;
	max_redemptions: number;
	metadata: Metadata;
	name: string;
	percent_off: number;
	redeem_by: Timestamp;
	times_redeemed: number;
	valid: boolean;
};

export type Plan = CommonStripeProperties & {
	active: boolean;
	aggregate_usage?: string;
	amount: number;
	amount_decimal: string;
	billing_scheme: "per_unit" | "tiered";
	currency: string;
	interval: Interval;
	livemode: boolean;
	metadata?: Metadata;
	nickname?: string;
	product: Product;
	tiers_mode?: string;
	transform_usage?: {
		divide_by: number;
		round: "up" | "down";
	};
	trial_period_days?: number;
	usage_type?: "metered" | "licensed";
};

export type SubscriptionSchedule = {
	phases: {
		plans: {
			plan: PriceId;
			quantity: number;
		}[];
	}[];
}; // todo

export interface InvoiceChangePreview {
	total: number;
	subtotal: number;
	futureBill: number;
	unitAmount: number;
	seats: number;
	lines: { label: string; value: number; isDiscount: boolean }[];
	type: "NEW" | "UPGRADE" | "DOWNGRADE" | "UNCHANGED";
}

export type PlanType = "Free" | "Team" | "Business" | "Enterprise";

const TeamMonthlyName = "Team (monthly)";
const TeamAnnualName = "Team (annual)";
const BusinessAnnualName = "Business (annual)";

export type PriceId = string;
export type ProductId = string;
type PriceMappings = Record<
	PriceId,
	{
		productId: ProductId;
		name: string;
		productName: string;
		interval: Interval;
		minSeats: number;
	}
>;

const PRICE_MAPPINGS: PriceMappings = {
	[StripeKeys.prices.teamMonthly]: {
		productId: StripeKeys.products.team,
		name: TeamMonthlyName,
		productName: "Team",
		interval: "month",
		minSeats: 3,
	},
	[StripeKeys.prices.teamAnnual]: {
		productId: StripeKeys.products.team,
		name: TeamAnnualName,
		productName: "Team",
		interval: "year",
		minSeats: 3,
	},
	[StripeKeys.prices.businessAnnual]: {
		productId: StripeKeys.products.business,
		name: BusinessAnnualName,
		productName: "Business",
		interval: "year",
		minSeats: 5,
	},
} as const;

export function getPriceMapping(
	priceId: PriceId
): PriceMappings[PriceId] | null {
	return PRICE_MAPPINGS[priceId] || null;
}

/**
 * Enum corresponding to the "license" column for a custom org user
 */
export enum License {
	NONE = 0,
	FREE_TRIAL,
	FULL,
}

export type Licensing = {
	license: License;
	days_left?: number;
};

export type InvoicePreview = {
	upcoming_invoice: Invoice;
	upcoming_subscription:
		| undefined
		| {
				object: "list";
				data: Array<SubscriptionSchedule>;
				has_more: boolean;
				url: string;
		  };
};

export type DowngradeScheduleDisplay = {
	priceId: PriceId;
	quantity: number;
	amountDue: number;
};

/**
 * Process InvoicePreview raw data into downgrade display data, extend as needed
 */
export const invoicePreviewToDowngradeScheduleDisplay = (
	invoicePreview: InvoicePreview | null | undefined | "NO_UPCOMING_INVOICE"
): DowngradeScheduleDisplay | null | undefined => {
	if (
		!invoicePreview ||
		invoicePreview === "NO_UPCOMING_INVOICE" ||
		!invoicePreview?.upcoming_subscription
	) {
		return null;
	}
	const newPlan =
		invoicePreview?.upcoming_subscription?.data?.[0]?.phases?.[0]
			?.plans?.[0];
	return {
		priceId: newPlan?.plan,
		quantity: newPlan?.quantity,
		amountDue: invoicePreview?.upcoming_invoice?.amount_due,
	};
};

/**
 * Do the subscription and downgrade schedule arguments have the same assigned plan, based on price ID
 */
export const isSamePlan = (
	subscription: Subscription | null | undefined,
	invoicePreview: InvoicePreview | "NO_UPCOMING_INVOICE" | null | undefined
): boolean => {
	const noUpcoming =
		!invoicePreview || invoicePreview === "NO_UPCOMING_INVOICE";
	if (!subscription && noUpcoming) {
		return true;
	}
	if (!subscription || noUpcoming) {
		return false;
	}
	// Note that the below plan (price) ids may be deprecated in stripe. If the subscription price
	// is deprecated that I think the invoice preview will also be, so we won't correct it here.
	const currentPriceId = subscription.plan?.id;
	return (
		currentPriceId === getUpcomingPriceId(invoicePreview as InvoicePreview)
	);
};

/**
 * Given an invoice preview, what will be the next price id based on either a plan downgrade or if there is not downgrade then the subscription.
 */
export const getUpcomingPriceId = (
	invoicePreview: InvoicePreview | null | undefined
): string | null | undefined => {
	if (!invoicePreview) {
		return invoicePreview;
	}
	const subscriptionLine =
		invoicePreview.upcoming_invoice?.lines?.data?.filter(
			(line: any) => line.type === "subscription"
		)?.[0];

	return (
		invoicePreviewToDowngradeScheduleDisplay(invoicePreview)?.priceId ||
		subscriptionLine?.plan?.id
	);
};

export const comparePriceIds = (
	oldPriceId: PriceId | undefined,
	newPriceId: PriceId
): "SAME" | "UPGRADE" | "DOWNGRADE" => {
	if (oldPriceId === newPriceId) {
		return "SAME";
	}
	const inUpgradeOrder = [
		StripeKeys.prices.teamMonthly,
		StripeKeys.prices.teamAnnual,
		StripeKeys.prices.businessAnnual,
	];
	return inUpgradeOrder.indexOf(newPriceId) >
		inUpgradeOrder.indexOf(oldPriceId || "")
		? "UPGRADE"
		: "DOWNGRADE";
};

export const getDisplayLicense = (license: License) => {
	return ["-", "Free trial", "Full"][license];
};

export const getDisplayDaysLeft = (daysLeft: number) => {
	if (daysLeft < 1) {
		return "Expired";
	}
	return daysLeft + " days left";
};

/**
 * Formats stripe price for display
 */
export const formatPrice = (price: number) => formatCost(price / 100);
