import React from "react";
import styles from "./SlateTextDisplay.module.scss";
import SlateAndEditor, { EditableProps } from "./_SlateAndEditor";

type SlateTextDisplayProps = {
	value: string;
	/** Props for the Editable component */
	editableProps?: EditableProps;
};

/**
 * Use this when you want to display some text as if you put it in a div.
 */
const SlateTextDisplay = ({
	value,
	editableProps = {},
}: SlateTextDisplayProps): JSX.Element => (
	<SlateAndEditor
		value={value}
		readOnly
		editableProps={editableProps}
		slatePluginOptions={{
			// Turn these into props into SlateTextArea as needed.
			useMentions: true,
			useAnchorMe: true,
		}}
	/>
);

export default SlateTextDisplay;
