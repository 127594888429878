import React from "react";
import { Avatar } from "antdv4";
import { getAvatarColorFromString } from "@components/UserAvatar/UserAvatar";
import classNames from "classnames";
import "./index.scss";
import { useGetPersonalWorkspacesQuery } from "@redux/team/TeamApi";
import Tooltip from "../Tooltip";
import Typography from "../Typography/Typography";
import { Workspace, WorkspaceForUser } from "@common/types";
import { useCurrentWorkspaceUuid } from "@helpers/Hooks/UseCurrentWorkspaceUuid";

type TeamAvatarProps = {
	avatarStyle?: React.CSSProperties;
	className?: string;
	/** Controls the size the avatar */
	avatarSize?: number;
	/** Controls the theme of the avatar */
	theme?: "normal" | "dark";
	/** Controls the size of the letter in the avatar */
	fontSize?: number;
	/** The team name to be shown on the avatar */
	teamName?: string;
	/** Styling for deactivated state */
	deactivated?: boolean;
	/** To shown the team's name as tooltip */
	tooltip?: boolean;
};
export default function TeamAvatar({
	avatarStyle,
	avatarSize = 32,
	fontSize = 16,
	theme = "normal",
	teamName,
	className,
	deactivated,
	tooltip,
}: TeamAvatarProps): JSX.Element {
	const currentWorkspaceId = useCurrentWorkspaceUuid();
	const { data: workspaces } = useGetPersonalWorkspacesQuery();
	const currentWorkspaceData = workspaces?.find(
		(ws: WorkspaceForUser) => ws.id === currentWorkspaceId
	);
	const currentTeamName =
		teamName !== undefined ? teamName : currentWorkspaceData?.name || "";

	const teamInitial = currentTeamName.charAt(0);

	const team_background = getAvatarColorFromString(currentTeamName);
	/** TODO: Add team_logo in DASH-1028 to support uploading logo */
	const component = (
		<Avatar
			size={avatarSize}
			className={classNames(
				"genemod-team-avatar",
				{ ["genemod-darkmode"]: theme === "dark" },
				className,
				{ deactivated }
			)}
			shape="square"
			style={{
				backgroundColor: team_background,
				minWidth: avatarSize,
				minHeight: avatarSize,
				fontSize: fontSize,
				...avatarStyle,
			}}
		>
			{teamInitial.toUpperCase()}
		</Avatar>
	);
	return tooltip ? (
		<Tooltip
			color="layer-inverse"
			title={
				<Typography bold color="text-inverse">
					{teamName}
				</Typography>
			}
		>
			{component}
		</Tooltip>
	) : (
		component
	);
}
