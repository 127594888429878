import { GenemodDocument } from "./GenemodDocument";
import { ExperimentId, ProtocolId } from "@common/types";

/**
 * Interface for genemod documents added to a protocol/experiment.
 */
export type PMDocument = GenemodDocument & {
	/**
	 * "protocol" is a main experiment document created when the experiment is created.
	 * "userDefined" was created by a user as a custom blank document.
	 */
	type: "protocol" | "userDefined";
	// the experiment or protocol parent id
	parentId: ExperimentId | ProtocolId;
	parentType: "EXPERIMENT" | "PROTOCOL";
};

export type PMDocumentType = PMDocument["type"];
