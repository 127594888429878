import React, { Component } from "react";
import { Radio } from "antd";
import "./Radiobutton.scss";
import cn from "classnames";

import { Demo, DemoSection, DemoWrapper } from "../index";

/**
 * Extends antd radio button
 * @param {Object} props
 * @param {"lightweight"} props.theme
 * @param {Boolean} props.noLabel
 */
class RadioButton extends Component {
	render() {
		return (
			<div
				className={cn("genemod-radiobutton", this.props.className, {
					["genemod-radiobutton__lightweight"]:
						this.props.theme === "lightweight",
					["genemod-radiobutton__checked"]: this.props.checked,
					["genemod-radiobutton__disabled"]: this.props.disabled,
					["genemod-radiobutton__noLabel"]: this.props.noLabel,
				})}
				data-cy={this.props.dataCy}
			>
				<Radio {...this.props}>{this.props.children}</Radio>
			</div>
		);
	}
}

// Same props as antd radiogroup
class RadioGroup extends Component {
	render() {
		return (
			<div className="genemod-radiogroup">
				<Radio.Group {...this.props}>{this.props.children}</Radio.Group>
			</div>
		);
	}
}

class RADIO_DEMO extends Component {
	state = {
		value: 1,
	};

	onChange = (e) => {
		console.log("radio checked", e.target.value);
		this.setState({
			value: e.target.value,
		});
	};

	onChange2 = (e) => {
		console.log("radio checked", e.target.value);
		this.setState({
			value2: e.target.value,
		});
	};

	render() {
		const radioStyle = {
			display: "block",
		};
		const { value, value2 } = this.state;
		return (
			<div className="genemod-radio-demo">
				<DemoWrapper>
					<DemoSection>
						<Demo
							title="basic usage"
							description="Behavior exhibited after a radiobutton is clicked is controlled by onChange prop in RadioGroup. Each RadioButton is given a value and the RadioGroup keeps track of the value that is selected."
						>
							<RadioGroup onChange={this.onChange} value={value}>
								<RadioButton style={radioStyle} value={1}>
									Choice 1
								</RadioButton>
								<RadioButton style={radioStyle} value={2}>
									Choice 2
								</RadioButton>
								<RadioButton style={radioStyle} value={3}>
									Choice 3
								</RadioButton>
							</RadioGroup>
						</Demo>
						<Demo
							title="Added Behavior"
							description="RadioButton contains props like defaultchecked, checked, disabled, autoFocus."
						>
							<RadioGroup
								onChange={this.onChange2}
								value={value2}
							>
								<RadioButton style={radioStyle} value={1}>
									This is untouched
								</RadioButton>
								<RadioButton style={radioStyle} value={2}>
									This is untouched
								</RadioButton>
								<RadioButton
									style={radioStyle}
									value={3}
									disabled={true}
								>
									This is disabled
								</RadioButton>
							</RadioGroup>
						</Demo>
						<Demo
							title="Lightweight theme"
							description="Theme used in filter panels"
						>
							<RadioButton theme="lightweight" checked={true}>
								Lightweight on
							</RadioButton>
							<RadioButton theme="lightweight" checked={false}>
								Lightweight off
							</RadioButton>
							<RadioButton theme="lightweight" checked={false}>
								<div>line1</div>
								<div>line2</div>
							</RadioButton>
						</Demo>
					</DemoSection>
				</DemoWrapper>
			</div>
		);
	}
}

export { RadioButton, RadioGroup, RADIO_DEMO };
