import { Typography } from "@common/components";
import { Color, ColorCssVarMap } from "@common/styles/Colors";
import {
	ProjectColorEnum,
	ProjectColorEnumToColor,
	ProjectProps,
} from "@common/types";
import { AtLeast } from "@helpers/TypeHelpers";
import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

type Colors = keyof typeof ProjectColorEnum;

export const projectKeyColors: {
	[key in Colors]: {
		color: Color;
		background: Color;
	};
} = {
	NAVY: {
		color: "royal-blue-06",
		background: "royal-blue-01",
	},
	PINK: {
		color: "pink-05",
		background: "pink-01",
	},
	RED: {
		color: "dust-red-06",
		background: "dust-red-01",
	},
	MINT: {
		color: "cyan-06",
		background: "cyan-01",
	},
	GREEN: {
		color: "green-06",
		background: "green-01",
	},
	GINGER: {
		color: "desert-06",
		background: "desert-01",
	},
	VIOLET: {
		color: "violet-06",
		background: "violet-01",
	},
	YELLOW: {
		color: "yellow-06",
		background: "yellow-01",
	},
};

type ProjectKeyTagProps = {
	project: AtLeast<ProjectProps, "color" | "key">;
	variant?: "small" | "default" | "large" | "extraLarge";
};
export default function ProjectKeyTag({
	project,
	variant = "default",
}: ProjectKeyTagProps) {
	type Colors = keyof typeof ProjectColorEnum;

	const currentColor =
		projectKeyColors[ProjectColorEnum[project.color] as Colors];

	return (
		<div
			className={classNames(styles.projectKeyTag, styles[variant])}
			style={{
				backgroundColor: ColorCssVarMap[currentColor.background],
			}}
		>
			<Typography variant="footnote" color={currentColor.color}>
				{project.key}
			</Typography>
		</div>
	);
}
