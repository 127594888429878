import React from "react";
import styles from "./index.module.scss";
import { GenemodIcon, Typography } from "@common/components";
import cn from "classnames";

type ItemPillButtonProps = {
	/** Item Name */
	name: string;
	/** the location of item placed */
	placedAt: string;
	/** whether the Button is focused or not (default = false)*/
	focused?: boolean;
	/** onClick behavior */
	onClick: () => void;
	/** onClick behavior when undo button clicked */
	undoOnClick: () => void;
};
export default function ItemPillButton({
	name,
	placedAt,
	focused = false,
	onClick,
	undoOnClick,
}: ItemPillButtonProps): JSX.Element {
	return (
		<div
			className={cn(
				styles.itemPill,
				{ [styles.itemPill_focused]: !placedAt && focused },
				{ [styles.itemPill_placed]: !!placedAt }
			)}
			onClick={onClick}
		>
			<Typography
				variant="label"
				color="text-secondary"
				ellipsis
				className={styles.itemName}
			>
				{name}
			</Typography>
			<div className={cn(styles.itemStatus)}>
				{placedAt ? (
					<>
						<Typography
							className={styles.itemPlaceText}
							variant="caption"
							color="text-tertiary"
						>
							{placedAt}
						</Typography>
						<div
							className={styles.itemUndoButton}
							onClick={undoOnClick}
						>
							<GenemodIcon name="undo" fill="button-primary" />
						</div>
					</>
				) : (
					<div className={styles.itemNormalStatus} />
				)}
			</div>
		</div>
	);
}
