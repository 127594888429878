import React from "react";
import { capitalizeFirstLetter } from "@helpers/Formatters";
import { AppState, useAppDispatch, appSelector } from "@redux/store";
import { commonModalActions } from "./CommonModals";

type ModalEntries = Extract<keyof AppState["commonModals"], string>;
/**
 * Hook that makes accessing common modal data a bit more convenient.
 * Gives you an object back with named "data", "set", and "closed" functions.
 */
export const useCommonModalState = <K extends ModalEntries>(modal: K) => {
	const dispatch = useAppDispatch();
	const data = appSelector((state) => state.commonModals[modal]);

	const capitalizedModal = capitalizeFirstLetter(modal);
	const actionName = `set${capitalizedModal}` as const;
	const action = commonModalActions[actionName] as any;

	type StateType = AppState["commonModals"][K];

	type OpenModalArg = StateType extends { data: unknown }
		? StateType["data"]
		: void;

	const openModal = (newValue: OpenModalArg) => {
		dispatch(action({ state: "OPEN", ...((newValue as any) || {}) }));
	};
	const closeModal = () => {
		dispatch(action("CLOSED"));
	};

	type ReturnType = {
		[Key in `is${typeof capitalizedModal}Visible`]: boolean;
	} & { [Key in `${K}Data`]: OpenModalArg } & {
		[Key in `open${typeof capitalizedModal}`]: typeof openModal;
	} & { [Key in `close${typeof capitalizedModal}`]: typeof closeModal } & {
		genericallyNamed: {
			isVisible: boolean;
			data: typeof data;
			openModal: typeof openModal;
			closeModal: typeof closeModal;
		};
	};

	const isVisible = data.state === "OPEN";
	const innerData = data as any;

	return {
		[`is${capitalizedModal}Visible`]: isVisible,
		[`${modal}Data`]: innerData,
		[`open${capitalizedModal}`]: openModal,
		[`close${capitalizedModal}`]: closeModal,
		genericallyNamed: {
			isVisible,
			data: innerData,
			openModal,
			closeModal,
		} as const,
	} as ReturnType;
};

type SomeComponent<Props> = (props: Props) => JSX.Element;

export const createUnmountOnHideModal =
	(key: ModalEntries) =>
	<Props,>(component: SomeComponent<Props>): SomeComponent<Props> =>
	(props: Props): JSX.Element => {
		const {
			genericallyNamed: { isVisible },
		} = useCommonModalState(key);

		if (!isVisible) return <></>;

		const UnsafeComponent = component as any;

		return <UnsafeComponent {...props} />;
	};
