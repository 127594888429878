import { useMemo } from "react";
import {
	useHistory as useHistoryExt,
	useLocation,
	Redirect,
	Link,
	Route,
	Switch,
	useParams,
	useRouteMatch,
	LinkProps,
	Prompt,
	RouteProps,
} from "react-router-dom";

export {
	Redirect,
	Link,
	Route,
	Switch,
	useParams,
	useRouteMatch,
	Prompt,
	useLocation,
};
export type { LinkProps, RouteProps };
const DEBUG = false;

/**
 * Use this hook in place of directly importing react-router-dom.
 * This will help us debug url changes which are otherwise tricky to track down.
 */
export const useHistory = () => {
	const history = useHistoryExt();

	const debugHistory = useMemo(
		() =>
			({
				...history,
				push: (location: any, state?: any) => {
					console.log({ type: "history.push", location, state });
					return history.push(location, state);
				},
				replace: (location: any, state?: any) => {
					console.log({ type: "history.replace", location, state });
					return history.replace(location, state);
				},
			} as typeof history),
		[history]
	);
	if (DEBUG) {
		return debugHistory;
	}
	return history;
};
