import {
	Button,
	ButtonV2,
	GenemodIcon,
	Modal,
	Notification,
} from "@common/components";
import React, {
	useState,
	createContext,
	useContext,
	SetStateAction,
	Dispatch,
	useEffect,
} from "react";
import { REPOSITORY_SECTIONS } from "@containers/Freezer/CreateNewItem/data";
import CreateNewItem from "@containers/Freezer/CreateNewItem/CreateNewItem";
import styles from "./styles.module.scss";
import {
	useBulkItemsCreateMutation,
	useItemCreateMutation,
} from "@redux/inventory/Item";
import { FurnitureCategoryId, Item } from "@common/types";
import { truncArgs } from "@helpers/Formatters";
import { useLocation } from "react-router-dom";
import { useParams } from "@helpers/URLParams";
import ConsumableCreatePanel from "@containers/Freezer/FurnitureCategoryView/ConsumableCreatePanel/ConsumableCreatePanel";
import { MessageWithLink } from "@components/Notification/Notification";
import { useHistory } from "react-router-dom";
import { useOrganizationRouter } from "@root/AppRouter";

type ErrorsContextProps = {
	hasErrors: boolean;
	setHasErrors: Dispatch<SetStateAction<boolean>>;
};

export const ErrorsContext = createContext({} as ErrorsContextProps);

function AddBtn({
	onClick,
	disabled,
	isLoading,
}: {
	onClick?: () => void;
	disabled?: boolean;
	isLoading: boolean;
}) {
	const { hasErrors } = useContext(ErrorsContext);
	return (
		<div
			style={{
				display: "block",
				position: "absolute",
				right: "32px",
				bottom: "32px",
			}}
		>
			<Button
				onClick={onClick}
				disabled={disabled || hasErrors}
				dataCy="add-new-item-btn"
				loading={isLoading}
			>
				Create Item
			</Button>
		</div>
	);
}

function CreateItemBtn({
	onClick,
	disabled,
	isLoading,
}: {
	onClick?: () => void;
	disabled?: boolean;
	isLoading?: boolean;
}) {
	const { hasErrors } = useContext(ErrorsContext);
	return (
		<div
			style={{
				display: "block",
				position: "absolute",
				right: "32px",
				bottom: "32px",
				borderRadius: "4px",
				padding: "5px 16px",
			}}
		>
			<ButtonV2
				onClick={onClick}
				disabled={disabled || hasErrors}
				color="var(--action-default)"
				type="primary"
				dataCy="add-new-item-btn"
				loading={isLoading}
			>
				Create Item
			</ButtonV2>
		</div>
	);
}

export function NewItemModal({
	isOpen,
	setIsOpen,
	isConsumable = false,
	isExpermentItemCreate = false,
	defaultName,
	handleSuccess,
}: {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isConsumable?: boolean;
	isExpermentItemCreate?: boolean;
	defaultName?: string;
	handleSuccess?: (item: Item) => void;
}) {
	const [isLoading, setIsLoading] = useState(false);
	const [bulkCreateItems] = useBulkItemsCreateMutation();
	const [createNewItem] = useItemCreateMutation();
	const [hasErrors, setHasErrors] = useState(false);
	const { pathname, search } = useLocation();
	const history = useHistory();
	const { appendBaseUrl, appendBasePath } = useOrganizationRouter();

	const handleCreateNewItem = (
		item: Item,
		callback?: (newBox: boolean, item: Item | Error) => void,
		copies?: number
	) => {
		setIsLoading(true);
		if (copies && copies > 1) {
			const payload = Array.from({ length: copies }, () => ({ ...item }));
			bulkCreateItems({ items: payload })
				.then((res) => {
					if ("data" in res && Array.isArray(res.data.items)) {
						// Iterate through the items
						for (const item of res.data.items) {
							callback && callback(true, item);
						}
					}
					Notification.success({
						message: (
							<span>
								{copies}
								{" Items been created."}
							</span>
						),
					});
				})
				.catch(() => {
					Notification.error({
						message: "Something happened, try later or contact us",
					});
				});
		} else {
			createNewItem({ item })
				.unwrap()
				.then((data) => {
					callback && callback(true, data);
					if (isExpermentItemCreate) {
						const link = data.location
							? `freezer/freezers/${data.location?.freezer}/boxes/${data.location?.box_location?.box}/?item_id=${data.id}`
							: `freezer/repository?tab=Freezers&layer=ITEM&q=${data.custom_id}`;

						const newPath = appendBaseUrl(link);

						Notification.success({
							message: (
								<MessageWithLink
									message={`Created Freezer item, ${data.name}`}
									linkText="View item"
									onClick={() => {
										history.push(newPath);
									}}
								/>
							),
						});
					} else {
						Notification.success({
							message: (
								<span>
									<b>{truncArgs`${data.name}`(68)}</b>
									{" has been created."}
								</span>
							),
						});
					}
					if (handleSuccess) {
						handleSuccess(data);
					}
				})
				.catch((e) => {
					console.log(e);
					Notification.error({
						message: "Something happened, try later or contact us",
					});
				});
		}
		setIsLoading(false);
		setIsOpen(false);
	};

	return (
		<Modal
			visible={isOpen}
			onCancel={() => setIsOpen(false)}
			title={isConsumable ? "Add new consumable" : "Add new item"}
			hideCancelButton
			hideOkButton
			destroyOnClose
			bodyStyle={{
				maxHeight: "calc(100vh - 343px)",
				overflow: "auto",
			}}
			wrapClassName={styles.newItemModal}
			dataCy="new-item"
		>
			<ErrorsContext.Provider value={{ hasErrors, setHasErrors }}>
				{isConsumable ? (
					<form className="information-form" autoComplete="off">
						<div className="form-body">
							<ConsumableCreatePanel
								extraPadding
								setConsumableId={() => {}}
								setIsCreate={setIsOpen}
								isRepositoryPage
							>
								<AddBtn isLoading={isLoading} />
							</ConsumableCreatePanel>
						</div>
					</form>
				) : (
					<CreateNewItem
						title="Create new item"
						focusedCell={null}
						onCancel={() => setIsOpen(false)}
						handleSubmit={handleCreateNewItem}
						withoutHeader
						formSections={REPOSITORY_SECTIONS}
						isExpermentItemCreate={isExpermentItemCreate}
						layerColor={1}
						redirectTo={pathname + search + "&modalOpen=true"}
						isRepositoryPage
						presetFields={
							defaultName
								? ({
										name: {
											value: defaultName,
											disabled: true,
											autoFocus: false,
										},
								  } as const)
								: undefined
						}
					>
						{isExpermentItemCreate ? (
							<CreateItemBtn isLoading={isLoading} />
						) : (
							<AddBtn isLoading={isLoading} />
						)}
					</CreateNewItem>
				)}
			</ErrorsContext.Provider>
		</Modal>
	);
}

function NewItemBtn({ isConsumable = false }: { isConsumable?: boolean }) {
	const [isOpen, setIsOpen] = useState(false);
	const { getParam } = useParams();

	useEffect(() => {
		if (getParam("modalOpen")) {
			setIsOpen(true);
		}
	}, [getParam("modalOpen")]);
	return (
		<>
			<ButtonV2
				type="primary"
				icon={"plus-circle"}
				onClick={() => setIsOpen(true)}
				dataCy="new-item-btn"
			>
				{isConsumable ? "New consumable" : "New item"}
			</ButtonV2>
			<NewItemModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				isConsumable={isConsumable}
			/>
		</>
	);
}

export default NewItemBtn;
