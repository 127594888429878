import { Color } from "@common/styles/Colors";
import { Button, ButtonProps } from "antdv5";
import classNames from "classnames";
import React from "react";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";
import styles from "./ButtonV2.module.scss";

export type ButtonV2Props = {
	icon?: IconName;
	iconColor?: Color;
	dataCy?: string;
	onLayer?: "layer-background" | "layer-01" | "layer-02" | "layer-03";
} & Omit<ButtonProps, "icon">;

const ButtonV2 = ({
	children,
	icon,
	type = "default",
	size = "middle",
	iconColor,
	onLayer,
	...props
}: ButtonV2Props) => {
	const { className, dataCy, ...remaingProps } = props;

	const getLayer = () => {
		if (!onLayer) return "";

		const mapping: {
			[key in typeof onLayer]: typeof onLayer;
		} = {
			"layer-background": "layer-01",
			"layer-01": "layer-02",
			"layer-02": "layer-03",
			"layer-03": "layer-01",
		};

		return mapping[onLayer];
	};

	return (
		<Button
			icon={
				icon ? (
					<GenemodIcon
						name={icon}
						color={props.disabled ? "text-disabled" : iconColor}
						stroke={iconColor}
						fill={props.disabled ? "text-disabled" : iconColor}
					/>
				) : null
			}
			type={type}
			className={classNames(
				styles.button,
				className,
				styles[type],
				styles[size],
				{
					[styles.disabled]: props.disabled,
					[styles.icon]: !children,
					[styles[getLayer()]]: onLayer && type === "default",
				},
				props.className
			)}
			{...remaingProps}
			data-cy={dataCy}
		>
			{children}
		</Button>
	);
};

export default ButtonV2;
