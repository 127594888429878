import { TabListTabText } from "@common/components/TabList/TabList";
import {
	ActivityLog,
	ButtonV2,
	EditableSidePanel,
	Input,
	Modal,
	Notification,
	SidePanel,
	TabList,
	TextArea,
	Typography,
} from "@components";
import { SearchSettingTabContent } from "@containers/Freezer/components/SearchSettings/SearchSettingsPanel";
import { ITEM_GROUP_CONTAINERS } from "@common/types";
import { truncArgs } from "@helpers/Formatters";
import { useItemTypeNameMappingsFn } from "@helpers/Hooks";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { IdsInConstType } from "@helpers/TypeHelpers";
import { useCommonModalState } from "@redux/CommonModals/hooks";

import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import React, { useEffect, useState } from "react";
import AnalyticsTabContent from "../AnalyticsTabContent/AnalyticsTabContent";
import "./ItemGroupSetting.scss";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	useItemGroupQuery,
	useItemGroupPatchMutation,
	useLazyGetItemGroupStorageSummaryQuery,
} from "@redux/inventory/ItemGroup";
import { EditableSidePanelSettings } from "@common/components/EditableSidePanel";
import InputV3 from "@common/components/InputV3/InputV3";

export type BoxReagentSettingsTabs =
	| "details"
	| "activity"
	| "analytics"
	| "customize";

export type BoxStorageProps = {
	name: string;
	items: number;
	children: BoxStorageProps[];
};

type ItemGroupStorageProps = {
	name: string;
	items: number;
};

export default function ItemGroupSettings(): JSX.Element {
	const {
		genericallyNamed: { isVisible, closePanel: _closePanel, data },
	} = useCommonPanelState("itemGroupSettings");
	const { data: item_group } = useItemGroupQuery(data.id || skipToken);

	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const [name, setName] = React.useState("");
	const [tempName, setTempName] = React.useState("");
	const [description, setDescription] = React.useState("");
	const [tempDescription, setTempDescription] = React.useState("");
	const type = "Item Group";

	const [isEdit, _setEdit] = React.useState(false);
	const setEdit = (v: boolean) => {
		_setEdit(v);
		if (v) {
			setTempName(name);
			setTempDescription(description);
		}
	};
	const [showUnsavedModal, setShowUnsavedModal] = React.useState(false);
	const [boxStorageData, setBoxStorageData] = React.useState<
		BoxStorageProps[]
	>([]);
	const [itemGroupStorageData, setItemGroupStorageData] = React.useState<
		ItemGroupStorageProps[]
	>([]);
	const [key, setKey] = useState<BoxReagentSettingsTabs>(
		data.defaultTab || "details"
	);
	const [updateItemGroup] = useItemGroupPatchMutation();
	const [getItemGroupSummary] = useLazyGetItemGroupStorageSummaryQuery();

	const itemTypeNameMappingsFn = useItemTypeNameMappingsFn();
	const { is_limit_reached: restrictFreezerAnalytics } =
		useFeatureRestrictionHook("freezer_analytics");
	const { id, container_type, item_type } = item_group
		? item_group
		: {
				id: -1,
				container_type: 1,
				item_type: 1,
		  };
	const viewOnly = !!item_group?.is_locked;

	useEffect(() => {
		if (isVisible && item_group) {
			setName(item_group?.name || "");
			setDescription(item_group?.description);
			setKey(data.defaultTab || "details");
			getItemGroupStorageData();
		}
	}, [isVisible, item_group]);

	const closePanel = () => {
		if (isVisible && hasUnsavedChanges()) {
			setShowUnsavedModal(true);
		} else {
			setEdit(false);
			_closePanel();
		}
	};

	const handleCancel = () => {
		setEdit(false);
	};

	const hasUnsavedChanges = () => {
		return (
			isEdit &&
			(tempName.trim() !== name.trim() || tempDescription !== description)
		);
	};

	const validateForm = () => {
		return !!tempName.trim().length;
	};

	const handleSubmit = () => {
		if (!validateForm() || !item_group) return;

		const failure = (err?: string) => {
			console.log("Failed to update box", err);
			Notification.warning({
				message: "Failed to update box",
			});
		};
		updateItemGroup({
			id: item_group.id,
			name: tempName.trim(),
			description: tempDescription.trim(),
		})
			.unwrap()
			.then((group) => {
				setName(group.name);
				setDescription(group.description);
				setEdit(false);
				SegmentTrackEvent(SegmentFreezerEvents.BOX_EDIT, {
					id: group.id,
					is_item_group: false,
				});
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${group.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});
			})
			.catch((err) => {
				console.log(err);
				failure();
			});
	};

	const getItemGroupStorageData = () => {
		getItemGroupSummary({ item_group: id }).then((res) => {
			const data = res.data;
			if (!data) return;

			setItemGroupStorageData([
				{
					name: data.name,
					items: data.items,
				},
			]);
		});
	};

	const settingContent = [
		{
			key: "details",
			tabtitle: <TabListTabText tabTitle="Details" />,
			tabcontent: (
				<div className="box-reagent-settings">
					{!isEdit ? (
						<>
							<EditableSidePanel
								title={name}
								type={
									type.toLowerCase() as EditableSidePanelSettings
								}
								showEdit={() => setEdit(true)}
								viewOnly={viewOnly}
								dataCy="item-group-editable-settings"
							>
								{description && (
									<div>
										<Typography
											withLinks
											className="sidePanel-description"
										>
											{description}
										</Typography>
									</div>
								)}
								<table>
									<div className="sidePanel-infoView-layout">
										<Typography className="sidePanel-infoView-leftSide">
											Item type
										</Typography>
										<Typography>
											{itemTypeNameMappingsFn(item_type)}
										</Typography>
									</div>
									<div className="sidePanel-infoView-layout">
										<Typography className="sidePanel-infoView-leftSide">
											Container
										</Typography>
										<Typography>
											{
												ITEM_GROUP_CONTAINERS[
													container_type as IdsInConstType<
														typeof ITEM_GROUP_CONTAINERS
													>
												]
											}
										</Typography>
									</div>
								</table>
							</EditableSidePanel>
						</>
					) : (
						<EditableSidePanel
							edit={isEdit}
							showEdit={() => {
								if (isVisible && hasUnsavedChanges()) {
									setShowUnsavedModal(true);
								} else {
									handleCancel();
								}
							}}
							inlineEdit
							type={
								type.toLowerCase() as EditableSidePanelSettings
							}
							onConfirm={handleSubmit}
							validateForm={validateForm}
							dataCy="item-group-editable-settings"
						>
							<div className="edit-form">
								<InputV3
									value={tempName}
									onChange={(e) =>
										setTempName(e.target.value)
									}
									required
									dataCy="item-group-settings-name-input"
								/>
							</div>
						</EditableSidePanel>
					)}
				</div>
			),
		},
		{
			key: "activity",
			tabtitle: <TabListTabText tabTitle="Activity" />,
			tabcontent: (
				<ActivityLog
					activityFilter={{
						location_model: "ItemGroup",
						location_object_id: id,
					}}
				/>
			),
		},
		{
			key: "analytics",
			tabtitle: <TabListTabText tabTitle="Analytics" />,
			tabcontent: (
				<AnalyticsTabContent
					title={type}
					showHeader={false}
					storageSummary={{
						dataSource: itemGroupStorageData,
						columns: [
							{
								title: "Item count",
								dataIndex: "name",
								key: "name",
								ellipsis: true,
							},
							{
								title: "Items",
								dataIndex: "items",
								key: "items",
								align: "right",
								width: 88,
							},
						],
						mockDataSource: itemGroupStorageData,
					}}
				/>
			),
		},
		{
			key: "customize",
			tabtitle: <TabListTabText tabTitle="Preference" />,
			tabcontent: (
				<SearchSettingTabContent
					visible={isVisible}
					selectedItemTypeID={item_type}
				/>
			),
		},
	] as const;

	return (
		<>
			<SidePanel
				isVisible={isVisible}
				onClose={() => closePanel()}
				header="Item group settings"
				dataCy="item-group-settings"
				showIcon={false}
			>
				<TabList
					inSettings
					tabListItems={settingContent as any}
					largeSize={true}
					activeKey={key}
					onChange={(k: BoxReagentSettingsTabs) => setKey(k)}
				/>
			</SidePanel>
			<UnsavedChangeModal
				isVisible={showUnsavedModal}
				setIsVisible={setShowUnsavedModal}
				closePanel={closePanel}
				handleCancel={handleCancel}
			/>
		</>
	);
}

function UnsavedChangeModal(props: {
	isVisible: boolean;
	setIsVisible: (visible: boolean) => void;
	handleCancel: (cancel: boolean) => void;
	closePanel: () => void;
}) {
	return (
		<Modal
			className="unsaved-change-modal"
			visible={props.isVisible}
			title="Unsaved changes"
			okText="Continue editing"
			cancelText="Discard"
			width={496}
			onOk={() => props.setIsVisible(false)}
			onCancel={() => {
				props.handleCancel(false);
				props.closePanel();
				props.setIsVisible(false);
			}}
		>
			Your changes will not be saved
		</Modal>
	);
}
