import { useEffect, useState } from "react";
import SegmentKeys from "./SegmentKeys";
import {
	SegmentFreezerEvents,
	SegmentPMEvents,
	SegmentEvent,
} from "./SegmentEvents";
import moment from "moment";
import { deprecated_getConfig } from "@common/config/GenemodConfig";
import {
	getFullNameFromAvatar,
	getRoleName,
	Organization,
	OrganizationUser,
} from "@common/types";
import useCurrentOrganization from "@helpers/Hooks/useCurrentOrganizationHook";
import useCurrentTeamMembers from "@helpers/Hooks/useCurrentTeamMembersHooks";
import { useLocation } from "@helpers/Hooks/UseRouterDom";

const ANALYTICS_TRACKING = deprecated_getConfig("ANALYTICS_TRACKING");

type GenemodWindow = typeof window & {
	analytics: any;
	genemodSegmentGlobal?: {
		organization?: Organization;
		currentTeamUser?: OrganizationUser;
		segmentIdDone: boolean;
	};
};

const getWindow = () => window as GenemodWindow;

/**
 * Initializer script that sets the segment environment
 * Sets the segment key, and page loading script
 * @function
 * */
export const initSegment = () => {
	if (!ANALYTICS_TRACKING) return;

	// Determine the Segment key based on the environment
	let segmentKey = SegmentKeys["qa"];
	if (process.env.REACT_APP_NODE_ENV === "production") {
		segmentKey = SegmentKeys["production"];
	} else if (process.env.REACT_APP_NODE_ENV === "dev") {
		segmentKey = SegmentKeys["dev"];
	}

	/* Below are the codes from segment documentation */

	// Create a queue, but don't obliterate an existing one!
	const analytics = (getWindow().analytics = getWindow().analytics || []);

	// If the real analytics.js is already on the page return.
	if (analytics.initialize) return;

	// If the snippet was invoked already show an error.
	if (analytics.invoked) {
		if (window.console && console.error) {
			console.error("Segment snippet included twice.");
		}
		return;
	}

	// Invoked flag, to make sure the snippet
	// is never invoked twice.
	analytics.invoked = true;

	// A list of the methods in Analytics.js to stub.
	analytics.methods = [
		"trackSubmit",
		"trackClick",
		"trackLink",
		"trackForm",
		"pageview",
		"identify",
		"reset",
		"group",
		"track",
		"ready",
		"alias",
		"debug",
		"page",
		"once",
		"off",
		"on",
		"addSourceMiddleware",
		"addIntegrationMiddleware",
		"setAnonymousId",
		"addDestinationMiddleware",
	];

	// Define a factory to create stubs. These are placeholders
	// for methods in Analytics.js so that you never have to wait
	// for it to load to actually record data. The `method` is
	// stored as the first argument, so we can replay the data.
	analytics.factory = function (method: any) {
		return function () {
			// eslint-disable-next-line prefer-rest-params
			const args = Array.prototype.slice.call(arguments);
			args.unshift(method);
			analytics.push(args);
			return analytics;
		};
	};

	// For each of our methods, generate a queueing stub.
	for (let i = 0; i < analytics.methods.length; i++) {
		const key = analytics.methods[i];
		analytics[key] = analytics.factory(key);
	}

	// Define a method to load Analytics.js from our CDN,
	// and that will be sure to only ever load it once.
	analytics.load = function (key: string, options: any) {
		// Create an async script element based on your key.
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.async = true;
		script.src =
			"https://cdn.segment.com/analytics.js/v1/" +
			key +
			"/analytics.min.js";

		// Insert our script next to the first script element.
		const first = document.getElementsByTagName("script")[0];
		first?.parentNode?.insertBefore?.(script, first);
		analytics._loadOptions = options;
	};

	// Add a version to keep track of what's in the wild.
	analytics.SNIPPET_VERSION = "4.1.0";

	// Load Analytics.js with your key, which will automatically
	// load the tools you've enabled for your account. Boosh!
	analytics.load(segmentKey);

	// Make the first page call to load the integrations. If
	// you'd like to manually name or tag the page, edit or
	// move this call however you'd like.
	// analytics.page();
};

/**
 * Set current user in segment analytics
 */
export const segmentIdentifyUser = () => {
	const data = getWindow()?.genemodSegmentGlobal;
	if (
		!data ||
		data.segmentIdDone ||
		!data.currentTeamUser ||
		!data.organization
	)
		return;
	const { currentTeamUser, organization } = data;
	const analytics = getWindow().analytics;
	if (analytics && ANALYTICS_TRACKING) {
		analytics.identify(currentTeamUser.user.id, {
			email: currentTeamUser.user.email,
			name: getFullNameFromAvatar(currentTeamUser.user),
			createdAt: moment(currentTeamUser.user.date_joined),
			institution: organization.institution,
			team_name: organization.name,
			team_id: organization.id,
			role: getRoleName(currentTeamUser),
		});
	}
	data.segmentIdDone = true;
};

/**
 * Track evvent in segment analytics
 */
export const SegmentTrackEvent = (event: SegmentEvent, properties = {}) => {
	const analytics = getWindow().analytics;

	// A race condition seemingly happens where the user is not yet identified, also init here
	segmentIdentifyUser();

	if (analytics && ANALYTICS_TRACKING) {
		analytics.track(event, properties);
	}
};

export const SegmentSetupUserIdentity = () => {};

export const useSegmentSetup = () => {
	const location = useLocation();

	const { organization } = useCurrentOrganization();
	const { currentUserAsTeamMember: currentTeamUser } =
		useCurrentTeamMembers();

	// we can assume the user is logged in at this point
	useEffect(() => {
		// Initialize segment
		initSegment();
	}, []);

	// Initialize segment global variables so that we can initialize segment when tracking an event
	getWindow().genemodSegmentGlobal = {
		organization,
		currentTeamUser,
		segmentIdDone:
			getWindow()?.genemodSegmentGlobal?.segmentIdDone || false,
	};

	const segmentIdDone = getWindow().genemodSegmentGlobal?.segmentIdDone;

	useEffect(() => {
		if (!segmentIdDone && organization && currentTeamUser) {
			segmentIdentifyUser();
		}
	}, [currentTeamUser, organization, segmentIdDone]);

	useEffect(() => {
		// segment thing
		(window as any)?.analytics?.page?.();
	}, [location.pathname]);
};

export { SegmentFreezerEvents, SegmentPMEvents };
