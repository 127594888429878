type StripeCountry = {
	/** ISO 3166-1 alpha-2 (2-letter) country code*/
	code: string;
	/** Name of the country */
	name: string;
};

export const COUNTRIES: StripeCountry[] = [
	{
		code: "AU",
		name: "Australia",
	},
	{
		code: "AT",
		name: "Austria",
	},
	{
		code: "BE",
		name: "Belgium",
	},

	{
		code: "BR",
		name: "Brazil",
	},

	{
		code: "BG",
		name: "Bulgaria",
	},

	{
		code: "CA",
		name: "Canada",
	},

	{
		code: "CY",
		name: "Cyprus",
	},

	{
		code: "CZ",
		name: "Czechia/Czech Republic",
	},

	{
		code: "DK",
		name: "Denmark",
	},

	{
		code: "EE",
		name: "Estonia",
	},

	{
		code: "FI",
		name: "Finland",
	},

	{
		code: "FR",
		name: "France",
	},

	{
		code: "DE",
		name: "Germany",
	},

	{
		code: "GR",
		name: "Greece",
	},

	{
		code: "HK",
		name: "Hong Kong",
	},

	{
		code: "HU",
		name: "Hungary",
	},

	{
		code: "IN",
		name: "India",
	},

	{
		code: "IE",
		name: "Ireland",
	},

	{
		code: "IT",
		name: "Italy",
	},

	{
		code: "JP",
		name: "Japan",
	},

	{
		code: "LV",
		name: "Latvia",
	},

	{
		code: "LT",
		name: "Lithuania",
	},

	{
		code: "LU",
		name: "Luxembourg",
	},

	{
		code: "MY",
		name: "Malaysia",
	},

	{
		code: "MT",
		name: "Malta",
	},

	{
		code: "MX",
		name: "Mexico",
	},

	{
		code: "NL",
		name: "Netherlands",
	},

	{
		code: "NZ",
		name: "New Zealand",
	},

	{
		code: "NO",
		name: "Norway",
	},

	{
		code: "PL",
		name: "Poland",
	},

	{
		code: "PT",
		name: "Portugal",
	},

	{
		code: "RO",
		name: "Romania",
	},

	{
		code: "SG",
		name: "Singapore",
	},

	{
		code: "SK",
		name: "Slovakia",
	},

	{
		code: "SI",
		name: "Slovenia",
	},

	{
		code: "ES",
		name: "Spain",
	},

	{
		code: "SE",
		name: "Sweden",
	},

	{
		code: "CH",
		name: "Switzerland",
	},

	{
		code: "GB",
		name: "United Kingdom",
	},

	{
		code: "US",
		name: "United States",
	},
];

export const INVOICE_STATUS = Object.freeze({
	draft: "Draft",
	open: "Open",
	paid: "Paid",
	uncollectible: "Uncollectible",
} as const);
