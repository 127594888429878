import { BaseDocument } from "./BaseDocument";
import { AttachmentAcceptedFile } from "./FileAttachment";
import { AxiosRequestConfig } from "axios";

export type BaseAttachment = BaseDocument & {
	id: number;
	/** uploaded attachment url */
	upload: string;
	/** type of attachment */
	file_type: AttachmentAcceptedFile;
};

export type BaseAttachmentUploadData = {
	data: FormData;
	config?: AxiosRequestConfig;
};
