import React from "react";
import { Tag } from "antdv4";
import { GenemodIcon, Typography } from "@common/components";
import styles from "./index.module.scss";
import classNames from "classnames";

type EmailTagProps = {
	label: string;
	onClose?: () => void;
	invalid: boolean;
};

export default function GenemodEmailTag({
	label,
	invalid,
	onClose,
}: EmailTagProps): JSX.Element {
	return (
		<Tag
			key={label}
			closable
			onClose={onClose}
			className={classNames(styles.emailTag, {
				[styles.emailTag__error]: invalid,
			})}
			closeIcon={
				<GenemodIcon name="exit" fill="text-secondary" size="small" />
			}
		>
			<Typography
				className={styles.chipText}
				ellipsis
				variant="label"
				color="text-secondary"
			>
				{label}
			</Typography>
		</Tag>
	);
}
