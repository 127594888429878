import { OrganizationUser, OrganizationUserId, UserId } from "@common/types";
import { useGetOrgUsersQuery } from "@redux/team/TeamApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useCurrentOrganization from "./useCurrentOrganizationHook";

type CustomUserProps = {
	type: "CustomUser";
	id: UserId;
};

type OrganizationUserProps = {
	type: "OrganizationUser";
	id: OrganizationUserId;
};

type Props = CustomUserProps | OrganizationUserProps;

const ORG_USER_FILTER = (id: OrganizationUserId) => (user: OrganizationUser) =>
	user.id === id;
const CUSTOM_USER_FILTER = (id: UserId) => (user: OrganizationUser) =>
	user.user.id === id;

/**
 * A hook for getting the org user for a given custom user or org user id. Must be in the current organization.
 */
export default function useOrganizationUser({ type, id }: Props) {
	const { organization } = useCurrentOrganization();
	const { data: users, ...rest } = useGetOrgUsersQuery(
		organization?.id || skipToken
	);

	const user =
		users?.find(
			type === "OrganizationUser"
				? ORG_USER_FILTER(id)
				: CUSTOM_USER_FILTER(id)
		) || null;
	return {
		user,
		...rest,
	} as const;
}
