import RATING_FIVE from "./5.png";
import RATING_FOUR from "./4.png";
import RATING_THREE from "./3.png";
import RATING_TWO from "./2.png";
import RATING_ONE from "./1.png";

export const RATING_MAP = {
	1: {
		emoji: RATING_ONE,
		placeholder: "Not at all",
	},
	2: {
		emoji: RATING_TWO,
		placeholder: "Not likely",
	},
	3: {
		emoji: RATING_THREE,
		placeholder: "Neutral",
	},
	4: {
		emoji: RATING_FOUR,
		placeholder: "Likely",
	},
	5: {
		emoji: RATING_FIVE,
		placeholder: "Very likely",
	},
} as const;
