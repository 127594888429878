import React, { useEffect, useState } from "react";
import {
	useRegisterMutation,
	useLoginMutation,
	useLogoutMutation,
	useUpdatePasswordMutation,
	useSendForgotEmailPasswordMutation,
	useResetForgottenPasswordMutation,
} from "@redux/user/UserApi";
import {
	LoginProviderProps,
	LoginContext,
	LoginStatus,
	AuthResult,
} from "../LoginContext";
import { setAxiosHeader } from "@common/API/API";
import {
	LocalStorageKeys,
	useLocalStorage,
} from "@helpers/Hooks/UseLocalStorageHook";
import { flattenUnion } from "@helpers/TypeHelpers";

const STANDARD_SUCCESS = { type: "success" };

const STANDARD_ERROR = {
	type: "failure",
	error: { name: "error", message: "drf auth failure" },
};

export const DrfLoginProvider = (props: LoginProviderProps) => {
	const [loginStatus, setLoginStatus] = useState<LoginStatus>("LOADING");
	const [lsAuthToken, setLsAuthToken] = useLocalStorage<string | null>(
		LocalStorageKeys.DRF_AUTH_TOKEN,
		null
	);

	const [register] = useRegisterMutation();
	const [login] = useLoginMutation();
	const [logout] = useLogoutMutation();
	const [updatePassword] = useUpdatePasswordMutation();
	const [sendForgotEmailPassword] = useSendForgotEmailPasswordMutation();
	const [resetForgottenPassword] = useResetForgottenPasswordMutation();

	const unwrapAndReturnAuthResult = <T,>(promise: Promise<T>) =>
		promise
			.then(() => STANDARD_SUCCESS)
			.catch(() => STANDARD_ERROR) as Promise<AuthResult>;
	const setAuthHeader = (key: string) =>
		setAxiosHeader("Authorization", `Token ${key}`);

	useEffect(() => {
		if (loginStatus !== "LOADING") return;
		if (lsAuthToken) {
			setAuthHeader(lsAuthToken);
			setLoginStatus("LOGGEDIN");
		} else {
			setLoginStatus("LOGGEDOUT");
		}
	}, [lsAuthToken, loginStatus]);

	return (
		<LoginContext.Provider
			value={{
				loginStatus,
				register: ({ email, password, first_name, last_name }) =>
					unwrapAndReturnAuthResult(
						register({
							email,
							password1: password,
							password2: password,
							first_name,
							last_name,
						}).unwrap()
					),
				login: ({ email, password }) =>
					login({ email, password })
						.unwrap()
						.then(({ key }) => {
							setLsAuthToken(key);
							setLoginStatus("LOGGEDIN");
							setAuthHeader(key);
							return STANDARD_SUCCESS;
						})
						.catch(() => {
							setLoginStatus("LOADING");
							return STANDARD_ERROR;
						}) as Promise<AuthResult>,
				logout: () =>
					logout()
						.unwrap()
						.then(() => {
							setLsAuthToken(null);
							setLoginStatus("LOGGEDOUT");
							return STANDARD_SUCCESS;
						})
						.catch(() => {
							return STANDARD_ERROR;
						}) as Promise<AuthResult>,
				resetForgottenPassword: ({
					email,
					newPassword,
					verificationParams,
				}) =>
					unwrapAndReturnAuthResult(
						resetForgottenPassword({
							// email,
							new_password1: newPassword,
							new_password2: newPassword,
							token:
								flattenUnion(verificationParams)?.token || "",
							uid: flattenUnion(verificationParams)?.uid || "",
						}).unwrap()
					),
				updatePassword: (_oldPassword, newPassword) =>
					unwrapAndReturnAuthResult(
						updatePassword({
							new_password1: newPassword,
							new_password2: newPassword,
						}).unwrap()
					),
				sendForgotEmailPassword: (email) =>
					unwrapAndReturnAuthResult(
						sendForgotEmailPassword({ email }).unwrap()
					),
			}}
		>
			{props.children}
		</LoginContext.Provider>
	);
};
