import React from "react";
import styles from "./FlaskLoadingAnimation.module.scss";
import { Demo, DemoSection, DemoWrapper } from "@components";
import { useTheme } from "../Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "../Theme/context/ThemeTypes";
import classNames from "classnames";

type AnimationProps = {
	/* animation duration: the time spent to fill the bottle */
	length?: number;
};

export default function FlaskLoadingAnimation({
	length = 2,
}: AnimationProps): JSX.Element {
	const [themeMode] = useTheme();
	return (
		<div
			className={classNames(styles.animationContainer, {
				[styles.animationContainer__light]:
					themeMode === THEME_PREFERENCE.light,
			})}
		>
			<svg x="0px" y="0px" style={{ display: "none" }}>
				<symbol id="wave">
					<path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
					<path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
					<path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
					<path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
				</symbol>
			</svg>
			<div className={styles.box}>
				<div
					id="water"
					className={styles.water}
					style={{
						animation: `${styles.fill} ${length}s linear`,
						animationIterationCount: 999,
					}}
				>
					<svg
						viewBox="0 0 560 20"
						className={classNames(
							styles.water_wave,
							styles.water_wave_back
						)}
					>
						<use xlinkHref="#wave"></use>
					</svg>
					<svg
						viewBox="0 0 560 20"
						className={classNames(
							styles.water_wave,
							styles.water_wave_front
						)}
					>
						<use xlinkHref="#wave"></use>
					</svg>
				</div>
			</div>
		</div>
	);
}

export function ANIMATION_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo>
					<FlaskLoadingAnimation length={5} />
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
