import { IconName } from "../GenemodIcon/GenemodIconRegistry";
export type FeatureType = { icon: IconName; name: string };
export const PLAN_GUIDE_DATA = Object.freeze({
	academia: {
		image: "academia",
		title: "Academia",
		description:
			"Designed for academic research labs looking to scale at a fraction of the cost",
		discount: 80,
		link: "https://genemod.net/application/academia",
		eligible: [
			"10 users or fewer",
			"New to a paid Genemod plan",
			"All users required to use an .edu or equivalent email address",
		] as string[],
		features: [
			{
				icon: "freezer",
				name: "Inventory",
			},
			{
				icon: "active-projects",
				name: "Notebook",
			},
			{
				icon: "protocol-library",
				name: "Protocol library",
			},
			{
				icon: "consumable",
				name: "Consumables",
			},
			{
				icon: "stock",
				name: "Stock tracker",
			},
			{
				icon: "edit",
				name: "Custom item types",
			},
			{
				icon: "search",
				name: "Advanced search",
			},
		] as FeatureType[],
	},
	institution: {
		image: "institution",
		title: "Institution",
		description:
			"Best value for research teams at clinical units and nonprofit institutions",
		discount: undefined,
		link: "https://genemod.net/application/institution",
		eligible: [
			"Clinical research unit",
			"Nonprofits",
			"Core facilities",
			"Non-governmental orgs (NGOs)",
		] as string[],
		features: [
			{
				icon: "freezer",
				name: "Inventory",
			},
			{
				icon: "active-projects",
				name: "Notebook",
			},
			{
				icon: "protocol-library",
				name: "Protocol library",
			},
			{
				icon: "consumable",
				name: "Consumables",
			},
			{
				icon: "history",
				name: "Revision history",
			},
			{
				icon: "search",
				name: "Advanced search",
			},
			{
				icon: "stock",
				name: "Stock tracker",
			},
			{
				icon: "box",
				name: "Order management",
			},
			{
				icon: "edit",
				name: "Custom item types",
			},
		] as FeatureType[],
	},
});
