import React, { useState, useEffect } from "react";
import { Typography, Input, Button, GenemodEmailTag } from "@components";
import { WideOnboarding, TransitionScreen } from "@containers/Auth/components/";
import { Fade } from "react-reveal";
import "../../../index.scss";
import "./index.scss";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { validateEmail } from "@helpers/Validators";
import {
	useCheckEmailMutation,
	useGetOrganizationsQuery,
	useInviteUsersMutation,
	useLazyGetWorkspacesQuery,
} from "@redux/team/TeamApi";
import { Select as EmailSelect } from "antdv4";
import classNames from "classnames";
import "../../../index.scss";
import "./index.scss";
import { WorkspaceId } from "@common/types";

export type emailErrorData = {
	error_messages?: string[];
	invalid_emails?: string[];
};

/**
 * Registration form for inviting teammates
 * @function
 * Accepts boolean indicating if invites were sent
 * */
export function InviteTeam(): JSX.Element {
	const [emails, setEmails] = useState<string[]>(["", "", ""]);
	const [isValid, setValid] = useState<any>(false);
	const [loading, setLoading] = useState(false);
	const [switchToSelect, setSwitchToSelect] = useState(false);
	// Error state that gets populated as we type into the tag textarea
	const [emailErrors, setEmailErrors] = useState<emailErrorData>({
		error_messages: [],
		invalid_emails: [],
	});
	// Error state that gets populated after clicking the "Send invitations" button
	const [emailSubmitErrors, setSubmitEmailErrors] = useState<any>([]);
	const history = useHistory();
	const [searchValue, setSearchValue] = useState("");
	const { data: organizations } = useGetOrganizationsQuery();
	// Extend this logic when we support multi org
	const organization = organizations?.[0];
	const organizationId = organization?.id;
	const [success, setSuccess] = useState(false);
	const [inviteUsers] = useInviteUsersMutation();
	const [checkEmail] = useCheckEmailMutation();

	// After orgainzation created,
	// Fetch workspace and get the first id of the first workspace for inviting collleagues
	const [fetchWorkspace] = useLazyGetWorkspacesQuery();
	const [firstWorkspaceUuid, setFirstWorkspaceUuid] = useState<
		WorkspaceId | undefined
	>(undefined);
	useEffect(() => {
		if (organization) {
			fetchWorkspace()
				.unwrap()
				.then((data) => setFirstWorkspaceUuid(data[0].id));
		}
	}, [organization]);
	useEffect(() => {
		const validEmails = emails.filter((x) => !!x.trim());
		setValid(
			validEmails.reduce(
				(v: any, email: any) => (!v ? false : validateEmail(email)),
				true
			) && validEmails.length
		);
	}, [emails]);

	useEffect(() => {
		const validEmails = emails.filter((x) => !!x.trim());
		if (switchToSelect === true) {
			if (validEmails.length === 0) {
				setEmails([]);
			} else {
				setEmails(validEmails);
				checkInviteEmail(validEmails)?.then?.((result) => {
					if (result !== true) {
						setEmailErrors({
							error_messages: [],
							invalid_emails: [],
							...result,
						});
					}
				});
			}
		}
	}, [switchToSelect, organizationId]);

	const handleSubmit = async () => {
		if (!organizationId || !firstWorkspaceUuid) return;
		setLoading(true);
		const areEmailsValid = await checkInviteEmail(
			emails.filter((x) => !!x.trim())
		);
		if (areEmailsValid !== true) {
			setLoading(false);
			return;
		}
		const request_list = emails
			.filter((email) => email)
			.map((email) => {
				// is_admin sets to true since newly created team starts with Free plan
				return {
					is_admin: true,
					email: email.trim().toLowerCase(),
					workspaces: [firstWorkspaceUuid],
				};
			});

		inviteUsers({ orgId: organizationId, request_list })
			.unwrap()
			.then(() => {
				setSuccess(true);
			})
			.catch((e: any) => {
				const errRes = e.response;
				if (!errRes) {
					setSubmitEmailErrors("Failed to invite.");
					return;
				}
				/**
				 * errorData in the format of Object[]
				 * [
				 * 	{}, // empty object for valid email
				 * 	{
				 *    email: {
				 *       error: string, // error message for the email
				 *       value: string  // the email value that got passed
				 *    }
				 *  }
				 * ]
				 */
				const errorData = errRes.data;
				const errorStatus = errRes.status;
				if (errorStatus === 400) {
					setSubmitEmailErrors(
						errorData.filter(
							(obj: any) => Object.keys(obj).length !== 0
						)
					);
				} else if (errorStatus === 403) {
					setSubmitEmailErrors(errorData.detail);
				}
			})
			.finally(() => setLoading(false));
	};

	/**
	 * Customized the tag render
	 * @function
	 * @param {Object} props
	 */
	function tagRender(props: any) {
		const { label, onClose } = props;

		return (
			<>
				{emailErrors.invalid_emails && (
					<GenemodEmailTag
						label={label}
						invalid={
							emailErrors.invalid_emails.includes(searchValue) ||
							emailErrors.invalid_emails.includes(label)
						}
						onClose={onClose}
					/>
				)}
			</>
		);
	}

	/**
	 * Called when the email inputs have been changed
	 * @function
	 * @param {String[]} values the current inputs
	 */
	function handleTextAreaChange(values: string[]) {
		//prevent to add empty string in the list
		if (values[values.length - 1] === "") return;
		setSearchValue("");
		setEmailErrors({ error_messages: [], invalid_emails: [] });
		setSubmitEmailErrors([]);
		checkInviteEmail(values)?.then?.((result) => {
			if (result !== true) {
				setEmailErrors({
					error_messages: [],
					invalid_emails: [],
					...result,
				});
			}
		});
		setEmails(values);
	}

	/**
	 * Whether the given email is valid
	 * Criteria for a valid email:
	 * 1. Not existing in the current team
	 * 2. Follows the email format
	 * 3. Not part of an existing team
	 * @param {String[]} emails list of emails to be checked upon
	 * @returns a promise for the validity of the email
	 */
	const checkInviteEmail = (emails: string[]) => {
		if (!organizationId) return;
		const emails_data = emails.map((value) => {
			return {
				email: value,
			};
		});

		const promise = checkEmail({
			orgId: organizationId,
			emails: emails_data,
		})
			.unwrap()
			.then(() => {
				return true;
			})
			.catch((err) => {
				const errRes = err.response;
				if (!errRes) {
					return {
						error_messages: ["Failed to check email."],
						invalid_emails: [emails],
					};
				}
				const errStatus = errRes.status;
				const errData = errRes.data;
				if (errStatus === 400) {
					return errData;
				} else if (errStatus === 404) {
					return {
						error_messages: ["Failed to check email."],
						invalid_emails: [emails],
					};
				}
			});
		return promise;
	};

	/**
	 * Stops the propagation when users press "ENTER" and there is no input (without tags)
	 * @function
	 */
	function handleKeyPress(e: any) {
		if (e.key === "Enter") {
			const item_exist = emails.find((v) => v === searchValue);
			if (!searchValue || item_exist) {
				//prevent to reselect item so that preventing the delete item with enter
				setSearchValue("");
				e.stopPropagation();
			}
		}
	}

	return (
		<>
			{success ? (
				<TransitionScreen
					timeout={3}
					onFinish={() => history.push("/app/dashboard")}
				/>
			) : (
				<WideOnboarding wrapperClassName="genemod-invite-team-wrapper">
					<div>
						<Fade>
							<Typography
								variant="title"
								bold
								style={{
									marginBottom: 32,
									textAlign: "center",
								}}
							>
								Invite your colleagues
							</Typography>
							{!switchToSelect ? (
								<div>
									{emails.map((email, index) => {
										const handleChange = (
											e: React.ChangeEvent<HTMLInputElement>
										) => {
											setEmailErrors({
												error_messages: [],
												invalid_emails: [],
											});
											setSubmitEmailErrors([]);
											const temp = [...emails];
											temp[index] = e.target.value;
											setEmails(temp);
										};

										const errorMsgObj =
											typeof emailSubmitErrors !==
												"string" &&
											emailSubmitErrors.find(
												(e: any) =>
													e.email.value === email
											);

										return (
											<Input
												key={index}
												value={email}
												onChange={handleChange}
												placeholder="name@company.com"
												onBlur={(e: any) => {
													if (!organizationId) return;
													checkEmail({
														orgId: organizationId,
														emails: [
															{
																email: e
																	.currentTarget
																	.value,
															},
														],
													})
														.unwrap()
														.catch((err) => {
															setEmailErrors(
																err.data
															);
														});
												}}
												validators={[
													{
														validator: (v) =>
															// If empty, leave it alone :)
															!v.trim()
																? true
																: validateEmail(
																		v
																  ),
														error: "Please enter a valid email",
													},
												]}
												error={errorMsgObj?.email.error}
												wrapperProps={{
													style: {
														marginBottom: 36,
													},
												}}
											/>
										);
									})}
									<Button
										type="text"
										onClick={() => {
											setSwitchToSelect(true);
											setSubmitEmailErrors([]);
										}}
										dataCy="add-many-at-once-btn"
									>
										Add many at once
									</Button>
								</div>
							) : (
								<div>
									<EmailSelect
										value={emails}
										mode="tags"
										className={classNames(
											"email-area",
											{
												["email-area-error"]:
													emailErrors.error_messages &&
													emailErrors.error_messages
														.length > 0,
											},
											{
												["email-area-filled"]:
													emails.length !== 0,
											}
										)}
										placeholder="Type or paste emails here. Separate emails by a space."
										tagRender={tagRender}
										onChange={handleTextAreaChange}
										onInputKeyDown={handleKeyPress}
										dropdownStyle={{ display: "none" }}
										tokenSeparators={[" ", ","]}
										searchValue={searchValue}
										onSearch={(v: string) =>
											setSearchValue(v)
										}
										data-cy="email-input"
									/>
								</div>
							)}
							<Typography
								variant="caption"
								className="email-errors"
							>
								{emailErrors.error_messages &&
									emailErrors.error_messages.join(" ")}
							</Typography>
						</Fade>

						<div
							style={{
								marginTop: 16,
							}}
						>
							<Button
								style={{
									justifyContent: "center",
									marginBottom: 20,
								}}
								stretch
								disabled={
									!isValid ||
									emailSubmitErrors.length > 0 ||
									!!emailErrors.invalid_emails?.length
								}
								onClick={handleSubmit}
								loading={loading}
								dataCy="send-invites"
							>
								Send invitations
							</Button>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
								}}
							>
								<Button
									dataCy="skip-invite"
									type="text"
									onClick={() => {
										setSuccess(true);
									}}
								>
									Skip for now
								</Button>
							</div>
						</div>
					</div>
				</WideOnboarding>
			)}
		</>
	);
}
