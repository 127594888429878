import { NominalUuid } from "@helpers/TypeHelpers";
import { ExperimentId } from "./ExperimentId";
import { GenemodDocumentUUID } from "./GenemodDocumentUUID";

export type ExperimentDocumentLinkId = NominalUuid<"ExperimentDocumentLink">;

export type ExperimentDocumentLink = {
	id: ExperimentDocumentLinkId;
	document: GenemodDocumentUUID;
	linked_document: ExperimentId;
};

export type ExperimentDocumentLinkPayload = Omit<ExperimentDocumentLink, "id">;
