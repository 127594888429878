import { NominalType } from "@helpers/TypeHelpers";
import moment from "moment";

/**
 * Example: 2021-08-25T07:13:47.435Z
 */
export type ISOString = NominalType<"ISOString", string>;

/**
 * Example: 2021-08-24
 */
export type DateStringDash =
	`${number}${number}${number}${number}-${number}${number}-${number}${number}`;

/**
 * Example: 08/24/2021
 */
export type DateStringSlash =
	`${number}${number}/${number}${number}/${number}${number}${number}${number}`;

/**
 * Format ISOstring into a sentence format.
 * Ex) returns September 13th 2021, at 3:34 PM
 */
export function formatDateString(date: ISOString) {
	return (
		moment(date).format("MMMM Do YYYY,") +
		" at " +
		moment(date).format("h:mm A")
	);
}

/**
 * Format ISOstring into a sentence format.
 * Ex) returns Sep 13, 2021 at 3:34 PM
 */
export function formatShortDateString(date: ISOString) {
	return moment(date).format("MMM DD, YYYY [ at ] h:mm A");
}

/**
 * Convert a date string YYYY-MM-DD into an iso formatted string.
 * This will ensure the datestring is parsed into the correct timezone.
 */
export function dateStringToISOString(dateString: DateStringDash): ISOString {
	return moment(dateString, "YYYY-MM-DD").toISOString() as ISOString;
}
