import { ProtocolVersionId, ProtocolId, ProjectProps } from "@common/types";
import { appSelector } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ProtocolSettings = {
	protocolId: ProtocolId;
	protocolVersionId?: ProtocolVersionId;
	editingMode?: boolean;
};

type ProjectSettings = {
	/** if projectId is undefined open GlobalProjectModal */
	projectId?: number;
	tab?: "managers" | "details";
	setRestoreProject?: (project: ProjectProps | null) => void;
};

type ExperimentSettings = {
	experimentId?: number;
	tab?: "details" | "contributors";
};

function getInitialSettingsState() {
	return {
		projectSettings: null as ProjectSettings | null,
		protocolSettings: null as ProtocolSettings | null,
		experimentSettings: null as ExperimentSettings | null,
		labelsSettings: false,
	};
}

export const pmSettingsSlice = createSlice({
	name: "pmSettings",
	initialState: getInitialSettingsState(),
	reducers: {
		setShowProjectSettings: (
			state,
			action: PayloadAction<ProjectSettings | null>
		) => {
			state = getInitialSettingsState();
			state.projectSettings = action.payload;
			return state;
		},
		setShowProtocolSettings: (
			state,
			action: PayloadAction<ProtocolSettings | null>
		) => {
			state = getInitialSettingsState();
			state.protocolSettings = action.payload;
			return state;
		},
		setShowExperimentSettings: (
			state,
			action: PayloadAction<ExperimentSettings | null>
		) => {
			state = getInitialSettingsState();
			state.experimentSettings = action.payload;
			return state;
		},
		setShowLabelsSettings: (state, action: PayloadAction<boolean>) => {
			state = getInitialSettingsState();
			state.labelsSettings = action.payload;
			return state;
		},
		closeAllPmSettings: (state) => {
			state = getInitialSettingsState();
			return state;
		},
	},
});

/** return opened settings panel */
export const getOpenedPmSettingPanel = () => {
	return appSelector((state) =>
		Object.keys(state.pm.pmSettings).find(
			(pmSetting: string) =>
				!!state.pm.pmSettings[
					pmSetting as keyof typeof state.pm.pmSettings
				]
		)
	);
};

export const {
	setShowLabelsSettings,
	setShowProjectSettings,
	setShowExperimentSettings,
	setShowProtocolSettings,
	closeAllPmSettings,
} = pmSettingsSlice.actions;
