import {
	Contributor,
	formatCustomId,
	Item,
	ItemPasteResponse,
} from "@common/types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

interface LooseObject {
	[key: string]: any;
}

/**
 * Helper functions for box data list
 * @module @helpers/BoxHelper
 */

/**
 * Extract the minimum and maximum id in given array.
 *
 * @example
 * const boxItems = [{ id: 2, ... }, { id: 5, ...}, { id: 60, ...}];
 *
 * const result = getIdRange(boxItems);
 * "2 - 50"
 *
 * @param {Array<Item>} boxItems An array contains the box items inside the target box.
 * @returns {""} If passed in parameter is null, return empty string.
 * @returns {String} A string consists the minimum id and the maximum id in the boxItems array.
 */
export function getIdRange(boxItems: Item[]) {
	if (!boxItems) {
		return "";
	}
	if (boxItems.length === 0) {
		return "-";
	} else {
		let minID = Number.MAX_SAFE_INTEGER;
		let maxID = Number.MIN_SAFE_INTEGER;
		let prefix = "";
		for (const item of boxItems) {
			if (!prefix) prefix = item.organization_prefix;
			minID = item.custom_id < minID ? item.custom_id : minID;
			maxID = item.custom_id > maxID ? item.custom_id : maxID;
		}

		return (
			formatCustomId(prefix, minID) +
			" - " +
			formatCustomId(prefix, maxID)
		);
	}
}

/**
 * Get total number of items in box
 *
 * @example
 * const boxItems = [{item}, {item}, {item}}];
 *
 * const result = getItemCount(boxItems);
 * <div>
 *  <UserAvatar/><div>name</div>
 * </div>
 *
 *
 * @param {Array<Item>} boxItems An array contains the box items inside the target box.
 * @returns {""} If passed in parameter is null, return String "0 items".
 * @returns {String} A string consists the number of items in box.
 */

export function getItemCount(boxItems: Item[]) {
	if (!boxItems) {
		return "0 items";
	}
	if (boxItems.length === 1) {
		return "1 item";
	}
	return boxItems.length + " items";
}

/**
 * Grouping the Box contributors based on boxItems
 *
 * @example
 * const boxItems = [{item}, {item}, {item}}];
 *
 * const result = [{
 * 		count: 2,
 * 		user: {
 * 			id: 3,
 * 			first_name: "test",
 * 			last_name: "user"
 * 		}
 * }, ...]
 *
 *
 * @param {Array<Item>} boxItems An array contains the box items inside the target box.
 * @returns {Array<Contributor>} An array of contributors for the box page.
 */
export function groupBoxItemsIntoContributors(boxItems: Item[]) {
	const contributorsDict: LooseObject = {};
	const contributorsArray = [] as Contributor[];

	// count contribution count per user
	boxItems.forEach((item) => {
		if (item.created_by?.id) {
			if (item.created_by.id in contributorsDict) {
				contributorsDict[item.created_by.id]++;
			} else {
				contributorsDict[item.created_by.id] = 1;
			}
		}
	});

	// creating the contibutors array
	Object.keys(contributorsDict).forEach((id) => {
		const currentContirbutorObj = boxItems.find(
			(item) => item.created_by?.id + "" === id
		)?.created_by;
		if (currentContirbutorObj) {
			const tempContributorData = {
				count: contributorsDict[id],
				user: currentContirbutorObj,
			};

			contributorsArray.push(tempContributorData);
		}
	});

	return contributorsArray;
}

/**
 * Merging the response from the pasteItems dispatch witht the current boxItems
 *
 * @example
 * const boxItems = [{item}, {item}, {item}}];
 * const pasteRespomse = [{
 * 		toDelete: false,
 * 		item: {item}
 * }, ...]
 *
 * const result = [{
 * 		count: 2,
 * 		user: {
 * 			id: 3,
 * 			first_name: "test",
 * 			last_name: "user"
 * 		}
 * }, ...]
 *
 *
 * @param {Array<Item>} boxItems An array contains the box items inside the target box.
 * @param {Array<ItemPasteResponse>} pasteResponse the response from pasting box items
 * @returns {Array<Item>} An array of items for the box.
 */
export function mergeBoxItemsWithPasteResponse(
	boxItems: Item[],
	pasteResponse: ItemPasteResponse[]
) {
	const createdItems = [] as Item[];
	const deletedItems = [] as Item["id"][];
	pasteResponse.forEach((pastedItem) => {
		if (pastedItem.toDelete) {
			deletedItems.push(pastedItem.item.id);
		} else {
			createdItems.push(pastedItem.item);
		}
	});

	const newBoxItems = [
		...boxItems.filter((item) => !deletedItems.includes(item.id)),
		...createdItems,
	];
	return newBoxItems;
}

const formatData = (item: Partial<Item>) => {
	if (!item.added_at) return null;

	const formattedDatetimeWithZZ = dayjs(item.added_at)
		.utcOffset(-8, true)
		.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZZ");
	const formattedDatetime =
		formattedDatetimeWithZZ.slice(0, -2) +
		":" +
		formattedDatetimeWithZZ.slice(-2);

	return formattedDatetime;
};
/** Gets back end format for item */
export const getItemBackendFormat = (item: Partial<Item>): Partial<Item> => {
	const data = {
		...item,
		name: (item.name || "").trim(),
		...(item.expiration_date && {
			expiration_date: dayjs(item.expiration_date).format("YYYY-MM-DD"),
		}),
		...(item.added_at && { added_at: formatData(item) }),
		type_data: item.type_data || {},
	};
	return data as any;
};
