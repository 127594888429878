import React, { useRef, useState } from "react";
import {
	Button,
	Demo,
	DemoSection,
	DemoWrapper,
	Typography,
	Notification,
} from "..";
import styles from "./NPSFeedback.module.scss";
import cn from "classnames";
import TextArea from "../TextArea/TextArea";
import { Modal } from "../Modal/Modal";
import { RATING_MAP } from "./data";
import { NPSFeedback } from "@common/types";
import { useFeedbackCreateMutation } from "@redux/Dashboard/DashboardApiSlice";
import { useCommonModalState } from "@redux/CommonModals/hooks";

type RatingAndCommentProps = {
	npsFeedback: NPSFeedback;
	onChange: (feedback: NPSFeedback) => void;
	showFeedbackInput?: boolean;
	setShowFeedbackInput?: (value: boolean) => void;
};

export function RatingAndComment({
	npsFeedback,
	onChange,
	showFeedbackInput = true,
	setShowFeedbackInput,
}: RatingAndCommentProps) {
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	return (
		<div className={styles.RatingAndCommentWrapper}>
			<div className={styles.ratings} data-cy="feedback-rating">
				{Object.keys(RATING_MAP).map((ratingKey) => {
					const value = +ratingKey as keyof typeof RATING_MAP;
					return (
						<div
							key={value}
							className={cn(styles.emojiContainer, {
								[styles.emojiContainer__selected]:
									value === npsFeedback.score,
							})}
							onClick={() => {
								onChange({ ...npsFeedback, score: value });
								setShowFeedbackInput?.(true);
								textAreaRef.current?.focus();
							}}
						>
							<div className={styles.emoji}>
								<img
									src={RATING_MAP[value].emoji}
									alt={`rating-${value}`}
								/>
							</div>
							<Typography
								variant="caption"
								color="text-secondary"
								className={styles.emojiPlaceholder}
							>
								{RATING_MAP[value].placeholder}
							</Typography>
						</div>
					);
				})}
			</div>
			{showFeedbackInput && (
				<TextArea
					forwardedRef={textAreaRef}
					autoFocus={!!npsFeedback.score}
					placeholder={"Tell us more…"}
					style={{ minHeight: 70 }}
					wrapperProps={{ style: { marginBottom: 0 } }}
					value={npsFeedback.feedback}
					onChange={(e) =>
						onChange({ ...npsFeedback, feedback: e.target.value })
					}
					data-cy="feedback-input"
				/>
			)}
		</div>
	);
}

export function NPSFeedbackModal() {
	const [npsFeedback, setNPSFeedback] = useState<NPSFeedback>({
		score: undefined,
		feedback: "",
	});
	const [feedbackCreate] = useFeedbackCreateMutation();

	const { isNPSFeedbackModalVisible, closeNPSFeedbackModal } =
		useCommonModalState("NPSFeedbackModal");

	const handleSubmit = () => {
		if (!npsFeedback.score) return;
		feedbackCreate(npsFeedback)
			.unwrap()
			.then((feedback) => {
				Notification.success({
					message: `Thank you, ${feedback.owner.first_name}! We have received your feedback.`,
				});
				handleClose();
			})
			.catch(() => {
				Notification.warning({
					message:
						"Failed to submit the feedback. Try again or contact us if it continues.",
				});
			});
	};

	const handleClose = () => {
		closeNPSFeedbackModal();
		setNPSFeedback({
			score: undefined,
			feedback: "",
		});
	};

	return (
		<Modal
			dataCy="nps-feedback-modal"
			visible={isNPSFeedbackModalVisible}
			onCancel={() => handleClose()}
			hideCancelButton
			okText="Submit"
			title={
				<Typography variant="title" style={{ marginLeft: 8 }}>
					Share your thoughts about Genemod
				</Typography>
			}
			onOk={() => handleSubmit()}
			okButtonProps={{ disabled: !npsFeedback.score }}
		>
			<div data-cy="nps-feedback-modal-content">
				<Typography
					variant="label"
					color="text-secondary"
					style={{ marginBottom: 16, textAlign: "center" }}
				>
					How likely would you recommend Genemod to your colleagues?
				</Typography>
				<RatingAndComment
					npsFeedback={npsFeedback}
					onChange={setNPSFeedback}
				/>
			</div>
		</Modal>
	);
}

export function RATING_AND_COMMENT_DEMO() {
	const { openNPSFeedbackModal } = useCommonModalState("NPSFeedbackModal");
	const [npsFeedback, setNPSFeedback] = useState<NPSFeedback>({
		score: undefined,
		feedback: "",
	});
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Rating and Comment">
					<RatingAndComment
						npsFeedback={npsFeedback}
						onChange={setNPSFeedback}
					/>
				</Demo>
			</DemoSection>
			<DemoSection>
				<Demo title="Rating Modal">
					<Button onClick={() => openNPSFeedbackModal()}>
						Click to open Modal
					</Button>
				</Demo>
				<NPSFeedbackModal />
			</DemoSection>
		</DemoWrapper>
	);
}
