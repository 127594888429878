import React, { useState } from "react";
import { Segmented as AntdSegmented, SegmentedProps } from "antdv5";
import { Typography } from "@common/components";
import { Demo, DemoSection, DemoWrapper } from "@components";
import styles from "./SegmentedV2.module.scss";
import classNames from "classnames";

export const SegmentedV2 = ({
	...props
}: Omit<SegmentedProps, "ref"> & React.RefAttributes<HTMLDivElement>) => {
	return (
		<AntdSegmented
			{...props}
			className={classNames(styles.segmented, props.className)}
		/>
	);
};

export default SegmentedV2;

export const SEGMENTED_DEMO_V2 = (): JSX.Element => {
	const [selectedStatus, setSelectedStatus] = useState(1);

	const filterLabel = [
		{ key: 1, title: "All" },
		{
			key: 2,
			title: "Requested",
		},
		{
			key: 3,
			title: "Processing",
		},
		{
			key: 4,
			title: "Complete",
		},
	];

	const filterVal = filterLabel.map((val) => ({
		label: (
			<div key={val.key}>
				<Typography
					variant="label"
					style={{
						fontFamily: "var(--font-family-lato)",
					}}
				>
					{val.title}
				</Typography>
			</div>
		),
		value: val.key,
	}));
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage"
					description="Basic Segmented V2 component"
				>
					<SegmentedV2
						value={selectedStatus}
						options={filterVal}
						onChange={(val) => {
							setSelectedStatus(Number(val));
						}}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
};
