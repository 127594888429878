import { DatePickerProps } from "antdv5";
import { DatePicker } from "antdv5/lib";
import classNames from "classnames";
import React from "react";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import styles from "./CustomDatePicker.module.scss";

type Props = DatePickerProps & { dataCy?: string };

const CustomDatePickerV3 = ({ dataCy, open = true, ...props }: Props) => {
	return (
		<div className={styles.datepicker}>
			<DatePicker
				allowClear={{
					clearIcon: (
						<GenemodIcon
							name="exit-circle"
							color="text-tertiary-v2"
						/>
					),
				}}
				{...props}
				open={open}
				popupClassName={classNames(styles.popup, props.popupClassName)}
				data-cy={dataCy}
			/>
		</div>
	);
};

export default CustomDatePickerV3;
