import { AtLeast, NominalType, NominalId } from "@helpers/TypeHelpers";
import { UUID } from "./Common";
import { ISOString } from "./Date";
import { OrganizationUser } from "./OrganizationUser";

export type WorkspaceUserStatus = NominalId<"WorkspaceUserStatus">;

export const WORKSPACE_USER_STATUS_HIEARCHY = {
	Active: 0 as WorkspaceUserStatus,
	Deactivated: 1 as WorkspaceUserStatus,
} as const;

export type WorkspaceUserRole = NominalId<"WorkspaceUserRole">;

export const WORKSPACE_USER_ROLE_HIEARCHY = {
	Admin: 0 as WorkspaceUserRole,
	Member: 1 as WorkspaceUserRole,
} as const;

export type WorkspaceStatus = NominalId<"WorkspaceStatus">;

export const WORKSPACE_STATUS_HIERARCHY = {
	Active: 0 as WorkspaceStatus,
	Deactivated: 1 as WorkspaceStatus,
} as const;

export type WorkspaceUserId = NominalType<"workspaceuser_id", string>;

export type WorkspaceUser = {
	id: WorkspaceUserId;
	role: WorkspaceUserRole;
	created_at: ISOString;
	workspace: UUID;
	org_user: OrganizationUser;
};

export type WorkspaceId = NominalType<"workspace_id", string>;

export type Workspace = {
	id: WorkspaceId;
	workspace_users: WorkspaceUser[];
	name: string;
	created_at: ISOString;
	status: WorkspaceStatus;
	organization: number;
};

export type WorkspaceForUser = Omit<
	Workspace,
	"workspace_users" | "created_at"
> & {
	admins: number;
	members: number;
};

export type PatchOrgUserData = AtLeast<OrganizationUser, "id" | "organization">;

export type PatchWorkSpaceUserEnterpriseData = {
	workspace_user_id: UUID;
	role?: WorkspaceUserRole;
	status?: WorkspaceUserStatus;
};
