import { SharingObjectButtons } from "@common/components/Sharing/SharingObjectButtons";
import { useTheme } from "@common/components/Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "@common/components/Theme/context/ThemeTypes";
import { WorkspaceSelectorModal } from "@common/components/WorkspaceSelector/index";
import { useSharingState } from "@common/helpers/Hooks/SharedObjectHooks";
import { useHistory, useLocation } from "@common/helpers/Hooks/UseRouterDom";
import { Color, ColorCssVarMap } from "@common/styles/Colors";
import {
	Column,
	Row,
	SharedInternalObj,
	SharingOptions,
	Space,
	SpaceCreateDto,
	SpaceId,
	SpaceTemplateName,
	Workspace,
	WorkspaceId,
	toSharedWriteDto,
} from "@common/types";
import {
	GenemodIcon,
	Input,
	Modal,
	Notification,
	TabList,
	TextArea,
	Typography,
} from "@components";
import SpaceCard, {
	ACTIVE_COLOR_LIST,
	COLOR_LIST,
	IMG_URL_LIST,
} from "@containers/Freezer/Consumables/SpaceCard/SpaceCard";
import { useCurrentWorkspaceUuid } from "@helpers/Hooks/UseCurrentWorkspaceUuid";
import { useCurrentPlanNameHook } from "@helpers/Hooks/featureRestrictionHook";
import { useUrlIsEditMode } from "@helpers/URLParams";
import {
	useApplySpaceTemplateMutation,
	useSpaceCreateMutation,
} from "@redux/freezer/ConsumableApiSlice";
import { useAppDispatch } from "@redux/store";
import { useOrganizationRouter } from "@root/AppRouter";
import classNames from "classnames";
import React, { createContext, useContext, useEffect, useState } from "react";
import { FREEZER_PATHS } from "../../index";
import styles from "./AddSpaceModal.module.scss";
import SPACE_TEMPLATE_1 from "./templates/space_template_1.png";
import SPACE_TEMPLATE_1_LIGHT from "./templates/space_template_1_light.png";
import SPACE_TEMPLATE_2 from "./templates/space_template_2.png";
import SPACE_TEMPLATE_2_LIGHT from "./templates/space_template_2_light.png";
import SPACE_TEMPLATE_3 from "./templates/space_template_3.png";
import SPACE_TEMPLATE_3_LIGHT from "./templates/space_template_3_light.png";
import SPACE_TEMPLATE_4 from "./templates/space_template_4.png";
import SPACE_TEMPLATE_4_LIGHT from "./templates/space_template_4_light.png";
import SPACE_TEMPLATE_5 from "./templates/space_template_5.png";
import SPACE_TEMPLATE_5_LIGHT from "./templates/space_template_5_light.png";
import SPACE_TEMPLATE_6 from "./templates/space_template_6.png";
import SPACE_TEMPLATE_6_LIGHT from "./templates/space_template_6_light.png";
import InputV3 from "@components/InputV3/InputV3";

type AddSpaceContextProps = {
	spaceTemplate: SpaceTemplateName;
	setSpaceTemplate: (templateName: SpaceTemplateName) => void;
	loading: boolean;
	setLoading: (isLoading: boolean) => void;
};

const AddSpaceContext = createContext<AddSpaceContextProps>({
	spaceTemplate: "lab_1",
	setSpaceTemplate: () => {},
	loading: false,
	setLoading: () => {},
});

export const AddSpaceContextProvider = (props: any) => {
	const [spaceTemplate, setSpaceTemplate] =
		useState<SpaceTemplateName>("lab_1");
	const [loading, setLoading] = useState<boolean>(false);

	return (
		<AddSpaceContext.Provider
			value={{ spaceTemplate, setSpaceTemplate, loading, setLoading }}
		>
			{props.children}
		</AddSpaceContext.Provider>
	);
};

type AddSpaceModalProps = {
	visible: boolean;
	onClose: () => void;
};

const DEFAULT_SPACE: Partial<Space> = {
	name: "",
	description: "",
	background: 0,
	is_archived: false,
	width: 10 as Column,
	height: 10 as Row,
} as const;

export default function AddSpaceModal({
	visible: visibleProp,
	onClose,
}: AddSpaceModalProps): JSX.Element {
	const history = useHistory();
	const { appendBaseUrl } = useOrganizationRouter();
	const currentWorkspaceId = useCurrentWorkspaceUuid();
	const currentWorkspace = { id: currentWorkspaceId } as Workspace;
	const [modalStep, setModalStep] = useState<number>(0);
	const [tempState, setTempState] = useState<Partial<Space>>({
		...DEFAULT_SPACE,
	});
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [, setEditMode] = useUrlIsEditMode();

	const [createSpace] = useSpaceCreateMutation();

	const { spaceTemplate, setSpaceTemplate, loading, setLoading } =
		useContext(AddSpaceContext);

	// Sharing state
	const {
		selectedWorkspaces,
		setSelectedWorkspaces,
		selectedSharingOption,
		setSelectedSharingOption,
		sharedObjectFields,
	} = useSharingState(tempState as SharedInternalObj<Space>, visibleProp);

	const [workspaceModalVisible, setWorkspaceModalVisible] = useState(false);

	const visible = visibleProp && !workspaceModalVisible;

	const [applySpaceTemplateToSpace] = useApplySpaceTemplateMutation();

	const closeModal = () => {
		setTempState({ ...DEFAULT_SPACE });
		if (modalStep > 0) setEditMode(true);
		setModalStep(0);
		setSelectedWorkspaces([currentWorkspace.id]);
		setWorkspaceModalVisible(false);
		onClose();
	};

	const handleAppyTemplate = () => {
		const pathNameTokens = location.pathname.split("/");
		const spaceID = parseInt(
			pathNameTokens[pathNameTokens.length - 1]
		) as SpaceId;
		const failure = (err?: string) => {
			Notification.warning({
				message: "Failed to apply template to space",
			});
		};
		setLoading(true);
		applySpaceTemplateToSpace({
			id: spaceID,
			templateName: spaceTemplate,
		})
			.unwrap()
			.then(() => {
				closeModal();
				setSpaceTemplate("lab_1");
				setLoading(false);
				setEditMode(false);
			})
			.catch(() => failure());
	};

	useEffect(() => {
		if (!visibleProp) {
			closeModal();
		}
	}, [visibleProp]);

	const onSubmit = (spaceData: Partial<Space>) => {
		const { sharing, ...rest } = spaceData;
		if (!sharing) return;

		const requestBody = {
			...rest,
			...sharedObjectFields,
		} as Space;

		createSpace(toSharedWriteDto<SpaceCreateDto>(requestBody))
			.unwrap()
			.then((space) => {
				history.push({
					pathname: appendBaseUrl(
						FREEZER_PATHS.SPACE.replace(":id", space.id + "")
					),
				});
			})
			.catch(() =>
				Notification.warning({
					message: "Failed to create the Space",
				})
			);
	};

	const validateForm = () => {
		return !!tempState.name && !!tempState.name.trim();
	};

	const modalContents = [
		{
			content: (
				<AddSpaceModalStepOne
					setWorkspaceOpen={() => setWorkspaceModalVisible(true)}
					selectedVal={selectedWorkspaces}
					tempState={tempState}
					setTempState={setTempState}
					selectedSharingLevelOption={selectedSharingOption}
					setSelectedSharingLevelOption={setSelectedSharingOption}
					setSelectedWorkspaces={setSelectedWorkspaces}
				/>
			),
			onOk: () => {
				onSubmit({
					...tempState,
					name: tempState.name?.trim(),
					sharing: selectedSharingOption,
				});
				setModalStep(1);
			},
			okText: "Continue",
			cancelText: null,
			onCancel: () => {
				closeModal();
			},
			title: "New consumable space",
			width: 592,
		},
		{
			content: <AddSpaceModalStepTwo />,
			onOk: () => {
				handleAppyTemplate();
			},
			okText: "Use this template",
			cancelText: "Build from scratch",
			onCancel: () => {
				closeModal();
			},
			title: "Choose a layout template",
			width: 664,
		},
	];

	return (
		<>
			<Modal
				visible={visible}
				onOk={modalContents[modalStep].onOk}
				onCancel={modalContents[modalStep].onCancel || closeModal}
				title={modalContents[modalStep].title}
				okText={modalContents[modalStep].okText}
				hideOkButton={!selectedSharingOption}
				cancelButtonProps={{
					style: {
						display: !modalContents[modalStep].cancelText
							? "none"
							: "",
						marginRight: 8,
					},
					type: "text" as unknown as any,
				}}
				cancelText={modalContents[modalStep].cancelText || ""}
				okButtonProps={{
					disabled: !validateForm(),
					loading: loading,
					style: {
						backgroundColor: "var(--action-default)",
					},
				}}
				width={modalContents[modalStep].width}
			>
				{modalContents[modalStep].content}
			</Modal>

			<WorkspaceSelectorModal
				visible={workspaceModalVisible}
				onCancel={() => setWorkspaceModalVisible(false)}
				hideOkButton={true}
				hideCancelButton={true}
				selectedVal={selectedWorkspaces}
				setSelectedVal={setSelectedWorkspaces}
			/>
		</>
	);
}

type AddSpaceModalStepOneProps = {
	tempState: Partial<Space>;
	setTempState: (prev: Partial<Space>) => void;
	selectedSharingLevelOption: SharingOptions | undefined;
	setSelectedSharingLevelOption: (value: SharingOptions | undefined) => void;
	setWorkspaceOpen: () => void;
	selectedVal: WorkspaceId[];
	setSelectedWorkspaces?: (value: WorkspaceId[]) => void;
};

function AddSpaceModalStepOne({
	tempState,
	setTempState,
	selectedSharingLevelOption,
	setSelectedSharingLevelOption,
	setWorkspaceOpen,
	selectedVal,
	setSelectedWorkspaces,
}: AddSpaceModalStepOneProps) {
	const { planName } = useCurrentPlanNameHook();
	const isEnterprise = planName === "enterprise";

	useEffect(() => {
		if (!isEnterprise) {
			setSelectedSharingLevelOption("workspace");
			setTempState({ ...tempState, sharing: "workspace" });
		}
	}, [isEnterprise]);

	return (
		<>
			{isEnterprise && (
				<SharingObjectButtons
					setWorkspaceOpen={setWorkspaceOpen}
					selectedVal={selectedVal}
					value={selectedSharingLevelOption}
					objectType="space"
					onChange={(value) => {
						setSelectedSharingLevelOption(value);
						setTempState({ ...tempState, sharing: value });
					}}
					showBottomSeparator={!!selectedSharingLevelOption}
					setSelectedWorkspaces={setSelectedWorkspaces}
				/>
			)}
			{selectedSharingLevelOption && (
				<>
					{selectedSharingLevelOption !== "org" && (
						<>
							<Typography
								style={{ marginBottom: 4 }}
								variant="headline5"
								color="text-secondary-v2"
								medium
							>
								Set up your space
							</Typography>
							<Typography
								style={{ marginBottom: 24 }}
								color="text-tertiary"
								variant="footnote"
							>
								You can change these details anytime in your
								space settings.
							</Typography>
						</>
					)}
					<div className={styles.spaceName}>
						<InputV3
							value={tempState.name}
							onChange={(e) =>
								setTempState({
									...tempState,
									name: e.target.value,
								})
							}
							label="Space name *"
							autoFocus
						/>
					</div>
					<TextArea
						value={tempState.description}
						onChange={(e) =>
							setTempState({
								...tempState,
								description: e.target.value,
							})
						}
						label="Description"
						placeholder="Keep everyone on the same page"
					/>
					<div className={styles.spaceBackground}>
						<SelectSpaceBackground
							tempState={tempState}
							setTempState={setTempState}
						/>
					</div>
				</>
			)}
		</>
	);
}

type SelectSpaceBackgroundProps = {
	tempState: Partial<Space>;
	setTempState: (prev: Partial<Space>) => void;
	includePreview?: boolean;
};

export function SelectSpaceBackground({
	tempState,
	setTempState,
	includePreview = false,
}: SelectSpaceBackgroundProps): JSX.Element {
	/** Renders the image or the pure color as the background depending on the given background code */
	const renderBackground = (bg: number, isSelected: boolean) => {
		const colorList = isSelected ? ACTIVE_COLOR_LIST : COLOR_LIST;
		if (bg < 4) {
			return { backgroundColor: ColorCssVarMap[colorList[bg] as Color] };
		} else {
			return {
				backgroundImage: IMG_URL_LIST[bg - 4],
				backgroundColor: "transparent",
				backgroundSize: "cover",
			};
		}
	};
	const nBackgrounds = COLOR_LIST.length + IMG_URL_LIST.length;

	return (
		<>
			<Typography
				variant="body"
				medium
				className={styles.backgroundLabel}
			>
				Background
			</Typography>
			<div style={{ marginLeft: 4 }}>
				{includePreview && (
					<div
						className={classNames(
							styles.backgroundGrid,
							styles.spaceCardPreviewGridModifier
						)}
					>
						<div className={styles.spaceCardPreview}>
							<SpaceCard
								isPreview
								space={
									tempState as Space /* A little bit dangerous */
								}
							/>
						</div>
					</div>
				)}
				<div className={styles.backgroundGrid}>
					{[...Array(nBackgrounds)].map((_, index) => (
						<div
							key={index}
							className={classNames(
								index > 3
									? styles.backgroundSpaceCardWithOutTransparency
									: styles.backgroundSpaceCard,
								{
									[styles.backgroundSpaceCard__selected]:
										tempState.background === index,
								}
							)}
							style={{
								...renderBackground(
									index,
									tempState.background === index
								),
							}}
							onClick={() =>
								setTempState({
									...tempState,
									background: index,
								})
							}
						>
							{tempState.background === index && (
								<GenemodIcon
									className={styles.checkIcon}
									name="checkmark"
									color="text-on-color"
									size="large"
								/>
							)}
						</div>
					))}
				</div>
			</div>
		</>
	);
}

enum SPACE_TEMPLATE_TYPES {
	"Lab" = 0,
	"Room",
}
type Template = {
	name: SpaceTemplateName;
	template_type: SPACE_TEMPLATE_TYPES;
	image: {
		dark: string;
		light: string;
	};
};
const CONSUMABLES_SPACE_TEMPLATES: {
	[key: string]: Template;
} = Object.freeze({
	LAB_1: {
		name: "lab_1",
		template_type: SPACE_TEMPLATE_TYPES.Lab,
		image: {
			dark: SPACE_TEMPLATE_1,
			light: SPACE_TEMPLATE_1_LIGHT,
		},
	},
	LAB_2: {
		name: "lab_2",
		template_type: SPACE_TEMPLATE_TYPES.Lab,
		image: {
			dark: SPACE_TEMPLATE_2,
			light: SPACE_TEMPLATE_2_LIGHT,
		},
	},
	LAB_3: {
		name: "lab_3",
		template_type: SPACE_TEMPLATE_TYPES.Lab,
		image: {
			dark: SPACE_TEMPLATE_3,
			light: SPACE_TEMPLATE_3_LIGHT,
		},
	},
	ROOM_1: {
		name: "room_1",
		template_type: SPACE_TEMPLATE_TYPES.Room,
		image: {
			dark: SPACE_TEMPLATE_4,
			light: SPACE_TEMPLATE_4_LIGHT,
		},
	},
	ROOM_2: {
		name: "room_2",
		template_type: SPACE_TEMPLATE_TYPES.Room,
		image: {
			dark: SPACE_TEMPLATE_5,
			light: SPACE_TEMPLATE_5_LIGHT,
		},
	},
	ROOM_3: {
		name: "room_3",
		template_type: SPACE_TEMPLATE_TYPES.Room,
		image: {
			dark: SPACE_TEMPLATE_6,
			light: SPACE_TEMPLATE_6_LIGHT,
		},
	},
} as const);

function AddSpaceModalStepTwo() {
	const [templateType, setTemplateType] = useState(SPACE_TEMPLATE_TYPES.Lab);
	const [templateIndex, setTemplateIndex] = useState<number>(0);
	const getTemplateOptions = (templateType: SPACE_TEMPLATE_TYPES) => {
		const options = Object.keys(CONSUMABLES_SPACE_TEMPLATES)
			.map((template_key) => CONSUMABLES_SPACE_TEMPLATES[template_key])
			.filter((template) => template.template_type === templateType);
		return options;
	};
	const { setSpaceTemplate } = useContext(AddSpaceContext);

	const templateOptions = getTemplateOptions(templateType);

	useEffect(() => {
		setSpaceTemplate(templateOptions[templateIndex].name);
	}, [templateType, templateIndex]);
	const tabItems = [
		{
			key: SPACE_TEMPLATE_TYPES.Lab,
			tabtitle: SPACE_TEMPLATE_TYPES[0],
			tabcontent: (
				<SpaceTemplateSelector
					templateIndex={templateIndex}
					setTemplateIndex={setTemplateIndex}
					templateOptions={templateOptions}
				/>
			),
		},
		{
			key: SPACE_TEMPLATE_TYPES.Room,
			tabtitle: SPACE_TEMPLATE_TYPES[1],
			tabcontent: (
				<SpaceTemplateSelector
					templateIndex={templateIndex}
					setTemplateIndex={setTemplateIndex}
					templateOptions={templateOptions}
				/>
			),
		},
	];

	return (
		<>
			<Typography variant="body" color="text-secondary-v2">
				You will be able to customize layout in the next step. Start
				with a template below, or skip and design your layout from
				scratch.
			</Typography>
			<div className={styles.templateSelectorContainer}>
				<TabList
					tabListItems={tabItems}
					hasTopGradient={false}
					hasBottomGradient={false}
					activeKey={templateType.toString()}
					onTabClick={(e: any) => {
						setTemplateIndex(0);
						setTemplateType(Number(e));
					}}
					centered
				/>
			</div>
		</>
	);
}

type SpaceTemplateSelectorProps = {
	templateIndex: number;
	setTemplateIndex: (ind: number) => void;
	templateOptions: Template[];
};

function SpaceTemplateSelector({
	templateIndex,
	setTemplateIndex,
	templateOptions,
}: SpaceTemplateSelectorProps) {
	const [themeMode] = useTheme();

	return (
		<>
			<div className={styles.selectorContainer}>
				<div
					className={styles.arrowContainer}
					onClick={() => {
						if (templateIndex > 0)
							setTemplateIndex(templateIndex - 1);
					}}
				>
					<GenemodIcon
						name="chevron-left"
						stroke="text-secondary"
						size="XLarge"
					/>
				</div>
				<div>
					<img
						className={styles.selectedTemplateImage}
						src={
							themeMode === THEME_PREFERENCE.dark
								? templateOptions[templateIndex].image.dark
								: templateOptions[templateIndex].image.light
						}
					/>
					<div className={styles.templateIndexIdentifier}>
						<Typography variant="label">{`${
							templateIndex + 1 + " / " + templateOptions.length
						}`}</Typography>
					</div>
				</div>
				<div
					className={styles.arrowContainer}
					onClick={() => {
						if (templateIndex < templateOptions.length - 1)
							setTemplateIndex(templateIndex + 1);
					}}
				>
					<GenemodIcon
						name="chevron-right"
						size="XLarge"
						stroke="text-secondary"
					/>
				</div>
			</div>
		</>
	);
}
