import React, { useState, useEffect, useRef } from "react";
import styles from "./ItemMetadata.module.scss";
import { GenemodIcon, Typography, LoadingSpinner } from "@components";
import Tags, { Tag } from "@common/components/Metadata/Tags";
import {
	useCustomFieldsMetadataDeleteMutation,
	useCustomFieldsMetadataPatchMutation,
	useCustomFieldsMetadataPostMutation,
	useItemMetadataQuery,
	useTagsMetadataDeleteMutation,
	useTagsMetadataPostMutation,
	useTagsMetadataQuery,
} from "@redux/Metadata/MetadataApiSlice";
import CustomFields from "@common/components/Metadata/CustomFields";
import {
	CustomFields as CustomFieldsType,
	Item,
	UpdateCustomFields,
} from "@common/types";
import ItemInterconectivityMetadata from "@containers/ProjectManagement/components/ProjectViewV2/components/ExperimentMetadata/InterconectivityMetadata/ItemInterconectivityMetadata";
import { useFreezerQuery } from "@redux/inventory/Freezer";
import ItemSharingPreferences from "../ItemSharingPreferences/ItemSharingPreferences";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export function ItemMetadata({ item }: { item?: Item }) {
	if (!item) return <></>;

	const { freezer: freezerId } = item.location || {};

	const { data: freezer, isLoading: isLoadingFreezer } = useFreezerQuery(
		freezerId || skipToken
	);

	const { data: metadata } = useItemMetadataQuery({
		itemId: item.id,
	});

	const { data: tagsList } = useTagsMetadataQuery(null);
	const [tagsMetadataPost] = useTagsMetadataPostMutation();
	const [tagsMetadataDelete] = useTagsMetadataDeleteMutation();

	const [customFieldsMetadataPost] = useCustomFieldsMetadataPostMutation();
	const [customFieldsMetadataPatch] = useCustomFieldsMetadataPatchMutation();
	const [customFieldsMetadataDelete] =
		useCustomFieldsMetadataDeleteMutation();

	const [tags, setTags] = useState<Tag[]>([]);
	const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
	const [newField, setNewField] = useState(false);
	const [customFields, setCustomFields] = useState<CustomFieldsType>({});

	useEffect(() => {
		setSelectedTags(metadata?.tags || []);
	}, [metadata?.tags]);

	useEffect(() => {
		setCustomFields(metadata?.custom_fields || {});
	}, [metadata?.custom_fields]);

	const onRemoveTag = (tagId: number) => {
		if (metadata?.uuid) {
			tagsMetadataDelete({
				tagId,
				metadataUuid: metadata.uuid,
			});
		}
	};

	useEffect(() => {
		if (tagsList) {
			setTags(tagsList);
		}
	}, [tagsList]);

	const onCreateTag = (tagName?: string) => {
		if (!tagName?.length) return;

		if (metadata?.uuid) {
			tagsMetadataPost({
				tags: [{ name: tagName }],
				metadataUuid: metadata.uuid,
			});
		}
	};

	const onSelectTag = (tag: Tag) => {
		if (metadata?.uuid) {
			tagsMetadataPost({
				tags: [tag],
				metadataUuid: metadata.uuid,
			});
		}
		setSelectedTags([...selectedTags, tag]);
	};

	const onAddNewField = (field: CustomFieldsType | undefined) => {
		if (field) {
			setCustomFields({ ...customFields, ...field });
			if (metadata?.uuid) {
				customFieldsMetadataPost({
					customFields: { ...field },
					metadataUuid: metadata?.uuid,
				});
			}
		}
		setNewField(false);
	};

	const onUpdateField = (
		updatedField: UpdateCustomFields,
		currentKey: string
	) => {
		if (updatedField) {
			const { [currentKey]: _, ...rest } = customFields;
			setCustomFields({
				...rest,
				[updatedField.new_key]: { value: updatedField.new_value },
			});
			if (metadata?.uuid) {
				customFieldsMetadataPatch({
					customFields: { ...updatedField },
					metadataUuid: metadata?.uuid,
				});
			}
		}
	};

	const onDeleteField = (customFieldKey: string) => {
		const newCustomFields = { ...customFields };
		delete newCustomFields[customFieldKey];
		setCustomFields(newCustomFields);
		if (metadata?.uuid) {
			customFieldsMetadataDelete({
				customFieldKey: customFieldKey,
				metadataUuid: metadata?.uuid,
			});
		}
	};

	const onAddCustomFieldClick = (event: any) => {
		event.stopPropagation();
		setNewField(true);
	};

	return (
		<>
			<Tags
				tagsList={tags}
				selectedTags={selectedTags}
				onRemoveTag={onRemoveTag}
				onCreateTag={onCreateTag}
				onSelectTag={onSelectTag}
				isArchived={item.is_archived || false}
			/>
			<>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: 9,
						marginTop: 32,
					}}
				>
					<Typography
						variant="headline5"
						bold
						color="text-primary-v2"
					>
						Custom fields
					</Typography>
					<div className={styles.plusIcon}>
						<GenemodIcon
							style={{ justifySelf: "center" }}
							name="plus-circle"
							onClick={onAddCustomFieldClick}
							color="text-secondary-v2"
						/>
					</div>
				</div>
				{metadata ? (
					<CustomFields
						newField={newField}
						fields={customFields}
						onAddNewField={onAddNewField}
						onUpdateField={onUpdateField}
						onDeleteField={onDeleteField}
						isArchived={item.is_archived || false}
					/>
				) : (
					<LoadingSpinner loading />
				)}
			</>
			<>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: 9,
						marginTop: 32,
					}}
				>
					<Typography
						variant="headline5"
						bold
						color="text-primary-v2"
					>
						Interconnectivity
					</Typography>
				</div>
				{metadata ? (
					<ItemInterconectivityMetadata metadata={metadata} />
				) : (
					<LoadingSpinner loading />
				)}
			</>
			<>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: 9,
						marginTop: 32,
					}}
				>
					<Typography
						variant="headline5"
						bold
						color="text-primary-v2"
					>
						Sharing & Permissions
					</Typography>
				</div>
				<div className={styles.sharedDisplay}>
					{!isLoadingFreezer ? (
						<ItemSharingPreferences sharedObject={freezer} />
					) : (
						<LoadingSpinner loading />
					)}
				</div>
			</>
		</>
	);
}
