import React, { LegacyRef, useRef, useState, useEffect } from "react";
import JsBarcode from "jsbarcode";
import styles from "./index.module.scss";
import cn from "classnames";
import {
	DPI,
	LabelDimensions,
	getCanvasDimensions,
} from "@common/types/Barcode";
import {
	Consumable,
	formatCustomId,
	getFullNameFromAvatar,
	Item,
} from "@common/types";
import moment from "moment";
import { Avatar } from "@common/types";

const LABEL_PREFIX = "label-";
export function getPrefixedId(id: string) {
	return `${LABEL_PREFIX}${id}`;
}

type LabelWrapperProps = React.HTMLProps<HTMLDivElement> & {
	labelDimensions: LabelDimensions;
	dpi: DPI;
	padding?: number | string;
};
function LabelWrapper({
	labelDimensions,
	dpi,
	padding,
	...props
}: LabelWrapperProps) {
	const dimensions = getCanvasDimensions(labelDimensions, dpi);
	return (
		<div
			{...props}
			className={cn(styles.labelWrapper, props.className)}
			style={{
				...props.style,
				width: dimensions.width,
				height: dimensions.height,
				padding,
			}}
		/>
	);
}

type LabelTextProps = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
> & {
	text: string;
	bold?: boolean;
};
function LabelText({ text, bold, ...props }: LabelTextProps) {
	let children: React.ReactNode = text;
	if (bold) {
		children = <strong>{text}</strong>;
	}

	return (
		<div {...props} className={cn(props.className, styles.labelText)}>
			{children}
		</div>
	);
}

function ItemName({ ...props }: LabelTextProps) {
	return (
		<LabelText
			{...props}
			bold={true}
			style={{
				fontSize: 20,
				marginBottom: "auto",
				lineHeight: 1,
				...props.style,
			}}
		/>
	);
}

type BarcodeProps = React.DetailedHTMLProps<
	React.ImgHTMLAttributes<HTMLImageElement>,
	HTMLImageElement
> & {
	value: string;
	orientation?: "p" | "portrait" | "l" | "landscape";
	height: React.CSSProperties["height"];
	width: React.CSSProperties["width"];
};
function Barcode({
	value,
	orientation = "l",
	height,
	width,
	...props
}: BarcodeProps) {
	const isVertical = orientation === "portrait" || orientation === "p";
	const ref = useRef<HTMLImageElement | null>(null);
	const [extraStyling, setExtraStyling] =
		useState<React.CSSProperties | null>(isVertical ? null : {});

	/**
	 * jsBarcode doesn't generate vertical barcodes so we need to rotate the
	 * image after it has been generated.
	 *
	 * The image occupies its original space in the DOM after being rotated.
	 * This causes the image to shift other elements around unexpectedly.
	 * We add extra styling to resolve this issue.
	 */
	useEffect(() => {
		if (extraStyling !== null && ref.current !== null) {
			if (isVertical) {
				JsBarcode(ref.current, value, {
					format: "CODE128",
					displayValue: false,
					margin: 0,
					height: ref.current.width,
					width: ref.current.height,
				});
			} else {
				JsBarcode(ref.current, value, {
					format: "CODE128",
					displayValue: false,
					margin: 0,
				});
			}
		}
	}, [extraStyling, ref, isVertical, value]);

	const refFunction: LegacyRef<HTMLImageElement> = (newRef) => {
		if (newRef !== null) {
			if (extraStyling === null) {
				if (isVertical) {
					setExtraStyling({
						height: newRef.width,
						width: newRef.height,
						transform: "rotate(-90deg)",
						transformOrigin: "top left",
						top: newRef.height,
					});
				} else {
					setExtraStyling({});
				}
			}
			ref.current = newRef;
		}
	};

	let image = (
		<img
			{...props}
			style={{
				...props.style,
				height,
				width,
				...extraStyling,
				position: "relative",
				maxWidth: "none",
			}}
			ref={refFunction}
		/>
	);

	if (isVertical && extraStyling !== null) {
		image = (
			<div
				style={{
					width: extraStyling.height,
					height: extraStyling.width,
					position: "relative",
					maxWidth: "none",
				}}
			>
				{image}
			</div>
		);
	}
	return image;
}
type LabelProps = {
	dpi: DPI;
	id: string;
	itemName: string;
	properties: string[];
	dimensions: LabelDimensions;
	nPropertiesMax: number;
};

function mapLabelProperties(property: string, key: number | string) {
	return <LabelText text={property} key={key} />;
}

/**
 * 15mL conical tube 2.5"x1"
 */
export function JTTA5({
	dpi,
	id,
	itemName,
	properties,
	dimensions,
	nPropertiesMax,
}: LabelProps): JSX.Element {
	return (
		<LabelWrapper
			id={getPrefixedId(id)}
			labelDimensions={dimensions}
			dpi={dpi}
			padding={10}
			className={styles.horizontalLayout}
			style={{ gap: 12 }}
		>
			<Barcode value={id} orientation="p" height="100%" width="16.67%" />
			<div className={styles.vStackData}>
				<ItemName text={itemName} />
				{properties.slice(0, nPropertiesMax).map(mapLabelProperties)}
			</div>
		</LabelWrapper>
	);
}

/**
 * 1ml skirted 1"x0.75"
 */
export function JTTA4({
	dpi,
	id,
	itemName,
	properties,
	dimensions,
	nPropertiesMax,
}: LabelProps) {
	return (
		<LabelWrapper
			labelDimensions={dimensions}
			dpi={dpi}
			padding={8}
			style={{ gap: 4 }}
			className={styles.horizontalLayout}
			id={getPrefixedId(id)}
		>
			<Barcode value={id} orientation="p" height="100%" width="34%" />
			<div className={styles.vStackData}>
				<ItemName text={itemName} style={{ fontSize: "inherit" }} />
				{properties.slice(0, nPropertiesMax).map(mapLabelProperties)}
			</div>
		</LabelWrapper>
	);
}

/**
 * 1.5ml Eppendorf 1"x0.5"
 */
export function JTTA7({
	dpi,
	id,
	properties,
	itemName,
	dimensions,
	nPropertiesMax,
}: LabelProps) {
	return (
		<LabelWrapper
			labelDimensions={dimensions}
			dpi={dpi}
			padding={8}
			style={{ gap: 4 }}
			className={styles.horizontalLayout}
			id={getPrefixedId(id)}
		>
			<Barcode value={id} orientation="p" height="100%" width="17.4%" />
			<div className={styles.vStackData}>
				<ItemName text={itemName} style={{ fontSize: "inherit" }} />
				{properties.slice(0, nPropertiesMax).map(mapLabelProperties)}
			</div>
		</LabelWrapper>
	);
}

/**
 * 1.8-2mL skirted 1"x1"
 * Horizontal layout. Not really "horizontal" since everything
 * is in the same column.
 */
export function JTTA29H({
	dpi,
	id,
	itemName,
	properties,
	dimensions,
	nPropertiesMax,
}: LabelProps) {
	return (
		<LabelWrapper
			id={getPrefixedId(id)}
			labelDimensions={dimensions}
			dpi={dpi}
			padding={8}
			className={styles.verticalLayout}
			style={{ gap: 6 }}
		>
			<Barcode value={id} orientation="l" height="30.5%" width="100%" />
			<ItemName text={itemName} style={{ fontSize: "inherit" }} />
			<div className={styles.vStackData}>
				{properties.slice(0, nPropertiesMax).map(mapLabelProperties)}
			</div>
		</LabelWrapper>
	);
}

/**
 * 1.8-2mL skirted 1"x1"
 * Vertical layout. Split top and bottom. Bottom is split in half.
 */
export function JTTA29V({
	dpi,
	id,
	itemName,
	properties,
	dimensions,
	nPropertiesMax,
}: LabelProps) {
	return (
		<LabelWrapper
			id={getPrefixedId(id)}
			labelDimensions={dimensions}
			dpi={dpi}
			padding={8}
			className={styles.verticalLayout}
			style={{ gap: 4 }}
		>
			<ItemName
				text={itemName}
				style={{ fontSize: "inherit", marginBottom: 0, height: "3em" }}
			/>
			<div
				className={styles.horizontalLayout}
				style={{ gap: 6, flex: 1 }}
			>
				<Barcode value={id} orientation="p" height="100%" width="19%" />
				<div className={styles.vStackData}>
					{properties
						.slice(0, nPropertiesMax)
						.map(mapLabelProperties)}
				</div>
			</div>
		</LabelWrapper>
	);
}

/**
 * Cryobox 2.5"x1"
 * Simple vertical layout
 */
export function CryoboxHSimple({
	dpi,
	id,
	itemName,
	properties,
	dimensions,
	nPropertiesMax,
}: LabelProps) {
	return (
		<LabelWrapper
			id={getPrefixedId(id)}
			labelDimensions={dimensions}
			dpi={dpi}
			padding="6px 18px"
			className={cn(styles.verticalLayout, styles.vStackCenter)}
			style={{ textAlign: "center" }}
		>
			<ItemName text={itemName} style={{ gap: 6, marginBottom: "0" }} />
			<Barcode value={id} orientation="l" height="36%" width="43.3%" />
			{properties.slice(0, nPropertiesMax).map(mapLabelProperties)}
		</LabelWrapper>
	);
}

/**
 * Cryobox 2.5"x1"
 * Horizontal layout
 */
export function CryoboxH({
	dpi,
	id,
	itemName,
	properties,
	dimensions,
	nPropertiesMax,
}: LabelProps) {
	return (
		<LabelWrapper
			id={getPrefixedId(id)}
			labelDimensions={dimensions}
			dpi={dpi}
			padding={8}
			className={styles.horizontalLayout}
			style={{ gap: 8 }}
		>
			<Barcode value={id} orientation="p" height="100%" width="16.7%" />
			<div className={styles.vStackData}>
				<ItemName text={itemName} />
				{properties.slice(0, nPropertiesMax).map(mapLabelProperties)}
			</div>
		</LabelWrapper>
	);
}

/**
 * Cryobox 1"x2.5"
 * Vertical layout
 */
export function CryoboxV({
	dpi,
	id,
	itemName,
	properties,
	dimensions,
	nPropertiesMax,
}: LabelProps) {
	// This one is flipped vertically...
	dimensions = {
		width: dimensions.height,
		height: dimensions.width,
		unit: "in",
	};
	return (
		<LabelWrapper
			id={getPrefixedId(id)}
			labelDimensions={dimensions}
			dpi={dpi}
			padding={6}
			className={styles.verticalLayout}
		>
			<ItemName text={itemName} />
			<div
				className={styles.horizontalLayout}
				style={{ height: "59.4%", gap: 6 }}
			>
				<Barcode
					value={id}
					orientation="p"
					height="100%"
					width="29.6%"
				/>
				<div
					className={cn(styles.vStackData, styles.evenSpacing)}
					style={{ gap: 18 }}
				>
					{properties
						.slice(0, nPropertiesMax)
						.map(mapLabelProperties)}
				</div>
			</div>
		</LabelWrapper>
	);
}

export type TemplateComponent = (props: LabelProps) => JSX.Element;
export type BarcodeLabel = {
	name: string;
	dimensions: LabelDimensions;
	nPropertiesMax: number;
	templates: TemplateComponent[];
};

export const ITEM_BARCODE_LABELS: BarcodeLabel[] = [
	{
		name: "LabTag JTTA-5",
		dimensions: {
			width: 2.5,
			height: 1,
			unit: "in",
		},
		nPropertiesMax: 3,
		templates: [JTTA5],
	},
	{
		name: "LabTag JTTA-4",
		dimensions: {
			width: 1,
			height: 0.75,
			unit: "in",
		},
		nPropertiesMax: 1,
		templates: [JTTA4],
	},
	{
		name: "LabTag JTTA-7",
		dimensions: {
			width: 1,
			height: 0.5,
			unit: "in",
		},
		nPropertiesMax: 1,
		templates: [JTTA7],
	},
	{
		name: "LabTag JTTA-29",
		dimensions: {
			width: 1,
			height: 1,
			unit: "in",
		},
		nPropertiesMax: 2,
		templates: [JTTA29H, JTTA29V],
	},
];
export const BOX_BARCODE_LABELS: BarcodeLabel[] = [
	{
		name: "Cryobox",
		dimensions: {
			width: 2.5,
			height: 1,
			unit: "in",
		},
		nPropertiesMax: 1,
		templates: [CryoboxHSimple, CryoboxH, CryoboxV],
	},
];

const DATE_FORMAT = "MMM D, YYYY";
export const PRINTABLE_PROPERTIES = [
	"custom_id",
	"printed_by",
	"printed_at",
	"created_by", // Avatar
	"created_at",
	"source",
	"reference",
	"catalog",
	"lot",
] as const;
export const PROPERTY_TO_LABEL = {
	custom_id: "ID",
	printed_by: "Printed by",
	printed_at: "Printed on",
	created_by: "Created by",
	created_at: "Created on",
	source: "Source",
	reference: "Reference",
	catalog: "Catalog",
	lot: "Lot",
} as const;
export type PrintableProperty = (typeof PRINTABLE_PROPERTIES)[number];

function isConsumable(object: any): object is Consumable {
	return "parent_space" in object;
}

function isItem(object: any): object is Item {
	return !isConsumable(object);
}

export function stringifyItemProperty(
	item: Item | Consumable,
	property: Exclude<PrintableProperty, "printed_by" | "printed_at">
): string {
	if (property === "created_by") {
		let avatar = item[property];
		if (!avatar) {
			avatar = { first_name: "Deleted", last_name: "User" };
		}
		return getFullNameFromAvatar(avatar);
	} else if (property === "created_at") {
		let date: string | null = null;
		if (property === "created_at" && isConsumable(item)) {
			date = item[property];
		} else if (isItem(item)) {
			date = item["added_at"];
		}
		return moment(date).format(DATE_FORMAT);
	} else if (property === "custom_id") {
		// Combine the custom id with the organization prefix
		if ((item as any).organization_prefix) {
			return formatCustomId(
				(item as Item).organization_prefix,
				item.custom_id
			);
		} else {
			return (item as Consumable).id.toString();
		}
	} else {
		return String(item[property]);
	}
}

export function formatItemProperties(
	item:
		| (Item & { printed_by: Avatar })
		| (Consumable & { printed_by: Avatar }),
	properties: PrintableProperty[]
) {
	return properties.map((property) => {
		const key = PROPERTY_TO_LABEL[property];
		let value = "";
		if (property === "printed_by") {
			value = getFullNameFromAvatar(item.printed_by);
		} else if (property === "printed_at") {
			value = moment().format(DATE_FORMAT);
		} else {
			value = stringifyItemProperty(item, property);
		}
		return `${key}: ${value}`;
	});
}
