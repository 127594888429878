import { getCurrentBodyTheme } from "@common/components/Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "@common/components/Theme/context/ThemeTypes";
import { NominalType } from "@helpers/TypeHelpers";
import ColorValues, { HexColor, RgbColor } from "./Colors.scss";

/**
 * Every color we use in the system should be part of this type. Use this type
 * as props for components.
 */
export type Color =
	/** Styleguide colors */
	// background
	| "background"
	| "background-hover"
	| "onboarding-background"
	| "background-accent"
	| "layer-accent-01"
	| "layer-accent-01-v2"
	| "layer-accent-02"

	// layer
	| "layer-01"
	| "layer-hover-01"
	| "layer-02"
	| "layer-02-v2"
	| "layer-hover-02"
	| "layer-03"
	| "layer-03-v2"
	| "layer-hover-03"
	| "layer-hover-03-v2"
	| "layer-inverse"
	| "layer-popover"

	// text
	| "text-placeholder"
	| "text-primary"
	| "text-primary-v2"
	| "text-secondary"
	| "text-secondary-v2"
	| "text-tertiary"
	| "text-tertiary-v2"
	| "text-ghost"
	| "text-on-color"
	| "text-inverse"
	| "text-disabled"
	| "button-text"
	| "button-hover-text"
	// border
	| "border-active"
	| "border-subtle"
	| "border-subtle-v2"
	| "border"
	// input
	| "field-01"
	| "field-02"
	| "field-disabled"
	// links
	| "link-primary"
	| "link-primary-hover"
	| "link-inverse"
	// accents
	| "accent-subtle"
	| "accent-strong"
	//buttons
	| "button-primary"
	| "button-hover-primary"
	| "button-primary-danger"
	| "button-hover-danger"
	| "button-disabled"
	| "button-plus-circle"
	| "action-hover"
	| "action-click"
	| "action-link"
	| "action-disabled"
	// system
	| "red"
	| "red-contrast"
	| "yellow"
	| "yellow-contrast"
	| "green"
	| "green-contrast"
	| "blue"
	| "blue-contrast"
	| "aqua"
	| "aqua-contrast"
	| "brown"
	| "neutral-gray"
	| "neutral-gray-contrast"
	| "gray-01"
	| "gray-04"
	| "gray-06"
	| "transparent"
	| "dust-red"
	// shadow
	| "shadow-container-color"
	| "shadow-text-and-border-color"
	| "shadow-overlay"
	| "platform-menu-shadow-color"
	| "box-card-handle-shadow-color"
	// ~~~~~~~~~~~ Exception colors ~~~~~~~~~~~
	| "layer-rack"
	| "platform-menu"
	| "rack-card"
	| "box-grid-cell"
	| "editor-table-cell-selected-border"
	| "ghost-cell-fill"
	| "ghost-cell-stroke"
	| "box-category-info"
	// ~~~~~~~~~~~ Furniture colors ~~~~~~~~~~~
	| "furniture-bench"
	| "furniture-bench-inside"
	| "furniture-shelf"
	| "furniture-shelf-inside"
	| "furniture-desk"
	| "furniture-desk-inside"
	| "furniture-default"
	| "furniture-default-inside"
	// ~~~~~~~~~~~ Editor colors ~~~~~~~~~~~
	| "color-1A"
	| "color-1B"
	| "color-1C"
	| "color-2A"
	| "color-2B"
	| "color-2C"
	| "color-3A"
	| "color-3B"
	| "color-3C"
	| "hl-1B"
	| "hl-1C"
	| "hl-2A"
	| "hl-2B"
	| "hl-2C"
	| "highlighter"
	// ~~~~~~~~~~~ Colors ~~~~~~~~~~~
	// ~~~~~~ Pink ~~~~~~
	| "pink-01"
	| "pink-02"
	| "pink-03"
	| "pink-04"
	| "pink-05"
	| "pink-06"
	| "pink-07"
	| "pink-08"
	| "pink-09"
	| "pink-10"
	// ~~~~ Dust Red ~~~~
	| "dust-red-01"
	| "dust-red-02"
	| "dust-red-03"
	| "dust-red-04"
	| "dust-red-05"
	| "dust-red-06"
	| "dust-red-07"
	| "dust-red-08"
	| "dust-red-09"
	| "dust-red-10"
	// ~~~~~ Desert ~~~~~
	| "desert-01"
	| "desert-02"
	| "desert-03"
	| "desert-04"
	| "desert-05"
	| "desert-06"
	| "desert-07"
	| "desert-08"
	| "desert-09"
	| "desert-10"
	// ~~~~~ Gold ~~~~~
	| "gold-01"
	| "gold-02"
	| "gold-03"
	| "gold-04"
	| "gold-05"
	| "gold-06"
	| "gold-07"
	| "gold-08"
	| "gold-09"
	| "gold-10"
	// ~~~~~ Yellow ~~~~~
	| "yellow-01"
	| "yellow-02"
	| "yellow-03"
	| "yellow-04"
	| "yellow-05"
	| "yellow-06"
	| "yellow-07"
	| "yellow-08"
	| "yellow-09"
	| "yellow-10"
	// ~~~~~ Lime ~~~~~
	| "lime-01"
	| "lime-02"
	| "lime-03"
	| "lime-04"
	| "lime-05"
	| "lime-06"
	| "lime-07"
	| "lime-08"
	| "lime-09"
	| "lime-10"
	// ~~~~~ Green ~~~~~
	| "green-01"
	| "green-02"
	| "green-03"
	| "green-04"
	| "green-05"
	| "green-06"
	| "green-07"
	| "green-08"
	| "green-09"
	| "green-10"
	// ~~~~~ Cyan ~~~~~
	| "cyan-01"
	| "cyan-02"
	| "cyan-03"
	| "cyan-04"
	| "cyan-05"
	| "cyan-06"
	| "cyan-07"
	| "cyan-08"
	| "cyan-09"
	| "cyan-10"
	// ~~~~~ Sky Blue ~~~~~
	| "sky-blue-01"
	| "sky-blue-02"
	| "sky-blue-03"
	| "sky-blue-04"
	| "sky-blue-05"
	| "sky-blue-06"
	| "sky-blue-07"
	| "sky-blue-08"
	| "sky-blue-09"
	| "sky-blue-10"
	// ~~~~~ Royal Blue ~~~~~
	| "royal-blue-01"
	| "royal-blue-02"
	| "royal-blue-03"
	| "royal-blue-04"
	| "royal-blue-05"
	| "royal-blue-06"
	| "royal-blue-07"
	| "royal-blue-08"
	| "royal-blue-09"
	| "royal-blue-10"
	// ~~~~~ Violet ~~~~~
	| "violet-01"
	| "violet-02"
	| "violet-03"
	| "violet-04"
	| "violet-05"
	| "violet-06"
	| "violet-07"
	| "violet-08"
	| "violet-09"
	| "violet-10"
	// ~~~~~~~~~~~ Live editing cursor colors ~~~~~~~~~~~
	| "cursor-blue"
	| "cursor-brown"
	| "cursor-green"
	| "cursor-indigo"
	| "cursor-mint"
	| "cursor-orange"
	| "cursor-pink"
	| "cursor-purple"
	| "cursor-red"
	| "cursor-yellow"
	// ~~~~~~~~~~~ Pastel palette ~~~~~~~~~~~~~~~~~~
	| "pastel-pink"
	| "pastel-pink-contrast"
	| "pastel-red"
	| "pastel-red-contrast"
	| "pastel-brown"
	| "pastel-brown-contrast"
	| "pastel-ginger"
	| "pastel-ginger-contrast"
	| "pastel-yellow"
	| "pastel-yellow-contrast"
	| "pastel-mint"
	| "pastel-mint-contrast"
	| "pastel-green"
	| "pastel-green-contrast"
	| "pastel-violet"
	| "pastel-violet-contrast"
	| "pastel-navy"
	| "pastel-navy-contrast"
	| "pastel-blue"
	| "pastel-blue-contrast"
	// ~~~~~~~~~~~ V2 ~~~~~~~~~~~~~~~~~~
	| "brand-07"
	| "brand-08"
	| "gray-03"
	| "gray-04"
	| "gray-08"

	// ~~~~~~~~~~~ Functional ~~~~~~~~~~~~~~~~~~
	| "informative"
	| "success"
	| "helper"
	| "warning"
	| "danger"
	| "error";

export type ColorOptions =
	| "pink"
	| "dust-red"
	| "desert"
	| "gold"
	| "yellow"
	| "lime"
	| "green"
	| "cyan"
	| "sky-blue"
	| "royal-blue"
	| "violet";

export type FunctionalColors =
	| "informative"
	| "success"
	| "helper"
	| "warning"
	| "error";

export type ColorRgb = `${Color}-rgb`;

// Type guard for FunctionalColors
export function isFunctionalColor(color: any): color is FunctionalColors {
	const functionalColors: FunctionalColors[] = [
		"error",
		"success",
		"informative",
		"helper",
		"warning",
	];
	return functionalColors.includes(color as FunctionalColors);
}

const LIGHT_PREFIX = "light-";
const DARK_PREFIX = "dark-";
/**
 * Add colors defined in colors.scss in this array
 */
const _COLORS = Object.keys(ColorValues)
	.filter((color) => color.startsWith(LIGHT_PREFIX))
	.filter((color) => !color.endsWith("rgb"))
	.map((color) => color.substring(LIGHT_PREFIX.length)) as Color[];

/**
 * A type for a color with the "var(--...)" formatting.
 * This should be rarely used.
 */
export type ColorCssVar = NominalType<string, "ColorCssVar">;

/**
 * Use this enum to refer to colors.
 * EG: Colors.background
 */
export const Colors = _COLORS.reduce(
	(obj, color) => ({
		...obj,
		[color]: color,
	}),
	{} as { [K in Color]: K }
);

/**
 * Mapping the color types to ColorCssVar.
 */
export const ColorCssVarMap = _COLORS.reduce(
	(obj, color) => ({
		...obj,
		[color]: `var(--${color})`,
		[`${color}-rgb`]: `var(--${color}-rgb)`,
	}),
	{} as Record<Color | ColorRgb, ColorCssVar>
);

// From our Color tokens to Hex string
export const ColorHexMap = {
	[THEME_PREFERENCE.light]: _COLORS.reduce(
		(obj, color) => ({
			...obj,
			[color]: ColorValues[`${LIGHT_PREFIX}${color}`],
		}),
		{} as { [K in Color]: HexColor }
	),
	[THEME_PREFERENCE.dark]: _COLORS.reduce(
		(obj, color) => ({
			...obj,
			[color]: ColorValues[`${DARK_PREFIX}${color}`],
		}),
		{} as { [K in Color]: HexColor }
	),
};

// From our Color tokens to comma separated rgb digits
export const ColorRgbMap = {
	[THEME_PREFERENCE.light]: _COLORS.reduce(
		(obj, color) => ({
			...obj,
			[color]: ColorValues[`${LIGHT_PREFIX}${color}-rgb`],
		}),
		{} as { [K in Color]: RgbColor }
	),
	[THEME_PREFERENCE.dark]: _COLORS.reduce(
		(obj, color) => ({
			...obj,
			[color]: ColorValues[`${DARK_PREFIX}${color}-rgb`],
		}),
		{} as { [K in Color]: RgbColor }
	),
};

type HexColorToRgbaTextProps = {
	color: Color;
	themeMode?: THEME_PREFERENCE;
	opacity?: number;
};

export function HexColorToRgbaText({
	color,
	themeMode,
	opacity = 1,
}: HexColorToRgbaTextProps): RgbColor | undefined {
	const bodyTheme = getCurrentBodyTheme();
	const rgb = ColorRgbMap[themeMode || bodyTheme][color];
	if (!rgb) return undefined;
	return `rgba(${rgb}, ${opacity})` as RgbColor;
}

export const TypographyColorClassMap = _COLORS.reduce(
	(obj, color) => ({
		...obj,
		[color]: `${color}-typography`,
	}),
	{} as { [K in Color]: `${K}-typography` }
);

export const colorCssVarToColor = (colorCssVar: ColorCssVar): Color =>
	colorCssVar.substring(6, colorCssVar.length - 1) as Color;

// ~~~~~~~~~~~~ Helpers to deal with deprecated document colors ~~~~~~~~~~~~

// The editor color css vars used to use "_" as a separator, now we use "-". This type respresents that
export type DeprecatedDocumentColor = NominalType<string, "DeprecatedColor">;

export const translateDeprecatedCssColorVar = (
	deprecatedColor: ColorCssVar | DeprecatedDocumentColor
): ColorCssVar => deprecatedColor.replace("_", "-") as ColorCssVar;

export const translateDeprecatedCssColorVarToColor = (
	deprecatedColor: ColorCssVar | DeprecatedDocumentColor
): Color => colorCssVarToColor(translateDeprecatedCssColorVar(deprecatedColor));
