import {
	ISOString,
	WorkspaceId,
	PaginatedSearchQuery,
	PaginatedSearchResults,
	Item,
	Consumable,
} from "@common/types";

export type RepositoryItemsFiltersOnly = Partial<{
	archived_at__gte: ISOString | null;
	archived_at__lte: ISOString | null;
	archived_by: string | null;
	is_archived: boolean | null;
	ordering: string | null;
	source: string | null;
	source__in: string[] | null;
	submitted_at__gte: ISOString | null;
	submitted_at__lte: ISOString | null;
	submitted_by: number | null;
	submitted_by__in: number[] | null;
	updated_at__gte: string | null;
	updated_at__lte: string | null;
	updated_by__in: string[] | null;
	workspace: WorkspaceId | null;
	search: string | null;
	location__box_location__box: number | null;
	include_filter_options: boolean | null;
	location__box_location__box__in: number[] | null;
	item_type__in: number[] | null;
	created_by__in: number[] | null;
	added_at__gte: string | null;
	added_at__lte: string | null;
	status__in: number[] | null;
	expiration_date__gte: string | null;
	expiration_date__lte: string | null;
	id: number | null;
	location__isnull: number;
}>;

export type RepositoryItemsFilters = Omit<
	PaginatedSearchQuery & RepositoryItemsFiltersOnly,
	"sort_by"
>;
export type RepositoryItemsResult = Omit<
	PaginatedSearchResults<Item>,
	"filter_options"
>;

export type RepositoryConsumablesFilters = Omit<
	PaginatedSearchQuery &
		Partial<{
			id: number | null;
			ordering: string | null;
			search: string | null;
			status__in: number[] | null;
			expiration_date__gte: string | null;
			expiration_date__lte: string | null;
			updated_at__gte: string | null;
			updated_at__lte: string | null;
			created_at__gte: string | null;
			created_at__lte: string | null;
			quantity__gte: string | null;
			quantity__lte: string | null;
			updated_by__in: string[] | null;
			created_by__in: string[] | null;
			location__isnull: number;
			include_filter_options: boolean | null;
			parent_furniture_category: number | null;
			parent_furniture_category__in: number[] | null;
		}>,
	"sort_by"
>;
export type RepositoryConsumableResult = PaginatedSearchResults<Consumable>;

export type ItemSetting = {
	visible: boolean;
	width?: number;
};

export type RepositorySearchSettings = {
	[item_id: string]: {
		column_order: string;
	} & {
		[schema_id: string]: ItemSetting;
	};
};

export enum FreezerLayersEnum {
	FREEZER = 1,
	SHELF = 2,
	RACK = 3,
	CATEGORY = 4,
	ITEMGROUP = 5,
	BOX = 6,
}

export type FreezerRepositorySettings = {
	owner: number;
	layer: FreezerLayersEnum;
	organization: number;
	data: {
		column_order: string;
	} & {
		[key: string]: ItemSetting;
	};
};
