import { Text, Transforms } from "slate";
import { Node } from "./Node";

export const Block = {
	...Node,
	/**
	 * @param {Editor} editor
	 * @param {Block} [block=this]
	 * @param {keyof Block} [key="value" | "align"]
	 * @returns {Any} The value of the block at the selection. Null if the block is not active
	 */
	getCurrentValue(editor, block = this, key) {
		if (!this.isActive(editor, block)) return null;
		const [match] = editor.getNodesOfType(block.type, {
			mode: "lowest",
		});
		if (!match) return null;

		const [node] = match;
		return node[key];
	},

	nodesOptions: () => {
		return {
			match: (node) => {
				if (Text.isText(node)) return false;
				if (node.type === "font-size") return true;
				return !node.type;
			},
		};
	},

	/**
	 * Toggles this block
	 * @param {Editor} editor
	 * @param {Block} [block=this]
	 * @param {keyof Block} [key="value" | "align"]
	 * @param {Any} [value=true]
	 */
	toggle(editor, block = this, key, value = true) {
		const isActive =
			this.isActive(editor, block) &&
			this.getCurrentValue(editor, block, key) === value;
		if (isActive) {
			// Remove block
			Transforms.setNodes(editor, { type: null });
		} else {
			Transforms.setNodes(
				editor,
				{
					...block,
					value,
				},
				{ ...this.nodesOptions() }
			);
		}
	},
	indent(editor, block = this, indent = 0) {
		Transforms.setNodes(editor, {
			...block,
			indent,
		});
	},
};
