import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Typography } from "@components";
import cn from "classnames";
import { MAX_COLUMNS, MAX_ROWS } from "../../TableV2/constants";

const DEFAULT_HEIGHT = 10;
const DEFAULT_WIDTH = 10;
const MAX_HEIGHT = MAX_ROWS;
const MAX_WIDTH = MAX_COLUMNS;
const DEFAULT_HOVER = { h: 1, w: 1 };

/**
 * Table size picker
 * @component
 *
 * @param {Object} props
 * @param {Any} props.resetDependency When this value changes, the table is reset
 * @param {Function} props.onClick Called when a cell is clicked. Provides dimensions selected in an object: { h, w }
 */
export default function TableSize(props) {
	const [height, setHeight] = useState(DEFAULT_HEIGHT);
	const [width, setWidth] = useState(DEFAULT_WIDTH);

	/** Stores user's position */
	const [hover, setHover] = useState(DEFAULT_HOVER);

	// Update height and width when at boundaries
	useEffect(() => {
		let { h, w } = hover;
		if (h === height) {
			setHeight(Math.min(MAX_HEIGHT, h + 1));
		} else if (h < height && height > DEFAULT_HEIGHT) {
			setHeight(Math.min(MAX_HEIGHT, Math.max(DEFAULT_HEIGHT, h + 1)));
		}

		if (w === width) {
			setWidth(Math.max(DEFAULT_WIDTH, Math.min(MAX_WIDTH, w + 1)));
		} else if (w < width && width > DEFAULT_WIDTH) {
			setWidth(Math.min(MAX_WIDTH, Math.max(DEFAULT_WIDTH, w + 1)));
		}
	}, [hover]);

	useEffect(() => {
		setHeight(DEFAULT_HEIGHT);
		setWidth(DEFAULT_WIDTH);
		setHover(DEFAULT_HOVER);
	}, [props.resetDependency]);

	/**
	 * @returns {[JSX.Element]} Table rows
	 */
	const getTable = () => {
		return Array(height)
			.fill()
			.map((_, h) => {
				const cells = Array(width)
					.fill()
					.map((_, w) => (
						<td
							key={w}
							className={cn({
								[styles.inRange]:
									w <= hover.w - 1 && h <= hover.h - 1,
							})}
							onMouseOver={() => setHover({ h: h + 1, w: w + 1 })}
							onClick={() =>
								props.onClick && props.onClick(hover)
							}
						/>
					));
				return <tr key={h}>{cells}</tr>;
			});
	};

	return (
		<div className={styles.container}>
			<table className={styles.table}>
				<tbody>{getTable()}</tbody>
			</table>
			<Typography variant="caption" style={{ margin: "0 auto" }}>
				{hover.h} x {hover.w}
			</Typography>
		</div>
	);
}
