import {
	ProjectProps,
	Experiment,
	Freezer,
	Box,
	Avatar,
	ISOString,
	Space,
	ConsumableSearchItem,
	ItemGroup,
} from ".";
import { ExperimentFile } from "./Folder";

export enum QuickAccessItemTypeEnum {
	FREEZER = 0,
	BOX,
	SPACE,
	CONSUMABLES_CATEGORY,
	PROJECT,
	EXPERIMENT,
	ITEM_GROUP,
}

export type QuickAccessItem =
	| FreezerQuickAccessItem
	| BoxQuickAccessItem
	| ItemGroupQuickAccessItem
	| SpaceQuickAccessItem
	| ConsumablesCategoryQuickAccessItem
	| ProjectQuickAccessItem
	| ExperimentQuickAccessItem;

type BaseQuickAccessItem = {
	type: QuickAccessItemTypeEnum;
	viewed_at: ISOString;
	org_user: Avatar;
	view_count: number;
};

export type FreezerQuickAccessItem = BaseQuickAccessItem & {
	type: QuickAccessItemTypeEnum.FREEZER;
	freezer: Freezer;
};
export type BoxQuickAccessItem = BaseQuickAccessItem & {
	type: QuickAccessItemTypeEnum.BOX;
	box: Box;
};
export type ItemGroupQuickAccessItem = BaseQuickAccessItem & {
	type: QuickAccessItemTypeEnum.ITEM_GROUP;
	item_group: ItemGroup;
};

export type SpaceQuickAccessItem = BaseQuickAccessItem & {
	type: QuickAccessItemTypeEnum.SPACE;
	space: Space;
};
export type ConsumablesCategoryQuickAccessItem = BaseQuickAccessItem & {
	type: QuickAccessItemTypeEnum.CONSUMABLES_CATEGORY;
	consumables_category: ConsumableSearchItem;
};
export type ProjectQuickAccessItem = BaseQuickAccessItem & {
	type: QuickAccessItemTypeEnum.PROJECT;
	project: ProjectProps;
};
export type ExperimentQuickAccessItem = BaseQuickAccessItem & {
	type: QuickAccessItemTypeEnum.EXPERIMENT;
	experiment_file: ExperimentFile;
};
