import { NominalId } from "@helpers/TypeHelpers";
import { ISOString } from "./Date";

export type OrganizationId = NominalId<"organization_id">;

export type Organization = {
	id: OrganizationId;
	name: string;
	timezone: string;
	institution: string;
	has_upgraded: boolean;
	is_user_active: boolean;
	created: ISOString;
};
