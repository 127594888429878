import React, { forwardRef, useMemo, useState, useCallback } from "react";
import styles from "./index.module.scss";
import { Transforms, Editor } from "slate";
import { GenemodIcon, Typography } from "@components";
import moment, { Moment } from "moment";
import Tooltip from "@common/components/Tooltip";
import { User } from "@common/types/User";
import { ListEditor, ListItemElement } from "../../formats/List";
import { PM_EDITOR_ID } from "../Editor";
import cn from "classnames";
import { useGenemodDocumentSettings } from "@components/Editor/hooks/useGenemodDocumentSettings";
import { useResizeEffect } from "@helpers/Hooks";
import useOrganizationUser from "@helpers/Hooks/useOrganizationUserHook";

type ListProps = {
	node: ListItemElement;
	children: React.ReactNode;
	editor: ListEditor;
};

const MIN_WIDTH_FOR_COMPLETE_INFO = 994;
export const LI = forwardRef((props: ListProps, ref: any) => {
	const editor = props.editor;
	const { completedAt, completedBy } = props.node;
	const { user, teamMembers } = useOrganizationUser();
	const listPermission = editor.permissions.list;
	const [showCompleteInfo, setShowCompleteInfo] = useState(false);

	// Prevent completed info to be cut off when both panels are opened
	const handleResize = () => {
		const editorRect = ref.current
			?.closest("#" + PM_EDITOR_ID)
			?.getBoundingClientRect();
		if (editorRect) {
			setShowCompleteInfo(
				editorRect.width >= MIN_WIDTH_FOR_COMPLETE_INFO
			);
		}
	};
	useResizeEffect(handleResize, true, [ref]);

	/**
	 * Checks if a given node is a sublist item.
	 * @param {ListItemElement} node The node to check.
	 * @returns {boolean} True if the node is a sublist item, false otherwise.
	 */
	const isSublistItem = useCallback(
		(node: ListItemElement): boolean => {
			// If node is falsy, it cannot be a sublist item.
			if (!node) return false;

			// Get the location of the node in the editor.
			const [nodeMatches] = Editor.nodes(editor, {
				at: [],
				match: (n) => n === node && n.type === "list-item",
			});

			// If the node was found in the editor, check if it is a sublist item.
			if (nodeMatches) {
				const [_, location] = nodeMatches;
				/**
				 * PM-1495
				 *
				 * When a list-item node is not a child of a list node, the two
				 * subsequent calls to Editor.parent will throw an error. The
				 * first call to Editor.parent will return the document root. The
				 * second call will throw an error because the root does not
				 * have a parent.
				 *
				 * This case is possible with older data that never got normalized.
				 */
				if (location.length === 1) return false;

				// Use the path to get the parent node.
				const listParent = Editor.parent(editor, location);

				if (listParent) {
					// If the parent is a list, return true because this is a sublist.
					const [_, parentLocation] = listParent;
					const [parentNode, _path] = Editor.parent(
						editor,
						parentLocation
					);
					return "type" in parentNode && parentNode.type === "list";
				}
			}

			// If the node was not found or is not a sublist item, return false.
			return false;
		},
		[editor]
	);

	// Sets the isComplete property in the editor node
	const handleCheckboxClick = useCallback(() => {
		if (!listPermission) return;

		const [node] = Editor.nodes(editor, {
			at: [],
			match: (n) => n === props.node,
		});
		if (node) {
			// Move cursor to the end of line.
			Transforms.select(editor, node[1]);
			Transforms.collapse(editor, { edge: "end" });
			Transforms.setNodes(
				editor,
				{
					completedAt: completedAt ? null : new Date().getTime(),
					completedBy: completedBy ? null : user?.id,
				} as any,
				{
					at: node[1],
				}
			);
		}
	}, [editor, listPermission, completedAt, completedBy, user, props.node]);

	const renderCompleteInfo = useCallback(
		(
			completedBy: User | undefined,
			completedAt: Moment,
			isTooltip = false
		) => {
			return (
				<div
					className={
						isTooltip
							? styles.completedInfoTooltip
							: styles.completedInfo
					}
				>
					<Typography
						variant="caption"
						resize={false}
						color={isTooltip ? "text-inverse" : "text-tertiary"}
					>
						{completedBy
							? `${completedBy.first_name.charAt(0)}. ${
									completedBy.last_name
							  }`
							: "Unknown"}
					</Typography>
					<Typography
						variant="caption"
						className={styles.completedDate}
						resize={false}
						color={isTooltip ? "text-inverse" : "text-tertiary"}
					>
						{completedAt.format("M/DD/YYYY") +
							" at " +
							completedAt.format("h:mm A")}
					</Typography>
				</div>
			);
		},
		[completedBy, completedAt]
	);

	const getCheckbox = useCallback(() => {
		// Get the user from redux
		const completedUser = completedBy
			? teamMembers?.find((member) => member.user.id == completedBy)?.user
			: null;
		const completedAt = moment(props.node.completedAt);
		return (
			<div
				contentEditable={false}
				className={cn(styles.checkboxContainer, {
					[styles.checkboxContainer__disabled]: !listPermission,
				})}
			>
				{completedUser &&
					showCompleteInfo &&
					renderCompleteInfo(completedUser, completedAt)}
				{!showCompleteInfo && completedUser ? (
					<Tooltip
						title={renderCompleteInfo(
							completedUser,
							completedAt,
							true
						)}
						className={styles.completedTooltip}
					>
						<GenemodIcon
							style={{
								position: "relative",
								bottom: "3px",
							}}
							name="protocol-step-complete"
							onClick={handleCheckboxClick}
							fill={listPermission ? undefined : "text-ghost"}
							stroke={listPermission ? undefined : "text-ghost"}
							decorative={!listPermission}
						/>
					</Tooltip>
				) : (
					<GenemodIcon
						name={
							completedUser
								? "protocol-step-complete"
								: "protocol-step-incomplete"
						}
						style={{
							position: "relative",
							bottom: "3px",
						}}
						fill={listPermission ? undefined : "text-ghost"}
						stroke={listPermission ? undefined : "text-ghost"}
						onClick={handleCheckboxClick}
						decorative={!listPermission}
					/>
				)}
			</div>
		);
	}, [
		completedBy,
		completedAt,
		listPermission,
		renderCompleteInfo,
		showCompleteInfo,
		handleCheckboxClick,
		teamMembers,
		props.node,
		listPermission,
	]);

	const [preferences] = useGenemodDocumentSettings(editor.documentId);
	const listType = useMemo(() => props.node.listType, [props.node.listType]);
	const isSublistItemNode = isSublistItem(props.node);
	const showBullet =
		(!isSublistItemNode &&
			listType == "bullet" &&
			preferences.bulleted_lists) ||
		(isSublistItemNode &&
			listType == "bullet" &&
			preferences.bulleted_sublists);

	const showNumbered =
		(!isSublistItemNode &&
			listType == "ordered" &&
			preferences.numbered_lists) ||
		(isSublistItemNode &&
			listType == "ordered" &&
			preferences.numbered_sublists);

	return (
		<li
			{...props}
			ref={ref}
			style={{ position: "relative" }}
			className={cn(
				!isSublistItemNode ? styles.listItem : styles.subListItem,
				styles.test,
				{
					[styles.complete]:
						!!completedAt &&
						!editor.experimentIsCompleted &&
						(showBullet || showNumbered),
					[styles.ordered]: listType == "ordered",
					[styles.bullet]: listType == "bullet",
				}
			)}
		>
			{showBullet && getCheckbox()}
			{showNumbered && getCheckbox()}
			{props.children}
		</li>
	);
});
