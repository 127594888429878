import React from "react";
import styles from "./DragAndDropGuidance.module.scss";
import Typography from "../Typography/Typography";
import cn from "classnames";

type DragAndDropGuidanceProps = {
	activeLabel: string;
	element: string;
	shape: "reactangle" | "square";
	allowMultipleItems?: boolean;
};

function DragAndDropGuidance({
	activeLabel,
	element,
	shape,
	allowMultipleItems,
}: DragAndDropGuidanceProps) {
	return (
		<div className={styles.guidance}>
			<div className={styles.legends}>
				<div
					className={cn(styles.commonBox, {
						[styles.squareBox]: shape === "square",
						[styles.reactangleBox]: shape === "reactangle",
					})}
					style={{ backgroundColor: "var(--layer-02-v2)" }}
				>
					<Typography color="text-primary" variant="small">
						{activeLabel}
					</Typography>
				</div>
				<div
					className={cn(styles.commonBox, styles.colorBox, {
						[styles.squareBox]: shape === "square",
						[styles.reactangleBox]: shape === "reactangle",
					})}
					style={{ backgroundColor: "var(--layer-02-v2)" }}
				>
					<Typography color="text-disabled" variant="small">
						Occupied
					</Typography>
				</div>
			</div>
			<Typography variant="headline5" className="mt-8 mb-2">
				How to move {element}
			</Typography>
			<Typography className={styles.fullWidth}>
				1. With your cursor, click {element} to select
				<br />
				2. Drag the {element} to the desired location
				<br />
				3. Release to drop the {element}
			</Typography>
			{allowMultipleItems ? (
				<>
					<Typography variant="headline5" className="mt-8">
						Did you know...
					</Typography>
					<ul className="mt-2">
						<li>
							<Typography>
								You can move multiple items at once. To select
								multiple items, hold down CONTROL (Windows) or
								COMMAND (Mac OS).
							</Typography>
						</li>
						<li>
							<Typography>
								You can swap item locations. Simply drag an item
								to the desired cell, and the two items will swap
								location.
							</Typography>
						</li>
					</ul>
				</>
			) : null}
		</div>
	);
}

export default DragAndDropGuidance;
