import {
	PMDocumentDTO,
	pmDocumentFromDto,
} from "@redux/ProjectManagement/PmSliceHelpers";
import { Protocol } from "./Protocol";

export type ProtocolDTO = Omit<Protocol, "documents"> & {
	documents: PMDocumentDTO[];
};

export const protocolFromDto = (protocol: ProtocolDTO): Protocol => ({
	...protocol,
	documents: protocol.documents.map((doc) =>
		pmDocumentFromDto(protocol.id, "protocol", doc)
	),
});
