import { deprecated_getConfig } from "@common/config/GenemodConfig";

/**
 * Url for cognito hosted UI.
 */
export const COGNITO_AUTH_BASE = deprecated_getConfig("COGNITO_AUTH_BASE");

/**
 * Internal url where cognito redirects to after a user login/registration on the hosted UI.
 */
export const REDIRECT_URI = deprecated_getConfig("REDIRECT_URI");

/**
 * Data to setup a cognito pool.
 */
export const COGNITO_POOL_DATA = deprecated_getConfig("COGNITO_POOL_DATA");

/**
 * The "response type" for cognito credentials.
 * See: https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-user-pool-oauth-2-0-grants/
 */
const RESPONSE_TYPE = "token";

/**
 * Standard search params to add to COGNITO_AUTH_BASE.
 */
export const COGNITO_URI_COMPONENTS = Object.entries({
	response_type: RESPONSE_TYPE,
	client_id: COGNITO_POOL_DATA.ClientId,
	redirect_uri: REDIRECT_URI,
})
	.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
	.join("&");
