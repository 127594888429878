import React from "react";
import { Badge, Space } from "antdv5";
import {
	Color,
	ColorHexMap,
	ColorOptions,
	FunctionalColors,
	isFunctionalColor,
} from "@common/styles/Colors";
import { Typography } from "@components";
import styles from "@components/InputV2/InputV2.module.scss";
import { useTheme } from "../Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "../Theme/context/ThemeTypes";

type BadgesProps = {
	/** ID of the input field */
	id?: string;
	/** Input's name */
	name?: string;
	/** Input value */
	/** Input label */
	label?: string | React.ReactNode;
	/** Input label color */
	labelColor?: Color;
	/** count of the badge */
	count?: React.ReactNode;
	/** color of the badge */
	color?: FunctionalColors | ColorOptions;
	/** show zero count */
	showZero?: boolean;
	/** overflow count */
	overflowCount?: number;
	/** dot style */
	dot?: boolean;
	/** Href of the badge */
	href?: string;
	/** offset of the badge */
	offset?: [number, number];
	/** size of the badge */
	size?: "default" | "small";
	/** status of the badge */
	status?: "success" | "processing" | "default" | "error" | "warning";
	/** text of the badge */
	text?: string;
	/** Use Ribbon */
	ribbon?: boolean;
	/** Children */
	children?: React.ReactNode;
};

/**
 * Badges component
 */
export const Badges = ({
	id,
	name,
	label,
	labelColor,
	count,
	color,
	showZero,
	overflowCount,
	dot,
	href,
	offset,
	size,
	status,
	text,
	ribbon,
	children,
}: BadgesProps): JSX.Element => {
	const [theme] = useTheme();
	const isFunctional = isFunctionalColor(color);
	const badgeColor =
		color && isFunctional ? ColorHexMap[theme][color] : undefined;

	const badgeBgColor =
		color && !isFunctional
			? ColorHexMap[theme][(color + "-01") as Color]
			: undefined;
	const badgeCountColor =
		color && !isFunctional
			? ColorHexMap[theme][(color + "-06") as Color]
			: undefined;
	const isColorOption = color && !isFunctional;

	const badge = () => {
		if (ribbon) {
			if (href) {
				return (
					<Space>
						<a href={href}>
							<Badge.Ribbon
								text={text}
								color={badgeColor}
								className={styles.badgeBase}
							>
								{children}
							</Badge.Ribbon>
						</a>
					</Space>
				);
			}
			return (
				<Space>
					<Badge.Ribbon
						text={text}
						color={badgeColor}
						className={styles.badgeBase}
					>
						{children}
					</Badge.Ribbon>
				</Space>
			);
		}
		if (href) {
			return (
				<Space>
					<a href={href}>
						<Badge
							count={count}
							color={badgeColor}
							showZero={showZero}
							overflowCount={overflowCount}
							dot={dot}
							offset={offset}
							size={size}
							status={status}
							text={text}
							className={styles.badgeBase}
						>
							{children}
						</Badge>
					</a>
				</Space>
			);
		}
		return (
			<Space>
				<Badge
					count={count}
					color={badgeColor}
					showZero={showZero}
					overflowCount={overflowCount}
					dot={dot}
					offset={offset}
					size={size}
					status={status}
					text={text}
					style={
						isColorOption
							? {
									backgroundColor: badgeBgColor,
									color: badgeCountColor,
							  }
							: {}
					}
					className={styles.badgeBase}
				>
					{children}
				</Badge>
			</Space>
		);
	};

	return (
		<div>
			{label && (
				<Typography
					variant="label"
					className={styles.label}
					color={labelColor}
					bold
				>
					{label}
				</Typography>
			)}
			{badge()}
		</div>
	);
};
