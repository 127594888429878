import { FREEZER_PAGES, FREEZER_PATHS } from "@containers/Freezer";
import { useSearchParams, useUrlIsEditMode } from "@helpers/URLParams";
import { useOrganizationRouter } from "@root/AppRouter";
import { useLocation, useRouteMatch } from "react-router-dom";

import { GenemodLinkType } from "@common/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	useFurnitureCategoryQuery,
	useFurnitureQuery,
	useSpaceQuery,
} from "@redux/freezer/ConsumableApiSlice";
import { keysMappedToSelf } from "@helpers/TypeHelpers";
import { useMemo } from "react";
import { useBoxQuery } from "@redux/inventory/Box";
import { useCategoryQuery } from "@redux/inventory/Category";
import { useFreezerQuery } from "@redux/inventory/Freezer";
import { useItemGroupQuery } from "@redux/inventory/ItemGroup";
import { useRackQuery } from "@redux/inventory/Rack";
import { useShelfQuery } from "@redux/inventory/Shelf";

export type CrumbPath = {
	label: string;
	secondLabel?: string;
	path: GenemodLinkType;
	objectId?: number;
	search?: URLSearchParams;
};

/** Helper function to return current Freezer page */
export const useFreezerPages = () => {
	const routerLocation = useLocation();
	const FREEZER_PATH_KEYS = keysMappedToSelf(FREEZER_PATHS);

	// Allows us to match url locations
	const FREEZE_PATHS_REGEX = Object.entries(FREEZER_PATHS).reduce(
		(out, [key, path]) => {
			// replace any id fields with regex
			let url = path.replace(/:[a-zA-Z_]*/g, "[0-9]+");
			// remove trailing backslash
			if (url.endsWith("/")) {
				url = url.slice(0, -1);
			} // allow for trailing backslashes
			url += "[/]*";
			// match until the end
			url += "$";
			return {
				...out,
				[url]: key,
			};
		},
		{} as { string: keyof typeof FREEZER_PATHS }
	);
	return useMemo(() => {
		const [, path] =
			Object.entries(FREEZE_PATHS_REGEX).find(([regex]) => {
				return !!routerLocation.pathname.match(regex);
			}) || [];
		const match = Object.keys(FREEZER_PATH_KEYS).find(
			(key) => key === path
		);
		return FREEZER_PAGES[match as keyof typeof FREEZER_PAGES];
	}, [routerLocation.pathname]);
};
export const useFreezerCrumbs = () => {
	const { appendBaseUrl } = useOrganizationRouter();

	const freezerHomeRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.HOME),
	});
	const freezerRoute = useRouteMatch<{ id: string }>({
		path: appendBaseUrl(FREEZER_PATHS.CONTENTS),
	});
	const advancedSearchRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.SEARCH),
	});
	const archivedRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.ARCHIVE),
	});
	const boxRoute = useRouteMatch<{ freezer_id: string; box_id: string }>({
		path: appendBaseUrl(FREEZER_PATHS.BOXES),
		exact: true,
	});
	const itemGroupRoute = useRouteMatch<{
		freezer_id: string;
		item_group_id: string;
	}>({
		path: appendBaseUrl(FREEZER_PATHS.ITEMGROUPS),
		exact: true,
	});
	const itemTypeRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.ITEMTYPE),
	});
	const { selected_rack } = useSearchParams<{ selected_rack: number }>();
	const { selected_category } = useSearchParams<{
		selected_category: number;
	}>();

	const { data: box } = useBoxQuery(
		Number(boxRoute?.params.box_id) || skipToken
	);

	const { data: itemGroup } = useItemGroupQuery(
		Number(itemGroupRoute?.params.item_group_id) || skipToken
	);

	const rack_id = selected_rack || box?.location.rack_location?.rack;
	const category_id =
		selected_category ||
		box?.location?.category ||
		itemGroup?.location.category;
	const { data: rack } = useRackQuery(rack_id || skipToken);
	const { data: category } = useCategoryQuery(category_id || skipToken);

	const { data: shelf } = useShelfQuery(
		(rack?.location && rack?.location.shelf) ||
			category?.location.shelf ||
			skipToken
	);
	const freezer_id =
		Number(freezerRoute?.params.id) ||
		(rack?.location && rack?.location.freezer) ||
		category?.location.freezer ||
		box?.location.freezer ||
		itemGroup?.location.freezer;
	const { data: freezer } = useFreezerQuery(freezer_id || skipToken);

	const archivedPath = archivedRoute || freezer?.is_archived;

	const crumbs: CrumbPath[] = [
		{
			label: archivedPath ? "Inventory archive" : "Freezers",
			path: archivedPath ? "FREEZER_ARCHIVE" : "FREEZER_HOME",
		},
	];

	if (advancedSearchRoute) {
		crumbs.push({
			label: "Advanced search",
			path: "FREEZER_SEARCH",
		});
	}
	if (itemTypeRoute) {
		crumbs.push({
			label: "Custom template",
			path: "FREEZER_ITEMTYPE",
		});
	}
	if (freezer) {
		crumbs.push({
			label: freezer.name,
			path: "FREEZER_CONTENTS",
			objectId: freezer.id,
		});
	}
	if (rack && rack.location && rack.location.freezer && shelf) {
		crumbs.push({
			label: rack.name,
			secondLabel: shelf.name,
			path: "FREEZER_CONTENTS",
			objectId: rack.location.freezer,
			search: new URLSearchParams({
				selected_rack: rack.id + "",
			}),
		});
	}
	if (category && shelf) {
		crumbs.push({
			label: category.name,
			secondLabel: shelf.name,
			path: "FREEZER_CONTENTS",
			objectId: category.location.freezer,
			search: new URLSearchParams({
				selected_category: category.id + "",
			}),
		});
	}
	if (box) {
		crumbs.push({
			label: box.name,
			path: "FREEZER_BOX",
			objectId: box.id,
		});
	}
	if (itemGroup) {
		crumbs.push({
			label: itemGroup.name,
			path: "FREEZER_ITEMGROUPS",
			objectId: itemGroup.id,
		});
	}

	if (!freezerHomeRoute && !archivedRoute && crumbs.length === 1) {
		return [];
	}
	return crumbs;
};

export const useConsumableCrumbs = () => {
	const { appendBaseUrl } = useOrganizationRouter();

	const consumableRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.CONSUMABLE),
	});
	const advancedSearchRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.CONSUMABLE_SEARCH),
	});

	const spaceRoute = useRouteMatch<{ id: string }>({
		path: appendBaseUrl(FREEZER_PATHS.SPACE),
	});
	const { selected_furniture } = useSearchParams<{
		selected_furniture: number;
	}>();
	const furnitureCategoryRoute = useRouteMatch<{
		space_id: string;
		furniture_id: string;
		furniture_category_id: string;
	}>({
		path: appendBaseUrl(FREEZER_PATHS.FURNITURE_CATEGORIES),
	});
	const [isEditMode] = useUrlIsEditMode();
	const spaceId =
		spaceRoute?.params.id || furnitureCategoryRoute?.params.space_id;
	const furnitureId =
		selected_furniture || furnitureCategoryRoute?.params.furniture_id;
	const { data: space } = useSpaceQuery(Number(spaceId) || skipToken);
	const { data: furniture } = useFurnitureQuery(
		Number(furnitureId) || skipToken
	);
	const { data: furnitureCategory } = useFurnitureCategoryQuery(
		Number(furnitureCategoryRoute?.params.furniture_category_id) ||
			skipToken
	);

	const crumbs: CrumbPath[] = [
		{
			label: space?.is_archived ? "Inventory archive" : "Consumables",
			path: space?.is_archived ? "FREEZER_ARCHIVE" : "FREEZER_CONSUMABLE",
		},
	];

	if (isEditMode) {
		crumbs.push({
			label: "Customize layout",
			path: "none",
		});
		return crumbs;
	}
	if (advancedSearchRoute) {
		crumbs.push({
			label: "Advanced search",
			path: "FREEZER_CONSUMABLE_SEARCH",
		});
		return crumbs;
	}
	if (space) {
		crumbs.push({
			label: space.name,
			path: "FREEZER_SPACE",
			objectId: space.id,
		});
	}
	if (furniture) {
		crumbs.push({
			label: furniture.name,
			path: "FREEZER_SPACE",
			objectId: furniture.parent_space,
			search: new URLSearchParams({
				selected_furniture: furniture.id + "",
			}),
		});
	}
	if (furnitureCategory) {
		crumbs.push({
			label: furnitureCategory.name,
			path: "FREEZER_FURNITURE_CATEGORY",
			objectId: furnitureCategory.id,
		});
	}

	if (!consumableRoute && crumbs.length === 1) {
		return [];
	}
	return crumbs;
};

export const useStockTrackerCrumbs = () => {
	const { appendBaseUrl } = useOrganizationRouter();

	const stockTrackerRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.STOCK_TRACKER),
	});
	const freezerStockTrackerEditRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.EDIT_FREEZER_STOCK_TRACKER),
	});
	const freezerStockTrackerCreateRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.CREATE_FREEZER_STOCK_TRACKER),
	});
	const consumableStockTrackerEditRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.EDIT_CONSUMABLE_STOCK_TRACKER),
	});
	const consumableStockTrackerCreateRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.CREATE_CONSUMABLE_STOCK_TRACKER),
	});

	const consumablePath =
		consumableStockTrackerEditRoute || consumableStockTrackerCreateRoute;
	const crumbs: CrumbPath[] = [
		{
			label: "Stock tracker",
			path: "FREEZER_STOCK_TRACKER",
			search: new URLSearchParams({
				tab: consumablePath ? "Consumables" : "Freezer",
			}),
		},
	];
	const edit =
		consumableStockTrackerEditRoute || freezerStockTrackerEditRoute;

	if (
		freezerStockTrackerEditRoute ||
		freezerStockTrackerCreateRoute ||
		consumableStockTrackerEditRoute ||
		consumableStockTrackerCreateRoute
	) {
		crumbs.push({
			label: `${edit ? "Edit" : "Create"} stock tracker for item`,
			path: "none",
		});
	}
	if (!stockTrackerRoute && crumbs.length === 1) {
		return [];
	}
	return crumbs;
};

export const useOrdersCrumbs = () => {
	const { appendBaseUrl } = useOrganizationRouter();
	const { id: editOrderId } = useSearchParams<{ id?: number }>();
	const ordersRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.ORDERS),
	});
	const orderCreateRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.CREATE_ORDER),
	});
	const orderArchivedRoute = useRouteMatch({
		path: appendBaseUrl(FREEZER_PATHS.ARCHIVED_ORDERS),
	});

	const crumbs: CrumbPath[] = [
		{
			label: "Orders",
			path: "FREEZER_ORDERS",
		},
	];

	if (orderCreateRoute) {
		crumbs.push({
			label: `${editOrderId ? "Edit" : "New"} request`,
			path: "FREEZER_CREATE_ORDER",
		});
	} else if (orderArchivedRoute) {
		crumbs.push({
			label: "Archived orders",
			path: "FREEZER_ARCHIVED_ORDERS",
		});
	}
	if (!ordersRoute && crumbs.length === 1) {
		return [];
	}
	return crumbs;
};

export const useRepositoryCrumbs = () => {
	const crumbs: CrumbPath[] = [
		{
			label: "Repository",
			path: "FREEZER_REPOSITORY",
		},
	];

	return crumbs;
};
