import { ColorCssVarMap } from "@common/styles/Colors";
import { IdsInConstType } from "@helpers/TypeHelpers";
export const PROTOCOL_STATUSES = Object.freeze({
	DRAFT: 0,
	PUBLISHED: 1,
	0: "Draft",
	1: "Published",
} as const);
export type ProtocolStatus = IdsInConstType<typeof PROTOCOL_STATUSES>;

export const STATUS_COLORS = {
	0: ColorCssVarMap.yellow,
	1: ColorCssVarMap.blue,
	2: ColorCssVarMap.green,
};
