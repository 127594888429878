import React, { useEffect, useState } from "react";
import { KeyListener, Modal, Typography } from "@components";

/** Simple Modal component to delete / clear rows or cols */
export default function DeleteModal(props: {
	visible: boolean;
	title: string;
	message?: string;
	onCancel: () => void;
	onConfirm: () => void;
	dataCy?: string;
}): JSX.Element {
	useEffect(() => {
		setLoading(false);
	}, [props.visible]);

	const [isLoading, setLoading] = useState(false);
	return (
		<Modal
			title={props.title}
			visible={props.visible}
			onOk={props.onConfirm}
			onCancel={props.onCancel}
			destroyOnClose
			okText={"Delete"}
			okButtonProps={{ loading: isLoading }}
			hideCancelButton
			dataCy={props.dataCy}
		>
			<KeyListener hotkeys={[{ key: "Enter", onPress: props.onConfirm }]}>
				<Typography>
					{props.message
						? props.message
						: "All the contents inside will be deleted. Proceed?"}
					<span style={{ fontWeight: "bold" }}>
						{" "}
						There is no undo.
					</span>
				</Typography>
			</KeyListener>
		</Modal>
	);
}
