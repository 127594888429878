import React from "react";
import { DatePicker } from "antdv4";
//for applying styles of antdv4 date-picker
import "antdv4/lib/date-picker/style/index.css";
import "antdv4/lib/tag/style/css";
import styles from "./CustomDateRangePicker.module.scss";
import { Button, GenemodIcon, Typography } from "..";
import { Moment } from "moment";
import cn from "classnames";

export type DateType = Moment | null;
export type DateRange = [DateType, DateType] | null;
type CustomDateRangePickerProps = {
	visible: boolean;
	dateFormat: string;
	selectedDate: DateRange;
	setSelectedDate: (date: DateRange) => void;
	onBack: () => void;
	onClose: () => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

export default function CustomDateRangePicker(
	props: CustomDateRangePickerProps
) {
	const { RangePicker } = DatePicker;

	return (
		<div className={styles.customDatePicker}>
			<div className={styles.header}>
				<div className={styles.iconAndText}>
					<GenemodIcon
						name="arrow-forward"
						style={{ transform: "rotate(180deg)", marginRight: 12 }}
						size="large"
						stroke="text-primary"
						onClick={() => props.onBack()}
					/>
					<Typography variant="subheadline">Custom range</Typography>
				</div>
				{props.selectedDate && (
					<Button
						type="text"
						onClick={() => props.setSelectedDate(null)}
					>
						Clear
					</Button>
				)}
			</div>
			<div className={styles.dateInputContainer}>
				<div className={styles.dataInputLabel}>
					<Typography>From</Typography>
					<Typography>To</Typography>
				</div>
				<RangePicker
					className={styles.custom_picker}
					dropdownClassName={styles.custom_picker_dropdown}
					open={props.visible}
					placeholder={[
						props.dateFormat.toLowerCase(),
						props.dateFormat.toLowerCase(),
					]}
					format={props.dateFormat}
					allowClear={false}
					allowEmpty={[true, true]}
					value={props.selectedDate}
					onChange={(moment) => {
						props.setSelectedDate(moment);
					}}
					dropdownAlign={{
						points: ["tr", "br"],
						offset: [17, 15],
					}}
					renderExtraFooter={() => (
						<div className={styles.pickerFooter}>
							<Button type="text" onClick={() => props.onClose()}>
								Show results
							</Button>
						</div>
					)}
					getPopupContainer={(trigger) =>
						trigger.parentNode as HTMLElement
					}
					onKeyDown={props.onKeyDown}
				/>
			</div>
		</div>
	);
}
