import React, { useState } from "react";
import { GenemodIcon, Typography } from "@components";
import { Skeleton } from "antdv4";
import cn from "classnames";
import styles from "./index.module.scss";
import UnsavedChangesModal from "../Modals";

import { useCurrentWorkspaceUuid } from "@helpers/Hooks/UseCurrentWorkspaceUuid";
import { useFreezersQuery } from "@redux/inventory/Freezer";

type FreezerListProps = {
	/** selected freezer id */
	freezerId: number | null;
	/** the function to set freezerId */
	setFreezerId: (id: number) => void;
	/** whether the UnsavedChangesModal to show or not */
	confirmMoveFreezer: boolean;
	/** Callback function to reset rack and shelf */
	clearRackAndShelf: () => void;
};

export default function FreezerList({
	freezerId,
	setFreezerId,
	confirmMoveFreezer,
	clearRackAndShelf,
}: FreezerListProps): JSX.Element {
	const workspaceId = useCurrentWorkspaceUuid();
	const { data: freezerList = [] } = useFreezersQuery({
		private: true,
		workspace: workspaceId,
		show_organization_freezers: true,
	});

	const [tempFreezerId, setTempFreezerId] = useState<number | null>(null);

	const handleFreezerClick = (freezerId: number, confirm = false) => {
		if (!confirm && confirmMoveFreezer) {
			//show confirmMoveFreezer Modal
			setTempFreezerId(freezerId);
			return;
		}
		setFreezerId(freezerId);
		clearRackAndShelf();
		setTempFreezerId(null);
	};

	const loading = !freezerId;
	return (
		<div
			className={cn(styles.freezerList, {
				[styles.freezerList__loading]: loading,
			})}
		>
			<Skeleton
				active
				loading={loading}
				title={false}
				paragraph={{ rows: 5 }}
			>
				{freezerList
					?.filter(
						(freezer) => !freezer.is_locked && !freezer.is_archived
					)
					.map((freezer) => {
						const isSelectedFreezer = freezerId === freezer.id;
						return (
							<div
								key={freezer.id}
								className={cn(styles.freezerCard, {
									[styles.freezerCard__active]:
										isSelectedFreezer,
								})}
								onClick={() => handleFreezerClick(freezer.id)}
							>
								<GenemodIcon
									className={styles.freezerIcon}
									name={
										freezer.sharing === "workspace"
											? "group"
											: freezer.sharing === "personal"
											? "private"
											: "building-o"
									}
									color={
										isSelectedFreezer
											? "brand-08"
											: "text-secondary-v2"
									}
								/>
								<Typography
									className={styles.FreezerName}
									ellipsis
									hideTooltip
									variant="label"
									color={
										isSelectedFreezer
											? "brand-08"
											: "text-secondary-v2"
									}
								>
									{freezer.name}
								</Typography>
							</div>
						);
					})}
			</Skeleton>
			<UnsavedChangesModal
				visible={!!tempFreezerId}
				onCancel={() => setTempFreezerId(null)}
				onOk={() => handleFreezerClick(tempFreezerId as number, true)}
			/>
		</div>
	);
}
