import { Avatar, OrganizationUserId } from ".";
import { DocumentMode } from "./DocumentMode";
import {
	DisabledDocumentPermissions,
	DocumentPermissions,
	EnabledDocumentPermissions,
} from "./DocumentPermissions";
import { PMDocument } from "./PMDocument";
import { ProtocolId } from "./ProtocolId";
import { ProtocolLabel } from "./ProtocolLabel";
import { ProtocolStatus, PROTOCOL_STATUSES } from "./ProtocolStatus";
import { ProtocolVersion, ProtocolVersionId } from "./ProtocolVersion";

export type Protocol = {
	id: ProtocolId;
	parent_project: number;
	parent_project_name: string;
	updated_at: moment.Moment | string;
	updated_by?: Avatar;
	version: number;
	created_at: moment.Moment | string;
	last_created_at: moment.Moment | string;
	last_created_by?: Avatar;
	name: string;
	documents: PMDocument[];
	status: ProtocolStatus;
	usage_count: number;
	owner?: Avatar;
	published_by?: Avatar;
	published_at: moment.Moment | string | null;
	num_versions: number;
	latest_version: ProtocolVersionId;
	latest_published_version: number;
	latest_draft_version: number;
	labels: ProtocolLabel[];
	is_bookmarked: boolean;
	bookmarked_by: OrganizationUserId[];
	total_usage?: number;
};

export const getProtocolDocumentPermissions = (
	protocol: Protocol | ProtocolVersion | undefined,
	mode: DocumentMode
): DocumentPermissions => {
	if (mode === "viewing" || !protocol) return DisabledDocumentPermissions;
	return EnabledDocumentPermissions;
};

export function isDraft(protocol: Protocol) {
	return protocol.status === PROTOCOL_STATUSES.DRAFT;
}
