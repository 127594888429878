import {
	GenemodSkeleton,
	ResponsiveTable,
	Table,
	Typography,
} from "@common/components";
import { ColorCssVarMap } from "@common/styles/Colors";
import RestrictionGradientLayout from "@containers/Freezer/components/RestrictionGradientLayout/RestrictionGradientLayout";
import { emphasizeText } from "@helpers/Formatters";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { TableProps } from "antd/lib/table";
import cx from "classnames";
import React from "react";
import styles from "./AnalyticsTabContent.module.scss";

type InternalTableProps<T> = {
	columns: TableProps<T>["columns"];
	dataSource: TableProps<T>["dataSource"];
	mockDataSource: TableProps<T>["dataSource"];
};

type AnalyticsTabContentProps<S extends LooseObject, T extends LooseObject> = {
	title: string;
	subtitle?: string;
	storageSummary: InternalTableProps<S>;
	spaceLeft?: InternalTableProps<T>;
	hideUpgradeSection?: boolean;
	showHeader?: boolean;
};

/**
 * Common component for the analytics tab in inventory settings panels.
 */
export default function AnalyticsTabContent<
	S extends LooseObject,
	T extends LooseObject
>({
	title,
	subtitle,
	storageSummary,
	spaceLeft,
	hideUpgradeSection = false,
	showHeader = true,
}: AnalyticsTabContentProps<S, T>): JSX.Element {
	return (
		<>
			<Typography
				className={styles.title}
				variant="headline5"
				medium
				color="text-primary-v2"
			>
				{title}
			</Typography>
			<AnalyticsTable
				data={storageSummary}
				hideUpgradeSection={hideUpgradeSection}
				className={styles.lastTableContainer}
				showHeader={showHeader}
			/>
			{subtitle && (
				<Typography variant="body" medium color="text-primary-v2">
					{subtitle}
				</Typography>
			)}
			<AnalyticsTable
				className={styles.tableContainer}
				data={spaceLeft}
				hideUpgradeSection={!!spaceLeft || hideUpgradeSection}
				showHeader={showHeader}
			/>
		</>
	);
}

const RANDOM_WIDTHS = ["70%", "55%", "85%"];

type AnalyticsTableProps<T> = {
	className?: string;
	data?: InternalTableProps<T>;
	hideUpgradeSection?: boolean;
	showHeader?: boolean;
};

interface LooseObject {
	[key: string]: any;
}

/**
 * Builds analytics tables. Also pads the data with skeletons if restricted.
 */
function AnalyticsTable<T extends LooseObject>({
	className,
	data,
	hideUpgradeSection = false,
	showHeader = true,
}: AnalyticsTableProps<T>): JSX.Element {
	const { is_limit_reached: restrictFreezerAnalytics } =
		useFeatureRestrictionHook("freezer_analytics");

	if (!data || !data.columns) return <></>;

	const { columns, mockDataSource } = data;
	let { dataSource: internalDataSource } = data;
	const mainChildrenDataTable = [] as any[];
	let classNameWithChildren = "";

	if (restrictFreezerAnalytics && mockDataSource) {
		internalDataSource = [...mockDataSource];

		// if the mock data has children we want to add the skeletons to the children rather than the top level data
		const mockDataChildren = (internalDataSource[0] as any)?.children as
			| T[]
			| undefined;
		const skeletonDataParent = mockDataChildren || internalDataSource;

		skeletonDataParent.forEach((_, ind) => {
			const currentRow = skeletonDataParent[ind];
			const currentRowColumnNames = Object.keys(currentRow);
			const tempCurrentRow: LooseObject = {};

			currentRowColumnNames.forEach((colName) => {
				if (colName === "name") {
					tempCurrentRow[colName] = (skeletonDataParent[ind] as any)[
						"name"
					];
				} else {
					tempCurrentRow[colName] = (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
							}}
						>
							<GenemodSkeleton
								width={RANDOM_WIDTHS[ind]}
								height={15}
								shape="round"
								animate={false}
								style={{
									backgroundColor:
										ColorCssVarMap["text-ghost"],
									opacity: "0.3",
								}}
							/>
						</div>
					);
				}
			});

			mainChildrenDataTable.push(tempCurrentRow);
		});

		if (Object.keys(internalDataSource[0]).includes("children")) {
			classNameWithChildren =
				"sidePanel-analytics-table-style-with-children";
			(internalDataSource[0] as any)["children"] = mainChildrenDataTable;
		}
	}

	return (
		<>
			<Table
				className={cx(
					"sidePanel-analytics-table-style",
					className,
					classNameWithChildren,
					"hideColumnBorders"
				)}
				dataSource={internalDataSource}
				columns={columns}
				showHeader={showHeader}
			/>
			{restrictFreezerAnalytics && !hideUpgradeSection && (
				<RestrictionGradientLayout
					title={emphasizeText`Upgrade to unlock ${"automated analytics"}`(
						"accent-subtle"
					)}
				/>
			)}
		</>
	);
}
