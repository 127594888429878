import { Dispatch, SetStateAction, useCallback, useLayoutEffect } from "react";

/** @file Useful hooks */

/**
 * Generic hooks
 * @module @helpers/Hooks
 * */
import { axios } from "@API";
import { useHistory, useParams } from "@common/helpers/Hooks/UseRouterDom";
import { HandledAxiosError } from "@redux/helpers/AxiosErrorHelpers";
import { DataPayload } from "@redux/helpers/DataPayload";
import { useAppDispatch } from "@redux/store";
import { AsyncThunk } from "@reduxjs/toolkit";
import { useOrganizationRouter } from "@root/AppRouter";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { NominalType, PersistedObject } from "./TypeHelpers";

import { useItemTypesQuery } from "@redux/inventory/Item";
import { nanoid } from "nanoid";

/**
 *  Hook that fetches data given an API endpoint.
 * @return {Array} Array containing fetched data, loading state, and a method to manually set data: [data, isLoading, setData]
 */
export function useDataFetch<T>(
	/** Endpoint URL */
	route: string,
	options: {
		/** Processes returned data */
		dataProcessor?: (data: any) => T;
		initialState?: T;
		/** Initial state of data */
		additionalDependencies?: any;
		/** Additional array of dependencies that should trigger an attempt at fetching */
		shouldFetch?: boolean;
		/** If true, then performs data fetch. Default true */
		callback?: (data: T) => void;
		/** Called after data is successfully fetched and processed */
		onFail?: () => void;
		/** Maximum number of attempts. Default 3 */
		maxAttempts?: number;
	} = {}
) {
	const {
		dataProcessor = null,
		initialState = null,
		additionalDependencies = [],
		shouldFetch = true,
		callback = null,
		onFail = null,
		maxAttempts = 3,
	} = options;
	const [attempts, setAttempts] = useState(maxAttempts);
	const [data, setData] = useState(initialState);
	const [isLoading, setLoading] = useState(shouldFetch);

	useEffect(() => {
		if (shouldFetch) {
			setLoading(true);
			axios
				.get(route)
				.then((res) => {
					let data = res.data;
					if (dataProcessor) {
						data = dataProcessor(data);
					}
					setData(data);
					setLoading(false);
					return data;
				})
				.then(callback)
				.catch((err) => {
					if (err.response) {
						if (err.response.status === 404) {
							// No data
							setLoading(false);
						} else {
							// Retry if remaining attempts
							if (attempts - 1 > 0) {
								setAttempts(attempts - 1);
							} else {
								setLoading(false);
								if (onFail) onFail();
							}
						}
					} else {
						console.log(err);
						setLoading(false);
						if (onFail) onFail();
					}
				});
		}
	}, [attempts, ...additionalDependencies]);

	return [data, isLoading, setData] as const;
}

/**
 * Hook for checking if a password meets
 * @function
 *
 * @param {string} password Password to be evaluated
 * @returns {array} Returns an array of 5 booleans.
 * isValid: Overall validity of the password
 * minLength: True if password meets length requirements
 * containsChar: True if password contains a character
 * containsNum: True if password contains a number
 * containsSpecial: True if password contains a special character
 */
/** Hook for checking if a password meets */
export function usePasswordValidation(
	/** Password to be evaluated */
	password: string
) {
	const [isValid, setValid] = useState(false);
	const [minLength, setMinLength] = useState(false);
	const [containsChar, setContainsChar] = useState(false);
	const [containsNum, setContainsNum] = useState(false);
	const [containsSpecialChar, setContainsSpecialChar] = useState(false);

	// Returns true if string is 8 characters
	const checkMinLength = (s: string) => {
		return s.length >= 8;
	};
	// Returns true if string contains 1 character
	const checkContainsChar = (s: string) => {
		return /(?=.*[a-zA-Z])/.test(s);
	};
	// Returns true if string contains a number
	const checkContainsNum = (s: string) => {
		return /\d/.test(s);
	};
	// Returns true if string contains a special character
	const checkSpecialChar = (s: string) => {
		return /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(s);
	};

	useEffect(() => {
		const ml = checkMinLength(password);
		if (ml !== minLength) setMinLength(ml);

		const cc = checkContainsChar(password);
		if (containsChar !== cc) setContainsChar(cc);

		const cn = checkContainsNum(password);
		if (containsNum !== cn) setContainsNum(cn);

		const cs = checkSpecialChar(password);
		if (containsSpecialChar !== cs) setContainsSpecialChar(cs);

		const validity = ml && cc && cn && cs;
		if (validity !== isValid) setValid(validity);
	}, [password]);
	return [
		isValid,
		minLength,
		containsChar,
		containsNum,
		containsSpecialChar,
	] as const;
}

/**
 * Saves previous value as it changes
 *
 * @param {any} [initialState=null] Initial state of data
 *
 * @return {any} The previous state of data
 * */

export function usePrevious<T>(
	/** Initial state of data */
	value: T
) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef<T>();

	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current;
}

/** get window Dimensions */
export function getWindowDimensions(): {
	width: number;
	height: number;
} {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

/** Hook that fetches the viewport/window dimension. */
export function useWindowDimensions(): {
	width: number;
	height: number;
} {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useResizeEffect(setWindowDimensions, false, []);

	return windowDimensions;
}

/**
 * Function that takes in an element and returns the height of the element in the window minus top and bottom padding.
 */
const getInnerHeight = (el: HTMLElement) => {
	const styles = window.getComputedStyle(el);
	const padding =
		parseFloat(styles.paddingTop) + parseFloat(styles.paddingBottom);
	return el.clientHeight - padding;
};

/**
 * Hook that takes in a ref, gets the parent height, and returns the height of the parent minus the sibling elements heights.
 */
export function useParentHeightMinusSiblings(
	/** Ref of the element whose parent height is to be calculated */
	ref: React.RefObject<HTMLElement>,
	extraDependencies: any[] = []
) {
	// Rerender when window dimensions change
	const { height: windowHeight } = useWindowDimensions();
	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (ref.current) {
			const parent = ref.current.parentElement;
			if (!parent) return;

			const parentHeight = getInnerHeight(parent);

			const siblings = Array.from(parent.children)
				.filter((child) => child !== ref.current)
				.map((child) => child.clientHeight);
			const siblingsHeight = siblings.reduce(
				(acc, height) => acc + height,
				0
			);

			setHeight(parentHeight - siblingsHeight);
		}
	}, [ref, windowHeight, ...extraDependencies]);

	return height;
}

/** Hook that returns the rack content width based on the amount of columns and rows in a rack. */
export function getRackContentDimensions(
	/** total rows in a rack */
	totalRows: number,
	/** total columns in a rack */
	totalCols: number,
	/** whether the width of the screen window is greater than 1920 px */
	isLargeScreen: boolean
): {
	width: number;
	height: number;
} {
	const lessThanFourColumns = totalCols <= 4;
	const LARGE_SCREEN_BOX_SIZE = {
		width: lessThanFourColumns ? 237.5 : 106.8,
		height: 124,
	};

	const SMALL_SCREEN_BOX_SIZE = {
		width: lessThanFourColumns ? 157.5 : 70.6,
		height: 80,
	};

	const singleBoxDimension = isLargeScreen
		? LARGE_SCREEN_BOX_SIZE
		: SMALL_SCREEN_BOX_SIZE;

	const rackContentsWidth = totalCols * singleBoxDimension.width;
	const rackContentsHeight = totalRows * singleBoxDimension.height;

	const returnedDimensions = {
		height: rackContentsHeight,
		width: rackContentsWidth,
	};

	return returnedDimensions;
}

/** Thunk that returns a function taking in a custom item type id and returns the item type name or "Loading" */
export function useItemTypeNameMappingsFn(): (itemTypeId: number) => string {
	const { data: itemTypes } = useItemTypesQuery();
	return (itemTypeId: number) =>
		itemTypes?.find((type) => type.id === itemTypeId)?.name || "Loading";
}

/**
 * "Debounces" given value so that the output value only changes after value is stable for as long as the given "millis" arguemnt.
 * Used to prevent spamming search requests/buttons/etc.
 * taken from https://usehooks.com/useDebounce/
 */
export function useDebounce<T>(value: T, millis: number): T {
	return useDebouncedCallbackWithValue(() => value, value, millis, [value]);
}

/**
 * If the edge is "LEADING", then the value will be true if both the previous and current value are true.
 * If the edge is "TRAILING", then the value will be true if either the previous or current value are true.
 */
export function useEdgeDebounce(
	value: boolean,
	edge: "LEADING" | "TRAILING",
	millis: number
): boolean {
	// add logic to debounce based on edge
	const prev = useDebounce(value, millis);
	if (edge === "LEADING") {
		return prev && value;
	}
	return prev || value;
}

/**
 * Debounces dependencies and calls callback after they are settled for millis number of milliseconds.
 */
export function useDebouncedCallback(
	callback: () => void,
	millis: number,
	dependencies: any[]
) {
	useDebouncedCallbackWithValue(callback, null, millis, dependencies);
}

/**
 * Debounces dependencies and calls callback after they are settled for millis number of milliseconds. Returns the result of the callback.
 */
export function useDebouncedCallbackWithValue<T>(
	callback: (oldResult: T) => T,
	initialValue: T,
	millis: number,
	dependencies: any[]
): T {
	const [result, setResult] = useState<T>(initialValue);
	useEffect(() => {
		// Update debounced value after delay
		const handler = setTimeout(() => {
			const newResult = callback(result);
			setResult(newResult);
		}, millis);
		return () => {
			clearTimeout(handler);
		};
	}, [millis, ...dependencies]);
	return result;
}

export const useDebounceEventHandler = <T>(
	callback: (arg: T) => void,
	millis = 200
): ((arg: T) => void) => {
	const handler = useRef<NodeJS.Timeout | null>(null);
	return (arg: T) => {
		if (handler.current) {
			clearTimeout(handler.current);
		}
		handler.current = setTimeout(() => {
			callback(arg);
		}, millis);
	};
};

/**
 * Abstracts away commonly used state related to API calls
 */
export type InfiniteScrollingAPI = {
	/** Current page number */
	page: number;
	/** Sets the page number */
	setPage: Dispatch<SetStateAction<number>>;
	/** Loading state */
	loading: boolean;
	/** Sets the loading state */
	setLoading: Dispatch<SetStateAction<boolean>>;
	/** If true, invokes the callback when the `target` element is visible in the `root` element. */
	enabled: boolean;
	/** Controls the invocation of the callback. */
	setEnabled: Dispatch<SetStateAction<boolean>>;
	/** The page size */
	pageSize: number;
	/** Sets the page size in the API object. */
	setPageSize: Dispatch<SetStateAction<number>>;
};

export type InfiniteScrollingConfig = {
	initOptions?: IntersectionObserverInit & {
		page?: number;
		enabled?: boolean;
		pageSize?: number;
		/** True, fetch data at first although target is not intersected*/
		firstFetch?: boolean;
		additionalDependencies?: any[];
		initialLoading?: boolean;
	};
	callback: (
		api: InfiniteScrollingAPI,
		entries: IntersectionObserverEntry[],
		observer: IntersectionObserver
	) => void;
	/**
	 * A ref to the HTML Element that triggers the callback function when intersecting the `root` defined in the initOptions.
	 */
	target: React.RefObject<any>;
};

/**
 * Hook that provides infinite scrolling functionality
 * @see IntersectionObserver https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * Usage:
 *
 * Create a ref to an element that should trigger a callback when it becomes visible:
 * const myRef = useRef();
 * <div ref={myRef}/>
 *
 * Pass it to the hook via `config.target`:
 * useInfiniteScrolling({ callback, target: myRef?.current })
 *
 */
export function useInfiniteScrolling(
	config: InfiniteScrollingConfig
): InfiniteScrollingAPI {
	const { callback, initOptions, target } = config;
	const [loading, setLoading] = useState<boolean>(
		initOptions?.initialLoading || false
	);
	const [enabled, setEnabled] = useState<boolean>(
		initOptions?.enabled ?? true
	);
	const [page, setPage] = useState<number>(initOptions?.page || 1);
	const [pageSize, setPageSize] = useState<number>(
		initOptions?.pageSize || 25
	);
	const [firstFetch, setfirstFetch] = useState(
		initOptions?.firstFetch || false
	);

	const api: InfiniteScrollingAPI = {
		loading,
		setLoading,
		enabled,
		setEnabled,
		page,
		setPage,
		pageSize,
		setPageSize,
	};

	const handleObserver = useCallback(
		(
			entries: IntersectionObserverEntry[],
			observer: IntersectionObserver
		) => {
			if (
				firstFetch ||
				(entries.length &&
					entries[0].isIntersecting &&
					enabled &&
					!loading)
			) {
				// Only call the callback when the target is intersecting
				// or if firstFetch is True
				setLoading(true);
				setfirstFetch(false);
				callback(api, entries, observer);
			}
		},
		[
			enabled,
			loading,
			callback,
			...(initOptions?.additionalDependencies || []),
		]
	);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, initOptions);
		if (target && target.current) {
			observer.observe(target.current);
		}
		return () => {
			observer.disconnect();
		};
	}, [target.current, handleObserver]);

	return api;
}

/**
 * Read object id from url parameter, fetch the object via redux action, 404 on failure to fetch oject.
 */
export const useLoadObjectFromUrlParam = <
	T extends PersistedObject,
	URLParam extends string
>(
	urlParamName: URLParam,
	payload: DataPayload<T | undefined>,
	fetchAction: AsyncThunk<T, T["id"], HandledAxiosError>,
	alsoOnFetch?: (id: T["id"]) => void
) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { getToolRoute } = useOrganizationRouter();

	const id = payload.data?.id;
	const urlId = (+useParams<Record<URLParam, string>>()[urlParamName] ||
		null) as T["id"] | null;

	if (urlId === null) {
		history.replace(getToolRoute("NOT_FOUND"));
	}

	useEffect(() => {
		if (urlId !== null) {
			dispatch(fetchAction(urlId));
			if (alsoOnFetch) {
				alsoOnFetch(urlId);
			}
		}
	}, [urlId]);

	useEffect(() => {
		if (
			payload.status === "ERROR" &&
			id !== urlId // if data is still in the store that matches the url then it was probably an error when we tried to update the data in a subcomponent
		) {
			history.push(getToolRoute("NOT_FOUND"));
		}
	}, [payload.status]);
	return payload;
};

/**
 * If you pass a callback into the native window.addEventListener any setStates done inside of it are not "batched" like in normal functions. So they can
 * run out of order with other set states in different parts of your code. This forces the listener to batch setStates. The types are taken from
 * the TS libraries (lib.dom.d.ts).
 */
type EventTypes = keyof WindowEventMap;
type ListenerFunc<K extends EventTypes> = (
	this: Window,
	ev: WindowEventMap[K]
) => any;
export const useSafeWindowEventListener = <K extends EventTypes>(
	type: K,
	listener: ListenerFunc<K>,
	options?: boolean | AddEventListenerOptions
) => {
	// store the listener in a ref so that it can be accessed in the effect immediately following
	// a render. Prevents the listener from being stale during out of sync events.
	const listenerRef = useRef(listener);
	listenerRef.current = listener;

	useEffect(() => {
		// initiate the event handler
		const bachedUpdateHandler = (ev: WindowEventMap[K]) =>
			ReactDOM.unstable_batchedUpdates(() =>
				(listenerRef.current as any)(ev)
			);
		window.addEventListener(
			type,
			bachedUpdateHandler as ListenerFunc<K>,
			options
		);

		// this will clean up the event every time the component is re-rendered
		return () => window.removeEventListener(type, bachedUpdateHandler);
	});
};

type DocumentEventTypes = keyof DocumentEventMap;
type DocumentListenerFunc<K extends DocumentEventTypes> = (
	this: Window,
	ev: DocumentEventMap[K]
) => any;
export const useSafeDocumentEventListener = <K extends DocumentEventTypes>(
	type: K,
	listener: DocumentListenerFunc<K>,
	options?: boolean | AddEventListenerOptions
) => {
	// store the listener in a ref so that it can be accessed in the effect immediately following
	// a render. Prevents the listener from being stale during out of sync events.
	const listenerRef = useRef(listener);
	listenerRef.current = listener;

	useEffect(() => {
		// initiate the event handler
		const bachedUpdateHandler = (ev: DocumentEventMap[K]) =>
			ReactDOM.unstable_batchedUpdates(() =>
				(listenerRef.current as any)(ev)
			);
		document.addEventListener(type, bachedUpdateHandler, options);

		// this will clean up the event every time the component is re-rendered
		return () => document.removeEventListener(type, bachedUpdateHandler);
	});
};

/**
 * Effect that is triggered on window resize. Callback is passed dimension parameters.
 */
export function useResizeEffect(
	callback: ({ width, height }: { width: number; height: number }) => void,
	runOnSetup = false,
	deps?: any[]
) {
	useEffect(() => {
		const handleResize = () => {
			callback(getWindowDimensions());
		};
		if (runOnSetup) {
			handleResize();
		}
		window.addEventListener("resize", handleResize);
		return () => {
			// It turns out the useEffect cleanup is called really fast and can remove
			// the event listener before the callback can even run. Adding a timeout
			// delays it until the next event loop cycle allowing the callback to run.
			// Note: this might be fixed in React 17: https://github.com/facebook/react/pull/17925
			setTimeout(() =>
				window.removeEventListener("resize", handleResize)
			);
		};
	}, deps);
}

/**
 * Provides a ref value, as well as a function to update the ref value, which also sets a dummy
 * state value to force the component to rerender.
 */
export function useRefWithRerender<T>(initialValue: T) {
	const ref = useRef<T>(initialValue);
	const [_forceRerender, setForceRerender] = useState(0);

	const setRef = (newValue: T) => {
		ref.current = newValue;
		setForceRerender((v) => v + 1);
	};

	return [ref, setRef] as const;
}

/**
 * Calls a callback on component unmount
 */
export function useOnUnmount(callback: () => void) {
	useLayoutEffect(() => {
		return () => {
			callback();
		};
	}, []);
}

/**
 * Use this "semaphore" to send a signal to child components to do some action.
 */
export type Semaphore = NominalType<"StateSempahore", number>;
export function useSemaphore() {
	const [semaphore, setSemaphore] = useState(0 as Semaphore);
	const updateSempahore = useCallback(
		() => setSemaphore((semaphore) => (semaphore + 1) as Semaphore),
		[]
	);
	return [semaphore, updateSempahore] as const;
}

type UseOptionallyControlledInputProps<T> = {
	value?: T;
	defaultValue: T;
	onChange?: (value: T) => void;
	isEqual?: (a?: T, b?: T) => boolean;
};
/**
 * Handles the logic for storing an internal value for when an input component needs to controlled externally or internally.
 */
export const useOptionallyControlledInput = <T>({
	value,
	defaultValue,
	onChange,
	isEqual = (a, b) => a === b,
}: UseOptionallyControlledInputProps<T>): [T, (v: T) => void] => {
	const [internalValue, setInternalValue] = useState(value || defaultValue);

	// Update internal value if value prop changed
	useEffect(() => {
		if (!isEqual(value, internalValue)) {
			setInternalValue(value || defaultValue);
		}
	}, [value]);

	const handleChange = (newValue: T) => {
		if (!isEqual(internalValue, newValue)) {
			setInternalValue(newValue);
		}
		if (!isEqual(value, newValue)) {
			onChange?.(newValue);
		}
	};
	return [value || internalValue, handleChange];
};

/**
 * Provides function that forces rerender
 */
export function useForceUpdate() {
	const [_, setValue] = useState(0);
	return () => setValue((value) => value + 1);
}

/**
 * Only use callback after first render
 */
export function useEffectAfterFirstCall(callback: () => void, deps?: any[]) {
	const firstCall = useRef(true);
	useEffect(() => {
		if (firstCall.current) {
			firstCall.current = false;
			return;
		}
		callback();
	}, deps);
}

/**
 * @returns a unique id that can be used as a class name
 */
export const useUniqueClass = () => {
	const [uniqueId] = useState(`uniq${nanoid().replace(/^[_-]/g, "")}`);
	return uniqueId;
};

/**
 * Provides a helper hook to toggle the side panel depending on the item that was click on a table
 */
export const useTogglePanel = (
	item: any,
	closePanel: boolean,
	setOpenPanel: (value: boolean) => void,
	setClosePanel: (value: boolean) => void,
	setItemId: (item: any) => void
) => {
	const [prevItem, setPrevItem] = useState();

	const onToggle = (close: boolean, newItem: any) => {
		if (close && newItem === prevItem) {
			setOpenPanel(false);
			setClosePanel(false);
			setItemId(null);
		} else {
			if (newItem && newItem !== prevItem) {
				setOpenPanel(true);
				setClosePanel(false);
			}
		}
	};

	useEffect(() => {
		setPrevItem(item);
		const timeout = setTimeout(() => {
			onToggle(closePanel, item);
		}, 300);
		return () => clearTimeout(timeout);
	}, [closePanel, item]);

	return null;
};
