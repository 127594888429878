import { Typography } from "@common/components";
import {
	ResponsiveTable,
	ResponsiveTableColumns,
} from "@common/components/Table/ResponsiveTable";
import { ItemMetadata, ItemUsedInFile } from "@common/types";
import { Link } from "@helpers/Hooks/UseRouterDom";
import { useOrganizationRouter } from "@root/AppRouter";
import { PM_ROUTES } from "@root/routes";
import React from "react";
import styles from "./InterconectivityMetadata.module.scss";
import { InterconectivityTooltip } from "./InterconectivityMetadata";

function ItemInterconectivityMetadata({
	metadata,
}: {
	metadata: ItemMetadata;
}) {
	const { used_in_files, mentioned_in_files, organization_prefix } = metadata;

	const { appendBaseUrl } = useOrganizationRouter();

	const usedFilesColumns: ResponsiveTableColumns<ItemUsedInFile>[] = [
		{
			key: "name",
			title: "Used in files",
			render: (_, used) => {
				const experimentUrl = appendBaseUrl(
					PM_ROUTES.FETCH_EXPERIMENT.replace(
						":id",
						used.id.toString()
					)
				);
				return (
					<InterconectivityTooltip
						title={used.file.file}
						orgPrefix={organization_prefix}
						id={used.file.file_id}
					>
						<>
							<div className={styles.linkParent}>
								<Link to={experimentUrl}>
									<Typography
										variant="label"
										color="link-primary"
										style={{ textDecoration: "underline" }}
									>
										{used.file.file}
									</Typography>
								</Link>
							</div>
						</>
					</InterconectivityTooltip>
				);
			},
		},
	];

	const mentionedFilesColumns: ResponsiveTableColumns<ItemUsedInFile>[] = [
		{
			key: "name",
			title: "Mentioned in files",
			render: (_, mentioned) => {
				const experimentUrl = appendBaseUrl(
					PM_ROUTES.FETCH_EXPERIMENT.replace(
						":id",
						mentioned.id.toString()
					)
				);
				return (
					<InterconectivityTooltip
						title={mentioned.file.file}
						orgPrefix={organization_prefix}
						id={mentioned.file.file_id}
					>
						<>
							<div className={styles.linkParent}>
								<Link to={experimentUrl}>
									<Typography
										variant="label"
										color="link-primary"
										style={{ textDecoration: "underline" }}
									>
										{mentioned.file.file}
									</Typography>
								</Link>
							</div>
						</>
					</InterconectivityTooltip>
				);
			},
		},
	];

	if (used_in_files.length === 0 && mentioned_in_files.length === 0) {
		return (
			<Typography variant="regular" color="text-tertiary-v2">
				This item is not used or mentioned in any files
			</Typography>
		);
	}

	return (
		<div>
			{used_in_files.length > 0 && (
				<>
					<ResponsiveTable
						hideColumnBorders
						dataSource={used_in_files}
						columns={usedFilesColumns}
						onRowClick={(record) => {
							const experimentUrl = appendBaseUrl(
								PM_ROUTES.FETCH_EXPERIMENT.replace(
									":id",
									record.file.file_id.toString()
								)
							);
							window.open(experimentUrl, "_blank");
						}}
					/>
				</>
			)}

			<div
				style={{
					backgroundColor: "var(--border-invisible)",
					height: 0,
					marginTop: 10,
				}}
			/>

			{mentioned_in_files.length > 0 && (
				<>
					<ResponsiveTable
						hideColumnBorders
						dataSource={mentioned_in_files}
						columns={mentionedFilesColumns}
						onRowClick={(record) => {
							const experimentUrl = appendBaseUrl(
								PM_ROUTES.FETCH_EXPERIMENT.replace(
									":id",
									record.file.file_id.toString()
								)
							);
							window.open(experimentUrl, "_blank");
						}}
					/>
				</>
			)}
		</div>
	);
}

export default ItemInterconectivityMetadata;
