/** 72 is default margin for 96 PPi letter (54 is for 72 PPI) */
export const PDF_PAGE_MARGIN = {
	top: 72,
	left: 72,
	right: 72,
	bottom: 72,
};

export const GENEMOD_LOGO_STYLE = Object.freeze({
	w: 120,
	h: 32,
	marginLeft: 32,
});

export const COLOR_RGB = Object.freeze({
	text_01: [0, 0, 0],
	text_02: [82, 82, 82],
	link: [65, 140, 216], //link-primary
	item_title: [35, 31, 32], // #231F20
	section_header_line: [216, 217, 217], //#d8d9d9
	section_detail_line: [238, 239, 240], //#eeeff0
});

export const FONT_STYLE = Object.freeze({
	title: {
		fontSize: 16,
		lineHeight: 26,
		color: COLOR_RGB.item_title,
	},
	body: {
		fontSize: 11,
		lineHeight: 18,
		color: COLOR_RGB.text_01,
	},
	label: {
		fontSize: 10,
		lineHeight: 14,
		color: COLOR_RGB.text_02,
	},
});
