import { connectRouter, routerMiddleware } from "connected-react-router";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { accountApi } from "./Account/AccountApi";
import { projectManagementApi } from "./ProjectManagement/PmApiSlice";
import { metadataApi } from "./Metadata/MetadataApiSlice";
import { pmDocumentSlice } from "./ProjectManagement/PmDocumentSlices";
import { projectPageSlice } from "./ProjectManagement/ProjectManagementSlices";
import { boxViewSlice } from "./freezer/BoxViewSlice";
import { spaceViewSlice } from "./freezer/SpaceViewSlice";
import { userApi } from "./user/UserApi";
import { pmSettingsSlice } from "./ProjectManagement/PmSettingsSlice";
import {
	commonModalReducers,
	itemTypeSlice,
} from "./CommonModals/CommonModals";
import { freezerApi } from "./freezer/FreezerApiSlice";
import { dashboardApi } from "./Dashboard/DashboardApiSlice";
import { analyticsApi } from "./Analytics/AnalyticsApi";
import { eventsApi } from "./events";
import { teamApi } from "./team/TeamApi";
import { adminEnterpriseApi } from "./AdminEnterprise/adminEnterpriseApiSlices";
import { consumableApi } from "./freezer/ConsumableApiSlice";
import { boxTableSlice } from "./freezer/BoxTableSlice";
import {
	baseSidePanelSlice,
	commonPanelReducers,
} from "./CommonPanels/CommonPanels";
import { pmTablesSlice } from "./ProjectManagement/PmTablesSlice";
import { chatGPTApi } from "./ChatGPT/ChatGPTApiSlice";
import { inventoryApi } from "./inventory";
import { equipmentsApi } from "./Facility/equipmentsApiSlice";

// Using require here because the import method is deprecated and will be removed in the next version of history
const createHistory = require("history").createBrowserHistory;

export const history = createHistory();

/**
 * Note: for some reason if we try to put the code to setup this root reducer inside of the configureStore argument object
 * we lose all of the types for AppState. Keep this declared separately.
 *
 * reducer is the top-level reducer
 */
const reducer = combineReducers({
	router: connectRouter(history) as any,
	freezer: combineReducers({
		boxView: boxViewSlice.reducer,
		spaceView: spaceViewSlice.reducer,
		boxTable: boxTableSlice.reducer,
	}),
	pm: combineReducers({
		documents: pmDocumentSlice.reducer,
		pmSettings: pmSettingsSlice.reducer,
		projectPage: projectPageSlice.reducer,
		pmTables: pmTablesSlice.reducer,
	}),
	commonModals: commonModalReducers,
	commonPanels: commonPanelReducers,
	baseSidePanel: baseSidePanelSlice.reducer,
	itemType: itemTypeSlice.reducer,

	// Api slices
	// Note: They must be at top level, not nested in a "combine Reducer"
	[freezerApi.reducerPath]: freezerApi.reducer,
	[metadataApi.reducerPath]: metadataApi.reducer,
	[projectManagementApi.reducerPath]: projectManagementApi.reducer,
	[userApi.reducerPath]: userApi.reducer,
	[dashboardApi.reducerPath]: dashboardApi.reducer,
	[accountApi.reducerPath]: accountApi.reducer,
	[analyticsApi.reducerPath]: analyticsApi.reducer,
	[eventsApi.reducerPath]: eventsApi.reducer,
	[adminEnterpriseApi.reducerPath]: adminEnterpriseApi.reducer,
	[teamApi.reducerPath]: teamApi.reducer,
	[consumableApi.reducerPath]: consumableApi.reducer,
	[chatGPTApi.reducerPath]: chatGPTApi.reducer,
	[inventoryApi.reducerPath]: inventoryApi.reducer,
	[equipmentsApi.reducerPath]: equipmentsApi.reducer,
});

export const store = configureStore({
	reducer: reducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(routerMiddleware(history))
			.concat(projectManagementApi.middleware)
			.concat(userApi.middleware)
			.concat(metadataApi.middleware)
			.concat(freezerApi.middleware)
			.concat(dashboardApi.middleware)
			.concat(accountApi.middleware)
			.concat(analyticsApi.middleware)
			.concat(adminEnterpriseApi.middleware)
			.concat(eventsApi.middleware)
			.concat(teamApi.middleware)
			.concat(eventsApi.middleware)
			.concat(consumableApi.middleware)
			.concat(chatGPTApi.middleware)
			.concat(equipmentsApi.middleware)
			.concat(inventoryApi.middleware),
	devTools: true,
});

export type AppState = ReturnType<typeof store.getState>;
export const appSelector: TypedUseSelectorHook<AppState> = useSelector;

setupListeners(store.dispatch);
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
