import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	const cls3 = { fill: "#911eb4" };
	const textStyle = {
		fill: "#fff",
		fontSize: "8px",
		fontFamily: "OpenSans-Extrabold, Open Sans",
		fontWeight: 800,
		letterSpacing: "0.06em",
	};
	return (
		<svg
			id="type_xps"
			xmlns="http://www.w3.org/2000/svg"
			width={48}
			height={48}
			viewBox="0 0 48 48"
			{...props}
		>
			<g id="XMLID_23_" transform="translate(-51.8 2)">
				<g id="XMLID_137_" transform="translate(62.8 6)">
					<path
						id="Path_4288"
						data-name="Path 4288"
						d="M2 0h13.568l7.551 7.48V30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
						transform="translate(1.61)"
						fill="#f5dae9"
					/>
					<path
						id="XMLID_140_"
						style={cls3}
						d="m330.7 6 7.584 7.475H330.7z"
						transform="translate(-313.554 -6)"
					/>
					<path
						id="XMLID_139_"
						style={cls3}
						transform="translate(0 18.739)"
						d="M0 0h21.056v9.99H0z"
					/>
					<path
						id="XMLID_138_"
						d="m62.8 454.9 1.606 1.357V454.9z"
						transform="translate(-62.8 -426.17)"
						fill="#5f1376"
					/>
				</g>
				<text
					id="xps"
					transform="translate(66.684 31.5)"
					style={textStyle}
				>
					<tspan x="0" y="0">
						xps
					</tspan>
				</text>
			</g>
		</svg>
	);
}

const XpsIcon = React.memo(SvgComponent);
export default XpsIcon;
