import { WorkspaceId } from "@common/types";
import classNames from "classnames";
import React, { HTMLProps } from "react";
import Popover from "../Popover/Popover";
import TeamAvatar from "../TeamAvatar";
import Typography from "../Typography/Typography";
import styles from "./TeamAvatarGroup.module.scss";
import { useGetPersonalWorkspacesQuery } from "@redux/team/TeamApi";
import { Spin } from "@components";

type Props = {
	workspaceIds: WorkspaceId[];
	maxAvatarNum?: number;
	fontSize?: number;
	avatarSize?: number;
} & HTMLProps<HTMLDivElement>;

const TeamAvatarGroup = ({
	workspaceIds,
	maxAvatarNum = 6,
	fontSize = 14,
	avatarSize = 24,
	...props
}: Props): JSX.Element => {
	const { data, isLoading } = useGetPersonalWorkspacesQuery({
		get_all: true,
	});
	const workspaces = data || [];
	const workspaceNames = workspaceIds.map((id) => {
		const workspace = workspaces.find((workspace) => workspace.id === id);
		return workspace?.name || "";
	});
	const avatars = workspaceNames.slice(0, maxAvatarNum);
	return (
		<Spin spinning={isLoading}>
			<div
				{...props}
				className={classNames(styles.wrapper, props.className)}
			>
				{avatars.map((name, index) => (
					<TeamAvatar
						avatarSize={avatarSize}
						fontSize={fontSize}
						key={index}
						teamName={name}
						tooltip
					/>
				))}
				{avatars.length !== workspaceNames.length && (
					<Popover
						content={
							<div>
								{workspaceNames
									.slice(maxAvatarNum, workspaceNames.length)
									.map((team, index) => (
										<div
											className={styles.teamCard}
											key={index}
										>
											<TeamAvatar
												avatarSize={avatarSize}
												fontSize={fontSize}
												teamName={team}
											/>
											<Typography>{team}</Typography>
										</div>
									))}
							</div>
						}
					>
						<Typography className={styles.plusNumber}>
							+{workspaceNames.length - avatars.length}
						</Typography>
					</Popover>
				)}
			</div>
		</Spin>
	);
};

export default TeamAvatarGroup;
