import React, { useMemo } from "react";
import { Button } from "@components";
import useCurrentWorkspaceUserHooks from "@helpers/Hooks/UseCurrentWorkspaceUserHook";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import { useOrganizationRouter } from "@root/AppRouter";
import { ACCOUNT_SETTINGS_PATHS } from "@root/routes";
import { ButtonType } from "../Button";

type UpgradeButtonProps = {
	/** Button className */
	className?: string;
	/**extra function call when the button is clicked */
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	stretch?: boolean;
	/** Button style */
	style?: React.CSSProperties;
	text?: string;
	size?: "small" | "default";
	type?: ButtonType;
};

export default function ExploreButton({
	className,
	onClick,
	stretch = false,
	style,
	text,
	size,
	type,
}: UpgradeButtonProps): JSX.Element {
	const { isOrgAdmin } = useCurrentWorkspaceUserHooks();
	const { appendBaseUrl } = useOrganizationRouter();

	const link = useMemo(() => {
		if (isOrgAdmin) {
			return {
				to: {
					pathname: appendBaseUrl(
						ACCOUNT_SETTINGS_PATHS.CHANGE_PLAN.route
					),
				},
			};
		}

		return {
			to: {
				pathname: "https://genemod.net/pricing",
			},
			target: "_blank",
		};
	}, [isOrgAdmin]);

	return (
		<Link
			{...link}
			style={{
				width: "100%",
			}}
		>
			<Button
				type={type}
				size={size}
				onClick={onClick}
				stretch={stretch}
				className={className}
				style={style}
				data-cy="explore-plans-button"
			>
				{text || "Explore plans"}
			</Button>
		</Link>
	);
}
