import React, { useState, useEffect } from "react";
import {
	Input,
	Modal,
	TextArea,
	Notification,
	ClickToEdit,
	LoadingScreen,
	Typography,
	GenemodIcon,
} from "@components";
import RearrangeModal from "@containers/Freezer/components/RearrangeModal/RearrangeModal";
import { SegmentTrackEvent, SegmentFreezerEvents } from "@Segment";
import { useDebounceEventHandler, useWindowDimensions } from "@helpers/Hooks";
import ShelfRow from "./ShelfRow";
import "./index.scss";
import { useHistory, useLocation } from "@common/helpers/Hooks/UseRouterDom";
import { useFreezer } from "../Contents";
import classNames from "classnames";
import { truncArgs } from "@helpers/Formatters";
import { FREEZER_NAME_CHAR_LIMITS } from "@containers/Freezer/data";
import Select from "@common/components/Select/Select";
import {
	Shelf,
	ShelfDataForBackend,
	Rack,
	isRack,
	Freezer,
	ITEM_STATUS,
} from "@common/types";
import { Category } from "@common/types/Category";
import {
	useFreezerUpdateMutation,
	useGetRepositoryFreezersQuery,
} from "@redux/inventory/Freezer";
import {
	useShelvesQuery,
	useCreateShelfMutation,
	useGetRepositoryShelvesQuery,
} from "@redux/inventory/Shelf";
import styles from "./ShelfView.module.scss";
import { FreezerMenu } from "@containers/Freezer/components/FreezerCard/FreezerCard";

const REARRANGE_SHELF_MODAL_WIDTH = 472;
const REARRANGE_SHELF_MODAL_WIDTH_LARGE = 698;

type ShelfViewProps = {
	selectRackOrCategory: (
		rack: Rack | Category,
		select?: boolean,
		callback?: () => void
	) => void;
	setShowEditShelfModal: (show: boolean) => void;
	setShowRearrangeRacksModal: (show: boolean) => void;
	showEditShelfModal: boolean;
	showRearrangeRacksModal: boolean;
};

export default function ShelfView(props: ShelfViewProps): JSX.Element {
	const [addShelf, setAddShelf] = useState<boolean>(false);
	const [editFreezer, setEditFreezer] = useState<boolean>(false);
	const [changeDimension, setChangeDimension] = useState<boolean>(false);
	const [shelfRow, setShelfRow] = useState<number>(-1);
	const onClickEmptyShelf = (rowIndex: number) => {
		setShelfRow(rowIndex);
		setAddShelf(true);
	};
	const { freezer } = useFreezer();
	const { data: shelves = [] } = useShelvesQuery(
		{
			id: freezer?.id as number,
			is_archive: freezer?.is_archived as boolean,
		},
		{
			skip: !freezer?.id,
		}
	);
	const [addShelfToFreezer] = useCreateShelfMutation();
	const [updateFreezer] = useFreezerUpdateMutation();
	const screenWidth = Object(useWindowDimensions()).width;
	const shelfModalWidth =
		screenWidth >= 1920
			? REARRANGE_SHELF_MODAL_WIDTH_LARGE
			: REARRANGE_SHELF_MODAL_WIDTH;

	const handleAddShelf = (shelfInfo: Partial<Shelf>) => {
		if (!freezer) return;

		// Set parent fridge id and location
		shelfInfo.freezer = freezer.id;
		shelfInfo.row = shelfRow;

		const createShelfData = shelfInfo as ShelfDataForBackend;
		addShelfToFreezer(createShelfData)
			.unwrap()
			.then((shelf) => {
				SegmentTrackEvent(SegmentFreezerEvents.SHELF_CREATE, {
					id: shelf.id,
				});
				setAddShelf(false);
			})
			.catch((err) => console.log(err));
	};

	const handleFreezerRename = (newName: string) => {
		if (!freezer) return;
		updateFreezer({
			id: freezer.id,
			name: newName.trim(),
		})
			.unwrap()
			.then((freezer) =>
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${freezer.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				})
			)
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		if (props.showRearrangeRacksModal) {
			setEditFreezer(true);
		} else {
			setEditFreezer(false);
		}
	}, [props.showRearrangeRacksModal]);

	useEffect(() => {
		if (props.showEditShelfModal) {
			setEditFreezer(true);
			setChangeDimension(true);
		} else {
			setEditFreezer(false);
			setChangeDimension(false);
		}
	}, [props.showEditShelfModal]);

	const [isLoading, setLoading] = useState<boolean>(false);
	const createShelfView = () => {
		return (
			<>
				<LoadingScreen isLoading={isLoading} message="Saving...">
					<div className={styles.shelfView}>
						<div className={styles.title}>
							<GenemodIcon
								name="freezer"
								fill="text-secondary-v2"
							/>
							<ClickToEdit
								className={styles.clickToEdit}
								value={freezer?.name || ""}
								onComplete={(val) => {
									if (!freezer) return;
									updateFreezer({
										id: freezer.id,
										name: val,
									});
								}}
								variant="headline5"
								color="text-secondary-v2"
								addConfirmAndCancel={false}
								component="input"
								lightBackground
								cancelEditionIfEmpty
								tabToSave
							/>
							{freezer && (
								<div style={{ marginLeft: "auto" }}>
									<FreezerMenu freezer={freezer}>
										<GenemodIcon name="meatballs" />
									</FreezerMenu>
								</div>
							)}
						</div>
						<div className={styles.shelfTable}>
							{Array(freezer?.shelves)
								.fill(freezer?.shelves)
								.map((_, index) => {
									const shelf = shelves?.filter(
										(s: Shelf) => s.row === index
									);
									if (shelf?.length) {
										return shelf[0];
									} else {
										return null;
									}
								})
								.map((shelf, index) => {
									return (
										<ShelfRow
											key={index}
											index={index}
											onClickEmptyShelf={
												onClickEmptyShelf
											}
											isEditingFreezer={editFreezer}
											isChangingDimension={
												changeDimension
											}
											selectRackOrCategory={
												props.selectRackOrCategory
											}
											shelf={shelf}
											showEditShelfModal={
												props.showEditShelfModal
											}
											setLoading={setLoading}
										/>
									);
								})}
						</div>
					</div>
				</LoadingScreen>
				<CreateShelfModal
					visible={addShelf}
					onConfirm={handleAddShelf}
					onCancel={() => setAddShelf(false)}
				/>
			</>
		);
	};

	return (
		<div className={styles.left}>
			<>
				{props.showRearrangeRacksModal === false &&
					props.showEditShelfModal === false &&
					createShelfView()}

				{/* Modal for rearranging racks on the shelf */}
				<RearrangeModal
					visible={props.showRearrangeRacksModal}
					className={styles.rearrangeRacks}
					width={shelfModalWidth}
					onOk={() => props.setShowRearrangeRacksModal(false)}
				>
					<div className="rearrange-racks-view">
						{createShelfView()}
					</div>
				</RearrangeModal>
				{/* Modal for changing shelf's dimension */}
				<RearrangeModal
					title="Change dimension"
					className={styles.changeDimension}
					visible={props.showEditShelfModal}
					width={472}
					onOk={() => props.setShowEditShelfModal(false)}
				>
					<div className="change-dimension-view">
						{createShelfView()}
					</div>
				</RearrangeModal>
			</>
		</div>
	);
}

type CreateShelfModalProps = {
	visible: boolean;
	onConfirm: (arg: Partial<Shelf>) => void;
	onCancel: () => void;
	isRepository?: boolean;
};
export function CreateShelfModal(props: CreateShelfModalProps) {
	useEffect(() => {
		setName("");
		setDescription("");
		setFreezer(null);
		setShowErrors(false);
	}, [props.visible]);

	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [showErrors, setShowErrors] = useState(false);
	const [freezer, setFreezer] = useState<Freezer | null>(null);

	const validateForm = () => {
		return !!name.trim().length;
	};

	const handleSubmit = () => {
		if (!validateForm()) {
			setShowErrors(true);
			return;
		}

		const info = {
			name: name,
			description: description,
			...(props.isRepository &&
				freezer && {
					freezer: freezer.id,
					row: (freezer as any).shelves_number,
				}),
		};

		props.onConfirm(info);
	};

	return (
		<Modal
			destroyOnClose
			title="New shelf"
			okText={"Create"}
			hideCancelButton
			onOk={handleSubmit}
			visible={props.visible}
			onCancel={props.onCancel}
			okButtonProps={{
				disabled: !validateForm(),
				style: {
					pointerEvents: "visible",
				},
			}}
			dataCy="add-shelf-modal"
		>
			<div className="content">
				<div>
					<Input
						autoFocus
						value={name}
						label="Shelf name*"
						placeholder={"e.g. Top shelf"}
						onChange={(e) => setName(e.target.value)}
						onPressEnter={handleSubmit}
						error={!name && showErrors ? "Required" : undefined}
						dataCy="new-shelf-name-input"
					/>
					{!props.isRepository ? (
						<TextArea
							label="Description"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							placeholder={"Keep everyone on the same page..."}
							style={{ minHeight: 124 }}
							dataCy="new-shelf-description-input"
						/>
					) : (
						<FreezerSelect
							freezer={freezer}
							setFreezer={setFreezer}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
}

function FreezerSelect({
	freezer,
	setFreezer,
}: {
	freezer: Freezer | null;
	setFreezer: (value: Freezer | null) => void;
}) {
	const [freezerSearch, setFreezerSearch] = useState<string>("");
	// const { setHasErrors } = useContext(ErrorsContext);
	const onSetConsumableSearch = useDebounceEventHandler((value: string) => {
		setFreezerSearch(value);
	}, 350);
	const { data: freezersData } = useGetRepositoryFreezersQuery(
		{
			page: 1,
			page_size: 10,
			search: freezerSearch,
			ordering: "-updated_at",
			status__in: [ITEM_STATUS.ACTIVE],
		},
		{ refetchOnMountOrArgChange: true }
	);

	return (
		<>
			<div
				style={{
					marginTop: "24px",
					gridColumn: "first-half-start / second-half-end",
				}}
			>
				<Typography
					variant="label"
					bold
					style={{
						marginBottom: "8px",
						fontFamily: "var(--text-primary)",
					}}
				>
					Location
				</Typography>
				<Select
					className="locationSelect"
					allowClear
					clearIcon={
						<GenemodIcon
							name="exit"
							size="small"
							onClick={() => {
								setFreezer(null);
							}}
						/>
					}
					isInput
					showSearch
					value={freezer?.name || freezerSearch || undefined}
					// handleFormData(newProps.key, e.target.value);
					onSelect={(n) => {
						const [idStr] = (n as string).split("-");
						const selectedId = parseInt(idStr, 10);
						const freezerFinded = freezersData?.results.find(
							(freezer) => freezer.id === selectedId
						);

						if (freezerFinded) {
							setFreezer(freezerFinded as any as Freezer);
						}
					}}
					notFoundContent={
						<div>
							<span>Freezer not found</span>
						</div>
					}
					onSearch={(value) => onSetConsumableSearch(value)}
					placeholder="Search a parent freezer"
					dataCy="create-item-box-select"
				>
					{freezersData?.results.map((freezer) => {
						const option = freezer as any;
						return (
							<Select.Option
								key={option.id}
								value={`${option.id}-${option.name}`}
								className="boxSelectItem"
								style={{
									padding: "4px 16px",
								}}
								disabled={
									option.shelves === option.shelves_number
								}
							>
								<div>
									<div className="boxNameContainer">
										<span
											style={{
												color: "var(--text-secondary)",
											}}
										>
											{option.name}
										</span>
										<span
											style={{
												color: "var(--text-tertiary)",
											}}
										>
											{option.shelves -
												option.shelves_number}
											{" slots available"}
										</span>
									</div>
								</div>
							</Select.Option>
						);
					})}
				</Select>
			</div>
		</>
	);
}

export function ShelfSelect({
	shelf,
	setShelf,
}: {
	shelf: Shelf | null;
	setShelf: (value: Shelf | null) => void;
}) {
	const [shelfSearch, setShelfSearch] = useState<string>("");

	const onSetConsumableSearch = useDebounceEventHandler((value: string) => {
		setShelfSearch(value);
	}, 350);

	const { data: shelvesData } = useGetRepositoryShelvesQuery({
		page_size: 10,
		page: 1,
		ordering: "-updated_at",
		search: shelfSearch,
		freezer__in: null,
		status__in: [ITEM_STATUS.ACTIVE],
		updated_by__in: null,
		include_filter_options: null,
		updated_at__gte: null,
		updated_at__lte: null,
		created_by__in: null,
		created_at__gte: null,
		created_at__lte: null,
	});

	return (
		<>
			<div
				style={{
					marginTop: "24px",
					gridColumn: "first-half-start / second-half-end",
				}}
			>
				<Typography
					variant="label"
					bold
					style={{
						marginBottom: "8px",
						fontFamily: "var(--text-primary)",
					}}
				>
					Location
				</Typography>
				<Select
					className="locationSelect"
					allowClear
					clearIcon={
						<GenemodIcon
							name="exit"
							size="small"
							onClick={() => {
								setShelf(null);
							}}
						/>
					}
					isInput
					showSearch
					value={shelf?.name || shelfSearch || undefined}
					// handleFormData(newProps.key, e.target.value);
					onSelect={(n) => {
						const [idStr] = (n as string).split("-");
						const selectedId = parseInt(idStr, 10);
						const shelfFinded = shelvesData?.results.find(
							(shelf) => shelf.id === selectedId
						);

						if (shelfFinded) {
							setShelf(shelfFinded as any as Shelf);
						}
					}}
					notFoundContent={
						<div>
							<span>Shelf not found</span>
						</div>
					}
					onSearch={(value) => onSetConsumableSearch(value)}
					placeholder="Search a parent Shelf"
					dataCy="create-item-box-select"
				>
					{shelvesData?.results
						.filter((s) => !s.is_archived)
						.map((shelf) => {
							const option = shelf as any;
							return (
								<Select.Option
									key={option.id}
									value={`${option.id}-${option.name}`}
									className="boxSelectItem"
									style={{
										padding: "4px 16px",
									}}
								>
									<div>
										<div className="boxNameContainer">
											<span
												style={{
													color: "var(--text-secondary)",
												}}
											>
												{option.name}
											</span>
										</div>
										<Typography
											color="text-tertiary"
											variant="caption"
										>
											{option.location_data?.freezer_name}
										</Typography>
									</div>
								</Select.Option>
							);
						})}
				</Select>
			</div>
		</>
	);
}

type ShelfViewContentProps = {
	showRearrangeRacksModal: boolean;
	showEditShelfModal: boolean;
	freezerId: number;
	isChangingDimension?: boolean;
};
export const ShelfViewContent = ({
	showEditShelfModal,
	showRearrangeRacksModal,
	freezerId,
	isChangingDimension = false,
}: ShelfViewContentProps): JSX.Element => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const { data: shelves = [] } = useShelvesQuery({ id: freezerId });
	const history = useHistory();
	const location = useLocation();
	const { freezer } = useFreezer(freezerId);

	const [addShelf, setAddShelf] = useState<boolean>(false);
	const [shelfRow, setShelfRow] = useState<number>(-1);

	const onClickEmptyShelf = (rowIndex: number) => {
		setShelfRow(rowIndex);
		setAddShelf(true);
	};
	const [updateFreezer] = useFreezerUpdateMutation();
	const [addShelfToFreezer] = useCreateShelfMutation();

	const handleFreezerRename = (newName: string) => {
		if (!freezer) return;
		updateFreezer({
			id: freezer.id,
			name: newName.trim(),
		})
			.unwrap()
			.then((freezer) =>
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${freezer.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				})
			)
			.catch((err) => console.log(err));
	};

	const handleAddShelf = (shelfInfo: Partial<Shelf>) => {
		if (!freezer) return;

		// Set parent fridge id and location
		shelfInfo.freezer = freezer.id;
		shelfInfo.row = shelfRow;

		const createShelfData = shelfInfo as ShelfDataForBackend;
		addShelfToFreezer(createShelfData)
			.unwrap()
			.then((shelf) => {
				SegmentTrackEvent(SegmentFreezerEvents.SHELF_CREATE, {
					id: shelf.id,
				});
				setAddShelf(false);
			})
			.catch((err) => console.log(err));
	};

	const selectRackOrCategory = (
		obj: Rack | Category,
		select = false,
		callback = () => {}
	) => {
		const originalParams = new URLSearchParams(location.search);
		const params = new URLSearchParams(location.search);
		const currentRack = params.get("selected_rack");
		const currentCategory = params.get("selected_category");
		if (select) {
			if (isRack(obj)) {
				params.set("selected_rack", "" + obj.id);
				params.delete("selected_category");
			} else {
				params.set("selected_category", "" + obj.id);
				params.delete("selected_rack");
			}
		} else if (currentRack && parseInt(currentRack) === obj.id) {
			params.delete("selected_rack");
		} else if (currentCategory && parseInt(currentCategory) === obj.id) {
			params.delete("selected_category");
		} else {
			if (isRack(obj)) {
				params.set("selected_rack", "" + obj.id);
				params.delete("selected_category");
			} else {
				params.set("selected_category", "" + obj.id);
				params.delete("selected_rack");
			}
		}
		if (originalParams.toString() !== params.toString()) {
			history.push({
				pathname: location.pathname,
				search: "?" + params.toString(),
			});
		}
		if (callback) callback();
	};

	return (
		<>
			<LoadingScreen isLoading={isLoading} message="Saving...">
				{!showRearrangeRacksModal && !showEditShelfModal && (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: 11,
						}}
					>
						<ClickToEdit
							value={freezer?.name || ""}
							onComplete={(e) => {
								handleFreezerRename(e);
							}}
							component="input"
							className={styles.freezerName}
							validators={[
								{
									validator: (val) => val.length > 0,
									error: "Please specify the name",
								},
							]}
							maxLength={FREEZER_NAME_CHAR_LIMITS.FREEZER}
							readOnly={freezer?.is_archived}
							stretchInput
						/>
					</div>
				)}
				<div
					className={classNames(
						"genemod-lightmode",
						"freeze-image-container"
					)}
				>
					<div
						className={classNames(
							"freezer-image",
							"genemod-lightmode"
						)}
					>
						<div className={styles.shelfTable}>
							{Array(freezer?.shelves)
								.fill(freezer?.shelves)
								.map((_, index) => {
									const shelf = shelves?.filter(
										(s: Shelf) => s.row === index
									);
									if (shelf?.length) {
										return shelf[0];
									} else {
										return null;
									}
								})
								.map((shelf, index) => {
									return (
										<ShelfRow
											key={index}
											index={index}
											onClickEmptyShelf={
												onClickEmptyShelf
											}
											isEditingFreezer={true}
											isChangingDimension={
												isChangingDimension
											}
											selectRackOrCategory={
												selectRackOrCategory
											}
											shelf={shelf}
											showEditShelfModal={
												showEditShelfModal
											}
											setLoading={setLoading}
											freezer={freezer}
										/>
									);
								})}
						</div>
					</div>
				</div>
			</LoadingScreen>
			<CreateShelfModal
				visible={addShelf}
				onConfirm={handleAddShelf}
				onCancel={() => setAddShelf(false)}
			/>
		</>
	);
};
