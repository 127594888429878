import { NominalId } from "@helpers/TypeHelpers";
import {
	Row,
	Column,
	OrganizationId,
	Avatar,
	ISOString,
	SpaceId,
} from "@common/types";
import { FURNITURE_COLOR } from "@containers/Freezer/SpaceView/SpaceViewData";

export type FurnitureLocation = {
	row: Row;
	column: Column;
};

export type FurnitureId = NominalId<"FurnitureId">;

export type Furniture = {
	/** Id for furniture */
	id: FurnitureId;
	/** Name of the object */
	name: string;
	/** owner */
	created_by: Avatar;
	/** locations */
	locations: FurnitureLocation[];
	/** description */
	description: string;
	/** created at */
	created_at: string;
	/** updated at */
	updated_at: ISOString;
	/** parent space */
	parent_space: SpaceId;
	/** furniture type */
	furniture_type: FurnitureTypeId;
	/** number of consumable items associated witht the furniture */
	total_consumables?: number;
};

export type FurnitureColor = keyof typeof FURNITURE_COLOR;

export type FurnitureTypeId = NominalId<"FurnitureTypeId">;

export type FurnitureType = {
	/** Id for furniture type */
	id: FurnitureTypeId;
	/** Name of the object */
	name: string;
	/** Owner avatar for furniture type */
	created_by: Avatar;
	/** Color enum for furniture type */
	color: FurnitureColor;
	/** Is furniture type a layout type */
	is_layout: boolean;
	/** Created date for furniture type */
	created_at: ISOString;
	/** Updated date for furniture type */
	updated_at: ISOString;
	/** Organization id for furniture type */
	org: OrganizationId;
};
