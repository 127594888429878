import React, { useEffect, useState } from "react";
import { useLinkUrl } from "@helpers/Hooks/UseUrlLink";
import { Link, useHistory } from "@common/helpers/Hooks/UseRouterDom";
import Button from "../Button";
import { Modal } from "../Modal/Modal";
import Typography from "../Typography/Typography";
import styles from "./WhatsNewPopup.module.scss";
import {
	useMarkWhatsNewAsReadMutation,
	useWhatsNewUpdatesQuery,
} from "@redux/Dashboard/DashboardApiSlice";
import {
	useCurrentUserQuery,
	usePatchCurrentUserMutation,
} from "@redux/user/UserApi";

const WhatsNewPopup = (): JSX.Element => {
	const history = useHistory();
	const getUrl = useLinkUrl();
	const [markRead] = useMarkWhatsNewAsReadMutation();
	const [patchUser] = usePatchCurrentUserMutation();
	const { data: user } = useCurrentUserQuery();
	const { data } = useWhatsNewUpdatesQuery();
	const entries = data || [];

	const {
		id,
		title,
		body_content,
		availability,
		explore_now,
		action_text,
		featured_image,
		action_href,
	} = entries[0] || {};

	// We only want to check if the modal should be open once on page load.
	const [hasCheckedOpenStatus, setHasCheckedOpenStatus] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (user && id) {
			if (!hasCheckedOpenStatus) {
				const isModalOpenRedux =
					!!user && !!id && user?.last_seen_updates < id;
				setIsModalOpen(isModalOpenRedux);
			}

			// Prevent modal from opening after page load (say when user or entries are refreshed)
			setHasCheckedOpenStatus(true);
		}
	}, [setHasCheckedOpenStatus, setIsModalOpen, user, id]);

	const updateLastSeen = () => {
		setIsModalOpen(false);
		if (!user) return;
		patchUser({ id: user?.id, last_seen_updates: id });
	};

	const updateLastSeenAndMarkRead = () => {
		updateLastSeen();
		markRead({ id });
	};

	return (
		<Modal
			//TODO : TEMPORARILY HIDING OUT
			visible={false}
			className={styles.modal}
			hideCancelButton
			hideOkButton
			onCancel={updateLastSeen}
			width={576}
		>
			<>
				<img
					height={240}
					width="100%"
					src={featured_image}
					alt="New feature"
					className={styles.img}
				/>
				<div className={styles.content}>
					<div>
						<Typography variant="title" bold>
							{title}
						</Typography>
						<Typography
							color="text-secondary"
							style={{
								fontStyle: "italic",
								marginBottom: 12,
								marginTop: 12,
							}}
						>
							{availability}
						</Typography>
						<span style={{ fontSize: 32 }}>🎉</span>
					</div>
					<div>
						<Typography style={{ marginBottom: 37 }}>
							{body_content}
						</Typography>
						<div
							style={{
								display: "grid",
								gap: 24,
								gridTemplateColumns: "repeat(2, 1fr)",
							}}
						>
							<Link
								to={{ pathname: action_href }}
								target="_blank"
								onClick={updateLastSeenAndMarkRead}
							>
								<Button
									shape="squared"
									type="ghost"
									className={styles.footerButton}
								>
									{action_text}
								</Button>
							</Link>
							<Button
								onClick={() => {
									updateLastSeenAndMarkRead();
									history.push(getUrl(explore_now));
								}}
								shape="squared"
								className={styles.footerButton}
							>
								Explore now
							</Button>
						</div>
					</div>
				</div>
			</>
		</Modal>
	);
};

export default WhatsNewPopup;
