import React from "react";
import { Anchor } from "antd";
import styles from "./DemoTools.module.scss";
import "./DemoTools.scss";
const { Link } = Anchor;

// Wrapper for demos
//
// Props
// title: string
//   Title of demo section
// description: string or ReactNode
//   Description under the demo
export function Demo(props) {
	return (
		<div className={styles.demo} id={props.title}>
			<h3 style={{ marginBottom: "15px" }}>{props.title}</h3>
			<div className={styles.demoContent}>{props.children}</div>
			{props.description && (
				<div className={styles.demoDescription}>
					<strong className={styles.demoDescriptionHeader}>
						Description
					</strong>
					<br />
					<div>{props.description}</div>
				</div>
			)}
		</div>
	);
}

export function DemoSection(props) {
	return (
		<div className={styles["demo-section"]}>
			{props.title && <h2>{props.title}</h2>}
			<div className={styles["demo-section-content"]}>
				{props.children}
			</div>
		</div>
	);
}

export function DemoWrapper(props) {
	const renderAnchors = () => {
		if (props.children) {
			let source = props.children;
			if (!Array.isArray(props.children)) {
				source = [props.children];
			}
			const names = source.reduce((acc, section) => {
				let source2 = section.props.children;
				if (!Array.isArray(source2)) {
					source2 = [source2];
				}
				return [
					...acc,
					...source2.map((child) => {
						return child.props.title;
					}),
				];
			}, []);
			const links = names.map((name, i) => {
				return <Link key={i} href={"#" + name} title={name} />;
			});
			return (
				<Anchor
					getContainer={() =>
						document.getElementById("genemod-demowrapper")
					}
				>
					{links}
				</Anchor>
			);
		}
		return null;
	};

	return (
		<div className={styles.demoWrapper + " genemod-demowrapper"}>
			<div className={styles.wrapperLeft}>{props.children}</div>
			<div className={styles.wrapperRight}>{renderAnchors()}</div>
		</div>
	);
}
