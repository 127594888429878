import { BaseAttachment } from "./BaseAttachment";
import { ExperimentFile } from "./Folder";

export enum FileUploadStatus {
	READY = "Ready",
	UPLOADING = "Uploading",
	COMPLETE = "Complete",
	ERROR = "Error"
}

export type ExperimentAttachment = BaseAttachment & {
	experiment: number;
	document_type: "ExperimentAttachment";
};

export type FileToUpload = {
	experimentFile?: ExperimentFile;
	file: File;
	status: FileUploadStatus;
	index: number;
};
