// TODO: add 600dpi. Requires responsive font-size.
export const DPI_OPTIONS = [203, 300] as const;
export type DPI = (typeof DPI_OPTIONS)[number];
export type LabelDimensions = {
	width: number;
	height: number;
	unit: "in";
};
export type CanvasDimensions = {
	width: number;
	height: number;
};

/**
 * Returns the dimensions of a canvas/image in pixels given
 * - the dimensions of the label in inches and
 * - the DPI of the printer.
 *
 * TODO:
 * - Add support for other units
 */
export function getCanvasDimensions(
	labelDimensions: LabelDimensions,
	dpi: DPI
): CanvasDimensions {
	const { width, height } = labelDimensions;
	return {
		width: Math.round(width * dpi),
		height: Math.round(height * dpi),
	};
}
