import React from "react";
import styles from "./DetailsTab.module.scss";
import { ClickToEdit, Typography } from "@common/components";
import { PriceClickToEdit } from "@common/components/ClickToEdit";
import {
	CURRENCY_TYPES,
	FreezerOrder,
	ITEM_ORDER_CHAR_LIMITS,
} from "@common/types";
import { IdsInConstType } from "@helpers/TypeHelpers";

import { Divider } from "antd";
import { useCanModifyOrder } from "@helpers/Hooks/OrdersHooks";
import { useOrderPatchMutation } from "@redux/freezer/FreezerApiSlice";

type Props = {
	order: FreezerOrder | undefined;
};

type Field = {
	label: string;
	value: string;
	onComplete: (value: string) => void;
	type: "field";
};

type Label = {
	label: string;
	value: string;
	type: "label";
};

type CurrencyId = IdsInConstType<typeof CURRENCY_TYPES>;

type Price = {
	label: string;
	value: string;
	currencyValue: CurrencyId;
	onComplete: (val: string, currency: CurrencyId) => void;
	type: "price";
};

type ItemGroups = {
	isPrice?: boolean;
	isBold?: boolean;
	maxLength?: number;
} & (Field | Label | Price);

const DetailsTab = ({ order }: Props) => {
	const [patchOrder] = useOrderPatchMutation();
	const readOnly = !useCanModifyOrder()(order);

	const updateOrder = (data: Partial<FreezerOrder>) => {
		if (!order) return;
		patchOrder({
			id: order.id,
			...data,
		});
	};

	const currencyValue = order?.currency || 0;
	const cost: string | null =
		order && order.price ? Number(order.price).toFixed(2) : null;

	const fieldGroups: {
		fields: ItemGroups[];
	}[] = [
		{
			fields: [
				{
					type: "field",
					label: "Grant ID",
					value: order?.grant_id || "",
					onComplete: (val) => updateOrder({ grant_id: val }),
					maxLength: ITEM_ORDER_CHAR_LIMITS.GRANT_ID,
				},
				{
					type: "field",
					label: "Quantity",
					value: order?.quantity?.toString() || "",
					onComplete: (val) => updateOrder({ quantity: Number(val) }),
				},
				{
					type: "price",
					label: "Unit cost",
					value: Number(cost || "0").toFixed(2),
					currencyValue,
					onComplete: (val, currency) =>
						updateOrder({ price: val, currency }),
					isPrice: true,
					maxLength: ITEM_ORDER_CHAR_LIMITS.PRICE,
				},
				{
					type: "label",
					label: "Total",
					value: ((order?.quantity || 0) * Number(cost || "0"))
						.toFixed(2)
						.toString(),
					isPrice: true,
					isBold: true,
				},
			],
		},
		{
			fields: [
				{
					type: "field",
					label: "Source / Vendor",
					value: order?.source || "",
					onComplete: (val) => updateOrder({ source: val }),
					maxLength: ITEM_ORDER_CHAR_LIMITS.SOURCE,
				},
				{
					type: "field",
					label: "Link",
					value: order?.link || "",
					onComplete: (val) => updateOrder({ link: val }),
					maxLength: ITEM_ORDER_CHAR_LIMITS.LINK,
				},
				{
					type: "field",
					label: "Reference",
					value: order?.reference || "",
					onComplete: (val) => updateOrder({ reference: val }),
					maxLength: ITEM_ORDER_CHAR_LIMITS.REFERENCE,
				},
				{
					type: "field",
					label: "Catalog no.",
					value: order?.catalog || "",
					onComplete: (val) => updateOrder({ catalog: val }),
					maxLength: ITEM_ORDER_CHAR_LIMITS.CATALOG,
				},
				{
					type: "field",
					label: "Lot no.",
					value: order?.lot || "",
					onComplete: (val) => updateOrder({ lot: val }),
					maxLength: ITEM_ORDER_CHAR_LIMITS.LOT,
				},
				{
					type: "field",
					label: "Concentration",
					value: order?.concentration || "",
					onComplete: (val) => updateOrder({ concentration: val }),
					maxLength: ITEM_ORDER_CHAR_LIMITS.CONCENTRATION,
				},
				{
					type: "field",
					label: "Vol/Mass",
					value: order?.volume || "",
					onComplete: (val) => updateOrder({ volume: val }),
					maxLength: ITEM_ORDER_CHAR_LIMITS.VOLUME,
				},
				{
					type: "field",
					label: "Notes",
					value: order?.notes || "",
					onComplete: (val) => updateOrder({ notes: val }),
					maxLength: ITEM_ORDER_CHAR_LIMITS.NOTES,
				},
			],
		},
	];

	const renderValue = (field: ItemGroups) => {
		const { type, value } = field;
		if (type === "field") {
			const { onComplete } = field;
			return (
				<ClickToEdit
					value={value}
					onComplete={onComplete}
					component="input"
					style={{ width: "100%" }}
					readOnly={readOnly}
					detectLinks
					maxLength={field.maxLength || undefined}
				/>
			);
		} else if (type === "price") {
			const { onComplete, currencyValue } = field;
			return (
				<PriceClickToEdit
					value={value}
					currencyValue={currencyValue}
					onComplete={(val, curr) => onComplete(val as string, curr)}
					enterToSave
					wrapperProps={{ style: { marginBottom: 0 } }}
					readOnly={readOnly}
					maxLength={field.maxLength || undefined}
				/>
			);
		} else {
			return (
				<Typography
					variant="label"
					bold={field.isBold}
					semibold={!field.isBold}
					style={{ marginLeft: 8 }}
				>
					{value}
				</Typography>
			);
		}
	};

	return (
		<div className={styles.details}>
			{fieldGroups.map((group, index) => {
				return (
					<>
						<div className={styles.group} key={index}>
							{group.fields.map((field) => {
								return (
									<>
										<Typography
											variant="label"
											semibold
											color="text-primary-v2"
										>
											{field.label}
										</Typography>
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											{field.isPrice &&
												field.type === "label" && (
													<Typography
														bold={field.isBold}
														semibold={!field.isBold}
														variant="label"
														color="text-secondary-v2"
														style={{
															marginLeft: 8,
														}}
													>
														{
															CURRENCY_TYPES[
																currencyValue
															]
														}
													</Typography>
												)}
											{renderValue(field)}
										</div>
									</>
								);
							})}
						</div>
						{index !== fieldGroups.length - 1 && <Divider />}
					</>
				);
			})}
		</div>
	);
};

export default DetailsTab;
