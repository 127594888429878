import React from "react";
import {
	GenemodIcon,
	Input,
	Select,
	SelectV2,
	Typography,
	UpgradeButton,
} from "@components";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import styles from "./ItemTypeSelect.module.scss";
import useCurrentWorkspaceUserHooks from "@helpers/Hooks/UseCurrentWorkspaceUserHook";
import "./ItemTypeSelect.scss";

import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { CustomItemTypeSchema } from "@common/types";
import { useItemTypesQuery } from "@redux/inventory/Item";

type ItemTypeSelectProps = {
	value: number;
	defaultValue?: number;
	disabled?: boolean;
	onSelect: (value: number) => void;
	style?: React.CSSProperties;
	defaultActiveFirstOption?: boolean;
	onSelectCreateNewType?: () => void;
	onCloseCreateNewType?: () => void;
	onShowUpgradeModal?: () => void;
	onCloseUpgradeModal?: () => void;
	dataCy?: string;
	redirectTo?: string;
	isVisible?: boolean;
	useBodyColor?: boolean;
};

export default function ItemTypeSelect({
	value,
	defaultValue,
	disabled = false,
	onSelect,
	style,
	defaultActiveFirstOption = false,
	onSelectCreateNewType,
	onCloseCreateNewType,
	onShowUpgradeModal,
	onCloseUpgradeModal,
	dataCy,
	redirectTo,
	isVisible = false,
	useBodyColor = false,
}: ItemTypeSelectProps): JSX.Element {
	const { data = [] } = useItemTypesQuery(undefined, {});

	let itemTypes = [...data];
	const customItemTypeIndex = itemTypes.findIndex((type) => type.id >= 1000);
	const customItemTypes =
		customItemTypeIndex > 0 ? itemTypes.splice(customItemTypeIndex) : [];
	itemTypes = customItemTypes.concat(itemTypes);

	const {
		is_limit_reached: customItemTypeLimitReached,
		restricted: restrictedFeature,
	} = useFeatureRestrictionHook("custom_item_type");
	const { isCurrentWorkspaceUserAdmin } = useCurrentWorkspaceUserHooks();

	const { openUpgradeModal } = useCommonModalState("upgradeModal");
	const { openCreateItemTypeModal } = useCommonModalState(
		"createItemTypeModal"
	);

	const onSelectInternal = (value: number | "createNewType") => {
		const showUpgradeModal = () => {
			openUpgradeModal({
				type: restrictedFeature ? "TEMPLATE_NO_ACCESS" : "TEMPLATE",
			});
		};

		if (value === "createNewType") {
			if (customItemTypeLimitReached) {
				showUpgradeModal();
				onCloseUpgradeModal?.();
			} else {
				openCreateItemTypeModal({
					onClose: onCloseCreateNewType,
					redirectTo: redirectTo,
				});
				onSelectCreateNewType?.();
			}
		} else {
			const selectItemType = itemTypes?.find((it) => it.id === value);
			if (selectItemType?.is_locked) {
				showUpgradeModal();
				onShowUpgradeModal?.();
				onCloseUpgradeModal?.();
			} else {
				onSelect(value);
			}
		}
	};

	const getItemTypeOptionIcon = (itemType: CustomItemTypeSchema) => {
		if (!itemType.predefined && itemType.is_locked) {
			return (
				<GenemodIcon
					name="lock"
					style={{
						position: "absolute",
						right: 12,
						top: 12,
					}}
				/>
			);
		}
		return <></>;
	};

	const selectedType = itemTypes?.find((it) => it.id === value);

	return (
		<div>
			{" "}
			{disabled && <Input value={selectedType?.name} disabled />}
			{!disabled && (
				<SelectV2
					id="item-type-selector"
					onSelect={(val) =>
						onSelectInternal(val as number | "createNewType")
					}
					className={`type-selector ${
						useBodyColor ? "useBodyColor" : ""
					}`}
					defaultValue={defaultValue || value}
					value={value}
					style={style}
					defaultActiveFirstOption={defaultActiveFirstOption}
					optionLabelProp="label"
					dataCy={dataCy}
					visible={isVisible}
					size="middle"
				>
					{itemTypes?.map((itemType) => {
						const val = itemType.id;
						return (
							<SelectV2.Option
								key={val}
								value={val}
								label={itemType.name}
								data-cy={`${dataCy}-${itemType.name.replace(
									/ /g,
									""
								)}`}
							>
								<SelectV2.SelectedOpt
									isSelected={val === value}
									label={
										<Typography style={{ display: "flex" }}>
											{itemType.name}{" "}
											{getItemTypeOptionIcon(itemType)}
										</Typography>
									}
								/>
							</SelectV2.Option>
						);
					})}
					{isCurrentWorkspaceUserAdmin && (
						<SelectV2.Option
							value={"createNewType"}
							className={styles.createNewItemTypeOption}
							data-cy={`${dataCy}-custom-type`}
						>
							<Typography>Custom type</Typography>

							{restrictedFeature ? (
								<UpgradeButton
									type="tag"
									onClick={() => {}}
									style={{
										position: "absolute",
										top: 12,
										right: 12,
									}}
								/>
							) : (
								<GenemodIcon
									name="plus"
									stroke="text-secondary"
									style={{
										position: "absolute",
										right: 17,
										bottom: 14,
									}}
								/>
							)}
						</SelectV2.Option>
					)}
				</SelectV2>
			)}
		</div>
	);
}
