import React, { useEffect } from "react";
import { ColorCssVarMap } from "@common/styles/Colors";
import { useLinkUrl } from "@helpers/Hooks/UseUrlLink";
import {
	useMarkWhatsNewAsReadMutation,
	useWhatsNewUpdatesQuery,
} from "@redux/Dashboard/DashboardApiSlice";
import {
	useCurrentUserQuery,
	usePatchCurrentUserMutation,
} from "@redux/user/UserApi";
import moment from "moment";
import {
	Link,
	useHistory,
	useLocation,
} from "@common/helpers/Hooks/UseRouterDom";
import Button from "../Button";
import GenemodDot from "../GenemodDot";
import { Modal } from "../Modal/Modal";
import ToggleButton from "../Toggle/Toggle";
import Typography from "../Typography/Typography";
import styles from "./WhatsNewModal.module.scss";
import { Spin } from "@components";

type WhatsNewModalProps = {
	visible: boolean;
	onClose: () => void;
};

const WhatsNewModal = ({
	visible,
	onClose,
}: WhatsNewModalProps): JSX.Element => {
	const location = useLocation();
	const { data: user } = useCurrentUserQuery();
	const [markRead] = useMarkWhatsNewAsReadMutation();
	const [patchUser, { isLoading }] = usePatchCurrentUserMutation();
	const { data, isFetching, isError } = useWhatsNewUpdatesQuery();
	const entries = data || [];

	const history = useHistory();
	const getUrl = useLinkUrl();

	useEffect(() => {
		onClose();
	}, [location]);

	const footer = (
		<div className={styles.footer}>
			<div className={styles.footerContent}>
				<ToggleButton
					value={user?.notify_for_updates}
					onChange={(notify_for_updates: boolean) => {
						if (!user) return;
						patchUser({ id: user.id, notify_for_updates });
					}}
				/>
				<Spin spinning={isLoading}>
					<Typography variant="label" color="text-secondary">
						Subscribe to newsletter
					</Typography>
				</Spin>
			</div>
		</div>
	);

	const title = (
		<div className={styles.title}>
			<Typography variant="title" bold>
				What&apos;s new
			</Typography>
		</div>
	);

	return (
		<Modal
			className={styles.modal}
			width={640}
			title={title}
			visible={visible}
			onCancel={onClose}
			hideCancelButton
			hideOkButton
			// footer={footer}
			bodyStyle={{ maxHeight: 720, overflow: "scroll" }}
		>
			<div data-cy="whats-new-modal" className={styles.content}>
				<Spin spinning={isFetching} />
				{isError && (
					<Typography variant="headline">
						Failed to load posts! Please refresh the page and try
						again.
					</Typography>
				)}
				{!isError && entries.length === 0 && (
					<Typography variant="headline">
						No updates found.
					</Typography>
				)}
				<div>
					{entries.map((notice, i) => {
						const {
							id,
							title,
							published_at,
							availability,
							body_content,
							action_text,
							action_href,
							explore_now,
							featured_image,
							is_viewed,
						} = notice;
						const markPostRead = () => markRead({ id });
						return (
							<div className={styles.newsCard} key={i}>
								<Typography
									variant="label"
									color="text-tertiary"
								>
									{moment(published_at).format("LL")}
								</Typography>
								<div style={{ position: "relative" }}>
									{!is_viewed && (
										<GenemodDot
											color={
												ColorCssVarMap["accent-strong"]
											}
											size={8}
											wrapperProps={{
												className: styles.dot,
											}}
										/>
									)}
									<Typography
										style={{
											marginTop: 8,
											marginBottom: 12,
										}}
										variant="title"
										bold
									>
										{title}
									</Typography>
								</div>
								<Typography>{availability}</Typography>
								{featured_image && (
									<img
										height={240}
										width="100%"
										src={featured_image}
										alt="New feature"
										className={styles.img}
									/>
								)}
								<Typography>
									{body_content}{" "}
									{action_text && action_href && (
										<Link
											to={{ pathname: action_href }}
											onClick={markPostRead}
											target="_blank"
											className={styles.actionText}
										>
											{action_text}
										</Link>
									)}
								</Typography>
								{explore_now && (
									<Button
										onClick={() => {
											markPostRead();
											history.push(getUrl(explore_now));
										}}
										style={{
											marginBottom: 16,
											marginTop: 24,
											width: 121,
										}}
										type="ghost"
										shape="squared"
									>
										Explore now
									</Button>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</Modal>
	);
};

export default WhatsNewModal;
