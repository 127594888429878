import {
	Button,
	Modal,
	Notification,
	Select,
	Typography,
} from "@common/components";
import { ValidationCheckbox } from "@common/components/Checkbox/Checkbox";
import { ValidationInput } from "@common/components/Input/Input";
import { ValidationTextArea } from "@common/components/TextArea/TextArea";
import { useTheme } from "@common/components/Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "@common/components/Theme/context/ThemeTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRequestUpgradeMutation } from "@redux/Account/AccountApi";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import React, { useState } from "react";
import {
	Controller,
	FieldValues,
	FormProvider,
	useForm,
} from "react-hook-form";
import { z } from "zod";
import IMG_LIGHT from "./assets/img-01.svg";
import IMG_DARK from "./assets/img-02.svg";
import SUBMITTED_DARK from "./assets/submitted-dark.svg";
import SUBMITTED_LIGHT from "./assets/submitted-light.svg";
import styles from "./index.module.scss";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import useCurrentOrganization from "@helpers/Hooks/useCurrentOrganizationHook";

type Plans = "team" | "business" | "enterprise";

const featuresList = [
	"Inventory",
	"Notebook",
	"Stock tracker",
	"Protocol library",
	"Advanced search",
	"Order management",
	"Consumables",
	"Custom item type",
];

const selectOptions: { index: Plans; label: string }[] = [
	{
		index: "team",
		label: "Industry - Team",
	},
	{
		index: "business",
		label: "Industry - Business",
	},
	{
		index: "enterprise",
		label: "Industry - Enterprise",
	},
];

const validationSchema = z.object({
	email: z
		.string({
			required_error: "Email is required",
			invalid_type_error: "Please insert a valid email",
		})
		.email({ message: "Email is required" }),
	notes: z.string().optional(),
	feature_interests: z.string().array().optional(),
	plan: z.string().optional(),
});

export default function RequestUpgradeModal(): JSX.Element {
	const {
		isRequestUpgradeModalVisible,
		closeRequestUpgradeModal,
		requestUpgradeModalData: { defaultPlan },
	} = useCommonModalState("requestUpgradeModal");
	const [theme] = useTheme();
	const [submitted, setSubmitted] = useState(false);
	const { data: user } = useCurrentUserQuery(undefined);
	const methods = useForm({
		defaultValues: { email: user?.email },
		resolver: zodResolver(validationSchema),
	});
	const { handleSubmit, reset } = methods;
	const [requestUpgrade, { isLoading }] = useRequestUpgradeMutation();
	const { organizationId: orgId } = useCurrentOrganization();

	const onSubmit = ({
		email,
		notes,
		feature_interests,
		plan,
	}: FieldValues) => {
		if (!orgId || !plan) return;
		const requestFields = {
			email,
			...(notes ? { notes } : {}),
			...(feature_interests.length > 0
				? { feature_interests: feature_interests.join(";") }
				: {}),
			interested_pricing_tier: selectOptions.find(
				(opt) => opt.index === plan
			)?.label as string,
		};

		requestUpgrade({
			orgId,
			fields: requestFields,
		})
			.unwrap()
			.then(() => {
				setSubmitted(true);
			})
			.catch(() => {
				Notification.warning({
					message: "Failed to request plan upgrade. Please try again",
				});
			});
	};

	return (
		<Modal
			visible={isRequestUpgradeModalVisible}
			hideOkButton
			hideCancelButton
			width={772}
			onCancel={() => {
				closeRequestUpgradeModal();
				setSubmitted(false);
				reset();
			}}
			title={
				!submitted ? (
					<Typography style={{ fontSize: 32 }} bold>
						Request team upgrade below
					</Typography>
				) : undefined
			}
		>
			{!submitted ? (
				<div className={styles.requestUpgradeModal}>
					<div className={styles.content}>
						<FormProvider {...methods}>
							<ValidationInput
								name="email"
								label="Email"
								wrapperProps={{ style: { marginBottom: 24 } }}
							/>
							<Typography
								style={{ marginBottom: 9 }}
								variant="label"
								bold
							>
								Which pricing plan are you interested in?
							</Typography>
							<Controller
								name="plan"
								defaultValue={defaultPlan}
								shouldUnregister
								render={({ field }) => (
									<Select
										{...field}
										className={styles.select}
									>
										{selectOptions.map(
											({ index, label }) => (
												<Select.Option
													key={index}
													value={index}
												>
													{label}
												</Select.Option>
											)
										)}
									</Select>
								)}
							/>

							<Typography
								style={{ marginTop: 24 }}
								variant="label"
								bold
							>
								Which features are important to your team?
								(Optional)
							</Typography>
							<Controller
								name="feature_interests"
								defaultValue={[]}
								render={() => (
									<div className={styles.features}>
										{featuresList.map((feature, index) => (
											<ValidationCheckbox
												key={index}
												label={feature}
												name="feature_interests"
												value={feature}
											/>
										))}
									</div>
								)}
							/>

							<ValidationTextArea
								name="notes"
								label="Message (Optional)"
								placeholder="Anything else we should know?"
								wrapperProps={{
									style: { marginBottom: 32, marginTop: 28 },
								}}
							/>
							<div className={styles.actions}>
								<Button
									onClick={handleSubmit(onSubmit)}
									size="small"
									loading={isLoading}
								>
									Submit
								</Button>
							</div>
						</FormProvider>
					</div>
					<img
						className={styles.img}
						src={
							theme === THEME_PREFERENCE.light
								? IMG_LIGHT
								: IMG_DARK
						}
						alt="Upgrade plan"
					/>
				</div>
			) : (
				<div className={styles.submitted}>
					<img
						src={
							theme === THEME_PREFERENCE.light
								? SUBMITTED_LIGHT
								: SUBMITTED_DARK
						}
						alt="Submitted"
					/>
					<Typography
						style={{ marginTop: 32, marginBottom: 24 }}
						variant="largeTitle"
						bold
					>
						Thank you!
					</Typography>
					<Typography style={{ marginBottom: 32 }}>
						We have received your request. Our team will be in touch
						shortly.
					</Typography>
					<Button
						onClick={() => {
							closeRequestUpgradeModal();
							setSubmitted(false);
							reset();
						}}
					>
						Done
					</Button>
				</div>
			)}
		</Modal>
	);
}
