import {
	Button,
	ButtonV2,
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodIcon,
	Typography,
} from "@components";
import { useWindowDimensions } from "@helpers/Hooks";
import { useVisibleOverlayMask } from "@helpers/Hooks/UseVisibleOverlayHook";
import { Modal as MD } from "antd";
import { ModalProps as AntdModalProps } from "antd/lib/modal";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { ButtonProps } from "../Button";
import { ButtonV2Props } from "../ButtonV2/ButtonV2";
import {
	Layer,
	LayerClasses,
	LayerSystemContextContainer,
} from "../LayerSystemContainer/LayerSystemContainer";
import styles from "./Modal.module.scss";
import "./Modal.scss";

export type ModalProps = AntdModalProps & {
	/** whether to display the modal */
	visible: boolean;
	/** the text to be shown on the header of the modal */
	title?: string | JSX.Element;
	/** the className to apply style on the modal */
	className?: string;
	/** the className to apply stlye on modal wrapper */
	wrapClassName?: string;
	/** The value of modal width (default: 448) */
	width?: number;
	/** Whether to resize the modal on large screen */
	resize?: boolean;
	/** if false, hide closeIcon (default: true) */
	closable?: boolean;
	/** whether to show modal in center */
	centered?: boolean;
	/** the style of modal body */
	bodyStyle?: React.CSSProperties;
	/** the text to be shown on the ok button */
	okText?: string;
	/** onClick behavior when users click on ok button */
	onOk?: () => void;
	/** hide the ok button */
	hideOkButton?: boolean;
	/** the properties of okButton on the modal */
	okButtonProps?: ButtonV2Props;
	/** the text to be shown on the Cancel button */
	cancelText?: string;
	/** onClick behavior when users click on the close icon or close button */
	onCancel?: () => void;
	/** the properties of cancelButton on the modal */
	cancelButtonProps?: ButtonV2Props;
	/** hide the cancel button */
	hideCancelButton?: boolean;
	/** hide the close button */
	hideCloseButton?: boolean;
	/** the footer of modal */
	footer?: string | React.ReactNode;
	/** whether to display footer button reversely (default as false) */
	switchButtonsPos?: boolean;
	/** Unmounts the child components on close (default: true) */
	destroyOnClose?: boolean;
	/** whether to set loading on confim button */
	confirmLoading?: boolean;
	/** whether to display subtitle or JSX.Element under the title */
	subtitle?: string | JSX.Element;
	/** children of Modal component */
	children?: React.ReactNode;
	/** Whether to close (onCancel) the modal when the mask (area outside the modal) is clicked (default: false) */
	maskClosable?: boolean;
	/** Skips the "useVisibleOverlayMask" that prevents overlapping modals. Only use this for old modals. */
	skipVisibleOverlayMask?: boolean;
	layer?: Layer;
	dataCy?: string;
	closeIconClassName?: string;
};

export function Modal({
	title,
	visible: propVisible,
	onCancel,
	className,
	footer,
	okButtonProps,
	hideOkButton = false,
	cancelButtonProps,
	hideCancelButton = false,
	hideCloseButton = false,
	cancelText = "Cancel",
	onOk,
	okText = "Confirm",
	switchButtonsPos = false,
	width = 448,
	resize = true,
	closable = true,
	centered = true,
	closeIcon,
	bodyStyle,
	wrapClassName,
	destroyOnClose = true,
	confirmLoading,
	subtitle,
	children,
	getContainer,
	maskClosable = false,
	skipVisibleOverlayMask = false,
	// Layer  Layer use for the layer system
	// const LAYER = 1;
	layer: LAYER = 1,
	dataCy,
	closeIconClassName,
}: ModalProps): JSX.Element {
	const visible = useVisibleOverlayMask(
		"MODAL",
		propVisible,
		skipVisibleOverlayMask
	);

	const renderSubtitle = () => (
		<div style={{ marginBottom: "24px" }}>
			{typeof subtitle === "string" ? (
				<Typography color="text-tertiary-v2" variant="body2">
					{subtitle}
				</Typography>
			) : (
				subtitle
			)}
		</div>
	);

	const screenWidth = Object(useWindowDimensions()).width;
	const isLargeScreen = screenWidth >= 1920;
	const largeModalWidth = resize ? width * 1.1 : width;

	// The antd modal stops the footer from rerendering after visible becomes false. This
	// causes an issue where the footer still has the prop "visible = true" even when the
	// modal now has "visible = false". Since the modal has a fade close effect the footer
	// is still rendered for a bit and the button is still active while the modal closes.
	// If we store the visible in a ref we always have the most up to date value to block
	// button clicks while the modal is closing.
	const footerVisible = useRef(visible);
	footerVisible.current = visible;

	return (
		<MD
			data-cy={dataCy}
			title={
				title ? (
					<LayerSystemContextContainer overrideLayer={LAYER}>
						<Typography
							variant="headline4"
							color="text-primary-v2"
							medium
						>
							{title}
						</Typography>
					</LayerSystemContextContainer>
				) : (
					title
				)
			}
			visible={visible}
			footer={
				footer === undefined ? (
					<ModalDefaultFooter
						onCancel={onCancel}
						cancelButtonProps={cancelButtonProps}
						cancelText={cancelText}
						onOk={onOk}
						okButtonProps={okButtonProps}
						okText={okText}
						switchButtonsPos={switchButtonsPos}
						visible={footerVisible}
						hideCancelButton={hideCancelButton}
						hideOkButton={hideOkButton}
						dataCy={dataCy}
					/>
				) : (
					footer
				)
			}
			closeIcon={
				!hideCloseButton &&
				(closeIcon || (
					<GenemodIcon
						className={closeIconClassName}
						color="text-secondary-v2"
						name="exit"
						size="large"
						style={{
							position: "absolute",
							top: "32px",
							right: "32px",
						}}
						onClick={onCancel}
						dataCy={dataCy && dataCy + "-close-modal-icon"}
					/>
				))
			}
			centered={centered}
			closable={closable}
			width={isLargeScreen ? largeModalWidth : width}
			className={classNames(
				dataCy ? `${dataCy}-modal` : "",
				"genemod-modal",
				{
					["genemod-modal-small-gutter"]: subtitle,
					[styles.hideCloseButton]: hideCloseButton,
				},
				className,
				LayerClasses[`layer-${LAYER}`]
			)}
			bodyStyle={bodyStyle}
			wrapClassName={wrapClassName}
			destroyOnClose={destroyOnClose}
			confirmLoading={confirmLoading}
			getContainer={getContainer}
			onCancel={onCancel}
			maskClosable={maskClosable}
		>
			{/* We had to apply the layer system as a class on the modal above, use a component below with
			a override to provide context to the modal contents */}
			<LayerSystemContextContainer overrideLayer={LAYER}>
				{subtitle && renderSubtitle()}
				{children}
			</LayerSystemContextContainer>
		</MD>
	);
}

type ModalDefaultFooterProps = {
	okText: string;
	onOk?: () => void;
	okButtonProps?: ButtonV2Props;
	cancelText: string;
	onCancel?: () => void;
	cancelButtonProps?: ButtonV2Props;
	switchButtonsPos: boolean;
	visible: React.MutableRefObject<boolean>;
	hideOkButton?: boolean;
	hideCancelButton?: boolean;
	dataCy?: string;
};

function ModalDefaultFooter({
	switchButtonsPos,
	onCancel,
	cancelButtonProps,
	cancelText,
	onOk,
	okButtonProps,
	okText,
	visible,
	hideCancelButton = false,
	hideOkButton = false,
	dataCy,
}: ModalDefaultFooterProps): JSX.Element | null {
	if (hideCancelButton && hideOkButton) return null;
	return (
		<div
			className={`${
				switchButtonsPos ? "switch-buttons" : ""
			} genemod-modal-default-footer`}
		>
			{!hideCancelButton && (
				<ButtonV2
					data-cy={dataCy && `${dataCy}-cancel-button`}
					onClick={() => (visible.current || visible) && onCancel?.()}
					style={{
						marginRight: switchButtonsPos ? "0px" : "16px",
						marginLeft: switchButtonsPos ? "16px" : "0px",
					}}
					{...cancelButtonProps}
				>
					{cancelText}
				</ButtonV2>
			)}
			{!hideOkButton && (
				<ButtonV2
					data-cy={dataCy && `${dataCy}-okButton`}
					onClick={() => (visible.current || visible) && onOk?.()}
					{...okButtonProps}
					type="primary"
				>
					{okText}
				</ButtonV2>
			)}
		</div>
	);
}

// Just popup w/ single button
export function Alert(props: any): JSX.Element {
	return (
		<Modal
			footer={<ModalDefaultFooter hideCancelButton {...props} />}
			{...props}
		>
			{props.children}
		</Modal>
	);
}

// Parent component for modal demos
export function MODAL_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage"
					description="Basic example of the modal"
				>
					<DEMO1 />
				</Demo>
				<Demo
					title="Alert modal"
					description={
						<span>
							The <strong>Alert</strong> modal comes with only an
							OK button.
						</span>
					}
				>
					<DEMO2 />
				</Demo>
				<Demo
					title="Customize contents"
					description="Modal is a wrapper class so anything can go inside"
				>
					<DEMO3 />
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}

// Basic demo
function DEMO1() {
	const [visible, setVis] = useState(false);
	return (
		<div>
			<Button onClick={() => setVis(true)}>Click me</Button>
			<Modal
				title="Basic modal"
				visible={visible}
				onOk={() => setVis(false)}
				onCancel={() => setVis(false)}
				hideCancelButton
			>
				Hello, world!
			</Modal>
		</div>
	);
}

// Alert demos
function DEMO2() {
	const [visible, setVis] = useState(false);
	const [claimed, setClaimed] = useState(false);
	return (
		<div>
			<Button onClick={() => setVis(true)}>Open alert modal</Button>
			<Alert
				visible={visible}
				onCancel={() => setVis(false)}
				title={claimed ? "Too bad so sad" : "You've won!"}
				okText={claimed ? "Goodbye" : "Claim my prize"}
				onOk={claimed ? () => setVis(false) : () => setClaimed(true)}
			>
				{claimed
					? "Just kidding."
					: "Congratulations, you are the 1,000,000th visitor. You've won $10,000!"}
			</Alert>
		</div>
	);
}

// Component content demo
function DEMO3() {
	const [visible, setVis] = useState(false);
	return (
		<div>
			<Button onClick={() => setVis(true)}>Click for a surprise</Button>
			<Alert
				closable={true}
				footer={null}
				visible={visible}
				onCancel={() => setVis(false)}
				title="Slot machine"
			>
				<Slots />
			</Alert>
		</div>
	);
}

function Slots() {
	// Constants
	const COST = 5;
	const ROLL_DURATION = 500;
	const WIN_CREDITS = 30;

	const [credits, setCredits] = useState(100);
	const [rolls, setRolls] = useState([0, 0, 0]);
	const [isRolling, setRolling] = useState(false);
	const [isWinner, setWinner] = useState(false);

	const onPlay = () => {
		setRolling(true);
		setCredits(credits - COST);
		setWinner(false);
		setRolls(Array(3).fill("?"));
		setTimeout(checkIfWinner, ROLL_DURATION);
	};

	const checkIfWinner = () => {
		const results = Array(3)
			.fill(0)
			.map(() => Math.floor(Math.random() * 9));
		setRolls(results);
		setRolling(false);
		if (results[0] === results[1] && results[1] === results[2]) {
			setWinner(true);
			setCredits(credits + WIN_CREDITS);
		}
	};

	return (
		<div className={styles.slots}>
			<div>
				{COST} credits to play! ({credits} remaining)
			</div>
			{isWinner && (
				<div>You won!! Here &apos;s {WIN_CREDITS} credits</div>
			)}
			<div className={styles.rollContainer}>
				{rolls.map((roll, index) => {
					return (
						<div className={styles.roll} key={"roll" + index}>
							{roll}
						</div>
					);
				})}
			</div>
			<Button onClick={onPlay} disabled={isRolling || credits < 5}>
				{credits < 5
					? "No credits remaining"
					: isRolling
					? "Playing..."
					: "Play!"}
			</Button>
		</div>
	);
}
