import React from "react";
import styles from "./ToggleButton.module.scss";
import cn from "classnames";
import { GenemodIcon, GenemodDot, Typography } from "@components";
import { ColorCssVarMap } from "@common/styles/Colors";
import LabelV2, { LabelProps } from "../LabelV2";

type ToggleButtonProps = LabelProps & {
	hasFilters: boolean;
	withIcon?: boolean;
	borderless?: boolean;
	wrapperClassName?: string;
};

export const ToggleButton = ({
	hasFilters,
	checked,
	title,
	withIcon = true,
	borderless = false,
	wrapperClassName,
	size,
	...rest
}: ToggleButtonProps) => {
	return (
		<LabelV2
			className={cn(styles.filtersToggle, wrapperClassName, {
				[styles.isToggled]: checked,
				[styles.isLarge]: size === "large",
				[styles.hasText]: !!title,
				[styles.borderless]: borderless,
			})}
			checkable
			checked={checked}
			size={size}
			{...rest}
		>
			<div
				style={{
					gap: 6,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
				}}
			>
				{withIcon && (
					<GenemodIcon name="filter-o" color="text-secondary-v2" />
				)}
				<GenemodDot
					wrapperProps={{
						className: cn(styles.dot, {
							[styles.show]: hasFilters,
							[styles.showWithoutIcon]: !!title && !withIcon,
							[styles.showWithIconTitle]: !!title && withIcon,
						}),
					}}
					color={ColorCssVarMap["dust-red"]}
				/>
				{title && (
					<Typography
						variant={size === "large" ? "body" : "body2"}
						className={cn({ [styles.isTextToggled]: checked })}
						color="text-secondary-v2"
					>
						{title}
					</Typography>
				)}
			</div>
		</LabelV2>
	);
};
