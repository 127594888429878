import React, { useState, useEffect, HTMLProps } from "react";
import "./Checkbox.scss";
import { GenemodIcon, Typography } from "@components";
import { Demo, DemoSection, DemoWrapper } from "../index";
import classNames from "classnames";
import { Color, ColorCssVarMap } from "@common/styles/Colors";
import { useFormContext } from "react-hook-form";
import styles from "./ValidationCheckbox.module.scss";

type CheckboxProps = {
	value?: boolean | "complicated";
	onChange?: (
		state: boolean | "complicated",
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	id?: string;
	className?: string;
	children?: any;
	color?: Color;
	style?: React.CSSProperties;
	disabled?: boolean;
	dataCy?: string;
};

export default function Checkbox({
	value,
	onChange,
	id,
	className,
	children,
	color = "accent-subtle",
	style,
	disabled,
	dataCy,
}: CheckboxProps): JSX.Element {
	const [currentValue, setValue] = useState(value || false);
	const handleCheck = (event: any) => {
		if (disabled) return;

		let nextState = false;
		if (currentValue === "complicated" || currentValue === false) {
			nextState = true;
		}

		// Handle inner state if not controlled by props
		if (value === undefined) {
			setValue(nextState);
		}
		// Add id to any event targets
		event.target.id = id;
		if (onChange) onChange(nextState, event);
	};
	useEffect(() => {
		if (value !== undefined && value !== currentValue) {
			setValue(value);
		}
	}, [value]);

	const renderCheckbox = () => {
		if (currentValue === true) {
			return (
				<GenemodIcon
					name="checkmark"
					size="small"
					fill={color}
					className="checked"
				/>
			);
		} else if (currentValue === "complicated") {
			return (
				<GenemodIcon
					name="minus"
					size="small"
					stroke={color}
					className="minus"
				/>
			);
		} else {
			return null;
		}
	};

	return (
		<div
			className={classNames("genemod-checkbox-container", className, {
				["genemod-checkbox-container__checked"]: value,
				["genemod-checkbox-container__disabled"]: disabled,
			})}
			style={style}
			onClick={handleCheck}
		>
			<div
				className="checkbox-icon"
				style={value ? { borderColor: ColorCssVarMap[color] } : {}}
			>
				{renderCheckbox()}
			</div>
			<label className="checkbox-label" data-cy={dataCy}>
				{children}
			</label>
		</div>
	);
}

type Props = {
	name: string;
	label?: string;
	wrapperProps?: HTMLProps<HTMLLabelElement>;
} & HTMLProps<HTMLInputElement>;

export const ValidationCheckbox = ({
	name,
	label,
	wrapperProps,
	...props
}: Props): JSX.Element => {
	const { register } = useFormContext();
	return (
		<label {...wrapperProps} className={styles.checkboxWrapper}>
			<input
				{...register(name)}
				{...props}
				type="checkbox"
				className={styles.checkboxInput}
			/>
			<span className={styles.checkbox}>
				<GenemodIcon
					size="small"
					name="checkmark"
					className={styles.icon}
				/>
			</span>
			{label && <Typography variant="label">{label}</Typography>}
		</label>
	);
};

// Demo code
export function CHECKBOX_DEMO(): JSX.Element {
	const checkBoxState = [false, true, "complicated"] as (
		| boolean
		| "complicated"
	)[];

	const [index, setIndex] = useState(0);
	const handleToggle = () => {
		if (index === checkBoxState.length - 1) {
			setIndex(0);
		} else {
			setIndex(index + 1);
		}
	};

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Basic usage" description="Basic Check box element">
					<div>
						<h4>Checked and uncheked</h4>
						<Checkbox>Check me out</Checkbox>
					</div>
				</Demo>
				<Demo
					title="Check box states"
					description="There are three different check box states."
				>
					<Checkbox value={true}>checked</Checkbox>
					<Checkbox value={false}>unchecked</Checkbox>
					<Checkbox value={"complicated"}>complicated</Checkbox>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
