import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import { useConfig } from "@common/config/GenemodConfig";

export type EventType =
	| "NEW_TEAM_INVITES_OTHER_USER"
	| "NEW_SUBSCRIPTION"
	| "CHOOSE_PLAN"
	| "TWO_DAY_RE_ENGAGEMENT";

export type EventData = {
	name: EventType;
	meta_data?: any;
};

export const analyticsApi = createApi({
	reducerPath: "analyticsApi",
	baseQuery: genemodBaseQuery({ baseUrl: "/analytics" }),
	endpoints: (builder) => ({
		analyticsEvent: builder.query<void, EventData>({
			query: (body) => ({
				url: `analytics-events/`,
				method: "POST",
				body,
			}),
		}),
	}),
});
const { useLazyAnalyticsEventQuery } = analyticsApi;

export const useAnalyticsEvent = (
	name: EventType
): ((meta_data?: any) => void) => {
	const analyticsEnabled = useConfig("ANALYTICS_TRACKING");
	const [sendEvent] = useLazyAnalyticsEventQuery();
	return (meta_data: any = {}) => {
		if (analyticsEnabled) sendEvent({ name, meta_data });
	};
};
