import React from "react";
import styles from "./index.module.scss";
import { Typography, GenemodIcon, Spin } from "@components";
import { Skeleton } from "antdv4";
import Table from "../Table";

import cn from "classnames";
import { ColumnProps, TableEventListeners } from "antd/es/table/interface";

/** Width of the loading skeleton title */
const SKELETON_WIDTH_TITLE = "30%";
/** Width of the loading skeleton paragraph */
const SKELETON_WIDTH_PARAGRAPH = "40%";

// Both parent and children types are required to have an id and name field
export interface NavigationObject {
	id: number;
	name: string;
}

export type WithSlots<T> = T & { slots?: number };

type NavigationListProps<Child> = {
	/** Level of current Navigation List */
	navigationLevel: "freezer" | "shelf" | "rack" | "category";
	/** Callback function when the user navigates backwards. If provided, displays the <- icon */
	onNavigateBack?: () => void;
	/** When the user clicks "Open" for a row in the list */
	onNavigateForward?: (item: Child) => void;
	/**
	 * Override the table columns
	 */
	columns?: Array<ColumnProps<Child>>;
	navigationElements: { name: string; onClick: () => void }[];
	onRow?: (record: Child, index: number) => TableEventListeners;
	parentName?: string;
	childrenList: Child[];
	loading: boolean;
};

export default function NavigationList<Child extends NavigationObject>(
	props: NavigationListProps<Child>
): JSX.Element {
	const {
		navigationLevel,
		onNavigateForward,
		columns,
		childrenList,
		loading,
		navigationElements,
		onRow,
	} = props;

	return (
		<div
			className={cn(styles.container, {
				[styles.container__loading]: loading,
			})}
		>
			<Skeleton
				active
				loading={loading}
				paragraph={{
					rows: 10,
					width: Array(10).fill(SKELETON_WIDTH_PARAGRAPH),
				}}
				title={{ width: SKELETON_WIDTH_TITLE }}
			>
				<div className={styles.arrowAndName}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: 12,
						}}
					>
						{navigationElements?.map((el, index) => {
							const { name, onClick } = el;
							return (
								<>
									<Typography
										key={index}
										variant="label"
										onClick={onClick}
										className={styles.navigationItem}
										ellipsis
										color={
											navigationElements.length - 1 ===
											index
												? "text-primary-v2"
												: "text-secondary-v2"
										}
										bold={
											navigationElements.length - 1 ===
											index
										}
									>
										{name}
									</Typography>
									{navigationElements.length - 1 !==
										index && (
										<GenemodIcon name="chevron-right" />
									)}
								</>
							);
						})}
					</div>
				</div>
				{loading ? (
					<Spin size="large" />
				) : childrenList.length ? (
					<Table
						dataSource={childrenList}
						columns={columns}
						onRowClick={(item) => onNavigateForward?.(item)}
						onRow={onRow}
					/>
				) : (
					<Typography
						color="text-secondary"
						style={{ marginTop: "32px" }}
					>
						This {navigationLevel} is empty
					</Typography>
				)}
			</Skeleton>
		</div>
	);
}
