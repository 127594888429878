import React, { forwardRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { GenemodIcon, Select } from "@components";
import { ToolbarWrapper, ToolbarBtn, ToolbarDivider } from "../Toolbar";
import { Table } from "../../formats";
import { Editor, Range, Point } from "slate";
import ColorPicker from "../ColorPicker";
import "./index.scss";
import { useGenemodSlate } from "@common/components/Editor/hooks/UseGenemodSlateHook";

/**
 * Wrapper class for the table component
 */
export const TableWrapper = forwardRef((props, ref) => {
	return (
		<div
			{...props}
			ref={ref}
			className={cn(props.className, "genemod-workspace-table-wrapper")}
			onDropCapture={(e) => {
				const types = e.dataTransfer.types;
				if (types.length && types[1] === "text/uri-list") {
					// Don't allow image drop in tables
					e.stopPropagation();
				}
			}}
		>
			<div style={{ position: "relative", width: "fit-content" }}>
				{props.children}
			</div>
		</div>
	);
});

/**
 * Table component
 */
export const TableComponent = forwardRef((props, ref) => {
	return (
		<div {...props} ref={ref} className="genemod-workspace-table-container">
			<table className="workspace-table">{props.children}</table>
		</div>
	);
});

export const TableTitle = forwardRef((props, ref) => {
	return (
		<caption
			{...props}
			ref={ref}
			style={{ captionSide: "top", padding: "0" }}
		>
			<div className="workspace-table-title">
				<div contentEditable={false} style={{ marginRight: 4 }}>
					<GenemodIcon fill="text-secondary" name="insert-table" />
				</div>
				{props.children}
			</div>
		</caption>
	);
});

export const TableToolbar = forwardRef((props, ref) => {
	const { Option } = Select;
	const editor = useGenemodSlate();
	const marks = Editor.marks(editor) || {};
	const hAlign = Table.getAlignment(editor, "horizontal") || "center";
	const vAlign = Table.getAlignment(editor, "vertical") || "middle";
	const isInTable = (() => {
		const { selection, permissions } = editor;
		if (!selection || !permissions.table) return false;

		const [selectionStart, selectionEnd] = Range.edges(selection);
		const [tableWrapper] = Editor.nodes(editor, {
			at: selection,
			match: (n) => n.type === Table.TableWrapper.type,
		});

		if (tableWrapper && tableWrapper[0].tableId == props.tableId) {
			// Check that selection is only in the table
			const [wrapperStart, wrapperEnd] = Editor.edges(
				editor,
				tableWrapper[1]
			);
			const outsideTable =
				Point.isBefore(selectionStart, wrapperStart) ||
				Point.equals(selectionStart, wrapperStart) ||
				Point.isAfter(selectionEnd, wrapperEnd) ||
				Point.equals(selectionEnd, wrapperEnd);

			const selectionContainsCells = [
				...Editor.nodes(editor, {
					at: selection,
					match: (n) => n.type === Table.TableCell.type,
				}),
			].length;
			return selectionContainsCells && !outsideTable;
		}
		return false;
	})();

	const handleHorizontalAlignment = (option) => {
		Table.setAlignment(editor, "horizontal", option);
	};

	const handleVerticalAlignment = (option) => {
		Table.setAlignment(editor, "vertical", option);
	};

	const handleFillColor = (option) => {
		Table.setFillColor(editor, option);
	};

	const fillColor = Table.getFillColor(editor);
	const getFillColorDisplay = () => {
		return (
			<GenemodIcon
				forceRerender
				name="fill"
				fill={fillColor}
				size="large"
			/>
		);
	};

	const ACTIVE_COLOR = "link-primary";
	const DEFAULT_ICON_COLOR = "text-secondary";
	const H_ALIGN_OPTIONS = [
		{
			name: "align-left",
			value: "left",
		},
		{
			name: "align-center",
			value: "center",
		},
		{
			name: "align-right",
			value: "right",
		},
	];
	const V_ALIGN_OPTIONS = [
		{
			name: "align-top",
			value: "top",
		},
		{
			name: "align-middle",
			value: "middle",
		},
		{
			name: "align-bottom",
			value: "bottom",
		},
	];

	return (
		<ToolbarWrapper
			{...props}
			ref={ref}
			className={cn("toolbar", {
				["toolbar__hidden"]: !isInTable,
			})}
			contentEditable={false}
		>
			<Select
				value={hAlign}
				optionLabelProp="label"
				onChange={handleHorizontalAlignment}
				className="genemod-select__editor-toolbar"
				dropdownClassName="genemod-select__editor-toolbar-menu"
				isSmall
			>
				{H_ALIGN_OPTIONS.map((option) => (
					<Option
						key={option.value}
						value={option.value}
						label={
							<GenemodIcon
								name={option.name}
								fill={DEFAULT_ICON_COLOR}
								size="large"
							/>
						}
					>
						<GenemodIcon
							forceRerender
							name={option.name}
							size="large"
							fill={
								hAlign === option.value
									? ACTIVE_COLOR
									: DEFAULT_ICON_COLOR
							}
						/>
					</Option>
				))}
			</Select>
			<Select
				value={vAlign}
				optionLabelProp="label"
				onChange={handleVerticalAlignment}
				className="genemod-select__editor-toolbar"
				dropdownClassName="genemod-select__editor-toolbar-menu"
				style={{ marginLeft: "4px" }}
				isSmall
			>
				{V_ALIGN_OPTIONS.map((option) => (
					<Option
						key={option.value}
						value={option.value}
						label={
							<GenemodIcon
								name={option.name}
								fill={DEFAULT_ICON_COLOR}
								size="large"
							/>
						}
					>
						<GenemodIcon
							forceRerender
							name={option.name}
							size="large"
							fill={
								vAlign === option.value
									? ACTIVE_COLOR
									: DEFAULT_ICON_COLOR
							}
						/>
					</Option>
				))}
			</Select>
			<ToolbarDivider />
			<ToolbarBtn
				icon="bold"
				description="Bold"
				onClick={() => editor.toggleMark("bold", true)}
				active={marks["bold"]}
			/>
			<ToolbarBtn
				icon="italic"
				description="Italic"
				onClick={() => editor.toggleMark("italic", true)}
				active={marks["italic"]}
			/>
			<ToolbarBtn
				icon="underline"
				description="Underline"
				onClick={() => editor.toggleMark("underline", true)}
				active={marks["underline"]}
			/>
			<ToolbarBtn
				icon="strikethrough"
				description="Strikethrough"
				onClick={() => editor.toggleMark("strikethrough", true)}
				active={marks["strikethrough"]}
			/>
			<ColorPicker
				type="highlight"
				includeNull
				style={{ marginLeft: "4px" }}
				onClick={handleFillColor}
				selectedColor={fillColor}
				display={getFillColorDisplay()}
			/>
		</ToolbarWrapper>
	);
});

export const InsertRow = forwardRef((props, ref) => {
	const style = useHiddenTableElementStyling(
		"rows",
		props.tableId,
		props.style
	);

	return (
		<tr
			{...props}
			ref={ref}
			className={styles.insertRow}
			contentEditable={false}
			style={style}
		>
			{props.children}
		</tr>
	);
});

export const InsertRowHeader = forwardRef((props, ref) => {
	const editor = useGenemodSlate();
	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		Table.addDimension(editor, "rows");
	};

	return (
		<th {...props} ref={ref} contentEditable={false} onClick={handleClick}>
			+{props.children}
		</th>
	);
});

export const InsertRowCell = forwardRef((props, ref) => {
	return (
		<td colSpan="100%" {...props} ref={ref} contentEditable={false}>
			{props.children}
		</td>
	);
});

export const InsertColumn = forwardRef((props, ref) => {
	const style = useHiddenTableElementStyling(
		"columns",
		props.tableId,
		props.style
	);

	const editor = useGenemodSlate();
	const handleClick = (e) => {
		e.preventDefault();
		Table.addDimension(editor, "columns");
	};

	return (
		<th
			{...props}
			ref={ref}
			className={styles.insertCol}
			contentEditable={false}
			onClick={handleClick}
			style={style}
		>
			+{props.children}
		</th>
	);
});

export const InsertColumnPlaceholder = forwardRef((props, ref) => {
	const style = useHiddenTableElementStyling(
		"columns",
		props.tableId,
		props.style
	);
	// Get the number of columns in the table and use as colSpan
	const editor = useGenemodSlate();
	const table = Table.getTable(editor);
	let rowSpan = "0";
	if (table) {
		rowSpan = table.rows;
	}

	return (
		<td
			{...props}
			ref={ref}
			contentEditable={false}
			rowSpan={rowSpan}
			className={styles.insertColPlaceholder}
			style={style}
		>
			{props.children}
		</td>
	);
});

/**
 * @param {"rows" | "columns"} dimension
 * @param {Number} tableId
 * @param {Object} style Component props.style
 * @returns {Object} Styling for the table element. Displays none if the specified table is not focused or cannot add more of the given dimension.
 */
function useHiddenTableElementStyling(dimension, tableId, style = {}) {
	const editor = useGenemodSlate();
	const isInTable = Table.getTable(editor)?.tableId === tableId;
	const isInWrapper = Table.isInTableWrapper(editor);
	const canAdd = Table.canAddDimension(editor, dimension);
	const isVisible = (isInWrapper || isInTable) && canAdd;

	const newStyle = { ...style };
	if (!isVisible || !editor.permissions.table) {
		newStyle.display = "none";
	}
	return newStyle;
}
