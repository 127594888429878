import { FONT_VARIANT } from "@common/components/Typography/Typography";
import { Color } from "@common/styles/Colors";
import { Demo, DemoSection, DemoWrapper, Typography } from "@components";
import { useOptionallyControlledInput } from "@helpers/Hooks";
import { useInputValidation } from "@helpers/InputHelper.js";
import classNames from "classnames";
import React, { useState } from "react";
import { InputValidator } from "../Input/Input";
import inputStyles from "../Input/Input.module.scss";
import { MentionNotificationOptions } from "../Mention/Mention";
import styles from "./SlateTextArea.module.scss";
import { PluginOptions } from "./SlateTextAreaHooks";
import SlateAndEditor, { EditableProps } from "./_SlateAndEditor";

const DEFAULT_MAX_LENGTH = 2000;

type TextAreaProps = {
	/** Textarea id */
	id?: string;
	/** Textarea value */
	value?: string;
	/** The placeholder of the textarea */
	placeholder?: string;
	placeholderVariant?: FONT_VARIANT;
	/** Textarea label */
	label?: string;
	/** Color of the label */
	labelColor?: Color;
	/** Props for the wrapper element of the textarea */
	wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
	/** Props for the Editable component */
	editableProps?: EditableProps;
	/** A forced error state that displays an error message */
	error?: string | boolean | null;
	/** A list of local functions that checks the validity of the input value */
	validators?: InputValidator[];
	/**
	 * Whether to use the maxLength validator. Defaults to true.
	 */
	useMaxLengthValidator?: boolean;
	/** Whether focus on the textarea on page load */
	autoFocus?: boolean | "END" | "FORCED";
	/** Called when the validity on the input changes */
	onValidityChange?: (arg0: boolean) => void;
	/** Called when the value of the input changes */
	onChange?: (value: string) => void;
	/** Whether to display a gutter at the bottom */
	gutterBottom?: boolean;
	/** Whether to disabled the input */
	disabled?: boolean;
	/** Do not show the focus underline */
	noFocusUnderline?: boolean;
	/** Remove border around the text area */
	noBorder?: boolean;
	notificationOptions?: MentionNotificationOptions;
	/**
	 * Options for the Slate plugins
	 */
	pluginOptions?: PluginOptions;
	onFocus?: () => void;
	onBlur?: () => void;
};
export default function SlateTextArea({
	id = "",
	value = "",
	placeholder = "",
	placeholderVariant,
	label = "",
	labelColor = "text-primary",
	error = "",
	autoFocus = false,
	wrapperProps = {},
	editableProps = {},
	validators = [],
	useMaxLengthValidator = true,
	onValidityChange = () => {},
	onChange = () => {},
	gutterBottom = true,
	disabled = false,
	noFocusUnderline = false,
	noBorder,
	notificationOptions,
	pluginOptions = {
		useMentions: true,
		useAnchorMe: false,
	},
	onFocus,
	onBlur,
}: TextAreaProps): JSX.Element {
	const [inputValue, handleChange] = useOptionallyControlledInput({
		value,
		onChange,
		defaultValue: "",
	});

	if (useMaxLengthValidator) {
		validators.push({
			validator: () => value.length <= DEFAULT_MAX_LENGTH,
			error: `Cannot exceed ${DEFAULT_MAX_LENGTH} characters`,
		});
	}
	const [isValid, showError] = useInputValidation(
		inputValue,
		validators,
		onValidityChange
	);

	const validity = isValid && !error;

	return (
		<div
			className={classNames(
				wrapperProps?.className,
				{ [inputStyles.container__error]: !validity },
				{
					[inputStyles.container__noBottomMargin]:
						gutterBottom === false,
				},
				inputStyles.container
			)}
			style={wrapperProps?.style}
		>
			{label && (
				<Typography
					variant="label"
					color={labelColor}
					className={inputStyles.label}
					bold
				>
					{label}
				</Typography>
			)}
			<div className={inputStyles.content}>
				<div
					className={classNames(
						inputStyles.inputContainer,
						styles.undoDisabledPointerEvents,
						{
							[inputStyles.inputContainer__error]: !validity,
							[inputStyles.inputContainer__disabled]: disabled,
							[inputStyles.inputContainer__noFocusUnderline]:
								noFocusUnderline,
							[inputStyles.inputContainer__noBorder]: noBorder,
						}
					)}
					style={{ overflow: "none", height: "100%" }}
				>
					<SlateAndEditor
						id={id}
						value={inputValue}
						placeholder={placeholder}
						placeholderVariant={placeholderVariant}
						autoFocus={autoFocus}
						editableProps={{
							...editableProps,
							className: classNames(
								inputStyles.input,
								styles.editable,
								editableProps.className
							),
						}}
						onChange={handleChange}
						readOnly={disabled}
						slatePluginOptions={pluginOptions}
						notificationOptions={notificationOptions}
						onFocus={onFocus}
						onBlur={onBlur}
					/>
				</div>
				{!validity && (showError || error) && (
					<Typography
						className={inputStyles.inputContainerError}
						variant="caption"
						color="red-contrast"
					>
						{showError || error}
					</Typography>
				)}
			</div>
		</div>
	);
}

export function SLATETEXTAREA_DEMO(): JSX.Element {
	const [isValid, setValid] = useState(true);
	const handleValidityChange = (value: boolean) => {
		setValid(value);
	};

	const [readOnlyText, setReadOnlyText] = useState("");
	const [text, setText] = useState("");
	const [text2, setText2] = useState("");
	const [text3, setText3] = useState("");

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage"
					description="Basic usage of SlateTextArea"
				>
					<SlateTextArea placeholder="The height of this SlateTextArea will auto-resize" />
					<SlateTextArea
						placeholder="Placeholder text"
						label="Editable"
						value={readOnlyText}
						onChange={setReadOnlyText}
						pluginOptions={{
							useMentions: true,
							useAnchorMe: true,
						}}
					/>
					<SlateTextArea
						placeholder="Placeholder text"
						label="Readonly"
						value={readOnlyText}
						disabled
						pluginOptions={{
							useMentions: true,
							useAnchorMe: true,
						}}
					/>
				</Demo>
				<Demo
					title="Input validation"
					description="Detect errors using input validation."
				>
					<SlateTextArea
						label="No text allowed"
						validators={[
							{
								validator: (val) => !val,
								error: "Text is not allowed here",
							},
						]}
						placeholder="Don't enter text here"
						value="Hello"
					/>
					<SlateTextArea
						value={text}
						// onChange={(v) => setText(v + ",")}
						onChange={setText}
						label="UPPERCASE ONLY"
						placeholder="ENTER UPPERCASE LETTERS"
						validators={[
							{
								validator: (text) =>
									text === text.toUpperCase(),
								error: "ONLY UPPERCASE LETTERS",
							},
						]}
					/>
				</Demo>
				<Demo
					title="Event Listeners"
					description="Setup callback functions to listen to validity and input changes"
				>
					<h4>
						Input status:{" "}
						<span style={{ color: isValid ? "green" : "red" }}>
							{isValid ? "valid" : "invalid"}
						</span>
					</h4>
					<SlateTextArea
						value={text2}
						onChange={setText2}
						label="Numbers only"
						placeholder="Enter a number"
						validators={[
							{
								validator: (text) => !isNaN(text),
								error: "Enter numbers only!",
							},
						]}
						onValidityChange={handleValidityChange}
					/>
					<h4>Current text: {text3}</h4>
					<SlateTextArea
						label="onChange listener"
						placeholder="Enter some text"
						onChange={setText3}
					/>
				</Demo>
				<Demo
					title="Forced error state"
					description="Pass a string or boolean to the error prop to display an error"
				>
					<SlateTextArea error={true} value="I have an error" />
					<SlateTextArea error="This textarea has an error" />
					<SlateTextArea error={false} value="No error here" />
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
