import { Avatar } from ".";

export type SimpleShelf = {
	/** ID of the object */
	id: number;
	/** Name of the object */
	name: string;
	/** Desciption of the shelf */
	description: string;
	is_freezer_archived: boolean;
	/** True if the Freezer that this shelf is in is archived */
	is_archived: boolean;
	/** Location row under the freezer */
	row: number;
	/** Freezer id that has the shelf */
	freezer: number;
};

export type Shelf = SimpleShelf & {
	/** Created by Id that has the shelf */
	created_by?: Avatar;
	updated_by?: Avatar;
	/** Date when the shelf was created */
	created_at?: string;
	/** Last date when the shlef is updated */
	updated_at?: string;
	rack_numbers?: number;
	category_numbers?: number;
	status?: number;
	location_data?: { freezer_name?: string; freezer_is_archived?: boolean };
};

/** Type data for sending new shelf data to the backend */
export type ShelfDataForBackend = Pick<
	Shelf,
	"name" | "description" | "row" | "freezer"
>;

type AvailableBoxes = {
	name: string;
	available_boxes: number;
};
type ItemSummary = {
	name: string;
	boxes: number;
	items: number;
};

type AvailableSpace = AvailableBoxes & {
	available_racks: AvailableBoxes[];
};

type StorageSummary = ItemSummary & {
	racks: ItemSummary[];
};

export type ShelfStorageSummary = {
	available_space: AvailableSpace[];
	storage_summary: StorageSummary[];
};
