import moment, { Moment } from "moment";
import { nanoid } from "nanoid";
import React from "react";
import { BaseElement, Editor, Element, Node, Range, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { NewDay } from "../../components/Day";
import { overrideFunction } from "../../plugins/PluginHelpers";
import { GenemodEditor } from "../../types";
import { aU } from "@fullcalendar/core/internal-common";

export type DayEditor = Editor &
	GenemodEditor & {
		/**
		 * Inserts a new date at the user's current location.
		 */
		insertNewDay: () => void;
		/**
		 * Sets the date for a day node.
		 */
		setDate: (id: string, date: Moment | null) => void;
		/**
		 * Query for day nodes
		 */
		matchDayNode: (node: Node) => node is DayElement;
		isAtDayNode: () => boolean;
	};

export type DayElement = BaseElement & {
	id: string;
	type: "day";
	date: string | null;
};
export const DAY_TYPE = "day";
export function matchDayNode(node: Node): node is DayElement {
	return Element.isElement(node) && node.type === DAY_TYPE;
}

export default function withDays<T extends DayEditor>(editor: T): T {
	let focusAfterRender = false;
	function getNewDayNode(): DayElement {
		return {
			type: DAY_TYPE,
			date: null,
			id: nanoid(),
			children: [{ text: "" }],
		};
	}

	overrideFunction(editor)("isInline")(
		(isInline) => (element) => matchDayNode(element) || isInline(element)
	);

	overrideFunction(editor)("isVoid")(
		(isVoid) => (element) => matchDayNode(element) || isVoid(element)
	);

	editor.matchDayNode = matchDayNode;

	const { renderElements } = editor;
	editor.renderElements = (props) => {
		const { element, children, attributes } = props;
		if (editor.matchDayNode(element)) {
			const _element = (
				<NewDay
					{...attributes}
					node={element}
					editor={editor}
					focusAfterRender={focusAfterRender}
				>
					{children}
				</NewDay>
			);
			focusAfterRender = false;
			return _element;
		}
		return renderElements(props);
	};

	editor.insertNewDay = () => {
		ReactEditor.focus(editor);
		const { selection } = editor;
		if (!selection) return;

		Transforms.insertNodes(editor, getNewDayNode(), {
			at: selection,
			select: true,
		});
		focusAfterRender = true;

		Transforms.move(editor);
	};

	// I think date is a moment... We should probably be using something serializable
	editor.setDate = (id, date) => {
		// Clear date from day nodes after this one
		// that have a date equal to or before this one
		let [...days] = Editor.nodes(editor, {
			at: [],
			mode: "highest",
			match: editor.matchDayNode,
		});

		if (!date) {
			Transforms.setNodes(
				editor,
				{ date: null },
				{
					at: [],
					mode: "highest",
					match: (n) => editor.matchDayNode(n) && n.id === id,
				}
			);
		} else {
			const thisDay = days.findIndex((day) => day[0].id === id);
			if (thisDay !== -1) {
				days = days.slice(thisDay + 1);
				const dayIds = days.map((day) => day[0].id);
				Transforms.setNodes(
					editor,
					{
						date: null,
					},
					{
						at: [],
						mode: "highest",
						match: (n) =>
							editor.matchDayNode(n) &&
							dayIds.includes(n.id) &&
							n.date !== null &&
							moment(n.date).isSameOrBefore(date),
					}
				);

				// Update current day node
				Transforms.setNodes(
					editor,
					{
						date: date.toISOString(),
					},
					{
						at: [],
						match: (n) => editor.matchDayNode(n) && n.id === id,
					}
				);
			}
		}
	};

	// Check if the cursor is at a day node (highlighted or not)
	editor.isAtDayNode = () => {
		const { selection } = editor;
		if (selection && Range.isCollapsed(selection)) {
			// search for the day node within the selection
			const [dayNode] = Editor.nodes(editor, {
				mode: "highest",
				at: selection,
				match: editor.matchDayNode,
			});

			if (dayNode) {
				return true;
			}
		}
		return false;
	};

	const { normalizeNode, insertFragment } = editor;
	editor.normalizeNode = (entry) => {
		const [node, path] = entry;
		if (editor.matchDayNode(node)) {
			// Fix bug where day nodes have the same id
			// Bug: DASH-3032: Changing one date field updates another on a pm document
			const otherDateNodes = Editor.nodes(editor, {
				match: (n) => matchDayNode(n),
				at: [],
			});
			for (const [otherNode, otherPath] of otherDateNodes) {
				if (
					matchDayNode(otherNode) &&
					otherNode.id === node.id &&
					otherPath[0] > path[0]
				) {
					// Duplicate node found
					// Generate a new id
					Transforms.setNodes(
						editor,
						{ ...node, id: nanoid() },
						{ at: path }
					);
					return;
				}
			}
		}
		normalizeNode(entry);
	};

	editor.insertFragment = (fragment) => {
		fragment = fragment.map((node) =>
			editor.matchDayNode(node) ? getNewDayNode() : node
		);
		insertFragment(fragment);
	};

	const { removeUserData } = editor;
	editor.removeUserData = () => {
		// Remove dates from all date nodes
		Transforms.setNodes(
			editor,
			{ date: null },
			{
				at: [],
				mode: "highest",
				match: editor.matchDayNode,
			}
		);
		removeUserData();
	};

	return editor;
}
