import React, { useState } from "react";
import SidePanel from "../SidePanel/SidePanel";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { Typography, GenemodIcon, WhatsNewModal } from "@common/components";
import styles from "./HelpCenterPanel.module.scss";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";
import { HelpCenterRow, HelpCenterRowProps } from "./HelpCenterRow";
import {
	HelpCenterSecondRow,
	HelpCenterSecondRowProps,
} from "./HelpCenterSecondRow";
import { Color } from "@common/styles/Colors";
import { useGetLocation } from "@helpers/Hooks/useGetLocationHook";
import { NPSFeedbackModal } from "../NPSFeedback/NPSFeedback";
import {
	useNpsStatusPatchMutation,
	useNpsStatusQuery,
} from "@redux/Dashboard/DashboardApiSlice";
import { NPSSubmissionStatusEnum } from "@common/types";
import {
	HELP_CENTER_URLS,
	PM_TOP_SUGGETIONS,
	FREEZER_TOP_SUGGESTIONS,
	TopSuggestionsLinkItem,
	ACCOUNT_TOP_SUGGESTIONS,
} from "@common/HelpCenterUrls";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import { useWhatsNewNotificaion } from "@helpers/Hooks/UseWhatsNewNotification";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";

const HelpCenterPanel = (): JSX.Element => {
	const currentLocation = useGetLocation();
	const {
		genericallyNamed: { isVisible, closePanel },
	} = useCommonPanelState("helpCenter");
	const [whatsNewVisible, setWhatsNewVisible] = useState(false);

	const { data: user } = useCurrentUserQuery();
	const [npsStatusPatch] = useNpsStatusPatchMutation();
	const { data: npsStatus } = useNpsStatusQuery(user?.id || -1, {
		skip: !user,
	});
	const { openContactUsPanel } = useCommonModalState("contactUsPanel");
	const { openNPSFeedbackModal } = useCommonModalState("NPSFeedbackModal");
	const showIndicator = useWhatsNewNotificaion();

	const handleFeedbackBtnClicked = () => {
		if (!user) {
			return;
		}
		if (npsStatus?.status === NPSSubmissionStatusEnum.DELAYED) {
			npsStatusPatch({
				userId: user.id,
				status: NPSSubmissionStatusEnum.NO_PROMPT,
			});
		}
		openNPSFeedbackModal();
	};

	const rowData = [
		{
			key: 1,
			title: "Inventory",
			icon: "freezer",
			description: "Learn how to manage, search, and import your items",
			color: "pastel-blue",
			link: HELP_CENTER_URLS.INVENTORY,
		},
		{
			key: 2,
			title: "Projects",
			icon: "active-projects",
			description: "Learn how to organize and expedite your experiments",
			color: "pastel-violet",
			link: HELP_CENTER_URLS.PROJECTS,
		},
		{
			key: 3,
			title: "Account & Team",
			icon: "private",
			description:
				"Learn how to manage users, upgrade plans, or edit profile",
			color: "pastel-yellow",
			link: HELP_CENTER_URLS.ACCOUNT,
		},
	] as HelpCenterRowProps[];

	const secondRowData = [
		{
			key: 1,
			title: "Visit help center",
			icon: "open-book",
			type: "link",
			link: HELP_CENTER_URLS.HOME,
			dataCy: "visit-help-center-link",
		},
		{
			key: 2,
			title: "Learn what's new on Genemod",
			icon: "bulb",
			type: "button",
			showIndicator,
			onClick: setWhatsNewVisible,
			dataCy: "learn-what-s-new-on-genemod",
		},
		{
			key: 3,
			title: "Give feedback",
			icon: "comment-o",
			type: "button",
			onClick: handleFeedbackBtnClicked,
			dataCy: "give-feedback",
		},
	] as HelpCenterSecondRowProps[];

	const renderTopSuggestions = (suggestions: TopSuggestionsLinkItem[]) => {
		return suggestions.map(
			(suggestion: TopSuggestionsLinkItem, ind: number) => {
				return (
					<Link
						key={ind}
						to={{
							pathname: suggestion.link,
						}}
						target="_blank"
					>
						<div className={styles.suggestionsRow}>
							<Typography
								className={styles.suggestions}
								variant="body"
								color="link-primary"
							>
								{suggestion.title}
							</Typography>
							<GenemodIcon
								className={styles.iconHover}
								style={{ marginLeft: "8px" }}
								name="link-tab"
								size="large"
							/>
						</div>
					</Link>
				);
			}
		);
	};

	const getLinks = () => {
		const suggestionTitle = (
			<Typography color="text-tertiary" variant="label">
				Top suggestions
			</Typography>
		);

		switch (currentLocation) {
			case "PROJECT":
			case "EXPERIMENT":
			case "ACTIVE_PROJECTS":
			case "ARCHIVED_PROJECTS":
			case "PROTOCOL_LIBRARY":
				return (
					<div className={styles.topSuggestionsWrapper}>
						{suggestionTitle}
						{renderTopSuggestions(PM_TOP_SUGGETIONS)}
					</div>
				);
			case "FREEZERS":
			case "FREEZER":
			case "BOX":
			case "CONSUMABLES":
			case "SPACE":
				return (
					<div className={styles.topSuggestionsWrapper}>
						{suggestionTitle}
						{renderTopSuggestions(FREEZER_TOP_SUGGESTIONS)}
					</div>
				);
			case "ACCOUNT_SETTINGS":
				return (
					<div className={styles.topSuggestionsWrapper}>
						{suggestionTitle}
						{renderTopSuggestions(ACCOUNT_TOP_SUGGESTIONS)}
					</div>
				);
			default:
				return <></>;
		}
	};

	return (
		<SidePanel
			dataCy="help-center-panel"
			isVisible={isVisible}
			onClose={() => closePanel()}
			drawerBodyStyle={{
				padding: 0,
			}}
			hideHeader
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					height: "100%",
				}}
			>
				<div
					style={{
						padding: 32,
						overflow: "auto",
					}}
				>
					<div
						style={{
							display: "flex",
						}}
					>
						<GenemodIcon
							className={styles.headerIcon}
							name="question-mark-o"
							size="large"
						/>
						<Typography
							className={styles.header}
							color="text-secondary"
							variant="largeTitle"
						>
							Help & Support
						</Typography>
					</div>
					{getLinks()}
					<Typography color="text-tertiary" variant="label">
						Topic by category
					</Typography>
					{rowData.map((row) => {
						return (
							<HelpCenterRow
								key={row.key}
								title={row.title}
								icon={row.icon as IconName}
								description={row.description}
								color={row.color as Color}
								link={row.link}
							/>
						);
					})}
					<div style={{ marginTop: 32 }}>
						{secondRowData.map((row) => {
							return (
								<HelpCenterSecondRow
									key={row.key}
									title={row.title}
									type={row.type}
									icon={row.icon as IconName}
									showIndicator={row.showIndicator}
									link={row?.link}
									onClick={row?.onClick}
									dataCy={row.dataCy}
								/>
							);
						})}
					</div>
				</div>
				<div className={styles.footer}>
					<div
						style={{
							padding: "32px 24px",
						}}
					>
						<Typography
							className={styles.footerDesc}
							variant="subheadline"
							color="text-secondary"
						>
							Have more questions?{" "}
							<a
								data-cy="contact-us-link"
								onClick={() => openContactUsPanel()}
							>
								Contact us
							</a>
						</Typography>
					</div>
				</div>
			</div>
			<WhatsNewModal
				data-cy="whats-new-modal"
				visible={whatsNewVisible}
				onClose={() => {
					setWhatsNewVisible(false);
				}}
			/>
			<NPSFeedbackModal />
		</SidePanel>
	);
};

export default HelpCenterPanel;
