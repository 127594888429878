import { ColorHexMap } from "@common/styles/Colors";
import { useLoginContext } from "@containers/Auth/LoginInterfaces/LoginContext";
import {
	useLocalStorage,
	LocalStorageKeys,
} from "@helpers/Hooks/UseLocalStorageHook";
import { RadioButton, Typography } from "../../";
import {
	useLazyPreferencesQuery,
	useUpdatePreferencesMutation,
} from "@redux/user/UserApi";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { THEME_PREFERENCE } from "./ThemeTypes";
import classNames from "classnames";
import styles from "./index.module.scss";
import { useLoginAndSignupDependencies } from "@root/router";

const LIGHTMODE_CLASS = "genemod-lightmode";
const DARKMODE_CLASS = "genemod-darkmode";

type ThemeContextProps = {
	/** current theme mode */
	currentTheme: THEME_PREFERENCE;
	/** function to set theme mode */
	setCurrentTheme: (theme: THEME_PREFERENCE) => void;
};

const ThemeContext = createContext<ThemeContextProps>({
	currentTheme: THEME_PREFERENCE.light,
	setCurrentTheme: () => {},
});

type ThemeProviderProps = {
	children: React.ReactNode;
};

export default function ThemeProvider(props: ThemeProviderProps) {
	const [getTheme] = useLazyPreferencesQuery();
	const { loginStatus, isEmailVerified } = useLoginAndSignupDependencies();
	const [currentTheme, setCurrentTheme] = useLocalStorage(
		LocalStorageKeys.THEME_PREFERENCE,
		THEME_PREFERENCE.light
	);
	const displayedTheme =
		loginStatus === "LOGGEDIN" ? currentTheme : THEME_PREFERENCE.light;
	useEffect(() => {
		if (displayedTheme === THEME_PREFERENCE.light) {
			document.body.classList.add(LIGHTMODE_CLASS);
			document.body.classList.remove(DARKMODE_CLASS);
		} else {
			document.body.classList.remove(LIGHTMODE_CLASS);
			document.body.classList.add(DARKMODE_CLASS);
		}
	}, [displayedTheme]);

	/**
	 * Fetches the user's theme preferences from the backend
	 * and sets the localStorage and state values.
	 */
	const fetchUserThemePreference = () => {
		getTheme()
			.unwrap()
			.then((res) => {
				setCurrentTheme(res.theme);
			});
	};

	useEffect(() => {
		if (loginStatus === "LOGGEDIN" && isEmailVerified === "VERIFIED")
			fetchUserThemePreference();
	}, [loginStatus, isEmailVerified]);

	return (
		<ThemeContext.Provider
			value={{
				currentTheme: displayedTheme,
				setCurrentTheme,
			}}
		>
			{props.children}
		</ThemeContext.Provider>
	);
}

/** helper function to use theme context */
export function useTheme() {
	const context = useContext(ThemeContext);
	const { currentTheme, setCurrentTheme } = context;
	const [updateTheme] = useUpdatePreferencesMutation();

	const updateCurrentTheme = (theme: THEME_PREFERENCE) => {
		setCurrentTheme(theme);
		updateTheme({ theme });
	};

	return [currentTheme, updateCurrentTheme] as const;
}

/**
 * Provides the value of a css variable. Respects the current theme setting.
 * WARNING: It turns out our computed values can be very different in local vs dev/qa/prod. For example
 * locally when we get a color variable we often get hex, but in dev/qa/prod we were getting an "HSLA"
 * color function that broke strip credit card entry.
 */
export function getCssPropertyValue(cssVariable: string) {
	let extractedVariable = cssVariable;
	if (cssVariable.includes("var")) {
		extractedVariable = cssVariable.slice(4, cssVariable.length - 1);
	}
	return getComputedStyle(document.body).getPropertyValue(extractedVariable);
}

/**
 * get hex value of fonts and colors
 */
export function useGenemodStyle() {
	const [currentTheme] = useTheme();

	const getFontStyle = (font: string, bold = false) => {
		const [size, lineHeight] = getCssPropertyValue(
			`--font-size-${font}`
		).split("/");
		const fontFamily = getCssPropertyValue("--font-family-lato");
		let fontWeight = getCssPropertyValue("--font-weight-regular");
		if (bold) {
			fontWeight = getCssPropertyValue("--font-weight-semibold");
		}
		return {
			size: +size.slice(0, size.length - 2),
			lineHeight: lineHeight,
			weight: fontWeight,
			fontFamily: fontFamily,
		};
	};

	const genemodFont = useMemo(() => {
		return {
			caption: getFontStyle("caption"),
			body: getFontStyle("body"),
			subHeadline: getFontStyle("subheadline", true),
		};
	}, []);

	const hexColors = ColorHexMap[currentTheme];

	const genemodColor = useMemo(() => {
		return {
			...hexColors,
			tooltip: currentTheme === THEME_PREFERENCE.dark ? "black" : "white",
		};
	}, [currentTheme]);

	return [genemodColor, genemodFont] as const;
}

export const getCurrentBodyTheme = () =>
	document.body.classList.contains(DARKMODE_CLASS)
		? THEME_PREFERENCE.dark
		: THEME_PREFERENCE.light;

type ThemeTogglesProps = {
	gap?: number;
};
export function ThemeToggles({ gap = 32 }: ThemeTogglesProps): JSX.Element {
	return (
		<div className={styles.themes}>
			<ThemeToggle theme={THEME_PREFERENCE.light} gap={gap} />
			<ThemeToggle theme={THEME_PREFERENCE.dark} />
		</div>
	);
}

type ThemeToggleProps = {
	theme: THEME_PREFERENCE;
	gap?: number;
};

function ThemeToggle({ theme, gap = 0 }: ThemeToggleProps): JSX.Element {
	const [currentTheme, setTheme] = useTheme();
	const selected = theme === currentTheme;

	const THEME_DISPLAY_NAMES = {
		[THEME_PREFERENCE.light]: "Light",
		[THEME_PREFERENCE.dark]: "Dark",
	};

	return (
		<div
			className={classNames(styles.themeToggleWrapper, {
				[styles.themeToggleWrapper__selected]: selected,
				[styles.themeToggleWrapper__lightTheme]:
					theme === THEME_PREFERENCE.light,
			})}
			style={{ marginRight: gap }}
			onClick={() => setTheme(theme)}
			data-cy={THEME_DISPLAY_NAMES[theme]}
		>
			<div className={styles.selectionArea}>
				<RadioButton
					checked={selected}
					noLabel
					className={styles.radio}
				/>
				<Typography variant="label" color="text-secondary">
					{THEME_DISPLAY_NAMES[theme]}
				</Typography>
			</div>
		</div>
	);
}
