export type CustomField = {
	value: any;
};

export type CustomFields = {
	[key: string]: CustomField;
};

export type UpdateCustomFields = {
	current_key: string;
	new_key: string;
	new_value: CustomField;
};
