import React, { useRef, useEffect } from "react";
import { Spin } from "@components";
import styles from "./LoadingScreen.module.scss";

// Props
// isLoading: bool
// message: string
// children: a single container with a ref
//
// Usage: Wrap this component around whichever
// container requires the loading screen. Child
// container should have a ref
/*
Example:
function someComponent(props) {
    const [isLoading, setLoading] = useState(false);
    const ref = useRef();

    return (
        <LoadingScreen isLoading={isLoading}>
            <div ref={ref}>...</div>
        </LoadingScreen>
    )
}
*/
export default function (props) {
	const ref = useRef();
	let parent = props.children.ref;
	let left = "";
	let top = "";
	let height = "";
	let width = "";
	let display = "none";
	if (parent && parent.current && ref.current) {
		let rect = parent.current.getBoundingClientRect();
		left = rect.left;
		top = rect.top;
		height = rect.height;
		width = rect.width;
		display = "flex";
	}

	let shield = !props.isLoading ? null : (
		<div
			ref={ref}
			className={styles.genemodLoadingCover}
			style={{ left, top, height, width, display }}
		>
			<Spin size="large"></Spin>
			<div className="genemod-loading-cover-msg">{props.message}</div>
		</div>
	);

	return (
		<>
			{props.children}
			{shield}
		</>
	);
}
