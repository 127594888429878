import {
	Event,
	EventType,
	getFullNameFromAvatar,
	ModelToName,
} from "@common/types";
import { FREEZER_PATHS } from "@containers/Freezer";
import { useOrganizationRouter } from "@root/AppRouter";
import { PM_ROUTES } from "@root/routes";
import { useAppendPmUrl } from "./useAppendPmUrls";

type EmptyEventHelpersType = {
	getEventLink: (event: Event) => string;
	getEventUserName: (event: Event) => string;
	getEventActivity: (event: Event) => string;
	getEventAction: (event: Event) => EventType;
};

type EventHelpersType = {
	getEventLink: () => string;
	getEventUserName: () => string;
	getEventActivity: () => string;
	action: EventType;
};

export const useEventLink = (_event?: Event) => {
	const { appendBaseUrl, appendBaseUrlAndParams } = useOrganizationRouter();
	const { appendProtocolUrl } = useAppendPmUrl();

	const getEventLink = (_event: Event) => {
		if (_event?.actions === "deleted") return "";
		// FREEZER Location
		if (
			_event.model === "Freezer" ||
			_event.model === "Shelf" ||
			_event.model === "Rack" ||
			_event.model === "Box" ||
			_event.model === "Item" ||
			_event.model === "ItemAttachment"
		) {
			const location =
				_event.freezer_location[_event.freezer_location.length - 1];
			if (!location) {
				return appendBaseUrl(
					`${FREEZER_PATHS.REPOSITORY}?tab=Freezer&id=${_event.object_id}`
				);
			}
			if ((location as any).item_group) {
				return appendBaseUrlAndParams(
					FREEZER_PATHS.ITEMGROUPS.replace(
						":item_group_id",
						"" + (location as any).item_group
					),
					{
						item_id: location.item,
					}
				);
			}
			if (location.item) {
				return appendBaseUrl(
					FREEZER_PATHS.ITEM.replace(":id", "" + location.item)
				);
			}
			if (location.box) {
				return appendBaseUrl(
					FREEZER_PATHS.BOX.replace(":id", "" + location.box)
				);
			}
			const rackLoc = location.rack
				? { selected_rack: location.rack }
				: undefined;
			return appendBaseUrlAndParams(
				FREEZER_PATHS.CONTENTS.replace(":id", "" + location.freezer),
				rackLoc
			);
		}
		// FREEZER custom item template
		if (_event.model === "CustomItemTypeSchema") {
			return appendBaseUrlAndParams(
				FREEZER_PATHS.ITEMTYPE.replace(
					":id",
					_event.object_id.toString()
				)
			);
		}
		// CONSUMABLE Location
		if (
			_event.model === "Space" ||
			_event.model === "Furniture" ||
			_event.model === "FurnitureCategory" ||
			_event.model === "Consumable"
		) {
			const location =
				_event.consumable_location[
					_event.consumable_location.length - 1
				];
			if (!location) {
				return appendBaseUrl(
					`${FREEZER_PATHS.REPOSITORY}?tab=Consumables&id=${_event.object_id}`
				);
			}
			if (location.furniture_category) {
				const consumable = location.consumable
					? { consumable_id: location.consumable }
					: undefined;
				return appendBaseUrlAndParams(
					FREEZER_PATHS.FURNITURE_CATEGORIES.replace(
						":space_id",
						"" + location.space
					)
						.replace(":furniture_id", "" + location.furniture)
						.replace(
							":furniture_category_id",
							"" + location.furniture_category
						),
					consumable
				);
			}
			if (location && location.space) {
				return appendBaseUrl(
					FREEZER_PATHS.SPACE.replace(":id", "" + location.space)
				);
			} else {
				return appendBaseUrl(
					FREEZER_PATHS.SPACE.replace(":id", "no-location")
				);
			}
		}
		// Project Location
		if (
			_event.model === "Project" ||
			_event.model === "Folder" ||
			_event.model === "ExperimentFile"
		) {
			const location =
				_event.project_location[_event.project_location.length - 1];
			if (location) {
				return appendBaseUrl(
					PM_ROUTES.FETCH_PROJECT.replace(
						":id",
						location.project?.toString() || ""
					)
				);
			}
		}
		if (_event.model === "ExperimentFileMaterials") {
			const location =
				_event.project_location[_event.project_location.length - 1];
			return appendBaseUrlAndParams(
				PM_ROUTES.PROJECT.replace(":state", "active"),
				{
					fileId: location.experiment_file?.toString(),
					projectId: location.project?.toString(),
					showMaterials: true,
				}
			);
		}
		// Protocol Location
		if (_event.model === "Protocol" || _event.model === "ProtocolVersion") {
			const location =
				_event.protocol_location[_event.protocol_location.length - 1];
			return appendProtocolUrl({
				version: location.protocol_version,
				protocolId: location.protocol || 0,
			});
		}
		return "";
	};
	return getEventLink;
};

export function useEventHelpers(event: Event): EventHelpersType;
export function useEventHelpers(): EmptyEventHelpersType;
export function useEventHelpers(
	event?: Event
): EventHelpersType | EmptyEventHelpersType {
	const lazyGetEventLink = useEventLink(event);

	const lazyGetEventActivity = (_event: Event) => {
		const action = _event.actions;
		const object = ModelToName[_event.model] || _event.model;
		const activity = `${action} ${object.toLowerCase()}`;
		return activity;
	};

	const lazyGetEventUserName = (_event: Event) => {
		if (!_event.user) return "Deleted User";
		return getFullNameFromAvatar(_event.user);
	};

	if (event) {
		return {
			getEventActivity: () => {
				return lazyGetEventActivity(event);
			},
			getEventLink: () => {
				return lazyGetEventLink(event);
			},
			getEventUserName: () => {
				return lazyGetEventUserName(event);
			},
			action: event.event_type,
		};
	} else {
		return {
			getEventActivity: lazyGetEventActivity,
			getEventLink: lazyGetEventLink,
			getEventUserName: lazyGetEventUserName,
			getEventAction: (event: Event) => event.event_type,
		};
	}
}
