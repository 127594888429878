import { Bookmark, BookmarkBulkDeletePayload } from "@common/types";
import { inventoryApi, withFreezerBase } from ".";

const bookmarkApi = inventoryApi.injectEndpoints({
	endpoints: (builder) => ({
		bookmark: builder.query<Bookmark[], void>({
			query: () => ({
				url: withFreezerBase(`v2/bookmarks/`),
			}),
			providesTags: ["Bookmark"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		removeItemBookmarks: builder.mutation<Bookmark[], BookmarkBulkDeletePayload>({
			query: (bookmarkIds) => ({
				url: withFreezerBase(`v2/bookmarks/bulk-delete/`),
				method: "POST",
				body: bookmarkIds,
			}),
			invalidatesTags: ["Bookmark", "Item", "FreezerItem"],
		}),
		removeItemBookmark: builder.mutation<void, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/bookmarks/item/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["Bookmark", "Item", "FreezerItem"],
		}),
		addItemBookmark: builder.mutation<Bookmark[], number>({
			query: (id) => ({
				url: withFreezerBase(`v2/bookmarks/`),
				method: "POST",
				body: { item: id },
			}),
			invalidatesTags: ["Bookmark", "Item", "FreezerItem"],
		}),
	}),
});

export const {
	useBookmarkQuery,
	useAddItemBookmarkMutation,
	useRemoveItemBookmarksMutation,
	useRemoveItemBookmarkMutation,
} = bookmarkApi;
