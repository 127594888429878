import React, { useState, useEffect } from "react";
import { Input, Typography, Button, GenemodIcon } from "@components";
import {
	Link,
	useHistory,
	useLocation,
} from "@common/helpers/Hooks/UseRouterDom";
import { usePasswordValidation } from "@helpers/Hooks";
import { PasswordRequirement } from "../Registration";
import { NarrowOnboarding } from "@containers/Auth/components";
import { useWindowDimensions } from "@helpers/Hooks";
import "../index.scss";
import styles from "./index.module.scss";
import { useSearchParamAndSet } from "@helpers/URLParams";
import {
	LocalStorageKeys,
	useLocalStorage,
} from "@helpers/Hooks/UseLocalStorageHook";
import { useLoginContext } from "../LoginInterfaces/LoginContext";
import { useConfig } from "@common/config/GenemodConfig";

const useResetCodes = ():
	| { code: string }
	| { uid: string; token: string }
	| null => {
	const location = useLocation();
	const authSystem = useConfig("REACT_APP_AUTH_SYSTEM");
	const [code] = useSearchParamAndSet("code");

	if (authSystem === "COGNITO") {
		if (!code) return null;
		return { code };
	}
	try {
		const path = location.pathname.replace("/reset/", "");
		const [uid, token] = path.split("/");

		return { uid, token };
	} catch (e) {
		console.error("Error processing password reset verification params.");
	}
	return null;
};

/**
 * Page for users to reset their password
 * @function
 * @category Auth
 * @subcategory Reset Password
 */
export default function ForgotPassword(): JSX.Element {
	const history = useHistory();
	const verificationParams = useResetCodes();
	// get email we stored when user was on the ForgotPassword page
	const [localStorageEmail] = useLocalStorage<string | null>(
		LocalStorageKeys.RESET_PASSWORD_EMAIL,
		null
	);
	const { resetForgottenPassword } = useLoginContext();

	const [email, setEmail] = useState(localStorageEmail || "");
	const [code, setCode] = useState("");

	const [password1, setPassword1] = useState("");
	const [password2, setPassword2] = useState("");
	/** Controls the visibility of the password */
	const [viewPass1, setViewPass1] = useState(false);
	const [viewPass2, setViewPass2] = useState(false);
	/** Controls the visibility the eye/eye-off icon */
	const [visible1, setVisible1] = useState(false);
	const [visible2, setVisible2] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [
		passwordValid,
		contains8Chars,
		containsChar,
		containsNum,
		containsSpecial,
	] = usePasswordValidation(password1);

	const [error, setError] = useState("");
	const handleSubmit = () => {
		if (success) return;
		if (!passwordValid || password1 !== password2) return;
		setLoading(true);

		resetForgottenPassword({
			email,
			newPassword: password1,
			verificationParams: verificationParams || { code },
		}).then((result) => {
			setLoading(false);
			if (result.type === "failure") {
				setError(
					"An error occurred. Please try again or request a new password reset link."
				);
				return;
			}
			setSuccess(true);
		});
	};

	const { width } = Object(useWindowDimensions());
	const largeWidth = width >= 1920;
	const errorCase =
		password1 !== "" &&
		(!contains8Chars || !containsChar || !containsNum || !containsSpecial);

	return (
		<NarrowOnboarding modalWidth={largeWidth ? 600 : 528}>
			{error ? (
				<ErrorScreen />
			) : (
				<>
					{success ? (
						<SucceessScreen />
					) : (
						<>
							<Typography
								variant="headline"
								bold
								className={styles.resetTitle}
							>
								Reset your password
							</Typography>
							<div
								style={{
									width: "100%",
									marginBottom: !errorCase ? 16 : 0,
								}}
							>
								<Input
									label="Enter the 6-digit code sent to your email"
									value={code}
									onChange={(e) => setCode(e.target.value)}
									autoFocus
									dataCy="reset-password-code-input"
								/>
								<Input
									label="New password"
									value={password1}
									id="password1"
									onChange={(e) =>
										setPassword1(e.target.value)
									}
									onPressEnter={handleSubmit}
									type={viewPass1 ? "text" : "password"}
									error={errorCase}
									gutterBottom={false}
									onFocus={() => setVisible1(true)}
									autoComplete="password"
									suffix={
										<GenemodIcon
											name={viewPass1 ? "eye" : "eye-off"}
											size="large"
											style={{
												display: !visible1
													? "none"
													: "flex",
											}}
											className={
												styles.passwordVisibility
											}
											onClick={() =>
												setViewPass1(!viewPass1)
											}
											fill="button-primary"
										/>
									}
									dataCy="reset-password-new-password-input"
								/>
							</div>
							<div className={styles.passwordRequirements}>
								<PasswordRequirement
									description="8 characters minimum"
									neutral={!password1}
									isValid={contains8Chars}
								/>
								<PasswordRequirement
									description="At least 1 letter"
									neutral={!password1}
									isValid={containsChar}
								/>
								<PasswordRequirement
									description="At least 1 number"
									neutral={!password1}
									isValid={containsNum}
								/>
								<PasswordRequirement
									description="At least 1 special character"
									neutral={!password1}
									isValid={containsSpecial}
								/>
							</div>

							<Input
								label="Confirm new password"
								value={password2}
								onChange={(e) => setPassword2(e.target.value)}
								id="password2"
								onPressEnter={handleSubmit}
								validators={[
									{
										validator: (v) =>
											!v.trim() ? true : v === password1,
										error: "Your passwords do not match",
									},
								]}
								type={viewPass2 ? "text" : "password"}
								onFocus={() => setVisible2(true)}
								autoComplete="password"
								suffix={
									<GenemodIcon
										name={viewPass2 ? "eye" : "eye-off"}
										size="large"
										style={{
											display: !visible2
												? "none"
												: "flex",
										}}
										className={styles.passwordVisibility}
										onClick={() => setViewPass2(!viewPass2)}
										fill="button-primary"
									/>
								}
								dataCy="reset-password-confirm-password-input"
							/>
							<Button
								stretch
								disabled={
									!passwordValid || password1 !== password2
								}
								onClick={success ? () => {} : handleSubmit}
								loading={loading}
								className={styles.resetButton}
								data-cy="reset-password-button"
							>
								Reset password
							</Button>
						</>
					)}
				</>
			)}
		</NarrowOnboarding>
	);
}

/** Success screen of the Reset Password */
function SucceessScreen(): JSX.Element {
	/** Redirect users to the login screen after 5s */
	const [timer, setTimer] = useState(5);
	let counter: NodeJS.Timeout;
	useEffect(() => {
		counter = setTimeout(() => setTimer(timer - 1), 1000);
		if (timer === 0) {
			window.location.href = "/login";
			clearTimeout(counter);
		}
		return () => {
			clearTimeout(counter);
		};
	});
	return (
		<>
			<Typography variant="title" bold className={styles.resultTitle}>
				Your password is reset
			</Typography>
			<Typography className={styles.successDescription}>
				Click the button below if you’re not redirected to login.
			</Typography>
			<Link to="/login" style={{ width: "100%" }}>
				<Button stretch data-cy="go-to-login-btn">
					Go to login
				</Button>
			</Link>
		</>
	);
}

/** Error screen of the Reset Password */
function ErrorScreen(): JSX.Element {
	return (
		<>
			<Typography variant="title" bold className={styles.resultTitle}>
				Something went wrong
			</Typography>
			<Typography className={styles.resultDescription}>
				The reset link you’re using is invalid. Please use the button
				below to request for a new link.
			</Typography>
			<Link to="/forgot-password" style={{ width: "100%" }}>
				<Button stretch>Request another link</Button>
			</Link>
			<Link to="/login">
				<Typography
					variant="subheadline"
					className={styles.loginButton}
					bold
					color="button-text"
				>
					Return to login
				</Typography>
			</Link>
		</>
	);
}
