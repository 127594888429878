import React from "react";
import { Icon, Button } from "antd";
import "./EditField.scss";

export default class EditField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isEditing: false,
			value: "",
		};
	}

	componentDidMount = () => {
		document.addEventListener("mousedown", this.checkBlur);
	};

	componentDidUnmount = () => {
		document.removeEventListener("mousedown", this.checkBlur);
	};

	checkBlur = (e) => {
		if (this.props.onBlur) {
			this.props.onBlur(e, this.onCancel, "genemod-editfield");
		} else {
			let parent = document.getElementById("genemod-editfield");
			if (parent && parent !== e.target && !parent.contains(e.target)) {
				e.preventDefault();
				e.stopPropagation();
				this.onCancel();
			}
		}
	};

	onClick = (e) => {
		if (!this.state.isEditing) {
			this.setState(
				{
					isEditing: true,
					value: this.props.value,
				},
				() => this.ref.focus()
			);
		}
	};

	onChange = (e) => {
		this.setState({
			value: e.target.value,
		});
	};

	onCancel = (e) => {
		this.setState({
			isEditing: false,
			value: "",
		});
	};

	onConfirm = (e) => {
		e.preventDefault();
		e.stopPropagation();
		let value = this.state.value;

		// Required guard
		if (this.props.required && !value) {
			this.props.onRequired(e);
			return;
		}

		if (this.props.onConfirm) {
			this.props.onConfirm(value, e);
		}
		this.onCancel();
	};

	setRef = (ref) => {
		this.ref = ref;
		if (ref) {
			ref.focus();
		}
	};

	render() {
		return (
			<div
				className={"genemod-editfield  " + this.props.className}
				onClick={this.onClick}
			>
				{this.state.isEditing || this.props.defaultOpen ? (
					<div id="genemod-editfield" className="edit-container">
						<input
							ref={this.setRef}
							value={this.state.value}
							onChange={this.onChange}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									this.onConfirm(e);
								}
							}}
							placeholder={this.props.placeholder}
						/>
						<div className="buttons">
							{this.props.required && !this.props.value && (
								<span className="required">Required</span>
							)}
							<Button
								className="editfield-btn"
								size="small"
								onClick={this.onConfirm}
							>
								<Icon
									style={{ color: "green" }}
									type="check"
								></Icon>
							</Button>
							{this.props.value && this.props.required && (
								<Button
									className="editfield-btn"
									size="small"
									onClick={this.onCancel}
								>
									<Icon
										style={{ color: "red" }}
										type="close"
									></Icon>
								</Button>
							)}
						</div>
					</div>
				) : (
					<div className="display-text">{this.props.value}</div>
				)}
			</div>
		);
	}
}
