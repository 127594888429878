import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { COGNITO_POOL_DATA } from "./CognitoConfig";

export const getUserPool = () => new CognitoUserPool(COGNITO_POOL_DATA);

export const getUser = (email: string) =>
	new CognitoUser({
		Username: getUserName(email),
		Pool: getUserPool(),
	});

export type LocalStorageUserNotFoundError = Error & {
	name: "LocalStorageUserNotFound";
};
/**
 * Gets user from local storage if the keys exist.
 * NOTE: the user will not have an active session.
 */
export const getLocalStorageUser = (
	failure?: (error: LocalStorageUserNotFoundError) => void
) => {
	const user = getUserPool().getCurrentUser();
	if (!user && failure) {
		failure({
			name: "LocalStorageUserNotFound",
			message: "Local storage user not found",
		});
	}
	return user;
};

/**
 * Converts a user's email to their username. For now we only support emails as usernames.
 */
export const getUserName = (email: string) => {
	return email;
};

export type UserIdPayload = {
	email_verified: boolean;
	email: string;
	given_name: string;
	family_name: string;
};

/**
 * Extracts user info from the cognito idToken
 */
export const getUserIdPayload = (user: CognitoUser) =>
	(user?.getSignInUserSession()?.getIdToken()?.payload as UserIdPayload) ||
	null;

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Callback helpers
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Result types
type CognitoResultFailure<E> = {
	type: "failure";
	error: E;
};
type CognitoResultSuccess<T> = {
	type: "success";
	result: T;
};
export type CognitoResult<T, E> =
	| CognitoResultSuccess<T>
	| CognitoResultFailure<E>;

export type EmptyResultError = {
	name: "EmptyResultError";
	message: string;
};

// Option types
type TransformCallbackOptions<U> = {
	emptyResultIsValid?: boolean; // default False
	alternativeSuccess?: (result: U) => void;
};

type ResultCallbacks<T, E> = {
	success: (result: T) => void;
	failure: (error: E) => void;
};

type TransformCallbacks<T, E> = {
	autoResolve: <U = T>(
		options?: TransformCallbackOptions<U>
	) => (error: Error | undefined | null, result: U | undefined) => void;
};

type CognitoPromiseCallback<T, E> = (
	args: ResultCallbacks<T, E> & TransformCallbacks<T, E>
) => void;

/**
 * The AWS library uses callbacks, let's convert them to promises so we can write cleaner code.
 * CognitoPromise helps us do that.
 *
 * Note: Since we cannot type errors from Promises in typescript I'm resolving the promise in
 * all cases and using a specific error type when there is an error.
 * See: https://stackoverflow.com/questions/50071115/typescript-promise-rejection-type
 */
export const cognitoPromise = <E = Error, T = void>(
	callback: CognitoPromiseCallback<T, E | EmptyResultError>
) =>
	new Promise<CognitoResult<T, E | EmptyResultError>>((resolve) => {
		const resultCallbacks: ResultCallbacks<T, E | EmptyResultError> = {
			success: (result) => resolve({ type: "success", result }),
			failure: (error) => resolve({ type: "failure", error }),
		};

		const transformCallbacks: TransformCallbacks<T, E | EmptyResultError> =
			{
				autoResolve:
					(options = {}) =>
					(error, result) => {
						const intOptions = {
							emptyResultIsValid: false,
							emptyResultMessage: "No result found",
							...options,
						};

						if (
							error ||
							(!intOptions.emptyResultIsValid && !result)
						) {
							resultCallbacks.failure(
								(error || {
									name: "EmptyResultError",
									message: "No result",
								}) as E | EmptyResultError
							);
							return;
						}
						if (intOptions.alternativeSuccess) {
							intOptions.alternativeSuccess(result as any);
						}
						resultCallbacks.success(result as unknown as T);
					},
			};

		callback({
			...resultCallbacks,
			...transformCallbacks,
		});
	});
