import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { Typography, GenemodIcon } from "@components";
import classNames from "classnames";
import {
	LocalStorageKey,
	useLocalStorage,
} from "@helpers/Hooks/UseLocalStorageHook";
import { Color, ColorCssVarMap } from "@common/styles/Colors";
import LayerSystemContainer from "../LayerSystemContainer/LayerSystemContainer";
import { UserSettingsKey } from "@common/types/UserPreference";
import { usePreferences } from "@helpers/Hooks/UsePreferencesHook";

type LockedBannerProps = {
	className?: string;
	message: string;
	localStorageKey: UserSettingsKey;
	colors?: {
		icon: Color;
		background?: string;
		border?: string;
	};
	/**
	 * If false the banner will be hidden despite local storage value
	 */
	extraVisibility?: boolean;
};

export default function LockedBanner({
	className,
	message,
	localStorageKey,
	colors,
	extraVisibility,
}: LockedBannerProps): JSX.Element {
	const [_lsVisible, setLsVisible] = usePreferences(localStorageKey);

	useEffect(() => {
		setLsVisible(extraVisibility)
	}, [])

	if (!_lsVisible) {
		return <></>;
	}

	const {
		icon: iconColor = "yellow",
		background: backgroundColor,
		border: borderColor = "var(--border-subtle)",
	} = { ...colors };

	return (
		<LayerSystemContainer
			className={classNames(styles.bannerContainer, className)}
		>
			<div
				className={styles.banner}
				style={{
					backgroundColor,
					border: `1px solid ${borderColor}`,
				}}
			>
				<div className={styles.iconAndText}>
					<GenemodIcon
						name="error"
						fill={iconColor}
						stroke={iconColor}
						size="large"
						decorative
					/>
					<Typography>{message}</Typography>
				</div>
				<GenemodIcon
					name="cancel"
					onClick={() => setLsVisible(false)}
				/>
			</div>
		</LayerSystemContainer>
	);
}
