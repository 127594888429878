import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ReviseHighlightedTextHangingToolbar.module.scss";
import {
	AIButton,
	GenemodIcon,
	LayerSystemContainer,
	Modal,
	TypingAnimation,
	Typography,
} from "@components";
import { Button } from "@common/components";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { RevisionType, RevisionTypeValue } from "@common/types/ChatGPT";
import { useGenemodSlate } from "../../hooks/UseGenemodSlateHook";
import { Editor, Transforms } from "slate";
import { useHangingBySelection } from "../../formats/HangingToolbar/HangingToolbar";
import { useRef } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	useCreateHighlightedTextRevisionRequestQuery,
	usePatchHighlightedTextRevisionMutation,
} from "@redux/ChatGPT/ChatGPTApiSlice";

const ReviseHighlightedTextHangingToolbar: React.FunctionComponent = () => {
	const {
		isReviseHighlightedTextHangingToolbarVisible,
		reviseHighlightedTextHangingToolbarData: { selectedRevisionTypeValue },
		closeReviseHighlightedTextHangingToolbar,
	} = useCommonModalState("reviseHighlightedTextHangingToolbar");

	const [patchHighlightedTextRevision] =
		usePatchHighlightedTextRevisionMutation();

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const ref = useRef<HTMLDivElement | null>(null);
	const editor = useGenemodSlate();
	const _currentText = editor.selection
		? Editor.string(editor, editor.selection)
		: "";
	const [currentText, setCurrentText] = useState("");
	const {
		data: revisionData,
		isFetching,
		refetch,
	} = useCreateHighlightedTextRevisionRequestQuery(
		isReviseHighlightedTextHangingToolbarVisible
			? {
					revision_type: selectedRevisionTypeValue,
					highlighted_text: currentText,
					experiment_document_uuid: editor.documentId,
			  }
			: skipToken
	);

	useEffect(() => {
		setCurrentText(_currentText);
	}, [_currentText]);

	useHangingBySelection(ref, "bottom");

	if (!isReviseHighlightedTextHangingToolbarVisible) {
		return <></>;
	}

	const ReviseButtonWithSelection: typeof ReviseButton = (props) => (
		<ReviseButton
			selectedRevisionType={selectedRevisionTypeValue}
			{...props}
		/>
	);

	const replace = () => {
		const completion = revisionData?.prompt_and_completion?.completion;
		if (!completion || !revisionData) return;
		Transforms.insertText(editor, completion);
		patchHighlightedTextRevision({
			id: revisionData.id,
			was_used: true,
		});
		closeReviseHighlightedTextHangingToolbar();
	};

	const getWordsWrappedInCommas = (inputText: string) => {
		// Regular expression to match words inside double quotes
		const regex = /"([^"]*)"/g;

		// Array to store matched words
		const matchedWords: string[] = [];

		// Match and push words to the array
		let match;
		while ((match = regex.exec(inputText)) !== null) {
			matchedWords.push(match[1]);
		}

		return matchedWords;
	};

	return (
		<>
			<LayerSystemContainer
				wrapperRef={ref}
				className={styles.reviseToolbar}
				overrideLayer={1}
				onMouseDown={(e) => {
					// Prevents the editor from losing focus
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<div className={styles.header}>
					<div className={styles.headerLeft}>
						<AIButton noCursor />
						<Typography variant="title" bold>
							Revise highlighted text
						</Typography>
					</div>
					<GenemodIcon
						name="exit"
						hoverEffect
						onClick={() =>
							closeReviseHighlightedTextHangingToolbar()
						}
					/>
				</div>
				<LayerSystemContainer
					className={styles.buttons}
					overrideLayer={3}
				>
					<ReviseButtonWithSelection
						revisionType={RevisionType.REWRITE}
					/>
					<ReviseButtonWithSelection
						revisionType={RevisionType.CASUAL}
					/>
					<ReviseButtonWithSelection
						revisionType={RevisionType.FORMAL}
					/>
					<ReviseButtonWithSelection
						revisionType={RevisionType.SHORTEN}
					/>
					<ReviseButtonWithSelection
						revisionType={RevisionType.EXPAND}
					/>
				</LayerSystemContainer>
				<LayerSystemContainer
					className={cn(styles.text, {
						[styles.isFetching]: isFetching,
					})}
				>
					{isFetching ? (
						<TypingAnimation />
					) : (
						<Typography variant="body">
							{revisionData?.prompt_and_completion?.completion ||
								""}
						</Typography>
					)}
				</LayerSystemContainer>
				<div className={styles.footer}>
					<div
						className={styles.regenerateButton}
						onClick={() => {
							const withoutTrailingPeriod = (
								revisionData?.prompt_and_completion
									?.completion || ""
							).replace(/\.$/, "");
							setCurrentText(withoutTrailingPeriod);
						}}
					>
						<GenemodIcon
							name="undo"
							style={{ transform: "scaleX(-1)" }}
							size="large"
						/>
						<Typography variant="body">Regenerate</Typography>
					</div>
					<Button onClick={replace} size="small">
						Replace
					</Button>
				</div>
			</LayerSystemContainer>
			<Modal
				visible={showConfirmModal}
				onCancel={() => setShowConfirmModal(false)}
				onOk={() => {
					setShowConfirmModal(false);
					closeReviseHighlightedTextHangingToolbar();
				}}
				title="Discard response?"
				okText="Discard"
				hideCancelButton
			>
				<Typography>Do you want to discard the AI response?</Typography>
			</Modal>
		</>
	);
};

export default ReviseHighlightedTextHangingToolbar;

const REVISE_BUTTON_DATA = {
	[RevisionType.REWRITE]: {
		icon: "rewrite",
		label: "Rewrite",
	},
	[RevisionType.CASUAL]: {
		icon: "casual-o",
		label: "Casual",
	},
	[RevisionType.FORMAL]: {
		icon: "formal",
		label: "Formal",
	},
	[RevisionType.SHORTEN]: {
		icon: "shorten",
		label: "Shorten",
	},
	[RevisionType.EXPAND]: {
		icon: "expand",
		label: "Expand",
	},
};

type ReviseButtonProps = React.ComponentProps<typeof Button> & {
	revisionType: RevisionTypeValue;
	selectedRevisionType?: RevisionTypeValue;
};

export const ReviseButton = ({
	revisionType,
	selectedRevisionType,
	onClick,
	...props
}: ReviseButtonProps) => {
	const { openReviseHighlightedTextHangingToolbar } = useCommonModalState(
		"reviseHighlightedTextHangingToolbar"
	);

	const openToolbar =
		(
			selectedRevisionTypeValue: RevisionTypeValue
		): React.MouseEventHandler<HTMLButtonElement> =>
		(e) => {
			onClick?.(e);
			openReviseHighlightedTextHangingToolbar({
				selectedRevisionTypeValue,
			});
		};

	return (
		<Button
			className={cn(styles.reviseButton, {
				[styles.selected]: selectedRevisionType === revisionType,
			})}
			type="layer"
			size="small"
			iconAutoColor={false}
			onClick={openToolbar(revisionType)}
			icon={REVISE_BUTTON_DATA[revisionType].icon}
			// eslint-disable-next-line react/no-children-prop
			children={REVISE_BUTTON_DATA[revisionType].label}
			{...props}
		/>
	);
};
