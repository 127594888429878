import {
	Button,
	Checkbox,
	DropDown,
	GenemodFilter,
	Typography,
} from "@common/components";
import { SearchFilterOption } from "@common/types";
import React from "react";
import styles from "./FreezerTab.module.scss";
import { Menu } from "antdv5";
import classNames from "classnames";
import { useParams } from "@helpers/URLParams";

type TypeColumnTitleProps = {
	title?: string;
	fieldName?: string;
	types: SearchFilterOption[];
	typeCheckboxes: number[];
};

export const TypeColumnTitle = ({
	title,
	types,
	typeCheckboxes,
	fieldName,
}: TypeColumnTitleProps) => {
	const { resetParamsOnURL, updateMultipleParamOnURL } = useParams();
	const filterApplied = typeCheckboxes.length !== 0;
	return (
		<div
			className={styles.columnHeader}
			onClick={(e) => e.stopPropagation()}
		>
			<Typography bold>{title || "Type"}</Typography>
			<DropDown
				overlayClassName={styles.dropdown}
				overlay={
					<Menu
						className={classNames(
							styles.menu,
							styles.typeColumnMenu
						)}
					>
						<div className={styles.items}>
							{types.map((type) => {
								return (
									<div
										key={type.value}
										className={styles.item}
									>
										<Checkbox
											className={styles.filterCheckbox}
											value={typeCheckboxes.includes(
												type.value
											)}
											onChange={(val) => {
												updateMultipleParamOnURL(
													fieldName || "type",
													type.value,
													val as boolean
												);
											}}
										>
											<Typography
												variant="label"
												ellipsis
											>
												{type.name}
											</Typography>
										</Checkbox>
									</div>
								);
							})}
						</div>
						<div className={styles.reset}>
							<Button
								type="link"
								onClick={() =>
									resetParamsOnURL(
										fieldName ? [fieldName] : ["type"]
									)
								}
							>
								Reset
							</Button>
						</div>
					</Menu>
				}
				getPopupContainer={() => document.body}
			>
				<div style={{ marginLeft: "auto" }}>
					<GenemodFilter
						applied={filterApplied}
						wrapperClassName={
							!filterApplied ? "isOnRepository" : ""
						}
					/>
				</div>
			</DropDown>
		</div>
	);
};
