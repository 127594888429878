import React from "react";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { Button, Typography } from "@components";
import { NarrowOnboarding } from "@containers/Auth/components";
import { useWindowDimensions } from "@helpers/Hooks";

export function InvalidInvitationPage(): JSX.Element {
	const history = useHistory();
	const { width } = Object(useWindowDimensions());
	const largeWidth = width >= 1920;

	const toLogin = () => {
		const path = "/login";
		history.push(path);
	};

	return (
		<NarrowOnboarding modalWidth={largeWidth ? 600 : 528}>
			<Typography
				variant="title"
				bold
				style={{
					marginTop: 48,
					marginBottom: 24,
				}}
			>
				This invite URL is invalid
			</Typography>
			<Typography
				style={{
					marginBottom: 16,
				}}
			>
				Your invitation may have expired.
			</Typography>
			<Typography
				style={{
					marginBottom: 32,
				}}
			>
				Please contact your admins for a new invitation
			</Typography>
			<Button
				stretch={true}
				style={{
					marginBottom: 72,
				}}
				onClick={toLogin}
			>
				Back to login
			</Button>
		</NarrowOnboarding>
	);
}
