import React from "react";
import styles from "./GoogleDrivePreview.module.scss";
import AttachmentPreviewContainer, {
	AttachmentPreviewContainerProps,
} from "../AttachmentPreview/AttachmentPreviewContainer";
import { GoogleDocCreateDto, GoogleDocDto } from "./GoogleDrivePickerData";

type GoogleDrivePreviewProps = {
	doc: GoogleDocDto | GoogleDocCreateDto | undefined | null;
	className?: string;
};

export const GoogleDrivePreview = ({
	doc,
	className,
}: GoogleDrivePreviewProps): JSX.Element => {
	// For drive files of type "document" we can use the normal url which allows the user to edit the doc within the iframe.
	// Other types of files (pdf, images) seem to break when using anything but the embedUrl.
	const src =
		doc?.raw_data?.type === "document"
			? doc?.raw_data?.url
			: doc?.raw_data?.embedUrl;

	if (!src) {
		return <></>;
	}

	return <iframe className={className} src={src} />;
};

type GoogleDrivePreviewWithHeaderProps = Omit<
	AttachmentPreviewContainerProps<GoogleDocDto | GoogleDocCreateDto>,
	"children"
>;

/**
 * Preview a google doc in the same container as an upload preview
 */
export const GoogleDrivePreviewWithHeader = (
	props: GoogleDrivePreviewWithHeaderProps
): JSX.Element => (
	<AttachmentPreviewContainer {...props}>
		<GoogleDrivePreview
			className={styles.inAttachmentPreviewContainer}
			doc={props.attachment}
		/>
	</AttachmentPreviewContainer>
);
