import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FurnitureBoundingBox } from "@containers/Freezer/SpaceView/SpaceViewData";

export const spaceViewSlice = createSlice({
	name: "spaceView",
	initialState: {
		editedFurnitureBoundingBox: null as FurnitureBoundingBox | null,
	},
	reducers: {
		setEditedFurnitureBoundingBox: (
			state,
			action: PayloadAction<FurnitureBoundingBox | null>
		) => {
			state.editedFurnitureBoundingBox = action.payload;
		},
	},
});

export const { setEditedFurnitureBoundingBox } = spaceViewSlice.actions;
