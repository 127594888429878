import {
	WorkspaceUser,
	WORKSPACE_USER_ROLE_HIEARCHY,
	OrganizationUser,
} from "@common/types";
import {
	useGetOrganizationsQuery,
	useGetOrgUsersQuery,
	useGetWorkspaceUsersQuery,
} from "@redux/team/TeamApi";
import { useCurrentUserQuery } from "@redux/user/UserApi";

/**
 * A hook used for checking different cases of a current the user on the current workspace:
 * - Wether the user is the last user in the current workspace (isLastWorkSpaceUser)
 * - Wether the user is an admin in the current workspace (isCurrentWorkspaceUserAdmin)
 *
 * NOTE: Most likely more useful for Enterprise plan
 */
export default function useCurrentWorkspaceUserHooks() {
	const { data: currentUserData } = useCurrentUserQuery();
	const { data: paginatedWorkspaceUsersData, isLoading } =
		useGetWorkspaceUsersQuery({
			page: 1,
			page_size: 999,
		});
	const { data: customUser } = useCurrentUserQuery();
	const { data: orgs } = useGetOrganizationsQuery();
	const { data: orgUsers } = useGetOrgUsersQuery(orgs?.[0].id || -1, {
		skip: !orgs,
	});
	const orgUser = orgUsers?.find((ou) => ou.user?.id === customUser?.id);
	const isOrgAdmin = orgUser?.is_admin;

	const currentWorkspaceUser = paginatedWorkspaceUsersData?.results.find(
		(wu) => wu.org_user.user.id === currentUserData?.id
	);

	const isLastWorkSpaceUser =
		!!currentWorkspaceUser && paginatedWorkspaceUsersData?.count === 1;

	const isCurrentWorkspaceUserAdmin =
		!!currentWorkspaceUser &&
		currentWorkspaceUser.role === WORKSPACE_USER_ROLE_HIEARCHY.Admin;

	const currentWorkspaceUsers = paginatedWorkspaceUsersData?.results;

	const currentWorkspaceUsersAsTeamMember = mapToTeamMember(
		paginatedWorkspaceUsersData?.results || []
	);

	return {
		isLastWorkSpaceUser,
		isOrgAdmin,
		isCurrentWorkspaceUserAdmin,
		isLoading,
		currentWorkspaceUser,
		currentWorkspaceUsers,
		currentWorkspaceUsersAsTeamMember,
	};
}

const mapToTeamMember = (wsUsers: WorkspaceUser[]): OrganizationUser[] => {
	return wsUsers.map((wsU) => {
		return wsU.org_user as OrganizationUser;
	});
};
