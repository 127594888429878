import { STATUS_HIERARCHY } from "@common/types";
import {
	useGetOrgUsersQuery,
	useLazyGetOrgUsersQuery,
} from "@redux/team/TeamApi";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import useCurrentOrganization from "./useCurrentOrganizationHook";

/**
 * A hook for getting the team members data of current organization
 * @returns
 * teamMembers - Active teamMembers data of current organization {TeamMemberType[]}
 * currentUserAsTeamMember - The current user data in {TeamMember} class form
 * teamMembersTypeAsTeamMember - Same as teamMembers, but in {TeamMember[]} class form
 * activeTeamMembers - All the active team members in {TeamMember[]} class form
 * isLoading - Whether the teamMembers data is currently loading
 * isSuccess - Whether the teamMembers is successfully loaded
 * isError - Whether failed to fetch teamMembers data
 */
export default function useCurrentTeamMembers() {
	const { organization } = useCurrentOrganization();
	const { data: user } = useCurrentUserQuery();
	const { data: teamMembers, ...rest } = useGetOrgUsersQuery(
		organization?.id as number,
		{
			skip: !organization?.id,
		}
	);
	const [getTeamMembers] = useLazyGetOrgUsersQuery();

	const fetchTeamMembers = () => getTeamMembers(organization?.id as number);

	const currentUserAsTeamMember = teamMembers?.find(
		(member) => member.user.id === user?.id
	);
	const activeTeamMembers = (teamMembers || []).filter(
		(member) => member.status === STATUS_HIERARCHY.Active
	);

	return {
		teamMembers,
		fetchTeamMembers,
		currentUserAsTeamMember,
		activeTeamMembers,
		...rest,
	} as const;
}
