import { useEffect, useState } from "react";
import {
	SerializedSharedObject,
	OrganizationUser,
	SharingOptions,
	Workspace,
	SharedInternalObj,
	WorkspaceId,
	Organization,
} from "@common/types";
import { actionOnTypeLiteral } from "@helpers/TypeHelpers";
import { useCurrentWorkspaceUuid } from "@helpers/Hooks/UseCurrentWorkspaceUuid";
import { useGetSpecificWorkspaceQuery } from "@redux/team/TeamApi";
import useCurrentOrganization from "./useCurrentOrganizationHook";
import useCurrentTeamMembers from "./useCurrentTeamMembersHooks";

/**
 * State mixin for creating/editing shared objects
 */
export const useSharingState = <T>(
	sharedObj: SharedInternalObj<T> | undefined,
	visible: boolean
) => {
	const [selectedSharingOption, setSelectedSharingOption] = useState<
		SharingOptions | undefined
	>();
	const currentWorkspaceId = useCurrentWorkspaceUuid();
	const { data, isSuccess } = useGetSpecificWorkspaceQuery(
		currentWorkspaceId,
		{
			skip: !currentWorkspaceId,
		}
	);
	const currentWorkspace = data as Workspace;
	const [selectedWorkspaces, setSelectedWorkspaces] = useState<WorkspaceId[]>(
		[currentWorkspace?.id]
	);

	const _getSharedObjectFields = useGetSharedObjectFields();
	const sharedObjectFields = _getSharedObjectFields(
		selectedSharingOption || sharedObj?.sharing || "personal",
		selectedWorkspaces
	);

	useEffect(() => {
		setSelectedSharingOption(sharedObj?.sharing);
		const currentWorkspaces =
			sharedObj?.access?.workspaces || selectedWorkspaces || [];
		if (currentWorkspaces.length === 0) {
			setSelectedWorkspaces([currentWorkspace?.id]);
		} else {
			setSelectedWorkspaces(currentWorkspaces);
		}

		// If add modal closed, reset the selected workspaces to just current workspace
		if (!visible) {
			setSelectedWorkspaces([currentWorkspace?.id]);
		}
	}, [visible, sharedObj, isSuccess]);

	return {
		selectedWorkspaces,
		setSelectedWorkspaces,
		selectedSharingOption,
		setSelectedSharingOption,
		sharedObjectFields,
	} as const;
};

/**
 * Use this to build sharing type object to send to backend
 */
const useGetSharedObjectFields = () => {
	const { organization } = useCurrentOrganization();
	const currentOrganization = organization as Organization;
	const { currentUserAsTeamMember } = useCurrentTeamMembers();
	const teamMember = (currentUserAsTeamMember ||
		{}) as unknown as OrganizationUser;

	return (
		selectedSharingLevelOption: SharingOptions,
		selectedWorkspaces?: WorkspaceId[]
	): SerializedSharedObject =>
		actionOnTypeLiteral(
			selectedSharingLevelOption || "personal"
		)<SerializedSharedObject>({
			personal: () => {
				if (!teamMember) throw "Must have teamMember";
				return {
					access: {
						organization_users: [teamMember.id],
						workspaces: [],
						organizations: [],
					},
				};
			},
			workspace: () => {
				if (!selectedWorkspaces) throw "Must have workspaces";
				return {
					access: {
						workspaces: selectedWorkspaces,
						organization_users: [],
						organizations: [],
					},
				};
			},
			org: () => {
				if (!currentOrganization) throw "Must have workspaces";
				return {
					access: {
						organizations: [currentOrganization.id],
						organization_users: [],
						workspaces: [],
					},
				};
			},
		});
};
