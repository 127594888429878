import { Merge } from "@helpers/TypeHelpers";
import {
	Consumable,
	FurnitureCategory,
	FurnitureType,
	SearchFilterOption,
	Space,
} from ".";

type FilterSectionBaseConfiguration = {
	/**
	 * The name of this filter in the URL parameters/backend API.
	 * */
	param: string;

	/** The label for this filter. */
	label: string;

	/** Supported conversions from param string to a different type. */
	conversion?: "number" | "numberlist" | "moment";

	/** If true, renders avatars by treating FilterOption.name as an Avatar object. */
	useAvatars?: boolean;

	/** The label used in search page breadcrumbs */
	breadCrumbLabel?: string;
};

// Create type for searchable filter sections. Only checkbox types are searchable for now.
type CheckboxFilterSectionConfiguration = FilterSectionBaseConfiguration & {
	type: "checkbox";
	searchable?: boolean;
	unit: string;
};

type RadioFilterSectionConfiguration = FilterSectionBaseConfiguration & {
	type: "radio";
	unit: string;
};

type DateRangeFilterSectionConfiguration = FilterSectionBaseConfiguration & {
	type: "daterange";
};

export type FilterSectionConfiguration =
	| DateRangeFilterSectionConfiguration
	| CheckboxFilterSectionConfiguration
	| RadioFilterSectionConfiguration;

export type DateRange = {
	start: moment.Moment | null;
	end: moment.Moment | null;
};

/**
 * Renderable form for search filter options
 */
export type RenderableSearchFilterOption = SearchFilterOption & {
	/**
	 * The name property is used as the default value for rendering. To render something else,
	 * provide the label property.
	 *
	 * Example
	 *
	 * If you want to render a UserAvatar component:
	 * {
	 *     ...
	 *     label: <UserAvatar user={option.name}/>
	 *     ...
	 * }
	 * */
	label?: React.ReactNode;

	/** The string used for searches */
	search?: string;
};

export type DateRangeEdge = "start" | "end";

export type ConsumableSearchItem = Merge<
	Consumable,
	{
		parent_space: Pick<Space, "name" | "id" | "is_locked">;
		parent_furniture: Pick<FurnitureType, "name" | "id">;
		parent_furniture_category: Pick<FurnitureCategory, "name" | "id">;
	}
>;

// To convert from a moment, use the moment.toString() method
export type ConsumableSearchFilters = Partial<{
	search: string;
	page: string;
	page_size: string;
	sort_by: string;
	space: number[];
	updated_start: string;
	updated_end: string;
	modified_by: number[];
	added_start: string;
	added_end: string;
	created_by: number[];
	expiration_date_start: string;
	expiration_date_end: string;
}>;

export type ConsumableSearchColumn =
	| Exclude<
			keyof Consumable,
			| "parent_furniture_category"
			| "parent_space"
			| "created_by"
			| "updated_by"
			| "currency"
			| "id"
	  >
	| "location";

export type ConsumableSearchSettingsColumn = Exclude<
	ConsumableSearchColumn,
	"custom_id"
>;

export type ConsumableSearchSettingsDto = Record<
	ConsumableSearchSettingsColumn,
	boolean
> & {
	column_order: string;
	columns_width: { [key: string]: number } | null;
};

export type ConsumableSearchSettings = Record<
	ConsumableSearchSettingsColumn,
	boolean
> & {
	column_order: ConsumableSearchSettingsColumn[];
	columns_width: { [key: string]: number } | null;
};

// Consumable search settings plus the columns we always display
export type FullConsumableSearchSettings = Record<
	ConsumableSearchColumn,
	boolean
> & {
	column_order: ConsumableSearchColumn[];
};
