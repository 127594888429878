import React, { useState } from "react";
import { Button } from "antd";
import image from "./popup_image.svg";
import "./PopupMessage.scss";

export default function (props) {
	let [classNames, setClassNames] = useState(["genemod-popup-message"]);
	let onClick = () => {
		classNames.push("genemod-popup-message-hidden");
		setClassNames([
			"genemod-popup-message",
			"genemod-popup-message-hidden",
		]);
		setTimeout(props.onOk, 700);
	};

	return (
		<div className={classNames.join(" ")}>
			<div className="content">
				<img className="left" alt="popup-image" src={image}></img>
				<div className="right">
					<div className="title">{props.title || ""}</div>
					<div className="message">{props.message || ""}</div>
					<Button className="button" onClick={onClick}>
						{props.okText || "OK"}
					</Button>
				</div>
			</div>
		</div>
	);
}
