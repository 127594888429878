import React, { useState } from "react";
import styles from "./index.module.scss";
import {
	Typography,
	DemoWrapper,
	DemoSection,
	Demo,
	Tooltip,
	GenemodIcon,
} from "@components";
import classNames from "classnames";
import { Color, ColorCssVarMap } from "@common/styles/Colors";

type ProgressBarProps = {
	/** Number between 0 and 1 with 1 being 100% */
	value: number;
	/** class name for wrapping div */
	className?: string;
	/** Label above the progress bar */
	label?: string;
	/** Label color (defulat = "text-primary") */
	labelColor?: Color;
	/** Element after the progress bar */
	suffix?: React.ReactNode;
	/** Default "var(--button-primary)" */
	color?: Color;
	/** style of base progress bar */
	barStyle?: React.CSSProperties;
	variant?: "default" | "progress";
};

/** Progress bar component
 * */
export function ProgressBar({
	value,
	suffix,
	label,
	labelColor = "text-primary",
	color = "button-primary",
	barStyle,
	variant = "default",
	...props
}: ProgressBarProps) {
	return (
		<div
			{...props}
			className={classNames(
				styles.container,
				{ [styles.progressContainer]: variant === "progress" },
				props.className
			)}
		>
			{label && (
				<Typography
					variant="label"
					color={labelColor}
					ellipsis
					className={styles.label}
				>
					{label}
				</Typography>
			)}
			<div className={styles.bar}>
				<div
					className={classNames(styles.progress, {
						[styles.progressBackground]: variant === "progress",
					})}
					style={barStyle}
				>
					<div
						className={classNames(styles.progress__inner, {
							[styles.progressInner]: variant === "progress",
						})}
						style={{
							width: `${Math.min(value * 100, 100)}%`,
							backgroundColor: ColorCssVarMap[color],
							borderRadius: barStyle?.borderRadius,
						}}
					/>
				</div>
				{suffix && (
					<Typography
						variant="caption"
						color="text-primary"
						className={styles.suffix}
					>
						{suffix}
					</Typography>
				)}
			</div>
		</div>
	);
}

type ProgressBarWithCompleteProps = {
	/** Number between 0 and 1 with 1 being 100% */
	value: number;
	/** Tooltip text to display for the complete button */
	tooltip: string;
	/** Callback fired when complete button is clicked */
	onCompleteClick: () => void;
	/** Avoid to displaying tooltip when the parent element is in read mode. Example: archived subrpojects */
	readOnly?: boolean;
};

/**
 * Progress bar plus a complete button at the end.
 */
export function ProgressBarWithComplete({
	value,
	tooltip,
	readOnly,
	onCompleteClick,
}: ProgressBarWithCompleteProps) {
	const [hideTooltip, setHideTooltip] = useState(false);

	let completeButton = (
		<div
			className={classNames(styles.checkIcon, {
				[styles.checkIcon__complete]: value === 1,
				[styles.checkIcon__inprogress]: value !== 1,
			})}
			style={{
				left: `calc(${value * 100}% - 8px)`,
			}}
		>
			<GenemodIcon
				style={readOnly ? { cursor: "default" } : {}}
				name="circle-check"
				size="large"
				onClick={() => {
					onCompleteClick();

					// hide the tooltip right away
					setHideTooltip(true);
					// after a bit of a delay, reenable the tooltip
					setTimeout(() => setHideTooltip(false), 300);
				}}
				fill={value === 1 ? "green" : "blue"}
			/>
		</div>
	);

	if (!readOnly) {
		completeButton = (
			<Tooltip
				title={tooltip}
				placement="right"
				hideTooltip={hideTooltip}
			>
				{completeButton}
			</Tooltip>
		);
	}
	return (
		<div
			className={classNames(styles.flex, styles.progressBarWithComplete)}
		>
			<ProgressBar
				value={value}
				barStyle={{
					height: 8,
					borderRadius: 6,
					width: 256,
				}}
				color={value === 1 ? "green" : "accent-subtle"}
			/>
			{completeButton}
		</div>
	);
}

export function PROGRESSBAR_DEMO() {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage of progress bar"
					description="Progress bar includes a label, suffix, and value"
				>
					<div style={{ width: "200px" }}>
						<ProgressBar
							value={0.75}
							suffix="3/4"
							label="My progress"
						/>
					</div>
					<div style={{ width: "200px" }}>
						<ProgressBar
							value={1}
							suffix="5/5"
							label="Make waffles"
						/>
					</div>
					<div style={{ width: "200px" }}>
						<ProgressBar
							value={0}
							suffix="Incomplete"
							label="Different Bar"
						/>
					</div>
					<div style={{ width: "200px" }}>
						<ProgressBar value={1} label="No suffix" />
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
