import { useEffect, useState } from "react";
import { Notification } from "@common/components";
import { truncArgs } from "@helpers/Formatters";
import { AtLeastId, PersistedObject } from "@helpers/TypeHelpers";
import { HandledAxiosError } from "@redux/helpers/AxiosErrorHelpers";
import { useAppDispatch } from "@redux/store";
import { AsyncThunk } from "@reduxjs/toolkit";

/**
 * Props needed to use the UseFormState hook.
 * If getFormItems is not provided then the return value of formItems is an empty array, you can use getFormItem instead directly.
 */
export type UseFormStateProps<ObjectType extends PersistedObject> = {
	currentOrDefaultObject: Partial<ObjectType> | undefined | null;
	isEditable?: boolean;
	saveOptions:
		| CallbackSaveOptions<ObjectType>
		| ActionSaveOptions<ObjectType>;
	getFormItems?: (
		getFormItem: FormItemProviderStateWrapper<ObjectType>,
		extra: ExtraFormState<ObjectType>
	) => React.ReactNode[];
	defaultInvalid?: boolean;
};

// In some cases we will need extra data about the internal form state (eg: Price on items uses two form items)
export type ExtraFormState<ObjectType extends PersistedObject> = {
	formState: Partial<ObjectType>;
	onChange: <K extends keyof ObjectType>(
		key: K,
		value: ObjectType[K]
	) => void;
	isEditing: boolean;
	onValidityChange: (key: keyof ObjectType, isValid: boolean) => void;
};

/**
 * State objects/functions passed from a form item provider
 */
export type FormItemProvidedState<
	ObjectType extends PersistedObject,
	Key extends keyof ObjectType
> = {
	value: ObjectType[Key] | undefined;
	isEditing: boolean;
	onChange: (value: ObjectType[Key] | undefined) => void;
	onValidityChange: (isValid: boolean) => void;
	key: Key;
	extra: ExtraFormState<ObjectType>;
};

/**
 * Type defined by callers of StatefulCollapsibleViewCard that allows specification of form items
 */
export type FormItemProvider<
	ObjectType extends PersistedObject,
	Key extends keyof ObjectType
> = (newProps: FormItemProvidedState<ObjectType, Key>) => React.ReactNode;

/**
 * Type for function that provides form state to form items defined in a FormItemProvider
 */
export type FormItemProviderStateWrapper<ObjectType extends PersistedObject> = <
	Key extends keyof ObjectType
>(
	key: Key,
	getter: FormItemProvider<ObjectType, Key>
) => React.ReactNode;

/**
 * Use this when you need to implement the save logic yourself
 */
export type CallbackSaveOptions<ObjectType extends PersistedObject> = {
	onSave: (
		changes: Partial<ObjectType>,
		onValidityChange: (key: keyof ObjectType, isValid: boolean) => void
	) => Promise<unknown> | undefined | null;
};

/**
 * Use this when you want the create/updates to happen automatically will generate snackbars for you.
 * We're making an assumption that the object has "name" in it. In the future we might want to flesh this out
 * but only when it becomes worth the time to implement it.
 *
 * Note: It would be nice to give parentId a proper type but I encountered problems making it a consistent type without adding a
 * generic type for it that has to be passed down to the hook itself.
 */
export type ActionSaveOptions<ObjectType extends PersistedObject> =
	| {
			mode: "CREATE";
			action: AsyncThunk<
				ObjectType,
				{ parentId: any; data: Partial<ObjectType> },
				HandledAxiosError
			>;
			parentId: any;
			onSuccess?: (newObject: ObjectType) => void;
			onFail?: () => void;
	  }
	| {
			mode: "PATCH";
			action: AsyncThunk<
				ObjectType,
				AtLeastId<ObjectType>,
				HandledAxiosError
			>;
			onSuccess?: (patchedObject: ObjectType) => void;
			onFail?: () => void;
	  };

/**
 * Narrows type for save options
 */
const isCallbackSaveOptions = <ObjectType extends PersistedObject>(
	options: CallbackSaveOptions<ObjectType> | ActionSaveOptions<ObjectType>
): options is CallbackSaveOptions<ObjectType> => {
	return (options as CallbackSaveOptions<ObjectType>).onSave !== undefined;
};

/**
 * Provides some standard funtionality to keep track and update state representing some "PersistedObject".
 */
export const useFormState = <ObjectType extends PersistedObject>(
	props: UseFormStateProps<ObjectType>
) => {
	const {
		currentOrDefaultObject,
		isEditable = true,
		saveOptions,
		getFormItems,
	} = props;

	const dispatch = useAppDispatch();
	const [isEditing, setIsEditing] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formState, setFormState] = useState<Partial<ObjectType>>({});
	const [validityState, setValidityState] = useState<
		Partial<Record<keyof ObjectType, boolean>>
	>({});
	const [lastId, setLastId] = useState<ObjectType["id"] | undefined>(
		currentOrDefaultObject?.id
	);

	useEffect(() => {
		if (!isEditing) {
			clearFormState();
		}
		if (currentOrDefaultObject?.id !== lastId) {
			onCancel();
		}
		setLastId(currentOrDefaultObject?.id);
	}, [JSON.stringify(currentOrDefaultObject)]);

	const onEdit = () => {
		setIsEditing(true);
	};

	const onCancel = () => {
		setIsEditing(false);
		setValidityState({});
		clearFormState();
	};

	const clearFormState = () => {
		const resetFormState: Partial<ObjectType> =
			currentOrDefaultObject || {};
		setFormState({ ...resetFormState });
	};

	const onChange = <K extends keyof ObjectType>(
		key: K,
		value: ObjectType[K] | undefined
	) => {
		setFormState({
			...formState,
			[key]: value,
		});
	};

	const getIsValid = () => {
		return !Object.values(validityState).some(
			(isValid) => isValid === false
		);
	};

	const onValidityChange = (key: keyof ObjectType, isValid: boolean) => {
		setValidityState((validityState) => {
			if (validityState[key] !== isValid) {
				// Note that we want to create a new object so that react knows to rerender on changes.
				return {
					...validityState,
					[key]: isValid,
				};
			}
			// Do not change the application reference if no change.
			return validityState;
		});
	};

	const saveWithOnSave = () => {
		setIsSubmitting(true);
		const onSave = (saveOptions as CallbackSaveOptions<ObjectType>).onSave;
		const promise = onSave(formState, onValidityChange);
		if (promise) {
			// Errors should be handled by the component passing the promise
			promise
				.then(() => {
					setIsSubmitting(false);
					setIsEditing(false);
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		}
	};

	const saveWithAction = () => {
		setIsSubmitting(true);
		const options = saveOptions as ActionSaveOptions<ObjectType>;

		let successSuffix = "saved.";
		let failurePrefix = "Failed to save ";

		const failWithMessage = () => {
			const name = (currentOrDefaultObject as any).name || "";
			const message = failurePrefix + name;
			Notification.warning({
				message: truncArgs`${failurePrefix} "${name}".`(68),
			});
			throw message;
		};

		let actionPayload: any;
		if (options.mode === "PATCH") {
			actionPayload = {
				id: (currentOrDefaultObject as any).id,
				...formState,
			};
			if (!actionPayload.id) {
				console.error("Cannot patch object without id!");
				failWithMessage();
			}
			successSuffix = "updated.";
			failurePrefix = "Failed to update ";
		} else if (options.mode === "CREATE") {
			actionPayload = {
				parentId: options.parentId,
				data: {
					...currentOrDefaultObject,
					...formState,
				},
			};
		}

		// Loosen up typing of action so we can call it without fussing too much with types
		const looseAction = options.action as AsyncThunk<
			ObjectType,
			any,
			HandledAxiosError
		>;
		return dispatch(looseAction(actionPayload))
			.then((response) => {
				const updatedObject = response?.payload as ObjectType;
				if ("error" in response) {
					failWithMessage();
					options.onFail?.();
				} else {
					const name = (updatedObject as any)?.name || "";
					Notification.success({
						message: truncArgs`"${name}" ${successSuffix}`(40),
					});
					setIsEditing(false);
					options.onSuccess?.(updatedObject);
				}
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	const saveFunction = isCallbackSaveOptions(saveOptions)
		? saveWithOnSave
		: saveWithAction;

	const extraFormStateForItemGetters = {
		formState,
		onChange,
		isEditing,
		onValidityChange,
	};

	const getFormItem = <K extends keyof ObjectType>(
		key: K,
		provider: FormItemProvider<ObjectType, K>
	) =>
		provider({
			value: formState[key] as ObjectType[K] | undefined,
			isEditing,
			onChange: (val: ObjectType[K] | undefined) => onChange(key, val),
			onValidityChange: (isValid: boolean) =>
				onValidityChange(key, isValid),
			key,
			extra: extraFormStateForItemGetters,
		});

	const formItems =
		getFormItems?.(getFormItem, extraFormStateForItemGetters) || [];

	return {
		isEditing,
		isSubmitting,
		isValid: getIsValid(),
		onEdit,
		onSave: saveFunction,
		onCancel,
		isEditable,
		formItems,
		getFormItem,
		extra: {
			inputProps: props,
			formState,
			setFormState,
			validityState,
			setValidityState,
		} as const,
	} as const;
};
