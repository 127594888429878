import React from "react";
import {
	ConnectionLostModal,
	ShareLinkModal,
	UpgradeModal,
	WorkspacePanel,
} from "@common/components";
import HelpCenterPanel from "@common/components/HelpCenterPanel/HelpCenterPanel";
import ContactUsPanel from "@common/components/ContactUsPanel";
import WhatsNewPopup from "@common/components/WhatsNewPopup/WhatsNewPopup";
import RequestUpgradeModal from "@common/modals/RequestUpgradeModal";
import CreateOrEditItemTypeModal from "@containers/Freezer/components/CreateOrEditItemTypeModal";

export default React.memo(function GlobalModals(): JSX.Element {
	return (
		<>
			<ShareLinkModal />
			{/* We will probably be adding this back in the future. */}
			{/* <ChangePlanModal /> */}
			<UpgradeModal />
			<HelpCenterPanel />
			<ContactUsPanel />
			<WhatsNewPopup />
			<WorkspacePanel />
			<RequestUpgradeModal />
			<CreateOrEditItemTypeModal />
			<ConnectionLostModal />
		</>
	);
});
