import React, { useState } from "react";
import styles from "./index.module.scss";
import { GenemodIcon, Typography, CheckboxV2, ButtonV2 } from "@components";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import cn from "classnames";

type CollapsibleContainerProps = {
	/** the title of container */
	title?: string;
	/** (start,end)=> ;  Called after dropping. Provides start and end indices */
	onDragEnd?: (start: number, end: number) => void;
	/** Default collapsed state */
	defaultCollapsed?: boolean;
	/** Default collapsed state */
	hideEditBtn?: boolean;
	/** Default collapsed state */
	hideCollapseBtn?: boolean;
	children: any;
	type?: any;
	onReset?: React.FormEventHandler<HTMLButtonElement> & (() => void);
};
export function CollapsibleContainer({
	title,
	onDragEnd,
	defaultCollapsed,
	hideEditBtn = false,
	hideCollapseBtn = false,
	children,
	onReset,
}: CollapsibleContainerProps): JSX.Element {
	const [isCollapsed, collapse] = useState(defaultCollapsed ?? true);

	// Sends start and end index to prop fn
	const handleDragEnd = (result: DropResult) => {
		if (result && onDragEnd) {
			const { source, destination } = result;
			if (source && destination) {
				const start = source.index;
				const end = destination.index;
				onDragEnd(start, end);
			}
		}
	};

	return (
		<div className={styles.collapsibleContainer}>
			<div
				className={cn(styles.header, {
					[styles.header__collapsed]: isCollapsed,
				})}
			>
				<div
					className={styles.titleHeader}
					onClick={() => {
						!hideCollapseBtn && collapse(!isCollapsed);
					}}
					style={{ cursor: hideCollapseBtn ? undefined : "pointer" }}
				>
					{!hideCollapseBtn && (
						<div className={styles.iconBox}>
							<GenemodIcon
								name={
									isCollapsed
										? "chevron-right"
										: "chevron-down"
								}
								color="text-secondary-v2"
							/>
						</div>
					)}

					<Typography
						id={styles.itemGroupType}
						variant="body"
						medium
						style={hideCollapseBtn ? { marginLeft: 16 } : undefined}
						color="text-secondary-v2"
					>
						{title}
					</Typography>
				</div>

				<div className={styles.contentHeader}>
					{!isCollapsed && !hideEditBtn && (
						<ButtonV2
							type="link"
							onClick={() => {
								if (
									typeof onReset !== "undefined" &&
									onReset !== null
								) {
									onReset();
								}
								collapse(false);
							}}
						>
							Reset
						</ButtonV2>
					)}
				</div>
			</div>
			{!isCollapsed && (
				<div className={styles.content}>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId={title + "-droppable"}>
							{(provided: any) => (
								<div
									className={styles.columns}
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{children}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			)}
		</div>
	);
}

// Props spread into checkbox
export function RearrangeableColumn(props: any) {
	return (
		<Draggable key={props.id} draggableId={props.id} index={props.index}>
			{(provided: any) => (
				<div
					className={styles.rearrangeableCol}
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<CheckboxV2 {...props}>
						<Typography>{props.children}</Typography>
					</CheckboxV2>
					<div className={styles.iconBox}>
						<GenemodIcon
							name="drag-handle"
							{...provided.dragHandleProps}
							color="text-secondary-v2"
						/>
					</div>
				</div>
			)}
		</Draggable>
	);
}
