import React, { useEffect } from "react";
import { appSelector, useAppDispatch } from "@redux/store";
import { finishItemPrint } from "@redux/freezer/BoxViewSlice";
import { jsPDF } from "jspdf";
import { getCellId } from "../data";
import moment from "moment";
import {
	CustomItemTypeSchema,
	getFullNameFromAvatar,
	Box,
	Item,
	CURRENCY_TYPES,
	ITEM_GROUP_CONTAINERS,
	ItemLocationData,
	ItemGroup,
	isItemGroup,
} from "@common/types";
import { capitalizeFirstLetter } from "@helpers/Formatters";
import { isSafariBrowser } from "@helpers/Browser";
import {
	addGenemodLogo,
	addNewDoc,
	addPageNumber,
	getMaxContentWidth,
	getNewPositionY,
	getTextHeight,
} from "@helpers/Print/PrintHelper";
import {
	COLOR_RGB,
	FONT_STYLE,
	PDF_PAGE_MARGIN,
} from "@helpers/Print/PrintTypes";

import { useBoxView, useItemGroup } from "../table/BoxTableHooks";
import { anchormeValidator } from "@common/components/GenemodAnchorme/GenemodAnchorme";
import {
	useItemTypesQuery,
	useItemQuery,
	useItemLocationQuery,
} from "@redux/inventory/Item";

export default function PrintItem(): JSX.Element | null {
	const dispatch = useAppDispatch();
	const { box } = useBoxView();
	const { item_group } = useItemGroup();
	const itemId = appSelector(
		(state) => state.freezer.boxView.itemPrintId.data
	);
	const { isSuccess: itemTypesSuccess, data: itemTypes } =
		useItemTypesQuery();
	const { data: item } = useItemQuery(itemId || -1, { skip: !itemId });
	const { data: itemLocation } = useItemLocationQuery(itemId || -1, {
		skip: !itemId,
	});
	const [pdfSrc, setPdfSrc] = React.useState("");

	useEffect(() => {
		if (!item || !itemTypesSuccess || !itemLocation) return;
		if (!box && !item_group) return;
		// Get corresponding item type
		const itemType = itemTypes.find(
			(itemType) => itemType.id === item.item_type
		);

		// Create printable pdf
		const pdf = printItem(
			item,
			box || (item_group as Box | ItemGroup),
			itemLocation,
			itemType
		);
		pdf.setProperties({
			title: item.name,
		});

		// Enable autoprint
		const isSafari = isSafariBrowser();
		if (!isSafari) {
			pdf.autoPrint();
		}

		// Display print dialog or open pdf in separate page
		const src = pdf.output("bloburi").toString();
		if (!isSafari) {
			setPdfSrc(src);
		} else {
			window.open(src);
		}
		dispatch(finishItemPrint());
	}, [item, box, status, itemLocation]);

	return pdfSrc ? (
		<iframe src={pdfSrc} style={{ zIndex: -1, display: "none" }} />
	) : null;
}

const DEFAULT_SPACE = Object.freeze({
	header: {
		horizontal: 32,
		vertical: 16,
		fieldAndValue: 4,
	},
	section: {
		vertical: 24,
		horizontal: 12,
	},
	sectionDetail: {
		vertical: 12,
		horizontal: 12,
	},
});

type BoxItemPath = {
	freezer: string;
	shelf: string;
	rack?: string;
	category?: string;
	box: string;
	cell: string;
	type: "box";
};

type ItemGroupItemPath = {
	freezer: string;
	shelf: string;
	category: string;
	"item group": string;
	container: string;
	type: "itemgroup";
};

type ItemPathType = BoxItemPath | ItemGroupItemPath;

/**
 * Add Main Header Area on the top of first page
 */
function addMainHeaderArea(doc: jsPDF, title: string, itemPath: ItemPathType) {
	let currentX = PDF_PAGE_MARGIN.left;
	let currentY = PDF_PAGE_MARGIN.top;
	const {
		fontSize: titleFontSize,
		lineHeight: titleLineHeight,
		color: titleColor,
	} = FONT_STYLE.title;
	const {
		fontSize: bodyFontSize,
		lineHeight: bodyLineHeight,
		color: bodyColor,
	} = FONT_STYLE.body;
	const { fontSize: labelFontSize, color: labelColor } = FONT_STYLE.label;
	const headerSpace = DEFAULT_SPACE.header;
	const maxFieldWidth = getMaxContentWidth(doc);

	// Genemod LOGO
	addGenemodLogo(doc);
	// Header Title
	doc.setTextColor(titleColor[0], titleColor[1], titleColor[2]);
	const textLines = doc
		.setFont("lato", "bold")
		.setFontSize(titleFontSize)
		.splitTextToSize(title, maxFieldWidth);

	doc.text(textLines, currentX, currentY, {
		baseline: "top",
		lineHeightFactor: titleLineHeight / titleFontSize,
	});
	currentY +=
		textLines.length * doc.getTextDimensions(textLines[0]).h +
		headerSpace.vertical;

	// Header fields
	const pathEntries = Object.entries(itemPath);
	pathEntries.forEach(([field, value], index) => {
		if (value === undefined || field === "type") {
			return;
		}
		let fieldValue = value;
		const textLines = doc
			.setFont("lato", "normal")
			.setFontSize(bodyFontSize)
			.splitTextToSize(value, maxFieldWidth);
		if (textLines.length > 1) {
			fieldValue = textLines[0].slice(0, textLines[0].length - 3) + "...";
		}
		const { w: valueW, h: valueH } = doc.getTextDimensions(fieldValue);
		const { w: fieldW, h: fieldH } = doc.getTextDimensions(field);
		if (
			currentX + valueW > maxFieldWidth ||
			currentX + fieldW > maxFieldWidth
		) {
			// if there is no enough space move next line
			currentY +=
				headerSpace.vertical +
				headerSpace.fieldAndValue +
				valueH +
				fieldH;
			currentX = PDF_PAGE_MARGIN.left;
		}
		doc.setTextColor(bodyColor[0], bodyColor[1], bodyColor[2]).text(
			fieldValue,
			currentX,
			currentY,
			{
				baseline: "top",
				lineHeightFactor: bodyLineHeight / bodyFontSize,
			}
		);
		doc.setTextColor(labelColor[0], labelColor[1], labelColor[2])
			.setFontSize(labelFontSize)
			.text(
				field,
				currentX,
				currentY + valueH + headerSpace.fieldAndValue,
				{
					baseline: "top",
				}
			);

		currentX += Math.max(valueW, fieldW) + headerSpace.horizontal;
		if (index === pathEntries.length - 1) {
			// move nextLine if it is the last field.
			currentY += valueH + fieldH + headerSpace.fieldAndValue;
		}
	});

	return [PDF_PAGE_MARGIN.left, currentY];
}

/**
 * Add Header Area on the top of first page
 */
function addHeaderArea(doc: jsPDF, title: string, itemPath: ItemPathType) {
	const currentX = PDF_PAGE_MARGIN.left;
	let currentY = PDF_PAGE_MARGIN.top;
	const {
		fontSize: bodyFontSize,
		lineHeight: bodyLineHeight,
		color: bodyColor,
	} = FONT_STYLE.body;
	const {
		fontSize: labelFontSize,
		color: labelColor,
		lineHeight: labelLineHeight,
	} = FONT_STYLE.label;
	const headerSpace = DEFAULT_SPACE.header;
	const maxFieldWidth = getMaxContentWidth(doc);

	// Genemod LOGO
	addGenemodLogo(doc);
	// Header Title
	doc.setTextColor(bodyColor[0], bodyColor[1], bodyColor[2]);
	const textLines = doc
		.setFont("lato", "normal")
		.setFontSize(bodyFontSize)
		.splitTextToSize(title, maxFieldWidth);
	doc.text(textLines, currentX, currentY, {
		baseline: "top",
		lineHeightFactor: bodyLineHeight / bodyFontSize,
	});
	currentY +=
		getTextHeight(
			textLines.length,
			doc.getTextDimensions(textLines[0]).h,
			bodyFontSize,
			bodyLineHeight
		) +
		headerSpace.vertical / 2;
	// Item Path
	const path = Object.entries(itemPath)
		.map(([_, value]) => value)
		.join(" > ");
	doc.setTextColor(labelColor[0], labelColor[1], labelColor[2]);
	const pathLines = doc
		.setFontSize(labelFontSize)
		.splitTextToSize(path, maxFieldWidth);
	doc.text(pathLines, currentX, currentY, {
		baseline: "top",
		lineHeightFactor: labelLineHeight / labelFontSize,
	});
	currentY += getTextHeight(
		pathLines.length,
		doc.getTextDimensions(pathLines[0]).h,
		labelFontSize,
		labelLineHeight
	);
	return currentY;
}

function addSectionHeader(
	doc: jsPDF,
	sectionTitle: string,
	startX: number,
	startY: number
) {
	const docWidth = doc.internal.pageSize.getWidth();
	let currentX = startX;
	let currentY = startY + DEFAULT_SPACE.section.vertical;
	const { fontSize: labelFontSize, color: labelColor } = FONT_STYLE.label;
	const { section_header_line: lineColor } = COLOR_RGB;
	const title = sectionTitle.toUpperCase();
	doc.setTextColor(labelColor[0], labelColor[1], labelColor[2])
		.setFontSize(labelFontSize)
		.text(title, currentX, currentY, {
			baseline: "top",
		});
	const { w: titleW, h: titleH } = doc.getTextDimensions(title);

	currentX += titleW + DEFAULT_SPACE.section.horizontal;
	currentY += titleH / 2;
	doc.setDrawColor(lineColor[0], lineColor[1], lineColor[2]);
	// draw solid line
	doc.setLineDashPattern([5, 0], 0);
	doc.line(currentX, currentY, docWidth - PDF_PAGE_MARGIN.right, currentY);
	currentY += titleH / 2;
	return [PDF_PAGE_MARGIN.left, currentY];
}

/**
 * get maxWidth of field key and fieldValue
 */
const getMaxWidthOfField = (doc: jsPDF) => {
	const maxContentWidth = getMaxContentWidth(doc, false);
	const maxValueWidth =
		maxContentWidth / 2 + DEFAULT_SPACE.sectionDetail.horizontal;
	const maxKeyWidth =
		maxContentWidth -
		maxValueWidth -
		DEFAULT_SPACE.sectionDetail.horizontal;

	return {
		maxKeyWidth,
		maxValueWidth,
	};
};

/**
 * get minimum height for section titles to be added to the current page
 * there would be at least space for section and one field key
 */
function getMinHeightForSectionTitle(
	doc: jsPDF,
	title: string,
	firstFieldKey: string
) {
	const { fontSize: labelFontSize } = FONT_STYLE.label;
	const { fontSize: bodyFontSize, lineHeight: bodyLineHeight } =
		FONT_STYLE.body;
	const { maxKeyWidth } = getMaxWidthOfField(doc);
	const titleH = doc.setFontSize(labelFontSize).getTextDimensions(title).h;
	const fieldKeyLines = doc
		.setFontSize(bodyFontSize)
		.splitTextToSize(capitalizeFirstLetter(firstFieldKey), maxKeyWidth);
	const fieldKeyH = getTextHeight(
		fieldKeyLines.length,
		doc.getTextDimensions(fieldKeyLines[0]).h,
		bodyFontSize,
		bodyLineHeight
	);

	return (
		DEFAULT_SPACE.section.vertical +
		titleH +
		DEFAULT_SPACE.sectionDetail.vertical +
		fieldKeyH
	);
}

/**
 * to make array which have sectionTitle and corressponding section field data
 * return [sectionTitle, {[fieldKey: string]: fieldValue(string | number | undefined) }][]
 */
function getSectionFields(item: Item, itemType?: CustomItemTypeSchema) {
	const {
		/** General information */
		custom_id,
		concentration,
		volume,
		added_at,
		created_by: owner,
		expiration_date,
		updated_at,
		updated_by,
		/** Note */
		notes,
		/** Source */
		source,
		reference,
		catalog,
		lot,
		packaging,
		currency,
		price,
	} = item;
	const dateFormat = "MMMM DD, YYYY";
	const sectionFields = [
		[
			"general information",
			{
				ID: custom_id,
				"Item type": itemType?.name,
				concentration,
				"Vol / Mass": volume,
				"Created on": moment(added_at).format(dateFormat),
				"Created by": owner
					? getFullNameFromAvatar(owner)
					: "Unknown user",
				"Expires on": expiration_date
					? moment(expiration_date).format(dateFormat)
					: "",
				"Last modified on": moment(updated_at).format(dateFormat),
				"Last modified by": updated_by
					? getFullNameFromAvatar(updated_by)
					: "Unknown user",
			},
		],
	] as [string, { [key: string]: string | number | undefined }][];
	if (itemType && itemType.schema.length > 0) {
		const typeFields = {} as { [key: string]: string };
		itemType.schema.forEach((schema) => {
			let value = "";
			if (item.type_data) {
				const typeValue = item.type_data[schema.uuid] || "";
				value =
					typeof typeValue === "object"
						? typeValue.join(" ,")
						: typeValue;
			}
			typeFields[schema.label] = value;
		});
		sectionFields.push([`${itemType.name} information`, typeFields]);
	}
	sectionFields.push(["notes", { notes }]);
	sectionFields.push([
		"source",
		{
			source,
			reference,
			"Catalog #": catalog,
			"Lot #": lot,
			packaging,
			price: price ? `${CURRENCY_TYPES[currency]} ${price}` : "",
		},
	]);
	return sectionFields;
}

/**
 * add section fields under section header
 */
function addSectionField(
	doc: jsPDF,
	fieldKey: string,
	fieldValue: string | number | undefined,
	startX: number,
	startY: number,
	title: string,
	itemPath: ItemPathType
) {
	const docWidth = doc.internal.pageSize.getWidth();
	const { maxKeyWidth, maxValueWidth } = getMaxWidthOfField(doc);
	const { fontSize, lineHeight, color } = FONT_STYLE.body;
	const { section_detail_line: lineColor } = COLOR_RGB;
	const { link: linkColor } = COLOR_RGB;
	let isLink = false;
	let currentY = startY + DEFAULT_SPACE.sectionDetail.vertical;
	let currentX = startX;

	// Add field Key
	const keyTextLines = doc
		.setFontSize(fontSize)
		.setTextColor(color[0], color[1], color[2])
		.splitTextToSize(capitalizeFirstLetter(fieldKey), maxKeyWidth);
	const { w: keyW, h: keyH } = doc.getTextDimensions(keyTextLines[0]);
	const fieldKeyHeight = getTextHeight(
		keyTextLines.length,
		keyH,
		fontSize,
		lineHeight
	);
	currentY = getNewPositionY(doc, currentY, fieldKeyHeight, {
		extraTopMargin: DEFAULT_SPACE.section.vertical,
		addHeaderArea: () => addHeaderArea(doc, title, itemPath),
	});
	doc.text(keyTextLines, startX, currentY, {
		baseline: "top",
		lineHeightFactor: lineHeight / fontSize,
	});
	currentX += keyW + DEFAULT_SPACE.sectionDetail.horizontal;

	// Add dash line between key and value
	const endLineX =
		docWidth -
		PDF_PAGE_MARGIN.right -
		maxValueWidth -
		DEFAULT_SPACE.sectionDetail.horizontal;
	const lineY = currentY + keyH / 2;
	doc.setDrawColor(lineColor[0], lineColor[1], lineColor[2]);
	// draw dash line
	doc.setLineDashPattern([5, 5], 0);
	doc.line(currentX, lineY, endLineX, lineY);
	currentX = endLineX + DEFAULT_SPACE.sectionDetail.horizontal;

	// Add field value
	const valueString = "" + fieldValue || "-";
	const valueTextLines = doc.splitTextToSize(valueString, maxValueWidth);
	if (fieldKey === "reference" && anchormeValidator.url(valueString)) {
		isLink = true;
	}

	let nLineInPage = 0;
	doc.setFontSize(fontSize);
	for (const textLine of valueTextLines) {
		const { h: textH } = doc.getTextDimensions(valueTextLines[0]);
		let newY = currentY + (textH / fontSize) * lineHeight * nLineInPage;
		const y = getNewPositionY(doc, newY, textH, {
			extraTopMargin: DEFAULT_SPACE.sectionDetail.vertical,
			addHeaderArea: () => addHeaderArea(doc, title, itemPath),
		});
		if (y < currentY) {
			// it's new page
			currentY = y;
			newY = y;
			nLineInPage = 0;
		}
		if (isLink) {
			doc.setTextColor(
				linkColor[0],
				linkColor[1],
				linkColor[2]
			).textWithLink(textLine, currentX, newY, {
				baseline: "top",
				url: valueString,
				lineHeightFactor: lineHeight / fontSize,
			});
		} else {
			doc.setTextColor(color[0], color[1], color[2]).text(
				textLine,
				currentX,
				newY,
				{
					baseline: "top",
					lineHeightFactor: lineHeight / fontSize,
				}
			);
		}
		nLineInPage += 1;
	}

	currentY += Math.max(
		fieldKeyHeight,
		getTextHeight(nLineInPage, keyH, fontSize, lineHeight)
	);
	return [PDF_PAGE_MARGIN.left, currentY];
}

function addNoteSection(
	doc: jsPDF,
	note: string,
	startX: number,
	startY: number,
	title: string,
	itemPath: ItemPathType
) {
	const { width: docWidth, height: docHeight } = doc.internal.pageSize;
	const maxContentWidth = getMaxContentWidth(doc, false);
	const { fontSize, color, lineHeight } = FONT_STYLE.body;
	const textLines = doc
		.setFontSize(fontSize)
		.splitTextToSize(note, maxContentWidth);
	const { h: textH } = doc.getTextDimensions(textLines[0]);
	let fieldHeight = getTextHeight(
		textLines.length,
		textH,
		fontSize,
		lineHeight
	);
	let currentY = startY + DEFAULT_SPACE.sectionDetail.vertical;
	const maxLineInPage = Math.floor(
		(docHeight - currentY - PDF_PAGE_MARGIN.bottom) /
			(fieldHeight / textLines.length)
	);
	doc.setTextColor(color[0], color[1], color[2]);
	doc.text(textLines.slice(0, maxLineInPage), startX, currentY, {
		baseline: "top",
		lineHeightFactor: lineHeight / fontSize,
	});
	if (textLines.length > maxLineInPage) {
		currentY = getNewPositionY(doc, currentY, fieldHeight, {
			extraTopMargin: DEFAULT_SPACE.sectionDetail.vertical,
			addHeaderArea: () => addHeaderArea(doc, title, itemPath),
		});
		doc.setFontSize(fontSize)
			.setTextColor(color[0], color[1], color[2])
			.text(textLines.slice(maxLineInPage), startX, currentY, {
				baseline: "top",
				lineHeightFactor: lineHeight / fontSize,
			});
		fieldHeight = getTextHeight(
			textLines.length - maxLineInPage,
			textH,
			fontSize,
			lineHeight
		);
	}

	return [startX, currentY + fieldHeight];
}

// Note: we support undefined item type schemas
// because users are allowed to delete them
function printItem(
	item: Item,
	container: Box | ItemGroup,
	itemLocation: ItemLocationData,
	itemType?: CustomItemTypeSchema
) {
	const doc = addNewDoc();
	let itemPath: ItemPathType;
	if (isItemGroup(container)) {
		itemPath = {
			type: "itemgroup",
			freezer: item.location_data.freezer_name,
			shelf: itemLocation.shelf_name,
			category: itemLocation.category_name,
			"item group": container.name,
			container: ITEM_GROUP_CONTAINERS[container.container_type],
		} as ItemGroupItemPath;
	} else {
		itemPath = {
			type: "box",
			freezer: item.location_data.freezer_name,
			shelf: itemLocation.shelf_name,
			...(itemLocation.rack_name
				? { rack: itemLocation.rack_name }
				: { category: itemLocation.category_name }),
			box: container.name,
			cell: getCellId(item, container.axis_direction),
		} as BoxItemPath;
	}

	let [currentX, currentY] = addMainHeaderArea(doc, item.name, itemPath);
	const sectionFields = getSectionFields(item, itemType);
	for (const [title, fields] of sectionFields) {
		const fieldEntries = Object.entries(fields);
		const needHeight = getMinHeightForSectionTitle(
			doc,
			item.name,
			fieldEntries[0][0]
		);
		currentY = getNewPositionY(doc, currentY, needHeight, {
			extraTopMargin: DEFAULT_SPACE.section.vertical,
			addHeaderArea: () => addHeaderArea(doc, title, itemPath),
		});
		[currentX, currentY] = addSectionHeader(doc, title, currentX, currentY);
		for (const [fieldKey, fieldValue] of fieldEntries) {
			if (fieldKey === "notes") {
				[currentX, currentY] = addNoteSection(
					doc,
					"" + fieldValue,
					currentX,
					currentY,
					item.name,
					itemPath
				);
				continue;
			}
			[currentX, currentY] = addSectionField(
				doc,
				fieldKey,
				fieldValue,
				currentX,
				currentY,
				item.name,
				itemPath
			);
		}
	}
	addPageNumber(doc);

	return doc;
}
