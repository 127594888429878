import { ISOString } from "./Date";
import { Avatar, TeamMemberAvatar } from "./User";

export type SubprojectProps = {
	id: number;
	name: string;
	description: string;
	key: string;
	custom_id: number;
	users: TeamMemberAvatar[];
	created_at: ISOString;
	created_by: Avatar;
	updated_at: ISOString;
	updated_by: Avatar;
	status: number;
	priority: number;
	parent_project: number;
	navigation: Record<string, unknown>;
	is_archived: boolean;
	due_date: ISOString | null;
	progress: number;
	completed_at: ISOString;
};
