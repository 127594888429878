import React, { useEffect, useState } from "react";
import styles from "./AcceptInvitation.module.scss";
import { useHistory, useLocation } from "@common/helpers/Hooks/UseRouterDom";
import { InvalidInvitationPage } from "../InvalidInvitationPage/index";
import {
	useAcceptInvite,
	useCurrentUserQuery,
	useVerifyInviteQuery,
} from "@redux/user/UserApi";
import YOU_ARE_ALL_SET_IMG from "./you-are-all-set.png";
import {
	NarrowOnboarding,
	TransitionScreen,
} from "@containers/Auth/components/";
import { Button, Typography, Spin } from "@common/components";
import { useWindowDimensions } from "@helpers/Hooks";
import {
	useSearchNumericAndSet,
	useSearchParamAndSet,
} from "@helpers/URLParams";
import { UUID } from "@common/types";
import { useLoginContext } from "@containers/Auth/LoginInterfaces/LoginContext";
import { useLazyGetOrganizationsQuery } from "@redux/team/TeamApi";

export type InvitationState = {
	email: string;
	hasExistingAccount: boolean;
	orgId: number;
	token: UUID;
};

export const useInvitationState = () => {
	const location = useLocation();
	const state = location.state as InvitationState;
	const { email, hasExistingAccount, orgId, token } = state || {};
	const valid =
		!!email &&
		!!orgId &&
		!!token &&
		(hasExistingAccount === false || hasExistingAccount === true);
	return valid
		? ({ email, hasExistingAccount, orgId, token } as const)
		: null;
};

/** Handles routing and data fetching for registration
 * @function
 * @category Auth
 * @subcategory Invitations
 * */
export default function AcceptInvitation() {
	const history = useHistory();
	const { loginStatus, logout } = useLoginContext();
	const { refetch } = useCurrentUserQuery();
	const [clickedContinue, setClickedContinue] = useState(false);
	const { width } = Object(useWindowDimensions());
	const largeWidth = width >= 1920;
	const [token] = useSearchParamAndSet("code");
	const [orgId] = useSearchNumericAndSet("organization");
	const [_beta] = useSearchParamAndSet("beta");
	const isBeta = _beta !== null;
	const invalidParams = (!orgId && !isBeta) || !token;

	const {
		data: verifyInviteData,
		isError: verifyIsError,
		isSuccess: verifyIsSuccess,
		isLoading: verifyIsLoading,
	} = useVerifyInviteQuery(
		{ token: token || "", isBeta },
		{ skip: invalidParams }
	);

	const [getOrganizations] = useLazyGetOrganizationsQuery();

	const [
		acceptInvite,
		{ isError: acceptIsError, isLoading: acceptIsLoading },
	] = useAcceptInvite();

	useEffect(() => {
		if (invalidParams || !verifyIsSuccess || !verifyInviteData) return;

		if (loginStatus === "LOGGEDIN") {
			acceptInvite({ orgId, token, isBeta })
				.then((resp) => {
					// We are likely logged in as a different account on this computer, logout
					if ((resp as any)?.error?.status === 403) {
						logout();
					} else {
						refetch();
						if (isBeta) {
							history.push("/signup/create-team");
						} else {
							getOrganizations();
						}
					}
				})
				.catch(() => {
					logout();
				});
		} else if (loginStatus === "LOGGEDOUT") {
			const pathname = verifyInviteData?.has_existing_account
				? "/login"
				: "/signup";
			history.push({
				pathname,
				state: {
					email: verifyInviteData.email,
					hasExistingAccount: verifyInviteData.has_existing_account,
					orgId,
					token,
				} as InvitationState,
			});
		}
	}, [
		history,
		acceptInvite,
		invalidParams,
		loginStatus,
		verifyIsSuccess,
		verifyInviteData,
	]);

	if (verifyIsLoading || acceptIsLoading || loginStatus === "LOADING") {
		return <Spin size="large" />;
	}

	// Are the params invalid or were there errors with verifying/accepting invite?
	const valid = !verifyIsError && !acceptIsError && !invalidParams;
	return (
		<div className={styles.container}>
			{valid && !clickedContinue && (
				<NarrowOnboarding
					modalWidth={largeWidth ? 600 : 528}
					modalHeight={largeWidth ? 786 : 695}
				>
					<img
						src={YOU_ARE_ALL_SET_IMG}
						alt="Sign up submission success."
						style={{
							width: "auto",
							height: 271,
							marginTop: 32,
							marginBottom: 48,
						}}
						data-cy="invite-signup-success"
					/>
					<Typography
						variant="title"
						bold
						style={{
							marginBottom: 32,
						}}
						color="text-primary"
					>
						You&apos;re all set!
					</Typography>
					<Button
						stretch
						onClick={() => setClickedContinue(true)}
						dataCy="continue-to-dashboard-btn"
					>
						Continue to Dashboard
					</Button>
				</NarrowOnboarding>
			)}
			{!valid && <InvalidInvitationPage />}
			{clickedContinue && (
				<TransitionScreen
					timeout={2}
					onFinish={() => history.push({ pathname: "/app" })}
				/>
			)}
		</div>
	);
}

export const buildAcceptInvitationUrl = (state: InvitationState) => ({
	pathname: `/invite`,
	search: `code=${state.token}&organization=${state.orgId}`,
});

export const buildAcceptBetaInvitationUrl = (code: string) => ({
	pathname: `/invite`,
	search: `code=${code}&beta`,
});
