import React, { forwardRef, ReactNode, useState } from "react";
import "./SelectV2.scss";
import styles from "./SelectV2.module.scss";
import { Select as ST } from "antdv5";
import { GenemodIcon, Typography } from "../index";
import className from "classnames";
import { SelectProps as AntdSelectProps, OptionProps } from "antdv5/lib/select";

export type GenemodSelectProps = AntdSelectProps & {
	// If true, we render a small version of the select
	isSmall?: boolean;
	// If true, we render a large version of the select
	isLarge?: boolean;
	// Set to true if the component is used along with other input fields
	isInput?: boolean;
	// If true, set the stroke color of the icon instead of fill color of the icon. Usually set the fill color of the icon when it is focused
	isStrokeIcon?: boolean;
	// Show the error message
	error?: string;
	// An icon that's attached to the dropdown menu. If not given, it will display the caret-down icon
	suffixIcon?: React.ReactNode;
	// Adding a class name to the dropdown
	dropdownClassName?: string;
	// Appends the drop down menu to the select container and sets up positions so it should scroll with the container
	scrollSafeMode?: boolean;
	// Classname for the component wrapper
	wrapperClassname?: string;
	// Open select by default
	defaultOpen?: boolean;
	// Manually set the visibility of the dropdown
	visible?: boolean;
	//Data-cy selector
	dataCy?: string;
};

const SelectV2 = (props: GenemodSelectProps): JSX.Element => {
	const [visible, setVisible] = useState(props.defaultOpen || props.visible);
	const [wasClicked, setWasClicked] = useState(false);

	const handleFocus = () => {
		if (!wasClicked) {
			setVisible(true);
		}
	};

	const handleBlur = () => {
		setVisible(false);
	};

	const handleVisibleChange = (open: boolean) => {
		setVisible(open);
	};

	return (
		<div
			className={className(
				"genemod-select-container",
				props.wrapperClassname
			)}
			style={{
				position: "relative",
			}}
			onKeyDown={(e) => {
				if (visible) {
					if (e.key === "Tab") {
						setVisible(false);
					}
				}
			}}
			data-cy={props.dataCy}
		>
			<ST
				popupMatchSelectWidth={false}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onDropdownVisibleChange={handleVisibleChange}
				onMouseEnter={() => setWasClicked(true)}
				onMouseLeave={() => setWasClicked(false)}
				open={visible}
				getPopupContainer={(trigger: any) => trigger.parentNode}
				{...props}
				popupClassName={className(
					"genemod-select-dropdown",
					props.dropdownClassName,
					{
						"genemod-select-dropdown__isSmall": props.isSmall,
						"genemod-select-dropdown__isInput": props.isInput,
					}
				)}
				className={className("genemod-select", props.className, {
					"genemod-select__error": props.status === "error",
					"genemod-select__isSmall": props.isSmall,
					"genemod-select__isLarge": props.isLarge,
					"genemod-select__hasTags":
						props.mode === "tags" || props.mode === "multiple",
					"genemod-select__isInput": props.isInput,
					"genemod-select__borderless":
						props.variant === "borderless",
					"genemod-select__isStrokeIcon": props.isStrokeIcon,
					"genemod-select__isFilled":
						(props.isInput &&
							props.value !== null &&
							props.value !== undefined) ||
						(props.isInput &&
							props.defaultValue !== null &&
							props.defaultValue !== undefined),
				})}
				suffixIcon={
					props.suffixIcon || (
						<GenemodIcon
							name="caret-down"
							fill={
								props.disabled
									? "text-disabled"
									: "text-secondary-v2"
							}
							size="small"
						/>
					)
				}
				allowClear={
					props.allowClear && {
						clearIcon: (
							<GenemodIcon
								name="exit-circle-o"
								fill="text-secondary-v2"
								size="small"
							/>
						),
					}
				}
			>
				{props.children}
			</ST>
			{typeof props.error === "string" && (
				<Typography
					style={{ marginTop: 8, marginBottom: -24 }}
					variant="caption"
					color="red-contrast"
				>
					{props.error}
				</Typography>
			)}
		</div>
	);
};

type SelectedOptionProps = {
	isSelected?: boolean;
	label?: string | number | React.ReactNode;
};

export function SelectedOption({
	isSelected = false,
	label,
}: SelectedOptionProps): JSX.Element {
	return (
		<div className={"selected-option-flex"}>
			<div>{label}</div>
			{isSelected && (
				<GenemodIcon name="dropdown-check" style={{ marginLeft: 12 }} />
			)}
		</div>
	);
}

type OptionWithCheckmarkProps = {
	children: React.ReactNode;
};

/**
 * An upgraded version of SelectedOption, don't need to worry about values or labels.
 */
function OptionWithCheckmark({ children }: OptionWithCheckmarkProps) {
	return (
		<div className={styles.selectedOptionFlex}>
			<div>{children}</div>
			<GenemodIcon
				name="dropdown-check"
				className={styles.dropdownCheck}
				style={{ marginLeft: 12 }}
			/>
		</div>
	);
}

const extras = {
	Option: ST.Option,
	OptGroup: ST.OptGroup,
	SelectedOpt: SelectedOption,
	OptionWithCheckmark: OptionWithCheckmark,
};
Object.assign(SelectV2, {
	Option: ST.Option,
	OptGroup: ST.OptGroup,
	SelectedOpt: SelectedOption,
	OptionWithCheckmark: OptionWithCheckmark,
});
export default SelectV2 as typeof SelectV2 &
	typeof extras & { children?: React.ReactNode };
