import { capitalizeFirstLetter } from "@helpers/Formatters";
import {
	createSlice,
	PayloadAction,
	SliceCaseReducers,
} from "@reduxjs/toolkit";

export type ModalStateType = "CLOSED" | "OPEN" | "HIDDEN";

type ExtraData = Record<any, any> | void;

// Defines a rought modal state type that we can check generics against
type ModalState<T extends ExtraData = void> = {
	state: ModalStateType;
} & (T extends void ? unknown : { data: T });

const INTIAL_STATE = { state: "CLOSED" } as const;

/**
 * Sets up a standard modal slice.
 */
export const createModalSlice =
	<T extends ExtraData = void>(initialData?: T | void) =>
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	<Name extends Readonly<string>>(name: Name) => {
		const actionName = `set${capitalizeFirstLetter(name)}` as const;
		const reducer = (
			_state: unknown,
			action: PayloadAction<ModalState<T>>
		) => action.payload;

		// type ReducerType = { [K in `set${Capitalize<Name>}`]: typeof reducer };

		const slice = createSlice<
			ModalState<T>,
			{ [K in `set${Capitalize<Name>}`]: typeof reducer },
			Name
		>({
			name,
			initialState: { ...INTIAL_STATE, ...initialData } as any,
			reducers: { [actionName]: reducer } as any,
		});
		return slice;
	};
/**
 * Sets up a standard Panel slice.
 */
export const createPanelSlice =
	<T extends ExtraData = void>(initialData?: T) =>
	<Name extends Readonly<string>>(name: Name) => {
		const initialState = {
			...(initialData || {}),
		} as T;
		const slice = createSlice<T, SliceCaseReducers<T>, Name>({
			name,
			initialState,
			reducers: {
				[`set${capitalizeFirstLetter(name)}`]: (state, action) => {
					return action.payload;
				},
			},
		});
		return slice;
	};
