import { envConfig } from "./_ConfigHelpers";

const CONFIG = {
	...envConfig("REACT_APP_DJANGO_URL", {
		production: "https://api.genemod.net",
		qa: "https://qa.api.genemod.net",
		dev: "https://dev.api.genemod.net",
		local: "http://localhost:8000",
	}),

	...envConfig("REACT_APP_YJS_HOSTNAME", {
		production: "wss://yjs.genemod.net",
		qa: "wss://nonprod.yjs.genemod.net",
		dev: "wss://nonprod.yjs.genemod.net",
		local: `ws://localhost:8080`,
	}),

	...envConfig(
		"GOOGLE_API_CONFIG",
		{
			production: {
				clientId:
					"923705792416-k8to8vfeuaehdd55nmuirselnc8nad3n.apps.googleusercontent.com",
				developerKey: "AIzaSyDH7jR8vg6ErBmrJTqih1jLSBIvXaIBJ1k",
			},
		},
		{
			defaults: {
				clientId:
					"361339033127-os8t8dn0fqes4uqoa6nnl6t7q1o7eoj9.apps.googleusercontent.com",
				developerKey: "AIzaSyCAIPRFMSzeN7C8tUQqhiZoK6INRWzCKXE",
			},
		}
	),

	...envConfig(
		"WEBSITE_URL",
		{
			local: "localhost:8000",
		},
		{ defaults: "genemod.net" }
	),

	/** backendUrl for share link */
	...envConfig("REACT_APP_URL", {
		production: "https://app.genemod.net",
		qa: "https://qa.app.genemod.net",
		dev: "https://dev.app.genemod.net",
		local: "http://localhost:3000",
	}),

	...envConfig(
		"USE_STRIPE",
		{
			onprem: false,
		},
		{ defaults: true }
		// { defaults: false }
	),
	...envConfig("STRIPE_KEYS", {
		production: {
			publishableKey: "pk_live_ok0NBMMVO1YYrH2CvZPlza3m00q86ibUVU",
			prices: {
				teamMonthly: "price_1KnZ2rAGjXIeDt1kxoxwxQxt",
				teamAnnual: "price_1KnZ2jAGjXIeDt1ktiiI161w",
				businessAnnual: "price_1KnZ2SAGjXIeDt1ktGjLJlZW",
			},
			products: {
				team: "prod_JE2WV50mzbYmzx",
				business: "prod_JE2XjGCQrhv2C1",
			},
		},
		qa: {
			publishableKey: "pk_test_AmD0FtVEms9BMSTdwJWAk4t500efidiv2D",
			prices: {
				teamMonthly: "price_1KnZ0ZAGjXIeDt1kglJs2A6W",
				teamAnnual: "price_1KnZ15AGjXIeDt1k5O0GpsTi",
				businessAnnual: "price_1KnYzsAGjXIeDt1km3tZo0r1",
			},
			products: {
				team: "prod_JAxrAIvfYV3EUv",
				business: "prod_JAxtTjF6MRzAha",
			},
		},
		dev: {
			publishableKey:
				"pk_live_51JgxfLLObGPUTx6jsE82ub8aNuMUBdiKLfNHbyNy90JPYRQkAj2cbhtVdXwG7i4hESkmBK6GUY7AmfNAEklvnQmQ00t21hxoWd",
			prices: {
				teamMonthly: "price_1KnYv4LObGPUTx6j70s6v8De",
				teamAnnual: "price_1KnYwOLObGPUTx6jwPDxbmeS",
				businessAnnual: "price_1Jh5ErLObGPUTx6jP1KD8ta7",
			},
			products: {
				team: "prod_KLmlHoFK6OWDbw",
				business: "prod_KLmoQil9x7y78I",
			},
		},
		local: {
			publishableKey:
				"pk_test_51JgxfLLObGPUTx6j4pocmYQ7qNvpY3sG8NNWOfxToTGsYHxL0psJKEb9MQcfDegzHGJnxaltfBBzWzKRYhlRBHFk00P4UyXk8L",
			prices: {
				teamMonthly: "price_1KmPbnLObGPUTx6jXGZUqTUg",
				teamAnnual: "price_1KmPbTLObGPUTx6jZZQbQHV2",
				businessAnnual: "price_1KmPaELObGPUTx6jvghqOsgp",
			},
			products: {
				team: "prod_KLn6tu9DbaQ1Jv",
				business: "prod_KLn4C5razeGRFP",
			},
		},
	}),

	/**
	 * Url for cognito hosted UI.
	 */
	...envConfig(
		"COGNITO_AUTH_BASE",
		{
			production: "",
		},
		{
			defaults:
				"https://genemodjeremytest3.auth.us-west-2.amazoncognito.com",
		}
	),

	/**
	 * Internal url where cognito redirects to after a user login/registration on the hosted UI.
	 */
	...envConfig(
		"REDIRECT_URI",
		{
			production: "",
		},
		{ defaults: "http://localhost:3000/cognitoauth" }
	),

	/**
	 * Data to setup a cognito pool.
	 */
	...envConfig("COGNITO_POOL_DATA", {
		production: {
			UserPoolId: "us-west-2_ONySOlD0Y",
			ClientId: "4chqg2b9n9p18lh54s6msr78a8",
		},
		qa: {
			UserPoolId: "us-west-2_ktpnnNW2M",
			ClientId: "2spmgoidkf4g5gom5k1v298t30",
		},
		dev: {
			UserPoolId: "us-west-2_EKbQdpSyL",
			ClientId: "6kp9v1kf2dkhebah274g1v2pi0",
		},
		local: {
			UserPoolId: "us-west-2_NYA91JA9o",
			ClientId: "6f82thtbvs76hgr0njahej8t4j",
		},
	}),

	...envConfig(
		"ANALYTICS_TRACKING",
		{
			onprem: false,
		},
		{ defaults: true }
	),
	...envConfig(
		"REACT_APP_AUTH_SYSTEM",
		{
			onprem: "DRF",
		} as const,
		{ defaults: "COGNITO" } as const
	),
	...envConfig(
		"REACT_APP_GOOGLE_DRIVE",
		{
			onprem: false,
		},
		{ defaults: false }
	),
	...envConfig("EXPERIMENT_REPORTS_FEATURE", {
		production: true,
		qa: true,
		dev: true,
		local: true,
	}),
	...envConfig("CHATGPT_FEATURE_ENABLED", {
		production: true,
		qa: true,
		dev: true,
		local: true,
	}),
} as const;

/**
 * Try not to use this anymore, use the hook
 */
export const deprecated_getConfig = <K extends keyof typeof CONFIG>(
	k: K
): (typeof CONFIG)[K] => {
	return CONFIG[k];
};

export const useConfig = <K extends keyof typeof CONFIG>(
	k: K
): (typeof CONFIG)[K] => {
	return CONFIG[k];
};
