import React, { HTMLProps } from "react";
import { Color } from "@common/styles/Colors";
import classNames from "classnames";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";
import Typography from "../Typography/Typography";
import styles from "./AlertDialog.module.scss";

type Props = {
	text: string;
	icon: IconName;
	link?: JSX.Element;
	onClose?: () => void;
	hideCloseButton?: boolean;
	iconColor?: Color;
	borderColor?: Color;
	iconStroke?: Color;
	learnMore?: string;
} & HTMLProps<HTMLDivElement>;

const AlertDialog = ({
	text,
	icon,
	link,
	onClose,
	className,
	hideCloseButton,
	iconColor = "blue",
	borderColor = "border-subtle",
	learnMore,
	iconStroke,
	...props
}: Props): JSX.Element => {
	return (
		<div
			{...props}
			style={{
				border: `solid 1px var(--${borderColor})`,
				marginTop: "-20px",
				...props.style,
			}}
			className={classNames(styles.main, className)}
		>
			<GenemodIcon
				size="large"
				color={iconColor}
				fill={iconColor}
				stroke={iconStroke}
				name={icon}
				style={{ cursor: "default" }}
			/>
			<Typography
				style={{ marginLeft: 8 }}
				variant="label"
				color="text-secondary"
			>
				{text}
			</Typography>
			{link && <span className={styles.link}>{link} </span>}
			{learnMore && (
				<Link
					to={{
						pathname: learnMore,
					}}
					target="_blank"
					className={styles.learnMore}
					style={{ marginRight: hideCloseButton ? 4 : 0 }}
				>
					Learn more
				</Link>
			)}
			{!hideCloseButton && (
				<GenemodIcon
					onClick={onClose}
					style={{
						marginLeft: link ? 12 : "auto",
					}}
					name="circle-cancel"
				/>
			)}
		</div>
	);
};

export default AlertDialog;
