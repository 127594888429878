import { useState, useEffect } from "react";
import { SearchSettings, RepositorySearchSettings } from "@common/types";
import {
	PanelSearchSettings,
	getPanelSearchSettings,
} from "@containers/Freezer/data";
import { useItemTypesQuery } from "@redux/inventory/Item";

export const createCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export function useTableSettings({
	searchSettings,
	visible,
	defaultFields,
	saveToDB,
	selectedItemTypeID,
}: {
	searchSettings: SearchSettings | RepositorySearchSettings | undefined;
	visible: boolean;
	defaultFields: { [field_uuid: string]: string };
	saveToDB: (settings: PanelSearchSettings[]) => void;
	selectedItemTypeID?: number;
}) {
	const { data: itemTypes } = useItemTypesQuery();
	const [settings, setSettings] = useState<PanelSearchSettings[]>([]);
	const [itemOrders, setItemOrders] = useState<{
		[setting_index: number]: string;
	}>({});

	useEffect(() => {
		if (visible && itemTypes && searchSettings && !settings.length) {
			// when open the setting, init {settings} with itemTypes and searchSettings
			let temp: PanelSearchSettings[] = [];
			if (selectedItemTypeID) {
				// only show this itemType setting
				const itemType = itemTypes.find(
					(itemType) => itemType.id === selectedItemTypeID
				);
				if (itemType) {
					temp = [
						getPanelSearchSettings(
							itemType,
							searchSettings[selectedItemTypeID],
							defaultFields
						),
					];
				}
			} else {
				temp = itemTypes.map((itemType) =>
					getPanelSearchSettings(
						itemType,
						searchSettings[itemType.id],
						defaultFields
					)
				);
			}

			setSettings(temp);
			handleItemOrders(temp);
		}
	}, [searchSettings, visible, itemTypes]);

	useEffect(() => {
		if (!visible && settings.length) {
			// when close the settings
			saveRearrangedColumnOrder(settings);
			setSettings([]);
			setItemOrders({});
		}
	}, [visible, settings]);

	/**
	 * Handle current item order
	 * @param {PanelSearchSettings} settings
	 */
	const handleItemOrders = (
		settings: PanelSearchSettings[],
		setting_index?: number
	) => {
		let orders: { [setting_index: number]: string } = {};
		if (setting_index) {
			orders = { ...itemOrders };
			orders[setting_index] = settings[setting_index].column_order;
		} else {
			settings.forEach((setting: PanelSearchSettings, index: number) => {
				orders[index] = setting.column_order;
			});
		}
		setItemOrders(orders);
	};

	const handleCheckbox = (nextState: boolean, e: any) => {
		const [setting_index, field] = e.target.id.split(",");
		const new_setting = createCopy(settings[setting_index]);
		new_setting.visibles[field] = nextState;
		settings[setting_index] = new_setting;
		setSettings(settings);
		saveToDB([new_setting]);
	};

	const handleRearrange = (
		setting_index: number,
		start: number,
		end: number
	) => {
		const result = itemOrders[setting_index].split(",");
		const [removed] = result.splice(start, 1);
		result.splice(end, 0, removed);

		const copy = createCopy(settings[setting_index]);
		copy.column_order = result.join(",");
		settings[setting_index] = copy;
		setSettings(settings);
		handleItemOrders(settings, setting_index);
		saveToDB([copy]);
	};

	const handleReset = (setting_index: number) => {
		const temp = createCopy(settings[setting_index]);
		temp.column_order = temp.default_order;
		Object.keys(temp.visibles)
			.filter((field_id) => !temp.visibles[field_id])
			.forEach((field_id) => (temp.visibles[field_id] = true));
		settings[setting_index] = temp;
		setSettings(settings);
		handleItemOrders(settings);
		saveToDB([temp]);
	};

	/**
	 * the function to save rearranged columns order to the backend (move unchecked columns to the end in colum_order field)
	 * @param {PanelSearchSettings} settings - the whole settings
	 */
	const saveRearrangedColumnOrder = (settings: PanelSearchSettings[]) => {
		// Object.keys(settings).forEach((type) => {
		settings.forEach((setting: PanelSearchSettings) => {
			const checked: string[] = [];
			const unchecked: string[] = [];

			setting.column_order.split(",").forEach((field_id) => {
				if (setting.visibles[field_id]) {
					checked.push(field_id);
				} else {
					unchecked.push(field_id);
				}
			});
			setting.column_order = checked.concat(unchecked).join(",");
		});
		saveToDB(settings);
	};

	const isSettingUpdated = (setting: PanelSearchSettings) => {
		return (
			setting.default_order !== setting.column_order ||
			Object.keys(setting.visibles).find(
				(field_id) => !setting.visibles[field_id]
			)
		);
	};

	return {
		settings,
		handleCheckbox,
		handleRearrange,
		handleReset,
		saveRearrangedColumnOrder,
		isSettingUpdated,
	};
}
