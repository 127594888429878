import React, { useState } from "react";
import styles from "./AttachmentsTab.module.scss";
import {
	Button,
	DropDown,
	GenemodIcon,
	ResponsiveTable,
	UserAvatar,
	Typography,
	Notification,
	CommonDeleteModal,
} from "@common/components";
import { ResponsiveTableColumns } from "@common/components/Table/ResponsiveTable";
import { getFileTypeIcon } from "@common/components/FileIcon";
import UploadAttachmentField from "./components/UploadAttachmentField";
import {
	useOrderAttachmentsDeleteMutation,
	useOrderAttachmentsQuery,
} from "@redux/freezer/FreezerApiSlice";
import { FreezerOrder, FreezerOrderAttachment } from "@common/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { PermanentDeleteModal } from "@containers/ProjectManagement/components/PMModals/PMModals";
import { MenuProps } from "antdv4";
import { AttachmentPreview } from "@components";

type Props = {
	order: FreezerOrder | undefined;
};

const AttachmentsTab = ({ order }: Props) => {
	const [deleteAttachment] = useOrderAttachmentsDeleteMutation();
	const [previewAttachment, setPreviewAttachment] =
		useState<null | FreezerOrderAttachment>(null);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [attachmentToDelete, setAttachmentToDelete] =
		useState<null | FreezerOrderAttachment>(null);
	const [showUploadingButton, setShowUploadButton] = useState(false);
	const { data: attachments = [] } = useOrderAttachmentsQuery(
		order?.id || skipToken
	);

	const downloadAttachment = (attachment: FreezerOrderAttachment) =>
		window.open(attachment.upload);

	const onDeleteClick = (attachment: FreezerOrderAttachment) => {
		setIsDeleteModalVisible(true);
		setAttachmentToDelete(attachment);
	};

	const getItems = (row: FreezerOrderAttachment) => {
		const items: MenuProps["items"] = [
			{
				key: "download",
				label: "Download",
				onClick: () => downloadAttachment(row),
			},
		];

		if (!order?.is_archived) {
			items.push({
				key: "delete",
				label: "Delete",
				onClick: () => onDeleteClick(row),
			});
		}
		return items;
	};

	const columns: ResponsiveTableColumns<FreezerOrderAttachment>[] = [
		{
			key: "file",
			title: "File",
			dataIndex: "name",
			render: (name, row) => {
				const Icon = getFileTypeIcon(row.file_type);
				return (
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "24px 1fr",
							gap: 14,
							alignItems: "center",
						}}
					>
						<Icon style={{ width: "100%" }} />
						<Typography ellipsis color="text-primary">
							{name}
						</Typography>
					</div>
				);
			},
			ellipsis: true,
			width: 162,
		},
		{
			key: "uploaded_by",
			title: "Uploaded by",
			dataIndex: "created_by",
			render: (created_by) => (
				<UserAvatar user={created_by} displayName />
			),
			ellipsis: true,
			width: 162,
		},
		{
			key: "actions",
			render: (_, row) => {
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<DropDown
							menu={{
								items: getItems(row),
							}}
						>
							<GenemodIcon name="meatballs" size="large" />
						</DropDown>
					</div>
				);
			},
		},
	];

	const renderUploadField = () => {
		if (order?.is_archived) return <></>;
		if (showUploadingButton || attachments.length === 0) {
			return (
				<div>
					<UploadAttachmentField
						order={order}
						style={{ marginTop: 12 }}
					/>
				</div>
			);
		}

		return (
			<Button type="link" onClick={() => setShowUploadButton(true)}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: 8,
						marginTop: 12,
					}}
				>
					<GenemodIcon
						name="upload"
						color="link-primary"
						fill="link-primary"
					/>
					Upload
				</div>
			</Button>
		);
	};

	return (
		<div className={styles.attachmentTab}>
			{attachments.length > 0 && (
				<ResponsiveTable
					columns={columns}
					dataSource={attachments}
					onRowClick={setPreviewAttachment}
				/>
			)}
			{renderUploadField()}
			<AttachmentPreview
				visible={!!previewAttachment}
				attachment={previewAttachment}
				onClose={() => setPreviewAttachment(null)}
				onDelete={() =>
					previewAttachment && onDeleteClick(previewAttachment)
				}
				onDownload={() =>
					previewAttachment && downloadAttachment(previewAttachment)
				}
			/>
			<CommonDeleteModal
				visible={isDeleteModalVisible}
				titleObject="attachment"
				bodyObject="this attachment"
				onOk={() => {
					if (!attachmentToDelete) return;
					deleteAttachment(attachmentToDelete.id)
						.then(() => {
							Notification.success({
								message: "Attachment deleted successfully.",
							});
						})
						.catch(() => {
							Notification.warning({
								message:
									"Failed to delete attachment. Please try again.",
							});
						})
						.finally(() => setIsDeleteModalVisible(false));
				}}
				onCancel={() => setIsDeleteModalVisible(false)}
			/>
		</div>
	);
};

export default AttachmentsTab;
