import API from "@API";
import {
	Avatar,
	Box,
	ConsumableSearchItem,
	Freezer,
	ItemGroup,
	NewFeaturePopOver,
	Notification,
	NotificationApps,
	NotificationSettings,
	NotificationSourceType,
	NPSFeedback,
	NPSStatus,
	OrganizationUserId,
	PageNumberPaginationParams,
	PageNumberPaginationResponse,
	PaginatedSearchResults,
	ProjectProps,
	QuickAccessItem,
	Space,
	WhatsNewPost,
	WorkspaceUser,
} from "@common/types";
import { ExperimentFile } from "@common/types/Folder";
import { ContactUsFormData } from "@containers/ContactUs/components/ContactUsForm";
import { Point } from "@containers/Dashboard/components/widgets/freezer_overview";
import { AtLeast, flattenUnion, IdsInConstType } from "@helpers/TypeHelpers";
import { WorkspaceUserPaginatedSearchQuery } from "@redux/AdminEnterprise/adminEnterpriseApiSlices";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

const MAX_QUICK_ACCESS_ITEMS = 6;
const TAG_TYPES = [
	"NPSStatus",
	"QuickAccessItems",
	"Notifications",
	"NotificationSettings",
	"WhatsNewPost",
	"WorkspaceUser",
] as const;

type NPSFeedbackDTO = {
	id: number;
	created_at: string;
	feedback: string;
	owner: Avatar;
	score: number;
};

type QuickAccessItemPayload = {
	type: QuickAccessItem["type"];
	freezer: number;
	box: number;
	item_group: number;
	space: number;
	consumables_category: number;
	project: number;
	experiment_file: number;
};

export interface QuickAccessInterface {
	freezer: Freezer;
	box: Box;
	item_group: ItemGroup;
	space: Space;
	consumables_category: ConsumableSearchItem;
	project: ProjectProps;
	experiment_file: ExperimentFile;
}

export type NotificationFilter = PageNumberPaginationParams & {
	read?: boolean;
	notification_type?: IdsInConstType<typeof NotificationApps>;
};
export type NotificationPayload = Omit<
	Notification,
	"created_at" | "sender" | "read" | "id" | "receiver"
> & {
	receiver: number;
};

const quickAccessIsBad = (dto: Partial<QuickAccessInterface>) => {
	try {
		return (
			!dto.freezer &&
			!dto.box &&
			!dto.item_group &&
			!dto.space &&
			!dto.consumables_category &&
			!dto.project &&
			!dto.experiment_file
		);
	} catch (err) {
		return true;
	}
};

const filterBadInstances = (dto: QuickAccessItem[]): QuickAccessItem[] => {
	try {
		return dto.filter((qa) => !quickAccessIsBad(flattenUnion(qa)));
	} catch (err) {
		return [];
	}
};

type ContactUsResponse = {
	detail: string;
};

type FetchItemProps = {
	obj: string;
	obj_id?: number;
	date_range: string;
};

export const dashboardApi = createApi({
	reducerPath: "dashboardApi",
	baseQuery: genemodBaseQuery({ baseUrl: "" }),
	tagTypes: TAG_TYPES,
	endpoints: (builder) => {
		const nps = {
			feedbackCreate: builder.mutation<NPSFeedbackDTO, NPSFeedback>({
				query: (body) => ({
					url: `nps/submissions/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["NPSStatus"],
				extraOptions: { maxRetries: 0 },
			}),
			npsStatus: builder.query<NPSStatus, number>({
				query: (userId) => `/nps/submission-status/${userId}/`,
				providesTags: ["NPSStatus"],
				extraOptions: {
					maxRetries: 0,
				},
				keepUnusedDataFor: 0,
			}),
			npsStatusPatch: builder.mutation<
				NPSStatus,
				{ userId: number; status: NPSStatus["status"] }
			>({
				query: ({ userId, status }) => ({
					url: `/nps/submission-status/${userId}/`,
					method: "PATCH",
					body: {
						status,
					},
				}),
				invalidatesTags: ["NPSStatus"],
				extraOptions: { maxRetries: 0 },
			}),
		} as const;
		const quickAccessItem = {
			quickAccessItems: builder.query<QuickAccessItem[], void>({
				query: () => ({
					url: `/dashboard/api/quick-access/`,
					method: "GET",
					params: {
						page_size: MAX_QUICK_ACCESS_ITEMS,
					},
				}),
				providesTags: ["QuickAccessItems"],
				extraOptions: {
					maxRetries: 0,
				},
				keepUnusedDataFor: 0,
				transformResponse: (
					response: PaginatedSearchResults<QuickAccessItem>
				) => filterBadInstances(response.results),
			}),
			quickAccessItemCreate: builder.mutation<
				QuickAccessItem,
				AtLeast<QuickAccessItemPayload, "type">
			>({
				query: (body) => ({
					url: `/dashboard/api/quick-access/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["QuickAccessItems"],
				extraOptions: { maxRetries: 0 },
			}),
		} as const;
		const contactUs = {
			contactUsSend: builder.mutation<
				ContactUsResponse,
				ContactUsFormData
			>({
				query: (body) => ({
					url: `/send-support-email/`,
					method: "POST",
					body,
				}),
			}),
		} as const;

		const notification = {
			notifications: builder.query<
				PageNumberPaginationResponse<Notification>,
				NotificationFilter
			>({
				query: (params) => ({
					url: `/notifications/`,
					method: "GET",
					params,
				}),
				providesTags: ["Notifications"],
				keepUnusedDataFor: 0,
			}),
			notificationCreate: builder.mutation<
				Notification,
				NotificationPayload
			>({
				query: (body) => ({
					url: `/notifications/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["Notifications"],
				extraOptions: { maxRetries: 0 },
			}),
			notificationPatch: builder.mutation<
				Notification,
				AtLeast<Notification, "id">
			>({
				query: (body) => ({
					url: `/notifications/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["Notifications"],
				extraOptions: { maxRetries: 0 },
			}),
			notificationsMarkAllRead: builder.mutation<void, void>({
				query: (body) => ({
					url: `/notifications/mark-all-read/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["Notifications"],
				extraOptions: { maxRetries: 0 },
			}),
			notificationsCount: builder.query<number, void>({
				query: () => ({
					url: `/notifications/count/`,
					method: "GET",
				}),
				providesTags: ["Notifications"],
			}),
			notificationSettings: builder.query<
				NotificationSettings,
				OrganizationUserId
			>({
				query: (org_user) => ({
					url: `/notifications/settings/${org_user}/`,
					method: "GET",
				}),
				providesTags: ["NotificationSettings"],
			}),
			patchNotificationSettings: builder.mutation<
				NotificationSettings,
				{
					org_user: OrganizationUserId;
					body: Partial<NotificationSettings>;
				}
			>({
				query: ({ org_user, body }) => ({
					url: `/notifications/settings/${org_user}/`,
					body,
					method: "PATCH",
				}),
				invalidatesTags: ["NotificationSettings"],
			}),
			enableAllNotificationSettings: builder.mutation<
				NotificationSettings,
				void
			>({
				query: () => ({
					url: `/notifications/settings/enable-all/`,
					method: "POST",
				}),
				invalidatesTags: ["NotificationSettings"],
			}),
			disableAllNotificationSettings: builder.mutation<
				NotificationSettings,
				void
			>({
				query: () => ({
					url: `/notifications/settings/disable-all/`,
					method: "POST",
				}),
				invalidatesTags: ["NotificationSettings"],
			}),
		} as const;

		const whatsNewUpdates = {
			whatsNewUpdates: builder.query<WhatsNewPost[], void>({
				query: (params) => ({
					url: `/update/api/whats-new/`,
					method: "GET",
					params,
				}),
				providesTags: ["WhatsNewPost"],
			}),
			markWhatsNewAsRead: builder.mutation<void, { id: number }>({
				query: ({ id }) => ({
					url: `/update/api/whats-new/${id}/mark-read/`,
					method: "POST",
				}),
				invalidatesTags: ["WhatsNewPost"],
			}),
		};

		const teamMembers = {
			getTeamMembers: builder.query<
				PaginatedSearchResults<WorkspaceUser>,
				WorkspaceUserPaginatedSearchQuery
			>({
				query: (params) => ({
					url: `/org/v2/workspace-user/`,
					method: "GET",
					params,
				}),
				extraOptions: { maxRetries: 0 },
				providesTags: ["WorkspaceUser"],
			}),
		};

		const fetchItemHistory = {
			fetchItem: builder.query<
				{
					items_created: Point[];
					items_total: Point[];
				},
				FetchItemProps
			>({
				query: (params) => ({
					url: `${API.freezerV2.analytics.itemsCreated()}/`,
					method: "GET",
					params,
				}),
			}),
		};

		const updates = {
			getLastTen: builder.query<any, void>({
				query: () => "updates/last-ten",
			}),
		};

		const newFeature = {
			newFeatures: builder.query<NewFeaturePopOver[], void>({
				query: (params) => ({
					url: `/update/api/new-feature/`,
					method: "GET",
					params,
				}),
			}),
			newFeatureSeen: builder.mutation<void, { id: number }>({
				query: ({ id }) => ({
					url: `/update/api/new-feature/${id}/seen/`,
					method: "POST",
				}),
			}),
		};

		return {
			...nps,
			...quickAccessItem,
			...contactUs,
			...notification,
			...whatsNewUpdates,
			...teamMembers,
			...fetchItemHistory,
			...updates,
			...newFeature,
		};
	},
});

export const {
	useFeedbackCreateMutation,
	useNpsStatusQuery,
	useNpsStatusPatchMutation,
	useQuickAccessItemsQuery,
	useQuickAccessItemCreateMutation,
	useContactUsSendMutation,
	useNotificationsQuery,
	useNotificationCreateMutation,
	useNotificationPatchMutation,
	useNotificationsMarkAllReadMutation,
	useNotificationsCountQuery,
	useWhatsNewUpdatesQuery,
	useMarkWhatsNewAsReadMutation,
	useGetTeamMembersQuery,
	useLazyGetTeamMembersQuery,
	useFetchItemQuery,
	useGetLastTenQuery,
	useLazyGetLastTenQuery,
	useNotificationSettingsQuery,
	usePatchNotificationSettingsMutation,
	useDisableAllNotificationSettingsMutation,
	useEnableAllNotificationSettingsMutation,

	// NewFeatures
	useNewFeaturesQuery,
	useNewFeatureSeenMutation,
} = dashboardApi;

export const useNotification = (
	source: NotificationSourceType
): ((payload: Omit<NotificationPayload, "source">) => any) => {
	const [createNotification] = useNotificationCreateMutation();
	return (payload: Omit<NotificationPayload, "source">) =>
		createNotification({ source, ...payload });
};

export const invalidateNotifications = dashboardApi.util.invalidateTags([
	"Notifications",
]);
