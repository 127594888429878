import {
	Button,
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodLink,
	Typography,
} from "@components";
import { useCurrentPlanNameHook } from "@helpers/Hooks/featureRestrictionHook";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import classNames from "classnames";
import React from "react";
import styles from "./UpgradeButton.module.scss";

type UpgradeButtonProps = {
	type?: "stars" | "tag" | "icon";
	text?: string;
	className?: string;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	stretch?: boolean;
	style?: React.CSSProperties;
	enterpriseGhost?: boolean;
	defaultBusiness?: boolean;
};

export default function UpgradeButton({
	type = "stars",
	text = "Upgrade",
	className,
	onClick,
	stretch = false,
	style,
	enterpriseGhost = false,
	defaultBusiness = false,
}: UpgradeButtonProps): JSX.Element {
	const { planName } = useCurrentPlanNameHook();
	const { openChangePlanModal } = useCommonModalState("changePlanModal");
	const { closeUpgradeModal } = useCommonModalState("upgradeModal");

	const defaultOnClick: React.MouseEventHandler<HTMLDivElement> = () =>
		openChangePlanModal({
			defaultBusiness,
		});

	const isEnterprise = planName === "enterprise";
	if (isEnterprise) {
		return (
			<GenemodLink to="ACCOUNT_SUBSCRIPTION" onClick={closeUpgradeModal}>
				<Button
					size="small"
					type={enterpriseGhost ? "ghost" : "primary"}
					dataCy="upgrade-button"
				>
					Go to subscription
				</Button>
			</GenemodLink>
		);
	}

	if (type === "stars") {
		return (
			<Button
				className={className}
				size="small"
				icon="stars"
				stretch={stretch}
				onClick={(onClick || defaultOnClick) as unknown as any}
				style={style}
				dataCy="upgrade-button"
			>
				{text}
			</Button>
		);
	}

	return (
		<div
			className={classNames(styles.upgradeTag, className)}
			onClick={onClick || defaultOnClick}
			style={style}
			data-cy="upgrade-button"
		>
			<Typography
				variant="caption"
				className={styles.tagLabel}
				color="button-primary"
			>
				Upgrade
			</Typography>
		</div>
	);
}

export function UPGRADEBUTTON_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Upgrade buttons">
					<div className={styles.upgradeButtonDemo}>
						<UpgradeButton type="stars" text="Try for free" />
						<UpgradeButton type="stars" stretch />
						<UpgradeButton type="tag" />
						<UpgradeButton type="icon" />
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
