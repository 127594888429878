import { Category, ISOString, TableItem, Avatar } from ".";
import { ShelfLocation } from "./ShelfLocation";

export type RackLocationData = {
	freezer_name: string;
	freezer_is_archived?: boolean;
	shelf_name: string;
	shelf_is_archived?: boolean;
};

/** Type data for `Rack`/`Category` */
export type Rack = {
	/** ID of the object */
	id: number;
	/** Name of the object */
	name: string;
	/** Number of rows */
	rows: number;
	/** Number of columns */
	columns: number;
	/** Number of items within this Rack */
	item_count: number;
	/** True if the Freezer that this Rack is in is archived */
	is_archived: boolean;
	is_locked: boolean;
	owner: number;
	created_at: ISOString;
	updated_at: ISOString;
	location: ShelfLocation;
	location_data: RackLocationData;
	is_category: false;
	num_boxes: number;
	rack_size: number;
	availability: number;
	created_by?: number | Avatar;
	updated_by?: number | Avatar;
	status?: number;
	freezer?: number;
};

/** Type data for sending new rack data to the backend */
export type RackDataForBackend = Pick<Rack, "name" | "rows" | "columns"> & {
	id?: number;
	location?: {
		freezer?: number;
		shelf: number;
		index: number;
	};
};

type AvailableItem = {
	name: string;
	available_items: number | null;
};
type ItemSummary = {
	name: string;
	items: number;
};

type AvailableSpace = AvailableItem & {
	available_boxes: AvailableItem[];
};

type StorageSummary = ItemSummary & {
	boxes: ItemSummary[];
};

export type RackStorageSummary = {
	available_space: AvailableSpace[];
	storage_summary: StorageSummary[];
};

export const isRack = (obj: Rack | Category): obj is Rack => {
	return (obj as Rack).rows !== undefined;
};
export function isRackFromTableItem(tableItem: TableItem): tableItem is Rack {
	return "location" in tableItem;
}
