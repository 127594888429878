import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import { Link, useRouteMatch } from "@common/helpers/Hooks/UseRouterDom";
import ADMIN_ONLY_ICON from "@common/icons/admin-only.svg";
import {
	GenemodIcon,
	LayerSystemContainer,
	SVG,
	Typography,
	UpgradeButton,
} from "@components";
import { useCurrentPlanNameHook } from "@helpers/Hooks/featureRestrictionHook";
import useCurrentTeamMembers from "@helpers/Hooks/useCurrentTeamMembersHooks";
import { useOrganizationRouter } from "@root/AppRouter";
import cn from "classnames";
import React from "react";
import { PlanName } from "../../types/FeatureRestriction";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";
import styles from "./index.module.scss";

export function ProductMenuWrapper({
	children,
	style,
}: {
	children?: React.ReactNode;
	style?: React.CSSProperties;
}) {
	return (
		<LayerSystemContainer
			className={styles.menuWrapper}
			overrideLayer="background"
			style={style}
		>
			<div className={styles.menuContents}>{children}</div>
		</LayerSystemContainer>
	);
}

export type ProductMenuItemProps = {
	icon: IconName;
	title: string;
	route: string;
	/**
	 * Menu item is visible but disabled unless the user's team is on a
	 * paid plan.
	 */
	paidFeature?: boolean;
	/**
	 * Only **organization** admins can see this menu item.
	 */
	adminOnly?: boolean;
	/**
	 * Specific plans that the item is available to.
	 */
	allowedPlans?: PlanName[];
	/**
	 * Check if the user is allowed to see this menu item and render an upgrade button
	 */
	showNotAllowed?: boolean;
	dataCy?: string;
};
export function ProductMenuItem({
	icon,
	title,
	route,
	paidFeature = false,
	adminOnly = false,
	allowedPlans,
	dataCy,
	showNotAllowed = false,
}: ProductMenuItemProps) {
	const { appendBaseUrl } = useOrganizationRouter();
	const routeMatch = useRouteMatch(appendBaseUrl(route));
	const selected = !!routeMatch;

	const { planName: planNameRaw, isLoading } = useCurrentPlanNameHook();
	const planName = (planNameRaw || "free").toLowerCase() as PlanName;
	const { currentUserAsTeamMember: user } = useCurrentTeamMembers();

	if (
		(adminOnly && !user?.is_admin) ||
		(allowedPlans && !allowedPlans.includes(planName) && !showNotAllowed)
	) {
		return <></>;
	}

	return (
		<Link
			to={appendBaseUrl(route)}
			onClick={() => {
				if (title === "Repository") {
					SegmentTrackEvent(SegmentFreezerEvents.REPOSITORY_VIEW, {});
				}
			}}
			data-cy={dataCy}
		>
			<div
				className={cn(styles.menuItem, {
					[styles.menuItem__selected]: selected,
				})}
			>
				<div className={styles.menuItemContent}>
					<GenemodIcon
						name={icon}
						size="large"
						color={selected ? "brand-08" : "text-secondary-v2"}
					/>
					<Typography
						variant="subheadline"
						color={selected ? "brand-08" : "text-secondary-v2"}
					>
						{title}
					</Typography>
					{!isLoading &&
					((paidFeature && planName === "free") ||
						(paidFeature &&
							allowedPlans &&
							!allowedPlans.includes(planName))) ? (
						<UpgradeButton type="tag" />
					) : null}
				</div>
				{adminOnly && <SVG src={ADMIN_ONLY_ICON} />}
			</div>
		</Link>
	);
}

export function ProductMenuTitle(
	props: React.ComponentProps<typeof Typography>
) {
	return (
		<Typography
			{...props}
			variant="headline2"
			className={cn(styles.menuTitle, props.className)}
		/>
	);
}

export function ProductMenuSubtitle(
	props: React.ComponentProps<typeof Typography>
) {
	return (
		<Typography
			{...props}
			variant="subheadline"
			bold
			className={cn(styles.menuSubtitle, props.className)}
		/>
	);
}
