import { OrganizationUserId } from "@common/types";
import { NominalId } from "@helpers/TypeHelpers";
import moment from "moment";

export type UserId = NominalId<"CustomUserId">;

/**
 * "CustomUser" type
 */
export type User = {
	id: UserId;
	email: string;
	first_name: string;
	last_name: string;
	job_title: string;
	email_verified: boolean;
	last_seen_updates: number;
	notify_for_updates: boolean;
	avatar?: Avatar;
	date_joined: string;
	profile_picture: string;
	institution: string;
	first_login: boolean;
};

/**
 * Type for avatar data
 */
export type Avatar = {
	id?: UserId;
	first_name: string;
	last_name: string;
	profile_picture?: string;
};

/**
 * Type for avatar data
 */
export type TeamMemberAvatar = {
	/** member id */
	id: OrganizationUserId;
	user: Avatar;
};

/**
 * Helper function to return full name of the Avatar
 * @param avatar
 */
export function getFullNameFromAvatar(avatar: Avatar) {
	return `${avatar?.first_name} ${avatar?.last_name}`;
}

export const getDefaultUser = (overrides?: Partial<User>): User => ({
	id: -1 as UserId,
	first_name: "",
	last_name: "",
	email: "",
	institution: "",
	job_title: "",
	email_verified: false,
	profile_picture: "",
	date_joined: moment().toString(),
	avatar: undefined,
	first_login: true,
	notify_for_updates: false,
	last_seen_updates: 0,
	...(overrides || {}),
});
