import API from "@API";
import {
	Organization,
	OrganizationUser,
	Workspace,
	WorkspaceForUser,
	WorkspaceId,
	WorkspaceUser,
	WorkspaceUserId,
	WorkspaceUserRole,
} from "@common/types";
import {
	AllPlanRestrictions,
	PlanName,
	PlanRestrictionData,
	RestrictionTypes,
	SingleFeatureRestrictionData,
} from "@common/types/FeatureRestriction";
import { PaginatedSearchResults } from "@common/types/Search";
import { StripeKeys } from "@common/types/Stripe";
import { AtLeastId } from "@helpers/TypeHelpers";
import { WorkspaceUserPaginatedSearchQuery } from "@redux/AdminEnterprise/adminEnterpriseApiSlices";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const CURRENT_PRICES = [
	StripeKeys.prices.businessAnnual,
	StripeKeys.prices.teamAnnual,
	StripeKeys.prices.teamMonthly,
];

export const teamApi = createApi({
	reducerPath: "teamApi",
	baseQuery: genemodBaseQuery({ baseUrl: "" }),
	tagTypes: [
		"Workspaces",
		"Organizations",
		"WorkspaceUsers",
		"OrgUsers",
		"Subscription",
		"Subscription plans",
		"Invoices",
		"Customer",
		"FeatureRestrictions",
	],
	endpoints: (builder) => {
		const org = {
			/**
			 * Fetch organizations available to the user.
			 */
			getOrganizations: builder.query<Organization[], void>({
				query: () => `/org/v2/`,
				providesTags: ["Organizations"],
			}),
			saveOrganization: builder.mutation<
				Organization,
				{ name: string; timezone: string; institution: string }
			>({
				query: (body) => ({
					url: "/org/v2/",
					method: "POST",
					body,
				}),
			}),
			patchOrganization: builder.mutation<
				Organization,
				AtLeastId<Organization>
			>({
				query: (body) => ({
					url: `/org/v2/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["Organizations"],
			}),
		} as const;

		const workspace = {
			/**
			 * Fetch workspaces available to the user. Workspace users are nested.
			 */
			getWorkspaces: builder.query<
				Workspace[],
				{ get_all?: boolean } | void
			>({
				query: (params) => ({
					url: `/org/v2/workspaces/`,
					method: "GET",
					params,
				}),
				providesTags: ["Workspaces"],
			}),
			getPersonalWorkspaces: builder.query<
				WorkspaceForUser[],
				{ get_all?: boolean } | void
			>({
				query: () => ({
					url: `/org/v2/user-workspaces/`,
					method: "GET",
				}),
				providesTags: ["Workspaces"],
			}),
			getSpecificWorkspace: builder.query<Workspace, WorkspaceId>({
				query: (id) => ({
					url: `/org/v2/workspaces/${id}/`,
					method: "GET",
				}),
				extraOptions: { maxRetries: 0 },
				providesTags: ["Workspaces"],
			}),
			patchWorkspace: builder.mutation<Workspace, AtLeastId<Workspace>>({
				query: (body) => ({
					url: `/org/v2/workspace/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["Workspaces"],
			}),
		} as const;

		const orgUsers = {
			getOrgUsers: builder.query<OrganizationUser[], number>({
				query: (orgId) => ({
					url: `/org/v2/${orgId}/org-users/`,
					method: "GET",
				}),
				extraOptions: { maxRetries: 0 },
				providesTags: ["OrgUsers"],
			}),
			checkEmail: builder.mutation<
				any,
				{
					orgId: number;
					emails: { email: string; workspaces?: WorkspaceId[] }[];
				}
			>({
				query: ({ orgId, emails }) => ({
					url: `/org/v2/${orgId}/check-invite-email/`,
					method: "POST",
					body: emails,
				}),
			}),
			inviteUsers: builder.mutation<
				Array<OrganizationUser>,
				{
					orgId: number;
					request_list: {
						is_admin?: boolean;
						email: string;
						workspaces: WorkspaceId[];
					}[];
				}
			>({
				query: ({ orgId, request_list }) => ({
					url: `/org/v2/${orgId}/org-users/`,
					method: "POST",
					body: [...request_list],
					headers: {
						"Genemod-OrganizationId": orgId,
					},
				}),
				invalidatesTags: ["OrgUsers", "WorkspaceUsers"],
			}),
			cancelInvitation: builder.mutation<
				void,
				{ teamId: number; userId: number }
			>({
				query: ({ userId, teamId }) => ({
					url: `/org/v2/${teamId}/org-users/${userId}/cancel-invite/`,
					method: "DELETE",
				}),
				invalidatesTags: ["OrgUsers"],
			}),
			transferOwnership: builder.mutation<
				void,
				{ teamId: number; userId: number }
			>({
				query: ({ teamId, userId }) => ({
					url: `/org/v2/${teamId}/org-users/${userId}/transfer-ownership/`,
					method: "PUT",
				}),
				invalidatesTags: ["OrgUsers", "WorkspaceUsers"],
			}),
			userReactiveOrAssignAdmin: builder.mutation<
				void,
				{
					orgId: number;
					userId: number;
					changedRequest:
						| {
								is_admin: boolean;
						  }
						| {
								status: string;
						  };
				}
			>({
				query: ({ orgId, userId, changedRequest }) => ({
					url: `/org/v2/${orgId}/org-users/${userId}/`,
					method: "PATCH",
					body: { ...changedRequest },
				}),
				invalidatesTags: [
					"OrgUsers",
					"WorkspaceUsers",
					"FeatureRestrictions",
				],
			}),
		};

		const workspaceusers = {
			getWorkspaceUsers: builder.query<
				PaginatedSearchResults<WorkspaceUser>,
				WorkspaceUserPaginatedSearchQuery
			>({
				query: (params) => ({
					url: `/org/v2/workspace-user/`,
					method: "GET",
					params,
				}),
				extraOptions: { maxRetries: 0 },
				providesTags: ["WorkspaceUsers"],
			}),
			removeWorkspaceuser: builder.mutation<void, WorkspaceUserId>({
				query: (id) => ({
					url: `/org/v2/workspace-user/${id}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["WorkspaceUsers"],
			}),
			patchWorkspaceUser: builder.mutation<
				WorkspaceUser,
				AtLeastId<WorkspaceUser>
			>({
				query: (body) => ({
					url: `/org/v2/workspace-user/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["WorkspaceUsers"],
			}),
			sendInvitations: builder.mutation<
				any,
				{
					orgId: number;
					body: {
						is_admin?: boolean;
						email: string;
						workspaces: WorkspaceId[];
						workspace_role: WorkspaceUserRole;
					}[];
				}
			>({
				query: ({ orgId, body }) => ({
					url: `/org/v2/${orgId}/org-users/`,
					method: "POST",
					body,
				}),
				invalidatesTags: ["WorkspaceUsers"],
			}),
			resendVerification: builder.mutation<void, void>({
				query: () => ({
					url: "/rest-auth/registration/resend-verification-email/",
					method: "POST",
				}),
			}),
		};

		const usageAnalysis = {
			getUsageAnalysis: builder.query<any, number>({
				query: (orgId) => ({
					url: `${API.org2.get(orgId).usage_analysis.getRoute()}/`,
					method: "GET",
				}),
			}),
		} as const;

		const featureRestriction = {
			getAllFeatureRestrictionLimits: builder.query<
				AllPlanRestrictions,
				void
			>({
				query: () => ({
					url: `${API.org2.stripe().featureRestrictionLimits()}/`,
					method: "GET",
				}),
			}),
			getFeatureRestriction: builder.query<PlanRestrictionData, number>({
				query: (orgId) => ({
					url: `${API.org2
						.get(orgId)
						.feature_restriction.getRoute()}/`,
					method: "GET",
				}),
				providesTags: ["FeatureRestrictions"],
			}),
			getFeatureRestrictionByFeature: builder.query<
				SingleFeatureRestrictionData,
				{ orgId: number; featureName: RestrictionTypes }
			>({
				query: ({ orgId, featureName }) => ({
					url: `${API.org2
						.get(orgId)
						.feature_restriction.getRoute()}/${featureName}/`,
					method: "GET",
				}),
				providesTags: ["FeatureRestrictions"],
			}),
			getPlanName: builder.query<{ plan_name: PlanName }, number>({
				query: (orgId) => ({
					url: `${API.org2.get(orgId).plan_name.getRoute()}/`,
					method: "GET",
				}),
				providesTags: ["FeatureRestrictions"],
			}),
		} as const;

		return {
			...org,
			...orgUsers,
			...workspace,
			...workspaceusers,
			...usageAnalysis,
			...featureRestriction,
		};
	},
});

export const {
	// Organizations
	useGetOrgUsersQuery,
	useLazyGetOrgUsersQuery,
	useGetOrganizationsQuery,
	usePatchOrganizationMutation,
	useSaveOrganizationMutation,
	useLazyGetOrganizationsQuery,

	// Workspaces
	useGetWorkspacesQuery,
	useGetPersonalWorkspacesQuery,
	useLazyGetWorkspacesQuery,
	useGetSpecificWorkspaceQuery,
	useGetWorkspaceUsersQuery,
	useRemoveWorkspaceuserMutation,
	usePatchWorkspaceUserMutation,
	usePatchWorkspaceMutation,
	useSendInvitationsMutation,
	useResendVerificationMutation,
	useCheckEmailMutation,
	useInviteUsersMutation,
	useCancelInvitationMutation,
	useTransferOwnershipMutation,
	useUserReactiveOrAssignAdminMutation,

	// Analysis
	useGetUsageAnalysisQuery,

	// Plan Nane
	useGetPlanNameQuery,

	// Feature restrictions
	useGetFeatureRestrictionQuery,
	useGetAllFeatureRestrictionLimitsQuery,
	useLazyGetFeatureRestrictionQuery,
	useGetFeatureRestrictionByFeatureQuery,
	useLazyGetFeatureRestrictionByFeatureQuery,
} = teamApi;
