import { ProductMenuItemProps } from "@common/components/ProductMenu";
import { FREEZER_PATHS } from "@root/routes";

export const INVENTORY_MENU_ITEMS: readonly ProductMenuItemProps[] = [
	{
		route: FREEZER_PATHS.HOME,
		title: "Freezers",
		icon: "freezer",
		dataCy: "active-freezers",
	},
	{
		route: FREEZER_PATHS.CONSUMABLE,
		title: "Consumables",
		icon: "consumable",
		paidFeature: true,
		dataCy: "consumables",
	},
	{
		route: FREEZER_PATHS.ORDERS,
		title: "Orders",
		icon: "box",
		paidFeature: true,
		dataCy: "inventory-orders",
	},
	{
		route: FREEZER_PATHS.STOCK_TRACKER,
		title: "Stock tracker",
		icon: "stock",
		dataCy: "stock-tracker",
	},
	{
		route: FREEZER_PATHS.REPOSITORY,
		title: "Repository",
		icon: "repository",
		dataCy: "repository",
		paidFeature: true,
	},
	{
		route: FREEZER_PATHS.ARCHIVE,
		title: "Archive",
		icon: "archive",
		dataCy: "inventory-archive",
	},
] as const;
