import { DocumentImageUUID, BaseDocument } from "@common/types";

export type DocumentImage = BaseDocument & {
	document_type: "ExperimentUpload";
	/** uuid of the image  */
	uuid: DocumentImageUUID;
	/** uploaded image url */
	upload: string;
};

export type DocumentImageDto = Exclude<DocumentImage, "name">;

export const documentImageFromDto = (dto: DocumentImageDto): DocumentImage => ({
	...dto,
	name: new URL(dto.upload).pathname.split("/").pop() || dto.uuid,
});
