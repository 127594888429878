// Highest level parent container
import ThemeProvider from "@common/components/Theme/context/ThemeContext";
import { ColorCssVarMap } from "@common/styles/Colors";
import { Typography } from "@components";
import { LoginProvider } from "@containers/Auth/LoginInterfaces/LoginContext";
import { NarrowOnboarding } from "@containers/Auth/components";
import { history, store } from "@redux/store";
import PublicRoutes from "@root/router";
import { ConfigProvider } from "antdv5";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import "./App.scss";

function App(): JSX.Element {
	const maintenance = process.env.REACT_APP_SERVER_STATUS !== "live";

	// Using {...{history}} to avoid type conflicts with the PublicRoutes component
	return (
		<ConfigProvider
			theme={{
				components: {
					Segmented: {
						colorBgLayout: "var(--layer-accent-01)",
						borderRadius: 4,
					},
					Tabs: {
						colorPrimary: ColorCssVarMap["border-active"],
					},
				},
			}}
		>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<LoginProvider>
						<ThemeProvider>
							<div id="genemod">
								{maintenance ? (
									<Maintenance />
								) : (
									<PublicRoutes />
								)}
							</div>
						</ThemeProvider>
					</LoginProvider>
				</ConnectedRouter>
			</Provider>
		</ConfigProvider>
	);
}

const SYSTEM_STATUS_URL = "https://genemod.net/system-status";
function Maintenance(): JSX.Element {
	return (
		<NarrowOnboarding>
			<Typography
				variant="title"
				bold
				style={{ marginBottom: 16, marginTop: 32 }}
			>
				Under maintenance
			</Typography>
			<Typography style={{ textAlign: "center" }}>
				Genemod is currently undergoing maintenance. Please check back
				at a later time. Visit our{" "}
				<a
					href={SYSTEM_STATUS_URL}
					style={{ color: ColorCssVarMap["link-primary"] }}
				>
					system status
				</a>{" "}
				page for more information.
			</Typography>
		</NarrowOnboarding>
	);
}

export default App;
