import React from "react";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { GenemodIcon, LayerSystemContainer, Typography } from "@components";
import styles from "./ShareButton.module.scss";
import cn from "classnames";

type ShareButtonProps = {
	className?: string;
	style?: React.CSSProperties;
};
export default function ShareButton(props: ShareButtonProps) {
	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	return (
		<LayerSystemContainer
			className={cn(styles.shareButton, props.className)}
			backgroundHover
			style={props.style}
			onClick={() => openShareLinkModal({})}
		>
			<GenemodIcon name="share" fill="text-secondary" />
			<Typography variant="label" color="text-secondary">
				Share
			</Typography>
		</LayerSystemContainer>
	);
}
