import React, { HTMLProps } from "react";
import { Typography } from "@common/components";
import { HexColorToRgbaText } from "@common/styles/Colors";
import {
	FreezerOrderStatusId,
	FREEZER_ORDER_STATUSES,
	FREEZER_ORDER_STATUSES_BY_ID,
} from "@common/types";
import classNames from "classnames";
import styles from "./StatusLabel.module.scss";

type Props = {
	status: FreezerOrderStatusId;
} & HTMLProps<HTMLDivElement>;
const StatusLabel = ({ status, ...props }: Props) => {
	const statusLabel =
		FREEZER_ORDER_STATUSES_BY_ID[status || (0 as FreezerOrderStatusId)]
			.label;

	const currentStatus =
		FREEZER_ORDER_STATUSES[
			statusLabel as keyof typeof FREEZER_ORDER_STATUSES
		];
	return (
		<div
			{...props}
			className={classNames(styles.label, props.className)}
			style={{
				backgroundColor: HexColorToRgbaText({
					color: currentStatus.color,
					opacity: 0.1,
				}),
				borderWidth: 1,
				borderStyle: "solid",
				borderColor: HexColorToRgbaText({
					color: currentStatus.color,
				}),
				...props.style,
			}}
		>
			<Typography variant="caption" color={`${currentStatus.color}`}>
				{currentStatus.label}
			</Typography>
		</div>
	);
};

export default StatusLabel;
