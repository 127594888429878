import React, { useEffect, useState } from "react";
import { GenemodIcon, Select } from "@components";
import { DEFAULT_TIMEZONE, TIME_ZONE_LIST } from "./TimeZoneData";
import moment from "moment";

/**
 * A component that show TimeZone Selector for create-team page
 */
type TimezoneProps = {
	onChange?: (val: any) => void;
};

function TimeZone({ onChange }: TimezoneProps): JSX.Element {

	// https://stackoverflow.com/a/30280636/16098999
	const getIsDST = () => {
		const now = new Date();
		const jan = new Date(now.getFullYear(), 0, 1).getTimezoneOffset();
		const jul = new Date(now.getFullYear(), 6, 1).getTimezoneOffset();
		return Math.max(jan, jul) != now.getTimezoneOffset();
	};

	const findLocalTimeZone = () => {
		const local_time_zone = moment().format("Z");
		const isDst = getIsDST();
		const tz = Object.keys(TIME_ZONE_LIST).find(
			(tz) =>
				(
					TIME_ZONE_LIST[tz as keyof typeof TIME_ZONE_LIST] as {
						name: string;
						value: string;
						dst?: string;
					}
				)[isDst ? "dst" : "value"] === local_time_zone
		);
		return tz ? tz : DEFAULT_TIMEZONE;
	};

	const [local_tz, setLocalTz] = useState(findLocalTimeZone());

	useEffect(() => {
		onChange?.(local_tz);
	}, []);

	return (
		<Select
			isInput
			suffixIcon={<GenemodIcon name="caret-down" />}
			defaultValue={local_tz}
			defaultActiveFirstOption
			onChange={onChange}
		>
			{TimezoneOptions()}
		</Select>
	);
}

/**
 * A component that returns time zone options
 * this component is reusable with different "Select" components
 *
 */
export function TimezoneOptions() {
	const { Option } = Select;
	return Object.keys(TIME_ZONE_LIST).map((tz) => (
		<Option key={tz}>
			{getTimezoneName(tz as keyof typeof TIME_ZONE_LIST)}
		</Option>
	));
}

/**
 * A function that returns timezone full name with proper format format: tz_name (GMT-diff_time)
 * this component is reusable function
 *
 * @param {String} tz timezone name in the TIME_ZONE_LIST
 * @returns {String} if tz in the time_zone_list, timezone full name
 *			else: return tz
 */
export function getTimezoneName(tz: keyof typeof TIME_ZONE_LIST) {
	if (tz in TIME_ZONE_LIST)
		return `${TIME_ZONE_LIST[tz].name} (GMT${TIME_ZONE_LIST[tz].value})`;
	return tz;
}

export default TimeZone;
