/* eslint @typescript-eslint/explicit-function-return-type: 0 */
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	QueryDefinition,
	fetchBaseQuery,
	retry,
} from "@reduxjs/toolkit/query/react";
import qs, { StringifyOptions } from "query-string";
import { axios, backendAPIUrl } from "@API";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";

const STRINGIFY_OPTIONS: StringifyOptions = { arrayFormat: "bracket" };

/**
 * Get's a "base query" that we can use in rtk query endpoints setup.
 * Sets the base url based on our configs and sets up auth/org headers.
 */
export const genemodBaseQuery = (args: FetchBaseQueryArgs) => {
	const baseUrl = backendAPIUrl + args.baseUrl + "/";
	return retry(
		async (fetchArgs, api, extraOptions) => {
			const result = await fetchBaseQuery({
				...args,
				baseUrl,
				paramsSerializer: (params) =>
					qs.stringify(params, STRINGIFY_OPTIONS),
				prepareHeaders(headers) {
					const token = axios.defaults.headers.common[
						"Authorization"
					] as string;
					const orgHeader = axios.defaults.headers.common[
						"Genemod-OrganizationId"
					] as string;
					const workspaceHeader = axios.defaults.headers.common[
						"Genemod-WorkspaceId"
					] as string;
					if (token) {
						headers.set("Authorization", token);
					}
					if (orgHeader) {
						headers.set("Genemod-OrganizationId", orgHeader);
					}
					if (workspaceHeader) {
						headers.set("Genemod-WorkspaceId", workspaceHeader);
					}
					return headers;
				},
			})(fetchArgs, api, extraOptions);

			if (
				result.error?.status === 403 &&
				(result.error?.data as any)?.detail === "Invalid token."
			) {
				window.location.pathname = "/login";
			}

			if (result.error?.status === 400 || result.error?.status === 404) {
				retry.fail(result.error);
			}

			return result;
		},
		{
			retryCondition(error, args, { attempt, extraOptions = {} }) {
				const { maxRetries = 5 } = extraOptions;
				if (attempt > maxRetries) {
					return false;
				}
				return true;
			},
		}
	);
};

// rtk query types we need to override for the time being for createSingletonQuery
type QD<Input, Output> = QueryDefinition<
	Input,
	BaseQueryFn<
		string | FetchArgs,
		unknown,
		FetchBaseQueryError,
		unknown,
		FetchBaseQueryMeta
	>,
	never,
	Output,
	string
>;
type OverrideQuery<Input, Output> = UseQuery<QD<Input, Output>>;

/**
 * Adds in a common usecase where we want to setup a query that we'll only use once per page and we want to be able to access the
 * results via a hook. Version 1.7 may add this functionality natively. We should switch to that in the future.
 *
 * Note: a limitation of this is that any query that has no "input" args will never actually get fetched.
 */
export const createSingletonQuery = <Input, Output>(
	endpointQuery: any // TODO: types
): OverrideQuery<Input | void, Output> => {
	// inputArgs includes the query args and options
	const inputArgs: { current: any } = {
		current: [undefined, { skip: true }],
	};

	return ((...args: any) => {
		const unsafeQuery = endpointQuery as any;
		if (args && args.length > 0) {
			inputArgs.current = args;
		}
		return unsafeQuery.useQuery(...inputArgs.current);
	}) as unknown as OverrideQuery<Input | void, Output>;
};

export const TEN_MINUTE_POLLING = { pollingInterval: 10 * 60 * 1000 } as const;

type RtkQueryErrorResponse = {
	error: FetchBaseQueryError | SerializedError;
};

/**
 * Helper to get a type for the standard response passed into the "then" call after a query
 */
export type RtkQueryResponse<T> =
	| {
			data: T;
	  }
	| RtkQueryErrorResponse;

export const isRtkQueryError = <T>(
	resp: RtkQueryResponse<T>
): resp is RtkQueryErrorResponse => "error" in resp;
