import React, { useState } from "react";
import { Segmented as AntdSegmented, SegmentedProps } from "antdv5";
import { Typography } from "@common/components";
import { Demo, DemoSection, DemoWrapper } from "@components";
import styles from "./Segmented.module.scss";
import classNames from "classnames";

export const Segmented = ({
	...props
}: Omit<SegmentedProps, "ref"> & React.RefAttributes<HTMLDivElement>) => {
	return (
		<AntdSegmented
			{...props}
			className={classNames(styles.segmented, props.className)}
		/>
	);
};

export default Segmented;

export const SEGMENTED_DEMO = (): JSX.Element => {
	const [selectedStatus, setSelectedStatus] = useState(1);

	const filterLabel = [
		{ key: 1, title: "All" },
		{
			key: 2,
			title: "Requested",
		},
		{
			key: 3,
			title: "Processing",
		},
		{
			key: 4,
			title: "Complete",
		},
	];

	const filterVal = filterLabel.map((val) => ({
		label: (
			<div key={val.key}>
				<Typography variant="label" bold={val.key === selectedStatus}>
					{val.title}
				</Typography>
			</div>
		),
		value: val.key,
	}));
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage"
					description="Basic Segmented component"
				>
					<Segmented
						value={selectedStatus}
						options={filterVal}
						onChange={(val) => {
							setSelectedStatus(Number(val));
						}}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
};
