import React from "react";
import {
	Point,
	Element,
	Node as SlateNode,
	Range,
	Editor,
	Transforms,
} from "slate";
import { Node } from "../../nodes";
import { getHexFromCss } from "../../helpers";
import {
	TableTitle as TT,
	TableWrapper as TW,
	TableComponent,
	TableToolbar as TBar,
	InsertRow,
	InsertRowHeader as IRH,
	InsertRowCell as IRC,
	InsertColumn,
	InsertColumnPlaceholder as ICP,
} from "../../components/Table";

import styles from "@common/components/Editor/TableV2/index.module.scss";

const TableHead = {
	...Node,
	type: "table-head",
	render(props) {
		return <thead {...props.attributes}>{props.children}</thead>;
	},
};

const TableBody = {
	...Node,
	type: "table-body",
	render(props) {
		return <tbody {...props.attributes}>{props.children}</tbody>;
	},
};

const TableHeader = {
	...Node,
	type: "table-header",
	render(props) {
		props.attributes.style = {
			...props.attributes.style,
			userSelect: "none",
		};
		return (
			<th contentEditable={false} {...props.attributes}>
				{props.children}
			</th>
		);
	},
};

const TableRow = {
	...Node,
	type: "table-row",
	render(props) {
		return <tr {...props.attributes}>{props.children}</tr>;
	},
};

const TableCell = {
	...Node,
	ALIGNMENT_DIRECTIONS: ["horizontal", "vertical"],
	type: "table-cell",
	hAlign: "center",
	vAlign: "middle",
	rowSpan: [],
	columnSpan: [],
	fill: null,
	render(props, node) {
		const customStyle = {};
		if (node.hAlign) {
			customStyle.textAlign = node.hAlign;
		}

		if (node.vAlign) {
			customStyle.verticalAlign = node.vAlign;
		}

		if (node.fill) {
			const colorHex = getHexFromCss(node.fill);
			customStyle.backgroundColor = colorHex + "54";
		}

		props.attributes.style = {
			...props.attributes.style,
			...customStyle,
		};

		return <td {...props.attributes}>{props.children}</td>;
	},
};

const TableWrapper = {
	...Node,
	type: "table-wrapper",
	render(props) {
		return <TW {...props.attributes}>{props.children}</TW>;
	},
};

export const TableTitle = {
	...Node,
	type: "table-title",
	render(props) {
		return <TT {...props.attributes}>{props.children}</TT>;
	},
};

const TableToolbar = {
	...Node,
	type: "table-toolbar",
	render(props, node) {
		const { tableId } = node;
		return (
			<TBar
				contentEditable={false}
				{...props.attributes}
				tableId={tableId}
			>
				{props.children}
			</TBar>
		);
	},
};

// Nodes for adding a row/column
const InsertTableRow = {
	...Node,
	type: "table-insert-row",
	render(props, node) {
		return (
			<InsertRow {...props.attributes} tableId={node.tableId}>
				{props.children}
			</InsertRow>
		);
	},
};

const InsertRowHeader = {
	...Node,
	type: "table-insert-row-header",
	render(props, node) {
		return (
			<IRH {...props.attributes} tableId={node.tableId}>
				{props.children}
			</IRH>
		);
	},
};

const InsertRowCell = {
	...Node,
	type: "table-insert-row-cell",
	isVoid: true,
	render(props) {
		return <IRC {...props.attributes}>{props.children}</IRC>;
	},
};

const InsertTableColumnHeader = {
	...Node,
	type: "table-insert-column-header",
	isVoid: true,
	render(props, node) {
		return (
			<InsertColumn {...props.attributes} tableId={node.tableId}>
				{props.children}
			</InsertColumn>
		);
	},
};

const InsertColumnPlaceholder = {
	...Node,
	type: "table-insert-column-placeholder",
	isVoid: true,
	render(props, node) {
		return (
			<ICP {...props.attributes} tableId={node.tableId}>
				{props.children}
			</ICP>
		);
	},
};

export const Table = {
	...Node,
	TableCell,
	TableWrapper,
	type: "table",
	MAX_ROWS: 20,
	MAX_COLUMNS: 20,
	render(props) {
		return (
			<TableComponent {...props.attributes}>
				{props.children}
			</TableComponent>
		);
	},

	register(editor) {
		editor.registerNode(this);
		editor.registerNode(TableHead);
		editor.registerNode(TableBody);
		editor.registerNode(TableHeader);
		editor.registerNode(TableRow);
		editor.registerNode(TableCell);
		editor.registerNode(TableWrapper);
		editor.registerNode(TableTitle);
		editor.registerNode(TableToolbar);
		editor.registerNode(InsertTableRow);
		editor.registerNode(InsertRowHeader);
		editor.registerNode(InsertRowCell);
		editor.registerNode(InsertTableColumnHeader);
		editor.registerNode(InsertColumnPlaceholder);
	},

	/**
	 * @param {Editor} editor
	 * @returns {Boolean} True if the selection is currently inside a table
	 */
	isInTable(editor) {
		const selection = editor.selection || editor.blurSelection;
		if (!selection || !selection.anchor || !selection.focus) return false;
		Transforms.select(editor, selection);
		return (
			editor.isNodeOfTypeActive(this.type) ||
			editor.isNodeOfTypeActive("table-v2")
		);
	},

	getCellAnchorClass() {
		return styles.cell__anchor;
	},

	/**
	 * @param {Editor} editor
	 * @returns {Boolean} True if the selection is in the title
	 */
	isInTitle(editor) {
		const selection = editor.selection || editor.blurSelection;
		if (!selection || !selection.anchor || !selection.focus) return false;
		Transforms.select(editor, selection);
		return (
			editor.isNodeOfTypeActive(TableTitle.type) ||
			editor.isNodeOfTypeActive("table-title-v2")
		); // missing the type reference for "table-title-v2"
	},

	/**
	 * @param {Editor} editor
	 * @returns {[TableTitle, Path]} The tabletitle node and path at the selection
	 */
	getTitleData(editor) {
		const { selection } = editor;
		if (!selection) return null;
		const [data] = Editor.nodes(editor, {
			at: selection,
			match: (n) =>
				n.type == TableTitle.type || n.type === "table-title-v2",
		});
		return data || null;
	},

	/**
	 * Inserts a table into the editor document
	 */
	insertTable(editor, rows, columns) {
		// Prevent nested tables
		if (this.isInTable(editor)) return;
		const ID = new Date().getTime();

		// Create table headers. Extra column for column headers
		const headers = Array(columns + 1)
			.fill()
			.map((_, index) => ({
				...TableHeader,
				children: [
					{
						text:
							index === 0 ? "" : String.fromCharCode(64 + index),
					},
				],
			}));

		// Add col column
		headers.push({
			...InsertTableColumnHeader,
			tableId: ID,
			children: [
				{
					text: "+",
				},
			],
		});

		// Insert into thead
		const thead = {
			...TableHead,
			children: [
				{
					...TableRow,
					row: 0,
					children: headers,
				},
			],
		};

		// Create each table row
		const trows = Array(rows)
			.fill()
			.map((_, row) => {
				return {
					...TableRow,
					row: row + 1,
					children: Array(columns + 1)
						.fill()
						.map((_, col) => {
							if (col === 0) {
								return {
									...TableHeader,
									children: [{ text: "" + (row + 1) }],
								};
							}

							return {
								...TableCell,
								rowSpan: [row + 1],
								columnSpan: [col],
								children: [{ text: "" }],
							};
						}),
				};
			});

		// Insert add column placeholder column
		trows[0].children.push({
			...InsertColumnPlaceholder,
			tableId: ID,
			children: [{ text: "" }],
		});

		// Row for adding a new row
		trows.push({
			...InsertTableRow,
			tableId: ID,
			children: [
				{
					...InsertRowHeader,
					tableId: ID,
					isVoid: true,
					children: [],
				},
				{
					...InsertRowCell,
					children: [{ text: "" }],
				},
			],
		});

		// Insert into tbody
		const tbody = {
			...TableBody,
			children: trows,
		};
		const title = {
			...TableTitle,
			children: [{ text: "Table" }],
		};

		const table = {
			...this,
			tableId: ID,
			rows,
			columns,
			children: [title, thead, tbody],
		};

		const toolbar = {
			...TableToolbar,
			tableId: ID,
			children: [{ text: "" }],
		};

		Transforms.insertNodes(editor, {
			...TableWrapper,
			tableId: ID,
			children: [toolbar, table],
		});
		this.focusEditor(editor);
		this.toCell(editor, 1, 1);
	},

	/**
	 * @param {Editor} editor
	 * @returns {Node} The table node. Null if not active
	 */
	getTable(editor) {
		const match = this.getTableData(editor);
		if (match) {
			return match[0];
		}
		return null;
	},

	/**
	 * @returns {[Node, Path]} The data for the table including path `[Node, Path]`
	 */
	getTableData(editor) {
		const { selection } = editor;
		if (!selection || !this.isActive(editor)) return null;
		const [match] = editor.getNodesOfType(this.type, {
			mode: "lowest",
			at: selection,
		});
		return match || null;
	},

	/**
	 * @returns {[Number]} The `Path` for the table in the selection. `null` if none
	 */
	getTablePath(editor) {
		const tableData = this.getTableData(editor);
		if (!tableData) return null;
		return tableData[1];
	},

	/**
	 * @param {Editor} editor
	 * @returns {Node | Null} The node for the current table cell. `null` if none
	 */
	getCurrentCell(editor) {
		const { selection } = editor;
		const table = this.getTable(editor);
		if (table && selection) {
			const [cell] = editor.getNodesOfType(TableCell.type, {
				at: selection,
				mode: "lowest",
			});
			if (cell) {
				return cell[0];
			}
		}
		return null;
	},

	/**
	 * Moves the selection to a specific cell. Not 0-indexed :)
	 * @param {Editor} editor
	 * @param {Number} row
	 * @param {Number} column
	 * @param {Object} options
	 * @param {Boolean} [options.collapse=false] If true, collapses the selection. Default is false
	 * @param {"left" | "right"} [options.direction="left"] Direction to collapse the selection
	 * @param {Boolean} [options.preserveOffset=false] If true, preserves the offset when collapse if `true`
	 */
	toCell(
		editor,
		row,
		column,
		options = { collapse: false, direction: "left", preserveOffset: false }
	) {
		options = {
			collapse: false,
			direction: "left",
			preserveOffset: false,
			...options,
		};

		const tableData = this.getTableData(editor);
		if (!tableData) return;

		// Check that row and column are within the bounds of the table
		const [table, tablePath] = tableData;
		if (
			!table ||
			row < 1 ||
			column < 1 ||
			row > table.rows ||
			column > table.columns
		)
			return;

		// Find corresponding table cell
		const [targetCell] = Editor.nodes(editor, {
			at: tablePath,
			match: (n) =>
				n.type === TableCell.type &&
				n.rowSpan.includes(row) &&
				n.columnSpan.includes(column),
			mode: "lowest",
		});
		if (!targetCell) return;

		// Preserve cell offset when moving up and down
		let previousOffset = editor.selection?.anchor?.offset || 0;

		// Select and collapse cell if needed
		Transforms.select(editor, targetCell[1]);
		if (options.collapse) {
			if (options.direction === "right") {
				Transforms.collapse(editor, {
					edge: "end",
				});
			} else if (options.direction === "left") {
				Transforms.collapse(editor, {
					edge: "start",
				});
			}

			// Apply previous offset
			if (options.preserveOffset && editor.selection) {
				// Get updated selection from editor
				const { selection } = editor;
				const range = {
					anchor: {
						...selection.anchor,
						offset: previousOffset,
					},
					focus: {
						...selection.focus,
						offset: previousOffset,
					},
				};

				// Check that the current cell can support the previous offset
				const [cellData] = editor.getNodesOfType(TableCell.type, {
					at: selection,
					mode: "lowest",
				});
				if (
					cellData &&
					SlateNode.string(cellData[0]).length >= previousOffset
				) {
					Transforms.setSelection(editor, range);
				}
			}
		}
	},

	/**
	 * Moves to the node below the table
	 * @param {Editor} editor
	 */
	moveBelowTable(editor) {
		const tablePath = this.getTablePath(editor);
		if (!tablePath) return;

		// Modify the path to the next node. It should exist because of the normalizer/plugin
		let newPath = tablePath.slice(0, tablePath.length - 1);
		newPath[newPath.length - 1] = newPath[newPath.length - 1] + 1;
		Transforms.select(editor, newPath);
		Transforms.collapse(editor);
	},

	/**
	 * Moves to the node above the table
	 * @param {Editor} editor
	 */
	moveAboveTable(editor) {
		const { selection } = editor;
		const [tableWrapper] = Editor.nodes(editor, {
			at: selection,
			match: (n) => n.type === TableWrapper.type,
		});
		// Move to the table's title
		if (!selection || !tableWrapper) return;
		const tableElements = SlateNode.children(editor, tableWrapper[1]);
		for (const [child, childPath] of tableElements) {
			if (child.type === TableTitle.type) {
				// Move to this node
				Transforms.select(editor, childPath);
				Transforms.collapse(editor, {
					edge: "end",
				});
			}
		}
	},

	isInTableWrapper(editor) {
		return (
			editor.isNodeOfTypeActive(TableWrapper.type) ||
			editor.isNodeOfTypeActive("table-wrapper-v2")
		);
	},

	isInOldTable(editor) {
		return editor.isNodeOfTypeActive(TableWrapper.type);
	},

	/**
	 * Navigates to the next cell in the table.
	 * If at the end of the row, navigates to the next row.
	 * If at the last row and column, navigates to the next block
	 */
	moveRight(editor, collapse = true) {
		const cell = this.getCurrentCell(editor);
		const table = this.getTable(editor);
		if (!cell || !table) return;

		// Check if at the edge of a row
		let { rowSpan, columnSpan } = cell;
		let row = rowSpan[0];
		let column = columnSpan[0] + 1;

		// Next row
		if (column > table.columns) {
			column = 1;
			row += 1;
		}

		if (row > table.rows) {
			this.moveBelowTable(editor);
			return;
		}
		this.toCell(editor, row, column, {
			collapse,
			direction: "left",
		});
	},

	/**
	 * Navigates to the previous cell in the table.
	 * If at the start of a row, navigates to the previous row.
	 * If at the first row and column, navigates to the previous block
	 */
	moveLeft(editor, collapse = true) {
		const cell = this.getCurrentCell(editor);
		const table = this.getTable(editor);
		if (!cell || !table) return;

		// Check if at the edge of a row
		let { rowSpan, columnSpan } = cell;
		let row = rowSpan[0];
		let column = columnSpan[0] - 1;

		// Next row
		if (column === 0) {
			column = table.columns;
			row -= 1;
		}

		if (row === 0) {
			this.moveAboveTable(editor);
			return;
		}
		this.toCell(editor, row, column, {
			collapse,
			direction: "right",
		});
	},

	/**
	 * Navigates to the cell directly above the current one in the table.
	 */
	moveUp(editor, collapse = true) {
		const cell = this.getCurrentCell(editor);
		const table = this.getTable(editor);
		if (!cell || !table) return;

		// Check if at the top
		let { rowSpan, columnSpan } = cell;
		let row = rowSpan[0] - 1;
		let column = columnSpan[0];

		// Next row
		if (row === 0) {
			this.moveAboveTable(editor);
			return;
		}
		this.toCell(editor, row, column, {
			collapse,
			preserveOffset: true,
		});
	},

	/**
	 * Navigates to the cell directly below the current one in the table.
	 */
	moveDown(editor, collapse = true) {
		const cell = this.getCurrentCell(editor);
		const table = this.getTable(editor);
		if (!cell || !table) return;

		// Check if at the top
		let { rowSpan, columnSpan } = cell;
		let row = rowSpan[0] + 1;
		let column = columnSpan[0];

		// Next row
		if (row > table.rows) {
			this.moveBelowTable(editor);
			return;
		}
		this.toCell(editor, row, column, {
			collapse,
			preserveOffset: true,
		});
	},

	hotkeys: [
		{
			shiftKey: true,
			key: "Tab",
			action: "left-tab",
		},
		{
			shiftKey: false,
			key: "Tab",
			action: "right-tab",
		},
		{
			key: "Enter",
			action: "enter",
		},
		{
			key: "ArrowUp",
			action: "up",
		},
		{
			key: "ArrowDown",
			action: "down",
		},

		{
			key: "ArrowLeft",
			action: "left",
		},
		{
			key: "ArrowRight",
			action: "right",
		},
	],

	onHotkey(editor, hotkey, event) {
		// Get selection info
		const { selection } = editor;
		if (!selection) return;

		const isCollapsed = Range.isCollapsed(selection);
		const isAtEnd = this.isAtEdge(editor, "right");
		const isAtStart = this.isAtEdge(editor, "left");

		switch (hotkey.action) {
			case "left-tab":
				if (this.isActive(editor)) {
					event.preventDefault();
					this.moveLeft(editor, false);
					return false;
				} else if (this.isInTitle(editor)) {
					event.preventDefault();
					// Move above table
					const [wrapper] = Editor.nodes(editor, {
						at: selection,
						match: (n) => n.type === TableWrapper.type,
					});
					if (!wrapper) return true;

					const wrapperStart = Editor.start(editor, wrapper[1]);
					const beforePoint = Editor.before(editor, wrapperStart);
					Transforms.select(editor, beforePoint);
					Transforms.collapse(editor);
					return false;
				}
				return true;
			case "right-tab":
				if (this.isActive(editor)) {
					event.preventDefault();
					this.moveRight(editor, false);
					return false;
				} else if (this.isInTitle(editor)) {
					event.preventDefault();
					const [wrapper] = Editor.nodes(editor, {
						at: selection,
						match: (n) => n.type === TableWrapper.node,
					});
					if (wrapper) {
						const [table] = Editor.nodes(editor, {
							at: wrapper[1],
							match: (n) => n.type === Table.type,
						});
						Transforms.select(editor, table[1]);
						this.toCell(editor, 1, 1);
						return false;
					}
				}
				return true;
			case "right":
				if (isCollapsed && isAtEnd) {
					if (this.isActive(editor)) {
						event.preventDefault();
						this.moveRight(editor);
						return false;
					} else if (this.isInTitle(editor)) {
						// Move to first cell
						const path = Editor.after(editor, selection);
						if (path) {
							event.preventDefault();
							Transforms.select(editor, path);
							this.toCell(editor, 1, 1, {
								collapse: true,
								direction: "left",
							});
						}
					} else if (selection) {
						// Check if user can move into the table
						const path = Editor.after(editor, selection);
						const [wrapper] = Editor.nodes(editor, {
							at: path,
							match: (n) => n.type === TableWrapper.type,
							mode: "lowest",
						});

						if (wrapper) {
							const [title] = Editor.nodes(editor, {
								at: wrapper[1],
								match: (n) => n.type === TableTitle.type,
							});
							if (title) {
								event.preventDefault();
								Transforms.select(editor, title[1]);
								Transforms.collapse(editor, { edge: "start" });
								return false;
							}
						}
					}
				}
				return true;
			case "left":
				if (isCollapsed && isAtStart) {
					if (this.isActive(editor)) {
						event.preventDefault();
						this.moveLeft(editor);
						return false;
					} else if (this.isInTitle(editor)) {
						event.preventDefault();
						// Move above table
						const [wrapper] = Editor.nodes(editor, {
							at: selection,
							match: (n) => n.type === TableWrapper.type,
						});
						if (!wrapper) return true;

						const wrapperStart = Editor.start(editor, wrapper[1]);
						const beforePoint = Editor.before(editor, wrapperStart);
						Transforms.select(editor, beforePoint);
						Transforms.collapse(editor);
						return false;
					} else if (selection) {
						const path = Editor.before(editor, selection);
						const [tableData] = Editor.nodes(editor, {
							at: path,
							match: (n) => n.type === Table.type,
							mode: "lowest",
						});

						if (tableData) {
							event.preventDefault();
							const [table, tablePath] = tableData;
							Transforms.select(editor, tablePath);
							this.toCell(editor, table.rows, table.columns, {
								collapse: true,
								direction: "right",
							});
							return false;
						}
					}
				}
				return true;
			case "up":
				if (this.isActive(editor)) {
					event.preventDefault();
					this.moveUp(editor);
					return false;
				}
				return true;
			case "down":
				if (this.isActive(editor)) {
					event.preventDefault();
					this.moveDown(editor);
					return false;
				}
				return true;
			case "enter":
				if (this.isActive(editor)) {
					if (this.isInTitle(editor)) {
						// Move to first cell
						const path = Editor.after(editor, selection);
						if (path) {
							event.preventDefault();
							Transforms.select(editor, path);
							this.toCell(editor, 1, 1, {
								collapse: true,
								direction: "left",
							});
						}
					} else {
						event.preventDefault();
						Editor.insertText(editor, "\n");
						return false;
					}
					return false;
				}
				return true;
			default:
				return true;
		}
	},
	plugin: withTables,

	/**
	 * @param {Editor} editor
	 * @param {"horizontal" | "vertical"} direction Direction of the alignment
	 * @param {String} alignment CSS value for the alignment
	 */
	setAlignment(editor, direction, alignment) {
		const { selection } = editor;
		if (!selection || !TableCell.ALIGNMENT_DIRECTIONS.includes(direction))
			return;

		const property = direction === "horizontal" ? "hAlign" : "vAlign";
		// Set the horizontal alignment property for cells in the selection
		Transforms.setNodes(
			editor,
			{
				[property]: alignment,
			},
			{
				at: selection,
				match: (n) => n.type === TableCell.type,
			}
		);
		this.focusEditor(editor);
	},

	/**
	 * @param {Editor} editor
	 * @param {"horizontal" | "vertical"} direction Which alignment direction to retrieve
	 * @returns {String} The horizontal alignment for the selected cells. Returns null if not all cells have the same alignment
	 */
	getAlignment(editor, direction) {
		const { selection } = editor;
		if (!selection || !TableCell.ALIGNMENT_DIRECTIONS.includes(direction))
			return null;

		let [...cells] = Editor.nodes(editor, {
			at: selection,
			match: (n) => n.type === TableCell.type,
		});

		// Check if there is a singular alignment value
		const property = direction === "horizontal" ? "hAlign" : "vAlign";
		cells = cells.reduce((alignments, [cell]) => {
			if (!alignments.includes(cell[property])) {
				return [...alignments, cell[property]];
			}
			return alignments;
		}, []);

		if (cells.length !== 1) return null;
		return cells[0];
	},

	/**
	 * @param {Editor} editor
	 * @param {String} color
	 */
	setFillColor(editor, color) {
		const { selection } = editor;
		if (!selection) return;

		Transforms.setNodes(
			editor,
			{
				fill: color,
			},
			{
				at: selection,
				match: (n) => n.type === TableCell.type,
			}
		);

		// Need to set timeout w/ colorpicker component for some reason...
		setTimeout(() => {
			this.focusEditor(editor);
		}, 50);
	},

	/**
	 * @param {Editor} editor
	 * @returns {String} The current fill color. Null if multiple values are present
	 */
	getFillColor(editor) {
		const { selection } = editor;
		if (!selection) return null;

		let [...cells] = Editor.nodes(editor, {
			at: selection,
			match: (n) => n.type === TableCell.type,
		});

		// Check if there is a singular alignment value
		cells = cells.reduce((alignments, [cell]) => {
			if (
				!alignments.includes(cell["fill"]) &&
				cell["fill"] !== undefined
			) {
				return [...alignments, cell["fill"]];
			}
			return alignments;
		}, []);

		if (cells.length !== 1) return null;
		return cells[0];
	},

	/**
	 * @returns {Number} The id for the table in the current selection
	 */
	getTableId(editor) {
		const table = this.getTable(editor);
		if (!table) return -1;
		return table.tableId;
	},

	/**
	 * @param {Editor} editor
	 * @param {"rows" | "columns"} dimension
	 * @returns {Boolean} True if the table can increase the specified dimension
	 */
	canAddDimension(editor, dimension) {
		// Check for the specific table id
		let table = this.getTable(editor);
		return table?.[dimension] < Table["MAX_" + dimension.toUpperCase()];
	},

	/**
	 * Adds a dimension to the table
	 * @param {Editor} editor
	 * @param {"rows" | "columns"} dimension
	 */
	addDimension(editor, dimension) {
		if (this.canAddDimension(editor, dimension)) {
			this.focusEditor(editor);
			const { selection } = editor;
			const tableData = this.getTableData(editor);
			if (tableData && selection) {
				const [table, tablePath] = tableData;
				let newRows = table.rows;
				if (dimension === "rows") {
					newRows += 1;

					const [addRow] = Editor.nodes(editor, {
						at: tablePath,
						match: (n) => n.type === InsertTableRow.type,
					});

					// Insert a new table row w/ header and data
					Transforms.insertNodes(
						editor,
						[
							{
								...TableRow,
								children: [
									{
										...TableHeader,
										children: [{ text: "" + newRows }],
									},
									...Array(table.columns)
										.fill()
										.map((_, index) => {
											return {
												...TableCell,
												rowSpan: [newRows],
												columnSpan: [index + 1],
												children: [{ text: "" }],
											};
										}),
								],
							},
						],
						{
							at: addRow[1],
						}
					);
				} else if (dimension === "columns") {
					// Add a th and td
					// Get all the rows in the table
					const rows = Editor.nodes(editor, {
						at: tablePath,
						match: (n) => n.type === TableRow.type,
					});
					for (const [row, rowPath] of rows) {
						// Insert a TableCell at the end of the row

						// Header row
						if (row.row === 0) {
							// Insert before "Add column" column
							const [placeholder] = Editor.nodes(editor, {
								at: rowPath,
								match: (n) =>
									n.type === InsertTableColumnHeader.type,
							});

							if (placeholder) {
								Transforms.insertNodes(
									editor,
									{
										...TableHeader,
										children: [
											{
												text: String.fromCharCode(
													64 + table.columns + 1
												),
											},
										],
									},
									{
										at: placeholder[1],
									}
								);
							}
						} else if (row.row === 1) {
							// Insert a td before the placeholder
							const [placeholder] = Editor.nodes(editor, {
								at: rowPath,
								match: (n) =>
									n.type === InsertColumnPlaceholder.type,
							});

							if (placeholder) {
								Transforms.insertNodes(
									editor,
									{
										...TableCell,
										rowSpan: [row.row],
										columnSpan: [table.columns + 1],
										children: [
											{
												text: "",
											},
										],
									},
									{
										at: placeholder[1],
									}
								);
							}
						} else {
							const rowEnd = Editor.end(editor, rowPath);
							Transforms.insertNodes(
								editor,
								{
									...TableCell,
									rowSpan: [row.row],
									columnSpan: [table.columns + 1],
									children: [{ text: "" }],
								},
								{
									at: rowEnd,
								}
							);
						}
					}
				}

				// Update the table's dimension
				Transforms.setNodes(
					editor,
					{
						[dimension]: table[dimension] + 1,
					},
					{
						at: selection,
						match: (n) => n.type === Table.type,
						mode: "lowest",
					}
				);

				// Focus the editor and move to the new row
				this.focusEditor(editor);
				if (dimension === "rows") {
					// Go to the new row
					this.toCell(editor, newRows, 1);
				} else {
					// To new column
					this.toCell(editor, 1, table.columns + 1);
				}
			}
		}
	},
	/**
	 * @param {Editor} editor
	 * @returns {Boolean} True if the selection contains a table wrapper
	 */
	isWrapperInSelection(editor) {
		const { selection } = editor;
		if (selection && Range.isExpanded(selection)) {
			// See if the tableWrapper is within the selection
			const [wrapperData] = Editor.nodes(editor, {
				at: selection,
				match: (n) => n.type === Table.TableWrapper.type,
			});

			if (!wrapperData || wrapperData.length < 2) {
				return false;
			}

			// Check if the selection passes both edges of the tableWrapper
			const [wrapperStart, wrapperEnd] = Editor.edges(
				editor,
				wrapperData[1]
			);
			const [selectionStart, selectionEnd] = Range.edges(selection);
			if (
				(Point.isBefore(selectionStart, wrapperStart) ||
					Point.equals(selectionStart, wrapperStart)) &&
				(Point.isAfter(selectionEnd, wrapperEnd) ||
					Point.equals(selectionEnd, wrapperEnd))
			) {
				// If it does, then the table is in the selection
				return true;
			}
		}
		return false;
	},
};

function withTables(editor) {
	const {
		normalizeNode,
		deleteFragment,
		deleteBackward,
		deleteForward,
		insertBreak,
		insertFragment,
		insertText,
	} = editor;

	editor.normalizeNode = (entry) => {
		const [node, nodePath] = entry;
		if (Editor.isEditor(node) || Element.isElement(node)) {
			// Force tables to have a block before and after it
			const [...children] = SlateNode.children(editor, nodePath);
			const tableIndex = children.findIndex(
				([childNode]) => childNode.type === TableWrapper.type
			);
			if (tableIndex !== -1) {
				const [, tablePath] = children[tableIndex];
				const emptyNode = {
					children: [{ text: "" }],
				};

				const beforeNode = children[tableIndex - 1];
				const afterNode = children[tableIndex + 1];
				if (!beforeNode || beforeNode[0].type === TableWrapper.type) {
					// Insert an empty block before the table
					Transforms.insertNodes(editor, emptyNode, {
						at: tablePath,
					});
				} else if (
					!afterNode ||
					afterNode[0].type === TableWrapper.type
				) {
					const behind = [...tablePath];
					behind[behind.length - 1] = behind[behind.length - 1] + 1;
					Transforms.insertNodes(editor, emptyNode, {
						at: behind,
					});
				}
			}
		}

		normalizeNode(entry);
	};

	editor.deleteFragment = () => {
		// Prevent cells from being deleted
		const { selection } = editor;
		const [tableWrapper] = Editor.nodes(editor, {
			match: (n) => n.type === TableWrapper.type,
			at: selection,
		});

		// Check if selection contains partial of tableWrapper
		if (tableWrapper) {
			// Get the start and end Points of the tableWrapper
			const [wrapperStart, wrapperEnd] = Editor.edges(
				editor,
				tableWrapper[1]
			);
			const [selectionStart, selectionEnd] = Range.edges(selection);
			if (
				(Point.isBefore(selectionStart, wrapperStart) ||
					Point.equals(selectionStart, wrapperStart)) &&
				(Point.isAfter(selectionEnd, wrapperEnd) ||
					Point.equals(selectionEnd, wrapperEnd))
			) {
				// The whole table is selected. Allow it to be deleted
				Transforms.removeNodes(editor, {
					at: tableWrapper[1],
					match: (n) => n.type === TableWrapper.type,
				});
			} else {
				// Partial selection. Only allow text to be deleted within the table
				const tableNodes = Editor.nodes(editor, {
					at: selection,
					match: (n) =>
						n.type === TableCell.type || n.type === TableTitle.type,
				});
				for (const [, nodePath] of tableNodes) {
					// Delete the text within the node
					for (const [, childPath] of SlateNode.children(
						editor,
						nodePath
					)) {
						const childRange = {
							anchor: Editor.start(editor, childPath),
							focus: Editor.end(editor, childPath),
						};
						const intersection = Range.intersection(
							selection,
							childRange
						);
						// There is text to delete within the cell
						if (Range.isExpanded(intersection)) {
							Transforms.delete(editor, {
								at: intersection,
							});
						}
					}
				}
				Transforms.collapse(editor, { edge: "focus" });
				return;
			}
		}

		deleteFragment();
	};

	/**
	 * Prevents tables and tablecells from being deleted via backspace and delete keys
	 * @param {Function} operation Operation to check
	 * @param {Function} getEdgePoint Function that returns the Point that we should check for
	 * @param {Function} getNextLocation Function that returns a Point where the user's next location would be after applying this operation
	 */
	const preventDelete = (operation, getEdgePoint, getNextLocation) => {
		return (unit) => {
			const { selection } = editor;

			if (Range.isCollapsed(selection)) {
				// Check if selection is at the corresponding edge point for this operation
				const [cell] = Editor.nodes(editor, {
					match: (n) => n.type === TableCell.type,
					at: selection,
				});

				if (Table.isInTitle(editor)) {
					const [, tablePath] = Table.getTitleData(editor);
					const edgePoint = getEdgePoint(editor, tablePath);

					// Selection is at the edge. Do not allow user to delete the table cell
					if (
						selection &&
						Point.equals(selection.anchor, edgePoint)
					) {
						return;
					}
				}

				if (cell) {
					const [, cellPath] = cell;
					const edgePoint = getEdgePoint(editor, cellPath);

					// Selection is at the edge. Do not allow user to delete the table cell
					if (
						selection &&
						Point.equals(selection.anchor, edgePoint)
					) {
						return;
					}
				} else {
					// Prevent deleting the table from outside
					const nextLocation = getNextLocation(editor, selection, {
						unit,
					});
					const [table] = Editor.nodes(editor, {
						match: (n) => n.type === TableCell.type,
						at: nextLocation,
					});
					if (table) return;
				}
			}

			// Continue op
			operation(unit);
		};
	};

	editor.deleteBackward = preventDelete(
		deleteBackward,
		Editor.start,
		Editor.before
	);
	editor.deleteForward = preventDelete(
		deleteForward,
		Editor.end,
		Editor.after
	);

	editor.insertBreak = () => {
		if (Table.isInTable(editor)) {
			if (Table.isInTitle(editor)) {
				return;
			}
			Transforms.insertText(editor, "\n");
			return;
		}
		insertBreak();
	};

	editor.insertFragment = (fragment) => {
		// Only allow text to be pasted within the table
		if (Table.isInOldTable(editor)) {
			// Get the text within the fragment and insert
			const str = SlateNode.string({ children: fragment });
			editor.insertText(str);
			return;
		}

		insertFragment(fragment);
	};

	editor.insertText = (text) => {
		// Same thing as insertFragment, but paste the text to all the selected cells
		const { selection } = editor;
		if (Range.isExpanded(selection) && Table.isInOldTable(editor)) {
			// Iterate through each cell
			const cells = Editor.nodes(editor, {
				at: selection,
				match: (n) => n.type === TableCell.type,
			});
			for (const [, cellPath] of cells) {
				Transforms.insertText(editor, text, {
					at: cellPath,
				});
			}
			Transforms.collapse(editor, {
				edge: "focus",
			});
			return;
		}
		insertText(text);
	};

	return editor;
}
