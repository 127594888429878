import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	const cls3 = { fill: "#911eb4" };
	return (
		<svg
			id="type_xps"
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<g id="XMLID_2_" transform="translate(-55.801 -1)">
				<g id="XMLID_58_" transform="translate(62.8 6)">
					<path
						id="Path_4299"
						data-name="Path 4299"
						d="M1 0h6.27c.495.495 3.1 3.06 3.54 3.5V13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"
						fill="#e7d3ed"
					/>
					<path
						id="XMLID_61_"
						style={cls3}
						d="m330.742 5.962 3.526 3.538h-3.525z"
						transform="translate(-323.47 -6)"
					/>
					<path
						id="XMLID_60_"
						style={cls3}
						transform="translate(-2 7.475)"
						d="M0 0h9.14v4.995H0z"
					/>
				</g>
			</g>
		</svg>
	);
}

const MiniXpsIcon = React.memo(SvgComponent);
export default MiniXpsIcon;
