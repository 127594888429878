import { WorkspaceId } from "./AdminEnterprisePortal";
import { OrganizationUserId } from "./OrganizationUser";
import { OrganizationId } from "./Organization";

export type SharedObject = {
	organization_users: number[];
	workspaces: WorkspaceId[];
	organizations: number[];
};

/**
 * Similar to `SharedObject` except all
 * fields contain serialized objects
 * instead of ids.
 */
export type SerializedSharedObject = {
	access: {
		organization_users: OrganizationUserId[];
		workspaces: WorkspaceId[];
		organizations: OrganizationId[];
	};
};

export type SharingOptions = "personal" | "workspace" | "org";
export type SharingOptionsField = {
	sharing: SharingOptions;
	is_shared: boolean;
};

type ClearSharingFields<T> = Omit<
	T,
	| "organization_users"
	| "workspaces"
	| "organizations"
	| "sharing"
	| "is_shared"
>;

/**
 * DTO received from the server
 */
export type SharedReadDto<CommonFields> = ClearSharingFields<CommonFields> &
	SerializedSharedObject;

/**
 * Object transformed from the read dto
 */
export type SharedInternalObj<CommonFields> = SharedReadDto<CommonFields> &
	SharingOptionsField;

/**
 * DTO written the server
 */
export type SharedWriteDto<CommonFields> = ClearSharingFields<CommonFields> &
	SharedObject;

/**
 * Converts from read dto to internal object
 */
export const fromSharedReadDto = <T>(
	obj: SharedReadDto<T>
): SharedInternalObj<T> => ({
	...obj,
	is_shared:
		obj.access.organization_users.length > 1 ||
		obj.access.workspaces.length > 0 ||
		obj.access.organization_users.length > 0,
	sharing: (() => {
		if (obj.access.organizations?.length) {
			return "org";
		} else if (obj.access.workspaces?.length) {
			return "workspace";
		}
		return "personal";
	})(),
});

/**
 * Converts from internal object to write dto
 */
export const toSharedWriteDto = <T>(
	obj: SharedInternalObj<T>
): SharedWriteDto<T> => {
	const out = {
		...obj,
	} as any;
	delete out["sharing"];
	delete out["is_shared"];
	return out as SharedWriteDto<T>;
};
