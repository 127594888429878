import { setAxiosHeader, clearAxiosHeader } from "@API";
import { flattenUnion } from "@helpers/TypeHelpers";
import { CognitoUser } from "amazon-cognito-identity-js";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import ReactDOM from "react-dom";
import { LoginStatus, LoginProviderProps, LoginContext } from "../LoginContext";
import {
	cognitoLogout,
	cognitoGetUserWithValidSession,
	LoginArgs,
	cognitoLoginViaTokens,
	CognitoTokens,
	cognitoLogin,
	cognitoRegister,
	cognitoUpdatePassword,
	cognitoResetForgottenPassword,
	cognitoSendForgotPasswordEmail,
} from "./service/CognitoService";

type CognitoContextProps = {
	cognitoUser: CognitoUser | null;
	loginViaTokens: (
		tokens: CognitoTokens
	) => ReturnType<typeof cognitoGetUserWithValidSession>;
};

const DUMMY_FUNC: any = () => {
	console.error("Dummy function called unexpectedly!");
};

const CognitoContext = createContext<CognitoContextProps>({
	cognitoUser: null,
	loginViaTokens: DUMMY_FUNC,
});

export const CognitoProvider = (props: LoginProviderProps) => {
	const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
	const [loginStatus, setLoginStatus] = useState<LoginStatus>("LOADING");

	// After we call login/logout/loginWithTokens in the cognito service we need to call this to sync the local storage
	// changes with our login state.
	const refreshFromStorage = useCallback(async () => {
		setLoginStatus("LOADING");
		const result = await cognitoGetUserWithValidSession();
		ReactDOM.unstable_batchedUpdates(() => {
			const user = result.type === "success" ? result.result : null;
			const jwtToken = user
				?.getSignInUserSession()
				?.getAccessToken()
				?.getJwtToken();
			if (result.type === "failure" || !jwtToken) {
				setLoginStatus("LOGGEDOUT");
				clearAxiosHeader("Authorization");
			} else {
				setLoginStatus("LOGGEDIN");
				setCognitoUser(user);
				setAxiosHeader("Authorization", "Token " + jwtToken);
			}
		});
		// pass through result so further .then chains can be called
		return result;
	}, [
		cognitoGetUserWithValidSession,
		setCognitoUser,
		setLoginStatus,
		setAxiosHeader,
		ReactDOM.unstable_batchedUpdates,
	]);

	const logout = useCallback(() => {
		ReactDOM.unstable_batchedUpdates(() => {
			cognitoLogout();
			clearAxiosHeader("Authorization");
		});
		return refreshFromStorage();
	}, [
		cognitoLogout,
		clearAxiosHeader,
		refreshFromStorage,
		ReactDOM.unstable_batchedUpdates,
	]);

	const login = useCallback(
		async (loginArgs: LoginArgs, rememberLogin: boolean) => {
			const result = await cognitoLogin(loginArgs, rememberLogin);
			if (result.type === "failure") {
				console.warn("Login failure!");
				return logout();
			}
			return refreshFromStorage();
		},
		[]
	);

	const loginViaTokens = useCallback(
		(tokens: CognitoTokens) => {
			cognitoLoginViaTokens(tokens);
			clearAxiosHeader("Authorization");
			return refreshFromStorage();
		},
		[cognitoLoginViaTokens, clearAxiosHeader, refreshFromStorage]
	);

	// Run on hard page nav
	useEffect(() => {
		refreshFromStorage();
	}, [refreshFromStorage]);

	return (
		<LoginContext.Provider
			value={{
				loginStatus,
				register: cognitoRegister,
				login,
				logout,
				resetForgottenPassword: ({
					email,
					newPassword,
					verificationParams,
				}) =>
					cognitoResetForgottenPassword(
						email,
						flattenUnion(verificationParams)?.code || "",
						newPassword
					),
				updatePassword: cognitoUpdatePassword,
				sendForgotEmailPassword: cognitoSendForgotPasswordEmail,
			}}
		>
			<CognitoContext.Provider value={{ loginViaTokens, cognitoUser }}>
				{props.children}
			</CognitoContext.Provider>
		</LoginContext.Provider>
	);
};

/**
 * Povides data from the cognito context. Most functionality used outside of the Auth/Cognito folder should use this.
 *
 * @param loginStatus Can be "PENDING" while we check/refresh cognito tokens.
 * @param cognitoUser Provides a reference to the "CognitoUser" class from the cognito library.
 * @param refreshFromStorage Callback that causes the context to check local storage again for login credentails and applies auth headers. Use after login.
 * @param logout Callback that logs out the user, clearing local storage and auth headers.
 */
export const useCognitoContext = () => {
	return useContext(CognitoContext);
};
