import { Avatar, getFullNameFromAvatar } from "@common/types/User";
import { ISOString } from "@common/types/Date";
import { PaginatedSearchResults } from "@common/types/Search";
import { PM_ROUTES } from "@root/routes";
import {
	ExperimentAttachment,
	AttachmentOrGenemodFile,
	GENEMOD_FILE,
} from "@common/types";
import { formatDate } from "@helpers/Formatters";
import qs from "query-string";

/**
 * Info needed from the search result endpoint to display an experiment result row
 */
export type ExperimentSearchResultDto = {
	experimentId: number;
	experimentName: string;
	subprojectId: number;
	subprojectKey: string;
	users: Avatar[];
	lastModifiedOn: ISOString;
	owner?: Avatar;
};

type BaseAttachmentResultDto = Omit<ExperimentAttachment, "type">;

/**
 * Info needed to display a subproject attachment search result row
 */
type SubprojectAttachmentSearchResultDto = BaseAttachmentResultDto & {
	parentType: "subproject";
	projectId: number;
	subprojectId: number;
	subprojectName: string;
	extension: string;
};

/**
 * Info needed to display an experiment attachment search result row
 */
export type ExperimentAttachmentSearchResultDto = BaseAttachmentResultDto & {
	parentType: "experiment";
	projectId: number;
	subprojectName: string;
	experimentId: number;
	experimentName: string;
	extension: string;
};

export type ProjectAttachmentSearchResultDto = BaseAttachmentResultDto & {
	parentType: "project";
	projectId: number;
	name: string;
	extension: string;
};

/**
 * Display data
 */
export type PmSearchResult = {
	iconType: AttachmentOrGenemodFile;
	title: string;
	path: string[];
	users: string[];
	url: string;
	displayDate: string;
};

/**
 * Entire search result payload
 */
export type ProjectSearchResultsDto = {
	experiments: PaginatedSearchResults<ExperimentSearchResultDto>;
	attachments: PaginatedSearchResults<
		| SubprojectAttachmentSearchResultDto
		| ExperimentAttachmentSearchResultDto
		| ProjectAttachmentSearchResultDto
	>;
};

/**
 * Processed search results for display
 */
export type ProjectSearchResults = {
	experiments: PaginatedSearchResults<PmSearchResult>;
	files: PaginatedSearchResults<PmSearchResult>;
};

const getDisplayDate = (isoString: ISOString) => {
	const today = new Date(new Date().toDateString()).getTime();
	const yesterday = today - 24 * 60 * 60 * 1000;
	const modified = new Date(isoString).getTime();

	if (modified > today) {
		return "Today";
	} else if (modified > yesterday) {
		return "Yesterday";
	}
	return formatDate(isoString);
};

const transformExperimentResult = (
	dto: ExperimentSearchResultDto
): PmSearchResult => {
	let users = dto.users.map((c) => `${c.first_name} ${c.last_name}`);
	if (!users.length) {
		users = [dto.owner ? getFullNameFromAvatar(dto.owner) : "Deleted user"];
	}
	return {
		iconType: GENEMOD_FILE,
		title: dto.experimentName,
		path: [dto.subprojectKey].filter(Boolean),
		users,
		url: `${PM_ROUTES.EXPERIMENT.replace(":id", "" + dto.experimentId)}`,
		displayDate: getDisplayDate(dto.lastModifiedOn),
	};
};

const transformExperimentAttachmentResult = (
	dto: ExperimentAttachmentSearchResultDto
): PmSearchResult => {
	const user = dto.created_by as Avatar;
	return {
		iconType: dto.extension as AttachmentOrGenemodFile,
		title: dto.name,
		path: [dto.subprojectName, dto.experimentName].filter(Boolean),
		users: [`${user.first_name} ${user.last_name}`],
		url: `${PM_ROUTES.EXPERIMENT.replace(
			":id",
			"" + dto.experimentId
		)}?${qs.stringify({
			activeTab: "attachments",
			attachmentId: dto.id,
			attachmentType: "attachment",
		})}`,
		displayDate: getDisplayDate(dto.created_at),
	};
};

const transformSubprojectAttachmentResult = (
	dto: SubprojectAttachmentSearchResultDto
): PmSearchResult => {
	const user = dto.created_by as Avatar;
	return {
		iconType: dto.extension as AttachmentOrGenemodFile,
		title: dto.name,
		path: [dto.subprojectName],
		users: [`${user.first_name} ${user.last_name}`],
		url: "",
		displayDate: getDisplayDate(dto.created_at),
	};
};

const transformProjectAttachmentResult = (
	dto: ProjectAttachmentSearchResultDto
): PmSearchResult => {
	const user = dto.created_by as Avatar;
	return {
		iconType: dto.extension as AttachmentOrGenemodFile,
		title: dto.name,
		path: [],
		users: [`${user.first_name} ${user.last_name}`],
		url: `${PM_ROUTES.PROJECT}?${qs.stringify({
			tab: "attachments",
			attachmentId: dto.id,
			projectId: dto.projectId,
		})}`,
		displayDate: getDisplayDate(dto.created_at),
	};
};

export const toProjectSearchResults = (
	dto: ProjectSearchResultsDto
): ProjectSearchResults => {
	// rtkquery silently fails if this function fails, wrap it and log any errors
	try {
		return {
			experiments: {
				...dto.experiments,
				results: dto.experiments.results.map(transformExperimentResult),
			},
			files: {
				...dto.attachments,
				results: dto.attachments.results.map((r) => {
					const map = {
						experiment: transformExperimentAttachmentResult(
							r as ExperimentAttachmentSearchResultDto
						),
						subproject: transformSubprojectAttachmentResult(
							r as SubprojectAttachmentSearchResultDto
						),
						project: transformProjectAttachmentResult(
							r as ProjectAttachmentSearchResultDto
						),
					};
					return map[r.parentType];
				}),
			},
		};
	} catch (e) {
		console.error(e);
		throw e;
	}
};

/**
 * Shared filter params for a search query
 */
type CommonFilterParams = {
	query?: string;
	page?: number;
};

/**
 * Search query filters when searching both experiments and attachments
 */
type BaseSearchFilters = CommonFilterParams & {
	filterType: "all";
};

/**
 * Search query filters when searching for an experiment
 */
export type ExperimentSearchFilters = CommonFilterParams & {
	parent_project?: number;
	parent_subproject?: number;
	filterType: "experiments";
	users?: number[];
	lastModifiedOnStart?: ISOString;
	lastModifiedOnEnd?: ISOString;
};

/**
 * Search query filters when searching for an attachment
 */
export type AttachmentSearchFilters = CommonFilterParams & {
	filterType: "files";
	file_type?: string[];
	createdOnStart?: ISOString;
	createdOnEnd?: ISOString;
	creator?: number[];
};

/**
 * Search filter type, which can either be for the "base" type, experiment only, or attachment only.
 */
export type ProjectSearchFilters =
	| BaseSearchFilters
	| ExperimentSearchFilters
	| AttachmentSearchFilters;
