import React, { useEffect, useState } from "react";
import { Table as TB } from "antd";
import { TableProps } from "antd/lib/table";
import { Demo, DemoSection, DemoWrapper } from "@components";
import "./Table.scss";
import { CustomExpandIcon } from "./ResponsiveTable";
import { nanoid } from "nanoid";

export type GenemodTableProps<T> = TableProps<T> & {
	/** If we are controlling the table sort externally, this will be the column key (append "-" for descending sort).
	 * Will rerender table when sort changes */
	controlledSortKey?: string | null | undefined;
};

// See antd Table for full list of props
// Pagination, Bordered, and ExpandIconAsCell are all rendered to false as per UX/UI specs
// All children are open by default, to close table children on default declare defaultExpandAllRows = {false}
export function Table<T>(props: GenemodTableProps<T>) {
	const controlledKey = useControlledSort(props);

	return (
		<TB
			bordered={false}
			expandIconAsCell={false}
			pagination={false}
			expandIcon={(props) => CustomExpandIcon(props)}
			defaultExpandAllRows={true}
			locale={{ emptyText: <span /> }}
			{...props}
			key={controlledKey}
		>
			{props.children}
		</TB>
	);
}

/**
 * Adds "sortOrder" key if the "controlledSortKey" prop is given. Also provides a key to force the table to rerender when
 * the sort changes.
 */
export const useControlledSort = <T,>({
	controlledSortKey,
	columns,
	key,
}: GenemodTableProps<T> & { key?: string }) => {
	// Add sort key to column
	if (controlledSortKey && columns) {
		const order = controlledSortKey.startsWith("-") ? "descend" : "ascend";

		// need to remove the "-" from the start to find the matching column
		const rawKey =
			order === "ascend"
				? controlledSortKey
				: controlledSortKey.substring(1);

		const sortCol = columns.find(
			(col) => col.key === rawKey || col.dataIndex === rawKey
		);
		if (sortCol) {
			sortCol.sortOrder = order;
		}
	}

	// There is a bug, when we are using a controlled sort key, and we go from a "descend" sort
	// to no sort, the table does not rerender, we need to force it to rerender.
	const [controlledKey, setControlledKey] = useState(`${key}_${nanoid()}`);
	useEffect(() => {
		setControlledKey(`${key}_${nanoid()}`);
	}, [key, controlledSortKey]);

	return controlledKey;
};

export function TABLE_DEMO() {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Table Basic Uses" description="Table Basics">
					<DEMO1 />
				</Demo>
				<Demo
					title="Different Table Sizes"
					description="There are only Small and Middle sizes"
				>
					<DEMO2 />
				</Demo>
				<Demo title="Tiered Table" description="Layers of Table data">
					<DEMO3 />
				</Demo>
				<Demo title="Empty table" description="No data">
					<Table
						dataSource={[]}
						columns={[
							{ title: "Column 1", key: "col1" },
							{
								title: "Column 2",
								key: "col2",
							},
							{
								title: "Column 3",
								key: "col3",
							},
						]}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}

function DEMO1() {
	const dataSource = [
		{
			key: "1",
			name: "Mike",
			age: 32,
			address: "10 Downing Street",
		},
		{
			key: "2",
			name: "John",
			age: 42,
			address: "10 Downing Street",
		},
	];

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Age",
			dataIndex: "age",
			key: "age",
		},
		{
			title: "Address",
			dataIndex: "address",
			key: "address",
		},
	];

	return (
		<div>
			<Table dataSource={dataSource} columns={columns} />
		</div>
	);
}

function DEMO2() {
	const dataSource = [
		{
			key: "1",
			name: "Mike",
			age: 32,
			address: "10 Downing Street",
		},
		{
			key: "2",
			name: "John",
			age: 42,
			address: "10 Downing Street",
		},
	];

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Age",
			dataIndex: "age",
			key: "age",
		},
		{
			title: "Address",
			dataIndex: "address",
			key: "address",
		},
	];

	return (
		<div>
			<div style={{ marginBottom: "50px" }}>
				<Table
					dataSource={dataSource}
					columns={columns}
					size={"middle"}
				/>
			</div>
			<div>
				<Table
					dataSource={dataSource}
					columns={columns}
					size={"small"}
				/>
			</div>
		</div>
	);
}

function DEMO3() {
	const columns = [
		{
			title: "",
			dataIndex: "icon",
			key: "icon",
			width: "48px",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Age",
			dataIndex: "age",
			key: "age",
			width: "12%",
		},
		{
			title: "Address",
			dataIndex: "address",
			width: "30%",
			key: "address",
		},
	];

	const data = [
		{
			key: 1,
			name: "John Brown sr.",
			age: 60,
			address: "New York No. 1 Lake Park",
			children: [
				{
					key: 11,
					name: "John Brown",
					age: 42,
					address: "New York No. 2 Lake Park",
				},
				{
					key: 12,
					name: "John Brown jr.",
					age: 30,
					address: "New York No. 3 Lake Park",
					children: [
						{
							key: 121,
							name: "Jimmy Brown",
							age: 16,
							address: "New York No. 3 Lake Park",
						},
					],
				},
				{
					key: 13,
					name: "Jim Green sr.",
					age: 72,
					address: "London No. 1 Lake Park",
					children: [
						{
							key: 131,
							name: "Jim Green",
							age: 42,
							address: "London No. 2 Lake Park",
							children: [
								{
									key: 1311,
									name: "Jim Green jr.",
									age: 25,
									address: "London No. 3 Lake Park",
								},
								{
									key: 1312,
									name: "Jimmy Green sr.",
									age: 18,
									address: "London No. 4 Lake Park",
								},
							],
						},
					],
				},
			],
		},
		{
			key: 2,
			name: "Joe Black",
			age: 32,
			address: "Sidney No. 1 Lake Park",
		},
	];

	return (
		<div>
			<Table columns={columns} dataSource={data} />
		</div>
	);
}
