import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import styles from "./Layout.module.scss";
import { LayerSystemContainer } from "@components";

export default function Layout(props: {
	children: React.ReactNode;
}): JSX.Element {
	return (
		<div className={styles.genemodLayout}>
			<LayerSystemContainer className={styles.genemodLayoutRight}>
				<Sidebar />
				<div className={styles.genemodContent}>{props.children}</div>
			</LayerSystemContainer>
		</div>
	);
}
