const accountBaseRoute = "account-settings";
export const ACCOUNT_SETTINGS_PATHS = Object.freeze({
	BASE: {
		route: accountBaseRoute,
	},
	PROFILE: {
		route: accountBaseRoute + "/profile",
	},
	SECURITY: {
		route: accountBaseRoute + "/security",
	},
	NOTIFICATIONS: {
		route: accountBaseRoute + "/notifications",
	},
	PREFERENCE: {
		route: accountBaseRoute + "/preferences",
	},
	MANAGE_TEAM: {
		route: accountBaseRoute + "/manage-team",
	},
	SUBSCRIPTION: {
		route: accountBaseRoute + "/subscription",
	},
	CHANGE_PLAN: {
		route: accountBaseRoute + "/change-plan",
	},
	AUDIT_LOGS: {
		route: accountBaseRoute + "/audit-logs",
	},
});

const pmBaseRoute = "projects";
export const PM_ROUTES = Object.freeze({
	BASE: pmBaseRoute,
	HOME: pmBaseRoute + "/active-projects",
	PROJECT: pmBaseRoute + "/project/:state",
	FETCH_PROJECT: pmBaseRoute + "/fetch-project/:id",
	EXPERIMENT: pmBaseRoute + "/experiment/:id",
	// Preview protocols from the New Experiment screen
	PROTOCOL_LIBRARY: pmBaseRoute + "/protocol-library",
	PROTOCOL: pmBaseRoute + "/protocol",
	ARCHIVED_PROJECTS: pmBaseRoute + "/archived-projects",
	REPORTS: pmBaseRoute + "/reports",
	FETCH_EXPERIMENT: pmBaseRoute + "/fetch-experiment/:id",
});

const inventoryBaseRoute = "freezer";
export const FREEZER_PATHS = Object.freeze({
	BASE: inventoryBaseRoute,
	HOME: `${inventoryBaseRoute}/active-freezers`,
	CONSUMABLE: `${inventoryBaseRoute}/consumables`,
	ARCHIVE: `${inventoryBaseRoute}/inventory-archive`,
	CONTENTS: `${inventoryBaseRoute}/freezers/:id`,
	BOXES: `${inventoryBaseRoute}/freezers/:freezer_id/boxes/:box_id/`,
	ITEMGROUPS: `${inventoryBaseRoute}/freezers/:freezer_id/item-groups/:item_group_id/`,
	SEARCH: `${inventoryBaseRoute}/active-freezers/search/`,
	ITEMTYPE: `${inventoryBaseRoute}/itemtype/:id`,
	SPACE: `${inventoryBaseRoute}/consumables/spaces/:id`,
	FURNITURE_CATEGORIES: `${inventoryBaseRoute}/consumables/spaces/:space_id/furnitures/:furniture_id/categories/:furniture_category_id`,
	CONSUMABLE_SEARCH: `${inventoryBaseRoute}/consumables/search/`,
	STOCK_TRACKER: `${inventoryBaseRoute}/stock-tracker`,
	CREATE_FREEZER_STOCK_TRACKER: `${inventoryBaseRoute}/stock-tracker/freezer/create`,
	EDIT_FREEZER_STOCK_TRACKER: `${inventoryBaseRoute}/stock-tracker/freezer/edit`,
	CREATE_CONSUMABLE_STOCK_TRACKER: `${inventoryBaseRoute}/stock-tracker/consumable/create`,
	EDIT_CONSUMABLE_STOCK_TRACKER: `${inventoryBaseRoute}/stock-tracker/consumable/edit`,
	ITEM: `${inventoryBaseRoute}/item/:id`,
	BOX: `${inventoryBaseRoute}/box/:id`,
	SHELF: `${inventoryBaseRoute}/shelf/:id`,
	RACK: `${inventoryBaseRoute}/rack/:id`,
	CATEGORY: `${inventoryBaseRoute}/category/:id`,
	FURNITURE_CATEGORY: `${inventoryBaseRoute}/furniture_categories/:id`,
	ORDERS: `${inventoryBaseRoute}/orders`,
	ARCHIVED_ORDERS: `${inventoryBaseRoute}/orders/archived`,
	CREATE_ORDER: `${inventoryBaseRoute}/orders/create`,
	REPOSITORY: `${inventoryBaseRoute}/repository`,
} as const);

const officeBaseRoute = "office";
export const OFFICE_PATHS = Object.freeze({
	BASE: officeBaseRoute,
	EDITOR: `${officeBaseRoute}/editor`,
} as const);

const equipmentsBaseRoute = "core-facility";
export const EQUIPMENTS_PATHS = Object.freeze({
	BASE: equipmentsBaseRoute,
	SCHEDULES: `${equipmentsBaseRoute}/schedules`,
	EQUIPMENTS: `${equipmentsBaseRoute}/equipments`,
	EQUIPMENT: `${equipmentsBaseRoute}/equipment/:id`,
	CREATE_EQUIPMENT: `${equipmentsBaseRoute}/create-equipment`,
	EDIT_EQUIPMENT: `${equipmentsBaseRoute}/edit-equipment/:id`,
});
