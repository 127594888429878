import {
	Category,
	CategoryDataForBackend,
	RackStorageSummary,
	Box,
	PaginatedSearchResults,
	PaginatedSearchQuery,
} from "@common/types";
import { AtLeastId } from "@helpers/TypeHelpers";
import { inventoryApi, withFreezerBase } from ".";

export type RepositoryCategoryFilters = Omit<
	PaginatedSearchQuery &
		Partial<{
			id: number | null;
			ordering: string | null;
			search: string | null;
			status__in: number[] | null;
			created_at__gte: string | null;
			created_at__lte: string | null;
			created_by__in: string[] | null;
			updated_at__gte: string | null;
			updated_at__lte: string | null;
			updated_by__in: string[] | null;
			location__shelf: number | null;
			location__shelf__in: number[] | null;
			location__isnull: number;
			include_filter_options: boolean | null;
		}>,
	"sort_by"
>;
export type RepositoryCategoryResult = PaginatedSearchResults<Category>;

const categoryApi = inventoryApi.injectEndpoints({
	endpoints: (builder) => ({
		createCategory: builder.mutation<
			Category,
			{ categoryData: CategoryDataForBackend }
		>({
			query: ({ categoryData }) => ({
				url: withFreezerBase(`v2/categories/`),
				method: "POST",
				body: categoryData,
			}),
			invalidatesTags: ["Category"],
		}),
		categoriesList: builder.query<
			Category[],
			{ location__freezer?: number; is_archived: boolean }
		>({
			query: (params) => ({
				url: withFreezerBase(`v2/categories/`),
				params: params,
			}),
			providesTags: ["Category"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		category: builder.query<Category, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/categories/${id}/`),
			}),
			providesTags: ["Category"],
		}),
		categoryPatch: builder.mutation<Category, AtLeastId<Category>>({
			query: (body) => ({
				url: withFreezerBase(`v2/categories/${body.id}/`),
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["Category"],
		}),
		categoryDelete: builder.mutation<void, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/categories/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["Category"],
		}),
		categoryStorageSummary: builder.query<RackStorageSummary, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/categories/${id}/storage-summary/`),
			}),
			providesTags: ["Category"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		categoryBoxes: builder.query<
			Box[],
			{
				id: number;
				is_archived?: boolean;
			}
		>({
			query: ({ id, is_archived = false }) => ({
				url: withFreezerBase(`v2/categories/${id}/boxes/`),
				params: {
					is_archived,
				},
			}),
			providesTags: ["Box"],
		}),
		getRepositoryCategories: builder.query<
			RepositoryCategoryResult,
			RepositoryCategoryFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/categories-repository/"),
				method: "GET",
				params,
			}),
			providesTags: ["Category"],
		}),
		restorableCategories: builder.query<
			RepositoryCategoryResult,
			RepositoryCategoryFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/restorable-categories/"),
				method: "GET",
				params,
			}),
			providesTags: ["Category"],
		}),
		getRepositoryCategory: builder.query<Category, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/categories-repository/${id}/`),
				method: "GET",
			}),
			providesTags: ["Category"],
		}),
		categoryBulkCreate: builder.mutation<
			Category[],
			CategoryDataForBackend[]
		>({
			query: (body) => ({
				url: withFreezerBase("v2/categories-repository/bulk-create/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Category"],
		}),
		categoryBulkUpdate: builder.mutation<
			Category[],
			Partial<CategoryDataForBackend>[]
		>({
			query: (body) => ({
				url: withFreezerBase("v2/categories-repository/bulk-update/"),
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["Category", "ItemGroup", "Box", "Item"],
		}),
		categoryBulkDelete: builder.mutation<void, { category_ids: number[] }>({
			query: (body) => ({
				url: withFreezerBase("v2/categories-repository/bulk-delete/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Category"],
		}),
	}),
});

export const {
	useCreateCategoryMutation,
	useCategoriesListQuery,
	useLazyCategoriesListQuery,
	useCategoryQuery,
	useCategoryPatchMutation,
	useCategoryDeleteMutation,
	useCategoryStorageSummaryQuery,
	useCategoryBoxesQuery,
	useGetRepositoryCategoriesQuery,
	useRestorableCategoriesQuery,
	useGetRepositoryCategoryQuery,
	useCategoryBulkCreateMutation,
	useCategoryBulkUpdateMutation,
	useCategoryBulkDeleteMutation,
} = categoryApi;
