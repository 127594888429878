import React from "react";
import { LoadingSpinner } from "@components";
import { useParams, Redirect } from "@common/helpers/Hooks/UseRouterDom";

import { FREEZER_PATHS } from "..";
import { useOrganizationRouter } from "@root/AppRouter";
import { useBoxQuery } from "@redux/inventory/Box";
import { useItemQuery } from "@redux/inventory/Item";

/**
 * Fetches item information and reroutes the user to the box/category page.
 * TODO Add UI for 404 instead of immediately redirecting
 * TODO Convert box view item_id param to item.id instead of cell https://genemod.atlassian.net/browse/DASH-1845
 */
export default function ItemReroute() {
	const { id: item_id } = useParams<{ id: string }>();
	const itemQuery = useItemQuery(Number(item_id));
	const boxQuery = useBoxQuery(
		Number(itemQuery.data?.location?.box_location?.box),
		{
			skip: itemQuery.isError || !itemQuery.data,
		}
	);
	const { appendBaseUrl } = useOrganizationRouter();

	if (
		itemQuery.isError &&
		"status" in itemQuery.error &&
		itemQuery.error.status === 404
	) {
		// Reroute to freezer home page
		return <Redirect to={FREEZER_PATHS.HOME} />;
	}

	// Reroute to box page
	if (itemQuery.isSuccess && boxQuery.isSuccess) {
		const { data: item } = itemQuery;
		const params = new URLSearchParams({
			item_id: String(item.id),
		});

		const route =
			FREEZER_PATHS.BOXES.replace(
				":freezer_id",
				String(item.location?.freezer)
			).replace(":box_id", String(item.location?.box_location?.box)) +
			"?" +
			params.toString();

		return <Redirect to={appendBaseUrl(route)} />;
	}

	// Reroute afterwards
	return <LoadingSpinner loading centered size="large" />;
}
