import Button from "@common/components/Button";
import { GridCoord } from "@common/components/InteractiveGrid/GridTypes";
import { Box, Category, Item, Rack } from "@common/types";
import {
	ButtonV2,
	GenemodIcon,
	Modal,
	Spin,
	Tooltip,
	Typography,
} from "@components";
import { getColumnLabel, getRowLabel } from "@containers/Freezer/data";
import { default as classnames, default as cn } from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { MovingCartContext, useCartPlacement } from "../..";
import UnsavedChangesModal from "../../Modals";
import ItemPillButton from "../ItemPillButton";
import styles from "./index.module.scss";

import { useBoxView } from "@containers/Freezer/table/BoxTableHooks";
import { useBoxQuery } from "@redux/inventory/Box";
import { useCategoryQuery } from "@redux/inventory/Category";
import { useFreezerQuery } from "@redux/inventory/Freezer";
import {
	useBoxItemsQuery,
	useMoveItemLocationMutation,
} from "@redux/inventory/Item";
import { useLazyRackQuery, useRackQuery } from "@redux/inventory/Rack";
import { useShelfQuery } from "@redux/inventory/Shelf";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { AXIS_DIRECTION } from "@common/types";

type ItemPlacementProps = {
	/** items which user wants to move */
	items?: Item[];
	/** id of current box for moving items*/
	boxId: number;
	/** Callback function when the user navigates backwards. */
	clearBox?: () => void;
	/** callback function after finish to move items */
	handleMovingCart?: (itemsData: Item[], box: Box) => void;
	/**
	 * Display mode. All onClick actions are disabled
	 * and buttons hidden.
	 */
	viewOnly?: boolean;
	cellSize?: number;
	className?: string;
};

export default function ItemPlacement({
	items = [],
	boxId,
	clearBox,
	handleMovingCart,
	viewOnly,
	cellSize = 48,
	className,
}: ItemPlacementProps): JSX.Element {
	const { data: boxItems } = useBoxItemsQuery(
		{
			id: boxId,
		} || skipToken
	);
	const { data: currentBox } = useBoxQuery(boxId || skipToken);
	const { data: rack } = useRackQuery(
		currentBox?.location.rack_location?.rack ?? skipToken
	);
	const { data: category } = useCategoryQuery(
		currentBox?.location.category ?? skipToken
	);

	let rackOrCategory: Rack | Category | null = null;
	if (rack) {
		rackOrCategory = rack;
	} else if (category) {
		rackOrCategory = category;
	}

	const { data: shelf } = useShelfQuery(
		rackOrCategory?.location.shelf ?? skipToken
	);
	const { data: freezer } = useFreezerQuery(shelf?.freezer ?? skipToken);
	const [fetchRack] = useLazyRackQuery();
	const [moveItemLocation] = useMoveItemLocationMutation();
	const { refetchItems } = useBoxView();

	const [loading, setLoading] = useState(false);
	const cartPlacement = useCartPlacement();
	const placedItemDict: Record<string, GridCoord> =
		cartPlacement.getLocations();
	const [confirmUnmoved, setConfirmUnmoved] = useState(false);
	const [confirmUnsaved, setConfirmUnsaved] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState<number | undefined>(
		items[0]?.id
	);
	const {
		rackId,
		categoryId,
		setConfirmMoveFreezer,
		setRack,
		setCategory,
		setShelf,
		setFreezer,
	} = useContext(MovingCartContext);

	useEffect(() => {
		if (!viewOnly) {
			window.onbeforeunload = (event: BeforeUnloadEvent) => {
				if (cartPlacement.getCount() > 0) {
					const e = event || window.event;
					e.preventDefault();
					if (e) {
						e.returnValue = "";
					}
					return "";
				}
				return null;
			};
			setConfirmMoveFreezer(cartPlacement.getCount() > 0);
		}
	}, [cartPlacement, viewOnly]);

	useEffect(() => {
		if (rackId && rackId === -1 && currentBox && !viewOnly) {
			// init the parents id(freezer/ shelf / rack) for MovingCart Modal.
			setRack(currentBox.location.rack_location?.rack || -1);
			setCategory(currentBox.location.category || -1);
			setFreezer(currentBox.location.freezer);
			fetchRack(currentBox.location.rack_location?.rack || -1)
				.unwrap()
				.then((rack) => {
					setShelf(rack.location.shelf);
				});
		}
	}, [currentBox]);

	useEffect(() => {
		// Remove the unbeforeunload message. Otherwise it will show up after we close the modal
		return () => {
			window.onbeforeunload = () => null;
			clearBox?.();
			setConfirmMoveFreezer(false);
		};
	}, []);

	// get CellID (ex. A1) using row and col index (0, 0) => A1
	const getCellIDFromLocation = (row: number, col: number) => {
		return String.fromCharCode(65 + col) + (row + 1);
	};

	const handleItemPlaced = (row: number, col: number, id: number) => {
		cartPlacement.placeIdAtLocation(id, row, col);
		const placedItemIds = Object.keys(placedItemDict).map((id) =>
			parseInt(id)
		);
		const placedItemIndex = items.findIndex((item) => item.id === id);
		const nextItem =
			items
				.slice(placedItemIndex)
				.find(
					(item) => item.id !== id && !placedItemIds.includes(item.id)
				) ||
			items
				.slice(0, placedItemIndex)
				.find(
					(item) => item.id !== id && !placedItemIds.includes(item.id)
				);
		setSelectedItemId(nextItem?.id);
	};

	const handleUndoItem = (
		row: number,
		col: number,
		id: number | null = null
	) => {
		cartPlacement.clearLocation(row, col);
		const deletedId = id ? id : cartPlacement.getIdAtLocation(row, col);
		setSelectedItemId(deletedId);
	};

	const handleMoveItem = (partialMove = false) => {
		if (!currentBox) return;
		setLoading(true);
		const placedKeys = Object.keys(placedItemDict).map((id) =>
			parseInt(id)
		);

		if (!partialMove && placedKeys.length !== items.length) {
			// show confirm partial move Modal
			setConfirmUnmoved(true);
			setLoading(false);
			return;
		}
		const itemList = partialMove
			? items.filter((item) => placedKeys.includes(item.id))
			: items;
		const newItemList = itemList.map((item) => {
			const location = placedItemDict[item.id];
			const updateItem = {
				id: item.id,
				new_location: {
					box: boxId,
					row: location.row,
					column: location.column,
				},
				old_location: {
					id: item.id,
					box: item.location?.box_location?.box ?? boxId,
					row: item.location?.box_location?.row ?? location.row,
					column:
						item.location?.box_location?.column ?? location.column,
				},
			};
			return updateItem;
		});
		setConfirmUnmoved(false);
		Promise.allSettled(
			newItemList.map((item) => moveItemLocation(item))
		).finally(() => {
			refetchItems();
			handleMovingCart?.(itemList, currentBox);
			setLoading(false);
		});
	};

	const handleNavigationBack = (confirmed = false) => {
		if (confirmed || !Object.keys(placedItemDict).length) {
			setConfirmUnsaved(false);
			clearBox?.();
		}
		setConfirmUnsaved(true);
	};

	// render header of the ItemPlacement
	const renderHeader = () => (
		<div className={styles.header}>
			<div className={styles.headerBlock}>
				<Typography
					className={styles.headerName}
					ellipsis
					hideTooltip
					variant="label"
					onClick={() => {
						setShelf(null);
						setRack(null);
						setCategory(null);
						clearBox?.();
					}}
				>
					{freezer?.name}
				</Typography>
				<GenemodIcon name="chevron-right" />
				<Typography
					className={styles.headerName}
					ellipsis
					hideTooltip
					variant="label"
					onClick={() => {
						setRack(null);
						setCategory(null);
						clearBox?.();
					}}
				>
					{shelf?.name}
				</Typography>
				<GenemodIcon name="chevron-right" />
				<Typography
					className={styles.headerName}
					ellipsis
					hideTooltip
					variant="label"
					onClick={clearBox}
				>
					{rackOrCategory?.name}
				</Typography>
				<GenemodIcon name="chevron-right" />
				<Typography
					className={styles.headerName}
					ellipsis
					hideTooltip
					variant="label"
					bold
				>
					{currentBox?.name}
				</Typography>
			</div>
			<div className={styles.headerBlock}>
				<Tooltip
					placement="left"
					title="Click on a “+” button to place an item"
					trigger="click"
				>
					<GenemodIcon
						name="question-mark-o"
						size="large"
						fill="text-secondary"
					/>
				</Tooltip>
				<ButtonV2
					disabled={!Object.keys(placedItemDict).length}
					size="small"
					style={{ marginLeft: "8px" }}
					onClick={() => handleMoveItem()}
					type="primary"
					loading={loading}
				>
					Move here
				</ButtonV2>
			</div>
		</div>
	);

	// To render Item table
	const renderItemTable = () => {
		if (!boxItems || !currentBox) {
			return <Spin />;
		}
		const num_rows = currentBox.rows;
		const num_cols = currentBox.columns;
		const col_header = Array(num_cols + 1).fill(0);
		const row_header = Array(num_rows + 1).fill(0);

		// Key is row + col concatenated as a string.
		const itemByLoc =
			boxItems.reduce<Record<string, Item>>((acc, curr) => {
				acc[
					`${curr.location?.box_location?.row}${curr.location?.box_location?.column}`
				] = curr;
				return acc;
			}, {}) || {};

		return (
			<div
				className={styles.cellTable}
				style={{
					gridTemplateColumns: `${
						cellSize / 2
					}px repeat(${num_cols},${cellSize}px)`,
					gridTemplateRows: `${
						cellSize / 2
					}px repeat(${num_rows},${cellSize}px)`,
				}}
			>
				{row_header.map((_, row_index) => {
					return col_header.map((_, col_index) => {
						//row_index = 0 or col_index 0 is header of Table
						const cellRow = row_index - 1;
						const cellCol = col_index - 1;
						const cellId = getCellIDFromLocation(cellRow, cellCol);
						const occupiedCell = itemByLoc[`${cellRow}${cellCol}`];

						if (row_index === 0 || col_index === 0) {
							//render table header
							return (
								<Typography
									key={cellId}
									variant="label"
									color="text-secondary"
									className={cn({
										[styles.topCell]: row_index === 0,
										[styles.leftCell]: col_index === 0,
									})}
									bold
								>
									{row_index === 0 && col_index === 0
										? ""
										: col_index === 0
										? getRowLabel(
												row_index,
												currentBox.axis_direction
										  )
										: getColumnLabel(
												col_index,
												currentBox.axis_direction
										  )}
								</Typography>
							);
						}
						const placedItemIndex =
							items.findIndex(
								(item) =>
									item.id ===
									cartPlacement.getIdAtLocation(
										cellRow,
										cellCol
									)
							) + 1;
						return (
							<div
								key={cellId}
								className={cn(styles.cell, {
									[styles.cell_viewOnly]: viewOnly,
									[styles.cell_occupied]: !!occupiedCell,
									[styles.cell_placed]: !!placedItemIndex,
									[styles.cell_placedAll]:
										!selectedItemId && !viewOnly,
								})}
								onClick={
									viewOnly
										? () => {}
										: () =>
												placedItemIndex
													? handleUndoItem(
															cellRow,
															cellCol
													  )
													: !occupiedCell
													? handleItemPlaced(
															cellRow,
															cellCol,
															selectedItemId as number
													  )
													: null
								}
							>
								{placedItemIndex ? (
									<>
										<Typography
											className={styles.cellPlaceText}
											variant="label"
										>
											{placedItemIndex}
										</Typography>
										<div className={styles.cellUndoButton}>
											<GenemodIcon
												name="undo"
												fill="button-primary"
												lightmode
											/>
										</div>
									</>
								) : !occupiedCell ? (
									<GenemodIcon
										className={styles.cellAddButton}
										lightmode
										size={viewOnly ? "small" : "default"}
										name="plus"
										decorative={viewOnly}
									/>
								) : (
									<Typography
										className={styles.occupiedCellName}
										color="text-ghost"
									>
										{occupiedCell?.name}
									</Typography>
								)}
							</div>
						);
					});
				})}
			</div>
		);
	};

	const getLocation = (
		location: GridCoord | undefined,
		currentBox: Box | undefined
	) => {
		if (!location || !currentBox) return "";
		const currentLocation =
			currentBox.axis_direction ===
			AXIS_DIRECTION.LETTER_ROWS_NUMBER_COLUMNS
				? getCellIDFromLocation(location.column, location.row)
				: getCellIDFromLocation(location.row, location.column);
		return currentLocation;
	};

	const renderItemPillButtons = () => {
		return items.map((item, index) => {
			const location: GridCoord = placedItemDict[item.id];
			return (
				<div key={index} className={styles.itemContainer}>
					<Typography
						variant="label"
						className={styles.itemListIndex}
					>
						{index + 1}
					</Typography>
					<ItemPillButton
						name={item.name}
						focused={selectedItemId === item.id}
						placedAt={getLocation(location, currentBox)}
						onClick={() => {
							if (!location) {
								setSelectedItemId(item.id);
							}
						}}
						undoOnClick={() => {
							if (location)
								handleUndoItem(
									location.row,
									location.column,
									item.id
								);
						}}
					/>
				</div>
			);
		});
	};

	return (
		<div
			className={cn(
				styles.itemPlacementContainer,
				{
					[styles.viewOnly]: viewOnly,
				},
				className
			)}
		>
			<div className={styles.itemScrollableArea}>
				<div className={styles.itemPlacement}>
					{!viewOnly && renderHeader()}
					<div
						className={classnames(
							styles.cellTableContainer,
							"genemod-lightmode"
						)}
					>
						{renderItemTable()}
					</div>
				</div>
			</div>
			{!viewOnly && (
				<>
					<div className={styles.itemListWrapper}>
						<div className={styles.itemListContainer}>
							{renderItemPillButtons()}
						</div>
					</div>
					<UnmovedItemModal
						visible={confirmUnmoved}
						nPlacedItems={Object.keys(placedItemDict).length}
						nTotalItems={items.length}
						onOk={() => handleMoveItem(true)}
						onCancel={() => setConfirmUnmoved(false)}
					/>
					<UnsavedChangesModal
						visible={confirmUnsaved}
						onCancel={() => setConfirmUnsaved(false)}
						onOk={() => handleNavigationBack(true)}
					/>
				</>
			)}
		</div>
	);
}

type UnmovedItemModalProps = {
	visible: boolean;
	/** number of placed itme to move */
	nPlacedItems: number;
	/** number of items user selected to move */
	nTotalItems: number;
	onOk: () => void;
	onCancel: () => void;
};

function UnmovedItemModal({
	visible,
	nPlacedItems,
	nTotalItems,
	onOk,
	onCancel,
}: UnmovedItemModalProps) {
	return (
		<Modal
			visible={visible}
			title={`${nPlacedItems} of ${nTotalItems} items moved`}
			okText={`Move ${nPlacedItems} ${
				nPlacedItems === 1 ? "item" : "items"
			}`}
			onOk={onOk}
			onCancel={onCancel}
			hideCancelButton
		>
			<Typography>
				The unplaced items will be returned to its original location.
				Close this modal to continue moving.
			</Typography>
		</Modal>
	);
}
