import { ReactEditor } from "slate-react";
import { Editor, Range } from "slate";

export const Node = {
	/**
	 * Type of the node
	 */
	type: "",

	/**
	 * @param {Object} props
	 * @param {Object} props.attributes
	 * @param {Object} props.children
	 * @param {Node} [props.leaf]
	 * @param {Node} [props.element]
	 * @param {Node} node Same as props.leaf or props.element
	 * @param {Editor} editor Slate editor instance
	 * @returns {Object} The provided props object with or without modifications
	 */
	render(props) {
		return props;
	},

	/**
	 * List of hotkey configurations. See `KeyboardEvent`
	 */
	hotkeys: [],

	/**
	 * Hotkey handler
	 * @param {Editor} editor Editor instance
	 * @param {Object} hotkey Matching hotkey configuration
	 * @returns {Boolean} True if the next hotkey handler should be evaluated
	 */
	onHotkey() {
		return true;
	},

	/**
	 * Attaches features to the editor instance
	 * @param {Editor} editor Editor instance
	 * @returns {Editor} A modified editor instance
	 */
	plugin(editor) {
		return editor;
	},

	/**
	 * @returns {Boolean} True if this node is active at the current selection
	 */

	/**
	 * Focuses the editor instance
	 * @param {Editor} editor
	 */
	focusEditor(editor) {
		ReactEditor.focus(editor);
	},

	/**
	 * @param {Editor} editor
	 * @param {Node} [node=this]
	 * @returns {Boolean} True if the node is active at the selection
	 */
	isActive(editor, node = this) {
		const { selection } = editor;
		const [...matches] = Editor.nodes(editor, {
			at: selection,
			match: (n) => n.type === node.type,
		});
		return !!matches.length;
	},

	/**
	 * Adds this node and any required nodes to the list of editor nodes
	 * @param {Editor} editor
	 */
	register(editor) {
		editor.registerNode(this);
	},

	/**
	 * @param {Editor} editor
	 * @param {"left" | "right"} edge
	 * @returns {Boolean} True if the user's selection is at the edge of a cell
	 */
	isAtEdge(editor, edge = "left") {
		const { selection } = editor;
		if (!selection) return false;

		const [start, end] = Range.edges(selection);
		if (edge === "left") {
			return start.offset === 0;
		} else if (edge === "right") {
			// Get text node length
			const [textNode] = Editor.nodes(editor, {
				at: selection,
				match: (n) => "text" in n,
				mode: "lowest",
			});
			if (!textNode) return false;
			return end.offset === textNode[0].text.length;
		}
	},
};
