import { DropDown, GenemodFilter, Typography } from "@common/components";
import styles from "../FreezerTab.module.scss";
import React from "react";
import { Menu } from "antdv5";
import { useRepository } from "../../RepositoryContext";
import SearchBarV2 from "@common/components/SearchBarV2/SearchBarV2";
import { FreezerLayers } from "../FreezerTab";
import classNames from "classnames";

export const NameColumnTitle = ({
	selectedLayer,
	title,
}: {
	selectedLayer: FreezerLayers | undefined;
	title?: string | JSX.Element;
}) => {
	const { search, setSearch, debounceSearch, handleSearchText } =
		useRepository();

	const filterApplied = search !== "";

	return (
		<div className={classNames(styles.columnHeader, "active-ordering")}>
			<Typography bold className="active-ordering">
				{title}
			</Typography>
			<DropDown
				overlayClassName={styles.dropdown}
				overlay={
					<Menu className={styles.menu}>
						<div className={styles.items}>
							<SearchBarV2
								placeholder={`Search by ${selectedLayer?.toLowerCase()} name`}
								className={styles.searchBar}
								value={search}
								onChange={(val) => {
									setSearch(val);
									if (val.length === 0 || val.trim()) {
										debounceSearch(val);
									}
								}}
								onSelect={() => handleSearchText(search)}
								disableSuggestions
								dataCy="repository-search"
							/>
						</div>
					</Menu>
				}
				getPopupContainer={() => document.body}
			>
				<div style={{ marginLeft: "auto" }} data-cy="location-filter">
					<GenemodFilter
						applied={filterApplied}
						wrapperClassName={
							!filterApplied ? "isOnRepository" : ""
						}
					/>
				</div>
			</DropDown>
		</div>
	);
};
