import React from "react";
import { Editor, Node, BaseElement } from "slate";
import {
	overrideFunction,
	RegisteredRenderElementsEditor,
} from "./PluginHelpers";
import { GenemodAnchorme } from "@components";

export const DETECTED_LINK_TYPE = "detected-link";

export type DetectedLinkElement = BaseElement & {
	type: typeof DETECTED_LINK_TYPE;
	url: string;
};

/**
 * Uses the anchorme plugin to automatically find links in the document contents and add the links.
 */
export const withAnchorMePlugin = <
	T extends Editor & RegisteredRenderElementsEditor
>(
	editor: T
): T => {
	overrideFunction(editor)("renderLeaves")((renderLeaves) => (props) => {
		if (editor.readOnly) {
			props.children = (
				<span {...props.attributes}>
					<GenemodAnchorme
						target="_blank"
						rel="noopener"
						contentEditable={false}
					>
						{Node.string(props.text)}
					</GenemodAnchorme>
					<div style={{ display: "none" }}>{props.children}</div>
				</span>
			);
		}
		return renderLeaves(props);
	});

	return editor;
};
