import React from "react";
import { GenemodSkeleton } from "@components";
import { ResponsiveTableProps } from "./ResponsiveTable";

export type LoadingSkeletonTableMixinProps = {
	/* Use the "renderSkeleton" function on each column when the table is loading */
	useLoadingSkeleton?: boolean;
	/* Number of skeleton rows to load */
	loadingSkeletonLength?: number;
};

export type LoadingSkeletonColumnMixinProps = {
	renderSkeleton?: "BLANK" | (() => React.ReactNode);
};

/**
 * When "loadingSkeleton" prop on table is true and the table is loading then we override the render function for the columns to
 * render a skeleton.
 */
export const useLoadingSkeletonMixin = <T,>(
	props: ResponsiveTableProps<T>
): ResponsiveTableProps<T> => {
	const {
		useLoadingSkeleton: loadingSkeleton,
		loadingSkeletonLength = 6,
		...filteredProps
	} = props;

	const { loading, columns } = props;

	let cleanedProps = filteredProps;

	if (loading && loadingSkeleton) {
		const { components, ...another } = cleanedProps;
		cleanedProps = another;
	}

	const showLoadingSkeletons = loading && loadingSkeleton;

	if (!showLoadingSkeletons) {
		return cleanedProps;
	}

	return {
		...cleanedProps,
		// remove loading, we are custom rendering instead
		loading: false,
		columns: columns?.map((col) => ({
			...col,
			render: () => {
				if (col.renderSkeleton === "BLANK") {
					return <></>;
				}
				if (!col.renderSkeleton) {
					return (
						<GenemodSkeleton
							style={{
								backgroundColor: "var(--border-subtle)",
							}}
							height={12}
							shape="round"
						/>
					);
				}
				return col.renderSkeleton();
			},
		})),
		dataSource: new Array(loadingSkeletonLength)
			.fill({})
			.map((_, id) => ({ key: id })) as any,
	};
};
