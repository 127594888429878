import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			data-name="type_image (x48)"
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			{...props}
		>
			<path
				data-name="Icon material-image"
				d="M36.5 32.944V8.056A3.566 3.566 0 0 0 32.944 4.5H8.056A3.566 3.566 0 0 0 4.5 8.056v24.888A3.566 3.566 0 0 0 8.056 36.5h24.888a3.566 3.566 0 0 0 3.556-3.556zm-22.222-9.777 4.444 5.351 6.222-8.018 8 10.667H8.056z"
				transform="translate(3.5 3.5)"
				style={{ fill: "#c1c1c3" }}
			/>
		</svg>
	);
}
const ImageIcon = React.memo(SvgComponent);
export default ImageIcon;
