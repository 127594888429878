import React, { useEffect, useRef, useState, useCallback } from "react";
import { GenemodSkeleton } from "@common/components";
import domtoimage from "dom-to-image"; // https://github.com/tsayen/dom-to-image

type ComponentThumbnailProps = {
	children: React.ReactNode;
	onThumbnailReady?: (thumbnail: HTMLImageElement) => void;
	customThumbnailAlt?: string;
	imageStyle?: React.CSSProperties;
	// debounceDelay is the delay in milliseconds before the thumbnail is generated
	debounceDelay?: number;
	// image quality is a number between 0 and 1
	imageQuality?: number;
};

/**
 * ComponentThumbnail is a component that generates a thumbnail of the children
 *
 *
 * Better use for:
 * - Single or very few instances: If you are only rendering a single instance of the thumbnail component or very few instances.
 * - Thumbnails that are immediately visible: If the thumbnails are always visible on the initial screen, there is no advantage to using lazy loading, as they would be generated immediately anyway.
 */

function ComponentThumbnail({
	children,
	onThumbnailReady,
	customThumbnailAlt,
	imageStyle,
	debounceDelay = 300,
	imageQuality,
}: ComponentThumbnailProps) {
	const [thumbnail, setThumbnail] = useState<HTMLImageElement | null>(null);
	const previewRef = useRef<HTMLDivElement | null>(null);

	const generateImage = useCallback(() => {
		if (previewRef.current) {
			domtoimage
				.toJpeg(previewRef.current, { quality: imageQuality })
				.then((dataUrl) => {
					const img = new Image();
					img.src = dataUrl;

					img.onload = () => {
						setThumbnail(img);
						if (onThumbnailReady) {
							onThumbnailReady(img);
						}
					};
				})
				.catch((error) => {
					console.error("Error generating thumbnail", error);
				});
		}
	}, [children]);

	useEffect(() => {
		const debounceTimeout = setTimeout(generateImage, debounceDelay);

		return () => {
			clearTimeout(debounceTimeout);
		};
	}, [generateImage]);

	return (
		<>
			<div
				ref={previewRef}
				style={
					!thumbnail
						? {
								width: "100%",
								height: "100%",
								position: "relative",
						  }
						: { display: "none" }
				}
			>
				{children}
				{!thumbnail && (
					<GenemodSkeleton
						width={"100%"}
						height={"100%"}
						animate
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							backgroundColor: "var(--layer-02)",
						}}
					/>
				)}
			</div>
			{!thumbnail ? (
				<GenemodSkeleton width={"100%"} height={"100%"} animate />
			) : (
				thumbnail && (
					<img
						src={thumbnail.src}
						style={{
							width: "100%",
							height: "100%",
							...imageStyle,
						}}
						alt={customThumbnailAlt || "Component Thumbnail"}
					/>
				)
			)}
		</>
	);
}

export default React.memo(ComponentThumbnail, (prevProps, nextProps) => {
	return prevProps.children === nextProps.children;
});

/**
 * LazyComponentThumbnail is a component that generates a thumbnail of the children
 * when the component is visible in the viewport. This one utilizes IntersectionObserver
 * to determine when the component is visible.
 *
 * Better use in situations where you have multiple instances of the thumbnail component rendering within a list, grid, or any other scrollable container
 */
export function LazyComponentThumbnail(props: ComponentThumbnailProps) {
	const [isVisible, setIsVisible] = useState(false);
	const [generatedImage, setGeneratedImage] =
		useState<HTMLImageElement | null>(null);
	const ref = useRef<HTMLDivElement | null>(null);

	const handleThumbnailReady = useCallback(
		(thumbnail: HTMLImageElement) => {
			setGeneratedImage(thumbnail);
			if (props.onThumbnailReady) {
				props.onThumbnailReady(thumbnail);
			}
		},
		[props]
	);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					setIsVisible(true);
					observer.disconnect();
				}
			},
			{
				rootMargin: "200px", // Adjust this value to control when the thumbnail should be generated
			}
		);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	if (generatedImage) {
		return (
			<img
				src={generatedImage.src}
				style={{
					width: "100%",
					height: "100%",
					...props.imageStyle,
				}}
				alt={props.customThumbnailAlt || "Component Thumbnail"}
			/>
		);
	}

	return (
		<div ref={ref}>
			{isVisible && (
				<ComponentThumbnail
					{...props}
					onThumbnailReady={handleThumbnailReady}
				/>
			)}
		</div>
	);
}
