import ContactUsForm, {
	ContactUsFormData,
	defaultContactFormData,
} from "@containers/ContactUs/components/ContactUsForm";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import React, { useState } from "react";
import {
	SidePanel,
	Button,
	GenemodIcon,
	Typography,
	Notification,
} from "@components";
import styles from "./index.module.scss";
import { useContactUsSendMutation } from "@redux/Dashboard/DashboardApiSlice";
import SUCCESS_ICON_IMG from "./assets/success-icon.png";
import {
	useGetOrgUsersQuery,
	useGetOrganizationsQuery,
} from "@redux/team/TeamApi";

export default function ContactUsPanel(): JSX.Element {
	const [formData, setFormData] = useState<ContactUsFormData>(
		defaultContactFormData
	);
	const [submitClicked, setSubmitClicked] = useState<boolean>(false);
	const { isContactUsPanelVisible, closeContactUsPanel } =
		useCommonModalState("contactUsPanel");
	const [showSuccessMessage, setShowSuccessMessage] =
		useState<boolean>(false);
	const [sendContantUs] = useContactUsSendMutation();
	const { data: orgs } = useGetOrganizationsQuery();
	const { data: orgUsers } = useGetOrgUsersQuery(orgs?.[0].id || -1, {
		skip: !orgs,
	});
	const owner = orgUsers?.find((user) => user.is_owner);
	const userEmail =
		owner?.user?.email || orgUsers?.[0]?.user?.email || "unknown";

	const handleSubmit = () => {
		setSubmitClicked(true);
		sendContantUs(formData)
			.unwrap()
			.then((res) => {
				const { detail } = res;
				if (detail !== "Wrong Request") {
					setShowSuccessMessage(true);
					setFormData(defaultContactFormData);
				}
			})
			.catch((err) => {
				console.error(err);
				Notification.warning({
					message: "Failed to send message: " + err,
				});
			});
	};

	const onPanelClose = () => {
		closeContactUsPanel();
		setShowSuccessMessage(false);
	};

	return (
		<SidePanel
			dataCy="contact-us-panel"
			isVisible={isContactUsPanelVisible}
			onClose={onPanelClose}
			drawerBodyStyle={{
				padding: 0,
			}}
			hideHeader
			zIndex={1001}
		>
			{!showSuccessMessage ? (
				<div className={styles.formContainer}>
					<div className={styles.headerAndForm}>
						<div className={styles.panelHeader}>
							<GenemodIcon
								name="arrow-forward"
								size="large"
								stroke="text-primary"
								style={{ transform: "rotate(180deg)" }}
								onClick={closeContactUsPanel}
							/>
							<Typography variant="title" bold>
								Contact us
							</Typography>
						</div>
						<ContactUsForm
							data={formData}
							onChange={setFormData}
							setSubmitClicked={setSubmitClicked}
							submitClicked={submitClicked}
						/>
					</div>
					<div className={styles.submitFooter}>
						<Button
							style={{
								pointerEvents: "visible",
							}}
							disabled={
								formData.description.length === 0 ||
								formData.support_email_topic === "0"
							}
							onClick={handleSubmit}
							size="small"
						>
							Send message
						</Button>
					</div>
				</div>
			) : (
				<div
					className={styles.success}
					data-cy="contact-us-panel-success"
				>
					<div className={styles.successWrapper}>
						<img src={SUCCESS_ICON_IMG} alt="Success" />
						<Typography
							variant="subheadline"
							bold
							style={{ marginTop: 32 }}
						>
							Message received!
						</Typography>
						<Typography
							color="text-secondary"
							style={{ marginTop: 16 }}
						>
							We will get back to you via {userEmail} shortly
						</Typography>
						<Button
							onClick={onPanelClose}
							style={{ marginTop: 24 }}
							size="small"
						>
							Close panel
						</Button>
					</div>
				</div>
			)}
		</SidePanel>
	);
}
