import React, { useEffect, useMemo, useState } from "react";
import { ReactEditor, withReact } from "slate-react";
import { createEditor, Descendant, Editor, Transforms } from "slate";
import {
	MENTION_INPUT_TYPE,
	MentionType,
	useMentionsPlugin,
} from "../Editor/plugins/withMentionsPlugin";
import {
	UtilityEditor,
	withUtilityPlugin,
} from "../Editor/plugins/withUtilityPlugin";
import { withRenderedEditorPlugin } from "../Editor/plugins/withRenderedEditorPlugin";
import { withAnchorMePlugin } from "../Editor/plugins/withAnchorMePlugin";
import { usePlaceholderPlugin } from "../Editor/plugins/withPlaceholderPlugin";
import {
	ForceRenderElementsEditor,
	withForceRenderElementsPlugin,
} from "../Editor/plugins/withForceRenderElementsPlugin";
import Typography, { FONT_VARIANT } from "../Typography/Typography";
import { MentionNotificationOptions } from "../Mention/Mention";
import {
	withRegisteredRenderElements,
	RegisteredRenderElementsEditor,
	overrideFunction,
} from "../Editor/plugins/PluginHelpers";
import { CustomEditor } from "../Editor/types";
import { nanoid } from "nanoid";
import { Text } from "slate";
import { match } from "assert";

/**
 * Add these props to components to control the plugins that should just work in a text area.
 */
export type PluginOptions = {
	useMentions?: boolean;
	useAnchorMe?: boolean;
	mention_type?: MentionType;
	enterButtonBehavior?:
		| {
				type: "newline";
		  }
		| {
				type: "submit_and_clear";
				onSubmit: React.MutableRefObject<() => void>;
		  };
};

export type SlateTextAreaMinimalEditor = Editor &
	UtilityEditor &
	ForceRenderElementsEditor &
	RegisteredRenderElementsEditor;

/**
 * Sets up the editor for textarea. Knows about what plugins we support.
 */
export const useSlateTextAreaEditor = (
	placeholder = "",
	options: PluginOptions,
	notificationOptions?: MentionNotificationOptions,
	placeholderVariant?: FONT_VARIANT
) => {
	const {
		useMentions = false,
		useAnchorMe = false,
		enterButtonBehavior = { type: "newline" },
	} = options;
	const withPlaceholderPlugin =
		usePlaceholderPlugin<SlateTextAreaMinimalEditor>(
			<Placeholder
				placeholder={placeholder}
				placeholderVariant={placeholderVariant}
			/>
		);

	const withMentionsPlugin = useMentionsPlugin("TEXT_AREA");

	const editor = useMemo(() => {
		let editor = withForceRenderElementsPlugin(
			withUtilityPlugin(
				withRenderedEditorPlugin(withReact(createEditor()))
			)
		);
		if (useMentions) {
			editor = withMentionsPlugin(editor, notificationOptions, options);
		}
		if (useAnchorMe) {
			editor = withAnchorMePlugin(editor);
		}
		if (enterButtonBehavior.type === "submit_and_clear") {
			editor = withSubmitEnterButtonBehavior(
				editor,
				enterButtonBehavior.onSubmit
			);
		}
		if (placeholder) {
			editor = withPlaceholderPlugin(editor);
		}
		return withRegisteredRenderElements(
			editor as CustomEditor
		) as SlateTextAreaMinimalEditor;
	}, []);
	return editor;
};

/**
 * Renders the placeholder
 */
const Placeholder = ({
	placeholder,
	placeholderVariant = "body2",
}: {
	placeholder: string;
	placeholderVariant?: FONT_VARIANT;
}) => {
	return (
		<Typography
			color="text-ghost"
			variant={placeholderVariant}
			style={{ minWidth: 200, transform: "translateY(1px)" }}
		>
			{placeholder}
		</Typography>
	);
};

type UseControlledSlateValueProps<ValueType> = {
	value: ValueType;
	onChange?: (value: ValueType) => void;
	editor: Editor;
	toSlateDescendants: (value: ValueType) => Descendant[];
	fromSlateDescendants: (nodes: Descendant[]) => ValueType;
};

/**
 * Encapsulates the behavior for a slate editor where the value is controlled externally.
 * Returns function to be passed as <Slate/> onChange prop.
 */
export const useControlledSlateValue = <ValueType,>({
	editor,
	value: externalValue,
	onChange: externalOnChange,
	toSlateDescendants,
	fromSlateDescendants,
}: UseControlledSlateValueProps<ValueType>): (() => void) => {
	const updateEditor = () => {
		editor.children = toSlateDescendants(externalValue);
		editor.onChange();
	};

	/**
	 * Set inital value of editor children
	 */
	useEffect(() => updateEditor(), [editor]);

	/**
	 * If external value changes update the editor
	 */
	useEffect(() => {
		const currentValue = fromSlateDescendants(editor.children);
		if (currentValue !== externalValue) {
			updateEditor();
		}
	}, [externalValue]);

	/**
	 * Run external on change when editor state changes.
	 * todo: Add debounce?
	 */
	const onSlateChange = () => {
		const newValue = fromSlateDescendants(editor.children);
		// Check against current value to prevent infinite onChange loop.
		if (newValue !== externalValue) {
			externalOnChange?.(newValue);
		}
	};

	return onSlateChange;
};

const withSubmitEnterButtonBehavior = <T extends SlateTextAreaMinimalEditor>(
	editor: T,
	onSubmit: React.MutableRefObject<() => void>
): T => {
	overrideFunction(editor)("onKeyDown")((onKeyDown) => (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			const { selection } = editor;
			if (selection) {
				const [matches] = Editor.nodes(editor, {
					at: selection,
					match: (node) => {
						return (node as any).type === MENTION_INPUT_TYPE;
					},
				});
				const node = matches?.[0];
				if (node && (node as any).type === MENTION_INPUT_TYPE) {
					return;
				}
			}
			onSubmit.current?.();
			editor.updateFullContent([
				{
					children: [{ text: "" }],
				},
			]);
			e.preventDefault();
			return;
		}
		onKeyDown(e);
	});
	return editor;
};
