import React from "react";
import notification, { ArgsProps } from "antdv5/lib/notification";
import {
	Button,
	ButtonV2,
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodIcon,
	Typography,
} from "@components";
import "./notifStyle.scss";

/**
 * A global Snack bar that lasts for 5 sec on the bottom-left of the screen
 *
 * It has two types: Notification.success or Notification.warning
 * @example
 * Notification.success({ message: "Invitation sent"});
 * Notification.warning({ duration: 10 });
 *
 * @param {String} message The message to display
 * @param {Number} [duration=5] The time the notification will last
 */
const Notification = () => ({
	success: (config?: ArgsProps) => {
		config = {
			className: "notification-success",
			placement: "bottom",
			icon: (
				<GenemodIcon
					name="success"
					decorative
					color="success"
					style={{ height: 16, width: 16 }}
				/>
			),
			closeIcon: (
				<GenemodIcon name="exit-circle" color="text-secondary" />
			),
			duration: 5,
			message: "Success",
			...config,
		};
		notification.open(config);
	},

	warning: (config?: ArgsProps) => {
		config = {
			className: "notification-error",
			placement: "bottom",
			icon: (
				<GenemodIcon
					name="error"
					fill="yellow"
					color="yellow"
					stroke="yellow"
					decorative
					style={{ height: 16, width: 16 }}
				/>
			),
			closeIcon: (
				<GenemodIcon name="exit-circle" color="text-secondary" />
			),
			duration: 5,
			message: "Try again or contact us if it continues",
			...config,
		};
		notification.open(config);
	},

	error: (config?: ArgsProps) => {
		config = {
			className: "notification-error",
			placement: "bottom",
			icon: (
				<GenemodIcon
					name="error"
					color="error"
					decorative
					style={{ height: 16, width: 16 }}
				/>
			),
			closeIcon: (
				<GenemodIcon name="exit-circle" color="text-secondary" />
			),
			duration: 5,
			message: "Try again or contact us if it continues",
			...config,
		};
		notification.open(config);
	},
});
type MessageWithLinkProps = {
	message: string | JSX.Element;
	linkText: string;
	onClick: () => void;
};
/**
 * Body of a message with a link. Helpful because <Link> component doesn't work inside <Notification>.
 */
export const MessageWithLink = ({
	message,
	linkText,
	onClick,
}: MessageWithLinkProps): JSX.Element => {
	return (
		<div style={{ display: "flex", alignItems: "center", gap: 12 }}>
			{message}
			<ButtonV2 type="link" onClick={onClick}>
				{linkText}
			</ButtonV2>
		</div>
	);
};

/**
 * A Notification snackbar for Experiment page
 * @returns Returns a snackbar with checkbox on the left
 */
export const ExperimentStatusNotification = () => ({
	open: (config?: ArgsProps & { checkColor?: string }) => {
		config = {
			className: "notification-experiment",
			placement: "bottomLeft",
			message: "Add a message",
			icon: (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRadius: "50%",
						backgroundColor:
							config?.checkColor || "var(--layer-03)",
						height: 18,
						width: 18,
						paddingLeft: 2,
					}}
				>
					<GenemodIcon
						name="checkmark"
						size="small"
						stroke="text-on-color"
						fill="text-on-color"
					/>
				</div>
			),
			duration: 5,
			closeIcon: <></>,
			onClose: () => {},
			...config,
		};
		notification.open(config);
	},
});

export default Notification();

export function NOTIFICATION_DEMO() {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Notification: Success (default)"
					description="Provides a feedback if user successfully accomplish a task"
				>
					<Button onClick={() => Notification().success()}>
						Click me
					</Button>
				</Demo>
				<Demo
					title="Notification: Error"
					description="Provides an error message if user fails to accomplish something"
				>
					<Button onClick={() => Notification().warning()}>
						Click me
					</Button>
				</Demo>
				<Demo
					title="Content guideline"
					description="Check out material.io for more details about how to write content"
				>
					<Typography>
						<ul>
							<li>Be concise, simple, and direct</li>
							<li>
								Avoid combining first and second person (avoid
								using “me” or “my”, and “you” or “your”, in the
								same phrase)
							</li>
							<li>
								Use common words that are easily understandable
								across all reading levels.
							</li>
							<li>
								Write in the present tense (e.g. “Message sent”
								instead of “Message has been sent”)
							</li>
							<li>
								Use numerals (“1, 2, 3” not “one, two, three”)
							</li>
							<li>Skip unnecessary punctuation</li>
						</ul>
					</Typography>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
