import React from "react";
import { truncArgs } from "@helpers/Formatters";
import {
	LocalStorageKeys,
	useLocalStorage,
} from "@helpers/Hooks/UseLocalStorageHook";
import useScript from "@helpers/Hooks/UseScriptHook";
import {
	useGoogleDocCreateMutation,
	useGoogleDocDeleteMutation,
} from "@redux/ProjectManagement/PmApiSlice";

import {
	GoogleDocCreateDto,
	GoogleDocCreateOptions,
	GoogleDocDto,
	RawGoogleDocumentObject,
} from "./GoogleDrivePickerData";
import { Notification } from "@common/components";
import { deprecated_getConfig, useConfig } from "@common/config/GenemodConfig";
import { useState } from "react";

export const ENABLE_GOOGLE_DRIVE = useConfig("REACT_APP_GOOGLE_DRIVE");

const DEFAULT_NOTIFICATION_NAME = "Google doc";

/**
 * Encapsulates some common behavior for interacting with google docs.
 * This may end up being too tighly coupled to DocumentTab/Workspace.
 */
export const useGooglePicker = (
	createPayload: GoogleDocCreateOptions,
	overrideCreate?: (dto: GoogleDocCreateDto) => void
) => {
	const openPicker = useDrivePicker();

	const [PMcreateGoogleDoc] = useGoogleDocCreateMutation();
	const [FreezerCreateGoogleDoc] = useGoogleDocCreateMutation();
	const [deleteGoogleDocInt] = useGoogleDocDeleteMutation();

	const deleteGoogleDoc = (dto: GoogleDocDto, callback?: () => void) => {
		deleteGoogleDocInt(dto.id).then((resp) => {
			if ("error" in resp) {
				Notification.warning({
					message:
						"Failed to remove the document. Try again or contact us if it continues.",
				});
				return;
			}
			callback?.();
			const name = dto?.name || DEFAULT_NOTIFICATION_NAME;
			Notification.success({
				message: (
					<span>
						<b>{truncArgs`${name}`(68)}</b>
						{" has been removed."}
					</span>
				),
			});
		});
	};

	const getGoogleCreateDocMethod = () => {
		const parentTypeToCreateMethodMap = {
			Project: PMcreateGoogleDoc,
			Subproject: PMcreateGoogleDoc,
			Experiment: PMcreateGoogleDoc,
			Item: FreezerCreateGoogleDoc,
		};

		return (
			parentTypeToCreateMethodMap[createPayload.parentType] || undefined
		);
	};

	const createGoogleDoc = (dto: GoogleDocCreateDto) => {
		const createMethod = getGoogleCreateDocMethod();

		if (!createMethod) return;
		createMethod({
			...createPayload,
			dto,
		}).then((resp) => {
			if ("error" in resp) {
				Notification.warning({
					message:
						"Failed to add the document. Try again or contact us if it continues.",
				});
				return;
			}
			const name = resp?.data?.name || DEFAULT_NOTIFICATION_NAME;
			Notification.success({
				message: (
					<span>
						<b>{truncArgs`${name}`(68)}</b>
						{" has been added."}
					</span>
				),
			});
		});
	};

	const openGooglePickerAndCreate = () =>
		openPicker().then((resp) => {
			const { docs = [] } = resp;
			const raw_data = docs[0] as RawGoogleDocumentObject | undefined;

			// User probably just closed the picker. Do not add a doc.
			if (!raw_data) return;
			(overrideCreate || createGoogleDoc)({
				document_type: "GoogleDocCreateDto",
				url: raw_data.url,
				name: raw_data.name,
				raw_data,
			});
		});

	return {
		openGooglePickerAndCreate,
		deleteGoogleDoc,
		getGoogleCreateDocMethod,
	} as const;
};

/**
 * Like useGooglePicker but does not upload all the docs until the end.
 */
export const useGooglePickerForDelayedCreate = (
	createPayload: GoogleDocCreateOptions
) => {
	const [createDtos, setCreateDtos] = useState<GoogleDocCreateDto[]>([]);
	const googlePickerData = useGooglePicker(createPayload, (dto) =>
		setCreateDtos((dtos) => [...dtos, dto])
	);
	const createAllGoogleDocs = (altPayload: GoogleDocCreateOptions) => {
		const createMethod = googlePickerData.getGoogleCreateDocMethod();
		if (!createMethod) return;

		Promise.all(
			createDtos.map((dto) =>
				createMethod({
					...createPayload,
					...altPayload,
					dto,
				})
			)
		).then(() => {
			// todo!!!!!
		});
	};

	const deleteGoogleDoc = (dto: GoogleDocCreateDto) => {
		setCreateDtos((dtos) => dtos.filter((d) => d.url !== dto.url));
	};

	return {
		...googlePickerData,
		deleteGoogleDoc,
		createDtos,
		createAllGoogleDocs,
	} as const;
};

/**
 * Loads in the google scripts. Must be done before any of the google apis can be used.
 * Should probably always be loaded in at the app or page level.
 * Doc: https://developers.google.com/picker/docs
 */
export const useGoogleScript = () => {
	useScript("https://apis.google.com/js/api.js", () => {
		window.gapi.load("auth", { callback: () => {} });
		window.gapi.load("picker", { callback: () => {} });
	});
};

export const GOOGLE_API_CONFIG = deprecated_getConfig("GOOGLE_API_CONFIG");

/**
 * Configuration for the google drive picker.
 */
type PickerConfiguration = {
	viewId: keyof typeof google.picker.ViewId;
	viewMimeTypes: string | null;
	setIncludeFolders: boolean;
	setSelectFolderEnabled: boolean;
	disableDefaultView: boolean;
	multiselect: boolean;
	appId: string;
	supportDrives: boolean;
	showUploadView: boolean;
	showUploadFolders: boolean;
	setParentFolder: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customViews: any[];
	locale: string;
	scope: string;
};

const GOOGLE_DRIVE_SCOPE = "https://www.googleapis.com/auth/drive.readonly";

const DEFAULT_PICKER_CONFIGURATION: PickerConfiguration = {
	appId: "",
	supportDrives: true,
	viewId: "DOCS",
	multiselect: false,
	showUploadView: true,
	showUploadFolders: true,
	setParentFolder: "",
	viewMimeTypes: null,
	customViews: [],
	locale: "en",
	setIncludeFolders: false,
	setSelectFolderEnabled: false,
	disableDefaultView: false,
	scope: GOOGLE_DRIVE_SCOPE,
};

/**
 * Handles the lower level interaction with the google auth/picker api. Stores user tokens in session storage.
 *
 * Note: Much of the code was sourced from: https://github.com/Jose-cd/React-google-drive-picker/blob/master/src/index.tsx
 */
const useDrivePicker = () => {
	const [authResponse, authAuthResponse] = useLocalStorage<
		GoogleApiOAuth2TokenObject | undefined
	>(LocalStorageKeys.GOOGLE_DOCS_API_TOKEN, undefined, {
		useSessionStorage: true,
	});

	/**
	 * Opens the google auth popup for users to login/auth if there isn't one stored in session storage.
	 *
	 * Note: If the user revokes the app access on google's dashboard, or if for some other reason the stored token
	 * becomes invalid, the picker ui fails forcing the user to have to refresh the page. This doesn't seem like a
	 * common use case but if it becomes one we can redo the logic and hit this endpoint to verify the token is still valid:
	 *
	 * https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=...
	 *
	 * I attempted to hit this endpoint on the front end but got a cors error. We might have to do it on the backend.
	 */
	const withAuthToken = (
		scope: string,
		callback: (authResponse: GoogleApiOAuth2TokenObject) => void
	) => {
		if (authResponse && isAuthResponseValid(authResponse, scope)) {
			// The token *should* be valid. Continue on.
			callback(authResponse);
			return;
		}

		window.gapi.auth.authorize(
			{
				client_id: GOOGLE_API_CONFIG.clientId,
				scope,
				immediate: false,
			},
			(authResponse) => {
				if (!isAuthResponseValid(authResponse, scope)) {
					Notification.warning({
						message:
							"Authentication failed. Try again or contact us if it continues.",
					});
					return;
				}
				authAuthResponse(authResponse);
				callback(authResponse);
			}
		);
	};

	const openPicker = (config: Partial<PickerConfiguration> = {}) =>
		new Promise<google.picker.ResponseObject>((resolve, reject) =>
			withAuthToken(
				config.scope || DEFAULT_PICKER_CONFIGURATION.scope,
				(token) => {
					const {
						appId,
						supportDrives,
						viewId,
						multiselect,
						showUploadView,
						showUploadFolders,
						setParentFolder,
						viewMimeTypes,
						customViews,
						locale,
						setIncludeFolders,
						setSelectFolderEnabled,
						disableDefaultView,
					} = {
						...DEFAULT_PICKER_CONFIGURATION,
						...config,
					};

					const view = new google.picker.DocsView(
						google.picker.ViewId[viewId]
					);
					if (viewMimeTypes) view.setMimeTypes(viewMimeTypes);
					if (setIncludeFolders) view.setSelectFolderEnabled(true);
					if (setSelectFolderEnabled)
						view.setSelectFolderEnabled(true);

					const uploadView = new google.picker.DocsUploadView();
					if (showUploadFolders) uploadView.setIncludeFolders(true);
					if (setParentFolder) uploadView.setParent(setParentFolder);

					const picker = new google.picker.PickerBuilder()
						.setAppId(appId)
						.setOAuthToken(token.access_token)
						.setDeveloperKey(GOOGLE_API_CONFIG.developerKey)
						.setCallback((pickerResponse) => {
							if (
								pickerResponse.action ===
								google.picker.Action.PICKED
							) {
								resolve(pickerResponse);
							} else if (
								pickerResponse.action ===
								google.picker.Action.CANCEL
							) {
								reject(pickerResponse);
							}
						})
						.setLocale(locale);

					if (!disableDefaultView) {
						picker.addView(view);
					}

					customViews.forEach((view) => picker.addView(view));

					if (multiselect) {
						picker.enableFeature(
							google.picker.Feature.MULTISELECT_ENABLED
						);
					}

					if (showUploadView) picker.addView(uploadView);

					if (supportDrives) {
						picker.enableFeature(
							google.picker.Feature.SUPPORT_DRIVES
						);
					}

					picker.build().setVisible(true);
					return true;
				}
			)
		);
	return openPicker;
};

/**
 * Spot checks the google token object to verify it is not expired or missing the scopes
 */
const isAuthResponseValid = (
	authResponse: GoogleApiOAuth2TokenObject | undefined,
	scope: string
) => {
	if (!authResponse || authResponse.error) return false;
	// expires_at and scope exists on the auth response but not in googles type
	const unsafeToken = authResponse as any as {
		expires_at: string;
		scope: string;
	};

	const isExpired = +unsafeToken.expires_at * 1000 < new Date().getTime();
	const hasDriveScope = unsafeToken.scope.includes(scope);

	return !isExpired && hasDriveScope;
};
