import {
	useGetOrganizationsQuery,
	useLazyGetOrganizationsQuery,
} from "@redux/team/TeamApi";
import { Organization, OrganizationId } from "@common/types";
import { useLoginContext } from "@containers/Auth/LoginInterfaces/LoginContext";

/**
 * A hook for getting the relevant data of current organization
 * @returns
 * organizations - Active organizations data of current organization
 * organization - Current organization data
 * organizationId - Id of current organization
 * allOrganizations - all the organizations this current logged in user is part of
 */
export default function useCurrentOrganization(orgId?: OrganizationId) {
	const { loginStatus } = useLoginContext();
	const { data: organizations, ...rest } = useGetOrganizationsQuery(
		undefined,
		{ skip: loginStatus === "LOGGEDOUT" }
	);
	const [getOrganizations] = useLazyGetOrganizationsQuery();

	const organization = orgId
		? organizations?.find((org) => org.id === orgId)
		: organizations?.[0];

	const allOrganizations = (organizations || []) as Organization[];

	return {
		organization,
		organizationId: organization?.id,
		allOrganizations,
		getOrganizations,
		...rest,
	} as const;
}
