import React, { useState, ComponentProps, useMemo } from "react";
import {
	Notification,
	GenemodIcon,
	Checkbox,
	Modal,
	Select,
	Typography,
} from "@components";
import {
	BarcodeLabel,
	PrintableProperty,
	ITEM_BARCODE_LABELS,
	PRINTABLE_PROPERTIES,
	PROPERTY_TO_LABEL,
	formatItemProperties,
	TemplateComponent,
	getPrefixedId,
} from ".";
import { DPI, DPI_OPTIONS, getCanvasDimensions } from "@common/types/Barcode";
import styles from "./BarcodeModal.module.scss";
import { Consumable, Item, User } from "@common/types";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import { nanoid } from "nanoid";
import moment from "moment";

type BarcodeModalContextProps = {
	openBarcodeModal: (items: Item[] | Consumable[]) => void;
	closeBarcodeModal: () => void;
	items: Item[] | Consumable[] | null;
};

const BarcodeModalContext = React.createContext<BarcodeModalContextProps>({
	openBarcodeModal: () => {},
	closeBarcodeModal: () => {},
	items: null,
});

export function BarcodeModalProvider(props: React.PropsWithChildren<object>) {
	const [items, setItems] = React.useState<Item[] | Consumable[] | null>(
		null
	);
	return (
		<BarcodeModalContext.Provider
			{...props}
			value={{
				openBarcodeModal: setItems,
				closeBarcodeModal: () => setItems(null),
				items,
			}}
		/>
	);
}

export function useBarcodeModal() {
	return React.useContext(BarcodeModalContext);
}

function getLabelKey(label: BarcodeLabel) {
	const { name, dimensions } = label;
	return `${name} (${dimensions.width}" x ${dimensions.height}")`;
}

const PREVIEW_WIDTH = 300;
export default function ItemBarcodeModal() {
	const context = useBarcodeModal();
	const { data: user } = useCurrentUserQuery(undefined, {
		skip: context.items === null,
	});
	const [selectedLabel, _setSelectedLabel] = useState<BarcodeLabel>(
		ITEM_BARCODE_LABELS[0]
	);
	const [dpi, setDpi] = useState<DPI>(DPI_OPTIONS[0]);
	const [showCustomSettings, setShowCustomSettings] = useState(false);
	const [selectedProperties, _setSelectedProperties] = useState<
		PrintableProperty[]
	>(PRINTABLE_PROPERTIES.slice(0, ITEM_BARCODE_LABELS[0].nPropertiesMax));
	const [selectedTemplateIndex, _setSelectedTemplateIndex] =
		useState<number>(0);

	const setSelectedLabel = (label: BarcodeLabel) => {
		const { nPropertiesMax } = label;
		_setSelectedProperties((properties) =>
			properties.slice(0, nPropertiesMax)
		);
		setSelectedTemplateIndex(0);
		_setSelectedLabel(label);
	};
	const setSelectedProperties = (properties: PrintableProperty[]) => {
		// Keep the properties in a specified order
		const orderedProperties = PRINTABLE_PROPERTIES.filter((property) =>
			properties.includes(property)
		);
		_setSelectedProperties(orderedProperties);
	};

	const setSelectedTemplateIndex = (index: number) => {
		_setSelectedTemplateIndex(
			Math.min(Math.max(0, index), selectedLabel.templates.length - 1)
		);
	};

	/**
	 * Scale the preview so that it fits in the modal.
	 */
	let scale = 1;
	const canvasDimensions = getCanvasDimensions(selectedLabel.dimensions, dpi);
	if (canvasDimensions.width > PREVIEW_WIDTH) {
		scale = PREVIEW_WIDTH / canvasDimensions.width;
	}

	const LabelComponent = selectedLabel.templates[selectedTemplateIndex];
	const previewItem = context.items?.[0];
	const previewItemProperties =
		previewItem && user
			? formatItemProperties(
					{ ...previewItem, printed_by: user },
					selectedProperties
			  )
			: [];

	const [loading, setLoading] = useState(false);
	const onOk = () => {
		if (context.items === null || context.items.length === 0 || !user)
			return;
		setLoading(true);

		const itemIds = context.items.map((item) =>
			getPrefixedId(String(item.id))
		);

		import("./utils").then(async ({ htmlToImages, imagesToPdf }) => {
			try {
				// This part takes a while and should probably be sped up.
				// Might be able to draw the label on the PDF instead of converting
				// to canvas first.
				const images = await htmlToImages(itemIds);
				// TODO: CryoboxV template requires portrait orientation
				const pdf = imagesToPdf(images, selectedLabel.dimensions, {
					orientation: "l",
				});
				const timestamp = moment(new Date()).format(
					"YYYY-MM-DD-HH:mm:ss"
				);
				pdf.save(`${timestamp}.pdf`);
				context.closeBarcodeModal();
			} catch (e) {
				Notification.error({
					message: "Failed to generate barcodes",
				});
			} finally {
				setLoading(false);
			}
		});
	};

	return (
		<Modal
			visible={context.items !== null && context.items.length > 0}
			title="Download barcode"
			hideCancelButton={true}
			okText="Download"
			width={500}
			onCancel={context.closeBarcodeModal}
			onOk={onOk}
			okButtonProps={{ loading }}
			className={styles.barcodeModal}
		>
			{user && context.items && (
				<LabelRenderer
					dpi={dpi}
					label={selectedLabel}
					items={context.items}
					properties={selectedProperties}
					labelTemplate={LabelComponent}
					user={user}
				/>
			)}
			<InputLabel>Label size</InputLabel>
			<Select
				value={getLabelKey(selectedLabel)}
				onChange={(value) => {
					const label = ITEM_BARCODE_LABELS.find(
						(label) => getLabelKey(label) === value
					);
					if (label) {
						setSelectedLabel(label);
					}
				}}
				style={{ marginBottom: 32 }}
			>
				{ITEM_BARCODE_LABELS.map((label) => {
					const key = getLabelKey(label);
					return (
						<Select.Option key={key} value={key}>
							{key}
						</Select.Option>
					);
				})}
			</Select>
			<InputLabel>Printer resolution</InputLabel>
			<Select
				value={dpi}
				onChange={(value) => setDpi(value as DPI)}
				style={{ marginBottom: 32 }}
			>
				{DPI_OPTIONS.map((dpi) => {
					return (
						<Select.Option key={dpi} value={dpi}>
							{dpi} DPI
						</Select.Option>
					);
				})}
			</Select>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: 16,
				}}
			>
				<InputLabel style={undefined}>Label preview</InputLabel>
				<Typography
					color="link-primary"
					style={{ cursor: "pointer" }}
					onClick={() => setShowCustomSettings(!showCustomSettings)}
				>
					{showCustomSettings ? "Hide" : "Show"} custom settings
				</Typography>
			</div>
			{showCustomSettings ? (
				<>
					<Typography style={{ marginBottom: 16 }}>
						Select up to{" "}
						<strong>
							{selectedLabel.nPropertiesMax}{" "}
							{selectedLabel.nPropertiesMax === 1
								? "property"
								: "properties"}
						</strong>
						:
					</Typography>
					<div className={styles.checkboxWrapper}>
						{PRINTABLE_PROPERTIES.map((property) => {
							const selected =
								selectedProperties.includes(property);
							return (
								<div key={property} className={styles.checkbox}>
									<Checkbox
										value={selected}
										onChange={(p) => {
											if (p) {
												setSelectedProperties([
													...selectedProperties,
													property,
												]);
											} else {
												setSelectedProperties(
													selectedProperties.filter(
														(prop) =>
															prop !== property
													)
												);
											}
										}}
										disabled={
											!selected &&
											selectedProperties.length >=
												selectedLabel.nPropertiesMax
										}
									>
										{PROPERTY_TO_LABEL[property]}
									</Checkbox>
								</div>
							);
						})}
					</div>
				</>
			) : null}
			<div className={styles.previewWrapper}>
				<div className={styles.previewHeader}>
					<GenemodIcon
						name="chevron-left"
						style={
							selectedTemplateIndex === 0
								? { opacity: 0, cursor: "default" }
								: undefined
						}
						onClick={() =>
							setSelectedTemplateIndex(selectedTemplateIndex - 1)
						}
					/>
					<Typography bold={true} color="text-primary">
						Template {selectedTemplateIndex + 1}
					</Typography>
					<GenemodIcon
						name="chevron-right"
						style={
							selectedTemplateIndex <
							selectedLabel.templates.length - 1
								? undefined
								: { opacity: 0, cursor: "default" }
						}
						onClick={() =>
							setSelectedTemplateIndex(selectedTemplateIndex + 1)
						}
					/>
				</div>
				<div className={styles.previewContent}>
					<div
						className={styles.previewComponent}
						style={{
							transformOrigin: "0 0",
							transform: `scale(${scale})`,
							width: scale * canvasDimensions.width,
							height: scale * canvasDimensions.height,
						}}
						key={`${getLabelKey(selectedLabel)}-${dpi}`}
					>
						{previewItem && (
							<LabelComponent
								dpi={dpi}
								id={String(previewItem.id)}
								itemName={previewItem.name}
								properties={previewItemProperties}
								dimensions={selectedLabel.dimensions}
								nPropertiesMax={selectedLabel.nPropertiesMax}
							/>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
}

function InputLabel(props: ComponentProps<typeof Typography>) {
	return (
		<Typography
			bold={true}
			color="text-primary"
			style={{ marginBottom: 8 }}
			{...props}
		/>
	);
}

type LabelDownloadProps = {
	label: BarcodeLabel;
	dpi: DPI;
	items: Item[] | Consumable[];
	properties: PrintableProperty[];
	labelTemplate: TemplateComponent;
	user: User;
};
/**
 * Renders labels offscreen.
 */
function LabelRenderer(props: LabelDownloadProps) {
	const {
		label,
		dpi,
		items,
		labelTemplate: Template,
		properties,
		user,
	} = props;

	// Use a key to force the template barcode to repaint correctly when
	// changing label properties that affect print resolution.
	const resolutionKey = useMemo(() => {
		return nanoid();
	}, [label, dpi]);

	return (
		<div style={{ position: "absolute", left: -9999, top: -9999 }}>
			{items.map((item) => {
				const itemProperties = formatItemProperties(
					{ ...item, printed_by: user },
					properties
				);
				return (
					<Template
						key={item.id + resolutionKey}
						dpi={dpi}
						properties={itemProperties}
						id={String(item.id)}
						itemName={item.name}
						dimensions={label.dimensions}
						nPropertiesMax={label.nPropertiesMax}
					/>
				);
			})}
		</div>
	);
}
