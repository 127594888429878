import { axios } from "@API";
import { Modal, Notification } from "@components";
import { truncArgs } from "@helpers/Formatters";
import { FreezerEntities } from "@redux/inventory";
import fileDownload from "js-file-download";
import React, { useCallback, useMemo, useState } from "react";

/**
 * Provides state management and progress modals for exporting data
 */
export function useExportState() {
	const [exportModal, setExportModal] = useState<null | React.ReactNode>(
		null
	);

	const handleExport = useCallback(
		(entityName: FreezerEntities, entityId: number, filename: string) => {
			setExportModal(
				<Modal
					visible
					title="Preparing for download"
					maskClosable={false}
					footer={null}
				>
					Preparing <strong>{filename}</strong> for download...
				</Modal>
			);

			const cancel = () => setExportModal(null);
			// rtkquery should not be used for downloads since it caches data.
			axios
				.get(`/freezer/api/v2/${entityName}/${entityId}/export/`, {
					responseType: "arraybuffer",
				})
				.then((res) => {
					const onDownload = () => {
						fileDownload(
							res.data,
							`${filename.replace(/\s/g, "_")}_export.zip`,
							res.headers["content-type"]
						);
						setExportModal(null);
						Notification.success({
							message: (
								<span>
									<b>{truncArgs`${filename}`(68)}</b>
									{" has been exported."}
								</span>
							),
						});
					};
					setExportModal(
						<Modal
							visible
							title="Export ready for download"
							onCancel={cancel}
							closable={true}
							okText="Download file"
							onOk={onDownload}
							dataCy="download-file"
						>
							Your data has been successfully exported. Click the
							button below to download it.
						</Modal>
					);
				})
				.catch((err) => {
					console.log({ err });
					setExportModal(
						<Modal
							visible
							title="Failed to download"
							onCancel={cancel}
							onOk={cancel}
							okText="Ok"
						>
							An error occurred while exporting your data. Please
							try again.
						</Modal>
					);
				});
		},
		[setExportModal]
	);

	return useMemo(() => {
		return {
			handleExport,
			exportModal,
		};
	}, [handleExport, exportModal]);
}
