import { PlanName, RestrictionTypes } from "@common/types/FeatureRestriction";
import { useGetPlanNameQuery } from "@redux/team/TeamApi";
import { useMemo } from "react";
import { useCurrentFeatureRestrictionByFeature } from "./useCurrentFeatureRestrictionHook";
import useCurrentOrganization from "./useCurrentOrganizationHook";

/**
 * return current plan name
 */
export function useCurrentPlanNameHook(): {
	planName: PlanName;
	isLoading: boolean;
} {
	const { organizationId: orgId, isLoading: loadingOrg } =
		useCurrentOrganization();
	const { data, isLoading: loadingPlanName } = useGetPlanNameQuery(
		orgId as number,
		{ skip: !orgId }
	);
	return useMemo(() => {
		return {
			planName: data?.plan_name ?? "free",
			isLoading: loadingOrg || loadingPlanName,
		};
	}, [data, loadingOrg, loadingPlanName]);
}

type useFeatureRestrictionHookReturn = {
	planName: PlanName;
	/** feature is restricted on the Plan */
	restricted: boolean;
	/** limit of feature (0: restricted, null: unlimited) */
	limit: number | null;
	/** current usage of feature */
	current: number;
	/** whether feature limit is reached or not */
	is_limit_reached: boolean;
};
/**
 * return given `feature` restriction data
 */
export function useFeatureRestrictionHook(
	feature: RestrictionTypes
): useFeatureRestrictionHookReturn {
	const { featureRestrictionData } =
		useCurrentFeatureRestrictionByFeature(feature);
	return useMemo(
		() => ({
			planName: featureRestrictionData?.plan_name ?? "free",
			restricted: featureRestrictionData?.limit === 0 ?? true,
			limit:
				featureRestrictionData?.limit === undefined
					? 0
					: featureRestrictionData?.limit,
			current: featureRestrictionData?.current ?? 0,
			is_limit_reached: featureRestrictionData?.is_limit_reached ?? true,
		}),
		[featureRestrictionData, feature]
	);
}
