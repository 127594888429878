import { RestrictionTypes } from "@common/types";
import {
	useGetAllFeatureRestrictionLimitsQuery,
	useGetFeatureRestrictionByFeatureQuery,
	useGetFeatureRestrictionQuery,
	useLazyGetFeatureRestrictionByFeatureQuery,
	useLazyGetFeatureRestrictionQuery,
} from "@redux/team/TeamApi";
import useCurrentOrganization from "./useCurrentOrganizationHook";

/**
 * A hook for getting the featureRestrictionData data of current organization
 * @returns
 * featureRestrictionData - Active featureRestrictionData data of current organization
 * isLoading - Whether the featureRestrictionData data is currently loading
 * isSuccess - Whether the featureRestrictionData is successfully loaded
 * isError - Whether failed to fetch the data
 */
export default function useCurrentFeatureRestriction() {
	const { organizationId: orgId } = useCurrentOrganization();

	const { data: featureRestrictionData, ...rest } =
		useGetFeatureRestrictionQuery(orgId as number, {
			skip: !orgId,
			refetchOnFocus: true,
			refetchOnMountOrArgChange: true,
		});
	const [getFeatureRestriction] = useLazyGetFeatureRestrictionQuery();

	const fetchFeatureRestriction = () =>
		getFeatureRestriction(orgId as number);

	return {
		featureRestrictionData,
		fetchFeatureRestriction,
		...rest,
	} as const;
}

export function useCurrentFeatureRestrictionByFeature(
	featureName?: RestrictionTypes
) {
	const { organizationId: orgId } = useCurrentOrganization();

	const { data: featureRestrictionData, ...rest } =
		useGetFeatureRestrictionByFeatureQuery(
			{
				orgId: orgId as number,
				featureName: featureName as RestrictionTypes,
			},
			{
				skip: !orgId || !featureName,
				refetchOnFocus: true,
				refetchOnMountOrArgChange: true,
			}
		);
	const [getFeatureRestriction] =
		useLazyGetFeatureRestrictionByFeatureQuery();

	const fetchFeatureRestriction = (innerFeatureName?: RestrictionTypes) => {
		if (!orgId || (!innerFeatureName && !featureName)) return;
		return getFeatureRestriction({
			orgId: orgId as number,
			featureName: (innerFeatureName || featureName) as RestrictionTypes,
		});
	};

	return {
		featureRestrictionData,
		fetchFeatureRestriction,
		...rest,
	} as const;
}

/** helper function to return featureDetails with given Plan */
export function useGetFeatureDetails(plan: string) {
	const { data: allPlanRestrictions } =
		useGetAllFeatureRestrictionLimitsQuery();
	const featureDetails = {
		projects: 0,
		freezers: 0,
		consumable: 0,
	};
	if (plan === "Free") {
		featureDetails.projects = allPlanRestrictions?.projects.free || 1;
		featureDetails.consumable =
			allPlanRestrictions?.consumables_spaces.free || 0;
		featureDetails.freezers = allPlanRestrictions?.shared_freezer.free || 2;
	} else if (plan === "Team") {
		featureDetails.projects = allPlanRestrictions?.projects.team || 6;
		featureDetails.consumable =
			allPlanRestrictions?.consumables_spaces.team || 3;
		featureDetails.freezers = allPlanRestrictions?.shared_freezer.team || 9;
	} else if (plan === "Business") {
		featureDetails.projects = allPlanRestrictions?.projects.business || 12;
		featureDetails.consumable =
			allPlanRestrictions?.consumables_spaces.business || 6;
		featureDetails.freezers =
			allPlanRestrictions?.shared_freezer.business || 18;
	}
	return {
		featureDetails,
	};
}
