import { IdsInConstType, NominalId } from "@helpers/TypeHelpers";
import {
	Avatar,
	ISOString,
	SpaceId,
	FurnitureCategoryId,
	FurnitureId,
	DateStringDash,
	CURRENCY_TYPES,
} from "@common/types";

export type ConsumableId = NominalId<"ConsumableId">;
export type CustomConsumableId = NominalId<"CustomConsumableId">;

type FurnitureCategoryObj = {
	id: FurnitureCategoryId;
	name: string;
	is_locked: boolean;
};

type FurnitureObj = {
	id: FurnitureId;
	name: string;
	is_locked: boolean;
};

type SpaceObj = {
	id: SpaceId;
	name: string;
	is_locked: boolean;
};

export type Consumable = {
	/** Consumabable id */
	id: ConsumableId;
	/** Name of the object */
	name: string;
	/** "Custom" Consumabable id, unique for each space */
	custom_id: CustomConsumableId;
	/** Parent space ID */
	parent_space: SpaceId;
	/** Consumable parent furniture category id */
	parent_furniture_category: FurnitureCategoryId;
	/** Consumable created by user */
	created_by: Avatar;
	/** Consumable created at date */
	created_at: ISOString;
	/** Consumable last modified by user */
	updated_by: Avatar;
	/** Consumable updated at date */
	updated_at: ISOString;
	/** Consumable quantity */
	quantity: number;
	/** Consumable expiration date */
	expiration_date: DateStringDash;
	/** Consumable notes */
	notes: string;
	/** Consumable  source */
	source: string;
	/** Consumable reference */
	reference: string;
	/** Consumable catalog */
	catalog: string;
	/** Consumable lot */
	lot: string;
	/** Consumable packaging type */
	packaging: string;
	/** Consumable price amount*/
	price: string;
	/** Consumable currency value */
	currency: IdsInConstType<typeof CURRENCY_TYPES>;
	/** Consumable unit */
	unit: string;
	organization_prefix: string;
	parent_furniture_category_obj: FurnitureCategoryObj;
	parent_space_obj: SpaceObj;
	parent_furniture_obj: FurnitureObj;
	added_at: ISOString;
};
