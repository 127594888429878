import { useSearchParams } from "@helpers/URLParams";

import { useOrganizationRouter } from "@root/AppRouter";
import React, { useEffect, useState } from "react";
import {
	useHistory,
	useLocation,
	useParams,
} from "@common/helpers/Hooks/UseRouterDom";
import { FREEZER_PATHS } from "..";
import BoxReagentSetting from "../components/BoxReagentSetting/BoxReagentSetting";
import FridgeSettings from "../components/FridgeSettings/FridgeSettings";
import RackSettings from "../components/RackSettings/RackSettings";
import ShelfRackSetting from "../components/ShelfRackSetting/ShelfRackSetting";
import ShelfSettings from "../components/ShelfSettings/ShelfSettings";
import CategoryView from "./CategoryView";
import "./Contents.scss";
import RackView from "./RackView";
import ShelfView from "./ShelfView";
import { Category, Rack, isRack } from "@common/types";
import styles from "./Content.module.scss";
import { GenemodSkeleton, LoadingSpinner } from "@common/components";
import CategorySettings from "../components/CategorySettings/CategorySettings";
import ItemGroupSettings from "../components/ItemGroupSettings/ItemGroupSetting";
import { useCategoryQuery } from "@redux/inventory/Category";
import { useFreezerQuery } from "@redux/inventory/Freezer";
import { useRackQuery } from "@redux/inventory/Rack";
import { useShelvesQuery } from "@redux/inventory/Shelf";

export const useFreezer = (freezerId?: number) => {
	const freezer_id = +useParams<{ id: string }>().id || -1;

	const freezerFetchId = freezerId || freezer_id;

	const { data, isLoading, isError } = useFreezerQuery(freezerFetchId, {
		skip: !freezerFetchId || freezerFetchId < 0,
	});

	return {
		freezer: data,
		freezerIsLoading: isLoading,
		freezerIsError: isError,
	};
};

export const useSelectedRack = () => {
	const { selected_rack } = useSearchParams<{ selected_rack: number }>();
	const {
		data: rack,
		isLoading,
		isFetching,
	} = useRackQuery(selected_rack || -1, {
		skip: !selected_rack,
	});

	// current selected rack
	return {
		rack: selected_rack ? rack : undefined,
		isLoading: isLoading,
		isFetching: isFetching,
	};
};

export const useSelectedCategory = () => {
	const { selected_category } = useSearchParams<{
		selected_category: number;
	}>();
	const {
		data: category,
		isLoading,
		isFetching,
	} = useCategoryQuery(selected_category || -1, {
		skip: !selected_category,
	});

	return {
		category: selected_category ? category : undefined,
		isLoading: isLoading,
		isFetching: isFetching,
	};
};

/** Freezer Contnets View */
function Contents(): JSX.Element {
	const { freezer, freezerIsLoading, freezerIsError } = useFreezer();
	const { rack: rackFromStore, isLoading } = useSelectedRack();
	const { category: categoryFromStore } = useSelectedCategory();

	const { getToolRoute } = useOrganizationRouter();
	const history = useHistory();
	const location = useLocation();
	const { data: shelves, isLoading: isLoadingShelves } = useShelvesQuery(
		{
			id: freezer?.id || -1,
		},
		{
			skip: !freezer,
		}
	);

	const { appendBaseUrl } = useOrganizationRouter();

	useEffect(() => {
		if (freezer) {
			if (freezer.is_locked) {
				history.push(appendBaseUrl(FREEZER_PATHS.HOME));
				return;
			}
		}
	}, [freezer]);

	useEffect(() => {
		if (!freezerIsLoading && freezerIsError) {
			history.push(getToolRoute("NOT_FOUND"));
		}
	}, [freezerIsLoading, freezerIsError]);

	/**
	 * Selects a rack
	 */

	const toggleRackOrCategory = (
		obj: Rack | Category,
		select = false,
		callback = () => {}
	) => {
		const originalParams = new URLSearchParams(location.search);
		const params = new URLSearchParams(location.search);
		const currentRack = params.get("selected_rack");
		const currentCategory = params.get("selected_category");
		if (select) {
			if (isRack(obj)) {
				params.set("selected_rack", "" + obj.id);
				params.delete("selected_category");
			} else {
				params.set("selected_category", "" + obj.id);
				params.delete("selected_rack");
			}
		} else if (currentRack && parseInt(currentRack) === obj.id) {
			params.delete("selected_rack");
		} else if (currentCategory && parseInt(currentCategory) === obj.id) {
			params.delete("selected_category");
		} else if (isRack(obj)) {
			params.set("selected_rack", "" + obj.id);
			params.delete("selected_category");
		} else {
			params.set("selected_category", "" + obj.id);
			params.delete("selected_rack");
		}

		if (originalParams.toString() !== params.toString()) {
			history.push({
				pathname: location.pathname,
				search: "?" + params.toString(),
			});
		}
		if (callback) callback();
	};

	const [showEditRackModal, setShowEditRackModal] = useState(false);
	const [showRearrangeRacksModal, setShowRearrangeRacksModal] =
		useState(false);
	const [showEditShelfModal, setShowEditShelfModal] = useState(false);
	const [showRearrangeBoxModal, setShowRearrangeBoxModal] = useState(false);

	const handleRearrengeBoxModal = (status: boolean, rack: Rack) => {
		setShowRearrangeBoxModal(status);
		if (rackFromStore?.id !== rack.id) {
			toggleRackOrCategory(rack, true);
		}
	};

	const handleShowEditRackModal = (status: boolean, rack: Rack) => {
		setShowEditRackModal(status);
		if (rackFromStore?.id !== rack.id) {
			toggleRackOrCategory(rack, true);
		}
	};

	return (
		<div className={styles.freezerContent}>
			{!freezer || isLoadingShelves ? (
				<ShelfViewSkeleton />
			) : (
				<ShelfView
					selectRackOrCategory={toggleRackOrCategory}
					setShowEditShelfModal={setShowEditShelfModal}
					setShowRearrangeRacksModal={setShowRearrangeRacksModal}
					showEditShelfModal={showEditShelfModal}
					showRearrangeRacksModal={showRearrangeRacksModal}
				/>
			)}
			{isLoading ? (
				<div className="right">
					<div
						style={{
							height: "100%",
							width: "100%",
							display: "grid",
							placeItems: "center",
						}}
					>
						<LoadingSpinner loading size="large" />
					</div>
				</div>
			) : rackFromStore || categoryFromStore ? (
				<>
					{categoryFromStore ? (
						<CategoryView
							showEditRackModal={showEditRackModal}
							showRearrangeBoxModal={showRearrangeBoxModal}
						/>
					) : (
						<RackView
							showEditRackModal={showEditRackModal}
							showRearrangeBoxModal={showRearrangeBoxModal}
							setShowEditRackModal={setShowEditRackModal}
							setShowRearrangeBoxModal={setShowRearrangeBoxModal}
						/>
					)}
				</>
			) : (
				<div className="right-empty"></div>
			)}
			<FridgeSettings />
			<ShelfSettings
				setShowRearrangeRacksModal={setShowRearrangeRacksModal}
			/>
			<ShelfRackSetting
				setShowRearrangeRacksModal={setShowRearrangeRacksModal}
				setShowRearrangeBoxModal={setShowRearrangeBoxModal}
				setShowEditRackModal={setShowEditRackModal}
			/>
			<RackSettings
				handleShowEditRackModal={handleShowEditRackModal}
				handleRearrengeBoxModal={handleRearrengeBoxModal}
			/>
			<CategorySettings />
			<BoxReagentSetting />
			<ItemGroupSettings />
		</div>
	);
}

export default Contents;

const ShelfViewSkeleton = () => {
	return (
		<div>
			<GenemodSkeleton
				height={24}
				width={100}
				style={{ marginBottom: 48, background: "var(--layer-02)" }}
				shape="round"
			/>
			<div className={styles.shelfSkeleton}>
				<GenemodSkeleton
					height={20}
					width={100}
					shape="round"
					style={{ marginBottom: 30 }}
				/>
				<ShelfCardSkeletons />
			</div>
		</div>
	);
};

export const ShelfCardSkeletons = () => {
	const skeleton = (
		<div className={styles.shelfCardSkeleton}>
			<GenemodSkeleton
				height={6}
				width={52}
				shape="round"
				style={{
					background: "rgba(var(--accent-subtle-rgb), 0.3)",
				}}
			/>
			<GenemodSkeleton
				height={11}
				width={50}
				shape="round"
				style={{ background: "var(--layer-02)" }}
			/>
		</div>
	);
	return (
		<div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
			{skeleton}
			{skeleton}
			{skeleton}
		</div>
	);
};
