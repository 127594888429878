import React from "react";
import { Drawer as AntdDrawer } from "antd";
import cn from "classnames";
import styles from "./index.module.scss";

type DrawerProps = React.ComponentProps<typeof AntdDrawer>;

/**
 * Override AntD's Drawer to add custom stylings and default props.
 */
export default function Drawer(props: DrawerProps) {
	return (
		<AntdDrawer
			width={480}
			closable={false}
			mask={false}
			{...props}
			className={cn(props.className, styles.drawer)}
		/>
	);
}
