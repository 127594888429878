import { GenemodSkeleton } from "@common/components";
import { ColorCssVarMap } from "@common/styles/Colors";
import React from "react";

type EventLogSkeletonProps = {
	backgroundColor?: "button-disabled" | "border-subtle";
};
const EventLogSkeleton = ({
	backgroundColor = "border-subtle",
}: EventLogSkeletonProps): JSX.Element => {
	const elements = [1, 2, 3, 4, 5, 6];
	return (
		<div style={{ marginTop: 29 }}>
			{elements.map((el) => (
				<div
					key={el}
					style={{
						display: "flex",
						gap: 12,
						alignItems: "center",
						padding: "8px 24px",
					}}
				>
					<GenemodSkeleton
						shape="circle"
						width={24}
						height={24}
						animate={false}
						style={{
							backgroundColor: ColorCssVarMap[backgroundColor],
						}}
					/>
					<div style={{ width: "100%" }}>
						<GenemodSkeleton
							shape="round"
							width={"100%"}
							height={12}
							animate={false}
							style={{
								backgroundColor:
									ColorCssVarMap[backgroundColor],
							}}
						/>
						<GenemodSkeleton
							shape="round"
							width={110}
							height={12}
							style={{
								marginTop: 8,
								backgroundColor:
									ColorCssVarMap[backgroundColor],
							}}
							animate={false}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export default EventLogSkeleton;
