import { Typography } from "@common/components";
import { NewsPopUp } from "@common/components/NewsPopUp/NewsPopUp";
import { useRouteMatch } from "@common/helpers/Hooks/UseRouterDom";
import {
	AccountKeys,
	AllGenemodLinkKeys,
	AllGenemodLinkPrefixes,
	EquipmentKeys,
	FreezerKeys,
	PmKeys,
} from "@common/types";
import { NewFeaturePopOver } from "@common/types/NewFeaturePopOver";
import { FREEZER_PATHS } from "@containers/Freezer/index";
import { actionOnTypeLiteral } from "@helpers/TypeHelpers";
import { useSearchParams } from "@helpers/URLParams";
import {
	useNewFeatureSeenMutation,
	useNewFeaturesQuery,
} from "@redux/Dashboard/DashboardApiSlice";
import { useOrganizationRouter } from "@root/AppRouter";
import {
	ACCOUNT_SETTINGS_PATHS,
	EQUIPMENTS_PATHS,
	PM_ROUTES,
} from "@root/routes";
import React, { useEffect, useMemo } from "react";

export default function GlobalNewFeaturesPopOver() {
	const { data: newFeaturesPopOver = [] } = useNewFeaturesQuery();

	return (
		<>
			{newFeaturesPopOver.map((newFeature) => (
				<PopOver key={newFeature.id} newFeaturePopOver={newFeature} />
			))}
		</>
	);
}

type PopOverProps = {
	newFeaturePopOver: NewFeaturePopOver;
};
function PopOver({ newFeaturePopOver }: PopOverProps) {
	const [seenNewFeaturePopOver] = useNewFeatureSeenMutation();
	const { refetch } = useNewFeaturesQuery();
	const pagePath = useMemo(() => {
		const [type, key] = newFeaturePopOver.page.split(/_(.*)/s) as [
			AllGenemodLinkPrefixes,
			AllGenemodLinkKeys
		];
		return actionOnTypeLiteral(type)({
			ACCOUNT: () => ACCOUNT_SETTINGS_PATHS[key as AccountKeys].route,
			PM: () => PM_ROUTES[key as PmKeys],
			FREEZER: () => FREEZER_PATHS[key as FreezerKeys],
			EQUIPMENT: () => EQUIPMENTS_PATHS[key as EquipmentKeys],
		});
	}, [newFeaturePopOver]);

	const { appendBaseUrl } = useOrganizationRouter();
	const route = useRouteMatch({
		path: appendBaseUrl(pagePath),
	});
	const searchParams = useSearchParams();
	const searchMatch = newFeaturePopOver.search_params
		? newFeaturePopOver.search_params
				.split(",")
				.every((param) => param in searchParams)
		: true;

	const visible = !!route && searchMatch;

	useEffect(() => {
		if (visible) {
			seenNewFeaturePopOver({ id: newFeaturePopOver.id }).catch(() =>
				refetch()
			);
		}
	}, [visible]);

	return (
		<NewsPopUp
			visible={visible}
			title={newFeaturePopOver.title}
			img={newFeaturePopOver.featured_image}
			content={
				<Typography>
					{newFeaturePopOver.body_content
						.split("\n")
						.map((content, index) => (
							<p key={index}>{boldText(content)}</p>
						))}
				</Typography>
			}
			onButtonClick={() => refetch()}
		/>
	);
}

/** Bold wrapped text with backtick(`) */
const boldText = (text: string): JSX.Element => (
	<>
		{text
			.split("`")
			.map((text, index) =>
				(index + 1) % 2 ? text : <b key={index}>{text}</b>
			)}
	</>
);
