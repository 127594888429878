import React, { useEffect, useMemo, useState } from "react";
import { SelectProps, SelectValue } from "antdv4/lib/select";
import { Select } from "antdv4";
import { Checkbox, GenemodIcon, Typography, SearchBar } from "@components";
import cn from "classnames";
import styles from "./FilterButton.module.scss";
import CustomDateRangePicker, { DateRange } from "../CustomDateRangePicker";
import moment from "moment";

export type FilterOption = {
	/** value of the option */
	value: string;
	/** displayed label on Button if option is selected */
	label?: string;
	/** displayed on dropdown */
	display?: React.ReactNode;
};

type FilterButtonProps = SelectProps<SelectValue> & {
	/** determine any filter is applied or not */
	isApplied: boolean;
	children: React.ReactNode;
	extraFilterButtonClassName?: string;
	extraDropdownClassName?: string;
	/** aligned dropdown menu default is "left"*/
	dropdownAlignTo?: "left" | "right";
	// show/hide Select border
	bordered?: boolean;
};

/** Basic filter button component */
export function FilterButton(props: FilterButtonProps): JSX.Element {
	return (
		<div className={styles.select_v4}>
			<Select
				className={cn(
					styles.genemodFilterButton,
					props.extraFilterButtonClassName,
					{
						[styles.genemodFilterButton__applied]: props.isApplied,
					}
				)}
				optionLabelProp="label"
				size="small"
				suffixIcon={
					<GenemodIcon
						name="caret-down"
						fill={
							props.isApplied ? "text-on-color" : "text-secondary"
						}
					/>
				}
				dropdownClassName={cn(
					styles.genemodFilterDropdown,
					props.extraDropdownClassName
				)}
				getPopupContainer={(trigger) =>
					trigger.parentNode as HTMLElement
				}
				dropdownMatchSelectWidth={false}
				virtual={false}
				{...props}
				dropdownAlign={{
					points:
						props?.dropdownAlignTo === "right"
							? ["tr", "br"]
							: ["tl", "bl"],
					offset: [0, 4],
					overflow: {
						adjustX: true,
						adjustY: false,
					},
				}}
			>
				{props.children}
			</Select>
		</div>
	);
}

type MenuFilterButtonProps = {
	/** list of the filter options */
	filterOptions: (string | FilterOption)[];
	/** value of applied option */
	selectedOption: string;
	/** set selectedFilter  */
	setSelectedOption: (value: string) => void;
	/** aligned dropdown menu default is "left"*/
	dropdownAlignTo?: "left" | "right";
};
/** Filter button component which is for applying single filter */
export function MenuFilterButton({
	filterOptions,
	selectedOption,
	setSelectedOption,
	dropdownAlignTo,
}: MenuFilterButtonProps): JSX.Element {
	const options = useMemo(
		() =>
			filterOptions.map((option) =>
				option instanceof Object
					? {
							value: option.value,
							label: option?.label || option.value,
							display: option?.display || option.value,
					  }
					: { value: option, label: option, display: option }
			),
		[filterOptions]
	);

	return (
		<FilterButton
			dropdownAlignTo={dropdownAlignTo}
			defaultValue={options[0].value}
			value={selectedOption}
			isApplied={options[0].value !== selectedOption}
			onChange={(val: any) => setSelectedOption(val)}
		>
			{options.map((option) => (
				<Select.Option
					key={option.value + option.label}
					value={option.value}
					label={option.label}
				>
					<div
						className={cn(styles.selectOption, {
							[styles.selectOption__selected]:
								option.value === selectedOption,
						})}
					>
						{option.display}
						<GenemodIcon
							className={styles.checkIcon}
							name="dropdown-check"
						/>
					</div>
				</Select.Option>
			))}
		</FilterButton>
	);
}

type CheckboxFilterButtonProps = {
	/** displayed label on button */
	label: string;
	/** list of filter option */
	filterOptions: (FilterOption | string)[];
	/** list of selected option */
	selectedOptions: string[];
	/** set selected options */
	setSelectedOptions: (option: string[]) => void;
	/** aligned dropdown menu default is "left"*/
	dropdownAlignTo?: "left" | "right";
	/** whether show num labels if options are selected (default = true) */
	showNumLabel?: boolean;
	/** class name for the extra filter button */
	extraFilterButtonClassName?: string;
	/** custom value for isApplied prop */
	isApplied?: boolean;
	/** custom suffix icon element */
	suffixIcon?: React.ReactNode;
	/** set black border around filter button */
	bordered?: boolean;
	renderMenu?: (menu: JSX.Element) => React.ReactNode;
};

/** Filter button component which is for applying multiple filter */
export function CheckBoxFilterButton({
	filterOptions,
	selectedOptions,
	setSelectedOptions,
	label,
	dropdownAlignTo,
	showNumLabel = true,
	extraFilterButtonClassName,
	isApplied,
	suffixIcon,
	bordered = false,
	renderMenu = (menu) => menu,
}: CheckboxFilterButtonProps): JSX.Element {
	const [inButton, setInButton] = useState(false);
	const [isInMenu, setIsInMenu] = useState(false);
	const [open, setOpen] = useState(false);
	const options = useMemo(
		() =>
			filterOptions.map((option) =>
				option instanceof Object
					? {
							value: option.value,
							label: option?.label || option.value,
							display: option?.display || option.value,
					  }
					: { value: option, label: option, display: option }
			),
		[filterOptions]
	);
	const handleClearAll = () => {
		setSelectedOptions([]);
	};
	const handleSelectOption = (key: SelectValue) => {
		if (selectedOptions.includes(key as any)) {
			setSelectedOptions(selectedOptions.filter((item) => item !== key));
		} else {
			setSelectedOptions([key as any, ...selectedOptions]);
		}
	};
	return (
		<FilterButton
			bordered={bordered}
			dropdownAlignTo={dropdownAlignTo}
			extraFilterButtonClassName={
				extraFilterButtonClassName ||
				cn({
					extraFilterButtonClassName,
					[styles.checkboxFilterButton__applied]:
						!!selectedOptions.length,
				})
			}
			isApplied={isApplied ?? !!selectedOptions.length}
			value={label}
			suffixIcon={
				suffixIcon || (
					<div className={styles.checkboxSuffix}>
						{showNumLabel && !!selectedOptions.length && (
							<Typography
								className={styles.numberLabel}
								color="text-inverse"
								variant="caption"
								bold
							>
								{selectedOptions.length}
							</Typography>
						)}
						<GenemodIcon
							name="caret-down"
							fill={
								selectedOptions.length
									? "text-on-color"
									: "text-secondary"
							}
						/>
					</div>
				)
			}
			onChange={handleSelectOption}
			open={open}
			onDropdownVisibleChange={(open: boolean) => {
				if (inButton) {
					setOpen(open);
				}
			}}
			onMouseEnter={() => setInButton(true)}
			onMouseLeave={() => setInButton(false)}
			onBlur={() => !isInMenu && setOpen(false)}
			dropdownRender={(menu) => (
				<div
					onMouseEnter={() => {
						setInButton(false);
						setIsInMenu(true);
					}}
					onMouseLeave={() => setIsInMenu(false)}
				>
					{renderMenu(
						<>
							{menu}
							<ClearFilterButton
								handleClearAll={handleClearAll}
							/>
						</>
					)}
				</div>
			)}
		>
			{options.map((option) => (
				<Select.Option key={option.value} value={option.value}>
					<Checkbox
						key={option.value}
						value={selectedOptions.includes(option.value)}
					>
						{option.display}
					</Checkbox>
				</Select.Option>
			))}
		</FilterButton>
	);
}

export function CheckBoxFilterButtonWithSearch({
	...props
}: CheckboxFilterButtonProps): JSX.Element {
	const [search, setSearch] = useState("");

	const { filterOptions, selectedOptions } = props;

	const lowerCaseSearch = search.toLowerCase().trim();

	// filtered filterOptions to include only those that match the search or are already selected
	const options = useMemo(
		() =>
			filterOptions.filter((option) => {
				const optionValue =
					(option instanceof Object ? option.value : option) || "";
				const optionLabel =
					(option instanceof Object
						? option.label
						: option
					)?.toLocaleLowerCase() || "";
				const isOptionSelected = selectedOptions.includes(optionValue);
				const isOptionMatchingSearch =
					optionValue
						.toLocaleLowerCase()
						?.includes(lowerCaseSearch) ||
					optionLabel?.includes(lowerCaseSearch);
				return isOptionSelected || isOptionMatchingSearch;
			}),
		[filterOptions, selectedOptions, search]
	);

	const renderMenu = (menu: JSX.Element) => {
		return (
			<>
				<div className={styles.searchBarWrapper}>
					<SearchBar
						value={search}
						onChange={setSearch}
						placeholder="Search for a source / vendor"
						disableSuggestions
						wrapperProps={{
							className: styles.searchBar,
							overrideLayer: 1,
						}}
						iconPosition="left"
						size="small"
						searchIconColor="text-secondary"
					/>
				</div>
				{menu}
			</>
		);
	};
	return (
		<CheckBoxFilterButton
			renderMenu={renderMenu}
			{...props}
			filterOptions={options}
		/>
	);
}

type DateFilterButtonProps = {
	allTimeLabel?: string;
	/** list of the filter options */
	filterDateOptions?: FilterOption[];
	/** date value of applied option [start, end]*/
	selectedDateOption: DateRange;
	/** set date options  */
	setSelectedDateOption: (value: DateRange) => void;
	/** aligned dropdown menu default is "left"*/
	dropdownAlignTo?: "left" | "right";
	defaultValue?: string;
	/** whether set applied color on default when the value is default  */
	appliedColorOnDefault?: boolean;
	customValueRender?: (value: string | undefined) => string | undefined;
};
/** Filter button component which is for applying single filter */
export function DateFilterButton({
	allTimeLabel = "Date",
	filterDateOptions,
	selectedDateOption,
	setSelectedDateOption,
	dropdownAlignTo,
	defaultValue: _defaultValue,
	appliedColorOnDefault = false,
	customValueRender: customLabelRender = (value) => value,
}: DateFilterButtonProps): JSX.Element {
	const dateFormat = "MM/DD/YYYY";
	const options = useMemo(
		() =>
			filterDateOptions || [
				{
					value: "",
					label: allTimeLabel,
					display: "All time",
				},
				{
					value: moment().subtract(1, "day").format(dateFormat),
					label: customLabelRender("Past 24 hours"),
					display: "Past 24 hours",
				},
				{
					value: moment().subtract(7, "day").format(dateFormat),
					label: customLabelRender("Past week"),
					display: "Past week",
				},
				{
					value: moment().subtract(2, "week").format(dateFormat),
					label: customLabelRender("Past 2 weeks"),
					display: "Past 2 weeks",
				},
				{
					value: moment().subtract(1, "month").format(dateFormat),
					label: customLabelRender("Past month"),
					display: "Past month",
				},
			],
		[filterDateOptions]
	);
	const defaultValue = _defaultValue || options?.[0].value;
	const [selectedOption, setSelectedOption] = useState<string | undefined>(
		defaultValue
	);

	// Reset selcted option when selectedDateOption has been reset
	if (!selectedDateOption && selectedOption !== defaultValue) {
		setSelectedOption(defaultValue);
	}

	const [customDate, setCustomDate] = useState(false);
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const handleSelectedValue = (value: any) => {
		if (value === "custom") {
			setCustomDate(true);
			setDropdownVisible(true);
			return;
		} else {
			if (value) {
				setSelectedDateOption([moment(value), null]);
			} else {
				setSelectedDateOption(null);
			}
			setSelectedOption(value);
		}
	};
	useEffect(() => {
		if (!selectedDateOption) return;
		/** init selectedOption if there is selectedDateOption value */
		const start = selectedDateOption[0]?.format(dateFormat);
		const end = selectedDateOption[1]?.format(dateFormat);
		if (!end && start) {
			setSelectedOption(moment(start).format(dateFormat));
		} else {
			setSelectedOption(undefined);
		}
	}, []);

	const getButtonLabel = () => {
		if (selectedOption === undefined && selectedDateOption) {
			const start = selectedDateOption[0]?.format(dateFormat);
			const end = selectedDateOption[1]?.format(dateFormat);
			let customRangeLabel = "";
			if (!start) {
				// If there is only "To" date
				customRangeLabel = `Before ${end}`;
			} else if (!end) {
				// If there is only "From" date
				customRangeLabel = `After ${start}`;
			} else if (start === end) {
				// If both dates are same
				customRangeLabel = `On ${start}`;
			} else {
				customRangeLabel = `${start} - ${end}`;
			}
			return customLabelRender(customRangeLabel);
		}
		return selectedOption;
	};

	const handleClearAll = () => {
		setSelectedDateOption(null);
		setSelectedOption(defaultValue);
	};

	return (
		<FilterButton
			dropdownAlignTo={dropdownAlignTo}
			extraDropdownClassName={customDate ? styles.DateFilterDropdown : ""}
			defaultValue={defaultValue}
			isApplied={
				(appliedColorOnDefault && defaultValue === selectedOption) ||
				defaultValue !== selectedOption
			}
			value={getButtonLabel()}
			onChange={handleSelectedValue}
			open={dropdownVisible}
			onDropdownVisibleChange={(open) => {
				setDropdownVisible(open);
				if (open) {
					setCustomDate(selectedOption === undefined);
				}
			}}
			dropdownRender={(menu) =>
				customDate ? (
					<CustomDateRangePicker
						visible={customDate && dropdownVisible}
						dateFormat={dateFormat}
						selectedDate={
							selectedOption !== undefined
								? null
								: selectedDateOption
						}
						setSelectedDate={(date) => {
							if (!date) {
								handleClearAll();
								return;
							}
							setSelectedDateOption(date);
							setSelectedOption(undefined);
						}}
						onBack={() => {
							setCustomDate(false);
							setDropdownVisible(true);
						}}
						onClose={() => {
							setDropdownVisible(false);
						}}
					/>
				) : (
					<div>
						{menu}
						<Typography
							variant="label"
							className={styles.customDateOption}
							onClick={() => setCustomDate(true)}
						>
							Custom date
							{selectedOption === undefined && (
								<GenemodIcon name="caret-right" />
							)}
						</Typography>
						<ClearFilterButton handleClearAll={handleClearAll} />
					</div>
				)
			}
		>
			{options.map((option) => (
				<Select.Option
					key={option.value + option.label}
					value={option.value}
					label={option.label}
				>
					<div
						className={cn(styles.selectOption, {
							[styles.selectOption__selected]:
								option.value === selectedOption,
						})}
					>
						{option.display}
						<GenemodIcon
							className={styles.checkIcon}
							name="dropdown-check"
						/>
					</div>
				</Select.Option>
			))}
		</FilterButton>
	);
}

type ClearFilterButtonProps = {
	handleClearAll: () => void;
};

const ClearFilterButton = ({ handleClearAll }: ClearFilterButtonProps) => (
	<Typography
		className={styles.clearAllOption}
		variant="label"
		color="link-primary"
		onMouseDown={(e) => {
			// prevent to call blur in Filterbutton
			e.preventDefault();
			handleClearAll();
		}}
	>
		Clear filter
	</Typography>
);
