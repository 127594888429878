import {
	DisabledDocumentPermissions,
	EnabledDocumentPermissions,
} from "./DocumentPermissions";
import { ExperimentFile } from "./Folder";

export type ExperimentFileDocumentPermissionDependencies = {
	experiment_file?: ExperimentFile;
};

export function getExperimentFileDocumentPermissions({
	experiment_file,
}: ExperimentFileDocumentPermissionDependencies) {
	if (!experiment_file) {
		return DisabledDocumentPermissions;
	}
	return EnabledDocumentPermissions;
}
