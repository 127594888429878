import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	const extraStyle = { fill: "#7e7e7e" };
	const extraStyle2 = { fill: "#c4c4c4" };
	const extraStyle3 = { fill: " #707070" };

	return (
		<svg
			data-name="type_unrecognized (x24)"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				data-name="Path 4295"
				d="M1 0h6.27c.495.495 3.1 3.06 3.54 3.5V13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"
				transform="translate(7 5.038)"
				style={extraStyle2}
			/>
			<path
				d="m330.742 5.962 3.526 3.538h-3.525z"
				transform="translate(-316.472 -.962)"
				style={extraStyle}
			/>
			<path
				data-name="Path 4321"
				d="M1.82-2.72h.54a.192.192 0 0 0 .21-.2v-.37c0-.71 1.55-.94 1.55-2.2A1.684 1.684 0 0 0 2.28-7.1a2.411 2.411 0 0 0-1.9.88.187.187 0 0 0 0 .26l.33.32a.163.163 0 0 0 .24 0 1.662 1.662 0 0 1 1.23-.6.8.8 0 0 1 .9.77c0 .92-1.45 1.05-1.45 2.14v.42a.19.19 0 0 0 .19.19zM1.39-.59a.691.691 0 0 0 .69.69.694.694 0 0 0 .7-.69.7.7 0 0 0-.7-.7.694.694 0 0 0-.69.7z"
				transform="translate(10 17)"
				style={extraStyle3}
			/>
		</svg>
	);
}

const MiniDefaultIcon = React.memo(SvgComponent);
export default MiniDefaultIcon;
