import React from "react";
import { Typography } from "@components";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import styles from "./PlanFAQ.module.scss";

export default function PlanFAQ() {
	const { openContactUsPanel } = useCommonModalState("contactUsPanel");
	const contactUsText = (capital: boolean) => (
		<a onClick={() => openContactUsPanel()}>
			{`${capital ? "Contact us" : "contact us"}`}
		</a>
	);
	const FAQdata = [
		{
			question: "Are there discounts for academia and nonprofits?",
			answer: (
				<>
					Yes! We offer discounted pricing for academic labs and
					institutions. Learn more in each respective tab at the top
					or {contactUsText(false)} for more information.
				</>
			),
		},
		{
			question: "Does Genemod offer monthly billing options?",
			answer: (
				<>
					All Genemod plans currently require annual contract
					commitments.
				</>
			),
		},
		{
			question: "Are there multi-year discounts for paid plans?",
			answer: (
				<>
					Discounts are available depending on the length of the
					contract and whether the team is new to a paid plan.{" "}
					{contactUsText(true)} to learn more
				</>
			),
		},
		{
			question: "Is there startup pricing?",
			answer: (
				<>
					Our Team plan for Industry is geared towards startups and
					offers our most popular features. {contactUsText(true)} for
					a demo to see how we can help establish a strong digital
					foundation for your startup.
				</>
			),
		},
		{
			question: "How is my data backed up?",
			answer: (
				<>
					Genemod utilizes Amazon Web Services (AWS) for hosting. AWS
					is a leader in infrastructure security, and maintains
					multiple security and compliance certifications including
					ISO 27001, SOC 1, and SOC 2. Normally, a minimum of 30 days
					of data will be backed up and retained. Depending on the
					customer’s requirements, we can adjust data retention
					policies as needed.
				</>
			),
		},
		{
			question:
				"Can we interface to our ERP, financial systems, and other external applications?",
			answer: (
				<>
					Third-party software interfacing is accomplished in the same
					way as for on-site deployments. {contactUsText(true)} for a
					demo to see how we can help establish a strong digital
					foundation for your team.
				</>
			),
		},
	] as const;

	return (
		<div className={styles.planFAQ}>
			<Typography variant="largeTitle" bold>
				Frequently Asked Questions
			</Typography>
			<div className={styles.faqList}>
				{FAQdata.map((faq, index) => (
					<FAQField key={"faq" + index} {...faq} />
				))}
			</div>
		</div>
	);
}

type FAQField = { question: string; answer: JSX.Element };
function FAQField({ question, answer }: FAQField) {
	return (
		<div className={styles.faqField}>
			<Typography variant="headline" bold>
				{question}
			</Typography>
			<Typography color="text-tertiary">{answer}</Typography>
		</div>
	);
}
