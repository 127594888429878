import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EditorDocumentTableId } from "@common/components/Editor/TableV2/types";
import { CellSelectionBounds } from "@common/components/Editor/TableV2/types";
import { appSelector } from "@redux/store";
import { createActionsHook } from "../helpers/ActionHelpers";
import _ from "lodash";
import { FormulaToken } from "@common/components/Editor/TableV2/formulaLexer";
import { tableFunctionTextToCellRangeBounds } from "@common/components/Editor/TableV2/CellHelpers";

export type FormulaReferencedCells = {
	bounds: CellSelectionBounds;
	token: FormulaToken;
};

export type SingleTableState = {
	formulaReferencedCells: FormulaReferencedCells[];
	copyDragHandleLocation: CellSelectionBounds | null;
};
export type ByTableIdState = Record<EditorDocumentTableId, SingleTableState>;

export type SingleTableAction<T> = PayloadAction<{
	tableId: EditorDocumentTableId;
	data: T;
}>;

const getInitialTableState = () =>
	({
		formulaReferencedCells: [],
		copyDragHandleLocation: null,
	} as SingleTableState);

const verifySingleTableStateIsInitialized = (
	tableId: EditorDocumentTableId,
	state: ByTableIdState
): SingleTableState => {
	if (!state[tableId]) {
		state[tableId] = getInitialTableState();
	}
	return state[tableId];
};

export const pmTablesSlice = createSlice({
	name: "pmTables",
	initialState: {
		byTableId: {} as ByTableIdState,
	},
	reducers: {
		/**
		 * Updates the formula referenced cells for a table
		 */
		updateFormulaReferencedCells: (
			state,
			action: SingleTableAction<{
				currentText: string;
			}>
		) => {
			const tableState = verifySingleTableStateIsInitialized(
				action.payload.tableId,
				state.byTableId
			);
			const setCells = (
				formulaReferencedCells: FormulaReferencedCells[]
			) => {
				const currentCells = state.byTableId[action.payload.tableId];
				if (_.isEqual(currentCells, formulaReferencedCells)) {
					return;
				}
				tableState.formulaReferencedCells = formulaReferencedCells;
			};
			const { currentText } = action.payload.data;
			try {
				setCells(tableFunctionTextToCellRangeBounds(currentText));
			} catch (e) {
				console.error(e);
				setCells([]);
			}
		},

		updateCopyDragHandleLocation: (
			state,
			action: SingleTableAction<{
				// draggedOverCell: Cell;
				selectionBounds: CellSelectionBounds | null;
			}>
		) => {
			const tableState = verifySingleTableStateIsInitialized(
				action.payload.tableId,
				state.byTableId
			);

			tableState.copyDragHandleLocation =
				action.payload.data.selectionBounds;
		},
	},
});

type SingleTableSlateNestedSelector<T> = (state?: SingleTableState) => T;

export const usePmTablesSingleTableSelector = <T = SingleTableState>(
	tableId: EditorDocumentTableId,
	nestedSelector?: SingleTableSlateNestedSelector<T>
): T | undefined => {
	return appSelector((state) => {
		const tableState = state.pm.pmTables.byTableId[tableId];
		if (nestedSelector) {
			return nestedSelector(tableState);
		}
		return tableState as T;
	});
};

export const usePmTablesActions = createActionsHook(pmTablesSlice.actions);
