import React, { useState } from "react";
import styles from "./PageNumbers.module.scss";
import classNames from "classnames";
import { GenemodIcon } from "@components";

/*
 * Props
 *
 * totalPages: int; Total number of pages
 * page (optional): int; Current page
 * onPageChange(optional): (page_num) =>; Called when page changes
 */
type PageNumbersProps = {
	totalPages?: number;
	page?: number | string;
	onPageChange?: (page_num: number) => void;
	className?: string;
};

export default function PageNumbers(props: PageNumbersProps): JSX.Element {
	const [curPage, setPage] = useState(1);
	const total = Math.max(1, props.totalPages ?? 1);
	let pageNum =
		typeof props.page === "string"
			? parseInt(props.page)
			: props.page ?? curPage;
	pageNum = Math.min(Math.max(1, pageNum), total);

	const renderPageNumbers = () => {
		const numbers = [];

		if (pageNum !== 1 && pageNum !== total) {
			if (pageNum > 1) {
				numbers.push(pageNum - 1);
			}

			numbers.push(pageNum);

			if (pageNum < total) {
				numbers.push(pageNum + 1);
			}
		}

		if (pageNum === 1) {
			numbers.push(pageNum);
			if (total > 1) {
				numbers.push(pageNum + 1);
			}
			if (total > 2) {
				numbers.push(pageNum + 2);
			}
		}

		if (pageNum === total && total > 1) {
			if (total > 2) {
				numbers.push(pageNum - 2);
			}
			if (total > 1) {
				numbers.push(pageNum - 1);
			}
			numbers.push(pageNum);
		}

		return (
			<>
				{pageNum > 2 && (
					<span
						onClick={() => handlePageClick(1)}
						className={classNames(
							styles.number,
							styles["number__ellipsis"]
						)}
					>
						...
					</span>
				)}
				{numbers.map((num) => (
					<span
						key={num}
						onClick={() => handlePageClick(num)}
						className={classNames(styles.number, {
							[styles["number__selected"]]: num === pageNum,
						})}
					>
						{num}
					</span>
				))}
				{pageNum < total - 1 && (
					<span
						onClick={() => handlePageClick(total)}
						className={classNames(
							styles.number,
							styles["number__ellipsis"]
						)}
					>
						...
					</span>
				)}
			</>
		);
	};

	const handlePageClick = (num: number) => {
		num = Math.min(total, Math.max(1, num));
		setPage(num);
		if (props.onPageChange) props.onPageChange(num);
	};

	return (
		<div className={classNames(styles.container, props.className)}>
			<div className={styles.numbers}>
				<span
					onClick={() => {
						if (pageNum > 1) handlePageClick(pageNum - 1);
					}}
					className={classNames(styles["prev-next-btn"], {
						[styles["prev-next-btn__disabled"]]: pageNum === 1,
					})}
				>
					<GenemodIcon
						name="caret-left"
						fill="text-secondary"
						style={{
							marginRight: 8,
							width: 18,
							height: 18,
						}}
					/>{" "}
					Prev
				</span>
				<div className={styles.pageNumContainer}>
					{renderPageNumbers()}
				</div>
				<span
					onClick={() => {
						if (pageNum < total) handlePageClick(pageNum + 1);
					}}
					className={classNames(styles["prev-next-btn"], {
						[styles["prev-next-btn__disabled"]]: pageNum === total,
					})}
				>
					Next{" "}
					<GenemodIcon
						name="caret-right"
						fill="text-secondary"
						style={{
							marginLeft: 8,
							width: 18,
							height: 18,
						}}
					/>
				</span>
			</div>
			<span className={styles.currentPage}>
				{pageNum} of {total}
			</span>
		</div>
	);
}
