import React from "react";
import {
	ResponsiveTableColumns,
	ResponsiveTableProps,
} from "./ResponsiveTable";

export type EditRowTableMixinProps<T> = {
	/**
	 * Use the "renderEditing" function on each column when the row is being edited
	 */
	isRowEditing?: (row: T) => boolean;
};

export type EditRowColumnMixinProps<T> = {
	/**
	 * If defined and the row is being editing this will replace the render function. Uses render if not defined.
	 * Renders nothing if "BLANK".
	 */
	renderEditing?:
		| "BLANK"
		| ((data: any, row: T, index: number) => React.ReactNode);
};

/**
 * Allows for rendering rows based on whether the table/row is being edited or not.
 */
export const useEditRowMixin = <T,>(
	props: ResponsiveTableProps<T>
): ResponsiveTableProps<T> => {
	const { isRowEditing, ...cleanedProps } = props;
	const { columns } = props;

	if (!isRowEditing) {
		return cleanedProps;
	}

	return {
		...cleanedProps,
		columns: columns?.map((col) => ({
			...col,
			render: overrideRender<T>((_, row) => isRowEditing(row))(col),
		})),
	};
};

/**
 * Common code used to override a render function, replacing it with the "renderEditing" function.
 */
export const overrideRender =
	<T,>(shouldRenderEditing: (data: any, row: T, index: number) => boolean) =>
	(col: ResponsiveTableColumns<T>) =>
	(data: any, row: T, index: number): React.ReactNode | undefined => {
		const { render, renderEditing } = col;

		if (renderEditing && shouldRenderEditing(data, row, index)) {
			if (renderEditing === "BLANK") {
				return <></>;
			}
			return renderEditing(data, row, index);
		}

		return render?.(data, row, index) || data;
	};
