import { FONT_STYLE, GENEMOD_LOGO_STYLE, PDF_PAGE_MARGIN } from "./PrintTypes";
import GENEMOD_LOGO from "@common/icons/genemod_logo_print.png";
import { useSafeWindowEventListener } from "@helpers/Hooks";
import jsPDF from "jspdf";
/** add lato fonts in jsPdf  */
/** js file made by https://rawgit.com/MrRio/jsPDF/master/fontconverter/fontconverter.html */
import "./fonts/Lato";
import "./fonts/Lato-Bold";
import "./fonts/Lato-Italic";
import "./fonts/Lato-BoldItalic";
/**
 * add hotkey for print
 */
export function usePrintKeyDown(callback: (e: KeyboardEvent) => void) {
	const onKeyDown = (e: KeyboardEvent) => {
		// Print item
		if ((e.ctrlKey || e.metaKey) && e.key === "p") {
			callback(e);
		}
	};
	useSafeWindowEventListener("keydown", onKeyDown);
}

export function addNewDoc() {
	const doc = new jsPDF({
		orientation: "portrait",
		unit: "px",
		format: "letter",
		hotfixes: ["px_scaling"],
	});
	doc.setLineWidth(1);
	doc.setFont("lato", "normal");
	return doc;
}

/**
 * add genemodLogo on the top right of the page
 */
export function addGenemodLogo(doc: jsPDF, marginRight : number = PDF_PAGE_MARGIN.right) {
	const docWidth = doc.internal.pageSize.getWidth();
	const { w, h } = GENEMOD_LOGO_STYLE;
	doc.addImage(
		GENEMOD_LOGO,
		"png",
		docWidth - w - marginRight,
		PDF_PAGE_MARGIN.top,
		w,
		h
	);
}

/**
 * get maximum width which contents can be added
 */
export function getMaxContentWidth(doc: jsPDF, logo = true, marginRight : number = PDF_PAGE_MARGIN.right) {
	const docWidth = doc.internal.pageSize.width;
	const { w: logoW, marginLeft: logoMarginLeft } = GENEMOD_LOGO_STYLE;
	let maxContentWidth =
		docWidth - PDF_PAGE_MARGIN.left - marginRight;
	if (logo) {
		maxContentWidth = maxContentWidth - logoW - logoMarginLeft;
	}
	return maxContentWidth;
}

/**
 * get maximum height which contents can be added
 */
export function getMaxContentHeight(doc: jsPDF) {
	const docHeight = doc.internal.pageSize.height;
	return docHeight - PDF_PAGE_MARGIN.top - PDF_PAGE_MARGIN.bottom;
}

/**
 * get exact Height of TextLine
 */
export function getTextHeight(
	nLines: number,
	/** textHeight by jsPDF */
	fontHeight: number,
	fontSize: number,
	lineHeight: number
) {
	/**
	 * Note: The height of text in jsPDF is a bit different from fontSize depending on characters and text options.
			 For example, bold text has a slightly higher height than normal text.
			 So we need how many more weights we need to get the exact height of the text. (fontHeight / fontSize)
			 Also, since the baseline is set to "top", 
			 we need to subtract the spacing of the difference from the textline. (fontHeight - fontSize) * 2 (top and bottom)
	 */
	return (
		nLines * lineHeight * (fontHeight / fontSize) -
		(fontHeight - fontSize) * 2
	);
}

/**
 * If there is no enough space add new page and
 * return new pos, otherwise, return same posY
 */
export function getNewPositionY(
	doc: jsPDF,
	startY: number,
	needHeight: number,
	options?: {
		extraTopMargin?: number;
		addHeaderArea?: () => number;
	}
) {
	const docHeight = doc.internal.pageSize.getHeight();
	const restPageHeight = docHeight - startY - PDF_PAGE_MARGIN.bottom;
	if (restPageHeight < needHeight) {
		doc.addPage();
		let currentY: number = PDF_PAGE_MARGIN.top;
		if (options?.addHeaderArea) {
			currentY = options.addHeaderArea();
		}

		return currentY + (options?.extraTopMargin || 0);
	}
	return startY;
}

/**
 * add page number on the bottom right of the page
 */
export function addPageNumber(doc: jsPDF) {
	// first element of interal.pages is always empty,
	// our page is start from second array, so need to -1
	const totalPage = doc.internal.pages.length - 1;
	const { fontSize, color } = FONT_STYLE.label;
	const { width: docWidth, height: docHeight } = doc.internal.pageSize;
	const marginRight = PDF_PAGE_MARGIN.right;
	// for 92PPI letter size (30 for 72PPI letter)
	const marginBottom = 40;
	const currentX = docWidth - marginRight;
	const currentY = docHeight - marginBottom;
	for (let i = 1; i <= totalPage; i++) {
		doc.setPage(i);
		doc.setTextColor(color[0], color[1], color[2])
			.setFontSize(fontSize)
			.text(`${i} / ${totalPage}`, currentX, currentY, {
				baseline: "bottom",
				align: "right",
			});
	}
}
