import { FieldType } from "@common/types";
import freeEmailDomains from "@containers/Auth/Registration/freeEmailDomains.json";

/** @file Text validastors */
/**
 * Text validastors
 * @module @helpers/Validators
 * */

/** Returns true if string is an email
 * @function
 */
export function validateEmail(s) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(s).toLowerCase());
}

export const validateWorkEmail = (email) => {
	const emailDomain = email?.split("@");
	if (!emailDomain[1]) return true;
	return !freeEmailDomains.includes(emailDomain[1]);
};

export const EMAIL_VALIDATOR = {
	validator: (v) => (!v.trim() ? true : validateEmail(v)),
	error: "Please enter a valid email",
};

/**
 * Returns true if the input contains special character
 * @function
 */
export function hasSpecialChar(input) {
	return /[~`!@#$%_^*+=\-[\]\\';,/{}|\\":<>?]/g.test(input.trim());
}

/**
 * Returns true if one of the item type data has an invalid num field value
 */
export function itemTypeDataHasInvalidNumValue(
	itemTypeData,
	currentItemType,
	itemTypes
) {
	if (!itemTypeData || !currentItemType || !itemTypes) return false;
	let currentItemTypeObj = itemTypes.find((it) => it.id === currentItemType);
	let currentItemTypeObjSchema = currentItemTypeObj?.schema || [];
	let totalInvalid = 0;
	Object.keys(itemTypeData).forEach((uuid) => {
		let fieldConfig = currentItemTypeObjSchema.find(
			(field) => field.uuid === uuid
		);
		if (fieldConfig?.type === FieldType.NUMBER) {
			if (isNaN(itemTypeData[uuid].trim())) totalInvalid++;
		}
	});
	return totalInvalid > 0;
}

/**
 * Returns true if the value is a valid positive number.
 */
export function isPositiveNumber(value) {
	const numberValue = Number(value);
	return numberValue > 0 && !isNaN(numberValue);
}
