import { Furniture, OrganizationId, SharedWriteDto } from "@common/types";
import { NominalId, NominalType } from "@helpers/TypeHelpers";
import { ISOString } from "./Date";
import { SharedInternalObj } from "./Sharing";
import { Avatar } from "./User";

export type SpaceTemplateName =
	| "lab_1"
	| "lab_2"
	| "lab_3"
	| "room_1"
	| "room_2"
	| "room_3";

export type Row = NominalType<"FurnitureLocationRow", number>;
export type Column = NominalType<"FurnitureLocationColumn", number>;
export type SpaceId = NominalId<"SpaceId">;

type SpaceBase = {
	/** Id for space */
	id: SpaceId;
	/** Name of the object */
	name: string;
	/** Space description */
	description: string;
	/** Organization the space is under */
	org: OrganizationId;
	/** Background code of the space */
	background: number;
	/** Created date */
	created_at: ISOString;
	/** Name of the user who created the Space */
	created_by: Avatar;
	/** Most recent updated date */
	updated_at: ISOString;
	/** The user who recently updated the Space */
	updated_by: Avatar;
	/** Whether the space is archived */
	is_archived: boolean;
	/** Width of the space */
	width: Column;
	/** Height of the space */
	height: Row;
	/** Amount of consumables in this space */
	total_consumables?: number;
	/** Whether the space is locked */
	is_locked: boolean;
};

export type Space = SharedInternalObj<SpaceBase>;

export type SimpleSpace = SharedInternalObj<
	Pick<
		SpaceBase,
		| "id"
		| "name"
		| "background"
		| "is_archived"
		| "is_locked"
		| "created_at"
	>
>;

export type SpaceFurnitureSummary = {
	/** List of furniture with counts */
	furnitures: {
		furniture: Furniture;
		category_count: number;
		item_count: number;
	}[];
	/** Total categories in the space */
	total_categories: number;
	/** Total items in the space */
	total_items: number;
};

/**
 * Minimal data needed to create a space
 */
export type SpaceCreateDto = SharedWriteDto<
	Partial<Space> & Pick<Space, "name" | "background">
>;
