import React, { useEffect, useState } from "react";
import { Tabs, TabsProps } from "antdv5";
import styles from "./TabList.module.scss";
import { IconName, Typography } from "@components";
import cn from "classnames";
import { GradientContainer } from "@components";
import classNames from "classnames";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import { GradientContainerProps } from "../GradientContainer/GradientContainer";
import { useSearchParamAndSet } from "@helpers/URLParams";
import { TabsType } from "antdv5/es/tabs";

const { TabPane } = Tabs;

export type TabItem = {
	key?: string | number;
	tabtitle: React.ReactNode;
	tabcontent?: React.ReactNode;
	icon?: IconName;
	count?: number;
	disabled?: boolean;
};

type TabListProps = Omit<TabsProps, "onTabClick" | "onChange"> & {
	tabListItems: TabItem[] | Readonly<TabItem[]>;
	onChange?: (key: any) => void;
	onTabClick?: (key: any) => void;
	defaultActiveKey?: string;
	className?: string;
	centered?: boolean;
	inSettings?: boolean;
	activeKey?: string;
	gradient?: boolean;
	gradientColor?: GradientContainerProps["color"];
	topGradientHeight?: number;
	bottomGradientHeight?: number;
	hasTopGradient?: boolean;
	hasBottomGradient?: boolean;
	largeSize?: boolean;
	dataCy?: string;
	size?: "small" | "default" | "large";
	type?: "line" | "editable-card";
};

/**
 * A component of TabList
 *
 *
 * @param {Object} props
 * @param {Boolean} [props.defaultActiveKey = props.tabListItems[0].key] - the defualt key to show tab
 * @param {Object[]} props.tabListItems - list of objects, (See above guide of tabListItems)
 * @param {Number} [props.topGradientHeight=32] - the height of the top gradient
 * @param {Number} [props.bottomGradientHeight=32] - the height of the bottom gradient
 * @param {Boolean} [props.hasTopGradient=true] - whether to display the bottom gradient
 * @param {Boolean} [props.hasBottomGradient=true] - whether to display the bottom gradient
 * @param {String} [props.gradientColor="layer-01"] - the color of the gradient
 * @param {Boolean} [props.centered=false] - whether to center align the tablist
 * @param {Boolean} [props.inSettings=false] - whether the tablist is used in settings panel
 * @param {String} [props.className] - className of the tablist
 * @param {React.CSSProperties} [props.tabBarStyle] - the style of the tab bar
 * @param {Boolean} [props.forceRenderTabs=false] - Forced render of content in tabs, not lazy render after clicking on tabs
 *
 */
const TabList = (props: TabListProps): JSX.Element => {
	const {
		defaultActiveKey,
		className,
		centered,
		inSettings,
		activeKey,
		gradient,
		gradientColor,
		topGradientHeight,
		bottomGradientHeight,
		hasTopGradient,
		hasBottomGradient,
		dataCy,
		size = "default",
	} = props;

	const { tabListItems, ...tabsProps } = props;

	const [key, setKey] = useState(defaultActiveKey);
	const currentKey = activeKey || key;

	const getVariant = (size: "small" | "default" | "large") => {
		switch (size) {
			case "small":
				return "small";
			case "large":
				return "headline5";
			default:
				return "regular";
		}
	};

	return (
		<div
			className={classNames(className, styles.genemodTabContainer, {
				[styles.genemodTabContainerCentered]: centered,
				[styles.genemodTabContainerSettings]: inSettings,
				[styles.withInSettingsScrollHeight]: inSettings,
				[styles.largeSize]: size === "large",
				[styles.smallSize]: size === "small",
			})}
		>
			<Tabs
				onChange={setKey}
				tabBarGutter={40}
				{...tabsProps}
				animated={{ inkBar: true, tabPane: false }}
				className={styles.tabs}
			>
				{tabListItems.map((item) => (
					<TabPane
						tab={
							<div
								data-cy={
									dataCy
										? `${dataCy}-${getKey(item)}`
										: undefined
								}
								className="flex gap-1.5 justify-center items-center"
							>
								{item.icon ? (
									<GenemodIcon
										name={item.icon}
										className={styles.icon}
									/>
								) : null}
								<Typography
									variant={getVariant(size)}
									color="text-secondary-v2"
								>
									{item.tabtitle}
								</Typography>
								{item.count ? (
									<span className={styles.tabCount}>
										{item.count > 99 ? "99+" : item.count}
									</span>
								) : null}
							</div>
						}
						key={getKey(item)}
						className={styles.fullHeight}
						disabled={item.disabled}
					>
						{(currentKey == getKey(item) && gradient) ||
						gradient ? (
							<GradientContainer
								className={styles.fullHeight}
								color={gradientColor}
								topHeight={topGradientHeight || 32}
								bottomHeight={bottomGradientHeight || 32}
								hasTop={hasTopGradient !== false}
								hasBottom={hasBottomGradient !== false}
							>
								{item.tabcontent}
							</GradientContainer>
						) : (
							<>{item.tabcontent}</>
						)}
					</TabPane>
				))}
			</Tabs>
		</div>
	);
};

export default TabList;

const getKey = ({ key, tabtitle }: TabItem) =>
	typeof key === "undefined" && typeof tabtitle === "string" ? tabtitle : key;

type TabListTabTextProps = {
	withUpgradeIcon?: boolean;
	tabTitle: string;
	hideTitle?: boolean;
};

/**
 * @param {String} props.tabTitle - The title text of the tab
 * @param {Boolean} [props.withUpgradeIcon=false] - Show the start icon next to it to indicate upgrade plan
 */

export function TabListTabText({
	withUpgradeIcon,
	tabTitle,
}: TabListTabTextProps): JSX.Element {
	if (withUpgradeIcon) {
		return (
			<Typography
				variant="body2"
				style={{ display: "flex", alignItems: "center", gap: 11 }}
				color="text-secondary-v2"
			>
				{tabTitle} <GenemodIcon name="stars" fill="accent-subtle" />
			</Typography>
		);
	}

	return (
		<Typography variant="body2" color="text-secondary-v2">
			{tabTitle}
		</Typography>
	);
}

type TabListUsingSearchParamsProps = Omit<
	TabListProps,
	"activeKey" | "onTabClick"
> & {
	/**
	 * Store the active tab in a query search param if defined.
	 */
	searchParam: string;
};

/**
 * Make a tab list using a url search param to store the tab state.
 */
export const TabListUsingSearchParams = ({
	searchParam,
	onChange,
	...props
}: TabListUsingSearchParamsProps): JSX.Element => {
	const { defaultActiveKey, tabListItems } = props;
	const defaultKey = defaultActiveKey || getKey(tabListItems[0]);
	const [paramKey, setParamKey] = useSearchParamAndSet(searchParam);

	useEffect(() => {
		if (
			defaultKey &&
			!tabListItems.map(getKey).includes(String(paramKey))
		) {
			setParamKey(String(defaultKey));
		}
	}, [paramKey, searchParam]);

	return (
		<TabList
			{...props}
			activeKey={paramKey || defaultKey + ""}
			onChange={(key) => {
				setParamKey(key, true, true);
				onChange?.(key);
			}}
		/>
	);
};
