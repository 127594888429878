import { useState, useCallback } from "react";

/**
 * Registry of local storage keys.
 */
const LocalStorageKeysList = [
	"THEME_PREFERENCE",
	"RESET_PASSWORD_EMAIL",
	"GOOGLE_DOCS_API_TOKEN",
	"BOX_CELL_CLIPBOARD",
	"LAST_USED_WORKSPACE_ID",
	"DRF_AUTH_TOKEN",
	"SEARCH_SUGGESTIONS",
] as const;

export type LocalStorageKey = (typeof LocalStorageKeysList)[number];

/**
 * Maps the keys to themselves so we can access them from a common variable.
 * Add new keys/types to LocalStorageKeysList.
 * EG: LocalStorageKeys.FREEZER_CATEGORY_ITEM_SORT
 */
export const LocalStorageKeys = Object.freeze(
	LocalStorageKeysList.reduce(
		(map, key) => ({
			...map,
			[key]: key,
		}),
		{}
	)
) as Readonly<{
	[K in LocalStorageKey]: K;
}>;

type LocalStorageHookOptions = {
	/** Default false */
	useSessionStorage?: boolean;
};

/*
 * Hook that can be used to store local storage values. It serializes values into a JSON object.
 * @param key - LocalStorageKey, if undefined, null, or empty string then nothing is set in storage
 * @param defaultValue - initial value to the local storage
 *
 * @return - current storedValue associated with the key and a function to update that value
 * */
export const useLocalStorage = <T>(
	key: LocalStorageKey | undefined | null,
	defaultValue: T,
	options?: LocalStorageHookOptions
) => {
	const { useSessionStorage = false } = options || {};

	/** Retrieve the currently stored value. If none is stored, it will set to the given value */
	const [storedValue, setStoredValue] = useState<T>(
		key
			? getLocalStorageItem(key, defaultValue, useSessionStorage)
			: defaultValue
	);
	const setValue = useCallback(
		(value: T) => {
			setStoredValue(value);
			if (key) {
				setLocalStorageItem(key, value, useSessionStorage);
			}
		},
		[setStoredValue, key /* key should not change */, setLocalStorageItem]
	);
	return [storedValue, setValue] as const;
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Local storage helpers
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const LOCAL_STORAGE_SERVICE_KEY = "LOCAL_STORAGE_SERVICE";

/**
 * Get value from localstorage, returns defaultValue if none currently exists.
 * @param localStorageKey expects a key registered when LocalStorageService was created
 * @param defaultValue returns this if nothing is found in localstorage, writes this value to local
 * storage when it is returned, by default it is undefined
 */
export function getLocalStorageItem<T>(
	localStorageKey: LocalStorageKey,
	defaultValue: T,
	useSessionStorage: boolean
): T {
	verifyLocalStorageKey(localStorageKey);
	const localStorageData = getAllLocalStorageServiceData(useSessionStorage);
	const value = localStorageData[localStorageKey];
	const valueIsNil = value === null || typeof value === "undefined";
	if (typeof defaultValue !== "undefined" && valueIsNil) {
		localStorageData[localStorageKey] = defaultValue;
		writeLocalStorageData(localStorageData, useSessionStorage);
		return defaultValue;
	}
	return value;
}

/**
 * Writes value to localstorage
 */
export function setLocalStorageItem<T>(
	localStorageKey: LocalStorageKey,
	value: T,
	useSessionStorage: boolean
) {
	verifyLocalStorageKey(localStorageKey);
	const localStorageData = getAllLocalStorageServiceData(useSessionStorage);
	localStorageData[localStorageKey] = value;
	writeLocalStorageData(localStorageData, useSessionStorage);
}

/**
 * Gets a parsed blob of all data at LOCAL_STORAGE_SERVICE_KEY
 */
function getAllLocalStorageServiceData(useSessionStorage: boolean) {
	const rawLocalStorageData = getStorage(useSessionStorage).getItem(
		LOCAL_STORAGE_SERVICE_KEY
	);
	let data = null;
	try {
		data = JSON.parse(rawLocalStorageData || "null"); // data is null if key was not in local storage
	} catch (e) {
		console.warn("Could not parse local storage data.", e);
	}
	// if data is not initialized (new user, local storage cleared) then rebuild it with fresh object
	if (data === null) {
		writeLocalStorageData({}, useSessionStorage);
		return {};
	}
	return data;
}

/**
 * Used to write entire blob to LOCAL_STORAGE_SERVICE_KEY
 */
function writeLocalStorageData(
	localStorageData: Partial<Record<LocalStorageKey, any>>,
	useSessionStorage: boolean
) {
	getStorage(useSessionStorage).setItem(
		LOCAL_STORAGE_SERVICE_KEY,
		JSON.stringify(localStorageData)
	);
}

/**
 * Logs an error to the console if the given key was not registered in LocalStorageService
 * @param {string} localStorageKey key to verify
 */
function verifyLocalStorageKey(localStorageKey: LocalStorageKey) {
	if (!LocalStorageKeys[localStorageKey]) {
		console.error(
			"LocalStorageKey should be registered in LocalStorageService: " +
				localStorageKey
		);
	}
}

/**
 * Get either local storage or session storage
 */
const getStorage = (useSessionStorage: boolean) =>
	useSessionStorage ? window.sessionStorage : window.localStorage;
