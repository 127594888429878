import React, { useEffect, useMemo, useState } from "react";
import {
	ActivityLog,
	EditableSidePanel,
	SidePanel,
	TabList,
	Notification,
} from "@components";
import { TabListTabText } from "@common/components/TabList/TabList";

import AnalyticsTabContent from "../AnalyticsTabContent/AnalyticsTabContent";
import { truncArgs } from "@helpers/Formatters";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	useCategoryQuery,
	useCategoryPatchMutation,
	useCategoryStorageSummaryQuery,
} from "@redux/inventory/Category";
import InputV3 from "@common/components/InputV3/InputV3";

export default function categorySettings(): JSX.Element {
	const {
		genericallyNamed: {
			isVisible,
			closePanel: _closePanel,
			data: { id },
		},
	} = useCommonPanelState("categorySettings");
	const { data: category } = useCategoryQuery(id || skipToken);

	const viewOnly = !!category?.is_archived;

	const [renameCategory, _setRename] = useState<boolean>(false);
	const { is_limit_reached: restrictFreezerAnalytics } =
		useFeatureRestrictionHook("freezer_analytics");
	const [updateCategory] = useCategoryPatchMutation();

	const handleCancel = () => {
		setRename(false);
	};

	const [name, setName] = useState<string>("");
	const [tempName, setTempName] = useState<string>("");
	const [categoryData, setCategoryData] = useState<
		| {
				name: string;
				available_items: number | null;
		  }[]
		| undefined
	>([]);

	const [_categoryData, storageData] = useCategoryStorageSummary(
		category?.id,
		true
	);
	useEffect(() => {
		if (isVisible && category) {
			setName(category.name || "");
		}
	}, [category, isVisible]);

	const setRename = (value: boolean) => {
		_setRename(value);
		if (value) setTempName(category?.name || "");
	};
	const closePanel = () => {
		if (renameCategory) {
			setRename(false);
		}
		_closePanel();
	};

	const validateForm = () => {
		return !!tempName.trim().length;
	};

	const handleSubmit = () => {
		const failure = (err?: string) => {
			console.log("Failed to edit the category", err, category);
			Notification.warning({
				message: "Failed to edit the category",
			});
		};
		if (!validateForm() || !category) return;
		updateCategory({
			id: category.id,
			name: tempName.trim(),
		})
			.unwrap()
			.then((category) => {
				setRename(false);
				setName(category.name);
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${category.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});
			})
			.catch(failure);
	};

	const detailContent = (
		<div className="category-settings">
			{!renameCategory ? (
				<EditableSidePanel
					title={name}
					type="category"
					edit={renameCategory}
					showEdit={() => setRename(true)}
					viewOnly={viewOnly}
					dataCy="category-settings-editable-panel"
				>
					<></>
				</EditableSidePanel>
			) : (
				<EditableSidePanel
					edit={renameCategory}
					type="category"
					showEdit={handleCancel}
					onConfirm={handleSubmit}
					validateForm={validateForm}
					inlineEdit
					dataCy="category-settings-editable-panel"
				>
					<div className="edit-form">
						<InputV3
							value={tempName}
							onChange={(e) => setTempName(e.target.value)}
							required
							dataCy="category-edit-name"
						/>
					</div>
				</EditableSidePanel>
			)}
		</div>
	);

	const activityContent = (
		<ActivityLog
			activityFilter={{
				location_model: "Category",
				location_object_id: category?.id,
			}}
		/>
	);

	useEffect(() => {
		if (_categoryData && _categoryData.length) {
			setCategoryData([
				..._categoryData,
				{
					name: "This table does not include item groups",
					available_items: null,
				},
			]);
		}
	}, [_categoryData]);

	const analyticName = "Category";
	const tabItems = [
		{
			key: 1,
			tabtitle: <TabListTabText tabTitle="Details" />,
			tabcontent: detailContent,
		},
		{
			key: 2,
			tabtitle: <TabListTabText tabTitle="Activity" />,
			tabcontent: activityContent,
		},
		{
			key: 3,
			tabtitle: (
				<TabListTabText
					tabTitle="Analytics"
					withUpgradeIcon={restrictFreezerAnalytics}
				/>
			),
			tabcontent: (
				<AnalyticsTabContent
					title={`Distribution in ${analyticName}`}
					subtitle="Available space in category"
					storageSummary={{
						dataSource: storageData,
						columns: [
							{
								title: "Box / Item group",
								dataIndex: "name",
								key: "name",
								ellipsis: true,
							},
							{
								title: "Items",
								dataIndex: "items",
								key: "items",
								align: "right",
								width: 88,
							},
						],
						mockDataSource: storageData,
					}}
					spaceLeft={{
						dataSource: categoryData,
						columns: [
							{
								title: "Box",
								dataIndex: "name",
								key: "name",
								ellipsis: true,
								render(text, record) {
									if (record.available_items === null) {
										return {
											props: {
												style: {
													overflow: "visible",
													color: "var(--text-tertiary-v2) !important",
												},
											},
											children: text,
										};
									}
									return {
										children: text,
									};
								},
							},
							{
								title: "Additional items allowed",
								dataIndex: "available_items",
								key: "available_items",
								align: "right",
							},
						],
						mockDataSource: categoryData,
					}}
				/>
			),
		},
	];

	return (
		<SidePanel
			isVisible={isVisible}
			onClose={() => closePanel()}
			header={"Category Settings"}
			showIcon={false}
			dataCy="category-settings"
		>
			<TabList inSettings tabListItems={tabItems} largeSize={true} />
		</SidePanel>
	);
}

/** convert categoryStorageSummaryData to categoryData and storageData */
export const useCategoryStorageSummary = (
	categoryId: number | undefined,
	isCategory: boolean
) => {
	const { data: categoryStorageSummary } = useCategoryStorageSummaryQuery(
		categoryId || -1,
		{ skip: !categoryId || !isCategory }
	);

	const [categoryData, storageData] = useMemo(() => {
		const data = categoryStorageSummary;
		const categoryData = data?.available_space.map((a) => ({
			name: a.name,
			available_items: a.available_items,
		}));
		const storageData = data?.storage_summary.map((s) => ({
			name: s.name,
			items: s.items,
		}));
		return [categoryData, storageData] as const;
	}, [categoryStorageSummary, isCategory]);
	return [categoryData, storageData] as const;
};
