import { RATING_MAP } from "@common/components/NPSFeedback/data";
import { Avatar } from "@common/types/User";

export type NPSFeedback = {
	score?: keyof typeof RATING_MAP;
	feedback: string;
};

export enum NPSSubmissionStatusEnum {
	NEVER_SEEN = 0,
	DELAYED = 1,
	NO_PROMPT = 2,
}

export type NPSStatus = {
	owner: Avatar;
	status: NPSSubmissionStatusEnum;
	show_nps_notification: boolean;
	show_nps_indicator: boolean;
};
