import { OrganizationId, PaginatedSearchResults } from "@common/types";
import {
	EquipmenUnitId,
	EquipmentId,
	EquipmentType,
	EquipmentUnit,
	Schedule,
	ScheduleId,
	SchedulePayload,
	ScheduleV2,
} from "@containers/Facility/constants";
import { AtLeastId } from "@helpers/TypeHelpers";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

const TAG_TYPES = ["EQUIPMENTS", "UPCOMING_RESERVATIONS"] as const;

type CreateEquipmentRequest = {
	name: string;
	image: File | null;
	is_combined: boolean;
	units: { name: string; location: string }[];
	organization: OrganizationId;
};

type EquipmentsFilter = {
	search?: string;
	page?: number;
	page_size?: number;
};

export type ScheduleFilters = {
	start_date__gte: string;
	start_date__lte: string;
};

export type EquipmentScheduleFilter = {
	equipment_type?: EquipmentId;
} & ScheduleFilters;

export type UnitScheduleFilter = {
	unit: EquipmenUnitId;
} & ScheduleFilters;

const withEquipmentsBase = (url: string) => `equipments/api/v1/${url}`;
export const equipmentsApi = createApi({
	reducerPath: "equipmentsApi",
	baseQuery: genemodBaseQuery({ baseUrl: "" }),
	tagTypes: TAG_TYPES,
	endpoints: (builder) => ({
		getAllEquipments: builder.query<EquipmentType[], void>({
			query: () => ({
				url: withEquipmentsBase("equipments/all/"),
				method: "GET",
			}),
			providesTags: ["EQUIPMENTS"],
		}),
		getEquipments: builder.query<
			PaginatedSearchResults<EquipmentType>,
			EquipmentsFilter
		>({
			query: (params) => ({
				url: withEquipmentsBase("equipments/"),
				method: "GET",
				params,
			}),
			providesTags: ["EQUIPMENTS"],
		}),
		getEquipment: builder.query<EquipmentType, EquipmentId>({
			query: (id) => ({
				url: withEquipmentsBase(`equipments/${id}/`),
				method: "GET",
			}),
			providesTags: ["EQUIPMENTS"],
		}),
		createEquipment: builder.mutation<
			EquipmentType,
			CreateEquipmentRequest
		>({
			query: (body) => ({
				url: withEquipmentsBase("equipments/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["EQUIPMENTS"],
		}),
		uploadEquipmentImage: builder.mutation<
			EquipmentType,
			{ file: File; id: EquipmentId }
		>({
			query: ({ file, id }) => {
				const body = new FormData();
				body.append("image", file, file.name);
				return {
					url: withEquipmentsBase(`equipments/${id}/upload-image/`),
					method: "POST",
					body,
					formData: true,
				};
			},
			invalidatesTags: ["EQUIPMENTS"],
		}),
		deleteEquipment: builder.mutation<void, EquipmentId>({
			query: (id) => ({
				url: withEquipmentsBase(`equipments/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["EQUIPMENTS"],
		}),
		updateEquipment: builder.mutation<
			EquipmentType,
			AtLeastId<EquipmentType>
		>({
			query: (body) => ({
				url: withEquipmentsBase(`equipments/${body.id}/`),
				method: "PUT",
				body,
			}),
			invalidatesTags: ["EQUIPMENTS"],
		}),
		scheduleEquipment: builder.mutation<Schedule, SchedulePayload>({
			query: (body) => ({
				url: withEquipmentsBase("schedules/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["EQUIPMENTS", "UPCOMING_RESERVATIONS"],
		}),
		editScheduleEquipment: builder.mutation<ScheduleV2, ScheduleV2>({
			query: (body) => ({
				url: withEquipmentsBase(`schedules/${body.id}/`),
				method: "PUT",
				body,
			}),
			invalidatesTags: ["EQUIPMENTS", "UPCOMING_RESERVATIONS"],
		}),
		getSchedulesEquipments: builder.query<
			Schedule[],
			EquipmentScheduleFilter
		>({
			query: (params) => ({
				url: withEquipmentsBase("schedules/"),
				params,
				method: "GET",
			}),
			providesTags: ["EQUIPMENTS"],
		}),
		getSchedulesEquipmentUnit: builder.query<
			Schedule[],
			UnitScheduleFilter
		>({
			query: (params) => ({
				url: withEquipmentsBase("schedules/"),
				params,
				method: "GET",
			}),
			providesTags: ["EQUIPMENTS"],
		}),
		getUserSchedule: builder.query<Schedule[], ScheduleFilters>({
			query: (params) => ({
				url: withEquipmentsBase("user-schedules/"),
				method: "GET",
				params,
			}),
			providesTags: ["EQUIPMENTS"],
		}),
		removeScheduleEquipment: builder.mutation<void, ScheduleId>({
			query: (id) => ({
				url: withEquipmentsBase(`schedules/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["EQUIPMENTS", "UPCOMING_RESERVATIONS"],
		}),
		upcomingReservation: builder.query<
			Omit<Schedule, "unit"> & { unit: EquipmentUnit },
			void
		>({
			query: () => ({
				url: withEquipmentsBase("upcoming-reservation/"),
				method: "GET",
			}),
			providesTags: ["UPCOMING_RESERVATIONS"],
		}),
	}),
});

export const {
	useGetAllEquipmentsQuery,
	useGetEquipmentsQuery,
	useGetEquipmentQuery,
	useDeleteEquipmentMutation,
	useCreateEquipmentMutation,
	useUploadEquipmentImageMutation,
	useGetUserScheduleQuery,
	useGetSchedulesEquipmentsQuery,
	useGetSchedulesEquipmentUnitQuery,
	useUpdateEquipmentMutation,
	useScheduleEquipmentMutation,
	useEditScheduleEquipmentMutation,
	useRemoveScheduleEquipmentMutation,
	useUpcomingReservationQuery,
} = equipmentsApi;
