import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	const textStyle = {
		fill: "#fff",
		fontSize: "7.5px",
		fontFamily: "OpenSans-Extrabold, Open Sans",
		fontWeight: 800,
		letterSpacing: "0.06em",
	};

	const cls3 = {
		fill: "#1a6a89",
	};

	return (
		<svg
			id="type_experiment"
			xmlns="http://www.w3.org/2000/svg"
			width={48}
			height={48}
			viewBox="0 0 48 48"
			{...props}
		>
			<g id="XMLID_2_" transform="translate(-51.8 2)">
				<g id="XMLID_58_" transform="translate(62.8 6)">
					<path
						id="Path_4303"
						data-name="Path 4303"
						d="M2 0h13.568l7.551 7.48V30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
						transform="translate(1.607)"
						fill="#c2f1fc"
					/>
					<path
						id="XMLID_60_"
						style={cls3}
						transform="translate(0 18.739)"
						d="M0 0h21.056v9.99H0z"
					/>
					<path
						id="XMLID_59_"
						d="m62.8 454.9 1.606 1.357V454.9z"
						transform="translate(-62.8 -426.17)"
						fill="#124d64"
					/>
					<g
						id="Group_18203"
						data-name="Group 18203"
						transform="translate(1.316 2)"
					>
						<g
							id="Group_18202"
							data-name="Group 18202"
							transform="translate(.17 -5.442)"
						>
							<path
								id="Union_129"
								data-name="Union 129"
								d="M0 6a4 4 0 0 1 2.667-3.772V-.333h-.333A.334.334 0 0 1 2-.667.334.334 0 0 1 2.334-1h3.333A.333.333 0 0 1 6-.667a.334.334 0 0 1-.333.334h-.334v2.56A4 4 0 1 1 0 6z"
								transform="translate(7.68 9.18)"
								stroke="#1a6a89"
								fill="none"
							/>
						</g>
						<path
							id="Intersection_3"
							data-name="Intersection 3"
							style={cls3}
							d="M3.738 5.117A4.211 4.211 0 0 1 0 .781C0 .708 0 .636.005.564A4 4 0 0 1 2.3.025C3.569.154 3.91.8 5.339.831A4.97 4.97 0 0 0 7.939 0 4.811 4.811 0 0 1 8 .781a4.211 4.211 0 0 1-3.74 4.336z"
							transform="translate(7.85 8.62)"
						/>
					</g>
				</g>
				<text
					id="doc"
					transform="translate(66.35 32.5)"
					fill="#fff"
					style={textStyle}
				>
					<tspan x="0" y="0">
						doc
					</tspan>
				</text>
			</g>
		</svg>
	);
}

const ExperimentIcon = React.memo(SvgComponent);
export default ExperimentIcon;
