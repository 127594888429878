import React from "react";
import { ButtonV2, GenemodIcon, Spin, Typography } from "@common/components";
import {
	FreezerOrderId,
	FreezerOrderStatusHistory,
	FreezerOrderStatusId,
	FREEZER_ORDER_STATUSES,
	getFullNameFromAvatar,
	FREEZER_ORDER_STATUS_HISTORY_ARCHIVED_ID,
	FreezerOrderStatusHistoryStatusId,
	FREEZER_ORDER_STATUS_HISTORY_LABELS,
} from "@common/types";
import { useOrderStatusAction } from "@common/helpers/Hooks/OrdersHooks";
import {
	useOrderQuery,
	useOrderStatusHistoryQuery,
} from "@redux/freezer/FreezerApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import styles from "./ProgressTab.module.scss";
import moment from "moment";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";

type ProgressTabProps = {
	orderIdNum?: FreezerOrderId;
	is_archived?: boolean;
};

const ProgressTab = ({
	orderIdNum,
	is_archived,
}: ProgressTabProps): JSX.Element => {
	const { data: orderStatusHistory, isLoading } = useOrderStatusHistoryQuery(
		orderIdNum || skipToken
	);

	const getOrderStatusActions = useOrderStatusAction();

	const {
		ordersInfoPanelData: { orderId },
	} = useCommonPanelState("ordersInfoPanel");
	const { data: order } = useOrderQuery(orderId);

	if (isLoading) return <Spin />;

	const lastStep = () => {
		if (!order) return;
		const status = getOrderStatusActions(
			order.status || (0 as FreezerOrderStatusId),
			order.id
		);

		return (
			<>
				{!is_archived && (
					<>
						<div className={styles.last_card}>
							<div className={styles.last_circle}>
								<GenemodIcon
									name="clock"
									color="text-secondary-v2"
								/>
							</div>
							<ButtonV2
								type="primary"
								size="large"
								onClick={status.onClick}
							>
								{status.label.includes("order")
									? status.label
									: status.label + " order"}
							</ButtonV2>
						</div>
					</>
				)}
			</>
		);
	};

	return (
		<div className={styles.progress_activity}>
			{orderStatusHistory?.results.map((data) => (
				<ProgressCard key={data.id} data={data} />
			))}
			{lastStep()}
		</div>
	);
};

export default ProgressTab;

type CardProps = {
	data: Pick<
		FreezerOrderStatusHistory,
		"status" | "created_at" | "created_by"
	>;
};

const ProgressCard = ({
	data: { status, created_at, created_by },
}: CardProps): JSX.Element => (
	<div className={styles.progress_card}>
		{status === FREEZER_ORDER_STATUS_HISTORY_ARCHIVED_ID ? (
			<div className={styles.archive_circle} />
		) : (
			<div className={styles.progress_circle} />
		)}

		<div style={{ display: "flex", flexDirection: "column" }}>
			<div
				style={{
					display: "flex",
				}}
			>
				<Typography color="text-secondary-v2" variant="label">
					{status ===
					(FREEZER_ORDER_STATUSES.Requested
						.id as unknown as FreezerOrderStatusHistoryStatusId)
						? "Submitted"
						: FREEZER_ORDER_STATUS_HISTORY_LABELS[
								status as keyof typeof FREEZER_ORDER_STATUS_HISTORY_LABELS
						  ]}
					&nbsp;
				</Typography>
				<Typography variant="label" color="text-secondary-v2">
					by {getFullNameFromAvatar(created_by)}{" "}
				</Typography>
			</div>
			<Typography
				style={{ marginTop: "2px" }}
				variant="caption"
				color="text-tertiary-v2"
			>
				{`${moment(created_at).format("LL")} at ${moment(
					created_at
				).format("h:mm A")}`}
			</Typography>
		</div>
	</div>
);
