export type PayloadStatus = "INITIALIZED" | "LOADING" | "FINISHED" | "ERROR";

export type DataPayload<T> = {
	data: T;
	status: PayloadStatus;
	error?: string;
};

export function initPayload<T>(
	data: T,
	status: PayloadStatus = "INITIALIZED"
): DataPayload<T> {
	return {
		data,
		status,
	};
}

export function updateLoading<T>(dataPayload: DataPayload<T>) {
	dataPayload.status = "LOADING";
	dataPayload.error = undefined;
}

export function updateSuccess<T>(dataPayload: DataPayload<T>, data: T) {
	dataPayload.data = data;
	dataPayload.status = "FINISHED";
	dataPayload.error = undefined;
}

export function updateFailure<T>(dataPayload: DataPayload<T>, error: string) {
	dataPayload.status = "ERROR";
	dataPayload.error = error;
}

export function updateSuccessStatus<T>(dataPayload: DataPayload<T>) {
	dataPayload.status = "FINISHED";
	dataPayload.error = undefined;
}
