import { FIELD_WIDTHS } from "@common/types";

export const CUSTOM_CONTENTS = Object.freeze({
	0: "TYPE_SELECTOR",
	1: "DATEPICKET_ADDED_AT",
	2: "DATEPICKER_EXPIRES",
	3: "PRICE",
	4: "ATTACHMENT",
	TYPE_SELECTOR: 0,
	DATEPICKET_ADDED_AT: 1,
	DATEPICKER_EXPIRES: 2,
	PRICE: 3,
	ATTACHMENT: 4,
} as const);

export const GENERAL_FIELD = Object.freeze({
	name: {
		label: "Item name",
		required: true,
		autoFocus: true,
		width: FIELD_WIDTHS.FIRST_HALF,
		submitOnEnter: true,
	},
	itemType: {
		type: "custom",
		label: "Item type",
		width: FIELD_WIDTHS.SECOND_HALF,
		content: CUSTOM_CONTENTS.TYPE_SELECTOR,
	},
	concentration: {
		label: "Concentration",
		width: FIELD_WIDTHS.FIRST_HALF,
		maxLength: 40,
	},
	volume: {
		label: "Vol / Mass",
		width: FIELD_WIDTHS.SECOND_HALF,
		maxLength: 40,
	},
	added_on: {
		label: "Created on",
		type: "custom",
		width: FIELD_WIDTHS.FIRST_HALF,
		// style: { marginBottom: 24 },
		content: CUSTOM_CONTENTS.DATEPICKET_ADDED_AT,
	},
	expires_on: {
		label: "Expires on",
		type: "custom",
		width: FIELD_WIDTHS.SECOND_HALF,
		// style: { marginBottom: 24 },
		content: CUSTOM_CONTENTS.DATEPICKER_EXPIRES,
	},
} as const);

const NOTE_FIELD = Object.freeze({
	notes: {
		type: "textarea",
		placeholder: "Keep everyone on the same page…",
		// style: { marginBottom: 24 },
	},
} as const);

const SOURCE_FIELD = Object.freeze({
	source: {
		label: "Source",
	},
	reference: {
		label: "Reference",
	},
	catalog: {
		label: "Catalog #",
		width: FIELD_WIDTHS.FIRST_HALF,
	},
	lot: {
		label: "Lot #",
		width: FIELD_WIDTHS.SECOND_HALF,
	},
	packaging: {
		label: "Packaging",
	},
	price: {
		label: "Price",
		validators: [
			{
				validator: (val: string) => !isNaN(+val?.trim()),
				error: "Please enter a number",
			},
		],
		maxLength: 15,
		// style: { marginBottom: 32 },
		type: "custom",
		content: CUSTOM_CONTENTS.PRICE,
	},
} as const);

export type FieldTypes =
	| keyof typeof GENERAL_FIELD
	| keyof typeof NOTE_FIELD
	| keyof typeof SOURCE_FIELD;

export const SECTION = Object.freeze({
	GENERAL: {
		sectionTitle: "General information",
		fields: GENERAL_FIELD,
		includeBoxSelect: false,
	},
	SPECIFIC_FIELD: {
		sectionTitle: "",
	},
	NOTES: {
		sectionTitle: "Notes",
		fields: NOTE_FIELD,
	},
	SOURCE: {
		sectionTitle: "Source",
		fields: SOURCE_FIELD,
	},
	ATTACHMENTS: {
		sectionTitle: "Attached files",
	},
} as const);

export const REPOSITORY_SECTIONS = Object.freeze({
	GENERAL: {
		sectionTitle: "",
		fields: GENERAL_FIELD,
		includeBoxSelect: true,
	},
	ADVANCED_INFO: {
		sectionTitle: "Advanced info",
		fields: {
			...SOURCE_FIELD,
			notes: {
				label: "Notes",
				type: "textarea",
				placeholder: "Keep everyone on the same page…",
			},
		},
		defaultCollapsed: true,
	},
} as const);
