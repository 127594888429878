import { useEffect } from "react";

export default function useScript(
	url: string,
	onLoad: (error: boolean) => void
) {
	useEffect(() => {
		const script = document.createElement("script");

		script.src = url;
		script.async = true;

		document.body.appendChild(script);
		script.addEventListener("load", () => onLoad(false));
		script.addEventListener("error", () => onLoad(true));

		return () => {
			document.body.removeChild(script);
		};
	}, [url]);
}
