import { Editor, Element, Node, NodeEntry, Range, Transforms } from "slate";
import { nanoid } from "nanoid";
import { TextDirection, TextUnit } from "slate/dist/interfaces/types";
import { ReactEditor } from "slate-react";
import React from "react";

/**
 * Abstract class that defines editor commands used for normalizing, inserting, deleting and more operations on nodes.
 * Common code has been abstracted to be reused.
 * @see ImageNode & UploadFile code.
 * Specific implementation on other components can be extended by overwriting the functions of the Abstract class.
 * @example
 * class MyEditorCommands extends AbstractEditorCommands { ...overwrite functions }
 *
 **/
abstract class AbstractEditorCommands {
	normalizeNode(
		entry: NodeEntry,
		type: string,
		editor: Editor,
		normalizeNode: (entry: NodeEntry) => void
	) {
		// Ensure that the node block always has blocks above and below it
		const [node, path] = entry;

		if ((node as any).type === type) {
			const matcher = (n: any) =>
				Element.isElement(n) && !Editor.isEditor(n);
			const [start, end] = Editor.edges(editor, path);

			const before = Editor.before(editor, start);
			const [prevBlock] = Editor.nodes(editor, {
				at: before,
				match: (node) =>
					!Editor.isEditor(node) && Element.isElement(node),
			});
			const emptyText = {
				children: [{ text: "" }],
			} as any;

			if (!before || (prevBlock && (prevBlock[0] as any).type === type)) {
				Transforms.insertNodes(editor, emptyText, {
					at: path,
				});
				return;
			}

			const after = Editor.after(editor, end);
			const [nextBlock] = Editor.nodes(editor, {
				at: after,
				match: matcher,
			});

			if (!nextBlock || (nextBlock[0] as any).type === type) {
				Transforms.insertNodes(editor, emptyText, {
					at: !nextBlock ? after : end,
				});
				return;
			}
		}

		normalizeNode(entry);
	}

	isVoid(
		element: Element,
		type: string,
		isVoid: (element: Element) => boolean
	) {
		return ("type" in element && element.type === type) || isVoid(element);
	}

	insertFragment(
		fragment: any,
		type: string,
		editor: Editor,
		insertFragment: (fragment: any) => void
	) {
		fragment = fragment.map((node: any) => {
			if ("type" in node && node["type"] === type) {
				const tempNode = { ...(node as any) };
				tempNode["id"] = nanoid();
				return tempNode;
			}
			return node;
		});
		insertFragment(fragment);
	}

	deleteFragment(
		type: string,
		editor: Editor,
		deleteFragment: (direction?: TextDirection | undefined) => void
	) {
		const { selection } = editor;
		if (selection) {
			if (Range.isExpanded(selection)) {
				const [nodes] = Editor.nodes(editor, {
					at: selection,
					match: (n) => (n as any).type === type,
				});
				if (nodes) {
					Transforms.removeNodes(editor, {
						at: nodes[1],
						match: (node) => (node as any).type === type,
					});
				}
			}
		}
		deleteFragment();
	}

	deleteBackward(
		unit: TextUnit,
		type: string,
		editor: Editor,
		deleteBackward: (unit: TextUnit) => void
	) {
		// Only allow the user to delete nodes if they are selected
		const { selection } = editor;
		if (selection && Range.isCollapsed(selection)) {
			const before = Editor.before(editor, selection);
			// node before the selection (line above)
			const [beforeSelection] = Editor.nodes(editor, {
				at: before,
				match: (node) => (node as any).type === type,
			});

			if (beforeSelection && before) {
				/**
				 * Performing backspace immediately after a node
				 * remove current line if currentText is empty and next line is not empty
				 * otherwise, focus to node instead of deleting
				 * */
				const after = Editor.after(editor, selection);
				const [node] = Editor.node(editor, selection);
				const currentText = Node.string(node);
				if (currentText === "" && after !== undefined) {
					Transforms.delete(editor);
				}
				Transforms.select(editor, before);
				return;
			}
		}
		deleteBackward(unit);
	}

	updateNodeById(editor: Editor, nodeId: string, data: any) {
		Transforms.setNodes(
			editor,
			{ ...data },
			{ match: (node) => (node as any).id === nodeId, at: [] }
		);
	}

	removeNodeById(editor: Editor, nodeId: string) {
		Transforms.removeNodes(editor, {
			match: (node: any) => node.id === nodeId,
			at: [],
		});
	}
	getNodeByEvent(
		editor: Editor,
		event: React.ChangeEvent<HTMLInputElement>
	): Node {
		const domNode = event.currentTarget;
		const slateNode = ReactEditor.toSlateNode(editor, domNode);
		const path = ReactEditor.findPath(editor, slateNode);
		return Node.get(editor, path);
	}
}

export class EditorCommands extends AbstractEditorCommands {}
