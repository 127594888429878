import React, { CSSProperties, useState } from "react";
import { GenemodIcon, Select, SelectV2 } from "@components";
import cn from "classnames";
import "./index.scss";
import styles from "./index.module.scss";
import commonStyles from "common/styles/common.module.scss";
import { Color as ColorType, ColorCssVarMap } from "@common/styles/Colors";

const DEFAULT_COLORS = [
	"var(--color-1A)",
	"var(--color-1B)",
	"var(--color-1C)",
	"var(--color-2A)",
	"var(--color-2B)",
	"var(--color-2C)",
	"var(--color-3A)",
	"var(--color-3B)",
	"var(--color-3C)",
];

const DEFAULT_HIGHLIGHT_COLORS = [
	"var(--hl-1B)",
	"var(--hl-1C)",
	"var(--hl-2A)",
	"var(--hl-2B)",
	"var(--hl-2C)",
];

const DEFAULT_WIDTH = 3;
const COLOR_WIDTH = 32;

type ColorPickerProps = {
	className?: string;
	style?: CSSProperties;
	dropdownClassName?: string;
	/** type of color picker */
	type: "highlight" | "color";
	/** List of colors */
	colors?: string[];
	/** Number of items per row */
	width?: number;
	/** Selected color */
	selectedColor: string;
	/** Last Selected color */
	lastSelectedColor?: string | null;
	/** Displayed value in the select container */
	display: JSX.Element | string;
	/** If true, includes the null color option */
	includeNull?: boolean;
	/** Called when a color is clicked. Passed the selected color */
	onClick: (color: string | null) => void;
	disabled?: boolean;
};

/**
 * Color picker "select" component.
 * Access default colors via `ColorPicker.DEFAULT_COLORS`.
 * To customize the position of the "null" color, provide it in the colors array.
 */
export default function ColorPicker(props: ColorPickerProps): JSX.Element {
	const { Option } = Select;
	const COLORS: (string | null)[] =
		props.colors || props.type === "color"
			? DEFAULT_COLORS
			: DEFAULT_HIGHLIGHT_COLORS;
	const WIDTH = (props.width || DEFAULT_WIDTH) * COLOR_WIDTH;
	const [isDropdownVisible, setDropdownVisible] = useState(false);

	const getOptions = () => {
		const colors = [...COLORS];
		if (props.includeNull) {
			colors.unshift(null);
		}

		const options = colors.map((color) => {
			return (
				<Option
					value={color || undefined}
					key={color}
					label={props.display}
				>
					<Color
						color={color}
						onClick={() => {
							props.onClick(color);
							setDropdownVisible(false); // Close dropdown when selecting a color
						}}
						selected={props.selectedColor === color}
					/>
				</Option>
			);
		});
		return options;
	};

	const handleSelectClick = (event: React.MouseEvent) => {
		const { clientX, currentTarget } = event;
		const { left, width } = currentTarget.getBoundingClientRect();

		// Calculate the relative click position as a percentage
		const clickPositionPercentage = ((clientX - left) / width) * 100;

		if (isDropdownVisible || props.type !== "highlight") {
			setDropdownVisible(!isDropdownVisible);
			return;
		}

		if (clickPositionPercentage <= 70 && props.lastSelectedColor) {
			// Perform action for left 66%
			props.onClick(props.lastSelectedColor);
		} else {
			// Perform action for right 33%
			setDropdownVisible(!isDropdownVisible);
		}
	};

	return (
		<SelectV2
			value={
				props.selectedColor && props.selectedColor !== "text-primary"
					? `var(--${props.selectedColor})`
					: COLORS[0]
			}
			className={cn(props.className, "gm__pm__colorpicker")}
			dropdownClassName={cn(
				styles.selectDropdown,
				{ [styles.highlight]: props.type === "highlight" },
				{ [styles["select__disabled"]]: props.disabled },
				props.dropdownClassName
			)}
			variant="borderless"
			dropdownStyle={{
				width: `${WIDTH}px`,
			}}
			disabled={props.disabled}
			optionLabelProp="label"
			suffixIcon={
				<GenemodIcon
					style={{
						transform: "rotate(90deg)",
						marginLeft: "-6px",
					}}
					name="chevron-right"
					size="small"
				/>
			}
			open={isDropdownVisible} // Control the dropdown visibility
			onClick={handleSelectClick}
			onDropdownVisibleChange={() => {}}
		>
			{getOptions()}
		</SelectV2>
	);
}

type ColorProps = {
	/** Color string. Supports any CSS colors. Default is white if color property is falsey */
	color: string | null;
	/** selected this color or not*/
	selected: boolean;
	/** OnClick callback function. Passed the color value */
	onClick: (color: string | null) => void;
};

/**
 * A single color
 */
function Color(props: ColorProps): JSX.Element {
	const color = props.color || ColorCssVarMap["text-primary"];
	return (
		<div onClick={() => props.onClick(props.color)}>
			<div className={cn(styles.color, commonStyles.flexCenter)}>
				{props.color === null ? (
					<div className={styles.nullColor} />
				) : (
					<>
						{props.selected && (
							<div
								className={cn(
									styles.colorSelected,
									commonStyles.flexCenter
								)}
								style={{ borderColor: color }}
							/>
						)}
						<div
							className={styles.colorIndicator}
							style={{ backgroundColor: color }}
						/>
					</>
				)}
			</div>
		</div>
	);
}

type ColorPickerIndicatorWrapperProps = {
	children: React.ReactNode;
	color?: ColorType;
};

/**
 * Wrapper component for the color picker indicator
 * @param {ColorType} color - The color to be displayed
 * @param {React.ReactNode} children - The children components
 * @returns {JSX.Element} - The color picker indicator wrapped in a container
 */
export function ColorPickerIndicatorWrapper({
	color,
	children,
}: ColorPickerIndicatorWrapperProps): JSX.Element {
	return (
		<div className={styles.colorPickerIndicatorWrapperContainer}>
			{children}
			{/* The color picker indicator */}
			<div
				className={styles.colorPickerIndicator}
				style={{ backgroundColor: `var(--${color})` }}
			></div>
		</div>
	);
}
