import React, { useMemo } from "react";
import "./Sort.scss";
import { Select, GenemodIcon, SelectV2 } from "@components";
import { SelectProps, SelectValue } from "antd/lib/select";
import { idKeysOfConst } from "@helpers/TypeHelpers";
import cn from "classnames";
import LayerSystemContainer from "../LayerSystemContainer/LayerSystemContainer";
import { GenemodSelectProps } from "../SelectV2/SelectV2";
import styles from "./Sort.module.scss";

type SortProps = GenemodSelectProps & {
	sortList: Record<string | number, string | number>;
	/** if true, use lightmode for Sort component.  */
	lightmode?: boolean;
};

export default function Sort({
	isSmall = true,
	...props
}: SortProps): JSX.Element {
	return (
		<LayerSystemContainer className={styles.wrapperButton}>
			<SelectV2
				optionLabelProp="label"
				defaultValue={props.defaultValue}
				wrapperClassname={cn("genemod-sort-select", {
					["genemod-lightmode"]: props.lightmode,
				})}
				onChange={props.onChange}
				suffixIcon={
					<GenemodIcon
						style={{ transform: "rotate(90deg)" }}
						color="text-tertiary-v2"
					/>
				}
			>
				{idKeysOfConst(props.sortList).map((sortOption) => {
					return (
						<SelectV2.Option
							value={sortOption}
							key={sortOption}
							label={
								<React.Fragment>
									<div className="icon-and-label">
										<GenemodIcon
											name="sort"
											className="sort-icons"
											fill={"text-secondary"}
											size="medium"
										/>
										{/*{props.sortList[sortOption]}*/}
									</div>
								</React.Fragment>
							}
						>
							<SelectV2.SelectedOpt
								isSelected={props.defaultValue === sortOption}
								label={props.sortList[sortOption]}
							/>
						</SelectV2.Option>
					);
				})}
			</SelectV2>
		</LayerSystemContainer>
	);
}

type OptionList = Readonly<string[]>;
type TypedSortProps<T extends OptionList> = Omit<
	GenemodSelectProps,
	"defaultValue" | "onChange"
> & {
	sortList: T;
	value: T[number];
	onChange: (value: T[number]) => void;
	/** if true, use lightmode for Sort component.  */
	lightmode?: boolean;
};

/**
 * Better typed sort component.
 */
export const TypedSort = <T extends OptionList>({
	value,
	sortList,
	onChange,
	...props
}: TypedSortProps<T>): JSX.Element => {
	const newSortList = useMemo(
		() =>
			sortList.reduce(
				(obj, label, index) => ({
					...obj,
					[label]: index,
					[index]: label,
				}),
				{} as Record<string | number, string | number>
			),
		[...sortList]
	);
	const newDefaultValue = newSortList[value]; // get index/id

	const newOnChange = (val: SelectValue) =>
		onChange(newSortList[val as number] as T[number]);

	return (
		<Sort
			{...props}
			onChange={newOnChange}
			sortList={newSortList}
			defaultValue={newDefaultValue}
		/>
	);
};
