export const DEFAULT_TIMEZONE = "Etc/GMT";
export const TIME_ZONE_LIST = {
	Kwajalein: {
		name: "Eniwetok, Kwajalein",
		value: "-12:00",
	},
	"Pacific/Midway": {
		name: "Midway Island, Samoa",
		value: "-11:00",
	},
	"US/Hawaii": {
		name: "Hawaii",
		value: "-10:00",
		dst: "-09:00",
	},
	"Pacific/Marquesas": {
		name: "Taiohae (Marquesas)",
		value: "-09:30",
	},
	"US/Alaska": {
		name: "Alaska",
		value: "-09:00",
		dst: "-08:00",
	},
	"US/Pacific": {
		name: "Pacific Time (US & Canada)",
		value: "-08:00",
		dst: "-07:00",
	},
	"US/Mountain": {
		name: "Mountain Time (US & Canada)",
		value: "-07:00",
		dst: "-06:00",
	},
	"US/Central": {
		name: "Central Time (US & Canada), Mexico City",
		value: "-06:00",
	},
	"US/Eastern": {
		name: "Eastern Time (US & Canada), Bogota, Lima",
		value: "-05:00",
	},
	"America/Caracas": {
		name: "Atlantic Time (Canada), Caracas, La Paz",
		value: "-04:00",
	},
	"Canada/Newfoundland": {
		name: "Newfoundland",
		value: "-03:30",
	},
	"America/Fortaleza": {
		name: "Brazil, Buenos Aires, Georgetown",
		value: "-03:00",
	},
	"America/Noronha": {
		name: "Atlantic islands",
		value: "-02:00",
	},
	"Atlantic/Azores": {
		name: "Azores, Cape Verde Islands",
		value: "-01:00",
	},
	"Etc/GMT": {
		name: "Western Europe Time, London, Lisbon, Casablanca",
		value: "+00:00",
	},
	"Europe/Brussels": {
		name: "Brussels, Copenhagen, Madrid, Paris",
		value: "+01:00",
	},
	"Europe/Kaliningrad": {
		name: "Kaliningrad, South Africa",
		value: "+02:00",
	},
	"Asia/Baghdad": {
		name: "Baghdad, Riyadh, Moscow, St. Petersburg",
		value: "+03:00",
	},
	"Asia/Tehran": {
		name: "Tehran",
		value: "+03:30",
	},
	"Asia/Muscat": {
		name: "Abu Dhabi, Muscat, Baku, Tbilisi",
		value: "+04:00",
	},
	"Asia/Kabul": {
		name: "Kabul",
		value: "+04:30",
	},
	"Asia/Yekaterinburg": {
		name: "Ekaterinburg, Islamabad, Karachi, Tashkent",
		value: "+05:00",
	},
	"Asia/Calcutta": {
		name: "Bombay, Calcutta, Madras, New Delhi",
		value: "+05:30",
	},
	"Asia/Kathmandu": {
		name: "Kathmandu, Pokhara",
		value: "+05:45",
	},
	"Asia/Almaty": {
		name: "Almaty, Dhaka, Colombo",
		value: "+06:00",
	},
	"Asia/Yangon": {
		name: "Yangon, Mandalay",
		value: "+06:30",
	},
	"Asia/Bangkok": {
		name: "Bangkok, Hanoi, Jakarta",
		value: "+07:00",
	},
	"Asia/Shanghai": {
		name: "Beijing, Perth, Singapore, Hong Kong",
		value: "+08:00",
	},
	"Australia/Eucla": {
		name: "Eucla",
		value: "+08:45",
	},
	"Asia/Seoul": {
		name: "Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
		value: "+09:00",
	},
	"Australia/Adelaide": {
		name: "Adelaide, Darwin",
		value: "+09:30",
	},
	"Pacific/Guam": {
		name: "Eastern Australia, Guam, Vladivostok",
		value: "+10:00",
	},
	"Australia/Lord_Howe": {
		name: "Lord Howe Island",
		value: "+10:30",
	},
	"Asia/Magadan": {
		name: "Magadan, Solomon Islands, New Caledonia",
		value: "+11:00",
	},
	"Pacific/Norfolk": {
		name: "Norfolk Island",
		value: "+11:00",
	},
	"Pacific/Auckland": {
		name: "Auckland, Wellington, Fiji, Kamchatka",
		value: "+12:00",
	},
	"Pacific/Chatham": {
		name: "Chatham Islands",
		value: "+12:45",
	},
	"Pacific/Apia": {
		name: "Apia, Nukualofa",
		value: "+13:00",
	},
	"Pacific/Kiritimati": {
		name: "Line Islands, Tokelau",
		value: "+14:00",
	},
};
