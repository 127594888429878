import { BaseDocument } from "./BaseDocument";
import { DocumentImageDto } from "./DocumentImage";
import { GenemodDocumentUUID, ContentType } from "@common/types";

export type GenemodDocumentSettings = {
	numbered_lists: boolean;
	numbered_sublists: boolean;
	bulleted_lists: boolean;
	bulleted_sublists: boolean;
};

export type GenemodDocument = BaseDocument & {
	document_type: "ExperimentDocument";
	uuid: GenemodDocumentUUID;
	content: ContentType;
	/**
	 * Only used for printing
	 */
	images: DocumentImageDto[];
	settings: GenemodDocumentSettings;
};
