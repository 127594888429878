import { PMDocumentDTO } from "@redux/ProjectManagement/PmSliceHelpers";
import { ISOString } from "./Date";
import { ExperimentId } from "./ExperimentId";
import { ExperimentStatus } from "./ExperimentStatus";
import { ProjectColorEnum } from "./ProjectProps";
import { Avatar, TeamMemberAvatar } from "./User";

export type Experiment = {
	id: ExperimentId;
	name: string;
	type: string;
	file_type: string;
	upload?: string;
	documents: PMDocumentDTO[];
	extra_documents: PMDocumentDTO[];
	created_at: ISOString;
	created_by?: Avatar;
	updated_at: ISOString;
	completed_at: moment.Moment | string | null;
	status: ExperimentStatus;
	parent_project: number;
	parent_subproject: number;
	updated_by?: Avatar;
	is_editable: boolean;
	is_archived: boolean;
	navigation: Record<string, unknown>;
	due_date: ISOString | null;
	description: string;
	users: TeamMemberAvatar[];
	color: ProjectColorEnum;
	custom_id: number;
	protocol: number;
};
