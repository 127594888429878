import { OrganizationUserId } from "@common/types";
import { Descendant, Node, Text } from "slate";
import {
	isMentionInputNode,
	isMentionNode,
	makeMentionInputElement,
	MENTION_INPUT_OFFLINE_CLIENT_ID,
	makeMentionElement,
} from "../Editor/plugins/withMentionsPlugin";

/**
 * "Special sequence" is a string surrounded by the special characters (non printing ascii) so we can store mentions.
 */
const SPECIAL_SEQUENCE_START = String.fromCharCode(18); // \x12
const SPECIAL_SEQUENCE_END = String.fromCharCode(19); // \x13
const SPECIAL_SEQUENCE_MATCH_REGEX = new RegExp(
	`${SPECIAL_SEQUENCE_START}(.*?)${SPECIAL_SEQUENCE_END}`,
	"g"
);

/**
 * Create a "special sequence" string from the given string.
 */
const makeSpecialSequence = (string: string) =>
	`${SPECIAL_SEQUENCE_START}${string}${SPECIAL_SEQUENCE_END}`;

/**
 * Tokenize a string into the regular string parts and special sequences, keeps ordering.
 * Also provides a convenience field "isSpecialSequence" if the token was a special sequence
 */
const splitSpecialSequence = (value: string) =>
	value
		.split(SPECIAL_SEQUENCE_MATCH_REGEX)
		.map((token, index) => ({ token, isSpecialSequence: index % 2 === 1 }));

/**
 * Inside the special sequence we encode the user id and name so we can build slate mention
 * elements and make the string saved on the backend searchable for the user name.
 *
 * Todo: add email for searching?
 */
const makeMentionSpecialSequence = (orgUser: number, name: string) =>
	makeSpecialSequence(`${orgUser} ${name}`);

const processMentionSpecialSequenceLine = (value: string) => {
	const children = splitSpecialSequence(value).map(
		({ token, isSpecialSequence }) => {
			if (!isSpecialSequence) {
				return { text: token };
			}

			// Split into the org user id and the name ("text")
			const [orgUserStr, ...textTokens] = token.split(" ");
			const text = textTokens.join(" ");
			const orgUser = +orgUserStr;

			if (orgUser === MENTION_INPUT_OFFLINE_CLIENT_ID) {
				return makeMentionInputElement({
					text,
					mention_type: "person",
				});
			}

			return makeMentionElement({
				text,
				id: orgUser as OrganizationUserId,
				type: "person",
			});
		}
	) as Descendant[];
	return [{ children }];
};

/**
 * Converts the text area string value, with special sequences, into slate nodes
 */
export const convertMentionSpecialSequenceToSlate = (
	value: string
): Descendant[] =>
	value
		.split("\n")
		.map(processMentionSpecialSequenceLine)
		.reduce((acc, val) => {
			return [...acc, ...val];
		}, []);

/**
 * Recursive function that converts a slate node into the text area string value with special sequences.
 *
 * Note: if the node ends up being a raw text node, and it immediately follows another text node, then that
 * is a newline, so we return "wasText" from this function to do this logic.
 *
 * Todo: make this a base text conversion and add plugins to support mention/other elements
 */
const convertNodeToMentionSpecialSequence = (
	node: Descendant,
	isTopLevel = false
): string => {
	const isMention = isMentionNode(node);
	const isMentionInput = isMentionInputNode(node);

	if (isMention || isMentionInput) {
		const orgUser = isMention ? node.id : MENTION_INPUT_OFFLINE_CLIENT_ID;
		const text = Node.string(node); // todo: support name changes
		const content = makeMentionSpecialSequence(Number(orgUser), text);
		return content;
	}

	if (Text.isText(node)) {
		return node.text;
	}

	return node.children
		.map((c) => convertNodeToMentionSpecialSequence(c))
		.join(isTopLevel ? "\n" : "");
};

/**
 * Converts contents of the slate editor to the text area value with special sequence
 */
export const convertSlateToMentionSpecialSequence = (
	nodes: Descendant[]
): string =>
	convertNodeToMentionSpecialSequence(
		{
			children: nodes,
		} as Descendant,
		true
	);
