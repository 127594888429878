/**
 * API and axios module
 * @module @API
 * */
// Settings up axios and default routes for backend endpoints
import { deprecated_getConfig } from "@common/config/GenemodConfig";
import Axios, { AxiosInstance } from "axios";
import {
	ConsumableId,
	FurnitureCategoryId,
	FurnitureId,
	FurnitureTypeId,
	GenemodDocumentUUID,
	SpaceId,
	SpaceTemplateName,
	UUID,
} from "../types";

export const backendAPIUrl = deprecated_getConfig("REACT_APP_DJANGO_URL");

const axiosInstance: AxiosInstance = Axios.create({
	baseURL: backendAPIUrl,
});

axiosInstance.interceptors.request.use((config) => {
	// Add trailing slash
	if (config && config.url && config.url[config.url.length - 1] !== "/") {
		config.url += "/";
	}
	return config;
});

export type CustomAxiosHeader =
	| "Genemod-OrganizationId"
	| "Authorization"
	| "Genemod-WorkspaceId";

export const setAxiosHeader = (
	header: CustomAxiosHeader,
	value: string | number
) => {
	axios.defaults.headers.common[header] = value;
};

export const clearAxiosHeader = (header: CustomAxiosHeader) => {
	delete axios.defaults.headers.common[header];
};

/**
 * API object used to generate routes
 *
 * @example
 * const route = API.auth.user();
 * axios.get(route).then(...)
 * */
const API = {
	freezer: {
		baseRoute: "freezer/api" as const,
		consumables() {
			const consumableRoute = this.baseRoute + "/consumables";
			return {
				space() {
					const spaceRoute = consumableRoute + "/space";
					return {
						/** GET/POST */
						getRoute() {
							return spaceRoute;
						},
						/** GET/PATCH/PUT/DELETE */
						get(spaceID: SpaceId) {
							const specificSpaceRoute =
								spaceRoute + `/${spaceID}`;
							return {
								getRoute() {
									return specificSpaceRoute;
								},
								/** GET/POST */
								furnitures() {
									return specificSpaceRoute + "/furnitures";
								},
								applySpaceTemplate(
									templateName: SpaceTemplateName
								) {
									return (
										specificSpaceRoute +
										"/apply-space-template/" +
										templateName
									);
								},
								activity() {
									return specificSpaceRoute + "/activity";
								},
							};
						},
						activity() {
							return spaceRoute + "/activity";
						},
					};
				},
				/** GET/PATCH/PUT/DELETE */
				furnitures(furnitureID: FurnitureId) {
					const furnitureSpecificRoute =
						consumableRoute + "/furnitures" + `/${furnitureID}`;
					return {
						getRoute() {
							return furnitureSpecificRoute;
						},
						activity() {
							return furnitureSpecificRoute + "/activity";
						},
						furnitureCategories() {
							return (
								furnitureSpecificRoute + "/furniture-categories"
							);
						},
					};
				},
				/** GET/PATCH/PUT/DELETE */
				furnitureCategories(furnitureCategoryID: FurnitureCategoryId) {
					const furnitureCategorySpecificRoute =
						consumableRoute +
						"/furniture-categories" +
						`/${furnitureCategoryID}`;
					return {
						getRoute() {
							return furnitureCategorySpecificRoute;
						},
						consumables() {
							return (
								furnitureCategorySpecificRoute + "/consumables"
							);
						},
						activity() {
							return furnitureCategorySpecificRoute + "/activity";
						},
					};
				},
				/** GET */
				furnitureTypes() {
					const furnitureTypeRoute =
						consumableRoute + "/furniture-types";
					return {
						getRoute() {
							return furnitureTypeRoute;
						},
						/** GET */
						get(furnitureTypeID: FurnitureTypeId) {
							const furnitureTypeSpecificRoute =
								furnitureTypeRoute + `/${furnitureTypeID}`;
							return {
								getRoute() {
									return furnitureTypeSpecificRoute;
								},
							};
						},
					};
				},
				/** GET/PATCH/PUT/DELETE */
				consumables(consumableID: ConsumableId) {
					const consumableSpecificRoute =
						consumableRoute + "/consumables" + `/${consumableID}`;
					return {
						getRoute() {
							return consumableSpecificRoute;
						},
						activity() {
							return consumableSpecificRoute + "/activity";
						},
						consume() {
							return consumableSpecificRoute + "/consume";
						},
					};
				},
				search() {
					const searchRoute = consumableRoute + "/search";
					return {
						getRoute() {
							return searchRoute;
						},
					};
				},
				searchSettings() {
					const searchSettingsRoute =
						consumableRoute + "/consumable-columns";
					return {
						getRoute() {
							return searchSettingsRoute;
						},
					};
				},
			};
		},
	},
	freezerV2: {
		baseRoute: "/freezer/api/v2" as const,
		/**
		 * Returns all routes relating to Freezer objects
		 * @function
		 * */
		freezers() {
			const freezerBaseRoute = this.baseRoute + "/freezers";
			return {
				getRoute() {
					return freezerBaseRoute;
				},
				get(freezerID: number) {
					const freezerRoute = freezerBaseRoute + `/${freezerID}`;
					return {
						getRoute() {
							return freezerRoute;
						},
						addShelf(rowNum: number) {
							return freezerRoute + `/row/${rowNum}`;
						},
						shelves() {
							return freezerRoute + "/shelves";
						},
						lastAdded() {
							return freezerRoute + "/last-added";
						},
						export() {
							return freezerRoute + "/export";
						},
						recentRackDimension() {
							return freezerRoute + "/most-recent-rack-dimension";
						},
						recentBoxDimension() {
							return freezerRoute + "/most-recent-box-dimension";
						},
						editShelves(rowNum: number) {
							return freezerRoute + `/row/${rowNum}`;
						},
						storageSummary() {
							return freezerRoute + "/storage-summary";
						},
						activity() {
							return freezerRoute + "/activity";
						},
					};
				},
				allFreezersStorageSummary() {
					return freezerBaseRoute + "/storage-summary";
				},
				activity() {
					return freezerBaseRoute + "/activity";
				},
			};
		},
		shelf(shelfID: number) {
			const shelfRoute = this.baseRoute + `/shelves/${shelfID}`;
			return {
				getRoute() {
					return shelfRoute;
				},
				racks() {
					return shelfRoute + "/racks";
				},
				storageSummary() {
					return shelfRoute + "/storage-summary";
				},
				activity() {
					return shelfRoute + "/activity";
				},
				export() {
					return shelfRoute + "/export";
				},
			};
		},
		rack(rackID: number) {
			const rackRoute = this.baseRoute + `/racks/${rackID}`;
			return {
				getRoute() {
					return rackRoute;
				},
				boxes() {
					return rackRoute + "/boxes";
				},
				col(colNum: number) {
					return rackRoute + `/col/${colNum}`;
				},
				row(rowNum: number) {
					return rackRoute + `/row/${rowNum}`;
				},
				rearrange() {
					return rackRoute + "/rearrange";
				},
				storageSummary() {
					return rackRoute + "/storage-summary";
				},
				activity() {
					return rackRoute + "/activity";
				},
				export() {
					return rackRoute + "/export";
				},
			};
		},
		box(boxID: number) {
			const boxRoute = this.baseRoute + `/boxes/${boxID}`;
			return {
				getRoute() {
					return boxRoute;
				},
				items() {
					return boxRoute + "/items";
				},
				col(id: number) {
					return boxRoute + `/col/${id}`;
				},
				row(id: number) {
					return boxRoute + `/row/${id}`;
				},
				bulk() {
					return boxRoute + "/items/bulk";
				},
				location() {
					return boxRoute + "/items/location";
				},
				rearrange() {
					return boxRoute + "/rearrange";
				},
				storageSummary() {
					return boxRoute + "/storage-summary";
				},
				activity() {
					return boxRoute + "/activity";
				},
				export() {
					return boxRoute + "/export";
				},
			};
		},
		/**
		 * @returns {Object} Item related routes
		 */
		item(itemID: number) {
			const itemRoute = this.baseRoute + `/items/${itemID}`;
			return {
				/**
				 * @returns {String} The route for editing a specific item
				 *
				 * Methods:
				 *
				 * GET
				 * Returns item details
				 *
				 * PATCH/PUT
				 * Updates the item
				 *
				 * DELETE
				 * Deletes the item
				 */
				getRoute() {
					return itemRoute;
				},
				/**
				 * @returns {String} The route for fetching an item's history
				 */
				history() {
					return `${itemRoute}/activity`;
				},
				attachments() {
					const itemAttachmentsRoute = `${itemRoute}/attachment-upload`;
					return {
						/**
						 *
						 * @returns - route for item attachment
						 *
						 * Methods:
						 *
						 * GET - get list of attachments for an item
						 *
						 * POST - create an attachment for an item
						 *
						 */
						getRoute() {
							return itemAttachmentsRoute;
						},
					};
				},
			};
		},
		/**
		 * ItemAttachment routes
		 * @function itemAttachments
		 */
		itemAttachments() {
			const itemAttachmentsRoute =
				API.freezerV2.baseRoute + "/item-attachments";
			return {
				getRoute() {
					return itemAttachmentsRoute;
				},
				/**
				 *
				 * @param iaId upload id of the item attachment
				 * @returns {String} The route for a specific item attachment
				 *
				 * Methods:
				 *
				 * PUT
				 *
				 * PATCH
				 *
				 * DELETE
				 *
				 */
				get(iaId: number | string) {
					const specificItemAttachmentRoute =
						this.getRoute() + `/${iaId}`;
					return specificItemAttachmentRoute;
				},
			};
		},
		bookmark() {
			const bookmarkBaseRoute = this.baseRoute + "/items/bookmarks";
			return {
				getRoute() {
					return bookmarkBaseRoute;
				},
				bulk() {
					return bookmarkBaseRoute + "/bulk";
				},
				get(item_id: number) {
					const bookmarkRoute = bookmarkBaseRoute + `/${item_id}`;
					return {
						getRoute() {
							return bookmarkRoute;
						},
					};
				},
			};
		},
		itemTypes() {
			return {
				getRoute() {
					return API.freezerV2.baseRoute + `/item-types`;
				},
				get(itemTypeID: number) {
					/**
					 * @returns {String} The route for editing a specific item type schema
					 *
					 * Methods:
					 *
					 * GET
					 * Returns item type schema detail
					 *
					 * PATCH
					 * Updates the item type schema
					 *
					 * DELETE
					 * Deletes the item type schema
					 */
					const itemTypeBaseRoute =
						API.freezerV2.baseRoute + `/item-types/${itemTypeID}`;
					return {
						getRoute() {
							return itemTypeBaseRoute;
						},
						/**
						 * @returns {String} The route for getting the item count grouped by a scpecific item type
						 * Methods:
						 *
						 * GET
						 * Returns total item associated with the ite type
						 */
						getItemCountByType() {
							return (
								API.freezerV2.baseRoute +
								`/item-count-by-type/${itemTypeID}`
							);
						},
						delete(fieldUUID: string) {
							return (
								API.freezerV2.baseRoute +
								`/item-types/${itemTypeID}/field/${fieldUUID}`
							);
						},
					};
				},
			};
		},
		search() {
			return this.baseRoute + `/search`;
		},
		searchSettings() {
			return this.baseRoute + "/search-settings";
		},
		freezerSettings() {
			return this.baseRoute + "/freezer-settings";
		},
		dropdownOptions(modelName: string, fieldName: string) {
			return (
				this.baseRoute + `/dropdown-options/${modelName}-${fieldName}`
			);
		},
		analytics: {
			itemsCreated() {
				return API.freezerV2.baseRoute + "/analytics/items-created";
			},
		},
		orders() {
			const ordersBaseRoute = this.baseRoute + "/item-orders";
			return {
				/**
				 * @returns {String} The route for creating Order
				 *
				 * Methods:
				 * Post
				 * Create the new Order
				 */
				getRoute() {
					return ordersBaseRoute;
				},
				/**
				 * @returns {String} The route for selected Order
				 *
				 * Methods:
				 *
				 * GET
				 * Returns the Order
				 *
				 * PATCH
				 * Updates the Order
				 *
				 * DELETE
				 * Deletes the Order
				 */
				get(orderId: number) {
					return ordersBaseRoute + `/${orderId}`;
				},
			};
		},
	},
	auth: {
		getRoute() {
			return "/rest-auth";
		},
		login: {
			getRoute() {
				return API.auth.getRoute() + "/login";
			},
		},
		logout: {
			getRoute() {
				return API.auth.getRoute() + "/logout";
			},
		},
		password: {
			getRoute() {
				return API.auth.getRoute() + "/password";
			},
			reset: {
				getRoute() {
					return API.auth.password.getRoute() + "/reset";
				},
				confirm: {
					getRoute() {
						return API.auth.password.reset.getRoute() + "/confirm";
					},
				},
			},
			change: {
				getRoute() {
					return API.auth.password.getRoute() + "/change";
				},
			},
		},
		registration: {
			getRoute() {
				return API.auth.getRoute() + "/registration";
			},
			verify: {
				getRoute() {
					return API.auth.registration.getRoute() + "/verify-email";
				},
			},
			resendVerification: () =>
				API.auth.registration.getRoute() + "/resend-verification-email",
		},
		user: {
			getRoute() {
				return API.auth.getRoute() + "/user";
			},
		},
	},
	dna: {
		// Get, post. User's personal files
		getRoute() {
			return "/dna/api";
		},
		// Get all folders or contents of a folder
		dir(parent_id: number) {
			const route = API.dna.getRoute() + "/dir";
			if (!parent_id) {
				return route;
			} else {
				return route + `/${parent_id}`;
			}
		},
		// Get all shared files or specific shared file
		shared(id: number) {
			const route = API.dna.getRoute() + "/shared";
			if (id) {
				return route + `/${id}`;
			} else {
				return route;
			}
		},
		// Endpoint for a specific personal file/folder
		get(id: number) {
			const route = API.dna.getRoute() + `/${id}`;
			return {
				// Get, delete, patch, put
				getRoute() {
					return route;
				},
				// Get, delete, post, patch, put
				share(userPK: number) {
					return route + `/share/${userPK}`;
				},
			};
		},
	},

	/**
	 * Metadata management endpoints.
	 */
	metadata: {
		/** Base route for all PM endpoints. */
		baseRoute: "/metadata/api",
	},
	/**
	 * Project management endpoints.
	 */
	pm: {
		/** Base route for all PM endpoints. */
		baseRoute: "/pm2/api",
		/**
		 * Project routes
		 * @function projects
		 */
		projects() {
			const projectsRoute = API.pm.baseRoute + "/projects";
			return {
				/**
				 * @function getRoute
				 * @returns {String} The route for retrieving all projects or creating projects.
				 *
				 * Methods:
				 *
				 * GET
				 * Returns all projects.
				 *
				 * POST
				 * Creates and returns project data.
				 * */
				getRoute() {
					return projectsRoute;
				},
				/**
				 * Routes for a specific project.
				 * @function get
				 * @param {String|Number} pid Project ID.
				 * @returns Object for generating routes for a specific project.
				 * */
				get(pid: number | string) {
					const specificProjRoute = this.getRoute() + `/${pid}`;
					return {
						/**
						 * @function getRoute
						 * @returns {String} The route for a specific project.
						 *
						 * Methods:
						 *
						 * GET
						 * Returns data for a project.
						 *
						 * PATCH
						 * Updates a project's fields.
						 *
						 * DELETE
						 * Deletes a project.
						 * */
						getRoute() {
							return specificProjRoute;
						},
						/**
						 * Routes for modifying a project's users.
						 * @function users
						 * @returns Object for generating project user routes
						 * */
						users() {
							const userRoute = this.getRoute() + "/users";
							return {
								/**
								 * @function getRoute
								 * @returns {String} The route for assigning a user to this project
								 *
								 * Methods:
								 *
								 * POST
								 * Assigns a user to this project
								 */
								getRoute() {
									return userRoute;
								},
								/**
								 * @function get
								 * @param {Number|String} uid User's ID
								 * @returns {String} The route for a project's user
								 *
								 * Methods:
								 *
								 * DELETE
								 * Removes a user from this project
								 */
								get(uid: number | string) {
									return this.getRoute() + `/${uid}`;
								},
							};
						},
						/**
						 * Route for obtaining/creating subproject into a project.
						 * @function subprojects
						 * @returns Object for generating subproject routes.
						 */
						subprojects() {
							const subprojectsRoute =
								this.getRoute() + "/subprojects";
							return {
								/**
								 * @function getRoute
								 * @returns {String} The route for getting a project's subprojects
								 *
								 * Methods:
								 *
								 * POST
								 * Adds a subproject to a project
								 */
								getRoute() {
									return subprojectsRoute;
								},
							};
						},
					};
				},
			};
		},
		/**
		 * Subprojects routes
		 * @function subprojects
		 */
		subprojects() {
			const subprojectsRoute = API.pm.baseRoute + "/subprojects";
			return {
				/**
				 * Routes for a specific project.
				 * @function get
				 * @param {String|Number} spid Subproject ID.
				 * @returns Object for generating routes for a specific Subproject.
				 * */
				get(spid: number | string) {
					const specificSubprojectRoute =
						subprojectsRoute + `/${spid}`;
					return {
						/**
						 * @function getRoute
						 * @returns {String} The route for a specific project.
						 *
						 * Methods:
						 *
						 * GET
						 * Returns data for a Subproject.
						 *
						 * PATCH
						 * Updates a Subproject's fields.
						 *
						 * DELETE
						 * Deletes a Subproject.
						 * */
						getRoute() {
							return specificSubprojectRoute;
						},
						/**
						 * Routes for modifying a subproject's users.
						 * @function users
						 * @returns Object for generating subprojects user routes
						 * */
						users() {
							const userRoute = this.getRoute() + "/users";
							return {
								/**
								 * @function getRoute
								 * @returns {String} The route for assigning a user to this project
								 *
								 * Methods:
								 *
								 * POST
								 * Assigns a user to this subproject
								 */
								getRoute() {
									return userRoute;
								},
								/**
								 * @function get
								 * @param {Number|String} uid User's ID
								 * @returns {String} The route for a subproject's user
								 *
								 * Methods:
								 *
								 * DELETE
								 * Removes a user from this subproject
								 */
								get(uid: number | string) {
									return this.getRoute() + `/${uid}`;
								},
							};
						},
						/**
						 * Routes for uploading attachments into a subproject.
						 * @function uploads
						 * @returns Object for generating subprojects uploads routes
						 * */
						uploads() {
							const uploadsRoute = this.getRoute() + "/uploads";
							return {
								/**
								 * @function getRoute
								 * @returns {String} The route for uploading attachments into a subproject
								 *
								 * Methods:
								 *
								 * GET
								 * Returns datas for attachments.
								 *
								 * POST
								 * upload a attachments
								 */
								getRoute() {
									return uploadsRoute;
								},
								/**
								 * @function get
								 * @param {Number} aid attachment's id
								 * @returns {String} The route for a attachments
								 *
								 * Methods:
								 *
								 * DELETE
								 * Removes a user from this subproject
								 */
								get(aid: number) {
									return this.getRoute() + `/${aid}`;
								},
							};
						},
						/**
						 * @function experiments
						 * @returns {String} Routes for subproject experiments
						 *
						 * Methods:
						 *
						 * GET
						 * Returns all experiments within this subproject
						 *
						 * POST
						 * Creates an experiment under this subproject
						 */
						experiments() {
							return this.getRoute() + "/experiments";
						},
						/**
						 * @function documents
						 * @returns {String} Routes for subproject documents
						 *
						 * Methods:
						 *
						 * GET
						 * Returns all documents within this subproject
						 */
						documents() {
							return this.getRoute() + "/documents";
						},
					};
				},
			};
		},
		/**
		 * Subproject upload routes
		 * @function subprojectUploads
		 */
		subprojectUploads() {
			const subprojectUploadsRoute =
				API.pm.baseRoute + "/subproject-uploads";
			return {
				getRoute() {
					return subprojectUploadsRoute;
				},
				/**
				 *
				 * @param suId id of the subproject upload
				 * @returns {String} The route for a specific subproject upload
				 */
				get(suId: number | string) {
					const specificSubprojectUploadRoute =
						this.getRoute() + `/${suId}`;
					return specificSubprojectUploadRoute;
				},
			};
		},
		/**
		 * Route for experiment documents
		 */
		documents() {
			const docsRoute = API.pm.baseRoute + "/experiment-documents";
			return {
				/**
				 * Routes for a specific experiment document.
				 * */
				get(uuid: GenemodDocumentUUID) {
					const specificRoute = docsRoute + `/${uuid}`;
					return {
						/**
						 * @function getRoute
						 * @returns {String} The route for a specific project.
						 *
						 * Methods:
						 *
						 * GET
						 * Returns data for a document.
						 *
						 * PATCH
						 * Updates an documents's fields.
						 *
						 * */
						getRoute() {
							return specificRoute;
						},
						/**
						 * Routes for uploading images into a doc.
						 * */
						imageUpload() {
							const uploadsRoute =
								this.getRoute() + "/image-upload";
							return {
								getRoute() {
									return uploadsRoute;
								},
							};
						},
						attachmentUpload() {
							const uploadsRoute =
								this.getRoute() + "/attachments";
							return {
								/**
								 * @function getRoute
								 * @returns {String} The route for uploading attachments into a experiment
								 *
								 * Methods:
								 * GET
								 * Returns datas for attachments.
								 * POST
								 * upload attachemtns
								 */
								getRoute() {
									return uploadsRoute;
								},
							};
						},
					};
				},
			};
		},

		/**
		 * Experiment routes
		 * @function experiments
		 */
		experiments() {
			const protocolsRoute = API.pm.baseRoute + "/experiments";
			return {
				/**
				 * Routes for a specific experiment.
				 * @function get
				 * @param {String|Number} pid Project ID.
				 * @returns Object for generating routes for a specific project.
				 * */
				get(eid: number | string) {
					const specificExpRoute = protocolsRoute + `/${eid}`;
					return {
						/**
						 * @function getRoute
						 * @returns {String} The route for a specific project.
						 *
						 * Methods:
						 *
						 * GET
						 * Returns data for an experiment.
						 *
						 * PATCH
						 * Updates an experiment's fields.
						 *
						 * DELETE
						 * Deletes an experiment.
						 * */
						getRoute() {
							return specificExpRoute;
						},
						/**
						 * Routes for uploading images into a experiment.
						 * @function imageUpload
						 * @returns Object for generating experiment image upload routes
						 * */
						imageUpload() {
							const uploadsRoute =
								this.getRoute() + "/image-upload";
							return {
								/**
								 * @function getRoute
								 * @returns {String} The route for uploading image into a experiment
								 *
								 * Methods:
								 * POST
								 * upload a image
								 */
								getRoute() {
									return uploadsRoute;
								},
							};
						},
						/**
						 * Routes for uploading attachments into a experiment.
						 * @function attachmentUpload
						 * @returns Object for generating experiment attachment upload routes
						 * */
						attachmentUpload() {
							const uploadsRoute =
								this.getRoute() + "/attachment-upload";
							return {
								/**
								 * @function getRoute
								 * @returns {String} The route for uploading attachments into a experiment
								 *
								 * Methods:
								 * GET
								 * Returns datas for attachments.
								 * POST
								 * upload attachemtns
								 */
								getRoute() {
									return uploadsRoute;
								},
								/**
								 * Methods:
								 *
								 * DELETE
								 * Remove the attachemnts from this experiment
								 */
								bulk() {
									return this.getRoute() + "/bulk";
								},
							};
						},
					};
				},
			};
		},
		/**
		 * Protocol routes
		 * @function experimentAtttachments
		 */
		experimentAttachments() {
			const experimentAttachmentsRoute =
				API.pm.baseRoute + "/experiment-attachments";
			return {
				getRoute() {
					return experimentAttachmentsRoute;
				},
				/**
				 *
				 * @param eaId id of the experiment attachment
				 * @returns {String} The route for a specific experiment attachment
				 */
				get(eaId: number | string) {
					const specificExperimentAttachmentRoute =
						this.getRoute() + `/${eaId}`;
					return {
						/**
						 *
						 * @returns {String} The route for specific experiment attachment
						 */
						getRoute() {
							return specificExperimentAttachmentRoute;
						},
					};
				},
			};
		},
		/**
		 * Protocol routes
		 * @function protocols
		 */
		protocols() {
			const protocolsRoute = API.pm.baseRoute + "/protocols";
			return {
				/**
				 * @function getRoute
				 * @returns {String} The route for retrieving all protocol names.
				 *
				 * Methods:
				 *
				 * GET
				 * Returns all protocol names.
				 *
				 * POST
				 * Creates and returns protocol name data.
				 * */
				getRoute() {
					return protocolsRoute;
				},
				/**
				 * Routes for a specific protocol.
				 * @function get
				 * @param {String|Number} pid protocol ID.
				 * @returns Object for generating routes for a specific protocol.
				 * */
				get(pid: number | string) {
					const specificProtocolRoute = this.getRoute() + `/${pid}`;
					return {
						/**
						 * @function getRoute
						 * @returns {String} The route for a specific protocol.
						 *
						 * Methods:
						 *
						 * GET
						 * Returns data for a protocol.
						 *
						 * PATCH
						 * Updates a protocol's fields (ex. name).
						 *
						 * DELETE
						 * Deletes the protocol and all versions under that protocol.
						 * */
						getRoute() {
							return specificProtocolRoute;
						},
					};
				},
			};
		},
		/**
		 * Protocol version routes
		 * @function protocolVersions
		 */
		protocolVersions() {
			const protocolVersionsRoute =
				API.pm.baseRoute + "/protocol-versions";
			return {
				/**
				 * @function getRoute
				 * @returns {String} The route for retieving protocol versions
				 *
				 * Methods:
				 *
				 * GET
				 * Returns all the protocol versions
				 *
				 * POST
				 * Creates a protocol version
				 */
				getRoute() {
					return protocolVersionsRoute;
				},
				/**
				 * Routes for a specific protocol version.
				 * @function get
				 * @param {String|Number} pvn protocol version number.
				 * @returns Object for generating routes for a specific protocol version.
				 * */
				get(pvn: number | string) {
					const specificProtocolVersionRoute =
						this.getRoute() + `/${pvn}`;
					return {
						/**
						 * @function getRoute
						 * @returns {String} The route for a specific protocol version.
						 *
						 * Methods:
						 *
						 * DELETE
						 * Deletes the version of the the protocol.
						 * */
						getRoute() {
							return specificProtocolVersionRoute;
						},
					};
				},
			};
		},
		/**
		 * Protocol search route
		 * @function search
		 */
		search() {
			const protocolSearchRoute = API.pm.baseRoute + "/search/protocols";
			/**
			 * @function getRoute
			 * @returns {String} The route for the protocol search
			 */
			return {
				getRoute() {
					return protocolSearchRoute;
				},
			};
		},
		/**
		 * Project archive route
		 * @function projectArchive
		 */
		projectArchive() {
			const projectArchiveRoute =
				API.pm.baseRoute + "/search/archived-projects";
			/**
			 * @function getRoute
			 * @returns {String} The route for the project archive
			 */
			return {
				getRoute() {
					return projectArchiveRoute;
				},
			};
		},
	},
	cal: {
		getRoute() {
			return "/cal/api";
		},
		create: {
			getRoute() {
				return API.cal.getRoute() + "/create";
			},
		},
		delete: {
			getRoute() {
				return API.cal.getRoute() + "/delete";
			},
		},
		update: {
			getRoute() {
				return API.cal.getRoute() + "/update";
			},
		},
	},
	dashboard: {
		getRoute() {
			return "/dashboard/api";
		},
		get(orgId: number) {
			const base = this.getRoute() + `/org/${orgId}`;
			return {
				/**
				 * @returns {String} - The route for the projects_overview
				 *
				 * Methods:
				 *
				 * GET
				 * Returns the pm analytic information under the given organization
				 */
				projects_overview: {
					getRoute() {
						return base + "/projects-overview";
					},
				},
			};
		},
	},
	org2: {
		/**
		 * @returns {String} The route for oranization
		 *
		 * Methods:
		 *
		 * Post
		 * post new oranization
		 *
		 * GET
		 * Returns oranization details
		 *
		 */
		getRoute() {
			return "/org/v2";
		},

		/**
		 * @param {Number} orgId - the id of oranization
		 * */
		get(orgId: number) {
			const base = this.getRoute() + `/${orgId}`;
			return {
				/**
				 * @returns {String} - The route for selected oranization
				 *
				 * Methods:
				 *
				 * GET
				 * Returns oranization details
				 *
				 * PATCH/PUT
				 * Updates the oranization info (name or time-zone)
				 *
				 * DELETE
				 * Deletes the oranization
				 */
				getRoute() {
					return base;
				},
				check_invite_email: {
					/**
					 * @returns {String} The route for checking invite email
					 *
					 * Methods:
					 *
					 * POST
					 * Returns the route for checking to see if the email invited is part of the user's org or a different org
					 */
					getRoute() {
						return base + "/check-invite-email";
					},
				},
				accept_invite: {
					getRoute() {
						return base + "/accept-invite";
					},
				},
				usage_analysis: {
					/**
					 * @returns {String} The route for usage_analysis
					 *
					 * Methods:
					 *
					 * GET
					 * Returns the usgae analysis under the given organization
					 */
					getRoute() {
						return base + "/usage-analysis";
					},
				},
				users: {
					/**
					 * @returns {String} The route for users in the team
					 *
					 * Methods:
					 *
					 * GET
					 * Returns all of members in the team
					 *
					 * Post
					 * Post new member in the team
					 */
					getRoute() {
						return base + "/org-users";
					},
					/**
					 * @param {Number} uid - the userID
					 */
					get(uid: number) {
						const base = this.getRoute() + `/${uid}`;
						return {
							/**
							 * @returns {String} - the route for selected user in team
							 *
							 * Methods:
							 *
							 * GET
							 * Returns user information as teammember in the team
							 *
							 * PATCH/PUT
							 * Updates the member info (is_Admin or status)
							 *
							 * DELETE
							 * Deletes the user in the team
							 */
							getRoute() {
								return base;
							},

							/**
							 * @returns {String} - the route of resend_invite  for selected user in team
							 *
							 * Methods:
							 
							 * PUT
							 * resend invitation to selected User 
							 */
							resendInvite() {
								return base + "/resend-invite";
							},

							/**
							 * @returns {String} - the route for transferring ownership from current member to selected member
							 *
							 * Methods:
							 
							 * PUT
							 * transfer ownership to selected member 
							 */
							transferOwnership() {
								return base + "/transfer-ownership";
							},
							/**	
							 * @returns {String} - the route of cancel_invite for selected user in team
							 *
							 * Methods:
							 
							 * DELETE
							 * cancel the invitation and remove the record 
							 */
							cancelInvite() {
								return base + "/cancel-invite";
							},

							/**
							 * @returns {String} - the route for request upgrade subscription 
							 *
							 * Methods:
							 
							 * POST
							 * request upgrade to owner
							 */
							sendUpgradeRequest() {
								return base + "/send-upgrade-request";
							},
						};
					},
				},
				stripe() {
					const stripeRoute = base + "/stripe";
					return {
						customer() {
							const customerRoute = stripeRoute + "/customer";
							return {
								/**
								 * Methods:
								 *
								 * GET
								 * Returns the Stripe customer object
								 * @see /common/types/Stripe
								 */
								getRoute() {
									return customerRoute + "/";
								},
								/**
								 * Methods:
								 *
								 * POST
								 * Change the user's subscription plan
								 */
								subscription() {
									return customerRoute + "/subscription/";
								},
								/**
								 * Methods:
								 *
								 * POST
								 * Update the team's default payment method
								 */
								payment() {
									return customerRoute + "/payment-method/";
								},
								/**
								 * Methods:
								 *
								 * POST
								 * Get the upcoming invoice given changes
								 */
								previewChanges() {
									return customerRoute + "/preview-changes/";
								},
								/**
								 * Methods:
								 *
								 * POST
								 * Get the upcoming invoice
								 */
								previewInvoice() {
									return customerRoute + "/preview-invoice/";
								},
								/**
								 * Methods:
								 *
								 * GET
								 * List the customer's invoices
								 */
								invoices() {
									return customerRoute + "/invoices/";
								},
							};
						},
					};
				},
				feature_restriction: {
					/**
					 * @returns {String} The route for feature_restriction
					 *
					 * Methods:
					 *
					 * GET
					 * Returns the feature restriction data under the given organization
					 */
					getRoute() {
						return base + "/feature-restriction";
					},
				},
				plan_name: {
					/**
					 * @returns {String} The route for plan_name
					 *
					 * Methods:
					 *
					 * GET
					 * Returns the plan name under the given organization
					 */
					getRoute() {
						return base + "/plan-name";
					},
				},
				sendUpgradeRequest() {
					return base + "/send-upgrade-request";
				},
			};
		},
		stripe() {
			const stripeRoute = API.org2.getRoute() + "/stripe";
			return {
				/**
				 * Methods:
				 *
				 * GET
				 * Returns a list of subscription plans
				 *
				 */
				subscriptionPlans() {
					return stripeRoute + "/subscription-plans/";
				},
				/**
				 * Methods:
				 *
				 * GET
				 * Returns a matching `PromotionCode` for the given `promoCode`
				 */
				promotions(promoCode: string) {
					return stripeRoute + "/promotions/" + promoCode;
				},
				/**
				 * Methods:
				 *
				 * GET
				 * Returns a list of feature restrictions
				 *
				 */
				featureRestrictionLimits() {
					return stripeRoute + "/feature-restriction-list";
				},
			};
		},
		checkInvite(token: UUID) {
			return this.getRoute() + "/check-invite/" + token;
		},
	},
	org: {
		getRoute() {
			return "/org";
		},
		announcements: {
			baseRoute: "/org/announcements",
			getRoute() {
				return API.org.announcements.baseRoute;
			},
			view(id: number) {
				return API.org.announcements.baseRoute + `/${id}`;
			},
		},
		verifyAccountStatus: {
			getRoute() {
				return API.org.getRoute() + "/verify-account-status/";
			},
		},
	},
	contact: {
		getRoute() {
			return "/send-support-email";
		},
	},
	update: {
		baseRoute: "/update/api",
		updates() {
			const updateBaseRoute = this.baseRoute + "/updates";
			return {
				getRoute() {
					return updateBaseRoute;
				},
				get(updateID: number) {
					const updateRoute = updateBaseRoute + `/${updateID}`;
					return {
						getRoute() {
							return updateRoute;
						},
					};
				},
				lastTen() {
					return updateBaseRoute + "/last-ten";
				},
			};
		},
	},
	user: {
		emailSubscription() {
			return "/user/email-subscription";
		},
		sidebarSettings() {
			return "/user/sidebar-settings/";
		},
		changePassword() {
			return "/user/change-password";
		},
		preferences() {
			return "/user/preferences";
		},
	},
	orders: {
		baseRoute: "/orders/item-orders",
		getRoute() {
			return API.orders.baseRoute;
		},
		get(orderID: number) {
			return {
				getRoute() {
					return API.orders.baseRoute + `/${orderID}`;
				},
				attachments() {
					return this.getRoute() + "/attachments/";
				},
			};
		},
	},
};

export const axios = axiosInstance;
export default API;
