import React, { useMemo } from "react";
import styles from "./BoxPreview.module.scss";
import { Typography } from "..";
import cn from "classnames";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { getColumnLabel, getRowLabel } from "@containers/Freezer/data";
import { Spin } from "@components";
import { useBoxQuery } from "@redux/inventory/Box";
import {
	useBoxItemsQuery,
	useSimpleItemsOnBoxQuery,
} from "@redux/inventory/Item";

type BoxPreviewProps = {
	/** boxId to show BoxPreview, if undefined reutun <></> */
	boxId?: number | null;
	cellSize?: number;
	emptyCellPlaceholder?: JSX.Element;
	className?: string;
};
export default function BoxPreview({
	boxId,
	cellSize = 32,
	emptyCellPlaceholder,
	className,
}: BoxPreviewProps): JSX.Element {
	const { data: box, isFetching } = useBoxQuery(boxId || skipToken);
	const { data: items = [] } = useSimpleItemsOnBoxQuery(boxId || skipToken);

	const { maxRow, maxCol } = useMemo(() => {
		return {
			maxRow: box?.rows ?? 0,
			maxCol: box?.columns ?? 0,
		};
	}, [box]);

	const renderRowHeader = () =>
		Array(maxRow)
			.fill(0)
			.map((_, index) => (
				<Typography
					key={`row-header-${index}`}
					className={styles.headerCell}
					style={{ gridArea: `${index + 2} / 1` }}
				>
					{getRowLabel(index + 1, box?.axis_direction)}
				</Typography>
			));

	const renderColHeader = () =>
		Array(maxCol)
			.fill(0)
			.map((_, index) => (
				<Typography
					key={`col-header-${index}`}
					className={styles.headerCell}
					style={{ gridArea: `1 / ${index + 2}` }}
				>
					{getColumnLabel(index + 1, box?.axis_direction)}
				</Typography>
			));

	/**
	 * Fill empty spaces with empty cells
	 */
	const renderEmptyCells = () =>
		Array(maxRow * maxCol - items.length)
			.fill(0)
			.map((_, index) => (
				<div key={`empty-${index}`} className={styles.cell}>
					{emptyCellPlaceholder}
				</div>
			));

	/**
	 * set items on Grid Box Table
	 */
	const renderItemCells = () =>
		items.map((item) => (
			<div
				key={`${item.id} ${item.name}`}
				style={{
					gridArea: `${
						(item.location?.box_location?.row || 0) + 2 || 0 + 2
					} / ${
						(item.location?.box_location?.column || 0) + 2 || 0 + 2
					}`,
				}}
				className={styles.cell}
			>
				<Typography ellipsis className={styles.itemCell}>
					{item.name}
				</Typography>
			</div>
		));

	if (isFetching) return <Spin />;
	if (!boxId) return <></>;

	return (
		<div className={cn(styles.boxWrapper, className)}>
			<div
				className={styles.cells}
				style={{
					gridTemplateRows: `15px repeat(${maxRow}, ${cellSize}px)`,
					gridTemplateColumns: `15px repeat(${maxCol}, ${cellSize}px)`,
				}}
			>
				<div key={"empty"} style={{ gridArea: "1 / 1" }} />
				{renderRowHeader()}
				{renderColHeader()}
				{renderItemCells()}
				{renderEmptyCells()}
			</div>
		</div>
	);
}
