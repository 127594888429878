import React, { useState, useEffect } from "react";
import styles from "./SearchSettingsPanel.module.scss";
import {
	DefaultFields,
	PanelSearchSettings,
	getPanelSearchSettings,
} from "../../data";
import { SidePanel, TabList, Typography, Spin } from "@components";
import { TabListTabText } from "@common/components/TabList/TabList";
import {
	CollapsibleContainer,
	RearrangeableColumn,
} from "../CollapsibleContainer";

import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import {
	useSearchSettingsQuery,
	usePatchSearchSettingsMutation,
} from "@redux/freezer/FreezerApiSlice";
import { useItemTypesQuery } from "@redux/inventory/Item";
import { createCopy, useTableSettings } from "@helpers/Hooks/useTableSettings";

type SearchSettingsPanelProps = {
	/** id of selected itemType to open item setting  */
	selectedSetting: number;
};

export default function SearchSettingsPanel(
	props: SearchSettingsPanelProps
): JSX.Element {
	const {
		genericallyNamed: { isVisible, closePanel },
	} = useCommonPanelState("freezerSearchSettings");

	const TAB_ITEMS = [
		{
			key: "column options",
			tabtitle: <TabListTabText tabTitle="Preferences" />,
			tabcontent: (
				<SearchSettingTabContent
					visible={isVisible}
					selectedSetting={props.selectedSetting}
				/>
			),
		},
	];

	return (
		<SidePanel
			header="Search settings"
			isVisible={isVisible}
			onClose={closePanel}
		>
			<TabList inSettings tabListItems={TAB_ITEMS} />
		</SidePanel>
	);
}

export function SearchSettingTabContent(props: {
	visible: boolean;
	/** id of selected itemType to show only the itemType setting  (used in itemList page)*/
	selectedItemTypeID?: number;
	/** id of selected setting itemType to open item setting  */
	selectedSetting?: number;
}): JSX.Element {
	const { data: searchSettings } = useSearchSettingsQuery();
	const [updateSearchSettings] = usePatchSearchSettingsMutation();

	const handleSave = async (settings: PanelSearchSettings[]) => {
		const data: any = {};
		settings.forEach(
			(setting) =>
				(data[setting.item_id] = {
					column_order: setting.column_order,
					...createCopy(setting.visibles),
				})
		);
		await updateSearchSettings(data);
	};

	const {
		settings,
		handleCheckbox,
		handleRearrange,
		handleReset,
		saveRearrangedColumnOrder,
		isSettingUpdated,
	} = useTableSettings({
		searchSettings,
		visible: props.visible,
		defaultFields: DefaultFields,
		saveToDB: handleSave,
		selectedItemTypeID: props.selectedItemTypeID,
	});

	const COLUMN_OPTIONS = (
		<div className={styles.container}>
			<Typography bold variant="headline" className={styles.title}>
				Customize table columns
			</Typography>
			{!settings.length ? (
				<Spin size="large" />
			) : (
				// // Container for each item type
				settings.map((setting, setting_index) => (
					<CollapsibleContainer
						key={setting_index}
						title={setting.name}
						onDragEnd={(start: number, end: number) =>
							handleRearrange(setting_index, start, end)
						}
						onReset={() => handleReset(setting_index)}
						defaultCollapsed={
							!props.selectedItemTypeID &&
							!(props.selectedSetting === setting.item_id)
						}
						type={setting.name}
						hideEditBtn={!isSettingUpdated(setting)}
					>
						{setting.column_order
							.split(",")
							.map((field_id, field_index) => (
								<RearrangeableColumn
									key={setting_index + "," + field_id}
									value={setting.visibles[field_id]}
									onChange={handleCheckbox}
									id={setting_index + "," + field_id}
									index={field_index}
								>
									{setting.fields[field_id]}
								</RearrangeableColumn>
							))}
					</CollapsibleContainer>
				))
			)}
		</div>
	);
	return COLUMN_OPTIONS;
}
