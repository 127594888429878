import React, { useCallback, useState } from "react";
import {
	Column,
	Row,
	GridCoord,
	MouseLocation,
	RestrictDragDirection,
	compareLocation,
} from "./GridTypes";
import { Merge } from "@helpers/TypeHelpers";
import { useSafeWindowEventListener } from "@helpers/Hooks";
import { nanoid } from "nanoid";
import ReactDOM from "react-dom";

const HITBOX_PERCENTAGE = 1.0;

type MouseEventHandler<Ev> = (location: GridCoord, ev: Ev) => void;

type DivProps = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;

type DraggableGridProps = Merge<
	DivProps,
	{
		className?: string;
		style?: React.CSSProperties;
		width: Column;
		height: Row;
		onDragStart: MouseEventHandler<
			React.MouseEvent<HTMLDivElement, MouseEvent>
		>;
		onDragEnd: MouseEventHandler<MouseEvent>;
		onDrag: MouseEventHandler<MouseEvent>;
		onMouseMove?: MouseEventHandler<MouseEvent>;
		restrict?: RestrictDragDirection;
		externalRef?: React.RefObject<HTMLDivElement>;
		renderExtra?: (
			updateMouseLocation: (
				mouseClientX: number,
				mouseClientY: number
			) => GridCoord | "NO_CHANGE"
		) => JSX.Element;
		dataCy?: string;
	}
>;

export const DraggableGrid = ({
	width,
	height,
	style,
	onDragStart,
	onDragEnd,
	onDrag,
	onMouseMove,
	restrict = "none",
	externalRef,
	renderExtra,
	children,
	dataCy,
	...divProps
}: DraggableGridProps): JSX.Element => {
	const [id] = useState(`draggableGrid_${nanoid()}`);
	const internalRef = React.useRef<HTMLDivElement>(null);
	const ref = externalRef || internalRef;
	const [mouseLocation, setMouseLocation] =
		useState<MouseLocation>("NOT_DRAGGING");

	const updateMouseLocation = (
		mouseClientX: number,
		mouseClientY: number
	): GridCoord | "NO_CHANGE" => {
		if (!ref.current) return "NO_CHANGE";
		const newLocation = calculateNewMousePosition(
			ref.current,
			mouseClientX,
			mouseClientY,
			width,
			height,
			mouseLocation,
			restrict,
			HITBOX_PERCENTAGE
		);

		if (
			mouseLocation === "NOT_DRAGGING" ||
			!compareLocation(mouseLocation, newLocation)
		) {
			setMouseLocation(newLocation);
			return newLocation;
		}
		return "NO_CHANGE";
	};

	// The logic would be simpler if we just had one event listener, but
	// sometimes the mouseup event is not fired when the mouse is released for some reason.
	const handleMouseMoveAndUp = useCallback(
		(ev: MouseEvent) => {
			setMouseLocation((location) => {
				if (location === "NOT_DRAGGING" || !ref.current)
					return "NOT_DRAGGING";

				// If the mouse is released, end the drag.
				if (ev.buttons === 0) {
					setTimeout(() => {
						onDragEnd(location as GridCoord, ev);
					});
					return "NOT_DRAGGING";
				}

				// If the mouse is still down, update the location.
				const newLocation = calculateNewMousePosition(
					ref.current,
					ev.clientX,
					ev.clientY,
					width,
					height,
					location,
					restrict,
					HITBOX_PERCENTAGE
				);
				if (compareLocation(location, newLocation)) {
					return location;
				}
				setTimeout(() => {
					onDrag(newLocation, ev);
				});
				return newLocation;
			});
		},
		[
			onDrag,
			onDragEnd,
			mouseLocation,
			setMouseLocation,
			ref,
			width,
			height,
			restrict,
		]
	);

	useSafeWindowEventListener("mouseup", handleMouseMoveAndUp);
	useSafeWindowEventListener("mousemove", handleMouseMoveAndUp);

	return (
		<>
			<div
				{...divProps}
				id={id}
				style={style}
				ref={internalRef}
				draggable={false}
				onMouseDown={(ev) => {
					ReactDOM.unstable_batchedUpdates(() => {
						onDragStart(
							updateMouseLocation(
								ev.clientX,
								ev.clientY
							) as GridCoord,
							ev
						);
						ev.stopPropagation();
					});
				}}
				onMouseMove={(ev) => {
					ReactDOM.unstable_batchedUpdates(() => {
						if (!ref.current) return;
						onMouseMove?.(
							calculateNewMousePosition(
								ref.current,
								ev.clientX,
								ev.clientY,
								width,
								height,
								mouseLocation,
								restrict,
								HITBOX_PERCENTAGE
							),
							ev as any
						);
					});
				}}
				data-cy={dataCy}
			>
				{children}
			</div>
			{renderExtra && renderExtra(updateMouseLocation)}
		</>
	);
};

/**
 * Will calculate the position your mouse is currently in in terms of the space table coordinates.
 *
 * If a current location is given, then the new position is only updated if your mouse is in the "hitbox"
 * of a table cell for a given coordinate.
 *
 * If the "position" is given, then only the relevant coordinate is calculated to save on renders when
 * doing a resize.
 */
const calculateNewMousePosition = (
	table: HTMLDivElement,
	mouseClientX: number,
	mouseClientY: number,
	spaceWidth: Column,
	spaceHeight: Row,
	currentLocation: GridCoord | "NOT_DRAGGING",
	// provide position if you only want one dimension calculated, set's the other dimension to 0 in output, expects it as 0 as well
	restrict: RestrictDragDirection,
	// how large in percentage we want the cell "hit box" to be (ie: we don't want a drag into the area around cell edges to trigger a change)
	hitBoxPercentage = 0.7
): GridCoord => {
	const tablePixelBounds = table.getBoundingClientRect();

	const currentLoc =
		currentLocation === "NOT_DRAGGING"
			? { row: null, column: null }
			: currentLocation;

	const row =
		restrict === "horizontal"
			? 0
			: getIndexAndInHitbox(
					currentLoc.row,
					mouseClientY,
					tablePixelBounds.top,
					tablePixelBounds.height,
					spaceHeight,
					hitBoxPercentage
			  );
	const column =
		restrict === "vertical"
			? 0
			: getIndexAndInHitbox(
					currentLoc.column,
					mouseClientX,
					tablePixelBounds.left,
					tablePixelBounds.width,
					spaceWidth,
					hitBoxPercentage
			  );

	return {
		row,
		column,
	} as GridCoord;
};

/**
 * Gets index that mouse is in and whether the mouse is within the "hitbox" of a cell for a single dimension
 */
const getIndexAndInHitbox = (
	currentIndex: number | null, // the row/column index that the mouse is currently set as hovered over
	clientPx: number, // mouse "client" location in pixel,
	tableMinPx: number, // origin of the table in the "client" in pixels,
	tableSizePx: number, // width/height of the table in pixels,
	spaceDim: number, // width/height of space table in terms of number of cells
	hitBoxPercentage: number
) => {
	// get x or y for mouse postion to table upper left corner in pixels
	const mousePx = clientPx - tableMinPx;

	// get height or width of each table cell in pixels
	const cellSizePx = tableSizePx / spaceDim;

	// calculate row or column index that the mouse is currently in
	const index = Math.trunc(mousePx / cellSizePx);

	// center of current cell
	const centerOfCellPx = (index + 0.5) * cellSizePx;

	// how distance from the center of the cell to the hit box edge
	const hitBoxRadius = (cellSizePx * hitBoxPercentage) / 2;

	// distance from cell center to mouse
	const mouseRadius = Math.abs(mousePx - centerOfCellPx);

	// is the mouse in the hit box of the current cell?
	const isMouseInHitbox = mouseRadius < hitBoxRadius;

	return currentIndex === null || isMouseInHitbox ? index : currentIndex;
};
