import { capitalizeFirstLetter } from "@helpers/Formatters";
import { appSelector, AppState, useAppDispatch } from "@redux/store";
import { baseSidePanelSlice, commonPanelActions } from "./CommonPanels";
import { useRef } from "react";

type PanelEntries = Extract<keyof AppState["commonPanels"], string>;
/**
 * Hook that makes accessing common panel data as commonModal hook.
 */
export const useCommonPanelState = <K extends PanelEntries>(panel: K) => {
	const dispatch = useAppDispatch();
	const data = appSelector((state) => state.commonPanels[panel]);
	const openedSidePanel = appSelector(
		(state) => state.baseSidePanel.openedSidePanel
	);
	const isVisible = openedSidePanel === panel;

	const capitalizedPanel = capitalizeFirstLetter(panel);
	const actionName = `set${capitalizedPanel}`;
	const action = commonPanelActions[actionName];

	type StateType = AppState["commonPanels"][K];

	const openPanel = (newValue: StateType) => {
		dispatch(action({ ...(newValue || {}) }));
		dispatch(
			baseSidePanelSlice.actions.setOpenedSidePanel({
				openedSidePanel: panel,
			})
		);
	};
	const closePanel = () => {
		dispatch(
			baseSidePanelSlice.actions.setOpenedSidePanel({
				openedSidePanel: "",
			})
		);
	};

	const togglePanel = (newValue: StateType) =>
		isVisible ? closePanel() : openPanel(newValue);

	type ReturnType = {
		[Key in `is${typeof capitalizedPanel}Visible`]: boolean;
	} & { [Key in `${K}Data`]: StateType } & {
		[Key in `open${typeof capitalizedPanel}`]: typeof openPanel;
	} & { [Key in `close${typeof capitalizedPanel}`]: typeof closePanel } & {
		[Key in `toggle${typeof capitalizedPanel}`]: typeof openPanel;
	} & {
		genericallyNamed: {
			isVisible: boolean;
			data: StateType;
			openPanel: typeof openPanel;
			closePanel: typeof closePanel;
		};
	};

	return {
		[`is${capitalizedPanel}Visible`]: isVisible,
		[`${panel}Data`]: data,
		[`open${capitalizedPanel}`]: openPanel,
		[`close${capitalizedPanel}`]: closePanel,
		[`toggle${capitalizedPanel}`]: togglePanel,
		genericallyNamed: {
			isVisible,
			data,
			openPanel,
			closePanel,
		} as const,
	} as ReturnType;
};

export const useCommonPanelsOpen = () => {
	const dispatch = useAppDispatch();
	const openedPanelRef = useRef<PanelEntries | "">();

	const openedPanel = appSelector(
		(state) => state.baseSidePanel.openedSidePanel
	) as PanelEntries | "";

	openedPanelRef.current = openedPanel;

	const openPanel = <K extends PanelEntries>(panel: K) => {
		return (data: AppState["commonPanels"][K]) => {
			const capitalizedPanel = capitalizeFirstLetter(panel);
			const setPanelData = commonPanelActions[`set${capitalizedPanel}`];
			dispatch(
				baseSidePanelSlice.actions.setOpenedSidePanel({
					openedSidePanel: panel,
				})
			);
			dispatch(setPanelData({ ...(data || {}) }));
		};
	};

	const togglePanel = <K extends PanelEntries>(panel: K) => {
		return (data: AppState["commonPanels"][K]) => {
			if (openedPanelRef.current === panel) {
				dispatch(
					baseSidePanelSlice.actions.setOpenedSidePanel({
						openedSidePanel: "",
					})
				);
			} else {
				openPanel(panel)(data);
			}
		};
	};

	const closePanel = () => {
		dispatch(
			baseSidePanelSlice.actions.setOpenedSidePanel({
				openedSidePanel: "",
			})
		);
	};

	return {
		openedPanel,
		togglePanel,
		openPanel,
		closePanel,
	};
};
