import {
	Box,
	BoxItemLocation,
	CustomItemTypeSchema,
	CustomItemTypeSchemaDTO,
	CustomItemTypeSchemaToNonDTO,
	Freezer,
	ItemGroup,
	ItemLocationData,
	NewBoxItemLocation,
	PaginatedSearchQuery,
	PaginatedSearchResults,
	RepositoryItemsFilters,
	RepositoryItemsFiltersOnly,
	RepositoryItemsResult,
	RepositorySearchSettings,
	SearchFilterState,
	SearchResults,
	SimpleItem,
} from "@common/types";
import { getItemBackendFormat } from "@helpers/BoxHelper";
import { AtLeastId } from "@helpers/TypeHelpers";

import { Item } from "@common/types";
import { freezerApi } from "@redux/freezer/FreezerApiSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { inventoryApi, withFreezerBase } from ".";
import { FilterOptionsSearch } from "@common/types/Search";
import { number } from "mathjs";
import {
	ExperimentDocumentItem,
	ExperimentDocumentItemPayload,
} from "@common/types/ExperimentDocumentItem";

export type BulkItemResponse = {
	items: Item[];
	failed: Item[];
};

const ItemApi = inventoryApi.injectEndpoints({
	endpoints: (builder) => ({
		simpleItemsOnBox: builder.query<SimpleItem[], number>({
			query: (id) => ({
				url: withFreezerBase(`v2/boxes/${id}/items-simple/`),
			}),
			providesTags: ["Item"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		boxItems: builder.query<Item[], { id: number; is_archived?: boolean }>({
			query: ({ id, is_archived = false }) => ({
				url: withFreezerBase(`v2/boxes/${id}/items/`),
				params: { is_archived },
			}),
			providesTags: ["Item"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		itemGroupItems: builder.query<
			Item[],
			{ id: number; is_archived?: boolean }
		>({
			query: ({ id, is_archived = false }) => ({
				url: withFreezerBase(`v2/item-groups/${id}/items/`),
				params: { is_archived },
			}),
			providesTags: ["Item"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		getBulkItemsFromItems: builder.query<
			PaginatedSearchResults<Item>,
			PaginatedSearchQuery & { ids: number[] }
		>({
			query: (params) => ({
				url: withFreezerBase(`v2/items/`),
				method: "GET",
				params: {
					...params,
					ids: params.ids.join(","),
					sort_by: "custom_id",
				},
			}),
			providesTags: ["Item"],
		}),

		getBulkItems: builder.query<
			Item[],
			{ parentBoxId: number; ids: number[] }
		>({
			query: ({ parentBoxId, ids }) => ({
				url: withFreezerBase(`v2/boxes/${parentBoxId}/items/bulk/`),
				method: "GET",
				params: { ids },
			}),
			providesTags: ["FreezerItem", "Item"],
		}),
		bulkItemsCreate: builder.mutation<
			BulkItemResponse,
			{ items: Partial<Item>[] }
		>({
			query: ({ items }) => ({
				url: withFreezerBase(`v2/items/bulk-create-items/`),
				method: "POST",
				body: items.map((item) => getItemBackendFormat(item)),
			}),
			invalidatesTags: ["Item"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		bulkItemsEdit: builder.mutation<
			BulkItemResponse,
			{ items: Partial<Item>[] }
		>({
			query: ({ items }) => ({
				url: withFreezerBase(`v2/items/bulk-edit-items/`),
				method: "POST",
				body: items.map((item) => getItemBackendFormat(item)),
			}),
			invalidatesTags: ["Item"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		bulkItemsDelete: builder.mutation<
			void,
			{ ids: number[]; invalidateItems?: boolean }
		>({
			query: ({ ids }) => ({
				url: withFreezerBase(`v2/items/bulk-delete/`),
				method: "POST",
				body: {
					ids,
				},
			}),
			invalidatesTags: ["Item"],
			onQueryStarted: async (
				{ invalidateItems = true },
				{ dispatch, queryFulfilled }
			) => {
				await queryFulfilled;
				if (invalidateItems) {
					dispatch(freezerApi.util.invalidateTags(["Item"]));
				}
			},
			extraOptions: {
				maxRetries: 0,
			},
		}),
		pasteItems: builder.mutation<
			| {
					type: "DELETE_ONLY";
			  }
			| {
					type: "CREATE_ONLY" | "CREATE_AND_DELETE";
					data: Item[];
			  }
			| {
					type: "NEITHER_DELETE_NOR_CREATE";
			  },
			{
				boxId: number;
				toDelete: number[];
				toCreate: Partial<Item>[];
			}
		>({
			invalidatesTags: ["Item", "Bookmark"],
			queryFn: async (
				{ toDelete, toCreate },
				_queryApi,
				_extraOptions,
				fetchWithBQ
			) => {
				let responseType = "NEITHER_DELETE_NOR_CREATE";
				const promises: MaybePromise<any>[] = [];
				if (toDelete.length) {
					responseType = "DELETE_ONLY";
					promises.push(
						await fetchWithBQ({
							url: withFreezerBase(`v2/items/bulk-delete/`),
							method: "POST",
							body: {
								ids: toDelete,
							},
						})
					);
				}

				let createPromise = null as MaybePromise<any> | null;
				if (toCreate.length) {
					if (responseType === "DELETE_ONLY") {
						responseType = "CREATE_AND_DELETE";
					} else {
						responseType = "CREATE_ONLY";
					}

					createPromise = fetchWithBQ({
						url: withFreezerBase(`v2/items/bulk-create-items/`),
						method: "POST",
						body: toCreate.map(getItemBackendFormat),
					});
					promises.push(createPromise);
				}

				const result = await Promise.all(promises);
				const firstResultWithError = result.find((res) => res?.error);
				if (firstResultWithError) {
					return {
						error: firstResultWithError.error as FetchBaseQueryError,
					};
				}
				const data = createPromise ? await createPromise : undefined;

				// There seems to be weird behavior with the query return type
				return {
					data: {
						type: responseType,
						data: data?.data,
					},
				} as any;
			},
		}),
		item: builder.query<Item, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/items/${id}/`),
			}),
			providesTags: ["Item"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		boxItemWithLocation: builder.query<
			Item,
			{ boxId: number; location: { column: number; row: number } }
		>({
			query: ({ boxId, location: { row, column } }) =>
				withFreezerBase(
					`v2/boxes/${boxId}/items/location/?row=${row}&column=${column}`
				),
			providesTags: ["Item"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		itemCreate: builder.mutation<
			Item,
			{ boxId?: number; item: Partial<Item> }
		>({
			query: ({ boxId, item }) => ({
				url: withFreezerBase(`v2/items/`),
				method: "POST",
				body: getItemBackendFormat(item),
			}),
			invalidatesTags: ["Box", "Item"],
		}),
		itemPatch: builder.mutation<Item, AtLeastId<Item>>({
			query: (item) => ({
				url: withFreezerBase(`v2/items/${item.id}/`),
				method: "PATCH",
				body: getItemBackendFormat(item),
			}),
			invalidatesTags: ["Item"],
		}),
		itemDelete: builder.mutation<void, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/items/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["Item", "Bookmark"],
		}),
		itemTypes: builder.query<CustomItemTypeSchema[], void>({
			query: () => ({
				url: withFreezerBase("v2/item-types/"),
			}),
			transformResponse: (response: CustomItemTypeSchemaDTO[]) =>
				response.map(CustomItemTypeSchemaToNonDTO),
			providesTags: ["ItemTypes"],
		}),
		itemType: builder.query<CustomItemTypeSchema, number>({
			query: (itemTypeId) => ({
				url: withFreezerBase(`v2/item-types/${itemTypeId}/`),
			}),
			transformResponse: (response: CustomItemTypeSchemaDTO) =>
				CustomItemTypeSchemaToNonDTO(response),
			providesTags: ["ItemType"],
		}),
		saveItemType: builder.mutation<
			CustomItemTypeSchema,
			Partial<CustomItemTypeSchemaDTO>
		>({
			query: (body) => ({
				url: withFreezerBase("v2/item-types/"),
				method: "POST",
				body,
			}),
			transformResponse: (response: CustomItemTypeSchemaDTO) =>
				CustomItemTypeSchemaToNonDTO(response),
			invalidatesTags: ["ItemType", "ItemTypes"],
		}),
		deleteItemType: builder.mutation<void, number>({
			query: (itemTypeId) => ({
				url: withFreezerBase(`v2/item-types/${itemTypeId}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["ItemType", "ItemTypes"],
		}),
		patchItemType: builder.mutation<
			CustomItemTypeSchema,
			{ itemTypeId: number; data: Partial<CustomItemTypeSchemaDTO> }
		>({
			query: ({ itemTypeId, data }) => ({
				url: withFreezerBase(`v2/item-types/${itemTypeId}/`),
				method: "PATCH",
				body: { ...data },
			}),
			transformResponse: (response: CustomItemTypeSchemaDTO) =>
				CustomItemTypeSchemaToNonDTO(response),
			invalidatesTags: ["ItemType", "ItemTypes"],
		}),
		getLastAddedItem: builder.query<
			{
				freezer: Freezer;
				last_added: Item;
				box?: Box;
				item_group: ItemGroup;
			},
			{ freezerId: number; params?: { exclude?: string[] } }
		>({
			query: ({ freezerId, params }) => ({
				url: withFreezerBase(`v2/freezers/${freezerId}/last-added/`),
				params,
			}),
			extraOptions: {
				maxRetries: 0,
			},
		}),
		itemLocation: builder.query<ItemLocationData, number>({
			query: (itemId) => ({
				url: withFreezerBase(`v2/items/${itemId}/location/`),
			}),
			extraOptions: {
				maxRetries: 0,
			},
		}),
		moveItemLocation: builder.mutation<
			void,
			{
				id: number;
				old_location: BoxItemLocation;
				new_location: NewBoxItemLocation;
				replace?: boolean;
			}
		>({
			query: ({ new_location, old_location, id, replace }) => ({
				url: withFreezerBase(`v2/items/${id}/change-item-location/`),
				method: "POST",
				params: {
					replace,
				},
				body: { new_location, old_location },
			}),
			extraOptions: {
				maxRetries: 0,
			},
			invalidatesTags: ["Item"],
		}),
		freezerItemSearch: builder.query<
			SearchResults,
			Partial<SearchFilterState>
		>({
			query: (params) => ({
				url: withFreezerBase(
					`v2/item-search/${
						params.initial
							? "initial"
							: params.is_grouped
							? "grouped"
							: "ungrouped"
					}/`
				),
				method: "GET",
				params: params,
			}),
			providesTags: ["FreezerItem", "Item"],
		}),

		// Repository Table settings:
		repositorySearchSettings: builder.query<RepositorySearchSettings, void>(
			{
				query: () => ({
					url: withFreezerBase(`v2/repository-settings/`),
					method: "GET",
				}),
				providesTags: ["RepositorySearchSettings"],
			}
		),
		patchRepositorySearchSettings: builder.mutation<
			RepositorySearchSettings,
			Partial<RepositorySearchSettings>
		>({
			query: (data) => ({
				url: withFreezerBase(`v2/repository-settings/`),
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["RepositorySearchSettings"],
		}),

		repositoryItems: builder.query<
			RepositoryItemsResult,
			RepositoryItemsFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/list-items/"),
				method: "GET",
				params: {
					...params,
					include_filter_options: false,
				},
			}),
			providesTags: ["Item"],
			extraOptions: {
				maxRetries: 3,
				backoff: async (attempt) => {
					let backoff = 4000;
					if (attempt === 1) backoff = 1000;
					if (attempt === 2) backoff = 2000;
					await new Promise((resolve) => {
						setTimeout(resolve, backoff);
					});
				},
			},
		}),
		restorableItems: builder.query<
			RepositoryItemsResult,
			RepositoryItemsFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/restorable-items/"),
				method: "GET",
				params: {
					...params,
					include_filter_options: true,
				},
			}),
			providesTags: ["Item"],
		}),
		repositoryItemsFilter: builder.query<
			FilterOptionsSearch,
			{
				include_archived?: boolean;
			} | null
		>({
			query: (params) => ({
				url: withFreezerBase("v2/list-items/item-filters/"),
				method: "GET",
				params,
			}),
			providesTags: ["Item"],
		}),
		addItemTobox: builder.mutation<
			void,
			{
				itemId: number;
				boxId: number;
			}
		>({
			query: ({ boxId, itemId }) => ({
				url: withFreezerBase(`v2/items/${itemId}/add-item-to-box/`),
				method: "POST",
				body: { box_id: boxId },
			}),
			extraOptions: {
				maxRetries: 0,
			},
			invalidatesTags: ["Item"],
		}),
		bulkRelocateItems: builder.mutation<
			void,
			{ ids: number[]; box_id: number }
		>({
			query: (body) => ({
				url: withFreezerBase("v2/items/bulk-relocate-items/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Item"],
		}),

		// ***********
		// ITEM EXPERIMENT MENTION
		// ***********

		itemQuickSearch: builder.query<
			{ id: number; name: string; custom_id: number }[],
			{ search?: string }
		>({
			query: (params) => ({
				url: withFreezerBase("v2/items-quick-search/"),
				method: "GET",
				params: params,
			}),
			providesTags: ["Item"],
		}),
	}),
});

export const {
	useSimpleItemsOnBoxQuery,
	useBoxItemsQuery,
	useItemGroupItemsQuery,
	useItemQuery,
	useLazyItemQuery,
	useBoxItemWithLocationQuery,
	useLazyBoxItemWithLocationQuery,
	useBulkItemsCreateMutation,
	useBulkItemsDeleteMutation,
	useBulkItemsEditMutation,
	usePasteItemsMutation,
	useGetBulkItemsFromItemsQuery,
	useLazyGetBulkItemsFromItemsQuery,
	useGetBulkItemsQuery,
	useLazyGetBulkItemsQuery,
	useItemCreateMutation,
	useItemPatchMutation,
	useItemDeleteMutation,
	useItemTypesQuery,
	useItemTypeQuery,
	useSaveItemTypeMutation,
	useDeleteItemTypeMutation,
	usePatchItemTypeMutation,
	useGetLastAddedItemQuery,
	useLazyGetLastAddedItemQuery,
	useItemLocationQuery,
	useLazyItemLocationQuery,
	useFreezerItemSearchQuery,
	useLazyFreezerItemSearchQuery,

	// ***********
	// REPOSITORY QUERIES
	// ***********
	useRepositorySearchSettingsQuery,
	usePatchRepositorySearchSettingsMutation,
	useRepositoryItemsQuery,
	useRepositoryItemsFilterQuery,
	useRestorableItemsQuery,
	useMoveItemLocationMutation,
	useAddItemToboxMutation,
	useBulkRelocateItemsMutation,

	// ***********
	// ITEM EXPERIMENT MENTION QUERIES
	// ***********

	useItemQuickSearchQuery,
} = ItemApi;
