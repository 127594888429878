import React, { useState, useEffect, createContext } from "react";
import { Modal } from "@components";
import FreezerList from "./FreezerList";
import ShelfList from "./NavigationList/ShelfList";
import RackList from "./NavigationList/RackList";
import styles from "./index.module.scss";
import { cloneDeep } from "lodash";
import { GridCoord } from "@common/components/InteractiveGrid/GridTypes";
import "./index.scss";
import Typography from "antdv4/lib/typography/Typography";

type MovingCartProps = {
	children?: React.ReactNode;
	visible: boolean;
	onClose?: () => void;
	title: JSX.Element | string;
	showRacks?: boolean;
};

type MovingCartContext = {
	/** ID of the selected rack */
	rackId: number | null;
	categoryId: number | null;
	freezerId: number | null;
	setFreezer: (freezer: number | null) => void;
	setShelf: (shelf: number | null) => void;
	setRack: (rack: number | null) => void;
	setCategory: (category: number | null) => void;
	/** Clears the selected rack */
	clearRack: () => void;
	clearCategory: () => void;
	/** whether the UnsavedChangesModal to show or not */
	/** If ture, show UnsavedChangesModal before move clicked freezer */
	setConfirmMoveFreezer: (confirmMoveFreezer: boolean) => void;
};

export const MovingCartContext = createContext<MovingCartContext>({
	rackId: null,
	categoryId: null,
	freezerId: null,
	setFreezer: () => {},
	setShelf: () => {},
	setRack: () => {},
	setCategory: () => {},
	clearRack: () => {},
	clearCategory: () => {},
	setConfirmMoveFreezer: () => {},
});

/**
 * Base component for moving cart
 */
export default function MovingCart({
	title,
	children,
	visible,
	onClose,
	showRacks = true,
}: MovingCartProps): JSX.Element {
	const [freezerId, setFreezerId] = useState<number | null>(null);
	const [shelfId, setShelfId] = useState<number | null>(-1);
	const [rackId, setRackId] = useState<number | null>(-1);
	const [categoryId, setCategoryId] = useState<number | null>(null);
	const [confirmMoveFreezer, setConfirmMoveFreezer] = useState(false);

	useEffect(() => {
		if (visible) {
			setFreezerId(null);
			setShelfId(-1);
			setRackId(-1);
			setCategoryId(-1);
		}
	}, [visible]);

	const clearRackAndShelf = () => {
		setShelfId(null);
		setRackId(null);
		setCategoryId(null);
		setConfirmMoveFreezer(false);
	};

	return (
		<Modal
			closeIconClassName={styles.closeIcon}
			visible={visible}
			onCancel={onClose}
			title={title}
			footer={null}
			width={1315}
			resize={false}
			wrapClassName="genemod-moving-cart"
			destroyOnClose
			skipVisibleOverlayMask
		>
			<div className={styles.container}>
				<div className={styles.body}>
					<FreezerList
						freezerId={freezerId}
						setFreezerId={setFreezerId}
						confirmMoveFreezer={confirmMoveFreezer}
						clearRackAndShelf={clearRackAndShelf}
					/>
					<div className={styles.right}>
						{!shelfId && showRacks ? (
							<ShelfList
								freezerId={freezerId}
								setShelfId={setShelfId}
							/>
						) : !rackId && !categoryId && showRacks ? (
							<RackList
								shelfId={shelfId}
								setshelfId={setShelfId}
								setRackId={setRackId}
								setCategoryId={setCategoryId}
							/>
						) : (
							<MovingCartContext.Provider
								value={{
									freezerId,
									rackId,
									categoryId,
									clearRack: () => setRackId(null),
									clearCategory: () => setCategoryId(null),
									setConfirmMoveFreezer:
										setConfirmMoveFreezer,
									setFreezer: setFreezerId,
									setShelf: setShelfId,
									setRack: setRackId,
									setCategory: setCategoryId,
								}}
							>
								{children}
							</MovingCartContext.Provider>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
}

export type UseCartPlacementAPI = {
	/** Clears all placed items */
	reset: () => void;
	/**
	 * Returns the ID of the item placed at the location.
	 * `undefined` if no item exists.
	 * */
	getIdAtLocation: (row: number, column: number) => number | undefined;
	/**
	 * Places an item at a specific location
	 */
	placeIdAtLocation: (id: number, row: number, column: number) => void;
	/**
	 * Clears a location
	 * */
	clearLocation: (row: number, column: number) => void;
	/**
	 * Returns the number of items placed
	 */
	getCount: () => number;
	/**
	 * Returns a dictionary with locations for all placed objects
	 *
	 * { id: `Location` }
	 */
	getLocations: () => Record<string, GridCoord>;
};

/**
 * Custom hook for handling placement of items in the moving cart
 */
export function useCartPlacement(): UseCartPlacementAPI {
	// Mapping from row to column to id of an item
	const [placement, setPlacement] = useState<
		Record<string, Record<string, number>>
	>({});

	return {
		reset: () => {
			setPlacement({});
		},
		getIdAtLocation: (row, column) => {
			return placement[row]?.[column];
		},
		/**
		 * Returns the list of the locations with id
		 * */
		getLocations: () => {
			const data: Record<string, GridCoord> = {};
			for (const row of Object.keys(placement)) {
				const columnData = placement[row];
				for (const column of Object.keys(columnData)) {
					const id = columnData[column];
					data[id] = {
						row: parseInt(row),
						column: parseInt(column),
					} as GridCoord;
				}
			}
			return data;
		},
		/**
		 * Places an item at a specific location
		 */
		placeIdAtLocation: (id: number, row: number, column: number) => {
			// Create a copy to avoid reference issues
			const copy = cloneDeep(placement);
			// Insert an entry for this row
			if (!copy[row]) {
				copy[row] = {};
			}
			copy[row][column] = id;
			setPlacement(copy);
		},
		clearLocation: (row: number, column: number) => {
			const copy = cloneDeep(placement);
			// If nothing has been placed in that location, exit
			if (!copy[row]?.[column]) return;

			delete copy[row][column];
			setPlacement(copy);
		},
		getCount: () => {
			return Object.keys(placement).reduce((acc, key) => {
				return acc + Object.keys(placement[key]).length;
			}, 0);
		},
	};
}
