import { GenemodIcon, Select, Spin } from "@components";
import { FieldType, UUID, Item } from "@common/types";
import {
	useAddDropdownOptionMutation,
	useDeleteDropdownOptionMutation,
	useLazyDropdownOptionsQuery,
} from "@redux/freezer/FreezerApiSlice";
import { AutoComplete } from "antd";
import { AutoCompleteProps } from "antd/lib/auto-complete";
import React, { useEffect, useState } from "react";
import { ANTIBIOTICS, GENUS_SPECIES_MAP } from "../../../data";
import "./ReagentSpecificFields.scss";
import { useItemTypesQuery } from "@redux/inventory/Item";

export const useCustomFormItemFields = (
	item: Item,
	FormItem: any,
	handleTypeDataChange: (event: any) => any
) => {
	const { data: itemTypes } = useItemTypesQuery();
	const typeData = item.type_data;
	const fieldData =
		itemTypes?.find((itemType) => itemType.id === item.item_type)?.schema ||
		[];

	/**
	 * Renders a custom component for a specific
	 */
	const renderCustomField = (fieldUuid: string, fieldType: number) => {
		const value = typeData?.[fieldUuid];
		const genusValue = item.type_data?.[
			fieldData.find((field) => field.type === FieldType.GENUS)?.uuid ||
				""
		] as string | undefined;

		switch (fieldType) {
			case FieldType.GENUS:
				return (
					<GenusSelect
						value={value as string}
						uuid={fieldUuid}
						onChange={handleTypeDataChange}
					/>
				);
			case FieldType.SPECIES:
				return (
					<SpeciesSelect
						value={value as string}
						uuid={fieldUuid}
						onChange={handleTypeDataChange}
						genus={genusValue}
					/>
				);
			case FieldType.SELECTION_MARKER:
				return (
					<AntibioticSelect
						value={value as string[]}
						uuid={fieldUuid}
						onChange={handleTypeDataChange}
					/>
				);
		}
		return null;
	};

	// Generate JSX for each field
	const fields = fieldData.map((field) => {
		if (
			field?.type === FieldType.GENUS ||
			field?.type === FieldType.SPECIES ||
			field?.type === FieldType.SELECTION_MARKER
		) {
			return (
				<FormItem
					key={field.uuid}
					gutterBottom
					label={field.label}
					type="custom"
					width={field.layout}
				>
					{renderCustomField(field.uuid, field.type)}
				</FormItem>
			);
		}

		let formType;
		let formValidators;
		if (field?.type === FieldType.MULTIPLE_LINE) {
			formType = "textarea";
		} else if (field?.type === FieldType.NUMBER) {
			formType = "number";
			formValidators = [
				{
					validator: (val: any) => !isNaN(val),
					error: "Please enter a number",
				},
			];
		} else if (field.type === FieldType.DATE_PICKER) {
			formType = "datepicker";
		}

		return (
			<FormItem
				key={field.uuid}
				label={field?.label}
				onChange={handleTypeDataChange}
				id={field.uuid}
				value={(typeData && typeData[field.uuid]) || ""}
				validators={formValidators}
				type={formType}
				width={field?.layout}
			/>
		);
	});
	return { fields, itemTypes };
};

type RenderReagentSpecificFieldsProps = {
	item: Item;
	FormSection: any;
	FormItem: any;
	handleTypeDataChange: (event: any) => any;
	key: any;
	defaultCollapsed?: boolean;
};

/**
 * Renders fields specific to the reagent type
 * @param {Object} props
 * @param {Item} props.item - the selected item
 * @param {Component} props.FormSection - the components to render FormSection
 * @param {Component} props.FormItem - the components to render each row in section
 * @param {Function} props.handleTypeDataChange - the function to handle values in field
 * @param {Boolean} props.defaultCollapsed - the default collapsed state of the section
 */
function RenderReagentSpecificFields({
	item,
	FormSection,
	FormItem,
	handleTypeDataChange,
	key,
	defaultCollapsed,
}: RenderReagentSpecificFieldsProps) {
	const { fields, itemTypes } = useCustomFormItemFields(
		item,
		FormItem,
		handleTypeDataChange
	);

	const itemTypeName =
		itemTypes?.find((itemType) => itemType.id === item.item_type)?.name ||
		"Custom";

	if (fields.length) {
		return (
			<FormSection
				dividerBefore
				key={key}
				sectionTitle={itemTypeName + " information"}
				defaultCollapsed={defaultCollapsed}
			>
				{fields}
			</FormSection>
		);
	} else {
		return null;
	}
}
export default RenderReagentSpecificFields;

type TypeSelectorProps = {
	value: string;
	placeholder?: string;
	onChange: (value: any) => any;
	onBlur?: (val: any) => void;
	autoFocus?: boolean;
};

export type StrainFieldType = TypeSelectorProps & {
	uuid?: UUID;
	onBlur?: any;
};

/**
 * the components to select Genus
 * @param {Object} props
 * @param {String} props.value - the value of Genus
 * @param {String} props.placeholder - placeholder for autocomplete
 * @param {Function} props.onChange -function called when content is changed
 */
export function GenusSelect({
	value,
	uuid,
	placeholder,
	onChange,
	onBlur,
	autoFocus,
}: StrainFieldType) {
	const onChangeWrap = (value: string) => {
		const temp = {
			target: {
				value: value,
				id: uuid,
			},
		};
		if (onChange) onChange(temp);
	};
	return (
		<ReagentSelector
			value={value}
			onChange={onChangeWrap as any}
			dataSource={Object.keys(GENUS_SPECIES_MAP)}
			placeholder={placeholder}
			onBlur={onBlur}
			autoFocus={autoFocus}
		/>
	);
}

export type SpeciesSelectProps = StrainFieldType & {
	genus: string | undefined;
};

/**
 * the components to select species for selected genus
 * @param {Object} props
 * @param {String} props.genus - the selected genus
 * @param {String} props.value - the value of species
 * @param {String} props.placeholder - placeholder for autocomplete
 * @param {Function} props.onChange -function called when content is changed
 */
export function SpeciesSelect({
	value,
	uuid,
	genus,
	placeholder,
	onChange,
	onBlur,
	autoFocus,
}: SpeciesSelectProps) {
	const onChangeWrap = (value: string) => {
		const temp = {
			target: {
				value: value,
				id: uuid,
			},
		};
		if (onChange) onChange(temp);
	};

	// Available species for selected genus
	let species: any[] = [];
	if (genus) {
		species =
			GENUS_SPECIES_MAP[genus as keyof typeof GENUS_SPECIES_MAP] || [];
	}

	return (
		<ReagentSelector
			value={value}
			onChange={onChangeWrap as any}
			dataSource={[...species]}
			placeholder={placeholder}
			onBlur={onBlur}
			autoFocus={autoFocus}
		/>
	);
}

// AutoComplete selector for Genus and Speciess
function ReagentSelector(
	props: TypeSelectorProps & Partial<AutoCompleteProps>
) {
	const [visible, setVisible] = useState(false);

	return (
		<div
			className="reagent-wrapper"
			onKeyDown={(e) => {
				if (visible && e.key === "Tab") {
					setVisible(false);
				}
			}}
		>
			<AutoComplete
				filterOption={(search, option) => {
					if (
						!props.value ||
						!option?.key ||
						typeof option.key !== "string"
					) {
						return false;
					}
					return option.key
						.toLowerCase()
						.includes(props.value.toLowerCase());
				}}
				onSearch={(value) => {
					const found = props.dataSource?.some((option) =>
						option
							.toString()
							.toLowerCase()
							.includes(value.toLowerCase())
					);
					setVisible(!!found);
				}}
				className="reagent-specific-autoselect"
				dropdownClassName="reagent-specific-select-dropdown"
				getPopupContainer={(trigger) =>
					trigger.parentNode as HTMLElement
				}
				allowClear
				{...props}
				open={visible}
				onFocus={() => {
					if (!props.dataSource?.length) {
						return;
					}
					setVisible(true);
				}}
				onBlur={(e) => {
					props?.onBlur?.(e);
					setVisible(false);
				}}
				autoFocus={props?.autoFocus}
			/>
		</div>
	);
}

/**
 * the components to select antibiotic
 * @param {Object} props
 * @param {String} props.value - the value of antibiotic
 * @param {Function} props.onChnage - the function to handle changing value.
 */

export type AntibioticSelectProps = {
	value: string[];
	uuid?: string;
	placeholder?: string;
	onChange: (val: any) => void;
	onBlur?: any;
	onKeyDown?: any;
} & Omit<StrainFieldType, "value">;

export function AntibioticSelect({
	value,
	uuid,
	onChange,
	onBlur,
	onKeyDown,
	autoFocus,
}: AntibioticSelectProps): JSX.Element {
	const [options, setOptions] = useState<string[]>([]);
	const [isLoading, setLoading] = useState(false);
	const [failedFetch, setFailed] = useState(false);
	const [lastFetch, setLastFetch] = useState(new Date());
	const [search, setSearch] = useState("");
	const [getDropdownOptions] = useLazyDropdownOptionsQuery();
	const [addDropdownOption] = useAddDropdownOptionMutation();
	const [deleteDropdownOption] = useDeleteDropdownOptionMutation();
	const { Option } = Select;
	useEffect(() => {
		fetchData();
	}, []);
	const sorter = (a: string, b: string) =>
		a.toLowerCase().localeCompare(b.toLowerCase());
	// Fetches data and sets it to the options array
	const fetchData = () => {
		if (!isLoading) {
			setLoading(true);
			setFailed(false);
			getDropdownOptions({ modelName: "strain", fieldName: "antibiotic" })
				.unwrap()
				.then((res) => {
					// Combine extra options w/ provided antibiotics
					setOptions(
						[
							...ANTIBIOTICS,
							...res.options,
							...(value || []),
						].reduce((acc: string[], val: string) => {
							if (
								!acc
									.map((x) => x.toLowerCase())
									.includes(val.toLowerCase())
							) {
								return [...acc, val];
							}
							return acc;
						}, [])
					);
					setLastFetch(new Date());
				})
				.catch((err) => {
					setOptions([]);
					setFailed(true);
					console.log("Failed to fetch options array", err);
				})
				.finally(() => setLoading(false));
		}
	};
	const onChangeWrap = (antibiotics: string[]) => {
		// Get all new values in antibiotics array
		const temp = {
			target: {
				value: antibiotics.filter((x) => !!x.trim()).sort(sorter),
				id: uuid,
			},
		};
		if (onChange) onChange(temp);
	};
	/** function to handle selectedValue from Select */
	const onSelect = (selectedValue: string) => {
		const hasOption = options.find((option) => option === selectedValue);
		if (!hasOption) {
			setOptions([...options, selectedValue]);
			addDropdownOption({
				modelName: "strain",
				fieldName: "antibiotic",
				options: selectedValue.trim(),
			});
		}
	};

	/** function to handle deSelectedValue from Select */
	const onDeSelect = (deSelectedValue: string) => {
		deleteDropdownOption({
			modelName: "strain",
			fieldName: "antibiotic",
			option: deSelectedValue,
		})
			.unwrap()
			.then((res) => {
				if (res?.options) {
					setOptions([...ANTIBIOTICS, ...res.options]);
				}
			});
	};

	// Limit to every 5 seconds
	const handleSearch = (text: string) => {
		const TIME_DIFF = 5 * 1000;
		if (new Date().getTime() - TIME_DIFF > lastFetch.getTime()) {
			fetchData();
		}
		setSearch(text);
	};
	// True if the search value is contained in the list of existing options
	const containsValue = options
		.map((x) => x.toLowerCase())
		.includes(search.toLowerCase());
	// const ADD_ITEM_OPTION = "add-new-antibiotic"
	const notFound = isLoading ? (
		<Spin size="small" />
	) : failedFetch ? (
		<div onClick={fetchData} style={{ cursor: "pointer" }}>
			Failed to fetch data. Click to try again.
		</div>
	) : (
		"No matching antibiotics"
	);
	return (
		<div className="antibiotics-selector">
			<Select
				mode="multiple"
				loading={isLoading}
				value={value as any}
				onChange={(val) => onChangeWrap(val as string[])}
				onSelect={(val) => onSelect(val as string)}
				onDeselect={(val) => onDeSelect(val as string)}
				notFoundContent={notFound}
				onSearch={handleSearch}
				menuItemSelectedIcon={
					<GenemodIcon name="exit" stroke="text-secondary" />
				}
				isInput
				scrollSafeMode
				onBlur={onBlur}
				onInputKeyDown={onKeyDown}
				autoFocus={autoFocus}
			>
				{options.sort(sorter).map((option, index) => {
					return (
						<Option key={option + index} value={option}>
							{option}
						</Option>
					);
				})}
				{search && !containsValue && (
					<Option value={search}>{search}</Option>
				)}
			</Select>
		</div>
	);
}
