import React from "react";
import { Button, ButtonV2, Table as TableComponent } from "@components";
import { NavigationObject, WithSlots } from "../NavigationList";
import "../index.scss";
import { Typography } from "@components";
import { GenemodTableProps } from "@common/components/Table/Table";
import styles from "./index.module.scss";

export default function Table<T extends WithSlots<NavigationObject>>({
	dataSource,
	columns,
	...rest
}: GenemodTableProps<T>): JSX.Element {
	const COLUMNS = [
		{
			width: 300,
			ellipsis: true,
			render: (_: any, item: T) => {
				return (
					<div>
						<Typography ellipsis hideTooltip>
							{item.name}
						</Typography>
						{item.slots && (
							<Typography variant="caption" color="text-tertiary">
								{item.slots} {"  "} slots available
							</Typography>
						)}
					</div>
				);
			},
		},
		{
			dataIndex: "id",
			render: () => {
				return (
					<ButtonV2 type="link" className={styles.linkButton}>
						Open
					</ButtonV2>
				);
			},
			width: 88,
			align: "center",
		},
	];

	return (
		<TableComponent
			className="genemod-moving-cart-table"
			columns={columns || COLUMNS}
			showHeader={false}
			rowClassName={styles.row}
			dataSource={dataSource}
			{...(rest as any)}
		/>
	);
}
