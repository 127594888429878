import {
	AccountKeys,
	AllGenemodLinkKeys,
	AllGenemodLinkPrefixes,
	EquipmentKeys,
	FreezerKeys,
	GenemodLinkType,
	PmKeys,
} from "@common/types";
import { FREEZER_PATHS } from "@containers/Freezer";
import { useOrganizationRouter } from "@root/AppRouter";
import {
	ACCOUNT_SETTINGS_PATHS,
	EQUIPMENTS_PATHS,
	PM_ROUTES,
} from "@root/routes";
import { actionOnTypeLiteral } from "../TypeHelpers";

export const useLinkUrl = () => {
	const { appendBaseUrlAndParams, getToolRoute } = useOrganizationRouter();

	const getUrl = (to: GenemodLinkType) => {
		const [type, key] = to.split(/_(.*)/s) as [
			AllGenemodLinkPrefixes,
			AllGenemodLinkKeys
		];

		// if not separated by _, give the 404 page
		if (!type || !key) {
			return getToolRoute("NOT_FOUND");
		}

		let path = actionOnTypeLiteral(type)({
			ACCOUNT: () => ACCOUNT_SETTINGS_PATHS[key as AccountKeys].route,
			PM: () => PM_ROUTES[key as PmKeys],
			FREEZER: () => FREEZER_PATHS[key as FreezerKeys],
			EQUIPMENT: () => EQUIPMENTS_PATHS[key as EquipmentKeys],
		});

		// if undefined, give the 404 page
		if (path === undefined) {
			return getToolRoute("NOT_FOUND");
		}

		path = appendBaseUrlAndParams(path);
		return path;
	};

	return getUrl;
};
