import React, { useState } from "react";
import styles from "./Filter.module.scss";
import { Checkbox, Select, GenemodIcon, Typography } from "@components";
import { SelectProps } from "antd/lib/select";
import { idKeysOfConst } from "@helpers/TypeHelpers";
import cn from "classnames";
import LayerSystemContainer from "../LayerSystemContainer/LayerSystemContainer";

type FilterProps = SelectProps & {
	/** A list of options that follows this structure:
	 *  const FREEZER_TYPES = Object.freeze({
        MINUS_EIGHTY: 0,
        MINUS_TWENTY: 1,
    ...
        0: "-80 °C",
        1: "-20 °C",
    ...
    } as const);
	*/
	filterOptions?: any;
	/** A list of options that are selected. If not given,
	 * The filter is for single selection */
	selectedOptions?: number[];
	children?: any;
	/**
	 * Allows you to adjust the anchor points for the dropdown menu.
	 *
	 * See here:
	 * https://github.com/react-component/select/issues/254
	 * https://codesandbox.io/s/ov56m6zk25?file=/index.js
	 * https://github.com/yiminghe/dom-align
	 */
	dropdownAlign?: any;
	hideIcon?: boolean;
	label?: string;
	dataCy?: string;
	bordered?: boolean;
};

export default function Filter(props: FilterProps): JSX.Element {
	const count = props.selectedOptions?.length || 0;
	const [dropdownVis, setDropdownVis] = useState(false);
	const [inButton, setInButton] = useState(false);
	const { hideIcon = false, label = "Filters" } = props;
	return (
		<LayerSystemContainer backgroundHover className={styles.wrapperButton}>
			<Select
				dataCy={props.dataCy}
				optionLabelProp={!props.selectedOptions ? "label" : undefined}
				value={
					!props.selectedOptions ? (
						(props.value as unknown as any)
					) : (
						<div className={styles.iconAndCount}>
							{hideIcon && (
								<GenemodIcon
									name="filter-o"
									color={"text-secondary-v2"}
								/>
							)}
							<Typography
								variant="label"
								color="text-secondary-v2"
							>
								{count === 0
									? label
									: `${label}:\u00A0\u00A0${count}`}
							</Typography>
						</div>
					)
				}
				defaultValue={props.defaultValue}
				wrapperClassname={cn(styles.genemodFilterSelect, {
					[styles.genemodFilterSelect__default]: !props.children,
					[styles.bordered]: props.bordered,
				})}
				onChange={props.onChange}
				open={dropdownVis}
				onDropdownVisibleChange={(vis: boolean) => {
					if (inButton) {
						setDropdownVis(vis);
					}
				}}
				onMouseEnter={() => setInButton(true)}
				onMouseLeave={() => setInButton(false)}
				onBlur={() => setDropdownVis(false)}
				isSmall
				suffixIcon={props.suffixIcon}
				getPopupContainer={props.getPopupContainer}
			>
				{props.children}
				{props.filterOptions &&
					idKeysOfConst(props.filterOptions).map((filterOption) => {
						return (
							<Select.Option
								data-cy={
									props.dataCy &&
									`${props.dataCy}-${filterOption}`
								}
								value={filterOption}
								key={filterOption}
								label={props.filterOptions?.[filterOption]}
							>
								{props.selectedOptions ? (
									<Checkbox
										key={filterOption}
										value={props.selectedOptions.includes(
											filterOption
										)}
									>
										{props.filterOptions?.[filterOption]}
									</Checkbox>
								) : (
									<Select.SelectedOpt
										isSelected={
											props.value ===
											props.filterOptions?.[filterOption]
										}
										label={
											props.filterOptions?.[filterOption]
										}
									/>
								)}
							</Select.Option>
						);
					})}
				{props.selectedOptions && (
					<Select.Option
						data-cy={props.dataCy && `${props.dataCy}-clear-all`}
						value="clear"
						key="clear"
						className={styles.clearAll}
					>
						Clear all
					</Select.Option>
				)}
			</Select>
		</LayerSystemContainer>
	);
}
