import React, { useContext, useEffect, useRef, useState } from "react";
import {
	// Modal,
	// Input,
	// TextArea,
	// GenemodIcon,
	// DropDown,
	// Typography,
	// Bookmark,
	// Notification,
	// UpgradeButton,
	// LayerSystemContainer,
	// CommonDeleteModal,
	ClickToEdit,
	TabList,
	ActivityLog,
	Spin,
} from "@components";
import {
	AttachmentCard,
	// ItemViewAndEditPanel,
} from "../components/ItemViewAndEditPanel/ItemViewAndEditPanel";
import "./Category.scss";
// import styles from "./Category.module.scss";
// import CollapsbilePanelStyles from "../components/CollapsibleViewEditPanel/CollapsibleViewEditPanel.module.scss";
// import CreateNewItem from "@containers/Freezer/CreateNewItem/CreateNewItem";
// import { SegmentTrackEvent, SegmentFreezerEvents } from "@Segment";
import { ClickParam } from "antd/lib/menu";
import { useAppDispatch } from "@redux/store";
import { prepareItemPrint } from "@redux/freezer/BoxViewSlice";
import classNames from "classnames";
import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import {
	ConfirmLeavingModal,
	SafeLeavingGuardContext,
} from "@common/context/SafeLeavingGuardContext";
import { Link, useLocation } from "@common/helpers/Hooks/UseRouterDom";
import { Item } from "@common/types";
import {
	Bookmark,
	CommonDeleteModal,
	DropDown,
	GenemodIcon,
	Input,
	LayerSystemContainer,
	Modal,
	Notification,
	TextArea,
	Typography,
	UpgradeButton,
} from "@components";
import CreateNewItem from "@containers/Freezer/CreateNewItem/CreateNewItem";
import { truncArgs } from "@helpers/Formatters";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
// import { prepareItemPrint } from "@redux/freezer/BoxViewSlice";
import { useItemAttachmentCreateMutation } from "@redux/freezer/FreezerApiSlice";
import {
	useAddItemBookmarkMutation,
	useRemoveItemBookmarkMutation,
} from "@redux/inventory/Bookmark";
import {
	useBulkItemsDeleteMutation,
	useItemCreateMutation,
	useItemPatchMutation,
} from "@redux/inventory/Item";
import {
	useItemGroupBulkCreateMutation,
	useItemGroupPatchMutation,
} from "@redux/inventory/ItemGroup";
// import { useAppDispatch } from "@redux/store";
import { Menu } from "antd";
// import { ClickParam } from "antd/lib/menu";
// import classNames from "classnames";
import {
	TempAttachmentsContext,
	useOpenOrderFormForItem,
} from "../BoxView/BoxView";
import AnimatedPanel from "../components/AnimatedPanel";
// import { useItemAttachmentCreateMutation } from "@redux/freezer/FreezerApiSlice";
// import {
// 	useRemoveItemBookmarkMutation,
// 	useAddItemBookmarkMutation,
// } from "@redux/inventory/Bookmark";
// import {
// 	useItemCreateMutation,
// 	useItemPatchMutation,
// 	useBulkItemsDeleteMutation,
// } from "@redux/inventory/Item";
// import {
// 	useItemGroupPatchMutation,
// 	useItemGroupBulkCreateMutation,
// } from "@redux/inventory/ItemGroup";
import { nanoid } from "nanoid";
import BoxCategoryInfo from "../components/BoxCategoryInfo";
import CollapsbilePanelStyles from "../components/CollapsibleViewEditPanel/CollapsibleViewEditPanel.module.scss";
import FreezerItemList from "../components/FreezerItemList/FreezerItemList";
import { ItemViewAndEditPanel } from "../components/ItemViewAndEditPanel/ItemViewAndEditPanel";
import { useExportState } from "../hooks";
import { useBoxView, useItemGroup } from "../table/BoxTableHooks";
import styles from "./Category.module.scss";
import "./Category.scss";

export default function BoxCategory(): JSX.Element {
	const dispatch = useAppDispatch();
	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const openOrderForm = useOpenOrderFormForItem();
	const { is_limit_reached: orderManagementRestricted } =
		useFeatureRestrictionHook("order_management");
	const { openItemGroupSettings } = useCommonPanelState("itemGroupSettings");
	const { openDeleteItemGroupModal } = useCommonModalState(
		"deleteItemGroupModal"
	);

	const {
		viewOnly,
		item_group,
		setItemIdInUrl: setItemId,
		item: selectedItem,
		itemIdInUrl: itemId,
		isItemFetching,
	} = useItemGroup();

	const [updateItemGroup] = useItemGroupPatchMutation();
	const [createBoxItem] = useItemCreateMutation();
	const [updateBoxItem] = useItemPatchMutation();
	const [deleteBookmark] = useRemoveItemBookmarkMutation();
	const [createBookmark] = useAddItemBookmarkMutation();
	const [addItemAttachment] = useItemAttachmentCreateMutation();

	const { handleExport, exportModal } = useExportState();

	const [addItem, setAddItem] = React.useState<boolean | null>(false);
	const [deleteItem, setDeleteItem] = React.useState(false);
	const [selectedItemIDs, setSelectedItemIDs] = React.useState<number[]>([]);
	const [isNewItem, setIsNewItem] = React.useState(false);
	const routerLocation = useLocation();
	const params = new URLSearchParams(routerLocation.search);
	const returnUrl = params.get("return_url");
	const [copyVisible, setCopyVisible] = React.useState(false);
	const [confirmLeavingModalVis, setConfirmLeavingModalVis] =
		React.useState(false);
	const { shouldBlock, setShouldBlock } = useContext(SafeLeavingGuardContext);

	// If an item is selected, hide the add item form
	useEffect(() => {
		if (addItem) {
			setAddItem(false);
		}
	}, [itemId]);

	const [menuVisible, setMenu] = useState(false);
	const [dropdownVisible, setDropdown] = React.useState(false);

	// Select results from search page
	useEffect(() => {
		const ids =
			params
				.get("search_ids")
				?.split(",")
				.map((id) => parseInt(id)) || [];
		setSelectedItemIDs(ids);
		if (ids.length) setItemId(ids[0], true);
	}, []);

	const renderDropdownMenu = () => {
		const Item = Menu.Item;

		if (!selectedItem) {
			return (
				<Menu onClick={handleDropdownSelection}>
					<Item key="settings">Settings</Item>
					<Item key="share">Share</Item>
					<Item key="export">Export</Item>
					{!viewOnly && (
						<Item key="delete item group">Delete group</Item>
					)}
				</Menu>
			);
		} else {
			return (
				<Menu onClick={handleDropdownSelection}>
					<Item key="make copy">Make a copy</Item>
					<Item key="request order">
						Request order{" "}
						{orderManagementRestricted && (
							<UpgradeButton
								type="tag"
								style={{ marginLeft: 24 }}
							/>
						)}
					</Item>
					<Item key="share">Share</Item>
					<Item key="print">Print</Item>
					<Item
						key="delete item"
						style={{ color: "var(--dust-red)" }}
					>
						Delete item
					</Item>
				</Menu>
			);
		}
	};

	/**
	 * Set this if you want to delete a specific item since the delete modal is shared
	 * between the dropdown delete and bulk delete
	 * */
	const [deleteItemId, setDeleteItemId] = useState<number | null>(null);
	useEffect(() => {
		if (!deleteItem && deleteItemId) {
			setDeleteItemId(null);
		}
	}, [deleteItem]);

	const handleDropdownSelection = (e: ClickParam) => {
		if (!item_group) return;
		const event = e.domEvent;
		event.stopPropagation();
		setMenu(false);
		setDropdown(false);

		const key = e.key;
		if (key === "delete item") {
			setDeleteItemId(selectedItem?.id || null);
			setDeleteItem(true);
		} else if (key === "make copy") {
			setCopyVisible(true);
		} else if (key === "settings") {
			openItemGroupSettings({ id: item_group.id });
		} else if (key === "delete item group") {
			openDeleteItemGroupModal({
				id: item_group.id,
				navigateToParentRack: true,
			});
		} else if (key === "share") {
			openShareLinkModal({});
		} else if (key === "request order") {
			openOrderForm();
		} else if (key === "export") {
			handleExport("item-groups", item_group.id, item_group.name);
		} else if (key === "print" && selectedItem) {
			dispatch(prepareItemPrint(selectedItem.id));
		}
	};

	const handleBookmarkClick = () => {
		if (!selectedItem) return;
		updateItemBookmark(selectedItem);
	};

	/**
	 * For updating bookmark list and the bookmark state of an item
	 * @param {Item} targetItem - Item data
	 */
	const updateItemBookmark = (targetItem: Item) => {
		const promise = targetItem.is_bookmarked
			? deleteBookmark(targetItem.id)
			: createBookmark(targetItem.id);
		promise
			.unwrap()
			.then(() =>
				Notification.success({
					message: `Bookmark has been ${
						targetItem.is_bookmarked ? "removed" : "added"
					}.`,
				})
			)
			.catch(() =>
				Notification.warning({
					message: `Failed to ${
						targetItem.is_bookmarked ? "remove" : "add"
					} bookmark.`,
				})
			);
	};

	const { tempAttachments, setTempAttachments } = useContext(
		TempAttachmentsContext
	);

	/**
	 * this function is to add new Item
	 */
	const handleAddNewItem = (partialItem: Partial<Item>) => {
		if (!item_group) return;
		const item = partialItem as Item;
		createBoxItem({ boxId: item_group.id, item })
			.unwrap()
			.then((item) => {
				// handle temp attachments creation if there are any
				if (tempAttachments.length) {
					const promises = tempAttachments.map((file) =>
						addItemAttachment({
							itemId: item.id || -1,
							data: file?.formData || new FormData(),
						}).unwrap()
					);

					Promise.all(promises)
						.then(() => {
							setTempAttachments([]);
						})
						.catch((error) => {
							console.log(error);
						});
				}

				setCopyVisible(true);
				setItemId(item.id);
				SegmentTrackEvent(SegmentFreezerEvents.ITEM_CREATE, {
					id: item.id,
					item_type: item.item_type,
				});
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${item.name}`(68)}</b>
							{" has been created."}
						</span>
					),
				});
			})
			.catch(() => {
				Notification.warning({
					message: "Failed to create a item.",
				});
			});
	};

	const handleEditItem = (
		editedItem: Item,
		callback: (newBox: boolean, item: Item) => void
	) => {
		if (!item_group) return;
		updateBoxItem(editedItem)
			.unwrap()
			.then((item) => {
				callback(true, item);
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${editedItem.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});
			})
			.catch(() => {
				Notification.warning({
					message: "Failed to update an item.",
				});
			});
	};

	const getVolumeSuffix = (value: any) => {
		if (!value) {
			return undefined;
		}
		if (value.length > 7) {
			return "/" + value.substring(0, 7) + "...";
		}
		return "/" + value;
	};

	const handleRenameItemGroup = (name: string) => {
		if (!item_group || item_group?.name === name.trim()) return;
		updateItemGroup({
			id: item_group.id,
			name: name.trim(),
		})
			.unwrap()
			.then(() => {
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});
			})
			.catch(() => {
				Notification.warning({
					message: "Failed to update a box",
				});
			});
	};

	const TABS = [];
	if (selectedItem) {
		TABS.push(
			...[
				{
					key: "details",
					tabtitle: "Details",
					tabcontent: (
						<div>
							<ItemViewAndEditPanel
								key={selectedItem.id}
								viewOnly={viewOnly}
								item={selectedItem}
								nameIsEditable={false}
								onEdit={handleEditItem}
								itemGroup
							/>
						</div>
					),
				},
				{
					key: "activity",
					tabtitle: "Activity",
					tabcontent: (
						<div className={styles.activityContainer}>
							<ItemActivity />
						</div>
					),
				},
				{
					key: "attachments",
					tabtitle: "Files",
					tabcontent: (
						<div className={styles.activityContainer}>
							<AttachmentCard
								item={selectedItem}
								viewOnly={viewOnly}
							/>
						</div>
					),
				},
			]
		);
	}

	return (
		<div className={styles.categoryWrapper}>
			<div className={styles.left}>
				{returnUrl ? (
					<Link className={styles.backToResults} to={returnUrl}>
						<GenemodIcon
							name="chevron-left"
							stroke="text-secondary"
						/>
						<Typography variant="caption" color="text-primary">
							Return to search results
						</Typography>
					</Link>
				) : (
					<></>
				)}
				<FreezerItemList
					onAddItem={() => {
						if (shouldBlock && !addItem) {
							setConfirmLeavingModalVis(true);
						} else {
							setAddItem(true);
						}
					}}
					selectedItemIDs={selectedItemIDs}
					setSelectedItemIDs={setSelectedItemIDs}
					onDelete={() => setDeleteItem(true)}
				/>
			</div>
			<div className={styles.right}>
				<div className={styles.boxCategoryInfoWrapper}>
					<BoxCategoryInfo
						dropdownMenu={renderDropdownMenu()}
						onNameChange={handleRenameItemGroup}
						setMenu={setMenu}
						menuVisible={menuVisible}
					/>
				</div>
				<AnimatedPanel visible={!!addItem} style={{ zIndex: 3 }}>
					{!!addItem && (
						<CreateNewItem
							title={`Create new "${item_group?.name}" unit`}
							presetFields={
								{
									name: {
										value: item_group?.name,
										disabled: true,
										autoFocus: false,
									},
									itemType: {
										disabled: true,
									},
									concentration: {
										autoFocus: true,
									},
									volume: {
										suffix: getVolumeSuffix(
											item_group?.container_volume
										),
									},
								} as const
							}
							onCancel={() => setAddItem(false)}
							handleSubmit={(item) => {
								setIsNewItem(true);
								handleAddNewItem(item);
								setAddItem(null);
							}}
							focusedCell={null}
							dataFromFreezerView={{
								setShouldBlock,
								box: null,
								item_group,
							}}
						/>
					)}
				</AnimatedPanel>
				<AnimatedPanel visible={!!selectedItem} style={{ zIndex: 2 }}>
					{selectedItem && (
						<LayerSystemContainer className={styles.itemInfo}>
							<Spin
								spinning={isItemFetching && !selectedItem?.name}
								wrapperClassName={styles.spin}
							>
								<div className={styles.header}>
									<ClickToEdit
										value={selectedItem.name}
										className={styles.itemName}
										onComplete={(value) => {
											handleRenameItemGroup(value);
										}}
										validators={[
											{
												validator: (val) =>
													val.length > 0,
												error: "Please specify the name",
											},
										]}
										readOnly={viewOnly}
										component="input"
										dataCy="cell-info-name"
									/>
									{!viewOnly && (
										<div className={styles.headerOptions}>
											<Bookmark
												isBookmarked={
													selectedItem.is_bookmarked
												}
												onChange={handleBookmarkClick}
												size="large"
											/>
											<DropDown
												overlay={renderDropdownMenu}
												getPopupContainer={(trigger) =>
													trigger.parentNode as HTMLElement
												}
												visible={dropdownVisible}
												onVisibleChange={setDropdown}
												placement="bottomLeft"
												type="meatballs"
											/>
										</div>
									)}
								</div>
								<div
									className={classNames(
										CollapsbilePanelStyles.panelCards,
										styles.itemViewAndEditWrapper
									)}
								>
									<TabList
										tabListItems={TABS}
										largeSize={true}
										defaultActiveKey="details"
										className={styles.tabs}
										hasTopGradient={false}
										hasBottomGradient={false}
									/>
								</div>
							</Spin>
						</LayerSystemContainer>
					)}
				</AnimatedPanel>
			</div>
			<ConfirmLeavingModal
				visible={shouldBlock && confirmLeavingModalVis}
				onOk={() => {
					// This modal is shown when user click create new item while editing item
					// other leaving cases will be handled by SafeLeavingGuard
					setAddItem(true);
					setShouldBlock?.(false);
					setConfirmLeavingModalVis(false);
				}}
				onCancel={() => {
					setConfirmLeavingModalVis(false);
				}}
			/>
			<DeleteItemModal
				itemID={deleteItemId}
				visible={deleteItem}
				setDeleteItem={setDeleteItem}
				selectedItemIDs={selectedItemIDs}
				setSelectedItemIDs={setSelectedItemIDs}
				setItemId={setItemId}
			/>
			{/* {box && <DeleteBoxModal />} */}
			<AddMultipleItemModal
				itemID={itemId}
				isVisible={copyVisible}
				setIsVisible={setCopyVisible}
				isNewItem={isNewItem}
				setIsNewItem={setIsNewItem}
				setItemId={setItemId}
			/>
			{exportModal}
		</div>
	);
}

type AddMultitpleItemModalTypeProps = {
	/** Currently selected item id */
	itemID: number | null;
	/** Whether to display the modal */
	isVisible: boolean;
	/** Set the visible value */
	setIsVisible: (data: boolean) => void;
	/** If ture, note is not shown and the copying quantity is negative 1 */
	isNewItem: boolean;
	/** Set the isNewItem value (true or false) */
	setIsNewItem: (data: boolean) => void;
	/** Set the itemId so that the last item will be selected in the list and url */
	setItemId: (id: number) => void;
};
/** The modal component for adding/copying item */
function AddMultipleItemModal({
	itemID,
	isVisible,
	setIsVisible,
	isNewItem,
	setIsNewItem,
	setItemId,
}: AddMultitpleItemModalTypeProps): JSX.Element {
	// const { box, items } = useBoxView();
	const { item_group, items } = useItemGroup();
	const item = items.find((item) => item.id === itemID);
	const [createBulkItems] = useItemGroupBulkCreateMutation();

	const [quantity, setQuantity] = React.useState<number | undefined>(1);
	const [isValid, setIsValid] = React.useState(true);
	const [isLoading, setIsLoading] = React.useState(false);
	const [tempCopyNotes, setTempCopyNotes] = React.useState("");

	const initValues = () => {
		setQuantity(1);
		setIsValid(true);
		setIsLoading(false);
		setTempCopyNotes("");
		setIsVisible(false);
		setIsNewItem(false);
	};

	useEffect(() => setQuantity(1), [isVisible]);

	const handleSubmit = () => {
		if (!item || (isNewItem && quantity === 1)) {
			initValues();
			return;
		}
		if (!item_group) return;
		setIsLoading(true);
		const postData = [] as Item[];

		if (quantity && quantity > 0) {
			for (let i = isNewItem ? 1 : 0; i < quantity; i++) {
				const tempItem = { ...item };
				if (tempCopyNotes) {
					tempItem.notes = tempCopyNotes;
				}
				postData.push(tempItem);
			}
		}

		createBulkItems({ item_group: item_group.id, items: postData })
			.unwrap()
			.then((res) => {
				const newItems = res;
				setItemId(newItems[newItems.length - 1].id);
				SegmentTrackEvent(SegmentFreezerEvents.ITEM_PASTE, {
					is_item_group: true,
				});
			})
			.catch(() => {
				Notification.warning({
					message: "Failed to copy item(s).",
				});
			})
			.finally(() => {
				initValues();
			});
	};

	// validatory for the quantity input in the add item and copy item modal
	const quantityValidators = [
		{
			validator: (val: any) => !isNaN(val) && val !== "",
			error: "You must enter a number",
		},
		{
			validator: (val: number) => val < 100,
			error: "Input should be lower than 100",
		},
		{
			validator: (val: number) => val !== 0,
			error: "Input should be higher than 0",
		},
		{
			validator: (val: number) => val > 0,
			error: "Input cannot be negative",
		},
		{
			validator: (val: number) => val % 1 === 0,
			error: "Input cannot be a decimal",
		},
	];

	return (
		<Modal
			visible={isVisible}
			title="Creating multiple copies?"
			className="category-modal"
			okText={"Done"}
			onOk={handleSubmit}
			okButtonProps={{
				onClick: handleSubmit,
				loading: isLoading,
				disabled: !isValid || isLoading,
			}}
			hideCancelButton
			onCancel={() => setIsVisible(false)}
		>
			<Input
				value={quantity}
				inputProps={{
					className: "genemod-quantity-input",
				}}
				type="number"
				autoFocus
				onChange={(e: any) => setQuantity(e.target.value)}
				label="How many copies of this unit are there?"
				validators={quantityValidators}
				onValidityChange={(v) => setIsValid(v)}
				suffix={
					<>
						<GenemodIcon
							name="caret-left"
							onClick={() => {
								if (quantity && quantity > 1)
									setQuantity(+quantity - 1);
							}}
						/>
						<GenemodIcon
							name="caret-right"
							onClick={() => {
								if (quantity) setQuantity(+quantity + 1);
							}}
						/>
					</>
				}
			/>
			{isVisible && !isNewItem && (
				<div className="form-group">
					<TextArea
						label="Notes"
						value={tempCopyNotes}
						onChange={(e) => {
							setTempCopyNotes(e.target.value);
						}}
						style={{ minHeight: 76 }}
					/>
				</div>
			)}
		</Modal>
	);
}

type DeleteItemModalTypeProps = {
	/** Currently selected item id */
	itemID: number | null;
	/** Selected row keys of the table */
	selectedItemIDs: number[];
	/** update selected row keys of the table */
	setSelectedItemIDs: (rowKeys: number[]) => void;
	/** Function for updating the visibility of modal */
	setDeleteItem: (isDeleting: boolean) => void;
	/** Whether this modal is visible or not */
	visible: boolean;
	/** Updating current selected item */
	setItemId: (id: number | null, replaceHistory?: boolean) => void;
};

/**
 * Displays the delete modal and handle the deletion of the item(s).
 *  - Click 'Delete': Delete the item group and back to Shelf & Category page
 *  - Click 'Cancel': Close the modal
 */
function DeleteItemModal({
	itemID,
	selectedItemIDs,
	setSelectedItemIDs,
	setDeleteItem,
	visible,
	setItemId,
}: DeleteItemModalTypeProps): JSX.Element {
	const { box } = useBoxView();
	const [deleteBulkItems] = useBulkItemsDeleteMutation();
	const [loading, setLoading] = useState(false);

	/**
	 *  If itemID is provided, then the user is deleting via the dropdown menu for a specific item
	 */
	const deleteIDs: number[] = itemID ? [itemID] : selectedItemIDs;
	const totalToBeDeleted = deleteIDs.length;

	const handleSubmit = () => {
		setLoading(true);
		deleteBulkItems({
			ids: deleteIDs,
		})
			.unwrap()
			.then(() => {
				setItemId(null);
				setSelectedItemIDs([]);
				setDeleteItem(false);
				SegmentTrackEvent(SegmentFreezerEvents.ITEM_DELETE, {});
				Notification.success({
					message: `${deleteIDs.length} item(s) deleted.`,
				});
			})
			.catch(() =>
				Notification.warning({
					message: "Failed to delete item(s).",
				})
			)
			.finally(() => {
				setLoading(false);
			});
	};

	const itemsMsg = `${totalToBeDeleted} ${
		totalToBeDeleted === 1 ? "item" : "items"
	}`;

	return (
		<CommonDeleteModal
			visible={visible}
			titleObject={itemsMsg}
			bodyObject={`${itemsMsg} and associated activity data`}
			onOk={handleSubmit}
			onCancel={() => setDeleteItem(false)}
			loading={loading}
		/>
	);
}

function ItemActivity() {
	const [refreshTrigger, setRefreshTrigger] = useState(nanoid());
	const ref = useRef<HTMLDivElement | null>(null);
	const { item } = useBoxView();

	useEffect(() => {
		setRefreshTrigger(nanoid());
		if (!ref.current) return;
		// keep the height of the card the same when refreshing
		ref.current.style.height = window.getComputedStyle(ref.current).height;
	}, [item]);

	if (!item) return <></>;

	return (
		<ActivityLog
			key={refreshTrigger}
			activityFilter={{
				location_model: "Item",
				location_object_id: item.id,
			}}
			onDoneLoading={() => {
				if (!ref.current) return;
				ref.current.style.height = "auto";
			}}
		/>
	);
}
