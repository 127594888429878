import { Link as Lnk, useHistory } from "@common/helpers/Hooks/UseRouterDom";
import {
	Button,
	GenemodIcon,
	Input,
	Notification,
	Typography,
} from "@components";
import {
	RegistrationFormData,
	useLoginContext,
} from "@containers/Auth/LoginInterfaces/LoginContext";
import { NarrowOnboarding } from "@containers/Auth/components/";
import { usePasswordValidation, useWindowDimensions } from "@helpers/Hooks";
import { useParams } from "@helpers/URLParams";
import { validateEmail, validateWorkEmail } from "@helpers/Validators";
import {
	WEBSITE_PRIVACY_POLICY,
	WEBSITE_TERMS_OF_SERVICE,
} from "@helpers/WebsiteLinks";
import { useResendVerificationMutation } from "@redux/team/TeamApi";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import "../../../index.scss";
import {
	buildAcceptBetaInvitationUrl,
	buildAcceptInvitationUrl,
	useInvitationState,
} from "../AcceptInvitation/AcceptInvitation";
import freeEmailDomains from "./freeEmailDomains.json";
import VERIFY_EMAIL_IMG from "./images/verify-email-illustration.png";
import styles from "./index.module.scss";

/**
 * Registration form
 * @function
 * @category Auth
 * @category Registration
 * */
export function Registration(): JSX.Element {
	const invitationState = useInvitationState();
	const isInvitation = !!invitationState;
	const { login, register, logout } = useLoginContext();

	const [isValid, setValid] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [emailErr, setEmailErr] = useState("");
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [showIcon, setShowIcon] = useState(false);
	const [resendVerification] = useResendVerificationMutation();
	const [data, setData] = useState({
		first_name: "",
		last_name: "",
		email: invitationState?.email || "",
		password: "",
	} as RegistrationFormData);
	const [
		passwordValid,
		contains8Chars,
		containsChar,
		containsNum,
		containsSpecial,
	] = usePasswordValidation(data.password);
	const { width } = Object(useWindowDimensions());
	const largeWidth = width >= 1920;
	const [submissionSuccess, setSubmissionSuccess] = useState(false);
	const history = useHistory();

	const betaParams = useBetaParams();

	// Update form data if beta params are present
	useEffect(() => {
		if (!betaParams.isBeta) return;
		setData((data) => ({
			...data,
			first_name: betaParams.first_name || "",
			last_name: betaParams.last_name || "",
			email: betaParams.email || "",
		}));
	}, [betaParams]);

	useEffect(() => {
		logout();
	}, []);

	// Handle validation changes here
	useEffect(() => {
		const validity =
			Object.keys(data).reduce(
				(v: any, key: any) =>
					!v
						? false
						: !!data[key as keyof RegistrationFormData].trim(),
				true
			) && passwordValid;

		if (validity !== isValid) {
			setValid(validity);
		}

		if (!validateEmail(data.email)) {
			setValid(false);
		}

		if (emailErr) setEmailErr("");
	}, [data, passwordValid]);

	// Handle form data changes
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const temp = { ...data } as RegistrationFormData;
		temp[e.target.id as keyof RegistrationFormData] = e.target.value;
		setData(temp);
	};

	const sendVerificationEmail = () =>
		resendVerification()
			.unwrap()
			.catch((err: any) => {
				let err_message =
					"Failed to send verification email. Try again or contact us if it continues.";
				if (err.response.status === 400) {
					err_message = err.response.data;
				}
				Notification.warning({ message: err_message });
			});

	// Handle signup submission
	const handleSubmit = async () => {
		setLoading(true);

		const userPass = {
			...data,
			email: data.email.toLowerCase(),
		};

		try {
			// Register
			const registerResult = await register(userPass);
			setLoading(false);

			if (registerResult.type === "failure") {
				if (registerResult.error.name === "UsernameExistsException") {
					setEmailErr(
						"This email is already in use. Please log in or use a different email."
					);
				} else if (
					registerResult.error.name === "InvalidPasswordException"
				) {
					setError(registerResult.error.message);
				} else {
					setError("An error occurred. Please try again.");
				}
				return;
			}

			// Login with new credentials after registering
			const loginResult = await login(userPass, false);
			if (loginResult.type === "failure") {
				// registration success but login failure, redirect to login page
				history.push("/app");
				return;
			}

			setSubmissionSuccess(true);
			if (!isInvitation && !betaParams.isBeta) {
				sendVerificationEmail();
			}
		} catch (error) {
			// Handle any errors that occurred during registration or login
			console.error("Error in handleSubmit:", error);
			setError("An error occurred. Please try again.");
			setLoading(false); // Make sure to set loading to false in case of an error
		}
	};

	if (submissionSuccess && isInvitation) {
		history.push({
			...buildAcceptInvitationUrl(invitationState),
			state: {},
		});
		return <></>;
	}

	if (submissionSuccess && betaParams.isBeta) {
		history.push({
			...buildAcceptBetaInvitationUrl(betaParams.betaCode),
			state: {},
		});
		return <></>;
	}

	const getSubHeader = () => {
		return (
			<Typography
				variant="headline"
				bold
				className={classNames(
					styles.centerText,
					styles.alreadyHaveAccount
				)}
			>
				Already have an account?{" "}
				<Link
					to="/login"
					style={{
						textDecoration: "underline",
						marginLeft: largeWidth ? 12 : 6,
						color: "var(--button-text)",
					}}
				>
					Login
				</Link>
			</Typography>
		);
	};

	return (
		<>
			{!submissionSuccess && betaParams.isBeta && (
				<div className={styles.betaBanner}>
					<Typography color="text-on-color">
						Sign up for Genemod Beta
					</Typography>
				</div>
			)}
			<NarrowOnboarding
				modalWidth={largeWidth ? 600 : 528}
				modalHeight={largeWidth ? 786 : 695}
				showBetaTag={betaParams.isBeta}
			>
				{!submissionSuccess ? (
					<div>
						{getSubHeader()}
						{error && (
							<Typography variant="caption" color="red">
								{error}
							</Typography>
						)}
						<div className={styles.nameInputsContainer}>
							<Input
								dataCy="first-name-signup"
								id="first_name"
								placeholder="Enter first name"
								label="First name"
								onChange={handleChange}
								value={data.first_name}
								autoFocus
								autoComplete="given-name"
							/>
							<Input
								dataCy="last-name-signup"
								id="last_name"
								placeholder="Enter last name"
								label="Last name"
								onChange={handleChange}
								value={data.last_name}
								autoComplete="family-name"
							/>
						</div>
						<Input
							dataCy="email-signup"
							id="email"
							label="Work email"
							onChange={handleChange}
							validators={[
								{
									validator: (v) =>
										!v.trim() ? true : validateEmail(v),
									error: "Please enter a valid email",
								},
								{
									validator: (v) =>
										!v.trim() ? true : validateWorkEmail(v),
									error: "Please enter your business email",
								},
							]}
							error={emailErr}
							value={data.email}
							disabled={isInvitation || betaParams.isBeta}
							autoComplete={"email"}
							placeholder="you@company.com"
						/>
						<Input
							dataCy="password-signup"
							id="password"
							label="Password"
							wrapperProps={{
								className: styles.passwordInput,
							}}
							type={passwordVisible ? "text" : "password"}
							onChange={handleChange}
							onFocus={() => setShowIcon(true)}
							value={data.password}
							autoComplete="new-password"
							suffix={
								<GenemodIcon
									size="large"
									name={passwordVisible ? "eye" : "eye-off"}
									style={{
										display: showIcon ? "flex" : "none",
										transform: "translateX(4px)",
									}}
									fill="button-primary"
									onClick={() =>
										setPasswordVisible(!passwordVisible)
									}
								/>
							}
						/>
						<div className={styles.passwordRequirements}>
							<PasswordRequirement
								description="8 characters minimum"
								neutral={!data.password}
								isValid={contains8Chars}
							/>
							<PasswordRequirement
								description="At least 1 letter"
								neutral={!data.password}
								isValid={containsChar}
							/>
							<PasswordRequirement
								description="At least 1 number"
								neutral={!data.password}
								isValid={containsNum}
							/>
							<PasswordRequirement
								description="At least 1 special character"
								neutral={!data.password}
								isValid={containsSpecial}
							/>
						</div>
						<Button
							dataCy="signup-submit-button"
							style={{
								justifyContent: "center",
								marginTop: largeWidth ? 48 : 32,
							}}
							disabled={!isValid}
							onClick={() => handleSubmit()}
							loading={isLoading}
							stretch
						>
							Sign up
						</Button>
						<div
							style={{
								marginTop: 32,
							}}
						>
							<Typography
								variant="label"
								className={styles.centerText}
							>
								By signing up, you agree to our <br />
								<a
									href={WEBSITE_TERMS_OF_SERVICE}
									target="_blank"
									rel="noopener noreferrer"
									className={styles.agreementLinks}
								>
									Terms of Service
								</a>
								&nbsp;and&nbsp;
								<a
									href={WEBSITE_PRIVACY_POLICY}
									target="_blank"
									rel="noopener noreferrer"
									className={styles.agreementLinks}
								>
									Privacy Policy
								</a>
							</Typography>
						</div>
					</div>
				) : (
					<SignUpSuccess />
				)}
			</NarrowOnboarding>
		</>
	);
}

export type PasswordRequirementProps = {
	description?: string;
	isValid?: boolean;
	neutral?: boolean;
};

/**
 * Indicates whether password requirements are met.
 * @function
 * @category Auth
 * @subcategory Registration
 */
export function PasswordRequirement({
	description,
	isValid,
	neutral,
}: PasswordRequirementProps): JSX.Element {
	return (
		<div
			className={classNames(styles.passwordReq, {
				[styles.passwordReq__valid]: isValid && !neutral,
				[styles.passwordReq__error]: !isValid && !neutral,
			})}
		>
			<div className={styles.statusContainer}>
				<div className={styles.requirementStatus} />
			</div>
			<Typography className={styles.requirementDescription}>
				{description}
			</Typography>
		</div>
	);
}

/**
 * A screen for sign up success after the user hit submit
 * Sign up not through invitation
 */
function SignUpSuccess() {
	return (
		<div
			data-cy="signup-success"
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<img
				src={VERIFY_EMAIL_IMG}
				alt="Sign up submission success. Please verify email"
				style={{
					width: 300,
					height: "auto",
					margin: "60px auto 56px",
					display: "block",
				}}
			/>
			<Typography
				variant="title"
				bold
				style={{
					marginBottom: 24,
					textAlign: "center",
				}}
			>
				Verify your email address
			</Typography>
			<Typography className={styles.centerText}>
				A verification link has been sent to your email address.
			</Typography>
			<Typography className={styles.centerText}>
				Follow the instructions to complete sign up.
			</Typography>
		</div>
	);
}

function Link(props: any): JSX.Element {
	return (
		<Lnk {...props} className="auth-link">
			{props.children}
		</Lnk>
	);
}

/**
 * A hook to get "beta program" params from url.
 * See the beta page of genemod_front_website_react
 */
const useBetaParams = () => {
	const { getParam } = useParams();
	const [data, setData] = useState({
		isBeta: false,
		email: "",
		first_name: "",
		last_name: "",
		betaCode: "",
	});

	const history = useHistory();

	useEffect(() => {
		const beta = getParam("beta");
		const email = getParam("email");
		const first_name = getParam("firstname");
		const last_name = getParam("lastname");
		const betaCode = getParam("code");

		const isBeta = beta !== null;
		if (!isBeta) return;

		setData({
			isBeta,
			email: email || "",
			first_name: first_name || "",
			last_name: last_name || "",
			betaCode: betaCode || "",
		});

		// clear all params
		history.replace({
			search: "",
		});
	}, []);

	return data;
};
