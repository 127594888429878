import { InputNumber } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Color } from "@common/styles/Colors";
import { useInputValidation } from "@helpers/InputHelper";
import { Demo, DemoSection, DemoWrapper, Typography } from "@components";
import styles from "@components/InputV2/InputV2.module.scss";
import numberInputStyles from "@components/InputNumber/InputNumber.module.scss";
import classNames from "classnames";
export const MAX_INTEGER = 2147483647;

export type InputV2Validator = {
	validator: (value: any) => boolean;
	error: string | JSX.Element;
};

export type InputNumberProps = {
	/** ID of the input field */
	id?: string;
	/** Input's name */
	name?: string;
	/** Input value */
	value?: string | number;
	/** The placeholder of the input */
	placeholder?: string;
	/** Input label */
	label?: string | React.ReactNode;
	/** Input label color */
	labelColor?: Color;
	/** Position of the input label */
	labelPosition?: "left" | "top";
	/** Whether to auto-focus the input */
	autoFocus?: boolean;
	/** Whether to disabled the input */
	disabled?: boolean;
	/** Props for the wrapper element of the input */
	wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
	/** Props for the input */
	inputProps?: React.HTMLAttributes<HTMLDivElement>;
	/** Props for the prefix */
	prefixProps?: React.HTMLAttributes<HTMLDivElement>;
	/** Prefix icon or text */
	prefix?: JSX.Element | string;
	/** Suffix icon or text */
	suffix?: JSX.Element | string;
	/** Whether to hide the error message */
	hideError?: boolean;
	/** A forced error state that displays an error message */
	error?: string | boolean | null;
	/** Whether to display the bottom margin. The margin is reserved for error message*/
	gutterBottom?: boolean;
	/** The max characters accepted by the input*/
	maxLength?: number;
	/** Input type */
	type?: string;
	/** A list of local functions that checks the validity of the input value */
	validators?: InputV2Validator[];
	/** Autocomplete */
	autoComplete?: string;
	/** ref of the input */
	forwardedRef?: React.RefObject<HTMLInputElement>;
	/** Called when the validity on the input changes */
	onValidityChange?: (arg0: boolean) => void;
	/** Called when the value of the input changes */
	onChange?: (value: number) => void;
	/** Called when users press on ENTER */
	onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	/** Called when the input loses focus */
	onBlur?: any;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	/** Called when the input is on focus */
	onFocus?: any;
	/** Use up and down number arrows */
	useNumberArrows?: boolean;
	/** Number arrows orientation */
	numberArrowsOrientation?: "horizontal" | "vertical";
	/* Icon for the number arrows **/
	arrowIcon?: "caret" | "chevron";
	/** minimum number user can enter */
	min?: number;
	/** maximum number user can enter */
	max?: number;
	/** default number */
	defaultValue?: number;
	/** remove border around the input */
	noBorder?: boolean;
	containerClassName?: string;
	dataCy?: string;
};

export default function InputNumberV2({
	id = "",
	placeholder = "",
	label = "",
	labelColor = "text-secondary",
	labelPosition = "top",
	disabled = false,
	autoFocus = false,
	hideError = false,
	error = "",
	prefix = "",
	suffix = "",
	gutterBottom = true,
	maxLength = 200,
	wrapperProps = {},
	inputProps = {},
	prefixProps = {},
	validators = [],
	type = "number",
	autoComplete,
	onValidityChange = () => {},
	onChange = () => {},
	onPressEnter = () => {},
	onBlur = () => {},
	onKeyDown = () => {},
	onFocus = () => {},
	forwardedRef,
	min = Number.MIN_SAFE_INTEGER,
	max = MAX_INTEGER,
	noBorder,
	name,
	containerClassName,
	dataCy,
	defaultValue,
	...props
}: InputNumberProps): JSX.Element {
	const { value } = props;
	const [inputValue, setValue] = useState(value ? value : "");
	const [isValid, showError] = useInputValidation(
		inputValue,
		[
			...(type === "number"
				? [
						{
							validator: (value: string) => +value >= min,
							error: `Value must be ${min} or greater`,
						},
						{
							validator: (value: string) => +value <= max,
							error: `Value must be ${max} or less`,
						},
						{
							validator: (value: string) => +value <= MAX_INTEGER,
							error: "Number is too large",
						},
				  ]
				: []),
			...validators,
		],
		onValidityChange
	);
	const canPressEnter = useRef<boolean>(true);
	const val = "value" in props ? props.value : inputValue;

	const inputValid = hideError || (!error && isValid);

	/** Sync internal value with props */
	useEffect(() => {
		if (
			value !== null &&
			typeof value !== "undefined" &&
			value !== inputValue
		) {
			setValue(value);
		}
	}, [value, inputValue]);

	const handleChange = (value: number | undefined) => {
		setValue(value ? value.toString() : "");
		if (onChange && value) onChange(value);
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && onPressEnter && canPressEnter.current) {
			onPressEnter(e);
			canPressEnter.current = false;
		}
	};

	const inputRef = useRef<HTMLInputElement>(null);
	const handleClick = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	useEffect(() => {
		setTimeout(() => {
			if (inputRef.current && autoFocus) {
				inputRef.current.focus();
			}
		}, 200);
	}, [inputRef, autoFocus]);

	return (
		<div {...wrapperProps}>
			{label && (
				<Typography
					variant="label"
					className={styles.label}
					color={labelColor}
					bold
				>
					{label}
				</Typography>
			)}
			<InputNumber
				id={id}
				name={name}
				onBlur={onBlur}
				className={classNames(
					numberInputStyles.input,
					inputProps && inputProps.className
				)}
				onKeyDown={onKeyDown}
				onFocus={onFocus}
				maxLength={maxLength}
				autoFocus={autoFocus}
				type={type}
				autoComplete={autoComplete}
				data-cy={dataCy}
				onKeyPress={handleKeyPress}
				value={val ? Number(val) : undefined}
				defaultValue={defaultValue ? defaultValue : 0}
				onChange={handleChange}
				min={min}
				max={max}
				disabled={disabled}
			/>
			{!inputValid && (
				<Typography
					className={styles.inputContainerError}
					variant="caption"
					color="red-contrast"
					data-cy={dataCy && dataCy + "-error"}
				>
					{error ? (error === true ? "" : error) : showError}
				</Typography>
			)}
		</div>
	);
}

export function INPUT_NUMBER_DEMO() {
	return (
		<DemoSection>
			<Demo title="Basic usage" description="Basic input element">
				<InputNumberV2
					id="input-number"
					label="Number"
					placeholder="Enter a number"
					type="number"
					min={1}
					max={10}
					defaultValue={3}
				/>
			</Demo>
		</DemoSection>
	);
}
