import { Checkbox, GenemodIcon, Typography } from "@components";
import { idKeysOfConst } from "@helpers/TypeHelpers";
import { Popover } from "antdv4";
import React, { HTMLProps, useState } from "react";
import Button from "../Button";
import styles from "./SimpleFilter.module.scss";

type Props = {
	label: string;
	selectedValues: number[];
	options: Readonly<any>;
	onChange: (value: number) => void;
	onClear?: () => void;
	getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
	cancelGetPopupContainer?: boolean;
	iconStyle?: HTMLProps<HTMLDivElement>["style"];
};

const SimpleFilter = ({
	label,
	selectedValues,
	options,
	onChange,
	onClear,
	getPopupContainer,
	cancelGetPopupContainer,
	iconStyle,
}: Props) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const handleFilterClick = (
		evt: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		evt.stopPropagation();
		setIsMenuOpen((prev) => !prev);
	};
	const handleOnClear = () => {
		setIsMenuOpen(false);
		onClear && onClear();
	};

	const menu = (
		<div>
			{idKeysOfConst(options).map((option, index) => {
				return (
					<div className={styles.option} key={index}>
						<Checkbox
							value={selectedValues.includes(index)}
							onChange={(e) => {
								onChange(index);
							}}
							style={{ marginLeft: 18 }}
						>
							{options[option].icon && (
								<GenemodIcon
									style={{
										marginRight: 3,
									}}
									name={options[option].icon}
								/>
							)}
							<Typography>
								{options[option].label || options[option]}
							</Typography>
						</Checkbox>
					</div>
				);
			})}
			<div className={styles.option}>
				<Button onClick={handleOnClear} type="link">
					Clear all
				</Button>
			</div>
		</div>
	);
	return (
		<Popover
			visible={isMenuOpen}
			onVisibleChange={(v: boolean) => (v ? null : setIsMenuOpen(false))}
			content={menu}
			placement="bottom"
			trigger="click"
			overlayClassName={styles.filterPopover}
			getPopupContainer={
				getPopupContainer && !cancelGetPopupContainer
					? (trigger) => trigger.parentElement || document.body
					: undefined
			}
		>
			<div className={styles.main}>
				<Typography variant="label" color="text-primary" bold>
					{label}
				</Typography>
				<GenemodIcon
					onClick={handleFilterClick}
					style={iconStyle}
					name="filter-o"
					fill={
						selectedValues.length === 0
							? "text-ghost"
							: "accent-subtle"
					}
				/>
			</div>
		</Popover>
	);
};

export default SimpleFilter;
