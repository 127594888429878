import {
	CURRENCY_TYPES,
	ConsumableSearchItem,
	Item,
	SortByOptions,
} from "@common/types";
import { AtLeast, AtLeastId, IdsInConstType } from "@helpers/TypeHelpers";

export type ExperimentMaterialSortBy = SortByOptions<"name" | "created_by">;

export type MaterialSearchPayload = Partial<{
	page: number;
	page_size: number;
	search: string;
	origin: ExperimentMaterialOriginEnum;
	origin_in: ExperimentMaterialOriginEnum[];
	include_filter_options: boolean;
	ordering: ExperimentMaterialSortBy;
}>;

export type ExperimentMaterialSearchPayload = Partial<
	MaterialSearchPayload & {
		experiment_file: number;
		experiment_file_in: number[];
	}
>;

export type FolderMaterialSearchPayload = Partial<
	MaterialSearchPayload & {
		folder: number;
		folder_in: number[];
	}
>;

export enum ExperimentMaterialOriginEnum {
	WEB_LINK = 0,
	FREEZER,
	CONSUMABLES,
}

export type Material = {
	id: number;
	name: string;
	origin: ExperimentMaterialOriginEnum;
	location?: string;
	quantity?: number;
	// Link related field
	link?: string | null;
	freezer_item?: Item | null;
	consumable_item?: ConsumableSearchItem | null;
	found: number;
	// Item related fields
	source: string;
	reference: string;
	catalog: string;
	lot: string;
	packaging: string;
	price: string;
	currency: IdsInConstType<typeof CURRENCY_TYPES>;
};

export type ExperimentMaterial = Material & {
	experiment_file: number;
};

export type FolderMaterial = Material & {
	folder: number;
};

export const ExperimentMaterialOriginEnumToLabel = Object.freeze({
	[ExperimentMaterialOriginEnum.WEB_LINK]: {
		label: "Web link",
		icon: "globe",
	},
	[ExperimentMaterialOriginEnum.FREEZER]: {
		label: "Freezer",
		icon: "freezer",
	},
	[ExperimentMaterialOriginEnum.CONSUMABLES]: {
		label: "Consumables",
		icon: "consumable",
	},
} as Record<ExperimentMaterialOriginEnum, { label: string; icon: string }>);

export function getExperimentMaterialPayload(
	material:
		| AtLeast<ExperimentMaterial, "name" | "experiment_file">
		| AtLeastId<ExperimentMaterial>
) {
	return {
		...material,
		freezer_item: material.freezer_item?.id || null,
		consumable_item: material.consumable_item?.id || null,
	};
}

export function getFolderMaterialPayload(
	material:
		| AtLeast<FolderMaterial, "name" | "folder">
		| AtLeastId<FolderMaterial>
) {
	return {
		...material,
		freezer_item: material.freezer_item?.id || null,
		consumable_item: material.consumable_item?.id || null,
	};
}
