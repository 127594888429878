import {
	PaginatedSearchQuery,
	PaginatedSearchResults,
	Rack,
	RackDataForBackend,
	RackStorageSummary,
} from "@common/types";
import { AtLeastId } from "@helpers/TypeHelpers";
import { inventoryApi, withFreezerBase } from ".";

export type RepositoryRackFilters = Omit<
	PaginatedSearchQuery &
		Partial<{
			id: number | null;
			ordering: string | null;
			search: string | null;
			status__in: number[] | null;
			created_at__gte: string | null;
			created_at__lte: string | null;
			created_by__in: string[] | null;
			updated_at__gte: string | null;
			updated_at__lte: string | null;
			updated_by__in: string[] | null;
			location__shelf: number | null;
			location__shelf__in: number[] | null;
			location__isnull: number;
			include_filter_options: boolean | null;
		}>,
	"sort_by"
>;
export type RepositoryRackResult = PaginatedSearchResults<Rack>;

const rackApi = inventoryApi.injectEndpoints({
	endpoints: (builder) => ({
		createRack: builder.mutation<Rack, RackDataForBackend>({
			query: (rackData) => ({
				url: withFreezerBase(`v2/racks/`),
				method: "POST",
				body: rackData,
			}),
			invalidatesTags: ["Rack", "Shelf", "Freezer"],
		}),
		racks: builder.query<Rack[], number>({
			query: (shelfId) => ({
				url: withFreezerBase(`v2/shelves/${shelfId}/racks/`),
			}),
			providesTags: ["Rack"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		racksList: builder.query<
			Rack[],
			{
				location__freezer?: number;
				is_archived: boolean;
			}
		>({
			query: (params) => ({
				url: withFreezerBase(`v2/racks/`),
				params: params,
			}),
			providesTags: ["Rack"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		rack: builder.query<Rack, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/racks/${id}/`),
			}),
			providesTags: ["Rack"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		rackPatch: builder.mutation<Rack, AtLeastId<Rack>>({
			query: (body) => ({
				url: withFreezerBase(`v2/racks/${body.id}/`),
				method: "PATCH",
				body,
			}),
			invalidatesTags: ["Rack"],
		}),
		rackDelete: builder.mutation<void, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/racks/${id}/`),
				method: "DELETE",
			}),
			invalidatesTags: ["Rack", "Shelf", "Freezer"],
		}),
		insertRackRow: builder.mutation<Rack, { id: number; rowNum: number }>({
			query: ({ id, rowNum }) => ({
				url: withFreezerBase(`v2/racks/${id}/insert-row/`),
				method: "POST",
				body: { row: rowNum },
			}),
			invalidatesTags: ["Rack", "Box"],
		}),

		insertRackCol: builder.mutation<Rack, { id: number; colNum: number }>({
			query: ({ id, colNum }) => ({
				url: withFreezerBase(`v2/racks/${id}/insert-column/`),
				method: "POST",
				body: { column: colNum },
			}),
			invalidatesTags: ["Rack", "Box"],
		}),
		deleteRackRow: builder.mutation<Rack, { id: number; rowNum: number }>({
			query: ({ id, rowNum }) => ({
				url: withFreezerBase(`v2/racks/${id}/delete-row/`),
				method: "POST",
				body: { row: rowNum },
			}),
			invalidatesTags: ["Rack", "Box"],
		}),
		deleteRackCol: builder.mutation<Rack, { id: number; colNum: number }>({
			query: ({ id, colNum }) => ({
				url: withFreezerBase(`v2/racks/${id}/delete-column/`),
				method: "POST",
				body: { column: colNum },
			}),
			invalidatesTags: ["Rack", "Box"],
		}),
		rackStorageSummary: builder.query<RackStorageSummary, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/racks/${id}/storage-summary/`),
			}),
			providesTags: ["Rack"],
			extraOptions: {
				maxRetries: 0,
			},
		}),
		getRecentRackDimensions: builder.query<
			{ row: number; col: number },
			number
		>({
			query: (freezerId) => ({
				url: withFreezerBase(
					`v2/freezers/${freezerId}/most-recent-rack-dimension/`
				),
			}),
		}),
		getRepositoryRacks: builder.query<
			RepositoryRackResult,
			RepositoryRackFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/racks-repository/"),
				method: "GET",
				params,
			}),
			providesTags: ["Rack"],
		}),
		restorableRacks: builder.query<
			RepositoryRackResult,
			RepositoryRackFilters
		>({
			query: (params) => ({
				url: withFreezerBase("v2/restorable-racks/"),
				method: "GET",
				params,
			}),
			providesTags: ["Rack"],
		}),
		getRepositoryRack: builder.query<Rack, number>({
			query: (id) => ({
				url: withFreezerBase(`v2/racks-repository/${id}/`),
				method: "GET",
			}),
			providesTags: ["Rack"],
		}),
		rackBulkCreate: builder.mutation<Rack[], RackDataForBackend[]>({
			query: (body) => ({
				url: withFreezerBase("v2/racks-repository/bulk-create/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Shelf", "Rack"],
		}),
		rackBulkUpdate: builder.mutation<Rack[], Partial<RackDataForBackend>[]>(
			{
				query: (body) => ({
					url: withFreezerBase("v2/racks-repository/bulk-update/"),
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["Rack", "ItemGroup", "Box", "Item"],
			}
		),
		rackBulkDelete: builder.mutation<void, { racks_ids: number[] }>({
			query: (body) => ({
				url: withFreezerBase("v2/racks-repository/bulk-delete/"),
				method: "POST",
				body,
			}),
			invalidatesTags: ["Shelf", "Rack"],
		}),
	}),
});

export const {
	useCreateRackMutation,
	useRacksQuery,
	useLazyRacksQuery,
	useRacksListQuery,
	useRackQuery,
	useLazyRackQuery,
	useRackPatchMutation,
	useRackDeleteMutation,
	useInsertRackColMutation,
	useInsertRackRowMutation,
	useDeleteRackColMutation,
	useDeleteRackRowMutation,
	useRackStorageSummaryQuery,
	useGetRecentRackDimensionsQuery,
	useLazyGetRecentRackDimensionsQuery,
	useGetRepositoryRacksQuery,
	useRestorableRacksQuery,
	useGetRepositoryRackQuery,
	useRackBulkCreateMutation,
	useRackBulkUpdateMutation,
	useRackBulkDeleteMutation,
} = rackApi;
