import {
	Consumable,
	Furniture,
	FurnitureCategory,
	FurnitureCategoryId,
	FurnitureType,
	RepositoryConsumableResult,
	RepositoryConsumablesFilters,
	SharedReadDto,
	SharedWriteDto,
	SimpleSpace,
	Space,
	SpaceCreateDto,
	SpaceTemplateName,
	fromSharedReadDto,
	SpaceId,
	SpaceFurnitureSummary,
	FurnitureCategoryWithItemCount,
	FurnitureId,
} from "@common/types";
import { AtLeastId, NominalId } from "@helpers/TypeHelpers";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const consumableApi = createApi({
	reducerPath: "consumableApi",
	baseQuery: genemodBaseQuery({ baseUrl: "/freezer/api/consumables" }),
	tagTypes: [
		"Space",
		"Furniture",
		"FurnitureCategory",
		"Consumable",
		"FurnitureType",
	],
	endpoints: (builder) => {
		const transformSpaceResponse = (resp: SharedReadDto<Space>) =>
			fromSharedReadDto(resp);

		const transformSimpleSpaceResponse = (
			resp: SharedReadDto<SimpleSpace>
		) => fromSharedReadDto(resp);

		const space = {
			spaceSummary: builder.query<SpaceFurnitureSummary, SpaceId>({
				query: (spaceId) =>
					`space-summary/${spaceId}/furniture-summary/`,
				providesTags: ["Space"],
			}),
			listSpaces: builder.query<SimpleSpace[], void>({
				query: () => "space/list-spaces/",
				transformResponse: (resp: SharedReadDto<SimpleSpace>[]) =>
					resp.map(transformSimpleSpaceResponse),
				providesTags: ["Space"],
			}),
			spaces: builder.query<Space[], void>({
				query: () => "space/",
				transformResponse: (resp: SharedReadDto<Space>[]) =>
					resp.map(transformSpaceResponse),
				providesTags: ["Space"],
			}),
			space: builder.query<Space, number>({
				query: (id) => `space/${id}/`,
				transformResponse: transformSpaceResponse,
				providesTags: ["Space"],
			}),
			spaceCreate: builder.mutation<Space, SpaceCreateDto>({
				query: (body) => ({
					url: `space/`,
					method: "POST",
					body,
				}),
				transformResponse: transformSpaceResponse,
				invalidatesTags: ["Space"],
			}),
			spacePatch: builder.mutation<
				Space,
				AtLeastId<SharedWriteDto<Space>>
			>({
				query: (body) => ({
					url: `space/${body.id}/`,
					method: "PATCH",
					body,
				}),
				transformResponse: transformSpaceResponse,
				invalidatesTags: ["Space"],
			}),
			spaceDelete: builder.mutation<void, number>({
				query: (id) => ({
					url: `space/${id}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Space"],
			}),
			applySpaceTemplate: builder.mutation<
				void,
				{ id: number; templateName: SpaceTemplateName }
			>({
				query: ({ id, templateName }) => ({
					url: `space/${id}/apply-space-template/${templateName}/`,
					method: "PUT",
				}),
				invalidatesTags: ["Space", "Furniture"],
			}),
		} as const;

		const furniture = {
			furnitures: builder.query<Furniture[], number>({
				query: (spaceId) => `space/${spaceId}/furnitures/`,
				providesTags: ["Furniture"],
			}),
			furnitureCreate: builder.mutation<
				Furniture,
				{ spaceId: number; furnitureData: Partial<Furniture> }
			>({
				query: ({ spaceId, furnitureData }) => ({
					url: `space/${spaceId}/furnitures/`,
					method: "POST",
					body: furnitureData,
				}),
				invalidatesTags: ["Furniture", "Space"],
			}),
			furniture: builder.query<Furniture, number>({
				query: (id) => `furnitures/${id}/`,
				providesTags: ["Furniture"],
			}),
			furniturePatch: builder.mutation<Furniture, AtLeastId<Furniture>>({
				query: (body) => ({
					url: `furnitures/${body.id}/`,
					method: "PATCH",
					body,
				}),
				extraOptions: {
					maxRetries: 0, // Disable retries for this mutation
				},
				invalidatesTags: ["Furniture"],
			}),
			furnitureDelete: builder.mutation<void, number>({
				query: (id) => ({
					url: `furnitures/${id}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Furniture", "Space"],
			}),
			furnitureItemSummary: builder.query<
				FurnitureCategoryWithItemCount[],
				FurnitureId
			>({
				query: (furnitureId) =>
					`furniture-summary/${furnitureId}/item-summary/`,
				providesTags: ["Furniture"],
			}),
		} as const;

		const furnitureCategory = {
			furnitureCategories: builder.query<FurnitureCategory[], number>({
				query: (furnitureId) =>
					`furnitures/${furnitureId}/furniture-categories/`,
				providesTags: ["FurnitureCategory"],
			}),
			furnitureCategoryCreate: builder.mutation<
				FurnitureCategory,
				{
					furnitureId: number;
					furnitureCategoryData: Partial<FurnitureCategory>;
				}
			>({
				query: ({ furnitureId, furnitureCategoryData }) => ({
					url: `furnitures/${furnitureId}/furniture-categories/`,
					method: "POST",
					body: furnitureCategoryData,
				}),
				invalidatesTags: ["FurnitureCategory", "Furniture", "Space"],
			}),
			furnitureCategory: builder.query<FurnitureCategory, number>({
				query: (id) => `furniture-categories/${id}/`,
				providesTags: ["FurnitureCategory"],
			}),
			furnitureCategoryPatch: builder.mutation<
				FurnitureCategory,
				AtLeastId<FurnitureCategory>
			>({
				query: (body) => ({
					url: `furniture-categories/${body.id}/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["FurnitureCategory"],
			}),
			furnitureCategoryDelete: builder.mutation<void, number>({
				query: (id) => ({
					url: `furniture-categories/${id}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["FurnitureCategory", "Space", "Furniture"],
			}),
		} as const;

		const consumable = {
			consumables: builder.query<Consumable[], FurnitureCategoryId>({
				query: (furnitureCategoryId) =>
					`furniture-categories/${furnitureCategoryId}/consumables/`,
				providesTags: ["Consumable"],
			}),
			repositoryConsumables: builder.query<
				RepositoryConsumableResult,
				RepositoryConsumablesFilters
			>({
				query: (params) => ({
					url: "/list-consumables/",
					method: "GET",
					params,
				}),
				providesTags: ["Consumable"],
			}),
			consumableCreate: builder.mutation<
				Consumable,
				{
					furnitureCategoryId: FurnitureCategoryId;
					consumableData: Partial<Consumable>;
				}
			>({
				query: ({ furnitureCategoryId, consumableData }) => ({
					url: `furniture-categories/${furnitureCategoryId}/consumables/`,
					method: "POST",
					body: consumableData,
				}),
				invalidatesTags: [
					"Consumable",
					"Furniture",
					"FurnitureCategory",
					"Space",
				],
			}),
			consumableRepositoryCreate: builder.mutation<
				Consumable,
				{ consumableData: Partial<Consumable> }
			>({
				query: ({ consumableData }) => ({
					url: `consumables-repository/`,
					method: "POST",
					body: consumableData,
				}),
				invalidatesTags: ["Consumable", "FurnitureCategory"],
			}),
			consumable: builder.query<Consumable, number>({
				query: (id) => `consumables/${id}/`,
				providesTags: ["Consumable"],
			}),
			consumablePatch: builder.mutation<
				Consumable,
				AtLeastId<Consumable>
			>({
				query: (body) => ({
					url: `consumables/${body.id}/`,
					method: "PATCH",
					body,
				}),
				async onQueryStarted(body, { dispatch, queryFulfilled }) {
					const individualUpdate = await dispatch(
						consumableApi.util.updateQueryData(
							"consumable",
							body.id,
							(draft) => {
								return body as Consumable;
							}
						)
					);
					if (!body.parent_furniture_category) return;
					const listUpdate = await dispatch(
						consumableApi.util.updateQueryData(
							"consumables",
							body.parent_furniture_category,
							(list) => {
								const index = list.findIndex(
									(consumable) => consumable.id === body.id
								);
								if (index !== -1) {
									Object.keys(body).forEach((key) => {
										if (
											key !== "id" &&
											Object.prototype.hasOwnProperty.call(
												list[index],
												key
											)
										) {
											(list[index] as any)[key] = (
												body as any
											)[key];
										}
									});
								}
							}
						)
					);

					queryFulfilled.catch(individualUpdate.undo);
					queryFulfilled.catch(listUpdate.undo);
				},
				invalidatesTags: ["Consumable"],
			}),
			consumableBulkCreate: builder.mutation<
				Consumable[],
				{ items: Partial<Consumable>[] }
			>({
				query: ({ items }) => ({
					url: `consumables-repository/bulk-create/`,
					method: "POST",
					body: items,
				}),
				invalidatesTags: ["Consumable", "Space", "Furniture"],
			}),
			bulkConsumableRepositoryPatch: builder.mutation<
				Consumable[],
				{ consumableData: Partial<Consumable[]> }
			>({
				query: ({ consumableData }) => ({
					url: `consumables-repository/bulk-update/`,
					method: "PATCH",
					body: consumableData,
				}),
				invalidatesTags: ["Consumable"],
			}),
			consumableDelete: builder.mutation<void, number>({
				query: (id) => ({
					url: `consumables/${id}/`,
					method: "DELETE",
				}),
				invalidatesTags: ["Consumable", "Space", "Furniture"],
			}),
			consumableBulkDelete: builder.mutation<void, { ids: number[] }>({
				query: ({ ids }) => ({
					url: `consumables-repository/bulk-delete/`,
					method: "POST",
					body: [...ids],
				}),
				invalidatesTags: ["Consumable", "Furniture", "Space"],
			}),
			consumableBulkChangeLocation: builder.mutation<
				void,
				{ consumables: number[]; parent_category_id: number }
			>({
				query: ({ parent_category_id, consumables }) => ({
					url: `consumables-repository/bulk-change-location/`,
					method: "POST",
					body: {
						consumables,
						parent_category_id,
					},
				}),
				invalidatesTags: ["Consumable"],
			}),
			consumableConsume: builder.mutation<
				Consumable,
				{
					id: NominalId<"ConsumableId">;
					quantity: number;
				}
			>({
				query: ({ id, quantity }) => ({
					url: `consumables/${id}/consume/`,
					method: "POST",
					body: {
						quantity_consumed: quantity,
					},
				}),
				invalidatesTags: ["Consumable"],
			}),
		} as const;

		const furnitureType = {
			furnitureTypes: builder.query<FurnitureType[], void>({
				query: () => `furniture-types/`,
				providesTags: ["FurnitureType"],
			}),
			furnitureType: builder.query<FurnitureType, number>({
				query: (id) => `furniture-types/${id}/`,
				providesTags: ["FurnitureType"],
			}),
		} as const;
		return {
			...space,
			...furniture,
			...furnitureCategory,
			...consumable,
			...furnitureType,
		};
	},
});

export const {
	useListSpacesQuery,
	useSpacesQuery,
	useSpaceQuery,
	useSpaceSummaryQuery,
	useSpaceCreateMutation,
	useSpacePatchMutation,
	useSpaceDeleteMutation,
	useApplySpaceTemplateMutation,

	useFurnituresQuery,
	useFurnitureQuery,
	useFurnitureCreateMutation,
	useFurniturePatchMutation,
	useFurnitureDeleteMutation,

	useFurnitureCategoriesQuery,
	useFurnitureCategoryQuery,
	useFurnitureCategoryCreateMutation,
	useFurnitureCategoryPatchMutation,
	useFurnitureCategoryDeleteMutation,
	useLazyFurnitureCategoriesQuery,

	useConsumablesQuery,
	useRepositoryConsumablesQuery,
	useConsumableQuery,
	useConsumableCreateMutation,
	useConsumableRepositoryCreateMutation,
	useConsumablePatchMutation,
	useBulkConsumableRepositoryPatchMutation,
	useConsumableDeleteMutation,
	useConsumableConsumeMutation,
	useConsumableBulkChangeLocationMutation,
	useConsumableBulkDeleteMutation,
	useConsumableBulkCreateMutation,
	useLazyConsumableQuery,
	useLazyConsumablesQuery,

	useFurnitureTypesQuery,
	useFurnitureTypeQuery,
	useFurnitureItemSummaryQuery,
} = consumableApi;
