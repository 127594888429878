import { ISOString } from "./Date";
import { Avatar } from "./User";

/**
 * BaseDocument corresponds to "BaseDocument" on the backend, covers file uploads, Genemod documents, and google docs.
 */
export type BaseDocumentType =
	| "ExperimentDocument" // Note: should more properly be named "genemod document"
	| "ExperimentDocumentAttachment"
	| "ExperimentUpload" //this type is for experiment image
	| "ProjectAttachment"
	| "SubprojectUpload"
	| "ExperimentAttachment"
	| "GoogleDriveDocument"
	| "ItemAttachment"
	| "FreezerOrderAttachment";

export type BaseDocument = {
	/**
	 * Name of document
	 */
	name: string;
	/**
	 * "Owner" of document
	 */
	owner: Avatar;
	/**
	 * Id of org the document is part of
	 */
	org: number;
	/**
	 * BaseDocumentType, to distiguish document types
	 */
	document_type: BaseDocumentType;
	/**
	 * When the doc was created
	 */
	created_at: ISOString;
	/**
	 * Who created the document
	 */
	created_by: Avatar;
	/**
	 * When the doc was last edited
	 */
	updated_at: ISOString;
	/**
	 * Who last edited the document
	 */
	updated_by: Avatar;
};
