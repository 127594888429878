import React from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useConfig } from "@common/config/GenemodConfig";

type TypeProps = {
	stripe: Stripe | null;
	elements: StripeElements | null;
};
type OmitTypeProps<P> = Omit<P, keyof TypeProps>;

const WithStripe = React.memo(({ Component, ...props }: { Component: any }) => {
	const stripe = useStripe();
	const elements = useElements();
	return <Component {...props} stripe={stripe} elements={elements} />;
});

const WithoutStripe = React.memo(
	({ Component, ...props }: { Component: any }) => {
		return <Component {...props} stripe={null} elements={null} />;
	}
);

/**
 * Higher order component that either loads or does not load stripe depending on the config setting.
 * We do this to prevent calls to stripe for on prem deploys.
 */
export const withOrWithoutStripe = <P extends TypeProps>(
	component: (props: P) => JSX.Element
): ((props: OmitTypeProps<P>) => JSX.Element) => {
	return (props: OmitTypeProps<P>) => {
		const useStripeConfig = useConfig("USE_STRIPE");
		if (useStripeConfig) {
			return <WithStripe {...props} Component={component} />;
		}
		return <WithoutStripe {...props} Component={component} />;
	};
};
