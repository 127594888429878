import {
	Avatar,
	Contributor,
	ITEM_GROUP_CONTAINERS,
	Item,
	getFullNameFromAvatar,
} from "@common/types";
import {
	ActivityLog,
	ClickToEdit,
	DropDown,
	GenemodIcon,
	Typography,
	UserAvatar,
} from "@components";
import { FREEZER_NAME_CHAR_LIMITS } from "@containers/Freezer/data";
import {
	BoxActivityLogKeyContext,
	useBoxView,
	useItemGroup,
} from "@containers/Freezer/table/BoxTableHooks";
import {
	getItemCount,
	groupBoxItemsIntoContributors,
} from "@helpers/BoxHelper";
import { pluralS } from "@helpers/Formatters";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { IdsInConstType } from "@helpers/TypeHelpers";

type BoxCategoryInfoProps = {
	dropdownMenu: JSX.Element;
	onNameChange: (name: string) => void;
	setMenu?: (visible: boolean) => void;
	menuVisible?: boolean;
};
/**
 * Box and Category are currently the same backend model so we can
 * use the same info component for both the boxview and categoryview.
 *
 * This component should be used within the BoxView component in
 * @containers/Freezer/BoxView/BoxView.tsx
 *
 * Note: they may diverge in the future.
 */
export default function BoxCategoryInfo(props: BoxCategoryInfoProps) {
	const {
		dropdownMenu,
		onNameChange: _onNameChange,
		menuVisible,
		setMenu,
	} = props;
	const {
		item_group,
		items: ig_items,
		viewOnly: ig_viewOnly,
	} = useItemGroup();
	const { box, items: box_items, viewOnly: box_viewOnly } = useBoxView();
	const { resetActivityLogKey } = useContext(BoxActivityLogKeyContext);
	const [refreshTrigger, setRefreshTrigger] = useState(nanoid());

	let is_item_group: boolean;
	let items: Item[];
	let viewOnly: boolean;
	let contributors: Contributor[];
	const obj = item_group || box;

	if (item_group) {
		is_item_group = true;
		items = ig_items;
		viewOnly = ig_viewOnly;
		contributors = groupBoxItemsIntoContributors(ig_items);
	} else {
		is_item_group = false;
		items = box_items;
		viewOnly = box_viewOnly;
		contributors = groupBoxItemsIntoContributors(box_items);
	}
	const contributorsLength = contributors.length;

	const onNameChange = (name: string) => {
		_onNameChange(name);
		resetActivityLogKey();
	};

	useEffect(() => {
		setRefreshTrigger(nanoid());
	}, [obj]);

	return (
		<div className={styles.boxInfoWrapper}>
			<div className={styles.boxNameAndOptions}>
				<ClickToEdit
					color="text-primary-v2"
					value={obj?.name || ""}
					className={styles.boxTitle}
					component="input"
					onComplete={(value) => onNameChange(value.trim())}
					validators={[
						{
							validator: (val) => val.length > 0,
							error: "Please specify the name",
						},
					]}
					readOnly={viewOnly}
					maxLength={FREEZER_NAME_CHAR_LIMITS.BOX}
				/>
				<DropDown
					overlay={<div>{dropdownMenu}</div>}
					onVisibleChange={(visible) => setMenu?.(visible)}
					visible={menuVisible}
					placement="bottomLeft"
					type="meatballs"
					className={styles.fixedHeaderItem}
				/>
			</div>
			<div className={styles.boxStats}>
				<div className={styles.boxStat}>
					<GenemodIcon
						name="lab-tube"
						decorative
						color="text-secondary-v2"
					/>
					<Typography color="text-secondary-v2" variant="body2">
						{getItemCount(items)}
					</Typography>
				</div>

				<div className={styles.boxStat}>
					<GenemodIcon
						name="box"
						decorative
						color="text-secondary-v2"
					/>
					{box ? (
						<Typography color="text-secondary-v2" variant="body2">
							{box.rows} x {box.columns} dimension
						</Typography>
					) : (
						<Typography color="text-secondary-v2" variant="body2">
							{`${
								ITEM_GROUP_CONTAINERS[
									item_group?.container_type as IdsInConstType<
										typeof ITEM_GROUP_CONTAINERS
									>
								]
							}`}
							{item_group?.container_volume &&
								` (${item_group?.container_volume})`}
						</Typography>
					)}
				</div>
				<div className={styles.boxStat}>
					<GenemodIcon
						name="people-group"
						decorative
						color="text-secondary-v2"
					/>
					<Typography color="text-secondary-v2" variant="body2">
						{contributorsLength} contributor
						{pluralS(contributorsLength)}
					</Typography>
				</div>
			</div>
			<div className={styles.divider} />
			<div className={styles.generalInfo}>
				<div className={styles.boxInfo}>
					<BoxInfoLabel>Created on</BoxInfoLabel>
					<DateField value={obj?.created_at} />
				</div>
				<div className={styles.boxInfo}>
					<BoxInfoLabel>Created by</BoxInfoLabel>
					<AvatarField user={obj?.created_by} />
				</div>
				<div className={styles.boxInfo}>
					<BoxInfoLabel>Modified on</BoxInfoLabel>
					<DateField value={obj?.updated_at} />
				</div>
				<div className={styles.boxInfo}>
					<BoxInfoLabel>Modified by</BoxInfoLabel>
					<AvatarField user={obj?.updated_by} />
				</div>
			</div>
			<div className={styles.divider} />
			<div className={styles.activity}>
				<ActivityLog
					key={refreshTrigger}
					activityFilter={{
						location_model: is_item_group ? "ItemGroup" : "Box",
						location_object_id: obj?.id,
					}}
				/>
			</div>
		</div>
	);
}

function DateField(
	props: React.ComponentProps<typeof Typography> & { value?: string }
) {
	const { value } = props;
	return (
		<Typography color="text-secondary-v2" variant="body" {...props}>
			{value === undefined ? "-" : moment(value).format("MMM D, YYYY")}
		</Typography>
	);
}

/**
 * Adjusts the styling of the UserAvatar component
 */
function AvatarField(props: { user?: Avatar | null }) {
	const { user } = props;
	return (
		<Typography
			variant="body"
			color="text-secondary-v2"
			style={{ display: "flex", alignItems: "center", gap: 8 }}
		>
			{user ? <>{getFullNameFromAvatar(user)}</> : "-"}
		</Typography>
	);
}

function BoxInfoLabel(props: React.ComponentProps<typeof Typography>) {
	return (
		<Typography variant="body" medium color="text-primary-v2" {...props} />
	);
}
