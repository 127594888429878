import React from "react";
import styles from "./GenemodAutoComplete.module.scss";
import cn from "classnames";
import { AutoComplete } from "antd";
import { LayerSystemContainer } from "@components";
import { AutoCompleteProps } from "antd/lib/auto-complete";

type GenemodAutoCompleteProps = AutoCompleteProps;

/**
 * Wraps the antd autocomplete and provides the correct styling.
 * Extend styling options here.
 */
const GenemodAutoComplete = (props: GenemodAutoCompleteProps) => {
	return (
		<AutoComplete
			{...props}
			dropdownRender={(menu, innerProps) => {
				if (props.dropdownRender) {
					menu = props.dropdownRender(menu, innerProps);
				}
				return (
					<LayerSystemContainer
						overrideLayer={3}
						style={{ borderRadius: 4 }}
					>
						{menu}
					</LayerSystemContainer>
				);
			}}
			dropdownClassName={cn(styles.dropdown, props.dropdownClassName)}
			className={cn(styles.genemodAutoComplete, props.className)}
		/>
	);
};

GenemodAutoComplete.Option = AutoComplete.Option;
export default GenemodAutoComplete;
