import useCurrentOrganization from "@helpers/Hooks/useCurrentOrganizationHook";
import { useSaveOrganizationMutation } from "@redux/team/TeamApi";
import React, { useEffect, useState } from "react";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { Typography, Input, Button, LoadingSpinner } from "@components";
import { WideOnboarding } from "@containers/Auth/components";
import TimeZone from "./TimeZone/TimeZone";
import { MAX_TEAM_NAME_LENGTH } from "@common/constants";
import "../../../index.scss";
import styles from "./index.module.scss";

/**
 * Team creation form for registration process
 * @function
 * @category Auth
 * @category Registration
 *
 * @param {function} onComplete Called when team has successfully been created
 * */
export function TeamCreation(): JSX.Element {
	const [name, setName] = useState("");
	const [institution, setInstitution] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [timezone, setTimeZone] = useState("");
	const history = useHistory();

	const {
		allOrganizations: organizations,
		getOrganizations,
		isLoading,
	} = useCurrentOrganization();
	const [saveOrganization] = useSaveOrganizationMutation();

	if (organizations.length > 0) {
		history.push({
			pathname: "/signup/invite-team",
		});
	}

	useEffect(() => {
		getOrganizations();
	}, []);

	const handleSubmit = () => {
		if (!name.trim()) return;
		setLoading(true);
		saveOrganization({
			name,
			timezone,
			institution,
		})
			.unwrap()
			.then(() => {
				getOrganizations();
			})
			.catch((err) => {
				if (!err.response) {
					setError(
						"Failed to create your team. Try again or contact us if it continues."
					);
					return;
				}
				setError(err.response.data.detail);
			})
			.finally(() => setLoading(false));
	};

	return (
		<WideOnboarding>
			<LoadingSpinner loading={isLoading}>
				<Typography
					dataCy="create-your-team"
					variant="title"
					bold
					className={styles.createTeamTitle}
				>
					Create your team
				</Typography>
				<Input
					dataCy="team-creation-institution"
					label="Institution"
					onChange={(e) => setInstitution(e.target.value)}
					value={institution}
					autoFocus
				/>
				<Input
					dataCy="team-creation-team-name"
					label="Team name"
					onChange={(e) => setName(e.target.value)}
					maxLength={MAX_TEAM_NAME_LENGTH}
				/>
				<Typography style={{ marginBottom: 8 }}>Time zone</Typography>
				<TimeZone onChange={(val: string) => setTimeZone(val)} />
				{error && (
					<Typography
						variant="caption"
						color="red"
						style={{ marginTop: 8 }}
					>
						{error}
					</Typography>
				)}
				<Button
					dataCy="team-creation-create-button"
					disabled={!name.trim() || !institution.trim()}
					onClick={handleSubmit}
					loading={loading}
					stretch
					style={{ marginTop: error ? 24 : 48 }}
				>
					Create team
				</Button>
			</LoadingSpinner>
		</WideOnboarding>
	);
}
