import React, { useState } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { Spin } from "../Spin/Spin";

type SpinProps = React.ComponentProps<typeof Spin>;

type LoadingSpinnerProps = SpinProps & {
	wrapper?: boolean;
	containerClassName?: string;
	loading: boolean;
	centered?: boolean;
};

/**
 * Spinner convenience function that takes inspiration from "react suspense". It displays the spinner if "loading"
 * is true, else it displays the children in the component.
 * https://reactjs.org/docs/concurrent-mode-suspense.html
 *
 * Usage example:
 * <LoadingSpinner loading={myData.status === "LOADING"}>
 *     <MyComponent />
 * </LoadingSpinner>
 */
const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
	if (props.loading) {
		const {
			containerClassName,
			centered,
			loading,
			children,
			size,
			...spinProps
		} = props;
		let spinner = <Spin {...spinProps} />;

		if (props.wrapper) {
			spinner = <Spin {...spinProps}>{children}</Spin>;
		}

		if (centered) {
			spinner = (
				<div className={cn(styles.container, containerClassName)}>
					{spinner}
				</div>
			);
		}
		return spinner;
	}
	return <>{props.children}</>;
};

export default LoadingSpinner;

export function LOADING_SPINNER_DEMO(): JSX.Element {
	const [showSpinner, setShowSpinner] = useState(false);

	return (
		<div>
			<div>
				<LoadingSpinner loading={true}>Never rendered!</LoadingSpinner>
			</div>
			<LoadingSpinner loading={false}>Never spinner!</LoadingSpinner>
			<div onClick={() => setShowSpinner(!showSpinner)}>
				Click me to show/hide the next spinner!
			</div>
			<LoadingSpinner loading={showSpinner}>
				Done spinning!
			</LoadingSpinner>
		</div>
	);
}
