export const ATTACHMENT_ACCEPTED_FILES = Object.freeze([
	"txt",
	"pdf",
	"doc",
	"docx",
	"xls",
	"xlsx",
	"csv",
	"ppt",
	"pptx",
	"xps",
] as const);

export const IMAGE_ACCEPTED_FILES = Object.freeze([
	"jpeg",
	"jpg",
	"png",
	"apng",
	"gif",
	"svg",
	"bmp",
] as const);

export function isImage(fileType: string | undefined): boolean {
	return (
		fileType !== undefined &&
		IMAGE_ACCEPTED_FILES.includes(
			fileType as (typeof IMAGE_ACCEPTED_FILES)[number]
		)
	);
}

export const VIDEO_ACCEPTED_FILES = Object.freeze([
	"mp4",
	"web,",
	"ogg",
	"mov",
] as const);

export const ALL_ACCEPTED_FILES = Object.freeze([
	...ATTACHMENT_ACCEPTED_FILES,
	...IMAGE_ACCEPTED_FILES,
	...VIDEO_ACCEPTED_FILES,
] as const);

export type AttachmentAcceptedFile = (typeof ALL_ACCEPTED_FILES)[number];
