import React, { useState } from "react";
import "./Popover.scss";
import { Popover as ANTPopover } from "antd";
import classNames from "classnames";
import { DemoWrapper, DemoSection, Demo, Button } from "../index";
import {
	LayerSystemContextContainer,
	LayerClasses,
} from "../LayerSystemContainer/LayerSystemContainer";
import { PopoverProps } from "antd/lib/popover";

const LAYER = 3;

type _PopoverProps = PopoverProps & {
	content: React.ReactNode;
	overlayClassName?: string;
};

// See ant design Popover for API
export default function Popover(props: _PopoverProps) {
	return (
		<ANTPopover
			{...props}
			content={
				props.content ? (
					<LayerSystemContextContainer overrideLayer={LAYER}>
						{props.content}
					</LayerSystemContextContainer>
				) : (
					props.content
				)
			}
			overlayClassName={classNames(
				"genemod-popover",
				props.overlayClassName,
				LayerClasses[`layer-${LAYER}`]
			)}
		></ANTPopover>
	);
}

export function POPOVER_DEMO() {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Basic usage" description="Basic usage of popover">
					<Popover
						title="This is a popover"
						content="Hello world"
						trigger="click"
					>
						<Button>Click Me</Button>
					</Popover>
				</Demo>
				<Demo
					title="Open method"
					description="Control how the popover is opened. Includes click, hover, active, focus, etc."
				>
					<Popover
						title="This is a popover"
						content="Hello world"
						trigger="click"
					>
						<Button>Click Me</Button>
					</Popover>
					<br />
					<Popover
						title="You hovered!"
						content="Open by hovering"
						trigger="hover"
					>
						<Button>Hover over me</Button>
					</Popover>
				</Demo>
				<Demo
					title="Customize content"
					description="Contents can be anything"
				>
					<Popover
						title="Any content you want!"
						content={<DemoContent />}
					>
						<Button>Doesn&apos;t have to be just text...</Button>
					</Popover>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}

function DemoContent() {
	const [count, setCount] = useState(0);

	return (
		<div>
			<div>Count: {count}</div>
			<Button onClick={() => setCount(count + 1)}>Increment</Button>
		</div>
	);
}
