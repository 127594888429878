import { Switch } from "antd";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Typography, Demo, DemoSection, DemoWrapper } from "../index";
import styles from "./ToggleV2.module.scss";

type ToggleButtonProps = {
	/** The default active text to be shown the toggle is switched on */
	activeText?: string;
	/** The default inactive text to be shown when the toggle is switched off */
	inactiveText?: string;
	/** controls the size of the toggle [isLarge = false]*/
	isLarge?: boolean;
	/** The value to be shown after the onChange is called */
	value?: boolean;
	/** the function to be called when there's change in the toggle state */
	onChange?: (event: any) => void;
	/** className for style of the ToggleButton */
	className?: string;
	dataCy?: string;
};
/** The Toggle component is used for changing the state of system functionalities */
export default function ToggleButtonV2(props: ToggleButtonProps): JSX.Element {
	const activeRef = useRef<HTMLDivElement>(null);
	const inactiveRef = useRef<HTMLDivElement>(null);
	const [isChecked, setChecked] = useState(false);
	const [activeWidth, setActiveWidth] = useState(0);
	const [inactiveWidth, setInactiveWidth] = useState(0);

	function handleChange(event: any) {
		if (props.onChange) props.onChange(event);
		setChecked(!isChecked);
	}

	useEffect(() => {
		if (props.value !== undefined) {
			setChecked(props.value);
		}
	}, [props.value, isChecked]);

	useEffect(() => {
		if (activeRef.current?.offsetWidth) {
			// 32px for padding differences of left and right
			setActiveWidth(activeRef.current?.offsetWidth + 32);
		}
	}, [activeRef]);

	useEffect(() => {
		if (inactiveRef.current?.offsetWidth) {
			setInactiveWidth(inactiveRef.current?.offsetWidth + 32);
		}
	}, [inactiveRef]);

	function renderLargeToggle() {
		return (
			<div
				className={classNames(
					styles.genemodLargeToggle,
					props.className
				)}
			>
				<label className={styles.genemodToggleBackground}>
					<input
						id="switch-button"
						className={classNames(styles.genemodToggleCheckbox, {
							[styles.checked]: isChecked,
						})}
						type="checkbox"
						onClick={handleChange}
					/>
					<div
						className={styles.toggleSlider}
						style={{
							width: isChecked ? inactiveWidth : activeWidth,
							// 2px is for border differences
							left: isChecked ? activeWidth + 2 : 0,
						}}
						data-cy={`${props.dataCy}-toggle`}
					/>
					<div className={styles.toggleLabels}>
						<Typography
							ref={activeRef}
							className={classNames({
								[styles.inactiveLabel]: isChecked,
								[styles.activeLabel]: !isChecked,
							})}
							variant="label"
							color="text-secondary-v2"
						>
							{props.activeText}
						</Typography>
						<Typography
							ref={inactiveRef}
							className={classNames({
								[styles.activeLabel]: isChecked,
								[styles.inactiveLabel]: !isChecked,
							})}
							variant="label"
							color="text-secondary-v2"
						>
							{props.inactiveText}
						</Typography>
					</div>
				</label>
			</div>
		);
	}

	function renderSmallToggle() {
		const toggleText = isChecked ? props.activeText : props.inactiveText;
		return (
			<div
				className={classNames(
					styles.genemodSmallToggle,
					props.className
				)}
			>
				<Switch
					checked={isChecked}
					size="small"
					onClick={handleChange}
					data-cy={`${props.dataCy}-toggle`}
				/>
				{toggleText && (
					<Typography
						variant="label"
						className={styles.smallToggleText}
						color="text-secondary-v2"
					>
						{toggleText}
					</Typography>
				)}
			</div>
		);
	}

	return props.isLarge ? renderLargeToggle() : renderSmallToggle();
}

export function TOGGLE_V2_DEMO() {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Large Toggles"
					description="Large toggles with adjustable width and fixed heights"
				>
					<ToggleButtonV2
						isLarge
						activeText={"Active"}
						inactiveText={"Inactive"}
					/>
					<br />
					<br />
					<ToggleButtonV2
						isLarge
						activeText={"On"}
						inactiveText={"Off"}
					/>
					<br />
					<br />
					<ToggleButtonV2
						isLarge
						activeText={"SUPER LONG TEXT Button"}
						inactiveText={"SUPER LONG TEXT Button"}
					/>
				</Demo>
				<Demo
					title="Normal Buttons"
					description="Different Normal Sized Button Styles"
				>
					<ToggleButtonV2 activeText={"On"} inactiveText={"Off"} />
					<br />
					<br />
					<ToggleButtonV2
						activeText={"Active"}
						inactiveText={"Inactive"}
					/>
				</Demo>
				<Demo
					title="Edge cases"
					description="Toggle resizes to fit the longest text"
				>
					<ToggleButtonV2
						activeText="Loooooooooooooooong text"
						inactiveText="Short"
						isLarge
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
