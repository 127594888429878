import React, { useState } from "react";
import { DemoWrapper, DemoSection, Demo, Typography } from "..";
import { Button, Notification } from "@components";
import styles from "./StepProgressList.module.scss";
import cn from "classnames";
import { ColorCssVarMap } from "@common/styles/Colors";

type StepProgressListType = {
	steps: ProgressStep[];
	currentStep: number;
	setCurrentStep: (step: number) => void;
	/** the widht of the progressbar */
	progressBarWidth?: number;
	className?: string;
};
export type ProgressStep = {
	/** label of the progress step */
	label: string;
	/** content of the step */
	content: React.ReactNode;
};

/** StepProgressList component which is the Tabs but has progressbar on the Top*/
export function StepProgressList({
	steps,
	currentStep,
	setCurrentStep,
	progressBarWidth,
	className,
}: StepProgressListType): JSX.Element {
	const barWidth = progressBarWidth || "100%";
	return (
		<div className={cn(styles.container, className)}>
			<div
				className={styles.progressBarContainer}
				style={{ width: barWidth }}
			>
				{steps.map((step, index) => (
					<StepProgress
						key={step.label + index}
						label={step?.label}
						isCurrentStep={currentStep === index}
						isPrevStep={currentStep > index}
						onClick={() => setCurrentStep(index)}
					/>
				))}
			</div>
			<div
				style={{
					width: "100%",
					height: "calc(100% - 83px)",
				}}
			>
				{steps[currentStep]?.content}
			</div>
		</div>
	);
}

/** component of the progress step */
function StepProgress(props: {
	label: string;
	isCurrentStep?: boolean;
	isPrevStep?: boolean;
	onClick?: () => void;
}) {
	const defaultColor = ColorCssVarMap["text-tertiary"];
	const prevColor = ColorCssVarMap["accent-subtle"];
	const currentColor = ColorCssVarMap["accent-strong"];
	const stepColor = props.isCurrentStep
		? currentColor
		: props.isPrevStep
		? prevColor
		: defaultColor;
	return (
		<div
			className={cn(styles.StepProgressContainer, {
				[styles.StepProgressContainer__previous]: props.isPrevStep,
			})}
			style={{
				color: stepColor,
			}}
			onClick={props.onClick}
		>
			<Typography variant="caption">{props.label}</Typography>
			<div
				className={styles.StepProgressBar}
				style={{ backgroundColor: stepColor }}
			/>
		</div>
	);
}

export function STEP_PROGRESS_LIST_DEMO(): JSX.Element {
	const [currentStep, setCurrentStep] = useState(0);
	const steps = [
		{
			label: "1. Choose plan",
			content: (
				<div>
					<Typography>select your plan!</Typography>
					<Button onClick={() => setCurrentStep(1)}>Next</Button>
				</div>
			),
		},
		{
			label: "2. Subscription term",
			content: (
				<div>
					<Typography>Subscription term</Typography>
					<Typography>Subscription term</Typography>
					<Typography>Subscription term</Typography>
					<Typography>Subscription term</Typography>
					<Typography>Subscription term</Typography>
					<Button onClick={() => setCurrentStep(2)}>Continue</Button>
				</div>
			),
		},
		{
			label: "3. Payment",
			content: (
				<div>
					<Typography color="text-primary">Payment</Typography>
					<Typography color="text-secondary">Payment</Typography>
					<Typography color="text-tertiary">Payment</Typography>
					<Button onClick={() => setCurrentStep(3)}>
						Confirm purchase
					</Button>
				</div>
			),
		},
		{
			label: "4. Confirmation",
			content: (
				<div>
					<Typography color="text-primary">
						~~~Confirmation~~~
					</Typography>
					<Button
						onClick={() =>
							Notification.success({ message: "Done!" })
						}
					>
						Confirm purchase
					</Button>
				</div>
			),
		},
	];
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="StepProgressList Basic Uses"
					description="It is StepProgressList Component"
				>
					<StepProgressList
						steps={steps}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
