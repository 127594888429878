import { FreezerOrder } from "@common/types";
import { useHistory } from "react-router-dom";
import { useLinkUrl } from "./UseUrlLink";

/**
 * Helper hook to return function which move to create order page
 */

type Params = {
	fromBoxView?: boolean;
	fromConsumables?: boolean;
} | void;
export function useNavigateToCreateOrderPage(params: Params) {
	const getUrl = useLinkUrl();
	const history = useHistory();
	const pathname = getUrl("FREEZER_CREATE_ORDER");

	return (
		props:
			| { mode: "edit"; id: FreezerOrder["id"] }
			| { mode: "create"; prefill?: Partial<FreezerOrder> } = {
			mode: "create",
		}
	) => {
		if (props.mode === "edit") {
			history.push({
				pathname,
				search: new URLSearchParams({
					id: props.id + "",
				}).toString(),
			});
			return;
		}

		history.push({
			pathname,
			state: {
				prefill: props.prefill,
				fromBoxView: params ? params.fromBoxView : false,
				fromConsumables: params ? params.fromConsumables : false,
			},
		});
	};
}
