import {
	usePreferencesQuery,
	useUpdatePreferencesMutation,
} from "@redux/user/UserApi";
import { useCallback, useEffect, useState } from "react";
import {
	UserSettings,
	useUserSettingsDefault,
} from "../../types/UserPreference";
import { ValueOrGet, applyValueOrGet } from "@helpers/TypeHelpers";

export const DEFAULT_NUM_ITEMS_PER_PAGE = "25";

type UserSettingsKey = keyof UserSettings;

/*
 * Hook that can be used to store local storage values. It serializes values into a JSON object.
 * @param key - LocalStorageKey, if undefined, null, or empty string then nothing is set in storage
 * @param defaultValue - initial value to the local storage
 *
 * @return - current storedValue associated with the key and a function to update that value
 * */
export const usePreferences = <K extends UserSettingsKey>(key: K) => {
	type V = UserSettings[K];
	const { data: data } = usePreferencesQuery();
	const [updatePreferences] = useUpdatePreferencesMutation();
	const userSettingsDefault = useUserSettingsDefault();

	// Store the settings in state so that we can update them without having to wait for the API call to complete
	// Prevents race conditions and allows us to update the UI immediately
	const [settings, setSettings] = useState<UserSettings | undefined>(
		data?.settings
	);

	// initialize the preferences if they are not set
	useEffect(() => {
		if (!data || settings) return;
		setSettings(data.settings);
	}, [data]);

	const setValue = useCallback(
		(value: ValueOrGet<V>) => {
			setSettings((currentSettings) => {
				if (!currentSettings) return currentSettings;
				const settings = {
					...currentSettings,
					[key]: applyValueOrGet(value, currentSettings[key]),
				};
				updatePreferences({
					settings,
				});
				return settings;
			});
		},
		[updatePreferences, key, data]
	);

	const settingsWithDefaults = {
		...userSettingsDefault,
		...settings,
	};
	const value = settingsWithDefaults[key];

	return [value, setValue] as const;
};

/**
 * This hook will initialize the user's preferences with the default values if they are missing.
 */
export const usePreferenceInitializer = () => {
	const { data, isFetching: arePreferencesFetching } = usePreferencesQuery();
	const [updatePreferences, { isLoading: arePreferencesUpdating }] =
		useUpdatePreferencesMutation();
	const userSettingsDefault = useUserSettingsDefault();

	useEffect(() => {
		if (!data) return;
		if (arePreferencesFetching || arePreferencesUpdating) return;

		const dataKeys = new Set(Object.keys(data.settings));
		const defaults = userSettingsDefault;
		const defaultKeys = new Set(
			(Object.keys(defaults) as UserSettingsKey[]).filter(
				(k) => defaults[k] !== undefined && defaults[k] !== null
			)
		);
		const missingKeys = [...defaultKeys].filter(
			(k) => !dataKeys.has(k)
		) as UserSettingsKey[];

		if (missingKeys.length === 0) return;

		const missingDefaults = missingKeys.reduce(
			(map, key) => ({
				...map,
				[key]: defaults[key],
			}),
			{} as Partial<UserSettings>
		);
		const settings = {
			...data.settings,
			...missingDefaults,
		};
		updatePreferences({ settings });
	}, [data]);
};
