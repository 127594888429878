import React from "react";
import { GenemodIcon, Typography, ExploreButton } from "@components";
import styles from "./RestrictionGradientLayout.module.scss";

type RestrictionGradientLayoutProps = {
	title: JSX.Element;
	/** the hight of gradient part */
	gradientHeight?: number;
	/** hide Gradient part */
	hideGradient?: boolean;
};

export default function RestrictionGradientLayout({
	title,
	gradientHeight = 136,
	hideGradient = false,
}: RestrictionGradientLayoutProps): JSX.Element {
	return (
		<div>
			{!hideGradient && (
				<div
					className={styles.restrictionGradient}
					style={{
						height: gradientHeight,
						marginTop: -gradientHeight,
					}}
				/>
			)}
			<div>
				<GenemodIcon name="diamond" size="XLarge" color="brand-08" />
				<Typography variant="largeTitle" style={{ margin: "16px 0" }}>
					{title}
				</Typography>
				<Typography
					color="text-tertiary"
					style={{
						marginBottom: 24,
					}}
				>
					Contact us to explore flexible packaging options for your
					team.
				</Typography>
				<ExploreButton style={{ marginBottom: "48px" }} />
			</div>
		</div>
	);
}
