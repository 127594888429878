import { Avatar, UserId } from "@common/types/User";
import {
	useSearchNumericAndSet,
	useSearchParamAndSet,
	useSearchStringListAndSet,
} from "@helpers/URLParams";

export type SortByOptions<T extends string> = T | `-${T}`;

export type SimpleOrgUser = {
	id: number;
	user: Avatar;
};

export enum FilterType {
	RACK = 1,
	CATEGORY = 2,
}

/**
 * Search filter option from the backend
 */
export type SearchFilterOption = {
	name: string | Avatar | SimpleOrgUser | UserId;
	count: number;
	/** This property should be unique. It's usually the id of a persisted object. */
	value: number;
	// 0 for CATEGORY, 1 for RACK
	type?: FilterType;
};

export type StringSearchFilterOption = Omit<SearchFilterOption, "name"> & {
	name: string;
};

export type AvatarSearchFilterOption = Omit<SearchFilterOption, "name"> & {
	name: Avatar;
};

export type UserIdSearchFilterOption = Omit<SearchFilterOption, "name"> & {
	name: UserId;
};

export type FilterOptionsSearch = Record<string, SearchFilterOption[]>;

export type PaginatedSearchResults<
	T,
	F extends FilterOptionsSearch = FilterOptionsSearch
> = {
	/** Total number of results across all pages. */
	count: number;
	total_page_count: number;
	results: Array<T>;
	filter_options: F;
};

export type PaginatedSearchQuery = {
	search?: string;
	page: number;
	page_size: number;
	sort_by?: string;
};

export type SearchQuery = {
	search?: string;
	ordering?: string;
};

/**
 * A hook that standardizes encoding PaginatedSearchQuery into the url.
 *
 * Maybe extend this to encode filters?
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePaginatedSearchQueryUrlParams = () => {
	const [search, _setSearch] = useSearchParamAndSet("search");
	const [page, setPage] = useSearchNumericAndSet("page");
	const [page_size, setPageSize] = useSearchNumericAndSet("page_size");
	const [sort_by, setSortBy] = useSearchParamAndSet("sort_by");
	const [status, setStatus] = useSearchStringListAndSet("status");

	const setSearch: typeof _setSearch = (arg, replaceNotPushHistory) => {
		_setSearch(arg, replaceNotPushHistory);
		setPage(1);
	};

	const handleEmptyString =
		(fn: (arg: string | null, replaceNotPushHistory?: boolean) => void) =>
		(arg: string | null, replaceNotPushHistory?: boolean) => {
			if (arg === "") {
				arg = null;
			}
			fn(arg, replaceNotPushHistory);
		};

	const values = {
		search: search || "",
		page: page || 1,
		page_size: page_size || 25,
		sort_by: sort_by || undefined,
		status,
	} as const;
	const setters = {
		setSearch: handleEmptyString(setSearch),
		setPage,
		setPageSize,
		setSortBy: handleEmptyString(setSortBy),
		setStatus,
	} as const;
	return {
		...values,
		...setters,
		values,
		setters,
		clearAll: () => {
			setSearch(null);
			setPage(null);
			setPageSize(null);
			setSortBy(null);
			setStatus([]);
		},
	} as const;
};

/**
 * Helps to transformResponse in an api definition
 */
export const searchResultsFromDtoMapper =
	<DTO, T>(mapper: (dto: DTO) => T) =>
	(blob: PaginatedSearchResults<DTO>): PaginatedSearchResults<T> => ({
		...blob,
		results: blob.results.map(mapper),
	});
