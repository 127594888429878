import React, { useEffect, useState } from "react";
import {
	GenemodIcon,
	Input,
	InputV2,
	Modal,
	Select,
	SelectV2,
	TextArea,
	Typography,
} from "@common/components";
import ItemTypeSelect from "@containers/Freezer/components/ItemTypeSelect/ItemTypeSelect";
import { nameKeysOfConst, NamesInConstType } from "@helpers/TypeHelpers";
import "./AddItemGroupModal.scss";
import {
	Freezer,
	Box,
	ITEM_GROUP_CONTAINERS,
	ItemGroupDataForBackend,
	ItemGroup,
	ITEM_STATUS,
	Category,
} from "@common/types";
import { useDebounceEventHandler } from "@helpers/Hooks";
import { useGetRepositoryCategoriesQuery } from "@redux/inventory/Category";
import InputV3 from "@common/components/InputV3/InputV3";

type AddItemGroupModalProps = {
	visible: boolean;
	defaultItemType: Freezer["default_item_type"];
	onConfirm: (itemGroup: Partial<ItemGroup>) => void;
	onCancel: () => void;
	isRepository?: boolean;
};

/** Modal component to create new Item Group in category */
export default function AddItemGroupModal(
	props: AddItemGroupModalProps
): JSX.Element {
	const NewItemGroup = {
		id: -1,
		name: "",
		item_type: props.defaultItemType,
	};
	const [itemGroup, setItemGroup] = useState<Partial<ItemGroup>>({
		...NewItemGroup,
	});
	// if false, show placeholder on container selector
	const [isContainerTypeDefined, setIsContainerTypeDefined] = useState(false);
	const [showErrors, setShowErrors] = useState(false);
	const [category, setCategory] = useState<Category | null>(null);

	useEffect(() => {
		setItemGroup({ ...NewItemGroup });
		setIsContainerTypeDefined(false);
		setShowErrors(false);
		setCategory(null);
	}, [props.visible]);

	const handleContainerType = (option: number) => {
		setIsContainerTypeDefined(true);
		handleChange("container_type", option);
	};

	const handleChange = (field: string, value: string | number) => {
		// Make copy so we don't change values in the state without calling setState
		const temp = { ...itemGroup };
		// Set value on the copy
		(temp as any)[field] = value;
		// Now update the state w/ the new copy
		setItemGroup(temp);
	};

	const handleSubmit = () => {
		setShowErrors(true);
		if (validateForm()) {
			const temp = { ...itemGroup };
			temp.name = temp.name?.trim();
			if (props.isRepository) {
				if (category) {
					(temp as ItemGroupDataForBackend).location = {
						category: category.id,
						rack_location: null,
					};
				}
			}
			props.onConfirm(temp);
		}
	};

	const validateForm = () => {
		return !!itemGroup.name?.trim().length && isContainerTypeDefined;
	};

	return (
		<Modal
			visible={props.visible}
			title={"New item group"}
			hideCancelButton
			okText={"Create group"}
			onOk={handleSubmit}
			onCancel={props.onCancel}
			className="item-group-modal"
			subtitle={"The following information will apply to all sub-items."}
			okButtonProps={{
				disabled: !validateForm(),
				style: {
					pointerEvents: "visible",
				},
			}}
			dataCy="add-item-group"
		>
			<InputV3
				autoFocus
				value={itemGroup.name}
				label="Group name *"
				labelColor="text-primary-v2"
				onChange={(e) => handleChange("name", e.target.value)}
				placeholder={"What's the item?"}
				errorMessage={
					!itemGroup.name && showErrors ? "Required" : undefined
				}
				dataCy="add-item-group-name"
				className="inputName"
			/>
			<div
				className="itemGroupType"
				style={{ display: "flex", flexDirection: "column" }}
			>
				<Typography
					variant="label"
					semibold
					color="text-primary-v2"
					style={{ marginBottom: 4 }}
				>
					Item type
				</Typography>
				<ItemTypeSelect
					value={itemGroup?.item_type as number}
					onSelect={(option) => handleChange("item_type", option)}
					dataCy="add-item-group-select-type"
					useBodyColor
				/>
				<div
					style={{
						display: "flex",
						marginTop: "32px",
					}}
				>
					<div className="containerType">
						<Typography
							variant="label"
							semibold
							color="text-primary-v2"
							style={{ marginBottom: 4 }}
						>
							Container *
						</Typography>
						<SelectV2
							placeholder="Choose"
							value={
								isContainerTypeDefined
									? itemGroup.container_type
									: undefined
							}
							onSelect={(option) =>
								handleContainerType(option as number)
							}
							style={{ width: "100%" }}
							error={
								!isContainerTypeDefined && showErrors
									? "Required"
									: undefined
							}
							dataCy="add-item-group-select-container"
						>
							{nameKeysOfConst(ITEM_GROUP_CONTAINERS)
								.filter((key) => key !== "DEFAULT")
								.map((key) => {
									const containerName =
										key as NamesInConstType<
											typeof ITEM_GROUP_CONTAINERS
										>;
									const val =
										ITEM_GROUP_CONTAINERS[containerName];
									return (
										<Select.Option
											key={val}
											value={val}
											data-cy={`add-item-group-select-container-${val}`}
										>
											{ITEM_GROUP_CONTAINERS[val]}
										</Select.Option>
									);
								})}
						</SelectV2>
					</div>
					<div
						className="containerVolumns"
						style={{
							display: "flex",
							flexDirection: "column",
							width: "38%",
						}}
					>
						<InputV3
							value={itemGroup?.container_volume}
							onChange={(e) =>
								handleChange("container_volume", e.target.value)
							}
							label="Container volume"
							labelColor="text-primary-v2"
							placeholder="e.g. 10mL"
							dataCy="add-item-group-volume"
						/>
					</div>
				</div>
				<TextArea
					label="Description"
					value={itemGroup.description}
					onChange={(e) =>
						handleChange("description", e.target.value)
					}
					placeholder={"Keep everyone on the same page..."}
					style={{ minHeight: 80 }}
					gutterBottom={false}
					dataCy="add-item-group-description"
				/>
				{props.isRepository && (
					<CategorySelect
						category={category}
						setCategory={setCategory}
					/>
				)}
			</div>
		</Modal>
	);
}

function CategorySelect({
	category,
	setCategory,
}: {
	category: Category | null;
	setCategory: (value: Category | null) => void;
}) {
	const [termSearch, setTermSearch] = useState<string>("");
	// const { setHasErrors } = useContext(ErrorsContext);
	const onSetConsumableSearch = useDebounceEventHandler((value: string) => {
		setTermSearch(value);
	}, 350);
	const { data: categoriesData } = useGetRepositoryCategoriesQuery(
		{
			page: 1,
			page_size: 10,
			search: termSearch?.startsWith("#") ? undefined : termSearch,
			ordering: "-updated_at",
			status__in: [ITEM_STATUS.ACTIVE],
		},
		{ refetchOnMountOrArgChange: true }
	);

	return (
		<>
			<div
				style={{
					marginTop: "24px",
					gridColumn: "first-half-start / second-half-end",
				}}
			>
				<Typography
					variant="label"
					bold
					style={{
						marginBottom: "8px",
						fontFamily: "var(--text-primary)",
					}}
				>
					Location
				</Typography>
				<Select
					className="locationSelect"
					allowClear
					clearIcon={
						<GenemodIcon
							name="exit"
							size="small"
							onClick={() => {
								setCategory(null);
							}}
						/>
					}
					isInput
					showSearch
					value={category?.name || termSearch || undefined}
					// handleFormData(newProps.key, e.target.value);
					onSelect={(n) => {
						const [idStr] = (n as string).split("-");
						const selectedId = parseInt(idStr, 10);
						const categoryFinded = categoriesData?.results.find(
							(category) => category.id === selectedId
						);

						if (categoryFinded) {
							setCategory(categoryFinded as any as Category);
						}
					}}
					notFoundContent={
						<div>
							<span>Category not found</span>
						</div>
					}
					onSearch={(value) => onSetConsumableSearch(value)}
					placeholder="Search a parent category"
					dataCy="create-item-box-select"
				>
					{categoriesData?.results.map((category) => {
						const option = category as any;
						const { freezer_name, shelf_name } =
							option.location_data;
						return (
							<Select.Option
								key={option.id}
								value={`${option.id}-${option.name}`}
								className="boxSelectItem"
								style={{
									padding: "4px 16px",
								}}
							>
								<div>
									<div className="boxNameContainer">
										<span
											style={{
												color: "var(--text-secondary)",
											}}
										>
											{option.name}
										</span>
									</div>
									{(freezer_name || shelf_name) && (
										<Typography
											color="text-tertiary"
											variant="caption"
										>
											{freezer_name
												? `${freezer_name} > ${shelf_name}`
												: shelf_name}
										</Typography>
									)}
								</div>
							</Select.Option>
						);
					})}
				</Select>
			</div>
		</>
	);
}
