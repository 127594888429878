import dayjs from "dayjs";
import { Typography, UserAvatar } from "@common/components";
import { Event, EventType } from "@common/types";
import { capitalizeFirstLetter } from "@helpers/Formatters";
import { useEventHelpers } from "@helpers/Hooks/useEventHelpersHook";
import moment from "moment";
import React, { HTMLProps } from "react";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import styles from "./EventLogCard.module.scss";
import classNames from "classnames";
import { UserAvatarSizes } from "../UserAvatar/UserAvatar";

type Props = {
	event: Event;
	avatarSize?: UserAvatarSizes;
	cursor?: "pointer" | "default";
} & HTMLProps<HTMLDivElement>;

const EventLogCard = ({
	event,
	avatarSize = UserAvatarSizes.M,
	cursor = "default",
	...props
}: Props): JSX.Element => {
	const { getEventLink, getEventUserName, getEventActivity, action } =
		useEventHelpers(event);
	const { timestamp, object_name, user } = event;
	const eventLink = getEventLink();
	const renderTime =
		moment.duration(moment().diff(moment(timestamp))).asHours() >= 1;
	const isDeleteAction = action === EventType.DELETE;
	const isLink =
		eventLink &&
		!isDeleteAction &&
		!props.onClick &&
		!["archived", "restored"].includes(event.actions);
	const hasClick = !!props.onClick;

	const content = (
		<div
			{...props}
			className={classNames(styles.main, {
				[styles.pointerStyle]: cursor === "pointer" && !isDeleteAction,
			})}
		>
			<div className={styles.avatarWrapper}>
				<UserAvatar user={user} size={avatarSize} />
			</div>
			<div style={{ width: "calc(100% - 32px - 16px)" }}>
				<div className={styles.activity}>
					<Typography color="text-secondary-v2" variant="body">
						{`${getEventUserName()} ${getEventActivity()} `}
						<Typography
							className={styles.objectName}
							variant="body"
							color={
								!isLink && !hasClick ? "none" : "link-primary"
							}
						>
							{object_name}
						</Typography>
					</Typography>
				</div>
				<Typography variant="body2" color="text-tertiary-v2">
					{moment(timestamp).format("LL")}{" "}
					{renderTime && `at ${dayjs(timestamp).format("h:mm A")} `}
				</Typography>
			</div>
		</div>
	);

	if (!isLink) {
		return content;
	}
	return <Link to={eventLink}>{content}</Link>;
};

export default EventLogCard;
