import { BaseEditor } from "slate";
import { RenderElementProps } from "slate-react";
import { CustomEditor } from "../types";

/**
 * This is a helper function that allows us to override a function on the editor.
 */
export const overrideFunction =
	<T extends BaseEditor>(editor: T) =>
	<K extends keyof T>(fnName: K) =>
	(getNewFn: (oldFn: T[K]) => T[K]): T => {
		const oldFn = editor[fnName];
		editor[fnName] = getNewFn(oldFn);
		return editor;
	};

export type RegisteredRenderElementsEditor = {
	registeredRenderElements?: {
		[key: string]: (props: RenderElementProps) => React.ReactNode;
	};
};

/**
 * This is a helper function that allows us to override a function on the editor.
 */
export const registerRenderElements =
	<T extends RegisteredRenderElementsEditor>(editor: T) =>
	(typeMatch: string) =>
	(render: (props: RenderElementProps) => React.ReactNode) => {
		// create a mapping on the editor for the type and render, call the map "regsisteredRenderElements"
		// if the editor doesn't have a "registeredRenderElements" property, create it
		if (!editor.registeredRenderElements) {
			editor.registeredRenderElements = {};
		}
		// add the type and render to the map
		editor.registeredRenderElements[typeMatch] = render;
		return editor;
	};

/**
 * This function is used to override the renderElements function on the editor.
 */
export const withRegisteredRenderElements = <T extends CustomEditor>(
	editor: T
) => {
	editor.renderElements;
	// override the renderElements function
	overrideFunction(editor)("renderElements")((oldFn) => (props) => {
		// if the editor has a "registeredRenderElements" property
		if (editor.registeredRenderElements) {
			// get the type of the element
			const { type } = props.element;
			// get the render function for the type
			const render = editor.registeredRenderElements[type as string];
			// if there is a render function for the type, call it
			if (render) {
				return render(props) as JSX.Element;
			}
		}
		// otherwise, call the old render function
		return oldFn(props);
	});
	return editor;
};
