import React, { useState } from "react";
import { GenemodIcon, Modal, Typography } from "@components";
import { useSafeWindowEventListener } from "@helpers/Hooks";

const ConnectionLostModal: React.FunctionComponent = () => {
	const [isOnline, setIsOnline] = useState(window.navigator.onLine);
	const [forceHide, setForceHide] = useState(false);
	useSafeWindowEventListener("online", () => setIsOnline(true));
	useSafeWindowEventListener("offline", () => setIsOnline(false));

	return (
		<>
			<GenemodIcon name="exit" style={{ display: "none" }} />
			<Modal
				visible={!isOnline && !forceHide}
				title="Connection lost"
				okText="Got it"
				hideCancelButton
				onCancel={() => setForceHide(true)}
				onOk={() => setForceHide(true)}
			>
				<Typography>
					Your Internet connection appears to be offline.
				</Typography>
			</Modal>
		</>
	);
};

export default ConnectionLostModal;
