import { useSurveyNotification } from "@common/components/SurveyNotification/useSurveyNotification";
import { useParams } from "@common/helpers/Hooks/UseRouterDom";
import { CellId, ISOString, Item, MoveItem } from "@common/types";
import {
	getLocalStorageItem,
	setLocalStorageItem,
} from "@helpers/Hooks/UseLocalStorageHook";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { TempAttachmentsContext } from "../BoxView/BoxView";
import {
	getAreaCellIdsFromSelection,
	getAreaDimensions,
	getCellId,
	getCellIdsForArea,
} from "../data";

import { GridCoord } from "@common/components/InteractiveGrid/GridTypes";
import { SafeLeavingGuardContext } from "@common/context/SafeLeavingGuardContext";
import { useSearchNumericAndSet } from "@common/helpers/URLParams";
import { Button, Notification } from "@components";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { flattenUnion } from "@helpers/TypeHelpers";
import {
	getBoxTableState,
	useBoxTableActions,
} from "@redux/freezer/BoxTableSlice";
import { useItemAttachmentCreateMutation } from "@redux/freezer/FreezerApiSlice";
import {
	useAddItemBookmarkMutation,
	useRemoveItemBookmarkMutation,
} from "@redux/inventory/Bookmark";
import { useBoxQuery, useLazyBoxQuery } from "@redux/inventory/Box";
import { useFreezerQuery } from "@redux/inventory/Freezer";
import {
	useBoxItemsQuery,
	useItemCreateMutation,
	useItemGroupItemsQuery,
	useItemPatchMutation,
	useItemQuery,
	useMoveItemLocationMutation,
	usePasteItemsMutation,
} from "@redux/inventory/Item";
import { useItemGroupQuery } from "@redux/inventory/ItemGroup";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useOrganizationRouter } from "@root/AppRouter";
import { ACCOUNT_SETTINGS_PATHS } from "@root/routes";
import { nanoid } from "nanoid";
import { useHistory } from "react-router-dom";

export const useSetCellId = () => {
	const { showNotification } = useSurveyNotification();
	const { items, setItemIdInUrl } = useBoxView();
	const { doNotUse_setCellId } = useBoxTableActions();

	return useCallback(
		(
			newCellId: CellId | null,
			toggle: "TOGGLE" | "SET" = "TOGGLE"
		): void => {
			const isSameCell = newCellId === getBoxTableState().cellId;
			if (toggle === "TOGGLE" && isSameCell) {
				doNotUse_setCellId(null);
				setItemIdInUrl(null);
				return;
			}
			doNotUse_setCellId(newCellId);

			// Update the item id in the url if there is an item occupying this new cell id
			const item = items.find((item) => getCellId(item) === newCellId);
			setItemIdInUrl(item?.id || null);

			if (!isSameCell) {
				showNotification();
			}
		},
		[showNotification, setItemIdInUrl, items, doNotUse_setCellId]
	);
};

const CLIPBOARD_TIMEOUT = 1000 * 60 * 60;

const CLIPBOARD_MANAGER = {
	getClipboard: () => {
		const { lastUpdated, cells, items } = getLocalStorageItem(
			"BOX_CELL_CLIPBOARD",
			{
				lastUpdated: new Date().toISOString() as ISOString,
				cells: [] as CellId[],
				items: {} as Record<CellId, Item>,
			},
			false
		);

		const now = new Date().getTime();
		const lastUpdatedTime = new Date(lastUpdated).getTime();
		const isClipboardTimedOut = now - lastUpdatedTime > CLIPBOARD_TIMEOUT;

		let newCells = cells;
		let newItems = items;

		if (isClipboardTimedOut) {
			newCells = [];
			newItems = {};
		}
		// refresh last updated
		CLIPBOARD_MANAGER.setClipboard(newCells, newItems);
		return { cells: newCells, items: newItems } as const;
	},
	setClipboard: (cells: CellId[], items: Record<CellId, Item>) => {
		setLocalStorageItem(
			"BOX_CELL_CLIPBOARD",
			{
				cells,
				items,
				lastUpdated: new Date().toISOString(),
			},
			false
		);
	},
};

/**
 * Handles the clipboard for the box items.
 * Saves the copied cells and item data to local storage so we can copy/paste
 * between boxes/tabs
 */
export const getClipboardManager = () => CLIPBOARD_MANAGER;

export const useBoxView = () => {
	const { resetActivityLogKey } = useContext(BoxActivityLogKeyContext);
	const { freezer_id, box_id } = useParams<{
		freezer_id: string;
		box_id: string;
	}>();

	const { data: freezer, isLoading: isFreezerLoaindg } = useFreezerQuery(
		Number(freezer_id) || skipToken
	);
	const { data: box, isLoading: isBoxLoading } = useBoxQuery(
		Number(box_id) || skipToken
	);
	const {
		data: items = [],
		isLoading: itemsLoading,
		isFetching: itemsFetching,
		refetch: refetchItems,
		isUninitialized: isItemsUninitialized,
	} = useBoxItemsQuery(
		box_id
			? {
					id: Number(box_id),
			  }
			: skipToken
	);

	const [itemIdInUrl, _setItemIdInUrl] = useSearchNumericAndSet("item_id");
	const setItemIdInUrl = useCallback(
		(itemId: number | null, rnp?: boolean) => {
			_setItemIdInUrl(itemId, rnp);
		},
		[_setItemIdInUrl]
	);

	const memoizedBox = useMemo(() => box, [box]);
	const memoizedBoxItems = useMemo(() => items, [items]);

	useEffect(() => {
		if (memoizedBox && memoizedBoxItems.length) {
			resetActivityLogKey();
		}
	}, [memoizedBox, memoizedBoxItems]);

	const {
		data: item,
		isLoading: isItemLoading,
		isFetching: isItemFetching,
	} = useItemQuery(itemIdInUrl || skipToken, {
		refetchOnMountOrArgChange: true,
	});

	const viewOnly = isBoxLoading || box?.is_archived || false;

	return {
		freezer,
		box,
		viewOnly,
		items,
		item,
		isFreezerLoaindg,
		isBoxLoading,
		isItemsLoading: itemsLoading || itemsFetching,
		isItemLoading,
		isItemFetching,
		isItemsUninitialized,
		itemIdInUrl,
		setItemIdInUrl,
		refetchItems,
	};
};

export const useItemGroup = () => {
	const { resetActivityLogKey } = useContext(BoxActivityLogKeyContext);
	const { freezer_id, item_group_id } = useParams<{
		freezer_id: string;
		item_group_id: string;
	}>();

	const { data: freezer, isLoading: isFreezerLoading } = useFreezerQuery(
		Number(freezer_id) || skipToken
	);

	const { data: item_group, isLoading: isItemGroupLoading } =
		useItemGroupQuery(Number(item_group_id) || skipToken);

	const {
		data: items = [],
		isLoading: itemsLoading,
		isFetching: itemsFetching,
	} = useItemGroupItemsQuery(
		item_group_id
			? {
					id: Number(item_group_id),
					is_archived: freezer?.is_archived,
			  }
			: skipToken
	);

	const [itemIdInUrl, _setItemIdInUrl] = useSearchNumericAndSet("item_id");
	const setItemIdInUrl = useCallback(
		(itemId: number | null, rnp?: boolean) => {
			_setItemIdInUrl(itemId, rnp);
		},
		[_setItemIdInUrl]
	);

	const memoizedItemGroup = useMemo(() => item_group, [item_group]);
	const memoizedItemGroupItems = useMemo(() => items, [items]);

	useEffect(() => {
		if (memoizedItemGroup && memoizedItemGroupItems.length) {
			resetActivityLogKey();
		}
	}, [memoizedItemGroup, memoizedItemGroupItems]);

	const {
		data: item,
		isLoading: isItemLoading,
		isFetching: isItemFetching,
	} = useItemQuery(itemIdInUrl || skipToken);

	const viewOnly = isItemGroupLoading || freezer?.is_archived || false;

	return {
		freezer,
		item_group,
		viewOnly,
		items,
		item,
		isFreezerLoading,
		isItemGroupLoading,
		isItemsLoading: itemsLoading || itemsFetching,
		isItemLoading,
		isItemFetching,
		itemIdInUrl,
		setItemIdInUrl,
	};
};

/**
 * Selection Manager options
 * @typedef {object} SelectionManagerOptions
 * @prop {filterSelection} [filterSelection] Filters cells before setting the selection
 */

/**
 * Filter cells in the selection
 * @callback filterSelection
 * @param {string[]} selection Raw selection
 * @returns {string[]} Filtered selection
 */

/**
 * Table cell selection manager hook
 *
 * @param {SelectionManagerOptions} options Options for selection manager
 */
function useSelectionManager(options: any) {
	const [selection, _setSelection] = useState<any[]>([]);
	const setSelection = (cells: string[]) => {
		let temp = [...cells];
		if (options.filterSelection) {
			temp = options.filterSelection(cells);
		}

		// Remove duplicates
		temp = temp.reduce((acc: string[], val) => {
			if (!acc.includes(val)) {
				acc.push(val);
			}
			return acc;
		}, []);
		_setSelection(temp);
		return temp;
	};

	return {
		/**
		 * The current selection
		 */
		selection,

		/**
		 * Replaces current selection with new selection
		 * @param {string[]} cells Array of cells to select
		 */
		replaceSelection(cells: string[]) {
			return setSelection([...cells]);
		},

		/**
		 * Adds cells to the current selection
		 * @param {string[]} cells
		 */
		appendCells(cells: string[]) {
			const temp = [...selection];
			cells.forEach((cell) => {
				if (!temp.includes(cell)) {
					temp.push(cell);
				}
			});
			setSelection(temp);
		},

		/**
		 * Removes cell from the selection
		 * @param {string[]} cells
		 */
		removeCells(cells: string[]) {
			setSelection(
				selection.filter((cell) => {
					return !cells.includes(cell);
				})
			);
		},

		/**
		 * If cell is in selection, removes it. Otherwise
		 * adds the cell to the selection
		 * @param {string} cell
		 */
		toggleCell(cell: string) {
			const method = this.selection.includes(cell)
				? "removeCells"
				: "appendCells";
			this[method]([cell]);
		},

		/**
		 * Clears selection
		 */
		clearSelection() {
			setSelection([]);
		},
	};
}

/**
 * useTableSelection options
 * @typedef {Object} TableSelectionOptions
 * @prop {filterSelection} [filterSelection=null]
 * Called as selection changes.
 * @prop {boolean} [ignoreSelection=false]
 * If true, ignores all pointer events
 */

/**
 * Object returned from useTableSelection
 * @typedef {object} TableSelectionReturn
 * @prop {string[]} selectedCells
 * Array of cells in the final selection after the user
 * finishes their selection
 * @prop {string[]} tempCells
 * Array of cells in the temporary selection while the user
 * is in the process of selecting
 * @prop {object} cellProps Props to be spread onto table cells
 * @prop {string} focusedCell Last cell that was clicked on
 * @prop {function} setSelection Sets the selection
 */

/** Hook for managing cell selections
 * @function
 * @prereq
 * Table cells MUST have a unique ID corresponding to their cell location.
 * Cell IDs must be from A1 to L12
 *
 * @param {TableSelectionOptions} [options] See useTableSelectionOptions
 * @returns {TableSelectionReturn} TableSelectionReturn object
 */
export function useTableSelection(options: any) {
	const selectionManager = useSelectionManager({
		filterSelection: options.filterSelection,
	});
	// After use finishes selection
	const [finalSelection, setFinalSelection] = useState<any>([]);
	const [focusedCell, setFocus] = useState("");
	const [isDragging, setDragging] = useState(false);
	const [initSelection, setInitSelection] = useState<any[]>([]);
	const [modifiers, setModifiers] = useState({
		current: {
			ctrl: false,
			shift: false,
		},
		dragStart: {
			ctrl: false,
			shift: false,
		},
	});
	const [lastCell, setLastCell] = useState("");

	useEffect(() => {
		if (options.ignoreSelection) {
			setDragging(false);
		}
	}, [options.ignoreSelection, isDragging]);

	const onMouseDown = (e: any) => {
		const cellId = e.target.id;

		if (!focusedCell || !modifiers.current.shift) {
			// Focus cell should not change on shift key
			setFocus(cellId);
		}
		setLastCell(cellId);
		setDragging(true);

		// Save modifiers and initial cells
		setInitSelection([...selectionManager.selection]);
		setModifiers({ ...modifiers, dragStart: { ...modifiers.current } });

		if (!selectionManager.selection.includes(cellId)) {
			const newSelection = [...selectionManager.selection, cellId];
			selectionManager.replaceSelection(newSelection);
			setFinalSelection(newSelection); // Immediate state update
		}
	};

	// Can only remove if NOT shift clicking, IS ctrl clicking,
	// and start cell is selected
	const isRemoving =
		!modifiers.dragStart.shift &&
		initSelection.includes(focusedCell) &&
		modifiers.dragStart.ctrl;

	const onMouseUp = () => {
		if (isDragging) {
			setDragging(false);
			if (options.ignoreSelection) return;
			const area = getAreaCellIdsFromSelection([
				focusedCell as CellId,
				lastCell as CellId,
			]);
			let temp: any[] = [];
			if (modifiers.dragStart.ctrl) {
				temp = temp.concat(initSelection);
			}

			if (isRemoving) {
				temp = temp.filter((x) => !area.includes(x));
			} else {
				temp = temp.concat(area);
			}
			setFinalSelection(selectionManager.replaceSelection(temp));
		}
	};

	const onMouseEnter = (e: any) => {
		if (isDragging) {
			setLastCell(e.target.id);
			const currentCell = e.target.id;
			let selection: any[] = [];
			const area = getAreaCellIdsFromSelection([
				focusedCell,
				currentCell,
			]);
			if (modifiers.dragStart.ctrl) {
				selection = selection.concat(initSelection);
			}

			if (isRemoving) {
				selection = selection.filter((x) => !area.includes(x));
			} else {
				selection = selection.concat(area);
			}

			selectionManager.replaceSelection(selection);
		}
	};
	// Listen to keyboard for ctrl and shift
	useEffect(() => {
		const handleKeys = (e: any) => {
			setModifiers({
				...modifiers,
				current: {
					ctrl: e.ctrlKey || e.metaKey,
					shift: e.shiftKey,
				},
			});
			if (e.key === "Escape") {
				setInitSelection([]);
				setFinalSelection([]);
				setLastCell("");
				setFocus("");
				selectionManager.clearSelection();
			}
		};
		document.addEventListener("keydown", handleKeys);
		document.addEventListener("keyup", handleKeys);
		document.addEventListener("mouseup", onMouseUp);
		return () => {
			document.removeEventListener("keydown", handleKeys);
			document.removeEventListener("keyup", handleKeys);
			document.removeEventListener("mouseup", onMouseUp);
		};
	});

	// Props for each table cell
	return {
		tempCells: selectionManager.selection,
		selectedCells: finalSelection,
		focusedCell,
		setSelection: (selection: any) => {
			selectionManager.replaceSelection(selection);
			setFinalSelection(selection);
		},
		cellProps: options.ignoreSelection
			? {}
			: {
					onMouseDown,
					onMouseEnter,
			  },
	};
}

/**
 * handle Add item or Edit Item
 */
export const useHandleAddOrEditItem = () => {
	const [createItem] = useItemCreateMutation();
	const [updateItem] = useItemPatchMutation();
	const [addItemAttachment] = useItemAttachmentCreateMutation();
	const { box, setItemIdInUrl } = useBoxView();
	const { setIsFocused } = useBoxTableActions();

	const { tempAttachments, setTempAttachments } = useContext(
		TempAttachmentsContext
	);

	return useCallback(
		(
			item: Item,
			callback?: (arg0: boolean, arg1: Item | Error) => void
		) => {
			if (!box) return;
			const addItemComplete = (
				item: Item,
				callback?: (arg0: boolean, arg1: Item | Error) => void
			) => {
				setIsFocused(true);
				setItemIdInUrl(item.id);

				if (callback) {
					callback(true, item);
				}
				setTempAttachments([]);
			};
			const isCreating = isNaN(item.id) || item.id < 0;
			const promise = isCreating
				? createItem({ boxId: box.id, item })
				: updateItem(item);
			promise
				.unwrap()
				.then((item) => {
					// handle temp attachments creation if there are any
					if (isCreating && tempAttachments.length) {
						const promises = tempAttachments.map((file) =>
							// NOTE: Since we are doing multiple requests here, there is a chance that one of the requests might be failing due to SQLite not being able to handle multiple request sometimes (database lock)
							addItemAttachment({
								itemId: item.id || -1,
								data: file?.formData || new FormData(),
							})
						);

						Promise.all(promises)
							.then(() => {
								addItemComplete(item, callback);
								setTempAttachments([]);
							})
							.catch((error) => {
								console.log(error);
							});
					} else {
						addItemComplete(item, callback);
					}
				})
				.catch((err) => {
					if (callback) {
						callback(false, err);
					}
					Notification.warning({
						message: `Failed to ${
							isCreating ? "create" : "update"
						} a item.`,
					});
				});
		},
		[
			box,
			createItem,
			setIsFocused,
			tempAttachments,
			updateItem,
			addItemAttachment,
			setTempAttachments,
		]
	);
};

/**
 * Hook to handle bookmark click
 */
export const useBookmarkToggleClick = () => {
	const [createBookmark] = useAddItemBookmarkMutation();
	const [deleteBookmark] = useRemoveItemBookmarkMutation();

	/**
	 * For updating the bookmark state of an item
	 */
	return useCallback(({ id, is_bookmarked }: Item) => {
		const promise = is_bookmarked ? deleteBookmark(id) : createBookmark(id);
		promise
			.unwrap()
			.then(() =>
				Notification.success({
					message: `Bookmark has been ${
						is_bookmarked ? "removed" : "added"
					}`,
				})
			)
			.catch(() =>
				Notification.warning({
					message: `Failed to ${
						is_bookmarked ? "remove" : "add"
					} bookmark.`,
				})
			);
	}, []);
};

// Checks if an item in the target area exists
export const useCheckOverlap = () => {
	const { items } = useBoxView();

	const checkOverlap = useCallback(
		(cells: CellId[], source: CellId, checkSingleCells = true) => {
			const { selectedAreaBounds } = getBoxTableState();
			const dims = getAreaDimensions(cells);
			let targetCells = getCellIdsForArea({
				...dims,
				source,
			});

			// pasting single cell
			if (cells.length === 1 && checkSingleCells) {
				targetCells = getCellIdsForArea(selectedAreaBounds[0]);
			}

			return targetCells.some((targetCell) => {
				const targetArr = items.filter(
					(item: Item) => getCellId(item) === targetCell
				);
				return !!targetArr.length;
			});
		},
		[items]
	);

	return checkOverlap;
};

type CopySelectedArgs = {
	cutNotCopy: boolean;
	focusModeOnly?: boolean;
};
const copySelected = (
	{ cutNotCopy, focusModeOnly = true }: CopySelectedArgs,
	items: Item[],
	setCutMode: any
): void => {
	const { isFocused, selectedAreaBounds } = getBoxTableState();
	if (focusModeOnly && !isFocused) return;
	// Don't allow multiple selections b/c behavior undecided
	if (selectedAreaBounds?.length > 1) {
		Notification.warning({
			message: `Invalid action. Cannot ${
				cutNotCopy ? "cut" : "copy"
			} multiple selections`,
		});
		return;
	}
	const selectedCells = getCellIdsForArea(selectedAreaBounds[0]);
	const cellItemMapping = selectedCells.reduce((obj, cell) => {
		const item = items.find((item) => getCellId(item) === cell);
		if (item) {
			return {
				...obj,
				[cell]: item,
			};
		}
		return obj;
	}, {} as Record<CellId, Item>);
	CLIPBOARD_MANAGER.setClipboard([...selectedCells], cellItemMapping);
	setCutMode(cutNotCopy);
};
// Copies all the selected cells into the clilpboard
export const useCopySelected = () => {
	const { items } = useBoxView();
	const { setCutMode, setItemsCut, clearItemsCut } = useBoxTableActions();

	const copySelected = useCallback(
		async ({
			cutNotCopy,
			focusModeOnly = true,
		}: {
			cutNotCopy: boolean;
			focusModeOnly?: boolean;
		}) => {
			const { isFocused, selectedAreaBounds } = getBoxTableState();
			if (focusModeOnly && !isFocused) return;
			// Don't allow multiple selections b/c behavior undecided
			if (selectedAreaBounds?.length > 1) {
				Notification.warning({
					message: `Invalid action. Cannot ${
						cutNotCopy ? "cut" : "copy"
					} multiple selections`,
				});
				return;
			}
			const selectedCells = getCellIdsForArea(selectedAreaBounds[0]);
			const cellItemMapping = selectedCells.reduce((obj, cell) => {
				const item = items.find((item) => getCellId(item) === cell);
				if (item) {
					return {
						...obj,
						[cell]: item,
					};
				}
				return obj;
			}, {} as Record<CellId, Item>);
			CLIPBOARD_MANAGER.setClipboard([...selectedCells], cellItemMapping);
			if (cutNotCopy) {
				const ids = Object.values(cellItemMapping).map(
					(item) => item.id
				);
				if (ids.length) {
					setItemsCut(ids);
				} else {
					clearItemsCut();
				}
			}
			setCutMode(cutNotCopy);
		},
		[items, setCutMode]
	);
	return copySelected;
	// return (args: CopySelectedArgs) => copySelected(args, items, setCutMode);
};

export const useShowItemLimitNotification = () => {
	const history = useHistory();
	const {
		is_limit_reached: isItemsLimitReached,
		current,
		limit,
	} = useFeatureRestrictionHook("items");
	const { appendBaseUrl } = useOrganizationRouter();

	if (!isItemsLimitReached) {
		return () => "NOT SHOWN";
	}

	return () => {
		Notification.error({
			message: (
				<div style={{ display: "flex", alignItems: "center" }}>
					You&apos;ve reached the item limit: {current}/{limit}{" "}
					<Button
						type="link"
						style={{ marginLeft: 32 }}
						onClick={() => {
							history.push({
								pathname: appendBaseUrl(
									ACCOUNT_SETTINGS_PATHS.CHANGE_PLAN.route
								),
							});
						}}
					>
						Explore plans
					</Button>
				</div>
			),
		});
		return "SHOWN";
	};
};

export const usePasteItems = () => {
	const { items } = useBoxView();
	const showItemLimitNotification = useShowItemLimitNotification();
	const [pasteItems] = usePasteItemsMutation();
	const [moveItemLocation] = useMoveItemLocationMutation();
	const { setItemIdInUrl, isItemsLoading, refetchItems } = useBoxView();
	const { setShouldBlock } = useContext(SafeLeavingGuardContext);
	const { setLoadingCells, clearLoadingCells, setCutMode, clearItemsCut } =
		useBoxTableActions();

	const { cutMode } = getBoxTableState();

	// lazy refetch of box
	const [fetchBox] = useLazyBoxQuery();

	useEffect(() => {
		// Clear loading cells after items have refreshed
		if (!isItemsLoading) {
			clearLoadingCells();
		}
	}, [isItemsLoading]);

	const cb = useCallback(
		(
			boxId: number,
			toDelete: number[],
			toCreate: Item[],
			toCopy?: MoveItem[],
			callback: () => void = () => {},
			replace?: boolean
		) => {
			const itemToCreate: Item[] =
				toCreate?.map((t) => {
					const originalItem = items.find((i) => i.id === t.id);
					return originalItem ? { ...originalItem, ...t } : t;
				}) ?? [];
			if (showItemLimitNotification() === "SHOWN") {
				return;
			}
			const loadingCells = itemToCreate.map(
				(item) =>
					({
						row: item.location?.box_location?.row || 0,
						column: item.location?.box_location?.column || 0,
					} as GridCoord)
			);
			if (toCopy?.length) {
				loadingCells.concat(
					toCopy.map(
						(item) =>
							({
								row: item.new_location?.row || 0,
								column: item.new_location?.column || 0,
							} as GridCoord)
					)
				);
			}
			setLoadingCells(loadingCells);

			const setItemIdInUrlAndNotify = (items: Item[]) => {
				const item = items.find(
					(item) => getCellId(item) === getBoxTableState().cellId
				);
				// Find the item pasted into the current cell and set the item id in url
				if (item) {
					setItemIdInUrl(item?.id || null);
				}

				const itemName =
					items.length > 1
						? `${items.length} items`
						: `"${items[0].name}"`;
				Notification.success({
					message: (
						<span>
							<b>{itemName}</b>
							{" has been pasted."}
						</span>
					),
				});
				// We manually refetch the box data again after pasting items to get the latest information of the box contributors
				fetchBox(boxId);
			};
			if (cutMode && toCopy?.length) {
				Promise.allSettled(
					toCopy.map((item) => moveItemLocation({ ...item, replace }))
				)
					.then((res) => {
						if (
							res.some((result) => result.status === "rejected")
						) {
							Notification.warning({
								message: "Failed to paste item(s).",
							});
						} else {
							setItemIdInUrlAndNotify(
								toCopy as unknown as Item[]
							);
						}
						callback();
					})
					.finally(() => {
						setShouldBlock(false);
						refetchItems();
						setCutMode(false);
						clearItemsCut();
					});
			} else {
				pasteItems({ boxId, toDelete, toCreate: itemToCreate })
					.then((res) => {
						const { error, data: wrappedData } = flattenUnion(res);
						if (error || !wrappedData) {
							Notification.warning({
								message: "Failed to paste item(s).",
							});
						}
						callback();
						const nonNullData = wrappedData as NonNullable<
							typeof wrappedData
						>;
						if (
							nonNullData.type === "CREATE_AND_DELETE" ||
							nonNullData.type === "CREATE_ONLY"
						) {
							setItemIdInUrlAndNotify(nonNullData.data);
							return;
						}

						// If no items are created, clear the item id in url because we are just pasting empty cells
						setItemIdInUrl(null);
					})
					.catch((_err) => {
						Notification.warning({
							message: "Failed to paste item(s).",
						});
					})
					.finally(() => {
						setShouldBlock(false);
					});
			}
		},
		[showItemLimitNotification, setItemIdInUrl, setShouldBlock]
	);

	return cb;
};

type BoxActivityLogKeyProps = {
	activityLogKey: string;
	resetActivityLogKey: () => void;
};

export const BoxActivityLogKeyContext = createContext<BoxActivityLogKeyProps>({
	activityLogKey: "",
	resetActivityLogKey: () => {},
});

export const BoxActivityLogKeyContextProvider = (props: any) => {
	const [activityLogKey, setActivityLogKey] = useState<string>(nanoid());

	const resetActivityLogKey = () => {
		setActivityLogKey(nanoid());
	};

	return (
		<BoxActivityLogKeyContext.Provider
			value={{ activityLogKey, resetActivityLogKey }}
		>
			{props.children}
		</BoxActivityLogKeyContext.Provider>
	);
};
