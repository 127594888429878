import React from "react";
import { RawGoogleDocumentObject } from "./GoogleDrivePickerData";

type GoogleDocIconProps = {
	callbackDoc: RawGoogleDocumentObject;
	className?: string;
};

/**
 * Gets the icon for a given google doc. This can be a regular "document", or a sheet, pdf, etc.
 * Google provides a url to use.
 */
export default function GoogleDocIcon({
	callbackDoc,
	className,
}: GoogleDocIconProps) {
	return <img src={callbackDoc.iconUrl} className={className} />;
}
