import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { FlaskLoadingAnimation } from "@components";
import styles from "./index.module.scss";
import {
	useGetPersonalWorkspacesQuery,
	useGetOrgUsersQuery,
} from "@redux/team/TeamApi";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useCurrentOrganization from "@helpers/Hooks/useCurrentOrganizationHook";
import { useTwoDayReengagementEvent } from "@helpers/Hooks/UseAnalyticsEventHook";
type TransitionScreenProps = {
	timeout?: number;
	onFinish?: () => void;
};

export default function TransitionScreen({
	timeout = 5,
	onFinish,
}: TransitionScreenProps): JSX.Element {
	const isLoading = useIntialLoadingData();
	useTwoDayReengagementEvent();
	const [timer, setTimer] = useState(timeout);
	let counter: any;
	useEffect(() => {
		counter = setTimeout(() => setTimer(timer - 1), 1000);
		if (timer <= 0 && !isLoading) {
			if (onFinish) onFinish();
			clearTimeout(counter);
		}
		return () => {
			clearTimeout(counter);
		};
	});
	return (
		<Fade duration={700}>
			<div className={styles.transitionModal}>
				<FlaskLoadingAnimation length={2} />
			</div>
		</Fade>
	);
}

/**
 * Block transition screen from ending until all the initial data is loaded
 */
const useIntialLoadingData = () => {
	const { isLoading: isLoadingWorkspaces } = useGetPersonalWorkspacesQuery();

	const { organization, isLoading: isLoadingOrg } = useCurrentOrganization();
	const { isLoading: isLoadingUser } = useCurrentUserQuery();
	const { isLoading: isLoadingOrgUsers } = useGetOrgUsersQuery(
		(organization?.id as number) || skipToken
	);

	return (
		isLoadingWorkspaces ||
		isLoadingOrg ||
		isLoadingUser ||
		isLoadingOrgUsers
	);
};
