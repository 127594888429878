import React, { createContext, useContext } from "react";
import cn from "classnames";
import styles from "./LayerSystemContainer.module.scss";

export type Layer = "background" | 1 | 2 | 3;
type LayerSystemContextType = { parentLayer: "none" | Layer };

export const LayerSystemContext = createContext<LayerSystemContextType>({
	parentLayer: "none",
});

const LAYER_PARENT_MAPPING = {
	none: "background",
	background: 1,
	1: 2,
	2: 3,
	3: 3, // This is basically an error state since we only have 3 layers
} as const;

export type LayerSystemContainerProps = React.HTMLAttributes<HTMLDivElement> & {
	/** Can be used to override the layer if needed in some case. */
	overrideLayer?: Layer;
	/** Background hover color effect */
	backgroundHover?: boolean;
	/** For some reason the div props do not have ref already, add in back in here */
	wrapperRef?:
		| React.RefObject<HTMLDivElement>
		| React.ForwardedRef<HTMLDivElement>;
};

/**
 * Styles "containers" with standard background colors based on parent LayerSystemContainer components.
 * Can have it do the background hover effect.
 * 
 * Also provides locally scoped css variables that can be used in the "children's" style:
 * 
	--current-layer
	--current-layer-rgb
    --current-layer-hover
    
    --previous-layer
    --previous-layer-rgb
    --previous-layer-hover

    --next-layer
    --next-layer-rgb
    --next-layer-hover
 */
const LayerSystemContainer: React.FunctionComponent<
	LayerSystemContainerProps
> = ({
	className,
	overrideLayer,
	children,
	backgroundHover,
	wrapperRef,
	...props
}: LayerSystemContainerProps) => {
	const { parentLayer } = useContext(LayerSystemContext);
	const layer = overrideLayer || LAYER_PARENT_MAPPING[parentLayer];

	const classNameValues = Object.entries({
		backgroundHover,
	}).reduce(
		(classNames, [key, value]) => ({
			...classNames,
			[styles[key]]: value || false,
		}),
		{} as Record<string, boolean>
	);

	return (
		<div
			{...props}
			ref={wrapperRef}
			className={cn(
				className,
				styles.background,
				styles[`layer-${layer}`],
				classNameValues
			)}
		>
			<LayerSystemContext.Provider value={{ parentLayer: layer }}>
				{children}
			</LayerSystemContext.Provider>
		</div>
	);
};

export default LayerSystemContainer;
export const LayerClasses = styles as Record<`layer-${Layer}`, string>;

type LayerSystemContextContainerProps = {
	/** Can be used to override the layer if needed in some case. */
	overrideLayer?: Layer;
	children?: React.ReactNode;
	dataCy?: string;
};

/**
 * Layer system container component that just provides context. Useful for avoiding the extra div.
 * NOTE: you need to make sure you set the layer class/variables manually!
 */
export const LayerSystemContextContainer: React.FunctionComponent<
	LayerSystemContextContainerProps
> = ({ overrideLayer, children, dataCy }: LayerSystemContextContainerProps) => {
	const { parentLayer } = useContext(LayerSystemContext);
	const layer = overrideLayer || LAYER_PARENT_MAPPING[parentLayer];

	return (
		<LayerSystemContext.Provider value={{ parentLayer: layer }}>
			{children}
		</LayerSystemContext.Provider>
	);
};
