import React, { useState, useEffect, useMemo } from "react";
import {
	ActivityLog,
	EditableSidePanel,
	SidePanel,
	TabList,
	Typography,
	Notification,
} from "@components";
import { TabListTabText } from "@common/components/TabList/TabList";
import AnalyticsTabContent from "../AnalyticsTabContent/AnalyticsTabContent";
import { truncArgs } from "@helpers/Formatters";

import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	useShelfQuery,
	useShelfPatchMutation,
	useShelfStorageSummaryQuery,
} from "@redux/inventory/Shelf";
import InputV3 from "@common/components/InputV3/InputV3";

type ShelfSettingsProps = {
	setShowRearrangeRacksModal: (show: boolean) => void;
};

export default function ShelfSettings(props: ShelfSettingsProps): JSX.Element {
	const {
		genericallyNamed: {
			isVisible,
			closePanel: _closePanel,
			data: { id },
		},
	} = useCommonPanelState("shelfSettings");
	const { data: shelf } = useShelfQuery(id || skipToken);
	const viewOnly = !!shelf?.is_archived;

	const { is_limit_reached: restrictFreezerAnalytics } =
		useFeatureRestrictionHook("freezer_analytics");
	const [showEditShelf, setEditShelf] = React.useState<boolean>(false);
	const [updateShelf] = useShelfPatchMutation();

	const handleCancel = () => {
		setEditShelf(false);
		setTempName(name);
		setTempDescription(description);
	};

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	const [tempName, setTempName] = useState("");
	const [tempDescription, setTempDescription] = useState("");

	useEffect(() => {
		if (!shelf) return;
		setName(shelf.name || "");
		setTempName(shelf.name || "");
		setDescription(shelf.description);
		setTempDescription(shelf.description);
	}, [shelf]);

	const validateForm = () => {
		return !!tempName.trim().length;
	};

	const handleSubmit = () => {
		if (!shelf || !validateForm()) return;

		const failure = (err?: string) => {
			console.log("Failed to edit the shelf", err, shelf);
			Notification.warning({
				message: "Failed to edit the shelf",
			});
		};
		updateShelf({
			...shelf,
			name: tempName.trim(),
			description: tempDescription,
		})
			.unwrap()
			.then((shelf) => {
				setEditShelf(false);
				setName(shelf.name);
				setDescription(shelf.description);
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${shelf.name}`(68)}</b>
							{" has been updated."}
						</span>
					),
				});
			})
			.catch(failure);
	};

	const closePanel = () => {
		if (showEditShelf) {
			setEditShelf(false);
		}
		setTempName(name);
		setTempDescription(description);
		_closePanel();
	};

	const detailContent = (
		<>
			{!showEditShelf ? (
				<EditableSidePanel
					title={name}
					type="shelf"
					edit={showEditShelf}
					showEdit={() => setEditShelf(true)}
					viewOnly={viewOnly}
					setShowRearrangeRacksModal={() =>
						props.setShowRearrangeRacksModal(true)
					}
				>
					<div>
						{description.trim().length !== 0 && (
							<Typography
								withLinks
								className="sidePanel-description"
							>
								{description}
							</Typography>
						)}
					</div>
				</EditableSidePanel>
			) : (
				<EditableSidePanel
					edit={showEditShelf}
					inlineEdit
					type="shelf"
					showEdit={handleCancel}
					onConfirm={handleSubmit}
					validateForm={validateForm}
				>
					<div className="edit-form">
						<InputV3
							value={tempName}
							onChange={(e) => setTempName(e.target.value)}
							required
						/>
					</div>
				</EditableSidePanel>
			)}
		</>
	);

	const activityContent = (
		<ActivityLog
			activityFilter={{
				location_model: "Shelf",
				location_object_id: shelf?.id,
			}}
		/>
	);

	const [shelfSpaceData, shelfStorageData] = useShelfStorageSummary(
		shelf?.id
	);

	const tabItems = [
		{
			key: 1,
			tabtitle: <TabListTabText tabTitle="Details" />,
			tabcontent: detailContent,
		},
		{
			key: 2,
			tabtitle: <TabListTabText tabTitle="Activity" />,
			tabcontent: activityContent,
		},
		{
			key: 3,
			tabtitle: (
				<TabListTabText
					tabTitle="Analytics"
					withUpgradeIcon={restrictFreezerAnalytics}
				/>
			),
			tabcontent: (
				<AnalyticsTabContent
					title={`Distribution in ${shelf?.name}`}
					subtitle="Available space in shelf"
					storageSummary={{
						dataSource: shelfStorageData,
						columns: [
							{
								dataIndex: "name",
								key: "name",
								title: "Rack / Category",
								ellipsis: true,
							},
							{
								title: "Boxes",
								dataIndex: "boxes",
								key: "boxes",
								align: "right",
								width: 72,
							},
							{
								title: "Items",
								dataIndex: "items",
								key: "items",
								align: "right",
								width: 88,
							},
						],
						mockDataSource: [
							{
								name: "Shelf A",
								boxes: 17,
								items: 22,
							},
						],
					}}
					spaceLeft={{
						dataSource: shelfSpaceData,
						columns: [
							{
								title: "Rack",
								dataIndex: "name",
								key: "name",
								ellipsis: true,
							},
							{
								title: "Additional boxes allowed",
								dataIndex: "available_boxes",
								key: "available_boxes",
								align: "right",
							},
						],
						mockDataSource: [
							{
								name: "Shelf A",
								available_boxes: 12,
							},
						],
					}}
				/>
			),
		},
	];
	return (
		<SidePanel
			isVisible={isVisible}
			onClose={() => closePanel()}
			header={"Shelf settings"}
			showIcon={false}
		>
			<TabList inSettings tabListItems={tabItems} largeSize={true} />
		</SidePanel>
	);
}
/** convert shelfStorageSummaryData to shelfData and shelfStorageData */
export const useShelfStorageSummary = (shelfId: number | undefined) => {
	const { data: shelfStorageSummary } = useShelfStorageSummaryQuery(
		shelfId || -1,
		{ skip: !shelfId }
	);
	const [shelfSpaceData, shelfStorageData] = useMemo(() => {
		const data = shelfStorageSummary;
		const spaceData = data?.available_space.map((s) => ({
			name: s.name,
			available_boxes: s.available_boxes,
		}));
		const storageData = data?.storage_summary.map((d) => ({
			name: d.name,
			boxes: d.boxes,
			items: d.items,
		}));
		return [spaceData, storageData] as const;
	}, [shelfStorageSummary]);
	return [shelfSpaceData, shelfStorageData] as const;
};
