import {
	BreadCrumb,
	GenemodIcon,
	LoadingSpinner,
	Typography,
} from "@common/components";
import {
	ResponsiveTable,
	ResponsiveTableColumns,
} from "@common/components/Table/ResponsiveTable";
import {
	ConsumableSearchItem,
	ExperimentMetadata,
	Item,
	LinkedFile,
	ProjectLink,
	formatCustomId,
} from "@common/types";
import { Link } from "@helpers/Hooks/UseRouterDom";
import {
	useItemLocationQuery,
	useItemQuery,
	useItemTypesQuery,
	useLazyItemQuery,
} from "@redux/inventory/Item";
import { useOrganizationRouter } from "@root/AppRouter";
import { FREEZER_PATHS, PM_ROUTES } from "@root/routes";
import { Popover } from "antdv5";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./InterconectivityMetadata.module.scss";

function ItemLocationData({
	item,
	freezerName,
	boxName,
}: {
	item: number;
	freezerName: string;
	boxName: string;
}) {
	const { appendBaseUrl } = useOrganizationRouter();
	const { data } = useItemLocationQuery(item);

	if (!data)
		return (
			<div
				style={{
					height: 60,
					width: "100%",
					display: "flex",
					alignItems: "center",
				}}
			>
				<LoadingSpinner loading />
			</div>
		);

	return (
		<>
			<div className={styles.linkParent}>
				<Typography variant="label" semibold color="text-tertiary">
					Freezer
				</Typography>
				<Link
					to={appendBaseUrl(
						FREEZER_PATHS.CONTENTS.replace(
							":id",
							(data.freezer as number).toString()
						)
					)}
				>
					<Typography
						variant="label"
						color="link-primary"
						style={{ textDecoration: "underline" }}
					>
						{freezerName}
					</Typography>
				</Link>
			</div>
			<div className={styles.linkParent}>
				<Typography variant="label" semibold color="text-tertiary">
					Shelf
				</Typography>
				<Link
					to={appendBaseUrl(
						FREEZER_PATHS.SHELF.replace(
							":id",
							(data.shelf as number).toString()
						)
					)}
				>
					<Typography
						variant="label"
						color="link-primary"
						style={{ textDecoration: "underline" }}
					>
						{data.shelf_name}
					</Typography>
				</Link>
			</div>
			{data.rack ? (
				<div className={styles.linkParent}>
					<Typography variant="label" semibold color="text-tertiary">
						Rack
					</Typography>
					<Link
						to={appendBaseUrl(
							FREEZER_PATHS.RACK.replace(
								":id",
								data.rack.toString()
							)
						)}
					>
						<Typography
							variant="label"
							color="link-primary"
							style={{ textDecoration: "underline" }}
						>
							{data.rack_name}
						</Typography>
					</Link>
				</div>
			) : (
				<div className={styles.linkParent}>
					<Typography variant="label" semibold color="text-tertiary">
						Category
					</Typography>
					<Link
						to={
							appendBaseUrl(
								FREEZER_PATHS.CONTENTS.replace(
									":id",
									(data.freezer as number).toString()
								)
							) + `?selected_category=${data.category}`
						}
					>
						<Typography
							variant="label"
							color="link-primary"
							style={{ textDecoration: "underline" }}
						>
							{data.category_name}
						</Typography>
					</Link>
				</div>
			)}
			<div className={styles.linkParent}>
				<Typography variant="label" semibold color="text-tertiary">
					Box
				</Typography>
				<Link
					to={appendBaseUrl(
						FREEZER_PATHS.BOX.replace(
							":id",
							(data.box as number).toString()
						)
					)}
				>
					<Typography
						variant="label"
						color="link-primary"
						style={{ textDecoration: "underline" }}
					>
						{boxName}
					</Typography>
				</Link>
			</div>
		</>
	);
}

function ConsumableLink({
	consumable,
	orgPrefix,
}: {
	consumable: ConsumableSearchItem;
	orgPrefix: string;
}) {
	const { appendBaseUrl } = useOrganizationRouter();
	const link =
		appendBaseUrl(
			FREEZER_PATHS.FURNITURE_CATEGORIES.replace(
				":space_id",
				"" + consumable.parent_space.id
			)
				.replace(":furniture_id", "" + consumable?.parent_furniture.id)
				.replace(
					":furniture_category_id",
					"" + consumable?.parent_furniture_category.id
				)
		) + `?consumable_id=${consumable.id}`;
	return (
		<InterconectivityTooltip
			title={consumable.name}
			id={consumable.id}
			orgPrefix={orgPrefix}
			icon="lab-tube"
		>
			<>
				<div className={styles.linkParent}>
					<Typography variant="label" semibold color="text-tertiary">
						Space
					</Typography>
					<Link
						to={
							appendBaseUrl(
								FREEZER_PATHS.SPACE.replace(
									":id",
									consumable.parent_space.id.toString()
								)
							) +
							`?selected_furniture=${consumable.parent_furniture.id}`
						}
					>
						<Typography
							variant="label"
							color="link-primary"
							style={{
								textDecoration: "underline",
							}}
						>
							{consumable.parent_space.name}
						</Typography>
					</Link>
				</div>
				<div className={styles.linkParent}>
					<Typography variant="label" semibold color="text-tertiary">
						Furniture
					</Typography>
					<Link
						to={
							appendBaseUrl(
								FREEZER_PATHS.SPACE.replace(
									":id",
									consumable.parent_space.id.toString()
								)
							) +
							`?selected_furniture=${consumable.parent_furniture.id}`
						}
					>
						<Typography
							variant="label"
							color="link-primary"
							style={{
								textDecoration: "underline",
							}}
						>
							{consumable.parent_furniture.name}
						</Typography>
					</Link>
				</div>
				<div className={styles.linkParent}>
					<Typography variant="label" semibold color="text-tertiary">
						Category
					</Typography>
					<Link to={link}>
						<Typography
							variant="label"
							color="link-primary"
							style={{
								textDecoration: "underline",
							}}
						>
							{consumable.parent_furniture_category.name}
						</Typography>
					</Link>
				</div>
			</>
		</InterconectivityTooltip>
	);
}

function ItemLink({ item, orgPrefix }: { item: Item; orgPrefix: string }) {
	return (
		<InterconectivityTooltip
			title={item.name}
			orgPrefix={orgPrefix}
			id={item.id}
			icon="lab-tube"
		>
			<ItemLocationData
				item={item.id}
				freezerName={item.location_data.freezer_name as string}
				boxName={item.location_data.box_name as string}
			/>
		</InterconectivityTooltip>
	);
}

export function InterconectivityTooltip({
	title,
	children,
	id,
	orgPrefix,
	icon = "experiment",
}: {
	title: string;
	children: React.ReactNode;
	id: number;
	orgPrefix: string;
	icon?: "experiment" | "lab-tube";
}) {
	const [tooltipVisible, setTooltipVisible] = useState<boolean>(true);
	const closeTimeout = useRef<ReturnType<typeof setTimeout>>();
	const startTimeout = useRef<ReturnType<typeof setTimeout>>();
	const ref = useRef<any>(null);

	const content = (
		<div className={styles.interconectivityLinkTooltip}>
			<div className={styles.linkTitleContainer}>
				<div className={styles.linkIcon}>
					<GenemodIcon name={icon} size="XLarge" />
				</div>
				<div>
					<Typography className={styles.linkTitle} bold>
						{title}
					</Typography>
					<Typography variant="label" color="text-tertiary">
						{formatCustomId(orgPrefix, id)}
					</Typography>
				</div>
			</div>
			{children}
		</div>
	);

	return (
		<Popover
			rootClassName={styles.popover}
			content={content}
			getPopupContainer={() => {
				return ref.current || document.body;
			}}
			placement="topRight"
		>
			<li ref={ref} className={styles.interconectivityLinkContainer}>
				<GenemodIcon name={icon} />
				<div
					className={styles.interconectivityLink}
					onMouseEnter={() => {
						clearTimeout(closeTimeout.current);
						startTimeout.current = setTimeout(() => {
							setTooltipVisible(true);
						}, 1000);
					}}
					onMouseLeave={() => {
						clearTimeout(startTimeout.current);
						closeTimeout.current = setTimeout(() => {
							setTooltipVisible(false);
						}, 300);
					}}
				>
					<Typography
						className={styles.interconectivityLinkText}
						variant="label"
						color="text-secondary-v2"
					>
						{title}
					</Typography>
				</div>
			</li>
		</Popover>
	);
}

function DocumentLink({
	fileLinked,
	orgPrefix,
}: {
	fileLinked: LinkedFile;
	orgPrefix: string;
}) {
	const { appendBaseUrl } = useOrganizationRouter();
	const experimentUrl = appendBaseUrl(
		PM_ROUTES.FETCH_EXPERIMENT.replace(":id", fileLinked.file_id.toString())
	);

	return (
		<InterconectivityTooltip
			title={fileLinked.file}
			orgPrefix={orgPrefix}
			id={fileLinked.file_id}
		>
			<>
				<div className={styles.linkParent}>
					<Typography variant="label" semibold color="text-tertiary">
						Project
					</Typography>
					<Link to={experimentUrl}>
						<Typography
							variant="label"
							color="link-primary"
							style={{ textDecoration: "underline" }}
						>
							{fileLinked.parent_project}
						</Typography>
					</Link>
				</div>
				{fileLinked.parent_folder ? (
					<div className={styles.linkParent}>
						<Typography
							variant="label"
							semibold
							color="text-tertiary"
						>
							Folder
						</Typography>
						<Link to={experimentUrl}>
							{fileLinked.parent_folder}
						</Link>
					</div>
				) : null}
			</>
		</InterconectivityTooltip>
	);
}

const DocumentItemName = ({ item }: { item: ConsumableSearchItem | Item }) => {
	return (
		<div className={styles.itemParent}>
			<GenemodIcon
				name="lab-tube"
				className={styles.itemIcon}
				size="default"
			/>
			<Typography
				variant="body"
				color="text-secondary-v2"
				className={styles.itemText}
			>
				{item.name}
			</Typography>
		</div>
	);
};

const DocumentItemLocation = ({ item }: { item: Item }) => {
	if (!item.location_data.freezer_name && !item.location_data.box_name) {
		return (
			<Typography variant="body" color="text-secondary-v2">
				Repository
			</Typography>
		);
	}

	return (
		<BreadCrumb
			label={item.location_data.freezer_name as string}
			isLastCrumb={false}
			items={[item.location_data.box_name as string]}
		/>
	);
};

const DocumentItemType = ({ itemName }: { itemName: string }) => {
	return (
		<Typography variant="body" color="text-secondary-v2">
			{itemName}
		</Typography>
	);
};

function InterconectivityMetadata({
	metadata,
}: {
	metadata: ExperimentMetadata;
}) {
	const history = useHistory();
	const { data: itemTypes } = useItemTypesQuery();
	const {
		items,
		consumables,
		backlinks,
		mentioned_projects,
		linked_items = [],
	} = metadata;

	const uniqueItemIds = useMemo(
		() => [...new Set(linked_items.map((item) => item.item))],
		[linked_items]
	);
	const [itemsData, setItemsData] = useState<Item[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	const { appendBaseUrl } = useOrganizationRouter();

	const [trigger] = useLazyItemQuery();

	useEffect(() => {
		if (uniqueItemIds.length >= 1) {
			const fetchItems = async () => {
				try {
					const fetchedItems = await Promise.all(
						uniqueItemIds.map(async (id) => {
							const item = await trigger(id).unwrap();
							if (error) throw new Error(error);
							return item;
						})
					);
					setItemsData(
						fetchedItems.filter((item) => item !== null) as Item[]
					);
				} catch (err) {
					const error = err as Error;
					setError(error.message);
				} finally {
					setIsLoading(false);
				}
			};

			fetchItems();
		} else {
			setIsLoading(false);
		}
	}, [uniqueItemIds, trigger]);

	const mentionedFilesColumns: ResponsiveTableColumns<ProjectLink>[] = [
		{
			key: "name",
			title: "Mentioned files",
			render: (_, mention) => {
				return (
					<DocumentLink
						key={mention.id}
						fileLinked={mention.file}
						orgPrefix={metadata.organization_prefix}
					/>
				);
			},
		},
	];

	const mentionedItemsColumns: ResponsiveTableColumns<Item>[] = [
		{
			key: "name",
			title: "Mentioned items",
			render: (_, item) => {
				return <DocumentItemName item={item} />;
			},
		},
		{
			key: "location",
			title: "Location",
			render: (_, item) => {
				return <DocumentItemLocation item={item} />;
			},
		},
		{
			key: "itemType",
			title: "Item Type",
			render: (_, item) => {
				const itemType = itemTypes?.find(
					(type) => type.id === item.item_type
				);

				const itemName = itemType?.name ?? "";

				return <DocumentItemType itemName={itemName} />;
			},
		},
	];

	const backlinksColumns: ResponsiveTableColumns<ProjectLink>[] = [
		{
			key: "name",
			title: "Backlinks",
			render: (_, mention) => {
				return (
					<DocumentLink
						key={mention.id}
						fileLinked={mention.file}
						orgPrefix={metadata.organization_prefix}
					/>
				);
			},
		},
	];

	const itemsAndConsumables = [
		...items.map((i) => ({ ...i, type: "item" })),
		...consumables.map((c) => ({ ...c, type: "consumable" })),
	];

	const relatedItemsColumns: ResponsiveTableColumns<
		(typeof itemsAndConsumables)[number]
	>[] = [
		{
			key: "name",
			title: "Related items",
			render: (_, i) => {
				if (i.type === "item") {
					const item = i as Item;
					return (
						<ItemLink
							key={item.id}
							item={item}
							orgPrefix={metadata.organization_prefix}
						/>
					);
				} else {
					const consumable = i as ConsumableSearchItem;
					return (
						<ConsumableLink
							key={consumable.id}
							consumable={consumable}
							orgPrefix={metadata.organization_prefix}
						/>
					);
				}
			},
		},
	];

	if (
		!mentioned_projects?.length &&
		!backlinks?.length &&
		!itemsAndConsumables?.length &&
		!itemsData?.length
	) {
		return (
			<Typography variant="regular" color="text-tertiary-v2">
				No links to display
			</Typography>
		);
	}

	return (
		<div>
			{mentioned_projects.length > 0 ? (
				<>
					<ResponsiveTable
						hideColumnBorders
						dataSource={mentioned_projects}
						columns={mentionedFilesColumns}
						onRowClick={(record) => {
							const experimentUrl = appendBaseUrl(
								PM_ROUTES.FETCH_EXPERIMENT.replace(
									":id",
									record.file.file_id.toString()
								)
							);
							window.open(experimentUrl, "_blank");
						}}
					/>
				</>
			) : null}
			{backlinks.length > 0 ? (
				<>
					<ResponsiveTable
						style={{ marginTop: 16 }}
						columns={backlinksColumns}
						dataSource={backlinks}
						onRowClick={(record) => {
							const experimentUrl = appendBaseUrl(
								PM_ROUTES.FETCH_EXPERIMENT.replace(
									":id",
									record.file.file_id.toString()
								)
							);
							window.open(experimentUrl, "_blank");
						}}
					/>
				</>
			) : null}
			{itemsData.length > 0 || isLoading ? (
				<>
					<ResponsiveTable
						style={{
							marginTop: 16,
						}}
						dataSource={itemsData}
						columns={mentionedItemsColumns}
						loading={isLoading}
						onRowClick={(record) => {
							const link =
								record &&
								record.location?.freezer &&
								record.location?.box_location?.box
									? `freezer/freezers/${record.location?.freezer}/boxes/${record.location?.box_location?.box}/?item_id=${record.id}`
									: `freezer/repository?layer=ITEM&q=${record?.custom_id}`;

							const itemPath = appendBaseUrl(link);
							window.open(itemPath, "_blank");
						}}
					/>
				</>
			) : null}
			{itemsAndConsumables && itemsAndConsumables.length > 0 && (
				<>
					<ResponsiveTable
						style={{ marginTop: 16 }}
						columns={relatedItemsColumns}
						dataSource={itemsAndConsumables}
						onRowClick={(record) => {
							let link = "";
							if (record.type === "consumable") {
								const consumable =
									record as ConsumableSearchItem;
								link =
									appendBaseUrl(
										FREEZER_PATHS.FURNITURE_CATEGORIES.replace(
											":space_id",
											"" + consumable.parent_space.id
										)
											.replace(
												":furniture_id",
												"" +
													consumable?.parent_furniture
														.id
											)
											.replace(
												":furniture_category_id",
												"" +
													consumable
														?.parent_furniture_category
														.id
											)
									) + `?consumable_id=${consumable.id}`;
							} else {
								const item = record as Item;
								link = appendBaseUrl(
									FREEZER_PATHS.ITEM.replace(
										":id",
										(item as Item).id.toString()
									)
								);
							}

							window.open(link, "_blank");
						}}
					/>
				</>
			)}
		</div>
	);
}

export default InterconectivityMetadata;
