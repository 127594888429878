import React, { useState } from "react";
import {
	Typography,
	Tooltip,
	GenemodIcon,
	Notification,
	Modal,
	Spin,
	RadioButtonV2,
	DropdownV2,
} from "@components";
import { useCommonModalState } from "@redux/CommonModals/hooks";

import cn from "classnames";
import styles from "./index.module.scss";
import {
	getColumnLabel,
	getRowLabel,
	MAX_COLS_BOX,
	MAX_ROWS_BOX,
	MIN_COLS,
	MIN_ROWS,
	toCellId,
} from "@containers/Freezer/data";
import { Menu } from "antdv5";
import RearrangeModal from "@containers/Freezer/components/RearrangeModal/RearrangeModal";
import { Item, AxisDirection } from "@common/types";
import { getLocation } from "../InnerBoxTable/InnerBoxTable";
import DeleteModal from "@containers/Freezer/components/DeleteModal";
import { GridCoord } from "@common/components/InteractiveGrid/GridTypes";
import {
	useBoxQuery,
	useBoxPatchMutation,
	useBoxColDeleteMutation,
	useBoxRowDeleteMutation,
	useBoxColInsertMutation,
	useBoxRowInsertMutation,
} from "@redux/inventory/Box";
import {
	useBoxItemsQuery,
	useBulkItemsDeleteMutation,
} from "@redux/inventory/Item";
import { DropdownMenuDivider } from "@common/components/DropDownV2/dropdownV2";

const MenuItem = Menu.Item;

const BOX_LABEL_TYPE = {
	NUMBERS_LETTERS: 0,
	LETTERS_NUMBERS: 1,
};

export default function CustomizeBoxViewModal(): JSX.Element {
	const {
		isCustomizeBoxModalVisible,
		closeCustomizeBoxModal,
		customizeBoxModalData,
	} = useCommonModalState("customizeBoxModal");
	const { data: box, isLoading } = useBoxQuery(customizeBoxModalData.boxId, {
		skip: !customizeBoxModalData.boxId,
		refetchOnMountOrArgChange: true,
	});
	const { data: items = [] } = useBoxItemsQuery(
		{
			id: customizeBoxModalData.boxId,
		},
		{
			skip: !customizeBoxModalData.boxId,
			refetchOnMountOrArgChange: true,
		}
	);
	const [isTableLoading, setIsTableLoading] = useState(false);
	const rowsAreNumbers = box?.axis_direction || 0;
	const [errorModal, setErrorModal] = useState<JSX.Element | null>(null);

	// rtk query hooks
	const [patchBox] = useBoxPatchMutation();
	const [deleteBoxItemsBulk] = useBulkItemsDeleteMutation();
	const [deleteBoxCol] = useBoxColDeleteMutation();
	const [deleteBoxRow] = useBoxRowDeleteMutation();
	const [addBoxCol] = useBoxColInsertMutation();
	const [addBoxRow] = useBoxRowInsertMutation();

	const onLabelChange = (axis_direction: AxisDirection) => {
		if (!box) return;
		setIsTableLoading(true);
		patchBox({ id: box.id, axis_direction })
			.unwrap()
			.catch(() => {
				Notification.warning({
					message:
						"Failed to update box. Please refresh the page and try again.",
				});
			})
			.finally(() => setIsTableLoading(false));
	};

	const num_rows =
		box && box.rows > -1 ? box?.rows : 8; /* Placeholder while loading */
	const num_cols =
		box && box.columns > -1
			? box?.columns
			: 8; /* Placeholder while loading */

	const singleCellDimension = {
		width: 76,
		height: 59,
	};

	/**
	 * For clearing box table row/column
	 * @param isRow boolean
	 * @param index row/col number (starting from 0)
	 */
	const clearDimension = (isRow: boolean, index: number) => {
		if (!box) return;
		const type = isRow ? "row" : "column";
		// Grab all items in that row/col
		const toDelete = items
			?.filter((b: Item) => getLocation(b, isRow) === index)
			.map((item) => item.id);
		const onConfirm = () => {
			if (!box || !items || !toDelete) return;

			setIsTableLoading(true);
			deleteBoxItemsBulk({
				ids: toDelete,
			})
				.unwrap()
				.then(() => {
					setErrorModal(null);
				})
				.catch(() => {
					Notification.warning({
						message: `Failed to delete ${type}`,
					});
				})
				.finally(() => setIsTableLoading(false));
		};

		if (toDelete?.length) {
			setErrorModal(
				<DeleteModal
					title={`Clear ${type}?`}
					onCancel={() => setErrorModal(null)}
					onConfirm={onConfirm}
					visible
				/>
			);
		}
	};

	/**
	 * Deletes a row/col at an index
	 * @param isRow boolean
	 * @param index index of the box row/column
	 */
	const deleteDimension = (isRow: boolean, index: number) => {
		if (!box) return;
		const copy = { ...box };
		const boxDimension = isRow ? copy.rows : copy.columns;
		const toDelete = items
			?.filter((b: Item) => getLocation(b, isRow) === index)
			.map((item) => item.id);

		const handleDelete = () => {
			if (!isRow && copy.columns === MIN_COLS) {
				setErrorModal(
					<Modal
						title={"Must have at least one column"}
						onOk={() => setErrorModal(null)}
						visible
					>
						You you must have at least one column in a box.
					</Modal>
				);
			} else if (isRow && copy.rows === MIN_ROWS) {
				setErrorModal(
					<Modal
						title={"Must have at least one row"}
						onCancel={() => setErrorModal(null)}
						onOk={() => setErrorModal(null)}
						visible
					>
						You you must have at least one row in a box.
					</Modal>
				);
			} else {
				if (boxDimension - 1 >= (isRow ? MIN_ROWS : MIN_COLS)) {
					// Decrease dimension of box in db and object
					const promise = isRow
						? deleteBoxRow({ boxId: box.id, row: index })
						: deleteBoxCol({ boxId: box.id, column: index });
					setIsTableLoading(true);
					promise
						.unwrap()
						.then((res) => {
							setErrorModal(null);
						})
						.catch(() => {
							Notification.warning({
								message: `${"Failed to delete box"} ${
									isRow ? "row" : "column"
								}`,
							});
						})
						.finally(() => setIsTableLoading(false));
				}
			}
		};

		if (toDelete?.length) {
			const type = isRow ? "row" : "column";
			setErrorModal(
				<DeleteModal
					title={`Delete ${type}?`}
					onCancel={() => setErrorModal(null)}
					onConfirm={handleDelete}
					visible
				/>
			);
		} else {
			handleDelete();
		}
	};

	/**
	 * Inserts a row/col before/after index
	 * @param isRow boolean
	 * @param index which index of the box row/column
	 * @param before before or after the specified index
	 * @returns
	 */
	const insertDimension = (
		isRow: boolean,
		index: number,
		before: boolean
	) => {
		if (!box) return;
		const copy = { ...box };
		const boxDimension = isRow ? copy.rows : copy.columns;

		if (!isRow && copy.columns === MAX_COLS_BOX) {
			setErrorModal(
				<Modal
					className={"reminder-modal"}
					title={"Reached the maximum number of columns"}
					onOk={() => setErrorModal(null)}
					onCancel={() => setErrorModal(null)}
					visible
				>
					You reached the maximum number of columns in a box.
				</Modal>
			);
		} else if (isRow && copy.rows === MAX_ROWS_BOX) {
			setErrorModal(
				<Modal
					className={"reminder-modal"}
					title={"Reached the maximum number of rows"}
					onOk={() => setErrorModal(null)}
					onCancel={() => setErrorModal(null)}
					visible
				>
					You reached the maximum number of rows in a box.
				</Modal>
			);
		} else {
			if (boxDimension + 1 <= (isRow ? MAX_ROWS_BOX : MAX_COLS_BOX)) {
				const targetIndex = before ? index : index + 1;
				const promise = isRow
					? addBoxRow({ boxId: box.id, row: targetIndex })
					: addBoxCol({ boxId: box.id, column: targetIndex });
				setIsTableLoading(true);
				promise
					.unwrap()
					.catch(() => {
						Notification.warning({
							message: `${"Failed to add box"} ${
								isRow ? "row" : "column"
							}`,
						});
					})
					.finally(() => setIsTableLoading(false));
			}
		}
	};

	// Header
	const getColHeaders = () => (
		<div
			className={styles.tableColHeader}
			style={{
				minWidth: singleCellDimension.width * num_cols,
			}}
		>
			{Array(num_cols)
				.fill(0)
				.map((_, index) => {
					const headerMenu = (
						<Menu>
							<MenuItem
								disabled={box?.columns === MAX_COLS_BOX}
								onClick={() => {
									insertDimension(false, index, true);
								}}
								data-cy="box-dim-insert-left"
							>
								{box?.columns === MAX_COLS_BOX ? (
									<Tooltip title="You have reached the maximum number of columns">
										Insert 1 left
									</Tooltip>
								) : (
									"Insert 1 left"
								)}
							</MenuItem>
							<MenuItem
								disabled={box?.columns === MAX_COLS_BOX}
								onClick={() => {
									insertDimension(false, index, false);
								}}
								data-cy="box-dim-insert-right"
							>
								{box?.columns === MAX_COLS_BOX ? (
									<Tooltip title="You have reached the maximum number of columns">
										Insert 1 right
									</Tooltip>
								) : (
									"Insert 1 right"
								)}
							</MenuItem>
							<DropdownMenuDivider />
							<MenuItem
								onClick={() => clearDimension(false, index)}
								data-cy="box-dim-clear-col"
							>
								<Typography color="danger">
									Clear column
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => deleteDimension(false, index)}
								data-cy="box-dim-delete-col"
							>
								<Typography color="danger">
									Delete column
								</Typography>
							</MenuItem>
						</Menu>
					);

					return (
						<div
							// onClick={() => selectColumn(index)}
							className={styles.colHeader}
							key={"table-column" + index}
							style={{
								minWidth: singleCellDimension.width,
							}}
						>
							<Typography>
								{getColumnLabel(index + 1, box?.axis_direction)}
							</Typography>
							<DropdownV2 overlay={headerMenu}>
								<GenemodIcon
									name="chevron-down"
									className="caret-down"
									fill="text-secondary-v2"
									dataCy={`box-dim-caret-col-${index}`}
								/>
							</DropdownV2>
						</div>
					);
				})}
		</div>
	);

	// Row header
	const getRowHeaders = () => (
		<div
			className={styles.tableRowHeader}
			style={{
				minHeight: singleCellDimension.height * num_rows,
			}}
		>
			{Array(num_rows)
				.fill(0)
				.map((x, index) => {
					const headerMenu = (
						<Menu>
							<MenuItem
								disabled={box?.rows === MAX_ROWS_BOX}
								onClick={() => {
									insertDimension(true, index, true);
								}}
								data-cy="box-dim-insert-above"
							>
								{box?.rows === MAX_ROWS_BOX ? (
									<Tooltip title="You've reached the maximum number of rows">
										Insert 1 above
									</Tooltip>
								) : (
									"Insert 1 above"
								)}
							</MenuItem>
							<MenuItem
								disabled={box?.rows === MAX_ROWS_BOX}
								onClick={() => {
									insertDimension(true, index, false);
								}}
								data-cy="box-dim-insert-below"
							>
								{box?.rows === MAX_ROWS_BOX ? (
									<Tooltip title="You've reached the maximum number of rows">
										Insert 1 below
									</Tooltip>
								) : (
									"Insert 1 below"
								)}
							</MenuItem>
							<DropdownMenuDivider />
							<MenuItem
								onClick={() => {
									clearDimension(true, index);
								}}
								data-cy="box-dim-clear-row"
							>
								<Typography color="danger">
									Clear row
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => {
									deleteDimension(true, index);
								}}
								data-cy="box-dim-delete-row"
							>
								<Typography color="danger">
									Delete row
								</Typography>
							</MenuItem>
						</Menu>
					);

					return (
						<div
							className={styles.rowHeader}
							key={"row-header-" + index}
							style={{
								minHeight: singleCellDimension.height,
							}}
						>
							<Typography>
								{getRowLabel(index + 1, box?.axis_direction)}
							</Typography>
							<DropdownV2 overlay={headerMenu}>
								<GenemodIcon
									name="chevron-down"
									className="caret-down"
									fill="text-secondary-v2"
									dataCy={`box-dim-caret-row-${index}`}
								/>
							</DropdownV2>
						</div>
					);
				})}
		</div>
	);

	// Actual table
	const getCells = () => {
		const cells = [] as JSX.Element[];
		for (let i = 0; i < num_rows; i++) {
			for (let j = 0; j < num_cols; j++) {
				const id = toCellId({ row: i, column: j } as GridCoord);
				const item = items.filter((item: Item) => {
					return (
						item.location?.box_location?.row === i &&
						item.location?.box_location.column === j
					);
				});

				const isEmptyCell = !item?.length;
				cells.push(
					<div
						key={id}
						className={cn(styles.cell, {
							[styles.cellEmpty]: isEmptyCell,
						})}
						id={id}
						style={{
							height: singleCellDimension.height,
							width: singleCellDimension.width,
						}}
					>
						{!isEmptyCell && item?.[0].is_bookmarked && (
							<GenemodIcon
								name="bookmark-freezer"
								fill="accent-strong"
								style={{
									cursor: "default",
								}}
							/>
						)}
						<div id={id} style={{ padding: 4 }}>
							<Typography variant="body2" color="text-disabled">
								{item?.[0]?.name}
							</Typography>
						</div>
					</div>
				);
			}
		}
		return <>{cells}</>;
	};

	const content = getCells();

	return (
		<>
			<RearrangeModal
				visible={isCustomizeBoxModalVisible}
				width={"auto" as any}
				onOk={closeCustomizeBoxModal}
				title="Customize box"
			>
				<Spin spinning={isLoading}>
					<Typography variant="body" medium color="text-primary-v2">
						Axis labels (row x column)
					</Typography>
					<div
						style={{
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "flex-start",
							marginTop: 12,
						}}
					>
						<RadioButtonV2
							name={String(BOX_LABEL_TYPE.NUMBERS_LETTERS)}
							value={String(BOX_LABEL_TYPE.NUMBERS_LETTERS)}
							className={styles.radioButton}
							radioLabel="Numbers x Letters"
							checked={
								rowsAreNumbers ===
								BOX_LABEL_TYPE.NUMBERS_LETTERS
							}
							onChange={(e) => onLabelChange(+e as AxisDirection)}
						/>
						<RadioButtonV2
							name={String(BOX_LABEL_TYPE.LETTERS_NUMBERS)}
							value={String(BOX_LABEL_TYPE.LETTERS_NUMBERS)}
							className={styles.radioButton}
							radioLabel="Letters x Numbers"
							checked={
								rowsAreNumbers ===
								BOX_LABEL_TYPE.LETTERS_NUMBERS
							}
							onChange={(e) => onLabelChange(+e as AxisDirection)}
						/>
					</div>
				</Spin>
				<Typography
					variant="body"
					medium
					color="text-primary-v2"
					style={{ marginTop: 24, marginBottom: 12 }}
				>
					Change dimension
				</Typography>
				<div className={cn(styles.boxEditGrid, "genemod-lightmode")}>
					<div className={styles.tableContent}>
						{isTableLoading && (
							<div className={styles.tableLoading}>
								<Spin />
							</div>
						)}{" "}
						{isLoading ? (
							<Spin spinning size="large" />
						) : (
							<>
								{getColHeaders()}
								<div
									className={styles.colTableRowHeaderAndGrid}
								>
									{getRowHeaders()}
									<div
										className={styles.middle}
										style={{
											display: "grid",
											gridTemplateColumns: `repeat(${num_cols}, ${singleCellDimension.width}px)`,
											gridTemplateRows: `repeat(${num_rows}, ${singleCellDimension.height}px)`,
										}}
									>
										{/*  adding genemod-lightmode class to utilize the same color for the lighmode since we want to use lightmode color for both theme */}
										{content}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</RearrangeModal>
			{errorModal}
		</>
	);
}
