import { useOrganizationRouter } from "@root/AppRouter";
import { PM_ROUTES } from "@root/routes";

type ProtocolParams = {
	protocolId: string | number;
	version?: string | number;
	mode?: string;
};

type ProjectParams = {
	projectId: string | number;
	fileId?: string | number;
	folderId?: string | number;
	notificationId?: string | number;
};

export const useAppendPmUrl = () => {
	const { appendBaseUrlAndParams } = useOrganizationRouter();

	const appendProtocolUrl = (params: ProtocolParams) =>
		appendBaseUrlAndParams(PM_ROUTES.PROTOCOL, params);

	const appendProjectUrl = (
		params: ProjectParams,
		state: "archived" | "active"
	) =>
		appendBaseUrlAndParams(
			PM_ROUTES.PROJECT.replace(":state", state),
			params
		);

	return {
		appendProjectUrl,
		appendProtocolUrl,
	};
};
