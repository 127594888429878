import {
	LayerSystemContainer,
	LoadingSpinner,
	SearchBar,
	Typography,
} from "@common/components";
import {
	useFurnitureCategoriesSearchQuery,
	useSearchBoxesStockAlertsQuery,
} from "@redux/freezer/FreezerApiSlice";
import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./AssignLocationPopup.module.scss";
import { useDebounceEventHandler } from "@helpers/Hooks";
import {
	Box,
	Category,
	Consumable,
	Freezer,
	FilterType,
	ITEM_STATUS,
	Rack,
	Shelf,
	TableItem,
	BoxLocationData,
} from "@common/types";
import { useGetRepositoryFreezersQuery } from "@redux/inventory/Freezer";
import { useParams } from "@helpers/URLParams";
import { useGetRepositoryRacksQuery } from "@redux/inventory/Rack";
import { useGetRepositoryShelvesQuery } from "@redux/inventory/Shelf";
import { useGetRepositoryCategoriesQuery } from "@redux/inventory/Category";
import { useGetRepositoryBoxesQuery } from "@redux/inventory/Box";

type Props = {
	selectedTableItem?: number;
	onSelect: (id: number, tableItem: TableItem, object?: FilterType) => void;
	onSelectConsumable?: (categoryId: number, category: Consumable) => void;
	isConsumable?: boolean;
};

export default ({
	isConsumable = false,
	onSelect,
	onSelectConsumable,
}: Props) => {
	const [search, setSearch] = useState("");
	const [termSearch, setTermSearch] = useState("");
	const [categorySearch, setCategorySearch] = useState("");
	const { getParam } = useParams();
	const layer = getParam("layer");

	const getPlaceHolder = () => {
		if (layer === "BOX") {
			return "rack or category";
		}
		if (layer === "SHELF") {
			return "freezer";
		}
		if (layer === "RACK" || layer === "CATEGORY") {
			return "shelf";
		}
		return "box";
	};

	const { data: boxSearchData, isFetching } = useGetRepositoryBoxesQuery(
		{
			page: 1,
			page_size: 10,
			search: termSearch?.startsWith("#") ? undefined : termSearch,
			ordering: "-updated_at",
			status__in: [ITEM_STATUS.ACTIVE],
		},
		{
			refetchOnMountOrArgChange: true,
			skip: layer !== "ITEM",
		}
	);
	const { data: freezersData } = useGetRepositoryFreezersQuery(
		{
			page: 1,
			page_size: 10,
			search: termSearch?.startsWith("#") ? undefined : termSearch,
			ordering: "-updated_at",
			status__in: [ITEM_STATUS.ACTIVE],
		},
		{ refetchOnMountOrArgChange: true }
	);
	const { data: categoriesData } = useGetRepositoryCategoriesQuery(
		{
			page: 1,
			page_size: 10,
			search: termSearch?.startsWith("#") ? undefined : termSearch,
			ordering: "-updated_at",
			status__in: [ITEM_STATUS.ACTIVE],
		},
		{
			refetchOnMountOrArgChange: true,
			skip: !(layer === "BOX" || layer === "ITEMGROUP"),
		}
	);
	const { data: shelvesData } = useGetRepositoryShelvesQuery(
		{
			page: 1,
			page_size: 10,
			search: termSearch?.startsWith("#") ? undefined : termSearch,
			ordering: "-updated_at",
			status__in: [ITEM_STATUS.ACTIVE],
		},
		{
			refetchOnMountOrArgChange: true,
			skip: !(layer === "RACK" || layer === "CATEGORY"),
		}
	);
	const { data: racksData } = useGetRepositoryRacksQuery(
		{
			page: 1,
			page_size: 5,
			search: termSearch?.startsWith("#") ? undefined : termSearch,
			ordering: "-updated_at",
			status__in: [ITEM_STATUS.ACTIVE],
		},
		{ refetchOnMountOrArgChange: true, skip: layer !== "BOX" }
	);

	const getResultLength = (): number => {
		if (layer === "BOX") {
			const racksLength = racksData?.results?.length || 0;
			const categoriesLength = categoriesData?.results?.length || 0;
			return racksLength + categoriesLength || 0;
		}
		if (layer === "SHELF") {
			return freezersData?.results.length || 0;
		}
		if (layer === "RACK") {
			return shelvesData?.results.length || 0;
		}
		if (layer === "CATEGORY") {
			return shelvesData?.results.length || 0;
		}
		if (layer === "ITEMGROUP") {
			return categoriesData?.results.length || 0;
		}
		return boxSearchData?.results.length || 0;
	};

	const results = boxSearchData?.results;
	const resultsLength = getResultLength();

	const { data: consumableSpaceData } = useFurnitureCategoriesSearchQuery(
		{
			page: 1,
			page_size: 10,
			stock_name: "",
			search: categorySearch,
			filter_from_repository: true,
		},
		{ refetchOnMountOrArgChange: true }
	);
	const spaceResults = consumableSpaceData?.results;
	const spaceResultsLength = consumableSpaceData?.count;

	const resultsLabel = useMemo(() => {
		if (termSearch.length && !resultsLength) {
			return `No ${getPlaceHolder()}${
				resultsLength !== 1 ? "es" : ""
			} found`;
		}
		if (!termSearch.length) {
			return "Suggested";
		}
		return `${resultsLength} ${getPlaceHolder()}${
			resultsLength !== 1 ? "es" : ""
		} found`;
	}, [resultsLength, termSearch]);

	const resultsCategoryLabel = useMemo(() => {
		return `${spaceResultsLength} categories found`;
	}, [categorySearch, spaceResultsLength]);

	const debounceSearch = useDebounceEventHandler((ev: string) => {
		isConsumable ? setCategorySearch(ev) : setTermSearch(ev);
	}, 350);

	const renderShelfResults = () => {
		return freezersData?.results?.map((freezer) => {
			const option = freezer as any;
			const spacesAvailable = option.shelves - option.shelves_number;
			const isFreezerAvailable = spacesAvailable > 0;
			return (
				<div
					key={option.id}
					className={cn(styles.boxContainer, {
						[styles.boxContainerDisabled]: !isFreezerAvailable,
					})}
					onClick={
						isFreezerAvailable
							? () => onSelect(option.id, option as Freezer)
							: undefined
					}
				>
					<div className={styles.boxNameContainer}>
						<span
							style={{
								color: "var(--text-secondary)",
							}}
						>
							{option.name}
						</span>
						<span
							style={{
								color: "var(--text-tertiary)",
							}}
						>
							{spacesAvailable}
							{" slots available"}
						</span>
					</div>
				</div>
			);
		});
	};

	const renderBoxResults = () => {
		const rackResults: any =
			racksData?.results.map((rack) => {
				const isRackAvailable = rack.availability > 0;
				return (
					<div
						key={rack.id}
						className={cn(styles.boxContainer)}
						onClick={() =>
							isRackAvailable
								? onSelect(rack.id, rack, FilterType.RACK)
								: null
						}
					>
						<div className={styles.boxNameContainer}>
							<div>
								<Typography variant="label">
									{rack.name}
								</Typography>
								<Typography
									color="text-tertiary"
									variant="caption"
									className="mt-1"
								>
									{rack.location_data.shelf_name &&
									rack.location_data.freezer_name
										? `${rack.location_data.freezer_name} > ${rack.location_data.shelf_name}`
										: rack.location_data.shelf_name
										? rack.location_data.freezer_name
										: ""}
								</Typography>
							</div>
							<Typography color="text-tertiary" variant="label">
								{rack.availability}
								{" slots available"}
							</Typography>
						</div>
					</div>
				);
			}) || [];
		const categoryResults = categoriesData?.results.map((category) => {
			return (
				<div
					key={category.id}
					className={cn(styles.boxContainer)}
					onClick={() =>
						onSelect(category.id, category, FilterType.CATEGORY)
					}
				>
					<div className={styles.boxNameContainer}>
						<Typography variant="label">{category.name}</Typography>
					</div>
				</div>
			);
		});
		return rackResults?.concat(categoryResults);
	};

	const renderBoxLocation = (location_data: BoxLocationData) => {
		const { freezer_name, rack_name, shelf_name, category_name } =
			location_data;
		let location = "";
		if (freezer_name) {
			location = freezer_name;
		}
		if (shelf_name) {
			location += location !== "" ? ` > ${shelf_name}` : shelf_name;
		}
		if (rack_name || category_name) {
			const rack_or_category = rack_name || category_name;
			location +=
				location !== "" ? ` > ${rack_or_category}` : rack_or_category;
		}
		return location;
	};

	const renderItemResults = () => {
		return results?.map((option) => {
			const boxMaxSpaces = option.rows * option.columns;
			const boxAvaibableSpaces = boxMaxSpaces - option.item_count;
			const isBoxAvailable = boxAvaibableSpaces > 0;
			const availability = isBoxAvailable ? boxAvaibableSpaces : "No";
			return (
				<div
					key={option.id}
					className={cn(styles.boxContainer, {
						[styles.boxContainerDisabled]: !isBoxAvailable,
					})}
					onClick={
						isBoxAvailable
							? () => onSelect(option.id, option as Box)
							: undefined
					}
				>
					<div className={styles.boxNameContainer}>
						<span
							style={{
								color: "var(--text-secondary)",
							}}
						>
							{option.name}
						</span>
						<span
							style={{
								color: "var(--text-tertiary)",
							}}
						>
							{availability}
							{" slots available"}
						</span>
					</div>
					<Typography color="text-tertiary" variant="caption">
						{renderBoxLocation(option.location_data)}
					</Typography>
				</div>
			);
		});
	};

	const renderCategoryResults = () => {
		return categoriesData?.results?.map((category) => {
			const option = category as any;
			const { freezer_name, shelf_name } = option.location_data;
			return (
				<div
					key={option.id}
					className={cn(styles.boxContainer)}
					onClick={() => onSelect(option.id, option as Category)}
				>
					<div className={styles.boxNameContainer}>
						<span
							style={{
								color: "var(--text-secondary)",
							}}
						>
							{option.name}
						</span>
					</div>
					{(freezer_name || shelf_name) && (
						<Typography color="text-tertiary" variant="caption">
							{freezer_name
								? `${freezer_name} > ${shelf_name}`
								: shelf_name}
						</Typography>
					)}
				</div>
			);
		});
	};

	const renderRackResults = () => {
		return shelvesData?.results.map((shelf) => {
			const option = shelf as any;
			return (
				<div
					key={option.id}
					className={cn(styles.boxContainer)}
					onClick={() => onSelect(option.id, option as Shelf)}
				>
					<div className={styles.boxNameContainer}>
						<span
							style={{
								color: "var(--text-secondary)",
							}}
						>
							{option.name}
						</span>
					</div>
					<Typography color="text-tertiary" variant="caption">
						{option.location_data?.freezer_name}
					</Typography>
				</div>
			);
		});
	};

	const renderCategoriesResults = () => {
		return spaceResults?.map((option) => {
			const { space, furniture } = option.path;
			return (
				<div
					key={option.id}
					className={cn(styles.boxContainer)}
					onClick={() =>
						onSelectConsumable &&
						onSelectConsumable(
							option.id,
							option as unknown as Consumable
						)
					}
				>
					<div className={styles.boxNameContainer}>
						<span
							style={{
								color: "var(--text-secondary)",
							}}
						>
							{option.name}
						</span>
					</div>
					<Typography color="text-tertiary" variant="caption">
						{`${space.name} > ${furniture.name}`}
					</Typography>
				</div>
			);
		});
	};

	return (
		<div className={styles.container} data-cy="assign-location-popup">
			<div className={styles.searchBarContainer}>
				<LayerSystemContainer>
					<SearchBar
						disableSuggestions
						value={search}
						onChange={(e) => {
							setSearch(e);
							debounceSearch(e);
						}}
						placeholder={`Search ${
							isConsumable ? "category" : getPlaceHolder()
						}`}
						iconPosition="left"
					/>
				</LayerSystemContainer>
			</div>
			<LoadingSpinner loading={isFetching} centered size="large">
				<Typography
					color="text-tertiary"
					className={styles.resultsLabel}
				>
					{isConsumable ? resultsCategoryLabel : resultsLabel}
				</Typography>
				<div className={styles.listContainer}>
					{isConsumable ? renderCategoriesResults() : null}
					{layer === "SHELF" ? renderShelfResults() : null}
					{layer === "BOX" ? renderBoxResults() : null}
					{layer === "ITEM" && !isConsumable
						? renderItemResults()
						: null}
					{layer === "RACK" || layer === "CATEGORY"
						? renderRackResults()
						: null}
					{layer === "ITEMGROUP" ? renderCategoryResults() : null}
				</div>
			</LoadingSpinner>
		</div>
	);
};
