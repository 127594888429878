import { GenemodLinkType } from "./GenemodLink";

export type NewFeaturePopOver = {
	id: number;
	title: string;
	body_content: string;
	featured_image: string;
	page: GenemodLinkType;
	search_params: string;
};
