import React from "react";
import styles from "./NarrowOnboarding.module.scss";
import classNames from "classnames";
import GENEMOD_LOGO from "./genemod_logo_light.png";
import { useWindowDimensions } from "@helpers/Hooks";
import {
	DemoWrapper,
	DemoSection,
	Demo,
	Typography,
	Button,
	LayerSystemContainer,
} from "@components";
import { Fade } from "react-reveal";
import ProjectKeyTag from "@containers/ProjectManagement/components/ProjectKeyTag";
import { ProjectColorEnum } from "@common/types/ProjectProps";

type NarrowOnboardProps = {
	modalWidth?: string | number;
	modalHeight?: string | number;
	className?: string;
	hideLogo?: boolean;
	children?: JSX.Element | React.ReactNode;
	showBetaTag?: boolean;
};

const MINIMUM_VERTICAL_SPACING = 64;

export default function NarrowOnboarding(
	props: NarrowOnboardProps
): JSX.Element {
	const { width } = Object(useWindowDimensions());
	const largeWidth = width >= 1920;
	const {
		modalWidth = largeWidth ? 512 : 456,
		modalHeight = "fit-content",
		className,
		children,
		hideLogo = false,
	} = props;
	const ref = React.useRef<HTMLDivElement>(null);

	return (
		// Temprarily adding the genemod-lightmode class name since we are currently only using light mode colors for anything auth related
		<div
			className={classNames(
				styles.parentDiv,
				className,
				"genemod-lightmode"
			)}
			style={
				ref.current
					? {
							minHeight:
								ref.current.getBoundingClientRect().height +
								MINIMUM_VERTICAL_SPACING * 2,
					  }
					: {}
			}
		>
			<LayerSystemContainer
				className={styles.narrowWrapper}
				wrapperRef={ref}
				style={{
					width: modalWidth,
					height: modalHeight,
					top: 0,
					position: "relative",
				}}
				overrideLayer={2}
			>
				<Fade>
					<div className={styles.imgWrapper}>
						{!hideLogo && (
							<>
								<img
									src={GENEMOD_LOGO}
									width={210}
									height={44}
								/>
								{props.showBetaTag && (
									<div style={{ height: 12 }}>
										<ProjectKeyTag
											project={{
												key: "Beta",
												color: ProjectColorEnum.GREEN,
											}}
										/>
									</div>
								)}
							</>
						)}
					</div>
					<div className={styles.childrenWrapper}>{children}</div>
				</Fade>
			</LayerSystemContainer>
		</div>
	);
}

export function NARROWONBOARDING_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage"
					description="Basic usage of narrow onboarding modal"
				>
					<NarrowOnboarding modalWidth={528}>
						<Typography
							variant="title"
							bold
							style={{
								marginTop: 48,
								marginBottom: 24,
							}}
						>
							This invite URL is invalid
						</Typography>
						<Typography
							style={{
								marginBottom: 16,
							}}
						>
							Your invitation may have expired.
						</Typography>
						<Typography
							style={{
								marginBottom: 32,
							}}
						>
							Please contact your admins for a new invitation
						</Typography>
						<Button
							stretch={true}
							onClick={() => {
								alert("Back to login");
							}}
							style={{
								marginBottom: 72,
							}}
						>
							Back to login
						</Button>
					</NarrowOnboarding>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
