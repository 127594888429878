import { ResponsiveTableProps } from "./ResponsiveTable";
import styles from "./SubtableCollapseArrowMixin.module.scss";

export type RenderSubtableCollapseArrowTableMixin<T> = {
	/**
	 * Return true from this function if the table should render subtable collapse arrow.
	 */
	showSubtableCollapseArrow?: boolean;
	/**
	 * Allows hiding of collapse arrows for specific rows.
	 */
	showSubtableCollapseArrowForRow?: (row: T) => boolean;
};

/**
 * Shows subtable collapse arrow. Note: only shows up if expandedRowRender is also defined.
 */
export const useRenderSubtableCollapseArrowMixin = <T,>(
	props: ResponsiveTableProps<T>
): ResponsiveTableProps<T> => {
	const {
		showSubtableCollapseArrow = false,
		showSubtableCollapseArrowForRow = () => true,
		...cleanedProps
	} = props;
	const { columns } = props;

	if (!showSubtableCollapseArrow) {
		return cleanedProps;
	}

	return {
		...cleanedProps,
		columns: [
			{
				key: "empty_for_subtable_collapse_arrow",
				width: 48,
				className: styles.SubtableCollapseArrow,
				onCell: (row) => {
					if (showSubtableCollapseArrowForRow(row)) {
						return {};
					}

					return {
						className: styles.hideSubtableCollapseArrow,
					};
				},
			},
			...(columns || []),
		],
	};
};
