import React, { useRef, useEffect, createContext, useState } from "react";
import {
	useParams,
	useHistory,
	useLocation,
} from "@common/helpers/Hooks/UseRouterDom";
import { FREEZER_PATHS } from "../index";
import { prepareItemPrint } from "@redux/freezer/BoxViewSlice";
import { appSelector, useAppDispatch } from "@redux/store";
import { ItemAttachment } from "@common/types";
import { LoadingSpinner, Typography } from "@common/components";
import { useOrganizationRouter } from "@root/AppRouter";
import { usePrintKeyDown } from "@helpers/Print/PrintHelper";
import SafeLeavingGuardProvider from "@common/context/SafeLeavingGuardContext";
import PrintItem from "./PrintItem";
import styles from "./BoxView.module.scss";
import BoxCategory from "../Category/BoxCategory";
import BoxTable from "../table/BoxTable";
import { NewsPopUp } from "@common/components/NewsPopUp/NewsPopUp";
import INLINE_EDITING_GIF from "./assets/inline-editing.gif";

import {
	BoxActivityLogKeyContextProvider,
	useBoxView,
} from "@containers/Freezer/table/BoxTableHooks";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/dist/query";
import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import ItemBarcodeModal, {
	BarcodeModalProvider,
} from "@common/barcode/BarcodeModal";
import { useNavigateToCreateOrderPage } from "@helpers/Hooks/useNavigateToCreateOrderPage";
import BoxReagentSetting from "../components/BoxReagentSetting/BoxReagentSetting";
import { usePreferences } from "@helpers/Hooks/UsePreferencesHook";
import { useItemQuery } from "@redux/inventory/Item";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~ BOX VIEW CONTEXT ~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export default function BoxView() {
	const [showInlineEditingAnnouncement, setShowInlineEditingAnnouncement] =
		usePreferences("SHOW_INLINE_EDIT_ANNOUNCEMENT");

	const history = useHistory();
	const dispatch = useAppDispatch();
	const { appendBaseUrl, getToolRoute } = useOrganizationRouter();
	const printLoading =
		appSelector((state) => state.freezer.boxView.itemPrintId.status) ===
		"LOADING";
	const { freezer, box, isBoxLoading, itemIdInUrl, setItemIdInUrl } =
		useBoxView();
	const { box_id } = useParams<{
		box_id: string;
	}>();
	const location = useLocation<{ isGroup: boolean }>();

	const { data: item, error: itemFetchError } = useItemQuery(
		itemIdInUrl || skipToken
	);

	// Clear the item id from the url, only do this in the regular box view component to prevent race conditions
	useEffect(() => {
		if ((itemFetchError as FetchBaseQueryError)?.status === 404) {
			setItemIdInUrl(null);
		}
	}, [itemFetchError]);

	useEffect(() => {
		if (isNaN(+box_id)) {
			console.error("Failed to fetch box", box);
			history.replace(getToolRoute("NOT_FOUND"));
			return;
		}
	}, []);

	useEffect(() => {
		if (freezer && freezer.is_locked) {
			history.push({
				pathname: appendBaseUrl(FREEZER_PATHS.HOME),
			});
		}
	}, [freezer]);

	useEffect(() => {
		if (!item) return;
		SegmentTrackEvent(SegmentFreezerEvents.ITEM_VIEW, {
			id: item.id,
			item_type: item.item_type,
		});
	}, [item]);

	/** add hot key for print */
	usePrintKeyDown((e) => {
		if (item) {
			e.preventDefault();
			dispatch(prepareItemPrint(item.id));
		}
	});

	const infoContainerRef = useRef<HTMLDivElement>(null);
	return (
		<LoadingSpinner loading={isBoxLoading || !box} centered size="large">
			{printLoading && (
				<LoadingSpinner
					loading
					centered
					size="large"
					tip="Preparing file..."
					containerClassName={styles.printSpinner}
					wrapper
				>
					<div className={styles.printSpinnerContent} />
				</LoadingSpinner>
			)}
			<BoxActivityLogKeyContextProvider>
				<BarcodeModalProvider>
					<SafeLeavingGuardProvider>
						<TempAttachmentsContextProvider>
							<div
								style={{
									width: "100%",
									height: "100%",
									position: "relative",
								}}
								ref={infoContainerRef}
							>
								{location.state?.isGroup ? (
									<>
										{isBoxLoading || !box ? (
											<div
												style={{
													height: "100%",
													width: "100%",
													display: "grid",
													placeItems: "center",
												}}
											>
												<LoadingSpinner loading />
											</div>
										) : (
											<BoxCategory />
										)}
									</>
								) : (
									<BoxTable key={`boxTable-${box_id}`} />
								)}
							</div>
						</TempAttachmentsContextProvider>
					</SafeLeavingGuardProvider>
					<ItemBarcodeModal />
				</BarcodeModalProvider>
			</BoxActivityLogKeyContextProvider>
			<PrintItem />
			<BoxReagentSetting />
			<NewsPopUp
				visible={false}
				title="We now support in-line editing"
				img={INLINE_EDITING_GIF}
				content={
					<div>
						<Typography>
							Click on the fields to edit directly in place
						</Typography>
						<Typography>
							- Press <b>ENTER</b> or <b>click outside</b> to save
						</Typography>
						<Typography>
							- Press <b>ESC</b> to cancel
						</Typography>
					</div>
				}
				onButtonClick={() => setShowInlineEditingAnnouncement(false)}
			/>
		</LoadingSpinner>
	);
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~ ATTACHMENT CONTEXT ~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

type TempAttachmentsContextProps = {
	tempAttachments: ItemAttachment[];
	setTempAttachments: (attachments: ItemAttachment[]) => void;
};

export const TempAttachmentsContext =
	createContext<TempAttachmentsContextProps>({
		tempAttachments: [],
		setTempAttachments: () => {},
	});

const TempAttachmentsContextProvider = (props: any) => {
	const [tempAttachments, setTempAttachments] = useState<ItemAttachment[]>(
		[]
	);

	return (
		<TempAttachmentsContext.Provider
			value={{ tempAttachments, setTempAttachments }}
		>
			{props.children}
		</TempAttachmentsContext.Provider>
	);
};

/**
 * Opens order form for the current item
 */
type Params = {
	fromBoxView: boolean;
	itemId?: number;
} | void;
export const useOpenOrderFormForItem = (params: Params) => {
	const { item } = useBoxView();
	const navigateToCreateOrderPage = useNavigateToCreateOrderPage(params);

	return () => {
		if (!item) return;
		const {
			name,
			source,
			reference,
			catalog,
			lot,
			packaging,
			price,
			currency,
			concentration,
			volume,
		} = item;

		navigateToCreateOrderPage({
			mode: "create",
			prefill: {
				name,
				source,
				reference,
				catalog,
				lot,
				packaging,
				price,
				currency,
				concentration,
				volume,
				item: item.id,
			},
		});
	};
};
