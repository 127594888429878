import {
	Link as Lnk,
	useHistory,
	useLocation,
} from "@common/helpers/Hooks/UseRouterDom";
import {
	Button,
	Checkbox,
	GenemodIcon,
	Input,
	LayerSystemContainer,
	Typography,
} from "@components";
import { useLoginContext } from "@containers/Auth/LoginInterfaces/LoginContext";
import {
	NarrowOnboarding,
	TransitionScreen,
} from "@containers/Auth/components";
import GENEMOD_LOGO from "@containers/Auth/components/NarrowOnboarding/genemod_logo_light.png";
import { useLoginMutation } from "@redux/user/UserApi";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
	buildAcceptInvitationUrl,
	useInvitationState,
} from "../AcceptInvitation/AcceptInvitation";
import "../index.scss";
import styles from "./index.module.scss";
import LOGIN_IMG from "./login-illustration.png";

export const useRedirectState = () => {
	const location = useLocation();
	const pathname = (location as any)?.state?.nextPathname || "/app";
	const search = (location as any)?.state?.nextSearch;
	return [pathname, search] as const;
};

const ADMIN_URL = "/admin";

const useIsEnterpriseAdminLogin = () => {
	const location = useLocation();
	return location.pathname.includes(ADMIN_URL);
};

/**
 * Login page
 * @function
 * @category Auth
 * @subcategory Login
 */
export default function Login(): JSX.Element {
	type dataProps = {
		[key: string]: string;
		email: string;
		password: string;
	};
	const history = useHistory();
	const { login, loginStatus } = useLoginContext();
	const invitationState = useInvitationState();
	const [onLogin] = useLoginMutation();
	const isInvitation = !!invitationState;
	const [redirectPath, redirectSearch] = useRedirectState();
	const [rememberLogin, setRemember] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [viewPass, setViewPass] = useState(false);

	const isEnterpriseAdminLogin = useIsEnterpriseAdminLogin();

	useEffect(() => {
		if (loginStatus === "LOGGEDIN") {
			if (isEnterpriseAdminLogin) {
				history.push({ pathname: ADMIN_URL });
			} else {
				history.push("/app");
			}
		}
	}, [loginStatus, isEnterpriseAdminLogin]);

	const [data, setData] = useState({
		email: invitationState?.email || "",
		password: "",
	} as dataProps);

	const cleanData = {
		...data,
		email: data.email?.trim().toLowerCase(),
	};

	const loginFailed = () => {
		setError("*Incorrect email or password");
		setLoading(false);
		setSuccess(false);
	};

	const loginSuccess = () => {
		setLoading(false);
		setSuccess(true);
	};

	// Login with cognito
	const handleCognitoLogin = (failureCallback: () => void) => async () => {
		setLoading(true);
		const results = await login(cleanData, rememberLogin);
		ReactDOM.unstable_batchedUpdates(() => {
			if (results.type === "failure") {
				failureCallback();
				return;
			}
			loginSuccess();
		});
	};

	// Check migration service (old login url) to see if they have logged into the old system with the correct credentials
	const checkMigrationService = () => {
		onLogin(cleanData)
			.unwrap()
			.then(() =>
				// Add a small delay to allow time for cognito user created on the backend to become stable.
				setTimeout(handleCognitoLogin(loginFailed), 250)
			)
			.catch(() => {
				loginFailed();
			})
			.finally(() => setLoading(false));
	};

	const handleSubmit = handleCognitoLogin(checkMigrationService);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const temp = { ...data } as dataProps;
		temp[e.target.id] = e.target.value;
		setData(temp);
		if (error) setError("");
	};

	const [passwordOnFocus, setPasswordOnFocus] = React.useState(false);
	const onFocus = () => setPasswordOnFocus(true);

	const reroute = () => {
		if (isInvitation) {
			history.push({
				...buildAcceptInvitationUrl(invitationState),
				state: {},
			});
			return;
		}
		if (isEnterpriseAdminLogin) {
			history.push({ pathname: ADMIN_URL });
			return;
		}
		// Even though we do this also in the router we have to do it here because of a race condition (?)
		history.push({
			pathname: redirectPath,
			search: redirectSearch,
		});
	};

	const loginBody = (
		<>
			<Input
				id="email"
				value={data.email}
				label="Email"
				error={error}
				gutterBottom
				onChange={handleChange}
				onPressEnter={handleSubmit}
				autoFocus
				autoComplete="email"
				disabled={isInvitation}
				dataCy="login-email"
			/>
			<Input
				id="password"
				value={data.password}
				label="Password"
				hideError
				gutterBottom
				type={viewPass ? "text" : "password"}
				onChange={handleChange}
				onPressEnter={handleSubmit}
				autoComplete="current-password"
				onFocus={onFocus}
				suffix={
					passwordOnFocus ? (
						<GenemodIcon
							name={viewPass ? "eye" : "eye-off"}
							size="large"
							style={{ transform: "translateX(4px)" }}
							onClick={() => setViewPass(!viewPass)}
							onFocus={onFocus}
							fill="button-primary"
						/>
					) : undefined
				}
				dataCy="login-password"
			/>
			<div
				className={"auth-flexRow"}
				style={{
					width: "100%",
				}}
			>
				<Checkbox
					value={rememberLogin}
					onChange={() => {
						setRemember(!rememberLogin);
					}}
					data-cy="login-remember-me"
				>
					Remember me
				</Checkbox>
				<Typography variant="label" data-cy="forgot-password">
					<Link to="/forgot-password">Forgot password?</Link>
				</Typography>
			</div>
			<Button
				onClick={handleSubmit}
				loading={loading}
				className={styles.loginButton}
				stretch
				data-cy="login-submit-button"
			>
				Login
			</Button>
		</>
	);

	if (success) {
		return <TransitionScreen timeout={2} onFinish={reroute} />;
	}

	if (isEnterpriseAdminLogin) {
		return <AdminLoginModal>{loginBody}</AdminLoginModal>;
	}

	return (
		<NarrowOnboarding className={styles.loginModal}>
			<Typography variant="headline" bold className={styles.smallHeader}>
				Don’t have an account?
				<Link
					style={{
						marginLeft: "8px",
						color: "var(--button-text)",
					}}
					to="/signup"
				>
					Sign up
				</Link>
			</Typography>
			<div style={{ marginTop: 32 }}></div>
			{loginBody}
		</NarrowOnboarding>
	);
}

export function Link(props: any): JSX.Element {
	return (
		<Lnk {...props} className="auth-link">
			{props.children}
		</Lnk>
	);
}

type AdminLoginModalProps = {
	children: JSX.Element | React.ReactDOM;
};

export function AdminLoginModal({
	children,
}: AdminLoginModalProps): JSX.Element {
	return (
		<LayerSystemContainer
			overrideLayer={1}
			className={styles.enterpriseAdminLoginPageWrapper}
		>
			<LayerSystemContainer className={styles.enterpriseAdminLoginModal}>
				<div className={styles.leftSection}>
					<div className={styles.header}>
						<Typography
							variant="caption"
							bold
							color="accent-subtle"
							style={{ marginBottom: 4 }}
						>
							Enterprise
						</Typography>
						<Typography
							variant="title"
							bold
							className={styles.smallHeader}
						>
							Admin console login
						</Typography>
					</div>
					{children}
				</div>
				<LayerSystemContainer className={styles.rightSection}>
					<img src={LOGIN_IMG} className={styles.loginImg} />
					<img
						src={GENEMOD_LOGO}
						width={210}
						height={44}
						className={styles.logo}
					/>
				</LayerSystemContainer>
			</LayerSystemContainer>
		</LayerSystemContainer>
	);
}
