import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	ExperimentSearchPayload,
	FileAttachmentSearchPayload,
} from "./PmApiSlice";

// ~~~~~~~~~ PROJECT PAGE ~~~~~~~~~

export type ExperimentTableFilters = Omit<
	ExperimentSearchPayload,
	"parent_project" | "parent_subproject"
>;

export const projectPageSlice = createSlice({
	name: "projectPage",
	initialState: {
		experimentTableFilters: {
			sort_by: "due_date",
			status: [],
		} as ExperimentTableFilters,
		attachmentTableFilters: {
			sort_by: "-updated_at",
			page: 1,
		} as FileAttachmentSearchPayload["params"],
	},
	reducers: {
		setExperimentTableFilters: (
			state,
			action: PayloadAction<ExperimentTableFilters>
		) => {
			state.experimentTableFilters = action.payload;
		},
		setAttachmentTableFilters: (
			state,
			action: PayloadAction<FileAttachmentSearchPayload["params"]>
		) => {
			state.attachmentTableFilters = action.payload;
		},
	},
});

export const { setExperimentTableFilters, setAttachmentTableFilters } =
	projectPageSlice.actions;
