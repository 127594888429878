import {
	Column,
	GridCoord,
	Row,
} from "@common/components/InteractiveGrid/GridTypes";
import { SafeLeavingGuardContext } from "@common/context/SafeLeavingGuardContext";
import { useLocation } from "@common/helpers/Hooks/UseRouterDom";
import { CellId } from "@common/types";
import { useForceUpdate, useSafeWindowEventListener } from "@helpers/Hooks";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import { actionOnTypeLiteral } from "@helpers/TypeHelpers";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import {
	getBoxTableState,
	useBoxTableActions,
	useBoxTableFields,
} from "@redux/freezer/BoxTableSlice";
import { searchResultsSelector } from "@redux/freezer/BoxViewSlice";
import { appSelector } from "@redux/store";
import React, { useContext, useEffect, useState } from "react";
import ItemMovingCart from "../components/MovingCart/ItemMovingCart";
import {
	boundCellCoord,
	getAreaBounds,
	getCellId,
	getMaxBounds,
	parseCellId,
	toCellId,
	wrapCellCoord,
} from "../data";
import BoxImport from "./BoxImport";
import { useBoxView, useCopySelected, useSetCellId } from "./BoxTableHooks";
import styles from "./Table.module.scss";
import { InnerBoxTable } from "./components/InnerBoxTable/InnerBoxTable";
import RightSideInfoPanels from "./components/RightSideInfoPanels/RightSideInfoPanels";
import { useOpenDeleteSelectedBoxTableCellsModal } from "./components/SelectedCellActionModals/SelectedCellActionModals";

export default React.memo(function BoxTable(): JSX.Element {
	const { isShareLinkModalVisible } = useCommonModalState("shareLinkModal");
	const { is_limit_reached: isItemsLimitReached } =
		useFeatureRestrictionHook("items");
	const { state } = useLocation<{ isImporting: boolean }>();

	// We're setting refs inside our mouse/keyboard event handlers to prevent race conditions. However, changing a ref does not trigger a rerender. This hook will allow us to force rerenders.
	const forceUpdate = useForceUpdate();
	const { box } = useBoxView();
	const selectedItemIndex = appSelector(
		(state) => state.freezer.boxView.selectedItemIndex
	);
	const searchResults = appSelector((state) =>
		searchResultsSelector.selectAll(state)
	);
	const { isFocused, isImporting, movingItems, draggingState } =
		useBoxTableFields(
			"isFocused",
			"isImporting",
			"movingItems",
			"draggingState"
		);

	const {
		setSelectedAreaBounds,
		clearSelectedAreaBounds,
		setIsImporting: _setIsImporting,
		resetBoxTableState,
	} = useBoxTableActions();

	const copySelected = useCopySelected();
	const { openPasteSelectedBoxTableCellsModal } = useCommonModalState(
		"pasteSelectedBoxTableCellsModal"
	);
	const deleteSelected = useOpenDeleteSelectedBoxTableCellsModal();
	const { openUpgradeModal } = useCommonModalState("upgradeModal");
	const setCellID = useSetCellId();
	const { shouldBlock, clearAllShouldBlockKeys, showShouldBlockModal } =
		useContext(SafeLeavingGuardContext);

	useEffect(() => {
		resetBoxTableState();
	}, [box?.id]);
	useSelectFirstCellIfTableIsEmpty();
	useSyncSelectionWithUrlParams();

	const setIsImporting = (isImporting: boolean) => {
		// Show an upgrade modal if the team has hit their item creation limit
		if (isImporting && isItemsLimitReached) {
			openUpgradeModal({ type: "ITEMS" });
			return;
		}

		_setIsImporting(isImporting);
	};

	useEffect(() => {
		if (searchResults.length && selectedItemIndex !== "UNSELECTED") {
			const proceedWithSelect = () => {
				const cellID = getCellId(searchResults[selectedItemIndex]);
				setCellID(cellID, "SET");
				clearSelectedAreaBounds(); //todo: verify
				// setSelectedCells([cellID]);
				forceUpdate();
			};
			if (shouldBlock) {
				showShouldBlockModal((option) => {
					if (option === "OK") {
						clearAllShouldBlockKeys();
						proceedWithSelect();
					}
				});
			} else {
				proceedWithSelect();
			}
		}
	}, [searchResults, selectedItemIndex]);

	useEffect(() => {
		if (state?.isImporting) {
			_setIsImporting(true);
		} else {
			_setIsImporting(false);
		}
	}, [state]);

	const handleHotkeys = async (e: KeyboardEvent) => {
		if (
			!isFocused ||
			movingItems ||
			!box ||
			box?.is_archived ||
			draggingState !== "NOT_DRAGGING" ||
			isShareLinkModalVisible
		) {
			return;
		}
		const { cellId } = getBoxTableState();
		if (e.ctrlKey || e.metaKey) {
			const { key } = e;
			switch (key) {
				case "c":
					await copySelected({ cutNotCopy: false });
					break;
				case "x":
					await copySelected({ cutNotCopy: true });
					break;
				case "v":
					e.preventDefault();
					openPasteSelectedBoxTableCellsModal({
						focusModeOnly: true,
					});
					break;
			}
		} else if (e.key === "Escape") {
			clearSelectedAreaBounds();
			setCellID(null);
		} else if (e.key === "Delete" || e.key === "Backspace") {
			deleteSelected();
		} else if (e.key === "Tab") {
			e.preventDefault();

			// Prevent tab key when search results exist
			if (searchResults.length) return;

			const coord = parseCellId(cellId || "A0");
			const moveIncrement = e.shiftKey ? -1 : 1;

			const newCellId = toCellId(
				wrapCellCoord(getMaxBounds(box))({
					...coord,
					column: coord.column + moveIncrement,
				} as GridCoord)
			);

			setCellID(newCellId);
		} else {
			handleArrowKeys(e);
		}
		forceUpdate();
	};
	useSafeWindowEventListener("keydown", handleHotkeys);

	const handleArrowKeys = (e: KeyboardEvent) => {
		if (!isFocused || !box) return;
		const arrowKeyList = [
			"ArrowUp",
			"ArrowDown",
			"ArrowLeft",
			"ArrowRight",
		] as const;
		const key = e.key as (typeof arrowKeyList)[number];

		if (!arrowKeyList.includes(key)) {
			// if input is not arrow key
			return;
		}

		e.preventDefault();
		const { cellId, selectedAreaBounds } = getBoxTableState();

		// if the shift key is pressed, we want to change the selected range of cells
		if (cellId && e.shiftKey && selectedAreaBounds.length === 1) {
			const { topLeft, bottomRight } = selectedAreaBounds[0];
			const { row, column } = parseCellId(cellId);

			const boundHeight = (num: number) =>
				Math.max(0, Math.min(num, box.rows)) as Row;
			const boundWidth = (num: number) =>
				Math.max(0, Math.min(num, box.columns)) as Column;

			const bottomIsBelowRow = row < bottomRight.row;
			const topIsAboveRow = row > topLeft.row;
			const leftIsLeftOfColumn = column > topLeft.column;
			const rightIsRightOfColumn = column < bottomRight.column;

			actionOnTypeLiteral(key)({
				ArrowUp: () => {
					if (bottomIsBelowRow) {
						setSelectedAreaBounds([
							{
								bottomRight: {
									column: bottomRight.column,
									row: boundHeight(bottomRight.row - 1),
								},
								topLeft,
							},
						]);
					} else {
						setSelectedAreaBounds([
							{
								bottomRight,
								topLeft: {
									column: topLeft.column,
									row: boundHeight(topLeft.row - 1),
								},
							},
						]);
					}
				},
				ArrowDown: () => {
					if (topIsAboveRow) {
						setSelectedAreaBounds([
							{
								bottomRight,

								topLeft: {
									column: topLeft.column,
									row: boundHeight(topLeft.row + 1),
								},
							},
						]);
					} else {
						setSelectedAreaBounds([
							{
								topLeft,
								bottomRight: {
									column: bottomRight.column,
									row: boundHeight(bottomRight.row + 1),
								},
							},
						]);
					}
				},
				ArrowLeft: () => {
					if (rightIsRightOfColumn) {
						setSelectedAreaBounds([
							{
								bottomRight: {
									column: boundWidth(bottomRight.column - 1),
									row: bottomRight.row,
								},
								topLeft,
							},
						]);
					} else {
						setSelectedAreaBounds([
							{
								bottomRight,
								topLeft: {
									column: boundWidth(topLeft.column - 1),
									row: topLeft.row,
								},
							},
						]);
					}
				},
				ArrowRight: () => {
					if (leftIsLeftOfColumn) {
						setSelectedAreaBounds([
							{
								bottomRight,
								topLeft: {
									column: boundWidth(topLeft.column + 1),
									row: topLeft.row,
								},
							},
						]);
					} else {
						setSelectedAreaBounds([
							{
								bottomRight: {
									column: boundWidth(bottomRight.column + 1),
									row: bottomRight.row,
								},
								topLeft,
							},
						]);
					}
				},
			});
			return;
		}

		let newCellId = "A1" as CellId;
		if (cellId) {
			const { row, column } = parseCellId(cellId);
			newCellId = toCellId(
				boundCellCoord(getMaxBounds(box))(
					actionOnTypeLiteral(key)({
						ArrowUp: () =>
							({
								row: row - 1,
								column,
							} as GridCoord),
						ArrowDown: () =>
							({
								row: row + 1,
								column,
							} as GridCoord),
						ArrowLeft: () =>
							({
								row,
								column: column - 1,
							} as GridCoord),
						ArrowRight: () =>
							({
								row,
								column: column + 1,
							} as GridCoord),
					})
				)
			);
		}
		clearSelectedAreaBounds();
		setCellID(newCellId, "SET");
		const newCoord = parseCellId(newCellId);
		setSelectedAreaBounds([{ topLeft: newCoord, bottomRight: newCoord }]);
	};

	return (
		<div className={styles.infoWrapper}>
			{isImporting ? (
				<BoxImport setImporting={setIsImporting} />
			) : (
				<div className={styles.boxWrapper}>
					<InnerBoxTable />
					{movingItems && box && <ItemMovingCart />}
					<RightSideInfoPanels />
				</div>
			)}
		</div>
	);
});

/**
 * Checks if there is an item id on page load and handles selecting the cell
 */
const useSyncSelectionWithUrlParams = () => {
	const { item, box } = useBoxView();
	const { setSelectedAreaBounds, doNotUse_setCellId } = useBoxTableActions();
	const { selectedAreaBounds } = useBoxTableFields("selectedAreaBounds");

	// Update state when the item id in the url changes.
	useEffect(() => {
		if (!item) return;
		const cellId = getCellId(item);
		doNotUse_setCellId(cellId);
		if (selectedAreaBounds.length > 0) return;
		setSelectedAreaBounds([getAreaBounds([cellId])]);
	}, [
		item,
		getCellId,
		doNotUse_setCellId,
		getAreaBounds,
		setSelectedAreaBounds,
		box
	]);
};

const useSelectFirstCellIfTableIsEmpty = () => {
	const { box, viewOnly, itemIdInUrl } = useBoxView();
	const { setSelectedAreaBounds, doNotUse_setCellId } = useBoxTableActions();
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (checked) return;

		if (!box) return;

		// If the box is not empty or the user is viewing the box we do not need to select the first cell
		if (viewOnly || itemIdInUrl || box.item_count > 0) {
			setChecked(true);
			return;
		}

		const cellId = "A1";
		doNotUse_setCellId(cellId);
		setSelectedAreaBounds([getAreaBounds([cellId])]);
	}, [
		checked,
		box,
		viewOnly,
		itemIdInUrl,
		doNotUse_setCellId,
		setSelectedAreaBounds,
		getAreaBounds,
		setChecked,
	]);
};
