import React, { useEffect } from "react";
import { useGetLocation } from "@helpers/Hooks/useGetLocationHook";
import GenemodIcon, { GenemodIconProps } from "../GenemodIcon/GenemodIcon";
import styles from "./HelpCenterNavbarButton.module.scss";
import classNames from "classnames";
import { useWhatsNewNotificaion } from "@helpers/Hooks/UseWhatsNewNotification";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";

const HelpCenterNavbarButton = (props: GenemodIconProps): JSX.Element => {
	const currentLocation = useGetLocation();
	const { isHelpCenterVisible, toggleHelpCenter, closeHelpCenter } =
		useCommonPanelState("helpCenter");
	const showIndicator = useWhatsNewNotificaion();

	useEffect(() => {
		closeHelpCenter();
	}, [currentLocation]);

	return (
		<div
			data-cy="help-center-navbar-button"
			{...props}
			className={styles.notificationIconWrapper}
		>
			<GenemodIcon
				{...props}
				className={classNames(styles.icon, {
					[styles.icon__active]: isHelpCenterVisible,
				})}
				name="question-mark-o"
				size="large"
				onClick={() => toggleHelpCenter()}
				showNotification={showIndicator}
			/>
		</div>
	);
};

export default HelpCenterNavbarButton;
