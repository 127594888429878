import {
	Button,
	Demo,
	DemoSection,
	DemoWrapper,
	Input,
	Modal,
	Notification,
	RadioButton,
	RadioGroup,
	Select,
	Spin,
	Tooltip,
	Typography,
} from "@components";
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	Elements,
	useElements,
} from "@stripe/react-stripe-js";
import {
	Stripe,
	StripeCardCvcElementChangeEvent,
	StripeCardExpiryElementChangeEvent,
	StripeCardNumberElement,
	StripeCardNumberElementChangeEvent,
	StripeElements,
} from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import STRIPE_LOGO_DARK from "./assets/stripe_logo_dark.png";
import STRIPE_LOGO_LIGHT from "./assets/stripe_logo_light.png";
import styles from "./index.module.scss";
import "./index.scss";

import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import useActivePriceId from "@common/helpers/Hooks/useActivePriceId";
import { Color, ColorHexMap, HexColorToRgbaText } from "@common/styles/Colors";
import { Price, PromotionCode, getPriceMapping } from "@common/types/Stripe";
import { useNewSubscriptionEvent } from "@helpers/Hooks/UseAnalyticsEventHook";
import useCurrentFeatureRestriction from "@helpers/Hooks/useCurrentFeatureRestrictionHook";
import useCurrentOrganization from "@helpers/Hooks/useCurrentOrganizationHook";
import useCurrentSubscription from "@helpers/Hooks/useCurrentSubscriptionHook";
import useCurrentTeamMembers from "@helpers/Hooks/useCurrentTeamMembersHooks";
import { withOrWithoutStripe } from "@helpers/WithOrWithoutStripeHOC";
import {
	CURRENT_PRICES,
	useChangeSubscriptionPlanMutation,
	useInvoiceChangePreviewQuery,
	useLazyPromotionCodeQuery,
	useProductPricing,
	useProducts,
	useStripeCustomer,
} from "@redux/Account/AccountApi";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { useOrganizationRouter } from "@root/AppRouter";
import { ACCOUNT_SETTINGS_PATHS } from "@root/routes";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import { useTheme } from "../Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "../Theme/context/ThemeTypes";
import { COUNTRIES } from "./data";
import GENEMOD_LOGO_DARK from "./genemod_logo_dark.png";
import GENEMOD_LOGO_LIGHT from "./genemod_logo_light.png";

const MIN_TEAM_PLAN_SEATS = 3;
export const DEFAULT_CARD_ERROR =
	"Oops! Your card was declined. Please try again. Contact your bank if the issue continues.";

export enum PAYMENT_OPTIONS {
	ADDED_PAYMENT,
	EXISTING_PAYMENT,
	NEW_PAYMENT,
}

/** Form data not handled by stripe elements */
export type LocalFormData = {
	name: string;
	zipcode: string;
	region: string;
};

// Stripe Logo component adjusting based on theme color
export function StripeLogo(): JSX.Element {
	const [themeMode] = useTheme();
	return (
		<img
			style={{
				height: 24,
				width: "auto",
			}}
			src={
				themeMode === THEME_PREFERENCE.dark
					? STRIPE_LOGO_DARK
					: STRIPE_LOGO_LIGHT
			}
			alt="powered by stripe"
		/>
	);
}

/**
 * Stripe based payment form. To access the form data,
 * refer to this page of the Stripe API:
 * https://stripe.com/docs/stripe-js/react#useelements-hook
 *
 * The Genemod application is wrapped in a `Stripe` context provider.
 * Any Stripe element can be accessed at any level of the app.
 * See the `GenemodApp` component.
 * */
export function PaymentForm(props: {
	/** Change callback function for data that is **not** handled by Stripe elements */
	onChange?: (data: LocalFormData) => void;
	/** Styling for the parent container */
	style?: React.CSSProperties;
	/** Called when the validity of the form changes */
	onValidityChange?: (arg0: boolean) => void;
}): JSX.Element {
	const { Option } = Select as any;
	const [formData, setFormData] = useState<LocalFormData>({
		name: "",
		zipcode: "",
		region: "US",
	});
	const { name, zipcode, region } = formData;
	const [nameTouched, setNameTouched] = useState(false);
	const [zipcodeTouched, setZipcodeTouched] = useState(false);

	const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!nameTouched) setNameTouched(true);
		const value = e.target.value;
		setFormData((prev) => ({ ...prev, name: value }));
	};

	const handleZip = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!zipcodeTouched) setZipcodeTouched(true);
		const value = e.target.value;
		setFormData((prev) => ({
			...prev,
			zipcode: value,
		}));
	};

	const handleRegionChange = (region: string) => {
		setFormData((prev) => ({
			...prev,
			region,
		}));
	};

	useEffect(() => {
		props.onChange?.(formData);
	}, [formData]);

	// Used to validate the form state
	const [cardNumData, setCardNumData] =
		useState<StripeCardNumberElementChangeEvent | null>(null);
	const [cardExpiryData, setCardExpiryData] =
		useState<StripeCardExpiryElementChangeEvent | null>(null);
	const [cardCvcData, setCardCvcData] =
		useState<StripeCardCvcElementChangeEvent | null>(null);

	// Trigger onValidityChange callback depending on input
	const [isValid, setValid] = useState<boolean>(false);
	useEffect(() => {
		const validity = Boolean(
			cardNumData?.complete &&
				cardExpiryData?.complete &&
				cardCvcData?.complete &&
				!!formData.name.trim() &&
				!!formData.zipcode.trim()
		);
		if (validity !== isValid) {
			setValid(validity);
			props.onValidityChange?.(validity);
		}
	}, [formData, cardNumData, cardExpiryData, cardCvcData]);

	/** Styling options for Stripe elements */
	const [themeMode] = useTheme();

	const GetStripeInputTextColorOptions = (
		themeMode: THEME_PREFERENCE,
		hover: Color,
		focus: Color,
		placeholder: Color
	) => {
		return {
			":hover": {
				color: HexColorToRgbaText({
					color: hover as Color,
					themeMode,
				}),
			},

			":focus": {
				color: HexColorToRgbaText({
					color: focus as Color,
					themeMode,
				}),
			},

			"::placeholder": {
				color: HexColorToRgbaText({
					color: placeholder as Color,
					opacity: 0.45,
					themeMode,
				}),
			},
		};
	};

	const CARD_OPTIONS = {
		style: {
			base: {
				...GetStripeInputTextColorOptions(
					themeMode,
					"text-ghost",
					"text-ghost",
					"text-tertiary"
				),
				iconColor: ColorHexMap[themeMode as 0 | 1]["text-secondary"],
				color: ColorHexMap[themeMode as 0 | 1]["text-primary"],
			},
			empty: GetStripeInputTextColorOptions(
				themeMode,
				"text-ghost",
				"text-ghost",
				"text-tertiary"
			),
			invalid: GetStripeInputTextColorOptions(
				themeMode,
				"red-contrast",
				"red-contrast",
				"red-contrast"
			),
			complete: GetStripeInputTextColorOptions(
				themeMode,
				"text-secondary",
				"text-secondary",
				"text-secondary"
			),
		},
	};

	return (
		<div className={cn("genemod-payment-form")} style={props.style}>
			<Input
				value={name}
				onChange={handleName}
				validators={[
					{
						validator: (v: string) => !nameTouched || !!v.trim(),
						error: "Name is required",
					},
				]}
				label="Name on card"
				placeholder="Name"
			/>
			<FormLabel>Card information</FormLabel>
			<CardNumberElement
				options={{
					...CARD_OPTIONS,
					showIcon: true,
					placeholder: "•••• •••• •••• ••••",
				}}
				onChange={setCardNumData}
			/>
			<div className={styles.row}>
				<CardExpiryElement
					options={CARD_OPTIONS}
					className={styles.half}
					onChange={setCardExpiryData}
				/>
				<CardCvcElement
					options={CARD_OPTIONS}
					className={styles.half}
					onChange={setCardCvcData}
				/>
			</div>
			<FormLabel>Country or region</FormLabel>
			<div
				className={styles.row}
				style={{
					alignItems: "flex-start",
				}}
			>
				<Select
					value={region}
					wrapperClassname={styles.half}
					style={{ width: "100%" }}
					onChange={(val) => handleRegionChange(val as string)}
					optionLabelProp="label"
					isInput
				>
					{COUNTRIES.map((country) => (
						<Option
							key={country.code}
							value={country.code}
							label={
								<React.Fragment>
									<div style={{ display: "flex" }}>
										{country.name}
									</div>
								</React.Fragment>
							}
						>
							<Select.SelectedOpt
								isSelected={country.code == region}
								label={country.name}
							/>
						</Option>
					))}
				</Select>
				<Input
					value={zipcode}
					onChange={handleZip}
					wrapperProps={{ className: styles.half }}
					validators={[
						{
							validator: (v: string) =>
								!zipcodeTouched || !!v.trim(),
							error: "Required",
						},
					]}
					placeholder="ZIP or postal code"
				/>
			</div>
		</div>
	);
}

/** Styled label for the payment form */
function FormLabel(props: {
	children: JSX.Element | string | JSX.Element[];
}): JSX.Element {
	return (
		<Typography
			style={{ marginBottom: 8 }}
			variant="label"
			color="text-secondary"
			bold
		>
			{props.children}
		</Typography>
	);
}

/**
 * Modal for upgrading from a free plan to a paid plan
 */
export function UpgradePlanModal(props: {
	/** If true, displays the modal */
	visible: boolean;
	/** ID of the plan to upgrade to */
	planId: string;
}): JSX.Element {
	const { visible, planId } = props;

	return (
		<Modal
			visible={visible}
			title={
				<div
					className={styles.flexrow}
					style={{
						justifyContent: "space-between",
						paddingRight: "40px",
					}}
				>
					<Typography variant="title" bold>
						Payment method
					</Typography>
					<StripeLogo />
				</div>
			}
		>
			<div
				className={styles.flexrow}
				style={{ alignItems: "flex-start" }}
			>
				<div
					style={{
						paddingRight: "32px",
						borderRight: "1px solid var(--border-subtle)",
					}}
				>
					<PaymentForm />
				</div>
				<div style={{ paddingLeft: "32px", width: "300px" }}>
					<PlanSelector planId={planId} />
				</div>
			</div>
		</Modal>
	);
}

type ChangePlanModalInnerProps = {
	stripe: Stripe | null;
	elements: StripeElements | null;
};

function ChangePlanModalInner({
	stripe,
	elements,
}: ChangePlanModalInnerProps): JSX.Element {
	const history = useHistory();
	const [theme] = useTheme();
	const { appendBaseUrl } = useOrganizationRouter();
	const runNewSubscriptionEvent = useNewSubscriptionEvent();
	const { featureRestrictionData, fetchFeatureRestriction } =
		useCurrentFeatureRestriction();
	const planName = featureRestrictionData?.plan_name;
	const { fetchTeamMembers } = useCurrentTeamMembers();
	const [changeSubscriptionPlan] = useChangeSubscriptionPlanMutation();
	const { organizationId } = useCurrentOrganization();
	const [promoCode, setPromoCode] = useState<PromotionCode | null>(null);

	// Payment data and states
	const { subscription } = useCurrentSubscription();
	const products = useProducts();
	const { data: customer } = useStripeCustomer();
	const paymentExists = !!customer?.invoice_settings.default_payment_method;
	const teamProductId =
		products?.find((product) => product.name === "Team")?.id || "";
	const businessProductId =
		products?.find((product) => product.name === "Business")?.id || "";

	// Since the prices includes the old ones, we filter it out the old prices and include the current prices only
	const teamPrices = useProductPricing(teamProductId).filter((p) =>
		CURRENT_PRICES.includes(p.id)
	);
	const businessPrices = useProductPricing(businessProductId).filter((p) =>
		CURRENT_PRICES.includes(p.id)
	);

	const currentPriceId = useActivePriceId();
	const [priceId, setPriceId] = useState<string | undefined>(undefined);
	const allPrices = teamPrices
		.concat(businessPrices)
		.filter((p) => p.id !== currentPriceId);
	const { activeTeamMembers } = useCurrentTeamMembers();
	const nActiveMembers = activeTeamMembers.length;
	const defaultSeats = Math.max(
		nActiveMembers,
		subscription?.quantity || MIN_TEAM_PLAN_SEATS
	);
	const currentSelectedPrice = allPrices.find((p) => p.id === priceId);
	const [paymentSelection, setPaymentSelection] = useState<PAYMENT_OPTIONS>(
		paymentExists
			? PAYMENT_OPTIONS.EXISTING_PAYMENT
			: PAYMENT_OPTIONS.NEW_PAYMENT
	);
	const [billingAddress, setBillingAddress] = useState<LocalFormData>();
	const [isValid, setValid] = useState<boolean>(false);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const isTeam = teamPrices.some((p) => p.id === currentPriceId);
	const isBusiness = businessPrices.some((p) => p.id === currentPriceId);

	// Modal data and states
	const {
		isChangePlanModalVisible,
		closeChangePlanModal,
		changePlanModalData,
	} = useCommonModalState("changePlanModal");

	const { defaultBusiness } = changePlanModalData;

	// Close upgrade modals when the payment modal is visible
	const { closeUpgradeModal } = useCommonModalState("upgradeModal");

	useEffect(() => {
		if (isChangePlanModalVisible) {
			closeUpgradeModal();
		}
	}, [isChangePlanModalVisible]);

	useEffect(() => {
		if (!isChangePlanModalVisible) {
			setPriceId(undefined);
			setError("");
		} else {
			// Handle the first selection of the plan
			const planDefaultPrices: { [key: string]: string } = {
				free: teamPrices?.[0]?.id,
				team: businessPrices?.[0]?.id,
				business: teamPrices?.[0]?.id,
			};

			if (defaultBusiness) {
				setPriceId(businessPrices?.[0]?.id);
			} else {
				setPriceId(planDefaultPrices[planName as string]);
			}
		}
	}, [isChangePlanModalVisible]);

	const buildOptionFromPrice = (price: Price | undefined) => {
		if (!price) {
			return <Spin />;
		}
		const isYearly = price?.recurring?.interval === "year";
		let unitCost = price.unit_amount / 100;
		if (isYearly) {
			unitCost = Math.floor(unitCost / 12);
		}
		return (
			<Select.Option key={price.id} value={price.id}>
				{price.product.name} (
				{price.recurring?.interval === "month" ? "Monthly" : "Annual"})
				- $ {unitCost} / user / month
			</Select.Option>
		);
	};

	/**
	 *
	 * @returns List of option menus based on current plan the org is on
	 * ex) if the org is on Team plan, then we show business prices only, and vice versa.
	 * if team is on free plan, we show all prices and they pick whether they want to upgrade to team or business plan.
	 */
	const getPriceOptions = () => {
		if (isTeam) {
			return businessPrices.map((price) => buildOptionFromPrice(price));
		} else if (isBusiness) {
			return teamPrices.map((price) => buildOptionFromPrice(price));
		} else {
			return allPrices.map((price) => buildOptionFromPrice(price));
		}
	};

	const getPaymentMethod = async (): Promise<string | undefined> => {
		// Stripe not loaded yet
		if (!stripe || !elements) return;

		try {
			const cardElement = elements.getElement(
				CardNumberElement
			) as StripeCardNumberElement;
			// Create payment method with stripe
			let paymentMethod, error;
			if (
				paymentExists &&
				paymentSelection === PAYMENT_OPTIONS.EXISTING_PAYMENT
			) {
				paymentMethod =
					customer?.invoice_settings.default_payment_method?.id;
			} else {
				const response = await stripe.createPaymentMethod({
					type: "card",
					card: cardElement,
					billing_details: {
						name: billingAddress?.name,
						address: {
							country: billingAddress?.region,
							postal_code: billingAddress?.zipcode,
						},
					},
				});
				paymentMethod = response.paymentMethod?.id;

				error = response.error;

				if (error) {
					// Show error
					setError(DEFAULT_CARD_ERROR);
					return;
				}
			}
			setError("");
			return paymentMethod;
		} catch (_) {
			Notification.warning({
				message: "An error occurred. Please try again",
			});
			return;
		}
	};

	const handleUpgrade = async () => {
		const paymentMethod = await getPaymentMethod();
		if (error || !stripe || !elements || !paymentMethod || !organizationId)
			return;

		try {
			setLoading(true);
			// Create subscription!
			await changeSubscriptionPlan({
				organizationId,
				price: priceId,
				payment_method: paymentMethod,
				promotion_code: promoCode?.code,
				quantity: defaultSeats,
				free_trial: false,
			}).unwrap();
			runNewSubscriptionEvent();
			// refresh relevant data
			fetchTeamMembers();
			await fetchFeatureRestriction();
			// After success procedures
			closeChangePlanModal();
			history.push(
				appendBaseUrl(ACCOUNT_SETTINGS_PATHS.SUBSCRIPTION.route)
			);
			Notification.success({
				message: "Subscription successfully updated!",
			});
		} catch (_) {
			Notification.warning({
				message: "An error occurred. Please try again",
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			visible={isChangePlanModalVisible}
			width={836}
			hideCancelButton
			hideOkButton
			hideCloseButton
			className={styles.changePlanModal}
		>
			<div className={styles.changePlanContainer}>
				<div className={styles.left}>
					<div
						className={styles.flexrow}
						style={{
							marginBottom: 32,
						}}
					>
						<Typography variant="title" bold>
							Change plan
						</Typography>
					</div>
					<Typography
						style={{ marginBottom: 12 }}
						variant="headline"
						bold
					>
						Plan
					</Typography>
					<Select
						placeholder="Select a plan"
						isInput
						value={priceId}
						onChange={(e: any) => {
							setPriceId(e);
						}}
						style={{
							marginBottom: 40,
						}}
					>
						{getPriceOptions()}
					</Select>
					<div
						className={styles.flexrow}
						style={{
							justifyContent: "space-between",
							marginBottom: 21,
						}}
					>
						<Typography variant="headline" bold>
							Payment method
						</Typography>
					</div>
					{error && (
						<div className={styles.messageContainer}>
							<GenemodIcon
								name="error"
								fill="yellow"
								stroke="yellow"
								className={styles.messageIcon}
							/>
							<Typography>{error}</Typography>
						</div>
					)}
					{paymentExists && (
						<RadioGroup
							value={paymentSelection}
							style={{ transform: "translateY(-16px)" }}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => setPaymentSelection(+e.target.value)}
						>
							{paymentExists && (
								<RadioButton
									value={PAYMENT_OPTIONS.EXISTING_PAYMENT}
								>
									Card ****{" "}
									{
										customer?.invoice_settings
											.default_payment_method?.card?.last4
									}
								</RadioButton>
							)}
							<RadioButton value={PAYMENT_OPTIONS.NEW_PAYMENT}>
								New credit card
							</RadioButton>
						</RadioGroup>
					)}
					{paymentSelection === PAYMENT_OPTIONS.NEW_PAYMENT && (
						<PaymentForm
							onChange={setBillingAddress}
							onValidityChange={setValid}
						/>
					)}
				</div>
				<div className={styles.right}>
					<div className={styles.exit}>
						<GenemodIcon
							hoverEffect
							size="large"
							onClick={() => {
								closeChangePlanModal();
							}}
							name="exit"
						/>
					</div>
					{/* Payment summary */}
					{priceId && (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								height: "calc(100% - 64px)",
							}}
						>
							<div>
								<ChangePlanModalPaymentSummary
									priceId={priceId}
									seats={defaultSeats}
									promoCode={promoCode}
									setPromoCode={setPromoCode}
								/>
								<Button
									stretch
									shape="squared"
									disabled={
										paymentSelection ===
										PAYMENT_OPTIONS.EXISTING_PAYMENT
											? false
											: !isValid
									}
									loading={loading}
									onClick={handleUpgrade}
								>
									Upgrade to{" "}
									{currentSelectedPrice?.product.name}
								</Button>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "flex-end",
								}}
							>
								<img
									src={
										theme === THEME_PREFERENCE.light
											? GENEMOD_LOGO_LIGHT
											: GENEMOD_LOGO_DARK
									}
									width={180}
									height={"auto"}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
}

export const ChangePlanModal = withOrWithoutStripe(ChangePlanModalInner);

type ChangePlanModalPaymentSummaryProps = {
	priceId: string;
	seats: number;
	promoCode: PromotionCode | null;
	setPromoCode: (promoCode: PromotionCode | null) => void;
};

function ChangePlanModalPaymentSummary({
	priceId,
	seats,
	promoCode,
	setPromoCode,
}: ChangePlanModalPaymentSummaryProps) {
	const { data: invoiceChangePreview, isError } =
		useInvoiceChangePreviewQuery({
			price: priceId,
			promotion_code: promoCode?.code,
			quantity: seats,
			free_trial: false,
		});

	useEffect(() => {
		if (isError) {
			Notification.warning({ message: "Failed to fetch prices" });
		}
	}, [isError]);

	const {
		total,
		subtotal,
		unitAmount,
		seats: previewSeats,
		lines,
	} = invoiceChangePreview || {};

	const { interval, minSeats } = getPriceMapping(priceId) || {};

	const planDuration = interval === "year" ? "12 mo" : "1 mo";

	const renderLines = lines?.map(({ label, value, isDiscount }, i) => {
		return (
			<div
				style={{
					display: "flex",
					alignItems: !isDiscount ? "center" : "flex-start",
					justifyContent: "space-between",
				}}
				key={i}
			>
				<div
					className={styles.flexrow}
					style={{
						justifyContent: "space-between",
					}}
				>
					{isDiscount ? (
						<div
							className={styles.flexrow}
							style={{
								justifyContent: "space-between",
							}}
						>
							<Typography>Promo code</Typography>
							<GenemodIcon
								name="cancel"
								onClick={() => setPromoCode(null)}
							/>
						</div>
					) : (
						<Typography>{label}</Typography>
					)}
				</div>
				<div
					style={{
						textAlign: "end",
					}}
				>
					<Tooltip
						placement="top"
						title="We have calculated the price considering your current subscription prorated price."
						hideTooltip={isDiscount}
						underlineText
					>
						<span style={{ cursor: "pointer" }}>
							- {formatDollar(value)}
						</span>
					</Tooltip>
					{isDiscount && (
						<Typography variant="caption" color="aqua">
							{label}
						</Typography>
					)}
				</div>
			</div>
		);
	});

	return (
		<>
			<Typography variant="headline" style={{ marginBottom: 12 }} bold>
				Payment summary
			</Typography>
			<div
				className={cn(styles.flexrow, styles.billAmount)}
				style={{
					justifyContent: "space-between",
				}}
			>
				<Typography>Bill amount</Typography>
				<Typography>
					{formatDollar(unitAmount)} x{" "}
					<Tooltip
						placement="top"
						title={`${minSeats} seats minimum`}
						underlineText
						hideTooltip={!minSeats}
					>
						<span
							style={{ cursor: "pointer" }}
						>{`${previewSeats} seats`}</span>
					</Tooltip>{" "}
					x {planDuration}.
				</Typography>
			</div>
			<div className={styles.subtotalAndDetails}>
				<div
					className={styles.flexrow}
					style={{
						justifyContent: "space-between",
					}}
				>
					<Typography>Subtotal</Typography>
					<Typography>{formatDollar(subtotal)}</Typography>
				</div>
				{renderLines}
				<AddPromoCode
					promoCode={promoCode}
					setPromoCode={setPromoCode}
				/>
			</div>
			<div
				className={cn(styles.flexrow, styles.dueToday)}
				style={{
					justifyContent: "space-between",
				}}
			>
				<Typography variant="title">Due today</Typography>
				<Typography variant="title">{formatDollar(total)}</Typography>
			</div>
		</>
	);
}

/**
 * Displays billing frequency options for a subscription plan
 * and allows the user to apply discount codes
 * */
function PlanSelector(props: { style?: React.CSSProperties; planId: string }) {
	const { planId } = props;
	const pricingPlans = useProductPricing(planId);
	// Billing option/frequency
	const [selectedOption, setOption] = useState<string>("");

	useEffect(() => {
		// Select the first plan as the default option
		setOption(pricingPlans[0]?.id || "");
	}, [pricingPlans]);

	const pricingOptionsJSX = React.useMemo(() => {
		return pricingPlans.map((price) => {
			return <PricingOption key={price.id} price={price} />;
		});
	}, [pricingPlans]);

	return (
		<div {...props}>
			<Typography variant="headline">Plan: Team</Typography>
			<RadioGroup value={selectedOption}>{pricingOptionsJSX}</RadioGroup>
		</div>
	);
}

function PricingOption(props: { price: Price }) {
	const { price } = props;
	return (
		<RadioButton value={price.id}>
			<Typography color="text-primary">
				{price.metadata.BILLING_LABEL}
			</Typography>
			<Typography variant="caption" color="text-secondary">
				$ {Number(price.unit_amount / 100)} / user / month
			</Typography>
		</RadioButton>
	);
}

export const formatDollar = (centsAmount = 0) => {
	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	})
		.format(centsAmount / 100)
		.replace("$", "$ ");
};

/**
 * This is a temporary Stripe object used for demo purposes. The actual Stripe object
 * is provided by the Elements context provider in GenemodApp.js. It can be accessed
 * using the `useStripe` hook.
 */
export function PAYMENT_FORM_DEMO(): JSX.Element {
	const stripe = loadStripe("demo");
	return (
		<DemoWrapper>
			<DemoSection title="Payment form">
				<Elements stripe={stripe}>
					<SUBMISSION_DEMO />
				</Elements>
			</DemoSection>
		</DemoWrapper>
	);
}

function SUBMISSION_DEMO() {
	const elements = useElements();
	const handleSubmit = () => {
		// Accessing the element information outside of the PaymentForm component
		const cardElement = elements?.getElement(CardNumberElement);
		console.log(cardElement);
	};

	return (
		<Demo
			description={
				<div>
					Reusable payment form. Stripe elements are accessible via
					the{" "}
					<a
						href="https://stripe.com/docs/stripe-js/react#useelements-hook"
						target="_blank"
						rel="noopener noreferrer"
					>
						useElements hook
					</a>
					.
				</div>
			}
		>
			<div style={{ width: 400 }}>
				<PaymentForm />
				<Button onClick={handleSubmit}>Submit</Button>
			</div>
		</Demo>
	);
}

type PromoCodeProps = {
	promoCode: PromotionCode | null;
	setPromoCode: (code: PromotionCode | null) => void;
};

export const AddPromoCode = ({ promoCode, setPromoCode }: PromoCodeProps) => {
	const [promoCodeInput, setPromoCodeInput] = useState("");
	const [isAddingPromo, setIsAddingPromo] = useState(false);

	const [getPromotionCode, { isLoading }] = useLazyPromotionCodeQuery();
	const [status, setStatus] = useState<"ERROR" | null>(null);

	const onPromoInputChange = (newValue: string) => {
		setPromoCodeInput(newValue);
		if (status === "ERROR") {
			setPromoCode(null);
		}
	};

	const handleAddPromo = () => {
		if (promoCodeInput) {
			getPromotionCode(promoCodeInput)
				.then(({ data, error }) => {
					if (error || !data) {
						setStatus("ERROR");
						return;
					}
					setIsAddingPromo(false);
					setPromoCodeInput("");
					setStatus(null);
					setPromoCode(data);
				})
				.catch(() => setStatus("ERROR"));
		}
	};

	// Promo is already added
	if (promoCode) {
		return null;
	}

	if (!isAddingPromo) {
		return (
			<Button
				type="text"
				onClick={() => setIsAddingPromo(true)}
				className={styles.addPromoButton}
			>
				Add promo code
			</Button>
		);
	}

	return (
		<Input
			value={promoCodeInput}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
				onPromoInputChange(e.target.value)
			}
			autoFocus
			suffix={
				<Spin spinning={isLoading}>
					{status === "ERROR" ? (
						<GenemodIcon
							name="cancel"
							onClick={() => onPromoInputChange("")}
						/>
					) : (
						<Button type="text" onClick={handleAddPromo}>
							Apply
						</Button>
					)}
				</Spin>
			}
			placeholder="Promo code"
			error={status === "ERROR" && "Invalid promo code"}
			onPressEnter={handleAddPromo}
			wrapperProps={{ className: styles.promoInput }}
		/>
	);
};
