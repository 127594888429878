/**
 * Methods for arrays
 * @module @helpers/Arrays
 */

import { ISOString } from "@common/types/Date";

/**
 * Performs a merge of two object arrays on a given key.
 *
 * Items that already exist inside the base array are replaced with matching items in the incoming array.
 * Items are matched on the "key" parameter using a shallow comparison.
 *
 * @example
 * const base = [{ id: 0, text: "hello" }, { id: 1, text: "goodbye" }];
 * const incoming = [{ id: 0, text: "new text" }, { id: 2, text: "welcome" }]
 *
 * const result = mergeObjectArrays(base, incoming);
 * [{ id: 0, text: 'new text' },
 *  { id: 1, text: 'goodbye' },
 *  { id: 2, text: 'welcome' }]
 *
 * @param {Array<T>} base Base array
 * @param {Array<T>} incoming Array to merge into the base array
 * @param {String} [key='id'] Used to compare objects. Default is 'id'
 * @returns {Array<T>} A new array containing items from the base and incoming arrays
 */
export function mergeObjectArrays(
	base: Array<any>,
	incoming: Array<any>,
	key = "id"
): Array<any> {
	const incomingCopy = [...incoming];
	return base
		.map((x: any) => {
			const ind = incomingCopy.findIndex((x2) => x2[key] === x[key]);
			if (ind !== -1) {
				return incomingCopy.splice(ind, 1)[0];
			} else {
				return x;
			}
		})
		.concat(incomingCopy);
}

/**
 * Insert new Object into sorted array{base} while maintaining the sorting criteria{sortedBy}
 * @param {Array<T>} base -the sorted array
 * @param {any} new_obj -obj to be inserted into {base}
 * @param {string} [sortby="name"] -sorting criteria
 */
export function insertObjectToArray(
	base: Array<any>,
	new_obj: any,
	sortby = "name"
): Array<any> {
	const position = _findPositionToInsert(
		base,
		new_obj,
		sortby,
		0,
		base.length
	);
	base.splice(position + 1, 0, new_obj);
	return base;
}

/**
 * find obj{new_obj} with the same {key} value in the sorted array{base}
 * and replacing {new_obj} according to the sorting criteria{sortedBy}
 * @param {Array<T>} base -the sorted array
 * @param {any} new_obj -obj to be inserted into {base}
 * @param {string} [key="id"] -the key value to indicate replacing item
 * @param {string} [sortby="name"] -sorting criteria
 */
export function replaceObjectToArray(
	base: Array<any>,
	new_obj: any,
	key = "id",
	sortby = "name"
): Array<any> {
	const insert_pos = _findPositionToInsert(base, new_obj, sortby);

	if (insert_pos !== -1 && base[insert_pos][key] === new_obj[key]) {
		// if the inserted location is same as current location, just replace the item
		base[insert_pos] = new_obj;
		return base;
	}
	const new_array = base.reduce(
		(accumulator: any, obj: any, index: number) => {
			if (obj[key] === new_obj[key]) {
				return accumulator;
			}
			if (insert_pos + 1 === index) {
				accumulator.push(new_obj);
			}
			accumulator.push(obj);
			return accumulator;
		},
		[]
	);
	if (new_array.length !== base.length) {
		//when new_obj will insert at the end of array
		new_array.push(new_obj);
	}
	return new_array;
}

/**
 * Find position where a new object will be inserted according to the sorted array
 * @param {Array<T>} base -the sorted array
 * @param {any} new_obj -obj to be inserted into {base}
 * @param {string} sortby -sorting criteria
 * @param {number} [start=0] -start position to find
 * @param {number} [end=base.length]  -end position to find
 */
function _findPositionToInsert(
	base: Array<any>,
	new_obj: any,
	sortby: string,
	start = 0,
	end = base.length
): number {
	if (!base.length) return 0;
	const mid_point = start + Math.floor((end - start) / 2);
	if (end - start <= 1 || base[mid_point][sortby] === new_obj[sortby]) {
		if (mid_point === 0 && base[mid_point][sortby] > new_obj[sortby]) {
			return -1;
		}
		return mid_point;
	}
	if (base[mid_point][sortby] < new_obj[sortby]) {
		return _findPositionToInsert(base, new_obj, sortby, mid_point, end);
	}

	return _findPositionToInsert(base, new_obj, sortby, start, mid_point);
}

type RawDate = Date | ISOString | number;
export function dateSorter(dateA: RawDate, dateB: RawDate): number {
	return new Date(dateA).getTime() - new Date(dateB).getTime();
}

/**
 * Creates an array from start to end, inclusive.
 */
export const range = (start: number, end: number) => {
	return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

/**
 * Remove duplicates from array
 */
export const distinct = <T,>(array: T[]): T[] => [...new Set(array)];

/**
 * Use this in a "reduce" call to remove duplicate elements from an array.
 * @example
 * const array = [1, 2, 3, 4, 5, 1, 2, 3, 4, 5];
 * const unique = array.reduce(...uniqueElementsReducer());
 * // unique = [1, 2, 3, 4, 5]
 */
export const uniqueElementsReducer = <T,>() =>
	[
		(accumulator: T[], value: T): T[] => {
			if (!accumulator.includes(value)) {
				accumulator.push(value);
			}
			return accumulator;
		},
		[] as T[],
	] as const;
