import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Demo, DemoSection, DemoWrapper, GenemodIcon } from "@components";
import "./Table.scss";
import {
	ColumnProps,
	ExpandIconProps,
	SortOrder,
	TableProps,
} from "antd/lib/table";
import cx from "classnames";
import { useResizeEffect } from "@helpers/Hooks";
import {
	LoadingSkeletonTableMixinProps,
	useLoadingSkeletonMixin,
	LoadingSkeletonColumnMixinProps,
} from "./LoadingSkeletonMixin";
import {
	EditRowColumnMixinProps,
	EditRowTableMixinProps,
	useEditRowMixin,
} from "./EditRowMixin";
import { NewRowTableMixinProps, useNewRowMixin } from "./NewRowMixin";
import {
	RenderSubtableCollapseArrowTableMixin,
	useRenderSubtableCollapseArrowMixin,
} from "./SubtableCollapseArrowMixin";
import { useControlledSort } from "./Table";
import { useUniqueClass } from "@helpers/Hooks";
import {
	SortableContext,
	arrayMove,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DndContext, DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import { UserSettingsKey } from "@common/types/UserPreference";
import { usePreferences } from "@helpers/Hooks/UsePreferencesHook";

/**
 * Default data used to determine size of columns. Tweak/add settings here!
 */
export const ResponsiveTableColumnWidths = Object.freeze({
	XS_COLUMN_WIDTH: { min: 88, percent: 0.1, max: 120 },
	S_COLUMN_WIDTH: { min: 140, percent: 0.15, max: 180 },
	M_COLUMN_WIDTH: { min: 192, percent: 0.22, max: 240 },
	L_COLUMN_WIDTH: { min: 300, percent: 0.27, max: 420 },
	VIEW_COLUMN_WIDTH: { min: 64, percent: 0.066, max: 140 },
} as const);

export type ResponsiveTableColumnWidthOption =
	keyof typeof ResponsiveTableColumnWidths;
export type ResponsiveTableColumnWidthParams = {
	min: number;
	percent: number;
	max: number;
};

export type ResponsiveTableColumns<T> = ColumnProps<T> &
	EditRowColumnMixinProps<T> &
	LoadingSkeletonColumnMixinProps & {
		responsiveWidth?: ResponsiveTableColumnWidthOption;
		customResponsiveWidth?: ResponsiveTableColumnWidthParams;
		editable?: boolean;
	};

export type ResponsiveTableProps<T> = Omit<TableProps<T>, "columns"> &
	EditRowTableMixinProps<T> &
	NewRowTableMixinProps<T> &
	RenderSubtableCollapseArrowTableMixin<T> &
	LoadingSkeletonTableMixinProps & {
		responsiveWidthOffset?: number;
		columns: undefined | ResponsiveTableColumns<T>[];
		onSort?: (key: React.Key, order: SortOrder | undefined) => void;
		// Where we should store the table sort value
		sortStorageKey?: UserSettingsKey;
		controlledSortKey?: string | null | undefined;
		row?: React.ElementType;
		dataCy?: string;
		expandRowOnClick?: boolean;
		hasBorderTop?: boolean;
		hideLastColumnBorder?: boolean;
		hideColumnBorders?: boolean;
		shadedHeader?: boolean;
	};

/**
 * Calculates the column width based on the current table width.
 *
 * For each column the "width" parameter is taken if present, followed in priority by the customResponsiveWidth then responsiveWidth values to determine what width a column should have.
 *
 * For ResponsiveTableColumnWidthParams, the min value is the minimum width in pixels to make the column, the max is the maxium pixel width, and the percentage is used to determine width
 * for sizes between the min/max.
 *
 * NOTE: There must be at least one column with an undefined width that is allowed to expand and collapse freely to take up remaining space!
 *
 * Background: table cells do not necessarily respect min and max widths. We want both min and max widths
 * on our tables to make them behave responsively.
 *
 * @param {number} responsiveWidthOffset This number is subtracted from the table width before doing sizing calculations. For example, if a table has fixed width columns the offset would be the sum of the fixed width columns.
 * @param {number} columns[i].responsiveWidth Use a one of the preset options from ResponsiveTableColumnWidths to determine column width
 * @param {number} columns[i].customResponsiveWidth Pass in custom min/percentage/max values to determine column width
 */
export function ResponsiveTable<T>(
	props: ResponsiveTableProps<T>
): JSX.Element {
	// Apply a random suffix to the table class so we can select the right table when there are multiple on a page.
	const [randomTableClass] = useState<string>(
		"responsive-table-" + Math.floor(Math.random() * 100000)
	);

	// Mixins
	props = useLoadingSkeletonMixin(props);
	props = useEditRowMixin<T>(props);
	props = useNewRowMixin<T>(props);
	props = useRenderSubtableCollapseArrowMixin<T>(props);

	const controlledKey = useControlledSort(props);

	let { columns } = props;

	const { responsiveWidthOffset = 0 } = props;
	const tableElement = document.querySelector(
		`.${randomTableClass} .ant-table`
	);
	const tableWidth =
		tableElement && tableElement?.getBoundingClientRect().width;
	// Do not attempt to set column widths until we have table width established
	if (tableWidth) {
		columns = columns?.map((col) => {
			const { responsiveWidth, customResponsiveWidth, ...otherProps } =
				col;
			let width = col.width;
			const widthParams =
				customResponsiveWidth ||
				(responsiveWidth &&
					ResponsiveTableColumnWidths[responsiveWidth]);
			if (!width && widthParams) {
				const { min, percent, max } = widthParams;
				const sectionWidth = tableWidth - responsiveWidthOffset;
				width = Math.max(min, Math.min(sectionWidth * percent, max));
				// this fix the issue that the last column in the table is getting with zero width.
			} else if (width === undefined) {
				const { min, percent, max } =
					ResponsiveTableColumnWidths["M_COLUMN_WIDTH"];
				const sectionWidth = tableWidth - responsiveWidthOffset;
				width = Math.max(min, Math.min(sectionWidth * percent, max));
			}
			return {
				...otherProps,
				width,
			} as ColumnProps<T>;
		});
	}

	// Setup local storage sort if available
	const defaultSortColumn = columns?.find(
		(column) => !!column.defaultSortOrder && !!column.key
	);
	// undefined means no default sort applied
	const defaultSort = defaultSortColumn && {
		key: defaultSortColumn.key as React.Key,
		sortOrder: defaultSortColumn.defaultSortOrder,
	};
	const [_sort, setSort] = usePreferences(props?.sortStorageKey || "DUMMY");
	const sort = (_sort as typeof defaultSort) || defaultSort;

	// if the parent component is controlling the sort manually already we do not want to save it to storage
	const parentControlsSort = columns?.some((column) => !!column.sortOrder);

	// Use local storage if we have a key and parent is not controlling sort
	let storageSort: typeof props.onSort;
	if (props?.sortStorageKey && !parentControlsSort) {
		storageSort = (key, sortOrder) => {
			// sort order being undefined means we remove the sort
			setSort(
				sortOrder && {
					key: key,
					sortOrder: sortOrder,
				}
			);
		};
		// we will be controlling sort internally, remove any set default
		if (defaultSortColumn) {
			delete defaultSortColumn.defaultSortOrder;
		}
		// Apply internal sort
		if (sort) {
			const sortedColumn =
				columns?.find((column) => column.key === sort.key) || {};
			sortedColumn.sortOrder = sort.sortOrder;
		}
	}

	const internalOnSort: typeof props.onSort = (key, order) => {
		props.onSort?.(key, order);
		storageSort?.(key, order);
	};

	columns = columns?.map((column) => ({
		...column,
		className: cx(column.className, {
			hasBorderTop: props.hasBorderTop,
			borderedHeader: props.bordered,
		}),
	}));

	return (
		<Table
			bordered={false}
			expandIconAsCell={false}
			pagination={false}
			expandIcon={(props) => CustomExpandIcon(props)}
			expandRowByClick={props.expandRowOnClick}
			defaultExpandAllRows={true}
			locale={{ emptyText: <span /> }}
			{...props}
			className={cx(
				randomTableClass,
				{
					hideLastColumnBorder: props.hideLastColumnBorder,
					hideColumnBorders: props.hideColumnBorders,
					shadedHeader: props.shadedHeader,
				},
				props.className
			)}
			columns={columns}
			onChange={(_pagination, _filters, sorter) => {
				if (sorter) {
					internalOnSort(sorter.columnKey, sorter.order);
				}
			}}
			rowKey={props?.rowKey}
			key={controlledKey}
			components={
				props.row
					? {
							body: {
								row: props.row,
							},
					  }
					: props.components // use the passed-in components prop if it exists
			}
			data-cy={`${props.dataCy}-table`}
		>
			{props.children}
		</Table>
	);
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
	"data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id: props["data-row-key"],
	});

	const style: React.CSSProperties = {
		...props.style,
		transform: CSS.Transform.toString(
			transform && { ...transform, scaleY: 1 }
		)?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
		transition,
		...(isDragging
			? {
					position: "relative",
					zIndex: 9999,
					boxShadow: "0px 4px 15px var(--shadow-overlay)",
			  }
			: {}),
	};

	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{React.Children.map(children, (child) => {
				if ((child as React.ReactElement).key === "drag") {
					return (
						<td
							ref={setActivatorNodeRef}
							style={{ touchAction: "none", cursor: "grab" }}
							className="flex items-center justify-center"
							{...listeners}
						>
							<GenemodIcon
								style={{
									cursor: "grab",
									position: "relative",
									left: "1px",
								}}
								name="drag-handle"
							/>
						</td>
					);
				}
				return child;
			})}
		</tr>
	);
};

type DraggableTableProps<T extends { id: UniqueIdentifier }> =
	FixedHeaderResponsiveTableProps<T> & {
		onDragEnd: (event: DragEndEvent) => void;
	};

export function DraggableTable<T extends { id: UniqueIdentifier }>(
	props: DraggableTableProps<T>
) {
	const [data, setData] = useState(props.dataSource || []);

	useEffect(() => {
		if (props.dataSource) {
			setData(props.dataSource);
		}
	}, [props.dataSource]);

	const onDragEnd = ({ active, over }: DragEndEvent) => {
		if (active.id !== over?.id) {
			setData((previous) => {
				const activeIndex = previous.findIndex(
					(i) => i.id === active.id
				);
				const overIndex = previous.findIndex((i) => i.id === over?.id);
				return arrayMove(previous, activeIndex, overIndex);
			});
		}
	};

	return (
		<DndContext
			onDragEnd={(e) => {
				onDragEnd(e);
				props.onDragEnd(e);
			}}
			data-cy={`${props.dataCy}-table`}
		>
			<SortableContext
				items={
					props.dataSource?.map(
						(item) => item.id as UniqueIdentifier
					) || []
				}
				strategy={verticalListSortingStrategy}
			>
				<FixedHeaderResponsiveTable
					row={Row}
					rowKey="id"
					{...props}
					dataSource={data}
				/>
			</SortableContext>
		</DndContext>
	);
}

type FixedHeaderResponsiveTableProps<T> = ResponsiveTableProps<T> & {
	/** Optional class name for wrapper div. Useful to set fixed height */
	wrapperClassName?: string;
	resizeSemaphore?: number;
	dataCy?: string;
};

/**
 * Wraps Responsive table in an extra div set to 100% height and calculates the y scroll parameter for the underlying ant table so that the header can be fixed to the top.
 */
export function FixedHeaderResponsiveTable<T>({
	wrapperClassName,
	resizeSemaphore = 0,
	...props
}: FixedHeaderResponsiveTableProps<T>) {
	const uniqueId = useUniqueClass();
	const [containerHeight, setContainerHeight] = useState<number | null>(null);
	const [headerHeight, setHeaderHeight] = useState<number | null>(null);

	const updateHeaderHeight = () => {
		setContainerHeight(
			document.querySelector(`.fixedHeaderResponsiveTable.${uniqueId}`)
				?.clientHeight || null
		);
		setHeaderHeight(
			document.querySelector(
				`.fixedHeaderResponsiveTable.${uniqueId} .ant-table-thead`
			)?.clientHeight || null
		);
	};

	useResizeEffect(updateHeaderHeight, true, []);

	// Periodically check if the table height has changed. This mostly helps when opening and closing devtools.
	useEffect(() => {
		const timer = setTimeout(() => {
			updateHeaderHeight();
		}, 300);
		return () => clearTimeout(timer);
	}, [resizeSemaphore]);

	const newProps = {
		...props,
		loading:
			props.loading || containerHeight === null || headerHeight === null,
	};

	if (containerHeight !== null && headerHeight !== null) {
		// setting "scroll" makes antd table header "fixed"
		newProps.scroll = { y: containerHeight - headerHeight };
	}

	return (
		<div
			className={cx(
				"fixedHeaderResponsiveTable",
				uniqueId,
				wrapperClassName
			)}
			data-cy={`${props.dataCy}-table`}
		>
			<ResponsiveTable {...newProps} />
		</div>
	);
}

//Rendering custom caret icons instead of default
export function CustomExpandIcon<T>({
	expandable,
	expanded,
	record,
	onExpand,
}: ExpandIconProps<T>) {
	if (!expandable) {
		return null;
	}
	return (
		<div
			onClick={(e) => {
				onExpand(record, e);
			}}
		>
			<GenemodIcon
				className={"expandIcon"}
				name={expanded ? "chevron-down" : "chevron-right"}
				size="large"
			/>
		</div>
	);
}

export function RESPONSIVE_TABLE_DEMO() {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Table Basic Uses" description="Table Basics">
					<DEMO1 />
				</Demo>
				<Demo title="Tiered Table" description="Layers of Table data">
					<DEMO3 />
				</Demo>
				<Demo title="Empty table" description="No data">
					<ResponsiveTable
						dataSource={[]}
						columns={[
							{ title: "Column 1", key: "col1" },
							{
								title: "Column 2",
								key: "col2",
							},
							{
								title: "Column 3",
								key: "col3",
							},
						]}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}

function DEMO1() {
	const dataSource = [
		{
			key: "1",
			name: "Mike",
			age: 32,
			address: "10 Downing Street",
			dateOfBirth: "1/2/1989",
		},
		{
			key: "2",
			name: "John",
			age: 42,
			address: "10 Downing Street",
			dateOfBirth: "10/12/1978",
		},
		{
			key: "2",
			name: "Mary",
			age: 40,
			address: "123 Main st",
			dateOfBirth: "7/11/1980",
		},
	];

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 80,
		},
		{
			title: "Age",
			dataIndex: "age",
			key: "age",
			responsiveWidth: "XS_COLUMN_WIDTH" as const,
		},
		{
			title: "Address",
			dataIndex: "address",
			key: "address",
		},
		{
			title: "D.O.B.",
			dataIndex: "dateOfBirth",
			key: "dateOfBirth",
			customResponsiveWidth: { min: 200, percent: 0.17, max: 250 },
		},
	];

	return (
		<div>
			<ResponsiveTable
				dataSource={dataSource}
				columns={columns}
				responsiveWidthOffset={80}
			/>
		</div>
	);
}

function DEMO3() {
	const columns = [
		{
			title: "",
			dataIndex: "icon",
			key: "icon",
			width: 48,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			responsiveWidth: "M_COLUMN_WIDTH" as const,
		},
		{
			title: "Age",
			dataIndex: "age",
			key: "age",
			responsiveWidth: "XS_COLUMN_WIDTH" as const,
		},
		{
			title: "Address",
			dataIndex: "address",
			key: "address",
			// responsiveWidth: "L_COLUMN_WIDTH" as const,
		},
	];

	const data = [
		{
			key: 1,
			name: "John Brown sr.",
			age: 60,
			address: "New York No. 1 Lake Park",
			children: [
				{
					key: 11,
					name: "John Brown",
					age: 42,
					address: "New York No. 2 Lake Park",
				},
				{
					key: 12,
					name: "John Brown jr.",
					age: 30,
					address: "New York No. 3 Lake Park",
					children: [
						{
							key: 121,
							name: "Jimmy Brown",
							age: 16,
							address: "New York No. 3 Lake Park",
						},
					],
				},
				{
					key: 13,
					name: "Jim Green sr.",
					age: 72,
					address: "London No. 1 Lake Park",
					children: [
						{
							key: 131,
							name: "Jim Green",
							age: 42,
							address: "London No. 2 Lake Park",
							children: [
								{
									key: 1311,
									name: "Jim Green jr.",
									age: 25,
									address: "London No. 3 Lake Park",
								},
								{
									key: 1312,
									name: "Jimmy Green sr.",
									age: 18,
									address: "London No. 4 Lake Park",
								},
							],
						},
					],
				},
			],
		},
		{
			key: 2,
			name: "Joe Black",
			age: 32,
			address: "Sidney No. 1 Lake Park",
		},
	];

	return (
		<div>
			<ResponsiveTable columns={columns} dataSource={data} />
		</div>
	);
}
