import { NominalUuid } from "@helpers/TypeHelpers";
import { ExperimentId } from "./ExperimentId";
import { GenemodDocumentUUID } from "./GenemodDocumentUUID";
import { ProtocolId } from "./ProtocolId";
import { EditorDocumentTableId } from "../components/Editor/TableV2/types";

export type BackendExperimentTableId = NominalUuid<"ExperimentTableId">;

export type ExperimentTable = {
	id: BackendExperimentTableId;
	protocol?: {
		name: string;
		protocol_id: ProtocolId;
		protocol_version: number;
	};
	experiment?: {
		name: string;
		file_id: ExperimentId;
		parent_project: number;
	};
	table_name: string;
	table_id: EditorDocumentTableId;
	table_document_id: GenemodDocumentUUID;
};

export type ExperimentTablePayload = {
	table_name: string;
	table_id: EditorDocumentTableId;
	document: GenemodDocumentUUID;
};

export type ExperimentDocumentTableSearch = {
	search?: string;
};
