import { User } from "@common/types/User";
import { ISOString } from "@common/types/Date";
import { WorkspaceId } from "./AdminEnterprisePortal";

export const apps = ["freezer", "pm"] as const;

export const models = [
	"Freezer",
	"Shelf",
	"Rack",
	"Box",
	"Item",
	"Space",
	"Furniture",
	"FurnitureCategory",
	"Consumable",
	"Project",
	"Folder",
	"ExperimentFile",
	"ItemOrder",
	// Item templates
	"CustomItemTypeSchema",
	"ExperimentMaterials",
	"Protocol",
	"ProtocolVersion",
	"ProjectAttachment",
	"ItemAttachment",
	"ExperimentFileMaterials",
	"Repository",
	"ConsumableRepository",
] as const;

export type App = (typeof apps)[number];
export type Model = (typeof models)[number];

/**
 * Mapping from backend model name to user-facing name.
 */
export const ModelToName: Partial<Record<Model, string>> = {
	FurnitureCategory: "Consumable Category",
	ItemOrder: "Order",
	CustomItemTypeSchema: "Item Template",
	ExperimentMaterials: "Material",
	ProtocolVersion: "Protocol Version",
	ProjectAttachment: "Project Attachment",
	ItemAttachment: "Item Attachment",
	ExperimentFile: "File",
	ExperimentFileMaterials: "Material",
};

/**
 * Mapping from app model name to user-facing name.
 */
export const AppToName: Partial<Record<App, string>> = {
	pm: "Projects",
	freezer: "Inventory",
};

export const NameToApp: Record<string, App> = {
	Projects: "pm",
	Inventory: "freezer",
};

export enum EventType {
	CREATE = 0,
	UPDATE = 1,
	DELETE = 2,
}

export type ModelEvent = {
	uuid: string;
	user?: User;
	timestamp: ISOString;
	app: App;
	model: Model;
	object_id: number;
	object_name: string;
	event_type: EventType;
	changes: { [key: string]: [string, string] };
	actions: string;
};

export type OrganizationEvent = ModelEvent & {
	organization?: number;
	workspace?: WorkspaceId;
};

export type EventLocation = {
	event: ModelEvent;
};

export type FreezersEventLocation = EventLocation & {
	freezer_location: {
		freezer?: number;
		shelf?: number;
		rack?: number;
		box?: number;
		item?: number;
		event: string;
	}[];
};

export type ConsumablesEventLocation = EventLocation & {
	consumable_location: {
		space?: number;
		furniture?: number;
		furniture_category?: number;
		consumable?: number;
		event: string;
	}[];
};

export type ProjectsEventLocation = EventLocation & {
	project_location: {
		project?: number;
		folder?: number;
		experiment_file?: number;
		event: string;
	}[];
};

export type ProtocolEventLocation = EventLocation & {
	protocol_location: {
		protocol?: number;
		protocol_version?: number;
		event: string;
	}[];
};

export type FreezersEvent = OrganizationEvent &
	FreezersEventLocation & {
		app: "freezer";
		model: "Freezer" | "Shelf" | "Rack" | "Box" | "Item" | "ItemAttachment";
	};

export type ConsumablesEvent = OrganizationEvent &
	ConsumablesEventLocation & {
		app: "freezer";
		model: "Space" | "Furniture" | "FurnitureCategory" | "Consumable";
	};

export type ItemOrderEvent = OrganizationEvent & {
	app: "freezer";
	model: "ItemOrder";
};

export type ItemTemplateEvent = OrganizationEvent & {
	app: "freezer";
	model: "CustomItemTypeSchema";
};

export type ProjectsEvent = OrganizationEvent &
	ProjectsEventLocation & {
		app: "pm";
		model:
			| "Project"
			| "Folder"
			| "ExperimentFile"
			| "ExperimentFileMaterials"
			| "ProjectAttachment";
	};

export type ProtocolEvent = OrganizationEvent &
	ProtocolEventLocation & {
		app: "pm";
		model: "Protocol" | "ProtocolVersion";
	};

export type Event =
	| FreezersEvent
	| ConsumablesEvent
	| ItemOrderEvent
	| ItemTemplateEvent
	| ProjectsEvent
	| ProtocolEvent;
