import React, { useState, ComponentPropsWithoutRef } from "react";
import cn from "classnames";
import styles from "./AvatarGroup.module.scss";
import {
	GenemodIcon,
	Input,
	Tooltip,
	Typography,
	UserAvatar,
} from "@common/components";
import { Popover } from "antd";
import {
	Avatar,
	getFullNameFromAvatar,
	OrganizationUserId,
} from "@common/types";
import useCurrentWorkspaceUserHooks from "@helpers/Hooks/UseCurrentWorkspaceUserHook";
import { UserAvatarSizes } from "./UserAvatar";

const AVATAR_SPACING = 4;

type UserAction = (
	id: OrganizationUserId,
	existingUsers: Avatar[] | undefined
) => void;

type AvatarGroupSizes = Exclude<
	UserAvatarSizes,
	UserAvatarSizes.XS | UserAvatarSizes.XL
>;

export interface AvatarGroupProps extends ComponentPropsWithoutRef<"div"> {
	maxAvatars?: number;
	users?: Avatar[];
	readOnly?: boolean;
	size?: AvatarGroupSizes;
	tooltip?: boolean;
	reversed?: boolean;
	showPlusNumber?: boolean;
	onAddUser?: UserAction;
	onDeleteUser?: UserAction;
}

const AvatarGroup = ({
	maxAvatars = 3,
	users: propUsers = [],
	readOnly,
	showPlusNumber = true,
	onAddUser,
	onDeleteUser,
	size = UserAvatarSizes.S,
	tooltip,
	...props
}: AvatarGroupProps): JSX.Element => {
	let users = propUsers;
	let remainingUsers = 0;
	if (maxAvatars) {
		users = users.slice(0, maxAvatars);
		remainingUsers = propUsers.length - maxAvatars;
	}

	const avatarsAndPlusNumber = users.map((user, index) => (
		<div
			className={styles.avatar}
			style={{ width: size, height: size }}
			key={`avatar-group${index}`}
		>
			<UserAvatar
				user={user}
				tooltip={tooltip}
				size={size}
				avatarStyle={{
					left: -1 * AVATAR_SPACING * index,
				}}
			/>
		</div>
	));

	if (showPlusNumber && remainingUsers > 0) {
		avatarsAndPlusNumber.push(
			<div
				className={styles.avatar}
				style={{
					width: size,
					height: size,
				}}
				key="plusNumber"
			>
				<UserAvatar
					size={size}
					avatarStyle={{
						left: -1 * AVATAR_SPACING * users.length,
					}}
					plusNumber={remainingUsers}
				/>
			</div>
		);
	}

	return (
		<div className={cn(styles.flex, styles.avatarGroup)} {...props}>
			{avatarsAndPlusNumber.length > 0 && (
				<div
					className={styles.flex}
					style={{
						width:
							(size - AVATAR_SPACING) *
								avatarsAndPlusNumber.length +
							AVATAR_SPACING,
						position: "relative",
					}}
				>
					{avatarsAndPlusNumber}
				</div>
			)}
			{!readOnly && (
				<Popover
					title=""
					placement="bottomRight"
					trigger="click"
					overlayClassName={styles.manageUsersPopover}
					content={
						<ManageUsers
							users={propUsers}
							onAddUser={onAddUser}
							onDeleteUser={onDeleteUser}
						/>
					}
				>
					<div
						className={styles.addPeopleContainer}
						style={{
							width: size,
							height: size,
							left: -1 * AVATAR_SPACING,
							position: users.length ? "relative" : "unset",
						}}
					>
						<Tooltip title="Assign users" disabled={!tooltip}>
							<GenemodIcon name="person-add" size="large" />
						</Tooltip>
					</div>
				</Popover>
			)}
		</div>
	);
};

export default AvatarGroup;

type ManageUsersProps = {
	users?: Avatar[];
	onAddUser?: UserAction;
	onDeleteUser?: UserAction;
};

const ManageUsers = ({
	users,
	onAddUser,
	onDeleteUser,
}: ManageUsersProps): JSX.Element => {
	const [search, setSearch] = useState("");
	const { currentWorkspaceUsersAsTeamMember } =
		useCurrentWorkspaceUserHooks();
	const teamMembers = currentWorkspaceUsersAsTeamMember.filter((teamMember) =>
		getFullNameFromAvatar(teamMember.user)
			.toLowerCase()
			.includes(search.toLowerCase())
	);

	const handleUserAction = (
		teamMemberId: OrganizationUserId,
		deleteUser: boolean
	) => {
		if (deleteUser) {
			onDeleteUser?.(teamMemberId, users);
		} else {
			onAddUser?.(teamMemberId, users);
		}
	};

	const userIds = (users || []).map((user) => user.id);

	return (
		<div className={styles.manageUsers}>
			<div className={styles.userSearchContainer}>
				<Input
					prefix={<GenemodIcon name="search" />}
					placeholder="Search for researchers"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					wrapperProps={{ className: styles.userSearch }}
					gutterBottom={false}
				/>
			</div>
			<div className={styles.userRows}>
				{teamMembers.length === 0 && (
					<Typography variant="label" color="text-tertiary">
						No people found.
					</Typography>
				)}
				{teamMembers.map((teamMember) => {
					const invited = userIds.includes(teamMember.user.id);
					return (
						<div className={styles.userRow} key={teamMember.id}>
							<UserAvatar
								user={teamMember.user}
								size={24}
								avatarStyle={{ flex: "0 0 24px" }}
							/>
							<Typography
								className={styles.userRowName}
								color="text-primary"
								ellipsis
							>
								{getFullNameFromAvatar(teamMember?.user)}
							</Typography>
							<Typography
								color="link-primary"
								style={{ cursor: "pointer" }}
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									handleUserAction(teamMember.id, invited);
								}}
							>
								{invited ? "Remove" : "Invite"}
							</Typography>
						</div>
					);
				})}
			</div>
		</div>
	);
};
