import React, { useState } from "react";
import { Demo, DemoSection, DemoWrapper, GenemodIcon } from "@components";
import classNames from "classnames";
import styles from "./index.module.scss";
import LayerSystemContainer from "../LayerSystemContainer/LayerSystemContainer";

export enum GRID_LIST_LAYOUT {
	GRID = 0,
	LIST,
}

type GridViewToggleProps = {
	isGrid?: boolean;
	onGrid: () => void;
	onList: () => void;
	style?: React.CSSProperties;
};

export default function GridViewToggle({
	isGrid = true,
	onGrid,
	onList,
	style,
}: GridViewToggleProps): JSX.Element {
	return (
		<LayerSystemContainer
			className={styles.gridViewToggleContainer}
			style={style}
		>
			<div
				className={classNames(styles.listView, {
					[styles.listView__selected]: !isGrid,
					[styles.listView__selected_start]: !isGrid,
				})}
				onClick={onList}
			>
				<GenemodIcon
					size="large"
					name="list"
					fill={!isGrid ? "text-secondary" : "text-ghost"}
				/>
			</div>
			<div
				className={classNames(styles.gridView, {
					[styles.gridView__selected]: isGrid,
					[styles.gridView__selected_end]: isGrid,
				})}
				onClick={onGrid}
			>
				<GenemodIcon
					size="large"
					name="card-view"
					fill={isGrid ? "text-secondary" : "text-ghost"}
				/>
			</div>
		</LayerSystemContainer>
	);
}

export function GRIDVIEWTOGGLE_DEMO(): JSX.Element {
	const [isGrid1, setIsGrid1] = useState<boolean>(true);
	const onGrid1 = () => {
		setIsGrid1(true);
	};
	const onList1 = () => {
		setIsGrid1(false);
	};
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Gridview toggle"
					description="Toggling between grid and list view"
				>
					<GridViewToggle
						onGrid={onGrid1}
						onList={onList1}
						isGrid={isGrid1}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
