import { useWhatsNewUpdatesQuery } from "@redux/Dashboard/DashboardApiSlice";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import moment from "moment";

export const useWhatsNewNotificaion = (): boolean => {
	const { data } = useWhatsNewUpdatesQuery();
	const { data: user } = useCurrentUserQuery();
	const entries = data || [];
	if (!user) return false;

	/**
	 * DASH-3254:
	 * Don't show notification for entries that are older than the user's
	 * account creation date.
	 */
	return entries
		.filter((entry) => moment(entry.published_at).isAfter(user.date_joined))
		.some((entry) => !entry.is_viewed);
};
