import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			data-name="type_video (x48)"
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			{...props}
		>
			<path
				data-name="Icon material-movie"
				d="m28.6 6 3.2 6.4H27L23.8 6h-3.2l3.2 6.4H19L15.8 6h-3.2l3.2 6.4H11L7.8 6H6.2a3.2 3.2 0 0 0-3.184 3.2L3 28.4a3.209 3.209 0 0 0 3.2 3.2h25.6a3.209 3.209 0 0 0 3.2-3.2V6z"
				transform="translate(5 6)"
				style={{ fill: "#c1c1c3" }}
			/>
		</svg>
	);
}
const VideoIcon = React.memo(SvgComponent);
export default VideoIcon;
