import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import { Event, App, Model } from "@common/types/Event";
import {
	PageNumberPaginationParams,
	PageNumberPaginationResponse,
} from "@common/types/Pagination";

type RestrictedEventsResponse = {
	num_restricted_activities?: number;
};

export type EventFilters = PageNumberPaginationParams & {
	/**
	 * Filter events by feature
	 */
	app?: App;
	model?: Model;
	object_id?: number;
	workspace?: string;
	/**
	 * Filter events that occurred within a location.
	 *
	 * Example:
	 * {  location_model: EventObject.FREEZER, location_object_id: 7 }
	 * // Returns all events (freezer, shelf, rack, box, item) that occurred within freezer 7
	 */
	timestamp__gte?: string;
	timestamp__lte?: string;
	user?: number[];
	exclude_private?: boolean;
} & (
		| {
				location_model?: Omit<
					Model,
					"ItemOrder" | "CustomItemTypeSchema"
				>;
				location_object_id?: number;
		  }
		| {
				// Both are required when using location_object_id
				location_object_id: number;
				location_model?: Omit<
					Model,
					"ItemOrder" | "CustomItemTypeSchema"
				>;
		  }
	);

export const eventsApi = createApi({
	reducerPath: "eventsApi",
	baseQuery: genemodBaseQuery({ baseUrl: "/events" }),
	endpoints: (builder) => {
		return {
			events: builder.query<
				PageNumberPaginationResponse<Event> & RestrictedEventsResponse,
				EventFilters
			>({
				query: (params) => ({
					url: "",
					params,
				}),
				keepUnusedDataFor: 0,
			}),
		};
	},
});

export const { useEventsQuery, useLazyEventsQuery } = eventsApi;
