import {
	ButtonV2,
	Demo,
	DemoSection,
	DemoWrapper,
	Typography,
} from "@components";
import React, { ReactElement, useRef } from "react";
import "./EditableSidePanel.scss";
import { useCommonModalState } from "@redux/CommonModals/hooks";

export type EditableSidePanelSettings =
	| "freezer"
	| "shelf"
	| "rack"
	| "category"
	| "storage unit"
	| "space"
	| "item group"
	| "box"
	| "experiment";

type EditableSidePanelProps = {
	id?: number | null;
	title?: string;
	label?: string;
	viewOnly?: boolean;
	type?: EditableSidePanelSettings;
	edit?: boolean;
	inlineEdit?: boolean;
	isSubmitting?: boolean;
	sidePanelLabelStyle?: React.CSSProperties;
	onConfirm?: () => void;
	validateForm?: () => boolean;
	showEdit?: () => void;
	children: React.ReactNode;
	dataCy?: string;
	setShowEditShelfModal?: (isVisible: boolean) => void;
	setShowRearrangeRacksModal?: () => void;
	handleShowEditRackModal?: () => void;
	openCustomizeBoxModal?: () => void;
};

export default function EditableSidePanel(
	props: EditableSidePanelProps
): JSX.Element {
	const parentRef = useRef<HTMLDivElement>(null);
	const childRef = useRef<HTMLDivElement>(null);
	const { openEditFridgeSettingsPanel } = useCommonModalState(
		"editFridgeSettingsPanel"
	);
	const { openEditBoxSettingsPanel } = useCommonModalState(
		"editBoxSettingsPanel"
	);

	const onEditClick = () => {
		if (props.id) {
			openEditFridgeSettingsPanel({ id: props.id });
		}
	};

	const ActionButtons = ({ type }: { type?: EditableSidePanelSettings }) => {
		if (!type) return;
		const buttons: { [key in EditableSidePanelSettings]: any } = {
			freezer: (
				<>
					{!props.viewOnly && (
						<div className="buttons">
							<ButtonV2
								className="edit-btn"
								onClick={onEditClick}
								size="small"
								data-cy={`${props.dataCy}-edit-button`}
							>
								Edit freezer
							</ButtonV2>
							<ButtonV2
								className="edit-btn"
								onClick={() =>
									props.setShowEditShelfModal?.(true)
								}
								size="small"
								data-cy={`${props.dataCy}-edit-button`}
							>
								Modify layout
							</ButtonV2>
						</div>
					)}
				</>
			),
			shelf: (
				<>
					{!props.viewOnly && (
						<div className="buttons">
							<ButtonV2
								className="edit-btn"
								onClick={() => props.showEdit?.()}
								size="small"
								data-cy={`${props.dataCy}-edit-button`}
							>
								Rename shelf
							</ButtonV2>
							<ButtonV2
								className="edit-btn"
								onClick={() =>
									props.setShowRearrangeRacksModal?.()
								}
								size="small"
								data-cy={`${props.dataCy}-edit-button`}
							>
								Modify layout
							</ButtonV2>
						</div>
					)}
				</>
			),
			rack: (
				<>
					{!props.viewOnly && (
						<div className="buttons">
							<ButtonV2
								className="edit-btn"
								onClick={() => props.showEdit?.()}
								size="small"
								data-cy={`${props.dataCy}-edit-button`}
							>
								Rename rack
							</ButtonV2>
							<ButtonV2
								className="edit-btn"
								onClick={() =>
									props.handleShowEditRackModal?.()
								}
								size="small"
								data-cy={`${props.dataCy}-edit-button`}
							>
								Modify layout
							</ButtonV2>
						</div>
					)}
				</>
			),
			category: (
				<div className="buttons">
					{!props.viewOnly && (
						<ButtonV2
							className="edit-btn"
							onClick={() => props.showEdit?.()}
							size="small"
							data-cy={`${props.dataCy}-edit-button`}
						>
							Rename category
						</ButtonV2>
					)}
				</div>
			),
			"storage unit": <h1></h1>,
			space: <h1></h1>,
			"item group": (
				<div className="buttons">
					{!props.viewOnly && (
						<ButtonV2
							className="edit-btn"
							onClick={() => props.showEdit?.()}
							size="small"
							data-cy={`${props.dataCy}-edit-button`}
						>
							Rename group
						</ButtonV2>
					)}
				</div>
			),
			box: (
				<>
					{!props.viewOnly && (
						<div className="buttons">
							<ButtonV2
								className="edit-btn"
								onClick={() => {
									if (props.id) {
										openEditBoxSettingsPanel({
											id: props.id,
										});
									}
								}}
								size="small"
								data-cy={`${props.dataCy}-edit-button`}
							>
								Edit box
							</ButtonV2>
							<ButtonV2
								className="edit-btn"
								onClick={() => props.openCustomizeBoxModal?.()}
								size="small"
								data-cy={`${props.dataCy}-edit-button`}
							>
								Customize box
							</ButtonV2>
						</div>
					)}
				</>
			),
			experiment: <h1></h1>,
		};
		return buttons[type];
	};

	return (
		<>
			<div ref={parentRef} className="editable-settings-container">
				<div className="settings-inner-content">
					{!props.edit ? (
						<div className="setting-header">
							{props.label && (
								<Typography
									variant="caption"
									color="text-tertiary-v2"
									className="setting-label"
								>
									{props.label}
								</Typography>
							)}
							<div className="title-and-edit">
								<Typography
									variant="headline5"
									color="text-primary-v2"
									className="setting-title"
									dataCy={`${props.dataCy}-title`}
								>
									{props.title}
								</Typography>
								<ActionButtons type={props.type} />
							</div>
						</div>
					) : (
						<div
							className="edit-top-section"
							style={{
								...(props.inlineEdit && { borderBottom: 0 }),
							}}
						>
							{!props.inlineEdit ? (
								<Typography variant="headline4" medium>
									Edit {props.type}
								</Typography>
							) : (
								props.children
							)}
							<div className={"edit-view-buttons"}>
								<ButtonV2
									id="cancel-button"
									onClick={() => props.showEdit?.()}
								>
									Cancel
								</ButtonV2>
								<ButtonV2
									id="save-button"
									onClick={() => props.onConfirm?.()}
									disabled={!props.validateForm?.()}
									loading={props?.isSubmitting}
									type="primary"
									data-cy={`${props.dataCy}-save-button`}
								>
									Save
								</ButtonV2>
							</div>
						</div>
					)}
					{!props.inlineEdit && (
						<div ref={childRef}>{props.children}</div>
					)}
				</div>
			</div>
		</>
	);
}

export function EDITABLESIDEPANELCOMPONENT_DEMO() {
	// possible props being sent to this component
	const settingContent = {
		header: "Fridge 1",
		content: (
			<div>
				A refrigerator is a machine for keeping things cold. It is
				sometimes called a fridge or an icebox. … People put food and
				drinks in it, to keep those items cold or good (unspoiled) for a
				longer time. A refrigerator has a heat pump. It takes heat away
				from the air inside the fridge.
			</div>
		),
	};

	const settingContent2 = {
		header: "Fridge 1",
		content: (
			<>
				<div>
					A refrigerator is a machine for keeping things cold. It is
					sometimes called a fridge or an icebox. … People put food
					and drinks in it, to keep those items cold or good
					(unspoiled) for a longer time. A refrigerator has a heat
					pump. It takes heat away from the air inside the fridge.
				</div>
				<table>
					<tr>
						<td>Type</td>
						<td>-80 freezers</td>
					</tr>
					<tr>
						<td>Dimension</td>
						<td>5 shelves</td>
					</tr>
					<tr>
						<td>Default item Type</td>
						<td>Strain</td>
					</tr>
					<tr>
						<td>Shared Preferences</td>
						<td>Shared Freezer</td>
					</tr>
				</table>
			</>
		),
	};

	const demoBoxStyle = {
		border: "1px solid white",
		padding: "10px",
		marginBottom: "10px",
	};

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic Usage"
					description="Basic Editable side panel component"
				>
					<div style={{ width: "29.6%" }}>
						<div style={demoBoxStyle}>
							<EditableSidePanel
								label="Freezer"
								title={settingContent.header}
							>
								{settingContent.content}
							</EditableSidePanel>
						</div>

						<div style={demoBoxStyle}>
							<EditableSidePanel
								label="Freezer"
								title={settingContent2.header}
							>
								{settingContent2.content}
							</EditableSidePanel>
						</div>
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
