import React from "react";
import { Button as AntButton } from "antd";
import styles from "./index.module.scss";
import {
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodIcon,
	LayerSystemContainer,
	Spin,
} from "@components";
import cn from "classnames";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";

export type ButtonType =
	| "primary"
	| "ghost"
	| "text"
	| "link"
	| "danger"
	| "layer";

export type ButtonProps = Omit<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	"type" | "color"
> & {
	/**
	 * Button size. Default is "default".
	 * Default button height: 32px
	 * Small button height: 24px
	 * Note: link type is always treated as small
	 * */
	size?: "default" | "small";
	/** Type of the button. Default is "primary" */
	type?: ButtonType;
	/** Button icon or the name of a `GenemodIcon`. Placed before the button text/children. */
	icon?: string | JSX.Element;
	/** Applies fill/stroke to icon with the default button text color. */
	iconAutoColor?: boolean;
	/** Loading state */
	loading?: boolean;
	/** If true, button expands to the full width of the parent container */
	stretch?: boolean;
	shape?: "rounded" | "squared";
	ref?: React.RefObject<HTMLButtonElement | null>;
	dataCy?: string;
	iconClassName?: string;
};

export default function Button(props: ButtonProps): JSX.Element {
	const { className, stretch = false, children } = props;
	// Remove unwanted AntD props
	const {
		disabled = false,
		size = "default",
		type = "primary",
		icon: propIcon = "",
		iconAutoColor = true,
		loading = false,
		shape = "rounded",
		iconClassName,
		...newProps
	} = props;

	// Do not display icon when loading
	const icon = !loading && propIcon;

	// List of classes
	const classNames = React.useMemo(() => {
		return cn(
			"genemod-button",
			styles.genemodButton,
			className,
			styles[type],
			{
				[styles.disabled]: disabled,
				[styles.stretch]: stretch,
				[styles.smallSize]:
					type === "link" || type === "text" || size === "small",
				[styles.defaultSize]:
					type !== "link" && type !== "text" && size === "default",
				[styles.squared]: shape === "squared",
				[styles.icon]: !!icon,
			}
		);
	}, [size, shape, type, className, disabled]);

	return (
		<AntButton
			{...(newProps as any)}
			className={classNames}
			disabled={disabled}
		>
			<div className={styles.GenemodButtonLayout} data-cy={props.dataCy}>
				{icon && (
					<div className={cn(styles.imageFile, iconClassName)}>
						{typeof icon === "string" ? (
							<GenemodIcon
								name={icon as IconName}
								iconClassName={cn({
									[styles[
										`buttonIcon__${
											disabled ? "disabled" : type
										}`
									]]: iconAutoColor,
								})}
							/>
						) : (
							icon
						)}
					</div>
				)}
				<Spin
					spinning={!!loading}
					className={styles.spinner}
					indicator={<LoadingSpinner />}
				>
					<span
						className={cn(styles.buttonTextLayout, {
							// Hide the text when loading
							[styles.buttonTextLayout__loading]: loading,
							[styles.buttonTextLayout__disabled]: disabled,
						})}
					>
						{children}
					</span>
				</Spin>
			</div>
		</AntButton>
	);
}

const LoadingSpinner = () => (
	<svg
		className={styles.loadingSpinner}
		viewBox="0 0 1024 1024"
		focusable="false"
		width="1em"
		height="1em"
	>
		<path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
	</svg>
);

export function BUTTON_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo title="Normal Buttons">
					<div className={styles.demoDisplay}>
						<div className={styles.buttonLayout}>
							<Button>Primary</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="ghost">Ghost</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button disabled>Disabled</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button disabled type="danger">
								Danger
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button shape="squared">Squared</Button>
						</div>
					</div>
				</Demo>
				<Demo title="Small Buttons">
					<div className={styles.demoDisplay}>
						<div className={styles.buttonLayout}>
							<Button size="small">Sm Primary</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button size="small" type="ghost">
								Sm Ghost
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="link">Link</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="text">Text</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button size="small" disabled>
								Sm Disabled
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="link" disabled>
								Link Disabled
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="text" disabled>
								Text Disabled
							</Button>
						</div>
						<Button size="small" type="danger">
							Sm Danger
						</Button>
					</div>
				</Demo>
				<Demo title="Icons">
					<div
						className={styles.demoDisplay}
						style={{ marginBottom: "16px" }}
					>
						<div className={styles.buttonLayout}>
							<Button icon="help">Primary icon</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="ghost" icon="help">
								Ghost icon
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="link" icon="help">
								Link icon
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="text" icon="help">
								Text icon
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button disabled icon="help">
								Disabled icon
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button
								type="danger"
								icon={<GenemodIcon name="trash" />}
							>
								Danger icon
							</Button>
						</div>
					</div>
					<div className={styles.demoDisplay}>
						<div className={styles.buttonLayout}>
							<Button size="small" icon="help">
								Sm primary icon
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button size="small" type="ghost" icon="help">
								Sm ghost icon
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="link" icon="help">
								Placeholder
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button size="small" disabled icon="help">
								Sm disabled icon
							</Button>
						</div>
						<Button
							size="small"
							type="danger"
							icon={<GenemodIcon name="trash" />}
						>
							Sm danger icon
						</Button>
					</div>
				</Demo>
				<Demo title="Combining variants and edge cases">
					<Button type="ghost" stretch>
						Stretched button
					</Button>
					<br />
					<br />
					<Button type="link" stretch>
						Stretched link
					</Button>
					<br />
					<br />
					<Button type="ghost" icon="help" stretch>
						Stretched ghost with icon
					</Button>
					<br />
					<br />
					<Button type="link" icon="help" stretch>
						Link Button with Fixed Size and Icon
					</Button>
				</Demo>
				<Demo title="Edge cases">
					<Button style={{ width: "100px" }}>Overflow text</Button>
					<br />
					<Button style={{ width: "100px" }} type="ghost">
						Overflow text
					</Button>
				</Demo>
				<Demo title="Loading Buttons">
					<div className={styles.demoDisplay}>
						<div className={styles.buttonLayout}>
							<Button loading>Primary</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="ghost" loading>
								Ghost
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button disabled loading>
								Disabled
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button disabled type="danger" loading>
								Danger
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button shape="squared" loading>
								Squared
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="link" loading>
								Link
							</Button>
						</div>
						<div className={styles.buttonLayout}>
							<Button type="text" loading>
								Text
							</Button>
						</div>
					</div>
				</Demo>
				<Demo title="Layer Button">
					<div className={styles.demoDisplay}>
						<div className={styles.buttonLayout}>
							<LayerSystemContainer
								overrideLayer="background"
								className={styles.layerContainer}
							>
								<Button type="layer">Background</Button>
							</LayerSystemContainer>
						</div>
						<div className={styles.buttonLayout}>
							<LayerSystemContainer
								overrideLayer={1}
								className={styles.layerContainer}
							>
								<Button type="layer">Layer 1</Button>
							</LayerSystemContainer>
						</div>
						<div className={styles.buttonLayout}>
							<LayerSystemContainer
								overrideLayer={2}
								className={styles.layerContainer}
							>
								<Button type="layer">Layer 2</Button>
							</LayerSystemContainer>
						</div>
						<div className={styles.buttonLayout}>
							<LayerSystemContainer
								overrideLayer={3}
								className={styles.layerContainer}
							>
								<Button type="layer">Layer 3</Button>
							</LayerSystemContainer>
						</div>
						<div className={styles.buttonLayout}>
							<LayerSystemContainer
								overrideLayer={2}
								className={styles.layerContainer}
							>
								<Button type="layer" loading>
									Layer 2 Loading
								</Button>
							</LayerSystemContainer>
						</div>

						<div className={styles.buttonLayout}>
							<LayerSystemContainer
								overrideLayer={2}
								className={styles.layerContainer}
							>
								<Button type="layer" disabled>
									Layer 2 Disabled
								</Button>
							</LayerSystemContainer>
						</div>

						<div className={styles.buttonLayout}>
							<LayerSystemContainer
								overrideLayer={2}
								className={styles.layerContainer}
							>
								<Button type="layer" icon="help">
									Layer 2 Icon
								</Button>
							</LayerSystemContainer>
						</div>
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
