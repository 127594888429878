import { IconName } from "@common/components/GenemodIcon/GenemodIconRegistry";
import { Color, ColorCssVarMap } from "@common/styles/Colors";
import {
	FreezerOrderId,
	MAP_NOTIFICATION_SOURCE_TO_LINK,
	Notification,
	NotificationApps,
	getFullNameFromAvatar,
	isNotificationSourceType,
} from "@common/types";
import {
	GenemodDot,
	GenemodIcon,
	GenemodLink,
	Tooltip,
	Typography,
	UserAvatar,
} from "@components";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import { useNotificationPatchMutation } from "@redux/Dashboard/DashboardApiSlice";
import cn from "classnames";
import React from "react";
import Moment from "react-moment";
import styles from "./NotificationCard.module.scss";

const avatarIconMap: {
	[key: string]: { icon: IconName; color: Color };
} = {
	INVENTORY: {
		icon: "freezer",
		color: "blue-contrast",
	},
	PROJECTS: {
		icon: "active-projects",
		color: "pastel-violet",
	},
	MENTIONS: {
		icon: "mention-at",
		color: "pastel-yellow",
	},
	EQUIPMENTS: {
		icon: "microscope",
		color: "blue-contrast",
	},
};

type Props = {
	notification: Notification;
	setNotificationsPanelVisible: (visible: boolean) => void;
};

const NotificationCard = ({
	notification,
	setNotificationsPanelVisible,
}: Props): JSX.Element => {
	const [patchNotification] = useNotificationPatchMutation();
	const { openOrdersInfoPanel } = useCommonPanelState("ordersInfoPanel");
	const { sender, description, object_name, read, created_at, object_id } =
		notification;
	const type: "user" | "stock" =
		notification.source === "STOCK_ALERT_RUNNING_LOW" ? "stock" : "user";
	const avatarIcon =
		avatarIconMap[NotificationApps[notification.notification_type]];
	const linkTo = isNotificationSourceType(notification.source)
		? MAP_NOTIFICATION_SOURCE_TO_LINK[notification.source]
		: "none";
	return (
		<GenemodLink
			to={linkTo}
			objectId={object_id}
			hoverUnderline={false}
			onClick={() => {
				if (notification.source === "ORDER_COMMENT") {
					openOrdersInfoPanel({
						orderId: object_id as FreezerOrderId,
						defaultTab: "3",
					});
					setNotificationsPanelVisible(false);
				}

				patchNotification({
					id: notification.id,
					read: true,
				});
			}}
			searchParams={
				notification.source === "EXPERIMENT_MENTION"
					? {
							notificationId: notification.id,
					  }
					: {}
			}
		>
			<div className={styles.main}>
				<div className={styles.avatarWrapper}>
					{type === "user" ? (
						<UserAvatar user={sender} size={32}></UserAvatar>
					) : (
						<div
							className={styles.typeIcon}
							style={{ backgroundColor: ColorCssVarMap["brown"] }}
						>
							<GenemodIcon
								name={type}
								color="text-on-color"
								fill="text-on-color"
								style={{ cursor: "default" }}
							/>
						</div>
					)}

					<div
						className={styles.notificationIcon}
						style={{
							backgroundColor: ColorCssVarMap[avatarIcon.color],
						}}
					>
						<GenemodIcon
							color="text-on-color"
							fill="text-on-color"
							name={avatarIcon.icon}
							size="small"
							style={{ cursor: "default" }}
						/>
					</div>
				</div>
				<div className={styles.content}>
					<Typography variant="label">
						{type === "user" &&
							`${
								sender
									? getFullNameFromAvatar(sender)
									: "Deleted user"
							} `}
						{`${description}${type === "user" ? " " : ": "}`}
						<Typography
							className={cn(styles.objectName, {
								[styles.objectName__link]: linkTo !== "none",
							})}
							variant="label"
							color="none"
						>
							{object_name}
						</Typography>
					</Typography>
					<Typography
						variant="caption"
						color="text-tertiary"
						style={{ marginTop: 4 }}
					>
						<Moment fromNow>{created_at}</Moment>
					</Typography>
				</div>
				<Tooltip
					title={
						<Typography variant="caption" color="text-inverse">
							Mark as {read ? "unread" : "read"}
						</Typography>
					}
				>
					<span
						className={cn(styles.dot, { [styles.read]: read })}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							patchNotification({
								id: notification.id,
								read: !read,
							});
						}}
					>
						<GenemodDot
							size={8}
							color={
								!read
									? ColorCssVarMap["accent-strong"]
									: ColorCssVarMap["button-disabled"]
							}
						/>
					</span>
				</Tooltip>
			</div>
		</GenemodLink>
	);
};

export default NotificationCard;
