import { AtLeastOneElement } from "@helpers/TypeHelpers";
import { Editor, Element, NodeEntry, Node, Transforms, Range } from "slate";
import { CustomElement, CustomElementType } from "../types";
import { ContentType } from "@common/types";

export type UtilityEditor = {
	/**
	 * Get blocks of a given type in the active selection range.
	 */
	getNodesOfType: (
		block: CustomElement["type"],
		options?: Parameters<typeof Editor.nodes>[1]
	) => Generator<NodeEntry<Node>, void, undefined>;

	/**
	 * Returns true if the current selection is within a specific block
	 */
	isNodeOfTypeActive: (
		...types: AtLeastOneElement<CustomElementType>
	) => boolean;

	/**
	 * Gets all of the currently active node entries.
	 */
	getAllActiveNodes: () => NodeEntry<Node>[];

	/**
	 * Replace nodes with other nodes or text.
	 * Note: unlike Transforms.insertText, this function will not remove text behind a node you are trying to replace.
	 */
	replaceNodes: (
		replacement: string | Node,
		options?: Parameters<typeof Transforms.removeNodes>[1]
	) => void;

	/**
	 * Copy the entire contents of a document to the existing editor. This should. trigger and onChange/live editor lifecycle functions.
	 * Returns same editor.
	 */
	updateFullContent: (content: ContentType) => void;

	/**
	 * Gets range of the one word under the cursor when selection is collapsed.
	 * if there is no word under the cursor, return undefined.
	 */
	getWordRange: () => Range | undefined;
};

/**
 * Renders the given placeholder plugin when the document body is empty.
 */
export const withUtilityPlugin = <T extends Editor>(
	e: T
): T & UtilityEditor => {
	const editor = e as unknown as T & UtilityEditor;
	editor.getNodesOfType = (block, options = {}) => {
		const { match = () => true, ...other } = options;

		const matches = Editor.nodes(editor as any, {
			match: (n, p) => {
				return (
					!Editor.isEditor(n) &&
					Element.isElement(n) &&
					n.type === block &&
					match(n, p)
				);
			},
			...other,
		});
		return matches;
	};

	editor.isNodeOfTypeActive = (...types) =>
		!!types.find((type) => {
			const [match] = editor.getNodesOfType(type);
			return match;
		});

	editor.getAllActiveNodes = () => [
		...Editor.nodes(editor as any, {
			match: (n) => {
				return !Editor.isEditor(n) && Element.isElement(n);
			},
		}),
	];

	editor.replaceNodes = (replacement, options = {}) => {
		if (typeof replacement === "string") {
			replacement = { text: replacement };
		}
		Editor.withoutNormalizing(editor as any, () => {
			Transforms.removeNodes(editor as any, options);
			Transforms.insertNodes(editor as any, replacement as Node, options);
		});
	};

	editor.updateFullContent = (content) => {
		// Copy document content to current live editor. Doing `editor.children = content` will not call
		// all of the needed lifecycle functions. To replace document content we need to select and replace everything.
		Transforms.select(editor, {
			anchor: Editor.start(editor, []),
			focus: Editor.end(editor, []),
		});
		Transforms.insertFragment(editor, [...content]);

		// Clear undo history
		// Note: the undo history plugin will not always be present (see: SlateTextArea)
		(editor as any).clearUndoHistory?.();
		// Trigger detection of changes
		editor.onChange();
	};

	editor.getWordRange = () => {
		const findLeftOffset = (word: string, offset: number): number => {
			if (offset < 0 || word[offset] === " ") return offset + 1;
			return findLeftOffset(word, offset - 1);
		};

		const findRightOffset = (word: string, offset: number): number => {
			if (word[offset] === " " || offset >= word.length) return offset;
			return findRightOffset(word, offset + 1);
		};

		if (!editor.selection || !Range.isCollapsed(editor.selection)) {
			return undefined;
		}

		const { path, offset } = editor.selection.focus;
		const [node] = Editor.node(editor, editor.selection);

		const text = Node.string(node);
		const leftEnd = findLeftOffset(text, offset - 1);
		const rightEnd = findRightOffset(text, offset);

		if (leftEnd === rightEnd) return undefined;

		return {
			anchor: { path, offset: leftEnd },
			focus: { path, offset: rightEnd },
		};
	};
	return editor;
};
