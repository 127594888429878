import {
	useOrderStatusSettingsPatchMutation,
	useOrderStatusSettingsQuery,
	_useOrdersSearchQuery,
	useOrderPatchMutation,
} from "@redux/freezer/FreezerApiSlice";
import { useCurrentWorkspaceUuid } from "@helpers/Hooks/UseCurrentWorkspaceUuid";
import useCurrentWorkspaceUserHooks from "@helpers/Hooks/UseCurrentWorkspaceUserHook";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	FreezerOrder,
	FreezerOrderStatusId,
	FREEZER_ORDER_STATUSES,
	FreezerOrderId,
} from "@common/types";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { Notification } from "@components";
/**
 * Gets actions required to move the order to the next step.
 */
export const useOrderStatusAction = () => {
	const [patchOrder] = useOrderPatchMutation();
	const [orderSettings] = useOrderSettings();
	const { isCurrentWorkspaceUserAdmin } = useCurrentWorkspaceUserHooks();

	const getActions = (
		status: FreezerOrderStatusId,
		orderId: FreezerOrderId
	) => {
		const possibleNextStatuses =
			status !== undefined && status !== null && orderSettings
				? getPossibleStateChanges(
						status,
						isCurrentWorkspaceUserAdmin,
						orderSettings.require_order_approval
				  )
				: [];
		const actions = {
			0: {
				label: "Approve",
				disabled: !possibleNextStatuses.includes(
					FREEZER_ORDER_STATUSES.Approved.id
				),
				onClick: () => {
					patchOrder({
						id: orderId,
						status: FREEZER_ORDER_STATUSES.Approved.id,
					});
				},
			},
			1: {
				label: "Place order",
				disabled: !possibleNextStatuses.includes(
					FREEZER_ORDER_STATUSES.Ordered.id
				),
				onClick: () => {
					patchOrder({
						id: orderId,
						status: FREEZER_ORDER_STATUSES.Ordered.id,
					});
				},
			},
			2: {
				label: "Receive",
				disabled: !possibleNextStatuses.includes(
					FREEZER_ORDER_STATUSES.Received.id
				),
				onClick: () => {
					patchOrder({
						id: orderId,
						status: FREEZER_ORDER_STATUSES.Received.id,
					});
				},
			},
			3: {
				label: "Archive order",
				disabled: false,
				onClick: () => {
					patchOrder({
						id: orderId,
						is_archived: true,
					})
						.unwrap()
						.then((order) => {
							Notification.success({
								message: `Order ${order.name} has been archived".`,
							});
						});
				},
			},
			4: {
				label: "Archive order",
				disabled: false,
				onClick: () => {
					patchOrder({
						id: orderId,
						is_archived: true,
					})
						.unwrap()
						.then((order) => {
							Notification.success({
								message: `Order ${order.name} has been archived".`,
							});
						});
				},
			},
		};

		return actions[status as keyof typeof actions];
	};

	return getActions;
};

/**
 * Gets the freezer order settings. Creates it if it doesn't exist.
 */
export const useOrderSettings = () => {
	const currentWorkspaceId = useCurrentWorkspaceUuid();
	const { data } = useOrderStatusSettingsQuery(currentWorkspaceId);
	const [updateSettings] = useOrderStatusSettingsPatchMutation();
	const settings = data?.results?.[0];
	return [settings, updateSettings] as const;
};

/**
 * Get the possible status changes for a given order status for the current user and settings.
 */
export const useCanModifyOrder = () => {
	const [settings] = useOrderSettings();
	return (order: FreezerOrder | undefined) => {
		if (!order || order?.is_archived) return false;

		const allow_modification_after_approval =
			settings?.allow_modification_after_approval || false;
		const status = order?.status ?? FREEZER_ORDER_STATUSES.Denied.id;

		const canModify =
			allow_modification_after_approval ||
			status === FREEZER_ORDER_STATUSES.Requested.id;

		return canModify;
	};
};

/**
 * Get the possible status changes for a given order status for the current user and settings.
 */
export const usePossibleStatusChanges = (
	status: FreezerOrderStatusId | undefined
) => {
	const [orderSettings] = useOrderSettings();
	const { isCurrentWorkspaceUserAdmin } = useCurrentWorkspaceUserHooks();

	const possibleNextStatuses =
		status !== undefined && orderSettings
			? getPossibleStateChanges(
					status,
					isCurrentWorkspaceUserAdmin,
					orderSettings.require_order_approval
			  )
			: [];
	return possibleNextStatuses;
};

export const useOrdersSearchQuery: typeof _useOrdersSearchQuery = (...args) => {
	const workspace = useCurrentWorkspaceUuid();
	const [filters, ...otherArgs] = args;
	return _useOrdersSearchQuery(
		filters === skipToken
			? skipToken
			: {
					workspace,
					...filters,
			  },
		...otherArgs
	);
};

/**
 * Get the possible status changes for a given order.
 */
const getPossibleStateChanges = (
	currentStatus: FreezerOrderStatusId,
	isAdmin: boolean,
	require_order_approval: boolean
): FreezerOrderStatusId[] => {
	const anyStatus = [
		FREEZER_ORDER_STATUSES.Requested.id,
		FREEZER_ORDER_STATUSES.Approved.id,
		FREEZER_ORDER_STATUSES.Ordered.id,
		FREEZER_ORDER_STATUSES.Received.id,
		FREEZER_ORDER_STATUSES.Denied.id,
	];

	const noStatus = [] as FreezerOrderStatusId[];

	if (!require_order_approval) return anyStatus;

	const newStatusMap = {
		ADMIN: {
			[FREEZER_ORDER_STATUSES.Requested.id]: [
				FREEZER_ORDER_STATUSES.Approved.id,
				FREEZER_ORDER_STATUSES.Denied.id,
			],
			[FREEZER_ORDER_STATUSES.Approved.id]: anyStatus,
			[FREEZER_ORDER_STATUSES.Ordered.id]: anyStatus,
			[FREEZER_ORDER_STATUSES.Received.id]: anyStatus,
			[FREEZER_ORDER_STATUSES.Denied.id]: [
				FREEZER_ORDER_STATUSES.Requested.id,
				FREEZER_ORDER_STATUSES.Approved.id,
			],
		},
		MEMBER: {
			[FREEZER_ORDER_STATUSES.Requested.id]: noStatus,
			[FREEZER_ORDER_STATUSES.Approved.id]: [
				FREEZER_ORDER_STATUSES.Requested.id,
				FREEZER_ORDER_STATUSES.Ordered.id,
				FREEZER_ORDER_STATUSES.Received.id,
			],
			[FREEZER_ORDER_STATUSES.Ordered.id]: [
				FREEZER_ORDER_STATUSES.Requested.id,
				FREEZER_ORDER_STATUSES.Approved.id,
				FREEZER_ORDER_STATUSES.Received.id,
			],
			[FREEZER_ORDER_STATUSES.Received.id]: [
				FREEZER_ORDER_STATUSES.Requested.id,
				FREEZER_ORDER_STATUSES.Approved.id,
				FREEZER_ORDER_STATUSES.Ordered.id,
			],
			[FREEZER_ORDER_STATUSES.Denied.id]: noStatus,
		},
	};

	return newStatusMap[isAdmin ? "ADMIN" : "MEMBER"][currentStatus].filter(
		(status) => status !== currentStatus
	);
};
