import React from "react";
import styles from "./HelpCenterPanel.module.scss";
import { Color, HexColorToRgbaText } from "@common/styles/Colors";
import { Typography, GenemodIcon } from "@common/components";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import { useTheme } from "../Theme/context/ThemeContext";

export type HelpCenterRowProps = {
	key: number;
	title: string;
	icon: IconName;
	description: string;
	color: Color;
	link: string;
};

export function HelpCenterRow({
	title,
	icon,
	description,
	color,
	link,
}: HelpCenterRowProps): JSX.Element {
	const [themeMode] = useTheme();
	return (
		<Link
			to={{
				pathname: link,
			}}
			target="_blank"
		>
			<div
				className={styles.row}
				style={{
					backgroundColor: HexColorToRgbaText({
						color,
						themeMode,
						opacity: 0.5,
					}),
				}}
			>
				<GenemodIcon className={styles.icon} name={icon} size="large" />
				<div style={{ width: "100%" }}>
					<div className={styles.innerTop}>
						<Typography
							className={styles.title}
							variant="subheadline"
							color="text-secondary"
							bold
						>
							{title}
						</Typography>
						<GenemodIcon
							className={styles.iconHover}
							name="link-tab"
							size="large"
							color="text-secondary"
						/>
					</div>
					<Typography
						className={styles.desc}
						color="text-tertiary"
						variant="label"
					>
						{description}
					</Typography>
				</div>
			</div>
		</Link>
	);
}
