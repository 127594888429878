import React, { HTMLProps, useState } from "react";
import { NotificationApps } from "@common/types";
import { Button, Typography } from "@components";
import { capitalizeFirstLetter } from "@helpers/Formatters";
import {
	NotificationFilter,
	useNotificationsCountQuery,
	useNotificationsMarkAllReadMutation,
	useNotificationsQuery,
} from "@redux/Dashboard/DashboardApiSlice";
import { TEN_MINUTE_POLLING } from "@redux/helpers/RtkQuery";
import { NotificationIdToLabel } from "@common/types/Notification";
import { Popover } from "antd";
import classNames from "classnames";
import { DropDownWithItems } from "../DropDown/dropdown";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import NotificationCard from "./components/NotificationCard";
import styles from "./NotificationNavbarButton.module.scss";
import EventLogSkeleton from "@containers/Dashboard/components/widgets/event-log/EventLogSkeleton";
import { useHistory } from "@helpers/Hooks/UseRouterDom";
import { ACCOUNT_SETTINGS_PATHS } from "@root/routes";
import { useOrganizationRouter } from "@root/AppRouter";

const NotificationNavbarButton = (
	props: HTMLProps<HTMLDivElement>
): JSX.Element => {
	const history = useHistory();
	const { appendBaseUrl } = useOrganizationRouter();
	const [filters, setFilters] = useState<NotificationFilter>({
		page: 1,
	});
	const { data: notificationSearchResult, isLoading } =
		useNotificationsQuery(filters);
	const [markAllAsRead] = useNotificationsMarkAllReadMutation();

	const { data: notificationCount } = useNotificationsCountQuery(
		undefined,
		TEN_MINUTE_POLLING
	);
	const showIndicator = !!notificationCount;
	const [visible, setVisible] = useState(false);

	const title = (
		<div className={styles.antPopoverTitle}>
			<Typography bold color="text-secondary">
				{`Notifications (${notificationCount})`}
			</Typography>
			<NotificationFilterButton
				filters={filters}
				onSelectFilters={setFilters}
			/>
			{notificationCount !== undefined && notificationCount > 0 && (
				<Typography
					variant="label"
					color="button-text"
					style={{ marginLeft: "auto", cursor: "pointer" }}
					onClick={() => {
						markAllAsRead()
							.unwrap()
							.catch((e) => {
								console.log(
									"Failed to mark all notifications as read",
									e
								);
							});
					}}
				>
					Mark all as read
				</Typography>
			)}
		</div>
	);

	const renderContent = () => {
		const isNoResults =
			(filters.read !== undefined ||
				filters.notification_type !== undefined) &&
			!notificationSearchResult?.results.length;

		if (isNoResults) {
			// when filter is applied, but there is no result,
			return (
				<Typography
					variant="label"
					color="text-tertiary"
					className={styles.emptyResult}
				>
					You don&apos;t have any notifications that match your
					filter.
				</Typography>
			);
		}

		if (isLoading && filters.page === 1) {
			return <EventLogSkeleton backgroundColor="button-disabled" />;
		}
		return (
			<div className={styles.popup}>
				<div className={styles.cardsContainer}>
					<div className={styles.cards}>
						{notificationSearchResult?.results.map(
							(notification) => (
								<NotificationCard
									key={notification.id}
									notification={notification}
									setNotificationsPanelVisible={setVisible}
								/>
							)
						)}
						<Typography
							variant="label"
							color="text-tertiary"
							className={classNames(styles.lastWords, {
								[styles.empty]: !filters.notification_type,
							})}
						>
							{filters.notification_type !== undefined ? (
								<>
									That&apos;s all your notifications for{" "}
									<b>
										{" "}
										{`${
											NotificationIdToLabel[
												filters.notification_type
											]
										} from last 30 days.`}
									</b>
								</>
							) : (
								<div style={{ paddingLeft: 24 }}>
									That&apos;s all your notifications{" "}
									<b> from last 30 days.</b>
								</div>
							)}
						</Typography>
					</div>
				</div>
				<div className={styles.notificationSettings}>
					<div
						style={{ display: "flex", alignItems: "center" }}
						onClick={() => {
							history.push(
								appendBaseUrl(
									ACCOUNT_SETTINGS_PATHS.NOTIFICATIONS.route
								)
							);
						}}
					>
						<Button type="text">
							Manage notification settings
						</Button>
						<GenemodIcon name="link-tab" color="button-text" />
					</div>
				</div>
			</div>
		);
	};

	return (
		<Popover
			placement="bottomRight"
			overlayClassName={styles.container}
			trigger="click"
			title={title}
			content={renderContent()}
			visible={visible}
			onVisibleChange={setVisible}
		>
			<div {...props} className={styles.notificationIconWrapper}>
				<GenemodIcon
					className={classNames(styles.icon, {
						[styles.icon__active]: visible,
					})}
					name="bell"
					size="large"
					showNotification={showIndicator}
				/>
			</div>
		</Popover>
	);
};

type NotificationFilterButtonProps = {
	filters: NotificationFilter;
	onSelectFilters: (filter: NotificationFilter) => void;
};
function NotificationFilterButton({
	filters,
	onSelectFilters,
}: NotificationFilterButtonProps) {
	const [filterDropdownVis, setFilterDropdownVis] = useState(false);
	const handleFilters = (
		selectedOption: Omit<NotificationFilter, "page">
	) => {
		onSelectFilters({
			...filters,
			...selectedOption,
		});
	};
	const isFilterApplied =
		filters.notification_type !== undefined || filters.read !== undefined;
	const filterOptions = [
		{
			label: "Unread",
			icon: "eye",
			action: () => handleFilters({ read: false }),
		},
		{
			label: "Mentions",
			icon: "mention-at",
			action: () =>
				handleFilters({
					notification_type: NotificationApps.MENTIONS,
				}),
		},
		{
			label: "Inventory",
			icon: "freezer",
			action: () =>
				handleFilters({
					notification_type: NotificationApps.INVENTORY,
				}),
		},
		{
			label: "Projects",
			icon: "active-projects",
			action: () =>
				handleFilters({
					notification_type: NotificationApps.PROJECTS,
				}),
		},
	] as const;

	const renderAppliedFilter = () => {
		const label =
			filters.notification_type !== undefined
				? capitalizeFirstLetter(
						NotificationApps[
							filters.notification_type
						].toLowerCase()
				  )
				: "Unread";
		return (
			<div className={styles.appliedFilter}>
				<Typography variant="label">{label}</Typography>
				<GenemodIcon
					name="cancel"
					onClick={() =>
						handleFilters({
							read: undefined,
							notification_type: undefined,
						})
					}
				/>
			</div>
		);
	};
	const renderFilterButton = () => (
		<DropDownWithItems
			visible={filterDropdownVis}
			onVisibleChange={setFilterDropdownVis}
			_afterClickHandler={() => setFilterDropdownVis(false)}
			menu={filterOptions}
		>
			<GenemodIcon
				name="filter"
				fill={filterDropdownVis ? "accent-subtle" : "text-ghost"}
				color="text-secondary"
			/>
		</DropDownWithItems>
	);

	return isFilterApplied ? renderAppliedFilter() : renderFilterButton();
}
export default NotificationNavbarButton;
