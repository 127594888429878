import React, { useRef, useState } from "react";
import {
	InfiniteScrollingConfig,
	useInfiniteScrolling,
} from "@common/helpers/Hooks";
import { getFullNameFromAvatar } from "@common/types/User";
import {
	LoadingSpinner,
	Typography,
	EmptyState,
	UserAvatar,
} from "@components";
import RestrictionGradientLayout from "@containers/Freezer/components/RestrictionGradientLayout/RestrictionGradientLayout";
import styles from "./index.module.scss";
import { Event, EventType } from "@common/types/Event";
import Moment from "react-moment";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import cn from "classnames";
import { useLazyEventsQuery } from "@redux/events";
import moment from "moment";
import EMPTY_STATE_IMG_DARK from "./assets/activity-empty-dark.png";
import EMPTY_STATE_IMG_LIGHT from "./assets/activity-empty-light.png";
import { emphasizeText } from "@helpers/Formatters";

type EventDataProps = {
	event: Event;
	/**
	 * Returns the user-facing name for an object type.
	 */
	renderObjectType: (event: Event) => string;
	/**
	 * Returns a link given an event. Not utilized in DELETE events.
	 */
	getLink: (event: Event) => string;
};

/**
 * Converts an event type into a user-facing verb in past-tense.
 */
function eventTypeToVerb(type: EventType) {
	return (
		{
			[EventType.CREATE]: "created",
			[EventType.UPDATE]: "updated",
			[EventType.DELETE]: "deleted",
		}[type] || ""
	);
}

/**
 * Renders a single Event.
 */
function EventData({ event, renderObjectType, getLink }: EventDataProps) {
	const { user, event_type, timestamp, object_name } = event;
	const isDelete = event_type === EventType.DELETE;

	let content = (
		<div
			className={cn(styles.eventData, {
				[styles.eventData__nolink]: isDelete,
			})}
		>
			<UserAvatar
				user={user}
				size={32}
				avatarStyle={{
					marginRight: 8,
					cursor: isDelete ? "default" : "pointer",
				}}
			/>
			<div>
				<Typography>
					{user ? getFullNameFromAvatar(user) : "Deleted user"}{" "}
					{eventTypeToVerb(event_type)} {renderObjectType(event)}{" "}
					<Typography
						className={styles.link}
						color={isDelete ? "text-primary" : "button-text"}
					>
						{object_name}
					</Typography>
				</Typography>
				<Typography variant="label" color="text-tertiary">
					<Moment format="MMMM D, YYYY [at] LT">{timestamp}</Moment>
				</Typography>
			</div>
		</div>
	);

	if (!isDelete) {
		content = <Link to={getLink(event)}>{content}</Link>;
	}
	return content;
}

type EventLogProps = {
	page_size?: number;
	renderObjectType: (event: Event) => string;
	getLink: (event: Event) => string;
	/**
	 * The max number of events to display.
	 */
	max?: number;
	/**
	 * Used in plan restriction upgrade message. The number of months that the
	 * **next** plan provides.
	 */
	nextPlanRestriction?: number;
};

const DEFAULT_PAGE_SIZE = 25;

/**
 * Handles fetching and rendering event log events.
 * TODO Handle plan limit of `Null`
 * TODO Add support for "View all activities" button
 * TODO Are the events sorted by timestamp?
 * TODO Add support for filtering via props
 */
export function EventLog({
	page_size = DEFAULT_PAGE_SIZE,
	renderObjectType,
	getLink,
	max,
	nextPlanRestriction,
}: EventLogProps) {
	const loaderRef = useRef<HTMLDivElement>(null);
	const [_events, setEvents] = useState<Event[]>([]);
	const [fetchEvents, { isFetching, isError, data }] = useLazyEventsQuery();
	const events = _events.slice(0, max || _events.length);

	/**
	 * Called when the user reaches the bottom of the activity log
	 */
	const handleScrollingFetch: InfiniteScrollingConfig["callback"] = async ({
		page,
		setPage,
		setEnabled,
	}) => {
		fetchEvents({
			page,
			page_size: max !== undefined && max < page_size ? max : page_size,
		}).then((response) => {
			if (response.isSuccess) {
				const { data } = response;
				setEvents((prev) => [...prev, ...data.results]);

				// Stop fetching if no more results
				const { total_page_count, count } = data;
				if (
					(total_page_count && page >= total_page_count) ||
					page_size * page >= count
				) {
					setEnabled(false);
				} else {
					setPage((p) => p + 1);
				}
			}
		});
	};

	const { enabled } = useInfiniteScrolling({
		target: loaderRef,
		callback: handleScrollingFetch,
		initOptions: {
			firstFetch: true,
		},
	});

	// Show the restriction message if we've reached the end
	// of the activity log and there are hidden activities.
	const showRestrictionMessage =
		!enabled &&
		data?.num_restricted_activities !== undefined &&
		data.num_restricted_activities > 0;

	return (
		<div className={styles.eventLog}>
			{!events.length && !isFetching && !isError ? (
				<EmptyState
					img={{
						light: EMPTY_STATE_IMG_LIGHT,
						dark: EMPTY_STATE_IMG_DARK,
					}}
					title="No activities found"
					description="Your actions from the past month will be displayed here"
				/>
			) : (
				events.reduce<{
					jsx: React.ReactNode[];
					currentDay: string | undefined;
				}>(
					(accumulator, event) => {
						// Add a new header for each day.
						if (
							!accumulator.currentDay ||
							!moment(accumulator.currentDay).isSame(
								event.timestamp,
								"day"
							)
						) {
							accumulator.currentDay = event.timestamp;
							const date = moment(event.timestamp);
							const title = date.isSame(moment(), "day")
								? "today"
								: date.format("MMMM D, YYYY");
							accumulator.jsx.push(
								<Typography
									color="text-tertiary"
									variant="subheadline"
								>
									{title.toUpperCase()}
								</Typography>
							);
						}
						accumulator.jsx.push(
							<EventData
								event={event}
								renderObjectType={renderObjectType}
								getLink={getLink}
							/>
						);
						return accumulator;
					},
					{
						jsx: [],
						currentDay: undefined,
					}
				).jsx
			)}
			{data?.num_restricted_activities !== undefined &&
				showRestrictionMessage && (
					<RestrictionGradientLayout
						title={emphasizeText`Upgrade to unlock ${"activity history"}`(
							"accent-subtle"
						)}
						hideGradient={data.num_restricted_activities < 1}
					/>
				)}
			<LoadingSpinner centered loading={isFetching} />
			<div ref={loaderRef} key="loader" />
		</div>
	);
}
