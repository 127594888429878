import React, { useState, useContext, createContext, useEffect } from "react";
import { nanoid } from "nanoid";
import { useOnUnmount } from "@helpers/Hooks";

type MaskType = "MODAL" | "SIDE_PANEL";

/**
 * Uses an app-wide stack to store unique ids for "overlays" (modals, settings) that ensures that only one is visible at at time.
 */
export const useVisibleOverlayMask = (
	type: MaskType,
	visible: boolean,
	skipMask: boolean
): boolean => {
	const [id] = useState(nanoid());
	const { visibleOverlayIds, setVisibleOverlayIds } = useContext(
		VisibleOverlayContext
	);
	const ids = visibleOverlayIds[type];
	const index = ids.indexOf(id);

	useEffect(() => {
		setVisibleOverlayIds((visibleOverlayIds) => {
			const ids = visibleOverlayIds[type];
			const copy = [...ids];
			if (visible && index < 0) {
				copy.push(id);
			} else if (!visible && index >= 0) {
				copy.splice(index, 1);
			}
			return {
				...visibleOverlayIds,
				[type]: copy,
			};
		});
	}, [visible, index]);

	// Clean up after component unmount
	useOnUnmount(() => {
		setVisibleOverlayIds((obj) => {
			const ids = obj[type];
			const index = ids.indexOf(id);
			if (index >= 0) {
				const copy = [...ids];
				copy.splice(index, 1);
				return {
					...obj,
					[type]: copy,
				} as Record<MaskType, string[]>;
			}
			return obj;
		});
	});

	if (skipMask) return visible;

	// Get visibility
	const visibleId = ids[ids.length - 1];
	const isVisible = visibleId === id;
	return isVisible;
};

export const VisibleOverlayContext = createContext({
	visibleOverlayIds: {
		MODAL: [] as string[],
		SIDE_PANEL: [] as string[],
	} as const,
	setVisibleOverlayIds: (() => {}) as React.Dispatch<
		React.SetStateAction<Record<MaskType, string[]>>
	>,
});

export const VisibleOverlayContextWrapper = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [visibleModalIds, setVisibleModalIds] = useState({
		MODAL: [] as string[],
		SIDE_PANEL: [] as string[],
	} as const);

	return (
		<VisibleOverlayContext.Provider
			value={{
				visibleOverlayIds: visibleModalIds,
				setVisibleOverlayIds: setVisibleModalIds,
			}}
		>
			{children}
		</VisibleOverlayContext.Provider>
	);
};
