import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { Typography } from "@components";
import { Fade } from "react-reveal";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import "../../../index.scss";

/**
 * Thank you page after registration is complete
 * @function
 * @param {boolean} [props.sentInvites]
 * @param {string} [props.name] Name of the user
 * If true, shows invite confirmation message
 * @param {boolean} [props.invited] The user is invited
 * @param {history} [props.history] history object
 * @param {function} [toDashboard] a function to route to the dashboard
 * */
export function ThankYou({ sentInvites, name, invited, history, toDashboard }) {
	useEffect(() => {
		if (invited) {
			setTimeout(function () {
				toDashboard();
			}, 2000);
		}
	}, [invited]);

	return (
		<Fade>
			<div className="auth-body">
				<Typography variant="title" bold>
					{invited ? (
						<>You&apos;re all set{name ? `, ${name}` : ""}</>
					) : (
						<>
							Almost done! Check your email to verify your account
						</>
					)}
				</Typography>
				<Typography
					style={{
						marginBottom: 56,
					}}
				>
					{invited ? (
						<>
							Click{" "}
							<Link
								to="/app/dashboard"
								style={{
									color: "var(--dark)",
									textDecoration: "underline",
								}}
							>
								here
							</Link>{" "}
							if you are not redirected in 5 seconds
						</>
					) : (
						<>
							Your account security is our top priority. To best
							protect your data, please verify your email before
							signing in.
						</>
					)}{" "}
					{sentInvites
						? "All of your invites have been sent out."
						: ""}
				</Typography>
				<div className="auth-centerRow">
					<img
						// src={invited ? THANK_YOU : ALMOST_DONE}
						alt="Registration complete"
						className={
							invited
								? styles.thankYouImage
								: styles.almostDoneImage
						}
					/>
				</div>
			</div>
		</Fade>
	);
}
