import classNames from "classnames";
import React, { HTMLProps, ReactNode } from "react";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import { useTheme } from "../../Theme/context/ThemeContext";
import { THEME_PREFERENCE } from "../../Theme/context/ThemeTypes";
import Typography from "../../Typography/Typography";
import styles from "./EmptyState.module.scss";

export type EmptyStateProps = {
	img: {
		dark: string;
		light: string;
	};
	imgStyle?: React.CSSProperties;
	title: string;
	description?: string;
	children?: ReactNode;
	learnMore?: string | ReactNode;
	dataCy?: string;
} & HTMLProps<HTMLDivElement>;

const EmptyState = ({
	img,
	title,
	description,
	children,
	learnMore,
	className,
	imgStyle,
	dataCy,
	...props
}: EmptyStateProps): JSX.Element => {
	const [theme] = useTheme();
	return (
		<div
			{...props}
			className={classNames(styles.main, className)}
			data-cy={dataCy}
		>
			<img
				src={theme === THEME_PREFERENCE.light ? img.light : img.dark}
				alt="Empty state"
				style={{
					marginBottom: 48,
					...imgStyle,
				}}
			/>
			<Typography
				color="text-primary"
				variant="headline2"
				style={{
					textAlign: "center",
					marginBottom: children ? "24px" : 0,
				}}
			>
				{title}
			</Typography>
			{children}
			{learnMore && (
				<span style={{ marginTop: children ? 16 : 32 }}>
					{typeof learnMore === "string" ? (
						<Link
							to={{
								pathname: learnMore,
							}}
							target="_blank"
						>
							Learn more
						</Link>
					) : (
						learnMore
					)}
				</span>
			)}
		</div>
	);
};

export default EmptyState;
