import React from "react";
import styles from "./Spin.module.scss";
import cn from "classnames";
import { Spin as AntSpin } from "antdv4";
import SPINNERV1 from "@common/icons/spinner.svg";
import SPINNERV2 from "@common/icons/spinner-v2.svg";
import { Demo, DemoSection, DemoWrapper, SVG } from "..";

type AntSpinProps = React.ComponentProps<typeof AntSpin> & {
	children?: React.ReactNode;
	spinnerClassName?: string;
	spinnerVersion?: number;
};

export function Spin(props: AntSpinProps) {
	let SPINNER = SPINNERV1;
	switch (props.spinnerVersion) {
		case 2:
			SPINNER = SPINNERV2;
			break;
		case 1:
		default:
			SPINNER = SPINNERV1;
	}
	return (
		<AntSpin
			indicator={
				<SVG
					src={SPINNER}
					className={cn(
						styles.genemodSpinner,
						props.spinnerClassName,
						{
							[styles.genemodSpinner__small]:
								props.size === "small",
							[styles.genemodSpinner__large]:
								props.size === "large",
						}
					)}
					decorative
				/>
			}
			{...props}
		>
			{props.children}
		</AntSpin>
	);
}

export function SPIN_DEMO() {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Small Spin"
					description={`Small size Spin *props: size="small"`}
				>
					<Spin size="small" />
				</Demo>
				<Demo
					title="Default Spin"
					description={`Small size Spin *props: size="default"`}
				>
					<Spin size="default" />
				</Demo>
				<Demo
					title="Large Spin"
					description={`Large Spin *props: size="Large"`}
				>
					<Spin size="large" />
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
