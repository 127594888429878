import React, { useEffect, useMemo, useRef, useState } from "react";
import { Typography, Spin } from "@components";
import styles from "./AttachmentPreview.module.scss";
import { nanoid } from "nanoid";
import Button from "../Button";
import {
	ExperimentAttachment,
	ProjectAttachment,
	SubprojectAttachment,
	ItemAttachment,
	FreezerOrderAttachment,
	ExperimentDocumentAttachment,
	ExperimentReportAttachment,
} from "@common/types";
import {
	ATTACHMENT_ACCEPTED_FILES,
	IMAGE_ACCEPTED_FILES,
	VIDEO_ACCEPTED_FILES,
} from "@common/types/FileAttachment";
import { getFileTypeIcon } from "../FileIcon";

type UploadPreviewProps = {
	attachment:
		| ProjectAttachment
		| SubprojectAttachment
		| ExperimentAttachment
		| ExperimentDocumentAttachment
		| ItemAttachment
		| FreezerOrderAttachment
		| ExperimentReportAttachment
		| null
		| undefined;
	previewWrapperStyles?: React.CSSProperties;
};

const DefaultIcon = getFileTypeIcon("default");

/**
 * Preview for the files we upload to s3
 */
type ATTACHMENT_TYPE = "video" | "image" | "file" | "unknown" | "loading";
export function UploadPreview({
	attachment,
	previewWrapperStyles,
}: UploadPreviewProps): JSX.Element {
	const [viewerKey, setViewerKey] = useState(nanoid());
	const [previewLoading, _setPreviewLoading] = useState(false);
	const [startBackoff, stopBackoff] = useExponentialBackoff(() => {
		setViewerKey(nanoid());
	});
	useEffect(() => {
		return () => stopBackoff();
	}, []);
	const setPreviewLoading = (loading: boolean) => {
		_setPreviewLoading(loading);
		if (loading) {
			startBackoff();
		} else {
			stopBackoff();
		}
	};
	const [attachmentSrc, attachmentType]: [string, ATTACHMENT_TYPE] =
		useMemo(() => {
			if (!attachment) return ["", "loading"];
			const fileType = attachment.file_type as any;
			if (VIDEO_ACCEPTED_FILES.includes(fileType)) {
				return [attachment.upload, "video"];
			} else if (IMAGE_ACCEPTED_FILES.includes(fileType)) {
				return [attachment.upload, "image"];
			} else if (ATTACHMENT_ACCEPTED_FILES.includes(fileType)) {
				setPreviewLoading(true);
				return [
					`https://docs.google.com/gview?url=${encodeURIComponent(
						attachment.upload
					)}&embedded=true`,
					"file",
				];
			}
			return ["", "unknown"];
		}, [attachment]);

	const renderAttachment = () => {
		if (attachmentType === "file") {
			return (
				<iframe
					key={viewerKey}
					src={attachmentSrc}
					onLoad={() => setPreviewLoading(false)}
					className={styles.attachmentFile}
				/>
			);
		} else if (attachmentType === "video") {
			return (
				<div className={styles.attachmentFile}>
					<video controls width="100%" height="100%">
						<source src={attachmentSrc} type="video/webm" />
						<source src={attachmentSrc} type="video/mp4" />
					</video>
				</div>
			);
		} else if (attachmentType === "image") {
			return (
				<div className={styles.attachmentFile}>
					<img
						src={attachmentSrc}
						style={{ maxWidth: "100%", maxHeight: "100%" }}
					/>
				</div>
			);
		} else if (attachmentType === "unknown") {
			return (
				<div className={styles.attachmentFile}>
					<DefaultIcon />
					<Typography
						variant="subheadline"
						bold
						color="text-on-color"
						style={{ marginTop: 16, marginBottom: 24 }}
					>
						Preview is not supported
					</Typography>
					<Button
						onClick={() => window.open(attachment?.upload)}
						size="small"
						type="primary"
					>
						Download
					</Button>
				</div>
			);
		}
		return <></>;
	};

	return (
		<div
			style={{
				position: "relative",
				...previewWrapperStyles,
				// width: "100%",
				// height: "100%",
			}}
		>
			{(previewLoading || attachmentType === "loading") && (
				<Spin className={styles.loading} size="large" />
			)}
			{renderAttachment()}
		</div>
	);
}

/**
 * Provides an exponential backoff time in ms.
 * Note that this implementation only allows one timer to run at once.
 */
const BACKOFF_TIMES = [2, 4, 8, 16, 30, 60, 60 * 5, 60 * 10].map(
	(t) => t * 1000
);
const useExponentialBackoff = (
	callback: () => void,
	backoffTimes = BACKOFF_TIMES
) => {
	const timeoutId = useRef<NodeJS.Timeout | null>(null);

	const stop = () => {
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
			timeoutId.current = null;
		}
	};

	const run = (backoffIndex: number) => {
		const backoff = backoffTimes[backoffIndex] || "DONE";
		if (backoff === "DONE") {
			stop();
			return;
		}
		timeoutId.current = setTimeout(() => {
			callback();
			run(backoffIndex + 1);
		}, backoff);
	};

	const start = () => {
		// prevent more than one running at a time
		if (timeoutId.current) return;
		run(0);
	};

	return [start, stop] as const;
};
