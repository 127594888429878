import React from "react";
import { Modal, Typography, Input, GenemodIcon } from "@components";
import InputV3 from "@common/components/InputV3/InputV3";

type DeleteConfirmModalProps = {
	visible: boolean;
	loading?: boolean;
	title: string;
	itemName?: string;
	onConfirm: () => void;
	onCancel: () => void;
	type?:
		| "freezer"
		| "shelf"
		| "category"
		| "rack"
		| "box"
		| "itemgroup"
		| "item"
		| "template"
		| "space"
		| "furnitureCategory"
		| "consumable"
		| "equipment"
		| "folder"
		| "file"
		| "version";
	buttonName?: string;
	itemCount?: number;
	message?: string | React.ReactNode;
	showWarning?: boolean;
};

// Modal for prompting user to delete a freezer
export default function DeleteConfirmModal({
	visible,
	loading = false,
	title,
	onConfirm,
	onCancel,
	type,
	buttonName,
	itemCount,
	message,
	showWarning = true,
}: DeleteConfirmModalProps): JSX.Element {
	const [text, setText] = React.useState("");

	const pluralTypes = {
		freezer: "freezers",
		shelf: "shelves",
		category: "categories",
		rack: "racks",
		box: "boxes",
		itemgroup: "item groups",
		item: "items",
		template: "templates",
		space: "spaces",
		furnitureCategory: "furniture categories",
		consumable: "consumables",
		equipment: "equipment",
		folder: "folders",
		file: "files",
		version: "versions",
	};

	const defaultWarningMessage = (
		<p>
			All contents will be deleted, and you won’t be able to retrieve the{" "}
			{itemCount === 1
				? type
				: pluralTypes[type as keyof typeof pluralTypes]}
			. <b>This action cannot be undone.</b>
		</p>
	);

	const warningMessage = {
		template: `All custom form fields and associated data will be deleted. ${itemCount} ${
			itemCount === 1 ? "item" : "items"
		} will be reset to a default template.`,
		space: "All storage units, categories, items, and activity data will be deleted, and you won't be able retrieve the space.",
		furnitureCategory:
			"All items and activity data will be deleted, and you won't be able to retrieve the category.",
		consumable: "",
		equipment:
			"All related data and events will be deleted. This action cannot be reverted.",
		default: defaultWarningMessage,
	};

	React.useEffect(() => {
		setText("");
	}, [visible]);

	const checkIfDeletable = () => text === "DELETE";

	const handleDelete = () => {
		if (checkIfDeletable()) {
			onConfirm();
		}
	};

	return (
		<Modal
			visible={visible}
			okText={`Delete  ${buttonName || type}`}
			title={
				<Typography
					variant="headline4"
					color="text-secondary-v2"
					style={{ marginBottom: 8 }}
				>
					{title}
				</Typography>
			}
			width={400}
			hideCancelButton
			okButtonProps={{
				onClick: handleDelete,
				disabled: !checkIfDeletable(),
				loading: loading,
				danger: true,
			}}
			dataCy="delete-modal"
			onCancel={onCancel}
		>
			{message && (
				<Typography
					color="text-secondary-v2"
					style={{ marginBottom: 8 }}
				>
					{message}
				</Typography>
			)}
			{showWarning && type && (
				<Typography
					color="text-secondary-v2"
					style={{ marginBottom: 24 }}
				>
					{warningMessage[type as keyof typeof warningMessage] ||
						warningMessage.default}
				</Typography>
			)}
			<Typography style={{ marginBottom: 8 }} color="text-secondary-v2">
				To delete{" "}
				{itemCount === 1
					? `this ${type}`
					: `these ${pluralTypes[type as keyof typeof pluralTypes]}`}
				, type DELETE.
			</Typography>
			<InputV3
				placeholder="Type DELETE to continue"
				value={text}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setText(e.target.value)
				}
				autoFocus
				onPressEnter={handleDelete}
				dataCy="delete-modal-input"
			/>
		</Modal>
	);
}
