import { NominalType } from "@helpers/TypeHelpers";

export type RestrictDragDirection = "horizontal" | "vertical" | "none";

export type Row = NominalType<"InteractiveGridRow", number>;
export type Column = NominalType<"InteractiveGridColumn", number>;
export type GridCoord = { row: Row; column: Column };

export type MouseLocation = GridCoord | "NOT_DRAGGING";
// Compares two furniture locations. If either are null/undefined it will return false.

export const compareLocation = (
	a: GridCoord | null | undefined,
	b: GridCoord | null | undefined
) => {
	if (!a || !b) {
		return false;
	}
	return a.row === b.row && a.column === b.column;
};

export const addGridCoords = (a: GridCoord, b: GridCoord): GridCoord => {
	return { row: a.row + b.row, column: a.column + b.column } as GridCoord;
};
