import React, { createContext, useEffect, useState } from "react";
import { Prompt, useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { Modal } from "@common/components";
import { Location } from "history";
import { actionOnTypeLiteralField } from "@helpers/TypeHelpers";
import { useRefWithRerender } from "@helpers/Hooks";

type LocationChangeTrigger = {
	type: "LOCATION_CHANGE";
	nextLocation: Location;
};

type ManualTrigger = {
	type: "MANUAL";
	callback: (option: "OK" | "CANCEL") => void;
};

type PromptTrigger = LocationChangeTrigger | ManualTrigger;

type SafeLeavingGuardContextProps = {
	/** if true, show confirmModal when user try to leave other location */
	shouldBlock: boolean;
	setShouldBlock: (shouldBlock: boolean) => void;
	/** add a key to track edit form  */
	addShouldBlockKey?: (key: string) => void;
	/** remove the key to stop track the form */
	removeShouldBlockKey?: (key: string) => void;
	/** Manually open the should block warning modal */
	showShouldBlockModal: (callback: ManualTrigger["callback"]) => void;
	clearAllShouldBlockKeys: () => void;
};

export const SafeLeavingGuardContext =
	createContext<SafeLeavingGuardContextProps>({
		shouldBlock: false,
		setShouldBlock: (v: boolean) => {},
		addShouldBlockKey: undefined,
		removeShouldBlockKey: undefined,
		showShouldBlockModal: () => {},
		clearAllShouldBlockKeys: () => {},
	});

export default function SafeLeavingGuardProvider(props: {
	children: React.ReactNode;
}): JSX.Element {
	const [shouldBlockKeys, setShouldBlockKeys] = useRefWithRerender(
		new Set<string>()
	);
	const history = useHistory();
	const [promptTrigger, setPromptTrigger] = useState<PromptTrigger | null>(
		null
	);

	useEffect(() => {
		// Remove the unbeforeunload message. Otherwise it will show up after we move
		return () => {
			window.onbeforeunload = () => null;
		};
	}, []);

	const addShouldBlockKey = (key: string) => {
		if (!shouldBlockKeys.current.has(key)) {
			// only if key is not in the Set list, add it to list
			setShouldBlockKeys(new Set([key, ...shouldBlockKeys.current]));
			return;
		}
		window.onbeforeunload = (event: any) => {
			const e = event || window.event;
			e.preventDefault();
			if (e) {
				e.returnValue = "";
			}
			return "";
		};
	};

	const removeShouldBlockKey = (key: string) => {
		if (shouldBlockKeys.current.has(key)) {
			const newShouldBlockKeys = new Set(shouldBlockKeys.current);
			newShouldBlockKeys.delete(key);
			setShouldBlockKeys(newShouldBlockKeys);
			return;
		}
		if (!shouldBlockKeys.current.size) {
			setPromptTrigger(null);
			window.onbeforeunload = () => null;
		}
	};

	return (
		<SafeLeavingGuardContext.Provider
			value={{
				shouldBlock: !!shouldBlockKeys.current.size,
				setShouldBlock: (block: boolean) =>
					block
						? addShouldBlockKey("block")
						: removeShouldBlockKey("block"),
				addShouldBlockKey: addShouldBlockKey,
				removeShouldBlockKey: removeShouldBlockKey,
				showShouldBlockModal: (callback) =>
					setPromptTrigger({
						type: "MANUAL",
						callback,
					}),
				clearAllShouldBlockKeys: () => setShouldBlockKeys(new Set()),
			}}
		>
			<Prompt
				when={!!shouldBlockKeys.current.size}
				message={(location) => {
					const { nextLocation } = (promptTrigger ||
						{}) as LocationChangeTrigger;
					if (!nextLocation && !!shouldBlockKeys.current.size) {
						setPromptTrigger({
							type: "LOCATION_CHANGE",
							nextLocation: location,
						});
						return false;
					}
					return true;
				}}
			/>
			{props.children}
			<ConfirmLeavingModal
				visible={!!promptTrigger && !!shouldBlockKeys.current.size}
				onCancel={() => {
					if (promptTrigger?.type === "MANUAL") {
						promptTrigger.callback("CANCEL");
					}
					setPromptTrigger(null);
				}}
				onOk={() => {
					promptTrigger &&
						actionOnTypeLiteralField(promptTrigger)("type")({
							LOCATION_CHANGE: (trigger) => {
								history.push(trigger.nextLocation as Location);
								setShouldBlockKeys(new Set());
							},
							MANUAL: (trigger) => trigger.callback("OK"),
						});

					setPromptTrigger(null);
				}}
			/>
		</SafeLeavingGuardContext.Provider>
	);
}

export function ConfirmLeavingModal(props: {
	visible: boolean;
	onOk: () => void;
	onCancel: () => void;
}): JSX.Element {
	return (
		<Modal
			visible={props.visible}
			title="You have unsaved changes"
			okText="Continue"
			cancelText="Cancel"
			onCancel={props.onCancel}
			onOk={props.onOk}
			switchButtonsPos
		>
			If you continue, you may lose the changes.
		</Modal>
	);
}
