import React from "react";
import { CommonDeleteModal, Notification } from "@components";
import { useCommonModalState } from "@redux/CommonModals/hooks";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { truncArgs } from "@helpers/Formatters";
import { useHistory } from "@helpers/Hooks/UseRouterDom";
import { isItemGroup as _isItemGroup } from "@common/types";
import { useBoxDeleteMutation, useBoxQuery } from "@redux/inventory/Box";

/**
 * A delete modal used for deleting Box/Item group level objects
 */
export default function DeleteBoxModal(): JSX.Element {
	const history = useHistory();
	const {
		isDeleteBoxModalVisible,
		closeDeleteBoxModal,
		deleteBoxModalData: { id, navigateToParentRack = false },
	} = useCommonModalState("deleteBoxModal");
	const [deleteBox] = useBoxDeleteMutation();

	const { data: box } = useBoxQuery(id || skipToken);
	const itemCount = box?.item_count || 0;
	const isItemGroup = box ? _isItemGroup(box) : false;
	const type = isItemGroup ? "item group" : "box";

	const deleteBoxSelected = () => {
		if (!box) return;
		deleteBox(box.id)
			.unwrap()
			.then(() => {
				closeDeleteBoxModal();
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${box?.name}`(68)}</b>
							{" has been deleted."}
						</span>
					),
				});
				if (navigateToParentRack) {
					history.push({
						pathname: history.location.pathname.replace(
							/boxes\/(.*)/g,
							""
						),
						search: new URLSearchParams({
							selected_rack:
								box.location.rack_location?.rack + "",
						}).toString(),
					});
				}
			})
			.catch((_err) =>
				Notification.warning({ message: `Failed to delete a ${type}.` })
			);
	};

	return (
		<CommonDeleteModal
			visible={isDeleteBoxModalVisible}
			titleObject={type}
			bodyObject={box?.name || ""}
			onOk={deleteBoxSelected}
			onCancel={closeDeleteBoxModal}
			dataCy="delete-box"
		/>
	);
}
