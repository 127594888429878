import * as Y from "yjs";
import invariant from "tiny-invariant";

export interface TCursor {
	anchor: any;
	focus: any;
	data: any;
}

/**
 * Returns the position of the sync item inside inside it's parent array.
 */
export function getArrayPosition(item: any) {
	let i = 0;
	let c = item.parent._start;
	while (c !== item && c !== null) {
		if (!c.deleted) {
			i += 1;
		}
		c = c.right;
	}
	return i;
}
/**
 * Returns the document path of a sync item
 */
export function getSyncNodePath(node: any): any {
	if (!node) {
		return [];
	}
	const { parent } = node;
	if (!parent) {
		return [];
	}
	if (parent instanceof Y.Array) {
		invariant(node._item, "Parent should be associated with a item");
		return [...getSyncNodePath(parent), getArrayPosition(node._item)];
	}
	if (parent instanceof Y.Map) {
		return getSyncNodePath(parent);
	}
	throw new Error(`Unknown parent type ${parent}`);
}

export function relativePositionToAbsolutePosition(
	sharedType: any,
	relativePosition: any
) {
	invariant(sharedType.doc, "Shared type should be bound to a document");
	const pos = Y.createAbsolutePositionFromRelativePosition(
		relativePosition,
		sharedType.doc
	);
	if (!pos) {
		return null;
	}
	return {
		path: getSyncNodePath(pos.type.parent),
		offset: pos.index,
	};
}
