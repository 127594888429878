import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

/**
 * Wrapper component that makes the contents magically appear.
 */
export default function AnimatedPanel(
	props: React.HTMLAttributes<HTMLDivElement> & { visible: boolean }
) {
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		if (!mounted && props.visible) {
			setMounted(true);
		}
	}, [props.visible, mounted]);

	return (
		<div
			{...props}
			className={cn(styles.animatedPanel, props.className, {
				[styles.visible]: props.visible,
				[styles.hidden]: !props.visible && mounted,
				[styles.unmounted]: !mounted,
			})}
		/>
	);
}
