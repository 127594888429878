import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
	Button,
	Typography,
	UserAvatar,
	ConfirmMessage,
	SlateTextArea,
	GenemodIcon,
	DropDown,
	ButtonV2,
} from "@common/components";
import { MenuInfo } from "rc-menu/lib/interface";
import SlateTextDisplay from "@common/components/SlateTextArea/SlateTextDisplay";
import {
	Avatar,
	FreezerOrderComment,
	FreezerOrderId,
	getFullNameFromAvatar,
} from "@common/types";
import {
	useOrderCommentsCreateMutation,
	useOrderCommentsDeleteMutation,
	useOrderCommentsPatchMutation,
	useOrderCommentsQuery,
	useOrderQuery,
} from "@redux/freezer/FreezerApiSlice";
import { useCurrentUserQuery } from "@redux/user/UserApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import moment from "moment";
import styles from "./CommentsTab.module.scss";
import useCurrentTeamMembers from "@helpers/Hooks/useCurrentTeamMembersHooks";
import { nanoid } from "nanoid";
import { ItemType } from "antdv4/lib/menu/hooks/useItems";
import type { MenuProps } from "antdv5";

type MenuItem = Required<MenuProps>["items"][number];

type CommentsTabProps = {
	orderId: FreezerOrderId;
};
export default function CommentsTab({ orderId }: CommentsTabProps) {
	const { data: orderComments } = useOrderCommentsQuery(
		orderId
			? {
					order: orderId,
			  }
			: skipToken
	);
	const { data: user } = useCurrentUserQuery();
	const [createComment] = useOrderCommentsCreateMutation();
	const handleCreateComment = (comment: string) => {
		if (!orderId) return;
		createComment({ order: orderId, body: comment });
	};

	return (
		<div className={styles.commentsTab}>
			<CommentTextArea
				user={user?.avatar}
				onSave={handleCreateComment}
				orderId={orderId}
			/>
			{orderComments?.results.map((orderComment) => (
				<CommentCard
					key={orderComment.id}
					orderComment={orderComment}
					orderId={orderId}
				/>
			))}
		</div>
	);
}

type CommentTextAreaProps = {
	orderId: FreezerOrderId;
	user?: Avatar;
	comment?: string;
	onSave?: (comment: string) => void;
	onCancel?: () => void;
	autoFocus?: boolean;
	/**
	 * Does not require focus to show save and cancel or tall height
	 */
	alwaysExpanded?: boolean;
};
function CommentTextArea({
	orderId,
	user,
	comment,
	autoFocus,
	onSave,
	onCancel,
	alwaysExpanded,
}: CommentTextAreaProps) {
	const { data: order } = useOrderQuery(orderId);
	const [text, setText] = useState(comment || "");
	const [_isFocused, setIsFocused] = useState(false);
	const isExpanded = text || comment || alwaysExpanded || _isFocused;

	// Logic to reset SlateTextArea when order changes or comment is saved/cancelled. Prevents slate from crashing.
	const getNewKey = () => `${order?.id}-${nanoid()}`;
	const [key, setKey] = useState(getNewKey());
	const resetText = () => {
		setText("");
		setKey(getNewKey());
	};

	useEffect(() => {
		if (order?.id) setKey(getNewKey());
	}, [order?.id]);

	const submit = () => {
		resetText();
		onSave?.(text);
	};

	const submitRef = React.useRef(submit);
	submitRef.current = submit;

	return (
		<div>
			<div className={styles.commentTextArea}>
				<UserAvatar user={user} />
				<div className={styles.textAreaAndButtons}>
					<SlateTextArea
						key={key}
						placeholder="Add comment"
						placeholderVariant="body"
						value={text}
						onChange={setText}
						gutterBottom={false}
						wrapperProps={{
							className: cn(styles.textArea),
						}}
						editableProps={{
							className: cn(styles.slateTextAreaEditable, {
								[styles.isNotExpanded]: !isExpanded,
							}),
						}}
						noBorder
						autoFocus={autoFocus}
						disabled={!user}
						notificationOptions={{
							type: "ORDER_COMMENT",
							onSelect(send, teamMember) {
								if (!order || !teamMember) return;
								send({
									object_id: order.id,
									object_name: order.name,
									receiver: teamMember.org_user.id,
								});
							},
						}}
						pluginOptions={{
							useAnchorMe: true,
							useMentions: true,
							mention_type: "person",
							enterButtonBehavior: {
								type: "submit_and_clear",
								onSubmit: submitRef,
							},
						}}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
					/>
					{isExpanded ? (
						<ButtonV2
							size="small"
							disabled={!text}
							type="primary"
							className="mt-2"
							onClick={
								!text
									? () => {}
									: () => {
											resetText();
											onSave?.(text);
									  }
							}
						>
							Add Comment
						</ButtonV2>
					) : null}
				</div>
			</div>
		</div>
	);
}

type CommentCardProps = {
	orderComment: FreezerOrderComment;
	orderId: FreezerOrderId;
};

function CommentCard({ orderComment, orderId }: CommentCardProps) {
	const { currentUserAsTeamMember } = useCurrentTeamMembers();

	const { created_by: user, created_at, body, order, id } = orderComment;
	const [showConfirmMessage, setShowConfirmMessage] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const createdByCurrentUser = user.id === currentUserAsTeamMember?.user.id;

	const [updateComment] = useOrderCommentsPatchMutation();
	const [deleteComment] = useOrderCommentsDeleteMutation();

	const handleMenu = (info: MenuItem) => {
		if (!info) return;
		switch (info.key) {
			case "edit":
				setIsEdit(true);
				break;
			case "delete":
				setShowConfirmMessage(true);
				break;
		}
	};

	const getMenuItems = (): ItemType[] => {
		return [
			{
				key: "edit",
				label: (
					<div>
						<Typography color="text-secondary-v2">Edit</Typography>
					</div>
				),
			},
			{
				type: "divider",
			},
			{
				key: "delete",
				label: (
					<div>
						<Typography color="dust-red">Delete</Typography>
					</div>
				),
			},
		];
	};

	if (isEdit) {
		return (
			<CommentTextArea
				user={user}
				comment={body}
				orderId={orderId}
				onSave={(body) => {
					updateComment({ id, order, body })
						.unwrap()
						.finally(() => setIsEdit(false));
				}}
				onCancel={() => setIsEdit(false)}
				alwaysExpanded
			/>
		);
	}

	return (
		<div className={styles.commentCardWrapper}>
			<div className={styles.commentCard}>
				<UserAvatar user={user} />
				<div className={styles.rightSide}>
					<div className={styles.userInfo}>
						<Typography
							variant="label"
							color="text-secondary-v2"
							ellipsis
						>
							{getFullNameFromAvatar(user)}
						</Typography>
						<Typography variant="label" color="text-tertiary-v2">
							{moment(created_at).format("LL")} at{" "}
							{moment(created_at).format("h:mm A")}
						</Typography>
						<DropDown
							menu={{
								items: getMenuItems(),
								onClick: (item) => handleMenu(item),
							}}
						>
							<div style={{ justifySelf: "end" }}>
								<GenemodIcon name="meatballs" />
							</div>
						</DropDown>
					</div>
					<SlateTextDisplay
						value={body}
						editableProps={{ className: styles.readOnly }}
					/>
				</div>
			</div>
			{showConfirmMessage && (
				<ConfirmMessage
					className={styles.confirmMessage}
					status="warning"
					iconName="error"
					onOk={() => {
						deleteComment({
							orderId: order,
							commentId: id,
						});
						setShowConfirmMessage(false);
					}}
					onCancel={() => {
						setShowConfirmMessage(false);
					}}
				>
					Are you sure you want to delete this comment?
				</ConfirmMessage>
			)}
		</div>
	);
}
