import { Moment } from "moment";
import React, { useState } from "react";
import moment from "moment";
import useCurrentTeamMembers from "@helpers/Hooks/useCurrentTeamMembersHooks";
import { FilterOption } from "@common/components/FilterButton";
import {
	Button,
	Checkbox,
	CustomDatePicker,
	DropDown,
	SearchBar,
	Typography,
	UserAvatar,
	GenemodFilter,
} from "@common/components";
import styles from "../FreezerTab.module.scss";
import classNames from "classnames";
import { Menu } from "antdv5";
import { useParams } from "@helpers/URLParams";

export type TimestampColumnTitleProps = {
	type: "Created" | "Updated"; // Define the type of timestamp
	timestampLte: string | null;
	setTimestampGte: (value: string | null) => void;
	timestampGte: string | null;
	setTimestampLte: (value: string | null) => void;
	timestampCheckboxes: string[];
};

export const TimestampColumnTitle = ({
	type,
	timestampCheckboxes,
	timestampGte,
	setTimestampGte,
	timestampLte,
	setTimestampLte,
}: TimestampColumnTitleProps) => {
	const { updateMultipleParamOnURL, resetParamsOnURL: resetParamOnURL } =
		useParams();
	const [search, setSearch] = useState("");
	const { activeTeamMembers } = useCurrentTeamMembers();
	const [validGteDate, setValidGteDate] = useState<Moment | null>(
		timestampGte ? moment(timestampGte) : null
	);
	const [validLteDate, setValidLteDate] = useState<Moment | null>(
		timestampLte ? moment(timestampLte) : null
	);
	const filteredMembers = activeTeamMembers.filter((member) =>
		`${member.user.first_name} ${member.user.last_name}`
			.toLowerCase()
			.includes(search)
	);
	const timestampFilters: FilterOption[] = (filteredMembers || []).map(
		(user) => ({
			value: "" + user.id,
			display: <UserAvatar user={user.user} displayName />,
		})
	);
	const typeLowercase = type.toLowerCase();
	const typeParam = `${typeLowercase}_by`;

	const title = type === "Updated" ? "Last modified" : type;

	const cleanDateOnBackspace = (
		e: React.KeyboardEvent<HTMLInputElement>,
		startOrEnd: "start" | "end"
	) => {
		const selectionStart = e.currentTarget.selectionStart || 0;
		const selectionEnd = e.currentTarget.selectionEnd || 0;
		if (
			(e.key === "Backspace" && e.currentTarget.value.length === 1) ||
			selectionEnd - selectionStart === e.currentTarget.value.length
		) {
			if (startOrEnd === "start") {
				setValidGteDate(null);
				resetParamOnURL([`${typeLowercase}AtGte`]);
			} else {
				setValidLteDate(null);
				resetParamOnURL([`${typeLowercase}AtLte`]);
			}
		}
	};

	const filterApplied =
		timestampGte || timestampLte || timestampCheckboxes.length !== 0
			? true
			: false;

	return (
		<div className={classNames(styles.columnHeader, "active-ordering")}>
			<Typography bold className="active-ordering">
				{title} on
			</Typography>
			<DropDown
				overlayClassName={styles.dropdown}
				overlay={
					<Menu className={classNames(styles.menu, styles.timestamp)}>
						<div className={styles.items}>
							<div className={styles.dateRange}>
								<CustomDatePicker
									value={validGteDate}
									placeholder="MM/DD/YYYY"
									labelText="From"
									className={styles.datepicker}
									labelStyle={{ fontWeight: "bold" }}
									onChange={(val) => {
										setValidGteDate(val);
										setTimestampGte(
											val?.format("MM/DD/YYYY") || null
										);
									}}
									onKeyDown={(e) =>
										cleanDateOnBackspace(e, "start")
									}
								/>
								<div className={styles.line}></div>
								<CustomDatePicker
									value={validLteDate}
									placeholder="MM/DD/YYYY"
									labelText="To"
									className={styles.datepicker}
									labelStyle={{ fontWeight: "bold" }}
									onChange={(val) => {
										setValidLteDate(val);
										setTimestampLte(
											val?.format("MM/DD/YYYY") || null
										);
									}}
									onKeyDown={(e) =>
										cleanDateOnBackspace(e, "end")
									}
								/>
							</div>
							<SearchBar
								iconClassName={styles.searchIcon}
								iconPosition="left"
								placeholder="Find people"
								wrapperProps={{ className: styles.searchBar }}
								value={search}
								onChange={(val) => setSearch(val)}
								disableSuggestions
							/>
							<div className="overflow-x-hidden overflow-y-auto max-h-52">
								{timestampFilters.map((user) => {
									return (
										<div
											key={user.value}
											className={styles.item}
										>
											<Checkbox
												className={
													styles.filterCheckbox
												}
												value={timestampCheckboxes.includes(
													user.value
												)}
												onChange={(val) => {
													updateMultipleParamOnURL(
														typeParam,
														Number(user.value),
														val as boolean
													);
												}}
											>
												<Typography
													variant="label"
													ellipsis
												>
													{user.display}
												</Typography>
											</Checkbox>
										</div>
									);
								})}
							</div>
						</div>
						<div className={styles.reset}>
							<Button
								type="link"
								onClick={() => {
									setValidLteDate(null);
									setValidGteDate(null);
									const paramsReseted = [
										typeParam,
										`${typeLowercase}AtGte`,
										`${typeLowercase}AtLte`,
									];
									if (typeLowercase === "created") {
										paramsReseted.push("addedAtGte");
										paramsReseted.push("addedAtLte");
									}
									resetParamOnURL(paramsReseted);
								}}
							>
								Reset
							</Button>
						</div>
					</Menu>
				}
				getPopupContainer={() => document.body}
			>
				<div style={{ marginLeft: "auto" }}>
					<GenemodFilter
						applied={filterApplied}
						wrapperClassName={
							!filterApplied ? "isOnRepository" : ""
						}
					/>
				</div>
			</DropDown>
		</div>
	);
};
