import React from "react";
import "./BreadCrumbButton.scss";
import {
	Demo,
	DemoSection,
	DemoWrapper,
	Typography,
	GenemodIcon,
} from "@components";
import { message } from "antd";

/**
 * Initializes sidebar settings with all tools
 * @param {String} text text inside the breadcrumb
 * @param {Function} onChange function called when breadcrumb is changed
 * @param {String} key prevent error message
 */
export default function BreadCrumbButton(props) {
	return (
		<div className="breadcrumb-button">
			<Typography
				className="breadcrumb-label"
				color="text-secondary"
				variant="label"
			>
				{props.text}
			</Typography>
			<GenemodIcon
				name="cancel"
				onClick={props.onChange}
				style={{ marginLeft: 4 }}
				fill="text-secondary"
			/>
		</div>
	);
}

export function BREADCRUMBBTN_DEMO() {
	const memberObject = {
		name: "Laura Kim",
		value: "12",
	};

	// const dateFilterBreadClumbLabel = "Before Oct 16, 2020"; // commented due to eslint

	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="BreadCrumbButton"
					description="Rendered when filters are applied in search"
				>
					<h4>Render Avatar</h4>
					<BreadCrumbButton
						key={memberObject.value}
						text={memberObject.name}
						onChange={() => message.info("removed member filter")}
					/>
					<br />
					<h4>Render Date</h4>
					<BreadCrumbButton
						key={"after"}
						text={"Before"}
						onChange={() => message.info("removed date filter")}
					/>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
