import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0,0,2048,2048"
		>
			<path
				className="OfficeIconColors_HighContrast"
				d="M 356 717 h -112 l 137 614 h 130 l 87 -406 q 1 -5 3 -17 q 2 -13 5 -26 q 2 -14 4 -26 q 2 -13 3 -19 q 0 6 2 18 q 2 11 5 24 q 2 13 5 26 q 2 12 4 19 l 92 407 h 129 l 135 -614 h -110 l -78 425 q -4 22 -6 41 q -3 19 -5 36 q -3 -24 -5 -44 q -3 -20 -7 -36 l -97 -422 h -121 l -94 429 q -2 7 -3 17 q -2 9 -3 20 q -2 10 -4 20 q -2 10 -3 17 q 0 -4 0 -9 q -1 -5 -2 -13 q -1 -9 -2 -21 q -2 -12 -5 -31 m 1198 -224 h -409 v -103 h 409 m 0 307 h -409 v -102 h 409 m 0 307 h -409 v -102 h 409 m 177 -731 q 28 32 28 71 v 1275 q 0 21 -8 40 q -8 18 -22 32 q -14 14 -32 22 q -19 8 -40 8 h -1229 q -21 0 -40 -8 q -19 -8 -32 -22 q -14 -14 -22 -32 q -8 -19 -8 -40 v -308 h -205 q -21 0 -40 -8 q -19 -8 -33 -22 q -14 -14 -22 -32 q -8 -19 -8 -40 v -820 q 0 -21 8 -39 q 8 -19 22 -33 q 14 -14 33 -22 q 19 -8 40 -8 h 205 v -308 q 0 -21 8 -39 q 8 -19 22 -33 q 13 -14 32 -22 q 19 -8 40 -8 h 847 q 47 0 77 35 m -2 375 h 249 l -249 -249 m 307 351 h -307 q -21 0 -40 -8 q -19 -8 -33 -21 q -14 -14 -22 -33 q -8 -19 -8 -40 v -308 h -819 v 308 h 512 q 21 0 40 8 q 18 8 32 22 q 14 14 22 33 q 8 18 8 39 v 820 q 0 21 -8 40 q -8 18 -22 32 q -14 14 -32 22 q -19 8 -40 8 h -512 v 307 h 1229 z"
			/>
			<path
				fill="#FAFAFA"
				fillOpacity="1.000"
				d="M 461 1853 v -1648 q 0 -21 15 -36 q 15 -15 36 -15 h 847 q 11 0 21 5 q 10 4 18 12 l 381 367 q 13 15 13 34 v 1281 q 0 22 -15 37 q -15 15 -36 15 h -1229 q -21 0 -36 -15 q -15 -15 -15 -37 z"
			/>
			<path
				fill="#797774"
				fillOpacity="1.000"
				d="M 1815 498 q 28 32 28 71 v 1275 q 0 21 -8 40 q -8 18 -22 32 q -14 14 -32 22 q -19 8 -40 8 h -1229 q -21 0 -40 -8 q -19 -8 -32 -22 q -14 -14 -22 -32 q -8 -19 -8 -40 v -1640 q 0 -21 8 -39 q 8 -19 22 -33 q 13 -14 32 -22 q 19 -8 40 -8 h 847 q 47 0 77 35 m -2 375 h 249 l -249 -249 m 307 1580 v -1229 h -307 q -21 0 -40 -8 q -19 -8 -33 -21 q -14 -14 -22 -33 q -8 -19 -8 -40 v -308 h -819 v 1639 z"
			/>
			<path
				fill="#41A5EE"
				fillOpacity="1.000"
				d="M 1638 922 h -409 v -103 h 409 z"
			/>
			<path
				fill="#2B7CD3"
				fillOpacity="1.000"
				d="M 1638 1126 h -409 v -102 h 409 z"
			/>
			<path
				fill="#185ABD"
				fillOpacity="1.000"
				d="M 1638 1331 h -409 v -102 h 409 z"
			/>
			<path
				fill="#185ABD"
				fillOpacity="1.000"
				d="M 1024 1536 h -819 q -21 0 -40 -8 q -19 -8 -33 -22 q -14 -14 -22 -32 q -8 -19 -8 -40 v -820 q 0 -21 8 -39 q 8 -19 22 -33 q 14 -14 33 -22 q 19 -8 40 -8 h 819 q 21 0 40 8 q 18 8 32 22 q 14 14 22 33 q 8 18 8 39 v 820 q 0 21 -8 40 q -8 18 -22 32 q -14 14 -32 22 q -19 8 -40 8 z"
			/>
			<path
				fill="#FFFFFF"
				fillOpacity="1.000"
				d="M 440 1146 q 3 19 5 31 q 1 12 2 21 q 1 8 2 13 q 0 5 0 9 q 1 -7 3 -17 q 2 -10 4 -20 q 1 -11 3 -20 q 1 -10 3 -17 l 94 -429 h 121 l 97 422 q 4 16 7 36 q 2 20 5 44 q 2 -17 5 -36 q 2 -19 6 -41 l 78 -425 h 110 l -135 614 h -129 l -92 -407 q -2 -7 -4 -19 q -3 -13 -5 -26 q -3 -13 -5 -24 q -2 -12 -2 -18 q -1 6 -3 19 q -2 12 -4 26 q -3 13 -5 26 q -2 12 -3 17 l -87 406 h -130 l -137 -614 h 112 z"
			/>
		</svg>
	);
}

const WordIcon = React.memo(SvgComponent);
export default WordIcon;
