import {
	Button,
	Checkbox,
	DropDown,
	GenemodFilter,
	SearchBar,
	Typography,
} from "@common/components";
import { useState } from "react";
import styles from "../FreezerTab.module.scss";
import { FilterType, SearchFilterOption } from "@common/types";
import React from "react";
import { Menu } from "antdv5";
import { useParams } from "@helpers/URLParams";
import { useRepository } from "../../RepositoryContext";

export type LocationColumnTitleProps = {
	locations: SearchFilterOption[];
	placeholder: string;
};

function getLocationName(location: SearchFilterOption) {
	// if (location.type === FilterType.CATEGORY) {
	// 	return `Category: ${location.name}`;
	// }
	// if (location.type === FilterType.RACK) {
	// 	return `Rack: ${location.name}`;
	// }
	return location.name;
}

export const LocationColumnTitle = ({
	locations,
	placeholder,
}: LocationColumnTitleProps) => {
	const {
		filters: { locatedIn: locationCheckboxes },
	} = useRepository();
	const { updateMultipleParamOnURL, resetParamsOnURL } = useParams();
	const [locationSearch, setLocationSearch] = useState("");
	const filteredLocations =
		locations?.filter((loc) =>
			(loc.name as string)
				.toLowerCase()
				.includes(locationSearch.toLowerCase())
		) || [];

	const filterApplied = locationCheckboxes.length !== 0;

	return (
		<div className={styles.columnHeader} data-cy="location-column">
			<Typography bold>Location</Typography>
			<DropDown
				overlayClassName={styles.dropdown}
				overlay={
					<Menu className={styles.menu}>
						<div className={styles.items}>
							<SearchBar
								iconClassName={styles.searchIcon}
								iconPosition="left"
								placeholder={placeholder}
								wrapperProps={{
									className: styles.searchBar,
								}}
								value={locationSearch}
								onChange={(val) => setLocationSearch(val)}
								disableSuggestions
							/>
							{filteredLocations.map((location) => {
								const value = location.type
									? location.value + location.type / 10
									: location.value;
								return (
									<div
										key={
											location.type
												? `${location.type}-${location.value}`
												: location.value
										}
										className={styles.item}
									>
										<Checkbox
											className={styles.filterCheckbox}
											value={locationCheckboxes.includes(
												value
											)}
											onChange={(val) => {
												if (val as boolean) {
													updateMultipleParamOnURL(
														"located_in",
														value,
														true
													);
												} else {
													updateMultipleParamOnURL(
														"located_in",
														value,
														false
													);
												}
											}}
											dataCy={
												(location.name as string)
													.toLowerCase()
													.replace(" ", "-") +
												"-filter"
											}
										>
											<Typography
												variant="label"
												ellipsis
											>
												{getLocationName(location)}
											</Typography>
										</Checkbox>
									</div>
								);
							})}
						</div>
						<div className={styles.reset}>
							<Button
								type="link"
								onClick={() => resetParamsOnURL(["located_in"])}
								dataCy="reset-location-filter"
							>
								Reset
							</Button>
						</div>
					</Menu>
				}
				getPopupContainer={() => document.body}
			>
				<div style={{ marginLeft: "auto" }} data-cy="location-filter">
					<GenemodFilter
						applied={filterApplied}
						wrapperClassName={
							!filterApplied ? "isOnRepository" : ""
						}
					/>
				</div>
			</DropDown>
		</div>
	);
};
