import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	const cls3 = { fill: "#727272" };
	const textStyle = {
		fill: "#fff",
		fontSize: "8px",
		fontFamily: "OpenSans-Extrabold, Open Sans",
		fontWeight: 800,
		letterSpacing: "0.06em",
	};
	return (
		<svg
			id="type_txt"
			xmlns="http://www.w3.org/2000/svg"
			width={48}
			height={48}
			viewBox="0 0 48 48"
			{...props}
		>
			<g id="XMLID_17_" transform="translate(-51.8 2)">
				<g id="XMLID_107_" transform="translate(62.8 6)">
					<path
						id="Path_4292"
						data-name="Path 4292"
						d="M2 0h13.568l7.551 7.48V30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
						transform="translate(1.607)"
						fill="#eaeaea"
					/>
					<path
						id="XMLID_110_"
						style={cls3}
						d="m330.7 6 7.584 7.475H330.7z"
						transform="translate(-313.554 -6)"
					/>
					<path
						id="XMLID_109_"
						style={cls3}
						transform="translate(0 18.739)"
						d="M0 0h21.056v9.99H0z"
					/>
					<path
						id="XMLID_108_"
						d="m62.8 454.9 1.606 1.357V454.9z"
						transform="translate(-62.8 -426.17)"
						fill="#4e4e4e"
					/>
				</g>
				<text
					id="txt"
					transform="translate(66.832 32.5)"
					style={textStyle}
				>
					<tspan x="0" y="0">
						txt
					</tspan>
				</text>
			</g>
		</svg>
	);
}

const TxtIcon = React.memo(SvgComponent);
export default TxtIcon;
