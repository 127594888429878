import { NominalId, ValuesOf } from "@helpers/TypeHelpers";
import { ISOString } from "./Date";
import { User } from "./User";

export type OrganizationUserId = NominalId<"OrganizationUserId">;

export type SimpleShareableUser = {
	id: OrganizationUserId;
	first_name: string;
	last_name: string;
};

export type OrganizationUser = {
	id: OrganizationUserId;
	user: User;
	// TODO This should be a team member status enum
	status: StatusValues;
	is_owner: boolean;
	// TODO This should be an enum
	licensing: { license: number; days_left?: number | undefined };
	last_login?: ISOString;
	created: ISOString;
	modified: ISOString;
	is_admin: boolean;
	organization: number;
};

/**
 * Gets the user's role in the organization.
 */
export const getRole = ({ is_owner, is_admin }: OrganizationUser) => {
	if (is_owner) return ROLE_HIERARCHY.Owner;
	if (is_admin) return ROLE_HIERARCHY.Admin;
	return ROLE_HIERARCHY.Member;
};

export const getRoleName = (member: OrganizationUser): RoleKeys =>
	Object.keys(ROLE_HIERARCHY)[getRole(member) as RoleValues] as RoleKeys;

/**
 * Get if the user is an admin (incl. owners) in the organization.
 */
export const isAdmin = (user: OrganizationUser) => {
	return user.is_admin || user.is_owner;
};

export type OrganizationUserRole = NominalId<"OrganizationUserRole">;

export const ROLE_HIERARCHY = {
	Owner: 0 as OrganizationUserRole,
	Admin: 1 as OrganizationUserRole,
	Member: 2 as OrganizationUserRole,
} as const;

export const STATUS_HIERARCHY = {
	Active: 0,
	Deactivated: 1,
	Pending: 2,
	Expired: 3,
} as const;
export type StatusValues = ValuesOf<typeof STATUS_HIERARCHY>;

export type RoleValues = ValuesOf<typeof ROLE_HIERARCHY>;
type RoleKeys = keyof typeof ROLE_HIERARCHY;
