import React, { Component } from "react";
import { Input, Icon, Avatar } from "antd";
import UserAvatar from "../UserAvatar/UserAvatar";
import "./UserSelector.scss";

// Props:
// allUsers,
// selectedUsers,
// selectedTitle,
// maxUsersSelected
class UserSelector extends Component {
	constructor(props) {
		super(props);
		this.state = { search: "" };
	}

	// Only selects up to this.props.maxUsersSelected
	selectUser = (user) => {
		let selected = [...this.props.selectedUsers, user];
		selected = selected.slice(
			0,
			this.props.maxUsersSelected
				? this.props.maxUsersSelected
				: selected.length
		);
		this.onChange(selected);
	};

	// Returns an array of unselected users
	getUnselectedUsers = () => {
		return this.props.allUsers.filter((user) => {
			return !this.props.selectedUsers.some(
				(selectedUser) => selectedUser.id === user.id
			);
		});
	};

	// Unselects a user
	removeUser = (id) => {
		let newList = this.props.selectedUsers.filter((user) => user.id !== id);
		this.onChange(newList);
	};

	// Passes in list of selected users when changed
	onChange = (selected) => {
		if (this.props.onChange) {
			this.props.onChange(selected);
		}
	};

	handleSearch = (e) => {
		this.setState({
			search: e.target.value,
		});
	};

	filterResults = (list) => {
		let search = this.state.search;
		search = search.trim().toLowerCase();
		return list.filter((user) => {
			let combined = user.first_name + user.last_name;
			combined = combined.trim().toLowerCase();
			return combined.includes(search);
		});
	};

	render() {
		return (
			<div className={"user-selector " + (this.props.className || "")}>
				{this.props.selectedTitle && (
					<div className="name-column">
						{this.props.selectedTitle}{" "}
						{this.props.maxUsersSelected
							? `(Max: ${this.props.maxUsersSelected})`
							: ""}
					</div>
				)}
				<div
					className={
						"select-users" +
						(this.props.selectedTitle
							? " remaining-width"
							: " full-width")
					}
				>
					<div className="selected-container">
						<div className="selected">
							{this.props.selectedUsers.map((user, index) => {
								return (
									<SelectedUser
										key={index}
										user={user}
										onClick={this.removeUser}
									/>
								);
							})}
						</div>
					</div>
					<div className="unselected">
						<div
							className={
								this.props.maxUsersSelected &&
								this.props.maxUsersSelected ===
									this.props.selectedUsers.length
									? "shield"
									: "hidden"
							}
						>
							<span>
								Max users: {this.props.maxUsersSelected}
							</span>
						</div>
						{!this.props.disableSearch && (
							<Input
								className="user-search"
								placeholder="Look for a lab member"
								value={this.state.search}
								onChange={this.handleSearch}
							></Input>
						)}
						<div className="user-results">
							{this.filterResults(this.getUnselectedUsers()).map(
								(user, index) => {
									return (
										<AddUserRow
											key={index}
											user={user}
											onClick={() =>
												this.selectUser(user)
											}
										/>
									);
								}
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function SelectedUser(props) {
	return (
		<div
			className="selected-user"
			onClick={() => props.onClick(props.user.id)}
		>
			<UserAvatar user={props.user} size={32}></UserAvatar>
		</div>
	);
}

function AddUserRow(props) {
	let user = props.user;
	return (
		<div className="add-user-row" onClick={props.onClick}>
			<Icon type="plus-circle" />
			<Avatar className="avatar" icon="user" />
			<span className="fullname">{`${user.first_name} ${user.last_name}`}</span>
		</div>
	);
}

export default UserSelector;
