import { ProtocolId } from "./ProtocolId";
import { ProtocolStatus } from "./ProtocolStatus";
import { SortByOptions } from "./Search";

export type ProtocolVersionSearchFilters = {
	sort_by?: SortByOptions<
		| "protocol__name"
		| "created_at"
		| "published_by"
		| "updated_at"
		| "is_bookmarked"
	>;
	page_size?: number;
	protocol?: ProtocolId;
	status?: ProtocolStatus;
	created_by?: number;
	labels?: string[];
};
