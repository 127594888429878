import React from "react";
import cn from "classnames";
import styles from "./ProgressCircles.module.scss";
import { GenemodIcon, Tooltip } from "@common/components";

export type ProgressStep = {
	key: string;
	title: string;
	component: JSX.Element;
	number: number;
	label: string;
	dataCy: string;
};

type ProgressCircleProps = {
	steps: ProgressStep[];
	currentStep: ProgressStep;
	onStepClick: (step: ProgressStep) => void;
};

function ProgressCircles({
	steps,
	currentStep,
	onStepClick,
}: ProgressCircleProps) {
	return (
		<div className={styles.progressBar}>
			{steps.map((step) => (
				<ProgressCircle
					key={step.key}
					step={step}
					currentStep={currentStep}
					onStepClick={onStepClick}
				/>
			))}
		</div>
	);
}

const ProgressCircle = ({
	step,
	currentStep,
	onStepClick,
}: {
	step: ProgressStep;
	currentStep: ProgressStep;
	onStepClick: (step: ProgressStep) => void;
}) => {
	const isActive = step.number <= currentStep.number;
	const isPreviousStep = step.number < currentStep.number;

	return (
		<>
			{step.number > 1 && <ProgressLine isActive={isActive} />}
			<Tooltip title={step.label}>
				<div
					className={cn(styles.progressCircle, {
						[styles.isActive]: isActive,
						[styles.isPreviousStep]: isPreviousStep,
					})}
					onClick={() => isPreviousStep && onStepClick(step)}
					data-cy={step.dataCy}
				>
					{isPreviousStep ? (
						<div className="flex items-center justify-center h-6">
							<GenemodIcon
								name="checkmark"
								color="text-on-color"
							/>
						</div>
					) : (
						step.number
					)}
				</div>
			</Tooltip>
		</>
	);
};

function ProgressLine({ isActive }: { isActive: boolean }) {
	return (
		<span
			className={cn(styles.progressLine, {
				[styles.isActive]: isActive,
			})}
		/>
	);
}

export default ProgressCircles;
