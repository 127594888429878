import * as React from "react";

// When an attachment does not have a file icon that correlates with its file type, we default its file icon to this
function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	const extraStyle = { fill: "#7e7e7e" };
	const extraStyle2 = { fill: "#c4c4c4" };
	const extraStyle3 = { fill: " #707070" };

	return (
		<svg
			data-name="type_unrecognized (x48)"
			xmlns="http://www.w3.org/2000/svg"
			width={48}
			height={48}
			viewBox="0 0 48 48"
			{...props}
		>
			<path
				data-name="Path 4295"
				d="M2.28 0h14.292c1.128 1.13 7.066 6.985 8.07 7.991v21.688a2.281 2.281 0 0 1-2.28 2.283H2.28A2.281 2.281 0 0 1 0 29.679V2.283A2.281 2.281 0 0 1 2.28 0z"
				transform="translate(12 8.038)"
				style={extraStyle2}
			/>
			<path
				d="m330.742 5.962 8.036 8.068h-8.036z"
				transform="translate(-302.151 2.038)"
				style={extraStyle}
			/>
			<path
				data-name="Path 4321"
				d="M3.811 3.122h1.26a.447.447 0 0 0 .49-.467v-.863C5.562.135 9.179-.4 9.179-3.342 9.179-5.56 7.312-7.1 4.885-7.1A5.626 5.626 0 0 0 .45-5.046a.437.437 0 0 0 0 .607l.77.747a.381.381 0 0 0 .56 0 3.879 3.879 0 0 1 2.871-1.4 1.873 1.873 0 0 1 2.1 1.8c0 2.147-3.384 2.451-3.384 4.994v.98a.443.443 0 0 0 .444.44zm-1 4.971A1.622 1.622 0 1 0 4.418 6.46a1.614 1.614 0 0 0-1.61 1.633z"
				transform="translate(19.673 26.359)"
				style={extraStyle3}
			/>
		</svg>
	);
}

const DefaultIcon = React.memo(SvgComponent);
export default DefaultIcon;
