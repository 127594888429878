import { NominalUuid } from "@helpers/TypeHelpers";
import { ExperimentId } from "./ExperimentId";
import { GenemodBaseModel } from "./GenemodBaseModel";
import { GenemodDocumentUUID } from "./GenemodDocumentUUID";

export type ChatMessageId = NominalUuid<"ChatMessageId">;

export type ChatMessage = GenemodBaseModel & {
	id: ChatMessageId;
	prompt: string;
	completion: string;
};

/**
 * Typically returned by list endpoint
 */
type WithSummary = {
	summary: string;
};

/**
 * Typically returned by detail endpoint
 */
type WithMessages = WithSummary & {
	prompt_and_completion_sequence: { prompt_and_completions: ChatMessage[] };
};

export type SuggestedPromptsId = NominalUuid<"SuggestedPromptsId">;
export type SuggestedPrompts = {
	id: SuggestedPromptsId;
	title_used_for_prompt: string;
	used_prompt: string;
	completion: string[];
};

export type ExperimentDocumentSuggestedPromptsId =
	NominalUuid<"ExperimentDocumentSuggestedPromptsId">;
export type ExperimentDocumentSuggestedPrompts = {
	id: ExperimentDocumentSuggestedPromptsId;
	experiment_document: GenemodDocumentUUID;
	suggested_prompts: SuggestedPrompts;
};

export type ExperimentDocumentChatId = NominalUuid<"ExperimentDocumentChatId">;

export type ExperimentDocumentChatListItem = GenemodBaseModel &
	WithSummary & {
		id: ExperimentDocumentChatId;
		experiment_file: ExperimentId;
	};

export type ExperimentDocumentChat = ExperimentDocumentChatListItem &
	WithMessages;

export const RevisionType = {
	REWRITE: 0,
	CASUAL: 1,
	FORMAL: 2,
	SHORTEN: 3,
	EXPAND: 4,
} as const;

export type RevisionTypeValue =
	(typeof RevisionType)[keyof typeof RevisionType];

export type HighlightedTextRevisionId =
	NominalUuid<"HighlightedTextRevisionId">;

export type HighlightedTextRevision = GenemodBaseModel & {
	id: HighlightedTextRevisionId;
	highlighted_text: string;
	revision_type: RevisionTypeValue;
	experiment_document: ExperimentId;
	was_used: boolean;
	prompt_and_completion: ChatMessage;
};
