export type PlanName = "free" | "team" | "business" | "enterprise"; // TODO: adapt this to PlanType in Stripe.ts

export type AllPlanRestrictionType = {
	free: number | undefined;
	team: number | undefined;
	business: number | undefined;
	enterprise: number | undefined;
};

// Add new restriction types here
type FreezerRestrictionTypes =
	| "shared_freezer"
	| "private_freezer"
	| "freezer_analytics"
	| "consumables_spaces"
	| "freezer_search"
	| "freezer_activity_history"
	| "custom_item_type"
	| "order_management"
	| "items";

type ProjectRestrictionTypes =
	| "projects"
	| "archived_projects"
	| "pm_protocol_library_search"
	| "pm_archived_search"
	| "pm_activity_history"
	| "protocols"
	| "protocol_versions";

type DashboardRestrictionTypes = "members";

type IntegrationRestrictionTypes = "google_drive";

type CoreFacilityRestrictionTypes = "core_facility";

export type RestrictionTypes =
	| FreezerRestrictionTypes
	| ProjectRestrictionTypes
	| DashboardRestrictionTypes
	| IntegrationRestrictionTypes
	| CoreFacilityRestrictionTypes;

type RestrictionTypesMappingType<OtherType> = {
	[Restriction in RestrictionTypes]: OtherType;
};

export type AllPlanRestrictions =
	RestrictionTypesMappingType<AllPlanRestrictionType>;

export type PlanRestrictionLimit = {
	current: number;
	limit: number | null;
	is_limit_reached: boolean;
};

export type PlanRestrictionData = {
	plan_name: PlanName;
} & RestrictionTypesMappingType<PlanRestrictionLimit>;

export type SingleFeatureRestrictionData = {
	plan_name: PlanName;
} & PlanRestrictionLimit;

export const PlanUpgradeMap = {
	free: "team",
	team: "business",
	business: "enterprise",
	enterprise: null,
} as const;

/**
 * Map from months to readable label
 */
export function getActivityHistoryLabel(months: number | undefined): string {
	if (!months) {
		return "unlimited"; // not currently used, may need to tweak wording
	}

	// make a more general function if these options become more flexible
	return {
		1: "1 month",
		6: "6 months",
		12: "one year",
	}[months] as string;
}
