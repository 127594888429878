import React, { useEffect } from "react";
import styles from "./CollapsibleCreatePanel.module.scss";
import Typography from "@common/components/Typography/Typography";
import Button from "@common/components/Button";
import { PersistedObject } from "@helpers/TypeHelpers";
import {
	useFormState,
	UseFormStateProps,
} from "@helpers/Hooks/UseFormStateHook";
import { ButtonV2 } from "@common/components";

type CollapsibleCreatePanelProps<T extends PersistedObject> = {
	title: string;
	onCancel: () => void;
	hideHeader?: boolean;
	nameEmpty?: boolean;
	extraPadding?: boolean;
} & UseFormStateProps<T>;

/**
 * Povides a standard panel meant to be sued with CollapsibleViewEditCards
 */
export const CollapsibleCreatePanel = <T extends PersistedObject>(
	props: CollapsibleCreatePanelProps<T>
) => {
	const title = props.title;
	const {
		isValid,
		isSubmitting,
		onSave,
		formItems: cards,
		extra,
	} = useFormState<T>(props);

	useEffect(() => {
		if (props.nameEmpty) {
			extra.setValidityState({
				name: false,
			} as any);
		}
	}, [props.nameEmpty]);

	return (
		<div className={styles.collapsibleCreatePanel}>
			{!props.hideHeader && (
				<div className={styles.panelHeader}>
					<Typography
						variant="headline4"
						medium
						color="text-secondary-v2"
					>
						{title}
					</Typography>
					<div
						style={{
							display: "flex",
						}}
					>
						<ButtonV2
							type="link"
							onClick={props.onCancel}
							style={{ marginRight: 16 }}
						>
							Cancel
						</ButtonV2>
						<ButtonV2
							type="primary"
							onClick={onSave}
							disabled={!isValid || isSubmitting}
							loading={isSubmitting}
						>
							Create item
						</ButtonV2>
					</div>
				</div>
			)}
			<div className={styles.container} style={{ overflowY: "scroll" }}>
				{cards.map((card, index) => (
					<div
						key={"card-" + index}
						style={{
							padding: props.extraPadding ? "32px 0" : undefined,

							borderBottom:
								index < cards.length - 1
									? "1px solid var(--border-subtle)"
									: "",
						}}
					>
						{card}
					</div>
				))}
			</div>
		</div>
	);
};
