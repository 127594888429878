import { IdsInConstType, NominalId, NominalType } from "@helpers/TypeHelpers";
import {
	Avatar,
	CURRENCY_TYPES,
	ISOString,
	Comment,
	PaginatedSearchResults,
	StringSearchFilterOption,
	PaginatedSearchQuery,
	UserId,
} from ".";
import { Color } from "../styles/Colors";
import { mapToKeyValue } from "../helpers/TypeHelpers";
import { WorkspaceId } from "./AdminEnterprisePortal";
import { BaseAttachment } from "./BaseAttachment";
import { UserIdSearchFilterOption } from "./Search";

export const ITEM_ORDER_CHAR_LIMITS = Object.freeze({
	NAME: 200,
	ORDER_NUMBER: 200,
	PRICE: 200,
	CATALOG: 200,
	LOT: 200,
	PACKAGING: 200,
	CONCENTRATION: 40,
	VOLUME: 40,
	GRANT_ID: 200,
	LINK: 200,
	REFERENCE: 200,
	SOURCE: 200,
	NOTES: 200,
});

export type FreezerOrderId = NominalId<"FreezerOrderId">;

export type FreezerOrder = {
	id: FreezerOrderId;
	/** ID of the item. Undefined if this order was not created from an item */
	item?: number;
	/** ID of the consumable. Undefined if this order was not created from an consumable */
	consumable?: number;
	/** Status of the order */
	status: FreezerOrderStatusId;
	/** Name of the item */
	name: string;
	/** User defined order number */
	order_number: string;
	/** Price per unit */
	price: string;
	/** Number of units ordered */
	quantity: number;
	currency: IdsInConstType<typeof CURRENCY_TYPES>;
	source: string;
	reference: string;
	catalog: string;
	lot: string;
	packaging: string;

	concentration: string;
	volume: string;
	submitted_by: Avatar;
	submitted_at: string;
	completed_by: Avatar;
	completed_at: string;
	updated_at: string;
	is_archived: boolean;
	grant_id?: string;
	link?: string;
	status_changed_at?: string;
	notes?: string;
};

export type FreezerOrderSettings = {
	id: NominalId<"FreezerOrderSettingsId">;
	workspace: WorkspaceId;
	require_order_approval: boolean;
	allow_modification_after_approval: boolean;
};

export const DEFAULT_ORDER_SETTINGS = {
	require_order_approval: true,
	allow_modification_after_approval: true,
};

export type FreezerOrderAttachment = BaseAttachment & {
	order: FreezerOrderId;
	document_type: "FreezerOrderAttachment";
};

export type FreezerOrderComment = Comment & {
	order: FreezerOrderId;
};

export const ORDER_TYPE_TO_LABEL = {
	name: "Item name",
	quantity: "Qty",
	price: "Unit cost",
	total_price: "Total cost",
	submitted_at: "Submitted on",
	submitted_by: "Submitted by",
	completed_at: "Completed on",
	completed_by: "Completed by",
	source: "Source / Vendor",
	reference: "Reference",
	catalog: "Catalog #",
	lot: "Lot #",
	packaging: "Packaging",
	concentration: "Concentration",
	volume: "Vol / Mass",
	order_number: "Order #",
	currency: "Currency",
	grant_id: "Grant ID",
};

/**
 * The type that order status categories must conform to
 */
type FreezerOrderStatusCategoryData = {
	id: number;
	label: string;
};

/**
 * Add new categories here
 */
const FREEZER_ORDER_STATUS_CATEGORY_DATA = [
	{
		id: 1,
		label: "Requested",
	},
	{
		id: 2,
		label: "Processing",
	},
	{
		id: 3,
		label: "Complete",
	},
	{
		id: 4,
		label: "Incomplete",
	},
] as const satisfies Readonly<FreezerOrderStatusCategoryData[]>;

/**
 * Order status category enum
 */
export const FREEZER_ORDER_STATUS_CATEGORIES = mapToKeyValue(
	FREEZER_ORDER_STATUS_CATEGORY_DATA,
	"label"
);

/**
 * Order status category id type
 */
export type FreezerOrderStatusCategoryId = NominalType<
	"FreezerOrderStatusCategoryTypeId",
	(typeof FREEZER_ORDER_STATUS_CATEGORY_DATA)[number]["id"]
>;

/**
 * The type that order status must conform to
 */
type FreezerOrderStatusDataType = {
	id: number;
	category: FreezerOrderStatusCategoryId;
	label: string;
	color: Color;
};

/**
 * Order status id type
 */
export type FreezerOrderStatusId = NominalType<
	"FreezerOrderStatusId",
	0 | 1 | 2 | 3 | 4
>;

/**
 * Add new statuses here
 */
const FREEZER_ORDER_STATUS_DATA = [
	{
		id: 0 as FreezerOrderStatusId,
		category: 1 as FreezerOrderStatusCategoryId,
		label: "Requested",
		color: "gray-06",
	},
	{
		id: 1 as FreezerOrderStatusId,
		category: 2 as FreezerOrderStatusCategoryId,
		label: "Approved",
		color: "violet-06",
	},
	{
		id: 2 as FreezerOrderStatusId,
		category: 2 as FreezerOrderStatusCategoryId,
		label: "Ordered",
		color: "royal-blue-06",
	},
	{
		id: 3 as FreezerOrderStatusId,
		category: 3 as FreezerOrderStatusCategoryId,
		label: "Received",
		color: "green-04",
	},
	{
		id: 4 as FreezerOrderStatusId,
		category: 4 as FreezerOrderStatusCategoryId,
		label: "Denied",
		color: "dust-red-06",
	},
] as const satisfies Readonly<FreezerOrderStatusDataType[]>;

/**
 * Order status enum
 */
export const FREEZER_ORDER_STATUSES = mapToKeyValue(
	FREEZER_ORDER_STATUS_DATA,
	"label"
);

export const FREEZER_ORDER_STATUSES_BY_ID = {
	[0 as FreezerOrderStatusId]: FREEZER_ORDER_STATUSES.Requested,
	[1 as FreezerOrderStatusId]: FREEZER_ORDER_STATUSES.Approved,
	[2 as FreezerOrderStatusId]: FREEZER_ORDER_STATUSES.Ordered,
	[3 as FreezerOrderStatusId]: FREEZER_ORDER_STATUSES.Received,
	[4 as FreezerOrderStatusId]: FREEZER_ORDER_STATUSES.Denied,
};

export type FilterOptions = {
	source: StringSearchFilterOption[];
	status: StringSearchFilterOption[];
	submitted_by: UserIdSearchFilterOption[];
	archived_by: UserIdSearchFilterOption[];
};

export type FreezerOrderSearchResults = PaginatedSearchResults<
	FreezerOrder,
	FilterOptions
>;

type FreezerOrderOrderingFilters = Extract<
	keyof FreezerOrder,
	"name" | "submitted_at" | "status_changed_at"
>;

export type FreezerOrderSearchFilterState = Omit<
	PaginatedSearchQuery &
		Partial<{
			is_archived: boolean;

			submitted_at__gte: ISOString | null;
			submitted_at__lte: ISOString | null;

			source__in: string[];

			submitted_by__in: UserId[];

			status__in: FreezerOrderStatusId[];

			updated_at__gte: ISOString | null;
			updated_at__lte: ISOString | null;
			include_filter_options: boolean | null;
			workspace: WorkspaceId;
			ordering:
				| FreezerOrderOrderingFilters
				| `-${FreezerOrderOrderingFilters}`;
		}>,
	"sort_by"
>;

/**
 * Fields that can be used to prefill the order form
 */
export type OrderFormData = Omit<
	FreezerOrder,
	| "id"
	| "is_complete"
	| "submitted_by"
	| "submitted_at"
	| "completed_by"
	| "completed_at"
	| "updated_at"
	| "status"
	| "is_archived"
>;

/**
 * Order status history id type
 */
export type FreezerOrderStatusHistoryStatusId = NominalType<
	"FreezerOrderStatusHistoryStatusId",
	0 | 1 | 2 | 3 | 4 | 5
>;

export type FreezerOrderStatusHistory = {
	id: NominalId<"FreezerOrderStatusHistoryId">;
	order: FreezerOrderId;
	status: FreezerOrderStatusHistoryStatusId;
	created_at: ISOString;
	created_by: Avatar;
	is_archived: boolean;
};

export const FREEZER_ORDER_STATUS_HISTORY_ARCHIVED_ID =
	5 as FreezerOrderStatusHistoryStatusId;

export const FREEZER_ORDER_STATUS_HISTORY_LABELS = {
	0: "Submitted",
	1: "Approved",
	2: "Ordered",
	3: "Received",
	4: "Denied",
	5: "Archived",
};
