import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			id="type_experiment_mini"
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				data-name="Path 4299"
				d="M1 0h6.27c.495.495 3.1 3.06 3.54 3.5V13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"
				fill="#c2f1fc"
				transform="translate(6.595 5)"
			/>
			<g data-name="Group 18209">
				<g data-name="Group 18202">
					<path
						data-name="Union 129"
						d="M0 5.181a3 3 0 0 1 2-2.829V.769a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1v1.583a3 3 0 1 1-4 2.829z"
						transform="translate(9.091 8.731)"
						stroke="#1a6a89"
						strokeWidth="0.8px"
						fill="none"
					/>
				</g>
				<path
					data-name="Intersection 3"
					d="M2.821 3A2.77 2.77 0 0 1 0 .458V.333A3.662 3.662 0 0 1 1.731.015c.951.078 1.205.454 2.275.473A4.445 4.445 0 0 0 5.952 0 2.185 2.185 0 0 1 6 .458 2.771 2.771 0 0 1 3.178 3z"
					transform="translate(9.092 14)"
					fill="#1a6a89"
				/>
			</g>
		</svg>
	);
}

const MiniExperimentIcon = React.memo(SvgComponent);
export default MiniExperimentIcon;
