import React from "react";
import { Tooltip, Modal, Icon } from "antd";
import UserAvatar from "../UserAvatar/UserAvatar";
import UserSelector from "../UserSelector/UserSelector";
import { Spin } from "@components";
import "./UserList.scss";
import "./icons/icons.scss";

export default class UserList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isEditing: false,
			tempSelected: [],
			isLoading: false,
		};
	}

	// Displays the edit user modal
	onEdit = () => {
		this.setState({
			isEditing: true,
			tempSelected: [...this.props.selectedUsers],
		});
	};

	// Closes the modal and calls the callback function.
	// Passes in a list of the selected users
	closeModal = (callback) => {
		let selected = [...this.state.tempSelected];
		this.setState(
			{
				isEditing: false,
				tempSelected: [],
			},
			() => {
				if (callback) {
					callback(selected);
				}
			}
		);
	};

	// Cancel editing
	onCancel = () => {
		this.closeModal(() => {
			if (this.props.onCancel) {
				this.props.onCancel();
			}
		});
	};

	// Save editing. Pass list to props.onSave
	onSave = () => {
		this.closeModal((selected) => {
			if (this.props.onSave) {
				this.props.onSave(selected);
			}
		});
	};

	onSelectedChange = (selected) => {
		this.setState({
			tempSelected: [...selected],
		});
	};

	render() {
		let maxDisplayed = this.props.maxUsersDisplayed
			? this.props.maxUsersDisplayed
			: this.props.selectedUsers.length;
		let showOverflow = this.props.selectedUsers
			? this.props.selectedUsers.length > this.props.maxUsersDisplayed
			: false;

		return (
			<>
				<div
					style={this.props.style}
					className={
						"genemod-userlist " +
						(this.props.className ? this.props.className : "")
					}
				>
					{this.props.displayLabel && (
						<div
							style={{ marginRight: "5px", whiteSpace: "nowrap" }}
						>
							{this.props.displayLabel}
						</div>
					)}
					{this.props.isLoading ? (
						<Spin style={{ display: "flex" }} />
					) : (
						<>
							{this.props.selectedUsers.length ? (
								<>
									{this.props.selectedUsers
										.slice(0, maxDisplayed)
										.map((user, i) => {
											return (
												<UserAvatar
													key={i}
													user={user}
													size={
														this.props.size
															? this.props.size
															: "default"
													}
													className="avatar"
												></UserAvatar>
											);
										})}
									{showOverflow && (
										<Tooltip
											title={`${
												this.props.selectedUsers
													.length -
												this.props.maxUsersDisplayed
											} more`}
										>
											<Icon
												className="ellipsis"
												type="ellipsis"
											/>
										</Tooltip>
									)}
								</>
							) : (
								<span style={{ marginRight: "5px" }}>None</span>
							)}
							{this.props.isEditable && (
								// +2 on height and width for border
								<span
									style={{
										fontSize: `${this.props.size / 2}px`,
										height: `${this.props.size + 2}px`,
										width: `${this.props.size + 2}px`,
										marginLeft: "5px",
									}}
									className="icon-edit edit-btn"
									onClick={this.onEdit}
								></span>
							)}
						</>
					)}
				</div>
				{this.state.isEditing && (
					<Modal
						className="userlist-modal"
						wrapClassName="userlist-modal-wrap"
						title={
							this.props.popupTitle
								? this.props.popupTitle
								: "No Title"
						}
						visible={this.state.isEditing}
						onOk={this.onSave}
						onCancel={this.onCancel}
						destroyOnClose={true}
						closable={false}
						okText="Save"
					>
						<UserSelector
							selectedTitle={this.props.selectedTitle}
							maxUsersSelected={this.props.maxUsersSelected}
							onChange={this.onSelectedChange}
							allUsers={this.props.allUsers}
							selectedUsers={this.state.tempSelected}
						/>
					</Modal>
				)}
			</>
		);
	}
}
