import React from "react";
import styles from "./index.module.scss";
import { DemoWrapper, DemoSection, Demo } from "@components";
import HelpCenterNavbarButton from "../HelpCenterNavbarButton/HelpCenterNavbarButton";
import NotificationNavbarButton from "../NotificationNavbarButton/NotificationNavbarButton";

type NavbarProps = { children: React.ReactNode; buttons?: JSX.Element[] };

/** Wrapper class for navigation bar
 * @function
 * @category components
 * @subcategory Navbar
 * */
export function Navbar({ children, buttons }: NavbarProps) {
	return (
		<div className={styles.container}>
			{children}
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: 16,
					marginLeft: "auto",
				}}
			>
				{buttons?.map((button) => button)}
				{buttons?.length && <NavbarDivider />}
				<NotificationNavbarButton />
				<HelpCenterNavbarButton />
			</div>
		</div>
	);
}

function NavbarDivider() {
	return (
		<div className={styles.navbarDividerContainer}>
			<div className={styles.navbarDivider} />
		</div>
	);
}

export function NAVBAR_DEMO() {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage"
					description="Just a wrapper component for navigation bar used on almost every page"
				>
					<div
						style={{
							width: "100%",
							height: "400px",
							background: "orange",
						}}
					>
						<Navbar>Navbar wrapper component</Navbar>
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
