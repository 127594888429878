import React from "react";
import { GenemodIcon, Typography, IconName } from "@components";
import { SharedInternalObj } from "@common/types";
import styles from "@containers/ProjectManagement/components/ProjectSharingPreferences/ProjectSharingPreferences.module.scss";

type SharedWithDisplayProps<T> = {
	sharedObject?: SharedInternalObj<T>;
};

type SharingPreferences = "personal" | "workspace" | "org";

const ItemSharingPreferences = <T,>({
	sharedObject,
}: SharedWithDisplayProps<T>): JSX.Element => {
	const sharingPreference = sharedObject?.sharing || "org";
	const sharingPreferences: {
		[key in SharingPreferences]: {
			title: string;
			description: string;
			icon: IconName;
		};
	} = {
		personal: {
			title: "Private",
			description: "Only people invited can access this freezer",
			icon: "lock-o",
		},
		workspace: {
			title: "Workspace",
			description: "Everyone in your workspace can access this freezer",
			icon: "people-group",
		},
		org: {
			title: "Organization",
			description:
				"Everyone in your organization can access this freezer",
			icon: "building-o",
		},
	} as const;

	const selectedSharingPreference = sharingPreferences[sharingPreference];

	return (
		<div className={styles.accessCard}>
			<div className={styles.icon}>
				<GenemodIcon
					name={selectedSharingPreference.icon}
					color="text-secondary-v2"
				/>
			</div>
			<span>
				<div className={styles.title}>
					<Typography
						style={{ fontWeight: 500 }}
						color={"text-secondary-v2"}
					>
						{selectedSharingPreference.title}
					</Typography>
				</div>
				<Typography variant="caption" color="text-tertiary-v2">
					{selectedSharingPreference.description}
				</Typography>
			</span>
		</div>
	);
};

export default ItemSharingPreferences;
