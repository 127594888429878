import React from "react";
import { Typography, GenemodIcon } from "@components";
import cn from "classnames";
import styles from "./PlanGuideCard.module.scss";
import { FeatureType, PLAN_GUIDE_DATA } from "./data";
import { Link } from "@common/helpers/Hooks/UseRouterDom";
import { IconName } from "../GenemodIcon/GenemodIconRegistry";

type PlanGuideCardProps = {
	type: "academia" | "institution";
};
export default function PlanGuideCard({ type }: PlanGuideCardProps) {
	const { image, title, discount, description, features, eligible, link } =
		PLAN_GUIDE_DATA[type];
	return (
		<div className={styles.cardContainer}>
			<div className={styles.leftSide}>
				<GenemodIcon
					name={image as IconName}
					color="accent-subtle"
					size="smallCard"
				/>
				<div className={styles.title}>
					<Typography variant="h1" color="accent-subtle">
						{title}
					</Typography>
					{discount && (
						<Typography
							className={styles.discount}
							variant="label"
							color="accent-strong"
							bold
						>
							{`Save ${discount}%`}
						</Typography>
					)}
				</div>
				<Typography color="text-tertiary">{description}</Typography>
				<Link
					to={{
						pathname: link,
					}}
					target="_blank"
				>
					<div className={styles.applyButton}>
						<Typography variant="headline" color="text-on-color">
							Apply now
						</Typography>
					</div>
				</Link>
			</div>
			<div className={styles.divider} />
			<div
				className={cn(styles.rightSide, {
					[styles.rightSide__institution]: type === "institution",
				})}
			>
				<EligibleContent
					contents={eligible}
					grid={type === "institution"}
				/>
				<FeatureContent features={features} />
			</div>
		</div>
	);
}

function EligibleContent({
	contents,
	grid,
}: {
	contents: string[];
	grid: boolean;
}) {
	return (
		<div className={styles.eligibleContent}>
			<Typography variant="headline" bold>
				Who is eligible:
			</Typography>
			<div
				className={cn(styles.eligibleList, {
					[styles.eligibleList__grid]: grid,
				})}
			>
				{contents.map((text) => (
					<div key={text} className={styles.eligibleText}>
						<GenemodIcon
							name="checkmark"
							fill="border-active"
							style={{ marginTop: 2 }}
						/>
						<Typography>{text}</Typography>
					</div>
				))}
			</div>
		</div>
	);
}

function FeatureContent({ features }: { features: FeatureType[] }) {
	return (
		<div className={styles.featureContent}>
			<Typography variant="headline" bold>
				What is included:
			</Typography>
			<div className={styles.featureList}>
				{features.map(({ icon, name }) => (
					<div key={name} className={styles.feature}>
						<GenemodIcon
							color="text-secondary"
							name={icon}
							size="large"
						/>
						<Typography>{name}</Typography>
					</div>
				))}
			</div>
		</div>
	);
}
