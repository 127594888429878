import React from "react";
import { Modal } from "@components";

export default function UnsavedChangesModal(props: {
	visible: boolean;
	onOk?: () => void;
	onCancel?: () => void;
}): JSX.Element {
	const { visible, onOk, onCancel } = props;
	return (
		<Modal
			visible={visible}
			title="You have unsaved changes"
			hideCancelButton
			onCancel={onCancel}
			onOk={onOk}
			okText="Leave page"
		>
			By leaving this page, you&apos;ll lose the current progress. To
			continue editing, press the exit button on the top right corner.
		</Modal>
	);
}
