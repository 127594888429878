import React from "react";
import {
	Demo,
	DemoSection,
	DemoWrapper,
	GenemodIcon,
	Typography,
} from "@components";
import styles from "./index.module.scss";
import moment from "moment";
import { formatShortDateString, ISOString } from "@common/types/Date";
import classnames from "classnames";

type LastUpdatedOnProps = {
	className?: string;
	date: ISOString;
};

export default function LastUpdatedOn({
	className,
	date,
}: LastUpdatedOnProps): JSX.Element {
	return (
		<Typography
			variant="caption"
			className={classnames(styles.lastUpdatedOn, className)}
			color="text-secondary"
		>
			<GenemodIcon name="checkmark" fill="accent-strong" />
			Last updated on {formatShortDateString(date)}
		</Typography>
	);
}

export function LASTUPDATEDON_DEMO(): JSX.Element {
	return (
		<DemoWrapper>
			<DemoSection>
				<Demo
					title="Basic usage"
					description="Basic last updated on element"
				>
					<LastUpdatedOn date={moment().toISOString() as ISOString} />
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}
