export type ProtocolLabel = {
	id: number;
	name: string;
};

/**
 * convert from ProtocolLabel[] to name list string[]
 */
export function tokenizeProtocolLabels(
	protocolLabels: ProtocolLabel[]
): string[] {
	return protocolLabels.map((l) => l.name);
}
