import { useLoginContext } from "@containers/Auth/LoginInterfaces/LoginContext";
import { useEffect } from "react";

type LogoutProps = {
	admin?: boolean;
};

export default function Logout({ admin = false }: LogoutProps) {
	const { loginStatus, logout } = useLoginContext();
	useEffect(() => {
		if (loginStatus === "LOGGEDIN") {
			// only logout after checking login status so we do not end in an infinite loop from the router state being updated
			logout();
		} else if (loginStatus === "LOGGEDOUT") {
			if (admin) {
				window.location.href = "/admin/login";
			} else {
				// navigate with window directly so redux and other js state is cleared.
				window.location.href = "/login";
			}
		}
	}, [loginStatus]);
	return null;
}
