export type Environment = "production" | "qa" | "dev" | "local" | "onprem";
export type EnvConfigOptions<T> = Partial<Record<Environment, T>>;

// Check if a string is either the string of a boolean ("true" or "false")
const isStringBoolean = (str: string) => {
	const loweredString = str.trim().toLocaleLowerCase();
	return loweredString === "true" || loweredString === "false";
};

// Convert boolean string ("true" or "false") to actual boolean data
const toBoolean = (dataStr: string) => {
	return !!(dataStr?.trim().toLowerCase?.() === "true");
};

/**
 * Returns the value for the current environment, defaulting to local
 */
export const envConfig = <K extends string, T>(
	key: K,
	opts: EnvConfigOptions<T>,
	extra?: { defaults?: T }
): Record<K, T> => {
	const env = process.env.REACT_APP_NODE_ENV as Environment;
	const { defaults } = extra || {};
	const possibleValues = [process.env[key], opts[env], defaults, opts.local];
	const validValues = possibleValues.filter(
		(v) => v !== null && v !== undefined
	);

	if (validValues.length === 0) {
		throw `Could not determine config: ${key}`;
	}

	let firstValidValue = validValues[0];

	// Check if a specific environment variable key value is stored as boolean string
	if (
		typeof firstValidValue === "string" &&
		isStringBoolean(firstValidValue)
	) {
		firstValidValue = toBoolean(firstValidValue) as unknown as T;
	}

	return { [key]: firstValidValue } as Record<K, T>;
};
