import { NominalUuid } from "@helpers/TypeHelpers";
import { ExperimentId } from "./ExperimentId";
import { Experiment } from "./Experiment";
import { BaseModel } from "./BaseModel";
import { Upload } from "./Upload";
import { AxiosRequestConfig } from "axios";
import { BaseAttachment } from "./BaseAttachment";
import { AttachmentAcceptedFile } from "./FileAttachment";

export type ExperimentReportId = NominalUuid<"ExperimentReportId">;

export type ExperimentReportListItem = {
	id: ExperimentReportId;
	name: string;
	experiments: ExperimentId[];
	upload: Upload;
	created_at: BaseModel["created_at"];
	created_by: BaseModel["created_by"];
};

export type ExperimentReport = Omit<ExperimentReportListItem, "experiments"> & {
	experiments: Experiment[];
};

export type ExperimentReportCreatePayload = {
	name: string;
	experiments: ExperimentId[];
	data: FormData;
	config?: AxiosRequestConfig;
};

export type ExperimentReportUpdatePayload = {
	id: ExperimentReportId;
	name: string;
};

export type ExperimentReportAttachment = {
	id: ExperimentReportId;
	/** uploaded attachment url */
	upload: string;
	/** type of attachment */
	file_type: AttachmentAcceptedFile;
	document_type: "ReportAttachment";
};
