export const PM_NAME_CHAR_LIMITS = Object.freeze({
	PROJECT: 200,
	FOLDER: 200,
	EXPERIMENT: 255,
	PROTOCOL: 200,
	REPORT: 200,
} as const);

export const PM_DESC_CHAR_LIMITS = Object.freeze({
	PROJECT: 200,
	SUBPROJECT: 200,
	EXPERIMENT: 200,
} as const);
